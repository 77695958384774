import React, { Fragment, useEffect, useRef } from "react";
import { FieldArray, reduxForm, formValueSelector } from "redux-form";
import NoteForm from "../../../../../partials/form/partials/note/NoteForm";
import { useSelector } from 'react-redux';

const ProspectNotesForm = (props) => {
  const { contacts = [], handleSubmit, onSubmit, profileId, selectedHeaderTab, setPageCount } = props;
  const selector = formValueSelector('prospect-notes');
  const noteFormDataRedux = useSelector(state => selector(state, 'notes'));
  const currentFormDataRef = useRef(null);

  useEffect(() => {
    currentFormDataRef.current = noteFormDataRedux;
  }, [noteFormDataRedux]);

  useEffect(() => {
    return () => {
      onSubmit({notes: currentFormDataRef.current});
    };
  }, []);

  useEffect(() => {
    props.destroy();
  }, [props.submitSucceeded]);

  return (
    <Fragment>
      <form className="prospectForm" onSubmit={handleSubmit}>
        <div className={contacts.length ? "" : "notes-field-disabled"}>
          <FieldArray
            name="notes"
            defaultValue={{ id: 4, name: "Prospect" }}
            placeholder="Enter a note about this prospect..."
            component={NoteForm}
            profileId={profileId}
            setPageCount={setPageCount}
            selectedHeaderTab={selectedHeaderTab}
          />
        </div>
      </form>
    </Fragment>
  );
};

export default reduxForm({ form: "prospect-notes" })(ProspectNotesForm);
