import React, { Fragment } from "react";
import { Form, Row } from "react-bootstrap";
import { Label } from "reactstrap";
import { TextareaAutosize, TextField } from "@material-ui/core";

const TextArea = ({
  input,
  label,
  rows,
  placeholder,
  meta, error, helperText,
  edit,
  ...others
}) => {
  return (
    <Fragment>
      {edit ? (
        <Fragment>
          <TextField
            fullWidth
            label={label}
            multiline
            rows={rows}
            {...input}
            error={error ?? (meta?.invalid)}
            helperText={helperText ?? meta?.error}
            placeholder={placeholder}
            {...others}
          />

          {/* <Form.Control
          size="sm"
            as="textarea"
            placeholder={placeholder}
            {...input}
            rows={rows}
            isInvalid={touched && error}
          />
          <Form.Control.Feedback type="invalid">
            {touched && error && <span>{error}</span>}
          </Form.Control.Feedback> */}
        </Fragment>
      ) : (
        <Fragment>
          <Row>
            <p>{input?.value}</p>
          </Row>
        </Fragment>
      )}
    </Fragment>
  );
};
TextArea.defaultProps = {
  rows: "3",
  edit: true,
};

export default TextArea;
