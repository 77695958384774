import React, { useState, Fragment, useEffect } from "react";
import {connect, useSelector} from "react-redux";
import { reduxForm, Field, FieldArray } from "redux-form";
import { Form, Col, Row, Spinner } from "react-bootstrap";
// import {
//   combineValidators,
//   composeValidators,
//   isRequired,
//   hasLengthGreaterThan,
// } from "revalidate";
import TextInput from "../../../../../partials/form/TextInput";
import Item from "../../../../../partials/display/Item";
import MultiSelect from "../../../../../partials/form/MultiSelect";
import ToggleSwitch from "../../../../../partials/form/toggleSwitch/ToggleSwitch";
import FormFeatures from "../../../../../partials/form/partials/FormFeatures";
import FormBuyerPreferences from "../../../../../partials/form/partials/FormBuyerPreferences";
import ThemeHeader from "../../../../../partials/content/ThemeHeader";
import ThemeButton from "../../../../../partials/button/ThemeButton";
import {getAllMembers, getMenu} from "../../../../../GeneralApi/GeneralApi";
import { updateBuyer } from "../../../crud/buyersCrud";
import { toast } from "react-toastify";
import {
  convertFormData,
  getObjWithoutValues,
  postBuyerDetailsProcessedInfo,
  formToQueryString,
} from "../../../../../_helpers/_utils/utils";
import * as buyersRedux from "../../../_redux/buyersRedux";
import { BuyerProfileContext } from "../BuyerProfileContextProvider";
import { useContext } from "react";

// const validate = combineValidators({
//   title: isRequired({ message: "The event title is required" }),
//   category: isRequired({ message: "The category is required" }),
//   description: composeValidators(
//     isRequired({ message: "Please enter a description" }),
//     hasLengthGreaterThan(4)({
//       message: "Description needs to be at least 5 characters",
//     })
//   )(),
//   city: isRequired("city"),
//   venue: isRequired("venue"),
//   checkbox: isRequired("checkbox"),
//   startTime: isRequired("date"),
//   endTime: isRequired("date"),
// });

const BuyerDetailsEdit = (props) => {
  const { handleSubmit, pristine, submitting, invalid, initialValues } = props;

  const { updateContext, buyersTableUpdate, filters } = useContext(
    BuyerProfileContext
  );
  const onFormSubmit = async (values) => {
    const { primary_contact, contacts, ...otherData } = values;

    let contacts_Id = contacts.map((item) => item.id);
    let savingValue = {
      contacts: contacts_Id,
      ...otherData,
    };
    let formatedValue = postBuyerDetailsProcessedInfo(savingValue);

    const formData = convertFormData(
      getObjWithoutValues([undefined, null], formatedValue)
    );

    formData.append("_method", "PATCH");
    return await updateBuyer(values.id, formData)
      .then(({ data }) => {
        toast.success("Buyer Saved Successfully");
        updateContext();
        //get the buyers for Active||Inactive||All on query where initially the filters doesnot include then we will add
        if(filters && !filters?.hasOwnProperty('active')){
          filters.active = 1;
        }
        buyersTableUpdate(formToQueryString(filters));
      })
      .catch((e) => {
        toast.error("Error while buyer saving");
      });
  };
  const [buyer_type, setBuyer_type] = useState({});
  const [date_time_unit, setDate_time_unit] = useState({});
  const [rating, setRating] = useState({});
  const [condition, setCondition] = useState({});
  const [to_sell, setTo_sell] = useState({});
  const [finance_status, setFinance_status] = useState({});

  const [members, setMembers] = useState(null);
  const permissions = useSelector((state) => state.auth.user.permissions);
  const isLeader = useSelector((state)=> state.auth.user.is_leader);
  // const member
  useEffect(() => {
    let query =
      "buyer_to_sell,property_type,rating,finance_status,buyer_condition,buyer_type,date_time_unit";
    getMenu(query)
      .then(({ data: { data } }) => {
        setBuyer_type(data.buyer_type);
        setDate_time_unit(
          data.date_time_unit?.filter((unit) => [3, 5, 4, 6].includes(unit?.id))
        );

        setRating(data.rating);
        setCondition(data.buyer_condition);
        setTo_sell(data.buyer_to_sell);
        setFinance_status(data.finance_status);
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    getAllMembers()
        .then(({ data: { data } }) => {
          setMembers(
              data.map((s) => ({
                name: `${s.initial}`,
                id: s.contact.id,
                optionTooltipTitle:`${s?.contact?.first_name ?? ''} ${s?.contact?.last_name ?? ''}`,
              }))
          );
        })
        .catch((e) => {
          toast.error("Error while fetching");
        });
  }, []);

  return (
    <Fragment>
      <div className="buyerDetails">
        <Form onSubmit={handleSubmit(onFormSubmit)} noValidate>
          <ThemeHeader
            left={<h5 className="title">Buyer Details</h5>}
            right={
              <ThemeButton
                disabled={invalid || pristine || submitting}
                type="submit"
                className="green-button"
              >
                {submitting ? (
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  ""
                )}
                Save
              </ThemeButton>
            }
          />

          <Row className="pb-3">
            <Col className="mt-auto" md={4}>
              <Field
                name="type"
                label="Buyer Type"
                placeholder="Select type"
                component={MultiSelect}
                showBottom={false}
                isMulti={false}
                options={buyer_type}
              />
            </Col>
            <Col className="mt-auto" md={4}>
              <Field
                name="rating"
                label="Rating"
                placeholder="Select a rating"
                component={MultiSelect}
                showBottom={false}
                isMulti={false}
                options={rating}
                removable={true}
              />
            </Col>
            {initialValues?.allocated_to && (
              <Col className="mt-auto" md={4}>
                <Item
                  lg="5"
                  md="5"
                  col="5"
                  left="Allocation"
                  classNames="m-auto"
                  right={
                    isLeader ? (<Field
                        name="allocated_to"
                        component={MultiSelect}
                        showBottom={false}
                        isMulti={false}
                        options={members}
                        placeholder="Team"
                        label="Member"
                    />) :initialValues?.allocated_to_initial
                    // initialValues?.allocated_to
                    // <Field
                    //   name="allocated_to"
                    //   type="text"
                    //   edit={false}
                    //   component={TextInput}
                    // />
                  }
                />
              </Col>
            )}
          </Row>

          <Row className="pb-3">
            <Col className="mt-auto" md={4}>
              <Row>
                <Col lg={6}>
                  <Field
                    className=" mr-1"
                    name="looking_date"
                    type="text"
                    component={TextInput}
                    label={"Looking For"}
                  />
                </Col>
                <Col>
                  <Field
                    name="looking_date_time_unit"
                    label=" "
                    component={MultiSelect}
                    className="w-100"
                    showBottom={false}
                    isMulti={false}
                    options={date_time_unit}
                  />
                </Col>
              </Row>
            </Col>
            <Col className="mt-auto" md={4}>
              <Row>
                <Col lg={6}>
                  <Field
                    className="mr-1"
                    name="ready_in_date"
                    type="text"
                    component={TextInput}
                    label={"Buy In"}
                  />
                </Col>
                <Col>
                  <Field
                    label=" "
                    name="ready_in_date_time_unit"
                    component={MultiSelect}
                    className="w-100"
                    showBottom={false}
                    isMulti={false}
                    options={date_time_unit}
                  />
                </Col>
              </Row>
            </Col>
            <Col className="mt-auto" md={4}>
              <Item
                lg="5"
                md="5"
                col="5"
                left="Active"
                classNames="m-auto"
                right={<Field name="active" component={ToggleSwitch} />}
              />
            </Col>
          </Row>

          <Row className="pb-3">
            <Col className="mt-auto" md={4}>
              <Field
                label="Condition"
                name="condition"
                placeholder="Select a condition"
                component={MultiSelect}
                showBottom={false}
                isMulti={false}
                options={condition}
              />
            </Col>
            <Col className="mt-auto" md={4}>
              <Field
                name="to_sell"
                label="To Sell?"
                placeholder="Select a status"
                component={MultiSelect}
                showBottom={false}
                isMulti={false}
                options={to_sell}
              />
            </Col>
            <Col className="mt-auto" md={4}>
              <Item
                lg="5"
                md="5"
                col="5"
                left="Do Not Call?"
                classNames="m-auto"
                right={<Field name="dnc" component={ToggleSwitch} />}
              />
            </Col>
          </Row>

          <Row className="pb-3">
            <Col className="mt-auto" md={4}>
              <Field
                label="Username"
                name="username"
                type="text"
                component={TextInput}
              />
            </Col>
            <Col className="mt-auto" md={4}>
              <Field
                label="Password"
                name="password"
                type="text"
                component={TextInput}
              />
            </Col>
            <Col className="mt-auto" md={4}>
              <Field
                label="Motivation"
                name="motivation"
                type="text"
                component={TextInput}
              />
            </Col>
          </Row>

          <Row className="pb-3">
            <Col className="mt-auto" md={4}>
              <Field
                name="finance_status"
                placeholder="Select a status"
                component={MultiSelect}
                showBottom={false}
                isMulti={false}
                options={finance_status}
                label="Finance Status"
              />
            </Col>
            <Col className="mt-auto" md={4}>
              <Field
                label="Bank"
                name="bank"
                type="text"
                component={TextInput}
              />
            </Col>
            <Col className="mt-auto" md={4}>
              <Field
                label="Finance Rate"
                name="rate"
                type="text"
                component={TextInput}
              />
            </Col>
          </Row>
          <FormBuyerPreferences />
          <FieldArray name="features" component={FormFeatures} height="30" />
        </Form>
      </div>
    </Fragment>
  );
};

const mapState = (state, ownProps) => {
  return {
    initialValues: { ...ownProps.profile },
  }; // Foram name must have to be different
};

export default connect(
  mapState,
  buyersRedux.actions
)(
  reduxForm({ form: "edit-buyer-details", enableReinitialize: true })(
    BuyerDetailsEdit
  )
);
