import React from "react";
import parse from "html-react-parser";
import { CloseOutlined } from '@ant-design/icons';

const PreviewEmail = ({ data, closeModal }) => {
  const closePreviewModal = () => {
    closeModal(false)
  }
  return (
    <div>
      <div className='d-flex justify-content-end pb-3'>
        <CloseOutlined style={{ fontSize: '20px' }} onClick={closePreviewModal} />
      </div>
      <div className='preview-email-table'>{parse(data)}</div>
    </div>
  );
};

export default PreviewEmail;
