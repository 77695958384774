import React, { Fragment } from "react";
import { FieldArray } from "redux-form";
// import DropzoneField from '../../../../partials/form/dropzoneField/DropzoneField'
import DropzoneFieldWithCropper from "../../../../partials/form/dropzoneField/DropzoneFieldWithCropper";
// import DropZoneWithDragger from '../../../../partials/form/dropzoneField/DropZoneWithDragger'
// import DropzoneFieldWithCropper from '../../../../partials/form/dropzoneField/DropzoneFieldWithCropper'

const AddNewListingImages = ({ hanldeSelectedImages, selectedItems }) => {
  return (
    <Fragment>
      <div className="container">
        {/* <FieldArray
                    hanldeSelectedImages={hanldeSelectedImages}
                    selectedItems={selectedItems}
                    name="images"
                    type='file'
                    acceptedFileType="image/*"
                    uploadType="image"
                    bulkAction
                    component={DropZoneWithDragger}
                /> */}
        <FieldArray
          bulkAction
          component={DropzoneFieldWithCropper}
          selectedItems={selectedItems}
          aspectRatio={3 / 2}
          // refData={listingRefValue}
          name="images"
          type="file"
          acceptedFileType="image/*"
          uploadType="image"
          overWriteStyles={{ width: 160, height: 105 }}
          hanldeSelectedImages={hanldeSelectedImages}
          // deletePermissions={["listings-delete"]}
          // downLoadPermissions={["listings-download"]}
        />
      </div>
    </Fragment>
  );
};

export default AddNewListingImages;
