import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest, delay, select, race, call } from "redux-saga/effects";
import { getAllContacts, getContactById } from "../crud/contactsCrud";

export const actionTypes = {
  ContactsLoading: "[ContactsLoading]",
  ContactFetched: "[ContactFetched]",
  ContactFetch: "[ContactFetch]",
  ContactsFetched: "[ContactsFetched]",
  ContactCreated: "[ContactCreated]",
  ContactUpdated: "[ContactUpdated]",
  ContactDeleted: "[ContactDeleted]",
  ContactsDeleted: "[ContactsDeleted]",
  ContactsStatusUpdated: "[ContactsStatusUpdated]",
  ContactsLoadingIndicator: "[ContactsLoadingIndicator]",

  ContactSelectedId: "[ContactSelectedId]",
  ContactFetchMore: "[ContactFetchMore]",
  ContactSelectedChangeId: "[ContactSelectedChangeId]",
  ContactSelected: "[ContactSelected]",
};

const initialContactsState = {
  isLoading: false,
  actionsLoading: false,
  queryString: "",
  entities: null,
  otherResInfo: null,
  contactForEdit: undefined,
  error: null,
  profile: null,
  viewData: {
    id: null,
    index: null,
    isLast: false,
  },
  continuousMood: false,
};

export const reducer = persistReducer(
  { storage, key: "wol-auth", whitelist: ["contacts"] },
  (state = initialContactsState, action) => {
    switch (action.type) {
      case actionTypes.ContactsFetched: {
        const { data, otherResInfo, queryString } = action.payload;
        return {
          ...state,
          entities: data,
          otherResInfo: otherResInfo,
          queryString: queryString
        };
      }
      case actionTypes.ContactSelectedId: {
        return {
          ...state,
          viewData: {
            id: action.payload.id,
            index: action.payload.index,
            isLast: false,
          },
        };
      }
      case actionTypes.ContactsLoadingIndicator: {
        const { indicator } = action.payload;
        return {
          ...state,
          isLoading: indicator 
        };
      }
      case actionTypes.ContactFetched: {
        const { data } = action.payload;
        return {
          ...state,
          profile: data,
        };
      }
      case actionTypes.ContactError: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  contactsLoading: (data) => ({ type: actionTypes.ContactsLoading, data }),
  contactFetch: (data) => ({
    type: actionTypes.ContactFetch,
    payload: data,
  }),
  // socialFeedPostAsync: data => ({ type: actionTypes.SocialFeedPostAsync, payload: data }),

  contactFetchMore: (data) => ({ type: actionTypes.ContactFetchMore, data }),
  contactSelectedId: (data) => ({
    type: actionTypes.ContactSelectedId,
    payload: data,
  }),
  contactSelectedChangeId: (data) => ({
    type: actionTypes.ContactSelectedChangeId,
    payload: data,
  }),
  contactSelected: (data) => ({
    type: actionTypes.ContactSelected,
    payload: data,
  }),

  contactFetched: (data) => ({
    type: actionTypes.ContactFetched,
    payload: data,
  }),
  contactsFetched: (data) => ({
    type: actionTypes.ContactsFetched,
    payload: data,
  }),

  contactsLoadingIndicator: (data) => ({
    type: actionTypes.ContactsLoadingIndicator,
    payload: data,
  }),

  contactCreated: (data) => ({
    type: actionTypes.ContactCreated,
    payload: { data },
  }),
  contactUpdated: (data) => ({
    type: actionTypes.ContactUpdated,
    payload: { data },
  }),
  contactDeleted: (data) => ({
    type: actionTypes.ContactDeleted,
    payload: { data },
  }),
  contactsDeleted: (data) => ({
    type: actionTypes.ContactsDeleted,
    payload: { data },
  }),
  contactsStatusUpdated: (data) => ({
    type: actionTypes.ContactsStatusUpdated,
    payload: { data },
  }),
  contactError: (error) => ({
    type: actionTypes.ContactError,
    payload: { error },
  }),
};

export const getContacts = (state) => state.contacts;

export function* sagaContacts() {
  yield takeLatest(actionTypes.ContactsLoading, function* contactsLoading(
    prams
  ) {
    yield put(actions.contactsLoadingIndicator({ indicator: true }));
    let contactsInfo = yield select(getContacts);
    let queryData = prams.data ? prams.data : (contactsInfo.queryString ? contactsInfo.queryString : "")
    console.log(queryData)
    const res = yield getAllContacts(queryData);
    const { data, ...rest } = { ...res.data };
    yield put(actions.contactsFetched({ data: data, otherResInfo: rest , queryString: queryData }));
    yield put(actions.contactsLoadingIndicator({ indicator: false }));
  });

  yield takeLatest(actionTypes.ContactFetchMore, function* contactFetchMore(
    prams
  ) {
    let contactsInfo = yield select(getContacts);
    const res = yield getAllContacts(prams.data);
    const { data, ...rest } = { ...res.data };
    const newData = contactsInfo.entities.concat(data);
    console.log(newData);
    yield put(actions.contactsFetched({ data: newData, otherResInfo: rest }));
  });

  yield takeLatest(actionTypes.ContactSelected, function* contactSelected({
    payload,
  }) {
    console.log("called TenantSelected");
    let contactsInfo = yield select(getContacts);
    let index = contactsInfo.entities.findIndex((item) => item.id === payload);
    console.log(index);
    console.log(contactsInfo.entities);
    console.log(payload);
    // console.log(index);
    yield put(actions.contactFetch(payload));
    yield put(actions.contactSelectedId({ id: payload, index: index }));
  });

  yield takeLatest(
    actionTypes.ContactSelectedChangeId,
    function* contactSelectedChangeId({ payload }) {
      console.log(payload);
      let contactsInfo = yield select(getContacts);
      console.log(contactsInfo.viewData.index);

      let singleInfo = contactsInfo.entities[contactsInfo.viewData.index];
      // let index = contactsInfo.entities.findIndex(item => item.id === payload);
      // yield delay(2000);
      console.log(contactsInfo.entities[contactsInfo.viewData.index]);
      console.log(payload);
      console.log(singleInfo);
      let newIndex;
      if (payload === "left") {
        newIndex = contactsInfo.viewData.index - 1;
      }
      if (payload === "right") {
        newIndex = contactsInfo.viewData.index + 1;
      }
      if(newIndex != -1 || newIndex > contactsInfo?.entities?.length )
      {
        let newId = contactsInfo.entities[newIndex].id;
        yield put(actions.contactFetch(newId));
        yield put(actions.contactSelectedId({ id: newId, index: newIndex }));
      }
      
    }
  );

  yield takeLatest(actionTypes.ContactFetch, function* contactFetch(
    data
  ) {
    const contact = yield getContactById(data.payload);
    yield put(actions.contactFetched(contact.data));
  });
}
