import React, { useEffect, useState } from 'react'
import { TextField, } from '@material-ui/core'

const OfficeSettings = ({ selectedNode }) => {
    const [officePhone, setOfficePhone] = useState('')
    const [officeWebsite, setOfficeWebsite] = useState('')
    const [officeName, setOfficeName] = useState('')

    const getTargetElement = targetClassName => {
        return selectedNode.getElementsByClassName(targetClassName)
    }
    useEffect(() => {

        let officeName = getTargetElement('officeName')[0].innerText
        setOfficeName(officeName);
        let officePhone = getTargetElement('office_phone')[0].innerText;
        setOfficePhone(officePhone);
        let officeWebsite = getTargetElement('office_website')[0].innerText
        setOfficeWebsite(officeWebsite);

    }, [selectedNode])

    const handleOfficePhoneChange = e => {
        getTargetElement('office_phone')[0].innerHTML = e.target.value
        setOfficePhone(e.target.value);
    }

    const handleOfficeNameChange = e => {
        getTargetElement('officeName')[0].innerHTML = e.target.value;
        setOfficeName(e.target.value);
    }
    const handleOfficeWebsiteChange = e => {
        getTargetElement('office_website')[0].innerHTML = e.target.value;
        setOfficeWebsite(e.target.value);
    }

    return (
        <div>
            <TextField
                className='my-3'
                type="text"
                label={'Office Name'}
                value={officeName}
                onChange={handleOfficeNameChange}
                fullWidth
                InputLabelProps={{
                    shrink: true
                }}
            />
            <TextField
                className='my-3'
                type="text"
                label={'Office Phone'}
                value={officePhone}
                onChange={handleOfficePhoneChange}
                fullWidth
                InputLabelProps={{
                    shrink: true
                }}
            />
            <TextField
                className='my-3'
                type="text"
                label={'Office Website'}
                value={officeWebsite}
                onChange={handleOfficeWebsiteChange}
                fullWidth
                InputLabelProps={{
                    shrink: true
                }}
            />

        </div>
    )
}
export default OfficeSettings