import React, { Component, Fragment } from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import { TextField } from "@material-ui/core";
import { Link, Redirect } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "./_redux/auth.duck";
import { requestPassword } from "./curd/auth.crud";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { convertFormData, mungeEmailAddress } from "../../_helpers/_utils/utils";
import ThemeButton from "../../partials/button/ThemeButton";

class ForgotPassword extends Component {
  state = {
    isRequested: false,
    username: "",
  };

  render() {
    const { intl } = this.props;
    const { isRequested, username } = this.state;

    return (
      <div className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper">
        <div className="kt-login__body">
          <div className="kt-login__form">
            {!isRequested ? (
              <Fragment>
                <div className="kt-login__title">
                  <h3>
                    {/* <FormattedMessage id="AUTH.FORGOT.TITLE" /> */} Forgot
                    Your Password?
                  </h3>
                  <p>
                    Enter your  username below <br /> and we'll
                    get you back on track.
                  </p>
                </div>
                <Formik
                  initialValues={{ username: "" }}
                  validate={(values) => {
                    const errors = {};

                    if (!values.username) {
                      errors.username = intl.formatMessage({
                        id: "AUTH.VALIDATION.REQUIRED_FIELD",
                      });
                    }

                    return errors;
                  }}
                  onSubmit={(values, { setStatus, setSubmitting }) => {
                    console.log(values);
                    let processedValue = convertFormData(values)
                    requestPassword(processedValue)
                      .then((res) => {
                        console.log(res);
                        this.setState({
                          isRequested: true,
                          username: res.data.message,
                        });
                      })
                      .catch(error => {
                        console.log(error)
                        setSubmitting(false);
                        setStatus("Username does not exist"
                        );
                      });
                  }}
                >
                  {({
                    values,
                    status,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (


                      <form onSubmit={handleSubmit} className="kt-form">
                        {console.log(status)}
                        {status && (
                          <div role="alert" className="alert alert-danger">
                            <div className="alert-text">{status}</div>
                          </div>
                        )}

                        <div className="form-group">
                          <TextField
                            type="text"
                            label="Username"
                            margin="normal"
                            fullWidth={true}
                            name="username"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.username}
                            helperText={touched.username && errors.username}
                            error={Boolean(touched.username && errors.username)}
                          />
                        </div>

                        <div className="kt-login__actions">
                          <div className="d-flex w-100 login justify-content-between"> <Link to="/auth">
                            <div>
                            <ThemeButton icon className="lightGrey-icon-button"><i className="fas fa-chevron-left"></i></ThemeButton>
                            </div>

                          </Link>     <ThemeButton disabled={isSubmitting} type="submit" className=" brand-button">Send Instructions</ThemeButton></div>

                        </div>
                      </form>
                    )}
                </Formik>
              </Fragment>
            ) : (
                <div className="kt-login__title pb-5 ">
                  <FontAwesomeIcon icon={faCheckCircle} size="6x" className='kt-font-brand pb-4' />
                  <h3 className="pb-4">
                    {/* <FormattedMessage id="AUTH.FORGOT.TITLE" /> */} Password
                  Reset Email Sent
                </h3>
                  <p >
                  Thank you. An email has successfully been sent. Please follow the instructions in the email to reset your password.
                    {/* Thank you. An email has been sent to{" "}
                    {mungeEmailAddress(username)} . Please follow the instruction in
                  the email to reset your password. */}
                </p>
                </div>
              )}
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(connect(null, auth.actions)(ForgotPassword));
