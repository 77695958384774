import React, { useState, useEffect } from 'react';
import { formToQueryString } from '../../../../_helpers/_utils/utils';
import { getAllListings } from '../../../listings/_crud/listingsCrud';
import {getAllRentals} from '../../../rentals/_crud/rentalsCrud';
import { IMAGE_PLACEHOLDER_URL } from '../../modals/digitalTemplate/AccordianConstants';
import _get from 'lodash/get';
import _find from 'lodash/find';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import { Col, Row } from 'react-bootstrap';
import { LinearProgress } from '@material-ui/core';
import _includes from "lodash/includes";
import _forEach from "lodash/forEach";
import { Field } from "redux-form";
import TextInput from "../../../../partials/form/TextInput";
import _debounce from 'lodash/debounce'
import { values } from 'lodash';
export default function WindowcardCustomise({
  selectedTemplateLayout = {},
  seletctedDate,
  setSeletctedDate,
  isEdit,
  selectedSocialTemplate,
  _selectedTemplate,
  
}) {
  const [blocks, setBlocks] = useState([]);
  const [editedListing, setEditedListing] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [q, setQ] = useState("");

  // console.log('=============selectedSocialTemplate=======================');
  // console.log(selectedTemplateLayout);
  // console.log(selectedSocialTemplate);
  // console.log(editedListing);
  // console.log(blocks);
  // console.log('===========selectedSocialTemplate=========================');

  const update =(value)=>{
    setIsLoading(true);
    let obj = {
      state: 1, 
      limit:10000, 
      search_kw: value?.search_kw,
    };
    let queryText = formToQueryString(obj);

    if (selectedTemplateLayout?.rentals == true || selectedSocialTemplate?.category?.name == "For Lease"){
      getAllRentals(queryText, true).then(({ data: { data, ...rest } }) => {
        setEditedListing(data.filter((block) =>block.id == selectedSocialTemplate.listing));
        // setBlocks(prev => prev.concat(data));
        setBlocks(data);
      }).finally(() => {
        setIsLoading(false);
      })
    }else{
    getAllListings(queryText, true).then(({ data: { data, ...rest } }) => {
        setEditedListing(data.filter((block) =>block.id == selectedSocialTemplate.listing));
        // setBlocks(prev => prev.concat(data));
        setBlocks(data);
      }).finally(() => {
        setIsLoading(false);
      })}
  }
  useEffect(() => {
    update()
  }, [])

const handleGlobalSearch = (value) => {
  setQ(value)
  update({search_kw: value });
};

const onSearch = _debounce(handleGlobalSearch, 500);
  const getImageURL = (assets = []) => {
    if (assets.length) {
      let imageObj = _find(assets, function (item) { return _get(item, 'type.id') === 1 });
      if (imageObj) {
        return imageObj.url;
      } else {
        return IMAGE_PLACEHOLDER_URL;
      }
    } else {
      return IMAGE_PLACEHOLDER_URL;
    }
  }
  const onDragStart = (event, template) => {
    event.dataTransfer.setData("drag-element", JSON.stringify(template));
    localStorage.setItem('current_dragging_template_layout_to_customise', event.target.className);
  };
  const getSectionContent = item => {
    return { url: getImageURL(_get(item, 'assets')), hoverContent: _get(item, 'property.address.full_address') }
  }

  return (
  <div>
      {isLoading ? <LinearProgress /> : null}
     <div className='mt-1 poster-template-customize social-media-accordian'>
  { !isEdit &&  <div className="mt-1">
                <Field
                    style={{ paddingLeft: "10px", paddingRight: "10px" }}
                    label={"Search Listings"}
                    name='search_tempaltes'
                    showErrorOnMount={true}
                    component={TextInput}
                    value={q}
                    onChange={(e) => {onSearch(e.target.value)}}
                />
            </div>}
        <Row>
          { isEdit ? _map(editedListing, (item, index) => {
            let { url, hoverContent } = getSectionContent(item)
            let splitAddress = hoverContent.split('NSW')

            return (
              <Col className='my-2 mx-2 p-0' key={index} style={{ flexGrow: 0 }}>
                <div
                  className='accordian-block' // className used for onDragOver identity purpose *
                  draggable
                  onDragStart={(event) => onDragStart(event, item)}
                >
                  <img
                    className="img__img"
                    src={url}
                    alt='house1'
                    loading="lazy"
                  />
                  <div className="img__description_layer">
                    <p className="img__description">{splitAddress ? splitAddress[0] : hoverContent}</p>
                  </div>
                </div>
              </Col>
            )
          }): _map(blocks, (item, index) => {
            let { url, hoverContent } = getSectionContent(item)
{/* let replacedURL = String(url).replace("https://v2-wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com", "https://j9v8vwl8o0.execute-api.ap-southeast-2.amazonaws.com/production") */}

            let splitAddress = hoverContent.split('NSW')

            return (
              <Col className='my-2 mx-2 p-0' key={index} style={{ flexGrow: 0 }}>
                <div
                  className='accordian-block' // className used for onDragOver identity purpose *
                  draggable
                  onDragStart={(event) => onDragStart(event, item)}
                >
                  <img
                    className="img__img"
                    src={url}
                    // src={`${replacedURL}?width=117&height=117`}
                    alt='house1'
                    loading="lazy"
                  />
                  <div className="img__description_layer">
                    <p className="img__description">{splitAddress ? splitAddress[0] : hoverContent}</p>
                  </div>
                </div>
              </Col>
            )
          })}
        </Row>

      </div>
    </div>
  )
}
