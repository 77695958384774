import React from "react";
import { connect } from "react-redux";
import objectPath from "object-path";
import Brand from "../brand/Brand";
import Topbar from "./Topbar/Topbar";
import HMenu from "./HMenu/HMenu";
import AnimateLoading from "../../../app/partials/layout/AnimateLoading";
import KTHeader from "../../_assets/js/header";
import * as builder from "../../ducks/builder";
import GlobalColorPalette from "../../../app/_helpers/GlobalColorPalette";
import { Link } from "react-router-dom";

class Header extends React.Component {
  headerCommonRef = React.createRef();
  

  componentDidMount() {
    // animate the header minimize the height on scroll down
    const { headerFixedEnabled, headerFixedDesktop } = this.props;
    if (headerFixedEnabled || headerFixedDesktop) {
      // header minimize on scroll down
      this.headerCommonRef.current.setAttribute("data-ktheader-minimize", "1");
    }

    let options = {
      classic: {
        desktop: true,
        mobile: false
      }
    };
    if (
      this.headerCommonRef.current.getAttribute("data-ktheader-minimize") ===
      "1"
    ) {
      objectPath.set(options, "minimize", {
        desktop: {
          on: "kt-header--minimize"
        },
        mobile: {
          on: "kt-header--minimize"
        }
      });
      objectPath.set(options, "offset", {
        desktop: 200,
        mobile: 150
      });
    }

    new KTHeader(this.headerCommonRef.current, options);
  }

   styles = theme => ({
    root: {
      backgroundColor: theme.palette.background.paper,
      width: 500,
    },
    mySelectedA: {
      color: 'blue',
    },  
    mySelectedB: {
      color: 'red',
    },
  
  });

  render() {
    const colors = this.props.colors;
    const themeStyles = {
      // define each css rules
      primaryText: '.primary-text { color:'+colors.primary+' !important;}',
      primaryBackground: '.primary-background { color:'+colors.brand+' !important;}',
     
      
      // ...
      // and all other rules as required
    }

    const element = document.createElement('style');
    element.type = 'text/css';
    document.head.appendChild(element);
    // get the sheet and append all the CSS rules
    const sheet = element.sheet;


    // sheet.insertRule(themeStyles[Object.keys(themeStyles)[0]],0);

    let style = (function() {
      // Create the <style> tag
      var style = document.createElement("style");
  
      // WebKit hack
      style.appendChild(document.createTextNode(""));
  
      // Add the <style> element to the page
      document.head.appendChild(style);
  
      return style;
  })();

  style.sheet.insertRule('.foo{color:red;}', 0);
  style.sheet.insertRule(themeStyles[Object.keys(themeStyles)[0]], 0);
  style.sheet.insertRule(themeStyles[Object.keys(themeStyles)[1]], 0);
  // style.sheet.removeRule(0)
  // style.sheet.removeRule(1)
  // style.sheet.removeRule(2)



    const {
      menuHeaderDisplay,
      headerContainerClasses,
      headerAttributes,
      headerClasses
    } = this.props;
    return (
      <div
        className={`kt-header foo primary-background primary-text kt-grid__item ${headerClasses}`}
        id="kt_header"
        {...headerAttributes}
        ref={this.headerCommonRef}
      >
        <GlobalColorPalette/>
        <AnimateLoading />
        <div className="kt-header__top">
          <div className={`kt-container ${headerContainerClasses}`}>
            <Brand /> 
            <Topbar />
          </div>
        </div>

        <div className="kt-header__bottom">
          <div className={`kt-container ${headerContainerClasses}`}>
            {/* <!-- begin: Header Menu --> */}
            {menuHeaderDisplay && <HMenu />}
            {/* <!-- end: Header Menu --> */}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => ({
  colors: store.builder.layoutConfig.colors.state,
  headerClasses: builder.selectors.getClasses(store, {
    path: "header",
    toString: true
  }),
  headerAttributes: builder.selectors.getAttributes(store, { path: "header" }),
  headerContainerClasses: builder.selectors.getClasses(store, {
    path: "header_container",
    toString: true
  }),
  menuHeaderDisplay: objectPath.get(
    store.builder.layoutConfig,
    "header.menu.self.display"
  ),
  headerFixedEnabled: objectPath.get(
    store.builder.layoutConfig,
    "header.self.fixed.desktop.enabled"
  ),
  headerFixedDesktop: objectPath.get(
    store.builder.layoutConfig,
    "header.self.fixed.desktop"
  )
});

export default connect(mapStateToProps)(Header);
