import React from 'react';
import Icon from '@ant-design/icons';

export const PNG_ICONS = {
    // BATH: 'https://v2-wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/assets/feature-icons/bath.png',
    // BED: 'https://v2-wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/assets/feature-icons/bed.png',
    // PARKING: 'https://v2-wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/assets/feature-icons/parking.png',
    BATH: 'https://v2-wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/assets/feature-icons/bath-new.png',
    BED: 'https://v2-wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/assets/feature-icons/bed-new.png',
    PARKING: 'https://v2-wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/assets/feature-icons/parking-new.png',
    LANDSIZE: 'https://v2-wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/assets/feature-icons/landsize-new.png',
    IMAGE: 'https://v2-wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/assets/placeholders/Image-Small.jpg',
    VIDEO: 'https://v2-wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/assets/placeholders/Video-Small.png',
    FACEBOOK: 'https://v2-wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/assets/placeholders/Facebook-Grey.png',
    INSTAGRAM: 'https://v2-wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/assets/placeholders/Instagram-Grey.png',
    LINKEDIN: 'https://v2-wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/assets/placeholders/LinkedIn-Grey.png',
    TWITTER: 'https://v2-wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/assets/placeholders/Twitter-Grey.png',
    PROFILE: 'https://v2-wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/assets/placeholders/Profile.png',
    YOUTUBE: 'https://v2-wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/assets/placeholders/YouTube-Grey.png'
};

export const ImagePlaceholderPath = 'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M5 8.5c0-.828.672-1.5 1.5-1.5s1.5.672 1.5 1.5c0 .829-.672 1.5-1.5 1.5s-1.5-.671-1.5-1.5zm9 .5l-2.519 4-2.481-1.96-4 5.96h14l-5-8zm8-4v14h-20v-14h20zm2-2h-24v18h24v-18z"/></svg>';
export const VideoPlaceholderPath = 'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-3 17v-10l9 5.146-9 4.854z"/></svg>';

const DragIcon = () => (
    <svg viewBox="64 64 896 896" focusable="false" data-icon="more" width="1em" height="1em" fill="currentColor" aria-hidden="true">
        <path d="M256 231a56 56 0 10112 0 56 56 0 10-112 0zm0 280a56 56 0 10112 0 56 56 0 10-112 0zm0 280a56 56 0 10112 0 56 56 0 10-112 0z"></path>
        <path d="M656 231a56 56 0 10112 0 56 56 0 10-112 0zm0 280a56 56 0 10112 0 56 56 0 10-112 0zm0 280a56 56 0 10112 0 56 56 0 10-112 0z"></path>
    </svg>
);

const CameraIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1rem" height="1rem" viewBox="0 0 24 24">
        <path fill="#ffffff" d="M5 5h-3v-1h3v1zm8 5c-1.654 0-3 1.346-3 3s1.346 3 3 3 3-1.346 3-3-1.346-3-3-3zm11-4v15h-24v-15h5.93c.669 0 1.293-.334 1.664-.891l1.406-2.109h8l1.406 2.109c.371.557.995.891 1.664.891h3.93zm-19 4c0-.552-.447-1-1-1-.553 0-1 .448-1 1s.447 1 1 1c.553 0 1-.448 1-1zm13 3c0-2.761-2.239-5-5-5s-5 2.239-5 5 2.239 5 5 5 5-2.239 5-5z" />
    </svg>
);

const PictureIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path fill="#ffffff" d="M5 8.5c0-.828.672-1.5 1.5-1.5s1.5.672 1.5 1.5c0 .829-.672 1.5-1.5 1.5s-1.5-.671-1.5-1.5zm9 .5l-2.519 4-2.481-1.96-4 5.96h14l-5-8zm8-4v14h-20v-14h20zm2-2h-24v18h24v-18z" />
    </svg>
);

const UploadIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path fill="#ffffff" d="M8 10h-5l9-10 9 10h-5v10h-8v-10zm11 9v3h-14v-3h-2v5h18v-5h-2z" />
    </svg>
);

const FacebookIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm3 8h-1.35c-.538 0-.65.221-.65.778v1.222h2l-.209 2h-1.791v7h-3v-7h-2v-2h2v-2.308c0-1.769.931-2.692 3.029-2.692h1.971v3z" />
    </svg>
);

const InstagramIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path d="M14.829 6.302c-.738-.034-.96-.04-2.829-.04s-2.09.007-2.828.04c-1.899.087-2.783.986-2.87 2.87-.033.738-.041.959-.041 2.828s.008 2.09.041 2.829c.087 1.879.967 2.783 2.87 2.87.737.033.959.041 2.828.041 1.87 0 2.091-.007 2.829-.041 1.899-.086 2.782-.988 2.87-2.87.033-.738.04-.96.04-2.829s-.007-2.09-.04-2.828c-.088-1.883-.973-2.783-2.87-2.87zm-2.829 9.293c-1.985 0-3.595-1.609-3.595-3.595 0-1.985 1.61-3.594 3.595-3.594s3.595 1.609 3.595 3.594c0 1.985-1.61 3.595-3.595 3.595zm3.737-6.491c-.464 0-.84-.376-.84-.84 0-.464.376-.84.84-.84.464 0 .84.376.84.84 0 .463-.376.84-.84.84zm-1.404 2.896c0 1.289-1.045 2.333-2.333 2.333s-2.333-1.044-2.333-2.333c0-1.289 1.045-2.333 2.333-2.333s2.333 1.044 2.333 2.333zm-2.333-12c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6.958 14.886c-.115 2.545-1.532 3.955-4.071 4.072-.747.034-.986.042-2.887.042s-2.139-.008-2.886-.042c-2.544-.117-3.955-1.529-4.072-4.072-.034-.746-.042-.985-.042-2.886 0-1.901.008-2.139.042-2.886.117-2.544 1.529-3.955 4.072-4.071.747-.035.985-.043 2.886-.043s2.14.008 2.887.043c2.545.117 3.957 1.532 4.071 4.071.034.747.042.985.042 2.886 0 1.901-.008 2.14-.042 2.886z" />
    </svg>
);

const LinkedInIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-2 16h-2v-6h2v6zm-1-6.891c-.607 0-1.1-.496-1.1-1.109 0-.612.492-1.109 1.1-1.109s1.1.497 1.1 1.109c0 .613-.493 1.109-1.1 1.109zm8 6.891h-1.998v-2.861c0-1.881-2.002-1.722-2.002 0v2.861h-2v-6h2v1.093c.872-1.616 4-1.736 4 1.548v3.359z" />
    </svg>
);

const TwitterIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6.066 9.645c.183 4.04-2.83 8.544-8.164 8.544-1.622 0-3.131-.476-4.402-1.291 1.524.18 3.045-.244 4.252-1.189-1.256-.023-2.317-.854-2.684-1.995.451.086.895.061 1.298-.049-1.381-.278-2.335-1.522-2.304-2.853.388.215.83.344 1.301.359-1.279-.855-1.641-2.544-.889-3.835 1.416 1.738 3.533 2.881 5.92 3.001-.419-1.796.944-3.527 2.799-3.527.825 0 1.572.349 2.096.907.654-.128 1.27-.368 1.824-.697-.215.671-.67 1.233-1.263 1.589.581-.07 1.135-.224 1.649-.453-.384.578-.87 1.084-1.433 1.489z" />
    </svg>
);

const BedIcon = () => (
    <svg x="0px" y="0px" viewBox="0 0 411.135 352.951" enableBackground="new 0 0 411.135 352.951">
        <g id="Layer_1">
            <g>
                <path fill="#822433" d="M397.934,122.432c-7.34,0-13.238,5.92-13.238,13.223v204.029c0,7.302,5.898,13.268,13.238,13.268
			c7.303,0,13.201-5.966,13.201-13.268V135.654C411.135,128.352,405.237,122.432,397.934,122.432"/>
                <path fill="#822433" d="M124.398,143.865c0-12.892-10.536-23.4-23.38-23.4h-32.53c-12.845,0-23.346,10.509-23.346,23.4v36.679
			h79.256V143.865z"/>
                <path fill="#822433" d="M337.985,120.465H167.211c-12.878,0-23.417,10.509-23.417,23.4v55.294H26.461V13.297
			C26.461,5.967,20.506,0,13.224,0C5.92,0,0,5.967,0,13.297v326.387c0,7.302,5.92,13.268,13.224,13.268
			c7.282,0,13.237-5.966,13.237-13.268v-33.45h311.524c12.855,0,23.359-10.466,23.359-23.383V143.865
			C361.344,130.974,350.84,120.465,337.985,120.465 M143.794,289.611H26.474v-73.847h117.321V289.611z"/>
            </g>
        </g>
        <g id="Clear_Space">
        </g>
    </svg>
);

const BathIcon = () => (
    <svg x="0px" y="0px" viewBox="0 0 422.23 352.936" enableBackground="new 0 0 422.23 352.936">
        <g id="Layer_1">
            <g>
                <path fill="#822433" d="M375.311,287.305c15.197-19.06,24.318-43.264,24.318-69.499v-33.829H21.988v33.829
			c0,27.532,10.015,52.713,26.584,72.254l-34.574,34.527c-6.401,6.482-6.401,17.024,0,23.504c6.541,6.46,17.077,6.46,23.524,0
			l36.202-36.209c17.332,10.995,37.741,17.494,59.732,17.494h154.683c23.262,0,44.914-7.141,62.768-19.452l35.896,37.834
			c6.33,6.667,16.863,6.893,23.568,0.592c6.629-6.282,6.945-16.899,0.547-23.561L375.311,287.305z M133.458,305.338
			c-48.27,0-87.445-39.308-87.445-87.532c0-5.473,4.421-9.93,9.961-9.93c5.475,0,10.003,4.457,10.003,9.93
			c0,37.265,30.275,67.519,67.481,67.519c5.497,0,9.958,4.514,9.958,9.999C143.416,300.854,138.955,305.338,133.458,305.338"/>
                <path fill="#822433" d="M410.885,148.469H11.309C5.061,148.469,0,153.538,0,159.785c0,6.252,5.061,11.304,11.309,11.304h399.576
			c6.25,0,11.346-5.052,11.346-11.304C422.23,153.538,417.135,148.469,410.885,148.469"/>
                <path fill="#822433" d="M53.796,132.27c5.543,0,10.035-4.484,10.035-10.005V54.888h74.58c2.762,0,5.004-2.246,5.004-4.968
			C143.416,22.389,120.984,0,93.529,0C67.285,0,45.978,20.408,44.022,46.162c-0.067,0.331-0.222,4.363-0.188,4.651v71.451
			C43.835,127.785,48.319,132.27,53.796,132.27"/>
            </g>
        </g>
        <g id="Clear_Space" display="none">
        </g>
    </svg>
);

const ParkingIcon = () => (
    <svg x="0px" y="0px" viewBox="0 0 370.912 352.924" enableBackground="new 0 0 370.912 352.924">
        <g id="Layer_1">
            <g>
                <path fill="#822433" d="M113.434,170.144c0-25.287,20.498-45.827,45.744-45.827h52.456c25.312,0,45.82,20.54,45.82,45.827
			c0,6.185,5.024,11.143,11.174,11.143c6.223,0,11.247-4.958,11.247-11.143c0-37.62-30.567-68.22-68.241-68.22h-52.456
			c-37.578,0-68.208,30.6-68.208,68.22c0,6.185,5.071,11.143,11.208,11.143C108.365,181.287,113.434,176.329,113.434,170.144"/>
                <path fill="#822433" d="M86.363,336.49c0,9.075,7.393,16.434,16.459,16.434s16.469-7.358,16.469-16.434v-23.918H86.363V336.49z" />
                <path fill="#822433" d="M251.551,336.49c0,9.075,7.339,16.434,16.471,16.434c9.167,0,16.449-7.358,16.449-16.434v-23.918h-32.92
			V336.49z"/>
                <path fill="#822433" d="M289.783,195.29H81.172c-16.155,0-29.164,13.039-29.164,29.098v47.635
			c0,16.024,13.009,29.097,29.164,29.097h208.611c16.056,0,29.149-13.072,29.149-29.097v-47.635
			C318.933,208.329,305.839,195.29,289.783,195.29 M102.378,268.277c-11.083,0-20.054-8.97-20.054-20.098
			c0-11.009,8.971-20.066,20.054-20.066c11.056,0,20.06,9.058,20.06,20.066C122.438,259.308,113.434,268.277,102.378,268.277
			 M268.533,268.277c-11.146,0-20.116-8.97-20.116-20.098c0-11.009,8.971-20.066,20.116-20.066c11.025,0,20.027,9.058,20.027,20.066
			C288.561,259.308,279.559,268.277,268.533,268.277"/>
                <path fill="#822433" d="M365.297,102.079L191.027,1.528c-3.45-2.037-7.718-2.037-11.177,0L5.6,102.079
			c-5.363,3.09-7.19,10.006-4.09,15.326c3.08,5.345,9.973,7.17,15.284,4.103l168.64-97.337l168.649,97.337
			c5.38,3.067,12.246,1.242,15.317-4.103C372.502,111.984,370.684,105.168,365.297,102.079"/>
            </g>
        </g>
        <g id="Clear_Space" display="none">
        </g>
    </svg>
);

const LandSizeIcon = () => (
    <svg id="Layer_1" x="0px" y="0px" viewBox="0 0 422.23 349.604" enableBackground="new 0 0 422.23 349.604">
        <g>
            <path fill="#822433" d="M417.429,325.454l-47.838-89.995c-6.725-12.652-23.949-23.002-38.276-23.002h-66.434
		c-26.315,41.612-53.837,73.395-53.837,73.395s-27.521-31.782-53.836-73.395H92.634c-14.327,0-31.66,10.291-38.519,22.87
		L4.904,325.584c-6.858,12.579-0.748,22.87,13.58,22.87h385.125C417.934,348.454,424.154,338.105,417.429,325.454z"/>
            <path fill="#822433" d="M211.044,1.151c-49.743,0-90.092,42.071-90.092,93.94c0,70.456,90.092,174.462,90.092,174.462
		s90.093-104.006,90.093-174.462C301.137,43.222,260.789,1.151,211.044,1.151 M211.044,128.64c-17.76,0-32.175-15.031-32.175-33.549
		c0-18.519,14.416-33.55,32.175-33.55c17.765,0,32.177,15.031,32.177,33.55C243.221,113.609,228.809,128.64,211.044,128.64"/>
        </g>
    </svg>
);

const NetworkIcon = () => (

    <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" version="1.1" viewBox="0 2 40 30" width="18" height="18">
        <g>
            <path fill="#484747" d="M32,26h-6V5c0-0.552,0.448-1,1-1h4c0.552,0,1,0.448,1,1V26z" />
            <path fill="#484747" d="M23,26h-6V9c0-0.552,0.448-1,1-1h4c0.552,0,1,0.448,1,1V26z" />
            <g>
                <path fill="#484747" d="M14,26h-6V13c0-0.552,0.448-1,1-1h4c0.552,0,1,0.448,1,1V26z" />
            </g>
            <g>
                <path fill="#484747" d="M5,26h-6V17c0-0.552,0.448-1,1-1h4c0.552,0,1,0.448,1,1V26z" />
            </g>
        </g>
    </svg>
);

const WifiIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg"  height="15" width="15" viewBox="0 70 512 512"><path d="M256 96c-81.5 0-163 33.6-221.5 88.3-3.3 3-3.4 8.1-.3 11.4l26.7 27.9c3.1 3.3 8.3 3.4 11.6.3 23.3-21.6 49.9-38.8 79.3-51 33-13.8 68.1-20.7 104.3-20.7s71.3 7 104.3 20.7c29.4 12.3 56 29.4 79.3 51 3.3 3.1 8.5 3 11.6-.3l26.7-27.9c3.1-3.2 3-8.3-.3-11.4C419 129.6 337.5 96 256 96z"/><path d="M113.2 277.5l28.6 28.3c3.1 3 8 3.2 11.2.3 28.3-25.1 64.6-38.9 102.9-38.9s74.6 13.7 102.9 38.9c3.2 2.9 8.1 2.7 11.2-.3l28.6-28.3c3.3-3.3 3.2-8.6-.3-11.7-37.5-33.9-87.6-54.6-142.5-54.6s-105 20.7-142.5 54.6c-3.3 3.1-3.4 8.4-.1 11.7zM256 324.2c-23.4 0-44.6 9.8-59.4 25.5-3 3.2-2.9 8.1.2 11.2l53.4 52.7c3.2 3.2 8.4 3.2 11.6 0l53.4-52.7c3.1-3.1 3.2-8 .2-11.2-14.8-15.6-36-25.5-59.4-25.5z"/></svg>
   
);

const BatteryIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 512 512"><path d="M396 144H64c-26.4 0-48 21.6-48 48v128c0 26.4 21.6 48 48 48h332c26.4 0 48-21.6 48-48V192c0-26.4-21.6-48-48-48zm20 176c0 11-9 20-20 20H64c-11 0-20-9-20-20V192c0-11 9-20 20-20h332c11 0 20 9 20 20v128zM464 204.6v102.8c16 0 32-27.7 32-51.4s-16-51.4-32-51.4z" /><path d="M384 192H76c-6.6 0-12 5.4-12 12v104c0 6.6 5.4 12 12 12h308c6.6 0 12-5.4 12-12V204c0-6.6-5.4-12-12-12z" /></svg>
);

const Attachment = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="64 64 896 896">
        <path fill="#00000073" d="M779.3 196.6c-94.2-94.2-247.6-94.2-341.7 0l-261 260.8c-1.7 1.7-2.6 4-2.6 6.4s.9 4.7 2.6 6.4l36.9 36.9a9 9 0 0012.7 0l261-260.8c32.4-32.4 75.5-50.2 121.3-50.2s88.9 17.8 121.2 50.2c32.4 32.4 50.2 75.5 50.2 121.2 0 45.8-17.8 88.8-50.2 121.2l-266 265.9-43.1 43.1c-40.3 40.3-105.8 40.3-146.1 0-19.5-19.5-30.2-45.4-30.2-73s10.7-53.5 30.2-73l263.9-263.8c6.7-6.6 15.5-10.3 24.9-10.3h.1c9.4 0 18.1 3.7 24.7 10.3 6.7 6.7 10.3 15.5 10.3 24.9 0 9.3-3.7 18.1-10.3 24.7L372.4 653c-1.7 1.7-2.6 4-2.6 6.4s.9 4.7 2.6 6.4l36.9 36.9a9 9 0 0012.7 0l215.6-215.6c19.9-19.9 30.8-46.3 30.8-74.4s-11-54.6-30.8-74.4c-41.1-41.1-107.9-41-149 0L463 364 224.8 602.1A172.22 172.22 0 00174 724.8c0 46.3 18.1 89.8 50.8 122.5 33.9 33.8 78.3 50.7 122.7 50.7 44.4 0 88.8-16.9 122.6-50.7l309.2-309C824.8 492.7 850 432 850 367.5c.1-64.6-25.1-125.3-70.7-170.9z"/>
    </svg>
);

const ImageSettings = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><path d="M15.562 20.22l-3.562.718.719-3.562 2.843 2.844zm-2.136-3.552l2.844 2.845 7.73-7.73-2.845-2.845-7.729 7.73zm-2.91.332l4.51-4.76-2.026-3.24-2.52 4-2.48-1.96-4 5.96h6.516zm-3.516-8.5c0-.828-.672-1.5-1.5-1.5s-1.5.672-1.5 1.5c0 .829.672 1.5 1.5 1.5s1.5-.671 1.5-1.5zm3.352 10.5h-8.352v-14h18v2.312h2v-4.312h-22v18h9.969l.383-2z"/></svg>
);

const renderIcon = (icon) => {
    switch (icon) {
        case 'drag-icon':
            return DragIcon;
        case 'camera-icon':
            return CameraIcon;
        case 'picture-icon':
            return PictureIcon;
        case 'upload-icon':
            return UploadIcon;
        case 'facebook-icon':
            return FacebookIcon;
        case 'instagram-icon':
            return InstagramIcon;
        case 'linkedin-icon':
            return LinkedInIcon;
        case 'twitter-icon':
            return TwitterIcon;
        case 'bath-icon':
            return BathIcon;
        case 'bed-icon':
            return BedIcon;
        case 'parking-icon':
            return ParkingIcon;
        case 'landsize-icon':
            return LandSizeIcon;
        case 'network-icon':
            return NetworkIcon;
        case 'wifi-icon':
            return WifiIcon;
        case 'battery-icon':
            return BatteryIcon;
        case 'attachment':
            return Attachment;
        case 'image-settings':
            return ImageSettings;
        default:
            return DragIcon;
    }
};

const CustomIcon = ({ icon, ...props }) => (
    <Icon component={renderIcon(icon)} {...props} />
);

export default CustomIcon;
