import { arrayMove } from "react-sortable-hoc";
import React, { Children, PureComponent } from "react";

import SortableReduxForm from "./SortableReduxForm";

class SortableList extends PureComponent {
  state = {
    items: Children.toArray(this.props.children)
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.props.fields.move(oldIndex, newIndex);
    this.setState({
      items: arrayMove(this.state.items, oldIndex, newIndex)
    });
    
    if (this.props.isDropped instanceof Function) {
      this.props.isDropped(true)
    }
  };

  render() {
    return (
      <SortableReduxForm
        initialValues={this.props.initialValues}
        onSubmit={this.handleSubmit}
        items={Children.toArray(this.props.children)}
        onSortEnd={this.onSortEnd}
        lockAxis="y"
        lockToContainerEdges
        lockOffset={["0%", "100%"]}
        useDragHandle
      />
    );
  }
}

export default SortableList;
