import React, { Fragment } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Row } from "react-bootstrap";
import { Label } from "reactstrap";
// import DatePicker from "react-datepicker";

import moment from "moment";
import DateAndTime from "../form-basic/dateAndTime/DateAndTime";
const DateInput = (props) => {
  const {
    input,
    selected,
    width,
    label,
    dateChange,
    format,
    meta: { touched, invalid, error },
    edit,
    showErrorOnMount = false,
    onBlurChange = () => {},
    helperText = "",
    makeAnOfferPage,
    ...rest
  } = props;

  const handleOnchange = (e) => {
    input.onChange(e);
    if (typeof dateChange === "function") {
      dateChange(e);
    }
  };
  const onBlurhandleChange = (e) => {
    input.onBlur(e);
    if (typeof onBlurChange === "function") {
      onBlurChange(e);
    }
  };

  return (
    <Fragment>
      {edit ? (
        <Fragment>
          <DateAndTime
            onChange={(e) => handleOnchange(e.target.value)}
            onBlur={(e) => onBlurhandleChange(e.target.value)}
            value={input?.value}
            {...rest}
            error={error ?? ((touched || showErrorOnMount) && invalid)}
            helperText={helperText ?? ((touched || showErrorOnMount) && error)}
            label={label}
            // shouldDisableDate={(date) => {
            //   console.log(date.toDate(), "dat_e");
            // }}
          />

          {touched && error && <Label color="red">{error}</Label>}
        </Fragment>
      ) : (
        <Row>{moment(input?.value).format("MM/DD/YYYY")}</Row>
      )}
    </Fragment>
  );
};

DateInput.defaultProps = {
  edit: true,
};

export default DateInput;
