import axios from "axios";
const WiseBerryAPIEnv = process.env.REACT_APP_WISEBERRY_API;
export const BUYERS_URL = WiseBerryAPIEnv + "buyer";

let ajaxRequest = null;

// CREATE =>  POST: add a new buyer to the server
export function createBuyer(buyer) {
  // return axios.post(BUYERS_URL, { buyer });
}

// READ
export function getAllBuyers(prams) {
  // cancel  previous ajax if exists
  if (ajaxRequest) {
    ajaxRequest.cancel();
  }

  // creates a new token for upcomming ajax (overwrite the previous one)
  ajaxRequest = axios.CancelToken.source();

  return axios.get(
    BUYERS_URL + "?limit=100" + (prams != null ? "&" + prams : ""),
    { cancelToken: ajaxRequest.token }
  );

  // return axios.get(BUYERS_URL + '?limit=30' + (prams != null ? ('&' + prams) : ''));
}

export function getBuyerMatchedProperty(prams) {
  return axios.get(
    BUYERS_URL + `/matched-listings` + (prams ? "?" + prams : "")
  );
}

export function getBuyerSettings() {
  return axios.get(`${WiseBerryAPIEnv}buyer-setting`);
}

export function postBuyerSettings(prams) {
  return axios.post(WiseBerryAPIEnv + `buyer-setting`, prams, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
}
export function updateBuyerSettings(prams, id) {
  return axios.post(WiseBerryAPIEnv + `buyer-setting/` + id, prams, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
}
export function getBuyerById(id) {
  return axios.get(`${BUYERS_URL}/${id}`);
}
export function sendBuyerListingPack(params) {
  return axios.post(`${BUYERS_URL}/send-listing-pack`, params);
}

export function sendSingleListingPack(params) {
  return axios.post(`${BUYERS_URL}/send-single-listing-pack`, params);
}

export function sendContractToBuyers(params) {
  return axios.post(`${BUYERS_URL}/send-listing-contract`, params);
}

// Mail

// Door knock

export function postDoorKnock(prams) {
  return axios.post(WiseBerryAPIEnv + `history`, prams, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
}

// Door Knock

// Referral
export function getAllReferral(prams) {
  return axios.get(WiseBerryAPIEnv + `finance?${prams ? prams : ""}`);
}

export function createNewReferral(prams) {
  return axios.post(WiseBerryAPIEnv + `finance`, prams, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
}

export function updateReferral(id, prams) {
  return axios.post(WiseBerryAPIEnv + `finance/` + id, prams, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
}

export function deleteReferral(id) {
  return axios.delete(WiseBerryAPIEnv + `finance/` + id);
}
// Referral
// Notes

export function getNotesByBuyerId(prams) {
  return axios.get(BUYERS_URL + "/notes?buyer=" + prams);
}
export function getNotesByBuyerIdAndCreatedBy(prams) {
  return axios.get(BUYERS_URL + "/notes?" + prams);
}

// Notes
// READ
export function getAllMail() {
  // return axios.get(BUYERS_URL);
  const mail = axios.get("/mail");
  return mail;
}

export function getMailById(mailId) {
  const mail = axios.get("/mail/34", { params: { searchText: "John" } });
  return mail;
}

export function updateMailById(data) {
  const mail = axios.post("/mail/34", { data });
  return mail;
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findBuyers(queryParams) {
  return axios.post(`${BUYERS_URL}/find`, { queryParams });
}

// UPDATE Status
export function updateStatusForBuyers(ids, status) {
  return axios.post(`${BUYERS_URL}/updateStatusForBuyers`, {
    ids,
    status
  });
}

// DELETE => delete the buyer from the server
export function deleteBuyer(buyerId) {
  return axios.delete(`${BUYERS_URL}/${buyerId}`);
}

// DELETE Buyers by ids
export function deleteBuyers(ids) {
  return axios.post(`${BUYERS_URL}/deleteBuyers`, { ids });
}

// Buyer Details
// READ
export function getModalBuyerDetails(buyerId) {
  return axios.get("/buyer/modal/details", { params: { buyerId: buyerId } });
}
// Buyer Details
// READ
export function getBuyerMatchedData(buyerId) {
  return axios.get("/buyer/modal/matchedInfo");
}

export function getBuyerTimeline(buyerId) {
  return axios.get("/contacts/timeline");
}

export function createNewBuyer(prams) {
  return axios.post(BUYERS_URL, prams, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
}

export function createNewOffer(prams) {
  return axios.post(`${WiseBerryAPIEnv}offer`, prams, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
}
export function getAllOffer(prams) {
  return axios.get(
    WiseBerryAPIEnv +
      "offer-detail?limit=30" +
      (prams != null ? "&" + prams : "")
  );
}

export function createNewEnquiry(prams) {
  return axios.post(`${WiseBerryAPIEnv}enquiry`, prams, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
}

export function updateBuyer(id, prams, contentType = "multipart/form-data") {
  return axios.post(BUYERS_URL + "/" + id, prams, {
    headers: {
      "Content-Type": contentType
    }
  });
}

export function bulkDeleteBuyer(prams) {
  return axios.post(BUYERS_URL + "/bulk-delete", prams, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

export function sendBuyerAppPromoMarketing(params) {
  return axios.post(BUYERS_URL + "/send-buyer-app-promo", params, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}
