import React from "react";
import MobileSearch from "../prospectMobile/MobileSearch";
import MobileContext from "./MobileContext"

const ProspectProfileModal = (props) => {
  const {
    closeModal,
  } = props;
  return (
    <div>
      <MobileContext>
       <MobileSearch closeModal={closeModal} {...props}/>
      </MobileContext>
    </div>
  );
};

export default ProspectProfileModal;
