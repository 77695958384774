import React, {Fragment, useState, useContext, useRef, useEffect} from "react";
import {connect} from "react-redux";
import {compose} from "redux";
import {Row, Col, Spinner} from "react-bootstrap";
import {reduxForm, Field, getFormValues} from "redux-form";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
// import { Divider } from "@material-ui/core";
import _isEmpty from "lodash/isEmpty";
import html2canvas from "html2canvas";
import Swal from "sweetalert2";
import domtoimage from "dom-to-image";

// import domtoimage from "dom-to-image-more";
import ThemeHeader from "../../../../partials/content/ThemeHeader";
import ThemeButton from "../../../../partials/button/ThemeButton";
import TextInput from "../../../../partials/form/TextInput";
import DigitalTemplateLayouts from "./DigitalTemplateLayouts";
import {DigitalTemplateContext} from "../../pages/digitalTemplate/DigitalTemplateContextProvider";
import DigitalTemplateContent from "./DigitalTemplateContent";
import DigitalTemplateContentEdit from "./DigitalTemplateContentEdit";
import DigitalTemplateContentCustomise from "./DigitalTemplateContentCustomise";
import {createSocialMediaTemplate} from "../../crud/socialMedailCrud";
import {convertFormData, errorAlert} from "../../../../_helpers/_utils/utils";
import {toPng} from "html-to-image";
import {
  SectionDetails,
  OPEN_FOR_INSPECTION,
  AUCTIONS,
  TESTIMONIALS,
  AGENT_PROFILES,
  RENTAL_OPEN_FOR_INSPECTION,
} from "./AccordianConstants";

const validate = (values = {}) => {
  const errors = {};
  if (!values?.name) {
    errors.name = "Required";
  }
  return errors;
};

const styleFamily = `
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans'), url('https://fonts.cdnfonts.com/s/14884/OpenSans-Regular.woff') format('woff');
}
`;

const NewDigitaltemplateForm = (props) => {
  const ref = useRef(null);
  const {
    setEditBackground,
    showEditSection,
    activeTab,
    setActiveTab,
    selectedSocialTemplate,
    deleteSocialTemplates,
    updateTableData,
  } = useContext(DigitalTemplateContext);
  const [selectedTemplateLayout, setSelectedTemplateLayout] = useState({});
  const [_selectedTemplate, _setSelectedTemplate] = useState("");
  const [seletctedDate, setSeletctedDate] = useState(null);
  const isEdit = !_isEmpty(selectedSocialTemplate);

  const HandleActiveTab = (event, newAlignment) => {
    if (newAlignment !== null) {
      setActiveTab(newAlignment);
    }
  };

  const {
    handleSubmit,
    pristine,
    submitting,
    invalid,
    closeModal,
    socialMedaiTemplateCategory,
    change,
    formValues,
    setLibraryDataSource,
  } = props;

  useEffect(() => {
    if (isEdit) {
      let templateUrl = selectedSocialTemplate?.assets?.find(
        (item) => item.type?.id === 5 && item.extension === "html"
      )?.url;
      if (templateUrl) {
        fetch(templateUrl)
          .then((r) => r.text())
          .then((t) => {
            _setSelectedTemplate(t);
          });
      }

      if (!_isEmpty(selectedSocialTemplate?.category)) {
        setSelectedTemplateLayout({
          ...selectedSocialTemplate.category,
          ...SectionDetails[selectedSocialTemplate.category?.id],
        });
      }
      setActiveTab("customise");
    }
    change("name", selectedSocialTemplate?.name);
  }, [selectedSocialTemplate]);

  const exportToPng = (
    element,
    desiredWidth,
    desiredHeight,
    fileName = "exported",
    scaleFactor = 2
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        const canvas = await html2canvas(element, {
          scale: Math.min(
            (scaleFactor * desiredWidth) / element.clientWidth,
            (scaleFactor * desiredHeight) / element.clientHeight
          ),
        });

        const outputCanvas = document.createElement("canvas");
        outputCanvas.width = desiredWidth;
        outputCanvas.height = desiredHeight;
        const ctx = outputCanvas.getContext("2d");
        ctx.drawImage(canvas, 0, 0, desiredWidth, desiredHeight);

        outputCanvas.toBlob((blob) => {
          const file = new File([blob], `${fileName}.png`, {
            type: "image/png",
            lastModified: new Date(),
          });
          resolve(file);
        }, "image/png");
      } catch (error) {
        console.error("Error exporting HTML to PNG:", error);
        reject(error);
      }
    });
  };

  const onFormSubmit = async (values) => {
    let bold;

    let category =
      selectedTemplateLayout.id ?? selectedSocialTemplate?.category?.id;
    let imageBlob = await new Promise(async (resolve, reject) => {
      if (
        [
          OPEN_FOR_INSPECTION,
          RENTAL_OPEN_FOR_INSPECTION,
          AUCTIONS,
          TESTIMONIALS,
          AGENT_PROFILES,
        ].includes(category)
      ) {
        let style = document.createElement("style");
        style.innerHTML = styleFamily;
        document.getElementById("poster-template").appendChild(style);
      }
      const scaleFactor = category == 2 || category == 3 ? 1 : 1;
      const imageQualityScale = 1.35;
      // Scale the contents within the div
      // ref.current.style.transform = `scale(${scaleFactor})`;
      // ref.current.style.transformOrigin = "top left";

      const getBackgroundImageElements = (container) => {
        const allElements = container.getElementsByTagName("*");
        const bgImageElements = Array.from(allElements).filter((element) => {
          const bgImage = window
            .getComputedStyle(element)
            .getPropertyValue("background-image");
          return bgImage && bgImage !== "none";
        });

        return bgImageElements;
      };

      const updateImageSrc = () => {
        const container = ref.current;
        const images = container.getElementsByTagName("img");
        const bgImages = getBackgroundImageElements(container);

        const imageLoadPromises = Array.from(images).map((image) => {
          return new Promise((resolve) => {
            image.onload = () => resolve(true);
            image.src = `https://wiseberry.com.au/load-listing-rental-image/${btoa(
              image.src
            )}`;
          });
        });

        const bgImageLoadPromises = bgImages.map((element) => {
          return new Promise((resolve) => {
            const oldBgImageUrl = window
              .getComputedStyle(element)
              .getPropertyValue("background-image");
            const oldUrl = oldBgImageUrl.slice(5, -2); // Extract the URL from the "url()" format
            const newUrl = `https://wiseberry.com.au/load-listing-rental-image/${btoa(
              oldUrl
            )}`;

            const image = new Image();
            image.onload = () => {
              element.style.backgroundImage = `url(${newUrl})`;
              resolve(true);
            };
            image.src = newUrl;
          });
        });

        return Promise.all([...imageLoadPromises, ...bgImageLoadPromises]);
      };

      await updateImageSrc();

      html2canvas(
        ref.current,
        // { useCORS: true }
        {
          useCORS: true,
          allowTaint: true,
          logging: true,
          scale: 2,
          width: ref.current.offsetWidth * scaleFactor * imageQualityScale,
          height: ref.current.offsetHeight * scaleFactor * imageQualityScale,
          onclone: (clonedElement) => {
            // Reset the transform property of the original element after cloning
            const clonedRef = clonedElement.getElementById("ref-element");
            clonedRef.style.transform = `scale(${scaleFactor *
              imageQualityScale})`;
            clonedRef.style.transformOrigin = "top left";
          },
        }
      )
        .then((canvas) => {
          const resizedCanvas = document.createElement("canvas");
          resizedCanvas.width = 1080;
          resizedCanvas.height = 1080;

          // Draw the generated canvas onto the new canvas
          const ctx = resizedCanvas.getContext("2d");
          ctx.drawImage(
            canvas,
            0,
            0,
            canvas.width,
            canvas.height,
            0,
            0,
            1080,
            1080
          );
          resizedCanvas.toBlob((blob) => {
            // Do something with the blob
            return resolve(
              new File([blob], `${values.name}.png`, {
                type: "image/png",
                lastModified: new Date(),
              })
            );
          });
        })
        .catch((error) => reject(console.error(error)));
    });

    let htmlContent = `<html>${ref.current.innerHTML}</html>`;
    const html_file = new File([htmlContent], `${values.name}.html`, {
      type: "text/html;charset=utf-8",
      lastModified: new Date(),
    });
    let data = {
      ...values,
      html_file,
      image_file: imageBlob,
      category: category,
    };
    if (selectedSocialTemplate.id) {
      data.id = selectedSocialTemplate.id;
    }
    const formData = convertFormData(data);
    if (selectedSocialTemplate.id) {
      formData.append("_method", "PATCH");
    }

    return await createSocialMediaTemplate(selectedSocialTemplate.id, formData)
      .then((res) => {
        updateTableData();
        closeModal();
      })
      .catch((err) => {
        errorAlert(err?.data);
      });
  };

  const handleCancel = () => {
    Swal.fire({
      title: "Warning!",
      text: "Are you sure want to exit?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#A7A9AC",
      cancelButtonColor: "#2BB673",
      confirmButtonText: "Cancel",
      cancelButtonText: "Yes",
    }).then(async (result) => {
      if (result.isDismissed) {
        if (selectedSocialTemplate.id && selectedSocialTemplate.newlyCreated) {
          await deleteSocialTemplates(
            {selected_ids: [selectedSocialTemplate.id]},
            true
          );
          closeModal();
        } else {
          closeModal();
        }
      }
    });
  };
  return (
    <Fragment>
      <div className="p-3">
        <form className="settings-form" onSubmit={handleSubmit(onFormSubmit)}>
          <Fragment>
            <ThemeHeader
              left={
                <Fragment>
                  <h5 className="title pt-4 pr-3">
                    {isEdit ? "Edit" : "New"} Template
                  </h5>
                  <Field
                    style={{width: "20vh"}}
                    label={"Name"}
                    name="name"
                    showErrorOnMount={true}
                    component={TextInput}
                  />
                </Fragment>
              }
              right={
                <Fragment>
                  <div className="pl-2">
                    <ThemeButton
                      className="lightGrey-button"
                      onClick={handleCancel}
                    >
                      Cancel
                    </ThemeButton>
                  </div>
                  <div className="pl-2">
                    <ThemeButton
                      disabled={invalid || pristine || submitting}
                      type="submit"
                      className="green-button"
                    >
                      {submitting ? (
                        <Spinner
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        ""
                      )}
                      Save
                    </ThemeButton>
                  </div>
                </Fragment>
              }
            />
            <Row>
              <Col lg={4} sm={4}>
                <Row>
                  <ToggleButtonGroup
                    value={activeTab}
                    exclusive
                    // className="float-right"
                    onChange={HandleActiveTab}
                    aria-label="text alignment"
                    style={{width: "100%"}}
                  >
                    <ToggleButton
                      className={`themeButton white-button ${
                        isEdit ? "pointer-events-none" : ""
                      }`}
                      value="template"
                      fullWidth
                      style={{
                        border: "solid",
                        borderWidth: "thin",
                        borderColor: "lightGrey",
                        width: "50%",
                      }}
                      disabled={isEdit}
                    >
                      TEMPLATE
                    </ToggleButton>

                    <ToggleButton
                      className={`themeButton white-button ${
                        _isEmpty(_selectedTemplate) ? "pointer-events-none" : ""
                      }`}
                      value="customise"
                      fullWidth
                      style={{
                        border: "solid",
                        borderWidth: "thin",
                        borderColor: "lightGrey",
                        width: "50%",
                      }}
                      sx={{width: 1}}
                      disabled={_isEmpty(_selectedTemplate)}
                    >
                      CUSTOMISE
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Row>
                {activeTab == "template" ? (
                  <DigitalTemplateLayouts
                    setSelectedTemplateLayout={setSelectedTemplateLayout}
                    templates={socialMedaiTemplateCategory}
                    _setSelectedTemplate={_setSelectedTemplate}
                  />
                ) : showEditSection ? (
                  <DigitalTemplateContentEdit
                    formValues={formValues}
                    setSeletctedDate={setSeletctedDate}
                  />
                ) : (
                  <DigitalTemplateContentCustomise
                    selectedTemplateLayout={selectedTemplateLayout}
                    seletctedDate={seletctedDate}
                    setSeletctedDate={setSeletctedDate}
                  />
                )}
              </Col>
              {/* <Divider orientation="vertical" flexItem /> */}
              <Col lg={8} sm={8} onClick={() => setEditBackground(true)}>
                <DigitalTemplateContent
                  ref={ref}
                  templateLayoutContent={_selectedTemplate}
                  selectedTemplateLayout={selectedTemplateLayout}
                  isEdit={isEdit}
                  setSeletctedDate={setSeletctedDate}
                />
              </Col>
            </Row>
          </Fragment>
        </form>
      </div>
    </Fragment>
  );
};
const mapState = (state, props) => {
  const values = getFormValues("new-digital-template")(state);
  return {
    testimonial: values?.testimonial,
    author: values?.author,
    template_image: values?.template_image?.[0]?.preview,
    templateText: values?.templateText,
    formValues: values,
    // ...props.preFillContactProfile?.contact,
  };
};

export default compose(
  connect(mapState),
  reduxForm({
    form: "new-digital-template",
    enableReinitialize: true,
    validate,
  })
)(NewDigitaltemplateForm);
