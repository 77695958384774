import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { toast } from "react-toastify";
import { getMenu } from "../../GeneralApi/GeneralApi";
import { updateBuyer } from "../../modules/buyers/crud/buyersCrud";
import { updateTenant } from "../../modules/tenants/crud/tenantsCrud";
import {
  addNumberSeparator,
  convertFormData,
} from "../../_helpers/_utils/utils";
import { CharacterCircle } from "../component/characterCircle/CharacterCircle";
import MaterialMultiSelect from "../form-basic/select/MaterialMultiSelect";
import Item from "./Item";

const Preferences = ({
  profile,
  forSideBar,
  updateContext,
  fromTenant,
  fromBuyer,
  fromProspect,
}) => {
  const [property_type, setProperty_type] = useState([]);
  const [tags, setTags] = useState([]);
  useEffect(() => {
    profile?.property_types?.length > 0 &&
      setTags(profile?.property_types?.map((type) => type?.id));
  }, [profile]);
  const handleSubmit = () => {
    // console.log(tag)
    if (
      // tags?.length > 0 ||
      tags?.sort()?.join("") !=
      profile?.property_types
        ?.map((type) => type?.id)
        ?.sort()
        ?.join("")
    ) {
      const formData = convertFormData({ property_types: tags, formDataOptions: { allowEmptyArrays: true } });

      formData.append("_method", "PATCH");
      fromBuyer &&
        updateBuyer(profile?.id, formData)
          .then(({ data }) => {
            console.log(data);
            toast.success("New Property Type Saved Successfully");
            updateContext && updateContext();
          })
          .catch((e) => {
            toast.error("Error while prospect saving");
          });
      fromTenant &&
        updateTenant(profile?.id, formData)
          .then(({ data }) => {
            console.log(data);
            toast.success("New Property Type Saved Successfully");
            updateContext && updateContext();
          })
          .catch((e) => {
            toast.error("Error while prospect saving");
          });
    }
  };
  useEffect(() => {
    let query = "property_type";
    getMenu(query)
      .then(({ data: { data } }) => {
        console.log("property_type", data.property_type);
        setProperty_type(data.property_type);
      })
      .catch(() => {});
  }, []);
  return (
    <Fragment>
      {!forSideBar && <h6 className="font-weight-bold  ">Preferences</h6>}
      <div className="d-flex flex-wrap pb-2  ">
        {profile?.features?.map((item, i) => {
          switch (item?.feature?.name) {
            case "Bathroom":
              return (
                <div className="btn">
                  {item?.qty} <i className="fas fa-bath    "></i>
                </div>
              );
            case "Bedroom":
              return (
                <div className="btn">
                  {item?.qty} <i className="fas fa-bed"></i>
                </div>
              );
            case "Land Size":
              return (
                <div className="btn">
                  {item?.feature_menu_input} {item?.feature_menu_option.name}{" "}
                  <i className="fas fa-map-marked-alt"></i>
                </div>
              );
            case "Parking":
              return (
                <div className="btn">
                  {item?.qty} <i className="fas fa-car-side    "></i>
                </div>
              );
            default:
              return "";
          }
        })}
      </div>
      {profile?.price_to ? (
        <Item
          left="Price"
          right={
            profile?.price_to && (
              <Fragment>
                <span>${addNumberSeparator(profile?.price_from)}</span>
                <span>-</span>
                <span>${addNumberSeparator(profile?.price_to)}</span>
              </Fragment>
            )
          }
        />
      ) : (
        ""
      )}

      {profile?.rent_to ? (
        <Item
          left="Price"
          right={
            <Fragment>
              <span>${profile?.rent_from}</span>
              <span> - </span>
              <span>${profile?.rent_to}</span>
              <span> {profile?.payment_frequency?.name}</span>
            </Fragment>
          }
        />
      ) : (
        ""
      )}

      {profile?.suburbs ? (
        <Item
          left="Locations"
          noMargin
          right={
            <Fragment>
              <div>
                <p>
                  {" "}
                  {profile?.suburbs
                    .filter((item, index) => index < 3)
                    .map((item) => item.name)
                    .join(", ")}{" "}
                  <span style={{ marginLeft: "1rem", display: "inline-flex" }}>
                    {profile?.suburbs?.length > 3 ? (
                      <CharacterCircle
                        small
                        background="buyer"
                        character={"+" + profile?.suburbs?.length}
                      />
                    ) : (
                      ""
                    )}
                  </span>{" "}
                </p>
              </div>
            </Fragment>
          }
        />
      ) : (
        ""
      )}

      {profile?.property_types ? (
        fromBuyer || fromTenant ? (
          <div>
            {property_type && (
              <MaterialMultiSelect
                label="Types"
                // placeholder={}
                options={property_type}
                onClose={() => {
                  handleSubmit();
                }}
                value={tags}
                // value={tags}
                // value={input?.value}
                onChange={(e) => {
                  setTags(e);
                }}
              />
            )}{" "}
          </div>
        ) : (
          <Item
            left="Types"
            noMargin
            right={
              <Fragment>
                <Fragment>
                  <div>
                    <p>
                      {" "}
                      {profile?.property_types
                        .filter((item, index) => index < 4)
                        .map((item) => item.name)
                        .join(", ")}{" "}
                      <span
                        style={{ marginLeft: "1rem", display: "inline-flex" }}
                      >
                        {" "}
                        {profile?.property_types?.length > 4 ? (
                          <CharacterCircle
                            small
                            background="buyer"
                            character={"+" + profile?.property_types?.length}
                          />
                        ) : (
                          ""
                        )}
                      </span>{" "}
                    </p>
                  </div>
                </Fragment>
              </Fragment>
            }
          />
        )
      ) : (
        ""
      )}
    </Fragment>
  );
};

export default Preferences;
