import React, { useCallback, useEffect, useState } from "react";
import { LinearProgress, TextField } from "@material-ui/core";
import { Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import _isEmpty from "lodash/isEmpty";

import { getAllListings } from "../../../modules/listings/_crud/listingsCrud";
import { formToQueryString } from "../../../_helpers/_utils/utils";
import ThemeButton from "../../button/ThemeButton";

const ListingPopover = ({ value, handleSelectedListing }) => {
  const [data, setData] = useState([]);
  const [otherResData, setOtherResData] = useState({});
  const [keyword, setKeyword] = useState("");
  const [price_from, setPrice_from] = useState("");
  const [price_to, setPrice_to] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const update = useCallback(
    (params = {}) => {
      let obj = { ...params, state: 1, show_on_website: 1 };
      if (price_from) {
        obj.price_from = price_from;
      }
      if (price_to) {
        obj.price_to = price_to;
      }
      if (keyword) {
        obj.suburb_name = keyword;
      }
      setIsLoading(true)
      getAllListings(formToQueryString(obj))
        .then(({ data: { data, ...rest } }) => {
          if (params.loadMore) {
            setData(prev => prev.concat(data));
          } else {
            setData(data);
          }
          setOtherResData(rest)
        })
        .catch((e) => {
          toast.success('Error while saving');
        })
        .finally(() => {
          setIsLoading(false)
        })
    },
    [keyword, price_from, price_to],
  )

  useEffect(() => {
    update()
  }, [keyword, price_from, price_to, update])

  const handleLoadMore = () => {
    let obj = {
      page: otherResData?.current_page + 1,
      loadMore: true
    };
    update(obj)
  }

  return (
    <div className="p-3">
      <div>
        <Row>
          <Col lg={4}>
            <TextField
              label="Price From"
              id="price_from"
              variant="outlined"
              size="small"
              onChange={(e) => {
                setPrice_from(e.target.value);
              }}
            />
          </Col>

          <Col lg={4}>
            <TextField
              label="Price To"
              id="price_to"
              variant="outlined"
              size="small"
              onChange={(e) => {
                setPrice_to(e.target.value);
              }}
            />
          </Col>
          <Col lg={4}>
            <TextField
              label="Suburb"
              id="suburb"
              variant="outlined"
              size="small"
              onChange={(e) => { setKeyword(e.target.value) }}
            />
          </Col>
        </Row>
        {isLoading ? <LinearProgress className='mt-2' /> : ""}
        <div className='listing-popover-content'>
          {data?.map((v) => (
            <Row
              onClick={() => {
                handleSelectedListing &&
                  handleSelectedListing(v?.property?.address?.full_address, v?.website_url);
              }}
              className="m-0 p-2 cursor-hand"
            >
              {v?.property?.address?.full_address}
            </Row>
          ))}
          <div className='d-flex justify-content-center'>
            {_isEmpty(data) && !isLoading ?
              <div className='mt-5'>No Data</div> : null}
            {!_isEmpty(data) ?
              < ThemeButton
                className="green-button"
                onClick={handleLoadMore}
                disabled={!otherResData?.next_page_url}
              >
                Load more
              </ThemeButton> : null}
          </div>
        </div>
      </div>
    </div >
  );
};

export default ListingPopover;
