import React from "react";
import { Modal } from "react-bootstrap";

const DefaultModal = ({ className, children, size, title, ...rest }, props) => {
  return (
    <Modal
      {...rest}
      className={`full-width modal-stretch ${className}`}
      size={size}
    >
      {title && (
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
      )}

      <Modal.Body
        className="overflow-y p-md-5 p-3 "
        style={{ overflow: rest?.hide ? "hidden" : "scroll" }}
      >
        {children}
      </Modal.Body>
    </Modal>
  );
};

DefaultModal.defaultProps = {
  size: "xl",
};
export default DefaultModal;
