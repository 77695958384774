import React from "react";
import { connect } from "react-redux";
import { change, submit } from "redux-form";
import ThemeButton from "../../../partials/button/ThemeButton";

const NoteRemoteFormButton = ({
  dispatch,
  label,
  // handleDoorKnock,
  formName,
  disabled = false,
  className = "",
  setNAButtonLoading = () => {},
}) => {
  const handleOnclick = () => {
    setNAButtonLoading(true);
    dispatch(change(formName, "type", label));

    setTimeout(() => {
      dispatch(submit(formName));
    }, 1000);

    // handleDoorKnock();
  };
  return (
    <ThemeButton
      disabled={disabled}
      className={`door-knock-button ${className}`}
      onClick={() => handleOnclick()}
    >
      {label}
    </ThemeButton>
  );
};

export default connect()(NoteRemoteFormButton);
