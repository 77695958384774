import axios from "axios";
import faker from "faker";
// const WiseBerryAPI = process.env.REACT_APP_WISEBERRY_API;
const WiseBerryAPI = process.env.REACT_APP_WISEBERRY_API;

// const WiseBerryAPI = 'http://127.0.0.1:8002/api';
export const LOGIN_URL = WiseBerryAPI + "/login";
export const REGISTER_URL = WiseBerryAPI + "/register";
export const REQUEST_PASSWORD_URL = WiseBerryAPI + "/forgot-password";
export const ME_URL = WiseBerryAPI + "/details";

export const DIARY_URL_APPOINTMENT = WiseBerryAPI + "appointment";
export const DIARY_URL_TASK = WiseBerryAPI + "task";
export const PROSPECTS = WiseBerryAPI + "prospect";

export function getEventsData() {
  const now = new Date();
  const events = [
    {
      id: 0,
      title: "All Day Event very long title",
      allDay: true,
      start: new Date(2021, 2, 0),
      end: new Date(2020, 2, 1),
    },
    {
      id: 1,
      title: "Long Event",
      start: new Date(2015, 3, 7),
      end: new Date(2015, 3, 10),
    },

    {
      id: 2,
      title: "DTS STARTS",
      start: new Date(2016, 2, 13, 0, 0, 0),
      end: new Date(2016, 2, 20, 0, 0, 0),
    },

    {
      id: 3,
      title: "DTS ENDS",
      start: new Date(2016, 10, 6, 0, 0, 0),
      end: new Date(2016, 10, 13, 0, 0, 0),
    },

    {
      id: 4,
      title: "Some Event",
      start: new Date(2015, 3, 9, 0, 0, 0),
      end: new Date(2015, 3, 10, 0, 0, 0),
    },
    {
      id: 5,
      title: "Conference",
      start: new Date(2015, 3, 11),
      end: new Date(2015, 3, 13),
      desc: "Big conference for important people",
    },
    {
      id: 6,
      title: "Meeting",
      start: new Date(2015, 3, 12, 10, 30, 0, 0),
      end: new Date(2015, 3, 12, 12, 30, 0, 0),
      desc: "Pre-meeting meeting, to prepare for the meeting",
    },
    {
      id: 7,
      title: "Lunch",
      start: new Date(2020, 8, 12, 12, 0, 0, 0),
      end: new Date(2015, 3, 12, 13, 0, 0, 0),
      desc: "Power lunch",
    },
    {
      id: 8,
      title: "Meeting",
      start: new Date(2015, 3, 12, 14, 0, 0, 0),
      end: new Date(2015, 3, 12, 15, 0, 0, 0),
    },
    {
      id: 9,
      title: "Happy Hour",
      start: new Date(2015, 3, 12, 17, 0, 0, 0),
      end: new Date(2015, 3, 12, 17, 30, 0, 0),
      desc: "Most important meal of the day",
    },
    {
      id: 10,
      title: "Dinner",
      start: new Date(2015, 3, 12, 20, 0, 0, 0),
      end: new Date(2015, 3, 12, 21, 0, 0, 0),
    },
    {
      id: 11,
      title: "Birthday Party",
      start: new Date(2015, 3, 13, 7, 0, 0),
      end: new Date(2015, 3, 13, 10, 30, 0),
    },
    {
      id: 12,
      title: "Late Night Event",
      start: new Date(2015, 3, 17, 19, 30, 0),
      end: new Date(2015, 3, 18, 2, 0, 0),
    },
    {
      id: 13,
      title: "Today",
      start: new Date(new Date().setHours(new Date().getHours() - 3)),
      end: new Date(new Date().setHours(new Date().getHours() + 3)),
    },
    {
      id: 14,
      title: "Today",
      start: new Date(new Date().setHours(new Date().getHours() - 1)),
      end: new Date(new Date().setHours(new Date().getHours() + 1)),
    },
    {
      id: 15,
      title: "Today",
      start: new Date(new Date().setHours(new Date().getHours() - 3)),
      end: new Date(new Date().setHours(new Date().getHours() + 3)),
    },
    {
      id: 15,
      title: "Point in Time Event",
      start: now,
      end: now,
    },
  ];
  // Authorization head should be fulfilled in interceptor.
  return events;
}

export function getAllDiaryAppointmentList(prams) {
  return axios.get(
    DIARY_URL_APPOINTMENT +
      // + '?limit=30'
      (prams ? "?" + prams : "")
  );
}

export function getAppointmentById(id) {
  return axios.get(DIARY_URL_APPOINTMENT + "/" + id);
}

export function getMicrositesBydAppoinmentID(id) {
  return axios.get(DIARY_URL_APPOINTMENT + "/microsites/" + id);
}

export function sendMicrosteToProspectEmail(id) {
  return axios.get(DIARY_URL_APPOINTMENT + "/send-pre-listing-email/"+id);
}

export function getAllTasks(prams) {
  return axios.get(DIARY_URL_TASK + (prams ? "?" + prams : ""));
}

export function postUpdateDiaryAppointmentById(id, prams) {
  return axios.post(DIARY_URL_APPOINTMENT + "/" + id, prams, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function postUpdateDiaryTaskById(id, prams) {
  return axios.post(DIARY_URL_TASK + "/" + id, prams, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}


export function openHomeBulkUpdate(prams) {
  return axios.post(DIARY_URL_APPOINTMENT+"/open-home-bulk-update", prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}







export function createNewAppointment(prams) {
  return axios.post(DIARY_URL_APPOINTMENT, prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function createNewTask(prams) {
  return axios.post(DIARY_URL_TASK, prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function completeTasks(prams) {
  return axios.post(DIARY_URL_TASK + "/complete", prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function deleteTasks(prams) {
  return axios.post(DIARY_URL_TASK + "/delete", prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function createAddress(prams) {
  return axios.post(WiseBerryAPI + "address", prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function createPropertyByAddress(prams) {
  return axios.post(WiseBerryAPI + "property", prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function findProspectByAddress(prams) {
  return axios.get(PROSPECTS + "/find-by-address?" + prams);
}

export function deleteTaskById(id) {
  return axios.delete(DIARY_URL_TASK + "/" + id);
}

export function deleteAppById(id) {
  return axios.delete(DIARY_URL_APPOINTMENT + "/" + id);
}
