import React from "react";
import { useAccordionToggle } from "react-bootstrap/AccordionToggle";

export const OfficeCustomToggle = ({ children, eventKey }) => {
  const decoratedOnClick = useAccordionToggle(eventKey, () =>
    console.log("totally custom!")
  );

  return <div onClick={decoratedOnClick}>{children}</div>;
};

const HeaderDropdownToggle = React.forwardRef((props, ref) => {
  return (
    <div
      ref={ref}
      className="kt-header__topbar-wrapper"
      onClick={(e) => {
        e.preventDefault();
        props.onClick(e);
      }}
    >
      {props.children}
    </div>
  );
});

HeaderDropdownToggle.displayName = "HeaderDropdownToggle";
export default HeaderDropdownToggle;
