import React, { Fragment } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { IconButton } from "@material-ui/core";
import { Field } from "redux-form";
import TextInput from "./TextInput";
import DeleteIcon from "@material-ui/icons/Delete";
import { useEffect } from "react";
import DateInput from "./DateInput";

const NestedChildInfo = ({
  fields,
  meta: { error, submitFailed },
  number,
  label,
}) => {
  useEffect(() => {
    if (number > 0) {
      let i = 0;

      if (number < fields.length) {
        for (i = 0; i < fields.length - number; i++) {
          fields.pop({});
        }
      }
      if (number > fields.length) {
        for (i = 0; i < number - fields.length; i++) {
          fields.push({});
        }
      }
    }
  }, [number]);

  return (
    <Fragment>
      {fields.map((child, index) => (
        <Fragment key={index}>
          <Form.Row className="pb-3">
            {/* <Form.Label>
              {index ? (
                <IconButton
                  onClick={() => fields.remove(index)}
                  color="secondary"
                  aria-label="delete"
                >
                  <DeleteIcon />
                </IconButton>
              ) : (
                ""
              )}
            </Form.Label> */}
            <Col>
              <Form.Row>
                <Col md={6}>
                  <Field
                    name={`${child}.name`}
                    type="text"
                    component={TextInput}
                    placeholder={`Child ${index + 1} Name`}
                    label={`Child ${index + 1} Name`}
                  />
                </Col>

                <Col md={6}>
                  <Field
                    name={`${child}.dob`}
                    type="date"
                    component={DateInput}
                    placeholder={`Child ${index + 1} Dob`}
                    label={`Child ${index + 1} Dob`}
                  />
                </Col>
              </Form.Row>
            </Col>
          </Form.Row>
        </Fragment>
      ))}
    </Fragment>
  );
};

export default NestedChildInfo;
