import React, { useState, useEffect } from 'react';
import { Button } from 'antd';
import { Field, FieldArray, reduxForm } from 'redux-form'

import { PlusCircleFilled, MinusCircleFilled } from '@ant-design/icons';
import MaterialSelect from '../../../../partials/form-basic/select/MaterialSelect';
import ToolTip from '../../../../partials/tooltip/ToolTip';
import ThemeButton from '../../../../partials/button/ThemeButton';
import ThemeHeader from '../../../../partials/content/ThemeHeader';


const WindowCardSettings = (props) => {
    const { handleSubmit } = props
    const [options, setOptions] = useState([{ id: 1, name: 'A3 Portrait-Auction', disabled: false }])
    const [optionVal, setOptionVal] = useState("")
    /* useEffect(() => {
        let ar = [{ id: 1, name: 'A3 Portrait-Auction', disabled: false }]
        setOptions(ar)
    }, []) */
    const renderTemplateField = () => {
        return <MaterialSelect
            label='Template'
            options={options}
            onChange={(e) => setOptionVal(e)}
            value={optionVal}
        />
    }
    const renderPersonalField = () => {
        return <MaterialSelect
            label='Personalisation'
        //    options={options}
        //    onChange={(e) => setOptionVal(e)}
        // value={optionVal}
        />
    }
    const renderCopyField = () => {
        return <MaterialSelect
            label='Copy Existing Template'
        //    options={options}
        //    onChange={(e) => setOptionVal(e)}
        // value={optionVal}
        />
    }
    const renderExtraFields = ({ fields }) => {
        return <div>
            {fields.map((member, index) => (
                <div key={index} style={{ display: 'flex', alignItems: 'end' }}>
                    <div style={{ width: '33%' }} className='m-1'>
                        <Field
                            name='copyTemplate'
                            // type="text"
                            component={renderCopyField}
                        />
                    </div>
                    <div style={{ width: '30%' }} className='m-1'>
                        <Field
                            name='personalisation'
                            component={renderPersonalField}
                        />
                    </div>
                    <div className='m-1' style={{ width: '3%' }} >
                        <MinusCircleFilled onClick={() => fields.remove(index)} style={{ fontSize: '20px', color: 'grey' }} />
                    </div>
                </div>
            ))}
            <div>
                <PlusCircleFilled style={{ fontSize: '20px', color: '#89344f', textAlign: 'center' }} onClick={() => fields.push({})} />
            </div>
        </div>

    }
    return (
        <form onSubmit={handleSubmit}>
            <div>
                <div>
                    <ThemeHeader left={<h4 className="title"> Window Cards Automation Settings</h4>} right={
                        <div style={{ display: 'flex' }}>
                            <ToolTip
                                className="mr-2"
                                name="edit"
                                content="edit"
                            />
                            <ThemeButton className='lightGrey-button text-white mr-2'>Cancel</ThemeButton>
                            <ThemeButton className='green-button'>Save</ThemeButton>
                        </div>
                    } />

                </div>
                <div style={{ fontWeight: 'bold' }}>Automatic Template Creation</div>
                <div style={{ width: '100%', display: 'flex' }}>
                    <div style={{ width: '33%' }} className='m-1'>
                        <Field
                            name="templateName"
                            // type="select"
                            component={renderTemplateField}
                        // label="Club Name"
                        />
                    </div>
                    {optionVal ?
                        <div style={{ width: '66%' }}>
                            <FieldArray name='extraField' component={renderExtraFields} />
                        </div>
                        : ""}
                </div>
                {/* </div> */}
            </div>
        </form>
    )
}
export default reduxForm({
    form: 'testModalFieldArrays',
    initialValues: { extraField: [{ copyTemplate: '' }] }
})(WindowCardSettings)