export const LeasedLayout1 = (overlay, leasedcolor = "#FFFFFF", otherColor = "#FFFFFF") => {
    return `<div id='poster-template' class='leased'><div style="width: 600px; height: 600px;border:1px solid #aa9f9f;background-size:cover;"class='bg-image-node node-content-item'><img src=${overlay} alt="forsale1" style="width: 100%;" /><div class='leased-block-1'style="position: absolute;word-break: normal; top: 48%; width:100%; color: ${otherColor}; font-size: 0.938vw; font-family: Arial, Helvetica, sans-serif; display: flex; flex-direction: column; justify-content: space-between; align-items: center;"><div style="padding: 0px 0px 0px; text-align: center;margin-top:3%; width:100%"class="leased-node node-content-item"><div style="font-family: PlayList; font-size: 3.65vw;color:${leasedcolor}" class="main-heading-node"></div><div class="main-content-block"style="height: 100%; width: 100%; display: flex; flex-direction: column;align-items: center;font-family:system-ui"><div class='suburb' style="font-weight:bold;font-size: 2.86vw;text-transform:capitalize;font-family:Cormorant Garamond; width: 100%;">[SUBURB]</div><div class='address' style="font-family: Open Sans,sans-serif;font-size:1.72vw;font-weight:bold; word-break:normal; width: 100%; margin-top: 5px;">[ADDRESS]</div></div></div></div></div></div>`
}

export const LeasedLayout2 = (overlay, leasedcolor = "#FFFFFF", otherColor = "#FFFFFF") => {
    return `<div id='poster-template' class='leased'><div style="width: 600px; height: 600px;border:1px solid #aa9f9f; background-size: cover;"class='bg-image-node node-content-item'><img src=${overlay} alt="forsale1" style="width: 100%;" /><div class='leased-block-1'style="position: absolute;word-break: normal; top: 40%; width:100%;  color: ${otherColor}; font-size: 0.938vw; font-family: Arial, Helvetica, sans-serif; display: flex; flex-direction: column; justify-content: space-between; align-items: center;"><div style="padding: 0px 0px 0px; text-align: center;width:100%"class="leased-node node-content-item"><div style="font-family: initial; font-size: 2.8vw ;color:${leasedcolor}" class="main-heading-node"></div><div class="main-content-block"style="height: 100%; width: 100%; display: flex; flex-direction: column;align-items: center;font-family:system-ui"><div class='suburb' style="font-weight:bold;font-size: 3.65vw;font-family:Cormorant Garamond;text-transform:capitalize; width: 100%;">[Suburb]</div><div class='address' style="font-family: Open Sans,sans-serif;font-size:1.72vw;font-weight:bold; word-break: normal; width: 100%; margin-top: 5px">[ADDRESS]</div></div></div></div></div></div>`
}
export const LeasedLayout3 = (overlay, leasedcolor = "#FFFFFF", otherColor = "#FFFFFF") => {
    return `<div id='poster-template' class='leased'><div style="width: 600px; height: 600px;border:1px solid #aa9f9f; background-size: cover;"class='bg-image-node node-content-item'><img src=${overlay} alt="forsale1" style="width: 100%;" /><div class='leased-block-1'style="position: absolute;word-break: normal; top: 43%; width:100%; color: ${otherColor}; font-size: 0.938vw; font-family:ui-sans-serif; display: flex; flex-direction: column; justify-content: space-between; align-items: center;"><div style="padding: 0px 0px 0px; text-align: center;width:100%"class="leased-node node-content-item"><div style="font-size: 2.8vw;color:${leasedcolor}" class="main-heading-node"></div><div class="main-content-block"style="height: 100%; width: 100%; display: flex; flex-direction: column;align-items: center;"><div class='suburb' style="font-weight:bold;font-size: 2.86vw;text-transform:capitalize; font-size:Cormorant Garamond; width: 100%;">[SUBURB]</div><div class='address' style="font-size:1.72vw;font-weight:bold; word-break: normal; width:  100%; margin-top: 5px; font-family: Open Sans">[ADDRESS]</div></div></div></div></div></div>`
}
// export const LeasedLayout4 = (overlay, leasedcolor = "#FFFFFF", otherColor = "#FFFFFF") => {
//     return `<div id='poster-template' class='leased'><div style="width: 600px; height: 600px;border:1px solid #aa9f9f; background-size: cover;"class='bg-image-node node-content-item'><img src=${overlay} alt="forsale1" style="width: 100%;" /><div class='leased-block-1'style="position: absolute;word-break: normal; top: 70%; width:100%;  color: ${otherColor}; font-size: 0.938vw; font-family:ui-sans-serif; display: flex; flex-direction: column; justify-content: space-between; align-items: center;"><div style="padding: 0px 0px 0px; text-align: center;width:100%"class="leased-node node-content-item"><div style="font-size: 2.8vw;color:${leasedcolor}" class="main-heading-node"></div><div class="main-content-block"style="height: 100%; width: 100%; display: flex; flex-direction: column;align-items: center;"><div class='suburb' style="font-weight:bold;font-size: 3.65vw;text-transform:capitalize; width: 100%;">[Suburb]</div><div class='address' style="font-size:1.30vw;font-weight:bold"; width: 100%; word-break: normal; margin-top: 5px>[ADDRESS]</div></div></div></div></div></div>`
// }
export const LeasedLayout4 = (overlay, leasedcolor = "#FFFFFF", otherColor = "#FFFFFF") => {
    return `<div id='poster-template' class='leased'><div style="width: 600px; height: 600px;border:1px solid #aa9f9f; background-size: cover;"class='bg-image-node node-content-item'><img src=${overlay} alt="forsale1" style="width: 100%;" /><div class='leased-block-1'style="position: absolute;word-break: normal; top: 70%; width:100%; color: ${otherColor}; font-size: 0.938vw; font-family:ui-sans-serif; display: flex; flex-direction: column; justify-content: space-between; align-items: center;"><div style="padding: 0px 0px 0px; text-align: center;width:100%"class="leased-node node-content-item"><div style="font-size: 2.8vw;color:${leasedcolor}" class="main-heading-node"></div><div class="main-content-block"style="height: 100%; width: 100%; display: flex; flex-direction: column;align-items: center;"><div class='suburb' style="font-weight:bold;font-size: 2.86vw;text-transform:capitalize; font-size:Cormorant Garamond;width: 100%;">[SUBURB]</div><div class='address' style="font-size:1.72vw;font-weight:bold; word-break: normal; width:  100%; margin-top: 5px; font-size:Open Sans">[ADDRESS]</div></div></div></div></div></div>`
}