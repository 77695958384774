import React, { useState } from 'react';
import _map from 'lodash/map';
import _filter from 'lodash/filter';

const APMFeatures = ({
  APMFeatureData,
  selectedFeatures,
  setSelectedFeatures,
}) => {
  const [selectAllChecked, setSelectAllChecked] = useState(false);

  const handleSelectAllToggle = (e) => {
    setSelectAllChecked(!selectAllChecked);
    if (e.target.checked) {
      let selectedIds = _map(_filter(APMFeatureData, data => !data.disabled), (feature) => feature.id);
      setSelectedFeatures(selectedIds);
    } else {
      setSelectedFeatures([]);
    }
  };

  const handleSelect = (e, key) => {
    let selectedData = [];
    if (key) {
      if (selectedFeatures.includes(key)) {
        let tempSelected = [...selectedFeatures].filter(val => val !== key);
        setSelectedFeatures(tempSelected);
      } else {
        setSelectedFeatures([...selectedFeatures, key]);
        selectedData.push(...selectedFeatures, key)
      }
      let tempAPMFeatureData = _filter(APMFeatureData, data => !data.disabled)
      if (tempAPMFeatureData.length === selectedData.length) {
        setSelectAllChecked(true);
      } else {
        setSelectAllChecked(false);
      }
    }
  };

  if (!APMFeatureData?.length) {
    return null;
  }

  return (
    <div>
      <div className="d-flex align-items-center">
        <div className="mt-1 mr-2">
          <input
            id="checkAllContacts"
            type="checkbox"
            checked={selectAllChecked}
            onChange={handleSelectAllToggle}
          />
        </div>
        <h6 className="title m-0">Features</h6>
      </div>
      {_map(APMFeatureData, (feature, index) => (
        <div key={index} className="ml-3 mb-2 d-flex">
          <div className="mt-1 mr-2">
            <input
              id={feature?.id}
              type="checkbox"
              onChange={(e) => handleSelect(e, feature?.id)}
              checked={selectedFeatures.includes(feature?.id)}
              disabled={feature?.disabled}
            />
          </div>
          <div>
            <div className={feature?.disabled ? 'disabled-text' : ""}>{feature?.name}: {feature?.qty}</div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default APMFeatures;
