import React, { useEffect, useState } from "react";
import { Row, Col, Spinner } from "react-bootstrap";

import { TextField } from "@material-ui/core";
import { getAllMembers } from "../../../../app/modules/setting/_crud/settingCrud";
import ThemeButton from "../../button/ThemeButton";
import querystring from 'query-string';
import _debounce from 'lodash/debounce';
import _get from 'lodash/get';
import { toast } from "react-toastify";


const AgentsPopover = ({ value, handleSelectedListing }) => {
  const [members, setMembers] = useState(null);
  const [page, setPage] = useState(1);
  const [isRemaining, setRemainingFlag] = useState(true);
  const [loading, setLoading] = useState(false);
  const [linkType, setLinkType] = useState(null);

  useEffect(() => {
    getMembersData();
  }, []);
  const getMembersData = (page = 1, params) => {
    if (!params?.search_kw?.length) {
      delete (params?.search_kw)
    }
    setLoading(true);
    let obj = { limit: 99, active: [1], ...params }
    const searchParams = querystring.stringify({ page, ...obj });
    getAllMembers(searchParams)
      .then((res) => {
        const { data, ...rest } = res.data;
        if (page === 1) {
          setMembers(res?.data?.data);
        } else {
          setMembers([
            ...members,
            ..._get(res, 'data.data', [])
          ]);
        }
        setLoading(false);
        if (!_get(res, 'data.next_page_url')) {
          setRemainingFlag(true);
        } else {
          setRemainingFlag(false);
        }
        setPage(page);
        // setOtherData(rest);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.data?.message)
      })
  }
  const handleLoadmore = () => {
    getMembersData(page + 1);
  }
  const update = (e) => {
    let filteredMember = members.filter(obj => obj.name.includes(e))
    setMembers(filteredMember);

  };

  const handleAgentDebounceSearch = (value) => {
    getMembersData(1, { search_kw: value })
  }
  const onSearch = _debounce(handleAgentDebounceSearch, 500);

  //   const openBuyer = (id) => {
  //     setSelectedId(id);
  //     setShowBuyer(true);
  //   };

  //   useEffect(() => {
  //     getTenListings()
  //       .then((res) => {
  //         setData(res.data.data);
  //       })
  //       .catch((e) => {
  //         // setSuccess(true);
  //         // setLoading(false);
  //         // toast.success('Error while saving');
  //       });
  //   }, []); // here

  //   const update = (keyword, key) => {
  //     let obj = {};
  //     if (keyword) {
  //       setKeyword(keyword);
  //       if (key == "price_from") {
  //         obj.price_from = keyword;
  //         obj.price_to = price_to;
  //       }
  //       if (key == "price_to") {
  //         obj.price_from = price_from;
  //         obj.price_to = keyword;
  //       }
  //       getTenListings(formToQueryString(obj))
  //         .then((res) => {
  //           setData(res.data.data);
  //         })
  //         .catch((e) => {
  //           // setSuccess(true);
  //           // setLoading(false);
  //           // toast.success('Error while saving');
  //         });
  //     }
  //   };

  // const onClickRow = (e) => {
  //   setSelectedId(e);
  //   setShowBuyer(true);
  // };

  return (
    <div style={{ maxHeight: '300px', overflowY: 'auto' }} className="p-3">
      <Row className='mb-3'>
        <Col md={6}>
          <div className='sms-agent-select mt-4'>
            <select className='select-agent' name="cars" id="cars" onChange={setLinkType}>
              <option value="0" disabled selected hidden>Select Agent</option>
              <option value="1">Agent</option>
              {/* <option value="2">Pre-Listing Microsite</option>
              <option value="3">Post-Listing Microsite</option> */}
            </select>
          </div>
        </Col>
        <Col md={6}>
          <TextField
            // label="Team"
            // id="price_from"
            // variant="outlined"
            // onChange={(e) => {
            //   setSelectedTeam(e.target.value);
            //   update(e.target.value);
            // }}
            placeholder='Search'
            onChange={(event) => { onSearch(event.target.value,) }}
            className="mt-4"
            size="small"
          />
        </Col>
      </Row>
      {members?.length > 0 && (
        <div>
          {" "}
          {members.map((v) => (<Row
              onClick={() => {
                handleSelectedListing(v.username, v.view_profile_url);
              }}
              className="px-3 py-2 cursor-hand"
            >
              {v.username}
              {/* {v.phone} */}
            </Row>
          ))}
        </div>
      )}
      {loading
        ? (<div style={{ textAlign: 'center' }}> <Spinner
          className='mr-2'
          animation="border"
          role="status"
          aria-hidden="true"
        /> </div>)
        : null
      }
      <div className='d-flex justify-content-center'>
        <ThemeButton disabled={isRemaining || loading} onClick={handleLoadmore} className={`${isRemaining || loading ? 'lightGrey-button' : 'green-button text-white'} `}>
          Load More
        </ThemeButton>
    </div>
    </div>
  );
};

export default AgentsPopover;
