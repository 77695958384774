import React from "react";
import {List, ListItem} from "@material-ui/core";
import clsx from "clsx";
import cuid from "cuid";
import {useSelector} from "react-redux";

import DrawerLeft from "../../../partials/layout/pageLayout/DrawerLeft";
import {stringCompare} from "../../../_helpers/_utils/utils";
import {MarketingBoardsList} from "../partials/MarketingBoardsList";
import AccessControl from "../../../_ accessControl/AccessControl";

const MarketingBoards = () => {
    const [component, setComponent] = React.useState("");
    const token = useSelector((state) => state.auth.authToken);
    const organisationId = useSelector(
        (state) => state.auth.user?.organisation?.id
    );

    const handleChangeComponent = (e) => {
        setComponent(e);
    };
    return (
        <DrawerLeft
            drawer={
                <>
                    <AccessControl
                        allowedPermissions={["campaigns-property-campaigns-all"]}
                        renderNoAccess={() => ""}
                    >
                        <List>
                            <ListItem>
                                <h5 className="font-weight-bold">CAMPAIGNS</h5>
                            </ListItem>

                            {["Property Campaigns"].map((text, index) => (
                                <ListItem
                                    onClick={() => handleChangeComponent(text)}
                                    key={cuid()}
                                    className={clsx(
                                        {active: stringCompare(text, component)},
                                        "list-disable"
                                    )}
                                >
                                    {text}
                                </ListItem>
                            ))}
                        </List>
                    </AccessControl>

                    <AccessControl
                        allowedPermissions={["homepage-setup-all"]}
                        renderNoAccess={() => ""}
                    >

                        <List>
                            <ListItem>
                                <h5 className="font-weight-bold">WEBSITE</h5>
                            </ListItem>
                            {["Homepage Setup",
                                "Testimonials",
                                "Blogs",
                                "Community Involvement",
                            ].map((text, index) => (
                                <ListItem
                                    onClick={() => handleChangeComponent(text)}
                                    key={cuid()}
                                    className={clsx({
                                        active: stringCompare(text, component),
                                    })}
                                >
                                    {text}
                                </ListItem>
                            ))}
                        </List>
                    </AccessControl>
                    <AccessControl
                        allowedPermissions={["microsites-all"]}
                        renderNoAccess={() => ""}
                    >
                        <List>
                            <ListItem>
                                <h5 className="font-weight-bold">MICROSITES</h5>
                            </ListItem>
                            {["Create New", "Microsites Settings"].map((text, index) => (
                                <ListItem
                                    onClick={() => handleChangeComponent(text)}
                                    key={cuid()}
                                    className={clsx({
                                        active: stringCompare(text, component),
                                    })}
                                >
                                    {text}
                                </ListItem>
                            ))}
                        </List>
                    </AccessControl>
                    <AccessControl
                        allowedPermissions={["social-media-templates-all"]}
                        renderNoAccess={() => ""}
                    >
                        <List>
                            <ListItem>
                                <h5 className="font-weight-bold">PRINT MARKETING</h5>
                            </ListItem>
                            {/* "Drop Cards",
                "Brochures",
                "Signboards",
                "Office Stationery",
                "Window Cards" */}
                            {["Window Cards"].map((text, index) => (
                                <ListItem
                                    onClick={() => handleChangeComponent(text)}
                                    key={cuid()}
                                    className={clsx({active: stringCompare(text, component)})}
                                >
                                    {text}
                                </ListItem>
                            ))}
                        </List>
                    </AccessControl>

                    <AccessControl
                        allowedPermissions={["social-media-templates-all"]}
                        renderNoAccess={() => ""}
                    >
                        <List>
                            <ListItem>
                                <h5 className="font-weight-bold">SOCIAL MEDIA</h5>
                            </ListItem>
                            {["Templates"].map((text, index) => (
                                <ListItem
                                    onClick={() => handleChangeComponent(text)}
                                    key={cuid()}
                                    className={clsx({
                                        active: stringCompare(text, component),
                                    })}
                                >
                                    {text}
                                </ListItem>
                            ))}
                        </List>
                    </AccessControl>
                </>
            }
            body={<MarketingBoardsList pageComponent={component}/>}
            bodyclassName="p-2"
        />
    );
};

export default MarketingBoards;
