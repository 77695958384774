import React, { useEffect } from "react";
import { FieldArray, reduxForm } from "redux-form";
import { Fragment } from "react";
import NoteForm from "./NoteForm";

const NoteFormWrapper = (props) => {
  const {
    contacts = [],
    handleSubmit,
    profileId,
    prospectLoading,
    setProspectLoading,
    setIsDisabled,
    isLoading,
    isDisabled,
  } = props;

  useEffect(() => {
    props.destroy();
  }, [props.submitSucceeded]);

  return (
    <Fragment>
      <form onSubmit={handleSubmit} style={{ width: "100%" }}>
        <div className={contacts.length ? "" : "notes-field-disabled note-form-mobile"}>
          <FieldArray
            name="notes"
            defaultValue={{ id: 4, name: "Prospect" }}
            placeholder="Enter a note about this prospect..."
            prospectLoading={prospectLoading}
            setProspectLoading={setProspectLoading}
            component={NoteForm}
            isLoading={isLoading}
            classNameButton={'button-add-note-form'}
            classname={'container-note-form-mobile'}
            profileId={profileId}
            setIsDisabled={setIsDisabled}
            isDisabled={isDisabled}
          />
        </div>
      </form>
    </Fragment>
  );
};

export default reduxForm({ form: "mobile-notes" })(NoteFormWrapper);
