import React, { Fragment, useEffect, useState } from "react";
import { components } from "react-select";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import AsyncCreatableSelect from "react-select/async-creatable";
import { InputLabel } from "@material-ui/core";
const menuHeaderStyle = {
  padding: "8px 12px",
};
function getLength(options) {
  return options.reduce((acc, curr) => {
    if (curr.options) return acc + getLength(curr.options);
    return acc + 1;
  }, 0);
}
// const Menu = (props) => {
//   const optionsLength = getLength(props.options);
//   return (
//     <Fragment>
//       <div style={menuHeaderStyle}>
//         Custom Menu with {optionsLength} options
//       </div>
//       <components.Menu {...props}>{props.children}</components.Menu>
//     </Fragment>
//   );
// };

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <ArrowDropDownIcon />
    </components.DropdownIndicator>
  );
};

const SingleValue = (props) => (
  <components.SingleValue {...props}>{props.data.label}</components.SingleValue>
);

const AsyncMultiSelectCreatable = (props) => {
  const {
    edit,
    input,
    options,
    placeholder,
    defaultValue,
    isMulti,
    defaultRef,
    handleOnClick,
    label,
    error,
    isClearable,
    disabled,
    ...others
  } = props;
  // const [item, setItem] = useState(null);
  const [dropdownOptions, setDropdownOptions] = useState({});
  
  useEffect(() => {
    setDropdownOptions(options);
  }, [options]);

  const handleChange = (e) => {
    if (typeof handleOnClick != "undefined") {
      handleOnClick(e);
    }
    input.onChange(e);
  };

  return (
    <Fragment>
      {edit ? (
        <div
          className={
            "multiSelect" +
            (error ? " required-border" : " not-required-border")
          }
        >
          <InputLabel>{label}</InputLabel>
          <AsyncCreatableSelect
            required
            {...others}
            onChange={handleChange}
            classNamePrefix="multiSelect"
            styles={{
              // Fixes the overlapping problem of the component
              menu: (provided) => ({ ...provided, zIndex: 9999 }),
            }}
            noOptionsMessage={({ inputValue }) =>
              inputValue ? "No Records found" : "Search to see results"
            }
            hideSelectedOptions={false}
            cacheOptions
            loadOptions={options}
            value={input.value}
            isMulti={isMulti}
            closeMenuOnSelect={isMulti ? false : true}
            placeholder={placeholder}
            components={{ SingleValue, DropdownIndicator }}
            ref={defaultRef}
            isClearable={isClearable ? true : false}
            disabled={disabled}
            // onCreateOption={(e)=>console.log(e)}
          />
        </div>
      ) : (
        <h6>{input.value.value}</h6>
      )}
    </Fragment>
  );
};

AsyncMultiSelectCreatable.defaultProps = {
  isMulti: true, //  pass true or false to make that specific Select Field multiple input accept or not. By default it's can accept multiple input.
  edit: true, // pass true or false to make that specific field editable or not. By default it's editable
  showBottom: false, // pass true or false to make selected options Bottom of the option field.
};

export default AsyncMultiSelectCreatable;
