import React from "react";

const ActiveDots = ({ active }) => {
  return (
    <div className="d-flex justify-content-center">
      {active ? (
        <span style={{ color: "#8FBC8F" }}>
          <i className="fas fa-circle"></i>
        </span>
      ) : (
        <span style={{ color: "#E9967A" }}>
          <i className="fas fa-circle"></i>{" "}
        </span>
      )}
    </div>
  );
};

export default ActiveDots;
