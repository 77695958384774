/* eslint-disable no-restricted-imports */
import React, {Fragment, useEffect, useCallback, useContext} from "react";
import clsx from "clsx";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import {Row} from "react-bootstrap";
import {ProspectsContext} from "../../../modules/prospect/pages/prospects/ProspectsContextProvider";
// import ThemeButton from "../../button/ThemeButton";

const drawerWidth = 320;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  title: {
    flexGrow: 1,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  vShow: {
    visibility: "visible",
  },
  vHide: {
    visibility: "hidden",
  },
  drawerPaper: {
    overflow: "hidden",
    width: drawerWidth,
    position: "absolute",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  },
  content: {
    overflowX: "auto",
    flexGrow: 1,
    width: "100%",
    padding: theme.spacing(1),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // marginRight: -drawerWidth,
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
      padding: theme.spacing(1),
    },
  },
  contentShift: {
    overflowX: "auto",
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
}));

const TableWithDrawer = ({
  drawer,
  table,
  title,
  handleDrawer,
  drawerClosed,
  customHeight,
  resetDrawerForm = () => {},
}) => {
  const [open, setOpen] = React.useState(false);
  const [resetFilter, setResetFilter] = React.useState(false);
  const {clearSelectedStreetAndSuburb} = useContext(ProspectsContext);
  useEffect(() => {
    setOpen(handleDrawer);
  }, [handleDrawer]);

  // console.log("drawer");
  const classes = useStyles();
  const theme = useTheme();

  // const handleDrawerOpen = () => {
  //   setOpen(true);
  // };

  const handleDrawerClose = () => {
    setOpen(false);
    drawerClosed();
  };
  const resetTableData = () => {
    resetDrawerForm({});
  };

  // const handleSearchChange = (event) => {
  //   if (event.target.value.length > 2) {
  //     // simulate getting search result
  //     setTimeout(() => {}, 500);
  //     return;
  //   }
  // };

  return (
    <Fragment>
      <Row>
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: open,
          })}
        >
          {table}
        </main>
        <Drawer
          className={clsx(classes.drawer, {
            [classes.vHide]: !open,
            [classes.vShow]: open,
          })}
          variant="persistent"
          anchor="right"
          open={open}
          classes={{
            paper: classes.drawerPaper + " shadow " + customHeight,
          }}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "ltr" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
            <div className="title">Filter {title}</div>
            {typeof drawer === "function" ? (
              <IconButton
                className="ml-auto"
                onClick={() => {
                  setResetFilter(!resetFilter);
                  handleDrawerClose();
                  resetTableData();
                  clearSelectedStreetAndSuburb();
                }}
                icon
              >
                <i className="fas fa-sync-alt fa-sm"></i>
              </IconButton>
            ) : (
              ""
            )}
          </div>
          <Divider />
          <div
            className={
              customHeight
                ? "container-fluid  mt-3 mb-4" + customHeight
                : "container-fluid page-drawer-body mt-3 mb-4"
            }
          >
            {typeof drawer === "function" ? drawer(resetFilter) : drawer}
          </div>
        </Drawer>
      </Row>
    </Fragment>
  );
};

export default TableWithDrawer;
