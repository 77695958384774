import React from "react";
import { Checkbox, FormControlLabel } from "@material-ui/core";

export default function ToggleIcon({
  input,
  icon,
  className,
  onChange,
  value,
  checkedIcon,
  name,
  checkBoxClassName,
  ...rest
}) {
  const handleOnChange = (e) => {
    if (typeof input !== "undefined") {
      input.onChange(e.target.checked ? 1 : 0);
    } else {
      onChange(e.target.checked ? 1 : 0);
    }
  };
  return (
    <FormControlLabel
      className={className}
      control={
        <Checkbox
          className={checkBoxClassName}
          checked={input?.value == 1 || value == 1 ? true : false}
          onClick={handleOnChange}
          icon={icon}
          checkedIcon={checkedIcon}
          name={input?.name}
          {...rest}
        />
      }
    />
  );
}
