import React, { Fragment, useState, useEffect } from "react";
import { Field, FieldArray } from "redux-form";
import { connect, useSelector } from "react-redux";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import _map from "lodash/map";
import _get from "lodash/get";
import { LinearProgress } from "@material-ui/core";

import ThemeButton from "../../../../partials/button/ThemeButton";
import ThemeHeader from "../../../../partials/content/ThemeHeader";
import Item from "../../../../partials/display/Item";
import TextArea from "../../../../partials/form/TextArea";
import TextInput from "../../../../partials/form/TextInput";
import ToggleSwitch from "../../../../partials/form/toggleSwitch/ToggleSwitch";
import ToolTip from "../../../../partials/tooltip/ToolTip";
import { getMenu } from "../../../../GeneralApi/GeneralApi";
import TextEditor from "../../../../partials/form/TextEditor";
import GroupIconInput from "../../../../partials/form/GroupIconInput";
import RenderMembers from "../../partials/homepageSetup/RenderMembers";
import { UserImageInputWithCropper } from "../../../../partials/form/UserImageInputWithCropper";
import SelectOptions from "../../../../partials/form/SelectOptions";
import { convertFormData } from "../../../../_helpers/_utils/utils";
import { updateOfficeDetails } from "../../../setting/_crud/settingCrud";

const SOCIAL_LINKS = [
  { placeholder: "Facebook", name: "facebook", img: "Facebook" },
  { placeholder: "LinkedIn", name: "linkedin", img: "LinkedIn" },
  { placeholder: "Instagram", name: "instagram", img: "Instagram" },
  { placeholder: "Youtube", name: "youtube", img: "Youtube" },
  { placeholder: "Twitter", name: "twitter", img: "Twitter" },
  { placeholder: "Google Tag Manager", name: "gtm", img: "GTM" },
  {
    placeholder: "Facebook Pixel",
    name: "facebook_pixel",
    className: "filter-saturate",
    img: "FacebookPixel",
  },
  {
    placeholder: "Google Review URL",
    name: "google_review_url",
    img: "google",
  },
];

const BUTTONS_SECTION = [
  { placeholder: "Auctions", name: "btn_auctions" },
  { placeholder: "Join Us", name: "btn_join_us" },
  { placeholder: "Sale Open Homes", name: "btn_sales_open_home" },
  { placeholder: "Rental Open Homes", name: "btn_rental_open_home" },
  {
    placeholder: "Rental Application Form",
    name: "btn_rental_application_form",
    hasInput: true,
  },
  { placeholder: "Notice to Repair", name: "btn_notice_to_repair" },
  {
    placeholder: "Request a Sale Appraisal",
    name: "btn_request_sale_appraisal",
  },
  {
    placeholder: "Request a Rental Appraisal",
    name: "btn_request_rental_appraisal",
  },
  { placeholder: "Notice to Vacate", name: "btn_notice_to_vacate" },
  {
    placeholder: "Book Holiday Accommodation",
    name: "btn_book_holiday_accomodation",
    hasInput: true,
  },
];

const HOME_FIELDS = [
  { label: "Page Title", name: "page_title", size: 6, component: TextInput },
  { label: "Sub-Title", name: "sub_title", size: 6, component: TextInput },
  { label: "Keywords", name: "keywords", size: 12, component: TextInput },
  {
    label: "Short Description",
    name: "short_description",
    size: 12,
    component: TextArea,
  },
];

const HomePageSetupForm = ({
  formValuesProps = {},
  handleSubmitCallback,
  handleLoadMore,
  organisation_id,
  officeDetailsLoading,
  submitting,
  pristine,
  invalid,
  otherResData,
  membersLoading,
  user,
}) => {
  const [typeOption, setTypeOption] = useState([]);
  const is_from_ho = useSelector((state) => state.auth?.user?.is_from_ho);
  useEffect(() => {
    let query = "24_hours";
    getMenu(query)
      .then(({ data: { data } }) => {
        setTypeOption(
          data["24_hours"].map((item) => ({
            ...item,
            label: item.name,
            value: item.name,
          }))
        );
      })
      .catch(() => {});
  }, []);

  // const updateMemberWebsiteOrder = member => {
  //   const formData = convertFormData(member);
  //   formData.append("_method", "PATCH");
  //   updateMember(member.id, formData)
  //     .then(res => {
  //       toast.success("Member order updated successfully");
  //     })
  //     .catch(err => {
  //       toast.error('Member order update Failed');
  //     })
  // }

  // const reOrderMembers = (list, startIndex, endIndex) => {
  //   const result = Array.from(list);
  //   const [removed] = result.splice(startIndex, 1);
  //   const [added] = result.splice(endIndex, 1);
  //   result.splice(endIndex, 0, removed);
  //   result.splice(startIndex, 0, added);
  //   return result.filter((elem) => elem != undefined);
  // };

  // const updateMembersPosition = (result, fields) => {
  //   let member_Order = reOrderMembers(fields.getAll(), result.source.index, result.destination.index);
  //   if (member_Order.length)
  //     setmemberDisplayOrder(member_Order.map((data) => data?.id));
  //   let srcMember = fields.get(result.source.index)
  //   let destMember = fields.get(result.destination.index)
  //   // source postion update
  //   updateMemberWebsiteOrder({ id: srcMember.id, website_display_order: result.destination.index + 1 })
  //   // destination postion update
  //   updateMemberWebsiteOrder({ id: destMember.id, website_display_order: result.source.index + 1 })
  // }

  const renderOpeningHours = ({ fields }) => {
    return fields.map((item, index) => {
      const { day, open, from, to } = fields.get(index);
      return (
        <Item
          col={6}
          md={6}
          lg={6}
          left={
            <Field
              name={`${item}.open`}
              component={ToggleSwitch}
              label={day}
              labelLeft={true}
              labelClass="flex-grow-1 p-1 "
              labelTrue="OPEN"
              labelFalse="CLOSED"
            />
          }
          right={
            <Fragment>
              {open ? (
                <Row style={{ height: 20 }}>
                  <Col lg={6}>
                    <Field
                      name={`${item}.from`}
                      value={from}
                      component={SelectOptions}
                      options={typeOption}
                    />
                  </Col>
                  <Col lg={6}>
                    <Field
                      name={`${item}.to`}
                      value={to}
                      component={SelectOptions}
                      options={typeOption}
                    />
                  </Col>
                </Row>
              ) : null}
            </Fragment>
          }
        />
      );
    });
  };

  const handleUpload = async (file) => {
    const isLte5M = file.size / 1024 / 1024 <= 5;
    if (!isLte5M) {
      toast.error("Maximum image size is 5MB!");
    } else {
      let data = { "files[]": file, _method: "PATCH" };

      return await new Promise((resolve, reject) => {
        const formData = convertFormData(data);
        updateOfficeDetails(organisation_id, formData)
          .then((res) => {
            if (_get(res, "data.data.assets.length")) {
              let fileValue = res.data.data.assets[
                res.data.data.assets.length - 1
              ]
                ? res.data.data.assets[res.data.data.assets.length - 1].url
                : "";
              if (fileValue) {
                resolve({ data: { link: fileValue } });
              } else {
                reject("file is not uploaded");
              }
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  };

  return (
    <div className="p-3 home-page-setup">
      {officeDetailsLoading && <LinearProgress />}
      <form onSubmit={handleSubmitCallback}>
        <ThemeHeader
          noScroll
          left={
            <Fragment>
              <h5 className="title">Homepage</h5>
            </Fragment>
          }
          right={
            <Fragment>
              <Fragment>
                <div className="pt-2">
                  <ToolTip
                    name="globe"
                    content="Globe"
                    onClick={() => {
                      var re = new RegExp("^(http|https)://", "i");
                      let site = _get(
                        user,
                        "organisation.office_website",
                        "https://wiseberry.com.au/"
                      );
                      if (!re.test(site)) {
                        site = `https://${user?.organisation?.office_website}/`;
                      }
                      window.open(site, "_blank");
                    }}
                  />
                </div>
                <div className="pl-2">
                  <ThemeButton className="lightGrey-button">Cancel</ThemeButton>
                </div>
                <div className="pl-2">
                  <ThemeButton
                    disabled={invalid || pristine || submitting}
                    type="submit"
                    className="green-button"
                  >
                    {submitting ? (
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : null}
                    Save
                  </ThemeButton>
                </div>
              </Fragment>
            </Fragment>
          }
        />
        <Row>
          <Col lg={6}>
            <Row>
              {_map(HOME_FIELDS, (item) => {
                return (
                  <Col lg={item.size}>
                    <Field
                      name={item.name}
                      component={item.component}
                      className="w-100"
                      label={item.label}
                    />
                  </Col>
                );
              })}
            </Row>
          </Col>
          {is_from_ho === 1 && (
            <Col lg={6}>
              <Row className="pl-5 mt-4">
                <Col style={{ fontWeight: 600 }} lg={2}>
                  Header Image :
                </Col>
                <Col>
                  <Field
                    name="display_image"
                    // roundedCircle={false}
                    // className="display-image-width"
                    // hide_mask={true}
                    placeholderImg="https://v2-wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/assets/placeholders/Image-Small.jpg"
                    component={UserImageInputWithCropper}
                    aspectRatio={3 / 2}
                    dropZoneStyle={{ width: "240px", height: "160px" }}
                  />
                </Col>
              </Row>
            </Col>
          )}
        </Row>
        <h6 className="marketingSubTitle pt-5">Opening Hours</h6>
        <Row className="pt-3 day-toggle">
          <Col lg={6} xl={6} className="home-opening-hours">
            <FieldArray name="opening_hours" component={renderOpeningHours} />
          </Col>
          {is_from_ho === 1 && (
            <Col lg={6} xl={6}>
              <Row className="pl-5 mt-4">
                <Col style={{ fontWeight: 600 }} lg={2}>
                  Display Image:
                </Col>
                <Col>
                  <Field
                    name="thumbnail_image"
                    placeholderImg="https://v2-wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/assets/placeholders/Image-Small.jpg"
                    component={UserImageInputWithCropper}
                    aspectRatio={3 / 2}
                    dropZoneStyle={{ width: "240px", height: "160px" }}
                  />
                </Col>
              </Row>
            </Col>
          )}
        </Row>
        <div className="editorMarketing">
          <h6 className="marketingSubTitle pt-5">Content</h6>
          {formValuesProps?.content !== undefined ? (
            <Field
              name="content"
              type="textarea"
              rows="6"
              options={{
                image: {
                  alt: { present: false, mandatory: false },
                  urlEnabled: true,
                  uploadEnabled: true,
                  previewImage: true,
                  alignmentEnabled: true,
                  inputAccept: "image/jpeg,image/jpg,image/png",
                  uploadCallback: handleUpload,
                },
              }}
              component={TextEditor}
              isContentHavingImage={true}
              {...(formValuesProps?.content?.length > 0 && {
                initialValues: formValuesProps?.content,
              })}
              placeholder="Comment about the offer"
            />
          ) : null}
          <h6 className="marketingSubTitle pt-5">Awards</h6>
          {/* {formValuesProps?.awards ? */}
          {formValuesProps?.awards !== undefined ? (
            <Field
              name="awards"
              type="textarea"
              rows="3"
              options={{
                image: {
                  alt: { present: false, mandatory: false },
                  urlEnabled: true,
                  uploadEnabled: true,
                  previewImage: true,
                  alignmentEnabled: true,
                  inputAccept: "image/jpeg,image/jpg,image/png",
                  uploadCallback: handleUpload,
                },
              }}
              component={TextEditor}
              isContentHavingImage={true}
              {...(formValuesProps?.awards?.length > 0 && {
                initialValues: formValuesProps?.awards,
              })}
              placeholder="Comment about the offer"
            />
          ) : null}
        </div>
        <h6 className="marketingSubTitle pt-5">Buttons</h6>
        <Row className="pt-3">
          {_map(BUTTONS_SECTION, (item, index) => (
            <Fragment>
              <Col lg={3} className="home-button">
                <Field
                  name={item.name}
                  component={ToggleSwitch}
                  label={item.placeholder}
                  labelLeft={true}
                  labelClass="flex-grow-1 p-1"
                  labelTrue="Show"
                  labelFalse="Hide"
                />
              </Col>
              {![2, 5, 8].includes(index) ? (
                <Col
                  lg={
                    item.name === "btn_book_holiday_accomodation"
                      ? 4
                      : [1, 4, 7].includes(index)
                      ? 2
                      : 1
                  }
                >
                  {item.hasInput && formValuesProps[item.name] ? (
                    <Field
                      component={TextInput}
                      placeholder="Enter URL"
                      name={
                        item.name === "btn_book_holiday_accomodation"
                          ? "book_holiday_accomodation_url"
                          : "rental_application_form_url"
                      }
                    />
                  ) : null}
                </Col>
              ) : null}
            </Fragment>
          ))}
        </Row>
        <h6 className="marketingSubTitle pt-5">Links & Settings</h6>
        <Row className="w-100">
          {_map(SOCIAL_LINKS, (item, index) => (
            <Fragment>
              <Col lg={5}>
                <div class="d-flex justify-content-start field-length">
                  <img
                    src={`/assets/images/links&settings/${item.img}.png`}
                    alt="text"
                    width="25"
                    height="25"
                    className={`mt-2 ${item?.className ?? ""}`}
                  />
                  <Field
                    placeholder={item.placeholder}
                    name={item.name}
                    className="field-length"
                    component={GroupIconInput}
                  />
                </div>
              </Col>
              {index % 2 === 0 ? <Col lg={2}></Col> : null}
            </Fragment>
          ))}
        </Row>
        <h6 className="marketingSubTitle pt-5">Profile Order</h6>
        <Row>
          <Container className="my-profile" fluid>
            {formValuesProps?.members && (
              <FieldArray
                // members={members}
                name="members"
                component={RenderMembers}
              />
            )}
          </Container>
        </Row>
        {otherResData?.next_page_url ? (
          <div className="d-flex justify-content-center pt-5">
            <ThemeButton onClick={handleLoadMore}>
              {membersLoading ? (
                <Spinner
                  className="mr-2"
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : null}
              Load More
            </ThemeButton>
          </div>
        ) : null}
      </form>
    </div>
  );
};
const mapState = (state) => ({
  user: state.auth.user,
});
export default connect(mapState)(HomePageSetupForm);
