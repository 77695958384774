import React, { useState, useEffect } from "react";
import "./propectmobile.css";
import { Button } from "@material-ui/core";
import { faChevronCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Field, getFormValues, reduxForm } from "redux-form";
import { connect } from "react-redux";
import {Link} from "react-router-dom"
import {
  getAllStreets,
  getAllSuburbs,
} from "../../../../GeneralApi/GeneralApi";
import { getAllProspects } from "../../curd/prospectsCrud";
import { formToQueryString } from "../../../../_helpers/_utils/utils";
import AsyncMultiSelect from "../../../../partials/form/AsyncMultiSelect";
import DefaultModal from "../../../../partials/modal/DefaultModal";
import ProspectMobileDetails from "./PropectMobileview";
import { toast } from "react-toastify";
import _ from "lodash";


const validate = (values) => {
  const errors = {};
  if (values.street_number_to) {
    if (!values.street_number_from) {
      errors.street_number_from = "Required";
    }
  }
  return errors;
};

const MobileSearch = (props) => {
  const { closeModal, formValues } = props;

  const [No, setNo] = useState(null);
  const [odd, setOdd] = useState(false);
  const [even, setEven] = useState(false);
  const [ascending, setAscending] = useState(false);
  const [descending, setDescending] = useState(false);
  const [showView, setShowView] = useState(false);
  const [more, setMore] = useState(0);
  const [mobileProspect, setMobileProspect] = useState([]);
  const [profileId, setProfile] = useState(null);
  const [prospectLoading, setProspectLoading] = useState(false);
  const [prospectQuery, setProspectQuery] = useState(null);
  const [otherResData, setOtherResData] = useState(null);
  const [autoSuburb, setAutoSuburb] = useState(null);
  const [surburbValue, setSurburbValue] = useState(null);

  // const getOptions = (text) => {
  //   let query = formToQueryString(
  //     { name: text, page: "prospect_filtering" },
  //     { name: 30 }
  //   );

  //   return getAllSuburbs(query)
  //     .then(({ data }) => {
  //       let options = data.data.map((item) => ({
  //         value: item.id,
  //         label: item.name + " - " + item?.state?.abbrev,
  //       }));
  //       return options;
  //     })
  //     .catch((e) => {
  //       // toast.success("Error while loading suburbs");
  //     });
  // };




  const getStreets = (text) => {
    let obj = {
      search_street: text,
      limit: 30,
      page: "prospect_filtering",
    };
    let query = formToQueryString(obj);
    return getAllStreets(query)
      .then(({ data }) => {
        setAutoSuburb(data);

        let options = data.data.map((item) => ({
          value: item.id,
          label: item.name + " - " + item.suburb.name,
          suburb_name: item.suburb.name,
          suburb_id: item.suburb.id,
          state_id: item.suburb.state.id,
          state_name: item.suburb.state.abbrev,
          postcode: item.suburb.postcode,
        }));
        return options;
      })
      .catch((e) => {});
  };

  useEffect(() => {
    if (!formValues?.street) {
      setSurburbValue(null);
    } else {
      setSurburbValue(
        autoSuburb?.data.filter(
          (item) =>
            item.id === (formValues?.street && formValues?.street?.value)
        )
      );
    }
  }, [formValues?.street]);


  const  isEven = (value)=> {
    if (value%2 == 0)
      return true;
    else
      return false;
  }

  const handleOnSubmit = (values = {}) => {
    values.preventDefault();

    if(even  && !isEven(No) ){
     return  toast.error( 'Please Enter Even  Street Number')
    }else if(odd  && isEven(No) ){
      return  toast.error( 'Please Enter Odd  Street Number')
    }
    
    setProspectLoading(true);
    let payload = {
      direction: descending ? "desc" : "asc",
      limit: 1000,
      sort_by: "street_number",
      sorting_options: descending ? "22" : "11",
      street: [formValues?.street?.value],
      // street_number_from: `${No}`,
      street_number_odd_even: !odd && !even ? " " : odd ? "odd" : "even",
      suburb: [surburbValue && surburbValue[0]?.suburb.id],
      zone: " ",
    };

    setProspectQuery(payload);

    let query = formToQueryString(payload);

    getAllProspects(query)
      .then((res) => {
        const { data, ...rest } = res.data;

        let mapData= data.map((item)=>{
          return item?.property?.address?.street_number
        })
let newIndex = _.findIndex(mapData, (e) => {
  return e == No
}, 0)



if(newIndex == -1){
  setProspectLoading(false);
 return  toast.error("Prospect Not Found ");
}
     
        setProfile(data[newIndex]?.id);
        setMore(newIndex)
        setMobileProspect(data);
        setProspectLoading(false);
        setShowView(true);

        setOtherResData(rest);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleOdd = () => {
    if (!odd) {
      setOdd(true);
      setEven(false);
    }
  };

  const handleEven = () => {
    if (!even) {
      setEven(true);
      setOdd(false);
    }
  };

  const handleAscending = () => {
    if (!ascending) {
      setAscending(true);
      setDescending(false);
    }
  };

  const handleDescending = () => {
    if (!descending) {
      setDescending(true);
      setAscending(false);
    }
  };

  const handleMobileClose = () => {
    setShowView(false);
  };

  const changeDirection = (direction) => {
    setProspectLoading(true);
    let index;
    let newId;
    index =
      mobileProspect &&
      mobileProspect.findIndex((item) => item.id === profileId);

    let newIndex;
    if (direction === "left") {
      newIndex = index - 1;
    }
    if (direction === "right") {
      newIndex = index + 1;
    }

    newId = mobileProspect[newIndex]?.id;

    if (newId) {
      setProfile(newId);
    }
  };

  return (
    <>
      <div
        id="kt_header_mobile"
        className="kt-header-mobile kt-header-mobile--fixed addedStyle"
      >
        <div className="kt-header-mobile__logo">
          <a href="/">
            <img
              alt="logo"
              src="https://v2-wiseberryonline-com-au.s3-ap-southeast-2.amazonaws.com/assets/Mobile-Logo.png"
            />
          </a>
        </div>
      </div>
      <div className="cover" style={{ marginTop: "5rem" }}>
        {showView ?

          <ProspectMobileDetails
              closeModal={() => handleMobileClose()}
              mobileProspect={mobileProspect}
              changeDirection={changeDirection}
              profileId={profileId}
              prospectLoading={prospectLoading}
              setProspectLoading={setProspectLoading}
              prospectQuery={prospectQuery}
              restData={otherResData}
              setMobileProspect={setMobileProspect}
              setRestData={setOtherResData}
              setProfile={setProfile}
              setMore={setMore}
              more={more}
            />
         : (
          <>
          <span  role="button" className="close-btn"  >
          <Link to="/prospects" >
          <FontAwesomeIcon
            icon={faChevronCircleLeft}
            size="1.7x"
            className="kt-font-brand"
          />
              </Link>
        </span>

        <div className="btn-container">
          <div className="btn-div">
            <button
              className="btnM m-2"
              size="large"
              style={{
                background: `${odd ? "#84052A" : "#fff"}`,
                color: odd && "#fff",
              }}
              onClick={() => {
                handleOdd();
              }}
            >
              ODD
            </button>
            <button
              className=" m-2 btnM"
              size="large"
              style={{
                background: `${even ? "#84052A" : "#fff"}`,
                color: even && "#fff",
              }}
              onClick={() => {
                handleEven();
              }}
            >
              EVEN
            </button>
          </div>
          {/* <div className="btn-div">
            <Button
              className=" m-2 btnM"
              size="large"
              style={{
                background: `${ascending ? "#84052A" : "#fff"}`,
                color: ascending && "#fff",
                textTransform: "capitalize",
              }}
              onClick={() => {
                handleAscending();
              }}
            >
              Ascending
            </Button>
            <Button
              className=" m-2 btnM"
              size="large"
              style={{
                background: `${descending ? "#84052A" : "#fff"}`,
                color: descending && "#fff",
                textTransform: "capitalize",
              }}
              onClick={() => {
                handleDescending();
              }}
            >
              Descending
            </Button>
          </div> */}
          <div className="search-text">
            <span> Search Street </span>
          </div>

          <div className="search-from">
            <form style={{ width: "100%" }} onSubmit={handleOnSubmit}>
              <input
                className="street-no"
                placeholder="No"
                type="number"
                onChange={(e) => setNo(e.target.value)}
              />
              <Field
                name="street"
                placeholder="Enter Street"
                component={AsyncMultiSelect}
                showBottom={false}
                isMulti={false}
                options={getStreets}
                className="mt-2 inputM2 "
              />

              {/* <Field
              name='suburbs'
              placeholder='Suburbs'
              component={AsyncMultiSelect}
              showBottom={false}
              isMulti={false}
              options={getOptions}
              className='mt-2 inputM2 '
            /> */}
              <input
                className="mt-2 inputM3"
                placeholder="Suburb"
                type="text"
                disabled
                defaultValue={surburbValue && surburbValue[0]?.suburb?.name}
                // onChange={(e) => setNo(e.target.value)}
              />

              <div className="search-btnM">
                <button
                  className=" m-2 search2"
                  size="large"
                  type="submit"
                  disabled={!formValues?.street?.value || !No}
                >
                  {prospectLoading ? "Searching..." : "Search"}
                </button>
              </div>
            </form>
          </div>
        </div>
        </>
        )}
     </div>
    </>
  );
};

function mapStateToProps(state, props) {
  const values = getFormValues("mobileSearch")(state);

  return {
    formValues: values,
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: "mobileSearch",
    touchOnChange: false,
    validate,
  })(MobileSearch)
);
