import React, { Fragment, useEffect, useState } from "react";
import { Accordion, Col, Form, Row } from "react-bootstrap";
import { Field } from "redux-form";
import { getMenu } from "../../../GeneralApi/GeneralApi";
import DateInput from "../../form/DateInput";
import MultiSelect from "../../form/MultiSelect";
import TextInput from "../../form/TextInput";
import CustomToggle from "../customToggle/CustomToggle";
import { InputAdornment } from "@material-ui/core";
import TextInputAmount from '../../../partials/form/TextInputAmount';

function ProspectDetails() {
  const [prospect_tags, setProspect_tags] = useState({});
  const [prospect_rating, setProspect_rating] = useState({});

  useEffect(() => {
    let query = "prospect_rating,tag";
    getMenu(query)
      .then(({ data: { data } }) => {
        setProspect_tags(data.tag);
        setProspect_rating(data.prospect_rating);
      })
      .catch(() => {});
  }, []);

  return (
    <Fragment>
      <CustomToggle eventKey="5" rightArrow={true} tabIndex={'-1'}>Prospect Details</CustomToggle>
      <Accordion.Collapse eventKey="5">
        <Form.Group as={Row}>
          <Col className="pb-2" sm={12}>
            <Field
              name="prospect_tags"
              label="Tags"
              placeholder="Tags"
              component={MultiSelect}
              showBottom={false}
              isMulti={true}
              options={prospect_tags}
            />
          </Col>
          <Col className="pb-2" sm={12}>
            <Field
              name="rating"
              label="Rating"
              placeholder="Rating"
              component={MultiSelect}
              showBottom={false}
              isMulti={false}
              options={prospect_rating}
            />
          </Col>
          <Col className="pb-1" sm={12}>
            <div className="row">
              <div className="col-6 pr-1">
                <Field
                  name="price_from"
                  // component={TextInput}
                  component={TextInputAmount}
                  label="From"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="col-6 pl-0">
                <Field
                  name="price_to"
                  // component={TextInput}
                  component={TextInputAmount}
                  label="To"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
          </Col>
          <Col className="pb-1" sm={12}>
            <div className="row">
              <div className="col-6 pr-1">
                <Field
                  type="date"
                  name="purchase_date_from"
                  component={DateInput}
                  placeholder="Purchase Date From"
                  label="Purchase Date From"
                />
              </div>
              <div className="col-6 pl-0">
                <Field
                  type="date"
                  name="purchase_date_to"
                  component={DateInput}
                  placeholder="Purchase Date To"
                  label="Purchase Date To"
                />
              </div>
            </div>
          </Col>
        </Form.Group>
      </Accordion.Collapse>
    </Fragment>
  );
}

export default ProspectDetails;
