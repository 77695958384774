import { Checkbox, FormControlLabel } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import { Form, Table } from "react-bootstrap";
import { Field } from "redux-form";
import Handle from "../../../../partials/component/handle/Handle";
import RadioInput from "../../../../partials/form/RadioInput";
import SortableTable from "../../../../partials/form/sortableTable/SortableTable";

const DuplicateContactsForm = (props) => {
  const { fields, data, mainContact } = props;

  const [selectedId, setSelectedId] = useState([]);
  const [checkAll, setCheckAll] = useState(false);
  useEffect(() => {
    if (mainContact) {
      fields.push(mainContact);
    }
    data.map((item, index) => {
      fields.push(item);
    });
  }, [data, mainContact]);

  const handleRemove = () => {
    for (let i = fields.length - 1; i >= 0; i--) {
      if (fields.get(i).selected) {
        fields.remove(i);
      }
    }
  };

  const handleSelectAllToggle = (e) => {
    const all = fields.getAll();
    const selected = e.target.checked;
    setCheckAll(selected);
    fields.removeAll();
    let selectAllArray = [];
    all.forEach((item, index) => {
      all[index].selected = selected;
      fields.push(item);
      selectAllArray.push(index);
    });
    if (selected) {
      setSelectedId(selectAllArray);
    } else {
      setSelectedId([]);
    }
  };
  const individualCheckBox = (event, index) => {
    let tempData = [];
    if (event) {
      setSelectedId([...selectedId, index]);
      tempData.push(...selectedId, index);
    } else {
      setSelectedId(selectedId.filter(val => val !== index))
    }
    if (fields?.length === tempData?.length) {
      setCheckAll(true);
    } else {
      setCheckAll(false);
    }
  }
  return (
    <div className="tableTheme feature_table theme-table-striped">
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>
              <FormControlLabel
                className="p-0 m-0"
                control={
                  <Checkbox
                    className="pt-0 pb-0 m-0"
                    onChange={handleSelectAllToggle}
                    name="checkToggle"
                    checked={checkAll}
                  />
                }
                label="Priority"
              />
            </th>
            <th>Name</th>
            <th>Phone</th>
            <th>Email</th>
          </tr>
        </thead>

        <SortableTable fields={fields}>
          {fields.map((item, index) => {
            console.log("item_id", fields?.get(index)?.id);

            return (
              <Fragment key={index}>
                <td>
                  <Field
                    name={`${item}.selected`}
                    component={RadioInput}
                    onChange={(event) => individualCheckBox(event, index)}
                    type="checkbox"
                    label={index + 1}
                  />
                </td>
                <td>
                  {fields?.get(index)?.first_name}{" "}
                  {fields?.get(index)?.last_name}
                </td>
                <td>
                  {fields?.get(index)?.phones[0]?.value
                    ? fields?.get(index)?.phones[0].value
                    : ""}
                </td>
                <td>
                  <div className="d-flex pr-2 justify-content-between">
                    {fields?.get(index)?.emails[0]?.value ? (
                      fields?.get(index)?.emails[0].value
                    ) : (
                      <p></p>
                    )}
                    <Handle />
                  </div>
                </td>
              </Fragment>
            );
          })}
        </SortableTable>
      </Table>
    </div>
  );
};

export default DuplicateContactsForm;
