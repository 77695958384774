/* eslint-disable no-restricted-imports */
import React, { useState, Fragment } from "react";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  useRowSelect,
} from "react-table";
import { makeStyles } from "@material-ui/styles";
import { Spinner } from "react-bootstrap";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    overflowX: "auto",
    height: 300,
    flexGrow: 1,
  },
  head: {
    backgroundColor: theme.palette.brand.main,
    color: "#fff",
    position: "sticky",
    fontSize: ".6rem",
    top: 0,
  },
  tableHead: {
    border: "3px solid " + theme.palette.brand.main,
    overflow: "auto",
    height: "50vh",
  },
  table: {
    minWidth: 700,
    height: 90,
  },
  tableCell: {
    fontSize: ".6rem",
  },
}));

function Table({ columns, data, update, handleChange }) {
  const classes = useStyles();
  // Use the state and functions returned from useTable to build your UI

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    preGlobalFilteredRows,
    setGlobalFilter,
    prepareRow,
    state: { sortBy, globalFilter, selectedRowIds },
  } = useTable(
    {
      columns,
      data,
    },

    useGlobalFilter, // useGlobalFilter!
    useSortBy,
    useRowSelect
  );

  React.useEffect(() => {
    console.log("sort");
  }, [sortBy]);

  return (
    <Fragment>
      <div className={"tableFixHead theme-table-striped " + classes.tableHead}>
        <table {...getTableProps()} className="table">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell, i) => {
                    return (
                      <td key={i} {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Fragment>
  );
}

function BuyerDetailsTable({ handleChange, data }) {
  const [items, setItems] = useState(data ? data : null);

  const columns = React.useMemo(
    () => [
      {
        Header: "Details",
        columns: [
          {
            Header: "Feature",
            accessor: "feature",
            Cell: ({ cell: { value } }) => <p>{value}</p>,
          },
          {
            Header: "Preference",
            accessor: "preference",
            Cell: ({ cell: { value } }) => <p>{value}</p>,
          },
          {
            Header: "Comments",
            accessor: "comments",
            Cell: ({ cell: { value } }) => <p>{value}</p>,
          },
        ],
      },
    ],
    []
  );

  // const datas = React.useMemo(() => items, [items]);

  return (
    <Fragment>
      {items ? (
        <Table
          className="buyerDetails-table w-100"
          columns={columns}
          data={data}
          handleChange={handleChange}
        />
      ) : (
        <Spinner animation="grow" size="sm" />
      )}
      \{" "}
    </Fragment>
  );
}

export default BuyerDetailsTable;
