/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React , { Component, useEffect, PureComponent }  from "react";
import clsx from "clsx";
import { getContactsData, getListingsData  } from "../curd/globalSearch";
import SearchDropdownContact from "./SearchDropdownContact";
import SearchDropdownListing from "./SearchDropdownListing";
import SearchDropdownBuyer from "./SearchDropdownBuyer";

export default class SearchResult extends Component {
  state = {
    contacts: [],
    listings: []
  }
  componentDidMount() {

    
    this.setState({
      // contacts: getContactsData(),
      listings: getListingsData()
    });
  }
   
  render() {
    const { contacts, listings } = this.state;
    const { data } = this.props;
console.log(contacts);
    if (!data) {
      return null;
    }

    if (data.length === 0) {
      return (
        <div className="kt-quick-search__result">
          <div className="kt-quick-search__message kt-hidden">
            No record found
          </div>
        </div>
      );
    }

    return (
      <div className="kt-quick-search__result">
        <SearchDropdownContact searchValue={this.props.searchValue}  data={contacts} />
        <SearchDropdownBuyer searchValue={this.props.searchValue}  />
        <SearchDropdownListing searchValue={this.props.searchValue}  />
      </div>
    );
  }
}
