import Swal from "sweetalert2";

import { sendBuyerAppPromoMarketing } from "../modules/buyers/crud/buyersCrud";

const useSendBuyerAppPromo = () => {
  const sendBuyersAppPromo = (type, contacts, onCallback) => {
    if (contacts?.length) {
      let htmlContent = "";

      for (let iContact = 0; iContact < 10; iContact++) {
        if (contacts[iContact]) {
          htmlContent += `<li class="text-left">${contacts[iContact]?.primary_contact?.first_name} ${contacts[iContact]?.primary_contact?.last_name}</li>`;
        }
      }

      if (contacts?.length > 10) {
        htmlContent += `<li class="text-left" style="list-style: none; margin-left: -14px; margin-top: 5px;">+ ${contacts?.length -
          10} buyers</li>`;
      }

      Swal.fire({
        title: `Buyer App Promotional ${type}`,
        html: `This will send the ${type} to the below contacts.<br /><br />
              <ul>
                ${htmlContent}
              </ul>
              <br />Do you confirm?
            `,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#2bb673",
        cancelButtonColor: "#e0e0e0",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        customClass: {
          cancelButton: "order-1",
          confirmButton: "order-2",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          sendBuyerAppPromoMarketing({
            selected_ids: contacts.map((item) => item.id),
            type: type,
          })
            .then((res) => {
              //   console.log("okey response", res);
              Swal.fire(`Promotional ${type} sent!`);

              console.log("hell yeahh", onCallback);

              if (onCallback) onCallback();
            })
            .catch((err) => {
              //   console.log(err);
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
              });
            });
        }
      });
    }
  };

  return sendBuyersAppPromo;
};

export default useSendBuyerAppPromo;
