import React, { Fragment, useEffect, useState } from "react";
import { Image, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic";
import { getContactById } from "../../../modules/contacts/crud/contactsCrud";
import ThemeButton from "../../button/ThemeButton";
import { CharacterCircle } from "../../component/characterCircle/CharacterCircle";
import { IconButton } from "@material-ui/core";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import PublicIcon from "@material-ui/icons/Public";
import CellPhone from "../../table/cells/CellPhone";
import ContactType from "../../table/cells/ContactType";
import DefaultModal from "../../modal/DefaultModal";
import ContactProfileModal from "../../../modules/contacts/modals/contactProfileModal/ContactProfileModal";

const AnnMemberPopover = (props) => {
  const { id, profileData, showContactForm = false, setShowContact } = props;
  const [data, setData] = useState(null);
  const [showContactProfile, setShowContactProfile] = useState(false);

  useEffect(() => {
    // console.log("called" + state.contactProfileId);
    if (profileData) {
      setData(profileData);
    } else {
      getContactById(id)
        .then(({ data }) => {
          setData(data.data);
          console.log(data);
        })
        .catch(() => {});
    }
  }, []);
  return (
    <Fragment>
            {showContactProfile ? (
        <DefaultModal
          size="xl"
          centered
          show={showContactProfile}
          onHide={() => setShowContactProfile(false)}
        >
          <ContactProfileModal
            contactId={id}
            // contactList={contacts}
            // continuousMode={contacts.continuousMood}
            closeModal={() => setShowContactProfile(false)}
          />
        </DefaultModal>
      ) : (
        ""
      )}
      {console.log(data)}
      <div className="container">
        <div className="row">
          <div className="userPopOver  member-popover dropShadow">
            <div className="d-flex p-2 pt-3 pb-3 mb-3 darkBlue-background">
              <div className="col-5 rel dropShadow">
                <div className="avatar">
                  <Image
                    src={
                      data?.profile_picture
                        ? data?.profile_picture
                        : toAbsoluteUrl("/media/users/default.jpg")
                    }
                  />
                </div>
              </div>
              <div className="col-7 m-auto dropShadow">
                <h6 className={`font-weight-bold ${showContactForm ? 'cursor-pointer' : ''}`} onClick={() => {if(showContactForm) setShowContact(true)}}>
                  {data?.contact?.first_name} {data?.contact?.last_name}
                </h6>
                <p>{data?.profession}</p>
                <p>{data?.company_name}</p>
                {/* <p>{id}</p> */}
              </div>
              {/* <div className="contact-edit-icon">
              <ThemeButton icon onClick={() => setShowContactProfile(true)}><i className="fas fa-user-edit"></i></ThemeButton>
              </div> */}
             
            </div>
            <div className="col rubyColor profileDetails ">
            {/* Print All phone number with different type icon */}
              {data?.contact?.phones?.map((obj) => (
                <Fragment>
                  <div className="row my-2 mx-auto">
                    <div className="col-2 text-right border-right m-auto border-dark">
                    {obj?.type?.id == 1 ?  <i className="fas fa-phone-alt"></i> : ''}
                    {obj?.type?.id == 2 ?  <i className="fas fa-home"></i> : ''}
                    {obj?.type?.id == 3 ?  <i className="fas fa-building"></i> : ''}
                    {obj?.type?.id == 4 ?  <i className="fas fa-fax"></i> : ''}
                    </div>
                    <div className="col-10 pl-4">
                      <a
                        href={`tel:${obj?.value}`}
                      >
                       <CellPhone value={obj} /> 
                      </a>
                    </div>
                  </div>
                </Fragment>
              ))}
            {/* Print All phone number with different type icon */}
              <div className="row my-2 mx-auto">
                <div className="col-2 text-right m-auto border-right border-dark">
                  <i className="far fa-envelope"></i>
                </div>
                <div className="col-10 pl-4">
                  <a
                    href={`email:${data?.contact?.emails?.[0]?.value}`}
                  >
                    {data?.contact?.emails?.[0]?.value}
                  </a>
                </div>
              </div>
              <div className="p-3 contact-socialButtons">
                {data?.public ? (
                  <IconButton
                    className="darkGrey-icon-button"
                    aria-label="facebook.com"
                    onClick={() =>
                      window.open(
                        "https://www.facebook.com/" + `${data?.facebook}`
                      )
                    }
                  >
                    <PublicIcon />
                  </IconButton>
                ) : (
                  ""
                )}

                {data?.facebook ? (
                  <IconButton
                    aria-label="facebook.com"
                    className="darkGrey-icon-button"
                    onClick={() =>
                      window.open(
                        "https://www.facebook.com/" + `${data?.facebook}`
                      )
                    }
                  >
                    <FacebookIcon />
                  </IconButton>
                ) : (
                  ""
                )}

                {data?.instagram ? (
                  <IconButton
                    aria-label="instagram.com"
                    className="darkGrey-icon-button"
                    onClick={() =>
                      window.open(
                        "https://www.instagram.com/" + `${data?.instagram}`
                      )
                    }
                  >
                    <InstagramIcon />
                  </IconButton>
                ) : (
                  ""
                )}

                {data?.twitter ? (
                  <IconButton
                    aria-label="twitter.com"
                    className="darkGrey-icon-button"
                    onClick={() =>
                      window.open(
                        "https://www.twitter.com/" + `${data?.twitter}`
                      )
                    }
                  >
                    <TwitterIcon />
                  </IconButton>
                ) : (
                  ""
                )}

                {data?.linkedin ? (
                  <IconButton
                    aria-label="Linkedin.com"
                    className="darkGrey-icon-button"
                    onClick={() =>
                      window.open(
                        "https://www.Linkedin.com/" + `${data?.linkedin}`
                      )
                    }
                  >
                    <LinkedInIcon />
                  </IconButton>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="row justify-content-center p-3">
              <ContactType value={data?.types} />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AnnMemberPopover;
