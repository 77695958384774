import React from "react";
import ToggleButton from "@material-ui/lab/ToggleButton";
import "./_toggleButtons.scss";

export default function ToggleSingleButton({ input, label, className }) {
  const handleSelect = () => {
    input.onChange(input.value === 1 ? 0 : 1);
  };

  return (
    <ToggleButton
      fontSize="small"
      className={className}
      selected={input.value === 1 ? true : false}
      onChange={handleSelect}
      value={input.value === 1 ? true : false}
    >
      {label}
    </ToggleButton>
  );
}
