import React, { Fragment, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Field } from "redux-form";
import { IconButton } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DefaultModal from "../../modal/DefaultModal";
import AddLinkedContacts from "../../../modules/contacts/modals/addLinkedContacts/AddLinkedContacts";
import TextInput from "../TextInput";
import { getMenu } from "../../../GeneralApi/GeneralApi";
import MultiSelect from "../MultiSelect";
import ThemeButton from "../../button/ThemeButton";

const FormLinkedContacts = ({ fields, fullButton, fieldArray }) => {
  const [showContactModal, setShowContactModal] = useState(false);
  const handleOnSelect = (e) => {
    // let selectedValue = e.map( s => ({label:s.name, value: s.id}) )
    e.map((item, index) => fields.push({ id: item.id, name: item.first_name }));
  };
  const [options, setOptions] = useState({});

  useEffect(() => {
    let query = "relationship";
    getMenu(query)
      .then(({ data: { data } }) => {
        console.log(data);
        setOptions(data.relationship);
      })
      .catch(() => {});
  }, []);

  return (
    <Fragment>
      {showContactModal ? (
        <DefaultModal
          size="xl"
          centered
          show={showContactModal}
          onHide={() => setShowContactModal(false)}
        >
          <AddLinkedContacts
            closeModal={() => setShowContactModal(false)}
            onSelect={handleOnSelect}
          />
        </DefaultModal>
      ) : (
        ""
      )}

      <Col sm={12}>
        <h5 className="title">
          Linked Contacts{" "}
          {fullButton ? (
            ""
          ) : (
            <IconButton aria-label="delete" size="small">
              <AddCircleIcon className="brand-text" fontSize="small" />
            </IconButton>
          )}{" "}
          <span></span>
        </h5>
        {fullButton ? (
          <ThemeButton
            onClick={() => setShowContactModal(true)}
            className="darkGrey-button w-100 font-weight-bold"
          >
            Add Contacts
          </ThemeButton>
        ) : (
          // <button
          //   type="button"
          //   className="btn btn-lg btn-block btn-full darkGrey-button text-white"
          //   onClick={() => setShowContactModal(true)}
          // >
          //   Add Contacts
          // </button>
          ""
        )}
        <div className="linked-contacts pt-3">
          {fields.map((member, index) => (
            <Row className="pb-2">
              <Col className="col-5 mt-auto">
                <Field
                  name={`${member}.relationship`}
                  component={MultiSelect}
                  showBottom={false}
                  isMulti={false}
                  defaultValue
                  options={options}
                  placeholder="Select Relationship"
                />
              </Col>
              <Col className="col-5 mt-auto">
                <Field
                  edit={false}
                  name={`${member}.name`}
                  type="text"
                  component={TextInput}
                  label="Name"
                />
              </Col>
              <Col className="col-2 m-auto">
                <IconButton
                  aria-label="delete"
                  size="small"
                  onClick={() => fields.remove(index)}
                >
                  <i className="fas fa-minus-circle"></i>
                </IconButton>
              </Col>
            </Row>
          ))}
        </div>
      </Col>
    </Fragment>
  );
};

export default FormLinkedContacts;
