import React, { Fragment, useRef, useState } from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  OverlayTrigger,
  Popover,
  Spinner,
} from "react-bootstrap";
import { Field, reduxForm, formValueSelector } from "redux-form";
import Picker from "emoji-picker-react";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

import ThemeHeader from "../../content/ThemeHeader";
import ThemeButton from "../../button/ThemeButton";
import MobileView from "./MobileView";
import { sendSms } from "../../../GeneralApi/GeneralApi";
import TextInput from "../../form/TextInput";
import ListingSearchPopover from "../../popover/listingSearch/ListingSearchPopover";
import TextArea from "../../form/TextArea";
import DefaultModal from "../../modal/DefaultModal";
import AgentsPopover from "../../popover/agentsPopver/AgentsPopover";
import ListingPopover from "../../popover/listingPopover/ListingPopover";
import SmsCounterTooltip from "../../SmsCounterTooltip/SmsCounterTooltip";

const Sms = (props) => {
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);

  const [showListing, setShowListing] = useState(false);
  const [targetListing, setTargetListing] = useState(null);

  const ref = useRef(null);
  const {
    handleSubmit,
    sms,
    queryString,
    data,
    // totalRecipient,
    change,
    pristine,
    invalid,
    submitting,
    // phoneData,
    attached,
    hasDefaultNumbers,
  } = props;
  // const [placeHolder, setPlaceHolder] = useState(null);
  // const [phoneNumbers, setPhoneNumbers] = useState("");
  const [showAddListing, setShowAddListing] = useState(false);
  console.log(data)
  // useEffect(() => {
  //   let numbers = props.isAllSelected
  //     ? ""
  //     : props.data
  //       .map((a) => a?.phones)
  //       .map((a) => a?.[0]?.value)
  //       .join(", ");
  //   let formPlaceholder = isAllSelected
  //     ? `Total ${totalRecipient} people selected`
  //     : data.length > 0
  //       ? numbers
  //       : "Please Enter Phone Number with coma separated";
  //   setPlaceHolder(formPlaceholder);
  //   // let numbers = isAllSelected
  //   //   ? ""
  //   //   : data.map((a) => a.phones).map((a) => a[0].value);
  //   // setPhoneNumbers(numbers ? numbers.join(", ") : "");
  //   // setPhoneNumbers(numbers);
  //   // document.getElementById("phone_number").value = ;
  // }, []);

  const handleOnSubmit = async (values) => {
    console.log("handleOnSubmit", values);
    if (!hasDefaultNumbers && !values.additional_numbers) {
      toast.warn("Please Fill Mobile number(s)");
      return;
    } else if (!values?.message) {
      toast.warn("Please Fill Sms content");
      return;
    }
    let unProcessedData = {};
    unProcessedData.sms_attributes = {};
    unProcessedData.sms_attributes.additional_numbers =
      values.additional_numbers;
    unProcessedData.sms_attributes.message = values.message;
    unProcessedData.sms_attributes.contact_ids = data
      .map((a = {}) => a?.id || a?.Contact_ID)
      .filter((item) => item);

    return await sendSms(queryString ?? "", unProcessedData)
      .then(({ data: { data } }) => {
        props.closeModal();
        toast.success("Sms Sent Successfully");
      })
      .catch(() => {
        toast.error("Sms Sent Failed");
      });
  };

  const handleSelectedListing = (listing, url) => {
    change(
      "message",
      sms ? sms + listing + ": " + url + "\n" : listing + ": " + url + "\n"
    );
  };

  const handleSelectedAgents = (name, phone) => {
    change(
      "message",
      sms ? sms + name + "\n" + phone + "\n" : name + "\n" + phone + "\n"
    );

    //     change("message", sms ? `${sms},
    //      ${name} ,
    //       ${phone} ` :
    //       (`${name},
    //  ${phone} `));
  };

  const onEmojiClick = (event, emojiObject) => {
    change("message", sms ? sms + emojiObject.emoji : emojiObject.emoji);
  };

  const handleClick = (event) => {
    setShow(!show);
    setTarget(event.target);
  };

  const handleClickListing = (event) => {
    setShowListing(!showListing);
    setTargetListing(event.target);
  };

  // const popover = (
  //   <Popover
  //     data-container=".ui-front"
  //     id="popover-basic-sms"
  //     style={{ top: "40px" }}
  //   >
  //     <ListingPopover handleSelectedListing={handleSelectedListing} />
  //   </Popover>
  // );

  // const popoverListing = (
  //   <Popover
  //     data-container=".ui-front"
  //     id="popover-basic-sms"
  //     style={{ top: "40px" }}
  //   >
  //     <AgentsPopover />
  //   </Popover>
  // );

  const onChange = (e) => {
    let regex = /(^[0-9,+]+$)+/g;
    if (!regex.test(e.target.value) && e.target.value.length) {
      e.preventDefault();
    }
  };

  const closeSmsModal = () => {
    Swal.fire({
      title: "Warning",
      text: "Are you sure want to exit?",
      icon: "warning",
      showCancelButton: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
      confirmButtonColor: "#A7A9AC",
      cancelButtonColor: "#2BB673",
      confirmButtonText: "Cancel",
      cancelButtonText: "Yes",
    }).then((result) => {
      if (result.isDismissed) {
        props.closeModal();
      }
    });
  };
  const { handleSubmitForm = handleOnSubmit } = props;

  return (
    <Fragment>
      {showAddListing && (
        <DefaultModal
          size="xl"
          show={showAddListing}
          onHide={() => setShowAddListing(false)}
        >
          <ListingSearchPopover
            onHide={() => setShowAddListing(false)}
            // closeModal={() => {
            //   console.log("closemb", showAddNew);
            //   setShowAddNew(false);
            //   console.log("closema", showAddNew);
            // }}
          />
        </DefaultModal>
      )}
      <div className="mobile-view sms-view">
        <ThemeHeader
          left={
            <Fragment>
              <h5 className="title">Preview and Send SMS</h5>
            </Fragment>
          }
          right={
            <Fragment>
              <div className="pl-2">
                <ThemeButton
                  className="lightGrey-button"
                  onClick={closeSmsModal}
                >
                  Cancel
                </ThemeButton>
              </div>
              <div className="pl-2">
                <form
                  className="sms-form "
                  onSubmit={handleSubmit(handleSubmitForm)}
                >
                  <ThemeButton
                    type="submit"
                    disabled={invalid || pristine || submitting}
                    className="green-button"
                  >
                    {submitting ? (
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      ""
                    )}
                    Send
                  </ThemeButton>
                </form>
              </div>
            </Fragment>
          }
        />

        <Row className="pt-2">
          <Col lg={6}>
            {hasDefaultNumbers ? (
              <div className="w-100 pointer-none">
                <Field
                  name="to"
                  component={TextArea}
                  className="scroll-hide"
                  // disabled
                  label="Sms to"
                />
              </div>
            ) : null}
            <div className="d-flex justify-content-between mt-3">
              <div className="w-100">
                <Field
                  name="additional_numbers"
                  component={TextInput}
                  label={hasDefaultNumbers ? "Additional number(s)" : "SMS to"}
                  onChange={(event) => onChange(event)}
                />
              </div>

              <div className="d-flex align-self-end">
                <div ref={ref}>
                  <OverlayTrigger
                    trigger={"click"}
                    placement="auto"
                    rootClose
                    container={ref.current}
                    overlay={
                      <Popover style={{ maxWidth: "60%" }}>
                        <ListingPopover
                          handleSelectedListing={handleSelectedListing}
                        />
                      </Popover>
                    }
                  >
                    <ThemeButton onClick={handleClick} icon>
                      {" "}
                      <i className="fas fa-home"></i>{" "}
                    </ThemeButton>
                  </OverlayTrigger>
                </div>
                <div ref={ref}>
                  <OverlayTrigger
                    trigger={"click"}
                    placement="auto"
                    rootClose
                    container={ref.current}
                    overlay={
                      <Popover style={{ minWidth: "60%" }}>
                        <AgentsPopover
                          handleSelectedListing={handleSelectedAgents}
                        />
                      </Popover>
                    }
                  >
                    <ThemeButton onClick={handleClickListing} icon>
                      <i className="fas fa-mobile-alt"></i>
                    </ThemeButton>
                  </OverlayTrigger>
                </div>

                {/* 
                <OverlayTrigger
                  trigger="click"
                  rootClose
                  placement="bottom"
                  overlay={popoverListing}
                >
                  <ThemeButton onClick={handleClickListing} icon>
                    {" "}
                    <i className="fas fa-phone    "></i>{" "}
                  </ThemeButton>
                </OverlayTrigger> */}

                <OverlayTrigger
                  trigger="click"
                  placement="right"
                  rootClose
                  overlay={
                    <Popover>
                      <Picker onEmojiClick={onEmojiClick} />
                    </Popover>
                  }
                >
                  <ThemeButton icon>
                    <i className="far fa-smile"></i>
                  </ThemeButton>
                </OverlayTrigger>
              </div>
            </div>
            <form className="sms-form mt-2">
              <SmsCounterTooltip message={sms} />

              <Field
                name="message"
                type="textarea"
                rows="3"
                component={TextArea}
                placeholder="Comment about the offer"
                returnHtml={false}
                initialValues={attached}
              />
            </form>
          </Col>
          <Col lg={6}>
            <MobileView sms={sms} />
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

const actions = {
  // closeModal: modal.actions.closeModal,
  // tasksAdd: diary.actions.addTask,
  // tasksUpdate: diary.actions.deleteTask,
};

const selector = formValueSelector("sms-preview");

const mapState = (state, props) => {
  const { data = [], hasDefaultNumbers = true, message } = props;
  const sms = selector(state, "message");
  console.log("props ++", props);
  const filterNumbers = (number) => {
    return !(/^02|^03|^07|^2|^9/.test(number)) && number.length > 9 && number.length < 11;
  };
  let defaultNumbers = hasDefaultNumbers
      ? [...new Set(data
          ?.map((item = {}) => {
            return item?.Phone || item?.phones?.[0]?.value || item?.value;
          })
          .filter((item) => item)
          .filter(filterNumbers)  // Apply the filter here
       )]
      : [];
  return {
    sms: sms,
    initialValues: {
      to: defaultNumbers ? defaultNumbers.join(", ") : "",
      additional_numbers: "",
      message,
    },
  };
};

Sms.defaultProps = {
  queryString: "",
  totalRecipient: "",
  data: [],
  hasDefaultNumbers: true,
};
export default connect(
  mapState,
  actions
)(reduxForm({ form: "sms-preview", enableReinitialize: true })(Sms));
