import React, { useState, createContext, useEffect } from "react";

import { useDispatch } from "react-redux";

import { getTenantById } from "../../crud/tenantsCrud";
import * as tenantsRedux from "../../_redux/tenantsRedux";

export const TenantProfileContext = createContext();
export const { Consumer } = TenantProfileContext;

const TenantProfileContextProvider = (props) => {
  const { selectedTenantsId, tenants } = props;
  const [profile, setProfile] = useState(null);
  const [tenantId, setTenantId] = useState(null);
  const [tenantIndex, setTenantIndex] = useState(null);
  const [continuousMode, setContinuousMode] = useState(false);
  const [tenantLoading, setTenantLoading] = useState(false);

  // const [buyerList, setBuyerList] = useState([{}]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedTenantsId) {
      setTenantId(selectedTenantsId);
    }
  }, [selectedTenantsId]);
  useEffect(() => {
    if (tenants && tenantId) {
      let index = tenants.findIndex((item) => item.id === profile.id);
      setTenantIndex(index);
      setTenantLoading(false);
    }
  }, [profile]);

  useEffect(() => {
    if (tenantId) {
      update();
    }
  }, [tenantId]);

  const update = () => {
    getTenantById(tenantId)
      .then(({ data: { data } }) => {
        setProfile(data);
      })
      .catch(() => {});
  };

  const changeDirection = (direction) => {
    setTenantLoading(true);
    let index = tenants?.findIndex((item) => item.id === profile.id);
    // yield delay(2000);
    let newIndex;
    if (direction === "left") {
      newIndex = index - 1;
    }
    if (direction === "right") {
      newIndex = index + 1;
    }
    let newId = tenants[newIndex].id;

    setTenantId(newId);
  };

  const tenantsTableUpdate = (data) => {
    dispatch(tenantsRedux.actions.tenantsLoading(data));
  };

  return (
    <TenantProfileContext.Provider
      value={{
        updateContext: update,
        tenantsTableUpdate,
        tenants,
        profile,
        tenantSelectedChangeId: changeDirection,
        selectedTenantsId: tenantId ? tenantId : selectedTenantsId,
        continuousMode,
        setContinuousMode,
        update,
        setTenantLoading,
        tenantLoading,
        filters: props.filters
      }}
    >
      {props.children}
    </TenantProfileContext.Provider>
  );
};

export default TenantProfileContextProvider;
