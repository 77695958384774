import React, { Fragment, useEffect } from "react";
// import TenantDetailsView from "./TenantDetailsView";
import AccessControl from "../../../../../_ accessControl/AccessControl";
import TenantDetailsEdit from "./TenantDetailsEdit";
import { getTenantProcessedInfo } from "../../../../../_helpers/_utils/utils";
import TenantDetailsView from "./TenantDetailsView";
import { useSelector } from "react-redux";

const TenantDetails = ({ profile }) => {
  const permissions = useSelector((state) => state.auth.user.permissions);

  // useEffect(() => {
  //   getTenantById(2)
  //     .then(({ data }) => {
  //       console.log(data);
  //     })
  //     .catch(() => {});
  // }, []);

  return (
    <Fragment>
      <AccessControl
        allowedPermissions={["tenants-edit"]}
        renderNoAccess={() => (
          <TenantDetailsView profile={getTenantProcessedInfo(profile)} />
        )}
      >
        {" "}
        <TenantDetailsEdit profile={getTenantProcessedInfo(profile)} />
      </AccessControl>
    </Fragment>
  );
};

export default TenantDetails;
