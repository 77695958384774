import React, { Fragment } from "react";
import { IconButton } from "@material-ui/core";
import { ReactComponent as MapMarker } from "../../../_metronic/_assets/media/icons/Map/Marker1.svg";
import { OverlayTrigger, Popover } from "react-bootstrap";

const Marker = () => {
  const popover = (
    <Popover id="popover-basic">
      <Popover.Title as="h3">Popover right</Popover.Title>
      <Popover.Content>
        And here's some <strong>amazing</strong> content. It's very engaging.
        right?
      </Popover.Content>
    </Popover>
  );
  return (
    <Fragment>
      <OverlayTrigger rootClose trigger="click" placement="right" overlay={popover}>
        <IconButton
          className="marker-button"
          onClick={() => console.log("clicked")}
        >
          {/* <i className="fas fa-home"></i> */}
          <MapMarker className="svg-icon svg-icon--brand" />
        </IconButton>
      </OverlayTrigger>
    </Fragment>
  );
};

export default Marker;
