import { useEffect, useRef } from "react";
export const useHasChangedValue = (val) => {
  // console.log(val);
  const prevVal = usePreviousValue(val);
  // console.log(prevVal);
  if (prevVal !== val) {
    return prevVal;
  }
};

export function usePreviousValue(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  // console.log("previous_", ref.current);
  return ref.current;
}

export default useHasChangedValue;

