import React, { Fragment, useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Field, FormSection } from "redux-form";
import { useSelector } from "react-redux";
import { Divider, InputAdornment } from "@material-ui/core";
import { toast } from "react-toastify";
import moment from "moment";

import MultiSelect from "../../../../partials/form/MultiSelect";
import DateInput from "../../../../partials/form/DateInput";
import TextInput from "../../../../partials/form/TextInput";
import { getAllMembers, getMenu } from "../../../../GeneralApi/GeneralApi";
import ToggleSwitch from "../../../../partials/form/toggleSwitch/ToggleSwitch";
import ThemeButton from "../../../../partials/button/ThemeButton";
import DefaultModal from "../../../../partials/modal/DefaultModal";
import AdjustPrice from "../adjustPrice/AdjustPrice";
import { number } from "../../../validation/Validation";

let periodMap = {
  4: "w",
  5: "M",
  6: "y",
};

const AddNewListingDetails = ({
  showAdjustPrice,
  edit,
  status,
  contract_in_office,
  custom_marketing,
  tenanted,
  lead_source,
  changeProp,
  starts,
  term_of_agreement,
  term_of_agreement_period,
  type,
}) => {
  const [rental_status, setRental_status] = useState({});
  // const [agreement_type, setAgreement_type] = useState({});
  const [rental_type, setRental_type] = useState({});
  const [ranking, setRanking] = useState({});
  const [marketing_package, setMarketing_package] = useState({});
  const [tag, setTag] = useState({});
  const [lead_sources, setLead_sources] = useState({});
  const [property_type, setProperty_type] = useState({});
  const [lived_in, setLived_in] = useState({});
  const [lifestyle, setLifestyle] = useState({});
  const [motivation_ranking, setMotivation_ranking] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [date_time_unit, setDate_time_unit] = useState({});
  const [rental_payment_frequency, setRental_payment_frequency] = useState({});
  const associated_offices =
    useSelector((state) => state.auth?.user?.accessible_organisations) ?? [];
  const [property_category, setProperty_category] = useState({});

  const landPreference = [
    {
      name: "Suburb Only",
      id: "0",
    },
    {
      name: "Full Address",
      id: "1",
    },
  ];

  useEffect(() => {
    let query =
      "rental_status,rental_type,property_category,motivation_ranking,tag,marketing_package,lead_source,ranking,property_type,listing_lived_in,listing_lifestyle,date_time_unit,rental_payment_frequency";
    getMenu(query)
      .then(({ data: { data } }) => {
        setRental_status(data.rental_status);
        setProperty_category(data.property_category);
        setRental_type(data.rental_type);
        setTag(data.tag);
        setMotivation_ranking(data.motivation_ranking);
        setLead_sources(data.lead_source);
        setRanking(data.ranking);
        setLifestyle(data.listing_lifestyle);
        setLived_in(data.listing_lived_in);
        setProperty_type(data.property_type);
        setMarketing_package(data.marketing_package);
        setDate_time_unit(
          data.date_time_unit.filter((obj) => [4, 5, 6].includes(obj.id))
        );
        setRental_payment_frequency(data.rental_payment_frequency);
      })
      .catch(() => {});
  }, []);

  const [members, setMembers] = useState(null);

  useEffect(() => {
    getAllMembers()
      .then(({ data: { data } }) => {
        setMembers(
          data.map((s) => ({
            name: `${s.initial}`,
            id: s.contact.id,
            optionTooltipTitle: `${s?.contact?.first_name ?? ""} ${s?.contact
              ?.last_name ?? ""}`,
          }))
        );
      })
      .catch((e) => {
        toast.error("Error while fetching");
      });
  }, []);

  const showOnWebsiteOnChange = () => {
    if (contract_in_office) {
      return true;
    } else {
      toast.warning(
        "Agreement In Office must be enabled to enable this feature",
        { autoClose: 5000, closeButton: false }
      );
      return false;
    }
  };

  const handleContractInOfficeChange = (val) => {
    if (!val) {
      changeProp("show_on_website", 0);
    }
  };

  useEffect(() => {
    calculateEndDate();
  }, [term_of_agreement_period, term_of_agreement, starts]);

  const calculateEndDate = () => {
    if (term_of_agreement_period && term_of_agreement && starts) {
      // !==0  - other from periodic
      let calculatedEndDate = moment(starts, "YYYY-MM-DD")
        .add(term_of_agreement, periodMap[term_of_agreement_period])
        .format("YYYY-MM-DD");
      changeProp("ends", calculatedEndDate);
    }
  };

  return (
    <Fragment>
      {modalShow ? (
        <DefaultModal
          size="xl"
          show={modalShow}
          onHide={() => setModalShow(false)}
        >
          <AdjustPrice onHide={() => setModalShow(false)} />
        </DefaultModal>
      ) : (
        ""
      )}
      <h5 className="title">Rental</h5>
      <Row className="pb-3">
        <Col lg={4}>
          <Field
            name="status"
            label="Status"
            component={MultiSelect}
            showBottom={false}
            isMulti={false}
            options={rental_status}
          />
        </Col>
        <Col lg={4}>
          <Field
            name="type"
            label="Type"
            component={MultiSelect}
            showBottom={false}
            isMulti={false}
            options={rental_type}
          />
        </Col>
        {type === 2 && (
          <Col lg={4}>
            <Field
              name="booking_url"
              component={TextInput}
              placeholder="Booking URL"
              label="Booking URL"
              // validate={[number]}
            />
          </Col>
        )}
        <Col className="pb-1 pt-2" lg={4}>
          <div className="row">
            {showAdjustPrice && edit ? (
              <Col>
                <ThemeButton
                  onClick={() => setModalShow(true)}
                  className="darkGrey-button w-100"
                >
                  Adjust Rent
                </ThemeButton>
              </Col>
            ) : (
              <Fragment>
                <div className="col-6 pr-1">
                  <Field
                    name="rent_amount"
                    component={TextInput}
                    placeholder="Rent"
                    label="Rent"
                    validate={[number]}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                  />
                </div>

                <div className="col-6 pl-0">
                  <Field
                    name="payment_frequency"
                    component={MultiSelect}
                    className="w-100"
                    showBottom={false}
                    isMulti={false}
                    options={rental_payment_frequency}
                    label=" "
                  />
                </div>
              </Fragment>
            )}
          </div>
        </Col>
        <Col className="pb-1" lg={4}>
          <Field
            name="price_view"
            component={TextInput}
            placeholder="Public View"
            label="Public View"
            // validate={[number]}
          />
        </Col>
        <Col className="pb-1" lg={4}>
          <Field
            name="bond"
            validate={[number]}
            component={TextInput}
            label="Bond"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
        </Col>
        <Col lg={4}>
          <Field
            label="Available From"
            name="available_from"
            type="date"
            component={DateInput}
          />
        </Col>
        <Col lg={4} className="pb-1 mt-auto  pt-md-2">
          <Field
            name="show_on_website"
            component={ToggleSwitch}
            customOnChange={showOnWebsiteOnChange}
            label="Show on Website:"
            labelLeft={true}
            labelClass="flex-grow-1 p-1"
          />
        </Col>
        <Col className="pb-3 pb-lg-1 " lg={4}>
          <Field
            name="important_notice"
            component={TextInput}
            placeholder="Important Notice"
            label="Important Notice"
          />
        </Col>
        <Col className="pb-3 pb-lg-1 " lg={4}>
          <Field
            name="conditions"
            component={TextInput}
            placeholder="Conditions"
            label="Conditions"
          />
        </Col>
        <Col lg={4}>
          <Field
            name="organisation"
            label="Organisation"
            component={MultiSelect}
            showBottom={false}
            isMulti={false}
            options={associated_offices}
          />
        </Col>
        <Col lg={4}>
          <FormSection name="property">
            <Field
              name="category"
              label="Property Category"
              component={MultiSelect}
              showBottom={false}
              isMulti={false}
              options={property_category}
            />
          </FormSection>
        </Col>
        <Col className="pb-1 mt-auto" lg={4}>
          <Field
            name="price_display_portal"
            component={ToggleSwitch}
            label="Display Price Portal:"
            labelLeft={true}
            labelClass="flex-grow-1 p-1"
          />
        </Col>
        `
        <Col>
          <Field
            name="show_full_address"
            label="Display"
            component={MultiSelect}
            showBottom={false}
            isMulti={false}
            options={landPreference}
          />
        </Col>
        `
        <Col className="pb-1" lg={4}>
          <Field
            label="Rea Rental Id"
            name="rea_listing_id"
            type="text"
            component={TextInput}
          />
        </Col>
        <Col className="pb-1" lg={4}>
          <Field
            label="Domain Rental Id"
            name="domain_listing_id"
            type="text"
            component={TextInput}
          />
        </Col>
      </Row>

      <Divider className="divider" variant="middle" />
      <h5 className="title">Property Manager</h5>
      <Row className="pb-3">
        <Col lg={4}>
          <Field
            name="property_manager"
            label="Property Manager"
            component={MultiSelect}
            showBottom={false}
            isMulti={false}
            options={members}
          />
        </Col>
        <Col lg={4}>
          <Field
            name="bdm"
            label="BDM"
            component={MultiSelect}
            showBottom={false}
            isMulti={false}
            options={members}
          />
        </Col>
        <Col lg={4}>
          <Field
            name="lead_source"
            label="Lead Source"
            component={MultiSelect}
            showBottom={false}
            isMulti={false}
            options={lead_sources}
          />
        </Col>
      </Row>

      <Divider className="divider" variant="middle" />
      <h5 className="title">Lease Agreement</h5>
      <Row className="pb-3">
        <Col lg={4} className="pb-1 mt-auto">
          <Field
            name="tenanted"
            component={ToggleSwitch}
            label="Tenanted:"
            labelLeft={true}
            labelClass="flex-grow-1 p-1"
          />
        </Col>
        <Col lg={4}>
          <div className="row">
            <div className="col-6 pl-0">
              <Field
                validate={[number]}
                name="term_of_agreement"
                component={TextInput}
                showErrorOnMount={true}
                label={<div className="text-truncate">Term of Agreement</div>}
              />
            </div>{" "}
            <div className="col-6 pr-1">
              <Field
                name="term_of_agreement_period"
                component={MultiSelect}
                className="w-100"
                showBottom={false}
                isMulti={false}
                options={date_time_unit}
                label=" "
              />
            </div>
          </div>
        </Col>
        <Col lg={4}>
          <Row>
            <Col className="pb-1" sm={12}>
              <div className="row">
                <div className="col-6 pr-1">
                  <Field
                    name="starts"
                    label="Start Date"
                    type="date"
                    component={DateInput}
                  />
                </div>
                <div className="col-6 pl-0">
                  <Field
                    name="ends"
                    label="End Date"
                    type="date"
                    component={DateInput}
                    disabled={term_of_agreement_period === 0}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      <Divider className="divider" variant="middle" />
      <h5 className="title">Property Management Agreement</h5>
      <Row className="pb-3">
        <Col lg={4} className="pb-1 mt-auto">
          <Field
            name="contract_in_office"
            component={ToggleSwitch}
            onChange={handleContractInOfficeChange}
            label="Agreement In Office:"
            labelLeft={true}
            labelClass="flex-grow-1 p-1"
          />
          {contract_in_office ? (
            <Field name="agreement_date" type="date" component={DateInput} />
          ) : (
            ""
          )}
        </Col>
        <Col lg={4}>
          <Field
            name="management_fee"
            validate={[number]}
            component={TextInput}
            label="Management Fee"
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
          />
        </Col>
        <Col lg={4}>
          <Field
            validate={[number]}
            name="letting_fee"
            component={TextInput}
            label="Letting Fee"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
        </Col>
        <Col lg={4}>
          <Field
            validate={[number]}
            name="marketing_fee"
            component={TextInput}
            label="Marketing Fee"
            InputProps={{
              startAdornment: <InputAdornment start="end">$</InputAdornment>,
            }}
          />
        </Col>
        <Col lg={4}>
          <Field
            validate={[number]}
            name="renewal_fee"
            component={TextInput}
            label="Renewal Fee"
            InputProps={{
              startAdornment: <InputAdornment start="end">$</InputAdornment>,
            }}
          />
        </Col>
        <Col lg={4}>
          <Field
            validate={[number]}
            name="lease_preparation_fee"
            component={TextInput}
            label="Lease Preparation Fee"
            InputProps={{
              startAdornment: <InputAdornment start="end">$</InputAdornment>,
            }}
          />
        </Col>
        <Col lg={4}>
          <Field
            name="tribunal_fee"
            validate={[number]}
            component={TextInput}
            label="Tribunal Fee"
            InputProps={{
              startAdornment: <InputAdornment start="end">$</InputAdornment>,
            }}
          />
        </Col>
        <Col lg={4}>
          <Row>
            <Col lg={6}>
              {" "}
              <Field
                name="other_fees"
                validate={[number]}
                component={TextInput}
                label="Other Fee"
                InputProps={{
                  startAdornment: (
                    <InputAdornment start="end">$</InputAdornment>
                  ),
                }}
              />
            </Col>
            <Col lg={6}>
              <Field
                name="other_fees_description"
                component={TextInput}
                label="Description"
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Divider className="divider" variant="middle" />
      <h5 className="title">Additional</h5>
      <Row className="pb-3">
        <Col lg={4}>
          <FormSection name="property">
            <Field
              name="type"
              label="Property Type"
              component={MultiSelect}
              showBottom={false}
              isMulti={false}
              options={property_type}
            />
          </FormSection>
        </Col>
        <Col lg={4} className="pb-1 mt-auto  pt-md-2">
          <Field
            name="is_vacant"
            component={ToggleSwitch}
            label="Vacant"
            labelLeft={true}
            labelClass="flex-grow-1 p-1"
          />
        </Col>
        <Col lg={4} className="pb-1 mt-auto  pt-md-2">
          <Field
            name="is_furnished"
            component={ToggleSwitch}
            label="Furnished"
            labelLeft={true}
            labelClass="flex-grow-1 p-1"
          />
        </Col>
        <Col lg={4} className="pb-1 mt-auto  pt-md-2">
          <Field
            name="is_pet_friendly"
            component={ToggleSwitch}
            label="Pet Friendly"
            labelLeft={true}
            labelClass="flex-grow-1 p-1"
          />
        </Col>
        <Col lg={4} className="pb-1 mt-auto  pt-md-2">
          <Field
            name="is_smoking_allowed"
            component={ToggleSwitch}
            label="Smoking Allowed"
            labelLeft={true}
            labelClass="flex-grow-1 p-1"
          />
        </Col>
        <Col lg={4} className="pb-1 mt-auto">
          <Field
            name="is_water_usage"
            component={ToggleSwitch}
            label="Water Usage"
            labelLeft={true}
            labelClass="flex-grow-1 p-1"
          />
        </Col>
        <Col lg={4}>
          <Field name="key_number" component={TextInput} label="Key Number" />
        </Col>{" "}
        <Col lg={4}>
          <Field
            Field
            name="alarm_number"
            component={TextInput}
            label="Alarm Number"
          />
        </Col>
      </Row>
    </Fragment>
  );
};

AddNewListingDetails.defaultProps = {
  showAdjustPrice: false,
  edit: true,
};

export default AddNewListingDetails;
