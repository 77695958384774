import React, { Fragment } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { IconButton } from "@material-ui/core";
import SingleAddress from "./SingleAddress";

const Address = ({
  fields,
  meta: { error, submitFailed, form },
  icon,
  label = "",
  showIcon,
  isTwoRow,
  singleData,
}) => {
  return (
    <Fragment>
      {fields.map((address, index) => (
        <Form.Group key={index} as={Row} className="mb-0">
          <Col sm={label.length == 0 ? 12 : 12}>
            <SingleAddress
              showIcon={showIcon}
              isTwoRow={isTwoRow}
              values={fields.get(index)}
              icon={
                fields.get(index)?.type == 1 ? (
                  <i className="fas fa-home    "></i>
                ) : fields.get(index)?.type == 2 ? (
                  <i className="fas fa-building"></i>
                ) : (
                  <i className="fas fa-shipping-fast"></i>
                )
              }
              fields={fields}
              formName={form}
              fieldArray={address}
              index={index}
            />
          </Col>
        </Form.Group>
      ))}
      {singleData || fields.length > 1 ? (
        " "
      ) : (
        <Row>
          <p>
            <IconButton
              onClick={() =>
                fields.push({
                  type: 3,
                  street_name: {},
                  suburb: {},
                  state: {},
                })
              }
              aria-label="delete"
              size="small"
              className="brand-icon-button pr-2"
              tabIndex={'-1'}
            >
              <i className="fas fa-plus-circle"></i>
            </IconButton>
            Add alternative postal address
          </p>
        </Row>
      )}
    </Fragment>
  );
};

Address.defaultProps = {
  singleData: false,
  showIcon: true,
  isTwoRow: false,
};

export default Address;
