import React, { useState, Fragment, useContext, useRef } from "react";
import { LinearProgress, Button } from "@material-ui/core";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import moment from "moment";
import _debounce from 'lodash/debounce';
import _isEmpty from 'lodash/isEmpty';
import _cloneDeep from "lodash/cloneDeep";
import {useSelector} from "react-redux";
import DynamicTableEdit from "../../../../partials/table/DynamicTableEdit";
import ThemeHeader from "../../../../partials/content/ThemeHeader";
import ThemeButton from "../../../../partials/button/ThemeButton";
import DefaultModal from "../../../../partials/modal/DefaultModal";
import AccessControl from "../../../../_ accessControl/AccessControl";
import ToolTip from "../../../../partials/tooltip/ToolTip";
import TableFilter from "../../../../partials/table/TableFilter";
import SocialShare from "../../../../partials/component/speedDial/SocialShare";
import DigitalTemplateSetting from "../../modals/digitalTemplate/DigitalTemplateSetting";
import { DigitalTemplateContext } from "../../pages/digitalTemplate/DigitalTemplateContextProvider";
// import LoadingSkeleton from "../../../../partials/display/skeleton/LoadingSkeleton";
import { downloadImageFromUrl } from "../../../../_helpers/_utils/utils";
import Email from "../../../../partials/component/email/Email";
import SmsWrap from "../../../../partials/component/sms/SmsWrap";
import axios from "axios";

function DigitalTemplateTable({
  setOpen,
  template,
  fetchMoreData,
  handleGlobalSearch,
  setShowTemplateForm,
}) {
  const {
    handleTemplateDelete,
    socialMediaTemplateLoading,
    setSelectedSocialTemplate,
    category,
  } = useContext(DigitalTemplateContext);
  const [selectedRows, setSelectedRows] = useState(false);
  const [showSetting, setShowSetting] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [showSms, setShowSms] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [fbPagedetails, setFbPagedetails] = useState(null);
  const [fbModal, setFbmodal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userChecked, setUserChecked] = useState([]);
  const [imagUrl, setimageURL] = useState(null);
  const previewData = useRef({ data: "" });

  const userId = useSelector(({ auth }) => auth?.user?.id);

  const rowClick = (value) => {
    setShowTemplateForm(true);
    setSelectedSocialTemplate(value);
  };

  const downloadOrPreviewImage = (imgString, download, fileName) => {
    if (!download) {
      previewData.current.data = imgString;
      setShowPreview(true);
    } else {
      // setFileName(value?.name)
      downloadImageFromUrl(imgString, fileName);
    }
  };

  const renderPreviewandDownload = (original) => {
    let imgString = original?.assets.filter((elem) => elem.extension != "")[original.assets.filter((elem) => elem.extension != "").length - 1]?.url ?? ""
    return (
      imgString ?
        <>
          <ThemeButton icon className="darkGrey-icon-button" onClick={() => downloadOrPreviewImage(imgString, false, )}>
            <i className="fas fa-eye"></i>
          </ThemeButton>
          <ThemeButton icon onClick={() => downloadOrPreviewImage(imgString, true,original?.name)}>
            <i className="fas fa-download"></i>{" "}
          </ThemeButton>
          <ThemeButton icon>
            <SocialShare
              url={imgString}
              title={original?.name ?? ""}
              smsFunction={() => { previewData.current.data = imgString; setShowSms(true); }}
              emailFunction={() => { previewData.current.data =`{{${imgString}}}`; setShowEmail(true); }}
              clipBoardFunction={() => { navigator.clipboard.writeText(imgString); toast.success("Link copied") }}
              setFbPagedetails={setFbPagedetails}
              setFbmodal={setFbmodal}
              setLoading={setLoading}
              setimageURL={setimageURL}
            />
          </ThemeButton>
        </>
        :
        <></>
    )
  }

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        columns: [
          {
            Header: "Date",
            width: 25,
            accessor: "created_at",
            Cell: ({ cell: { value } }) => (
              <p className="text-center">
                {moment(value).format("DD/MM/YYYY")}
              </p>
            ),
          },
          {
            Header: "Template",
            accessor: "category",
            Cell: ({
              cell: {
                value,
                row: { original },
              },
            }) => (
              <p onClick={() => rowClick(original)} className="cursor-hand">
                {value?.name ?? ""}
              </p>
            ),
          },
          {
            Header: "Title",
            accessor: "name",
            Cell: ({
              cell: { value },
              cell: {
                row: { original },
              },
            }) => (
              <div className="d-flex justify-content-between">
                <div onClick={() => rowClick(original)} className="cursor-hand">
                  <p>{original?.name ?? ""}</p>
                </div>
                <div className="d-flex">
                  {renderPreviewandDownload(original)}
                </div>
              </div>
            ),
          },
        ],
      },
    ],
    []
  );

  const onSearch = _debounce(handleGlobalSearch, 500);

  // const check = function (r) {
  //   // debugger
  //   const { userAgent = '' } = window.navigator|| {};
  //   const ua = userAgent.toLowerCase();
  //   return r.test(ua);
  // };

  // const getBrowserStyle = () => {
  //   const style = {};

  //   if (check(/opera/)) {// Opera
  //     style.imageRendering = '-o-crisp-edges';
  //   } else if (check(/firefox/)) {// Firefox
  //     style.imageRendering = '-moz-crisp-edges';
  //   } else if (check(/chrome/) || check(/webkit/)) {// Chrome
  //     style.imageRendering = '-webkit-optimize-contrast';
  //   } else {
  //     style.imageRendering = 'crisp-edges'
  //   }
  //   style.imageRendering = 'pixelated'
  //   return style;
  // };
  // const imgStyle = getBrowserStyle();




  const handleChecked = (e,index) => {
    // newState[index].checked = e.target.checked;
    console.log('===========handleChecked=========================');
    console.log(e.target.value);
    console.log(e.target.checked);
    console.log('============handleChecked========================');
    // debugger
    let ids= e.target.value
let item = userChecked[index]
    if(e.target.checked){
      setUserChecked((userChecked) => [...userChecked, ids]);
    }else{
      // debugger
      const removeItem = userChecked.findIndex((obj) => {
        return obj == item;
      });

      if(removeItem != -1){
        let newState =_cloneDeep( [...userChecked]);
        newState.splice(removeItem, 1);
        setUserChecked(newState);
      }

    }
   
    // setUserChecked((prev) =>{
    //   return [...prev.userChecked, e.target?.value]
    // });

    // setarr(item.Icon,e, item.checked)
  };

  const  selectedPages1=    fbPagedetails?.filter(item => userChecked.includes(item.id));
  console.log('===========fbPagedetails=========================');
  console.log(fbPagedetails);
  console.log(userChecked);
  console.log(selectedPages1);
  console.log(imagUrl);
  console.log(loading);
  console.log('===========fbPagedetails=========================');
  

  const handlePost= async()=>{
     const  selectedPages=   fbPagedetails?.filter(item => userChecked.includes(item.id))
     console.log('===========handlePost=========================');
     console.log(selectedPages);
     console.log('===========handlePost=========================');
      setLoading(true)
     const  promises = selectedPages?.map( async page => {
  // debugger
     let res =   await axios.post(`https://graph.facebook.com/${page?.id}/photos?url=${imagUrl}&access_token=${page?.access_token}`)
     return res;
      });

      console.log('===========promises=========================');
      console.log(promises);
      console.log(loading);
      console.log('===========promises=========================');
  await Promise.all(promises);
  setUserChecked([])
      setLoading(false)
      setFbmodal(false)
 
  }

  return (
    <div className="p-3">
      {showSetting ? (
        <DefaultModal
          size="xl"
          centered
          show={showSetting}
          onHide={() => setShowSetting(false)}
        >
          <DigitalTemplateSetting closeModal={() => setShowSetting(false)} />
        </DefaultModal>
      ) : null}

      {fbModal &&
        <Modal
          centered
          // size="sm"
          className="full-width  modal-stretch"
          show={fbModal}
          onHide={() => setFbmodal(false)}
        >
          <Modal.Header closeButton className="pt-1 pb-1" style={{paddingTop: '2px', paddingRight: 5, display:'flex', justifyContent:'center', alignItems:'center' }}></Modal.Header>
          <Modal.Body >
          <div style={{
             height: "100%",
    width:"auto",
    // padding:"2.5%",
    background: '#fff',
          }}>
          <div style={{marginTop: '-1rem', paddingRight: 5, display:'flex', justifyContent:'center', alignItems:'center' , fontSize: 18, fontWeight: 'bolder',paddingBottom:20}}>Select Facebook Page</div>
          <div>{loading&& <LinearProgress />}</div>
      {   fbPagedetails?.map((fbpage,i)=>{
return(

<label
                  htmlFor={`check-boxlist-${i}`}
                  className=" "
                  style={{ display:'flex', justifyContent:'flex-start', alignItems:'center' , fontSize: 14, fontWeight: 500}}
                >
                  <input
                    type="checkbox"
                    id={`check-boxlist-${i}`}
                    // className=" check-box  appearance-none h-8 w-8 border-2 rounded-lg border-burgundy"
                    onChange={(e) => handleChecked(e,i)}
                    // checked={item?.checked || false}
                    style={{ marginRight: 5, fontSize: 18, fontWeight: 'bolder'}}
                    value={fbpage.id}
                  />
                  {/* <Check stroke="burgundy" stroke-width="6" className={` text-[4rem] h-8 w-8 text-white absolute left-0 top-0 ${toggle == true ? 'text-opacity-100 bg-burgundy' : 'text-opacity-100'}  check-1  transition-all ease-in-out delay-150`} /> */}
                  {fbpage.name}
                </label>
              
)
         })
}
<div style={{marginTop: '-1rem', paddingRight: 5, display:'flex', justifyContent:'center', alignItems:'center' , fontSize: 18, fontWeight: 'bolder', paddingTop:30}} >
                {/* <button onClick={()=>handlePost()}>Post</button> */}
                <ThemeButton
                onClick={() => handlePost()}
                className="green-button text-white font-weight-bold"
                disabled={_isEmpty(userChecked)}
              >
                {" "}
                Post
              </ThemeButton>
                </div>
          </div>
          
          </Modal.Body>
        </Modal>}
      {showPreview &&
        <Modal
          centered
          // size="sm"
          className="full-width  modal-stretch"
          show={showPreview}
          onHide={() => setShowPreview(false)}
        >
          <Modal.Header
            closeButton
            className="pt-1 pb-1"
            style={{ paddingTop: "2px", paddingRight: 5 }}
          ></Modal.Header>
          <Modal.Body>
            <div
              style={{
                height: "100%",
                width: "auto",
                // padding:"2.5%",
                background: "#fff",
              }}
            >
              <img
                src={
                  previewData.current.data !== ""
                    ? previewData.current.data
                    : "/media/listings/wiseberry_default_property.jpg"
                }
                // width="1080px"
                // height="1080px"
                alt="Wiseberry default property"
                style={{
                  imageRendering: "auto",
                  objectFit: "cover",
                  width: "100%",
                  height: "100%",
                }}
              />
            </div>
          </Modal.Body>
        </Modal>
      }

      {showSms ? (
        <DefaultModal
          size="xl"
          centered
          show={showSms}
          onHide={() => setShowSms(false)}
        >
          <SmsWrap
            closeModal={() => setShowSms(false)}
            data={[]}
            hasDefaultNumbers={false}
            message={previewData.current.data}
          />
        </DefaultModal>
      ) : null}

      {showEmail ? (
        <DefaultModal
          size="xl"
          centered
          show={showEmail}
          onHide={() => setShowEmail(false)}
        >
          <Email
            closeModal={() => setShowEmail(false)}
            totalRecipient={0}
            message={previewData.current.data}
            data={[]}
            hasDefaultEmails={false}
          />
        </DefaultModal>
      ) : (
        ""
      )}

      <ThemeHeader
        className="p-1"
        noScroll
        left={
          <>
            <h5 className="title  max-w">Social Media Templates</h5>
            {
              <AccessControl
                allowedPermissions={["mail-setup"]}
                renderNoAccess={() => <></>}
              >
                <ToolTip
                  name="cog"
                  content="Setting"
                  onClick={() => setShowSetting(true)}
                  className="m-auto"
                />
              </AccessControl>
            }

            {/* <IconButton className="ml-auto" onClick={() => updateTable()} icon>
              <i className="fas fa-sync-alt fa-sm"></i>
            </IconButton> */}
          </>
        }
        right={
          <>
            {userId !== 194764 ? (
              <>
                <div className="p-2">
                  <TableFilter
                    length={template.length}
                    handleGlobalSearch={onSearch}
                  />
                </div>
                <div className="p-2">
                  <ThemeButton
                    className="darkGrey-button text-white font-weight-bold"
                    onClick={(e) => setOpen(true)}
                  >
                    Filter
                  </ThemeButton>
                </div>
                <div className="p-2">
                  <ThemeButton
                    onClick={() => handleTemplateDelete(selectedRows)}
                    className={`${
                      _isEmpty(selectedRows) || socialMediaTemplateLoading
                        ? "pointer-events-none"
                        : ""
                    } lightGrey-button font-weight-bold`}
                    disabled={
                      _isEmpty(selectedRows) || socialMediaTemplateLoading
                    }
                  >
                    Delete
                  </ThemeButton>
                </div>
                <div className="p-2">
                  <ThemeButton
                    onClick={() => setShowTemplateForm(true)}
                    className="green-button text-white font-weight-bold"
                    disabled={_isEmpty(category)}
                  >
                    Add
                  </ThemeButton>
                </div>
              </>
            ) : null}
          </>
        }
      />
      {socialMediaTemplateLoading ? <LinearProgress /> : null}

      <div className="dynamic-table">
        <DynamicTableEdit
          handleChange={(e) => setSelectedRows(e)}
          isEditable={true}
          columns={columns}
          data={template}
          disableSort={false}
          update={fetchMoreData}
          customColumnWidth={true}
        />
      </div>
    </div>
  );
}

export default DigitalTemplateTable;
