import React, { Fragment } from "react";
import { CharacterCircle } from "../../component/characterCircle/CharacterCircle";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
const ContactType = ({ value }) => {
  return (
    <Fragment>
      <div className="d-flex">
        {value?.map((item) => {
          return (
            <Fragment>
              <AvatarGroup>
                {item.name.includes("Buyer") ? (
                  <CharacterCircle small background="buyer" character="B" />
                ) : (
                  ""
                )}
                {item.name.includes("Tenant") ? (
                  <CharacterCircle small background="tenant" character="T" />
                ) : (
                  ""
                )}
                {item.name.includes("Landlord") ? (
                  <CharacterCircle small background="landlord" character="L" />
                ) : (
                  ""
                )}
                {item.name.includes("Seller") ? (
                  <CharacterCircle small background="seller" character="S" />
                ) : (
                  ""
                )}
                {item.name.includes("Prospect") ? (
                  <CharacterCircle small background="prospect" character="P" />
                ) : (
                  ""
                )}
                {item.name.includes("Servicer") ? (
                  <CharacterCircle small background="servicer" character="SV" />
                ) : (
                  ""
                )}
              </AvatarGroup>
            </Fragment>
          );
        })}
      </div>
    </Fragment>
  );
};

export default ContactType;
