import React, { Fragment, useContext, useState } from "react";
import { connect } from "react-redux";
import * as modals from "../../../../_modal/_redux/modalRedux";
import * as contactsRedux from "../../../_redux/contactsRedux";
import ThemeHeader from "../../../../../partials/content/ThemeHeader";
import ThemeButton from "../../../../../partials/button/ThemeButton";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import EmailIcon from "@material-ui/icons/Email";
import EventIcon from "@material-ui/icons/Event";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import DefaultModal from "../../../../../partials/modal/DefaultModal";
import Sms from "../../../../../partials/component/sms/Sms";
import Email from "../../../../../partials/component/email/Email";
import CallReminder from "../../../../../partials/component/callReminder/CallReminder";
import { ContactProfileContext } from "../ContactProfileContextProvider";
import Appointment from "../../../../diary/partials/modals/appointment/Appointment";
import moment from "moment";
import SmsWrap from "../../../../../partials/component/sms/SmsWrap";
import ToolTipForSvg from "../../../../../partials/tooltip/ToolTipForSvg";
import _isEmpty from 'lodash/isEmpty';

const ContactProfileHeader = (props) => {
  const { closeModal } = props;
  const [showSms, setShowSms] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [showDiary, setShowDiary] = useState(false);
  const [showCallReminder, setShowCallReminder] = useState(false);
  const [fillFormData, setFillFormData] = useState({});

  const {
    contactList,
    profile,
    contactId,
    contactIndex,
    changeDirection,
    contactLoading,
  } = useContext(ContactProfileContext);

  // console.log(props);
  // console.log(profile?.phones?.map((a) => a?.type === 1));
  // console.log(profile?.emails);
  const handleClickedLeft = () => {
    changeDirection("left");
  };

  window.onunload = function () {
    localStorage.removeItem('note');
  }
  
  window.onunload ()

  const handleClickedRight = () => {
    // console.log(contactList?.length);
    // console.log(contactIndex);
    changeDirection("right");
  };
  return (
    <Fragment>
      {showSms ? (
        <DefaultModal
          size="xl"
          centered
          show={showSms}
          onHide={() => setShowSms(false)}
        >
          <SmsWrap
            closeModal={() => setShowSms(false)}
            data={[profile]}

            // phoneData={selectedRow.map((a) => a.phones)}
            // totalRecipient={buyers?.otherResInfo?.total}
            // queryString={buyers?.queryString}
          />
        </DefaultModal>
      ) : (
        ""
      )}

      {showEmail ? (
        <DefaultModal
          size="xl"
          centered
          show={showEmail}
          onHide={() => setShowEmail(false)}
        >
          <Email
            closeModal={() => setShowEmail(false)}
            data={[profile]}
          />
        </DefaultModal>
      ) : (
        ""
      )}
      {showDiary ? (
        <DefaultModal
          size="xl"
          centered
          show={showDiary}
          onHide={() => setShowDiary(false)}
        >
          {/* <ContactProfileDiary closeModal={() => setShowDiary(false)} /> */}
          <Appointment
            setFillFormData={setFillFormData}
            fillFormData={fillFormData}
            initialValues={{
              starts: moment().format("YYYY-MM-DDTHH:mm"),
              ends: moment().format("YYYY-MM-DDTHH:mm"),
              contacts: [profile],
              type: 3,
            }}
            closeModal={() => setShowDiary(false)}
          />
        </DefaultModal>
      ) : (
        ""
      )}
      {showCallReminder ? (
        <DefaultModal
          size="xl"
          centered
          show={showCallReminder}
          onHide={() => setShowCallReminder(false)}
        >
          <CallReminder
            closeModal={() => setShowCallReminder(false)}
            contactId={contactId}
            initialValues={{ for: props?.user?.contact?.id }}
          />
        </DefaultModal>
      ) : (
        ""
      )}
      <ThemeHeader
        className="pb-3"
        noScroll
        right={
          <Fragment>
            <ToolTipForSvg content="SMS">
              <ThemeButton onClick={() => setShowSms(true)} icon>
                <PhoneAndroidIcon />
              </ThemeButton>
            </ToolTipForSvg>
            <ToolTipForSvg content="Email">
              <ThemeButton onClick={() => setShowEmail(true)} icon>
                <EmailIcon />
              </ThemeButton>
            </ToolTipForSvg>
            <ToolTipForSvg content="Appointment">
              <ThemeButton onClick={() => setShowDiary(true)} icon>
                <EventIcon />
              </ThemeButton>
            </ToolTipForSvg>
            <ToolTipForSvg content="Call Reminder">
              <ThemeButton onClick={() => setShowCallReminder(true)} icon>
                <AccessTimeIcon />
              </ThemeButton>
            </ToolTipForSvg>
            {contactList.length ? (
              <div
                className={
                  contactId ? "btn-toolbar nav-icon-button pr-2" : "d-none"
                }
              >
                <div className="btn-group">
                  <ThemeButton
                   disabled={contactLoading || contactList[0].id === contactId || _isEmpty(profile)}
                    onClick={() => handleClickedLeft()}
                    icon
                  >
                    <i className={contactLoading || contactList[0].id === contactId ||
                      _isEmpty(profile) ? "disable-prospect-header-btn fas fa-caret-left" : "fas fa-caret-left"}></i>
                  </ThemeButton>
                  <ThemeButton disabled={contactLoading || _isEmpty(profile)} onClick={() => handleClickedRight()} icon>
                    <i className={contactLoading || _isEmpty(profile) ? "disable-prospect-header-btn fas fa-caret-right" : "fas fa-caret-right"}></i>
                  </ThemeButton>
                </div>
              </div>
            ) : (
              ""
            )}

            <ThemeButton onClick={closeModal} className="lightGrey-button">
              Close
            </ThemeButton>
          </Fragment>
        }
      />
    </Fragment>
  );
};

const mapState = (state) => ({
  user: state.auth.user
});
export default connect(
  mapState,
)(ContactProfileHeader);
