import axios from "axios";

const WiseBerryAPIEnv = process.env.REACT_APP_WISEBERRY_API;
export const RENTAL_URL = WiseBerryAPIEnv + "rental";
let ajaxRequest = null;

export function getAllRentals(prams, customizeLimit = false) {
  // cancel  previous ajax if exists
  if (ajaxRequest) {
    ajaxRequest.cancel();
  }

  // creates a new token for upcomming ajax (overwrite the previous one)
  ajaxRequest = axios.CancelToken.source();

  return axios.get(
    RENTAL_URL + `${customizeLimit ? '?' : '?limit=30'}` + (prams != null ? "&" + prams : ""),
    { cancelToken: ajaxRequest.token }
  );

  // return axios.get(
  //   RENTAL_URL + "?limit=30" + (prams != null ? "&" + prams : "")
  // );
}

export function postRentalUpload(prams) {
  return axios.post(RENTAL_URL + "/upload", prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function getRentalMatchedProperty(id, prams) {
  return axios.get(
    RENTAL_URL + `/matched-rentals?id=${id}&match_by=${prams ? prams : ""}`
  );
}

export function getRentalMatchedTenant(id, prams) {
  return axios.get(
    RENTAL_URL + `/matched-tenants?rental=${id}&${prams ? prams : ""}`
  );
}

export function getRentalsEnquiredTenant(listingId, prams) {
  return axios.get(
    WiseBerryAPIEnv +
      "rental" +
      `/enquired-tenants?limit=30&rental=${listingId}${
        prams ? "&" + prams : ""
      }`
  );
}

export function getRentalsViewedTenant(listingId, prams) {
  return axios.get(
    WiseBerryAPIEnv +
      "rental" +
      `/viewed-tenants?limit=30&rental=${listingId}${prams ? "&" + prams : ""}`
  );
}

export function getRentalById(id) {
  return axios.get(`${RENTAL_URL}/${id}`);
}

export function createNewRental(prams) {
  return axios.post(RENTAL_URL, prams, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function getNotesByRentalId(prams) {
  return axios.get(RENTAL_URL + "/notes?" + prams);
}

export function getFilesByRentalId(id) {
  return axios.get(RENTAL_URL + "/files?rental=" + id);
}

export function getRentalCheckListById(prams) {
  return axios.get(WiseBerryAPIEnv + "checklist?" + (prams ? prams : ""));
}

// READ

export function getRentalDetailsById(rentalId) {
  // const rental = rentals.filter(rental => rental.id === rentalId);
  return axios.get("/rental/details", { params: { id: 12312 } });
  // return axios.get(`${CONTACTS_URL}/${rentalId}`);
}
export function getRentalReportById(rentalId) {
  // const rental = rentals.filter(rental => rental.id === rentalId);
  return axios.get("/rental/report", { params: { id: 12312 } });
  // return axios.get(`${CONTACTS_URL}/${rentalId}`);
}

export function getRentalAdvertisingList() {
  // const rental = rentals.filter(rental => rental.id === rentalId);
  return axios.get("/rental/advertising/selectable");
  // return axios.get(`${CONTACTS_URL}/${rentalId}`);
}

export function getLibraryDataByRentalId(prams) {
  return axios.get(WiseBerryAPIEnv + "asset?" + (prams ? prams : ""));
}
export function getRentalTextById(id) {
  return axios.get(WiseBerryAPIEnv + "marketing-text?rental=" + id);
}

export function postRentalTextById(prams) {
  return axios.post(WiseBerryAPIEnv + "marketing-text", prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function getRentalOfPortals() {
  return axios.get(WiseBerryAPIEnv + "organisation/listing-upload-portals");
}

export function getRentalCheckListSetting() {
  // const rental = rentals.filter(rental => rental.id === rentalId);
  return axios.get("/rental/checkList/settings");
}

export function updateRental(id, prams) {
  return axios.post(RENTAL_URL + "/" + id, prams, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function getRentalAppointment(id) {
  return axios.get(WiseBerryAPIEnv + "appointment?rental=" + id);
}
export function getTimelineByRentalId(prams) {
  return axios.get(RENTAL_URL + "/timeline?" + prams);
}

export function postRentalWithdraw(prams) {
  return axios.post(RENTAL_URL + "/withdraw", prams, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function getRentalsSettings() {
  return axios.get(WiseBerryAPIEnv + "organisation/rental-setting");
}

export function updateRentalSettings(prams) {
  return axios.post(WiseBerryAPIEnv + "organisation/rental-setting", prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function bulkDeleteRental(prams) {
  return axios.post(RENTAL_URL + "/bulk-delete", prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function adjustRentPrice(prams) {
  return axios.post(RENTAL_URL + "/adjust-price", prams, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
export function getRetalPriceHistory(prams) {
  return axios.get(RENTAL_URL + "/get-price-history?rental=" + prams);
}
