import React, { useState, useRef, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import "./_customDropdown.scss";

const CustomDropdown = (props) => {
  const myRef = useRef();
  const [open, setOpen] = useState(false);
  const handleClickOutside = (e) => {
    if (!myRef.current.contains(e.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });

  const toggleDropdown = () => {
    setOpen(!open);
  };

  return (
    <Dropdown
      onSelect={props.onSelect}
      ref={myRef}
      show={open}
      className={`custom-dropdown ${props.className ?? ""}`}
    >
      <Dropdown.Toggle
        onClick={toggleDropdown}
        className={`border text-dark ${props.inputClassName ?? ""}`}
        disabled={props.disabled === undefined ? false : props.disabled}
        style={{ backgroundColor: props.bgColor ?? "white" }}
      >
        {props.label}
      </Dropdown.Toggle>

      <Dropdown.Menu>{props.children}</Dropdown.Menu>
    </Dropdown>
  );
};

export default CustomDropdown;
