import React, { Fragment } from "react";

const StreetAddress = ({
  value,
  viewType = "",
  checkExpiration = false,
  isExpired = false,
  isExpiring = false,
  isMatch = 2,
  visited = null,
}) => {
  const getExpiredClassName = () => {
    let color = "";
    if (checkExpiration) {
      if (isExpired) {
        color = "red-color";
      } else if (isExpiring) {
        color = "blue-color";
      } 
      else {
        color = visited ? "green-color" : (isMatch === 1 ? "orange-color" : "");
      }
    }
    else {
      color = visited ? "green-color" : (isMatch === 1 ? "orange-color" : "");
    }
    return color;
  };

  return (
    <Fragment>
      <div className={`text-capitalize break-word ${getExpiredClassName()}`}>
        {value?.street_unit ? value?.street_unit + "/" : ""}
        {value?.street_number} {value?.street?.name}
        {","} {value?.street?.suburb?.name}
      </div>
    </Fragment>
  );
};

export default StreetAddress;
