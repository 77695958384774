import React, { Suspense, lazy, useEffect } from "react";
import { Route, Switch, withRouter } from "react-router-dom";

import { LayoutSplashScreen } from "../../../_metronic";
import TabPage from "../../modules/_tab/pages/TabPage";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

import * as tabRedux from "../../modules/_tab/_redux/tabRedux";
import { stringCompare, ucFirst } from "../../_helpers/_utils/utils";
import MenuConfig from "../../../_metronic/layout/MenuConfig";
// import MenuConfig from "../layout/MenuConfig";

const HomePage = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    let res = location.pathname.replaceAll("/", " ").split(" ");

    let props = {};

    let menuConfig = MenuConfig.header.items;

    if (stringCompare(location.pathname, "/")) {
      dispatch(
        tabRedux.actions.callTabManager({
          name: "News",
          props: { title: menuConfig.find((obj) => obj.title == "News").title },
        })
      );
    } else {
      // console.log(location.pathname.replaceAll("/", " ").split(" "));

      if (res.includes("listings") && res.length > 2) {
        // console.log(res);
        dispatch(
          tabRedux.actions.callTabManager({
            name: "SingleListing",
            id: res[2],
            navTitle: res[2],
            subComponent: res[3] ? res[3] : "",
          })
        );

        // dispatch(tabRedux.actions.callTabManager({name: 'SingleListing' ,id:res[2],navTitle:res[2],props:{}}));
      } else if (res.includes("rentals") && res.length > 2) {
        dispatch(
          tabRedux.actions.callTabManager({
            name: "SingleRental",
            id: res[2],
            navTitle: res[2],
            subComponent: res[3] ? res[3] : "",
          })
        );
      } else {
        // dispatch(tabRedux.actions.callTabManager({name: ucFirst(res[1]),navTitle:menuConfig.find(obj=> obj.page.toLowerCase() == res[1].toLowerCase()).title,props:{}}));
        dispatch(
          tabRedux.actions.callTabManager({
            name: ucFirst(res[1]),
            props: props,
          })
        );
      }
    }
  }, [dispatch, location.pathname]);

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          // <Redirect exact from="/" to="/tab" />
        }
        <Route path="/" component={TabPage} />
        {/* <Route path="/builder" component={Builder} />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/tab" component={TabPage} />
        <Route path="/news" component={NewsPage} />
        <Route path="/diary" component={Diary} />
        <Route path="/docs" component={DocsPage} />
        <Redirect to="/error/error-v1" /> */}
      </Switch>
    </Suspense>
  );
};

export default withRouter(HomePage);
