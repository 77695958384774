import React from "react";
import { Grid, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MicrositeFormLabel from "./MicrositeFormLabel";

const MicrositeAutocomplete = ({
  label,
  name,
  value,
  placeholder,
  options,
  disabled,
  isLoading = false,
  ...rest
}) => (
  <Grid container direction="row" style={{ marginBottom: 12 }}>
    <Grid item xs={12}>
      <MicrositeFormLabel label={label} disabled={disabled} />
    </Grid>
    <Grid item xs={12}>
      <Autocomplete
        options={options}
        getOptionLabel={(option) => option.name + " (" +option.date +")"}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Listing Appointment"
            variant="standard"
          />
        )}
        variant="standard"
        loading={isLoading}
        {...rest}
      />
    </Grid>
  </Grid>
);

export default MicrositeAutocomplete;
