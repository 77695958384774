import React, {Fragment} from "react";

const ThemeHeader = ({
  left,
  right,
  middle,
  leftClass,
  rightClass,
  middleClass,
  className,
  fromListing,
  noScroll,
}) => {
  return (
    <Fragment>
      <div
        className={
          "d-flex" +
          (noScroll ? " " : " overflow-x ") +
          (fromListing ? " " : " justify-content-between ") +
          className
        }
        // style={{position: "fixed"}}
      >
        <div className={"d-flex " + leftClass}>{left}</div>
        {middle ? <div className={middleClass}>{middle}</div> : ""}
        <div className={"d-flex justify-content-md-end " + rightClass}>
          {right}
        </div>
      </div>
    </Fragment>
  );
};

ThemeHeader.defaultProps = {
  rightClass: "ml-auto",
  className: "pb-3 ",
};
export default ThemeHeader;
