import React from "react";

import { Button } from "@material-ui/core";

import CustomDropdown from "../../../partials/dropdown/CustomDropdown";

const BuyersAppDropdown = ({
  disabled,
  contacts,
  chooseOption = () => {},
  onCallback,
}) => (
  <CustomDropdown
    label="Send Buyers App"
    disabled={disabled}
    className="ml-3"
    inputClassName="font-weight-bold"
    bgColor={`${disabled ? "lightgrey" : "#c0d9e6"}`}
  >
    <div className="d-flex">
      <Button
        onClick={() => {
          chooseOption("Email", contacts, onCallback);
        }}
      >
        Send via Email
      </Button>
    </div>
    <div className="d-flex">
      <Button
        onClick={() => {
          chooseOption("SMS", contacts, onCallback);
        }}
      >
        Send via Sms
      </Button>
    </div>
  </CustomDropdown>
);

export default BuyersAppDropdown;
