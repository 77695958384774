import React, { Fragment, useEffect, useState, useContext } from "react";
import _get from "lodash/get";
import _map from "lodash/map";
import { toast } from "react-toastify";
import _isEmpty from "lodash/isEmpty";
import _words from "lodash/words";
import _replace from "lodash/replace";
import _toLower from "lodash/toLower";
import _filter from "lodash/filter";
import { Spin } from "antd";
import moment from "moment";

import ThemeButton from "../../../../../partials/button/ThemeButton";
import ThemeHeader from "../../../../../partials/content/ThemeHeader";
import LinearProgressLoading from "../../../../../partials/icon/LinearProgressLoading";
import ToggleSwitchBasic from "../../../../../partials/form/toggleSwitch/ToggleSwitchBasic";
import SaleHistory from "./SaleHistory";
import APMContacts from "./APMContacts";
import APMFeatures from "./APMFeatures";
import { createNewContact } from "../../../../contacts/crud/contactsCrud";
import {
  postProcessedContactInfo,
  convertFormData,
  getObjWithoutValues,
  getProspectDetails,
  updateProspectDetails,
} from "../../../../../_helpers/_utils/utils";
import { updateProspect } from "../../../curd/prospectsCrud";
import { getMenu } from "../../../../../GeneralApi/GeneralApi";
import { ProspectProfileContext } from "../ProspectProfileContextProvider";
import { ProspectsContext } from "../../../pages/prospects/ProspectsContextProvider";
import PriceFinderMarketDetails from "./PriceFinderMarketDetails";
import PriceFinderRentalHistoryTable from "./PriceFinderRentalHistoryTable";
import PriceFinderValuationTable from "./PriceFinderValuationTable";
import PriceFinderListingHistoryTable from "./PriceFinderListingHistoryTable";

const APM_LAND_SIZE_UNIT = "m2";
const V2_LAND_SIZE_KEY = "Land Size";

const PriceFinder = ({
  profile,
  viewLoading,
  propertyDetails,
  closeModal,
  // addContactIfAny = false,
}) => {
  const [checkedSalesHistory, setSelectSaleHistory] = useState(false);
  const [selectedAPMContacts, setSelectedContacts] = useState([]);
  const [landSizeChecked, setCheckedLandSize] = useState(false);
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [APMContactData, setContactData] = useState([]);
  const [APMFeatureData, setFeaturesData] = useState([]);
  const [featureOptions, setFeatureOptions] = useState([]);
  const [landSizeMenuOption, setLandSizeMenuOption] = useState([]);
  const [listingChecked, setCheckedListing] = useState(false);
  const [rentalChecked, setCheckedRental] = useState(false);
  const [dncInclude, includeDNC] = useState(false);
  const [dncEnabled, enableDNC] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [includeOwner, setIncludeOwner] = useState(false);
  const [enableOwners, setEnableOwners] = useState(false);
  const [selectedOwners, setSelectedOwners] = useState([]);
  const { updateContext } = useContext(ProspectProfileContext);
  const { updateTable } = useContext(ProspectsContext);
  // const [createContacts, setCreateContacts] = useState(false);
  // const [contactHasPhone, setContactHasPhone] = useState(false);

  useEffect(() => {
    const tempData = _map(propertyDetails?.phoneNumbers, (contact, index) => ({
      id: `apm_contact_${index}`,
      first_name: contact?.initials,
      last_name: contact?.surname,
      number: contact.number,
      ...contact,
    }));
    // const dncCheckData = tempData.filter(contact => contact.doNotCall);
    // enableDNC(!!dncCheckData?.length)
    setContactData(tempData);
    const tempFeatureData = _map(propertyDetails?.features, (value, key) => {
      // let capitalWords = _words(key);
      // let compareWord = capitalWords[capitalWords.length - 1];
      // let newVal = compareWord.endsWith('s') ? _replace(compareWord, /([A-z])$/, '') : compareWord;
      let featureVal = featureOptions.filter(
        (feature) => _toLower(feature.apm_value) === _toLower(key)
      );
      return {
        id: `apm_feature_${key}`,
        name: key,
        ..._get(featureVal, "[0]", {}),
        qty: typeof value === "boolean" ? (value ? 1 : 0) : value,
        disabled: featureVal.length ? false : true,
      };
    });
    setFeaturesData(tempFeatureData);
  }, [propertyDetails, featureOptions]);

  useEffect(() => {
    let tempData = selectedAPMContacts.map((val) => {
      return APMContactData.find((data) => {
        return data.id === val;
      });
    });
    let selectedDnc = tempData.filter((val) => {
      return val?.doNotCall;
    });
    if (
      selectedDnc?.length === selectedAPMContacts?.length &&
      selectedAPMContacts?.length >= 1
    ) {
      enableDNC(true);
    } else {
      enableDNC(false);
    }
  }, [selectedAPMContacts]);

  useEffect(() => {
    if (propertyDetails.ownership?.ownerOccupied) {
      setEnableOwners(true);
    } else {
      setEnableOwners(false);
    }
  }, [propertyDetails]);

  useEffect(() => {
    getMenu("feature,feature_menu_option")
      .then((res) => {
        if (res?.data?.data) {
          setFeatureOptions(res?.data?.data?.feature);
          let landSizeOption = _get(
            res,
            "data.data.feature_menu_option",
            []
          ).filter((option) => option.name === APM_LAND_SIZE_UNIT);
          if (landSizeOption?.length) {
            setLandSizeMenuOption(landSizeOption[0]);
          }
        }
      })
      .catch((err) => {});
  }, []);

  const addToExisting = async (isAddExisting = true) => {
    setIsLoading(true);
    let prospectData = {};
    let tempOwnerContactsData = [];
    let tempSelectedOwnerData;
    let selectedContactData = APMContactData.filter((contact) =>
      selectedAPMContacts.includes(contact.id)
    );
    let selectedContacts;
    let selectedMemberContacts;
    selectedMemberContacts = selectedContactData.map((contact) => ({
      first_name: contact.first_name,
      last_name: contact.last_name,
      phones: [
        {
          type: 1,
          value: contact.number,
          unsubscribe_date: contact.doNotCall ? new Date() : null,
        },
      ],
    }));
    if (selectedOwners.length) {
      tempSelectedOwnerData = propertyDetails?.ownership?.owners.filter(
        (val, index) => selectedOwners.includes(index)
      );
      tempOwnerContactsData = tempSelectedOwnerData.map((val) => {
        return {
          first_name: val.first_name ?? "",
          last_name: val.surname ?? "",
        };
      });
    }
    selectedContacts = [...selectedMemberContacts, ...tempOwnerContactsData];
    // const dncCheckData = selectedContactData.filter(
    //   (contact) => !contact.doNotCall
    // );
    if (selectedContacts?.length) {
      let contactsId = [];
      await Promise.all(
        selectedContacts.map((item) => {
          return new Promise((resolve, reject) => {
            let formatedValue = postProcessedContactInfo(item);

            const formData = convertFormData(
              getObjWithoutValues([undefined, null], formatedValue)
            );

            createNewContact(formData)
              .then(({ data }) => {
                resolve(data);
                contactsId = [...contactsId, data?.data?.id];
              })
              .then(({ data }) => {})
              .catch((e) => {
                resolve({});
              });
          });
        })
      );
      if (contactsId.length) {
        if (isAddExisting) {
          let existingContacts = _map(
            profile?.contacts,
            (contact) => contact.id
          );
          prospectData.contacts = [...existingContacts, ...contactsId];
        } else {
          prospectData.contacts = contactsId;
        }
      }
    }
    if (dncInclude) {
      prospectData.dnc = dncEnabled ? 1 : 0;
    }
    if (includeOwner) {
      prospectData.owner_occupied = enableOwners ? 1 : 0;
    }
    const featuresAPMData = APMFeatureData.filter((feature) =>
      selectedFeatures.includes(feature.id)
    ).map((featureData) => ({
      feature: featureData.id,
      qty: featureData.qty,
      comments: "",
    }));
    if (landSizeChecked) {
      const landFeatureOption = _get(
        featureOptions.filter((feature) => feature.name === V2_LAND_SIZE_KEY),
        "[0]",
        {}
      );
      featuresAPMData.push({
        feature: landFeatureOption?.id ?? "20",
        feature_menu_input: _get(propertyDetails, "landDetails.propertyArea"),
        feature_menu_option: landSizeMenuOption?.id ?? "1",
      });
    }
    if (featuresAPMData.length) {
      let existingProfile = getProspectDetails(profile);
      if (!prospectData?.property) prospectData.property = {};
      if (existingProfile?.property?.id) {
        prospectData.property.id = existingProfile?.property?.id;
      }
      if (isAddExisting) {
        if (existingProfile?.property?.features) {
          prospectData.property.features = [
            ...existingProfile?.property?.features,
            ...featuresAPMData,
          ];
        }
      } else {
        prospectData.property.features = featuresAPMData;
      }
    }
    const saleHistory = _get(propertyDetails, "saleHistory.sales[0]", {});
    if (
      checkedSalesHistory &&
      saleHistory?.price?.value &&
      saleHistory?.saleDate?.value
    ) {
      prospectData.purchase_price = saleHistory.price.value;
      prospectData.purchase_date = moment(saleHistory.saleDate.value).format(
        "YYYY-MM-DD"
      );
    }
    if (propertyDetails?.marketStatus?.forSale && listingChecked) {
      const listingDetails = _get(
        propertyDetails,
        "listingHistory.listings[0]",
        {}
      );
      let agentDetails = _get(listingDetails, "agencies[0]", {});
      if (agentDetails.name) {
        prospectData.listing = 1;
        if (_toLower(agentDetails.name).includes("wiseberry")) {
          prospectData.lwu = 1;
        } else {
          prospectData.lwu = 0;
          prospectData.lwo = listingDetails.name;
          // prospectData.lwo_expiry_date = need to check the date on APM data;
          prospectData.lwo_price =
            listingDetails?.price?.value ?? listingDetails?.price?.display;
        }
      }
    }
    if (propertyDetails?.marketStatus?.forRent && rentalChecked) {
      const rentalDetails = _get(
        propertyDetails,
        "rentalHistory.listings[0]",
        {}
      );
      let agentDetails = _get(rentalDetails, "agencies[0]", {});
      if (agentDetails.name) {
        prospectData.rental = 1;
        if (_toLower(agentDetails.name).includes("wiseberry")) {
          prospectData.rwu = 1;
        } else {
          prospectData.rwu = 0;
          prospectData.rwo = rentalDetails.name;
          prospectData.rwo_price =
            rentalDetails?.price?.value ?? rentalDetails?.price?.display;
        }
      }
    }
    // let savingValue = {
    //   ...updateProspectDetails(prospectData),
    // };

    const formData = convertFormData(
      getObjWithoutValues([undefined, null], prospectData)
    );

    formData.append("_method", "PATCH");
    updateProspect(profile.id, formData)
      .then(({ data }) => {
        updateContext();
        updateTable();
        setIsLoading(false);
        closeModal();
        toast.success("Prospect Saved Successfully");
      })
      .catch((e) => {
        setIsLoading(false);
        toast.error("Error while prospect saving");
      });
  };

  const createProspectContact = async (contacts) => {
    let contactsId = [];
    await Promise.all(
      contacts.map((item) => {
        return new Promise((resolve, reject) => {
          let formatedValue = postProcessedContactInfo(item);

          const formData = convertFormData(
            getObjWithoutValues([undefined, null], formatedValue)
          );

          createNewContact(formData)
            .then(({ data }) => {
              resolve(data);
              contactsId = [...contactsId, data?.data?.id];
            })
            .then(({ data }) => {})
            .catch((e) => {
              resolve({});
            });
        });
      })
    );
    // if (contactsId.length) {
    //   if (isAddExisting) {
    //     let existingContacts = _map(
    //       profile?.contacts,
    //       (contact) => contact.id
    //     );
    //     prospectData.contacts = [...existingContacts, ...contactsId];
    //   } else {
    const prospectData = {};
    prospectData.contacts = contactsId;
    //   }
    // }

    const formData = convertFormData(
      getObjWithoutValues([undefined, null], prospectData)
    );

    formData.append("_method", "PATCH");
    updateProspect(profile.id, formData)
      .then(({ data }) => {
        updateContext();
        updateTable();
        setIsLoading(false);
        closeModal();
        toast.success("Prospect Saved Successfully");
      })
      .catch((e) => {
        setIsLoading(false);
        toast.error("Error while prospect saving");
      });
  };

  // if (addContactIfAny && !createContacts) {

  //   if (APMContactData?.length > 0) {

  //     setCreateContacts(true);

  //     const addApmContacts = APMContactData;
  //     let hasPhone = false;

  //     for (let i = 0; i < addApmContacts.length; i++) {
  //       if (addApmContacts[i]?.number) {
  //         hasPhone = true;
  //         addApmContacts[i]['phones'] = [{ type: 1, value: addApmContacts[i]?.number }];
  //       }
  //     }

  //     if (hasPhone) {
  //       setContactHasPhone(true);
  //       createProspectContact(addApmContacts);
  //     }

  //   }

  // }

  return (
    <div>
      {viewLoading ? <LinearProgressLoading /> : null}

      <Spin
        spinning={
          (_isEmpty(propertyDetails) &&
            !APMContactData.length &&
            !APMFeatureData.length) ||
          isLoading
        }
      >
        <ThemeHeader
          left={<h5 className="title m-0">APM Match</h5>}
          right={<ThemeButton onClick={closeModal}>Close</ThemeButton>}
        />
        {!viewLoading ? (
          <>
            <SaleHistory
              checkedSalesHistory={checkedSalesHistory}
              setSelectSaleHistory={setSelectSaleHistory}
              propertyDetails={propertyDetails}
            />

            <APMContacts
              APMContactData={APMContactData}
              selectedAPMContacts={selectedAPMContacts}
              setSelectedContacts={setSelectedContacts}
              selectedOwners={selectedOwners}
              setSelectedOwners={setSelectedOwners}
              propertyDetails={propertyDetails}
            />
            <div>
              {APMContactData.length ? (
                <div>
                  <div className="d-flex align-items-center">
                    <div className="mt-1 mr-2">
                      <input
                        type="checkbox"
                        onChange={(e) => includeDNC(e?.target?.checked)}
                        checked={dncInclude}
                        disabled={!selectedAPMContacts.length}
                      />
                    </div>
                    <h6
                      className={`${
                        !selectedAPMContacts.length ? "disabled-text" : ""
                      } title m-0`}
                    >
                      {" "}
                      Include DNC
                    </h6>
                  </div>
                  <div
                    className={`${
                      !selectedAPMContacts.length ? "disabled-text" : ""
                    } ml-3 mb-2 d-flex `}
                  >
                    <ToggleSwitchBasic
                      label=" DO Not Call:"
                      labelLeft={true}
                      value={dncEnabled ? 1 : 0}
                    />
                  </div>
                </div>
              ) : null}
            </div>
            <div>
              {propertyDetails?.ownership?.owners.length ? (
                <div>
                  <div className="d-flex align-items-center">
                    <div className="mt-1 mr-2">
                      <input
                        type="checkbox"
                        onChange={(e) => setIncludeOwner(e?.target?.checked)}
                        checked={includeOwner}
                      />
                    </div>
                    <h6 className={`title m-0`}>Include Owner Occupied</h6>
                  </div>
                  <div className={`ml-3 mb-2 d-flex`}>
                    <ToggleSwitchBasic
                      label=" Owners Occupied:"
                      labelLeft={true}
                      value={enableOwners ? 1 : 0}
                    />
                  </div>
                </div>
              ) : null}
            </div>
            <APMFeatures
              APMFeatureData={APMFeatureData}
              selectedFeatures={selectedFeatures}
              setSelectedFeatures={setSelectedFeatures}
            />
            {propertyDetails?.landDetails?.propertyArea ? (
              <div>
                <div className="d-flex align-items-center">
                  <div className="mt-1 mr-2">
                    <input
                      type="checkbox"
                      onChange={(e) => setCheckedLandSize(e?.target?.checked)}
                      checked={landSizeChecked}
                    />
                  </div>
                  <h6 className="title m-0"> Land Details</h6>
                </div>
                <div className="ml-3 mb-2 d-flex">
                  Land Size : {propertyDetails?.landDetails?.propertyArea ?? ""}{" "}
                  {landSizeMenuOption?.name ?? ""}
                </div>
              </div>
            ) : null}
            {propertyDetails?.listingHistory?.listings?.length ? (
              <PriceFinderListingHistoryTable
                propertyDetails={propertyDetails}
                listingChecked={listingChecked}
                setCheckedListing={setCheckedListing}
              />
            ) : null}
            {propertyDetails?.rentalHistory?.listings?.length ? (
              <PriceFinderRentalHistoryTable
                propertyDetails={propertyDetails}
                rentalChecked={rentalChecked}
                setCheckedRental={setCheckedRental}
              />
            ) : null}
            <PriceFinderMarketDetails propertyDetails={propertyDetails} />
            {propertyDetails?.valuationDetails?.valuations?.length ? (
              <PriceFinderValuationTable propertyDetails={propertyDetails} />
            ) : null}
          </>
        ) : null}
        <div className="mt-4 text-center">
          <ThemeButton
            className="green-button mr-3"
            onClick={() => addToExisting(true)}
            disabled={isLoading}
            // disabled={!APMContactData?.length}
          >
            Add to Existing
          </ThemeButton>
          <ThemeButton
            className="red-button mr-3"
            onClick={() => addToExisting(false)}
            disabled={isLoading}
          >
            {`Delete Existing & Add`}
          </ThemeButton>
        </div>
      </Spin>
    </div>
  );
};

export default PriceFinder;
