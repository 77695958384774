/* eslint-disable no-restricted-imports */
import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => (
  {
    '@global': {
        body: {
          backgroundColor: theme.palette.body.main,
        },
        '.brand-text': {
            color: theme.palette.brand.main,
        },
        '.brand-background': {
            backgroundColor: theme.palette.brand.main,
        },
        '.background-light-blue': {
          backgroundColor: theme.palette.brand.main,
        },
        '.color-light-blue': {
          backgroundColor: theme.palette.brand.main,
        },
        '.background-green': {
          backgroundColor: theme.palette.green.main,
        },
        '.color-green': {
          backgroundColor: theme.palette.green.main,
        },
        '.background-dark-grey': {
          backgroundColor: theme.palette.darkGrey.main,
        },
        '.color-dark-grey': {
          backgroundColor: theme.palette.darkGrey.main,
        }
      },
  root: {
  },
}
));

export default function GlobalColorPalette() {
  const classes = useStyles();

  return (
    <Fragment>
        
    </Fragment>
  );
}