import React, { Fragment, useContext, useState } from "react";
// import AccordionContext from "react-bootstrap/AccordionContext";
import { useAccordionToggle, Accordion, Card, Spinner } from "react-bootstrap";

import { connect } from "react-redux";
import { reduxForm, FieldArray } from "redux-form";
import RenderContact from "../../../../../partials/form/sortableArrayFields/RenderContact";
import ThemeButton from "../../../../../partials/button/ThemeButton";
import * as buyersRedux from "../../../_redux/buyersRedux";
import DefaultModal from "../../../../../partials/modal/DefaultModal";
import NewContact from "../../../../contacts/modals/contactFormModal/NewContact";



const BuyerContacts = (props) => {
  const {
    handleSubmit,
    submitting,
    profileId,
    pristine,
    invalid,
    profile,
    change,
    setNewContactId,
    onFormSubmit,
    setAddNewContact,
    initialValues
  } = props;
  console.log('initialValues', initialValues)
  const [showAddNewContact, setShowAddNewContact] = useState(false);
  return (
    <Fragment>
      {showAddNewContact && (
        <DefaultModal
          size="xl"
          centered
          show={showAddNewContact}
          onHide={() => setShowAddNewContact(false)}
        >
          <NewContact
            closeModal={() => setShowAddNewContact(false)}
            setNewContactId={setNewContactId}
            onFormSubmit={onFormSubmit}
            submitFormName="buyer-contacts"
            OutsideContact={true}
          />
        </DefaultModal>
      )}
      <form className="contactForm" onSubmit={handleSubmit}>
        <div className="d-flex justify-content-end pb-3">
          <ThemeButton
            // disabled={invalid || submitting || pristine}
            // type="submit"
            className="darkGrey-button mr-3"
            onClick={() => {
              setShowAddNewContact(true);
              setAddNewContact(true);
            }}
          >
            Add Linked Contact
          </ThemeButton>
          <ThemeButton
            disabled={invalid || pristine || submitting}
            type="submit"
            className="green-button"
          >
            {submitting ?
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              /> : ''}
            Save
          </ThemeButton>
        </div>
        <div
          style={{
            maxHeight: "80vh",
            overflow: "auto",
          }}
        >
          <FieldArray
            profileId={profileId}
            name="contacts"
            component={RenderContact}
            isAtleastOneContact={true}
            viewData={props.data}
            allowedPermission="buyers-edit"
            change={change}
          />
          {/* <Accordion>
            <Card>
              <Card.Header className="d-flex justify-content-between pl-3 pr-3">
                <CustomToggle eventKey="0">
                  Additional Contact
                  {/* {fields.get(index).first_name} {fields.get(index).last_name}  
                </CustomToggle>

                {/* <CustomToggle fields={fields} index={index} eventKey="0">
                    {fields.get(index).first_name} {fields.get(index).last_name}
                  </CustomToggle>  
              </Card.Header>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <ContactFormPersonalDetails
                    selectedContact={selectedContact}
                  />
                  <Row>
                    <Col md={6}>
                      <Row>
                        <Col md={4}>
                          <Field
                            name={`profile_picture`}
                            component={UserImageInput}
                          />{" "}
                        </Col>
                        <Col md={8}>
                          <ContactFormSocialInfo />
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6}>
                      <FormTags />
                    </Col>
                  </Row>
                  <hr />
                  <ContactFormContactInfo />
                  <hr />
                  <ContactFormFamilyDetails />
                  <hr />
                  <Row>
                    <Col md={6}>
                      <ContactConnections />
                    </Col>
                    <Col md={6}>
                     <FieldArray
                          name={`${member}.linked_contacts`}
                          fullButton
                          fieldArray={member}
                          component={FormLinkedContacts}
                        />  
                    </Col>
                  </Row>
                </Card.Body>
               </Accordion.Collapse>
            </Card>
          </Accordion> */}
        </div>
      </form>
    </Fragment>
  );
};

const mapState = (state, props) => {
  return {
    initialValues: {
      contacts: props.data,
      // ...props.preFillContactProfile?.contact,
    },
  };
};

export default connect(
  mapState,
  buyersRedux.actions
)(
  reduxForm({
    form: "buyer-contacts",
    enableReinitialize: true,
  })(BuyerContacts)
);
