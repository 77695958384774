import React, { Fragment, useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import ContactConnections from "../../../../../partials/display/contactConnection/ContactConnections";
import BasicInfo from "../../../../../partials/display/BasicInfo";
import BasicInfoAva from "../../../../../partials/display/BasicInfoAva";
import Tags from "../../../../../partials/display/Tags";
import Profession from "../../../../../partials/display/Profession";
import FamilyDetails from "../../../../../partials/display/FamilyDetails";
import LinkedContacts from "../../../../../partials/display/LinkedContacts";
import DefaultModal from "../../../../../partials/modal/DefaultModal";
import ContactFormModal from "../../contactFormModal/ContactFormModal";
import ThemeButton from "../../../../../partials/button/ThemeButton";
import { deleteContact } from "../../../crud/contactsCrud";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import * as contactsRedux from "../../../_redux/contactsRedux";
import DuplicateContacts from "../../duplicateContacts/DuplicateContacts";
import { ContactProfileContext } from "../ContactProfileContextProvider";
import AccessControl from "../../../../../_ accessControl/AccessControl";
import _isEmpty from 'lodash/isEmpty'
const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: "none",
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
}));

const ContactProfileSideBar = ({
  // profile,
  closeModal,
  queryString,
  contactsLoading,
}) => {
  const [showContactModal, setShowContactModal] = useState(false);
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [profileUpdated, setProfileUpdated] = useState({});
  const { update, updateContext, profile } = useContext(ContactProfileContext);

  useEffect(() => {
    if (!_isEmpty(profileUpdated)) {
      update();
    }
  }, [profileUpdated]);

  const handleDelete = (key) => {
    let value = {};
    value.id = key;
    deleteContact(key)
      .then(({ data: { data } }) => {
        toast.success("File Deleted Successfully");
        contactsLoading(queryString);
        closeModal();
      })
      .catch((e) => {});
  };
  const classes = useStyles();
  return (
    <Fragment>
      {showContactModal ? (
        <DefaultModal
          size="xl"
          centered
          show={showContactModal}
          onHide={() => setShowContactModal(false)}
        >
          <ContactFormModal
            profileId={profile.id}
            closeModal={() => setShowContactModal(false)}
            setProfileUpdated={setProfileUpdated}
            profileUpdated={profileUpdated}
            formName={"edit-contact"}
          />
        </DefaultModal>
      ) : (
        ""
      )}

      {showDuplicateModal ? (
        <DefaultModal
          size="xl"
          centered
          show={showDuplicateModal}
          onHide={() => setShowDuplicateModal(false)}
        >
          <DuplicateContacts
            closeModal={() => setShowDuplicateModal(false)}
            contactId={profile?.id}
            closeContactModal={closeModal}
            updateContext={updateContext}
            profile={profile}
          />
        </DefaultModal>
      ) : (
        ""
      )}
      <div className="card mb-5">
        <div className="card-body">
          <div className="pl-0">
            <div className="main-profile-overview">
              <BasicInfoAva
                edit={true}
                editClicked={() => setShowContactModal(true)}
                profile={profile}
              />
              <div className="contact-profileBasicInfo">
                {profile?.duplicate_contacts?.length > 1 ? (
                  <Fragment>
                    {" "}
                    <div className="p-4 mb-5 duplicate-contact">
                      <div className=" pb-3 d-flex justify-content-center">
                        <p>
                          There are {profile?.duplicate_contacts?.length - 1}{" "}
                          potential duplicates of this contact.
                        </p>
                      </div>
                      <div className="d-flex justify-content-center">
                        <ThemeButton
                          onClick={() => setShowDuplicateModal(true)}
                          className="darkGrey-button"
                        >
                          View
                        </ThemeButton>
                      </div>
                    </div>
                  </Fragment>
                ) : (
                  ""
                )}
                <BasicInfo profile={profile} />
              </div>
              <ContactConnections profile={profile} />

              <div className="linked-contacts pt-3">
                <LinkedContacts profile={profile} />
              </div>
              <div className="tags pt-3">
                <Tags profile={profile} />
              </div>
              <div className="professions pt-3">
                <Profession profile={profile} />
              </div>
              <div className="family-details pt-3 pb-3">
                <FamilyDetails profile={profile} />
              </div>
              <AccessControl
                allowedPermissions={["contacts-delete"]}
                renderNoAccess={() => ""}
              >
                <ThemeButton
                  onClick={() => handleDelete(profile.id)}
                  className="brand-button w-100"
                >
                  Delete
                </ThemeButton>
              </AccessControl>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = ({ contacts: { queryString } }) => ({
  queryString: queryString,
});

export default connect(
  mapStateToProps,
  contactsRedux.actions
)(ContactProfileSideBar);
