import React from "react";
import ThemeButton from "../../../../../partials/button/ThemeButton";
import { Card } from "react-bootstrap";
import _isEmpty from "lodash/isEmpty";

import { ListGroup } from "react-bootstrap";
import { Popover } from "@material-ui/core";

const MenuItem = ({ label, onClick }) => (
  <ListGroup.Item style={{ cursor: "pointer" }} onClick={onClick}>
    {label}
  </ListGroup.Item>
);

const NewInspectionPopover = ({
  prospectProfile,
  setShowOpenHome,
  setShowListingModal,
  setShowRentalModal,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div className="pt-1">
      <ThemeButton
        className={
          _isEmpty(prospectProfile) ? "disable-prospect-header-btn" : ""
        }
        disabled={_isEmpty(prospectProfile)}
        icon
        onClick={handleClick}
      >
        <i className="fas fa-home"></i>
      </ThemeButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Card style={{ width: "18rem" }}>
          <ListGroup variant="flush">
            <MenuItem
              label="New Inspection"
              onClick={() => {
                setAnchorEl(false);
                setShowOpenHome(true);
              }}
            />

            <MenuItem
              label="New Listing"
              onClick={() => {
                setAnchorEl(false);
                setShowListingModal(true);
              }}
            />

            <MenuItem
              label="New Rental"
              onClick={() => {
                setAnchorEl(false);
                setShowRentalModal(true);
              }}
            />
          </ListGroup>
        </Card>
      </Popover>
    </div>
  );
};

export default NewInspectionPopover;
