import React, {useEffect, useState} from "react";

const MediaTags = ({tags, setTags}) => {
    const [values, setValues] = useState(0);
    const [allTags, setAllTags] = useState({
        website: 0,
        property_portals: 0,
        is_heading: 0,
        is_description: 0,
        is_link: 0,
        is_virtual_tour: 0,
        youtube_id: 0
    });

    // Update allTags based on tags
// Update allTags based on tags
    useEffect(() => {
        setAllTags({...allTags, ...tags});
    }, [tags]);

    const handleTags = (value) => {
        setAllTags(prevTags => {
            const updatedTags = {...prevTags, [value]: prevTags[value] === 0 ? 1 : 0};
            setTags(updatedTags);
            return updatedTags;
        });
    };

    return (
        <>
            <div className="form-check">
                <input
                    className="form-check-input"
                    type="checkbox"
                    value="website"
                    checked={tags.website === 1 ? true : false}
                    onClick={(e) => handleTags(e.target.value)}
                    id="textTags"
                />
                <label className="form-check-label" for="textTags">
                    Wiseberry Website
                </label>
            </div>
            <hr/>
            <div className="form-check">
                <input
                    className="form-check-input"
                    type="checkbox"
                    checked={tags.property_portals === 1 ? true : false}
                    onClick={(e) => handleTags(e.target.value)}
                    value="property_portals"
                    id="textTags"
                />
                <label className="form-check-label" for="textTags">
                    Property Portals
                </label>
            </div>
            <hr/>
            <div className="form-check">
                <input
                    onClick={(e) => handleTags(e.target.value)}
                    className="form-check-input"
                    type="checkbox"
                    checked={tags.is_heading === 1 ? true : false}
                    value="is_heading"
                    id="textTags"
                />
                <label className="form-check-label" for="textTags">
                    Heading
                </label>
            </div>
            <hr/>
            <div className="form-check">
                <input
                    onClick={(e) => handleTags(e.target.value)}
                    className="form-check-input"
                    type="checkbox"
                    value="is_description"
                    id="textTags"
                    checked={tags.is_description === 1 ? true : false}
                />
                <label className="form-check-label" for="textTags">
                    Description
                </label>
            </div>
            <hr/>
            <div className="form-check">
                <input
                    onClick={(e) => handleTags(e.target.value)}
                    className="form-check-input"
                    type="checkbox"
                    value="is_link"
                    id="textTags"
                    checked={tags.is_link === 1 ? true : false}
                />
                <label className="form-check-label" for="textTags">
                    Link
                </label>
            </div>
            <hr/>
            <div className="form-check">
                <input
                    onClick={(e) => handleTags(e.target.value)}
                    className="form-check-input"
                    type="checkbox"
                    value="is_virtual_tour"
                    id="textTags"
                    checked={tags.is_virtual_tour === 1 ? true : false}
                />
                <label className="form-check-label" for="textTags">
                    Virtual Tour
                </label>
            </div>
            <hr/>
            <div className="form-check">
                <input
                    onClick={(e) => handleTags(e.target.value)}
                    className="form-check-input"
                    type="checkbox"
                    value="youtube_id"
                    id="textTags"
                    checked={tags.youtube_id === 1 ? true : false}
                />
                <label className="form-check-label" for="textTags">
                    Youtube ID
                </label>
            </div>
        </>
    );
};

export default MediaTags;
