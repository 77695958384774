/* eslint-disable no-restricted-imports */
import { Avatar } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Divider } from "@material-ui/core";
import DefaultModal from "../../modal/DefaultModal";
import Email from "../../component/email/Email";
import Sms from "../../component/sms/Sms";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    fontSize: "13px",
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

const ToShare = ({ url, profile, hasDefaultEmails, fullFile }) => {
  const classes = useStyles();
  const [showSms, setShowSms] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [showPopover, setShowPopover] = useState(true)

  useEffect(() => {
    if (showEmail || showSms) {
      setShowPopover(false);
    } else {
      setShowPopover(true);
    }
  }, [showEmail, showSms])

  return (
    <Fragment>
      {showSms ? (
        <DefaultModal
          size="xl"
          centered
          show={showSms}
          onHide={() => setShowSms(false)}
        >
          <Sms
            closeModal={() => setShowSms(false)}
            // phoneData={[phone]}
            data={[profile]}
            attached={url}
          />
        </DefaultModal>
      ) : (
        ""
      )}

      {showEmail ? (
        <DefaultModal
          size="xl"
          centered
          show={showEmail}
          onHide={() => setShowEmail(false)}
        >
          <Email
            closeModal={() => setShowEmail(false)}
            data={[profile]}
            hasDefaultEmails={hasDefaultEmails}
            // emailData={[email]}
            fullFile={fullFile}
            attached={url}
          />
        </DefaultModal>
      ) : (
        ""
      )}
      {showPopover ?
        <div>
          <div className="d-flex cursor-hand p-3" onClick={() => setShowEmail(true)}>
            <div className="pr-3">
              <Avatar className={classes.small + " darkGrey-background"}>
                <i className="fas fa-envelope    "></i>
              </Avatar>
            </div>
            <div className="p-1 m-auto">Email</div>
          </div>
          <Divider className="mx-2" variant="middle" />
          <div className="d-flex cursor-hand p-3" onClick={() => setShowSms(true)}>
            <div className="pr-3">
              <Avatar className={classes.small + " darkGrey-background"}>
                <i className="fas fa-mobile    "></i>
              </Avatar>
            </div>
            <div>SMS</div>
          </div>
        </div>
        : null}
    </Fragment>
  );
};

export default ToShare;
