import React, { useState, createContext } from "react";
// import { getBuyerById } from "../../../buyers/crud/buyersCrud";

export const AddNewListingsContext = createContext({});
export const { Consumer } = AddNewListingsContext;

const AddNewListingsContextProvider = (props) => {
  const { closeModal } = props;
  const [listingValues, setListingValues] = useState(null);
  const [inputAddress, setInputAddress] = useState(null);
  return (
    <AddNewListingsContext.Provider
      value={{
        listingValues,
        setListingValues,
        inputAddress,
        setInputAddress,
        closeModal,
      }}
    >
      {props.children}
    </AddNewListingsContext.Provider>
  );
};

export default AddNewListingsContextProvider;
