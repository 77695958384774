import React, { useState, useCallback } from "react";
import { toast } from "react-toastify";
// import { Button, Modal, Form, Col, Row } from "react-bootstrap";
// import EditorField from "../../../../../../../partials/form/editorField/EditorField";
import { reduxForm, FieldArray } from "redux-form";
import { connect } from "react-redux";
import {
  combineValidators,
  isRequired,
  composeValidators,
  hasLengthGreaterThan,
} from "revalidate";
import moment from "moment";

// import TextInput from "../../../../../../../partials/form/TextInput.js";

import MakeAnOfferItem from "./MakeAnOfferItem";

import { createNewOffer } from "../../../../../crud/buyersCrud";
import { convertFormData } from "../../../../../../../_helpers/_utils/utils";
import ThemeButton from "../../../../../../../partials/button/ThemeButton";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./_makeAnOffer.scss";

const validate = combineValidators({
  title: isRequired({ message: "The event title is required" }),
  category: isRequired({ message: "The category is required" }),
  description: composeValidators(
    isRequired({ message: "Please enter a description" }),
    hasLengthGreaterThan(4)({
      message: "Description needs to be at least 5 characters",
    })
  )(),
  city: isRequired("city"),
  venue: isRequired("venue"),
  checkbox: isRequired("checkbox"),
  startTime: isRequired("date"),
  endTime: isRequired("date"),
});

const MakeAnOffer = (props) => {
  const {
    handleSubmit,
    pristine,
    submitting,
    invalid,
    profileId,
    numSelected,
    closeModal,
  } = props;
  const [show, setShow] = useState(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const onFormSubmit = useCallback((values) => {
    let filteredValue = {};
    filteredValue.offers = values.makeAnOffer.map(
      ({ date, address, listing, ...other }) => {
        return {
          listing: listing,
          buyer: profileId,
          offer_detail: {
            offer_party: 1,
            offer_date: moment().format("YYYY-MM-DDTHH:mm"),
            ...other,
          },
        };
      }
    );
    let processedValue = convertFormData(filteredValue);
    createNewOffer(processedValue)
      .then(({ data }) => {
        toast.success("New offer added!");
        closeModal();
      })
      .catch(() => {});
  }, []);

  return (
    <>
      <form
        className="contactForm pt-3 pl-5 pr-5 pb-5"
        onSubmit={handleSubmit(onFormSubmit)}
      >
        <div className="d-flex justify-content-between pb-3">
          <div className="header-left d-flex w-100 justify-content-start m-auto">
            <h5 className="brand-color font-weight-bold">Make an Offer</h5>
          </div>
          <div className="header-right d-flex w-100 justify-content-end">
            <div className="pr-2 ">
              <ThemeButton
                onClick={() => closeModal()}
                className="lightGrey-button font-weight-bold"
              >
                Cancel
              </ThemeButton>
            </div>
            <div>
              <ThemeButton
                type="submit"
                disabled={invalid || pristine || submitting}
                onClick={() => handleClose}
                className="submit-button"
              >
                Save
              </ThemeButton>
            </div>
          </div>
        </div>
        <FieldArray
          profileId={profileId}
          name="makeAnOffer"
          component={MakeAnOfferItem}
        />
      </form>
    </>
  );
};

const actions = {
  // socialFeedPost: news.actions.socialFeedPostAsync,
};

const mapState = (state, props) => {
  return {
    initialValues: {
      makeAnOffer: props.numSelected,
    },
  };
};
export default connect(
  mapState,
  actions
)(
  reduxForm({ form: "tenantMakeAnOffer", validate, enableReinitialize: true })(
    MakeAnOffer
  )
);
