import React, { Fragment } from 'react'
import { FieldArray } from 'redux-form'
import DropzoneField from '../../../../partials/form/dropzoneField/DropzoneField'
// import DropzoneFieldWithCropper from '../../../../partials/form/dropzoneField/DropzoneFieldWithCropper'

const AddNewListingImages = ({ hanldeSelectedImages, selectedItems }) => {
    return (
        <Fragment>
            <div className="container">
                <FieldArray hanldeSelectedImages={hanldeSelectedImages} selectedItems={selectedItems} name="images" type='file' acceptedFileType="image/*" uploadType="image" bulkAction component={DropzoneField} />
            </div>
        </Fragment>
    )
}

export default AddNewListingImages
