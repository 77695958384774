import axios from "axios";
const WiseBerryAPI = process.env.REACT_APP_WISEBERRY_API;
// const WiseBerryAPI = process.env.REACT_APP_WISEBERRY_API;
// const WiseBerryAPI = 'http://127.0.0.1:8000/api';
export const LOGIN_URL = WiseBerryAPI + "/login";
export const REGISTER_URL = WiseBerryAPI + "/register";
export const REQUEST_PASSWORD_URL = WiseBerryAPI + "/forgot-password";
export const ME_URL = WiseBerryAPI + "/details";

export function getNewsData() {
  // Authorization head should be fulfilled in interceptor.
  const data = [
    {
      id: "1",
      createdAt: "2020-05-28T23:52:39.211Z",
      name: "Emilie Paucek",
      avatar:
        "https://s3.amazonaws.com/uifaces/faces/twitter/samgrover/128.jpg",
      desc: "SDD Chips"
    },
    {
      id: "2",
      createdAt: "2020-05-28T15:47:51.542Z",
      name: "Adelia Emmerich II",
      avatar:
        "https://s3.amazonaws.com/uifaces/faces/twitter/nsamoylov/128.jpg",
      desc: "neural-net"
    },
    {
      id: "3",
      createdAt: "2020-05-28T00:53:40.242Z",
      name: "Alysson Glover",
      avatar:
        "https://s3.amazonaws.com/uifaces/faces/twitter/johnsmithagency/128.jpg",
      desc: "redundant"
    },
    {
      id: "4",
      createdAt: "2020-05-28T05:06:58.268Z",
      name: "Shaina Little",
      avatar: "https://s3.amazonaws.com/uifaces/faces/twitter/mds/128.jpg",
      desc: "Bond Markets Units European Composite Unit (EURCO)"
    },
    {
      id: "5",
      createdAt: "2020-05-28T14:28:53.663Z",
      name: "Jeffery Rohan Jr.",
      avatar:
        "https://s3.amazonaws.com/uifaces/faces/twitter/agustincruiz/128.jpg",
      desc: "Sausages Frozen"
    },
    {
      id: "6",
      createdAt: "2020-05-28T21:03:19.643Z",
      name: "Adolf Bogisich",
      avatar:
        "https://s3.amazonaws.com/uifaces/faces/twitter/oksanafrewer/128.jpg",
      desc: "New Mexico"
    },
    {
      id: "7",
      createdAt: "2020-05-29T00:13:10.472Z",
      name: "Chad Towne V",
      avatar: "https://s3.amazonaws.com/uifaces/faces/twitter/joreira/128.jpg",
      desc: "Personal Loan Account Movies"
    },
    {
      id: "8",
      createdAt: "2020-05-28T06:37:41.772Z",
      name: "Miss Toy Homenick",
      avatar: "https://s3.amazonaws.com/uifaces/faces/twitter/ankitind/128.jpg",
      desc: "Port"
    },
    {
      id: "9",
      createdAt: "2020-05-28T08:10:49.431Z",
      name: "Owen Howell",
      avatar: "https://s3.amazonaws.com/uifaces/faces/twitter/mutlu82/128.jpg",
      desc: "bluetooth"
    },
    {
      id: "10",
      createdAt: "2020-05-28T07:06:22.924Z",
      name: "Maybell Shields",
      avatar:
        "https://s3.amazonaws.com/uifaces/faces/twitter/coreyginnivan/128.jpg",
      desc: "Philippines azure deposit"
    },
    {
      id: "11",
      createdAt: "2020-05-28T10:57:36.183Z",
      name: "Amber Bergnaum",
      avatar:
        "https://s3.amazonaws.com/uifaces/faces/twitter/franciscoamk/128.jpg",
      desc: "Manager database Identity"
    },
    {
      id: "12",
      createdAt: "2020-05-28T16:34:52.108Z",
      name: "Ashton Gutmann III",
      avatar:
        "https://s3.amazonaws.com/uifaces/faces/twitter/lingeswaran/128.jpg",
      desc: "multi-byte Yemeni Rial"
    },
    {
      id: "13",
      createdAt: "2020-05-28T07:07:02.680Z",
      name: "Martin Schneider",
      avatar: "https://s3.amazonaws.com/uifaces/faces/twitter/sta1ex/128.jpg",
      desc: "Chilean Peso Unidades de fomento Lari enterprise"
    },
    {
      id: "14",
      createdAt: "2020-05-28T12:13:23.406Z",
      name: "Mr. Gussie Lang",
      avatar:
        "https://s3.amazonaws.com/uifaces/faces/twitter/vaughanmoffitt/128.jpg",
      desc: "Leone Djibouti"
    },
    {
      id: "15",
      createdAt: "2020-05-28T03:14:45.861Z",
      name: "Melba Hand",
      avatar: "https://s3.amazonaws.com/uifaces/faces/twitter/SULiik/128.jpg",
      desc: "Frozen Engineer"
    },
    {
      id: "16",
      createdAt: "2020-05-28T15:50:30.542Z",
      name: "Krystina Wiza",
      avatar:
        "https://s3.amazonaws.com/uifaces/faces/twitter/johannesneu/128.jpg",
      desc: "Beauty cohesive"
    },
    {
      id: "17",
      createdAt: "2020-05-28T09:08:15.925Z",
      name: "Ibrahim Okuneva",
      avatar:
        "https://s3.amazonaws.com/uifaces/faces/twitter/joshmedeski/128.jpg",
      desc: "Total"
    },
    {
      id: "18",
      createdAt: "2020-05-28T20:21:16.415Z",
      name: "Kaitlin Grady",
      avatar: "https://s3.amazonaws.com/uifaces/faces/twitter/pyronite/128.jpg",
      desc: "Developer transmit"
    },
    {
      id: "19",
      createdAt: "2020-05-28T18:31:32.014Z",
      name: "Litzy Conn",
      avatar:
        "https://s3.amazonaws.com/uifaces/faces/twitter/brandonmorreale/128.jpg",
      desc: "auxiliary"
    },
    {
      id: "20",
      createdAt: "2020-05-28T06:09:43.915Z",
      name: "Hilda Bode",
      avatar:
        "https://s3.amazonaws.com/uifaces/faces/twitter/Chakintosh/128.jpg",
      desc: "Small one-to-one"
    },
    {
      id: "21",
      createdAt: "2020-05-28T16:07:30.530Z",
      name: "Robyn McDermott",
      avatar:
        "https://s3.amazonaws.com/uifaces/faces/twitter/ademilter/128.jpg",
      desc: "program GB synergies"
    },
    {
      id: "22",
      createdAt: "2020-05-28T19:39:49.733Z",
      name: "Alda Quigley",
      avatar: "https://s3.amazonaws.com/uifaces/faces/twitter/saschamt/128.jpg",
      desc: "transform Tokelau"
    },
    {
      id: "23",
      createdAt: "2020-05-28T05:23:25.174Z",
      name: "Lynn Fritsch",
      avatar:
        "https://s3.amazonaws.com/uifaces/faces/twitter/justinrhee/128.jpg",
      desc: "Bahrain cyan"
    },
    {
      id: "24",
      createdAt: "2020-05-28T05:50:51.553Z",
      name: "Saul Corkery",
      avatar:
        "https://s3.amazonaws.com/uifaces/faces/twitter/duck4fuck/128.jpg",
      desc: "Hat Vietnam"
    },
    {
      id: "25",
      createdAt: "2020-05-28T01:41:19.276Z",
      name: "Jerrold Oberbrunner III",
      avatar: "https://s3.amazonaws.com/uifaces/faces/twitter/gt/128.jpg",
      desc: "access overriding"
    },
    {
      id: "26",
      createdAt: "2020-05-28T13:56:54.766Z",
      name: "Mireille Carter",
      avatar: "https://s3.amazonaws.com/uifaces/faces/twitter/cherif_b/128.jpg",
      desc: "Vista Administrator"
    },
    {
      id: "27",
      createdAt: "2020-05-28T09:26:45.416Z",
      name: "Dagmar Will",
      avatar: "https://s3.amazonaws.com/uifaces/faces/twitter/barputro/128.jpg",
      desc: "South Carolina"
    },
    {
      id: "28",
      createdAt: "2020-05-28T21:01:26.402Z",
      name: "Lavonne O'Connell",
      avatar:
        "https://s3.amazonaws.com/uifaces/faces/twitter/ripplemdk/128.jpg",
      desc: "parse Gorgeous National"
    },
    {
      id: "29",
      createdAt: "2020-05-28T10:22:32.400Z",
      name: "Taylor Witting",
      avatar:
        "https://s3.amazonaws.com/uifaces/faces/twitter/markretzloff/128.jpg",
      desc: "magenta"
    },
    {
      id: "30",
      createdAt: "2020-05-28T01:29:47.281Z",
      name: "Brando Stamm I",
      avatar: "https://s3.amazonaws.com/uifaces/faces/twitter/emsgulam/128.jpg",
      desc: "Interactions"
    },
    {
      id: "31",
      createdAt: "2020-05-28T10:03:10.731Z",
      name: "Samir Roberts",
      avatar: "https://s3.amazonaws.com/uifaces/faces/twitter/polarity/128.jpg",
      desc: "Industrial Administrator turquoise"
    },
    {
      id: "32",
      createdAt: "2020-05-28T21:24:35.646Z",
      name: "Linnea Haley",
      avatar:
        "https://s3.amazonaws.com/uifaces/faces/twitter/thatonetommy/128.jpg",
      desc: "salmon sensor Kyrgyz Republic"
    },
    {
      id: "33",
      createdAt: "2020-05-28T18:00:56.337Z",
      name: "Ferne Ebert",
      avatar:
        "https://s3.amazonaws.com/uifaces/faces/twitter/jennyshen/128.jpg",
      desc: "Customer Tuvalu Self-enabling"
    },
    {
      id: "34",
      createdAt: "2020-05-28T16:22:32.661Z",
      name: "Nat Wilderman",
      avatar: "https://s3.amazonaws.com/uifaces/faces/twitter/hota_v/128.jpg",
      desc: "Kids Wyoming SMTP"
    },
    {
      id: "35",
      createdAt: "2020-05-28T15:11:45.612Z",
      name: "Kaci Hodkiewicz",
      avatar:
        "https://s3.amazonaws.com/uifaces/faces/twitter/sawalazar/128.jpg",
      desc: "Shoes"
    },
    {
      id: "36",
      createdAt: "2020-05-28T16:18:51.780Z",
      name: "Luciano Wolf",
      avatar: "https://s3.amazonaws.com/uifaces/faces/twitter/catarino/128.jpg",
      desc: "Bahraini Dinar mobile real-time"
    },
    {
      id: "37",
      createdAt: "2020-05-28T19:18:18.129Z",
      name: "Bailee Thiel",
      avatar:
        "https://s3.amazonaws.com/uifaces/faces/twitter/madebybrenton/128.jpg",
      desc: "Checking Account"
    },
    {
      id: "38",
      createdAt: "2020-05-28T18:36:09.090Z",
      name: "Bert O'Kon",
      avatar:
        "https://s3.amazonaws.com/uifaces/faces/twitter/0therplanet/128.jpg",
      desc: "Gloves"
    },
    {
      id: "39",
      createdAt: "2020-05-28T17:28:51.811Z",
      name: "Mr. Lesly Cummings",
      avatar:
        "https://s3.amazonaws.com/uifaces/faces/twitter/themrdave/128.jpg",
      desc: "Kids IB data-warehouse"
    },
    {
      id: "40",
      createdAt: "2020-05-28T11:39:36.589Z",
      name: "Rebecca Russel",
      avatar: "https://s3.amazonaws.com/uifaces/faces/twitter/nvkznemo/128.jpg",
      desc: "JSON withdrawal"
    },
    {
      id: "41",
      createdAt: "2020-05-28T17:37:05.265Z",
      name: "Danielle Yundt",
      avatar:
        "https://s3.amazonaws.com/uifaces/faces/twitter/raphaelnikson/128.jpg",
      desc: "Innovative connect Corner"
    },
    {
      id: "42",
      createdAt: "2020-05-28T10:14:48.839Z",
      name: "Jadon Kertzmann",
      avatar:
        "https://s3.amazonaws.com/uifaces/faces/twitter/brenmurrell/128.jpg",
      desc: "context-sensitive"
    },
    {
      id: "43",
      createdAt: "2020-05-28T07:58:42.094Z",
      name: "Cole Wiza",
      avatar: "https://s3.amazonaws.com/uifaces/faces/twitter/alevizio/128.jpg",
      desc: "Comoro Franc convergence Pennsylvania"
    },
    {
      id: "44",
      createdAt: "2020-05-28T00:37:50.622Z",
      name: "Lisa Kassulke",
      avatar:
        "https://s3.amazonaws.com/uifaces/faces/twitter/itskawsar/128.jpg",
      desc: "Multi-layered Wooden"
    },
    {
      id: "45",
      createdAt: "2020-05-28T21:42:58.765Z",
      name: "Houston Kutch",
      avatar: "https://s3.amazonaws.com/uifaces/faces/twitter/_vojto/128.jpg",
      desc: "Savings Account"
    },
    {
      id: "46",
      createdAt: "2020-05-28T17:03:58.611Z",
      name: "Taya Gibson",
      avatar: "https://s3.amazonaws.com/uifaces/faces/twitter/otozk/128.jpg",
      desc: "plum connecting"
    },
    {
      id: "47",
      createdAt: "2020-05-28T02:32:07.443Z",
      name: "Winfield Frami",
      avatar:
        "https://s3.amazonaws.com/uifaces/faces/twitter/kiwiupover/128.jpg",
      desc: "Tactics"
    },
    {
      id: "48",
      createdAt: "2020-05-28T00:55:19.338Z",
      name: "Janae Barton",
      avatar:
        "https://s3.amazonaws.com/uifaces/faces/twitter/denisepires/128.jpg",
      desc: "data-warehouse Berkshire Practical Granite Ball"
    },
    {
      id: "49",
      createdAt: "2020-05-28T21:15:29.748Z",
      name: "Minnie Schuster Jr.",
      avatar:
        "https://s3.amazonaws.com/uifaces/faces/twitter/borges_marcos/128.jpg",
      desc: "Coordinator Parkway"
    },
    {
      id: "50",
      createdAt: "2020-05-28T10:42:45.970Z",
      name: "Antonette Prohaska",
      avatar:
        "https://s3.amazonaws.com/uifaces/faces/twitter/terryxlife/128.jpg",
      desc: "matrix"
    },
    {
      id: "51",
      createdAt: "2020-05-28T16:32:52.976Z",
      name: "Billy Ratke",
      avatar:
        "https://s3.amazonaws.com/uifaces/faces/twitter/kiwiupover/128.jpg",
      desc: "Mall Senior"
    },
    {
      id: "52",
      createdAt: "2020-05-28T11:24:54.420Z",
      name: "Mr. Lillie Bartell",
      avatar: "https://s3.amazonaws.com/uifaces/faces/twitter/imsoper/128.jpg",
      desc: "neural-net SDD synergize"
    },
    {
      id: "53",
      createdAt: "2020-05-28T07:30:12.300Z",
      name: "Darlene Blanda",
      avatar: "https://s3.amazonaws.com/uifaces/faces/twitter/dhrubo/128.jpg",
      desc: "parsing encompassing"
    },
    {
      id: "54",
      createdAt: "2020-05-28T10:06:08.917Z",
      name: "Kristin Cormier",
      avatar:
        "https://s3.amazonaws.com/uifaces/faces/twitter/xtopherpaul/128.jpg",
      desc: "GB action-items"
    },
    {
      id: "55",
      createdAt: "2020-05-28T16:38:13.251Z",
      name: "Alycia Veum",
      avatar:
        "https://s3.amazonaws.com/uifaces/faces/twitter/fuck_you_two/128.jpg",
      desc: "withdrawal JSON Florida"
    },
    {
      id: "56",
      createdAt: "2020-05-28T08:01:41.416Z",
      name: "Gracie D'Amore",
      avatar: "https://s3.amazonaws.com/uifaces/faces/twitter/opnsrce/128.jpg",
      desc: "generating Home Loan Account end-to-end"
    }
  ];

  return data;
}

// READ
export function getTopLeaders() {
  return axios.get(WiseBerryAPI + "top-leaders?limit=10");
}

export function postPost(prams) {
  return axios.post(WiseBerryAPI + "post", prams, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
}

export function updatePost(id, prams) {
  return axios.post(WiseBerryAPI + "post/" + id, prams, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
}

export function getAllPosts(prams) {
  return axios.get(WiseBerryAPI + "post?" + (prams ? prams : ""));
}

export function getPostLike(id) {
  return axios.get(WiseBerryAPI + "post/like?post=" + id);
}

export function getPostApproved(id) {
  return axios.get(WiseBerryAPI + "post/approve?post=" + id);
}

export function getNetworkResults(props) {
  return axios.get(WiseBerryAPI + "network-results?" + props);
}

export function getPostDelete(id) {
  return axios.delete(WiseBerryAPI + "post/" + id);
}
export function getTopOfficesByListings() {
  return axios.get(WiseBerryAPI + "top-offices-by-listings");
}

export function getTopOfficesBySales() {
  return axios.get(WiseBerryAPI + "top-offices-by-sales");
}
// + '?from=2021-06-01&to=2022-05-01
export function getTopOfficesByBonus() {
  return axios.get(WiseBerryAPI + "top-offices-by-bonus?limit=10");
}

export function getTopPointsGame() {
  return axios.get(WiseBerryAPI + "top-points-game");
}

export function getTopPerformers() {
  return axios.get(WiseBerryAPI + "top-performers");
}

export function getAnniversaries() {
  return axios.get(WiseBerryAPI + "anniversaries");
}

export function getProspectingRatios() {
  return axios.get(WiseBerryAPI + "top-prospecting-ratios");
}

export function getListingRatios() {
  return axios.get(WiseBerryAPI + "top-listing-ratios");
}

export function getBuyerRatios() {
  return axios.get(WiseBerryAPI + "top-buyer-ratios");
}
