/* eslint-disable no-unused-vars */
import React from "react";

import UserNotifications from "../../../../app/partials/layout/UserNotifications";
import ActivityLog from "../../../../app/partials/layout/ActivityLog";
import UserProfile from "../../../../app/partials/layout/UserProfile";
import { toAbsoluteUrl } from "../../../utils/utils";
import TimeStamp from "../../../../app/partials/component/timestamp/TimeStamp";
import SearchDropdown from "../../../../app/modules/globalSearch/partials/SearchDropdown";
import { IconButton } from "@material-ui/core";
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import HelpCenter from "../../../../app/partials/layout/HelpCenter";
import ScrapBook from "../../../../app/partials/layout/scrapBook/ScrapBook";
import { Link } from "react-router-dom";
import ThemeButton from "../../../../app/partials/button/ThemeButton";

export default class Topbar extends React.Component {
  render() {
    return (
      <div className="kt-header__topbar">



        {/* Print Time start */}
        <TimeStamp />
        {/* Print Time end */}

        {/* Search Dropdown start */}
        <SearchDropdown />
        {/* Search Dropdown end */}


        <UserNotifications

          pulse="true"
          pulseLight="false"
          skin="dark"
          iconType=""
          type="success"
          useSVG="true"
          dot="false"
        />
        {/* <ActivityLog /> */}
        <ScrapBook
          iconType=""
          useSVG="true"

        />
        {/* <MyCart
          icon={toAbsoluteUrl("/media/icons/svg/Shopping/Cart%233.svg")}
          iconType=""
          useSVG="true"
          bgImage={toAbsoluteUrl("/media/misc/bg-1.jpg")}
        /> */}

        

        <div className="m-auto max-w d-flex">
        <Link to="/resource_center" >
          <ThemeButton icon className="white-icon-button nav-icon" ><i className="fas fa-cloud-download-alt "></i></ThemeButton>
        
            </Link>
        <Link to="help_center">
        <ThemeButton icon className="white-icon-button nav-icon" > <i className="fas fa-question-circle    "></i></ThemeButton>
         
            </Link>
        </div>
{/* 
        <HelpCenter

          skin="dark"

        /> */}

        {/* <QuickActionsPanel
          bgImage={toAbsoluteUrl("/media/misc/bg-2.jpg")}
          skin="dark"
          iconType=""
          icon={toAbsoluteUrl("/media/icons/svg/Media/Equalizer.svg")}
          useSVG="true"
          gridNavSkin="light"
        />

        <QuickPanelToggler /> */}

        {/* <LanguageSelector iconType="" /> */}

        <UserProfile showAvatar={false} showHi={true} showBadge={true} />
      </div>
    );
  }
}
