import axios from "axios";
export const CONTACTS_URL = "api/Contacts";
const WiseBerryAPIEnv = process.env.REACT_APP_WISEBERRY_API;
export const LISTING_URL = WiseBerryAPIEnv + "listing";
export const LISTING_ADDRESS = WiseBerryAPIEnv + "address/autocomplete";
export const SALES_ADVICE_PRINT = WiseBerryAPIEnv + "sale/seller-email?";

let ajaxRequest = null;

export function getAllListingAddress(params) {
  // cancel  previous ajax if exists
  if (ajaxRequest) {
    ajaxRequest.cancel();
  }

  // creates a new token for upcomming ajax (overwrite the previous one)
  ajaxRequest = axios.CancelToken.source();

  return axios.get(LISTING_ADDRESS + (params ? "?" + params : ""), {
    cancelToken: ajaxRequest.token,
  });
}

export function getAllListing(prams) {
  // cancel  previous ajax if exists
  if (ajaxRequest) {
    ajaxRequest.cancel();
  }

  // creates a new token for upcomming ajax (overwrite the previous one)
  ajaxRequest = axios.CancelToken.source();

  return axios.get(LISTING_URL + "?limit=30" + (prams ? "&" + prams : ""), {
    cancelToken: ajaxRequest.token,
  });
}

export function getAllListings(prams, customizeLimit = false) {
  // cancel  previous ajax if exists
  // if (ajaxRequest) {
  //   ajaxRequest.cancel();
  // }

  // // creates a new token for upcomming ajax (overwrite the previous one)
  // ajaxRequest = axios.CancelToken.source();

  // return axios.get(LISTING_URL + "?limit=30" + (prams ? "&" + prams : ""), {
  //   cancelToken: ajaxRequest.token,
  // });

  return axios.get(
    LISTING_URL +
      `${customizeLimit ? "?" : "?limit=30"}` +
      (prams ? "&" + prams : "")
  );
}

export function getListingsWithAppointmentNoOffer(prams) {
  return axios.get(
    LISTING_URL +
      "/with-appointments-no-offers" +
      "?limit=30" +
      (prams ? "&" + prams : "")
  );
}

export function getCurrentListings(prams) {
  return axios.get(
    LISTING_URL + "/current?limit=30" + (prams ? "&" + prams : "")
  );
}

export function getTenListings(prams) {
  return axios.get(LISTING_URL + "?limit=10" + (prams ? "&" + prams : ""));
}
//
// Get Enquired Buyer
export function getListingsEnquiredBuyer(listingId, prams) {
  return axios.get(
    LISTING_URL +
      `/enquired-buyers?limit=30&listing=${listingId}${
        prams ? "&" + prams : ""
      }`
  );
}

// Get Enquired Buyer
export function getListingsViewedBuyer(listingId, prams) {
  return axios.get(
    LISTING_URL +
      `/viewed-buyers?limit=30&listing=${listingId}${prams ? "&" + prams : ""}`
  );
}

// Get Matched Buyer
export function getListingsMatchedBuyer(listingId, prams) {
  return axios.get(
    LISTING_URL +
      `/matched-buyers?limit=30&listing=${listingId}${prams ? "&" + prams : ""}`
  );
}

export function getListingById(id) {
  return axios.get(`${LISTING_URL}/${id}`);
}

export function createNewListing(prams) {
  return axios.post(LISTING_URL, prams, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function updateListing(id, prams) {
  return axios.post(LISTING_URL + "/" + id, prams, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

// Listing Settings

export function updateListingSettings(prams) {
  return axios.post(WiseBerryAPIEnv + "organisation/listing-setting", prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function getListingSettings() {
  return axios.get(WiseBerryAPIEnv + "organisation/listing-setting");
}

// CREATE =>  POST: add a new contact to the server
export function createContact(contact) {
  return axios.post(CONTACTS_URL, { contact });
}

// READ

export function getListingDetailsById(listingId) {
  // const listing = listings.filter(listing => listing.id === listingId);

  return axios.get("/listing/details", { params: { id: 12312 } });
  // return axios.get(`${CONTACTS_URL}/${listingId}`);
}
export function getListingReportById(listingId) {
  return axios.get(LISTING_URL + "/reports?listing=" + listingId);
}

export function addNewReport(prams) {
  return axios.post(LISTING_URL + "/reports", prams, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function deleteListingReport(props) {
  return axios.delete(LISTING_URL + "/reports?" + props);
}

export function getListingAdvertisingList() {
  return axios.get(WiseBerryAPIEnv + "marketing-item");
}

export function getListingTextById(id) {
  return axios.get(WiseBerryAPIEnv + "marketing-text?listing=" + id);
}

export function postListingTextById(prams) {
  return axios.post(WiseBerryAPIEnv + "marketing-text", prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function postListingUpload(prams) {
  return axios.post(LISTING_URL + "/upload", prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function postListingText(prams) {
  return axios.post(WiseBerryAPIEnv + "marketing-text/bulk-update", prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function postListingWithdraw(prams) {
  return axios.post(LISTING_URL + "/withdraw", prams, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function getCheckListSetting(prams) {
  return axios.get(
    WiseBerryAPIEnv +
      "organisation/checklist-setting" +
      (prams ? `?${prams}` : "")
  );
}

export function postCheckListSetting(prams) {
  return axios.post(WiseBerryAPIEnv + "organisation/checklist-setting", prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function deleteCheckListSetting(prams) {
  return axios.post(
    WiseBerryAPIEnv + "organisation/checklist-setting/bulk-delete",
    prams,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
}

export function getListingCheckListById(prams) {
  return axios.get(WiseBerryAPIEnv + "checklist?" + (prams ? prams : ""));
}

export function postTaskBulkUpdate(prams) {
  return axios.post(WiseBerryAPIEnv + "task/bulk-update", prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}
export function postCreateCheckList(prams) {
  return axios.post(WiseBerryAPIEnv + "checklist", prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}
export function getListingAdvertisingSById(id) {
  return axios.get(LISTING_URL + "/advertising-schedule?listing=" + id);
}

export function postListingAdvertisingUpdate(prams) {
  return axios.post(LISTING_URL + "/advertising-schedule", prams, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

// File Upload
export function getListingLibraryFiles(id, query) {
  return axios.get(
    WiseBerryAPIEnv + "asset?listing=" + id + "&" + query ? query : ""
  );
}

export function postListingAdvertisingComplete(prams) {
  return axios.post(LISTING_URL + "/advertising-schedule/complete", prams, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function postListingAdvertisingRemove(prams) {
  return axios.delete(LISTING_URL + "/advertising-schedule?", { data: prams });
}

export function getListingCheckListSetting() {
  // const listing = listings.filter(listing => listing.id === listingId);
  return axios.get("/listing/checkList/settings");
  // return axios.get(`${CONTACTS_URL}/${listingId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findContacts(queryParams) {
  return axios.post(`${CONTACTS_URL}/find`, queryParams);
}

// UPDATE => PUT: update the contact on the server
export function updateContact(contact) {
  return axios.put(`${CONTACTS_URL}/${contact.id}`, { contact });
}

// UPDATE Status
export function updateStatusForContacts(ids, status) {
  return axios.post(`${CONTACTS_URL}/updateStatusForContacts`, {
    ids,
    status,
  });
}

// get Notes

export function getNotesByListingId(prams) {
  return axios.get(LISTING_URL + "/notes?" + prams);
}

// get Notes

// Timeline
export function getTimelineByListingId(prams) {
  return axios.get(LISTING_URL + "/timeline?" + prams);
}

// Timeline

// File Upload
export function getFilesByListingId(id) {
  return axios.get(LISTING_URL + "/files?listing=" + id);
}

export function getLibraryDataByListingId(prams) {
  return axios.get(WiseBerryAPIEnv + "asset?" + (prams ? prams : ""));
}

export function uploadListingById(prams) {
  return axios.post(`${WiseBerryAPIEnv}asset`, prams, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function bulkUpdateAssetInformation(prams) {
  console.log("bulk", prams);
  return axios.post(`${WiseBerryAPIEnv}asset/bulk-update`, prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

// File Upload

// Adjust Price
export function getListingPriceHistory(prams) {
  return axios.get(LISTING_URL + "/get-price-history?listing=" + prams);
}
export function adjustPrice(prams) {
  return axios.post(LISTING_URL + "/adjust-price", prams, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
// Adjust Price

// Sales Advice
export function getListingSalesAdvice(id) {
  return axios.get(WiseBerryAPIEnv + "sale?listing=" + id);
}

export function postListingSalesAdvice(prams) {
  return axios.post(WiseBerryAPIEnv + "sale", prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function updateListingSalesAdvice(id, prams) {
  return axios.post(WiseBerryAPIEnv + "sale/" + id, prams, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function letterTestU(id, prams) {
  console.log(1)
  return axios.post(WiseBerryAPIEnv + "test-letter/", prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function letterTest(prams) {
  console.log(2)
  return axios.post(WiseBerryAPIEnv + "test-letter/", prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}


// Sales Advice

// Uploads

export function getListOfPortals() {
  return axios.get(WiseBerryAPIEnv + "organisation/listing-upload-portals");
}

// export function postListingSalesAdvice(prams) {
//   return axios.post(WiseBerryAPIEnv+"sale", prams, {
//     headers: {
//       "Content-Type": "multipart/form-data",
//     },

// })}

// Uploads

// Seller Report

export function getListingsSellerReport(id) {
  return axios.get(LISTING_URL + "/seller-report?listing=" + id);
}

export function updateListingsSellerReport(prams) {
  return axios.post(LISTING_URL + "/seller-report", prams, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function getSellerReportEmailView(id) {
  return axios.get(LISTING_URL + "/seller-report/preview?listing=" + id);
}

export function postSellerReportEmailSend(prams) {
  return axios.post(LISTING_URL + "/seller-report/send", prams, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function postListingFlyer(prams) {
  return axios.post(LISTING_URL + "/flyer-pdf", prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

// Seller Report
//http://localhost:8004/v1/property/find-by-address?street_number=23&street_name=Eacham Street&suburb_name=FAIRFIELD WEST&postcode=2165
export function getPropertyIdByAddress(params) {
  return axios.get(WiseBerryAPIEnv + "property/find-by-address?" + params);
  // return axios.get(WiseBerryAPIEnv + 'property/find-by-address?' + "street_number=23&street_name=Eacham Street&suburb_name=FAIRFIELD WEST&postcode=2165")
}

export function getListingByAddress(params) {
  return axios.get(WiseBerryAPIEnv + "listing/find-by-address?" + params);
  // return axios.get(WiseBerryAPIEnv + 'property/find-by-address?' + "street_number=23&street_name=Eacham Street&suburb_name=FAIRFIELD WEST&postcode=2165")
}
export function updateProperty(prams, id) {
  return axios.post(WiseBerryAPIEnv + "property" + "/" + id, prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function createProperty(prams, id) {
  return axios.post(WiseBerryAPIEnv + "property", prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function getListingAppointment(id) {
  return axios.get(WiseBerryAPIEnv + "appointment?listing=" + id);
}

export function bulkDeleteListing(prams) {
  return axios.post(LISTING_URL + "/bulk-delete", prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function getSalesAdvicePrint(params) {
  return axios.get(SALES_ADVICE_PRINT + params);
  // return axios.get(WiseBerryAPIEnv + 'property/find-by-address?' + "street_number=23&street_name=Eacham Street&suburb_name=FAIRFIELD WEST&postcode=2165")
}

export function sendNewSellerAppPassword(params) {
  return axios.post(LISTING_URL + "/resend-seller-app-password", params, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}
