import React, { Fragment } from "react";
import MemberName from "../popover/memberPopover/MemberName";
import Item from "./Item";

const ContactDetails = ({ profile, type }) => {
  return (
    <Fragment>
      <h6 className="font-weight-bold pb-3 pt-3">{type} Details</h6>

      {profile?.allocated_to ? (
        <Item
          left="Allocation"
          right={
            <Fragment>
              <MemberName id={profile?.allocated_to.id} data={profile.data}>
                {profile?.allocated_to.initial
                  ? profile?.allocated_to.initial
                  : profile?.allocated_to.first_name}
              </MemberName>
            </Fragment>
          }
        />
      ) : (
        ""
      )}
      {profile?.type ? <Item left="Types" right={profile?.type.name} /> : ""}
      {profile?.rating ? (
        <Item left="Rating" right={profile?.rating.name} />
      ) : (
        ""
      )}
      {profile?.looking_period ? <Item left="Looking" right="ALB" /> : ""}
      {profile?.ready_in ? <Item left="Ready In" right="ALB" /> : ""}
      {profile?.condition ? (
        <Item left="Condition" right={profile?.condition.name} />
      ) : (
        ""
      )}
      {profile?.to_sell ? (
        <Item left="To Sell?" right={profile?.to_sell.name} />
      ) : (
        ""
      )}
      {profile?.motivation ? (
        <Item left="Motivation" right={profile?.motivation} />
      ) : (
        ""
      )}
    </Fragment>
  );
};

export default ContactDetails;
