/* eslint-disable no-restricted-imports */
import React, { Fragment, useState } from "react";
import { Button, Chip } from "@material-ui/core";
import CustomDropdown from "../../../../../partials/dropdown/CustomDropdown";
import ToggleSwitch from "../../../../../partials/form/toggleSwitch/ToggleSwitch";
import MakeAnOffer from "./modals/makeAnOffer/MakeAnOffer";
import Enquiry from "./modals/enquiry/Enquiry";
import ThemeHeader from "../../../../../partials/content/ThemeHeader";
import TableFilter from "../../../../../partials/table/TableFilter";
import ThemeButton from "../../../../../partials/button/ThemeButton";
import { reduxForm, Field } from "redux-form";
import { OverlayTrigger } from "react-bootstrap";
import AccessControl from "../../../../../_ accessControl/AccessControl";
import DefaultModal from "../../../../../partials/modal/DefaultModal";
import Email from "../../../../../partials/component/email/Email";
import SmsWrap from "../../../../../partials/component/sms/SmsWrap";
import Appointment from "../../../../diary/partials/modals/appointment/Appointment";
import moment from "moment";
import _debounce from 'lodash/debounce';
import _split from 'lodash/split';
import _concat from 'lodash/concat';
import { toast } from "react-toastify";
import { sendTenantsListingPack } from "../../../crud/tenantsCrud";

const TenantMatchedHeader = (props) => {
  const { handleSubmit, selectedItem, totalNumber, profileId, profile, handleGlobalSearch } = props;

  const [showEnquire, setShowEnquire] = useState(false);
  const [showMakeAnOffer, setShowMakeAnOffer] = useState(false);
  const [showAppointment, setShowAppointment] = useState(false);
  const [fillFormData, setFillFormData] = useState({});
  const [showSms, setShowSms] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [openRentalPackModal, setOpenRentalPackModal] = useState(false);
  const [htmlData, setHtmlData] = useState("<p></p>");

  const onSearch = _debounce(handleGlobalSearch, 500)
  const submitRentalpack = async(values) => {
    let rentalIds = selectedItem.map(val => {
      return val?.rental
    })
    let toValues = _concat(_split(values?.to, ','), _split(values.additional_emails, ','));
    let processedValues = {
      tenant: profileId,
      rentals: rentalIds,
      subject: values.subject,
      head_text: values.body,
      to: toValues.filter(obj => obj)
    }
    if (values.preview === 1) {
      processedValues.preview = 1
    }
    return await sendTenantsListingPack(processedValues).then((res) => {
      if (values.preview === 1) {
        setHtmlData(res.data);
      }
      else {
        toast.success(res?.data?.message)
      }
    }).catch((err) => {
      toast.error(err?.data?.message);
    })
  }
  return (
    <Fragment>
      {showSms ? (
        <DefaultModal
          size="xl"
          centered
          show={showSms}
          onHide={() => setShowSms(false)}
        >
          <SmsWrap
            closeModal={() => setShowSms(false)}
            data={profile?.primary_contact?.phones}
          />
        </DefaultModal>
      ) : (
        ""
      )}

      {showEmail ? (
        <DefaultModal
          size="xl"
          centered
          show={showEmail}
          onHide={() => setShowEmail(false)}
        >
          <Email
            closeModal={() => setShowEmail(false)}
            data={[profile?.primary_contact]}
          />
        </DefaultModal>
      ) : (
        ""
      )}
      {showAppointment ? (
        <DefaultModal
          size="xl"
          centered
          show={showAppointment}
          onHide={() => setShowAppointment(false)}
        >
          <Appointment
            setFillFormData={setFillFormData}
            fillFormData={fillFormData}
            initialValues={{
              starts: moment().format("YYYY-MM-DDTHH:mm"),
              ends: moment().format("YYYY-MM-DDTHH:mm"),
              contacts: [profile?.primary_contact],
              app_type: 10,
              rentals: selectedItem?.map((item) => item?.rental),
            }}
            closeModal={() => setShowAppointment(false)}
          />
        </DefaultModal>
      ) : (
        ""
      )}
      {showEnquire ? (
        <DefaultModal
          size="xl"
          centered
          show={showEnquire}
          onHide={() => setShowEnquire(false)}
        >
          <Enquiry
            size="xl"
            centered
            profileId={profileId}
            numSelected={selectedItem}
            closeModal={() => setShowEnquire(false)}
          />
        </DefaultModal>
      ) : (
        ""
      )}

      {showMakeAnOffer ? (
        <DefaultModal
          size="xl"
          centered
          show={showMakeAnOffer}
          onHide={() => setShowMakeAnOffer(false)}
        >
          <MakeAnOffer
            size="xl"
            centered
            profileId={profileId}
            numSelected={selectedItem}
            closeModal={() => setShowMakeAnOffer(false)}
          />
        </DefaultModal>
      ) : (
        ""
      )}
      {openRentalPackModal ? (
        <DefaultModal
          size="xl"
          centered
          show={openRentalPackModal}
          onHide={() => setOpenRentalPackModal(false)}
        >
          <Email
            initialHtmlData={htmlData}
            subject={"Properties we think you may like"}
            message={"We are excited to be sharing with you some homes we think you’re going to love. Properties are not staying on the market long, give us a call if you are interested to have an inspection."}
            closeModal={() => setOpenRentalPackModal(false)}
            handleSubmitForm={submitRentalpack}
            data={[profile?.primary_contact]}
          />
        </DefaultModal>
      ) : (
        ""
      )}
      <form onSubmit={handleSubmit}>
        <ThemeHeader
          left={
            <Fragment>
              <div className="pr-2">
                <CustomDropdown label="Matching By">
                  <Field name="price" label="Price" component={ToggleSwitch} />
                  <Field
                    label="Location"
                    name="location"
                    component={ToggleSwitch}
                  />
                  <Field
                    label="Property Type"
                    name="property-type"
                    component={ToggleSwitch}
                  />
                  <Field
                    label="Property Feature"
                    name="feature"
                    component={ToggleSwitch}
                  />
                  <Field label="Tags" name="tags" component={ToggleSwitch} />
                </CustomDropdown>
              </div>

              <CustomDropdown label="Action">
                <div className="d-flex">
                  <Button onClick={() => setShowEmail(true)}>
                    Send via Email
                  </Button>
                </div>
                <div className="d-flex">
                  <Button onClick={() => setShowSms(true)}>Send via Sms</Button>
                </div>
                <div className="d-flex">
                  <Button onClick={() => setShowAppointment(true)}>
                    Book Appointment
                  </Button>
                </div>
                <div className="d-flex">
                  <Button onClick={() => setShowMakeAnOffer(true)}>
                    Make an Offer
                  </Button>
                </div>
                <div className="d-flex">
                  <Button onClick={() => setShowEnquire(true)}>Enquire</Button>
                </div>
              </CustomDropdown>
              {
                selectedItem?.length >= 1 ?
                  <div className='ml-2 '>
                    <ThemeButton className='border bg-white text-black' onClick={() => { setOpenRentalPackModal(true) }}> Rental Pack</ThemeButton>
                  </div> : null
              }
            </Fragment>
          }
          right={
            <Fragment>
              <AccessControl
                allowedPermissions={["tenants-download"]}
                renderNoAccess={() => ""}
              >
                <ThemeButton icon>
                  <i className="fas fa-download    "></i>
                </ThemeButton>
              </AccessControl>

              <div className="pl-2 pr-2">
                <TableFilter handleGlobalSearch={onSearch} />
              </div>
              <Chip
                label={`Matched Properties: ${totalNumber}`}
                variant="outlined"
              />
            </Fragment>
          }
        />
      </form>
    </Fragment>
  );
};

export default reduxForm({
  form: "tenantMatchedHeader",
  initialValues: {},
  onChange: (values, dispatch, props, previousValues) => {
    props.submit();
  }, // a unique identifier for this form
})(TenantMatchedHeader);
