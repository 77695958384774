import Skeleton from "@material-ui/lab/Skeleton";
import React, { Fragment } from "react";

const LoadingSkeleton = ({height}) => {
  return (
    <Fragment>
      <Skeleton animation="wave" variant="text" />
      <Skeleton animation="wave" variant="text" />
      <Skeleton animation="wave" variant="rect" width="100%" height={height} />
    </Fragment>
  );
};

export default LoadingSkeleton;
