import React, { Fragment, useEffect, useState } from "react";
import { convertToRaw } from "draft-js";
import htmlToDraft from 'html-to-draftjs';
import { Editor } from "react-draft-wysiwyg";
import draftToMarkdown from "draftjs-to-markdown";
import ThemeButton from "../button/ThemeButton";
import { Form, Row, FormControl } from "react-bootstrap";
import { EditorState, ContentState, convertFromHTML } from "draft-js";
// import { stateFromMarkdown } from "draft-js-import-markdown";
import draftToHtml from "draftjs-to-html";

const TextAreaEmoji = ({
  input,
  rows,
  meta,
  classnames,
  placeholder,
  meta: { touched, error },
  edit,
  options,
  returnHtml,
  initialValues = "<p></p>",
  emailContent,
  isContentHavingImage = false,
  ...props
}) => {
  const initializeEditorState = () => {
    if (isContentHavingImage) {
      const blocksFromHtml = htmlToDraft(initialValues);
      return ContentState.createFromBlockArray(blocksFromHtml.contentBlocks, blocksFromHtml.entityMap);
    } else {
      const contentBlock = convertFromHTML(initialValues);
      return ContentState.createFromBlockArray(contentBlock.contentBlocks);
    }
  }
  const [data, setData] = useState(
    EditorState.createWithContent(initializeEditorState()
    )
  );

  const handleChange = (e) => {
    setData(e);
    const value = draftToHtml(convertToRaw(e.getCurrentContent()));
    input.onChange(value);
    //  if (returnHtml) {
    //    input.onChange(draftToHtml(convertToRaw(e.getCurrentContent())));
    // } else {
    //    input.onChange(draftToMarkdown(convertToRaw(e.getCurrentContent())));
    // }
  };

  useEffect(() => {
    if (meta?.dirty) {
      return;
    }
    if (!input?.value) {
      return;
    }
    console.log('initialValues', initialValues)
    setData(EditorState.createWithContent(initializeEditorState()));

  }, [initialValues]);

  //   useEffect(() => {

  //     setData(input.value)

  //       }, [input.value]);

  return (
    <Fragment>
      {edit ? (
        <Fragment>
          <div className={"d-flex textAreaEmoji " + classnames}>
            <Editor
              wrapperClassName="textAreaEmoji-wrapper w-100"
              editorClassName="textAreaEmoji-editor w-100"
              onEditorStateChange={handleChange}
              toolbar={options}
              editorState={data}
            // defaultEditorState={data.editorState}
            />
          </div>
          <Form.Control.Feedback type="invalid">
            {touched && error && <span>{error}</span>}
          </Form.Control.Feedback>
        </Fragment>
      ) : (
        <Fragment>
          <Row>
            <p>{input?.value}</p>
          </Row>
        </Fragment>
      )}
    </Fragment>
  );
};
TextAreaEmoji.defaultProps = {
  rows: "3",
  edit: true,
  options: {},
  returnHtml: true,
};

export default TextAreaEmoji;
