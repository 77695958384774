import { Tooltip } from "@material-ui/core";
import React, { Fragment } from "react";
import { Col } from "react-bootstrap";
import { Row } from "react-bootstrap";
import Item from "./Item";

const LinkedContacts = ({ profile }) => {
  return (
    <Fragment>
      <div className="pt-2 pb-2">
        <h6 className="font-weight-bold">Linked Contacts</h6>
        {profile?.linked_contacts ? (
          <Fragment>
            {profile?.linked_contacts.map((item, index) => (
              <Row key={index}>
                <Col lg={4}>{item?.relationship?.name}</Col>
                <Col lg={8}>
                  {item && (
                    <Tooltip title={`${item?.first_name} ${item?.last_name}`}>
                      <div className="justify-content-between text-truncate">
                        <p className="m-0">
                          {item?.first_name} {item?.last_name}{" "}
                        </p>
                      </div>
                    </Tooltip>
                  )}
                </Col>
              </Row>
            ))}
          </Fragment>
        ) : (
          ""
        )}
      </div>
    </Fragment>
  );
};

export default LinkedContacts;
