import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import moment from "moment";
import { Modal } from "antd";
import { AvatarGroup } from "@material-ui/lab";
import _debounce from 'lodash/debounce'
import { LinearProgress } from "@material-ui/core";
import { Avatar } from "@material-ui/core";
import { toast } from "react-toastify";

import MemberName from "../../../../partials/popover/memberPopover/MemberName";
import ActiveDots from "../../../../partials/table/cells/ActiveDots";
import ThemeHeader from "../../../../partials/content/ThemeHeader";
import ThemeButton from "../../../../partials/button/ThemeButton";
import DefaultModal from "../../../../partials/modal/DefaultModal";
import AddNewTestimonials from "../../partials/testimonials/AddNewTestimonials";
import DynamicTableEdit from "../../../../partials/table/DynamicTableEdit";
import { convertFormData, formToQueryString, processTestimonialInfo } from "../../../../_helpers/_utils/utils";
import TableFilter from "../../../../partials/table/TableFilter";
import { getAllTestimonials, testimonialAPI } from "../../../setting/_crud/settingCrud";
import LoadingSkeleton from "../../../../partials/display/skeleton/LoadingSkeleton";
import Swal from "sweetalert2";

const Testimonials = () => {
  const [showAddNewRole, setShowAddNewRole] = useState(false);
  const [selectedTestimonial, setSelectedTestimonial] = useState({});
  const [selectedData, setSelectedData] = useState([]);
  const [otherResData, setOtherResData] = useState(null);
  const [testimonials, setTestimonials] = useState([]);
  const [isLoading, setIsLoading] = useState([]);

  useEffect(() => {
    getTestimonialsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTestimonialsData = (values) => {
    let obj = {
      ...values,
      limit: 30,
    };

    let query = formToQueryString(obj);
    setIsLoading(true)
    getAllTestimonials(query)
      .then((res) => {
        const { data, ...rest } = res.data;
        if (values?.loadMore) {
          setTestimonials(testimonials.concat(data));
        } else {
          setTestimonials(data);
        }
        setOtherResData(rest);
      })
      .catch(() => { })
      .finally(() => {
        setIsLoading(false)
      })
  };

  const fetchMoreData = () => {
    let obj = {
      page: otherResData?.current_page + 1,
      loadMore: true
    };
    if (otherResData?.current_page !== otherResData?.last_page) {
      getTestimonialsData(obj);
    }
  };
  const rowClick = data => {
    setShowAddNewRole(true);
    setSelectedTestimonial(processTestimonialInfo(data));
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        columns: [
          {
            Header: "Date",
            accessor: "created_at",
            Cell: ({ cell: { value } }) => (
              <p className="center">
                {value ? moment(value).format("DD/MM/YYYY") : ""}
              </p>
            ),
          },
          {
            Header: "Title",
            accessor: "heading",
            Cell: ({
              cell: { value }
            }) => <Fragment>{value}</Fragment>,
          },
          {
            Header: "Author",
            accessor: "created_by",
            Cell: ({
              cell: { value }
            }) => (
              <Fragment>
                {/* <MemberName id={value}>{value}</MemberName> */}
                {value}
              </Fragment>
            ),
          },
          {
            Header: "Agent",
            accessor: "contacts",
            Cell: ({
              cell: { value }
            }) => (
              <div onClick={(e) => e.stopPropagation()} className='testimonial-avatar'>
                {" "}
                <AvatarGroup max={15}>
                  {value?.length
                    ? value.map((obj) => (
                      <Avatar alt={obj?.initial}>
                        {" "}
                        <MemberName id={obj?.id} className='pr-0'>
                          {obj?.initial ?? '??'}
                        </MemberName>{" "}
                      </Avatar>
                    ))
                    : ""}
                </AvatarGroup>
              </div>
            ),
          },
          {
            Header: "Status",
            accessor: "active",
            Cell: ({
              cell: { row: { original } },
            }) => (
              <Fragment>
                <ActiveDots active={original.show_on_website || original.show_agent} />
                {/* <Status active={original?.active} /> */}
              </Fragment>
            ),
          },
        ],
      },
    ],
    []
  );

  const handleOnSubmit = async ({ id, organisation, link, ...values }) => {
    console.log('handleOnSubmit', values, id)
    let data = { ...values, type: 1, rental: null, listing: values.listing?.value }
    if (id) {
      data['_method'] = 'PATCH'
    }
    const formData = convertFormData(data);
    return await testimonialAPI(id, formData)
      .then(res => {
        getTestimonialsData()
        closeTestimonialModal()
        toast.success(`Testimonail ${id ? 'Updated' : 'Created'} Successfully`)
      })
      .catch(err => {
        toast.error(`${id ? 'Update' : 'Create'} Testimonail Failed`)
      })
  };

  const handleTestimonialsDelete = () => {
    let data = { selected_ids: selectedData.map(item => item.id) }

    Modal.confirm({
      title: 'Warning',
      content: 'Are you sure want to delete?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        const formData = convertFormData(data);
        testimonialAPI('bulk-delete', formData)
          .then(res => {
            toast.success("Blog(s) Deleted Successfully");
            getTestimonialsData()
          })
          .catch(err => {
            toast.error("Blog(s) Deleted Failed");
          })
      },
    })
  };

  const handleSearch = (value) => {
    getTestimonialsData({ search_kw: value })
  };

  const handleGlobalSearch = _debounce(handleSearch, 500);

  const closeTestimonialModal = () => {
    setShowAddNewRole(false);
    setSelectedTestimonial({});
  }

  const handleClose = () => {
    Swal.fire({
      title: "Warning",
      text: "Are you sure want to exit?",
      icon: "warning",
      showCancelButton: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
      confirmButtonColor: "#A7A9AC",
      cancelButtonColor: "#2BB673",
      confirmButtonText: "Cancel",
      cancelButtonText: "Yes",
    }).then((result) => {
      if (result.isDismissed) {
        closeTestimonialModal()
      }
    });
  };

  return (
    <div>
      {showAddNewRole ? (
        <DefaultModal
          size="xl"
          show={showAddNewRole}
          onHide={handleClose}
        >
          <AddNewTestimonials
            onSubmit={handleOnSubmit}
            selectedTestimonial={selectedTestimonial}
            closeModal={closeTestimonialModal}
            closeWithAlert={handleClose}
          />
        </DefaultModal>
      ) : (
        ""
      )}
      <ThemeHeader
        className="p-4"
        left={<h4 className="title">Testimonials</h4>}
        right={
          <Fragment>
            <div className="mr-3">  <TableFilter
              length={testimonials.length}
              handleGlobalSearch={handleGlobalSearch}
            />
            </div>
            <div className="pr-2">
              <ThemeButton
                className="lightGrey-button"
                onClick={() => handleTestimonialsDelete()}
                disabled={!selectedData.length}
              >
                Delete
              </ThemeButton>
            </div>

            <ThemeButton
              className="green-button"
              onClick={() => setShowAddNewRole(true)}
            >
              Add Testimonial
            </ThemeButton>
          </Fragment>
        }
      />
      {isLoading ? <LinearProgress /> : ""}
      {
        isLoading && !testimonials.length ?
          <LoadingSkeleton height={"77vh"} />
          :
          <DynamicTableEdit
            columns={columns}
            handleChange={(e) => setSelectedData(e)}
            data={testimonials}
            isEditable={true}
            handleRowClick={rowClick}
            update={fetchMoreData}
          />
      }
    </div>
  );
};

export default Testimonials;
