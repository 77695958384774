import React, { Fragment, useEffect, useState, useRef } from "react";
import { Field, FieldArray } from "redux-form";
import { Checkbox, IconButton, withStyles } from "@material-ui/core";
import RadioInput from "../../form/RadioInput";
import ThemeButton from "../../button/ThemeButton";
import Dictaphone from "../../dictaphone/Dictaphone";
import { green } from "@material-ui/core/colors";
import { Form } from "react-bootstrap";
import { setDate } from "date-fns/esm";
import Handle from "../../component/handle/Handle";
import SortableList from "../../form/sortableForm/SortableList";

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox className="p-0" color="default" {...props} />);

const RenderScrapPaper = ({
  data,
  fields,
  handleRowClick,
  placeholder,
  touch,
  isDropped,
  handleUpdate,
  handleDelete,
  isEditable,
  
}) => {

  const handleOnChange = (e) => {
    // let changedData = fields.getAll().map(({completed_at,id, ...other})=> id == e ? { ...other, completed_at: completed_at ? null : new Date() ,id } : { ...other, completed_at,id } )
    handleUpdate(e)
    console.log('onchange')
  };
  return (
    <Fragment>
      <div className="scrapPaperTable">
          <SortableList isDropped={isDropped} fields={fields}>
          {fields.map((field, index) => (
              <div
                key={index}
                // onClick={() => handleCLick(data.get(index)?.id)}
                className={
                " d-flex p-2"
                }
              >
                <div scope="row" className="p-right-2 flex-shrink-1" style={{width: 40}} >
                  <GreenCheckbox checked={fields.get(index)?.completed_at ? true : false}  onChange={() =>handleOnChange(fields.get(index)?.id)} />
                {/* <Form.Check inline  checked={item.completed_at ? true : false} type="checkbox" onChange={() =>handleOnChange(item.id)}  /> */}
                </div>
                <div className={ ( fields.get(index)?.completed_at ? "green-color" : "") + " flex-grow-1"} > {fields.get(index)?.description}</div>
                
                  {isEditable ? (
                    <div className="d-flex" >
                    <ThemeButton
                      icon
                      className="brand-icon-button small-icon "
                      onClick={() => handleDelete(fields.get(index)?.id)}
                    >
                      {" "}
                      <i className="fas fa-minus-circle"></i>{" "}
                    </ThemeButton>
                    <div className='m-auto'> 
                <Handle />
              </div>
                    </div>
                  ) : (
                    ""
                  )}
               
              </div>
            ))}
            </SortableList>
      </div>
    </Fragment>
  );
};
RenderScrapPaper.defaultProps = {};
export default RenderScrapPaper;
