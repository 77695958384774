import React, { useState, useContext, Fragment } from "react";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker.css";
import Header from "./Header";
import DynamicTableEdit from "../../../../../partials/table/DynamicTableEdit";
import { getBuyerMatchedProperty } from "../../../crud/buyersCrud";
import LoadingSkeleton from "../../../../../partials/display/skeleton/LoadingSkeleton";
import {
  currencyFormatter,
  getFeaturesProcessedData,
  formToQueryString,
} from "../../../../../_helpers/_utils/utils";
import Status from "../../../../../partials/table/cells/Status";
import StreetSuburb from "../../../../../partials/display/StreetSuburb";
import MemberName from "../../../../../partials/popover/memberPopover/MemberName";
import { BuyerProfileContext } from "../BuyerProfileContextProvider";

const _ = require("lodash");
const BuyerMatched = ({ edit, handleChange, profileId, profile }) => {
  const [items, setItems] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [otherResData, setOtherResData] = useState([]);
  const { setMatchedListingSelected } = useContext(BuyerProfileContext);

  const update = (value) => {
    let obj = {
      limit: 30,
      ...value,
    };
    if (value?.page) {
      obj.page = value.page;
    }
    obj.buyer = profileId;

    let query = formToQueryString(obj);
    getBuyerMatchedProperty(query)
      .then((res) => {
        const { data, ...rest } = res.data.data;

        if (value?.loadMore) {
          setItems(items.concat(data));
        } else {
          setItems(data);
        }
        setOtherResData(rest);
      })
      .catch(() => {});
  };

  const fetchMoreData = () => {
    if (otherResData?.next_page_url) {
      update({ page: otherResData.current_page + 1, loadMore: true });
    }
  };

  const handleGlobalDebounceSearch = (value) => {
    let obj = {};
    if (value) {
      obj.search_kw = value;
    }
    update(obj);
  };
  const columns = React.useMemo(
    () => [
      {
        Header: "Matched",
        columns: [
          {
            Header: "Address",
            accessor: "address",
            Cell: ({
              cell: {
                row: { original },
              },
            }) => (
              <StreetSuburb
                value={original?.property?.address}
                isExpiring={original?.is_expiring}
                isExpired={original?.is_expired}
                //visited={original?.has_past_appointment}
                seen={original?.seen_listing}
                checkExpiration={false}
              />
            ),
          },
          {
            Header: "Suburb",
            accessor: "suburb",
            Cell: ({
              cell: {
                row: { original },
              },
            }) => <p>{original?.property?.address?.street?.suburb?.name}</p>,
          },
          {
            Header: "Postcode",
            accessor: "postcode",
            Cell: ({
              cell: {
                row: { original },
              },
            }) => (
              <p>{original?.property?.address?.street?.suburb?.postcode}</p>
            ),
          },
          {
            Header: "Price",
            accessor: "price_to",
            Cell: ({
              cell: {
                row: { original },
              },
            }) => (
              <p>
                {currencyFormatter(original.price_from)} -{" "}
                {currencyFormatter(original.price_to)}
              </p>
            ),
          },
          {
            Header: <i className="fas fa-bed"></i>,
            accessor: "bed",
            Cell: ({
              cell: { value },
              cell: {
                row: { original },
              },
            }) => (
              <p className="text-center">
                {getFeaturesProcessedData(
                  original?.property?.features,
                  "Bedroom"
                )}
              </p>
            ),
          },
          {
            Header: <i className="fas fa-bath"></i>,
            accessor: "bath",
            Cell: ({
              cell: { value },
              cell: {
                row: { original },
              },
            }) => (
              <p className="text-center">
                {getFeaturesProcessedData(
                  original?.property?.features,
                  "Bathroom"
                )}
              </p>
            ),
          },
          {
            Header: <i className="fas fa-car"></i>,
            accessor: "parking",
            Cell: ({
              cell: { value },
              cell: {
                row: { original },
              },
            }) => (
              <p className="text-center">
                {" "}
                {getFeaturesProcessedData(
                  original?.property?.features,
                  "Parking"
                )}
              </p>
            ),
          },
          {
            Header: <i className="fas fa-map-marked"></i>,
            accessor: "land_size",
            Cell: ({
              cell: {
                row: { original },
              },
            }) => (
              <p className="text-center">
                {
                  original.property?.features?.find((f) => f?.feature?.id == 20)
                    ?.qty
                }
              </p>
            ),
          },
          {
            Header: "Status",
            accessor: "status",
            Cell: ({
              cell: {
                row: { original },
              },
            }) => (
              <Status
                is_vacant={original?.is_vacant}
                email_sent={original.email_sent}
                has_future_appointment={original.has_future_appointment}
                has_past_appointment={original.has_past_appointment}
                offer_made={original.offer_made}
                sms_sent={original.sms_sent}
                status={original?.status}
                sale_agreed={original?.sale?.status?.id === 1 ? 1 : null}
                sale_condition={original?.sale?.status?.id === 2 ? 1 : null}
                contract_in_office={original?.contract_in_office}
              />
            ),
          },
          {
            Header: "Team",
            disableSortBy: true,
            accessor: "allocated_to",
            Cell: ({
              cell: { value },
              cell: {
                row: {
                  original: { contacts },
                },
              },
            }) => {
              let uniqContacts = _.uniqBy(
                contacts,
                (obj) => obj?.id
              )?.filter((k) => [7, 2, 17, 5].includes(k?.contact_type?.id));
              let length = uniqContacts?.length - 1;
              return (
                <Fragment>
                  <div className="text-center d-flex">
                    {uniqContacts.map((obj, index) => (
                      <Fragment>
                        <MemberName id={obj.id}>
                          {obj?.initial}
                          {index != length ? "," : ""}
                        </MemberName>
                      </Fragment>
                    ))}
                  </div>
                </Fragment>
              );
            },
          },
        ],
      },
    ],
    []
  );

  const datas = React.useMemo(() => items, [items]);

  const handleMatchingBy = (e) => {
    Object.keys(e).forEach((key) => {
      if (e[key] !== 1) delete e[key];
    });
    let key = Object.keys(e);
    if (key.length > 0) {
      update({ match_by: key.join(",") });
    } else {
      update();
    }
  };

  return (
    <div>
      <Header
        enableListingPack={true}
        enableOffer={true}
        enableEnquiry={true}
        buyer_contact={profile?.primary_contact}
        profileId={profileId}
        selectedItem={selectedItem}
        totalNumber={datas?.length ? datas?.length : 0}
        onSubmit={handleMatchingBy}
        handleGlobalSearch={handleGlobalDebounceSearch}
        profile={profile}
      />
      <div className="mail-log-table">
        {datas ? (
          <DynamicTableEdit
            allSelected={(e) => console.log(e.target.checked)}
            handleChange={(e) => {
              console.log("e",e)
              setSelectedItem(
                e.map((obj) => ({
                  date: moment().format("YYYY-MM-DDTHH:mm"),
                  address: obj?.property?.address?.full_address,
                  listing: obj.id,
                  offer_party: 1,
                  primary_contact: obj?.contacts?.[0],
                  is_contract: obj.assets?.some(asset => asset.is_contract === 1) ?? false
                }))
              );
              setMatchedListingSelected(
                e.map((obj) => ({
                  date: moment().format("YYYY-MM-DDTHH:mm"),
                  address: obj?.property?.address?.full_address,
                  listing: obj.id,
                  offer_party: 1,
                  primary_contact: obj?.contacts?.[0],
                }))
              );
            }}
            isEditable={true}
            columns={columns}
            data={datas}
            update={fetchMoreData}
          />
        ) : (
          <LoadingSkeleton height="40vh" />
        )}
      </div>
    </div>
  );
};

export default BuyerMatched;
