import React, {
  useState,
  useEffect,
  Fragment,
  // useCallback,
  useContext,
} from "react";
import {
  getContactById,
  updateContact,
} from "../../../../contacts/crud/contactsCrud";
import {
  convertFormData,
  getObjWithoutValues,
  getProcessedContactInfo,
  postProcessedContactInfo,
  formToQueryString,
} from "../../../../../_helpers/_utils/utils";
import BuyerContacts from "./BuyerContacts";
import LoadingSkeleton from "../../../../../partials/display/skeleton/LoadingSkeleton";
// import { Accordion, Card } from "react-bootstrap";
// import { IconButton } from "@material-ui/core";
// import DefaultModal from "../../../../../partials/modal/DefaultModal";
// import AddLinkedContacts from "../../../../contacts/modals/addLinkedContacts/AddLinkedContacts";
import { toast } from "react-toastify";
import { updateBuyer } from "../../../crud/buyersCrud";
import { BuyerProfileContext } from "../BuyerProfileContextProvider";
// import Swal from "sweetalert2";

const dynamicGetContact = (id) => {
  return getContactById(id);
};

const BuyerContactsWrap = (props) => {
  const { updateContext, buyersTableUpdate, filters } = useContext(
    BuyerProfileContext
  );

  const { profile, profileId } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [additionalContact, setAdditionalContact] = useState([]);
  const [showContactModal, setShowContactModal] = useState(null);
  const [data, setData] = useState(null);
  const [newContactId, setNewContactId] = useState(null);
  const [contactsList, setContactsList] = useState([]);
  const [addNewContact, setAddNewContact] = useState(false);

  const handleOnSelect = (e) => {
    setAdditionalContact(e);
  };

  useEffect(() => {
    setAdditionalContact(null);
  }, [profileId]);

  useEffect(() => {
    let contact_list = [];
    let raw_contact_list = [];
    setIsLoading(true);
    const { contacts, ...other } = profile;
    const promises = [];
    // promises.push(dynamicGetContact(profile?.contact.id));

    if (contacts?.length > 0) {
      contacts.map((item) => {
        promises.push(dynamicGetContact(item.id));
      });
    }

    if (additionalContact?.length > 0) {
      additionalContact.map((item) => {
        promises.push(dynamicGetContact(item.id));
      });
    }

    Promise.all(promises)
      .then((res) => {
        res.map((item) => {
          contact_list.push(getProcessedContactInfo(item.data.data));
          raw_contact_list.push(item.data.data);
        });

        setIsLoading(false);
      })
      .then(() => {
        setContactsList(raw_contact_list);
        // console.log(contact_list, "contact_list");
      });

    contact_list.map((obj, index) => {
      obj.isOpen = false;
      obj.actionType = "View";
    });
    setData(contacts?.map((contact) => getProcessedContactInfo(contact)));
  }, [profile.contacts, additionalContact]);

  const onFormSubmit = async (values) => {
    // console.log(values, "_data_response_id form submit");
    const contactsId = [
      ...values.contacts.map((item) => item.id),
      ...(newContactId ? [newContactId] : []),
    ];

    // console.log(contactsId, "_data_response_id form contactsId");

    let main_contact = values.contacts[0];
    let _linked_contact = values.contacts.slice(1);

    if (!addNewContact) {
      await Promise.all(
        values.contacts.map(async (item) => {
          // let linked_contacts = item.linked_contacts;

          // console.log(item);
          // console.log(linked_contacts);
          // if (item.id == main_contact.id) {
          //   item.linked_contacts.push(
          //     ..._linked_contact.map((obj) => ({ id: obj.id, relationship: "" }))
          //   );
          // }
          // console.log(
          //   _linked_contact.map((obj) => ({ id: obj.id, relationship: "" }))
          // );
          // console.log(linked_contacts);

          let formatedValue = postProcessedContactInfo(item);

          // const formData = convertFormData(
          //   getObjWithoutValues([undefined, null], formatedValue)
          // );

          const formData = convertFormData({
            ...getObjWithoutValues([undefined, null], formatedValue),
            formDataOptions: { allowEmptyArrays: true },
          });

          formData.append("_method", "PATCH");
          return await updateContact(item.id, formData)
            .then(({ data }) => {
              // console.log(data);
              buyersTableUpdate(formToQueryString(filters));
              updateContext();
              toast.success("Post Updated Successfully");
            })
            .then(({ data }) => {})
            .catch((e) => {});
        })
      );
    } else {
      setAddNewContact(false);
    }

    let buyerFormValue = {};
    buyerFormValue.buyer_linked_contacts = contactsId;
    buyerFormValue._method = "PATCH";
    // console.log('buyerFormValuebuyerFormValue', buyerFormValue);
    // let buyerFormatedData = convertFormData({
    //   ...getObjWithoutValues([undefined, null], buyerFormValue),
    //   formDataOptions: { allowEmptyArrays: true }
    // });

    // buyerFormatedData.append("_method", "PATCH");

    updateBuyer(profileId, buyerFormValue, "application/json")
      .then(({ data }) => {
        toast.success("Buyer Saved Successfully");
        buyersTableUpdate(formToQueryString(filters));
        updateContext();
      })
      .catch((e) => {});
  };

  return (
    <Fragment>
      {isLoading && contactsList ? (
        <LoadingSkeleton />
      ) : (
        <BuyerContacts
          onSubmit={onFormSubmit}
          profileId={profileId}
          data={data}
          viewData={contactsList}
          setNewContactId={setNewContactId}
          onFormSubmit={onFormSubmit}
          setAddNewContact={setAddNewContact}
        />
      )}
      {/* <Accordion>
        <Card>
          <Card.Header
            onClick={() => setShowContactModal(true)}
            className="d-flex justify-content-between pl-3 pr-3"
          >
            <h5 className="title">Additional Contact</h5>
            <div className="d-flex">
              <IconButton size="small">
                <i className="fas fa-plus-circle"></i>
              </IconButton>
            </div>
          </Card.Header>
        </Card>
      </Accordion> */}
    </Fragment>
  );
};

export default BuyerContactsWrap;
