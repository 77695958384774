import React, { Fragment, useEffect } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { Checkbox, FormControlLabel, IconButton } from "@material-ui/core";
import { Field } from "redux-form";
import TextInput from "./TextInput";
import DeleteIcon from "@material-ui/icons/Delete";
import MultiSelect from "./MultiSelect";
import FlagIcon from "@material-ui/icons/Flag";
import ToggleIcon from "./toggleIcon/ToggleIcon";
import ThemeButton from "../button/ThemeButton";
//validate
// const required = (value) => (value ? undefined : "Required");

// const numberValidate = (value) =>
//   value && isNaN(Number(value)) ? "Must be a number" : undefined;
// const minValue = (min) => (value) =>
//   value && value < min ? `Must be at least ${min}` : undefined;
// const minValue1 = minValue(1);
// const emailValidate = (value) =>
//   value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
//     ? "Invalid email address"
//     : undefined;
const RenderUnsubscribe = ({ input, icon, checkedIcon, name }) => {
  return (
    <Fragment>
      <FormControlLabel
        control={
          <Checkbox
            onClick={(e) =>
              input.onChange(e.target.checked ? new Date() : null)
            }
            checked={input.value ? true : false}
            icon={icon}
            checkedIcon={checkedIcon}
            name={input.name}
            tabIndex={'-1'}
          />
        }
      />
    </Fragment>
  );
};

const NestedPhoneEmail = ({
  fields,
  options,
  mobile,
  email,
  meta: { error, submitFailed },
  placeholder,
  defaultValue,
  showErrorOnMount,
  onChange,
  type="text"

}) => {

  const phoneFormatter = (number, index) => {
    if (!number) return '';
    const { type } = fields.get(index)
    number = number.substring(0, 10);
    let target = []
    if (type === 2) {
      // landline
      if (number.length > 8) {
        // having pind code
        if (number.substr(0, 2)) target.push(number.substr(0, 2))
        if (number.substr(2, 4)) target.push(number.substr(2, 4))
        if (number.substr(6)) target.push(number.substr(6))
      } else {
        if (number.substr(0, 4)) target.push(number.substr(0, 4))
        if (number.substr(4)) target.push(number.substr(4))
      }
    } else {
      //mobile
      if (number.substr(0, 4)) target.push(number.substr(0, 4))
      if (number.substr(4, 3)) target.push(number.substr(4, 3))
      if (number.substr(7)) target.push(number.substr(7))
    }
    return target.join(' ')
  };

  const phoneParser = (number) => number ? number.replace(/ /g, '') : '';

  return (
    <Fragment>
      {fields.map((member, index) => (
        <Row className="pb-2">
          {(mobile || email) && index === 0 ? (
            ""
          ) : (
            <Col className="col-5">
              <Field
                name={`${member}.type`}
                component={MultiSelect}
                showBottom={false}
                isMulti={false}
                defaultValue={defaultValue}
                options={options}
                placeholder="Please select"
                label="Type"
              />
            </Col>
          )}

          <Col>
            <Field
              label={mobile ? "Number" : "Email Address"}
              name={`${member}.value`}
              // placeholder={mobile ? '0000-000-000' : ''}
              // format={mobile ? (e) => phoneFormatter(e, index) : null}
              // parse={mobile ? phoneParser : null}
              type={type}
              component={TextInput}
              showErrorOnMount={showErrorOnMount}
              onChange={onChange}
            />
          </Col>
          {/* <Col className="col-2 m-auto"> */}
          <div>
            {index === 0 ? (
              ""
            ) : (
              <ThemeButton
                className="small-icon lightGrey-icon-button"
                icon
                onClick={() => fields.remove(index)}
              >
                <i className="fas fa-minus-circle"></i>
              </ThemeButton>
            )}
            <Field
              name={`${member}.unsubscribe_date`}
              icon={<FlagIcon />}
              checkedIcon={<FlagIcon className="brand-color" />}
              component={RenderUnsubscribe}
            />
          </div>

          {/* </Col> */}
        </Row>
      ))}
      <div>
        <p>
          <IconButton
            tabIndex='-1'
            onClick={() => fields.push({ type: 2 })}
            aria-label="delete"
            size="small"
            className="brand-icon-button pr-2"
          >
            <i className="fas fa-plus-circle"></i>
          </IconButton>
          Add{" "}
          {mobile ? (
            <Fragment>phone number</Fragment>
          ) : (
            <Fragment>email address</Fragment>
          )}
        </p>
      </div>
    </Fragment>
  );
};

export default NestedPhoneEmail;
