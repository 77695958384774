import React, { Fragment, useEffect, useState } from 'react'
import { Form, Col, Row } from 'react-bootstrap'
import NestedSingeInput from '../NestedSingeInput'
import { FieldArray, Field } from 'redux-form'
import TextInput from '../TextInput'
import { getMenu } from '../../../GeneralApi/GeneralApi'
import NestedPhoneEmail from '../NestedPhoneEmail'
import LoadingSkeleton from '../../display/skeleton/LoadingSkeleton'

const ContactFormContactInfo = ({fieldArray}) => {

  const [options, setOptions] = useState({});
  const [emailOptions, setEmailOptions] = useState({});


  useEffect(() => {

    let query = "phone_type,email_type"
    getMenu(query)
    .then(({ data: { data } }) => {
      setOptions(data.phone_type)
      setEmailOptions(data.email_type)
      console.log(options)
      console.log(emailOptions)
    })
    .catch(() => {});
  }, []);
    return (
        <Fragment>
      <h5 className='title'>Contact Information</h5>

      <Form.Row>
        <Col lg={6}>
          {options.length > 0 ? <FieldArray 
          options={options} 
          mobile 
          type="number" 
          name={fieldArray ? `${fieldArray}.phones` : 'phones'}  
           component={NestedPhoneEmail} 
           /> : <LoadingSkeleton/> }
          
        </Col>

        <Col lg={6}>

        {emailOptions.length > 0 
        ? <FieldArray
          options={emailOptions} 
          email 
          name={fieldArray ? `${fieldArray}.emails` : 'emails'} 
          type="email"  
          component={NestedPhoneEmail} />  
        : <LoadingSkeleton/> }
          
        </Col>
      </Form.Row>
    </Fragment>
  
    )
}

export default ContactFormContactInfo
