import React, { useState, createContext, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

import { deleteSocialMediaTemplates, getAllSocialMediaTemplates } from "../../crud/socialMedailCrud";
import { errorAlert, formToQueryString } from "../../../../_helpers/_utils/utils";
import { getMenu } from "../../../../GeneralApi/GeneralApi";

export const DigitalTemplateContext = createContext();
export const { Consumer } = DigitalTemplateContext;

const DigitalTemplateContextProvider = (props) => {
  const [template, setTemplates] = useState([]);
  const [continuousMode, setContinuousMode] = useState(false);
  const [otherResData, setOtherResData] = useState(null);
  const [prospectQuery, setProspectQuery] = useState(null);
  const [soldListings, setSoldListings] = useState([]);
  const [selectedListing, setSelectedListing] = useState(null);
  const [editBackground, setEditBackground] = useState(false);
  const [editText, setEditText] = useState(false);
  const [editFeatures, setEditFeatures] = useState(false);
  const [showEditSection, setShowEditSection] = useState(false);
  const [selectedNode, setSelectedNode] = useState(false);
  const [activeTab, setActiveTab] = useState("template");
  const [libraryDataSource, setLibraryDataSource] = useState([])
  const [selectedSocialTemplate, setSelectedSocialTemplate] = useState({})
  const [socialMediaTemplateLoading, setSocialMediaTemplateLoading] = useState(false)
  const [category, setCategory] = useState(null)
  // const user = useSelector(({ auth }) => auth.user.contact);

  useEffect(() => {
    update();
    handleGetMenuSocialMediaTemplate();
  }, []);

  useEffect(() => {
    update();
  }, [prospectQuery]);

  const handleResetLib = () => {
    setLibraryDataSource(null)
  }
  useEffect(() => {
    if (activeTab === "template") {
      handleResetLib()
    }
  }, [activeTab])

  const handleGetMenuSocialMediaTemplate = () => {
 
    let query = "social_media_template_category";
    getMenu(query)
      .then(({ data: { data } }) => {
        setCategory(data.social_media_template_category?.sort((a, b) => a.id - b.id))
      })
      .catch(err => {
        errorAlert(err?.data)
      })
  }

  const onSubmitDrawerForm = (values) => {
    setProspectQuery(values);
    // update(values);
  };

  const fetchMoreData = () => {
    let obj = {
      ...prospectQuery,
    };
    obj.page = otherResData?.current_page + 1;
    obj.loadMore = true;
    console.log(obj);
    if (otherResData?.next_page_url) {
      update(obj);
    }
  };
  const handleGlobalSearch = (value) => {
    update({ name: value });
  };

  const update = (values) => {
    let obj = {
      // allocated_to: user.id,
      ...prospectQuery,
      ...values,
      limit: 30,
    };

    setSocialMediaTemplateLoading(true)
    let query = formToQueryString(obj);

    getAllSocialMediaTemplates(query)
      .then((res) => {
        const { data, ...rest } = res.data;
        if (values?.loadMore) {
          setTemplates(template.concat(data));
        } else {
          setTemplates(data);
        }
        setOtherResData(rest);
      })
      .catch(err => {
        errorAlert(err?.data)
      })
      .finally(() => {
        setSocialMediaTemplateLoading(false)
      })
  };

  const handleSort = useCallback((sortBy) => {
    console.log(sortBy);

    let obj = {};
    let sort_query;
    if (sortBy?.[0]) {
      obj.sort_by = sortBy[0].id;

      if (sortBy[0].desc) {
        obj.direction = "desc";
      } else {
        obj.direction = "asc";
      }
      setProspectQuery({ ...prospectQuery, ...obj });
      update(obj);
    } else {
      update();
    }
  }, []);

  const deleteSocialTemplates = async (values, isInvalidTemplateDelete = false) => {
    setSocialMediaTemplateLoading(true)
    return await deleteSocialMediaTemplates(values)
      .then(({ data: { message } }) => {
        setSocialMediaTemplateLoading(false)
        if (!isInvalidTemplateDelete) {
          toast.success(message)
          update();
        }
      })
      .catch(err => {
        setSocialMediaTemplateLoading(false)
        errorAlert(err?.data)
      });
  }

  const handleTemplateDelete = selectedData => {
    let values = { selected_ids: selectedData.map(item => item.id) };
    Swal.fire({
      title: "Delete Template?",
      text: "Are you sure want to delete?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#A7A9AC",
      cancelButtonColor: "#2BB673",
      confirmButtonText: "Cancel",
      cancelButtonText: "Yes",
    }).then((result) => {
      if (result.isDismissed) {
        deleteSocialTemplates(values)
      }
    });
  };



  return (
    <DigitalTemplateContext.Provider
      value={{
        template,
        continuousMode,
        setContinuousMode,
        onSubmitDrawerForm,
        fetchMoreData,
        otherResData,
        handleGlobalSearch,
        updateTableData: update,
        handleSort,
        soldListings,
        setSoldListings,
        selectedListing,
        setSelectedListing,
        editBackground,
        setEditBackground,
        editText,
        setEditText,
        editFeatures,
        setEditFeatures,
        showEditSection,
        setShowEditSection,
        selectedNode,
        setSelectedNode,
        activeTab,
        setActiveTab,
        libraryDataSource,
        setLibraryDataSource,
        selectedSocialTemplate,
        setSelectedSocialTemplate,
        handleTemplateDelete,
        socialMediaTemplateLoading,
        deleteSocialTemplates,
        setProspectQuery,
        update,
        category
      }}
    >
      {props.children}
    </DigitalTemplateContext.Provider>
  );
};

export default DigitalTemplateContextProvider;
