import React, { Fragment } from "react";
import ThemeHeader from "../../../../partials/content/ThemeHeader";
import { FieldArray } from "redux-form";
import ListingText from "../../../../partials/form/partials/listingText/ListingText";

const AddNewListingText = () => {
  return (
    <div className="container">
        <FieldArray name="texts"  component={ListingText} />
    </div>
  );
};

export default AddNewListingText;
