import React from "react";
// eslint-disable-next-line no-restricted-imports
// import { createMuiTheme } from "@material-ui/core/styles";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/styles";
import { useSelector, shallowEqual } from "react-redux";

export default function ThemeProvider(props) {
  const { children } = props;
  const colors = useSelector(({ builder: { layoutConfig: { colors: { state } } } }) => state, shallowEqual);

  const theme = createTheme(
    /**
     * @see https://material-ui.com/customization/themes/#theme-configuration-variables
     */
    {
      // direction: "rtl",
      typography: {
        fontFamily: ["arial"].join(",")
      },

      palette: {
        brand: {
          // light: will be calculated from palette.primary.main,
          main: colors?.brand ? colors.brand : "#822433"
          // dark: will be calculated from palette.primary.main,
          // contrastText: will be calculated to contrast with palette.primary.main
        },
        subBrand: {
          // light: will be calculated from palette.primary.main,
          main: colors?.subBrand ? colors.subBrand : "#4C0E1B"
          // dark: will be calculated from palette.primary.main,
          // contrastText: will be calculated to contrast with palette.primary.main
        },
        darkGrey: {
          // light: will be calculated from palette.primary.main,
          main: colors?.darkGrey ? colors.darkGrey : "#414042"
          // dark: will be calculated from palette.primary.main,
          // contrastText: will be calculated to contrast with palette.primary.main
        },
        lightBlue: {
          // light: will be calculated from palette.primary.main,
          main: colors?.lightBlue ? colors.lightBlue : "#EFF9FE"
          // dark: will be calculated from palette.primary.main,
          // contrastText: will be calculated to contrast with palette.primary.main
        },
        green: {
          // light: will be calculated from palette.primary.main,
          main: colors?.green ? colors.green : "#2BB673"
          // dark: will be calculated from palette.primary.main,
          // contrastText: will be calculated to contrast with palette.primary.main
        },
        lightGrey: {
          // light: will be calculated from palette.primary.main,
          main: colors?.lightGrey ? colors.lightGrey : "#A7A9AC"
          // dark: will be calculated from palette.primary.main,
          // contrastText: will be calculated to contrast with palette.primary.main
        },
        primary: {
          // light: will be calculated from palette.primary.main,
          main: "#5d78ff"
          // dark: will be calculated from palette.primary.main,
          // contrastText: will be calculated to contrast with palette.primary.main
        },
        secondary: {
          // light: will be calculated from palette.primary.main,
          main: "#d81b60",
          // dark: will be calculated from palette.primary.main,
          contrastText: "#ffffff"
        },
        body: {
          // light: will be calculated from palette.primary.main,
          main: "#fff",
          // dark: will be calculated from palette.primary.main,
          contrastText: "black"
        },
        error: {
          // light: will be calculated from palette.primary.main,
          main: "#fd397a"
          // dark: will be calculated from palette.primary.main,
          // contrastText: will be calculated to contrast with palette.primary.main
        }
      },

      /**
       * @see https://material-ui.com/customization/globals/#default-props
       */
      props: {
        // Name of the component ⚛️
        MuiButtonBase: {
          // The properties to apply
          disableRipple: true // No more ripple, on the whole application 💣!
        },

        // Set default elevation to 1 for popovers.
        MuiPopover: {
          elevation: 1
        }
      }
    }
  );

  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
}
