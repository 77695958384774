// Pint Time Stamp
import React from "react";
import "./_timeStamp.scss";

const TimeStamp = () => {
  // console.log("render time");
  return (
    <div className=" time-stamp m-auto d-none d-sm-block food-Color">
      {/* <span
                className="text-muted font-weight-bold mr-2"
                id="kt_dashboard_daterangepicker_title"
              >
                Today
              </span> */}
      <span className="text-primary " id="kt_dashboard_daterangepicker_date">
        {new Intl.DateTimeFormat("en-GB", {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "2-digit",
        }).format(new Date())}
      </span>
    </div>
  );
};

export default TimeStamp;
