import React, { useEffect, useState, Fragment, useContext } from "react";
import { DigitalTemplateContext } from "../digitalTemplate/DigitalTemplateContextProvider";

import NewWindowCardForm from "./NewWindowCardForm";
import { WindowAccordianData } from "./WindowCardAccordianConstant";


const NewWindowCard = ({ closeModal, updateTableData }) => {
    // const { category } = useContext(DigitalTemplateContext);
    const [windowCardsTemplateCategory, setWindowCardsTemplateCategory] = useState([]);

    // useEffect(() => {
    //     let categories = category.map(item => {
    //         return { ...item, ...AccordianData.find(_item => item.id === _item.id) }
    //     })
    //     setWindowCardsTemplateCategory(categories);
    // }, [category]);

    return (
        <Fragment className="p-3">
            <NewWindowCardForm
                closeModal={closeModal}
                updateTableData={updateTableData}
                windowCardsTemplateCategory={WindowAccordianData}
            />
        </Fragment>
    );
};

export default NewWindowCard;
