import React, { Fragment, useEffect, useState, useContext } from "react";
import { useSelector } from "react-redux";
import { Field, FormSection, getFormValues } from "redux-form";
import { Alert, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import { Divider, InputAdornment } from "@material-ui/core";

import MultiSelect from "../../../../partials/form/MultiSelect";
import DateInput from "../../../../partials/form/DateInput";
import TextInput from "../../../../partials/form/TextInput";
import { getAllMembers, getMenu } from "../../../../GeneralApi/GeneralApi";

import ToggleSwitch from "../../../../partials/form/toggleSwitch/ToggleSwitch";
import ThemeButton from "../../../../partials/button/ThemeButton";
import DefaultModal from "../../../../partials/modal/DefaultModal";
import AdjustPrice from "../adjustPrice/AdjustPrice";
import { maxValue, number } from "../../../validation/Validation";
import NumberFormatCustom from "../../../../partials/form-basic/textField/NumberFormatCustom";
import TextInputAmount from "../../../../partials/form/TextInputAmount";
import { SingleListingContext } from "../../partials/singleListingList/SingleListingContextProvider";
import DragAndDrop from "../../../../partials/form/DragAndDrop";
import { convertFormData } from "../../../../_helpers/_utils/utils";
import { uploadListingById, letterTest } from "../../_crud/listingsCrud";
import Swal from "sweetalert2";
import { PulseLoader } from 'react-spinners';
import {sendMicrosteToProspectEmail} from "../../../marketing/crud/socialMedailCrud";

import EmailReportPreview from "../../partials/singleListingList/salesAdvice/EmailReportPreview";


const maxValue100 = maxValue(100);
const AddNewListingDetails = ({
  setSendAgencyEmail,
  handleMail,
  handleFile,
  showAdjustPrice,
  edit,
  status,
  contract_in_office,
  // custom_marketing,
  tenanted,
  lead_source,
  // marketingPackage,
  fee_percent,
  agreement_type,
  // listing_agent,
  agency_agreement_check,
  changeProp,
}) => {
  const landPreference = [
    {
      name: "Suburb Only",
      id: "0",
    },
    {
      name: "Full Address",
      id: "1",
    },
  ];
  const [listing_status, setListing_status] = useState({});
  const [agreementTypes, setAgreementTypes] = useState({});
  const [ranking, setRanking] = useState({});
  const [property_category, setProperty_category] = useState({});
  const [propertyConstruction, setPropertyConstruction] = useState({});
  const [marketingPackages, setMarketingPackages] = useState({});
  const [tag, setTag] = useState({});
  const [lead_sources, setLead_sources] = useState({});
  const [propertyType, setPropertyType] = useState({});
  const [lived_in, setLived_in] = useState({});
  const [lifestyle, setLifestyle] = useState({});
  const [motivation_ranking, setMotivation_ranking] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [coListingAgent, setCoListingAgent] = useState([]);
  const [displayAgent, setDisplayAgent] = useState([]);
  const [listingAgent, setlistingAgent] = useState([]);
  const { listingDetails, deletedContact, setDeletedContact, formName } = useContext(
    SingleListingContext
  );
  const associated_offices =
    useSelector((state) => state.auth?.user?.accessible_organisations) ?? [];
  const [showPopup, setShowPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const hasSeller =
  listingDetails?.contacts?.filter((c) => c.contact_type?.id === 10)?.length > 0;

  const formValues = useSelector(state => getFormValues(formName)(state));
  const hasSellerInFormValues =
    formValues?.contacts?.filter((c) => c?.contact_type == 10)?.length > 0;

  const sellerExists = hasSeller || hasSellerInFormValues;

  const [emailContent, setEmailContent] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const normalizeUsername = (value) => {
    return value.replace(/[^a-z.]/g, '');
  };
  const handleUploadFiles = async files => {    
    const result = await Swal.fire({
      title: 'Send Agency Agreement Email?',
      html: ` <style> 
      .note {
        font-size: 0.9em; /* Slightly smaller than main text */
        color: #822433; /* Different color */
        margin-top: 10px; /* Spacing for readability */
        font-style: italic; /* Differentiating style */
        text-align: center; /* Natural alignment */
      }

      .note * { /* Emphasizing the asterisk */
        font-weight: bold;
      }
      .loader {
        border: 5px solid #f3f3f3;
        border-top: 5px solid #822433;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        animation: spin 2s linear infinite;
      }

      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
    </style> 

    <div style='    display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;' id='banner'>
      <span style="font-size:1.3em;">Would you like to send the vendor/s the "Welcome to Wiseberry" Email with the Agency Agreement attached?</span> 
      
      <span class="eye-icon" id="preview1" style="color: black; margin-top: 10px"><i class="fas fa-eye"></i></span>
      <div class="note">
        *NOTE: The Agency Agreement button will not work yet in the 'Preview' mode.
      </div>
      <div id="loadingSpinner1" style="display: none;">
        <div class="loader"></div>
      </div>
    </div>`, 
      icon: 'question',
      showCancelButton: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
      confirmButtonColor: "#2BB673",
      cancelButtonColor: "#A7A9AC",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      reverseButtons: true,
      onOpen: () => {
        const actions = Swal.getActions();
        actions.style.cssText = "display: flex; flex-direction: row; justify-content: space-between; width: 98%; padding: 0; margin-top: 20px; margin-left: 5px; margin-bottom: 5px; margin-right: 5px;";
        Swal.getCancelButton().style.cssText = "flex-grow: 1; margin: 0 5px; font-size: 1.2em;";
        Swal.getConfirmButton().style.cssText = "flex-grow: 1; margin: 0 5px; background-color: #2BB673; color: white; font-size: 1.2em;";
      },
      didOpen: () => {
        const previewBtn = document.getElementById("preview1");
        previewBtn.addEventListener("click", async (event) => {
          if (!listingDetails?.id) {
            console.log(formValues);
          
            const contact = formValues.contacts.find(c => c.contact_type === 10);
            if (!contact) {
              toast.error("Seller contact details not found");
              return;
            }
      
            let address; // Declare address here
      
            if (formValues && formValues.domain_address && formValues.domain_address.address) {
              address = formValues.domain_address.address; // Assign value to address
              console.log(address); // This will print the address
            } else {
              toast.error("Address not found");
              return;
            }
      
            let payload = {
              contact: contact.id,
              address: address, // address is now accessible here
              pre_creation: true,
              new_listing: true,
            };
            handlePreviewProcess("preview1", "loadingSpinner1", payload);
            event.stopPropagation();
            event.preventDefault();
            return;
          }  
      
          let payload = {
            listing: listingDetails.id,
            new_listing: true,
          };
          handlePreviewProcess("preview1", "loadingSpinner1", payload);
          event.stopPropagation();
          event.preventDefault();
        });
      }
    });
    setIsLoading(true);
    setShowPopup(false);
    setSendAgencyEmail(result.isConfirmed);
    
    if(!listingDetails) {
      if (files?.length > 0) {
        handleFile(files); // assuming only one file is uploaded
        setShowPopup(false);
        setIsLoading(false);
        return;
      }
    }
    let value = {};
    value.listing = listingDetails.id; // Ensure that listingId is defined and accessible here
    value.agency_agreement_check = true;
    value.files = files;

    if (files?.length > 0) {
      value.files_information = files?.map(file => {
        const { fileContent, ...other } = file;
        return { is_private: 1 };
      });
    }
    let formData = convertFormData(value);

    uploadListingById(formData)
      .then(({ data: { data } }) => {
        toast.success("File Added Successfully");
        setIsLoading(false);
      })
      .catch(e => {
        toast.error(e.message ?? "Error while uploading file");
        setIsLoading(false);
      });
  };

  const togglePopup = (event) => {
    // Only show the popup when the switch is turned on
    if (!sellerExists) {
      toast.warning('Please add Vendor contact details in the "Contacts" tab to check Agency Agreement', { autoClose: 5000, closeButton: false });
      changeProp("agency_agreement_check", 0);
      return false;
    }else if (event) {
      setShowPopup(true);
    }
    return true;
  };

  

  useEffect(() => {
    setCoListingAgent(
      listingDetails?.contacts?.filter((c) => c.contact_type.id === 7)
    );
    setDisplayAgent(
      listingDetails?.contacts?.filter((c) => c.contact_type.id === 17)
    );
    setlistingAgent(
      listingDetails?.contacts?.filter((c) => c.contact_type.id === 2)
    );
  }, [listingDetails]);

  useEffect(() => {
    let query =
      "listing_status,agreement_type,motivation_ranking,tag,marketing_package,lead_source,ranking,property_type,property_category,construction_type,listing_lived_in,listing_lifestyle";
    getMenu(query)
      .then(({ data: { data } }) => {
        setListing_status(
          data.listing_status.filter((obj) => [1, 2, 4, 5, 12].includes(obj.id))
        );
        setProperty_category(data.property_category);
        setPropertyConstruction(data.construction_type ?? []);
        setAgreementTypes(data.agreement_type);
        setTag(data.tag);
        setMarketingPackages(data?.marketing_package);
        setMotivation_ranking(data.motivation_ranking);
        setLead_sources(data.lead_source);
        setRanking(data.ranking);
        setLifestyle(data.listing_lifestyle);
        setLived_in(data.listing_lived_in);
        setPropertyType(data.property_type);
      })
      .catch(() => {});
  }, []);

  // useEffect(() => {
  //   setMarketingPackages(marketing_package);
  // },[marketing_package])
  const handleAdjustPrice = (e) => {
    // console.log(e);
    setModalShow(false);
  };
  const [members, setMembers] = useState({});

  useEffect(() => {
    getAllMembers()
      .then(({ data: { data } }) => {
        // console.log(data, "menue data");
        setMembers(
          data.map((s) => ({
            name: s.initial,
            id: s.contact.id,
            optionTooltipTitle: `${s?.contact?.first_name ?? ""} ${s?.contact
              ?.last_name ?? ""}`,
          }))
        );
      })
      .catch((e) => {
        toast.error("Error while fetching");
      });
  }, []);

  function showSwalContainer() {
    const swalContainer = document.querySelector(".swal2-container");
    if (swalContainer) {
      swalContainer.style.display = "flex";
    }
  }

  function hideSwalContainer() {
    const swalContainer = document.querySelector(".swal2-container");
    if (swalContainer) {
      swalContainer.style.display = "contents";
    }
  }

  const handleCloseModal = () => {
    setIsModalOpen(false);
    showSwalContainer();
  };

  const onFormSubmitTestLetter = async (values) => {
    const formData = convertFormData(values);
    console.log(values)
    return await letterTest(formData)
      .then(({data}) => {
        // Destructure 'data' from the response
        if (data) {
          toast.success("Letter generated successfully");
          return data.data;
        }
      })
      .catch((e) => {
        console.error(e);
        toast.error(e.data.data[0]);
      });
  };


  async function handlePreviewProcess(
    previewButtonId,
    loadingSpinnerId = "none",
    payload
  ) {
    const previewButton = document.getElementById(previewButtonId);
    const loadingSpinner = document.getElementById(loadingSpinnerId);
    const bannerModal = document.getElementById("banner");
    loadingSpinner.style.display = "inline-block"; // Show loading spinner
    previewButton.style.display = "none"; // Hide button

    const emailHtml = await onFormSubmitTestLetter(payload);

    if (emailHtml) {
      handlePreviewClick(emailHtml);
    } else {
      console.error("Failed to generate email preview.");
    }

    loadingSpinner.style.display = "none"; // Hide loading spinner
    previewButton.style.display = "contents"; // Show button
  }

  function handlePreviewClick(emailHtml) {
    if (emailHtml) {
      const bannerModal = document.getElementById("banner");
      // bannerModal.style.display = "none";
      setEmailContent(emailHtml);
      setIsModalOpen(true);
      hideSwalContainer();
    } else {
      console.error("Failed to generate email preview.");
    }
  }

 /* const showOnWebsiteOnChange = (event) => {
    if (!contract_in_office) {
      toast.warning("Contract In Office must be enabled to enable this feature", { autoClose: 5000, closeButton: false });
      return false;
    } else if (event) {
      Swal.fire({
        title: '<span style="font-size:1.9em; font-weight:bold;">Send Bulk Email to Buyers?</span>', 
        html: ` <style> 
                  .loader {
                    border: 5px solid #f3f3f3;
                    border-top: 5px solid #822433;
                    border-radius: 50%;
                    width: 20px;
                    height: 20px;
                    animation: spin 2s linear infinite;
                  }

                  @keyframes spin {
                    0% { transform: rotate(0deg); }
                    100% { transform: rotate(360deg); }
                  }
                </style> 

                <div style='    display: flex;
                  justify-content: center;
                  align-items: center;
                  flex-direction: column;' id='banner'>
                  <span style="font-size:1.3em;">Do you want to send a bulk email to all buyers about this property?</span> 
                  
                  <span class="eye-icon" id="preview" style="color: black; margin-top: 10px"><i class="fas fa-eye"></i></span>
                  <div id="loadingSpinner" style="display: none;">
                    <div class="loader"></div>
                  </div>
                </div>`, 
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes, send it !',
        cancelButtonText: 'No, don\'t send',
        reverseButtons: true,
        didOpen: () => {
          const previewBtn = document.getElementById("preview");

          previewBtn.addEventListener("click", async (event) => {
            if(!listingDetails?.id){  
              toast.info("This preview is only available after creation.");
              return;
            }
            let payload = {
              listing: listingDetails.id,
              listed_edm: true,
            };
            handlePreviewProcess("preview", "loadingSpinner", payload);
            event.stopPropagation();
            event.preventDefault();
          });
        },
        onOpen: () => {
          const actions = Swal.getActions();
          actions.style.cssText = "display: flex; flex-direction: row; justify-content: space-between; width: 98%; padding: 0; margin-top: 20px; margin-left: 5px; margin-bottom: 5px; margin-right: 5px;"; 
          Swal.getCancelButton().style.cssText = "flex-grow: 1; margin: 0 5px; font-size: 1.2em;"; 
          Swal.getConfirmButton().style.cssText = "flex-grow: 1; margin: 0 5px; background-color: #2BB673; color: white; font-size: 1.2em;"; 
        },
        onRender: () => {
          const popup = Swal.getPopup();
          popup.style.padding = '0px';  // Remove padding
        }
      }).then((result) => {
        if (result.isConfirmed) {
          handleMail(true);
        } else {
          handleMail(false);
        }
      });
      
      
      
                
    }
    return true;
  };*/

  //send post-listed email
  const sendMicrositeNotListedEmailToProspect = (listing_id,type= 2) => {
    Swal.fire({
      title: '<span style="font-size:1.9em; font-weight:bold;">Confirmation</span>',
      html:  '<span style="font-size:1.3em;">Would you like to send the Post-Listing Microsite (Listed)?</span>',
      icon: "info",
      showCancelButton: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
      confirmButtonColor: "#A7A9AC",
      cancelButtonColor: "#2BB673",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      reverseButtons: true,
      onOpen: () => {
        const actions = Swal.getActions();
        actions.style.cssText = "display: flex; flex-direction: row; justify-content: space-between; width: 98%; padding: 0; margin-top: 20px; margin-left: 5px; margin-bottom: 5px; margin-right: 5px;";
        Swal.getCancelButton().style.cssText = "flex-grow: 1; margin: 0 5px; font-size: 1.2em;";
        Swal.getConfirmButton().style.cssText = "flex-grow: 1; margin: 0 5px; background-color: #2BB673; color: white; font-size: 1.2em;";
      },
      onRender: () => {
        const popup = Swal.getPopup();
        popup.style.padding = '0px';  // Remove padding
      }
    }).then(result => {
      if (result.isConfirmed) {
        sendMicrosteToProspectEmail(`${listing_id}?type=${type}&from=listing`)
            .then(({ data: { data } }) => {
              toast.success(data?.message || "Microsite email send successfully!");
            })
            .catch(error => {
              if (
                  error &&
                  (error.data?.data?.no_template === 1)
              ) {
                Swal.fire({
                  title: error?.data?.data?.title,
                  text:error?.data?.data?.message,
                  icon: "info",
                  showCancelButton: false,
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                  confirmButtonColor: "#2BB673",
                  cancelButtonColor: "#A7A9AC",
                  confirmButtonText: "Ok",
                  cancelButtonText: "No"
                });
              } else {
                toast.error(error.data?.data?.message || "Error on sending Microsites.");
              }
            });
      }
    });
  };

  const handleContractInOfficeChange = (val) => {
    if (!val) {
      changeProp("show_on_website", 0);
    }
  };

  return (
    <Fragment>
            {isModalOpen ? (
        <DefaultModal
          size="xl"
          centered
          show={isModalOpen}
          onHide={() => setPreview(false)}
          hide={true}
        >
          <EmailReportPreview
            closeModal={handleCloseModal}
            url={emailContent}
          />
        </DefaultModal>
      ) : (
        ""
      )}
      {isLoading && (
        <div style={{position: 'fixed', top: 0, left: 0, height: '100vh', width: '100vw', zIndex: 9999, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(0,0,0,0.3)'}}>
          <PulseLoader color={'#822433'} loading={isLoading} size={15} />
        </div>
      )}
      {modalShow ? (
        <DefaultModal
          size="xl"
          show={modalShow}
          onHide={() => setModalShow(false)}
        >
          <AdjustPrice
            selectedValues={(e) => handleAdjustPrice(e)}
            onHide={() => setModalShow(false)}
          />
        </DefaultModal>
      ) : null}
      <h5 className="title">Listing</h5>
      <Row className="pb-3">
        <Col lg={4}>
          <Field
            name="status"
            label="Status"
            component={MultiSelect}
            showBottom={false}
            isMulti={false}
            options={listing_status}
            error={agreement_type === 2 && status !== 4}
          />
        </Col>
        <Col lg={4}>
          <Field
            name="agreement_type"
            label="Agreement Type"
            component={MultiSelect}
            showBottom={false}
            isMulti={false}
            options={agreementTypes}
          />
        </Col>
        <Col lg={4}>
          <Row>
            <Col className="pb-1" sm={12}>
              <div className="row">
                <div className="col-6 pr-1">
                  <Field
                    name="starts"
                    label="Start Date"
                    type="date"
                    component={DateInput}
                  />
                </div>
                <div className="col-6 pl-0">
                  <Field
                    name="ends"
                    label="End Date"
                    type="date"
                    component={DateInput}
                    // validate={[required]}
                    showErrorOnMount={true}
                    helperText=" Required"
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="pb-3">
        <Col className="pb-1" lg={4}>
          <div className="row">
            {showAdjustPrice && edit ? (
              <Col>
                <ThemeButton
                  onClick={() => setModalShow(true)}
                  className="darkGrey-button w-100"
                >
                  Adjust Price
                </ThemeButton>
              </Col>
            ) : (
              <Fragment>
                <div className="col-6 pr-1">
                  <Field
                    name="price_from"
                    component={TextInputAmount}
                    placeholder="From"
                    label=" Price From"
                    validate={[number]}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                  />
                </div>

                <div className="col-6 pl-0">
                  <Field
                    name="price_to"
                    component={TextInputAmount}
                    placeholder="To"
                    label="Price To"
                    validate={[number]}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                  />
                </div>
              </Fragment>
            )}
          </div>
        </Col>

        <Col className="pb-1" lg={4}>
          <div className="row">
            <div className="col-6 pr-1">
              <Field
                name="group_price_from"
                component={TextInputAmount}
                placeholder="From"
                label="Group price From"
                validate={[number]}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                  inputComponent: NumberFormatCustom,
                }}
              />
            </div>
            <div className="col-6 pl-0">
              <Field
                name="group_price_to"
                component={TextInputAmount}
                placeholder="To"
                label="Group price To"
                validate={[number]}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                  inputComponent: NumberFormatCustom,
                }}
              />
            </div>
          </div>
        </Col>
        <Col className="pb-1" lg={4}>
          <Field
            name="price_view"
            component={TextInput}
            placeholder="Public Price View"
            label="Public Price View"
          />
        </Col>
      </Row>

      <Row className="pb-3">
        <Col className="pb-3 pb-lg-1 " lg={4}>
          <Field
            name="important_notice"
            component={TextInput}
            placeholder="Important Notice"
            label="Important Notice"
          />
        </Col>
        <Col className="pb-1 mt-auto" lg={4}>
          <Field
            name="show_on_website"
            component={ToggleSwitch}
            // customOnChange={showOnWebsiteOnChange}
            label="Show on Website:"
            labelLeft={true}
            labelClass="flex-grow-1 p-1"
          />
        </Col>
        <Col className="pb-1 mt-auto" lg={4}>
          <Field
            name="tenanted"
            component={ToggleSwitch}
            label="Tenanted:"
            labelLeft={true}
            labelClass="flex-grow-1 p-1"
          />
        </Col>
      </Row>
      <Row className="pb-3">
        <Col lg={4}>
          <Field
            name="tags"
            label="Tags"
            component={MultiSelect}
            showBottom={false}
            isMulti={true}
            options={tag}
          />
        </Col>
        <Col className="pb-1" lg={agency_agreement_check ? 2 : 4}>
          <Field
            name="agency_agreement_check"
            component={ToggleSwitch}
            customOnChange={togglePopup}
            label="Agency Agreement Check"
            labelLeft={true}
            labelClass="flex-grow-1 p-1"
          />
        </Col>{" "}
        {agency_agreement_check ? (
          <Col className="pb-1" lg={2}>
            {" "}
            <Field
              name="agency_agreement_check_date"
              type="date"
              component={DateInput}
            />
          </Col>
        ) : (
          ""
        )}
        <Col lg={4}>
          <Field
            name="organisation"
            label="Organisation"
            component={MultiSelect}
            showBottom={false}
            isMulti={false}
            options={associated_offices}
          />
        </Col>
        <Col lg={4}>
          <FormSection name="property">
            <Field
              name="category"
              label="Property Category"
              component={MultiSelect}
              showBottom={false}
              isMulti={false}
              options={property_category}
            />
          </FormSection>
        </Col>
        <Col className="pb-1 mt-auto" lg={4}>
          <Field
            name="price_display_portal"
            component={ToggleSwitch}
            label="Display Price Portal:"
            labelLeft={true}
            labelClass="flex-grow-1 p-1"
          />
        </Col>
        <Col>
          <Field
            name="show_full_address"
            label="Display"
            component={MultiSelect}
            showBottom={false}
            isMulti={false}
            options={landPreference}
          />
        </Col>
        <Col lg={4}>
          <Field
            name="construction_type"
            label="Construction Type"
            component={MultiSelect}
            showBottom={false}
            isMulti={false}
            options={propertyConstruction}
          />
        </Col>
        {listingDetails &&
          <Col lg={4}>
            <ThemeButton
                onClick={() => sendMicrositeNotListedEmailToProspect(listingDetails?.id)}
                className="darkGrey-button w-100"
            >Send Post Listing Microsite
            </ThemeButton>
          </Col>
        }
      </Row>
      <Divider className="divider" variant="middle" />
      <h5 className="title">Contract</h5>
      <Row className="pb-3">
        <Col className="pb-1" lg={4}>
          <Field
            name="fee_percent"
            component={TextInput}
            label="Fee"
            validate={[number, maxValue100]}
            error={fee_percent > 100 || fee_percent < 0}
            helperText={
              fee_percent > 100
                ? "The fee percentage should be less than 100"
                : ""
            }
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
          />
        </Col>

        <Col className="pb-1" lg={4}>
          {/* <FieldArray name="marketing" component={MarketingPackage} /> */}
          <div className="row">
            <div className="col-12 pr-1">
              <Field
                name="marketing_package"
                label="Marketing Package"
                component={MultiSelect}
                showBottom={false}
                isMulti={false}
                options={marketingPackages}
              />
            </div>
            {/* <div className="col-3 pl-0">
              <Field
                name="marketing_cost"
                // component={TextInput}
                component={TextInputAmount}
                disabled={marketingPackage === 1 ? false : true}
                validate={[number]}
                label="Amount"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            </div> */}
          </div>
        </Col>
        <Col className="pb-1" lg={4}>
          <Field
            name="vpa"
            type="number"
            component={TextInput}
            label="VPA"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
        </Col>
      </Row>
      <Row className="pb-3">
        <Col className="pb-1" lg={contract_in_office ? 2 : 4}>
          <Field
            name="contract_in_office"
            component={ToggleSwitch}
            onChange={handleContractInOfficeChange}
            label="Contract In Office:"
            labelLeft={true}
            labelClass="flex-grow-1 p-1"
          />
        </Col>{" "}
        {contract_in_office ? (
          <Col className="pb-1" lg={2}>
            {" "}
            <Field name="contract_date" type="date" component={DateInput} />
          </Col>
        ) : (
          ""
        )}
        <Col className="pb-1" lg={4}>
          <Field
            name="taxable_supply"
            component={ToggleSwitch}
            label="Taxable Supply:"
            labelLeft={true}
            labelClass="flex-grow-1 p-1"
          />
        </Col>
      </Row>
      <Divider className="divider" variant="middle" />
      <h5 className="title">Agents</h5>
      <Row className="pb-3">
        <Col className="pb-1" lg={4}>
          <Field
            name="listing_agent"
            label="Listing Agent"
            component={MultiSelect}
            options={members}
            isMulti={false}
            onChange={(newValue) => {
              if (newValue === "" && listingAgent.length > 0) {
                const currentDeletedContacts = [...deletedContact];
                currentDeletedContacts.push({
                  ...listingAgent[0],
                  deleteContact: 1,
                });
                setDeletedContact(currentDeletedContacts);
              }
            }}
          />
        </Col>
        <Col className="pb-1" lg={4}>
          <Field
            name="display_agent"
            label="Display Agent"
            component={MultiSelect}
            options={members}
            isMulti={false}
            onChange={(newValue) => {
              if (newValue === "" && displayAgent.length > 0) {
                const currentDeletedContacts = [...deletedContact];
                currentDeletedContacts.push({
                  ...displayAgent[0],
                  deleteContact: 1,
                });
                setDeletedContact(currentDeletedContacts);
              }
            }}
          />
        </Col>
        <Col className="pb-1" lg={4}>
          <Field
            name="co_listing_agent"
            label="Co-listing Agent"
            component={MultiSelect}
            options={members}
            isMulti={false}
            onChange={(newValue) => {
              if (newValue === "" && coListingAgent.length > 0) {
                const currentDeletedContacts = [...deletedContact];
                currentDeletedContacts.push({
                  ...coListingAgent[0],
                  deleteContact: 1,
                });
                setDeletedContact(currentDeletedContacts);
              }
            }}
          />
        </Col>
        <Col className="pb-1" lg={4}>
          <Field
            name="lead_source"
            label="Lead Sources"
            component={MultiSelect}
            showBottom={false}
            isMulti={false}
            options={lead_sources}
          />
        </Col>
        {lead_source === 1 ? (
          <Fragment>
            <Col className="pb-1" lg={4}>
              <Field
                name="lead_source_team"
                label="Team"
                component={MultiSelect}
                showBottom={false}
                isMulti={false}
                options={members}
              />
            </Col>
          </Fragment>
        ) : (
          ""
        )}
        {[5, 6, 13].includes(lead_source) ? ( //if the lead source id is 5 6 or 13 show details field
          <Fragment>
            <Col className="pb-1" lg={4}>
              <Field
                label="Details"
                name="lead_source_other"
                type="text"
                component={TextInput}
              />
            </Col>
          </Fragment>
        ) : (
          ""
        )}
        <Col className="pb-1" lg={4}>
          <Field
            label="Rea Listing Id"
            name="rea_listing_id"
            type="text"
            component={TextInput}
          />
        </Col>
        <Col className="pb-1" lg={4}>
          <Field
            label="Domain Listing Id"
            name="domain_listing_id"
            type="text"
            component={TextInput}
          />
        </Col>
      </Row>

        <>
          <Divider className="divider" variant="middle" />
          <h5 className="title">Sellers App</h5>

          {!hasSeller ? (
            <Row className="p-3">
              <Alert variant="danger" style={{ width: "100%", opacity: 0.8 }}>
                Please add Vendor contact details in the "Contacts" tab to activate the Sellers App.
              </Alert>
            </Row>
          ) : (
            <Row className="pb-3">
              <Col lg={4} className="pr-md-4">
                <Field
                  label="Username"
                  name="seller_app_username"
                  type="text"
                  component={TextInput}
                  placeholder="Username"
                  normalize={normalizeUsername}
                  helperText="Listing ID will be automatically add at end. Do not add number on end. Only a-z and . is acceptable."
                />
              </Col>

              <Col lg={4} className="pr-md-4">
                <Field
                  label="Password"
                  name="seller_app_password"
                  type="password"
                  component={TextInput}
                  placeholder="Enter New password (optional)"
                />
              </Col>
              <Col className="pb-1 mt-auto" lg={4}>
                <Field
                  name="seller_app_active"
                  component={ToggleSwitch}
                  label="Active:"
                  labelLeft={true}
                  labelClass="flex-grow-1 p-1"
                />
              </Col>
            </Row>
          )}
        </>

      {/* <Divider className="divider" variant="middle" /> */}
      <h5 className="title">Ranking</h5>
      <Row className="pb-3">
        <Col lg={4} className="pr-md-4">
          <Field
            name="ranking"
            label="Ranking"
            component={MultiSelect}
            showBottom={false}
            isMulti={false}
            options={motivation_ranking}
          />
        </Col>

        <Col lg={4}>
          <Field
            label="Motivation"
            name="motivation"
            type="text"
            component={TextInput}
            placeholder="Motivation"
          />
        </Col>
      </Row>
      <Divider className="divider" variant="middle" />
      {status === 4 ? (
        <Fragment>
          <h5 className="title">Auction</h5>
          <Row className="pb-3">
            <Col lg={4} className="pr-md-4">
              <Field
                name="auction_date"
                label="Date and Time"
                type="datetime-local"
                component={DateInput}
              />
            </Col>

            <Col lg={4}>
              <Field
                label="Venue"
                name="auction_location"
                type="text"
                component={TextInput}
                placeholder="Auction Venue"
              />
            </Col>
          </Row>
        </Fragment>
      ) : (
        ""
      )}
      <Divider className="divider" variant="middle" />
      <h5 className="title">Additional</h5>
      <Row className="pb-3">
        <Col lg={4}>
          <FormSection name="property">
            <Field
              name="type"
              label="Property Type"
              component={MultiSelect}
              showBottom={false}
              isMulti={false}
              options={propertyType}
            />
          </FormSection>
        </Col>

        <Col lg={4} className="pt-3">
          <Field
            name="is_vacant"
            component={ToggleSwitch}
            label="Vacant:"
            labelLeft={true}
            labelClass="flex-grow-1 p-1"
          />
        </Col>
        <Col lg={4} className="pt-3">
          <Field
            name="troubled_listing"
            component={ToggleSwitch}
            label="Troubled Listing:"
            labelLeft={true}
            labelClass="flex-grow-1 p-1"
          />
        </Col>
      </Row>
      <Row className="pb-3">
        <Col lg={4}>
          <Field
            name="lived_in"
            label="Lived In"
            component={MultiSelect}
            showBottom={false}
            isMulti={false}
            options={lived_in}
          />
        </Col>
        <Col lg={4}>
          <Field
            name="lifestyle"
            label="Lifestyle Type:"
            component={MultiSelect}
            showBottom={false}
            isMulti={false}
            options={lifestyle}
          />
        </Col>
        <Col lg={4}>
          <Field
            label="Weekly Rent Return"
            name="weekly_rent_return"
            // component={TextInput}
            component={TextInputAmount}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
        </Col>
      </Row>
      <Row className="pb-3">
        <Col lg={4}>
          <Field
            label="Council Rates"
            name="council_rate"
            // component={TextInput}
            component={TextInputAmount}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
        </Col>
        <Col lg={4}>
          <Field
            label="Water Rates"
            name="water_rate"
            // component={TextInput}
            component={TextInputAmount}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
        </Col>
        <Col lg={4}>
          <Field
            label="Strata Rates"
            name="strata_rate"
            // component={TextInput}
            component={TextInputAmount}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
        </Col>
      </Row>
      <Row className="pb-3">
        <Col lg={4}>
          <Field
            label="Key Number"
            name="key_number"
            type="text"
            component={TextInput}
          />
        </Col>
        <Col lg={4}>
          <Field
            label="Alarm Number"
            name="alarm_number"
            type="text"
            component={TextInput}
          />
        </Col>
        {tenanted === 1 ? (
          <Fragment>
            <Col lg={4}>
              <Field
                label="Lease Expiry"
                name="lease_expiry"
                type="date"
                component={DateInput}
              />
            </Col>
          </Fragment>
        ) : (
          ""
        )}
      </Row>

      {showPopup && (
        <DefaultModal
          show={showPopup}
          onHide={() => {
            setShowPopup(false);
          }}
          title="Upload your Agency Agreement"
          size="lg"
        >
          <Fragment>
            <DragAndDrop handleUploadFiles={handleUploadFiles} 
                          multiple={false}  
            />
            <Fragment>
                  <Fragment>
                    <div className="pl-2">
                      <ThemeButton
                        className="lightGrey-button font-weight-bold"
                        onClick={() => setShowPopup(false)}
                      >
                        Cancel
                      </ThemeButton>
                    </div>
                  </Fragment>
                </Fragment>
          </Fragment>
        </DefaultModal>
      )}
    </Fragment>
  );
};

AddNewListingDetails.defaultProps = {
  showAdjustPrice: false,
  edit: true,
  shouldValidate: () => true,
};

export default AddNewListingDetails;

