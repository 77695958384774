import React, { Fragment, useState, useEffect } from "react";
import { Form, Col } from "react-bootstrap";
import { Field } from "redux-form";
import { Spin } from "antd";

import TextInput from "../TextInput";
import ToggleSwitch from "../toggleSwitch/ToggleSwitch";
import DateInput from "../DateInput";
import AutoComplete from "../AutoComplete";
import { formToQueryString } from "../../../_helpers/_utils/utils";
import { getAllContacts } from "../../../modules/contacts/crud/contactsCrud";
import PropertyAddressSwitch from "../PropertyAddressSwitch";
//Field validation

const ContactFormPersonalDetails = ({
  fieldArray,
  selectedContact = () => {},
  profileId,
  change,
  formSectionName,
  profileLoading,
  selectedOption,
  index,
  auto,
  highlightContact = false,
  // profile
}) => {
  const [options, setOptions] = useState([]);

  const handleOnchange = (e) => {
    if (e.target.value) {
      let query = formToQueryString({ name: e.target.value });
      getAllContacts(query)
        .then(({ data: { data } }) => {
          if (highlightContact) {
            data.sort((a, b) => b.is_buyer_app - a.is_buyer_app);
          }
          setOptions(
            data.map((obj) => ({
              name: obj.first_name + "" + obj.last_name,
              ...obj,
            }))
          );
        })
        .catch((e) => {});
    } else {
      setOptions([]);
    }
  };

  const handleSelectedContact = (e) => {
    if (index || auto) {
      selectedOption(e, index);
    } else {
      selectedContact(e);
    }
  };
  return (
    <Spin spinning={profileLoading} tip={"Loading..."} className="mt-4">
      <Fragment>
        <h5 className="title">Personal Details</h5>

        <Form.Row className="pb-3">
          <Col md={4} id={fieldArray}>
            <Field
              name={fieldArray ? `${fieldArray}.first_name` : "first_name"}
              handleOnchange={handleOnchange}
              options={options}
              type="text"
              selectedOption={handleSelectedContact}
              component={AutoComplete}
              placeholder="First Name"
              showErrorOnMount={true}
              label="First Name"
              highlight={highlightContact}
              required
            />
          </Col>

          <Col md={4}>
            {profileId ? (
              <Field
                name={fieldArray ? `${fieldArray}.last_name` : "last_name"}
                type="text"
                component={TextInput}
                placeholder="Last Name"
                label="Last Name"
              />
            ) : (
              <Field
                name={fieldArray ? `${fieldArray}.last_name` : "last_name"}
                handleOnchange={handleOnchange}
                options={options}
                type="text"
                selectedOption={handleSelectedContact}
                component={AutoComplete}
                placeholder="Last Name"
                label="Last Name"
                showErrorOnMount={true}
                highlight={highlightContact}
                required
              />
            )}
          </Col>
          <Col md={4}>
            <Field
              name={fieldArray ? `${fieldArray}.middle_name` : "middle_name"}
              type="text"
              component={TextInput}
              label="Middle Name"
              placeholder="Middle Name"
            />
          </Col>
        </Form.Row>

        <Form.Row className="pb-3">
          <Col md={4}>
            <Field
              name={
                fieldArray ? `${fieldArray}.preferred_name` : "preferred_name"
              }
              type="text"
              component={TextInput}
              placeholder="Preferred Name"
              label="Preferred Name"
            />
          </Col>

          <Col md={4}>
            <Field
              name={fieldArray ? `${fieldArray}.dob` : "dob"}
              component={DateInput}
              type="date"
              label="Date of Birth"
              placeholder="Date of Birth"
            />
          </Col>
        </Form.Row>

        {/* <FieldArray
        name={fieldArray ? `${fieldArray}.addresses` : "addresses"}
        label="Address"
        component={Address}
      /> */}
        {/* <PropertyFullAddress
        changeProp={change}
        placeHolder='Address'
        name={fieldArray ? `${fieldArray}.addresses` : "addresses"}
        formSectionName={formSectionName}
      /> */}

        <PropertyAddressSwitch
          changeProp={change}
          formSectionName={formSectionName}
          name={fieldArray ? `${fieldArray}.addresses` : "addresses"}
          placeHolder="Address"
          label="Address"
          // defaultOptions={{ label: profile?.addresses?.[0]?.full_address, value: profile?.addresses?.[0]?.id }}
        />

        <Form.Row className="pb-3">
          <Col md={4}>
            <Field
              name={fieldArray ? `${fieldArray}.profession` : "profession"}
              type="text"
              component={TextInput}
              placeholder="Profession"
              label="Profession"
            />
          </Col>
          <Col md={4}>
            <Field
              name={fieldArray ? `${fieldArray}.company_name` : "company_name"}
              type="text"
              component={TextInput}
              label="Company Name"
              placeholder="Company Name"
            />
          </Col>
          <Col md={4}>
            <Field
              name={fieldArray ? `${fieldArray}.job_role` : "job_role"}
              type="text"
              component={TextInput}
              label="Role"
              placeholder="Role"
            />
          </Col>
        </Form.Row>

        {/* <FieldArray
        name={fieldArray ? `${fieldArray}.workAddress` : "workAddress"}
        component={Address}
        label="Work Address"
        singleData={true}
      /> */}
        {/* <PropertyFullAddress
        changeProp={change}
        placeHolder='Work Address'
        name={fieldArray ? `${fieldArray}.workAddress` : "workAddress"}
        formSectionName={formSectionName}
      /> */}

        <PropertyAddressSwitch
          changeProp={change}
          name={fieldArray ? `${fieldArray}.workAddress` : "workAddress"}
          formSectionName={formSectionName}
          placeHolder="Work Address"
          label="Work Address"
          singleData={true}
          // defaultOptions={{ label: profile?.addresses?.[1]?.full_address, value: profile?.addresses?.[1]?.id }}
        />

        <Form.Row className="pb-3 pt-3">
          <Col>
            <div className="d-flex justify-content-end">
              <div className="d-flex">
                <Form.Label className="pr-3 min-content pt-0" column sm={4}>
                  Servicing Our Office:
                </Form.Label>
                <Field
                  name={
                    fieldArray ? `${fieldArray}.is_servicer` : "is_servicer"
                  }
                  component={ToggleSwitch}
                />
              </div>
            </div>
          </Col>
        </Form.Row>
      </Fragment>
    </Spin>
  );
};

ContactFormPersonalDetails.defaultProps = {
  fieldArray: "",
  profileLoading: false,
};
export default ContactFormPersonalDetails;
