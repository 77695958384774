import React from "react";
import {
  Box,
  Typography,
  Paper,
  withStyles,
  makeStyles,
} from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    border: `1px solid ${theme.palette.divider}`,
    flexWrap: "wrap",
  },
  divider: {
    margin: theme.spacing(1, 0.5),
  },
}));

const StyledToggleButtonGroup = withStyles((theme) => ({
  grouped: {
    margin: theme.spacing(0.5),
    border: "none",
    "&:not(:first-child)": {
      borderRadius: theme.shape.borderRadius,
    },
    "&:first-child": {
      borderRadius: theme.shape.borderRadius,
    },
  },
}))(ToggleButtonGroup);

const FilterOriginToggle = ({ options, value, changeValue, border = true }) => {
  const classes = useStyles();

  return (
    <Box className="p-2">
      <Paper elevation={0} className={classes.paper}>
        <StyledToggleButtonGroup
          value={value}
          exclusive
          onChange={(e, v) => changeValue(v)}
          aria-label="Toggle Filter"
          style={{ height: 34 }}
          className="filter-toggle"
        >
          {options.map((opt, index) => (
            <ToggleButton
              key={index}
              value={opt.value}
              aria-label={`Filter by ${opt.label}`}
              style={{
                minWidth: 100,
              }}
              className="no-border"
            >
              <Typography
                variant="body1"
                style={{ textTransform: "capitalize" }}
              >
                {opt.label}
              </Typography>
            </ToggleButton>
          ))}
        </StyledToggleButtonGroup>
      </Paper>
    </Box>
  );
};
export default FilterOriginToggle;
