import { InputAdornment } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Field } from "redux-form";
import { getAllSuburbs, getMenu } from "../../../GeneralApi/GeneralApi";
import BuyerCustomMultiSelect from "../../../modules/buyers/modals/buyerProfileModal/details/BuyerCustomMultiSelect";
import VerticalAsyncMultiSelect from "../../../modules/buyers/partials/customMultiSelect/VerticalAsyncMultiSelect";
import VerticalMultiSelect from "../../../modules/buyers/partials/customMultiSelect/VerticalMultiSelect";
import { formToQueryString } from "../../../_helpers/_utils/utils";
import Item from "../../display/Item";
import AsyncMultiSelect from "../AsyncMultiSelect";
import MultiSelect from "../MultiSelect";
import TextInput from "../TextInput";
import TextInputAmount from "../TextInputAmount";

const getOptionSuburbLabel = (option) => (
  <Fragment>
    {option.label ? `${option.label} ${option?.state_name}` : ""}
  </Fragment>
);
const FormBuyerPreferences = () => {
  const [property_type, setProperty_type] = useState({});

  useEffect(() => {
    let query = "property_type";
    getMenu(query)
      .then(({ data: { data } }) => {
        setProperty_type(data.property_type);
      })
      .catch(() => {});
  }, []);

  const getSuburbOptions = (text) => {
    let query = formToQueryString({ name: text });

    return getAllSuburbs(query)
      .then(({ data }) => {
        console.log(data.data);
        let options = data.data.map((item) => ({
          value: item.id,
          label: item.name,
          state_id: item.state.id,
          state_name: item.state.abbrev,
          postcode: item.postcode,
        }));
        console.log(options);
        return options;
      })
      .catch((e) => {});
  };

  return (
    <Row className="pb-3">
      <Col xl="12">
        <h5 className="title font-weight-bold">Preferences</h5>
      </Col>
      <Col xl="12">
        <Row>
          <Col md="4">
            <Row>
              <Col md="6">
                <Field
                  name="price_from"
                  type="text"
                  component={TextInputAmount}
                  placeholder="From"
                  label="From"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                />
              </Col>
              <Col md="6">
                <Field
                  name="price_to"
                  type="text"
                  component={TextInputAmount}
                  placeholder="To"
                  label="To"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                />
              </Col>
            </Row>
          </Col>
          <Col md="4">
            <Field
              label="Locations"
              name="suburbs"
              placeholder="Locations"
              component={AsyncMultiSelect}
              showBottom={false}
              isMulti={true}
              getOptionLabel={getOptionSuburbLabel}
              options={getSuburbOptions}
            />
          </Col>
          <Col md="4">
            <Field
              name="property_types"
              placeholder="Select a property type"
              component={MultiSelect}
              showBottom={false}
              isMulti={true}
              options={property_type}
              label="Property Types"
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default FormBuyerPreferences;
