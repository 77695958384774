
import React, { Fragment } from 'react';
import { Form } from 'react-bootstrap';
import Dictaphone from '../../dictaphone/Dictaphone';



const ToDoTextInput = ({
  disabled,
  edit,
  label,
  name,
  width,
  type,
  placeholder,
  meta: { touched, error },
  input,
  definedRef,
  className
}) => {

  const handleVoiceToSpeech = (e) => {
   
    input.onChange(e)

  };

  return (
    <Fragment>
      <Fragment>
        <div className="d-flex flex-grow-1">
        <Form.Control
        size="sm"
          type={type}
          placeholder={placeholder}
          disabled={disabled ? true : false}
          {...input}
          isInvalid={touched && error}
        />
        <Dictaphone handleVoiceToSpeech={(e) => handleVoiceToSpeech(e)} />
        </div>

      </Fragment>

    </Fragment>



  )

};

export default ToDoTextInput;