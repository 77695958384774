import React, { Fragment, useEffect, useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { IconButton } from "@material-ui/core";
import { Field, FieldArray } from "redux-form";
import Swal from "sweetalert2";
import { formToQueryString } from "../../../_helpers/_utils/utils";
import { getAllContacts, getContactById } from "../../../modules/contacts/crud/contactsCrud";
import { getAllProspects } from "../../../modules/prospect/curd/prospectsCrud";
import { getAllBuyers } from "../../../modules/buyers/crud/buyersCrud";
import MultiSelect from "../MultiSelect";
import TextInput from "../TextInput";
import AutoComplete from "../AutoComplete";
import NestedPhoneEmailAutoComplete from "../NestedPhoneEmailAutoComplete";
import { required } from "../../../modules/validation/Validation";
import NestedPhoneEmail from "../NestedPhoneEmail";
import LoadingSkeleton from "../../display/skeleton/LoadingSkeleton";
import { getMenu } from "../../../GeneralApi/GeneralApi";
import ThemeButton from "../../button/ThemeButton";


const QuickContactBody = ({
  fields,
  change,
  selectedContact,
  meta: { error, submitFailed },
  label,
  sendBuyer,
  setFillFormData,
  contactDetails,
  // setContactUpdate
}) => {
  const [options, setOptions] = useState({});
  const [phoneOptions, setPhoneOptions] = useState({});
  const [emailOptions, setEmailOptions] = useState({});
  const [contactLoaded, setContactLoaded] = useState([]);
  useEffect(() => {
    let query = "relationship,phone_type,email_type";
    getMenu(query)
      .then(({ data: { data } }) => {
        console.log(data);
        setPhoneOptions(data.phone_type);
        setEmailOptions(data.email_type);
        setOptions(data.relationship);
      })
      .catch(() => {});
  }, []);

  // useEffect(() => {
  //   console.log("refreshed_");
  // }, [fields]);

  const [contactsList, setContactsList] = useState([]);
  const handleOnchange = (e) => {
    if (e.target.value) {
      let query = formToQueryString({ name: e.target.value });
      getAllContacts(query)
        .then(({ data: { data } }) => {
          setContactsList(
            data.map((obj) => ({
              name: obj.first_name + "" + obj.last_name,
              ...obj,
            }))
          );
        })
        .catch((e) => {});
    } else {
      setOptions([]);
    }

    console.log(e.target.value);
  };

;



  useEffect(() => {
    if(contactDetails != null){
      handleSelectedContact(contactDetails,0)
    }
  }, [contactDetails])


  const handleSelectedContact = async (e, index) => {
    console.log(e);

    // const buyer = await handleSearchBuyerByContact (e.id)
    // console.log(buyer)
    if (e) {
      Swal.fire({
        title: "Update Contact?",
        text:
          "This will open " +
          e?.first_name +
          " " +
          e?.last_name +
          `'s contact details so you can update${contactDetails ?'.' :' or use their contact.'}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#A7A9AC",
        cancelButtonColor: "#2BB673",
        confirmButtonText: "Cancel",
        cancelButtonText: "Yes",
      }).then((result) => {
        console.log(result);
        if (result.isDismissed) {
          let contactInfo;
          getContactById(e.id)
            .then(({ data }) => {
              if (setFillFormData) {
                const contactdata = data.data;

                let query = formToQueryString({ contact: e.id });
                getAllProspects(query)
                  .then(({ data: { data } }) => {
                    let obj = {};
                    for (let i = 0; i < data.length; i++) {
                      obj[data[i]?.id] =
                        data[i]?.property?.address?.street_number +
                        data[i]?.property?.address?.street?.name;
                    }
                    console.log("data_length", data, data?.length);
                    if (data?.length > 0) {
                      console.log("data_length  larger", data);

                      Swal.fire({
                        title: "Select Prospect",
                        input: "radio",
                        inputOptions: obj,
                        inputValidator: (value) => {
                          if (!value) {
                            return "You need to choose something!";
                          }
                        },
                      }).then((d) => {
                        if (d) {
                          Swal.fire(
                            `You selected: ${
                              data?.find((da) => da?.id == d?.value)?.property
                                ?.address?.street?.name
                            }`
                          );
                          // console.log(
                          //   data?.find((da) => da?.id == d?.value),
                          //   data,
                          //   "data_selected_",
                          //   d
                          // );

                          setFillFormData({
                            // app_type: 4,
                            contacts: [
                              {
                                emails:
                                  contactdata?.emails?.length > 0
                                    ? contactdata?.emails
                                    : [{ type: 1, value: "" }],
                                phones:
                                  contactdata?.phones?.length > 0
                                    ? contactdata?.phones
                                    : [{ type: 1, value: "" }],
                                first_name: contactdata?.first_name,
                                last_name: contactdata?.last_name,
                              },
                            ],
                            prospect_address: {
                              meeting_location: [
                                {
                                  street_unit: data?.find(
                                    (da) => da?.id == d?.value
                                  )?.property?.address?.street_unit,
                                  street_number: data?.find(
                                    (da) => da?.id == d?.value
                                  )?.property?.address?.street_number,
                                  street_name: {
                                    value: data?.find(
                                      (da) => da?.id == d?.value
                                    )?.property?.address?.street?.id,
                                    label: data?.find(
                                      (da) => da?.id == d?.value
                                    )?.property?.address?.street?.name,
                                    suburb_name: data?.find(
                                      (da) => da?.id == d?.value
                                    )?.property?.address?.street?.suburb?.name,
                                    suburb_id: data?.find(
                                      (da) => da?.id == d?.value
                                    )?.property?.address?.street?.suburb?.id,
                                    state_id: data?.find(
                                      (da) => da?.id == d?.value
                                    )?.property?.address?.street?.suburb?.state
                                      ?.id,
                                    state_name: data?.find(
                                      (da) => da?.id == d?.value
                                    )?.property?.address?.street?.suburb?.state
                                      ?.name,
                                    postcode: data?.find(
                                      (da) => da?.id == d?.value
                                    )?.property?.address?.street?.suburb
                                      ?.postcode,
                                  },
                                  suburb: {
                                    value: data?.find(
                                      (da) => da?.id == d?.value
                                    )?.property?.address?.street?.suburb?.id,
                                    label: data?.find(
                                      (da) => da?.id == d?.value
                                    )?.property?.address?.street?.suburb?.name,
                                  },
                                  state: {
                                    label: data?.find(
                                      (da) => da?.id == d?.value
                                    )?.property?.address?.street?.suburb?.state
                                      ?.name,
                                    value: data?.find(
                                      (da) => da?.id == d?.value
                                    )?.property?.address?.street?.suburb?.state
                                      ?.id,
                                  },
                                  postcode: data?.find(
                                    (da) => da?.id == d?.value
                                  )?.property?.address?.street?.suburb
                                    ?.postcode,
                                },
                              ],
                            },
                          });
                        }
                      });
                    } else {

                      setFillFormData({
                        // app_type: 4,
                        contacts: [
                          {
                            emails:
                              contactdata?.emails.length > 0
                                ? contactdata?.emails
                                : [{ type: 1, value: "" }],
                            phones:
                              contactdata?.phones.length > 0
                                ? contactdata?.phones
                                : [{ type: 1, value: "" }],
                            first_name: contactdata?.first_name,
                            last_name: contactdata?.last_name,
                          },
                        ],

                        prospect_address: {
                          meeting_location: [
                            {
                              street_unit:
                                contactdata?.addresses?.[0]?.street_unit,
                              street_number:
                                contactdata?.addresses?.[0]?.street_number,
                              street_name: {
                                value: contactdata?.addresses?.[0]?.street?.id,
                                label:
                                  contactdata?.addresses?.[0]?.street?.name,
                                suburb_name:
                                  contactdata?.addresses?.[0]?.street?.suburb
                                    ?.name,
                                suburb_id:
                                  contactdata?.addresses?.[0]?.street?.suburb
                                    ?.id,
                                state_id:
                                  contactdata?.addresses?.[0]?.street?.suburb
                                    ?.state?.id,
                                state_name:
                                  contactdata?.addresses?.[0]?.street?.suburb
                                    ?.state?.name,
                                postcode:
                                  contactdata?.addresses?.[0]?.street?.suburb
                                    ?.postcode,
                              },
                              suburb: {
                                value:
                                  contactdata?.addresses?.[0]?.street?.suburb
                                    ?.id,
                                label:
                                  contactdata?.addresses?.[0]?.street?.suburb
                                    ?.name,
                              },
                              state: {
                                label:
                                  contactdata?.addresses?.[0]?.street?.suburb
                                    ?.state?.name,
                                value:
                                  contactdata?.addresses?.[0]?.street?.suburb
                                    ?.state?.id,
                              },
                              postcode:
                                contactdata?.addresses?.[0]?.street?.suburb
                                  ?.postcode,
                            },
                          ],
                        },
                      });
                    }
                  })
                  .catch((e) => {});
              } else {
                // debugger
                contactInfo = data?.data;
                let filedValue = fields.getAll();
                filedValue[index].first_name = contactInfo?.first_name;
                filedValue[index].last_name = contactInfo?.last_name;
                filedValue[index].phones =
                  contactInfo?.phones?.length > 0
                    ? contactInfo?.phones?.map(({ type, ...obj }) => ({
                        type: type.id,
                        ...obj,
                      }))
                    : [{ type: 1, value: "" }];
                filedValue[index].emails =
                  contactInfo?.emails?.length > 0
                    ? contactInfo?.emails?.map(({ type, ...obj }) => ({
                        type: type.id,
                        ...obj,
                      }))
                    : [{ type: 1, value: "" }];
                filedValue[index].contact_id = contactInfo?.id;
                console.log(filedValue);
                fields.removeAll();
                filedValue.map((obj) => fields.push(obj));
                setContactLoaded(contactLoaded.concat(index));
                // setContactUpdate(filedValue);
              }
            })
            .catch((e) => {});
        }
      });
    }
  };
  const handleRemove = (index) => {
    Swal.fire({
      title: "Remove Contact?",
      text: "This will remove the additional contact details",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#A7A9AC",
      cancelButtonColor: "#2BB673",
      confirmButtonText: "Cancel",
      cancelButtonText: "Yes",
    }).then((result) => {
      if (result.isDismissed) {
        fields.remove(index);
      }
    });
  };

  const handleSearchBuyerByContact = async (value) => {
    let buyer;

    if (value) {
      let obj = {
        contact: value,
      };

      getAllBuyers(formToQueryString(obj))
        .then(({ data: { data } }) => {
          buyer = data;
        })
        .catch((e) => {});
    }

    return buyer;
  };

  return (
    <Fragment>
      <Fragment>
        { contactDetails == null && fields.map((contacts, index) => {
          // console.log(index, "index_ ", index % 2 == 0);
          return (
            <Form.Group
              key={index}
              as={Row}
              className={
                "mb-0  " + (index % 2 == 0 ? "" : "lightBlue-background")
              }
            >
              <Col>
                {index != 0 ? (
                  <Row className="pb-2">
                    <Col md={6}>
                      <Field
                        name={`${contacts}.relationship`}
                        component={MultiSelect}
                        showBottom={false}
                        isMulti={false}
                        options={options}
                        label="Relationship"
                      />
                    </Col>
                    <Col md={6}>
                      <p>
                        <IconButton
                          onClick={() => handleRemove(index)}
                          size="small"
                          className="brand-icon-button"
                        >
                          <i className="fas fa-minus-circle"></i>
                        </IconButton>
                        Remove Contact
                      </p>
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
                <Row className="pb-2">
                  {" "}
                  <Col md={6}>
                    {contactLoaded.includes(index) ? (
                      <Field
                        name={`${contacts}.first_name`}
                        component={TextInput}
                        label="First Name"
                        validate={[required]}
                        showErrorOnMount={true}
                      />
                    ) : (
                      <Field
                        name={`${contacts}.first_name`}
                        handleOnchange={handleOnchange}
                        options={contactsList}
                        type="text"
                        selectedOption={(e) => handleSelectedContact(e, index)}
                        component={AutoComplete}
                        placeholder="First Name"
                        label="First Name"
                        showErrorOnMount={true}
                        validate={[required]}
                      />
                    )}
                  </Col>
                  <Col md={6}>
                    <Field
                      name={`${contacts}.last_name`}
                      component={TextInput}
                      label="Last Name"
                      placeholder="Last Name"
                      validate={[required]}
                      showErrorOnMount={true}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    {phoneOptions.length > 0 ? (
                      setFillFormData ? (
                        <FieldArray
                          options={phoneOptions}
                          mobile
                          name={`${contacts}.phones`}
                          component={NestedPhoneEmailAutoComplete}
                          contactFields={fields}
                          // validate={[required]}
                          setFillFormData={setFillFormData}
                          showErrorOnMount={true}
                          type="tel"
                        />
                      ) : (
                        <FieldArray
                          options={phoneOptions}
                          mobile
                          name={`${contacts}.phones`}
                          component={NestedPhoneEmail}
                          type="tel"
                          contactFields={fields}
                          // validate={[required]}
                          setFillFormData={setFillFormData}
                          showErrorOnMount={true}
                        />
                      )
                    ) : (
                      <LoadingSkeleton />
                    )}
                  </Col>

                  <Col lg={6}>
                    {emailOptions.length > 0 ? (
                      setFillFormData ? (
                        <FieldArray
                          options={emailOptions}
                          email
                          name={`${contacts}.emails`}
                          // validate={[required]}
                          component={NestedPhoneEmailAutoComplete}
                          setFillFormData={setFillFormData}
                          showErrorOnMount={true}
                        />
                      ) : (
                        <FieldArray
                          options={emailOptions}
                          email
                          name={`${contacts}.emails`}
                          component={NestedPhoneEmail}
                          type="email"
                          // validate={[required]}
                          showErrorOnMount={true}
                        />
                      )
                    ) : (
                      <LoadingSkeleton />
                    )}
                  </Col>
                </Row>
              </Col>
            </Form.Group>
          );
        })}

       {contactDetails ?(
fields.map((contacts, index)=>{

return(
  <Form.Group
              key={index}
              as={Row}
              className={
                "mb-0  " + (index % 2 == 0 ? "" : "lightBlue-background")
              }
            >
<Row className="pb-2">
       <Col lg={6}>
       <Field
  name={`${contacts}.first_name`}
  component={TextInput}
  label="First Name"
  // validate={[required]}
  // showErrorOnMount={true}
/>
       </Col>
       <Col md={6}>
                    <Field
                      name={`${contacts}.last_name`}
                      component={TextInput}
                      label="Last Name"
                      placeholder="Last Name"
                      // validate={[required]}
                      // showErrorOnMount={true}
                    />
                  </Col>

  </Row>
  <Row>
                  <Col lg={6}>
                    {phoneOptions.length > 0 ? (
                      setFillFormData ? (
                        <FieldArray
                          options={phoneOptions}
                          mobile
                          name={`${contacts}.phones`}
                          component={NestedPhoneEmailAutoComplete}
                          contactFields={fields}
                          // validate={[required]}
                          setFillFormData={setFillFormData}
                          showErrorOnMount={true}
                          type="tel"
                        />
                      ) : (
                        <FieldArray
                          options={phoneOptions}
                          mobile
                          name={`${contacts}.phones`}
                          component={NestedPhoneEmail}
                          type="tel"
                          contactFields={fields}
                          // validate={[required]}
                          setFillFormData={setFillFormData}
                          showErrorOnMount={true}
                        />
                      )
                    ) : (
                      <LoadingSkeleton />
                    )}
                  </Col>

                  <Col lg={6}>
                    {emailOptions.length > 0 ? (
                      setFillFormData ? (
                        <FieldArray
                          options={emailOptions}
                          email
                          name={`${contacts}.emails`}
                          // validate={[required]}
                          component={NestedPhoneEmailAutoComplete}
                          setFillFormData={setFillFormData}
                          showErrorOnMount={true}
                        />
                      ) : (
                        <FieldArray
                          options={emailOptions}
                          email
                          name={`${contacts}.emails`}
                          component={NestedPhoneEmail}
                          type="email"
                          // validate={[required]}
                          showErrorOnMount={true}
                        />
                      )
                    ) : (
                      <LoadingSkeleton />
                    )}
                  </Col>
                </Row>
            </Form.Group>


)
  })

        ):("")}
      </Fragment>
    </Fragment>
  );
};

export default QuickContactBody;


