import React, { useEffect, useState } from "react";

import cuid from "cuid";
import {
  Box,
  Button,
  IconButton,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from "@material-ui/core";

import { AddCircle } from "@material-ui/icons";

import { Row } from "react-bootstrap";

import { getAllCommunityInvolvement } from "../../../setting/_crud/settingCrud";

import MicrositeToggle from "./MicrositeToggle";
import Divider from "@material-ui/core/Divider";

const MicrositeCommunityInvolvement = ({
  value,
  selected,
  onChange,
  onSelect,
  CommunityInvolvement,
  setCommunityInvolvement,
  filteredCommunityInvolvement,
  setFilteredCommunityInvolvement,
  disabled,
  refetch = false,
  setRefetch = () => {},
  showModal = () => {},
}) => {
  const [searchCommunityInvolvement, setSearchCommunityInvolvement] = useState("");
  const [hidingClass, setHidingClass] = useState("d-none");


  useEffect(() => {
    fetchCommunityInvolvement();
  }, []);

  useEffect(() => {
    if (refetch) {
      setRefetch(false);
      fetchCommunityInvolvement();
    }
  }, [refetch]);

  useEffect(() => {
    if (!searchCommunityInvolvement) {
      setFilteredCommunityInvolvement(CommunityInvolvement);
    } else {
      setFilteredCommunityInvolvement(
        CommunityInvolvement.filter(
          (community) =>
            String(community.title)
              .toLowerCase()
              .includes(searchCommunityInvolvement) ||
            community?.contacts?.filter(
              (tc) =>
                String(tc.first_name)
                  .toLowerCase()
                  .includes(searchCommunityInvolvement) ||
                String(tc.last_name)
                  .toLowerCase()
                  .includes(searchCommunityInvolvement)
            )?.length > 0
        )
      );
    }
  }, [searchCommunityInvolvement]);

  const fetchCommunityInvolvement = () => {
    getAllCommunityInvolvement("limit=100")
      .then((res) => {
        if (res.status === 200) {
          setCommunityInvolvement(res.data.data);
          setFilteredCommunityInvolvement(res.data.data);
        }
      })
      .catch((err) => console.log("error loading testimonails", err));
  };

  return (
    <>
      <MicrositeToggle
        label="Community Involvement"
        name="community_involvement"
        disabled={disabled}
        value={value}
        onChange={onChange}
      >
        <Box marginRight={2}>
          <IconButton onClick={() => showModal(true)}>
            <AddCircle style={{ color: "#822433" }} />
          </IconButton>
        </Box>
      </MicrositeToggle>

      {!disabled && value ? (
        <Box className="show_more_box">
          <TextField
            name=" community_involvement_quick_search"
            label={false}
            placeholder="Quick search name or agent"
            className="w-100"
            disabled={disabled}
            onChange={(e) => setSearchCommunityInvolvement(e.target.value)}
            value={searchCommunityInvolvement}
          />
          {filteredCommunityInvolvement?.length > 0 ? (
            <>
              {filteredCommunityInvolvement.map((community, index) => (
                <Row
                  className={`${index > 4 ? hidingClass : ""}`}
                  key={cuid()}
                  style={{ marginLeft: 8 }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          selected[`community_${community.id}`] ?? false
                        }
                        onChange={(e) => {
                          console.log(e.target,'eeee');
                          console.log(e.target.checked,'eeee');
                          console.log(e.target.name,'eeee');
                          console.log(selected);
                          onSelect({
                            ...selected,
                            [e.target.name]: e.target.checked,
                          });
                        }}
                        name={`community_${community.id}`}
                      />
                    }
                    label={community.title}
                  />
                </Row>
              ))}
            </>
          ) : (
            <Typography variant="subtitle2" style={{ padding: "8px" }}>
              No Community Involvement found
            </Typography>
          )}

          {filteredCommunityInvolvement?.length > 4 ? (
            <Box textAlign="center">
              <Button
                onClick={() => setHidingClass(!hidingClass ? "d-none" : "")}
                color="primary"
              >
                {!hidingClass ? "Show less" : "Show more"}
              </Button>
            </Box>
          ) : null}
        </Box>
      ) : null}
      <Divider/>
    </>
  );
};

export default MicrositeCommunityInvolvement;
