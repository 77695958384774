import React, { useState, useEffect } from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { useDropzone } from "react-dropzone";
import cuid from "cuid";
import ImagePreview from "./ImagePreview";
import { Row } from "react-bootstrap";

import { validateAndResizeImage } from "../../../_helpers/_utils/utils";

const DropzoneFieldWithCropper = ({
  fields,
  acceptedFileType,
  uploadType,
  // bulkAction,
  deletePermissions,
  downLoadPermissions,
  // input: { onChange, value },
  // disabled,
  // className = "",
  // aspectRatio = 1,
  // dropZoneStyle = {},
  selectedItems = [],
  overWriteStyles = {},
  refData = {},
  hanldeSelectedImages = () => {},
}) => {
  const [images, setImages] = useState([]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: acceptedFileType,
    onDrop: (acceptedFiles) => {
      let targetImages = [];
      acceptedFiles.map(async (file) => {
        const blobFile = await validateAndResizeImage(file);
        const formatedFile = new File([blobFile], file.name);
        targetImages.push(
          Object.assign(formatedFile, {
            preview: URL.createObjectURL(formatedFile),
            id: cuid(),
            isSelected: false,
            title: "",
          })
        );
        fields.push({ fileContent: formatedFile });
      });
      setImages(images.concat(targetImages));
    },
  });

  useEffect(() => {
    if (fields.length > 0) {
      setImages(fields.getAll());
    }
  }, []);

  useEffect(() => {
    if (fields.length > 0) {
      refData.current = fields;
    }
  }, [fields]);

  const selectedImage = (file) => {
    var values = [].filter
      .call(document.getElementsByName("selectedImage"), (c) => c.checked)
      .map((c) => c.value);
    hanldeSelectedImages(values);
  };

  const handleChangeTitle = (value, id) => {
    const newFiles = images;
    newFiles[id].title = value;
    setImages(newFiles);
  };

  const onSortEnd = (props) => {
    const { oldIndex, newIndex } = props;
    if (oldIndex !== newIndex) {
      const removetItem = fields.get(oldIndex);
      fields.remove(oldIndex);
      fields.splice(newIndex, 0, removetItem);
    }
  };

  const SortableItem = SortableElement((props) => {
    const { value, _index } = props;
    return (
      <ImagePreview
        selectedItems={selectedItems}
        images={images}
        // removeImage={remove}
        selectedImage={selectedImage}
        changeTitle={handleChangeTitle}
        uploadType={uploadType}
        fields={fields}
        imageInfo={
          fields.get(_index)?.fileContent
            ? fields.get(_index)?.fileContent
            : fields.get(_index)
        }
        file={value}
        index={_index}
        deletePermissions={deletePermissions}
        downLoadPermissions={downLoadPermissions}
        overWriteStyles={overWriteStyles}
      />
    );
  });

  const SortableGallery = SortableContainer(({ items }) => {
    return (
      <Row className="mt-4">
        {items.map((image, index) => (
          <SortableItem
            key={image}
            index={index}
            value={image}
            _index={index}
          />
        ))}
      </Row>
    );
  });

  return (
    <section>
      <div className="drop-zone-with-cropper d-flex justify-content-center">
        <div {...getRootProps({ className: "dropzone disabled" })}>
          <input {...getInputProps()} />
          <p>Drag 'n' drop some files here, or click to select files</p>
        </div>
      </div>
      <SortableGallery items={fields} onSortEnd={onSortEnd} axis={"xy"} />
    </section>
  );
};

export default DropzoneFieldWithCropper;
