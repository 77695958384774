import { makeStyles, TextField } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
}));

const DateAndTime = ({ value, ...custom }) => {
  const classes = useStyles();
  return (
    <TextField
      fullWidth
      {...custom}
      value={value}
      className={classes.textField}
      InputLabelProps={{
        shrink: true,
      }}
    />
  );
};
export default DateAndTime;
