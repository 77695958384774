export const getOpenHomeTemplate1 = (overlay, color = '#830B2C', seperator = "#FFFFFF", leftAligned = false,) => {
    return `<div id='poster-template' class='open-home'>
    <div style="width: 600px; height: 600px;border:1px solid #aa9f9f; background-size: cover;"
        class='bg-image-node node-content-item'>
        <img src=${overlay} alt="forsale1" style="width: 100%;" />
        <div class='open-home-block-1' 
            style="position: absolute;word-break: normal; top: 130px; width: ${leftAligned ? '67.3%' : '100%'}; color: ${color}; font-size: 16px; font-family: Arial, Helvetica, sans-serif; display: flex; flex-direction: column; justify-content: space-between; align-items: center;" id='getOpenHomeTemplate1'>
            <div style="padding: 0px 0px 0px !important; text-align: center;margin-top:15px; width: ${leftAligned ? '100%' : '71%'}"
                class="main-heading-block open-home-node node-content-item">
                <div style="font-family: PlayList; font-size: 16px;" class="main-heading-node"></div>

                <div style="display:flex;justify-content:center;width:100%;font-size:16px; width: 100%;" class='sub-heading-node   new-class-2'>
                    <div class='sub-heading-node-day' style="font-weight:bold;font-family:Cormorant Garamond;  flex-grow:1; text-align: end;">[Open
                        Home Date]</div> &nbsp
                    <div class='sub-heading-node-month' style="font-weight:normal;font-family: Open Sans; flex-grow:1; text-align: left;">
                        [Open Home Date]</div>
                </div>

            </div>
            <div class="main-content-block"
                style="height: 100%; width: 100%; display: flex; flex-direction: column;align-items: center;font-family:system-ui">
        
                <div class='open-home-node inspection node-content-item'
                    style="text-align: center;margin-top:15px; width: ${leftAligned ? '100%' : '71%'}">
                    <div class='address' style="font-weight:bold; width: 100%; font-family:Cormorant Garamond; ">[ADDRESS]</div>
                <div class='features'
                        style="display:flex;justify-content:center;font-family: Open Sans,sans-serif; width: 100%;">
                        <div class="bed" > X </div>&nbsp<span>BED</span>&nbsp
                        <div style="color:${seperator};font-weight:bold">|</div>&nbsp
                        <div class="bath"> X  </div>&nbsp<span>BATH</span>&nbsp
                        <div style="color:${seperator};font-weight:bold">|</div>&nbsp
                        <div class="car"> X </div>&nbsp<span>CAR</span>
                    </div>
                </div>
                <div class='open-home-node inspection node-content-item'
                    style="text-align: center;margin-top:15px; width: ${leftAligned ? '100%' : '71%'}">
                    <div class='address' style="font-weight:bold;font-family:Cormorant Garamond; ">[ADDRESS]</div>
                    <div class='features'
                        style="display:flex;justify-content:center;font-family: Open Sans,sans-serif; width: 100%; ">
                        <div class="bed"> X </div>&nbsp<span>BED</span>&nbsp
                        <div style="color:${seperator};font-weight:bold">|</div>&nbsp
                        <div class="bath"> X  </div>&nbsp<span>BATH</span>&nbsp
                        <div style="color:${seperator};font-weight:bold">|</div>&nbsp
                        <div class="car"> X </div>&nbsp<span>CAR</span>
                    </div>
                </div>
                <div class='open-home-node inspection node-content-item'
                    style="text-align: center;margin-top:15px; width: ${leftAligned ? '100%' : '71%'}">
                    <div class='address' style="font-weight:bold; font-family:Cormorant Garamond; ">[ADDRESS]</div>
                  <div class='features'
                        style="display:flex;justify-content:center;font-family: Open Sans,sans-serif; width: 100%;">
                        <div class="bed"> X </div>&nbsp<span>BED</span>&nbsp
                        <div style="color:${seperator};font-weight:bold">|</div>&nbsp
                        <div class="bath"> X  </div>&nbsp<span>BATH</span>&nbsp
                        <div style="color:${seperator};font-weight:bold">|</div>&nbsp
                        <div class="car"> X </div>&nbsp<span>CAR</span>
                    </div>
                </div>
                <div class='open-home-node inspection node-content-item'
                    style="text-align: center;margin-top:15px; width: ${leftAligned ? '100%' : '71%'}">
                    <div class='address' style="font-weight:bold; font-family:Cormorant Garamond; ">[ADDRESS]</div>
                    <div class='features'
                        style="display:flex;justify-content:center;font-family: Open Sans,sans-serif; width: 100%; ">
                        <div class="bed"> X </div>&nbsp<span>BED</span>&nbsp
                        <div style="color:${seperator};font-weight:bold">|</div>&nbsp
                        <div class="bath"> X  </div>&nbsp<span>BATH</span>&nbsp
                        <div style="color:${seperator};font-weight:bold">|</div>&nbsp
                        <div class="car"> X </div>&nbsp<span>CAR</span>
                    </div>
                </div>
                <div class='open-home-node inspection node-content-item'
                    style="text-align: center;margin-top:15px; width: ${leftAligned ? '100%' : '71%'}">
                    <div class='address' style="font-weight:bold; font-family:Cormorant Garamond; ">[ADDRESS]</div>
                    <div class='features'
                        style="display:flex;justify-content:center;font-family: Open Sans,sans-serif; width: 100%;">
                        <div class="bed"> X </div>&nbsp<span>BED</span>&nbsp
                        <div style="color:${seperator};font-weight:bold">|</div>&nbsp
                        <div class="bath"> X </div>&nbsp<span>BATH</span>&nbsp
                        <div style="color:${seperator};font-weight:bold">|</div>&nbsp
                        <div class="car"> X </div>&nbsp<span>CAR</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>`
}

export const getOpenHomeTemplate2 = (overlay, color = '#830B2C', seperator = "#FFFFFF") => {
    return `<div id='poster-template' class='open-home'>
    <div style="width: 600px; height: 600px;border:1px solid #aa9f9f; background-size: cover;"
        class='bg-image-node node-content-item'>
        <img src=${overlay} alt="forsale1" style="width: 100%;" />
        <div class='open-home-block-1'
            style="position: absolute;word-break: normal; top: 130px; width: 100%; color: ${color}; font-size: 16px; font-family: Arial, Helvetica, sans-serif; display: flex; flex-direction: column; justify-content: space-around; align-items: center;" id='getOpenHomeTemplate2'>
            <div style=" text-align: center;" class=" layout2 main-heading-block open-home-node node-content-item ">
               <div style="font-family: PlayList; font-size: 16px;" class="main-heading-node"></div>
               <div style="display:flex;justify-content:center;width:100%;font-size:16px; width: 100%;" class='sub-heading-node  new-class new-class-2'>
                    <div class='sub-heading-node-day' style="font-weight:bold;font-family:Cormorant Garamond;  flex-grow:1; text-align: end;">[Open
                        Home Date]</div> &nbsp
                    <div class='sub-heading-node-month' style="font-weight:normal;font-family: Open Sans,sans-serif; flex-grow:1; text-align: left;">
                        [Open Home Date]</div>
                </div>
            </div>
            <div class="main-content-block"
                style="height: 100%; width: 100%; display: flex; flex-direction: column; align-items: center;font-family: Open Sans,sans-serif;">
                <div class='open-home-node private-open-home node-content-item' style="text-align: center;margin-top:2%;">
                <div class='time' style="display: flex;justify-content:center; width: 100%;">
                <div class="start-time" style="flex-grow: 1; text-align: right;">[Start time]</div>&nbsp
                <div>-</div>&nbsp
                <div class="end-time" style="flex-grow: 1; text-align: left;">[End time]</div>
            </div>
                    <div class='address' style="font-weight: bold;font-family:Cormorant Garamond; ">[ADDRESS]</div>
                    <div class='features' style="display:flex;justify-content:center; ">
                        <div class="bed"> X </div>&nbsp<span>BED</span>&nbsp
                        <div style="color:${seperator};font-weight:bold">|</div>&nbsp
                        <div class="bath"> X </div>&nbsp<span>BATH</span>&nbsp
                        <div style="color:${seperator};font-weight:bold">|</div>&nbsp
                        <div class="car"> X </div>&nbsp<span>CAR</span>
                    </div>
                </div>
                <div class='open-home-node private-open-home node-content-item' style="text-align: center;margin-top:2%;">
                    <div class='time' style="display: flex;justify-content:center; width: 100%;">
                        <div class="start-time" style="flex-grow: 1; text-align: right;">[Start time]</div>&nbsp
                        <div>-</div>&nbsp
                        <div class="end-time" style="flex-grow: 1; text-align: left;">[End time]</div>
                    </div>
                    <div class='address' style="font-weight: bold;font-family:Cormorant Garamond; ">[ADDRESS]</div>
                    <div class='features' style="display:flex;justify-content:center; ">
                        <div class="bed"> X </div>&nbsp<span>BED</span>&nbsp
                        <div style="color:${seperator};font-weight:bold">|</div>&nbsp
                        <div class="bath"> X  </div>&nbsp<span>BATH</span>&nbsp
                        <div style="color:${seperator};font-weight:bold">|</div>&nbsp
                        <div class="car"> X </div>&nbsp<span>CAR</span>
                    </div>
                </div>
                <div class='open-home-node private-open-home node-content-item' style="text-align: center;margin-top:2%;">
                    <div class='time' style="display: flex;justify-content:center; width: 100%;">
                        <div class="start-time" style="flex-grow: 1; text-align: right;">[Start time]</div>&nbsp
                        <div>-</div>&nbsp
                        <div class="end-time" style="flex-grow: 1; text-align: left;">[End time]</div>
                    </div>
                    <div class='address' style="font-weight: bold;font-family:Cormorant Garamond; ">[ADDRESS]</div>
                    <div class='features' style="display:flex;justify-content:center; ">
                        <div class="bed"> X </div>&nbsp<span>BED</span>&nbsp
                        <div style="color:${seperator};font-weight:bold">|</div>&nbsp
                        <div class="bath"> X  </div>&nbsp<span>BATH</span>&nbsp
                        <div style="color:${seperator};font-weight:bold">|</div>&nbsp
                        <div class="car"> X </div>&nbsp<span>CAR</span>
                    </div>

                </div>
                <div class='open-home-node private-open-home node-content-item' style="text-align: center;margin-top:2%;">
                    <div class='time' style="display: flex;justify-content:center; width: 100%;">
                        <div class="start-time" style="flex-grow: 1; text-align: right;">[Start time]</div>&nbsp
                        <div>-</div>&nbsp
                        <div class="end-time" style="flex-grow: 1; text-align: left;">[End time]</div>
                    </div>
                    <div class='address' style="font-weight: bold;font-family:Cormorant Garamond; ">[ADDRESS]</div>
                    <div class='features' style="display:flex;justify-content:center; ">
                        <div class="bed"> X </div>&nbsp<span>BED</span>&nbsp
                        <div style="color:${seperator};font-weight:bold">|</div>&nbsp
                        <div class="bath"> X </div>&nbsp<span>BATH</span>&nbsp
                        <div style="color:${seperator};font-weight:bold">|</div>&nbsp
                        <div class="car"> X </div>&nbsp<span>CAR</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>`
}

export const getOpenHomeTemplate3 = (overlay, color = '#830B2C', seperator = "#FFFFFF") => {
    return `<div id='poster-template' class='open-home'>
    <div style="width: 600px; height: 600px;border:1px solid #aa9f9f;  background-size: cover;"
        class='bg-image-node node-content-item'>
        <img src=${overlay} alt="forsale1" style="width: 100%;" />
        <div class='open-home-block-1'
            style="position: absolute;word-break: normal; top: 80px; width: 50%; color: ${color}; font-size: 16px; font-family: Arial, Helvetica, sans-serif; display: flex; flex-direction: column; justify-content: space-around; align-items: center;" id='getOpenHomeTemplate3'>
            <div style=" text-align: center;left:300px;top: 65px;position:absolute;width:100%"
                class="main-heading-block open-home-node node-content-item small-laptop big-laptop">
                <div style="font-family: PlayList; font-size: 16px;" class="main-heading-node"></div>

                <div style="display:flex;justify-content:center;width:100%;font-size:16px; width: 100%;color:#000000;" class='sub-heading-node  new-class new-class-2 style_3_4'>
                <div class='sub-heading-node-day' style="font-weight:bold;font-family:Cormorant Garamond;  flex-grow:1; text-align: end;">[Open
                    Home Date]</div> &nbsp
                <div class='sub-heading-node-month' style="font-weight:normal;font-family: Open Sans,sans-serif; flex-grow:1; text-align: left;">
                    [Open Home Date]</div>
            </div>

            </div>
            <div class="main-content-block style-3"
                style="height: 100%; width: 100%; display: flex; flex-direction: column; align-items: center;font-family: Open Sans,sans-serif; margin-top:0%;">
                <div class='open-home-node inspection node-content-item  layout3' style="text-align: center;margin-top:3%;">
                   
                    <div class='address' style="font-weight: bold;font-family:Cormorant Garamond; ">
                        <div>[ADDRESS</div>
                        <div>HERE]</div>
                    </div>
                    <div class='features' style="display:flex;justify-content:center; width:100%; ">
                        <div class="bed"> X </div>&nbsp<span>BED</span>&nbsp
                        <div style="color:${seperator};font-weight:bold">|</div>&nbsp
                        <div class="bath"> X </div>&nbsp<span>BATH</span>&nbsp
                        <div style="color:${seperator};font-weight:bold">|</div>&nbsp
                        <div class="car"> X </div>&nbsp<span>CAR</span>
                    </div>
                </div>
                <div class='open-home-node inspection node-content-item' style="text-align: center;margin-top:3%;">
            
                    <div class='address' style="font-weight: bold;font-family:Cormorant Garamond; ">
                        <div>[ADDRESS</div>
                        <div>HERE]</div>
                    </div>
                    <div class='features' style="display:flex;justify-content:center; width: 100%;">
                        <div class="bed"> X </div>&nbsp<span>BED</span>&nbsp
                        <div style="color:${seperator};font-weight:bold">|</div>&nbsp
                        <div class="bath"> X </div>&nbsp<span>BATH</span>&nbsp
                        <div style="color:${seperator};font-weight:bold">|</div>&nbsp
                        <div class="car"> X </div>&nbsp<span>CAR</span>
                    </div>
                </div>
                <div class='open-home-node inspection node-content-item' style="text-align: center;margin-top:3%;">
                    <div class='address' style="font-weight: bold;font-family:Cormorant Garamond; ">
                        <div>[ADDRESS</div>
                        <div>HERE]</div>
                    </div>
                    <div class='features' style="display:flex;justify-content:center; width: 100%;">
                        <div class="bed"> X </div>&nbsp<span>BED</span>&nbsp
                        <div style="color:${seperator};font-weight:bold">|</div>&nbsp
                        <div class="bath"> X </div>&nbsp<span>BATH</span>&nbsp
                        <div style="color:${seperator};font-weight:bold">|</div>&nbsp
                        <div class="car"> X </div>&nbsp<span>CAR</span>
                    </div>

                </div>
                <div class='open-home-node inspection node-content-item' style="text-align: center;margin-top:3%;">
                    <div class='address' style="font-weight: bold;font-family:Cormorant Garamond; ">
                        <div>[ADDRESS</div>
                        <div>HERE]</div>
                    </div>
                    <div class='features' style="display:flex;justify-content:center; width: 100%;">
                        <div class="bed"> X </div>&nbsp<span>BED</span>&nbsp
                        <div style="color:${seperator};font-weight:bold">|</div>&nbsp
                        <div class="bath"> X </div>&nbsp<span>BATH</span>&nbsp
                        <div style="color:${seperator};font-weight:bold">|</div>&nbsp
                        <div class="car"> X </div>&nbsp<span>CAR</span>
                    </div>

                </div>
                <div class='open-home-node inspection node-content-item' style="text-align: center;margin-top:3%;">
             
                    <div class='address' style="font-weight: bold;font-family:Cormorant Garamond; ">
                        <div>[ADDRESS</div>
                        <div>HERE]</div>
                    </div>
                    <div class='features' style="display:flex;justify-content:center; width: 100%;">
                        <div class="bed"> X </div>&nbsp<span>BED</span>&nbsp
                        <div style="color:${seperator};font-weight:bold">|</div>&nbsp
                        <div class="bath"> X </div>&nbsp<span>BATH</span>&nbsp
                        <div style="color:${seperator};font-weight:bold">|</div>&nbsp
                        <div class="car"> X </div>&nbsp<span>CAR</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>`
}
export const getOpenHomeTemplate4 = (overlay, color = '#FFFFFF', seperator = "#FFFFFF") => {
    return `<div id='poster-template' class='open-home'>
    <div style="width: 600px; height: 600px;border:1px solid #aa9f9f;  background-size: cover;"
        class='bg-image-node node-content-item'>
        <img src=${overlay} alt="forsale1" style="width: 100%;" />
        <div class='open-home-block-1'
            style="position: absolute;word-break: normal; top: 2.08vw; width: 50%; color: ${color}; font-size: 16px; font-family: Arial, Helvetica, sans-serif; display: flex; flex-direction: column; justify-content: space-around; align-items: center;" id='getOpenHomeTemplate4'>
            <div style=" text-align: center;left:300px;top: 110px;position:absolute;width:100%"
                class="small-laptop main-heading-block open-home-node node-content-item  ">
                <div style="font-family: PlayList; font-size: 16px;" class="main-heading-node"></div>

                <div style="display:flex;justify-content:center;width:100%;font-size:16px; width: 100%;color:#000000;" class='sub-heading-node  new-class new-class-2 style_3_4'>
                <div class='sub-heading-node-day' style="font-weight:bold;font-family:Cormorant Garamond;  flex-grow:1; text-align: end;">[Open
                    Home Date]</div> &nbsp
                <div class='sub-heading-node-month' style="font-weight:normal;font-family: Open Sans,sans-serif; flex-grow:1; text-align: left;">
                    [Open Home Date]</div>
            </div>

            </div>
            <div class="main-content-block"
                style="height: 100%; width: 100%; display: flex; flex-direction: column; align-items: center;font-family: Open Sans,sans-serif;">
                <div class='open-home-node private-open-home node-content-item layout4' style="text-align: center;margin-top:3%;">
                    <div class='time' style="display: flex;justify-content:center; width:100%;">
                        <div class="start-time" style="flex-grow: 1; text-align: right;">[Start time]</div>&nbsp
                        <div>-</div>&nbsp
                        <div class="end-time" style="flex-grow: 1; text-align: left;">[End time]</div>
                    </div>
                    <div class='address' style="font-weight: bold;font-family:Cormorant Garamond; ">
                        <div>[ADDRESS</div>
                        <div>HERE]</div>
                    </div>
                    <div class='features' style="display:flex;justify-content:center; width:100%; ">
                        <div class="bed"> X </div>&nbsp<span>BED</span>&nbsp
                        <div style="color:${seperator};font-weight:bold">|</div>&nbsp
                        <div class="bath"> X </div>&nbsp<span>BATH</span>&nbsp
                        <div style="color:${seperator};font-weight:bold">|</div>&nbsp
                        <div class="car"> X </div>&nbsp<span>CAR</span>
                    </div>
                </div>
                <div class='open-home-node private-open-home node-content-item' style="text-align: center;margin-top:3%;">
                <div class='time' style="display: flex;justify-content:center; width:100%;">
                <div class="start-time" style="flex-grow: 1; text-align: right;">[Start time]</div>&nbsp
                <div>-</div>&nbsp
                <div class="end-time" style="flex-grow: 1; text-align: left;">[End time]</div>
            </div>
                    <div class='address' style="font-weight: bold;font-family:Cormorant Garamond; ">
                        <div>[ADDRESS</div>
                        <div>HERE]</div>
                    </div>
                    <div class='features' style="display:flex;justify-content:center; width: 100%;">
                        <div class="bed"> X </div>&nbsp<span>BED</span>&nbsp
                        <div style="color:${seperator};font-weight:bold">|</div>&nbsp
                        <div class="bath"> X </div>&nbsp<span>BATH</span>&nbsp
                        <div style="color:${seperator};font-weight:bold">|</div>&nbsp
                        <div class="car"> X </div>&nbsp<span>CAR</span>
                    </div>
                </div>
                <div class='open-home-node private-open-home node-content-item' style="text-align: center;margin-top:3%;">
                <div class='time' style="display: flex;justify-content:center; width:100%;">
                <div class="start-time" style="flex-grow: 1; text-align: right;">[Start time]</div>&nbsp
                <div>-</div>&nbsp
                <div class="end-time" style="flex-grow: 1; text-align: left;">[End time]</div>
            </div>
                    <div class='address' style="font-weight: bold;font-family:Cormorant Garamond; ">
                        <div>[ADDRESS</div>
                        <div>HERE]</div>
                    </div>
                    <div class='features' style="display:flex;justify-content:center; width: 100%;">
                        <div class="bed"> X </div>&nbsp<span>BED</span>&nbsp
                        <div style="color:${seperator};font-weight:bold">|</div>&nbsp
                        <div class="bath"> X </div>&nbsp<span>BATH</span>&nbsp
                        <div style="color:${seperator};font-weight:bold">|</div>&nbsp
                        <div class="car"> X </div>&nbsp<span>CAR</span>
                    </div>

                </div>
                <div class='open-home-node private-open-home node-content-item' style="text-align: center;margin-top:3%;">
                <div class='time' style="display: flex;justify-content:center; width:100%;">
                <div class="start-time" style="flex-grow: 1; text-align: right;">[Start time]</div>&nbsp
                <div>-</div>&nbsp
                <div class="end-time" style="flex-grow: 1; text-align: left;">[End time]</div>
            </div>
                    <div class='address' style="font-weight: bold;font-family:Cormorant Garamond; ">
                        <div>[ADDRESS</div>
                        <div>HERE]</div>
                    </div>
                    <div class='features' style="display:flex;justify-content:center; width: 100%;">
                        <div class="bed"> X </div>&nbsp<span>BED</span>&nbsp
                        <div style="color:${seperator};font-weight:bold">|</div>&nbsp
                        <div class="bath"> X </div>&nbsp<span>BATH</span>&nbsp
                        <div style="color:${seperator};font-weight:bold">|</div>&nbsp
                        <div class="car"> X </div>&nbsp<span>CAR</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>`
}

export const getAuctionTemplate1 = (overlay, color = '#830B2C', leftAligned = false, seperator = "#FFFFFF") => {
    return `<div id='poster-template'><div style="width: 600px; height: 600px;border:1px solid #aa9f9f; background-size: cover;" class='bg-image-node node-content-item'>
    <img src=${overlay} alt="forsale1" style="width: 100%;" />
    <div class='open-home-block-1' style="position: absolute; top: 10%;word-break: normal;  width: ${leftAligned ? '50%' : '100%'};  ; height: 85%; color: ${color}; font-size: 0.78vw; font-family: Arial, Helvetica, sans-serif; display: flex; flex-direction: column; justify-content: space-between; align-items: center;">    

    <div style="text-align: center;  width: ${leftAligned ? '100%' : '71%'}; margin-top:13%" class=" main-heading-block
    auction-node node-content-item">
    <div style="font-family: PlayList; font-size: 2.08vw;" class="main-heading-node"></div>
    <div style="display:flex;justify-content:center;width:100%;font-size:1.04vw" class='sub-heading-node'>
        <div class='sub-heading-node-day' style="font-weight:bold;font-family:Cormorant Garamond; flex-grow: 1; text-align: right;">[Auction
            Date]</div>
        &nbsp
        <div class='sub-heading-node-month' style="font-weight:normal;font-family: Open Sans,sans-serif; flex-grow: 1; text-align: left;">
            [Auction Date]</div>
    </div>

      <div class="main-content-block" style=" width: 100%; display: flex; flex-direction: column; align-items: center;">
  
        <div class='auction-node node-content-item' style="text-align: center; width: '100%'}">        
          <div class='time-and-price' style=" justify-content: center;font-family:system-ui;margin-top:8%;">
          <div style="
          display: flex;
          justify-content: center;
   
      ">
          <div  class='time' style=" font-family: Open Sans; "><span style="width: "fit-content:">[Auction Time]</span></div>
          <div class='auction-seperator-line'  style="margin: 0px 5px;font-weight: bold;color:${seperator}"> | </div>
          <div class='price-node'  style=" font-family: Open Sans; ">[Listing Price]</div>   
          </div>
         
       <div class='address'style="font-weight:bold">
       <div style="font-weight:bold; font-family:Cormorant Garamond;" >[ADDRESS]</div>
       </div>
           
          </div>
  
        </div>
        <div class='auction-node node-content-item' style="text-align: center; width: '100%'}">        
          <div class='time-and-price' style=" justify-content: center;font-family:system-ui;margin-top:5%;">
          <div style="
          display: flex;
          justify-content: center;

      ">
          <div class='time' style=" font-family: Open Sans; ">[Auction Time]</div>
          <div class='auction-seperator-line'  style="margin: 0px 5px;font-weight: bold;color:${seperator}"> | </div>
          <div class='price-node' style=" font-family: Open Sans;">[Listing Price]</div>   
          </div>
         
       <div class='address'style="font-weight:bold">
       <div style="font-weight:bold; font-family:Cormorant Garamond;" >[ADDRESS]</div>
       </div>
           
          </div>
  
        </div>
        <div class='auction-node node-content-item' style="text-align: center; width: '100%'}">        
          <div class='time-and-price' style=" justify-content: center;font-family:system-ui;margin-top:5%;">
          <div style="
          display: flex;
          justify-content: center;
     
      ">
          <div class='time' style=" font-family: Open Sans; ">[Auction Time]</div>
          <div class='auction-seperator-line'  style="margin: 0px 5px;font-weight: bold;color:${seperator}"> | </div>
          <div class='price-node' style=" font-family: Open Sans; ">[Listing Price]</div>   
          </div>
         
       <div class='address'style="font-weight:bold">
       <div style="font-weight:bold; font-family:Cormorant Garamond;" >[ADDRESS]</div>
       </div>
           
          </div>
  
        </div>
        <div class='auction-node node-content-item' style="text-align: center; width: '100%'}">        
          <div class='time-and-price' style=" justify-content: center;font-family:system-ui;margin-top:5%;">
          <div style="
          display: flex;
          justify-content: center;
       
      ">
          <div class='time' style=" font-family: Open Sans;  ">[Auction Time]</div>
          <div  class='auction-seperator-line'  style="margin: 0px 5px;font-weight: bold;color:${seperator}"> | </div>
          <div class='price-node' style=" font-family: Open Sans;  ">[Listing Price]</div>   
          </div>
         
       <div class='address'style="font-weight:bold">
       <div style="font-weight:bold; font-family:Cormorant Garamond;" >[ADDRESS]</div>
       </div>
           
          </div>
  
        </div>
        <div class='auction-node node-content-item' style="text-align: center; width: '100%'}">        
          <div class='time-and-price' style=" justify-content: center;font-family:system-ui;margin-top:5%;">
          <div style="
          display: flex;
          justify-content: center;
     
      ">
          <div class='time' style=" font-family: Open Sans; ">[Auction Time]</div>
          <div class='auction-seperator-line'   style="margin: 0px 5px;font-weight: bold;color:${seperator}"> | </div>
          <div class='price-node' style=" font-family: Open Sans; ">[Listing Price]</div>   
          </div>
         
       <div class='address'style="font-weight:bold">
       <div style="font-weight:bold; font-family:Cormorant Garamond;" >[ADDRESS]</div>
       </div>
           
          </div>
  
        </div>
  
    
  
      
  
       
  
       
        </div>
      </div>
    </div>
    </div></div>`
}
export const getAuctionTemplate3 = (overlay, color = '#830B2C', leftAligned = true, seperator = "#FFFFFF") => {
    return `<div id='poster-template'><div style="width: 600px; height: 600px;border:1px solid #aa9f9f; background-size: cover;" class='bg-image-node node-content-item'>
  <img src=${overlay} alt="forsale1" style="width: 100%;" />
  <div class='open-home-block-1' style="position: absolute; top: 10%;word-break: break-word;  width: ${leftAligned ? '50%' : '100%'};  ; height: 85%; color: ${color}; font-size: 15px; font-family: Arial, Helvetica, sans-serif; display: flex; flex-direction: column; justify-content: space-between; align-items: center;">    

    <div class="main-content-block layout4" style="height: 100%; width: 100%; display: flex; flex-direction: column; align-items: center;">

      <div class='auction-node node-content-item' style="text-align: center; width: '100%'}">        
        <div class='time-and-price' style=" justify-content: center;font-family:system-ui;margin-top:5%;">
          <div class='time'style="font-family:system-ui">[Auction Time]</div>
     <div class='address'style="font-weight:bold">
          <div style="font-weight:bold;" >[ADDRESS</div>
          <div style="font-weight:bold;"> HERE ]</div>
     </div>
          <div class='price-node'>[Listing Price]</div>        
        </div>

      </div>

     <div class='auction-node node-content-item' style="text-align: center; width:'100%'}">        
        <div class='time-and-price' style=" justify-content: center;font-family:system-ui;margin-top:5%">
          <div class='time'style="font-family:system-ui">[Auction Time]</div>
     <div class='address'style="font-weight:bold">
          <div style="font-weight:bold;" >[ADDRESS</div>
          <div style="font-weight:bold;"> HERE ]</div>
     </div>
          <div class='price-node'>[Listing Price]</div>        
        </div>
      </div>

     <div class='auction-node node-content-item' style="text-align: center; width: '100%'}">        
        <div class='time-and-price' style=" justify-content: center;font-family:system-ui;margin-top:5%">
          <div class='time'style="font-family:system-ui"><span style="width: "fit-content:">[Auction Time]</span></div>
     <div class='address'style="font-weight:bold">
          <div style="font-weight:bold;" >[ADDRESS</div>
          <div style="font-weight:bold;"> HERE ]</div>
     </div>
          <div class='price-node'>[Listing Price]</div>        
        </div>
      </div>

     <div class='auction-node node-content-item' style="text-align: center; width: '100%'}">        
        <div class='time-and-price' style=" justify-content: center;font-family:system-ui;margin-top:5%">
          <div class='time'style="font-family:system-ui">[Auction Time]</div>
     <div class='address'style="font-weight:bold">
          <div style="font-weight:bold;" >[ADDRESS</div>
          <div style="font-weight:bold;"> HERE ]</div>
     </div>
          <div class='price-node'>[Listing Price]</div>        
        </div>
      </div>

     <div class='auction-node node-content-item' style="text-align: center; width:'100%'}">        
        <div class='time-and-price' style=" justify-content: center;font-family:system-ui;margin-top:5%">
          <div class='time'style="font-family:system-ui">[Auction Time]</div>
     <div class='address'style="font-weight:bold">
          <div style="font-weight:bold;" >[ADDRESS</div>
          <div style="font-weight:bold;"> HERE ]</div>
     </div>
          <div class='price-node'>[Listing Price]</div>        
        </div>
      </div>
    </div>
  </div>
  </div></div>`
}

export const getAuctionTemplate2 = (overlay, color = '#830B2C') => {
    return `<div id='poster-template'><div style="width: 600px; height: 600px; background: #c8dfe9; background-size: cover;" class='bg-image-node node-content-item'>
  <img src=${overlay} alt="forsale1" style="width: 100%;" />
  <div class='open-home-block-1' style="position: absolute; top: 40px; width: 100%; height: 80%; color: ${color}; font-size: 18px; font-family: Arial, Helvetica, sans-serif; display: flex; flex-direction: column; justify-content: space-around; align-items: center;">    
    <div style="padding: 5px 0px 30px; text-align: center;" class="main-heading-block auction-node node-content-item">
      <div style="font-family: PlayList; font-size: 40px;" class="main-heading-node">Auctions This Weekend</div>
      <div class='sub-heading-node'>[Open Home Date]</div>
    </div>
    <div class="main-content-block" style="height: 100%; width: 100%; display: flex; flex-direction: column; justify-content: space-evenly; align-items: center;">
      <div class='auction-node node-content-item' style="text-align: center;">
        <div class='time' style="font-weight: bold">[Auction Time]</div>
        <div class='address'>[Listing Address]</div>
      </div>
      <div class='auction-node node-content-item' style="text-align: center;">
        <div class='time' style="font-weight: bold">[Auction Time]</div>
        <div class='address'>[Listing Address]</div>
      </div>
      <div class='auction-node node-content-item' style="text-align: center;">
        <div class='time' style="font-weight: bold">[Auction Time]</div>
        <div class='address'>[Listing Address]</div>
      </div>
      <div class='auction-node node-content-item' style="text-align: center;">
        <div class='time' style="font-weight: bold">[Auction Time]</div>
        <div class='address'>[Listing Address]</div>
      </div>
    </div>
  </div>
  </div></div>`
}










{/* <div id='poster-template'>
    <div style="width: 600px;border:1px solid #aa9f9f; height: 600px; background-size: cover;"
        class='bg-image-node node-content-item'>
        <img src=${overlay} alt="forsale1" style="width: 100%;" />
        <div class='open-home-block-1'
            style="position: absolute;word-break: break-word; top: 22%; width: ${leftAligned ? '67.3%' : '100%'}; height: 85%; color: ${color}; font-size: 18px; font-family: Open Sans,sans-serif; display: flex; flex-direction: column; justify-content: space-between; align-items: center;">
            <div style="text-align: center;  width: ${leftAligned ? '100%' : '71%'}"" class=" main-heading-block
                auction-node node-content-item">
                <div style="font-family: PlayList; font-size: 40px;" class="main-heading-node"></div>
                <div style="display:flex;justify-content:center;width:100%;font-size:20px" class='sub-heading-node'>
                    <div class='sub-heading-node-day' style="font-weight:bold;font-family:Cormorant Garamond;">[Auction
                        Date]</div>
                    &nbsp
                    <div class='sub-heading-node-month' style="font-weight:normal;font-family: Open Sans,sans-serif;">
                        [Auction Date]</div>
                </div>

            </div>
            <div class="main-content-block"
                style="height: 100%; width: 100%; display: flex; flex-direction: column;margin-top:3%; align-items: center;">
                <div class='auction-node node-content-item' style="text-align: center; width: '100%'}">        
        <div class='time-and-price'style=" display: flex; justify-content: center;">
          <div class='time'">[Auction Time]</div>
     <div class='address'style="font-weight:bold">
          <div style="font-weight:bold;" >[ADDRESS</div>
          <div style="font-weight:bold;"> HERE ]</div>
     </div>
          <div class='price-node'>[Listing Price]</div>        
        </div>
        
      </div>

                <div class='auction-node node-content-item'
                    style="text-align: center;margin-top:3%; width: ${leftAligned ? '100%' : '71%'}">
                    <div class='time-and-price' style=" display: flex; justify-content: center;">
                        <div class='time'>[Auction Time]</div>
                        <div style="margin: 0px 5px;font-weight: bold;color:${seperator}"> | </div>
                        <div class='price-node'>[Listing Price]</div>
                    </div>
                    <div style="font-weight: bold;" class='address'>[ADDRESS]</div>
                </div>

                <div class='auction-node node-content-item'
                    style="text-align: center;margin-top:3%; width: ${leftAligned ? '100%' : '71%'}">
                    <div class='time-and-price' style="display: flex; justify-content: center;">
                        <div class='time'>[Auction Time]</div>
                        <div style="margin: 0px 5px;font-weight: bold;color:${seperator}"> | </div>
                        <div class='price-node'>[Listing Price]</div>
                    </div>
                    <div style="font-weight: bold;" class='address'>[ADDRESS]</div>
                </div>

                <div class='auction-node node-content-item'
                    style="text-align: center;margin-top:3%; width: ${leftAligned ? '100%' : '71%'}">
                    <div class='time-and-price' style="display: flex; justify-content: center;">
                        <div class='time'>[Auction Time]</div>
                        <div style="margin: 0px 5px;font-weight: bold;color:${seperator}"> | </div>
                        <div class='price-node'>[Listing Price]</div>
                    </div>
                    <div style="font-weight: bold;" class='address'>[ADDRESS]</div>
                </div>

                <div class='auction-node node-content-item'
                    style="text-align: center;margin-top:3%; width: ${leftAligned ? '100%' : '71%'}">
                    <div class='time-and-price' style=" display: flex; justify-content: center;">
                        <div class='time'>[Auction Time]</div>
                        <div style="margin: 0px 5px;font-weight: bold;color:${seperator}"> | </div>
                        <div class='price-node'>[Listing Price]</div>
                    </div>
                    <div style="font-weight: bold;" class='address'>[ADDRESS]</div>
                </div>
            </div>
        </div>
    </div>
</div> */}