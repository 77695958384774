import React, { useEffect, useState } from "react";
import { Link, Switch, Route, Redirect } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_metronic";
import "../../../_metronic/_assets/sass/pages/login/login-1.scss";
import Login from "./Login";
import Registration from "./Registration";
import ForgotPassword from "./ForgotPassword";
import { getRandomInt } from "../../_helpers/_utils/utils";
import ResetPassword from "./ResetPassword";

export default function AuthPage() {
  const [image, setImage] = useState(1);

  useEffect(() => {
    let numb = getRandomInt(1, 5);
    setImage(
      "https://v2-wiseberryonline-com-au.s3-ap-southeast-2.amazonaws.com/assets/log-in-page/" +
        numb +
        ".jpg"
    );
  }, []);
  // console.log("authPage");
  return (
    <>
      <div className="kt-grid kt-grid--ver kt-grid--root">
        <div
          id="kt_login"
          className="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1"
        >
          <div
            style={{
              backgroundImage: `url(${toAbsoluteUrl(image)})`,
            }}
            className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile login-page-body"
          >
            <div className="kt-grid__item kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-login__aside">
              <div className="kt-grid__item"></div>
              <div
                style={{ textAlign: "center" }}
                className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver"
              >
                <div
                  style={{ margin: "auto" }}
                  className="kt-grid__item--middle"
                >
                  <Link to="/" className="kt-login__logo">
                    <img
                      alt="Logo"
                      className="w-100"
                      src={toAbsoluteUrl(
                        "https://v2-wiseberryonline-com-au.s3-ap-southeast-2.amazonaws.com/assets/Main-Logo.png"
                      )}
                    />
                  </Link>
                  <h4 className="kt-login__subtitle d-none d-sm-block">
                    Welcome Home.
                  </h4>
                </div>
              </div>
              <div className="kt-grid__item">
                <div className="kt-login__info">
                  <div className="kt-login__copyright">
                    &copy; {new Date().getFullYear()} Wiseberry
                  </div>
                  <div className="kt-login__menu">
                    <Link
                      target="_blank"
                      to={{ pathname: "https://wiseberry.com.au/privacy" }}
                      className="kt-link"
                    >
                      Privacy
                    </Link>
                    <Link
                      target="_blank"
                      to={{ pathname: "https://wiseberry.com.au/terms" }}
                      className="kt-link"
                    >
                      Terms
                    </Link>
                    <Link
                      target="_blank"
                      to={{ pathname: "https://wiseberry.com.au/legal" }}
                      className="kt-link"
                    >
                      legal
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper">
              <Switch>
                <Route path="/auth/login" component={Login} />
                <Route path="/auth/registration" component={Registration} />
                <Route
                  path="/auth/forgot-password"
                  component={ForgotPassword}
                />
                <Route path="/auth/reset-password" component={ResetPassword} />
                <Redirect from="/auth" exact={true} to="/auth/login" />
                <Redirect to="/auth/login" />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
