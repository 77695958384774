import React, { Fragment, useContext, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import Item from "../../../../../partials/display/Item";
import FeaturesTable from "../../../../../partials/display/property/featuresTable/FeaturesTable";
import { ProspectProfileContext } from "../ProspectProfileContextProvider";
import moment from "moment";

const ProspectDetailsView = (props) => {
  const { prospectProfile } = useContext(ProspectProfileContext);

  return (
    <Fragment>
      <div className="buyerDetails">
        <Row className="pb-3">
          <h5 className="title">Property Details</h5>
        </Row>
        <Row>
          <Col xl="5">
            <Item
              left="Address"
              right={prospectProfile?.property?.address?.full_address}
            />
          </Col>
          <Col xl="5">
            <Item
              left="Purchase Date"
              right={
                prospectProfile?.purchase_date &&
                moment(prospectProfile?.purchase_date).format("DD/MM/YYYY")
              }
            />
          </Col>
          <Col xl="2">
            <Item left="Rating" right={prospectProfile?.rating?.name} />
          </Col>
        </Row>
        <Row className="pb-5">
          <Col xl="5">
            <Item
              left="Status"
              right={
                prospectProfile?.lwo &&
                "Listed With " +
                  prospectProfile?.lwo +
                  " for " +
                  prospectProfile?.lwo_price +
                  "(Expiry: " +
                  moment(prospectProfile?.lwo_expiry_date).format(
                    "DD/MM/YYYY"
                  ) +
                  ")"
              }
            />
          </Col>
          <Col xl="5">
            <Item
              left="Purchase Price"
              right={
                "$" + (prospectProfile?.price ? prospectProfile?.price : 0)
              }
            />
          </Col>
        </Row>
      </div>{" "}
      <h5 className="title">Property Features</h5>
      <FeaturesTable
        height="30"
        features={prospectProfile?.features ? prospectProfile?.features : []}
      />{" "}
    </Fragment>
  );
};

export default ProspectDetailsView;
