import React, { useEffect, useState, Fragment } from "react";
import moment from "moment";
import { toast } from "react-toastify";
import _split from "lodash/split";
import _concat from "lodash/concat";
import { LinearProgress } from "@material-ui/core";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import EmailIcon from "@material-ui/icons/Email";

import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker.css";

import {
  currencyFormatter,
  formToQueryString,
} from "../../../../_helpers/_utils/utils";
import ThemeHeader from "../../../../partials/content/ThemeHeader";
import DynamicTableEdit from "../../../../partials/table/DynamicTableEdit";
import ThemeButton from "../../../../partials/button/ThemeButton";
import DefaultModal from "../../../../partials/modal/DefaultModal";
import Sms from "../../../../partials/component/sms/Sms";
import Email from "../../../../partials/component/email/Email";
import {
  getAllDiaryAppointmentList,
  openHomeBulkUpdate,
} from "../../../diary/_curd/diaryCurd";

import { sendBuyerListingPack } from "../../crud/buyersCrud";
import { sendSms } from "../../../../GeneralApi/GeneralApi";
const BuyerOpenHome = ({
  // edit,
  // handleChange,
  buyerId,
  closeModal,
  profile,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showSms, setShowSms] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [otherResData, setOtherResData] = useState([]);
  const [htmlData, setHtmlData] = useState("<p></p>");

  const fetchMoreData = () => {
    if (otherResData?.next_page_url) {
      update({
        page: otherResData.current_page + 1,
        loadMore: true,
      });
    }
  };

  useEffect(() => {
    update();
  }, []);

  const update = (prams) => {
    let obj = {
      type: 5,
      from: moment().format("YYYY-MM-DD"),
      ...prams,
    };
    obj.limit = "100";
    setIsLoading(true);
    let query = formToQueryString(obj);
    getAllDiaryAppointmentList(query)
      .then((res) => {
        const { data, ...rest } = res.data;
        if (prams?.loadMore) {
          setItems(items.concat(data));
        } else {
          if (data?.length === 0)
            toast.warning("No Open Home Appointments Found");
          setItems(data);
        }
        setOtherResData(rest);
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Matched",
        columns: [
          {
            Header: "Date",
            accessor: "date",
            Cell: ({
              cell: {
                row: { original },
              },
            }) => <p>{moment(original.starts).format("DD/MM/YYYY h:mm a")}</p>,
          },
          {
            Header: "Address",
            accessor: "address",
            Cell: ({
              cell: {
                row: { original },
              },
            }) => (
              <p>{original?.listings?.[0]?.property?.address?.full_address}</p>
            ),
          },
          {
            Header: "Suburb",
            accessor: "suburb",
            Cell: ({
              cell: {
                row: { original },
              },
            }) => (
              <p>
                {
                  original?.listings?.[0]?.property?.address?.street?.suburb
                    ?.name
                }
              </p>
            ),
          },
          {
            Header: "Price",
            accessor: "price_to",
            Cell: ({
              cell: {
                row: { original },
              },
            }) => (
              <p>
                {currencyFormatter(original?.listings?.[0]?.price_from)} -{" "}
                {currencyFormatter(original?.listings?.[0]?.price_to)}
              </p>
            ),
          },
        ],
      },
    ],
    []
  );

  const addToOpenHome = async () => {
    let obj = {
      selected_ids: selectedItem.map((obj) => obj.id),
      buyer: buyerId,
    };
    setIsLoading(true);
    await openHomeBulkUpdate(obj)
      .then((res) => {
        toast.success("Successfully added with open Home");
        setIsLoading(false);
        closeModal();
      })
      .catch(() => {});
  };

  const submitBuyerAppointment = async (values) => {
    let listingIds = selectedItem.map((val) => {
      return val.listings.map((data) => data.id);
    });
    let toValues = _concat(
      _split(values?.to, ","),
      _split(values.additional_emails, ",")
    );
    let processedValues = {
      buyer: buyerId,
      listings: listingIds.flat(),
      subject: values.subject,
      head_text: values.body,
      to: toValues.filter((obj) => obj),
    };
    if (values.preview === 1) {
      processedValues.preview = 1;
    }
    return await sendBuyerListingPack(processedValues).then((res) => {
      if (values.preview === 1) {
        setHtmlData(res.data);
      } else {
        setShowEmail(false);
        toast.success(res?.data?.message);
      }
    });
  };
  const sendBuyerOpenSms = (values) => {
    let unProcessedData = {};
    unProcessedData.sms_attributes = {};
    unProcessedData.sms_attributes.buyer_ids = profile?.id;
    unProcessedData.sms_attributes.additional_numbers =
      values.additional_numbers;
    unProcessedData.sms_attributes.message = values.message;
    unProcessedData.sms_attributes.contact_ids = [profile?.primary_contact.id];

    sendSms("", unProcessedData)
      .then(({ data: { data } }) => {
        setShowSms(false);
        toast.success("Sms Sent Successfully");
      })
      .catch(() => {
        toast.error("Sms Sent Failed");
      });
  };
  return (
    <div>
      {showSms ? (
        <DefaultModal
          size="xl"
          centered
          show={showSms}
          onHide={() => setShowSms(false)}
        >
          <Sms
            handleSubmitForm={sendBuyerOpenSms}
            closeModal={() => setShowSms(false)}
            data={profile?.primary_contact.phones}
            hasDefaultNumbers={true}
            message={`Hi ${
              profile?.primary_contact?.first_name
            }\n We thought you might be interested to attend the open homes of the following properties.\n \n ${selectedItem
              .map((val) =>
                val.listings.map(
                  (data) =>
                    `${data?.property?.address?.full_address} \n ${data?.website_url}`
                )
              )
              .join("\n \n")}
        
`}
          />
        </DefaultModal>
      ) : null}
      {showEmail ? (
        <DefaultModal
          size="xl"
          centered
          show={showEmail}
          onHide={() => setShowEmail(false)}
        >
          <Email
            initialHtmlData={htmlData}
            subject="Open Homes"
            handleSubmitForm={submitBuyerAppointment}
            message={`Hi ${profile?.primary_contact?.first_name}\nWe'd love to see you at one of our next open homes.Here are some properties we feel you might like to inspect. They are sure not to stay on the market long.
          `}
            closeModal={() => setShowEmail(false)}
            data={[profile?.primary_contact]}
          />
        </DefaultModal>
      ) : null}
      {isLoading ? <LinearProgress /> : ""}

      <ThemeHeader
        right={
          <Fragment>
            <ThemeButton
              icon
              onClick={() => addToOpenHome()}
              disabled={isLoading || !selectedItem?.length}
            >
              <i className="fas fa-user-plus"></i>
            </ThemeButton>
            <ThemeButton
              disabled={!selectedItem?.length}
              onClick={() => setShowSms(true)}
              icon
            >
              <PhoneAndroidIcon />
            </ThemeButton>
            <ThemeButton
              disabled={!selectedItem?.length}
              onClick={() => setShowEmail(true)}
              icon
            >
              <EmailIcon />
            </ThemeButton>
            <div className="p-2">
              <ThemeButton className="lightGrey-button" onClick={closeModal}>
                Cancel
              </ThemeButton>
            </div>
          </Fragment>
        }
      />
      <div className="mail-log-table">
        <DynamicTableEdit
          // allSelected={(e) => console.log(e.target.checked)}
          handleChange={(e) => setSelectedItem(e)}
          isEditable={true}
          columns={columns}
          data={items}
          update={fetchMoreData}
        />
      </div>
    </div>
  );
};

export default BuyerOpenHome;
