import React, { Fragment } from 'react'

const Tags = ({profile}) => {
  return (
    <Fragment>
      <h6 className="font-weight-bold">Tags</h6>
      <div className="d-flex flex-wrap bd-highlight pb-3">
     
            {profile?.tags?.map((item, index) => (
              <Fragment key={index}>
                <div className="label-brand">{item.name}</div>
              </Fragment>
            ))}
      </div>
    </Fragment>
  )
}

export default Tags
