import React, { Fragment, useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { Field } from "redux-form";
// import TextInput from "../../TextInput";
import SortableTable from "../../sortableTable/SortableTable";
import Handle from "../../../component/handle/Handle";
import RadioInput from "../../RadioInput";
import MultiSelect from "../../MultiSelect";
import DefaultModal from "../../../modal/DefaultModal";
import LinkedContactModal from "./LinkedContactModal";
import Swal from "sweetalert2";
import { getMenu } from "../../../../GeneralApi/GeneralApi";
import { getDummyTableRow } from "../../../../_helpers/_utils/utils";
import ContactProfileModal from "../../../../modules/contacts/modals/contactProfileModal/ContactProfileModal";

const CONTACTS_NOT_ALLOW_DO_DELETE = [2, 5, 6, 7, 17, 20];

const FormContacts = (props) => {
  const {
    fields,
    update,
    height,
    deleteItem,
    roleFilter,
    hideCheckBox,
    singleListingContact,
    fromSalesAdvice,
    defaultContactType,
    contacts = "",
    setDeletedContact,
  } = props;

  // const [featuresValue, setFeaturesValue] = useState("");
  const [showLinkedContactModal, setShowLinkedContactModal] = useState(false);
  const [allow, setAllow] = useState(false);
  const [showContactProfile, setShowContactProfile] = useState(false);
  const [contact_type, setContact_type] = useState(null);
  const [allContact_type, setAllContact_type] = useState(null);
  const [clickedRowId, setClickedRowId] = useState("");

  useEffect(() => {
    let query = "contact_type&page_name=listing_details";
    getMenu(query)
      .then(({ data: { data } }) => {
        setContact_type(
          // singleListingContact
          //   ? data.contact_type.filter((type) => {
          //       return (
          //         roleFilter.includes(type.id) && singleListingContact
          //       );
          //     })
          //   : fromSalesAdvice
          //   ? data.contact_type.filter(
          //       (type) => [9, 11].includes(type.id) && singleListingContact
          //     )
          //   : data.contact_type

          fromSalesAdvice
            ? data.contact_type.filter(
                (type) => [9, 11].includes(type.id) && singleListingContact
              )
            : data.contact_type.filter((type) => {
                return roleFilter.includes(type.id);
              })
        );
        setAllContact_type(data.contact_type);
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    console.log(update);
    if (update) {
      update.map((item) => {
        console.log(item);
        fields.push({
          id: item.id,
          isSelected: false,
          contact_type: defaultContactType,
          name: item?.first_name + " " + item?.last_name,
          phone: item?.phones ? item?.phones[0]?.value : "",
          email: item?.emails ? item?.emails[0]?.value : "",
        });

        if (item?.linked_contacts?.length) {
          Swal.fire({
            title: "Linked contact found",
            text:
              item.name +
              " is linked to " +
              item.linked_contacts.length +
              " contacts. Would you also like to insert these contacts",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#414042",
            cancelButtonColor: "#C0D9E6",
            confirmButtonText: "Proceed",
          }).then((result) => {
            if (result.isConfirmed) {
              item.linked_contacts.map((obj) => {
                fields.push({
                  id: obj.id,
                  isSelected: false,
                  contact_type: defaultContactType,
                  name: obj?.first_name + " " + obj?.last_name,
                  phone: obj?.phones ? obj?.phones[0]?.value : "",
                  email: obj?.emails ? obj?.emails[0]?.value : "",
                });
              });
              Swal.fire("Added!", "Linked Contacts added.", "success");
            }
          });
        } else {
        }
        console.log("done");
      });
    }
  }, [update]);

  useEffect(() => {
    handleRemove();
  }, [props.deleteItem]);

  const handleRemove = () => {
    let deleted = [];
    for (let i = fields.length - 1; i >= 0; i--) {
      if (fields.get(i).isSelected) {
        fields.remove(i);
        deleted.push(fields.get(i));
      }
    }
    setDeletedContact && setDeletedContact(deleted);
    document.getElementById("checkToggle").checked = false;
  };

  const handleSelectAllToggle = (e) => {
    const selected = document.getElementById("checkToggle").checked
      ? true
      : false;
    if (props.changeProp) {
      fields.map((item) => {
        props.changeProp(`${item}.isSelected`, selected);
      });
    } else {
      const all = fields?.getAll();

      fields.removeAll();
      if (all) {
        all.forEach((item, index) => {
          all[index].isSelected = selected;
          fields.push(item);
        });
      }
    }
  };

  const handleLinkedContact = (e) => {
    console.log(e);
    setAllow(e);
    setShowLinkedContactModal(false);

    // if(e)
    // {
    //   update.map((item) => {
    //     if(item.linked_contacts)
    //     {
    //       item.linked_contacts.map((linkedItem) => {

    //         fields.push({
    //           id: linkedItem.id,
    //           isSelected: false,
    //           role: "",
    //           name: linkedItem?.name,
    //           phone: linkedItem?.phones ? linkedItem?.phones[0]?.value : "",
    //           email: linkedItem?.emails ? linkedItem?.emails[0]?.value : "",
    //         });

    //       })
    //       setShowLinkedContactModal(true)
    //     }
    //   });
    // }
  };

  return (
    <Fragment>
      {showContactProfile ? (
        <DefaultModal
          size="xl"
          centered
          show={showContactProfile}
          onHide={() => setShowContactProfile(false)}
        >
          <ContactProfileModal
            contactId={clickedRowId}
            contactList={contacts}
            continuousMode={contacts.continuousMood}
            closeModal={() => setShowContactProfile(false)}
          />
        </DefaultModal>
      ) : (
        ""
      )}
      {showLinkedContactModal ? (
        <DefaultModal
          size="xl"
          centered
          show={showLinkedContactModal}
          onHide={() => setShowLinkedContactModal(false)}
        >
          <LinkedContactModal
            handleLinkedContact={handleLinkedContact}
            closeModal={() => setShowLinkedContactModal(false)}
          />
          {/* <ContactForm  formName='create-new-contact' onSubmit={HandleOnsubmit} closeModal={() => setShowAddContact(false)} /> */}
        </DefaultModal>
      ) : (
        ""
      )}
      <div
        className="tableTheme theme-table-striped feature_table  "
        style={{ height: `"${height}vh"` }}
      >
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>
                <input
                  id="checkToggle"
                  type="checkbox"
                  onChange={handleSelectAllToggle}
                />
              </th>
              <th>Role</th>
              <th>Name</th>
              <th>Phone</th>
              <th>Email</th>
            </tr>
          </thead>
          {fields.length ? "" : <Fragment></Fragment>}

          <SortableTable fields={fields}>
            {fields.map((item, index) => {
              // console.log(
              //   roleFilter.includes(fields.get(index).contact_type) &&
              //     singleListingContact &&
              //     fields.get(index).contact_type,
              //   item,
              //   index,
              //   singleListingContact,
              //   fields.get(index).contact_type,
              //   roleFilter.includes(fields.get(index).contact_type),
              //   fields.get(index),
              //   "form_contact_"
              // );
              let showPlainText =
                !roleFilter.includes(fields.get(index).contact_type) &&
                singleListingContact &&
                fields.get(index).contact_type;

              // let showCheckBox =
              //   hideCheckBox.includes(fields.get(index).contact_type) &&
              //   singleListingContact &&
              //   fields.get(index).contact_type;

              return (
                <Fragment key={index}>
                  <td>
                    {/* {showCheckBox || !fields.get(index).contact_type ? (
                      <Field
                        name={`${item}.isSelected`}
                        component={RadioInput}
                        type="checkbox"
                      />
                    ) : (
                      ""
                    )} */}
                    {!CONTACTS_NOT_ALLOW_DO_DELETE.includes(
                      fields.get(index).contact_type
                    ) && (
                      <Field
                        name={`${item}.isSelected`}
                        component={RadioInput}
                        type="checkbox"
                      />
                    )}
                  </td>
                  <td>
                    {showPlainText ? (
                      <div className="text-truncate">
                        {
                          allContact_type?.find(
                            (type) => type.id == fields.get(index).contact_type
                          )?.name
                        }
                      </div>
                    ) : (
                      <Field
                        name={`${item}.contact_type`}
                        component={MultiSelect}
                        // return ![10,8,9,11,4].includes(k?.contact_type?.id)).fields.get(index).contact_type?
                        //   (<div>{fields.get(index).contact_type}</div>)
                        //   :<MultiSelect/}
                        showBottom={false}
                        isMulti={false}
                        options={contact_type}
                      />
                    )}
                  </td>
                  <td>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      {fields.get(index).name}

                      <div
                        className="cursor-pointer"
                        onClick={() => {
                          if (contacts !== "") {
                            setShowContactProfile(true);
                            setClickedRowId(fields.get(index).id);
                          }
                        }}
                      >
                        {singleListingContact ? (
                          ""
                        ) : (
                          <i className="fas fa-user-edit" />
                        )}
                      </div>
                    </div>

                    {/* <Field
                      disabled
                      name={`${item}.name`}
                      component={TextInput}
                      type="text"
                      placeholder="Name"
                    /> */}
                  </td>
                  <td>
                    {/* <Field
                      disabled
                      name={`${item}.phone`}
                      component={TextInput}
                      type="text"
                      edit
                      placeholder="Phone"
                    /> */}
                    {fields.get(index).phone}
                  </td>
                  <td>
                    <div className="d-flex pr-2 justify-content-between">
                      {fields.get(index).email ? (
                        fields.get(index).email
                      ) : (
                        <p></p>
                      )}
                      <Handle />
                    </div>
                  </td>
                </Fragment>
              );
            })}
          </SortableTable>

          <tbody>
            {[...Array(getDummyTableRow(height, fields?.length))].map(
              (e, i) => {
                return (
                  <tr key={i}>
                    <td key={i}></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                );
              }
            )}
          </tbody>
        </Table>
      </div>
    </Fragment>
  );
};
FormContacts.defaultProps = {
  height: 79,
  roleFilter: [10, 8, 9, 11, 4],
  hideCheckBox: [10, 8, 9, 11, 4],
};

export default FormContacts;
