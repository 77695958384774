import React, { useState, createContext, useEffect, useRef } from "react";
import { formToQueryString } from "../../../../_helpers/_utils/utils";
import { getAllProspects } from "../../curd/prospectsCrud";
import { useSelector } from "react-redux";
import _last from "lodash/last";
import { toast } from "react-toastify";
import _isEmpty from "lodash/isEmpty";
const _ = require("lodash");

export const MobileContext = createContext({ prospects: [] });
export const { Consumer } = MobileContext;

const queryString = require("query-string");

const MobileContextProvider = (props) => {
//   const [prospects, setProspects] = useState([]);
//   const [continuousMode, setContinuousMode] = useState(false);
//   const [otherResData, setOtherResData] = useState(null);
//   const [prospectQuery, setProspectQuery] = useState(null);
//   const [isLoading, setIsLoading] = useState(false);
//   const [resetFlag, setResetFlag] = useState(false);
//   const [sortFilterItems, setSortFilterItems] = useState("");
//   const [been_contacted_arr, setBeen_contacted_arr] = useState(false);
//   const [pageCount, setPageCount] = useState(0);
//   const searchFilter = useRef({ search_kw: "" });

const [mobileProspect, setMobileProspect] = useState([])
const [profileId, setProfile] = useState(null)
const [prospectLoading, setProspectLoading] = useState(false)
const [prospectQuery, setProspectQuery] = useState(null);
const [otherResData, setOtherResData] = useState(null);


useEffect(() => {
    if (mobileProspect ) {
        setProfile(mobileProspect[0]?.id)
        setProspectLoading(false);
    }
    // debugger
  }, [mobileProspect]);

  let last = _last(mobileProspect  && mobileProspect )?.id
  useEffect(() => {
    if( (last !== undefined  &&  profileId !== undefined ) && ( last=== profileId ))
    fetchMoreData()
  }, [last,profileId]);

  
  const fetchMoreData = () => {

    // debugger
    let obj = {
      ...prospectQuery,
    };

    obj.page = otherResData?.current_page + 1;
    obj.loadMore = true;
    if (otherResData?.next_page_url) {
      update(obj);
      
    }
  };


  const update = (values) => {
    
    let obj = {
        ...values,
        limit: 10,
      };

      if (values?.page) {
        obj.page = values.page;
      }

      const query = formToQueryString(obj);
      getAllProspects(query)
        .then((res) => {
          const { data, ...rest } = res.data;
        
  if(data.length === 0 ){
    // alert("No more prospect")
    // setIsLoading(false);
    toast.error("No more prospects");
  }
       
         if (values?.loadMore) {
            setMobileProspect(mobileProspect.concat(data));
            // setMobileProspect((prev) => ({ ...prev, ...data }));
          } else {
            setMobileProspect(data);
            // setIsLoading(false);
          }
        //   setIsLoading(false);
          setOtherResData(rest);
        //   setResetFlag(false);
          
        })
        .catch(() => {
        //   setIsLoading(false);
        //   setResetFlag(false);
        });
  
    //   if (values?.limit) {
    //     delete obj?.limit;
    //   }
  
    //   setProspectfilter(values);
    };


  return (
    <MobileContext.Provider
      value={{
        mobileProspect,
        setMobileProspect,
        profileId,
        setProfile,
        prospectLoading,
        setProspectLoading,
        prospectQuery,
        setProspectQuery,
        otherResData,
        setOtherResData,
      }}
    >
      {props.children}
    </MobileContext.Provider>
  );
};

export default MobileContextProvider;
