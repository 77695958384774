import React from 'react'
import DynamicTableEdit from "../../../../../partials/table/DynamicTableEdit";
import moment from 'moment';
import { Accordion } from "react-bootstrap";
import CustomToggle from '../../../../../partials/filter/customToggle/CustomToggle';

const PriceFinderValuationTable = ({ propertyDetails }) => {

    const columns = React.useMemo(
        () => [
            {
                Header: "RentalHistory",
                columns: [
                    {
                        Header: "Amount",
                        disableSortBy: true,
                        accessor: "amount",
                        Cell: ({
                            cell: { value },
                            cell: {
                                row: { original },
                            },
                        }) => (
                            <div>
                                {original?.amount ?? ''}
                            </div>
                        ),
                    },
                    {
                        Header: "Date",
                        disableSortBy: true,
                        accessor: "date",
                        Cell: ({
                            cell: {
                                row: { original },
                            },
                        }) => <p>{moment(original?.date).format("DD/MM/YYYY") ?? 'No Date'}</p>,
                    },
                    {
                        Header: "Method",
                        disableSortBy: true,
                        accessor: "method",
                        Cell: ({
                            cell: {
                                row: { original },
                            },
                        }) => (
                            <div>{original?.method ?? ''}</div>
                        ),
                    },
                ],
            },
        ],
        []
    );
    return (
        <div>
            <Accordion >
                <CustomToggle eventKey={2}>
                    {" "}
                    <div className="brand-color">Valuation Details</div>
                </CustomToggle>
                <Accordion.Collapse eventKey={2}>
                    <DynamicTableEdit
                        columns={columns}
                        height={"45"}
                        data={propertyDetails?.valuationDetails?.valuations ?? []}
                    />
                </Accordion.Collapse>
            </Accordion>
        </div>
    )
}

export default PriceFinderValuationTable;