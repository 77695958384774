import React from "react";
import WindowCardContextProvider from "./WindowCardContextProvider";
import Windowcards from "./Windowcards";

const WindowsCardWrap = () => {
    return (
        <div>
            <WindowCardContextProvider>
                <Windowcards />
            </WindowCardContextProvider>
        </div>
    );
};

export default WindowsCardWrap;
