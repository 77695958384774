import React, { useState } from 'react';
import moment from 'moment';
import _map from 'lodash/map';
import _get from 'lodash/get';
import _filter from 'lodash/filter';

const SaleHistory = ({ propertyDetails, checkedSalesHistory, setSelectSaleHistory }) => {
  const handleSelectAllToggle = (e) => {
    setSelectSaleHistory(!checkedSalesHistory);
  };

  // const handleSelect = (e, selectedId) => {
  //   let selectedData = [];
  //   if (selectedId) {
  //     if (!checkedSalesHistory.includes(selectedId)) {
  //       setSelectSaleHistory([...checkedSalesHistory, selectedId]);
  //       selectedData.push(...checkedSalesHistory, selectedId)
  //     } else {
  //       let selectedIds = _filter([...checkedSalesHistory], sale => sale !== selectedId);
  //       setSelectSaleHistory(selectedIds);
  //     }
  //   }
  //   if (saleHistory.length === selectedData.length) {
  //     setSelectAllChecked(true);
  //   } else {
  //     setSelectAllChecked(false);
  //   }

  // };

  const saleHistory = _get(propertyDetails, 'saleHistory.sales', []);
  if (!saleHistory.length) {
    return null;
  }
  return (
    <div>
      <div className="d-flex align-items-center">
        <div className="mt-1 mr-2">
          <input
            id="checkAllSales"
            type="checkbox"
            checked={checkedSalesHistory}
            onChange={handleSelectAllToggle}
          />
        </div>
        <h6 className="title m-0">Sale History</h6>
      </div>
      {_map(saleHistory, (sale, index) => (
        <div key={index} className="ml-4 mb-2 d-flex">
          {/* <div className="mt-1 mr-2">
            <input
              type="checkbox"
              onChange={(e) => handleSelect(e, sale?.id)}
              checked={checkedSalesHistory.includes(sale?.id)}
            />
          </div> */}
          <div>
            <div>Price: {sale?.price?.display}</div>
            <div>Type: {sale?.saleType}</div>
            <div>Date: {sale?.saleDate?.value ? moment(sale?.saleDate?.value).format('DD MMM YYYY') : null}</div>
            <div>Land size: {sale?.landDetails?.propertyArea}</div>
          </div>
        </div>
      ))}

    </div>
  );
}

export default SaleHistory;
