import React, { useState, Fragment, useEffect } from "react";
import { Col, OverlayTrigger, Popover, Row } from "react-bootstrap";
import { reduxForm, Field } from "redux-form";
import { connect } from "react-redux";
import {
  combineValidators,
  isRequired,
  composeValidators,
  hasLengthGreaterThan,
} from "revalidate";
import ThemeHeader from "../../../../partials/content/ThemeHeader";
import ThemeButton from "../../../../partials/button/ThemeButton";
import TextInput from "../../../../partials/form/TextInput";
import ContactPopover from "../../../../partials/popover/ContactPopover";
import DynamicTableEdit from "../../../../partials/table/DynamicTableEdit";
import Skeleton from "@material-ui/lab/Skeleton";
import {
  createNewContact,
  getAllContacts,
  updateContact,
} from "../../crud/contactsCrud";
import DefaultModal from "../../../../partials/modal/DefaultModal";
import NewContact from "../contactFormModal/NewContact";
import {
  convertFormData,
  getObjWithoutValues,
  postProcessedContactInfo,
} from "../../../../_helpers/_utils/utils";
import { toast } from "react-toastify";
const queryString = require("query-string");

const validate = combineValidators({
  title: isRequired({ message: "The event title is required" }),
  category: isRequired({ message: "The category is required" }),
  description: composeValidators(
    isRequired({ message: "Please enter a description" }),
    hasLengthGreaterThan(4)({
      message: "Description needs to be at least 5 characters",
    })
  )(),
  city: isRequired("city"),
  venue: isRequired("venue"),
  checkbox: isRequired("checkbox"),
  startTime: isRequired("date"),
  endTime: isRequired("date"),
});

const AddLinkedContacts = (props) => {
  const {
    handleSubmit,
    pristine,
    closeModal,
    submitting,
    onSelect,
    invalid,
  } = props;
  const [items, setItems] = useState(null);
  const [otherResData, setOtherResData] = useState(null);
  const [formData, setFormData] = useState(null);
  const [selectedData, setSelectedData] = useState([]);
  const [contactModalShow, setContactModalShow] = useState(false);

  // setItems(data.data);
  useEffect(() => {
    update();
  }, []);

  const update = (prams) => {
    let obj = { ...prams }
    if (prams?.loadMore) {
      delete obj?.loadmore
    }

    let query = queryString.stringify(obj);

    getAllContacts(query)
      .then(({ data }) => {
        if (prams?.loadMore) {
          setItems(items.concat(data?.data));
        } else {
          setItems(data?.data);
        }
        setOtherResData(data)
      })
      .catch(() => { });
  };
  const fetchMoreData = () => {
    if (otherResData?.next_page_url) {
      let obj = { page: otherResData.current_page + 1, loadMore: true }
      update(obj);
    }
  }
  useEffect(() => {
    if (formData) {
      // let query = queryString.stringify(formData);
      update(formData);
    }
  }, [formData]);

  const handleSave = () => {
    onSelect(selectedData);
    closeModal();
  };
  const handleContactOnsubmit = (values) => {
    let formatedValue;
    const { id, notes, ...othersContact } = values;

    const postProcessedContact = postProcessedContactInfo(othersContact);

    formatedValue = {
      notes: notes ? (notes[0]?.detail ? notes : null) : null,
      ...postProcessedContact,
    };

    const formData = convertFormData(
      getObjWithoutValues([undefined, null], formatedValue)
    );
    if (id) {
      formData.append("_method", "PATCH");

      updateContact(id, formData)
        .then(async ({ data: { data } }) => {
          toast.success("Contact updated Successfully");
          setContactModalShow(false);
          await update();
          setSelectedData([data, ...selectedData]);
          // setItems([data,...selectedData]);
        })
        .catch((e) => {
          toast.error("Error while saving");
        });
    } else {
      createNewContact(formData)
        .then(async ({ data: { data } }) => {
          toast.success("Contact created Successfully");
          await update();
          setSelectedData([data, ...selectedData]);
          setContactModalShow(false);
          // setItems([...items, data]);
        })
        .catch((e) => {
          toast.error("Error while saving");
        });
    }
  };
  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        columns: [
          {
            Header: "Name",
            accessor: "name",
            Cell: ({
              cell: { value },
              cell: {
                row: { original },
              },
              cell: data,
            }) => (
              <Fragment>
                <OverlayTrigger
                  trigger="hover"
                  placement="right"
                  overlay={
                    <Popover id="popover-basic">
                      <ContactPopover data={original} />
                    </Popover>
                  }
                >
                  <p className="max-content ">
                    {original.first_name} {original.last_name}
                  </p>
                </OverlayTrigger>
              </Fragment>
            ),
          },
          {
            Header: "Phone",
            accessor: "phones",
            Cell: ({
              cell: { value },
              cell: {
                row: { original },
              },
              cell: data,
            }) => <p>{value ? value[0]?.value : ""}</p>,
          },

          {
            Header: "Email",
            accessor: "emails",
            Cell: ({
              cell: { value },
              cell: {
                row: { original },
              },
              cell: data,
            }) => <p>{value ? value[0]?.value : ""}</p>,
          },
        ],
      },
    ],
    []
  );

  return (
    <Fragment>
      {contactModalShow ? (
        <DefaultModal
          size="xl"
          show={contactModalShow}
          onHide={() => setContactModalShow(false)}
        >
          <NewContact
            submitFromDiffComponent={handleContactOnsubmit}
            closeModal={() => setContactModalShow(false)}
            newFormName={"LinkedContact"}
          />
        </DefaultModal>
      ) : (
        ""
      )}
      <div className="buyerFormModal">
        <Fragment>
          <ThemeHeader
            left={
              <Fragment>
                <h5 className="title">Add linked Contacts</h5>
              </Fragment>
            }
            right={
              <Fragment>
                <Fragment>
                  <div className="pl-2">
                    <ThemeButton
                      onClick={() => setContactModalShow(true)}
                      className="submit-button"
                    >
                      Add New Contact
                    </ThemeButton>
                  </div>
                  <div className="pl-2">
                    <ThemeButton
                      className="lightGrey-button"
                      onClick={closeModal}
                    >
                      Cancel
                    </ThemeButton>
                  </div>
                  <div className="pl-2">
                    <ThemeButton
                      disabled={selectedData.length ? false : true}
                      onClick={handleSave}
                      className="submit-button"
                    >
                      Save
                    </ThemeButton>
                  </div>
                </Fragment>
              </Fragment>
            }
          />

          <form
            onChange={() =>
              setTimeout(handleSubmit((params) => setFormData(params)))
            }
          >
            <Row className="pb-3">
              <Col md={3}>
                <Field name="name" placeholder="Name" component={TextInput} />
              </Col>
              <Col md={3}>
                <Field
                  name="phone"
                  placeholder="Phone Number"
                  component={TextInput}
                />
              </Col>
              <Col md={3}>
                <Field
                  name="email"
                  placeholder="Email Address"
                  component={TextInput}
                />
              </Col>
              <Col md={3}>
                <Field
                  name="company_name"
                  placeholder="Company Name"
                  component={TextInput}
                />
              </Col>
            </Row>
          </form>

          <div className="dynamic-table pt-2">
            {items ? (
              <DynamicTableEdit
                handleChange={(e) => setSelectedData(e)}
                isEditable={true}
                columns={columns}
                data={items}
                update={fetchMoreData}
              // handleRowClick={rowClick}
              />
            ) : (
              <div>
                <Skeleton animation="wave" variant="text" />
                <Skeleton animation="wave" variant="text" />
                <Skeleton animation="wave" variant="rect" width="100%" />
              </div>
            )}
          </div>
        </Fragment>
      </div>
    </Fragment>
  );
};

const actions = {
  // socialFeedPost: news.actions.socialFeedPostAsync,
};
const mapState = (state) => ({
  // loading: state.async.loading,
  // buttonName: state.async.elementName,
});
export default connect(
  mapState,
  actions
)(reduxForm({ form: "add-linked-contacts", validate })(AddLinkedContacts));
