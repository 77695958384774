import React, { useState, useEffect, Fragment } from "react";

import { Button } from "react-bootstrap";
import cuid from "cuid";
import { Spin } from "antd";

import { Waypoint } from "react-waypoint";
import { getMenu } from "../../../../../GeneralApi/GeneralApi";
import { formToQueryString } from "../../../../../_helpers/_utils/utils";
import ThemeButton from "../../../../../partials/button/ThemeButton";
import { TimelineDropdown } from "../../../../tenants/modals/tenantProfileModal/timeline/TimelineDropdown";
import TimelineItem from "../../../../../partials/display/timeLine/TimelineItem";
import { getTimelineByProspectId } from "../../../curd/prospectsCrud";
import AccessControl from "../../../../../_ accessControl/AccessControl";

export default function ProspectTimeline({ profileId }) {
  const [selected, setSelected] = useState([]);

  const [pastTimelineOtherData, setPastTimelineOtherData] = useState(null);
  const [pastTimeline, setPastTimeline] = useState([]);
  const [futureTimeline, setFutureTimeline] = useState([]);

  const [timelineType, setTimelineType] = useState(null);
  const [timeLineLoading, setTimeLineLoading] = useState(false);

  useEffect(() => {
    let query = "timeline_activity_type";
    getMenu(query)
      .then(({ data: { data } }) => {
        setTimelineType(
          data.timeline_activity_type.map((s) => ({
            label: s.name,
            value: s.id,
          }))
        );
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    let selectedOptions = selected.map(function(el) {
      return el.value;
    });
    setPastTimeline([]);
    setFutureTimeline([]);
    setPastTimelineOtherData([]);
    getPastUpdate(
      formToQueryString({
        type: selectedOptions,
        direction: "past",
        limit: 20,
        prospect: profileId,
      })
    );
    let futureQuery = formToQueryString({
      type: selectedOptions,
      limit: 50,
      prospect: profileId,
    });
    console.log(futureQuery);
    getFutureUpdate(futureQuery);
    setTimeout(() => {
      if (document.getElementById("timeline-button")) {
        let elmnt = document.getElementById("timeline-button");
        elmnt.scrollIntoView({ behavior: "smooth" });
      }
    }, 2000);
  }, [selected, profileId]);

  const getPastUpdate = (prams) => {
    setTimeLineLoading(true);
    getTimelineByProspectId(prams)
      .then((res) => {
        console.log(res);
        let { data, ...other } = res.data;
        console.log(data);
        console.log(res.data);

        setPastTimeline(data);
        setPastTimelineOtherData(other);
        setTimeLineLoading(false);
      })
      .catch(() => {
        setTimeLineLoading(false);

      });
  };

  const getFutureUpdate = (prams) => {
    setTimeLineLoading(true);
    getTimelineByProspectId(prams)
      .then(({ data: { data } }) => {
        setFutureTimeline(data);
        setTimeLineLoading(false);
      })
      .catch(() => {
        setTimeLineLoading(false);

      });
  };

  const handlePastGetmore = () => {
    let selectedOptions = selected.map(function(el) {
      return el.value;
    });
    if (
      pastTimelineOtherData?.current_page !== pastTimelineOtherData?.last_page
    ) {
      let pastQuery = formToQueryString({
        type: selectedOptions,
        direction: "past",
        limit: 20,
        page: pastTimelineOtherData.current_page + 1,
      });
      getTimelineByProspectId(pastQuery)
        .then((res) => {
          console.log(res);
          let { data, ...other } = res.data;
          console.log(data);
          console.log(res.data);

          setPastTimeline(pastTimeline.concat(data));
          setPastTimelineOtherData(other);
        })
        .catch(() => {});
    }
  };

  return (
    <React.Fragment>
      {
        timeLineLoading
          ? <div className='d-flex justify-content-center mt-5'>
            <Spin />
          </div>
          : <div>
      <div className="timeline-dropdown d-flex justify-content-end">
        <AccessControl
          allowedPermissions={["prospects-download"]}
          renderNoAccess={() => ""}
        >
          <ThemeButton icon>
            <i className="fas fa-download    "></i>
          </ThemeButton>
        </AccessControl>
        <div className="float-item">
          {timelineType ? (
            <TimelineDropdown
              options={timelineType}
              value={selected}
              onChange={setSelected}
            />
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="timeline-body">
        <div className="timeline timeline-5">
          {futureTimeline.length
            ? futureTimeline?.map((item, i) => (
                <Fragment key={cuid()}>
                  <TimelineItem
                    date={item.timeline_date}
                    text={item.text}
                    type={item.type}
                    info_items={item.info_items}
                    id={item.id}
                  />
                  {console.log(item)}
                </Fragment>
              ))
            : ""}
          <div className="timeline-item align-items-start timeline-start-button">
            <div className="timeline-label"></div>
            <div className="timeline-badge">
              <ThemeButton id="timeline-button" className="timeline-button">
                {" "}
                Now{" "}
              </ThemeButton>
            </div>
            <div className="timeline-content"></div>
          </div>
          {pastTimeline.length
            ? pastTimeline?.map((item, i) => (
                <Fragment key={cuid()}>
                  <TimelineItem
                    date={item.timeline_date}
                    text={item.text}
                    type={item.type}
                    info_items={item.info_items}
                    id={item.id}
                  />
                  {console.log(item)}
                </Fragment>
              ))
            : ""}
          <Waypoint onEnter={handlePastGetmore} />
        </div>
      </div>
          </div>
      }
    </React.Fragment>
  );
}
