import React, { useContext, useState, Fragment } from "react";
import { Accordion, Col, Row } from "react-bootstrap";
import _isEqual from 'lodash/isEqual';
import _get from 'lodash/get';
import CustomToggle from "../../../../partials/filter/customToggle/CustomToggle";
import { toAbsoluteUrl } from "../../../../../_metronic";
import { WindowCardContext } from "./WindowCardContextProvider";
import { Field } from "redux-form";
import TextInput from "../../../../partials/form/TextInput";



const window_acc = [
    {
        title: 'For Sale', id: -95, identifier: 'forsale'
    },
    {
        title: 'Auction', id: -97, identifier: 'auction'
    }, {
        title: 'For Lease', id: -99, identifier: 'forLease', rental: true
    },

]
const WindowCardLayouts = ({
    templates,
    _setSelectedTemplate,
    setSelectedTemplateLayout,
}) => {
    const { setShowEditSection } = useContext(WindowCardContext);
    const [selectedIndex, setSelectedIndex] = useState(null);

 console.log('====templatestemplates================================');
 console.log(templates);
 console.log('======templatestemplates==============================');
    return (
        <div>
            {/* <div className="mt-1">
                <Field
                    style={{ paddingLeft: "10px", paddingRight: "10px" }}
                    label={"Search Templates"}
                    name='search_tempaltes'
                    showErrorOnMount={true}
                    component={TextInput}
                />
            </div> */}
            <Accordion defaultActiveKey={0} className='mt-2 social-media-accordian'>
                {templates?.map(({ name, data, id, ...rest }, index) => (
                    <Fragment>
                        <CustomToggle eventKey={index.toString()} rightArrow={true}>
                            <div className=" brand-color">
                                {name ?? "Category Name Here"}
                            </div>
                        </CustomToggle>

                        <Accordion.Collapse className="mb-5" eventKey={index.toString()}>
                            <div>
                                {window_acc.map((val, subIndex) => {
                                  
                                    return <div >
                                        <Accordion defaultActiveKey={0} className='mt-2 '>
                                            <CustomToggle eventKey={subIndex.toString()} rightArrow={true}>
                                                <div className=" pl-5 brand-color">
                                                    {val?.title}
                                                </div>
                                            </CustomToggle>
                                            <Accordion.Collapse className="mb-5" eventKey={subIndex.toString()}>
                                                <div >
                                                    <Row>
                                                        {data[val?.identifier]?.data.map(({ template, html, }, subIndex) =>{                                  
                                                            let rentals  = data[val?.identifier]?.rental                                                                                                                                                     
                                                             return(
                                                            <Col lg={6} md={6} sm={6} key={subIndex}>
                                                                {" "}
                                                                <img
                                                                    onClick={() => {
                                                                        setSelectedIndex([index, subIndex])
                                                                        _setSelectedTemplate(html)
                                                                        setSelectedTemplateLayout({ name, id, ...rest, template,rentals}); 
                                                                        setShowEditSection(false)
                                                                    }}
                                                                    src={toAbsoluteUrl(template)}
                                                                    className={`p-1 ${_isEqual(selectedIndex, [index, subIndex]) ? 'green-border' : ''}`}
                                                                    style={{ width: "100%" }}
                                                                    alt=""
                                                                />
                                                            </Col>
                                                        )})}
                                                    </Row>
                                                </div>
                                            </Accordion.Collapse>
                                        </Accordion>
                                    </div>
                                })}
                            </div>
                        </Accordion.Collapse>
                    </Fragment>
                ))}
            </Accordion>
        </div >
    );
};

export default WindowCardLayouts;
