import React, { Fragment, useEffect, useState } from "react";
import { Accordion, Col, Form, Row } from "react-bootstrap";
import { Field } from "redux-form";
import { getMenu } from "../../../GeneralApi/GeneralApi";
import DateInput from "../../form/DateInput";
import MultiSelect from "../../form/MultiSelect";
import TextInput from "../../form/TextInput";
import CustomToggle from "../customToggle/CustomToggle";

const FamilyDetails = () => {
  const [marital_status, setMarital_status] = useState({});


  useEffect(() => {

    let query = "contact_type,tag,marital_status"
    getMenu(query)
      .then(({ data: { data } }) => {
        setMarital_status(data.marital_status)
      })
      .catch(() => { });
  }, []);
  return (
    <Fragment>
      <CustomToggle eventKey="2" rightArrow={true} tabIndex={'-1'}>Family Details</CustomToggle>
      <Accordion.Collapse className="mb-5" eventKey="2">
        <Form.Group as={Row}>

          <Col className="pb-1" sm={12}>
            <Field
              name="marital_status"
              component={MultiSelect}
              showBottom={false}
              isMulti={false}
              defaultValue
              options={marital_status}
              label="Marital Status"
              placeholder="Marital Status"
            />
          </Col>
          <Col className="pb-1" sm={12}>
            <Field name="children_from" label="Number of Children From" component={TextInput} placeholder="Number of Children From" />
          </Col>
          <Col className="pb-1" sm={12}>
            <Field name="children_to" label="Number of Children To" component={TextInput} placeholder="Number of Children To" />
          </Col>
          <Col className="pb-1" sm={12}>
            <Field name="children_age_from" label="Children Age From" component={TextInput} placeholder="Children Age From" />
          </Col>
          <Col className="pb-1" sm={12}>
            <Field name="children_age_to" label="Children Age To" component={TextInput} placeholder="Children Age To" />
          </Col>
          <Col className="pb-1" sm={12}>
            <Field name="children_dob_from" label="Children Birthday From" type="date" component={DateInput} placeholder="Children Birthday From" />
          </Col>
          <Col className="pb-1" sm={12}>
            <Field name="children_dob_to" label="Children Birthday To" type="date" component={DateInput} placeholder="Children Birthday To" />
          </Col>
          <Col className="pb-1" sm={12}>
            <Field name="income_from" label="Household Income From" component={TextInput} placeholder="Household Income From" />
          </Col>
          <Col className="pb-1" sm={12}>
            <Field name="income_to" label="Household Income To" component={TextInput} placeholder="Household Income To" />
          </Col>
        </Form.Group>
      </Accordion.Collapse>
    </Fragment>
  )
}

export default FamilyDetails
