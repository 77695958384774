import React, { useContext, useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Field, getFormValues, reduxForm, reset } from "redux-form";
import { Form, Col, Row } from "react-bootstrap";

import TextInput from "../../../../partials/form/TextInput";
import MultiSelect from "../../../../partials/form/MultiSelect";
import ThemeButton from "../../../../partials/button/ThemeButton";
import DateInput from "../../../../partials/form/DateInput";
import { DigitalTemplateContext } from "../../pages/digitalTemplate/DigitalTemplateContextProvider";

const validate = (values) => {
  const errors = {};
  if (values.street_number_to) {
    if (!values.street_number_from) {
      errors.street_number_from = "Required";
    }
  }
  return errors;
};

const DrawerForm = (props) => {
  const {
    handleSubmit,
    submitting,
    category,
    anyTouched,
    invalid,
    resetFilter,
    onSubmitDrawerForm,
  } = props;

  const [socialMedaiTemplateCategory, setSocialMedaiTemplateCategory] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    // update()
    console.log("categorycategory", category);
    setSocialMedaiTemplateCategory(category);
  }, [category])


  // const update = () => {
  //   console.log("tjis is drawreeeeeee");
  //   let query = "social_media_template_category";
  //   getMenu(query)
  //     .then(({ data: { data } }) => {
  //       let category = data.social_media_template_category?.sort((a, b) => a.id - b.id)
  //       setSocialMedaiTemplateCategory(category);
  //     })
  //     .catch(() => { })
  // };


  useEffect(() => {
    dispatch(reset("create-email-table"));
  }, [resetFilter]);

  const handleOnSubmit = (values) => {
    const { state, active, ...others } = values;

    let processedValues = {
      state,
      ...others,
    };

    onSubmitDrawerForm(processedValues);
  };

  return (
    <form
      onSubmit={
        handleSubmit(handleOnSubmit)
        // () => console.log("submit")
      }
      className="hide-vertical-scroll  mb-4"
      style={{ maxHeight: "70vh" }}
    >
      <Form.Group as={Row}>
        <Col className="pb-1" sm={12}>
          <Field
            name="category"
            label="Template"
            component={MultiSelect}
            showBottom={false}
            options={socialMedaiTemplateCategory}
            isMulti={false}
          />
        </Col>
        <Col className="pb-1" sm={12}>
          <Field name="name" label="Title" component={TextInput} />
        </Col>
        <div className="col-6 pr-1 pb-2">
          <Field
            label="Date From"
            name="start_from"
            type="date"
            component={DateInput}
          />
        </div>
        <div className="col-6 pl-0 pb-2">
          <Field
            label="Date To"
            name="start_to"
            type="date"
            component={DateInput}
          />
        </div>
      </Form.Group>
      <div className="d-flex">
        <ThemeButton
          type="submit"
          className="w-100 submit-button"
          disabled={submitting || !anyTouched || invalid}
        >
          Filter
        </ThemeButton>
      </div>
    </form>
  );
};

function mapStateToProps(state, props) {
  const values = getFormValues("digital-template-drawer-form")(state);

  return {
    formValues: values,
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: "digital-template-drawer-form",
    enableReinitialize: true,
    touchOnChange: true,
    validate,
  })(DrawerForm)
);
