import React, { Fragment, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Field } from "redux-form";
import { getAllSuburbs, getMenu } from "../../../GeneralApi/GeneralApi";
import VerticalAsyncMultiSelect from "../../../modules/buyers/partials/customMultiSelect/VerticalAsyncMultiSelect";
import VerticalMultiSelect from "../../../modules/buyers/partials/customMultiSelect/VerticalMultiSelect";
import { formToQueryString } from "../../../_helpers/_utils/utils";
import Item from "../../display/Item";
import MultiSelect from "../MultiSelect";
import TextInput from "../TextInput";
import TextInputAmount from "../TextInputAmount";

const getOptionSuburbLabel = (option) => (
  <Fragment>
    {option.label ? `${option.label} ${option?.state_name}` : ""}
  </Fragment>
);
const FormTenantPreferences = () => {
  const [property_type, setProperty_type] = useState({});
  const [date_time, setDate_time] = useState({});

  useEffect(() => {
    let query = "property_type,rental_payment_frequency";
    getMenu(query)
      .then(({ data: { data } }) => {
        setProperty_type(data.property_type);

        setDate_time(data.rental_payment_frequency);
      })
      .catch(() => {});
  }, []);

  const getSuburbOptions = (text) => {
    let query = formToQueryString({ name: text });

    return getAllSuburbs(query)
      .then(({ data }) => {
        console.log(data.data);
        let options = data.data.map((item) => ({
          value: item.id,
          label: item.name,
          state_id: item.state.id,
          state_name: item.state.abbrev,
          postcode: item.postcode,
        }));
        console.log(options);
        return options;
      })
      .catch((e) => {});
  };

  return (
    <Row className="pb-3">
      <Col xl="12">
        <h5 className="title font-weight-bold">Preferences</h5>
      </Col>
      <Col xl="12">
        <Row>
          <Col xl="6">
            <Item
              lg="3"
              md="3"
              col="3"
              left="Price"
              right={
                <Form.Group as={Row}>
                  <Col sm={4} className="pr-3 pr-md-0 pb-3 pb-md-3">
                    <Field
                      name="rent_from"
                      type="text"
                      component={TextInputAmount}
                      placeholder="From"
                    />
                  </Col>
                  <Col sm={4} className="pr-3 pr-md-0 pb-3 pb-md-3">
                    <Field
                      name="rent_to"
                      type="text"
                      component={TextInputAmount}
                      placeholder="To"
                    />
                  </Col>
                  <Col sm={4} className="pr-3 pr-md-0 pb-3 pb-md-3">
                    <Field
                      name="payment_frequency"
                      component={MultiSelect}
                      className="w-100"
                      showBottom={false}
                      isMulti={false}
                      options={date_time}
                    />
                  </Col>
                </Form.Group>
              }
            />
          </Col>
          <Col xl="12">
            <Field
              name="suburbs"
              placeholder="Select Location"
              component={VerticalAsyncMultiSelect}
              label="Locations"
              getOptionLabel={getOptionSuburbLabel}
              options={getSuburbOptions}
            />
            <Field
              name="property_types"
              placeholder="Select a property type"
              component={MultiSelect}
              showBottom={false}
              isMulti={true}
              options={property_type}
              label="Property Type"
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default FormTenantPreferences;
