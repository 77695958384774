import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { Image } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../_metronic";

const imagePlaceholderStyle = {
  cursor: "pointer",
};

export const UserImageInput = (props) => {
  const {
    input: { onChange, value },
    disabled,
    isCircle,
  } = props;
  console.log(value);
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    multiple: false,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      onChange(acceptedFiles);
    },
  });

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
      if (typeof props.getImage === "function") {
        props.getImage(files);
      }
    },
    [files]
  );

  if (disabled) {
    return null;
  }

  return (
    <div
      {...getRootProps()}
      className="text-center profile-image-container"
      style={imagePlaceholderStyle}
    >
      <input {...getInputProps()} />
      {files?.length ? (
        <Image
          className={props.className}
          src={files[0].preview}
          roundedCircle={props.roundedCircle}
        />
      ) : (
        <Image
          className={props.className}
          src={value ? value : toAbsoluteUrl(props.image_url)}
          roundedCircle={props.roundedCircle}
        />
      )}
      {props.hide_mask ? (
        ""
      ) : (
        <div className={`mask ${isCircle ? "" : "service-Mask"}`}></div>
      )}
    </div>
  );
};

UserImageInput.defaultProps = {
  image_url: "/media/users/default.jpg",
  className: "profile-image",
  fullWidth: true,
  roundedCircle: true,
  hide_mask: false,
  isCircle: true,
};
