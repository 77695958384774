import React from 'react'
import NumberFormat from 'react-number-format';

const NumberFormatCustom = (props) => {
  const { inputRef, onChange,value, setZeroForEmpty = false, ...other } = props;

  const handleOnChange = (values)=> {

    onChange( values.floatValue ?? 0);
  }
    return (
        <NumberFormat
        {...other}
        value={value == 0 && !setZeroForEmpty ? '' : value}
        displayType={'number'} 
        // isNumericString={true}
        getInputRef={inputRef}
        onValueChange={(values) =>handleOnChange(values)}
        thousandSeparator
      />
    )
}

export default NumberFormatCustom