import React from "react";
// import moment from "moment";
import { CharacterCircle } from "../../component/characterCircle/CharacterCircle";
import { getFormattedDate } from "../../../_helpers/_utils/utils";

export default function LastContact({ date, chanel, by }) {
  return (
    <>
      {date && (
        <div className="d-flex justify-content-center">
          <div>{getFormattedDate(date)}</div>
          {by?.id ? <span>({by?.initial})</span> : ""}
          <div>
            {chanel === "Appointment" ? (
              <CharacterCircle background="appointment" small character="A" />
            ) : null}
            {chanel === "Note" ? (
              <CharacterCircle background="note" small character="N" />
            ) : null}
          </div>
        </div>
      )}
    </>
  );
}
