import React, { useState, Fragment, useEffect, useContext } from "react";
import { connect } from "react-redux";
import {
  reduxForm,
  Field,
  FieldArray,
  getFormValues,
  FormSection,
} from "redux-form";
import { Form, Col, Row } from "react-bootstrap";
import { InputAdornment } from "@material-ui/core";

import ThemeHeader from "../../../../../partials/content/ThemeHeader";
import ThemeButton from "../../../../../partials/button/ThemeButton";
import DateInput from "../../../../../partials/form/DateInput";
import TextInput from "../../../../../partials/form/TextInput";
import MultiSelect from "../../../../../partials/form/MultiSelect";
import ToggleSwitch from "../../../../../partials/form/toggleSwitch/ToggleSwitch";
import FormTags from "../../../../../partials/form/partials/FormTags";
import FormFeatures from "../../../../../partials/form/partials/FormFeatures";
import { getMenu } from "../../../../../GeneralApi/GeneralApi";
import { updateProspect } from "../../../curd/prospectsCrud";
import {
  convertFormData,
  getObjWithoutValues,
  updateProspectDetails,
} from "../../../../../_helpers/_utils/utils";
import { toast } from "react-toastify";
import { ProspectProfileContext } from "../ProspectProfileContextProvider";
import { ProspectsContext } from "../../../pages/prospects/ProspectsContextProvider";
import TextInputAmount from "../../../../../partials/form/TextInputAmount";
import PropertyAddressSwitch from "../../../../../partials/form/PropertyAddressSwitch";

const companiesOptions = [
  { id: 0, name: "Competitors" },
  { id: 1, name: "Wiseberry" },
];

const ProspectDetailsEdit = (props) => {
  const {
    handleSubmit,
    submitting,
    change,
    fieldArray,
    formValues,
    prospectId,
  } = props;

  const [rating, setRating] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { updateContext } = useContext(ProspectProfileContext);

  const { updateTable } = useContext(ProspectsContext);

  useEffect(() => {
    let query =
      "buyer_to_sell,property_type,prospect_rating,finance_status,buyer_condition,buyer_type,date_time_unit";
    getMenu(query)
      .then(({ data: { data } }) => {
        setRating(data.prospect_rating);
      })
      .catch(() => {});
  }, []);

  const onFormSubmit = async () => {
    setIsSubmitting(true);

    let savingValue = {
      ...updateProspectDetails(formValues),
    };

    const formData = convertFormData(
      getObjWithoutValues([undefined, null], savingValue)
    );

    formData.append("_method", "PATCH");
    await updateProspect(prospectId, formData)
      .then(({ data }) => {
        setIsSubmitting(false);
        updateContext();
        toast.success("Prospect Saved Successfully");

      })
      .catch((e) => {
        toast.error("Error while prospect saving");
      });
  };

  return (
    <Fragment>
      <div className="buyerDetails">
        <Form onSubmit={handleSubmit(onFormSubmit)} noValidate>
          <ThemeHeader
            left={<h5 className="title">Property Details</h5>}
            right={
              <ThemeButton
                type="submit"
                className="submit-button"
                disabled={submitting || isSubmitting}
              >
                Save
              </ThemeButton>
            }
          />
          <FormSection name="property">
            <PropertyAddressSwitch
              changeProp={change}
              formSectionName={"property"}
              name={fieldArray ? `${fieldArray}.address` : "address"}
              googleAddressName="domain_address"
              placeHolder="Address"
              label="Address"
            />
          </FormSection>

          <Row className="pb-3">
            <Col className="mt-auto" md={4}>
              <Field
                label="Purchase Date"
                name="purchase_date"
                type="date"
                component={DateInput}
              />
            </Col>
            <Col className="mt-auto" md={4}>
              <Field
                name="purchase_price"
                type="text"
                component={TextInputAmount}
                placeholder="Price"
                label="Price"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            </Col>
            <Col className="mt-auto" md={4}>
              <Field
                name="rating"
                label="Rating"
                placeholder="Select a rating"
                component={MultiSelect}
                showBottom={false}
                isMulti={false}
                options={rating}
              />
            </Col>
          </Row>
          <Row className="pt-3 pb-2 align-items-center">
            <Col md={4}>
              <Row className="align-items-center">
                <Col lg={5} sm={3}>
                  <div class="min-content">Do Not Call?:</div>
                </Col>
                <Col lg={7} sm={9}>
                  <Field name="dnc" component={ToggleSwitch} />
                </Col>
              </Row>
            </Col>
            <Col md={4}>
              <Row className="align-items-center">
                <Col sm={3}>
                  <div class="min-content">Listing?:</div>
                </Col>
                <Col sm={formValues.listing ? 3 : 9}>
                  <Field name="listing" component={ToggleSwitch} />
                </Col>

                {formValues.listing === 1 ? (
                  <Col sm={6}>
                    <div className="ml-2">
                      <Field
                        name="lwu"
                        label="Listed With"
                        component={MultiSelect}
                        showBottom={false}
                        isMulti={false}
                        options={companiesOptions}
                      />
                    </div>
                  </Col>
                ) : (
                  ""
                )}
              </Row>
            </Col>
            <Col md={4}>
              <Row className="align-items-center">
                <Col sm={3}>
                  <div class="min-content">Rental?:</div>
                </Col>
                <Col sm={formValues.rental ? 3 : 9}>
                  <Field name="rental" component={ToggleSwitch} />
                </Col>

                {formValues.rental === 1 ? (
                  <Col sm={6}>
                    <div className="ml-2">
                      <Field
                        name="rwu"
                        label="Rented WIth"
                        component={MultiSelect}
                        showBottom={false}
                        isMulti={false}
                        options={companiesOptions}
                      />
                    </div>
                  </Col>
                ) : (
                  ""
                )}
              </Row>
            </Col>
          </Row>
          {formValues.listing === 1 && (
            <>
              {formValues.lwu !== 1 ? (
                <Row>
                  <Col className="mt-auto" md={4}>
                    <Field
                      name="lwo"
                      label="Listed With"
                      placeholder="Listed With"
                      component={TextInput}
                    />
                  </Col>
                  <Col className="mt-auto" md={4}>
                    <Field
                      name="lwo_price"
                      type="text"
                      component={TextInput}
                      placeholder="Price"
                      label="Price"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                    />
                  </Col>
                  <Col className="mt-auto" md={4}>
                    <Field
                      name="lwo_expiry_date"
                      label="Expiry Date"
                      type="date"
                      component={DateInput}
                    />
                  </Col>
                </Row>
              ) : (
                ""
              )}
            </>
          )}

          {formValues.rental === 1 && (
            <>
              {formValues.rwu !== 1 ? (
                <Row>
                  <Col className="mt-auto" md={4}>
                    <Field
                      name="rwo"
                      label="Rented With"
                      placeholder="Rented With"
                      component={TextInput}
                    />
                  </Col>
                  <Col className="mt-auto" md={4}>
                    <Field
                      name="rwo_price"
                      type="text"
                      component={TextInput}
                      placeholder="Price"
                      label="Price"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                    />
                  </Col>
                </Row>
              ) : (
                ""
              )}
            </>
          )}
         {/*<Row className="pb-2">
            <FormTags />
          </Row>*/}
          <FormSection name="property">
            <FieldArray name="features" component={FormFeatures} height="30" />
          </FormSection>
        </Form>
      </div>
    </Fragment>
  );
};

ProspectDetailsEdit.defaultProps = {
  fieldArray: "",
};
const mapState = (state, ownProps) => {
  const values = getFormValues("prospectDetailsform")(state);
  return {
    formValues: values,
  }; // Form name must have to be different
};
export default connect(mapState)(
  reduxForm({
    form: "prospectDetailsform",
    enableReinitialize: true,
  })(ProspectDetailsEdit)
);
