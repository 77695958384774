
import React, { useEffect, useState } from 'react';
import FeaturesSettings from './FeaturesSettings';
import { TextField, /* Select, MenuItem */ } from '@material-ui/core'

const InspectionSettings = ({ selectedNode }) => {
    const [address, setAddress] = useState('');
    const getTargetElement = targetClassName => {
        return selectedNode.getElementsByClassName(targetClassName)[0]
    }
    useEffect(() => {
        console.log('selectedNodeselectedNode', selectedNode)
        setAddress(getTargetElement('address').innerText)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedNode])

    const handleAddressChange = e => {
        getTargetElement('address').innerHTML = e.target.value
        setAddress(e.target.value)
    }
    return (
        <div>
            <TextField
                className='my-3'
                type="text"
                label="Address"
                value={address}
                onChange={handleAddressChange}
                fullWidth
                InputLabelProps={{
                    shrink: true
                }}
            />
            <div className='mt-2'>
                <FeaturesSettings selectedNode={selectedNode} />
            </div>
        </div>
    )
}

export default InspectionSettings
