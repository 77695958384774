import React, { Fragment, useState, useEffect, useContext } from "react";
import { Row } from "react-bootstrap";
import NoteLists from "../../../../../partials/component/notes/NoteLists";
import { Alert, AlertTitle } from "@material-ui/lab";
import {
  convertFormData,
  formToQueryString,
  wbSuccessToast,
  getObjWithoutValues,
  getProcessedContactInfo,
} from "../../../../../_helpers/_utils/utils";
import { createNotes, updateNotes } from "../../../../../GeneralApi/GeneralApi";
import { toast } from "react-toastify";
import ProspectNotesForm from "./ProspectNotesForm";
import { postDoorKnock } from "../../../../buyers/crud/buyersCrud";
import { updateProspect, getNotes } from "../../../curd/prospectsCrud";
import { ProspectProfileContext } from "../ProspectProfileContextProvider";
import QuickContact from "../../../../../partials/form/quickContact/QuickContact";
import {
  updateContact,
  createNewContact,
} from "../../../../contacts/crud/contactsCrud";
import { Spin } from "antd";

const ProspectNotes = (props) => {
  const {
    profileId,
    profile: { contacts },
    updateTable,
    setPageCount,
    selectedHeaderTab,
  } = props;
  const [notes, setNotes] = useState(null);
  const {
    continuousMode,
    updateContext,
    changeDirection,
    setProspectLoading,
    pc,
    dk,
    updateNote,
    setUpdateNote,
  } = useContext(ProspectProfileContext);
  const [otherResData, setOtherResData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const newProfile = contacts;

  useEffect(() => {
    update();
  }, [profileId]);

  useEffect(() => {
    if (updateNote) {
      setUpdateNote(false);
      update();
    }
  }, [updateNote]);

  const update = (value) => {
    if (profileId) {
      setIsLoading(true);
      setProspectLoading(true);
      let obj = {
        prospect: profileId,
        limit: 100,
      };

      if (value?.page) {
        obj.page = value.page;
      }

      const query = formToQueryString(obj);
      getNotes(query)
        .then((res) => {
          const { data, ...rest } = res.data;
          if (value?.loadMore) {
            setNotes(notes.concat(data));
          } else {
            setNotes(data);
          }
          setOtherResData(rest);
          setProspectLoading(false);
          setIsLoading(false);
        })
        .catch(() => {});
    }
  };

  const handleMakePinned = (noteId, isPinned) => {
    const formData = convertFormData({});
    formData.append("pinned", isPinned === 1 ? 0 : 1);
    formData.append("_method", "PATCH");
    if (profileId) {
      updateNotes(noteId, formData)
        .then(({ data: { data } }) => {
          wbSuccessToast("success", data.message);
          update();
        })
        .catch((e) => {});
    }
  };

  const handleOnSubmit = async (e) => {
    let storedNote = localStorage.getItem("note");

    let postCall;
    setProspectLoading(true);

    let obj = {};

    if (
      e.type == "NA" &&
      e.notes == null &&
      storedNote === (undefined || null)
    ) {
      obj = {
        prospect: profileId,
        na: 1,
        note: { type: 2, detail: "Na Clicked", prospect: profileId },
      };
      const doorKnockData = convertFormData(obj);
      postCall = await postDoorKnock(doorKnockData)
        .then(({ data: { data } }) => {
          setProspectLoading(false);
          toast.success("Na Saved");
          update();
        })
        .catch((e) => {
          setProspectLoading(false);
          wbSuccessToast("error", "Data Error");
        });

      if (continuousMode) {
        changeDirection("right");
        setPageCount((count) => count + 1);
      }
      if (document.getElementById("prospect-notes-note")) {
        document.getElementById("prospect-notes-note").value = "";
      }
    } else {
      if (storedNote === (undefined || null) && e.notes == null) {
        setProspectLoading(false);
        //toast.error("Note is empty");
      } else {
        if (e.type) {
          if (e.type == "PC") {
            obj = {
              prospect: profileId,
              phone_call: 1,
              note: {
                type: 4,
                detail: storedNote || e.notes[0].detail,
                prospect: profileId,
              },
            };
          } else if (e.type == "NA") {
            obj = {
              prospect: profileId,
              na: 1,
              note: {
                type: 2,
                detail: storedNote || e.notes[0].detail,
                prospect: profileId,
              },
            };
          } else if (e.type == "DK") {
            obj = {
              prospect: profileId,
              door_knock: 1,
              note: {
                type: 4,
                detail: storedNote || e.notes[0].detail,
                prospect: profileId,
              },
            };
          }
          const doorKnockData = convertFormData(obj);
          postCall = await postDoorKnock(doorKnockData)
            .then(({ data: { data } }) => {
              update();
              setProspectLoading(false);
              localStorage.removeItem("note");
            })
            .catch((e) => {
              setProspectLoading(false);
              wbSuccessToast("error", "Data Error");
            });
        } else {
          if(!e.notes?.[0])return;
          const formData = convertFormData(e.notes[0]);
          formData.append("prospect", profileId);
          if (profileId) {
            postCall = await createNotes(formData)
              .then(({ data: { data } }) => {
                wbSuccessToast("success", data.message);
                update();
                setProspectLoading(false);
              })
              .catch((e) => {
                setProspectLoading(false);
                wbSuccessToast("error", "Data Error");
              });
          }
        }
        if (continuousMode) {
          changeDirection("right");
          setPageCount((count) => count + 1);
        }
        if (document.getElementById("prospect-notes-note")) {
          document.getElementById("prospect-notes-note").value = "";
        }
      }
    }

    return postCall;
  };

  const handleAddContact = async (e) => {
    setProspectLoading(true);
    const { contacts } = e;
    let contact_id;
    let processedContact = getProcessedContactInfo(contacts[0]);

    const formData = convertFormData(processedContact);

    const noteDetails = `New Contact Added : First Name: ${
      contacts[0]?.first_name
    } || Last Name: ${contacts[0]?.last_name} ${
      contacts[0]?.phones[0]?.value !== ""
        ? `|| Phone: ${contacts[0]?.phones[0]?.value}`
        : ""
    } ${
      contacts[0]?.emails[0]?.value !== ""
        ? `|| Email: ${contacts[0]?.emails[0]?.value}`
        : ""
    }`;

    const noteType = 4;

    if (processedContact?.contact_id) {
      formData.append("_method", "PATCH");
      await updateContact(processedContact?.contact_id, formData)
        .then(({ data: { data } }) => {
          // console.log(data);
          contact_id = data.id;
          toast.success("Contact Updated Successfully");
          if (!newProfile?.length) {
            let payload = {
              prospect: profileId,
              detail: noteDetails,
              type: noteType,
            };
            const formData = convertFormData(payload);
            createNotes(formData)
              .then(({ data: { data } }) => {
                wbSuccessToast("success", data.message);
                setProspectLoading(false);
                update();
              })
              .catch((e) => {
                setProspectLoading(false);
                wbSuccessToast("error", "Data Error");
              });
          }

          setProspectLoading(false);
        })
        .catch((e) => {
          // console.log(e.value, e, "errormesage");
          toast.error("Error While Saving");
          setProspectLoading(false);
        });
    } else {
      await createNewContact(formData)
        .then(({ data: { data } }) => {
          // console.log(data);
          contact_id = data.id;

          let payload = {
            prospect: profileId,
            detail: noteDetails,
            type: noteType,
          };
          // debugger
          const formData = convertFormData(payload);
          createNotes(formData)
            .then(({ data: { data } }) => {
              wbSuccessToast("success", data.message);
              setProspectLoading(false);
              update();
            })
            .catch((e) => {
              setProspectLoading(false);
              wbSuccessToast("error", "Data Error");
            });

          toast.success("Contact Created Successfully");
          setProspectLoading(false);
        })
        .catch((e) => {
          // console.log(e.value, e, "errormesage");
          toast.error("Error While Saving");
          setProspectLoading(false);
        });
    }

    let prospectFormValue = {};
    prospectFormValue.contacts = [contact_id];

    let prospectFormatedData = convertFormData(
      getObjWithoutValues([undefined, null], prospectFormValue)
    );

    prospectFormatedData.append("_method", "PATCH");

    updateProspect(profileId, prospectFormatedData)
      .then(({ data }) => {
        // console.log(data);
        toast.success("Prospect Updated Successfully");
        updateContext();
      })
      .catch((e) => {});
  };

  const fetchMore = () => {
    if (otherResData?.next_page_url) {
      update({ page: otherResData.current_page + 1, loadMore: true });
    }
  };
  return (
    <Fragment>
      {contacts?.length ? null : (
        <div>
          <Alert severity="error">
            <AlertTitle>No Contact Found</AlertTitle>
            Please add a contact through <strong>Quick Contact</strong> or
            Contact Tab before saving a note
          </Alert>
          <QuickContact onSubmit={handleAddContact} />
        </div>
      )}
      <Spin
        spinning={isLoading}
        tip={"Loading..."}
        style={{ color: "#822633" }}
      >
        <ProspectNotesForm
          contacts={contacts}
          onSubmit={handleOnSubmit}
          profileId={profileId}
          setPageCount={setPageCount}
          selectedHeaderTab={selectedHeaderTab}
        />
        <Row></Row>
        <NoteLists
          makePinned={handleMakePinned}
          fetchMore={fetchMore}
          notes={notes}
        />
      </Spin>
    </Fragment>
  );
};

export default ProspectNotes;
