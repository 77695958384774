import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker.css";
import DynamicTableEdit from "../../../../../partials/table/DynamicTableEdit";
import { getTenantMatchedProperty } from "../../../crud/tenantsCrud";
import LoadingSkeleton from "../../../../../partials/display/skeleton/LoadingSkeleton";
import clsx from "clsx";
import TenantMatchedHeader from "./TenantMatchedHeader";
import StreetAddress from "../../../../../partials/display/StreetAddress";
import { formToQueryString, getFeaturesProcessedData } from "../../../../../_helpers/_utils/utils";
import MemberName from "../../../../../partials/popover/memberPopover/MemberName";
import Status from "../../../../../partials/table/cells/Status";
import ToolTip from "../../../../../partials/tooltip/ToolTip";
import moment from "moment";

const TenantMatched = ({ edit, handleChange, profileId, profile }) => {
  const [items, setItems] = useState(null);
  const [matchedBy, setMatchedBy] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [otherResData, setOtherResData] = useState(null);
  // setItems(data.data);
  console.log(edit);
  // useEffect(() => {
  //   update()
  // }, []);

  useEffect(() => {
    update({ page: 1 });
  }, [matchedBy]);

  const update = (prams) => {
    let obj = {
      limit: 30,
      ...prams,
    }
    if (matchedBy) {
      obj.match_by = matchedBy;
    }
    if (prams?.page) {
      obj.page = prams.page;
    }
    obj.tenant =  profileId 
    if (obj.loadMore) {
      delete (obj.loadMore)
    }
    let query = formToQueryString(obj);
    getTenantMatchedProperty( query)
      .then(({ data }) => {
        if (prams.loadMore) {
          setItems(items.concat(data?.data?.data));
        } else {
          setItems(data?.data?.data);
        }
        setOtherResData(data.data)
      })
      .catch(() => { });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Matched",
        columns: [
          {
            Header: "Address",
            accessor: "address",
            Cell: ({
              cell: {
                row: { original },
              },
            }) => <p>{original?.property?.address?.full_address}</p>,
          },
          {
            Header: "Suburb",
            accessor: "suburb",
            Cell: ({
              cell: {
                row: { original },
              },
            }) => <StreetAddress value={original?.property?.address} />,
          },
          {
            Header: "Postcode",
            accessor: "postcode",
            Cell: ({
              cell: {
                row: { original },
              },
            }) => (
              <p>{original?.property?.address?.street?.suburb?.postcode}</p>
            ),
          },
          {
            Header: "Price",
            accessor: "price_to",
            Cell: ({
              cell: {
                row: { original },
              },
            }) => <p>${original?.rent_amount}</p>,
          },
          {
            Header: <i className="fas fa-bed"></i>,
            accessor: "bed",
            Cell: ({
              cell: {
                row: { original },
              },
            }) => (
              <p className="text-center">
                {getFeaturesProcessedData(
                  original?.property?.features,
                  "Bedroom"
                )}
              </p>
            ),
          },
          {
            Header: <i className="fas fa-bath"></i>,
            accessor: "bath",
            Cell: ({
              cell: { value },
              cell: {
                row: { original },
              },
            }) => (
              <p className="text-center">
                {getFeaturesProcessedData(
                  original?.property?.features,
                  "Bathroom"
                )}
              </p>
            ),
          },
          {
            Header: <i className="fas fa-car"></i>,
            accessor: "parking",
            Cell: ({
              cell: { value },
              cell: {
                row: { original },
              },
            }) => (
              <p className="text-center">
                {" "}
                {getFeaturesProcessedData(
                  original?.property?.features,
                  "Parking"
                )}
              </p>
            ),
          },
          {
            Header: <i className="fas fa-paw    "></i>,
            accessor: "is_pet_friendly",
            Cell: ({
              cell: { value },
              cell: {
                row: { original },
              },
            }) => (
              <p>
                <i
                  className="fas fa-circle"
                  style={{
                    color: original?.is_pet_friendly == 1 ? "green" : "red",
                  }}
                ></i>
              </p>
            ),
          },
          {
            Header: "Team",
            accessor: "property_manager",
            Cell: ({
              cell: { value },
              cell: {
                row: { original },
              },
            }) => (
              <MemberName
                id={
                  original?.contacts?.find(
                    (contact) => contact?.contact_type?.id === 6
                  )?.id
                }
              >
                {" "}
                {
                  original?.contacts?.find(
                    (contact) => contact?.contact_type?.id === 6
                  )?.initial
                }
              </MemberName>
            ),
          },
          {
            Header: "Type",
            accessor: "type",
            Cell: ({
              cell: { value },
              cell: {
                row: { original },
              },
            }) => (
              <p>
                {" "}
                {value?.id === 1 && (
                  <ToolTip name="home" justIcon content="Permanent" />
                )}
                {value?.id === 2 && (
                  <ToolTip name="plane" justIcon content="Holiday" />
                )}
                {value?.id === 3 && (
                  <ToolTip name="store" justIcon content="Commercial" />
                )}
              </p>
            ),
          },
          {
            Header: "Status",
            // accessor: "property_type",
            Cell: ({
              cell: { value },
              cell: {
                row: { original },
              },
            }) => (
              <Status
                show_on_website={original.show_on_website}
                marketing_text={original.marketing_text}
              />
            ),
          },
        ],
      },
    ],
    []
  );
  console.log(items);
  const fetchMoreData = () => {
    let obj = {};
    obj.page = otherResData.current_page + 1;
    if (otherResData?.next_page_url) {
      obj.loadMore = true
      update(obj);
    }
  };

  const rowClick = (e) => {
    // setShowRowOnClick(!showRowOnClick)
  };

  const datas = React.useMemo(() => items, [items]);

  const handleMatchingBy = (e) => {
    Object.keys(e).forEach((key) => {
      if (e[key] !== 1) delete e[key];
    });
    let key = Object.keys(e);
    setMatchedBy(key.join(","));
  };
  const handleGlobalDebounceSearch = (value) => {
    let obj = {}
    if (value) {
      obj.search_kw = value
    }
    update(obj)
  };

  return (
    <div>
      <TenantMatchedHeader
        profileId={profileId}
        selectedItem={selectedItem}
        totalNumber={datas?.length ? datas?.length : 0}
        onSubmit={handleMatchingBy}
        profile={profile}
        handleGlobalSearch={handleGlobalDebounceSearch}
      />
      <div className="mail-log-table">
        {datas ? (
          <DynamicTableEdit
            allSelected={(e) => console.log(e.target.checked)}
            handleChange={(e) =>
              setSelectedItem(
                e.map((obj) => ({
                  date: moment().format("YYYY-MM-DD"),
                  address: obj?.property?.address?.full_address,
                  rental: obj.id,
                  offer_party: 1,
                  primary_contact: obj?.contacts?.[0],
                }))
              )
            }
            isEditable={true}
            columns={columns}
            data={datas}
            update={fetchMoreData}
          />
        ) : (
          <LoadingSkeleton height="40vh" />
        )}
      </div>
    </div>
  );
};

export default TenantMatched;
