import React, { useEffect, useState, useRef } from "react";
import { Fragment } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Field, getFormValues, reduxForm } from "redux-form";
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { Editor } from "@tinymce/tinymce-react";
import { getMenu } from "../../../../GeneralApi/GeneralApi";
import ThemeButton from "../../../../partials/button/ThemeButton";
import ThemeHeader from "../../../../partials/content/ThemeHeader";
import MultiSelect from "../../../../partials/form/MultiSelect";
import TextArea from "../../../../partials/form/TextArea";
import Templates from "./templatesBlog";
import { useSelector } from "react-redux";
import TextEditor from "../../../../partials/form/TextEditor";
import TextInput from "../../../../partials/form/TextInput";
import ToggleSwitch from "../../../../partials/form/toggleSwitch/ToggleSwitch";
import { UserImageInputWithCropper } from "../../../../partials/form/UserImageInputWithCropper";
import ToolTip from "../../../../partials/tooltip/ToolTip";
import { convertFormData } from "../../../../_helpers/_utils/utils";
import { updateBlog } from "../../../setting/_crud/settingCrud";
import { plugins } from "chart.js";

const AddNewBlogPost = ({ closeModal, closeWithAlert, handleSubmit, selectedBlog, setSelectedBlog, formValues, invalid, pristine, submitting, content, setContent}) => {
  const [tag, setTags] = useState({});
  const formRef = useRef(null)
  const editorRef = useRef(null);
  const org = useSelector((state) => state.auth.user.organisation.id);
const [plugins, setPlugins] = useState() 
const [editimage_cors_hosts, setEditimageCorsHosts] = useState()
const [menubar, setMenuBar] = useState()
const [toolbar, setToolBar] = useState()
const [colorMap ,setColorMap] = useState([])
 
  const useDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
  const isSmallScreen = window.matchMedia('(max-width: 1023.5px)').matches;

  useEffect(() => {
    let query = "blog_tag";
    getMenu(query)
      .then(({ data: { data } }) => {
        setTags(data.blog_tag);
      })
      .catch(() => { });
    formRef.current = {}

    if(org === 4){
      setPlugins('preview importcss searchreplace autolink autosave  directionality code visualblocks visualchars fullscreen image link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons')
      setEditimageCorsHosts(['picsum.photos'])
      setMenuBar('file edit view insert format tools table help')
      setToolBar('undo redo | bold italic underline strikethrough | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview  print | insertfile image media template link anchor codesample | ltr rtl')
      setColorMap([
        "000000", "Black",
        "993300", "Burnt orange",
        "333300", "Dark olive",
        "003300", "Dark green",
        "003366", "Dark azure",
        "000080", "Navy Blue",
        "333399", "Indigo",
        "333333", "Very dark gray",
        "800000", "Maroon",
        "FF6600", "Orange",
        "808000", "Olive",
        "008000", "Green",
        "008080", "Teal",
        "0000FF", "Blue",
        "666699", "Grayish blue",
        "808080", "Gray",
        "FF0000", "Red",
        "FF9900", "Amber",
        "99CC00", "Yellow green",
        "339966", "Sea green",
        "33CCCC", "Turquoise",
        "3366FF", "Royal blue",
        "800080", "Purple",
        "999999", "Medium gray",
        "FF00FF", "Magenta",
        "FFCC00", "Gold",
        "FFFF00", "Yellow",
        "00FF00", "Lime",
        "00FFFF", "Aqua",
        "00CCFF", "Sky blue",
        "993366", "Red violet",
        "FFFFFF", "White",
        "FF99CC", "Pink",
        "FFCC99", "Peach",
        "FFFF99", "Light yellow",
        "CCFFCC", "Pale green",
        "CCFFFF", "Pale cyan",
        "99CCFF", "Light sky blue",
        "CC99FF", "Plum"
      ])
    }else{
      setPlugins('textcolor')
      setEditimageCorsHosts([])
      setMenuBar('file edit view format tools')
      setToolBar('undo redo | bold italic underline strikethrough | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview  print')
      setColorMap([
        '822433', 'Red',
        '95a1ab', 'Grey',
        'fff', 'White',
        '000000','black'
      ])
    }
  }, []);

  useEffect(() => {
    if (formRef.current && formValues) {
      formRef.current = formValues
    }
  }, [formValues, content])

 const  handleChange = (editor) => {
    setContent(editor?.target?.getContent());
  }
  const handleUpload = async (file) => {
    
    const isLte5M = ((file.blob().size / 1024) / 1024) <= 5;
    if (!isLte5M) {
      toast.error("Maximum image size is 5MB!");
    } else {
      const { id } = selectedBlog;
      let data = { 'files[]': file.blob() };
      let isFormValid = true
      if (id) {
        data['_method'] = 'PATCH'; // No need required valued here
      } else {
        const { author, tags, header_image, title } = formRef.current
        if (!title || !author || !tags || !header_image) {
          isFormValid = false
        }
      }
      if (isFormValid) {
    
        const { author, tags, header_image, title } = formRef.current
        data = {
          ...data,
          title,
          author,
          tags,
          header_image: header_image[0]
        }
        return await new Promise((resolve, reject) => {
          const formData = convertFormData(data);
          updateBlog(id, formData)
            .then(res => {
              if (_get(res, 'data.data')) {
                setSelectedBlog(res.data.data)
              }
              if (_get(res, 'data.data.assets.length')) {
                let fileValue = res.data.data.assets[res.data.data.assets.length - 1]
                  ? res.data.data.assets[res.data.data.assets.length - 1].url
                  : '';
                if (fileValue) {
                  resolve(fileValue);
                } else {
                  reject('file is not uploaded');
                }
              }
            })
            .catch(err => {
              reject(err);
            })
        });
      } else {
        toast.error("Please fil the required fields - Author, Tags and Header Image");
      }
    }
  };


 


  return (
    <div className="p-3 blog-form">
      <form onSubmit={handleSubmit}>
        <ThemeHeader
          left={
            <Fragment>
              <h5 className="title">
                {!_isEmpty(selectedBlog) ? "Edit" : "Add New"} Blog Post
              </h5>
            </Fragment>
          }
          right={
            <Fragment>
              <Fragment>
                <div className="pr-2 pt-2 home-opening-hours">
                  <Field
                    name="is_public"
                    component={ToggleSwitch}
                    labelTrue="Public"
                    labelFalse="Private"
                  />
                </div>
                {formValues?.is_public ? (
                  <div className="pt-2">
                    <ToolTip name="globe" content="Globe" />
                  </div>
                ) : (
                  ""
                )}
                <div className="pl-2">
                  <ThemeButton
                    onClick={pristine ? closeModal : closeWithAlert}
                    className="lightGrey-button font-weight-bold"
                  >
                    Cancel
                  </ThemeButton>
                </div>
                <div className="pl-2">
                  <ThemeButton
                    disabled={(_isEmpty(selectedBlog)? (invalid || pristine || submitting) : (invalid || submitting))}
                    type="submit"
                    className="green-button"
                  >
                    {submitting ?
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      /> : ''}
                    Save
                  </ThemeButton>
                </div>
              </Fragment>
            </Fragment>
          }
        />
        <Row>
          <Col lg={6}>
            <Row>
              <Col lg={6}>
                <Field
                  required
                  component={TextInput}
                  label="Page Title"
                  name={"title"}
                  inputProps={{ maxLength: 45 }}
                />
              </Col>
              <Col lg={6}>
                <Field
                  required
                  component={TextInput}
                  label="Author"
                  name={"author"}
                />
              </Col>
              
              <Col lg={12} className="pt-2">
                <Field
                  required
                  name="tags"
                  label="Tag"
                  component={MultiSelect}
                  showDelete={false}
                  isMulti={false}
                  options={tag}
                />
              </Col>
              <Col lg={12} className="pt-2">
                <Field
                  required
                  component={TextInput}
                  label="Keywords"
                  name={"keywords"}
                />
              </Col>
              <Col lg={12} className="pt-2">
                <Field
                  required
                  component={TextArea}
                  label="Short Description"
                  name={"short_description"}
                  inputProps={{ maxLength: 120 }}
                />
              </Col>
            </Row>
          </Col>
          <Col lg={1}></Col>
          <Col lg={5}>
            <Row className='pl-5 mt-4' >
              <Col style={{ fontWeight: 600 }} lg={3}>Header Image :</Col>
              <Col>
                <Field
                  name="header_image"
                  roundedCircle={false}
                  image_url='https://v2-wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/assets/placeholders/Image-Small.jpg'
                  component={UserImageInputWithCropper}
                  aspectRatio={3 / 2}
                  dropZoneStyle={{ width: '240px', height: '240px' }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <div className="editorMarketing">
          <h6 className="marketingSubTitle pt-5">Content</h6>
          <Editor
            apiKey='u6g9d3acj6kag2nokc4st5sveo5ko8nyoji47i6pb4xnwnzp'
            onInit={(evt, editor) => formRef.current = editor}
            initialValue={selectedBlog.content ?? '' }
            init={{
              
              selector: 'textarea',
              plugins: plugins,
              editimage_cors_hosts: editimage_cors_hosts,
              menubar: menubar,
              toolbar: toolbar,
              color_map: colorMap,
              toolbar_sticky: true,
              toolbar_sticky_offset: isSmallScreen ? 102 : 108,
              autosave_ask_before_unload: true,
              autosave_interval: '30s',
              autosave_prefix: '{path}{query}-{id}-', 
              autosave_restore_when_empty: false,
              autosave_retention: '2m',
              image_advtab: true,
              images_upload_handler: handleUpload,
              templates: Templates,
              template_cdate_format: '[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]',
              template_mdate_format: '[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]',
              height: 800,
              image_caption: true,
              line_height_formats: '0.5 1 1.2 1.4 1.6 2',
              quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
              noneditable_class: 'mceNonEditable',
              toolbar_mode: 'sliding',
              contextmenu: 'link image table',
              skin: useDarkMode ? 'oxide-dark' : 'oxide',
              content_css: useDarkMode ? 'dark' : 'default',
              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }',
       
            }}
            onChange={handleChange}
            
          />
      
        </div>
      </form>{" "}
    </div>
  );
};
const mapState = (state, { selectedBlog }) => {
  const formValues = getFormValues("new-blog")(state);
  return {
    formValues,
    initialValues: !_isEmpty(selectedBlog)
      ? selectedBlog
      : {
        title: '',
        author: '',
        tags: [],
        keywords: '',
        short_description: '',
        header_image: null,
        content: '',
        is_public: 1
      }
  };
};

export default connect(mapState)(
  reduxForm({ form: "new-blog" })(AddNewBlogPost)
);
