import React, { useEffect, useState, Fragment } from "react";
import { Badge } from "@material-ui/core";
import RecentActorsIcon from "@material-ui/icons/RecentActors";

import { Spinner } from "react-bootstrap";
import { formToQueryString } from "../../../_helpers/_utils/utils";
import { toAbsoluteUrl } from "../../../../_metronic";
import { getAllBuyers } from "../../buyers/crud/buyersCrud";
import DefaultModal from "../../../partials/modal/DefaultModal";
import BuyerProfileModal from "../../buyers/modals/buyerProfileModal/BuyerProfileModal";

const SearchDropdownBuyer = ({searchValue}) => {
  const [data, setData] = useState([]);
  const [showBuyer, setShowBuyer] = useState(false);
  const [selectedId, setSelectedId] = useState(null);


  useEffect(() => {
    if (searchValue) {
      getAllBuyers(formToQueryString({ search_kw: searchValue }))
        .then((res) => {
          setData(res.data.data);
        })
        .catch((e) => {
          // setSuccess(true);
          // setLoading(false);
          // toast.success('Error while saving');
        });
    }

  }, [searchValue]); // here

  const onClickProfile = (e) => {
    setSelectedId(e);
    setShowBuyer(true);
  };

  return (
    <Fragment>
      {showBuyer ? (
        <DefaultModal
          size="xl"
          centered
          show={showBuyer}
          onHide={() => setShowBuyer(false)}
        >
          <BuyerProfileModal
            buyerId={selectedId}
            buyerList={data}
            closeModal={() => setShowBuyer(false)}
          />
        </DefaultModal>
      ) : (
        ""
      )}
      <div className="pb-4">
        {data && data.length ? (
          <div>
            <div className="kt-quick-search__category">
              {" "}
              Buyer List{" "}
              <Badge color="secondary" badgeContent={data.length}>
                <RecentActorsIcon />
              </Badge>
            </div>
            {data.map((item, index) => (
              <React.Fragment key={index}>
                <div className="kt-quick-search__item">
                  {/* TODO: icons and avatars don't load from array */}

                  <div className="kt-quick-search__item-img">
                    <img
                      src={
                        item?.primary_contact?.profile_picture
                          ? item?.primary_contact?.profile_picture
                          : toAbsoluteUrl("/media/users/default.jpg")
                      }
                      alt=""
                    />
                  </div>

                  <div className="kt-quick-search__item-wrapper">
                    <p
                      className="kt-quick-search__item-title"
                      onClick={() => onClickProfile(item.id)}
                    >
                      {" "}
                      {item.primary_contact?.first_name} {item.primary_contact?.last_name}
                    </p>

                    <div className="kt-quick-search__item-desc">
                      {item?.primary_contact?.phones?.[0]?.value}
                    </div>
                    {/* <div className="kt-quick-search__item-desc">
                      {item.desc}
                    </div> */}
                  </div>
                </div>
              </React.Fragment>
            ))}
          </div>
        ) : (
          <div>
            <div className="kt-quick-search__category">
              {" "}
              Buyer List{" "}
              <Badge color="secondary" badgeContent={data.length}>
                <RecentActorsIcon />
              </Badge>
            </div>
            <Spinner animation="grow" />
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default SearchDropdownBuyer;
