import React from "react";
import AddNewListingsContextProvider from "./AddNewListingsContextProvider";
import AddNewListingWrap from "./AddNewListingWrap";
import SingleListingContextProvider from "../../partials/singleListingList/SingleListingContextProvider";

const AddNewListings = (props) => (
  <SingleListingContextProvider {...props}>
    <AddNewListingsContextProvider>
      <AddNewListingWrap {...props} />
    </AddNewListingsContextProvider>
  </SingleListingContextProvider>
);
export default AddNewListings;
