import React from "react";

const ThemeButton = ({
  onClick,
  children,
  className,
  icon,
  small,
  disabled,
  bold,
  mobile,
  ...others
}) => {
  const otherClass = icon ? " darkGrey-icon-button " : " darkGrey-button ";
  const styleProps = className ? className : otherClass;
  const smallClass= small ? ' btn themeButtonSmall ' : ' btn themeButton '
  const classes = icon ? " icon-btn " : smallClass;
  const icon2 =  mobile ? "icon-btn2": ""
 
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      type="button"
      {...others}
      className={styleProps + classes + (bold ? " font-weight-bold " : "") + icon2}
    >
      {children}
    </button>
  );
};

ThemeButton.defaultProps = {
  small: false,
};

export default ThemeButton;
