import React, { useState, createContext, useEffect } from "react";

import { useDispatch } from "react-redux";

import { getBuyerById } from "../../crud/buyersCrud";

import * as buyersRedux from "../../_redux/buyersRedux";
export const BuyerProfileContext = createContext({});
export const { Consumer } = BuyerProfileContext;

const BuyerProfileContextProvider = (props) => {
  const { buyerList } = props;
  const dispatch = useDispatch();

  const [profile, setProfile] = useState(null);
  const [continuousMode, setContinuousMode] = useState(false);
  const [buyerLoading, setBuyerLoading] = useState(false);
  const [buyerId, setBuyerId] = useState(null);
  const [buyerIndex, setBuyerIndex] = useState(null);
  const [pageCount, setPageCount] = useState(0);

  const [conNote, setConNote] = useState(null);
  const [matchedListingSelected, setMatchedListingSelected] = useState([]);

  let buyerNotContacted = buyerList?.filter(
    (item) => item?.been_contacted !== 1
  );
  useEffect(() => {
    if (props.continuousMode) {
      setContinuousMode(props.continuousMode);
    }
  }, [props.continuousMode]);

  useEffect(() => {
    if (profile && buyerList) {
      let index = buyerList.findIndex((item) => item.id === profile.id);
      setBuyerIndex(index);
      setBuyerLoading(false);
    }
  }, [profile]);

  useEffect(() => {
    if (props.buyerId) {
      setBuyerId(props.buyerId);
    }
  }, [props.buyerId]);

  useEffect(() => {
    if (buyerId) {
      update();
    }
  }, [buyerId]);

  const update = () => {
    // console.log("called to update the context");
    getBuyerById(buyerId)
      .then(({ data: { data } }) => {
        if (data?.finance_status && typeof data?.finance_status === "number") {
          setProfile({ ...data, finance_status: { id: data?.finance_status } });
        } else {
          setProfile(data);
        }
      })
      .catch(() => {});
  };

  const changeDirection = (direction) => {
    setBuyerLoading(true);
    let index;
    let newId;
    if (continuousMode) {
      index = buyerNotContacted.findIndex((item) => item.id === profile.id);
    } else {
      index = buyerList.findIndex((item) => item.id === profile.id);
    }

    // yield delay(2000);
    let newIndex;
    if (direction === "left") {
      newIndex = index - 1;
    }
    if (direction === "right") {
      newIndex = index + 1;
    }

    if (continuousMode) {
      newId = buyerNotContacted[newIndex]?.id;
      setConNote(newId);
      setBuyerId(newId);
    } else {
      newId = buyerList[newIndex].id;
      setBuyerId(newId);
    }
  };

  const buyersTableUpdate = (data) => {
    dispatch(buyersRedux.actions.buyersLoading(data));
  };

  return (
    <BuyerProfileContext.Provider
      value={{
        updateContext: update,
        buyersTableUpdate,
        buyerIndex,
        profile,
        buyerList,
        changeDirection,
        buyerId,
        setBuyerId,
        continuousMode,
        setContinuousMode,
        setBuyerLoading,
        buyerLoading,
        filters: props.filters,
        buyerNotContacted,
        conNote,
        matchedListingSelected,
        setMatchedListingSelected,
        pageCount,
        setPageCount,
      }}
    >
      {props.children}
    </BuyerProfileContext.Provider>
  );
};

export default BuyerProfileContextProvider;
