import React, { useEffect, useState, Fragment, useContext } from "react";
import { connect, useDispatch } from "react-redux";
import { Col, Modal, Row } from "react-bootstrap";
import { getTenantById } from "../../crud/tenantsCrud";
import * as modals from "../../../_modal/_redux/modalRedux";
import { makeStyles, AppBar, Tab, Tabs, Divider } from "@material-ui/core";
import TenantProfileSideBar from "./tenantProfileSideBar/TenantProfileSideBar";
import TenantProfileHeader from "./tenantProfileHeader/TenantProfileHeader";

import TenantMail from "./mail/TenantMail";
import TenantTimeline from "./timeline/TenantTimeline";
import TenantDetails from "./details/TenantDetails";
import TenantMatched from "./matched/TenantMatched";
import TenantNotes from "./notes/TenantNotes";
import TenantContactsWrap from "./contacts/TenantContactsWrap";
import { TenantProfileContext } from "./TenantProfileContextProvider";
import LoadingSkeleton from "../../../../partials/display/skeleton/LoadingSkeleton";
import _isEmpty from 'lodash/isEmpty';
import { LinearProgress } from "@material-ui/core";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Fragment>{children}</Fragment>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: "none",
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
}));

const TenantProfileModalBody = (props) => {
  // const {
  //   contactProfileId,
  //   tenants,
  //   tenantSelectedChangeId,
  //   selectedTab,
  //   closeModal,
  // } = props;

  const { closeModal } = props;
  const { tenants, selectedTenantsId, profile, updateContext, tenantLoading,filters } = useContext(
    TenantProfileContext
  );
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const dispatch = useDispatch();
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  // const [profile, setProfile] = useState({});
  // const profile = useSelector(({ contacts: {profile} }) => profile, shallowEqual);

  // useEffect(() => {
  //   if (tenants.viewTenantIndex) {
  //     // setProfile(getContactById(state.contactProfileId)[0]);
  //     console.log(tenants.viewTenant.id);
  //     getTenantById(tenants.viewTenant.id)
  //       .then(({ data }) => {
  //         setProfile(data);

  //         console.log(data);
  //       })
  //       .catch(() => {});
  //     // dispatch(contactsRedux.actions.contactFetchAsync(props.id)); // dispatch action to fetch contact types
  //   }
  //   // dispatch(tab.actions.tabsAdd(newTabObject));
  // }, [tenants.viewTenant.id]);

  return (
    <>
      {tenantLoading ? <LinearProgress /> : <div style={{ height: '4px' }}></div>}
      {!_isEmpty(profile) ? <Fragment>
      <Row className="pb-2">
        <Col lg={{ span: 9, offset: 3 }}>
            <TenantProfileHeader selectedHeaderTab={value} closeModal={closeModal} selectedTenantsId={selectedTenantsId}/>
        </Col>
      </Row>

      <Row>
        <Col lg={4} xl={3} className="modal-sidebar">
          <TenantProfileSideBar
            closeModal={closeModal}
            profile={profile}
            updateContext={updateContext}
          />
          <Divider orientation="vertical" flexItem />
        </Col>

        <Col md={7} lg={8} xl={9} className="contact-profileBody">
          <div className="contact-profileBody">
            <div className={classes.root}>
              <AppBar position="static" className="app-bar">
                <Tabs
                  className="tabs"
                  variant="scrollable"
                  scrollButtons="auto"
                  value={value}
                  onChange={handleChange}
                  TabIndicatorProps={{ style: { background: "transparent" } }}
                >
                  <Tab label="Notes" {...a11yProps(0)} />
                  <Tab label="Contacts" {...a11yProps(1)} />
                  <Tab label="Details" {...a11yProps(2)} />
                  <Tab label="Matched" {...a11yProps(3)} />
                  <Tab label="Timeline" {...a11yProps(4)} />
                  <Tab label="Mail" {...a11yProps(5)} />
                </Tabs>
              </AppBar>
              <TabPanel className="single-tab-body" value={value} index={0}>
                <TenantNotes
                  profileId={profile?.id}
                  contactId={profile?.contact?.id}
                  name="test"
                />
              </TabPanel>
              <TabPanel className="single-tab-body" value={value} index={1}>
                <TenantContactsWrap
                  profile={profile}
                  tenants={tenants}
                  profileId={selectedTenantsId}
                  filters={filters}
                />
              </TabPanel>
              <TabPanel className="single-tab-body" value={value} index={2}>
                <TenantDetails
                  profile={profile}
                  profileId={selectedTenantsId}
                />
              </TabPanel>
              <TabPanel className="single-tab-body" value={value} index={3}>
                <TenantMatched
                  profile={profile}
                  profileId={selectedTenantsId}
                />
              </TabPanel>
              <TabPanel className="single-tab-body" value={value} index={4}>
                <TenantTimeline
                  contactId={profile?.contact?.id}
                  profileId={selectedTenantsId}
                />
              </TabPanel>
              <TabPanel className="single-tab-body" value={value} index={5}>
                <TenantMail
                  contactId={profile?.contact?.id}
                  profileId={selectedTenantsId}
                  profile={profile}
                />
              </TabPanel>
            </div>
          </div>
        </Col>
      </Row>
      </Fragment> : <LoadingSkeleton />}
    </>
  );
};

export default TenantProfileModalBody;
