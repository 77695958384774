import React from "react";
import { Col, Row } from "react-bootstrap";
import { SortableContainer, SortableElement } from "react-sortable-hoc";

import { toAbsoluteUrl } from "../../../../../_metronic";

const RenderMembers = ({ members, fields, meta: { error, submitFailed } }) => {
  const onSortEnd = (props) => {
    const { oldIndex, newIndex } = props
    if (oldIndex !== newIndex) {
      const removetItem = fields.get(oldIndex)
      fields.remove(oldIndex);
      fields.splice(newIndex, 0, removetItem);
    }
  }

  const SortableItem = SortableElement(({ _index }) => {
    return (
      <div>
        <div className="d-flex justify-content-center">
          <img
            src={
              fields.get(_index)?.contact?.profile_picture
                ? fields.get(_index)?.contact?.profile_picture
                : toAbsoluteUrl("/media/users/default.jpg")
            }
            className="profile-image rounded-circle"
            alt=""
            style={{ minHeight: 150 }}
          />
        </div>

        <div className="mt-auto d-flex justify-content-end">
          {/* <ThemeButton
              icon
              onClick={() => fields.remove(index)}
            >
              <i
                className="fas fa-minus-circle"
                fontSize="small"
              ></i>
            </ThemeButton> */}
          <div class="numberCircle">{_index + 1}</div>
        </div>

        <div class="font-weight-bold d-flex justify-content-center pt-3">
          {fields.get(_index).contact.first_name}{" "}
          {fields.get(_index).contact.last_name}
        </div>
        <div class=" d-flex justify-content-center">
          {fields?.get(_index)?.position || ''}
        </div>
      </div>
    )
  })

  const SortableGallery = SortableContainer(({ items }) => {
    return (
      <Row className='mt-4'>
        {items.map((member, index) => (
          <Col lg={2}>
            <SortableItem key={member} index={index} _index={index} />
          </Col>
        ))}
      </Row>
    )
  });

  return (
    <section>
      <SortableGallery
        items={fields}
        onSortEnd={onSortEnd}
        axis={"xy"}
      />
    </section>
  );
};

export default RenderMembers;



// const makeOnDragEndFunction = (fields) => (result) => {
//   // dropped outside the list
//   if (!result.destination) {
//     return;
//   }
//   if (result.source.index != result.destination.index) {
//     fields.swap(result.source.index, result.destination.index);
//     updateMembersPosition(result, fields)
//   }
// };
// const [memberId, setMemberId] = useState("");
// const [showAddUser, setShowAddUser] = useState(false);
// const handleMultiChange = (e) => {
//   console.log(e);
//   console.log(fields.getAll());
//   console.log(members);

//   if (fields?.getAll()?.filter((obj) => obj.id == e).length) {
//   } else {
//     fields.push(members.find((obj) => obj.id == e));
//   }

//   setMemberId(e);
// };

// const handleOnSubmitMember = (values) => {
//   let formatedValue = postProcessedMemberInfo(values);

//   const formData = convertFormData(formatedValue);

//   createNewMember(formData)
// 	.then(({ data: { data } }) => {
// 	  toast.success("Member Created Successfully");
// 	  fields.push(data);
// 	  setShowAddUser(false);
// 	})
// 	.catch((e) => {
// 	  toast.error("Error while Updating Member");
// 	});
// };



// {/* <Col md={5} lg={3}>
//             <div>
//               <div className="d-flex justify-content-center  ">
//                 <img
//                   onClick={() => setShowAddUser(true)}
//                   src={toAbsoluteUrl("/media/users/default.jpg")}
//                   className="profile-image rounded-circle mb-3"
//                   alt=""
//                 />
//               </div>
//             </div>
//           </Col> */}
//           <DragDropContext onDragEnd={makeOnDragEndFunction(fields)}>
//           <Droppable droppableId="droppable_members" direction='horizontal'>
//             {(provided, snapshot) => (
//               <Row
//                 ref={provided.innerRef}
//               // style={getListStyle(snapshot.isDraggingOver)}
//               >
//                 {fields.map((member, index) => (
//                   <Col lg={2}>
//                     <Draggable
//                       // style={(_isDragging, draggableStyle) => ({
//                       //   ...draggableStyle,
//                       //   position: "static ",
//                       // })}
//                       key={member}
//                       draggableId={member}
//                       index={index}
//                     >
//                       {(provided, snapshot) => (
//                         <div
//                           ref={provided.innerRef}
//                           {...provided.draggableProps}
//                           {...provided.dragHandleProps}
//                           className="draggingAvatar"
//                           fluid
//                         >
//                           <div className="d-flex justify-content-center">
//                             <img
//                               src={
//                                 fields.get(index)?.contact?.profile_picture
//                                   ? fields.get(index)?.contact?.profile_picture
//                                   : toAbsoluteUrl("/media/users/default.jpg")
//                               }
//                               className="profile-image rounded-circle"
//                               alt=""
//                               style={{ minHeight: 150 }}
//                             />
//                           </div>

//                           <div className="mt-auto d-flex justify-content-end">
//                             {/* <ThemeButton
//                               icon
//                               onClick={() => fields.remove(index)}
//                             >
//                               <i
//                                 className="fas fa-minus-circle"
//                                 fontSize="small"
//                               ></i>
//                             </ThemeButton> */}
//                             <div class="numberCircle">{index + 1}</div>
//                           </div>

//                           <div class="font-weight-bold d-flex justify-content-center pt-3">
//                             {fields.get(index).contact.first_name}{" "}
//                             {fields.get(index).contact.last_name}
//                           </div>
//                           <div class=" d-flex justify-content-center">
//                             {fields?.get(index)?.position || ''}
//                           </div>
//                         </div>
//                       )}
//                     </Draggable>
//                   </Col>
//                 ))}
//               </Row>
//             )}
//           </Droppable>
//         </DragDropContext>
