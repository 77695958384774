/* eslint-disable no-restricted-imports */
import React, { Fragment, useContext, useState,useEffect } from "react";
import { Field } from "redux-form";
import { useSelector } from "react-redux";
import AccordionContext from "react-bootstrap/AccordionContext";
import { useAccordionToggle, Accordion, Card, Col, Row } from "react-bootstrap";
import { IconButton } from "@material-ui/core";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

import ContactFormPersonalDetails from "../partials/ContactFormPersonalDetails";
import ContactFormContactInfo from "../partials/ContactFormContactInfo";
import ContactFormFamilyDetails from "../partials/ContactFormFamilyDetails";
import ContactFormSocialInfo from "../partials/ContactFormSocialInfo";
// import { UserImageInput } from "../UserImageInput";
import SortableList from "../sortableForm/SortableList";
import Handle from "../../component/handle/Handle";
import FormTags from "../partials/FormTags";
import AccordionContactView from "../../../modules/buyers/modals/buyerProfileModal/contacts/AccordionContactView";
import AccessControl from "../../../_ accessControl/AccessControl";
import { UserImageInputWithCropper } from "../UserImageInputWithCropper";
import {
  getContactById,
} from "../../../modules/contacts/crud/contactsCrud";
import {
  getProcessedContactInfo,
} from "../../../_helpers/_utils/utils";
import Swal from "sweetalert2";


function CustomToggle({
  children,
  fields,
  index,
  eventKey,
  allowedPermission,
  isAtleastOneContact,
  setToogle
}) {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle((eventKey), () =>{
    console.log("totally custom!",eventKey)
    setToogle(eventKey)
  });

  const isCurrentEventKey = currentEventKey === eventKey;
  const deleteWarningMessage = () => {
    Swal.fire({
      title: "Warning",
      text: "Are you sure want to delete the contact?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#A7A9AC",
      cancelButtonColor: "#2BB673",
      confirmButtonText: "Cancel",
      cancelButtonText: "Yes",
    }).then((result) => {
      if (result.isDismissed) {
        fields.remove(index)
        console.log('swaaaslllldata');
      }
    });
  };



  
  return (
    <Fragment>
      <h5 className="title">{children}</h5>
      <div className="d-flex">
        <IconButton
          onClick={(e) => {
            decoratedOnClick();
          }}
          className="pr-3"
        >
          {isCurrentEventKey ? (
            <KeyboardArrowDownIcon fontSize="inherit" />
          ) : (
            <KeyboardArrowLeftIcon fontSize="inherit" />
          )}
        </IconButton>
        {
          (isAtleastOneContact && fields.length === 1) ?
            null :
            <AccessControl
              allowedPermissions={[allowedPermission]}
              renderNoAccess={() => ""}
            >
              <IconButton onClick={deleteWarningMessage} size="small">
                <i className="fas fa-minus-circle"></i>
              </IconButton>
            </AccessControl>
        }

        <Handle />
      </div>
    </Fragment>
  );
}

const RenderContact = (props) => {
  const {
    fields,
    meta: { error, submitFailed },
    data,
    change,
    profileId,
    viewData,
    defaultActiveKey,
    allowedPermission,
    isAtleastOneContact = false,
    isSubmit,
    setFrom=()=>{},
    autoProspectNote,
    // setLoadProfile,
    ...rest
  } = props;

  const [loadProfile, setLoadProfile] = useState(null);
 
 console.log('==========autoProspectNote==========================');
 console.log(autoProspectNote);
 console.log('==========autoProspectNote==========================');
  const selectedOption = (value,index) => {
  
 
    if (value) {
      Swal.fire({
        title: "Existing contact found!!",
        text:
          "Existing contact found ( " +
          value.first_name+" "+ value.last_name +' , '+value?.phones?.[0]?.value+" , "+value?.emails?.[0]?.value+
          "). Would you like to open or update that contact.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#A7A9AC",
        cancelButtonColor: "#2BB673",
        confirmButtonText: "Cancel",
        cancelButtonText: "Yes",
      }).then((result) => {
        if (result.isDismissed) {
          // setProfileID(value.id);
          getContactById(value.id)
            .then(({ data: { data } }) => {
          
              fields.remove(index)

              let initialValue = getProcessedContactInfo(data);

              setLoadProfile(initialValue);
              fields.insert(index , initialValue)
              //
              console.log(data);
              console.log(initialValue);
            })
            .catch(() => {});
          console.log(result);
        }
      });
    }
  };

  


  const hasPermission = useSelector(
    (state) => state.auth.user.permissions
  ).some((permission) => ["contacts-edit"]?.includes(permission?.name));
   const [open,setOpen] = useState("")
  const [defaultkeys,setDefaultKeys]=useState([0]);

  const getCurrentArrayIndex= (data)=>{
        let getAllFields = fields.getAll()
        let  setDefault= [...defaultkeys,parseInt(data)]
        setOpen({id:Date.now(),index:data});
        setDefaultKeys(setDefault)
  }

  useEffect(()=>{
    if(rest.formFields?.length>0){
      
      let arrayFields =rest.formFields.map((data,index)=>({ first_name:data.first_name,index }))
      let findIndex="";
      arrayFields.forEach(data=>{
        if(data.first_name===""){
          findIndex=data.index;
          return true
        }
      })
      if(findIndex!==""){
        getCurrentArrayIndex(findIndex);
      }
    }
  },[isSubmit])

  useEffect(()=>{
    if(open!==""){
        document.getElementById(`contacts[${open.index}]`).scrollIntoView()
    }
  },[open.id])

 const setToogle=(data)=>{
    let setDefault=[]
    if(defaultkeys.includes(data)){
       setDefault= defaultkeys.filter(key=>data!==key)
    }else{
       setDefault= [...defaultkeys,data]
      }
      setDefaultKeys(setDefault)
      setOpen("");
   }

  return (
    <Fragment>
      {submitFailed && error && <span>{error}</span>}
      {fields ? (
        <SortableList fields={fields}>
          {fields.map((member, index) => (
            <Accordion key={index} defaultActiveKey={0}>
              <Card>
                <Card.Header className="d-flex justify-content-between pl-3 pr-3">
                  <CustomToggle
                    fields={fields}
                    index={index}
                    eventKey={index}
                    allowedPermission={allowedPermission}
                    isAtleastOneContact={isAtleastOneContact}
                    setToogle={setToogle}
                  >
                    {fields.get(index).first_name} {fields.get(index).last_name}
                  </CustomToggle>
                </Card.Header>
                <Accordion.Collapse eventKey={index} in={defaultkeys?.includes(index)?true:false}>
                  <Card.Body>
                    <AccessControl
                      allowedPermissions={["contacts-edit"]}
                      renderNoAccess={() => (
                        <AccordionContactView
                          profile={viewData?.find(
                            (singleData) =>
                              singleData.id == fields.get(index).id
                          )}
                        />
                      )}
                    >
                      {" "}
                      <>
                        <ContactFormPersonalDetails
                          profileId={profileId}
                          fieldArray={member}
                          change={change}
                          selectedOption={selectedOption}
                          index={index}
                          auto={true}
                          setFrom={setFrom}
                        />
                        <Row>
                          <Col md={6}>
                            <Row>
                              <Col md={4}>
                                {" "}
                                <Field
                                  name={`${member}.profile_picture`}
                                  fieldArray={member}
                                  component={UserImageInputWithCropper}
                                  className='profile'
                                  dropZoneStyle={{ borderRadius: '50%' }}
                                />
                              </Col>
                              <Col md={8}>
                                <ContactFormSocialInfo fieldArray={member} />
                              </Col>
                            </Row>
                          </Col>
                          <Col md={6}>
                            <FormTags fieldArray={member} labelTop={false} />
                          </Col>
                        </Row>
                        <hr />
                        <ContactFormContactInfo fieldArray={member} />
                        <hr />
                        <ContactFormFamilyDetails
                          number_of_children={
                            fields.get(index).number_of_children
                          }
                          fieldArray={member}
                        />
                      </>{" "}
                    </AccessControl>

                    <hr />
                    {/* <Row>
                      <Col md={6}>
                        <ContactConnections fieldArray={member} />
                      </Col>
                      <Col md={6}>
                        <FieldArray
                          name={`${member}.linked_contacts`}
                          fullButton
                          fieldArray={member}
                          component={FormLinkedContacts}
                        />
                      </Col>
                    </Row> */}
                  </Card.Body>
                  {/* <h1>asdasd</h1> */}
                </Accordion.Collapse>
              </Card>
            </Accordion>
          ))}
        </SortableList>
      ) : (
        ""
      )}
    </Fragment>
  );
};

export default RenderContact;
