import React, { useEffect, useState } from 'react'
import { TextField, } from '@material-ui/core'
import moment from 'moment';
import _includes from 'lodash/includes';

const OpenHomesSettings = ({ selectedNode,listingData,showEndtime }) => {
    const [openDate, setOpenDate] = useState('')
    const [startTime, setStartTime] = useState('')
    const [endTime, setEndTime] = useState('')

    // const getTargetElement = targetClassName => {
    //     return selectedNode.getElementsByClassName(targetClassName)
    // }

;

    useEffect(() => {
        setOpenDate(moment((listingData?.starts)|| (listingData)).format('YYYY-MM-DD').toString());
        setStartTime(moment((listingData?.starts)|| (listingData)).format("HH:mm"));
        setEndTime(moment(listingData?.ends ).format("HH:mm"));
    }, [])

    useEffect(() => {
        setOpenDate(moment((listingData?.starts)|| (listingData)).format('YYYY-MM-DD').toString());
        setStartTime(moment((listingData?.starts)|| (listingData)).format("HH:mm"));
        setEndTime(moment(listingData?.ends).format("HH:mm"));
    }, [selectedNode])
    // useEffect(() => {
    //     setOpenDate(moment((startTime)).format('YYYY-MM-DD').toString());
    //     setStartTime(moment((startTime)).format("HH:mm"));
    //     setEndTime(moment(endTime).format("HH:mm"));
    // }, [selectedNode])
   

    // console.log('===========OpenHomesSettings=========================');
    // console.log(selectedNode);
    // console.log(listingData);
    // console.log(startTime);
    // console.log('============OpenHomesSettings========================')

    const handleDateChange = (e) => {
        setOpenDate(e.target.value)
        if(listingData){

            let day = moment(e.target.value).format(
                "dddd")
                let  date = moment(e.target.value).format('D MMMM YYYY')

                if(_includes(selectedNode.className, 'openHomeDate')){
                    selectedNode.innerHTML = `${day}, ${date}, ${moment(startTime, 'hh:mm A').format('h:mm A')}  - ${moment(endTime, 'hh:mm A').format('h:mm A')}`;
                } else if(_includes(selectedNode.className, 'openHomesBlock')){
                        selectedNode.childNodes[1].innerHTML = `${day}, ${date}, ${moment(startTime, 'hh:mm A').format('h:mm A')} - ${moment(endTime, 'hh:mm A').format('h:mm A')} `;
                    } else if(_includes(selectedNode.className, 'auctionDate')){
                        selectedNode.innerHTML = `${day}, ${date}, ${moment(startTime, 'hh:mm A').format('h:mm A')}`;
                    }else if(_includes(selectedNode.className, 'openHomesAuctionBlock')){
                        selectedNode.childNodes[1].innerHTML = `${day}, ${date}, ${moment(startTime, 'hh:mm A').format('h:mm A')} `;
                    }

                }
                
        }
     
    
    const handleStartTimeChange = (e) => {
        setStartTime(e.target.value)
        if(listingData){
            let day = moment(openDate).format(
                "dddd")
                let  date = moment(openDate).format('D MMMM YYYY')
                if(_includes(selectedNode.className, 'openHomeDate')){
                    selectedNode.innerHTML = `${day}, ${date}, ${moment(e.target.value, 'hh:mm A').format('h:mm A')}  - ${moment(endTime, 'hh:mm A').format('h:mm A')}`
                } else if(_includes(selectedNode.className, 'openHomesBlock')){
                        selectedNode.childNodes[1].innerHTML = `${day}, ${date}, ${moment(e.target.value, 'hh:mm A').format('h:mm A')}  - ${moment(endTime, 'hh:mm A').format('h:mm A')}`;
                    } else if(_includes(selectedNode.className, 'auctionDate')){
                        selectedNode.innerHTML = `${day}, ${date}, ${moment(e.target.value, 'hh:mm A').format('h:mm A')} `
                    }else if (_includes(selectedNode.className, 'openHomesAuctionBlock')) {
                        selectedNode.childNodes[1].innerHTML = `${day}, ${date}, ${moment(e.target.value, 'hh:mm A').format('h:mm A')}`;
                    }


                } 
    
    }


    const handleEndTimeChange = (e) => {
        setEndTime(e.target.value)
        if(listingData){
            let day = moment(openDate).format(
                "dddd")
                let  date = moment(openDate).format('D MMMM YYYY')
                if(_includes(selectedNode.className, 'openHomeDate')){
                    selectedNode.innerHTML = `${day}, ${date}, ${moment(startTime, 'hh:mm A').format('h:mm A')}  - ${moment(e.target.value, 'hh:mm A').format('h:mm A')}`;
                }else{
                    selectedNode.childNodes[1].innerHTML = `${day}, ${date}, ${moment(startTime, 'hh:mm A').format('h:mm A')}  - ${moment(e.target.value, 'hh:mm A').format('h:mm A')}`;
                }
          
        }
    }
  
    
   

    return (
        <div>
            <TextField
                className='my-3'
                type="date"
                label={'Date'}
                value={openDate}
                onChange={(e) => handleDateChange(e)}
                fullWidth
                InputLabelProps={{
                    shrink: true
                }}
            />

      
            <TextField
                className='my-3'
                type="time"
                label={'Start Time'}
                value={startTime}
                onChange={handleStartTimeChange}
                fullWidth
                InputLabelProps={{
                    shrink: true
                }}
            />
          { showEndtime &&  <TextField
                className='my-3'
                type="time"
                label={'End Time'}
                value={endTime}
                onChange={handleEndTimeChange}
                fullWidth
                InputLabelProps={{
                    shrink: true
                }}
            />}

        </div>
    )
}
export default OpenHomesSettings