import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { reset } from "redux-form";
import {
  deleteToDo,
  getAllToDo,
  postToDo,
  updateToDo,
} from "../../../modules/header/_crud/headerCurd";
import {
  convertedDateFormate,
  convertFormData,
} from "../../../_helpers/_utils/utils";
import ScrapPaper from "./ScrapPaper";

export const ScrapPaperWrap = ({ isEditable }) => {
  const [items, setItems] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    update();
  }, []);

  const update = (e) => {
    getAllToDo(e)
      .then(({ data: { data } }) => {
        setItems(data);
      })
      .catch((e) => {});
  };

  const onFormSubmit = useCallback((values) => {
    let processedData = {};
    let data = [];

    data = values.toDolist;

    if (values.description) {
      data.unshift({ description: values.description });
    }

    processedData.to_do_items = data.map(({ display_order, ...other }, index) => ({
      display_order: index + 1,
      ...other,
    }));
    console.log(processedData);
    const formData = convertFormData(processedData);

    postToDo(formData)
      .then(({ data: { data } }) => {
        update();
        dispatch(reset('scrapPaperForm'));
      })
      .catch((e) => {});

  }, []);

  const handleUpdate = (id) => {
    let completed_at = items.filter((obj) => obj.id == id)[0].completed_at
      ? null
      : convertedDateFormate(new Date(), "YYYY-MM-DD");
    let formattedFormData = {};
    formattedFormData.completed_at = completed_at;

    const formData = convertFormData(formattedFormData);
    formData.append("_method", "PATCH");
    updateToDo(id, formData)
      .then(({ data: { data } }) => {
        update();
      })
      .catch((e) => {});
    console.log(convertedDateFormate(new Date(), "YYYY-MM-DD"));
  };

  const handleDelete = (id) => {
    deleteToDo(id)
      .then(({ data: { data } }) => {
        update();
      })
      .catch((e) => {});
  };
  return (
    <Fragment>
      <ScrapPaper
        isEditable={isEditable}
        onSubmit={onFormSubmit}
        handleDelete={handleDelete}
        handleUpdate={handleUpdate}
        data={items}
      />
    </Fragment>
  );
};
