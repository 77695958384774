import React, { Fragment } from "react";
import { Form, Col } from "react-bootstrap";
import { Avatar, Grid, Input, InputAdornment, makeStyles, TextField } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

const GroupIconInput = ({
  input,
  label,
  name,
  width,
  type,
  icon,
  placeholder,
  meta: { touched, error }, ...custom
}) => {

  return (
    
    <Fragment>
      <div className="col-xl-12 w-100 pb-2">

      {/* <Grid container spacing={1} alignItems="flex-end" className="justify-content-center">
          <Grid xs={2} className="d-flex justify-content-end" item>
          {icon}
          </Grid>
          <Grid xs={7} className="d-flex justify-content-start pl-3" item>
            <TextField fullWidth {...input}
            {...custom} placeholder={placeholder} />
          </Grid>
        </Grid> */}
        <Input
        placeholder={placeholder}
        fullWidth
          {...input}
          {...custom}
          startAdornment={
            <InputAdornment position="start">
               {icon}
            </InputAdornment>
          }
        />
      </div>
    </Fragment>
  );
};

export default GroupIconInput;
