import React from "react";
import PriceFinder from "./PriceFinder";

const ProspectViewPriceModal = ({
  profile,
  viewLoading,
  propertyDetails,
  closeModal,
}) => (
  <PriceFinder
    profile={profile}
    viewLoading={viewLoading}
    propertyDetails={propertyDetails}
    closeModal={closeModal}
  />
);

export default ProspectViewPriceModal;
