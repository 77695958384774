import React, { Fragment } from "react";
import {
    SortableContainer,
    SortableElement,
} from "react-sortable-hoc";



const SortableItem = SortableElement(({ item }) => {
    return item ? <tr className="sortable-element" >
        {item}
    </tr> : <tr>a</tr>

});



const SortableTableContainer = SortableContainer(
    ({ items }) => {
        return (
            <Fragment>
                <tbody>
                    {items.map((item, index) => {
                        return <SortableItem index={index} key={item.key} item={item} />
                    }
                    )}
                </tbody>
            </Fragment>
        );
    }
);

export default SortableTableContainer;
