import React, { useEffect, Fragment } from "react";
import { useSpeechRecognition } from "./useSpeechRecognition";
import { IconButton } from "@material-ui/core";
import "./dictaphone.scss";
import _isEmpty from "lodash/isEmpty";
const _ = require("lodash");

function isInt(str) {
  return !isNaN(str) && Number.isInteger(parseFloat(str));
}

const Dictaphone = ({ handleVoiceToSpeech }) => {
  const speech = useSpeechRecognition();

  const checkPhoneNumber = (value) => {
    if (_isEmpty(value)) {
      return false;
    }

    let string = value.replace(/\s/g, "");

    if (isInt(string) && string.length === 10) {
      return string;
    } else {
      return false;
    }
  };
  useEffect(() => {
    // Using an IIFE
    (async function anyNameFunction() {
      // console.log(speech?.transcript);

      if (speech?.transcript) {
        let number = checkPhoneNumber(speech?.transcript);
        if (!number) {
          await handleVoiceToSpeech(speech.transcript ? speech.transcript : "");
        } else {
          await handleVoiceToSpeech(number);
        }
      }
    })();
  }, [speech.listening]);
  // console.log(speech.listening);
  return (
    <Fragment>
      {speech.browserSupportsSpeechRecognition ? (
        <IconButton
          onClick={speech.startListening}
          className={"dictaphone "}
          disabled={speech.listening ? "true" : false}
          aria-label="delete"
        >
          {speech.listening ? (
            <i className="fas fa-microphone green-color"></i>
          ) : (
            <i className="fas fa-microphone"></i>
          )}
        </IconButton>
      ) : (
        ""
      )}
    </Fragment>
  );
};

export default Dictaphone;
