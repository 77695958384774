import React, { useEffect } from 'react';
import { Select, Input, Form, Row, Col } from 'antd';
import _get from 'lodash/get';
import _map from 'lodash/map';

const { Option } = Select;

const InputField = ({
  name,
  label,
  placeholder,
  style = {},
  className,
  rules = [],
  ...rest
}) => {
  return (
    <div className="field-wrapper">
      <div className="field-label">{label}</div>
      <Form.Item name={name} rules={rules}>
        <Input
          autoComplete='off'
          style={style}
          className={className}
          placeholder={placeholder}
          {...rest}
        />
      </Form.Item>
    </div>
  );
};

const SelectField = ({
  name,
  label,
  rules = [],
  placeholder,
  className = '',
  onChange,
  dataSource = [],
  size = 'small',
  ...rest
}) => {
  return (
    <div className="field-wrapper">
      <div className="field-label">{label}</div>
      <Form.Item name={name} className={className} rules={rules}>
        <Select
          size={size}
          style={{ width: "100%" }}
          placeholder={placeholder}
          className="select_content"
          onChange={onChange}
          {...rest}
        >
          {_map(dataSource, (data, key) => {
            return (
              <Option value={data.value} key={key} disabled={data && data.disabled}>
                <span dangerouslySetInnerHTML={{ __html: data.label }}></span>
              </Option>
            );
          })}
        </Select>
      </Form.Item>
    </div>
  );
};

const dataSource = [
  { label: `m<sup>2</sup>`, value: 'm2' },
  { label: 'cent', value: 'cent' },
  { label: 'acre', value: 'acre' }
]

function FeaturesSettings({ selectedNode }) {
  const [form] = Form.useForm()

  useEffect(() => {
    console.log('selectedNode', selectedNode)
    const getVisibility = className => {
      let ele = selectedNode.getElementsByClassName(className)
      if (ele && ele[0]) {
        return ele[0].innerHTML
      } else {
        return 0
      }
    }
    let bed = getVisibility('bed')
    let bath = getVisibility('bath')
    let car = getVisibility('car')
    let area = (getVisibility('land') && getVisibility('land').split(' ')) || []

    form.setFieldsValue({
      bed: isNaN(bed) ? 0 : bed,
      bath: isNaN(bath) ? 0 : bath,
      car: isNaN(car) ? 0 : car,
      area: isNaN(area[0]) ? 0 : area[0],
      type: area[1] ? area[1] : 'm2'
    })
  }, [form, selectedNode])

  const onFormValuesChange = (changedVal, formValues) => {
    let key = Object.keys(changedVal)[0]
    if (key === 'area' || key === 'type') {
      let selectedElLand = selectedNode.getElementsByClassName('land')[0];
      if (formValues.area > 0 && _get(selectedElLand, 'parentNode.style.display') === 'none') {
        selectedElLand.parentNode.style.display = 'flex';
      } else if (formValues.area <= 0 && _get(selectedElLand, 'parentNode.style.display') === 'flex') {
        selectedElLand.parentNode.style.display = 'none';
      }
      selectedElLand.innerHTML = `${formValues.area} ${formValues.type}`
    } else {
      let selectedEl = selectedNode.getElementsByClassName(key)[0];
      if (changedVal[key] > 0 && _get(selectedEl, 'parentNode.style.display') === 'none') {
        selectedEl.parentNode.style.display = 'flex';
      } else if (changedVal[key] <= 0 && _get(selectedEl, 'parentNode.style.display') === 'flex') {
        selectedEl.parentNode.style.display = 'none';
      }
      selectedEl.innerHTML = changedVal[key]
    }
  }

  const onKeyDown = e => {
    if (e.keyCode === 69 || e.keyCode === 187 || e.keyCode === 189) {
      e.preventDefault();
    }
  }

  return (
    <div>
      <Form form={form} onValuesChange={onFormValuesChange}>
        <Row>
          <Col span={2}> <i className="fas fa-bed" style={{ color: "black", width: "18px", height: "18px" }}></i></Col>
          <Col span={5} offset={1}><InputField onKeyDown={onKeyDown} type='number' min={0} name='bed' /></Col>
        </Row>
        <Row>
          <Col span={2}><i className="fas fa-bath" style={{ color: "black", width: "18px", height: "18px", marginTop: '10px' }}></i></Col>
          <Col span={5} offset={1}><InputField onKeyDown={onKeyDown} type='number' min={0} name='bath' /></Col>
        </Row>
        <Row>
          <Col span={2}>  <i className="fas fa-car" style={{ color: "black", width: "18px", height: "18px", marginTop: '10px' }}></i></Col>
          <Col span={5} offset={1}><InputField onKeyDown={onKeyDown} type='number' min={0} name='car' /></Col>
        </Row>
        {/* {
          area ?
            <Row>
              <Col span={2}><i className="fas fa-map-marked-alt" style={{ color: "black", width: "18px", height: "18px", marginTop: '10px' }}></i></Col>
              <Col span={5} offset={1}><InputField onKeyDown={onKeyDown} type='number' min={0} name='area' /></Col>
              <Col span={6} offset={1}>
                <SelectField style={{ marginTop: '5px' }} name='type' dataSource={dataSource} placeholder='' />
              </Col>
            </Row> : null
        } */}
      </Form>
    </div>
  )
}

export default FeaturesSettings
