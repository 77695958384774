import React, { useEffect, useState, Fragment, useContext } from "react";
import { Col, Row } from "react-bootstrap";
// import { getBuyerById } from "../../crud/buyersCrud";
import { makeStyles, AppBar, Tab, Tabs, Divider } from "@material-ui/core";
import BuyerProfileSideBar from "./buyerProfileSideBar/BuyerProfileSideBar";
import BuyerProfileHeader from "./buyerProfileHeader/BuyerProfileHeader";

import BuyerMail from "./mail/BuyerMail";
import BuyerTimeline from "./timeline/BuyerTimeline";
import BuyerDetails from "./details/BuyerDetails";
import BuyerMatched from "./matched/BuyerMatched";
import BuyerNotes from "./notes/BuyerNotes";
import BuyerContactsWrap from "./contacts/BuyerContactsWrap";
import LoadingSkeleton from "../../../../partials/display/skeleton/LoadingSkeleton";
import { BuyerProfileContext } from "./BuyerProfileContextProvider";
// import BuyerContextProvider  from "../../partials/BuyerContextProvider";
import { LinearProgress } from "@material-ui/core";
import _last from "lodash/last";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Fragment>{children}</Fragment>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: "none",
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
}));

const BuyerModal = (props) => {
  const {
    // changeDirection,
    closeModal,
    resetTableData,
    fetchMoreData,
    updateTable,
    isListingPage,
      triggerFrom
  } = props;
  const [value, setValue] = React.useState(0);
  const [been_contacted_arr, setBeen_contacted_arr] = useState(false);

  // const [pageCount, setPageCount] = useState(0);
  // const { pageCount, setPageCount } = useContext(BuyerContextProvider);
  const {
    profile,
    buyerId,
    // setBuyerId,
    // setBuyerList,
    // buyerList,
    updateContext,
    buyerLoading,
    continuousMode,
    buyerNotContacted,
    filters,
    pageCount,
    setPageCount,
  } = useContext(BuyerProfileContext);

  let last = _last(buyerNotContacted)?.id === buyerId;

  useEffect(() => {
    setBeen_contacted_arr(
      (last && last) || buyerNotContacted?.length === 0 ? true : false
    );
  }, [last, buyerNotContacted]);

  useEffect(() => {
    if (continuousMode && been_contacted_arr) {
      fetchMoreData();
    }
    // eslint-disable-next-line
  }, [continuousMode, been_contacted_arr, pageCount]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const classes = useStyles();

  return (
    <>
      {buyerLoading ? (
        <LinearProgress />
      ) : (
        <div style={{ height: "4px" }}></div>
      )}
      {profile ? (
        <Fragment>
          <Row className="pb-2">
            <Col lg={{ span: 9, offset: 3 }}>
              <BuyerProfileHeader
                profileId={buyerId}
                closeModal={closeModal}
                selectedHeaderTab={value}
                resetTableData={resetTableData}
                setPageCount={setPageCount}
                updateTable={updateTable}
                isListingPage={isListingPage}
                triggerFrom={triggerFrom}
              />
            </Col>
          </Row>

          <Row>
            <Col lg={4} xl={3} className="modal-sidebar">
              <BuyerProfileSideBar
                closeModal={closeModal}
                profile={profile}
                updateContext={updateContext}
              />
              <Divider orientation="vertical" flexItem />
            </Col>

            <Col lg={8} xl={9} className="pl-lg-1">
              <AppBar position="static" className="app-bar pb-2">
                <Tabs
                  className="tabs"
                  variant="scrollable"
                  scrollButtons="auto"
                  value={value}
                  onChange={handleChange}
                  TabIndicatorProps={{
                    style: { background: "transparent" },
                  }}
                >
                  <Tab label="Notes" {...a11yProps(0)} />
                  <Tab label="Contacts" {...a11yProps(1)} />
                  <Tab label="Details" {...a11yProps(2)} />
                  <Tab label="Matched" {...a11yProps(3)} />
                  <Tab label="Timeline" {...a11yProps(4)} />
                  <Tab label="Mail" {...a11yProps(5)} />
                </Tabs>
              </AppBar>
              <div className="contact-profileBody pl-5">
                <div className={classes.root}>
                  <TabPanel className="single-tab-body" value={value} index={0}>
                    <BuyerNotes
                      profileId={buyerId}
                      name="test"
                      setPageCount={setPageCount}
                    />
                  </TabPanel>
                  <TabPanel className="single-tab-body" value={value} index={1}>
                    <BuyerContactsWrap
                      profile={profile}
                      profileId={buyerId}
                      filters={filters}
                    />
                    {/* <BuyerContacts profile={profile} profileId = {buyerId} /> */}
                  </TabPanel>
                  <TabPanel className="single-tab-body" value={value} index={2}>
                    <BuyerDetails profile={profile} profileId={buyerId} />
                  </TabPanel>
                  <TabPanel className="single-tab-body" value={value} index={3}>
                    <BuyerMatched profileId={buyerId} profile={profile} />
                  </TabPanel>
                  <TabPanel className="single-tab-body" value={value} index={4}>
                    <BuyerTimeline profileId={buyerId} />
                  </TabPanel>
                  <TabPanel className="single-tab-body" value={value} index={5}>
                    <BuyerMail
                      profileId={buyerId}
                      contacts={profile?.contacts}
                    />
                  </TabPanel>
                </div>
              </div>
            </Col>
          </Row>
        </Fragment>
      ) : (
        <LoadingSkeleton />
      )}
    </>
  );
};

export default BuyerModal;
