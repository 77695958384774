/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import objectPath from "object-path";
import * as builder from "../../ducks/builder";
import CloseIcon from "@material-ui/icons/Close";
import { ButtonGroup, Button } from "@material-ui/core";
import * as tab from "../../../app/modules/_tab/_redux/tabRedux";
import { stringCompare } from "../../../app/_helpers/_utils/utils";

class SubHeader extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedTab: "",
    };
  }

  // Delete page from Sub Header

  handleDeleteTab = (tabToDelete) => {

    let index;

    if(tabToDelete.name == 'SingleListing' || tabToDelete.name == 'SingleRental')
    {
      index = this.props.tabs.findIndex((obj,index) => obj.name == tabToDelete.name && obj.props.id == tabToDelete.props.id)
    }
    else
    {
      index = this.props.tabs.findIndex((obj,index) => obj.name == tabToDelete.name)
    }

    let length = this.props.tabs.length - 1;

    if(index === length && length === 0) {  
      this.props.history.push("/news")
    }
    else if (index === length && length > 0)
    {

      let _index = index-1;

      if(this.props.tabs[_index].name == 'SingleListing' || this.props.tabs[_index].name == 'SingleRental')
      {
        this.props.history.push("/listings/"+this.props.tabs[index-1].props.id)
      }
      else
      {
        this.props.history.push("/"+this.props.tabs[index-1].name.toLowerCase())
      }
      
    }
    
    else 

    {
      // if((index+1) === length)
      // {
      //   alert('before the last one'+index)
      // }
      // if((index+1) === length)
      // {
      //   alert('before the last one'+index)
      // }
    }

    this.props.tabDelete(tabToDelete);

  };

  // Call setting Page

  handleOpenTab = () => {
    this.props.callTabManager({ name: 'Builder' });
  };


  handleSelectTab = (tabInfo) => {


    let currentTab = tabInfo.navTitle ? tabInfo.navTitle : tabInfo.name
    if(this.props.selectedTab === currentTab)
    {
      return;
    }
    this.props.selectTab(currentTab);
    this.setState({ selectedTab: currentTab });
    let parent = document.querySelector('.tab-content');
    let children = parent.children; // [<div className="child1">]
    for (var i = 0; i < children.length; i++) {
      if (children[i].className == 'fade tab-pane active show') {
        children[i].className = 'fade tab-pane'
        break;
      }
    }
    //to add the removes class from the notification since they are using the same class name it will
    // clear the tab and again add the class
    let parentId = parent.id;
    if (parentId === 'header-notification') {
      children[i].className = 'fade tab-pane active show';
    }

    if (tabInfo.name == "SingleListing") {
      // this.props.history.push('/listings/' + tabInfo.id)
    }
    else if (tabInfo.name == "SingleRental") {
      // this.props.history.push('/rental/' + tabInfo.id)
    }
    else {
      this.props.history.push("/"+currentTab.toLowerCase().replace(/\s/g, '_'))
    }


  };

  componentDidMount() {
    
  }



  render() {

    const { subheaderClasses, subheaderContainerClasses, selectedTab } = this.props;

    const createTabs = () => {
      const tabs = this.props.tabs;

      const allTabs = tabs.map((tab, index) => {
        return (

          <Fragment key={index}>
            {/* <span className="kt-subheader__separator kt-subheader__separator--v" /> */}
            <ButtonGroup
              className={"subNavGroup " + (stringCompare(tab.props.navTitle ? tab.props.navTitle : tab.name, selectedTab) ? 'darkBlue-background font-weight-bold' : '')}
              variant="contained"
              color="primary"
              aria-label="split button"
            >

              <Button
                className={"subNav " + (stringCompare(tab.props.navTitle ? tab.props.navTitle : tab.name, selectedTab) ? 'font-weight-bold' : '')}
                onClick={() => this.handleSelectTab(tab.props)}

              >
                {tab && tab.props && tab.props.navTitle ? tab.props.navTitle.replaceAll("_", " ") : tab.name.replaceAll("_", " ")}
              </Button>
              <Button
                className="subNavIcon"

                color="primary"
                size="small"
                onClick={() => this.handleDeleteTab(tab)}
              >
                <CloseIcon />
              </Button>
            </ButtonGroup>
          </Fragment>
        );
      });

      return <Fragment>{allTabs}</Fragment>;

    };


    return (
      <Fragment>
        <div className={`kt-subheader kt-grid__item ${subheaderClasses}`} id="kt_subheader">
          <div className={`kt-container ${subheaderContainerClasses}`}>

            {/* Sub Header Main */}

            <div className="kt-subheader__main ">
              <div id="content">

                <div className="d-flex flex-row bd-highlight">
                  {/* Dynamic Tab Creation start */}

                  {createTabs()}

                  {/* Dynamic Tab Creation end */}
                </div>
              </div>


            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = store => ({
  config: store.builder.layoutConfig,
  tabs: store.tab.tab,
  selectedTab: store.tab.selectedTab,
  menuConfig: store.builder.menuConfig,
  menuHeaderDisplay: objectPath.get(store.builder.layoutConfig, "header.menu.self.display"),
  layout: objectPath.get(store.builder.layoutConfig, 'subheader.layout'),
  fluid: objectPath.get(store.builder.layoutConfig, 'footer.self.width') === 'fluid',
  clear: objectPath.get(store.builder.layoutConfig, 'subheader.clear'),
  isOpen: false,
  subheaderClasses: builder.selectors.getClasses(store, {
    path: "subheader",
    toString: true
  }),
  subheaderContainerClasses: builder.selectors.getClasses(store, {
    path: "subheader_container",
    toString: true
  }),
});



export default withRouter(connect(mapStateToProps, tab.actions)(SubHeader));
