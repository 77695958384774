import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import './badgeIcon.scss';


const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(2),
    },
  },
}));

export default function BadgeIcon({number, icon}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Badge className='badge-icon' badgeContent={number} children={<i className="fas fa-bell"></i>} color={'secondary'} />
      {/* <Badge badgeContent={100} {...defaultProps} /> */}
    </div>
  );
}