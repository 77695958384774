import React, { Fragment, useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import DynamicTableEdit from "../../../../../partials/table/DynamicTableEdit";
import LoadingSkeleton from "../../../../../partials/display/skeleton/LoadingSkeleton";
import { currencyFormatter, formToQueryString } from "../../../../../_helpers/_utils/utils";
import Status from "../../../../../partials/table/cells/Status";
import Header from "../../../../buyers/modals/buyerProfileModal/matched/Header";
import { getProspectMatchedData } from "../../../curd/prospectsCrud";
import MemberName from "../../../../../partials/popover/memberPopover/MemberName";
import CellPhone from "../../../../../partials/table/cells/CellPhone";
import CellEmail from "../../../../../partials/table/cells/CellEmail";
import CreatedAt from "../../../../../partials/table/cells/CreatedAt";
import LastContact from "../../../../../partials/table/cells/LastContact";
import DefaultModal from "../../../../../partials/modal/DefaultModal";
import BuyerProfileModal from "../../../../buyers/modals/buyerProfileModal/BuyerProfileModal";

const BuyerMatched = ({ edit, handleChange, profileId, profile}) => {
  const [items, setItems] = useState(null);
  const [otherResData, setOtherResData] = useState(null);
  const [matchedBy, setMatchedBy] = useState("price");
  const [selectedItem, setSelectedItem] = useState(null);
  const [showBuyer, setShowBuyer] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  useEffect(() => {
    update({ page: 1 });
  }, [matchedBy]);

  const update = (prams) => {
    let obj = {
      ...prams, limit: 30
    }
    if (matchedBy) {
      obj.match_by = matchedBy;
    }
    if (obj.loadMore) {
      delete (obj.loadMore)
    }
    obj.prospect = profileId
    let query = formToQueryString(obj);
    getProspectMatchedData(query)
      .then(({ data }) => {
        if (prams.loadMore) {
          setItems(items.concat(data?.data?.data));
        } else {
          setItems(data?.data?.data);
        }
        setOtherResData(data.data)
      })
      .catch(() => {});
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Mail",
        columns: [
          {
            Header: "Created",
            accessor: "created_at",
            Cell: ({ cell: { value } }) => <CreatedAt date={value} />,
          },
          {
            Header: "Name",
            accessor: "name",
            Cell: ({
              cell: {
                row: { original },
              },
            }) => (
              <Fragment>
                <div onClick={() => rowClick(original)}>
                  <MemberName
                    trigger="hover"
                    id={original?.primary_contact?.id}
                    data={original?.primary_contact}
                  >
                    {original?.primary_contact?.first_name +
                      " " +
                      original?.primary_contact?.last_name}
                  </MemberName>
                </div>
              </Fragment>
            ),
          },
          {
            Header: "Phone",
            accessor: "phones",
            Cell: ({
              cell: { value },
              cell: {
                row: { original },
              },
            }) => (
              <Fragment>
                <CellPhone value={original?.primary_contact?.phones[0]} />
              </Fragment>
            ),
          },
          {
            Header: "Email",
            accessor: "emails",
            Cell: ({
              cell: { value },
              cell: {
                row: { original },
              },
              cel,
            }) => (
              <Fragment>
                <CellEmail value={original?.primary_contact?.emails[0]} />
              </Fragment>
            ),
          },
          {
            Header: "Rating",
            accessor: "rating",
            Cell: ({ cell: { value } }) => (
              <p className="text-center">{value?.name}</p>
            ),
          },
          {
            Header: "Price",
            accessor: "price_to",
            Cell: ({
              cell: {
                row: { original },
              },
            }) => (
              <p className="text-center">
                {currencyFormatter(original.price_to)}
              </p>
            ),
          },
          {
            Header: "Status",
            accessor: "dnc",
            Cell: ({
              cell: {
                row: { original },
              },
            }) => (
              <Fragment>
                <Status
                  referred_for_finance={original?.referred_for_finance}
                  is_in_exclusive_period={original?.is_in_exclusive_period}
                  purchased_from_us={original?.purchased_from_us}
                  unsubscribed={original?.unsubscribed}
                  dnc={original?.dnc}
                  active={original?.active}
                />
              </Fragment>
            ),
          },

          {
            Header: "Last Contact",
            accessor: "last_contacted_date",
            Cell: ({
              cell: {
                row: { original },
              },
            }) => (
              <LastContact
                date={original.last_contacted_date}
                chanel={original.last_contacted_channel}
                by={original?.last_contacted_by}
              />
            ),
          },
        ],
      },
    ],
    []
  );
  const fetchMoreData = () => {
    let obj = {};
    if (otherResData?.next_page_url) {
      obj.page = otherResData.current_page + 1;
      obj.loadMore = true
      update(obj);
    }
  };

  const rowClick = (e) => {
    setShowBuyer(true);
    setSelectedId(e.id);
  };
  const datas = React.useMemo(() => items, [items]);

  const handleMatchingBy = (e) => {
    Object.keys(e).forEach((key) => {
      if (e[key] !== 1) delete e[key];
    });
    let key = Object.keys(e);
    setMatchedBy(key.join(","));
  };
  const handleDebounceSearch = (value) => {
    let obj = {}
    if (value) {
      obj.search_kw = value
    }
    update(obj)
  }
  return (
    <div>

{showBuyer ? (
        <DefaultModal
          size="xl"
          centered
          show={showBuyer}
          onHide={() => setShowBuyer(false)}
        >
          <BuyerProfileModal
            buyerId={selectedId}
            buyerList={datas}
            closeModal={() => setShowBuyer(false)}
          />
        </DefaultModal>
      ) : (
        ""
      )}


      <Header
        handleGlobalSearch={handleDebounceSearch}
        profileId={profileId}
        selectedItem={selectedItem}
        totalNumber={datas?.length ? datas?.length : 0}
        onSubmit={handleMatchingBy}
        datas={selectedItem?.map((item) => datas.find((val) => val.id === item.listing)).map(data => data?.primary_contact)}
        profile={profile}
      />
      <div className="mail-log-table">
        {datas ? (
          <DynamicTableEdit
            allSelected={(e) => { }}
            handleRowClick={rowClick}
            handleChange={(e) =>
              setSelectedItem(
                e.map((obj) => ({
                  date: new Date(),
                  address: obj?.address?.full_address,
                  listing: obj.id,
                  offer_party: 1,
                }))
              )
            }
            isEditable={true}
            columns={columns}
            data={datas}
            update={fetchMoreData}
          />
        ) : (
          <LoadingSkeleton height="40vh" />
        )}
      </div>
    </div>
  );
};

export default BuyerMatched;
