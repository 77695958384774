import {
  LOGIN_URL,
  ME_URL,
  REGISTER_URL,
  REQUEST_PASSWORD_URL
} from "../../app/GeneralApi/auth.crud";
import userTableMock from "./userTableMock";
import { stateOptions, matchedOptions } from './Data';
import { getRndInteger } from "../../app/_helpers/_utils/utils";
import { fake } from "faker";


var faker = require("faker");
const team = ["ALB", "KP", "CL", "BP"]
export default function mockAuth(mock) {
  mock.onPost(LOGIN_URL).reply(({ data }) => {
    console.log('login moc')
    const { email, password } = JSON.parse(data);
    console.log(data)
    if (email && password) {
      const user = {
        "token_type": "Bearer",
        "expires_in": 31536000,
        "access_token": "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiNjBjNmQyOGY4M2Y4NzljYmM3M2M1YzlmYjE5MTFiZDE4NGJjZDFmZTJlMjUzYzdkMTZjOTUwNWQwNmY0ZmVlNWM0YzE3MDBiM2EzY2ZhNzIiLCJpYXQiOjE1OTM3Mzg4NzUsIm5iZiI6MTU5MzczODg3NSwiZXhwIjoxNjI1Mjc0ODc1LCJzdWIiOiIxNTYxNjE5Iiwic2NvcGVzIjpbImNyZWF0ZS1hcHBsaWNhdGlvbi1zZXR0aW5nIiwiY3JlYXRlLWNvbnRhY3QiLCJjcmVhdGUtbWVtYmVyIiwiY3JlYXRlLXJvbGUiLCJkZWxldGUtY29udGFjdCIsImRlbGV0ZS1maWxlIiwiZGVsZXRlLW1lbWJlciIsImRlbGV0ZS1yb2xlIiwidXBkYXRlLWFwcGxpY2F0aW9uLXNldHRpbmciLCJ1cGRhdGUtY29udGFjdCIsInVwZGF0ZS1tZW1iZXIiLCJ1cGRhdGUtcm9sZSIsInZpZXctYXBwbGljYXRpb24tc2V0dGluZyIsInZpZXctY29udGFjdCIsInZpZXctbWVtYmVyIiwidmlldy1yb2xlIl19.ewdgpehvJOVjnmeQhjnZJpoIsbwSdCJTJm-VIG2gF__9YZPFgVZrOi5ylG9BjwOqFmVcGLcF3m9Z-0QHEkskN70-TVG2tbFd9Qn1JyfSAagMg8gppDbVg396GwlUy381ZEeAwL0ZKWkCSLkNuicN2MZv6AEZeKQA1XYq6JEpIrC61oxG2_YBCL3eoHGgcEvJRVePBU3vrAnxNCei3jfeyLUz4xkXVzIUOjBFDRDe7qFakKCeO9WvCCHHuXtp8onYAHJq4qxdaADhCE4TTZDaEsmFSBjodc4UMa4mER_MnuxPd9LY23PEs1PplGosU8uKb5hxpmbtCsep6GKzAeJCuvgwJcjxhob7pJxlAqSB8CufOQkCb7EJXZwMsd6WVLewwJMZsGlQB3Ce5IK40zGl_1qZZKNgW-5PSmyaUeL4sUoDxigt-wm8FP0e1JRpFEVviqrCUKCGuad2P7bxRILatyP8RW_vuBC7uxtmhcIgNFbxAxJ9O9pJuXdn4fFLDlz3KyCrYVEZFWO-GpAmGLWVEUDNSB4LUOvCY-ckqVOBxSTsvLPvT_t0IJbeXbyWWwXdzpLZ2sazS7A2EKNblJobsK4j-e16GMIbp67pgOIvVDzpDZY62fkdnYO9ZtEqqhDBTzfS5BqHdFise-RZzLUPKgaxRGfbhHZ0zI-3g-XN7fQ",
        "refresh_token": "def50200aa8194849370bcfc93027bd38a6172e94ab01ab53bb235a70c8711e0151b6f7c04f68f523861b87bbfbfd6e558da64fe1d7b1dd2149c2a9cd856b399fe876d2dc3e4e3e574c99cf53c0cf6d22368678132a29604606683217d548615baeb4043e187bb6595ecca9e5892ef304f68c5034bfcfdc573486ddcfa1512a3e9bcd7ef5d458831a96f53b0aa71c4949d128d1d1804542e492918eacc12833078de3a90f1c1ab5d6ff77a8c8f3e3d925650306146e2f38aa7e83087e29087a50140146ea440b1ec0ba2ae27889b99eb43b48421fa712c724e36cd0604d07263cd34689dd3d011808b9d39c89554b3269d034124c3b95c9afbefc233e0b02c762543ce4a07db4e97890c2cd6be371983003665139cca4b28f84f651831310f8577d20383a640271b1026bd448ac3ed8f1f1da225b90009905f27db7583c7b5e9e5c0b29962055f8074a24a96f6fce45e9ae237deb915fd9855400bdc82b48cf5f3dca518111565ee61d7537ce561483e40f46d65274c29a33f107672c3eac2136bcfda88984b81c7ff56dabdbc7a012bf5d92558e5c6211ce773055c0e3835a8e8f3682506d489b4a50d3d900b101385d52e4bf9be083ed7920bff9f90434b6f01f5688f68a3d9cbb6b0a274599d294b751234d8262d0d411ed620c8ac41d458014dd98ba34e9b374b096a31ca5d879ea1de8d2c8b13e635c8ed6682cba1c8c9885298028e07ca0f6e2b075184f33d3be3cca4957366a670267d17b71c34b7af231ddd7dc6ea19383a7105ccfd06777cf2cc015f147012182422a79abb8e9eb1d8f3c709cdbddb208f27ee4532ba82484d5dbf857e67fbd22bd3affefd1ae49eddf95cb0e5b3cd32c813f37e5dd1778bd21192114d424f08de7b0bda97ecf4"
      };
      console.log(user)
      if (user) {
        return [200, { ...user, password: undefined }];
      }
    }

    return [400];
  });

  mock.onPost(REGISTER_URL).reply(({ data }) => {
    const { email, fullname, username, password } = JSON.parse(data);

    if (email && fullname && username && password) {
      const user = {
        email,
        fullname,
        username,
        password,
        roles: [2], // Manager
        accessToken: "access-token-" + Math.random(),
        refreshToken: "access-token-" + Math.random(),
        pic: process.env.PUBLIC_URL + "/media/users/default.jpg"
      };

      userTableMock.push(user);

      return [200, { ...user, password: undefined }];
    }

    return [400];
  });

  mock.onPost(REQUEST_PASSWORD_URL).reply(({ data }) => {
    const { email } = JSON.parse(data);

    if (email) {
      const user = userTableMock.find(
        x => x.email.toLowerCase() === email.toLowerCase()
      );

      if (user) {
        user.password = undefined;

        return [200, { ...user, password: undefined }];
      }
    }

    return [400];
  });

  mock.onGet(ME_URL).reply(({ headers: { Authorization } }) => {
    const accessToken =
      Authorization &&
      Authorization.startsWith("Bearer ") &&
      Authorization.slice("Bearer ".length);

    if (accessToken) {
      const user = userTableMock.find(x => x.accessToken === accessToken);

      if (user) {
        return [200, { ...user, password: undefined }];
      }
    }

    return [401];
  });

  mock.onGet('/contact/34', { params: { searchText: "John" } }).reply(200, {
    "id": 1,
    "first_name": "Admin",
    "last_name": "Wiseberry",
    "other_name": "WB",
    "email": "test@test.com",
    "mobile": "0412345678",
    "home_phone": "0212345678",
    "office_phone": "0212345678",
    "address": {
      "id": 2,
      "unit": null,
      "street_number": "9",
      "street_name": "Craigmore",
      "street_type": "Drive",
      "suburb": "KELLYVILLE",
      "state": "NSW",
      "postcode": "2155"
    },
    "type": [
      "B",
      "P",
      "S"
    ],
    "related_contacts_count": 2,
    "last_contacted_date": "2020-06-30T01:54:22.000000Z",
    "is_last_contactet_appointment": true
  });


  mock.onGet('/mail').reply(200,
    {
      "data": [
        {
          "id": "1",
          "createdAt": "2020-07-06T09:09:35.255Z",
          "date": "2020-03-10T06:09:30.695Z",
          "from": "Marcelle Steuber",
          "type": 93,
          "subject": "Principal Data Producer",
          "body": "We need to bypass the multi-byte IB protocol!"
        },
        {
          "id": "2",
          "createdAt": "2020-07-06T13:55:05.410Z",
          "date": "2019-07-20T07:09:44.198Z",
          "from": "Pattie Lubowitz",
          "type": 85,
          "subject": "Dynamic Solutions Technician",
          "body": "The AGP firewall is down, reboot the haptic bandwidth so we can override the AGP card!"
        },
        {
          "id": "3",
          "createdAt": "2020-07-06T23:34:52.284Z",
          "date": "2020-03-23T16:20:26.894Z",
          "from": "Santa Cronin",
          "type": 60,
          "subject": "Lead Integration Director",
          "body": "programming the matrix won't do anything, we need to connect the bluetooth SCSI application!"
        },
        {
          "id": "4",
          "createdAt": "2020-07-06T13:52:41.222Z",
          "date": "2019-12-09T16:38:28.498Z",
          "from": "Grant Nicolas",
          "type": 4,
          "subject": "Senior Brand Orchestrator",
          "body": "Try to transmit the COM system, maybe it will program the optical capacitor!"
        },
        {
          "id": "5",
          "createdAt": "2020-07-06T12:41:39.271Z",
          "date": "2019-12-15T05:54:22.454Z",
          "from": "Eleazar Bernhard",
          "type": 93,
          "subject": "Regional Group Officer",
          "body": "I'll copy the wireless SQL system, that should port the SQL transmitter!"
        },
        {
          "id": "6",
          "createdAt": "2020-07-07T04:14:08.924Z",
          "date": "2019-11-25T20:43:57.573Z",
          "from": "Akeem Cartwright",
          "type": 96,
          "subject": "Internal Solutions Supervisor",
          "body": "Try to transmit the SSL sensor, maybe it will synthesize the neural circuit!"
        },
        {
          "id": "7",
          "createdAt": "2020-07-06T14:32:50.735Z",
          "date": "2020-04-09T05:19:03.696Z",
          "from": "Coby Dibbert",
          "type": 12,
          "subject": "National Directives Representative",
          "body": "The SMS protocol is down, quantify the multi-byte panel so we can compress the TCP bus!"
        },
        {
          "id": "8",
          "createdAt": "2020-07-06T10:22:24.494Z",
          "date": "2019-11-13T16:25:45.157Z",
          "from": "Skye Cassin",
          "type": 39,
          "subject": "Product Optimization Specialist",
          "body": "I'll parse the auxiliary JBOD port, that should monitor the USB monitor!"
        },
        {
          "id": "9",
          "createdAt": "2020-07-07T04:28:52.695Z",
          "date": "2019-07-30T08:51:25.286Z",
          "from": "Clifton Harber",
          "type": 80,
          "subject": "Future Usability Representative",
          "body": "The SMTP alarm is down, copy the virtual matrix so we can parse the HTTP array!"
        },
        {
          "id": "10",
          "createdAt": "2020-07-06T08:32:13.793Z",
          "date": "2019-11-20T22:32:57.354Z",
          "from": "Clementina O'Reilly",
          "type": 32,
          "subject": "Lead Creative Strategist",
          "body": "The PCI bus is down, synthesize the virtual port so we can quantify the SCSI panel!"
        },
        {
          "id": "11",
          "createdAt": "2020-07-07T03:56:40.109Z",
          "date": "2020-04-26T03:52:35.011Z",
          "from": "Kariane Sporer",
          "type": 88,
          "subject": "Dynamic Quality Administrator",
          "body": "You can't copy the firewall without programming the solid state SDD pixel!"
        },
        {
          "id": "12",
          "createdAt": "2020-07-07T04:06:03.371Z",
          "date": "2020-01-29T17:10:28.295Z",
          "from": "Miss Colten Hackett",
          "type": 8,
          "subject": "National Assurance Officer",
          "body": "I'll navigate the digital AGP microchip, that should panel the SAS transmitter!"
        },
        {
          "id": "13",
          "createdAt": "2020-07-07T04:06:44.676Z",
          "date": "2019-10-20T01:03:24.097Z",
          "from": "Cali Koss",
          "type": 32,
          "subject": "District Assurance Executive",
          "body": "I'll compress the neural USB program, that should system the JSON application!"
        },
        {
          "id": "14",
          "createdAt": "2020-07-07T03:13:46.909Z",
          "date": "2019-12-22T01:55:34.931Z",
          "from": "Dr. Estella Leannon",
          "type": 19,
          "subject": "Product Usability Administrator",
          "body": "The USB array is down, connect the redundant alarm so we can program the SMTP array!"
        },
        {
          "id": "15",
          "createdAt": "2020-07-06T19:49:54.953Z",
          "date": "2019-09-01T13:35:32.903Z",
          "from": "Kasandra Denesik",
          "type": 35,
          "subject": "Corporate Web Analyst",
          "body": "The SCSI transmitter is down, compress the 1080p pixel so we can synthesize the AGP system!"
        },
        {
          "id": "16",
          "createdAt": "2020-07-06T12:56:37.658Z",
          "date": "2020-06-10T12:11:14.504Z",
          "from": "Darrin Schowalter",
          "type": 83,
          "subject": "Global Creative Officer",
          "body": "The SMS protocol is down, copy the open-source interface so we can bypass the XML card!"
        },
        {
          "id": "17",
          "createdAt": "2020-07-06T15:55:56.441Z",
          "date": "2020-02-18T09:32:10.763Z",
          "from": "Edmund Brown",
          "type": 49,
          "subject": "Future Paradigm Director",
          "body": "We need to hack the wireless XML firewall!"
        },
        {
          "id": "18",
          "createdAt": "2020-07-06T07:40:08.668Z",
          "date": "2019-07-19T05:58:51.132Z",
          "from": "Freeman Fritsch",
          "type": 57,
          "subject": "District Configuration Liaison",
          "body": "compressing the bandwidth won't do anything, we need to connect the neural JBOD matrix!"
        },
        {
          "id": "19",
          "createdAt": "2020-07-06T23:12:19.572Z",
          "date": "2019-12-21T14:43:42.124Z",
          "from": "Verna Witting",
          "type": 67,
          "subject": "Customer Creative Producer",
          "body": "You can't input the capacitor without bypassing the bluetooth JSON port!"
        },
        {
          "id": "20",
          "createdAt": "2020-07-06T20:39:46.777Z",
          "date": "2019-10-24T15:21:18.896Z",
          "from": "Darlene Rodriguez I",
          "type": 85,
          "subject": "Investor Group Facilitator",
          "body": "Try to program the SSL monitor, maybe it will program the primary port!"
        },
        {
          "id": "21",
          "createdAt": "2020-07-06T21:35:02.585Z",
          "date": "2019-09-15T21:16:21.169Z",
          "from": "Priscilla Will",
          "type": 64,
          "subject": "Chief Response Planner",
          "body": "I'll copy the redundant HTTP circuit, that should hard drive the ADP system!"
        },
        {
          "id": "22",
          "createdAt": "2020-07-06T21:12:16.361Z",
          "date": "2020-05-18T12:23:01.926Z",
          "from": "Katlyn Volkman",
          "type": 61,
          "subject": "Legacy Functionality Developer",
          "body": "You can't index the circuit without connecting the optical AGP feed!"
        },
        {
          "id": "23",
          "createdAt": "2020-07-06T20:55:23.294Z",
          "date": "2019-09-07T08:20:50.661Z",
          "from": "Jacinto Marquardt Sr.",
          "type": 74,
          "subject": "Forward Directives Director",
          "body": "programming the bandwidth won't do anything, we need to hack the haptic XSS bus!"
        },
        {
          "id": "24",
          "createdAt": "2020-07-07T01:41:03.565Z",
          "date": "2020-04-29T05:57:46.245Z",
          "from": "Alaina Gislason",
          "type": 51,
          "subject": "Investor Brand Liaison",
          "body": "Use the wireless SQL monitor, then you can reboot the online alarm!"
        },
        {
          "id": "25",
          "createdAt": "2020-07-06T10:52:47.467Z",
          "date": "2020-01-04T21:30:35.948Z",
          "from": "Stephanie Hilll",
          "type": 87,
          "subject": "Internal Markets Officer",
          "body": "If we connect the microchip, we can get to the USB driver through the online ADP interface!"
        },
        {
          "id": "26",
          "createdAt": "2020-07-07T02:39:35.546Z",
          "date": "2020-01-01T12:56:19.938Z",
          "from": "Candida Mante",
          "type": 93,
          "subject": "Forward Data Designer",
          "body": "The JSON protocol is down, reboot the open-source matrix so we can bypass the COM port!"
        },
        {
          "id": "27",
          "createdAt": "2020-07-07T02:55:47.471Z",
          "date": "2020-06-24T21:22:30.881Z",
          "from": "Jasen Rice",
          "type": 99,
          "subject": "Future Functionality Administrator",
          "body": "The SDD alarm is down, quantify the wireless sensor so we can connect the AGP array!"
        },
        {
          "id": "28",
          "createdAt": "2020-07-06T12:33:16.124Z",
          "date": "2020-02-29T23:24:23.904Z",
          "from": "Ms. Erling Bradtke",
          "type": 93,
          "subject": "Legacy Interactions Producer",
          "body": "generating the system won't do anything, we need to override the virtual SAS matrix!"
        },
        {
          "id": "29",
          "createdAt": "2020-07-06T23:20:04.757Z",
          "date": "2020-02-08T05:59:46.427Z",
          "from": "Chandler Swaniawski",
          "type": 81,
          "subject": "Chief Research Analyst",
          "body": "Use the 1080p HDD card, then you can generate the virtual driver!"
        },
        {
          "id": "30",
          "createdAt": "2020-07-07T01:35:38.457Z",
          "date": "2019-11-13T13:10:10.931Z",
          "from": "Evan Leannon",
          "type": 0,
          "subject": "Forward Brand Liaison",
          "body": "You can't transmit the hard drive without transmitting the haptic USB capacitor!"
        },
        {
          "id": "31",
          "createdAt": "2020-07-07T03:02:55.600Z",
          "date": "2020-02-14T08:31:03.384Z",
          "from": "Geovanny Volkman",
          "type": 38,
          "subject": "Dynamic Factors Orchestrator",
          "body": "hacking the matrix won't do anything, we need to input the cross-platform PNG pixel!"
        },
        {
          "id": "32",
          "createdAt": "2020-07-06T17:45:53.137Z",
          "date": "2020-04-03T15:35:59.042Z",
          "from": "Marcos Hagenes",
          "type": 69,
          "subject": "Customer Applications Facilitator",
          "body": "If we synthesize the driver, we can get to the JSON protocol through the auxiliary SSL application!"
        },
        {
          "id": "33",
          "createdAt": "2020-07-06T23:52:15.211Z",
          "date": "2019-09-23T15:40:22.153Z",
          "from": "Royce Huels",
          "type": 96,
          "subject": "Lead Infrastructure Coordinator",
          "body": "I'll quantify the bluetooth EXE alarm, that should system the SMS protocol!"
        },
        {
          "id": "34",
          "createdAt": "2020-07-06T10:55:56.936Z",
          "date": "2019-08-19T17:48:10.085Z",
          "from": "Gerald Von",
          "type": 58,
          "subject": "Product Infrastructure Assistant",
          "body": "We need to connect the haptic SAS driver!"
        },
        {
          "id": "35",
          "createdAt": "2020-07-07T00:27:03.852Z",
          "date": "2019-11-20T14:51:17.832Z",
          "from": "Stephan Yundt",
          "type": 31,
          "subject": "Forward Branding Planner",
          "body": "I'll connect the redundant SCSI bus, that should firewall the FTP protocol!"
        },
        {
          "id": "36",
          "createdAt": "2020-07-06T18:18:09.650Z",
          "date": "2019-11-29T13:53:42.242Z",
          "from": "Wellington Smith",
          "type": 56,
          "subject": "Human Optimization Administrator",
          "body": "The SDD feed is down, input the online port so we can generate the SCSI application!"
        },
        {
          "id": "37",
          "createdAt": "2020-07-07T05:54:25.398Z",
          "date": "2020-01-27T13:47:53.760Z",
          "from": "Kamren Wehner",
          "type": 96,
          "subject": "Global Creative Agent",
          "body": "I'll copy the haptic PCI feed, that should application the SDD bus!"
        },
        {
          "id": "38",
          "createdAt": "2020-07-07T01:41:25.621Z",
          "date": "2019-12-10T23:28:02.300Z",
          "from": "Dr. Earline Homenick",
          "type": 14,
          "subject": "Internal Accounts Strategist",
          "body": "I'll transmit the 1080p RSS program, that should firewall the IB sensor!"
        },
        {
          "id": "39",
          "createdAt": "2020-07-06T10:05:39.679Z",
          "date": "2019-10-04T16:40:47.369Z",
          "from": "Mrs. Rylan Vandervort",
          "type": 7,
          "subject": "Customer Markets Strategist",
          "body": "We need to override the neural IB card!"
        }
      ],
      "links": {
        "first": "http://auxatia.serveousercontent.com/api/v1/contact?page=1",
        "last": "http://auxatia.serveousercontent.com/api/v1/contact?page=104090",
        "prev": null,
        "next": "http://auxatia.serveousercontent.com/api/v1/contact?page=2"
      },
      "meta": {
        "current_page": 1,
        "from": 1,
        "last_page": 104090,
        "path": "http://auxatia.serveousercontent.com/api/v1/contact",
        "per_page": 15,
        "to": 15,
        "total": 1561349
      }
    });

  mock.onGet('/buyers').reply(200,
    {
      "data": [
        {
          "id": "1",
          "createdAt": "2020-07-14T06:26:27.052Z",
          "first_name": "Lilla",
          "avatar": "https://s3.amazonaws.com/uifaces/faces/twitter/russell_baylis/128.jpg",
          "last_name": "Bauch",
          "phone": "520.361.9569 x6833",
          "email": "Dannie.Denesik32@gmail.com",
          "weeks": 96,
          "rating": "rating 1",
          "price": "00953017",
          "status": 69,
          "matched": 71,
          "seen": 21,
          "offers": 13,
          "linked_contacts": 3,
          "last_contacted": "2020-07-13T07:49:42.454Z"
        },
        {
          "id": "2",
          "createdAt": "2020-07-13T10:05:32.088Z",
          "first_name": "Wendell",
          "avatar": "https://s3.amazonaws.com/uifaces/faces/twitter/nicoleglynn/128.jpg",
          "last_name": "Pfeffer",
          "phone": "(141) 888-3860 x08097",
          "email": "Charlie_Simonis@gmail.com",
          "weeks": 31,
          "rating": "rating 2",
          "price": "16573851",
          "status": 37,
          "matched": 76,
          "seen": 83,
          "offers": 49,
          "linked_contacts": 81,
          "last_contacted": "2020-07-13T21:01:58.030Z"
        },
        {
          "id": "3",
          "createdAt": "2020-07-13T21:44:02.707Z",
          "first_name": "Virgil",
          "avatar": "https://s3.amazonaws.com/uifaces/faces/twitter/cynthiasavard/128.jpg",
          "last_name": "Lueilwitz",
          "phone": "587-744-0171",
          "email": "Tyrese.Wilderman95@yahoo.com",
          "weeks": 48,
          "rating": "rating 3",
          "price": "30151837",
          "status": 70,
          "matched": 20,
          "seen": 55,
          "offers": 93,
          "linked_contacts": 43,
          "last_contacted": "2020-07-13T22:19:42.637Z"
        },
        {
          "id": "4",
          "createdAt": "2020-07-13T22:50:31.590Z",
          "first_name": "Erica",
          "avatar": "https://s3.amazonaws.com/uifaces/faces/twitter/derienzo777/128.jpg",
          "last_name": "Casper",
          "phone": "(311) 478-0413 x3567",
          "email": "Terrance.Torp23@yahoo.com",
          "weeks": 4,
          "rating": "rating 4",
          "price": "01499298",
          "status": 80,
          "matched": 73,
          "seen": 45,
          "offers": 48,
          "linked_contacts": 75,
          "last_contacted": "2020-07-13T21:46:09.340Z"
        },
        {
          "id": "5",
          "createdAt": "2020-07-13T19:39:05.319Z",
          "first_name": "Malinda",
          "avatar": "https://s3.amazonaws.com/uifaces/faces/twitter/finchjke/128.jpg",
          "last_name": "Littel",
          "phone": "949.134.1962",
          "email": "Arch.Ryan1@gmail.com",
          "weeks": 12,
          "rating": "rating 5",
          "price": "22720860",
          "status": 14,
          "matched": 18,
          "seen": 71,
          "offers": 43,
          "linked_contacts": 68,
          "last_contacted": "2020-07-13T19:39:24.835Z"
        },
        {
          "id": "6",
          "createdAt": "2020-07-14T01:55:15.413Z",
          "first_name": "Addie",
          "avatar": "https://s3.amazonaws.com/uifaces/faces/twitter/sterlingrules/128.jpg",
          "last_name": "Emard",
          "phone": "176-154-5567",
          "email": "Oswaldo.Cartwright81@yahoo.com",
          "weeks": 46,
          "rating": "rating 6",
          "price": "40967654",
          "status": 57,
          "matched": 26,
          "seen": 38,
          "offers": 14,
          "linked_contacts": 74,
          "last_contacted": "2020-07-13T09:33:16.978Z"
        },
        {
          "id": "7",
          "createdAt": "2020-07-13T11:32:22.288Z",
          "first_name": "Leann",
          "avatar": "https://s3.amazonaws.com/uifaces/faces/twitter/kucingbelang4/128.jpg",
          "last_name": "Waelchi",
          "phone": "(016) 424-1469 x14885",
          "email": "Valentin.Emmerich99@yahoo.com",
          "weeks": 70,
          "rating": "rating 7",
          "price": "31444826",
          "status": 23,
          "matched": 71,
          "seen": 14,
          "offers": 12,
          "linked_contacts": 19,
          "last_contacted": "2020-07-13T09:18:54.562Z"
        },
        {
          "id": "8",
          "createdAt": "2020-07-13T22:27:15.898Z",
          "first_name": "Vivienne",
          "avatar": "https://s3.amazonaws.com/uifaces/faces/twitter/bpartridge/128.jpg",
          "last_name": "Veum",
          "phone": "028-757-9071 x7607",
          "email": "Annalise.Grimes@yahoo.com",
          "weeks": 73,
          "rating": "rating 8",
          "price": "72667484",
          "status": 4,
          "matched": 88,
          "seen": 55,
          "offers": 76,
          "linked_contacts": 66,
          "last_contacted": "2020-07-13T11:36:24.662Z"
        },
        {
          "id": "9",
          "createdAt": "2020-07-13T15:21:58.718Z",
          "first_name": "Delilah",
          "avatar": "https://s3.amazonaws.com/uifaces/faces/twitter/bluefx_/128.jpg",
          "last_name": "Schimmel",
          "phone": "(110) 872-6306 x87432",
          "email": "Kenton98@hotmail.com",
          "weeks": 46,
          "rating": "rating 9",
          "price": "40351724",
          "status": 67,
          "matched": 32,
          "seen": 45,
          "offers": 18,
          "linked_contacts": 26,
          "last_contacted": "2020-07-14T04:13:40.529Z"
        },
        {
          "id": "10",
          "createdAt": "2020-07-14T06:34:53.647Z",
          "first_name": "Dorcas",
          "avatar": "https://s3.amazonaws.com/uifaces/faces/twitter/pmeissner/128.jpg",
          "last_name": "Hermann",
          "phone": "(419) 056-0275 x87637",
          "email": "Sammie.Sawayn@hotmail.com",
          "weeks": 21,
          "rating": "rating 10",
          "price": "06708054",
          "status": 67,
          "matched": 47,
          "seen": 44,
          "offers": 22,
          "linked_contacts": 8,
          "last_contacted": "2020-07-14T03:57:23.365Z"
        },
        {
          "id": "11",
          "createdAt": "2020-07-13T20:10:08.163Z",
          "first_name": "Keeley",
          "avatar": "https://s3.amazonaws.com/uifaces/faces/twitter/uxward/128.jpg",
          "last_name": "Rempel",
          "phone": "1-920-107-1304 x74679",
          "email": "Van.Herzog40@hotmail.com",
          "weeks": 28,
          "rating": "rating 11",
          "price": "97857385",
          "status": 78,
          "matched": 92,
          "seen": 10,
          "offers": 40,
          "linked_contacts": 93,
          "last_contacted": "2020-07-14T03:25:53.752Z"
        },
        {
          "id": "12",
          "createdAt": "2020-07-13T12:06:43.453Z",
          "first_name": "Ramiro",
          "avatar": "https://s3.amazonaws.com/uifaces/faces/twitter/gauchomatt/128.jpg",
          "last_name": "Adams",
          "phone": "604-131-4661 x273",
          "email": "Gail.Koepp@hotmail.com",
          "weeks": 58,
          "rating": "rating 12",
          "price": "37687558",
          "status": 61,
          "matched": 87,
          "seen": 81,
          "offers": 80,
          "linked_contacts": 35,
          "last_contacted": "2020-07-13T08:30:45.105Z"
        },
        {
          "id": "13",
          "createdAt": "2020-07-13T10:53:36.283Z",
          "first_name": "Heather",
          "avatar": "https://s3.amazonaws.com/uifaces/faces/twitter/joshmedeski/128.jpg",
          "last_name": "Denesik",
          "phone": "(298) 726-0475 x528",
          "email": "London76@yahoo.com",
          "weeks": 14,
          "rating": "rating 13",
          "price": "07836121",
          "status": 94,
          "matched": 1,
          "seen": 14,
          "offers": 38,
          "linked_contacts": 33,
          "last_contacted": "2020-07-14T00:23:44.700Z"
        },
        {
          "id": "14",
          "createdAt": "2020-07-14T00:32:59.257Z",
          "first_name": "Talon",
          "avatar": "https://s3.amazonaws.com/uifaces/faces/twitter/dimaposnyy/128.jpg",
          "last_name": "Barrows",
          "phone": "350.278.9567 x0878",
          "email": "Dallas.Bergnaum@hotmail.com",
          "weeks": 10,
          "rating": "rating 14",
          "price": "73632672",
          "status": 31,
          "matched": 65,
          "seen": 65,
          "offers": 32,
          "linked_contacts": 90,
          "last_contacted": "2020-07-14T01:27:02.304Z"
        },
        {
          "id": "15",
          "createdAt": "2020-07-13T20:47:04.761Z",
          "first_name": "Colin",
          "avatar": "https://s3.amazonaws.com/uifaces/faces/twitter/agromov/128.jpg",
          "last_name": "Bergnaum",
          "phone": "445.442.4255",
          "email": "Jeffrey.Kshlerin67@yahoo.com",
          "weeks": 87,
          "rating": "rating 15",
          "price": "30741898",
          "status": 61,
          "matched": 17,
          "seen": 56,
          "offers": 72,
          "linked_contacts": 98,
          "last_contacted": "2020-07-13T19:04:24.200Z"
        },
        {
          "id": "16",
          "createdAt": "2020-07-13T23:07:10.304Z",
          "first_name": "Roma",
          "avatar": "https://s3.amazonaws.com/uifaces/faces/twitter/osmond/128.jpg",
          "last_name": "Strosin",
          "phone": "1-779-992-4424 x844",
          "email": "Jamil.McKenzie@gmail.com",
          "weeks": 84,
          "rating": "rating 16",
          "price": "41336618",
          "status": 51,
          "matched": 91,
          "seen": 27,
          "offers": 85,
          "linked_contacts": 84,
          "last_contacted": "2020-07-13T12:01:33.917Z"
        },
        {
          "id": "17",
          "createdAt": "2020-07-13T22:10:25.390Z",
          "first_name": "Stephania",
          "avatar": "https://s3.amazonaws.com/uifaces/faces/twitter/vigobronx/128.jpg",
          "last_name": "Walsh",
          "phone": "1-480-031-0160 x9460",
          "email": "Mattie.Nitzsche74@yahoo.com",
          "weeks": 72,
          "rating": "rating 17",
          "price": "46129447",
          "status": 100,
          "matched": 92,
          "seen": 57,
          "offers": 9,
          "linked_contacts": 33,
          "last_contacted": "2020-07-13T10:50:56.992Z"
        },
        {
          "id": "18",
          "createdAt": "2020-07-14T05:31:39.173Z",
          "first_name": "Linnie",
          "avatar": "https://s3.amazonaws.com/uifaces/faces/twitter/borges_marcos/128.jpg",
          "last_name": "Crona",
          "phone": "(279) 493-8028 x7474",
          "email": "Tyreek84@hotmail.com",
          "weeks": 10,
          "rating": "rating 18",
          "price": "95418220",
          "status": 84,
          "matched": 15,
          "seen": 18,
          "offers": 79,
          "linked_contacts": 74,
          "last_contacted": "2020-07-13T15:42:30.549Z"
        },
        {
          "id": "19",
          "createdAt": "2020-07-13T09:39:38.021Z",
          "first_name": "Callie",
          "avatar": "https://s3.amazonaws.com/uifaces/faces/twitter/sprayaga/128.jpg",
          "last_name": "Renner",
          "phone": "925-202-4363 x78001",
          "email": "Yvonne90@gmail.com",
          "weeks": 58,
          "rating": "rating 19",
          "price": "01983064",
          "status": 82,
          "matched": 34,
          "seen": 25,
          "offers": 33,
          "linked_contacts": 94,
          "last_contacted": "2020-07-14T01:45:58.128Z"
        },
        {
          "id": "20",
          "createdAt": "2020-07-14T05:12:49.313Z",
          "first_name": "Griffin",
          "avatar": "https://s3.amazonaws.com/uifaces/faces/twitter/guischmitt/128.jpg",
          "last_name": "Baumbach",
          "phone": "595.275.3308 x385",
          "email": "Yolanda.Mraz@yahoo.com",
          "weeks": 0,
          "rating": "rating 20",
          "price": "87959059",
          "status": 55,
          "matched": 66,
          "seen": 25,
          "offers": 4,
          "linked_contacts": 80,
          "last_contacted": "2020-07-13T09:21:23.215Z"
        },
        {
          "id": "21",
          "createdAt": "2020-07-14T05:34:45.732Z",
          "first_name": "Emmy",
          "avatar": "https://s3.amazonaws.com/uifaces/faces/twitter/silv3rgvn/128.jpg",
          "last_name": "Corwin",
          "phone": "091.985.5218",
          "email": "Verlie_Huel@yahoo.com",
          "weeks": 24,
          "rating": "rating 21",
          "price": "66986297",
          "status": 41,
          "matched": 4,
          "seen": 49,
          "offers": 12,
          "linked_contacts": 72,
          "last_contacted": "2020-07-14T05:48:29.706Z"
        },
        {
          "id": "22",
          "createdAt": "2020-07-13T16:52:42.479Z",
          "first_name": "Ruth",
          "avatar": "https://s3.amazonaws.com/uifaces/faces/twitter/rweve/128.jpg",
          "last_name": "Crist",
          "phone": "1-947-238-3691",
          "email": "Bryana.Hoppe39@hotmail.com",
          "weeks": 33,
          "rating": "rating 22",
          "price": "75960573",
          "status": 10,
          "matched": 80,
          "seen": 98,
          "offers": 38,
          "linked_contacts": 2,
          "last_contacted": "2020-07-13T12:28:42.554Z"
        },
        {
          "id": "23",
          "createdAt": "2020-07-13T18:39:22.135Z",
          "first_name": "Elfrieda",
          "avatar": "https://s3.amazonaws.com/uifaces/faces/twitter/derekcramer/128.jpg",
          "last_name": "Ruecker",
          "phone": "047.054.6337",
          "email": "Lori.Stroman14@gmail.com",
          "weeks": 20,
          "rating": "rating 23",
          "price": "49806750",
          "status": 12,
          "matched": 27,
          "seen": 67,
          "offers": 73,
          "linked_contacts": 12,
          "last_contacted": "2020-07-13T15:59:10.756Z"
        },
        {
          "id": "24",
          "createdAt": "2020-07-13T07:15:22.962Z",
          "first_name": "Kristopher",
          "avatar": "https://s3.amazonaws.com/uifaces/faces/twitter/faisalabid/128.jpg",
          "last_name": "Schaden",
          "phone": "(157) 445-7939 x31274",
          "email": "Loy53@hotmail.com",
          "weeks": 42,
          "rating": "rating 24",
          "price": "56252828",
          "status": 53,
          "matched": 50,
          "seen": 62,
          "offers": 35,
          "linked_contacts": 74,
          "last_contacted": "2020-07-13T13:02:39.997Z"
        },
        {
          "id": "25",
          "createdAt": "2020-07-13T15:50:07.281Z",
          "first_name": "Alec",
          "avatar": "https://s3.amazonaws.com/uifaces/faces/twitter/polarity/128.jpg",
          "last_name": "Gislason",
          "phone": "993.179.4983",
          "email": "Derrick.Marvin79@hotmail.com",
          "weeks": 94,
          "rating": "rating 25",
          "price": "17950814",
          "status": 98,
          "matched": 20,
          "seen": 68,
          "offers": 68,
          "linked_contacts": 66,
          "last_contacted": "2020-07-13T20:50:31.245Z"
        },
        {
          "id": "26",
          "createdAt": "2020-07-13T21:10:50.948Z",
          "first_name": "Magnolia",
          "avatar": "https://s3.amazonaws.com/uifaces/faces/twitter/darcystonge/128.jpg",
          "last_name": "Bartell",
          "phone": "1-918-875-6087 x17507",
          "email": "Josefina49@gmail.com",
          "weeks": 70,
          "rating": "rating 26",
          "price": "92926941",
          "status": 99,
          "matched": 36,
          "seen": 49,
          "offers": 72,
          "linked_contacts": 68,
          "last_contacted": "2020-07-14T01:49:17.184Z"
        },
        {
          "id": "27",
          "createdAt": "2020-07-14T01:11:09.478Z",
          "first_name": "Jimmy",
          "avatar": "https://s3.amazonaws.com/uifaces/faces/twitter/mvdheuvel/128.jpg",
          "last_name": "Feil",
          "phone": "417.509.3297 x50688",
          "email": "Joey.Turcotte@gmail.com",
          "weeks": 33,
          "rating": "rating 27",
          "price": "07122319",
          "status": 72,
          "matched": 21,
          "seen": 22,
          "offers": 58,
          "linked_contacts": 41,
          "last_contacted": "2020-07-14T05:36:49.679Z"
        },
        {
          "id": "28",
          "createdAt": "2020-07-14T05:29:39.169Z",
          "first_name": "Stewart",
          "avatar": "https://s3.amazonaws.com/uifaces/faces/twitter/m_kalibry/128.jpg",
          "last_name": "Wilkinson",
          "phone": "245-722-7083 x48523",
          "email": "Alejandrin87@gmail.com",
          "weeks": 31,
          "rating": "rating 28",
          "price": "84990291",
          "status": 96,
          "matched": 16,
          "seen": 16,
          "offers": 3,
          "linked_contacts": 41,
          "last_contacted": "2020-07-13T11:28:47.704Z"
        },
        {
          "id": "29",
          "createdAt": "2020-07-13T12:02:12.841Z",
          "first_name": "Al",
          "avatar": "https://s3.amazonaws.com/uifaces/faces/twitter/lososina/128.jpg",
          "last_name": "Walker",
          "phone": "(249) 123-0384",
          "email": "Wayne61@gmail.com",
          "weeks": 55,
          "rating": "rating 29",
          "price": "52539173",
          "status": 30,
          "matched": 70,
          "seen": 84,
          "offers": 18,
          "linked_contacts": 31,
          "last_contacted": "2020-07-13T09:44:02.568Z"
        },
        {
          "id": "30",
          "createdAt": "2020-07-13T18:58:13.713Z",
          "first_name": "Sarai",
          "avatar": "https://s3.amazonaws.com/uifaces/faces/twitter/itskawsar/128.jpg",
          "last_name": "Reinger",
          "phone": "570.479.7266",
          "email": "Hellen87@hotmail.com",
          "weeks": 60,
          "rating": "rating 30",
          "price": "63151885",
          "status": 40,
          "matched": 68,
          "seen": 25,
          "offers": 25,
          "linked_contacts": 3,
          "last_contacted": "2020-07-14T05:52:28.950Z"
        },
        {
          "id": "31",
          "createdAt": "2020-07-13T23:34:55.849Z",
          "first_name": "Estevan",
          "avatar": "https://s3.amazonaws.com/uifaces/faces/twitter/lokesh_coder/128.jpg",
          "last_name": "Waelchi",
          "phone": "(840) 039-3914",
          "email": "Leone46@yahoo.com",
          "weeks": 9,
          "rating": "rating 31",
          "price": "98748154",
          "status": 21,
          "matched": 1,
          "seen": 42,
          "offers": 50,
          "linked_contacts": 78,
          "last_contacted": "2020-07-13T07:50:23.347Z"
        },
        {
          "id": "32",
          "createdAt": "2020-07-14T04:15:26.413Z",
          "first_name": "Lulu",
          "avatar": "https://s3.amazonaws.com/uifaces/faces/twitter/thinkleft/128.jpg",
          "last_name": "Rogahn",
          "phone": "(990) 029-3886 x52967",
          "email": "Aaron46@gmail.com",
          "weeks": 48,
          "rating": "rating 32",
          "price": "96020065",
          "status": 83,
          "matched": 59,
          "seen": 68,
          "offers": 36,
          "linked_contacts": 89,
          "last_contacted": "2020-07-13T18:25:48.880Z"
        }
      ],
      "links": {
        "first": "http://auxatia.serveousercontent.com/api/v1/contact?page=1",
        "last": "http://auxatia.serveousercontent.com/api/v1/contact?page=104090",
        "prev": null,
        "next": "http://auxatia.serveousercontent.com/api/v1/contact?page=2"
      },
      "meta": {
        "current_page": 1,
        "from": 1,
        "last_page": 104090,
        "path": "http://auxatia.serveousercontent.com/api/v1/contact",
        "per_page": 15,
        "to": 15,
        "total": 1561349
      }
    });

  mock.onGet('/locations').reply(function (config) {
    console.log(config)

    const locations = stateOptions.filter(i => i.label.toLowerCase().includes(config.params.searchText.toLowerCase()));
    console.log(locations);
    return [200, locations];

    // if (config.params.searchText === 'Bob') { // or check for deep equality with config.params
    //   return [200, 'Hello'];
    // } else {
    //   return [400];
    // }
  });

  // Buyer Modal Details


  mock.onGet('/buyer/modal/details').reply(function (config) {

    const locations = stateOptions.filter(i => i.label.toLowerCase().includes(config.params.searchText.toLowerCase()));
    return [200, locations];

  });

  mock.onGet('/buyer/modal/matchedInfo').reply(200,
    {
      "data": [
        {
          "id": "1",
          "createdAt": "2020-07-19T13:11:19.743Z",
          "address": "63315 Vanessa Port",
          "suburb": "Gleichnershire",
          "postcode": "21385-5041",
          "price": "62271845",
          "bed": 25,
          "washroom": 84,
          "garage": 96,
          "area": "378.00",
          "status": 42,
          "team": "Dr."
        },
        {
          "id": "2",
          "createdAt": "2020-07-20T05:16:14.472Z",
          "address": "2852 Rippin Highway",
          "suburb": "New Clemens",
          "postcode": "74784-8014",
          "price": "17755825",
          "bed": 38,
          "washroom": 99,
          "garage": 62,
          "area": "814.00",
          "status": 82,
          "team": "Ms."
        },
        {
          "id": "3",
          "createdAt": "2020-07-20T02:21:27.227Z",
          "address": "896 Wisoky Throughway",
          "suburb": "Marlenton",
          "postcode": "12539",
          "price": "55489105",
          "bed": 49,
          "washroom": 27,
          "garage": 72,
          "area": "368.00",
          "status": 65,
          "team": "Dr."
        },
        {
          "id": "4",
          "createdAt": "2020-07-20T04:29:07.875Z",
          "address": "40195 Sheila Knoll",
          "suburb": "Nickolasside",
          "postcode": "55192-1857",
          "price": "81797882",
          "bed": 13,
          "washroom": 36,
          "garage": 3,
          "area": "815.00",
          "status": 77,
          "team": "Miss"
        },
        {
          "id": "5",
          "createdAt": "2020-07-20T06:46:26.808Z",
          "address": "81585 Crist Via",
          "suburb": "Darrionview",
          "postcode": "94865-1301",
          "price": "71054445",
          "bed": 72,
          "washroom": 44,
          "garage": 83,
          "area": "900.00",
          "status": 10,
          "team": "Dr."
        },
        {
          "id": "6",
          "createdAt": "2020-07-19T08:50:45.990Z",
          "address": "66591 Kassulke Court",
          "suburb": "North Cloydmouth",
          "postcode": "16862-5824",
          "price": "89595535",
          "bed": 66,
          "washroom": 44,
          "garage": 61,
          "area": "904.00",
          "status": 14,
          "team": "Miss"
        },
        {
          "id": "7",
          "createdAt": "2020-07-19T09:41:55.823Z",
          "address": "620 Ivah Gardens",
          "suburb": "North Aurore",
          "postcode": "89932",
          "price": "63537928",
          "bed": 61,
          "washroom": 15,
          "garage": 39,
          "area": "366.00",
          "status": 91,
          "team": "Miss"
        },
        {
          "id": "8",
          "createdAt": "2020-07-19T20:39:45.049Z",
          "address": "961 Jakubowski Neck",
          "suburb": "Lake Cleveland",
          "postcode": "82164",
          "price": "38106178",
          "bed": 63,
          "washroom": 26,
          "garage": 82,
          "area": "503.00",
          "status": 63,
          "team": "Dr."
        },
        {
          "id": "9",
          "createdAt": "2020-07-19T21:29:08.289Z",
          "address": "2100 Abbie Shoals",
          "suburb": "Georgianaview",
          "postcode": "87776",
          "price": "98474687",
          "bed": 51,
          "washroom": 30,
          "garage": 19,
          "area": "688.00",
          "status": 43,
          "team": "Mrs."
        },
        {
          "id": "10",
          "createdAt": "2020-07-19T18:47:37.984Z",
          "address": "675 Lesch Loop",
          "suburb": "Doyleview",
          "postcode": "14473",
          "price": "42890256",
          "bed": 18,
          "washroom": 75,
          "garage": 80,
          "area": "896.00",
          "status": 24,
          "team": "Mrs."
        },
        {
          "id": "11",
          "createdAt": "2020-07-19T20:47:56.380Z",
          "address": "83613 O'Hara Passage",
          "suburb": "Maggioside",
          "postcode": "22774",
          "price": "89889067",
          "bed": 84,
          "washroom": 61,
          "garage": 23,
          "area": "637.00",
          "status": 94,
          "team": "Dr."
        },
        {
          "id": "12",
          "createdAt": "2020-07-20T00:11:28.333Z",
          "address": "2268 Ritchie Forge",
          "suburb": "Naomiburgh",
          "postcode": "41478",
          "price": "82658015",
          "bed": 0,
          "washroom": 53,
          "garage": 25,
          "area": "525.00",
          "status": 72,
          "team": "Ms."
        },
        {
          "id": "13",
          "createdAt": "2020-07-19T23:29:49.827Z",
          "address": "8006 Ryann Roads",
          "suburb": "North Damian",
          "postcode": "66737-6624",
          "price": "75681704",
          "bed": 25,
          "washroom": 47,
          "garage": 23,
          "area": "9.00",
          "status": 78,
          "team": "Ms."
        },
        {
          "id": "14",
          "createdAt": "2020-07-19T09:28:24.245Z",
          "address": "4585 Constantin Inlet",
          "suburb": "North Juvenal",
          "postcode": "85282",
          "price": "35074282",
          "bed": 58,
          "washroom": 55,
          "garage": 16,
          "area": "30.00",
          "status": 20,
          "team": "Mrs."
        },
        {
          "id": "15",
          "createdAt": "2020-07-19T13:26:36.691Z",
          "address": "4517 Murphy Light",
          "suburb": "Lake Molly",
          "postcode": "94622",
          "price": "87332357",
          "bed": 50,
          "washroom": 94,
          "garage": 27,
          "area": "140.00",
          "status": 40,
          "team": "Ms."
        },
        {
          "id": "16",
          "createdAt": "2020-07-19T07:48:36.691Z",
          "address": "3512 Abbott Roads",
          "suburb": "North Ursula",
          "postcode": "29695-9309",
          "price": "81300122",
          "bed": 22,
          "washroom": 32,
          "garage": 26,
          "area": "807.00",
          "status": 5,
          "team": "Dr."
        },
        {
          "id": "17",
          "createdAt": "2020-07-19T17:16:13.269Z",
          "address": "8019 Hilpert Trail",
          "suburb": "New Gunnarport",
          "postcode": "57788",
          "price": "44267462",
          "bed": 34,
          "washroom": 100,
          "garage": 8,
          "area": "308.00",
          "status": 7,
          "team": "Mr."
        },
        {
          "id": "18",
          "createdAt": "2020-07-19T18:51:13.259Z",
          "address": "20942 Tess Plains",
          "suburb": "Terrytown",
          "postcode": "02329",
          "price": "37796582",
          "bed": 14,
          "washroom": 70,
          "garage": 25,
          "area": "24.00",
          "status": 46,
          "team": "Miss"
        },
        {
          "id": "19",
          "createdAt": "2020-07-19T08:43:18.286Z",
          "address": "171 Ena View",
          "suburb": "Susanhaven",
          "postcode": "86240",
          "price": "83096866",
          "bed": 91,
          "washroom": 35,
          "garage": 56,
          "area": "522.00",
          "status": 40,
          "team": "Mrs."
        },
        {
          "id": "20",
          "createdAt": "2020-07-19T22:49:42.060Z",
          "address": "816 Barton Falls",
          "suburb": "Reichertburgh",
          "postcode": "65389-3209",
          "price": "87924334",
          "bed": 19,
          "washroom": 46,
          "garage": 77,
          "area": "445.00",
          "status": 1,
          "team": "Mr."
        },
        {
          "id": "21",
          "createdAt": "2020-07-19T16:08:09.682Z",
          "address": "1073 Patience Stravenue",
          "suburb": "Hansbury",
          "postcode": "19838",
          "price": "98974743",
          "bed": 75,
          "washroom": 90,
          "garage": 95,
          "area": "393.00",
          "status": 38,
          "team": "Mrs."
        },
        {
          "id": "22",
          "createdAt": "2020-07-19T18:10:51.915Z",
          "address": "1692 Rickey Mountain",
          "suburb": "New Walterview",
          "postcode": "67633-5372",
          "price": "30303368",
          "bed": 83,
          "washroom": 8,
          "garage": 90,
          "area": "295.00",
          "status": 36,
          "team": "Mr."
        },
        {
          "id": "23",
          "createdAt": "2020-07-19T20:11:11.181Z",
          "address": "3665 Sydni Stravenue",
          "suburb": "South Zachery",
          "postcode": "58959",
          "price": "84126407",
          "bed": 55,
          "washroom": 50,
          "garage": 46,
          "area": "239.00",
          "status": 67,
          "team": "Mrs."
        },
        {
          "id": "24",
          "createdAt": "2020-07-19T17:16:10.916Z",
          "address": "10444 Hackett Terrace",
          "suburb": "Baumbachland",
          "postcode": "66274-5486",
          "price": "74648580",
          "bed": 64,
          "washroom": 47,
          "garage": 53,
          "area": "429.00",
          "status": 13,
          "team": "Mrs."
        },
        {
          "id": "25",
          "createdAt": "2020-07-19T12:38:13.266Z",
          "address": "2030 Spinka Well",
          "suburb": "Arnebury",
          "postcode": "37278-7839",
          "price": "96781439",
          "bed": 56,
          "washroom": 88,
          "garage": 58,
          "area": "817.00",
          "status": 27,
          "team": "Mrs."
        },
        {
          "id": "26",
          "createdAt": "2020-07-19T07:46:31.100Z",
          "address": "415 Upton Ways",
          "suburb": "South Jevon",
          "postcode": "22668-7759",
          "price": "50551553",
          "bed": 29,
          "washroom": 82,
          "garage": 17,
          "area": "205.00",
          "status": 37,
          "team": "Mr."
        },
        {
          "id": "27",
          "createdAt": "2020-07-19T10:04:13.509Z",
          "address": "81243 Walsh Road",
          "suburb": "Moorefort",
          "postcode": "03611",
          "price": "70785221",
          "bed": 59,
          "washroom": 53,
          "garage": 80,
          "area": "675.00",
          "status": 20,
          "team": "Mrs."
        },
        {
          "id": "28",
          "createdAt": "2020-07-19T20:01:56.123Z",
          "address": "441 Lorenz Drive",
          "suburb": "Legroshaven",
          "postcode": "29884-3693",
          "price": "61863556",
          "bed": 67,
          "washroom": 94,
          "garage": 48,
          "area": "677.00",
          "status": 87,
          "team": "Dr."
        },
        {
          "id": "29",
          "createdAt": "2020-07-20T01:50:38.751Z",
          "address": "430 Mitchell Loaf",
          "suburb": "Daretown",
          "postcode": "52484",
          "price": "38258498",
          "bed": 11,
          "washroom": 50,
          "garage": 88,
          "area": "950.00",
          "status": 24,
          "team": "Ms."
        },
        {
          "id": "30",
          "createdAt": "2020-07-20T06:49:04.814Z",
          "address": "297 Towne Highway",
          "suburb": "Waelchiburgh",
          "postcode": "48241",
          "price": "22437928",
          "bed": 41,
          "washroom": 95,
          "garage": 26,
          "area": "176.00",
          "status": 36,
          "team": "Mr."
        },
        {
          "id": "31",
          "createdAt": "2020-07-19T09:28:52.903Z",
          "address": "41441 Leuschke Passage",
          "suburb": "West Cesar",
          "postcode": "82648",
          "price": "03155320",
          "bed": 39,
          "washroom": 61,
          "garage": 1,
          "area": "337.00",
          "status": 47,
          "team": "Miss"
        },
        {
          "id": "32",
          "createdAt": "2020-07-19T13:07:41.774Z",
          "address": "22110 Arnaldo Path",
          "suburb": "New Eino",
          "postcode": "76781",
          "price": "60328513",
          "bed": 22,
          "washroom": 62,
          "garage": 31,
          "area": "719.00",
          "status": 19,
          "team": "Dr."
        },
        {
          "id": "33",
          "createdAt": "2020-07-19T20:35:40.330Z",
          "address": "16615 Ziemann Lodge",
          "suburb": "Kshlerinside",
          "postcode": "98114-9974",
          "price": "81996031",
          "bed": 92,
          "washroom": 87,
          "garage": 79,
          "area": "483.00",
          "status": 80,
          "team": "Mr."
        },
        {
          "id": "34",
          "createdAt": "2020-07-19T12:18:14.796Z",
          "address": "1268 Abernathy Valleys",
          "suburb": "Port Christellehaven",
          "postcode": "69568",
          "price": "69383398",
          "bed": 28,
          "washroom": 91,
          "garage": 85,
          "area": "722.00",
          "status": 66,
          "team": "Mr."
        },
        {
          "id": "35",
          "createdAt": "2020-07-19T10:54:31.487Z",
          "address": "6390 Walter Cliff",
          "suburb": "South Hassanmouth",
          "postcode": "32812-9026",
          "price": "80199273",
          "bed": 18,
          "washroom": 14,
          "garage": 94,
          "area": "587.00",
          "status": 74,
          "team": "Miss"
        },
        {
          "id": "36",
          "createdAt": "2020-07-19T18:47:53.349Z",
          "address": "570 Braun Mall",
          "suburb": "Johnnyshire",
          "postcode": "88439",
          "price": "20942573",
          "bed": 59,
          "washroom": 5,
          "garage": 56,
          "area": "710.00",
          "status": 12,
          "team": "Mr."
        },
        {
          "id": "37",
          "createdAt": "2020-07-20T03:44:23.130Z",
          "address": "362 Miller Stream",
          "suburb": "Kossmouth",
          "postcode": "46613-3559",
          "price": "81585319",
          "bed": 92,
          "washroom": 35,
          "garage": 53,
          "area": "435.00",
          "status": 57,
          "team": "Dr."
        },
        {
          "id": "38",
          "createdAt": "2020-07-19T21:56:40.434Z",
          "address": "59300 Lindsay Station",
          "suburb": "South Robertaton",
          "postcode": "13758-9618",
          "price": "19515509",
          "bed": 14,
          "washroom": 85,
          "garage": 32,
          "area": "868.00",
          "status": 34,
          "team": "Mrs."
        },
        {
          "id": "39",
          "createdAt": "2020-07-20T03:39:24.794Z",
          "address": "32370 Fisher Trail",
          "suburb": "Raphaelleland",
          "postcode": "90997",
          "price": "15058483",
          "bed": 72,
          "washroom": 6,
          "garage": 4,
          "area": "319.00",
          "status": 40,
          "team": "Ms."
        },
        {
          "id": "40",
          "createdAt": "2020-07-19T11:46:59.269Z",
          "address": "386 Pacocha Union",
          "suburb": "Lake Haley",
          "postcode": "50274-4260",
          "price": "51109580",
          "bed": 61,
          "washroom": 61,
          "garage": 40,
          "area": "325.00",
          "status": 7,
          "team": "Mr."
        },
        {
          "id": "41",
          "createdAt": "2020-07-19T12:49:06.532Z",
          "address": "1850 Powlowski Loop",
          "suburb": "Hilpertburgh",
          "postcode": "95510",
          "price": "80947699",
          "bed": 19,
          "washroom": 98,
          "garage": 79,
          "area": "392.00",
          "status": 1,
          "team": "Mr."
        },
        {
          "id": "42",
          "createdAt": "2020-07-20T07:08:43.517Z",
          "address": "406 Padberg Neck",
          "suburb": "Lake Jeremie",
          "postcode": "66352",
          "price": "17124416",
          "bed": 3,
          "washroom": 60,
          "garage": 64,
          "area": "668.00",
          "status": 36,
          "team": "Ms."
        },
        {
          "id": "43",
          "createdAt": "2020-07-19T15:20:15.311Z",
          "address": "264 Omer Green",
          "suburb": "Port Mohamedburgh",
          "postcode": "55794-8563",
          "price": "26531748",
          "bed": 39,
          "washroom": 19,
          "garage": 33,
          "area": "692.00",
          "status": 91,
          "team": "Ms."
        },
        {
          "id": "44",
          "createdAt": "2020-07-19T23:30:21.674Z",
          "address": "29080 Alfonso Hill",
          "suburb": "Mableton",
          "postcode": "31045-8957",
          "price": "96502448",
          "bed": 38,
          "washroom": 37,
          "garage": 79,
          "area": "65.00",
          "status": 89,
          "team": "Mr."
        },
        {
          "id": "45",
          "createdAt": "2020-07-20T01:08:46.937Z",
          "address": "8227 Bogan Parkways",
          "suburb": "East Lonville",
          "postcode": "84231",
          "price": "69264675",
          "bed": 66,
          "washroom": 41,
          "garage": 97,
          "area": "204.00",
          "status": 98,
          "team": "Ms."
        },
        {
          "id": "46",
          "createdAt": "2020-07-19T21:30:13.712Z",
          "address": "7560 Helmer Valleys",
          "suburb": "East Kassandra",
          "postcode": "41982-9235",
          "price": "15288027",
          "bed": 15,
          "washroom": 39,
          "garage": 23,
          "area": "530.00",
          "status": 91,
          "team": "Miss"
        },
        {
          "id": "47",
          "createdAt": "2020-07-19T10:19:10.799Z",
          "address": "370 Padberg Neck",
          "suburb": "Cummerataport",
          "postcode": "20425-3961",
          "price": "27847066",
          "bed": 7,
          "washroom": 21,
          "garage": 31,
          "area": "486.00",
          "status": 12,
          "team": "Mr."
        },
        {
          "id": "48",
          "createdAt": "2020-07-19T21:55:22.300Z",
          "address": "981 Hal Turnpike",
          "suburb": "Jerdeton",
          "postcode": "59800-9021",
          "price": "74449285",
          "bed": 48,
          "washroom": 64,
          "garage": 25,
          "area": "866.00",
          "status": 26,
          "team": "Ms."
        },
        {
          "id": "49",
          "createdAt": "2020-07-19T16:27:12.799Z",
          "address": "308 Alena Flat",
          "suburb": "Kunzeland",
          "postcode": "67467",
          "price": "32133268",
          "bed": 87,
          "washroom": 38,
          "garage": 73,
          "area": "594.00",
          "status": 40,
          "team": "Dr."
        },
        {
          "id": "50",
          "createdAt": "2020-07-19T18:03:39.175Z",
          "address": "09928 Billy Crossing",
          "suburb": "Lake Lexus",
          "postcode": "82286",
          "price": "94689567",
          "bed": 82,
          "washroom": 94,
          "garage": 18,
          "area": "78.00",
          "status": 55,
          "team": "Mr."
        }
      ],
      "links": {
        "first": "http://auxatia.serveousercontent.com/api/v1/contact?page=1",
        "last": "http://auxatia.serveousercontent.com/api/v1/contact?page=104090",
        "prev": null,
        "next": "http://auxatia.serveousercontent.com/api/v1/contact?page=2"
      },
      "meta": {
        "current_page": 1,
        "from": 1,
        "last_page": 104090,
        "path": "http://auxatia.serveousercontent.com/api/v1/contact",
        "per_page": 15,
        "to": 15,
        "total": 1561349
      }
    });

  mock.onGet('/contacts/timeline').reply(function (config) {

    let userList = [];
    const list = ["oneToOne",
      "buyer",
      "general",
      "listing",
      "openHome",
      "marketReview",
      "rental",
      "rentalOpenHome",
      "sellerVisit",
      "tenant",
      "virtualListing",
      "virtualBuyer",
      "brand",
      "darkRed",
      "darkGrey",
      "darkBlue",
      "lightBlue",
      "green",
      "lightGrey",
      "landlord",
      "prospect",
      "seller",
      "servicer"];

    for (var i = 0; i < 20; i++) {
      userList.push({
        date: faker.date.past(),
        type: list[Math.floor(Math.random() * list.length)],
        data: [
          {
            listing: faker.lorem.sentence(),
            opinion: faker.finance.amount(),
            rating: faker.random.number(),
            comments: faker.lorem.sentences(),
            date: faker.date.past(),

          }, {
            listing: faker.lorem.sentence(),
            opinion: faker.finance.amount(),
            rating: faker.random.number(),
            comments: faker.lorem.sentences(),
            date: faker.date.past(),
          }
        ]
      });
    }

    // const locations = stateOptions.filter(i => i.label.toLowerCase().includes(config.params.searchText.toLowerCase()));
    return [200, userList];

  });

  mock.onGet('/prospects').reply(function (config) {

    let userList = [];

    for (var i = 0; i < 20; i++) {
      userList.push({
        suburb: faker.address.state(),
        street_name: faker.address.streetName(),
        street_number: faker.random.number({ "max": 100 }),
        unit: faker.random.number({ "max": 100 }),
        contact: faker.name.firstName() + " " + faker.name.lastName(),
        phone: faker.phone.phoneNumber(),
        price: faker.random.number({
          'min': 500000,
          'max': 5000000
        }),
        status: {
          call: faker.random.boolean(),
          house: faker.random.boolean(),
          key: faker.random.boolean(),
        },
        date: faker.date.past(),

        note: faker.lorem.sentences(),
      });
    }

    // const locations = stateOptions.filter(i => i.label.toLowerCase().includes(config.params.searchText.toLowerCase()));
    return [200, userList];

  });

  mock.onGet('/addressList').reply(function (config) {
    console.log(config)
    let data = [];
    for (var i = 0; i < getRndInteger(25, 150); i++) {
      if (config.params.suburb && config.params.streets && config.params.streetNo) {
        data.push(getRndInteger(1, 100)); //Unit
      }
      else if (config.params.suburb && config.params.streets && !config.params.streetNo) {
        data.push(getRndInteger(1, 150)); //Street No
      }
      else if (config.params.suburb && !config.params.streets && !config.params.streetNo) {
        data.push(faker.address.streetName());
      } else {
        data.push(faker.address.state());
      }
    }
    console.log(data);
    return [200, data];
  });


  mock.onGet('/zoneAnalysis').reply(function (config) {
    console.log(config)

    let data = [];
    for (var i = 1; i < getRndInteger(50, 150); i++) {

      let suburb = faker.address.state();
      for (var k = 1; k < getRndInteger(1, 5); k++) {
        data.push({
          zone: suburb + " " + k,
          team: config?.params?.options ? config.params.options : team[Math.floor(Math.random() * team.length)],
          street_number: getRndInteger(15, 70),
          properties: getRndInteger(800, 9000),
          contacted: getRndInteger(0, 300),
          listed: {
            min: getRndInteger(15, 70),
            max: getRndInteger(70, 100)
          },
          sold: {
            min: getRndInteger(0, 5),
            max: getRndInteger(0, 25)
          },
          buyers: getRndInteger(150, 2000)
        });
      }
    }


    return [200, data];
  });

  mock.onGet('/zoneAnalysis/edit').reply(function (config) {
    console.log(config)
    const teamOptions = [
      {
        label: 'All',
        value: '1',
      },
      {
        label: 'ALB',
        value: 'alb',
      },
      {
        label: 'KP',
        value: 'kp',
      },
    ];
    const team = ["ALB", "KP", "CL", "BP"]
    const sign = ["Sold", "For Sale",]
    const company = ["Ray White", "Lj Hoocker", "Purple Briks", "NDP"]
    let data = [];

    let suburb = []

    for (var k = 1; k < getRndInteger(5, 10); k++) {
      suburb.push({
        suburb: faker.address.state(),
      });
    }

    let streetName = []

    for (k = 1; k < getRndInteger(5, 10); k++) {
      streetName.push({
        streetName: faker.address.streetName(),
      });
    }


    let streets = [];

    for (k = 1; k < getRndInteger(15, 50); k++) {
      streets.push({
        id: k,
        street: faker.address.streetName(),
        properties: getRndInteger(7, 100),
        contacted: getRndInteger(0, 10),
      });
    }



    let signs = [];

    for (k = 1; k < getRndInteger(15, 50); k++) {
      signs.push({
        id: k,
        date: faker.date.past(),
        address: faker.address.streetAddress(),
        company: company[Math.floor(Math.random() * team.length)],
        price: getRndInteger(700000, 10000000),
        sign: sign[Math.floor(Math.random() * sign.length)],
      });
    }

    let zone = {
      name: config?.params?.zone ? config.params.zone : 'no name',
      team: teamOptions,
      signs: signs,
      streets: streets,
      streetName: streetName,
      suburb: suburb

    }

    console.log(zone);
    return [200, zone];
  });

  mock.onGet('/diary/tasks').reply(function (config) {
    console.log(config)
    const Appts = ["oneToOne",
      "buyer",
      "general",
      "listing",
      "openHome",
      "marketReview",
      "rentalOpenHome",
      "sellerVisit",
      "tenant",
      "virtualListing",
      "virtualBuyer"]

    const tasks = ["Buyers to Call", "Contacts to call", "Listings Checklist", "Prospects to Call", "To Do"]
    let data = [];
    var now = config.params?.current_month ? new Date(config.params.current_month) : new Date();
    console.log(now)

    console.log(config.params?.current_month)

    if (config.params?.diaryType === 'tasks') {
      for (var i = 0; i < getRndInteger(100, 150); i++) {
        let date = faker.date.between(new Date(now.getFullYear(), now.getMonth(), 1), new Date(now.getFullYear(), now.getMonth() + 1, 0))
        data.push({
          id: i,
          category: tasks[Math.floor(Math.random() * tasks.length)],
          title: "Call " + faker.name.firstName() + " " + faker.address.streetAddress(),
          start: date,
          end: date,
        })
      }
    }
    else {
      for (var i = 0; i < getRndInteger(100, 150); i++) {
        let date = faker.date.between(new Date(now.getFullYear(), now.getMonth(), 1), new Date(now.getFullYear(), now.getMonth() + 1, 0))
        data.push({
          id: i,
          category: Appts[Math.floor(Math.random() * Appts.length)],
          title: faker.address.streetAddress(),
          start: date,
          end: new Date(new Date(date).setHours(date.getHours() + 4)),
        })
      }
    }

    console.log(data);
    return [200, data];
  });

  mock.onGet('/listing/details').reply(function (config) {
    console.log(config)

    let Listings = [];

    for (var i = 0; i < faker.random.number({
      'min': 5,
      'max': 15
    }); i++) {
      Listings.push({
        id: i,
        report: {
          isReportUploaded: faker.random.boolean(),
          title: faker.lorem.sentence(),
        },
        date: faker.date.recent(),
        companyName: faker.company.companyName(),
        preparedBy: faker.name.firstName() + " " + faker.name.lastName(),
        requestedBy: faker.name.firstName() + " " + faker.name.lastName(),
        ins: faker.random.boolean(),
        rjp: faker.random.boolean(),
        note: faker.lorem.sentences(),
      });
    }

    console.log(Listings);
    return [200, Listings];
  });

  mock.onGet('/listing/report').reply(function (config) {
    console.log(config)

    let Listings = {
      type: {
        label: "Sarah",
        value: "3",
      },
      date_conducted: faker.date.recent(),
      companyName: faker.company.companyName(),
      preparedBy: faker.name.firstName() + " " + faker.name.lastName(),
      requestedBy: faker.name.firstName() + " " + faker.name.lastName(),
      pi: faker.random.boolean(),
      rp: faker.random.boolean(),
      note: faker.lorem.sentences(),
    };




    console.log(Listings);
    return [200, Listings];
  });



  mock.onGet('/listing/checklist').reply(function (config) {
    console.log(config)
    let Listings = [];

    for (var i = 0; i < faker.random.number({
      'min': 2,
      'max': 4
    }); i++) {

      let subTask = []

      for (var k = 0; k < getRndInteger(0, 5); k++) {
        subTask.push(faker.lorem.sentence());
      }


      Listings.push({
        id: faker.random.uuid(),
        priority: faker.random.number({
          'min': 1,
          'max': 5
        }),
        task: {
          task: faker.lorem.sentence(),
          subTask: subTask,
        },

        date: faker.date.between(2020, 2021),
        allocation: "Listing Agent",

      });
    }
    console.log(Listings);
    // const locations = stateOptions.filter(i => i.label.toLowerCase().includes(config.params.searchText.toLowerCase()));
    return [200, Listings];

  });


  mock.onGet('/listing/checkList/settings').reply(function (config) {
    console.log(config)
    let Listings = [];

    for (var i = 0; i < faker.random.number({
      'min': 10,
      'max': 15
    }); i++) {

      let subTask = []

      for (var k = 0; k < getRndInteger(0, 5); k++) {
        subTask.push(faker.lorem.sentence());
      }


      Listings.push({
        id: faker.random.uuid(),
        priority: faker.random.number({
          'min': 1,
          'max': 5
        }),
        task: {
          task: faker.lorem.sentence(),
          subTask: subTask,
        },
        allocation: "Listing Agent",
        when: {
          number: faker.random.number(30),
          Timetype: 'Hours',
          position: 'Afer',
          Type: 'Listing',
        },

      });
    }
    console.log(Listings);
    // const locations = stateOptions.filter(i => i.label.toLowerCase().includes(config.params.searchText.toLowerCase()));
    return [200, Listings];

  });


  mock.onGet('/listing/advertising').reply(function (config) {
    console.log(config)
    let Listings = [];

    for (var i = 0; i < faker.random.number({
      'min': 5,
      'max': 10
    }); i++) {
      Listings.push({
        id: faker.random.uuid(),
        advertisingItem: faker.lorem.sentence(),
        price: faker.random.number({
          'min': 200,
          'max': 500
        }),
        completed: faker.date.recent(),
      });
    }
    console.log(Listings);
    // const locations = stateOptions.filter(i => i.label.toLowerCase().includes(config.params.searchText.toLowerCase()));
    return [200, Listings];

  });
  
  mock.onGet('/listing/advertising/selectable').reply(function (config) {
    console.log(config)
    let Listings = [];

    for (var i = 0; i < faker.random.number({
      'min': 30,
      'max': 100
    }); i++) {
      Listings.push({
        id: faker.random.uuid(),
        advertisingItem: faker.lorem.sentence(),
        price: faker.random.number({
          'min': 200,
          'max': 500
        }),
        completed: null,
      });
    }
    console.log(Listings);
    // const locations = stateOptions.filter(i => i.label.toLowerCase().includes(config.params.searchText.toLowerCase()));
    return [200, Listings];

  });

  mock.onGet('/contacts').reply(function (config) {
    console.log(config)
    let Listings = [];
  
    for (var i = 0; i < faker.random.number({
      'min': 30,
      'max': 100
    }); i++) {
      Listings.push({
        id: i,
      address: faker.address.streetAddress(),
      full_name: faker.name.findName(),
      phone: faker.phone.phoneNumber(),
      email: {
        isUnsubscribed: faker.random.boolean(),
        email:faker.internet.email(),
      },
      linked_contacts: faker.random.number({
        'min': 0,
        'max': 5
      }),
      last_contact: {
        date: faker.date.between(2020, 2021),
        flag:  faker.random.boolean(),
      },
      type: faker.random.boolean(),
  
  
      });
    }
    console.log(Listings);
    // const locations = stateOptions.filter(i => i.label.toLowerCase().includes(config.params.searchText.toLowerCase()));
    return [200, Listings];
  
  });

  mock.onGet('/tenants').reply(function (config) {
    console.log(config)
    let Listings = [];
  
    for (var i = 0; i < faker.random.number({
      'min': 30,
      'max': 100
    }); i++) {
      Listings.push({
        id: faker.random.uuid(),
      address: faker.address.streetAddress(),
      full_name: faker.name.findName(),
      phone: faker.phone.phoneNumber(),
      email: {
        isUnsubscribed: faker.random.boolean(),
        email:faker.internet.email(),
      },
      linked_contacts: faker.random.number({
        'min': 0,
        'max': 5
      }),
      last_contact: {
        date: faker.date.between(2020, 2021),
        flag:  faker.random.boolean(),
      },
      type: faker.random.boolean(),
  
  
      });
    }
    console.log(Listings);
    // const locations = stateOptions.filter(i => i.label.toLowerCase().includes(config.params.searchText.toLowerCase()));
    return [200, Listings];
  
  });


  mock.onGet('/tenant/matched-info').reply(function (config) {
    console.log(config)
    let Listings = [];
  
    for (var i = 0; i < faker.random.number({
      'min': 30,
      'max': 100
    }); i++) {
      Listings.push({
      id: faker.random.uuid(),
      address: faker.address.streetAddress(),
      suburb: faker.address.city(),
      postcode: faker.address.zipCode(),
      rent: faker.random.number({
        'min': 400,
        'max': 600
      }),
      bed: faker.random.number({
        'min': 1,
        'max': 4
      }),
      toilet: faker.random.number({
        'min': 1,
        'max': 2
      }),
      parking: faker.random.number({
        'min': 1,
        'max': 3
      }),
      petAllowed: faker.random.boolean(),
      type: faker.random.boolean(),
      linked_contacts: faker.random.number({
        'min': 0,
        'max': 5
      }),
      last_contact: {
        date: faker.date.between(2020, 2021),
        flag:  faker.random.boolean(),
      },
      status: {
        emailSent: faker.random.boolean(),
        isVacant: faker.random.boolean(),
      },
      team: faker.name.title(),
  
  
      });
    }
    console.log(Listings);
    // const locations = stateOptions.filter(i => i.label.toLowerCase().includes(config.params.searchText.toLowerCase()));
    return [200, Listings];
  
  });
  


  mock.onGet('/listings').reply(function (config) {
    console.log(config)
    let Listings = [];

    for (var i = 0; i < faker.random.number({
      'min': 50,
      'max': 100
    }); i++) {
      Listings.push({
        id: i,
        listingType: config.params?.listingType ? config.params.listingType.label : 'Current',
        address: {
          address: faker.address.streetAddress(),
          isNew: faker.random.boolean(),
          date: faker.date.between(2020, 2021)
        },
        suburb: faker.address.state(),
        postcode: faker.random.number({ "max": 100 }),
        priceRange: faker.random.number({
          'min': 400,
          'max': 600
        }) + "K - " + faker.random.number({
          'min': 600,
          'max': 900
        }) + "K",
        bed: faker.random.number({
          'min': 1,
          'max': 4
        }),
        toilet: faker.random.number({
          'min': 1,
          'max': 2
        }),
        parking: faker.random.number({
          'min': 1,
          'max': 3
        }),
        land: faker.random.number({
          'min': 400,
          'max': 900
        }) + " m2",
        team: Math.floor(Math.random() * team.length),
        status: {
          hasMarketingMaterials: faker.random.boolean(),
          isVacant: faker.random.boolean(),
          isSaleAgreed: faker.random.boolean(),
          isConditional: faker.random.boolean(),
          isLiveOnWebsite: faker.random.boolean(),
        },
        contact: {
          fullName: faker.name.firstName() + " " + faker.name.lastName(),
          isTenanted: faker.random.boolean(),
          multipleContact: faker.random.number({
            'min': 1,
            'max': 10
          }),
        },
        phone: faker.phone.phoneNumber(),
        mt: faker.random.number({
          'min': 1,
          'max': 10
        }),
      });
    }
    console.log(Listings);
    // const locations = stateOptions.filter(i => i.label.toLowerCase().includes(config.params.searchText.toLowerCase()));
    return [200, Listings];

  });

  mock.onPost('/mail/34').reply(function (config) {
    console.log(config)
    let Listings = [];

    for (var i = 0; i < faker.random.number({
      'min': 30,
      'max': 100
    }); i++) {
      Listings.push({
        id: faker.random.uuid(),
        advertisingItem: faker.lorem.sentence(),
        price: faker.random.number({
          'min': 200,
          'max': 500
        }),
        completed: null,
      });
    }
    console.log(Listings);
    // const locations = stateOptions.filter(i => i.label.toLowerCase().includes(config.params.searchText.toLowerCase()));
    return [200, Listings];

  });

  mock.onGet('/mail/34').reply(function (config) {
    console.log(config)
    let Listings = {
      "id": "17",
      "createdAt": "2020-07-06T15:55:56.441Z",
      "date": "2020-02-18T09:32:10.763Z",
      "from": "Edmund Brown",
      "type": 49,
      "subject": faker.lorem.sentence(),
      "body": "We need to hack the wireless XML firewall!"
    };

    console.log(Listings);
    // const locations = stateOptions.filter(i => i.label.toLowerCase().includes(config.params.searchText.toLowerCase()));
    return [200, Listings];

  });

  // mock.onGet('/mail/34', { params: { searchText: "John" } }).reply(200,
  //   {
  //     "id": "17",
  //     "createdAt": "2020-07-06T15:55:56.441Z",
  //     "date": "2020-02-18T09:32:10.763Z",
  //     "from": "Edmund Brown",
  //     "type": 49,
  //     "subject": faker.lorem.sentence(),
  //     "body": "We need to hack the wireless XML firewall!"
  //   });




}




