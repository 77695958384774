import React, { useState, useEffect, useCallback } from "react";
import { Button, Modal, Form, Col, Row } from "react-bootstrap";
import EditorField from "../../../../../../../partials/form/editorField/EditorField";
import { reduxForm, Field, FieldArray } from "redux-form";
import { connect } from "react-redux";
import {
  combineValidators,
  isRequired,
  composeValidators,
  hasLengthGreaterThan,
} from "revalidate";
import TextInput from "../../../../../../../partials/form/TextInput.js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./_makeAnOffer.scss";
import MakeAnOfferItem from "./MakeAnOfferItem";

const validate = combineValidators({
  title: isRequired({ message: "The event title is required" }),
  category: isRequired({ message: "The category is required" }),
  description: composeValidators(
    isRequired({ message: "Please enter a description" }),
    hasLengthGreaterThan(4)({
      message: "Description needs to be at least 5 characters",
    })
  )(),
  city: isRequired("city"),
  venue: isRequired("venue"),
  checkbox: isRequired("checkbox"),
  startTime: isRequired("date"),
  endTime: isRequired("date"),
});

const MakeAnOffer = (props) => {
  const { handleSubmit, pristine, submitting, invalid, numSelected } = props;
  const [show, setShow] = useState(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const onFormSubmit = useCallback((values) => {
    console.log(values);
    // setShow(false);
  }, []);

  return (
    <>
      <Modal
        show={show}
        size="xl"
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="makeAnOffer"
      >
        <Modal.Body>
          <form className="contactForm" onSubmit={handleSubmit(onFormSubmit)}>
            <div className="d-flex justify-content-between pb-3">
              <div className="header-left d-flex w-100 justify-content-start m-auto">
                <h5 className="brand-color font-weight-bold">Make nd Offer</h5>
              </div>
              <div className="header-right d-flex w-100 justify-content-end">
                <div className="pr-2 ">
                  <Button
                    onClick={handleClose}
                    variant="contained"
                    className="theme-button lightGrey-button"
                  >
                    Cancel
                  </Button>
                </div>
                <div>
                  <Button
                    variant="contained"
                    className="theme-button green-button"
                    type="submit"
                    disabled={invalid || pristine || submitting}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>
            <FieldArray
              numSelected={numSelected}
              name="makeAnOffer"
              component={MakeAnOfferItem}
            />
          </form>

          <div className="row">
            <div className="col-md-4"></div>
            <div className="col-md-8"></div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const actions = {
  // socialFeedPost: news.actions.socialFeedPostAsync,
};
const mapState = (state) => ({
  // loading: state.async.loading,
  // buttonName: state.async.elementName,
});
export default connect(
  mapState,
  actions
)(reduxForm({ form: "tenantMakeAnOffer", validate })(MakeAnOffer));
