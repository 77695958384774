import { Popover } from "react-bootstrap";
import { OverlayTrigger } from "react-bootstrap";

import React from "react";

const ToolTipForSvg = ({
  name,
  content,
  onClick,
  justIcon,
  children,
  ...others
}) => {
  return (
    <div>
      <OverlayTrigger
        // trigger="hover"
        rootClose
        placement="bottom"
        overlay={
          <Popover className="tooltipContent" id="popover-basic">
            {content}
          </Popover>
        }
        children={<div className="p-1">{children}</div>}
      ></OverlayTrigger>
    </div>
  );
};

export default ToolTipForSvg;
