import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { LinearProgress, TextField } from "@material-ui/core";
import Swal from "sweetalert2";
import * as auth from "./_redux/auth.duck";
import { login } from "./curd/auth.crud"; // Login using actual api Information
import ThemeButton from "../../partials/button/ThemeButton";
import { createGuid } from "../../_helpers/_utils/utils";

function Login(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [displayHDO, setDisplayHDO] = useState(false);
  const urlParameter = new URL(window.location.href).searchParams;

  if (localStorage.getItem("wiseberry.trustedDevice.key") === null) {
    localStorage.setItem("wiseberry.trustedDevice.key", createGuid());
    localStorage.setItem("wiseberry.trustedDevice.created", new Date());
  }

  const trustedDeviceKey = localStorage.getItem("wiseberry.trustedDevice.key");

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const ssoAuthentiation = async (oauthToken = null) => {
    if (oauthToken) {
      const ssoResponse = await login(
        "oauthsso",
        "oauthsso",
        null,
        null,
        null,
        null,
        oauthToken
      ).then(({ data }) => {
        if (data?.access_token || data?.data?.access_token) {
          return data?.access_token || data?.data?.access_token;
        } else {
          return false;
        }
      });

      if (ssoResponse) {
        disableLoading();

        props.login(ssoResponse);
      } else {
        Swal.fire(
          "Authentication Failed",
          "Single Sign On Authentication Failed!",
          "error"
        );
      }
    }
  };

  if (urlParameter.has("auth_token")) {
    ssoAuthentiation(urlParameter.get("auth_token"));
    return (
      <LinearProgress
        classes={{
          colorPrimary: "brand-background", // class name, e.g. `classes-nesting-label-x`
          barColorPrimary: "darkRed-background", // class name, e.g. `classes-nesting-label-x`
        }}
      />
    );
  } else {
    return (
      <>
        <div className="kt-login__body">
          <div className="kt-login__form">
            <div className="kt-login__title">
              <h3>
                {/* https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage */}
                <FormattedMessage id="AUTH.LOGIN.TITLE" />
              </h3>
            </div>
            <div className="col text-center">
              <span
                onClick={() => setDisplayHDO(!displayHDO)}
                style={{ color: "#f1f1f1" }}
              >
                ~
              </span>
            </div>

            <Formik
              initialValues={{
                username: "",
                password: "",
                root: "",
              }}
              validate={(values) => {
                const errors = {};

                if (!values.username) {
                  errors.username = intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                  });
                }

                if (!values.password) {
                  errors.password = intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                  });
                }

                return errors;
              }}
              onSubmit={(values, { setStatus, setSubmitting }) => {
                enableLoading();
                login(
                  values.username,
                  values.password,
                  values.root,
                  trustedDeviceKey
                )
                  .then(({ data }) => {
                    if (data?.access_token || data?.data?.access_token) {
                      disableLoading();
                      props.login(
                        data?.access_token || data?.data?.access_token
                      );
                    } else if (
                      data?.mfa_code_sent ||
                      data?.data?.mfa_code_sent
                    ) {
                      Swal.fire({
                        title: "Multi-Factor Authentication",
                        html: `
                          <input type="text" id="mfa_code" class="swal2-input" placeholder="Security code">
                          <input type="checkbox" id="trust_this_device"> Trust this device for the next 14 days
                        `,
                        confirmButtonText: "Confirm",
                        focusConfirm: false,
                        preConfirm: () => {
                          const mfa_code = Swal.getPopup().querySelector(
                            "#mfa_code"
                          ).value;
                          const trust_this_device = Swal.getPopup().querySelector(
                            "#trust_this_device"
                          ).checked;
                          if (!mfa_code) {
                            Swal.showValidationMessage(`Please enter the code`);
                          }
                          return {
                            mfa_code: mfa_code,
                            trust_this_device: trust_this_device,
                          };
                        },
                      }).then((result) => {
                        if (result?.value?.mfa_code) {
                          login(
                            values.username,
                            values.password,
                            values.root,
                            trustedDeviceKey,
                            result.value.mfa_code,
                            result.value.trust_this_device
                          )
                            .then((data) => {
                              if (data?.data.access_token) {
                                disableLoading();
                                props.login(data.data.access_token);
                              } else {
                                Swal.fire(data.trim());
                              }
                            })
                            .catch(() => {
                              disableLoading();
                              setSubmitting(false);
                              setStatus(
                                intl.formatMessage({
                                  id: "AUTH.VALIDATION.INVALID_LOGIN",
                                })
                              );
                            });
                        }
                      });
                    } else {
                      console.log("error");
                    }
                  })
                  .catch(() => {
                    disableLoading();
                    setSubmitting(false);
                    setStatus(
                      intl.formatMessage({
                        id: "AUTH.VALIDATION.INVALID_LOGIN",
                      })
                    );
                  });
              }}
            >
              {({
                values,
                status,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <form
                  noValidate={true}
                  autoComplete="off"
                  className="kt-form"
                  onSubmit={handleSubmit}
                >
                  {status ? (
                    <div role="alert" className="alert alert-danger">
                      <div className="alert-text">{status}</div>
                    </div>
                  ) : null}

                  <div
                    className={`form-group ${!displayHDO ? "d-none" : null}`}
                  >
                    <TextField
                      type="text"
                      label="Head office's use only"
                      margin="normal"
                      className="kt-width-full"
                      name="root"
                      onChange={handleChange}
                      value={values.root}
                    />
                  </div>

                  <div className="form-group">
                    <TextField
                      type="text"
                      label="Username"
                      margin="normal"
                      className="kt-width-full"
                      name="username"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.email}
                      helperText={touched.username && errors.username}
                      error={Boolean(touched.username && errors.username)}
                    />
                  </div>

                  <div className="form-group">
                    <TextField
                      type="password"
                      margin="normal"
                      label="Password"
                      className="kt-width-full"
                      name="password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.password}
                      helperText={touched.password && errors.password}
                      error={Boolean(touched.password && errors.password)}
                    />
                  </div>
                  <div className="mt-5"></div>

                  {loading ? (
                    <LinearProgress
                      classes={{
                        colorPrimary: "brand-background", // class name, e.g. `classes-nesting-label-x`
                        barColorPrimary: "darkRed-background", // class name, e.g. `classes-nesting-label-x`
                      }}
                    />
                  ) : null}
                  <div className="d-flex login">
                    <ThemeButton
                      disabled={isSubmitting}
                      type="submit"
                      className="w-100 brand-button"
                    >
                      Jump In
                    </ThemeButton>
                  </div>

                  <div className="d-flex mt-2 justify-content-start">
                    <Link
                      to="/auth/forgot-password"
                      className="kt-link kt-login__link-forgot"
                    >
                      <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
                    </Link>
                  </div>
                  {/* 
                  <div className="kt-login__actions">
                    <Link
                      to="/auth/forgot-password"
                      className="kt-link kt-login__link-forgot"
                    >
                      <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
                    </Link>

                    <button
                      id="kt_login_signin_submit"
                      type="submit"
                      disabled={isSubmitting}
                      className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                        {
                          "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
                        }
                      )}`}
                      style={loadingButtonStyle}
                    >
                      Sign In
                    </button>
                  </div> */}
                </form>
              )}
            </Formik>

            {/* <div className="kt-login__divider">
              <div className="kt-divider">
                <span />
                <span>OR</span>
                <span />
              </div>
            </div>

            <div className="kt-login__options">
              <Link to="http://facebook.com" className="btn btn-primary kt-btn">
                <i className="fab fa-facebook-f" />
                Facebook
              </Link>
              <Link to="http://twitter.com" className="btn btn-info kt-btn">
                <i className="fab fa-twitter" />
                Twitter
              </Link>
              <Link to="google.com" className="btn btn-danger kt-btn">
                <i className="fab fa-google" />
                Google
              </Link>
            </div> */}
          </div>
        </div>
      </>
    );
  }
}

export default injectIntl(connect(null, auth.actions)(Login));
