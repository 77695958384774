import React from "react";

import BlogPosts from "../pages/blogPosts/BlogPosts";
import CommunityInvolvement from "../pages/communityInvolvement/CommunityInvolvement";
import DigitalTemplateWrap from "../pages/digitalTemplate/DigitalTemplateWrap";
import HomepageSetup from "../pages/homepageSetup/HomepageSetup";
import MicrositesWrap from "../pages/microsites/MicrositesWrap";
import Testimonials from "../pages/testimonials/Testimonials";
import Windowcards from "../pages/WindowCards/Windowcards";
import WindowsCardWrap from "../pages/WindowCards/WindowcardsWrap";

export function MarketingBoardsList({ pageComponent }) {
  // console.log(pageComponent);
  // return <div>test</div>
  switch (pageComponent) {
    case "Homepage Setup":
      return <HomepageSetup />;
    case "Testimonials":
      return <Testimonials />;
    case "Blogs":
      return <BlogPosts />;
    case "Community Involvement":
      return <CommunityInvolvement />;
    case "Create New":
      return <MicrositesWrap />;
    case "Microsites Settings":
      return <MicrositesWrap settings={true} />;
    case "Templates":
      return <DigitalTemplateWrap />;
    case "Window Cards":
      return <WindowsCardWrap />;
    // case "Buyers":
    //     return <Buyers />
    // case 'Unsubscribes/Bounces':
    //     return <UnsubscribesAndBounces />;
    // case 'Leverage':
    //     return <Leverage />;
    // case 'Employee Notes':
    //     return <EmployeeNotesLandingPage />;
    // case 'Sales Meeting':
    //     return <SalesMeeting />;
    // case '1 on 1 Meeting':
    //     return <OneOnOneMeeting />
    // case 'L-M Meeting':
    //     return  <LeaderMeeting/>
    // case 'Goal Setting':
    //     return  <LeaderTarget/>
    // case 'Listings':
    //     return <SystemBoardsListings />
    // case "Sales":
    //     return <SystemBoardsSales />
    // case "Settlements":
    //     return <Settlements />
    // case "Market Reviews":
    //     return <MarketReview />;
    // case "Rentals":
    //     return <Rentals />;
    // case "Benchy's Board":
    //     return <BenchysBoard />
    // case "Finance Referrals":
    //     return <FinanceReferrals />
    // case "Sources":
    //     return <Sources />
    default:
      return "";
  }
}
