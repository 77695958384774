import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import cuid from "cuid";

export const actionTypes = {
  TabAdd: "[Tab add]",
  TabLoaded: "[Tab]",
  TabDelete: "[Tab Delete]",
  TabError: "[TabsError]",
  CallTab: "[Call Tab]",
  CallTabManager: "[call Tab]",
  CloseTab: "[close Tab]",
  SelectTab: "[Select Tab]",
  ChangeNavTitle: "[ChangeNavTitle]",
};

export const initialTabsState = {
  tab: [],
  newTab: [],
  selectedTab: [],
};

const skipComponents = ["ContactProfile", "SingleListing", "SingleRental"];

export const reducer = persistReducer(
  { storage, key: "wol-auth", whitelist: ["tabs"] },
  (state = initialTabsState, action) => {
    switch (action.type) {
      case actionTypes.TabLoaded: {
        // console.log("a");
        const tabs = action.payload;
        return { ...state, tabs };
      }

      case actionTypes.TabAdd: {
        const tab = action.payload;
        // console.log(tab);
        if (skipComponents.includes(tab.name)) {
          let common_obj = state.tab.filter(
            (el) => el.props.id == tab?.props?.id
          );
          if (common_obj.length) {
            return { ...state, selectedTab: common_obj[0].props.navTitle }; //if Tab already exists just add update selectedTab
          } else {
            return {
              ...state,
              tab: [...state.tab, {...tab,id:cuid(),navTitle:tab?.props?.navTitle}],
              selectedTab: tab.props.navTitle,
            };
          }
        }
        if (state.tab.some((el) => el.name === tab.name)) {
          return { ...state, selectedTab: tab.name }; //if Tab already exists just add update selectedTab
        }
        return { ...state, tab: [...state.tab, {...tab,id:cuid(),navTitle:tab.name}], selectedTab: tab.name }; //Add selected Tab to Navigation
      }
      case actionTypes.TabDelete: {
        const deleteTab = action.payload;
        if (skipComponents.includes(deleteTab.name)) {
          return {
            ...state,
            tab: [
              ...state.tab.filter(
                (tab) => tab.props.navTitle !== deleteTab.props.navTitle
              ),
            ],
          };
        }
        return {
          ...state,
          tab: [...state.tab.filter((tab) => tab.name !== deleteTab.name)],
        };
      }
      case actionTypes.ChangeNavTitle: {
        const payload = action.payload;
        const { navTitle, ...otherPayload } = payload;
        // console.log(payload);
        let data = {
          navTitle: navTitle,
          ...otherPayload,
        };
        // props: {id: props.id, name:props.name,props.navTitle:"asd"}
        let value = state.tab.map(({ props,navTitle, ...other }) => ({
          ...other,
          navTitle:props?.id == payload.id ? data?.navTitle :(skipComponents.includes(other?.name) ? props.navTitle :other?.name ),
          props: props?.id == payload.id ? data : props,
        }));
        // console.log(value);
        // let objIndex = state.tab.findIndex((obj => obj.props.id === payload.id));
        // state.tab[objIndex].props = data;
        // let realValue = state.tab.map(obj => {
        //     if(obj.props.id === payload.id)
        //     {
        //         const { props, ...other } = obj;
        //         return ({ ...other, props: data })
        //     }
        //     else
        //     {
        //         return obj
        //     }
        // });
        // console.log(state.tab.map(obj => {

        //     console.log(obj.props.id)
        //     console.log(payload.id)
        //     if(obj.props.id === payload.id)
        //     {
        //         const { props, ...other } = obj;
        //         return ({ ...other, props: data })
        //     }
        //     else
        //     {
        //         return obj
        //     }
        // }))
        // console.log(state.tab);
        // console.log(value)
        // console.log(value)
        // console.log(state.tab.filter(obj => obj.props.id = payload.id)[0].)
        // return {...state,tab: value, selectedTab: payload.navTitle};
        // if(skipComponents.includes(payload.name))
        // {
        //     if(state.tab.some(el => el.props.navTitle === payload.props.navTitle)){
        //         return {...state, selectedTab: payload.props.navTitle };    //if Tab already exists just add update selectedTab
        //     }
        //     else{
        //         return {...state, tab: [...state.tab,payload ], selectedTab: payload.props.navTitle };
        //     }

        // }
        // if(state.tab.some(el => el.name === payload.name)){
        //     return {...state, selectedTab: payload.name };    //if Tab already exists just add update selectedTab
        // }
        return { ...state, tab: value, selectedTab: payload?.navTitle };
      }
      case actionTypes.TabError: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }
      case actionTypes.CallTabManager: {
        const data = action.payload; // Get Modal Type and props for Modal View
        // console.log("actions");
        // console.log(data, "tab_manager");
        return { ...state, newTab: data };
      }

      case actionTypes.SelectTab: {
        const data = action.payload; // Get Modal Type and props for Modal View
        // console.log(data);
        return { ...state, selectedTab: data };
      }

      case actionTypes.CloseTab: {
        return { ...state, newTab: [] };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  tabDelete: (tab) => ({ type: actionTypes.TabDelete, payload: tab }),
  changeNavTitle: (tab) => ({ type: actionTypes.ChangeNavTitle, payload: tab }),
  tabLoaded: (data) => ({ type: actionTypes.TabLoaded, payload: { data } }),
  tabAdd: (tabs) => ({ type: actionTypes.TabAdd, payload: tabs }),
  tabError: (error) => ({ type: actionTypes.TabError, payload: { error } }),
  callComponent: (data) => ({ type: actionTypes.CallComponent, payload: data }),
  closetab: () => ({ type: actionTypes.CloseTab }),
  callTabManager: (data) => ({
    type: actionTypes.CallTabManager,
    payload: data,
  }),
  selectTab: (data) => ({ type: actionTypes.SelectTab, payload: data }),
};

function* callComponenta(action) {
  // console.log("action");
  try {
    // console.log(action);
    // const { data: news } = yield getEventsData();
    // // console.log(news.data);
    yield put(actions.newsLoaded(""));
  } catch (e) {
    // yield put({type: LOAD_USERS_ERROR, error: e.message});
  }
}

export function* sagaTab() {
  yield takeLatest(actionTypes.CallTabManager, callComponenta);
}
