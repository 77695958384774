import axios from "axios";
import {DIARY_URL_APPOINTMENT} from "../../diary/_curd/diaryCurd";

const WiseBerryAPIEnvSocialMedia =
  process.env.REACT_APP_WISEBERRY_API + "social-media-template";
const WBMicrosite = process.env.REACT_APP_WISEBERRY_API + "microsite";
let ajaxRequest = null;

export function getAllSocialMediaTemplates(params) {
  // cancel  previous ajax if exists
  if (ajaxRequest) {
    ajaxRequest.cancel();
  }

  // creates a new token for upcomming ajax (overwrite the previous one)
  ajaxRequest = axios.CancelToken.source();

  return axios.get(WiseBerryAPIEnvSocialMedia + (params ? "?" + params : ""), {
    cancelToken: ajaxRequest.token,
  });
}

export function createSocialMediaTemplate(id = null, data) {
  let url = WiseBerryAPIEnvSocialMedia;
  if (id) {
    url += `/${id}`;
  }
  return axios.post(url, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function deleteSocialMediaTemplates(data) {
  return axios.post(WiseBerryAPIEnvSocialMedia + `/bulk-delete`, data);
}

// Microsites
export const getAllMicrosite = (params) => {
  return axios.get(WBMicrosite + (params ? "?" + params : ""));
};

export const addNewMicrosite = (payload) => {
  return axios.post(WBMicrosite, payload);
};

export const updateMicrosite = (id, payload) => {
  return axios.patch(`${WBMicrosite}/${id}`, payload);
};

export const deleteMicrosite = (id) => {
  return axios.delete(`${WBMicrosite}/${id}`);
};

export const getAllMicrositeTemplate = (params) => {
  return axios.get(`${WBMicrosite}-template` + (params ? "?" + params : ""));
};

export const updateMicrositeSettings = (user_id, type, template) => {
  return axios.post(`${WBMicrosite}-settings`, { user_id, type, template });
};



export function sendMicrosteToProspectEmail(id) {
  return axios.get(`${WBMicrosite}` + "/send-microsite-email/"+id);
}

export function getAppointmentMicrositeDetails(id){
  return axios.get(`${WBMicrosite}`+ "/appointment/"+id);
}
//to check slug uniqueness
export function checkMicrositeSlug(id){
  return axios.get(`${WBMicrosite}`+ "/check-slug/"+id);
}
