import React, { Fragment,  useState } from "react";
import { Card } from "react-bootstrap";
// import LoadingSkeleton from "../../display/skeleton/LoadingSkeleton";
import { FormGroup, FormControlLabel, Switch } from "@material-ui/core";
import LoadingSkeleton from "../../../../partials/display/skeleton/LoadingSkeleton";
import NoteItem from "./MobileNoteItem";
import { Waypoint } from "react-waypoint";

const MobileNoteLists = ({notes = [], makePinned,fetchMore, profile}) => {
  const [displayGeneralNote, setDisplayGeneralNote] = useState(false);

  const tag = notes?.map((note)=>{

    let noteId = note?.prospect && note?.prospect[0]?.id
    let profileId  = profile?.types.filter((pro)=> pro?.prospect_id == noteId)

    return{...note, profileId,
    }

  })


  let pinnedNotes = tag?.filter(note => note.pinned === 1);
  let normalNotes = tag?.filter(note => note.pinned !== 1).sort((a, b) => (new Date(b.created_at) - new Date(a.created_at)));
  return (
    <Fragment>
     <FormGroup>
        <FormControlLabel
          control={
            <Switch
              onChange={() => setDisplayGeneralNote(!displayGeneralNote)}
            />
          }
          label="Display general notes"
        />
      </FormGroup>
      <Card className="note-list" style={{width:"100%"}}>
        {notes ? <Fragment>

          {pinnedNotes?.map((item, index) => (
          <NoteItem makePinned={makePinned} data={item}           displayGeneral={displayGeneralNote}/>
        ))}
        {normalNotes?.map((item, index) => (
          <NoteItem makePinned={makePinned} data={item} profile={profile?.types}           displayGeneral={displayGeneralNote}/>
        ))}
        </Fragment> : <LoadingSkeleton height='30vh' />}

        <Waypoint onEnter={fetchMore} />
      </Card>
    </Fragment>
  );
};

export default MobileNoteLists;
