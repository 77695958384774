import React, { Fragment} from "react";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { reduxForm } from "redux-form";
import ThemeHeader from "../../content/ThemeHeader";
import MobileView from "./MobileView";
import { TextField } from "@material-ui/core";
import { CloseOutlined } from "@ant-design/icons";

const Sms = (props) => {
  const { previewData, preview, setEnableMailPreview } = props;

  const closePreviewModal = () => {
    setEnableMailPreview(false);
  };

  return (
    <Fragment>
      <div className="mobile-view sms-view">
        <ThemeHeader
          left={
            <Fragment>
              <h5 className="title">Preview SMS</h5>
            </Fragment>
          }
          right={
            <Fragment>
              <div className="pl-2">
                <CloseOutlined
                  style={{ fontSize: "20px" }}
                  onClick={closePreviewModal}
                />
              </div>
            </Fragment>
          }
        />

        <Row className="pt-2">
          <Col lg={6}>
            <div className="d-flex justify-content-between mt-3">
              <div className="d-flex align-self-end"></div>
            </div>
            <form className="sms-form mt-2">
              <TextField
                fullWidth
                // label={label}
                multiline
                rows="3"
                value={previewData}
                disabled
              />
            </form>
          </Col>
          <Col lg={6}>
            <MobileView sms={previewData} preview={preview} />
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default connect()(
  reduxForm({ form: "sms-preview", enableReinitialize: true })(Sms)
);
