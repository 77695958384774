import React, { Fragment, useEffect, useState } from "react";
import { Accordion, Col, Form, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { Field } from "redux-form";
import { getAllMembers, getMenu } from "../../../GeneralApi/GeneralApi";
import DateInput from "../../form/DateInput";
import MultiSelect from "../../form/MultiSelect";
import ToggleButtons from "../../form/toggleButtons/ToggleButtons";
import CustomToggle from "../customToggle/CustomToggle";

function PropertyActivity() {
  const [members, setMembers] = useState(null);
  const [actions, setActions] = useState([{id:'pc',name:'PC'},{id:'dk',name:'DK'},{id:'na',name:'NA'}]);

  useEffect(() => {
    let query = "property_type";

    getAllMembers()
      .then(({ data: { data } }) => {
        setMembers(
          data.map((s) => ({
            name: `${s.initial}`,
            id: s.contact.id,
            optionTooltipTitle:`${s?.contact?.first_name ?? ''} ${s?.contact?.last_name ?? ''}`,
          }))
        );
      })
      .then(
        // getMenu(query).then(({ data: { data } }) => {
        //   setActions(data.property_type);
        // })
      )
      .catch((e) => {
        toast.error("Error while fetching");
      });
  }, []);

  return (
    <Fragment>
      <CustomToggle eventKey="6" rightArrow={true} tabIndex={'-1'}>Prospect Activity</CustomToggle>
      <Accordion.Collapse eventKey="6">
        <Form.Group as={Row}>
          <Col className="pb-1 pt-1" sm={12}>
            <Field
              type="date"
              label="Last Contacted From"
              name="last_contacted_from"
              component={DateInput}
              placeholder="Last Contacted From"
            />
          </Col>
          <Col className="pb-1" sm={12}>
            <Field
              type="date"
              label="Last Contacted To"
              name="last_contacted_to"
              component={DateInput}
              placeholder="Last Contacted To"
            />
          </Col>
          <Col className="pb-1 pt-1" sm={12}>
            <Field
              type="date"
              label="Appointment Date From"
              name="appointment_date_from"
              component={DateInput}
              placeholder="Appointment Date From"
            />
          </Col>
          <Col className="pb-1" sm={12}>
            <Field
              type="date"
              label="Appointment Date To"
              name="appointment_date_to"
              component={DateInput}
              placeholder="Appointment Date To"
            />
          </Col>

          <Col className="pb-1 pt-2" xs={12}>
            <Field
              name="marked_as"
              component={ToggleButtons}
              // labelOne="Marked As"
              labelTwo="Not Marked As"
            />
          </Col>
          <Col className="pb-1" sm={12}>
            <Field
              name="marked_as_actions"
              placeholder="Select Actions"
              label="Actions"
              component={MultiSelect}
              showBottom={false}
              isMulti={true}
              options={actions}
            />
          </Col>
          <Col className="pb-1" sm={12}>
            <div className="row">
              <div className="col-6 pr-1">
                <Field
                  type="date"
                  name="history_date_from"
                  component={DateInput}
                  placeholder="Date From"
                />
              </div>
              <div className="col-6 pl-0">
                <Field
                  type="date"
                  name="history_date_to"
                  component={DateInput}
                  placeholder="Date To"
                />
              </div>
            </div>
          </Col>
          <Col className="pb-1" sm={12}>
            <Field
              name="history_created_by"
              label="Team"
              placeholder="Select Team"
              component={MultiSelect}
              showBottom={false}
              isMulti={false}
              options={members}
            />
          </Col>
        </Form.Group>
      </Accordion.Collapse>
    </Fragment>
  );
}

export default PropertyActivity;
