import React, {useEffect, Fragment, useState, useRef} from "react";
import {
  useTable,
  useExpanded,
  useSortBy,
  useGlobalFilter,
  useRowSelect,
  usePagination,
} from "react-table";
import clsx from "clsx";
import {Waypoint} from "react-waypoint";
import {Checkbox, makeStyles, TablePagination} from "@material-ui/core";
import {getDummyTableRow} from "../../_helpers/_utils/utils";
import Pagination from "@material-ui/lab/Pagination";
import Loader from "../Loader/Loader";

const _ = require("lodash");

const useStyles = makeStyles({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    borderRadius: 3,
    width: 16,
    height: 16,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: "#137cbd",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#106ba3",
    },
  },
});

const IndeterminateCheckbox = React.forwardRef(
  (
    {indeterminate, is_selectable, created_manually, setIsAllSelected, ...rest},
    ref
  ) => {
    const classes = useStyles();
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;
    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        {/* e.stopPropagation() will stop it from row onClick */}

        {/* {rest.allSelected ?  <Checkbox   onClick={(e) => rest.allSelected(e)} className="table-checkbox" ref={resolvedRef} {...rest} /> : rest.is_selectable ?  <Checkbox   onClick={(e) => {e.stopPropagation()}} className="table-checkbox" ref={resolvedRef} {...rest} /> : <Checkbox   className="table-checkbox display-none" ref={resolvedRef} />} */}
        {is_selectable && created_manually ? (
          <Checkbox
            onClick={(e) => {
              setIsAllSelected(false);
              e.stopPropagation();
            }}
            checkedIcon={
              <span className={clsx(classes.icon, classes.checkedIcon)} />
            }
            icon={<span className={classes.icon} />}
            className={classes.root + " table-checkbox"}
            disableRipple
            color="default"
            ref={resolvedRef}
            {...rest}
          />
        ) : (
          <Checkbox
            className={classes.root + " table-checkbox display-none"}
            ref={resolvedRef}
          />
        )}
      </>
    );
  }
);

const IndeterminateCheckboxSelectAll = React.forwardRef(
  ({indeterminate, allSelected, setIsAllSelected, ...rest}, ref) => {
    const classes = useStyles();
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;
    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    const selectAll = (e) => {
      setIsAllSelected(e.target.checked);
      if (_.isFunction(allSelected)) {
        allSelected(e);
      }
    };

    return (
      <>
        {/* {console.log(indeterminate)}{console.log(rest)} */}
        {/* e.stopPropagation() will stop it from row onClick */}

        {/* {rest.allSelected ?  <Checkbox   onClick={(e) => rest.allSelected(e)} className="table-checkbox" ref={resolvedRef} {...rest} /> : rest.is_selectable ?  <Checkbox   onClick={(e) => {e.stopPropagation()}} className="table-checkbox" ref={resolvedRef} {...rest} /> : <Checkbox   className="table-checkbox display-none" ref={resolvedRef} />} */}
        <Checkbox
          onClick={(e) => selectAll(e)}
          className={classes.root + " table-checkbox"}
          ref={resolvedRef}
          checkedIcon={
            <span className={clsx(classes.icon, classes.checkedIcon)} />
          }
          icon={<span className={classes.icon} />}
          disableRipple
          color="default"
          {...rest}
        />
      </>
    );
  }
);
const defaultPropGetter = () => ({});
const DynamicTableEditBody = ({
  scrollToTop,
  columns,
  data,
  update,
  handleChange,
  isEditable,
  showFooter,
  allSelectable,
  handleRowClick,
  allSelected,
  isConditional,
  height,
  header,
  getRowProps = defaultPropGetter,
  className,
  addNewRow,
  hideColumns,
  addNewRowBottom,
  renderRowSubComponent,
  onSort,
  disableSort,
  selectRowChangeColor,
  preSelected,
  notHidden,
  dash_header,
  customColumnWidth,
  notScrollToTop,
  refreshWhenLoad,
  selectAllDefault = false,
  defaultSortBy,
  fromReferral,
  gotoPage,
  nextPage,
  previousPage,
  setPageSize,
  isLoading = false,
  showPagination = false,
  paginationData,
  fetchMoreData,
  autoResetSelectedRows = true,
  resetSelectedRows = false,
}) => {
  const [rowBackgroundColor, setRowBackgroundColor] = useState(null);
  const [selectedRows, setSelectedRows] = useState(preSelected);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const myRef = useRef(null);

  useEffect(() => {
    setHiddenColumns(hideColumns);
    setSelectedRows(preSelected);
  }, [hideColumns, isEditable, preSelected, selectedRows]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    preGlobalFilteredRows,
    prepareRow,
    selectedFlatRows,
    footerGroups,
    visibleColumns,
    setHiddenColumns,
    state: {sortBy, expanded},
    toggleAllRowsSelected,
    setSortBy,
  } = useTable(
    {
      columns,
      data,
      autoResetSelectedRows: autoResetSelectedRows,
      // autoResetSelectedRows:true,
      initialState: {
        hiddenColumns: hideColumns,
        ...(preSelected && {selectedRowIds: selectedRows}),
      },
      manualSortBy: true,
      disableSortBy: disableSort,
      // defaultCanSort: false,
    },

    isEditable
      ? (hooks) => {
          hooks.allColumns.push((columns) => [
            // Let's make a column for selection
            {
              id: "selection",
              width: "20",
              // The header can use the table's getToggleAllRowsSelectedProps method
              // to render a checkbox.  Pagination is a problem since this will select all
              // rows even though not all rows are on the current page.  The solution should
              // be server side pagination.  For one, the clients should not download all
              // rows in most cases.  The client should only download data for the current page.
              // In that case, getToggleAllRowsSelectedProps works fine.
              Header: ({getToggleAllRowsSelectedProps}) => (
                <div>
                  <IndeterminateCheckboxSelectAll
                    allSelected={allSelected}
                    {...getToggleAllRowsSelectedProps()}
                    setIsAllSelected={setIsAllSelected}
                  />
                </div>
              ),
              // The cell can use the individual row's getToggleRowSelectedProps method
              // to the render a checkbox
              Cell: ({row}) => (
                <div>
                  <IndeterminateCheckbox
                    created_manually={
                      isConditional
                        ? row.original?.created_manually
                          ? true
                          : false
                        : true
                    }
                    is_selectable={
                      allSelectable ? allSelectable : row.original.is_selectable
                    }
                    {...row.getToggleRowSelectedProps()}
                    setIsAllSelected={setIsAllSelected}
                  />
                </div>
              ),
            },
            ...columns,
          ]);
        }
      : "",
    useGlobalFilter, // useGlobalFilter!
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  useEffect(() => {
    if (defaultSortBy) {
      setSortBy(defaultSortBy);
    }
  }, [defaultSortBy]);

  useEffect(() => {
    if (sortBy && onSort) {
      onSort(sortBy);
    }
  }, [onSort, sortBy]);

  useEffect(() => {
    if (selectAllDefault) {
      toggleAllRowsSelected(true);
    }
  }, [selectAllDefault]);

  useEffect(() => {
    // console.log(selectedFlatRows.map((d) => d.original));
    if (isEditable) {
      // console.log(selectedFlatRowsHasChanged)
      handleChange(selectedFlatRows.map((d) => d.original));
    }
  }, [selectedFlatRows.length]);

  useEffect(() => {
    if (resetSelectedRows) {
      setSelectedRows([]);
      toggleAllRowsSelected(false);
    }
  }, [resetSelectedRows]);
  useEffect(() => {
    // console.log(selectedFlatRows.map((d) => d.original));
    if (isEditable) {
      // console.log(selectedFlatRowsHasChanged)
      handleChange(selectedFlatRows.map((d) => d.original));
    }
  }, [selectedFlatRows.length]);

  const handleRowClicked = ({original}) => {
    if (handleRowClick) {
      setRowBackgroundColor(original.id);
      handleRowClick(original);
    }
  };
  useEffect(() => {
    var elmnt = document.getElementById(dash_header);
    !notScrollToTop &&
      (dash_header ? elmnt.scrollIntoView() : myRef.current.scrollIntoView());
  }, [data?.[0]?.id]);

  useEffect(() => {
    if (isAllSelected === true) {
      toggleAllRowsSelected(true);
    }
  }, [data.length]);

  //pagination related code block
  const [paginationPageSize, setPaginationPageSize] = useState(15);
  const handlePageSizeChange = (event) => {
    setPaginationPageSize(event?.target?.value);
    fetchMoreData(1, event?.target?.value);
  };

  const handlePageChange = (event, value) => {
    fetchMoreData(value, paginationPageSize);

    // setPage(value);
  };

  return (
    <>
      <div
        className={"theme-table-striped " + className}
        style={{
          height: `${height}vh`,
          // ...(data?.length + 1 <
          //   Math.round(Math.round(window.innerHeight / (100 / height)) / 37) &&
          // !notHidden &&
          // height > 50
          //   ? {
          //       overflowY: "hidden",
          //       // overflowY:  fromReferral ? "scroll" : "hidden",
          //     }
          //   : {
          //       overflowY: "scroll",
          //       // overflowY:  fromReferral ? "scroll" : "hidden",
          //     }),
        }}
      >
        {header ? header : ""}
        <table {...getTableProps()} className="table">
          <thead ref={myRef}>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => {
                  const getWidth = (col, index, edit) => {
                    if (col.Header === "Date") {
                      return "100px";
                    } else if (index === 0 && edit) {
                      return "40px";
                    } else if (
                      col.id === "contract_in_office" ||
                      col.id === "website" ||
                      col.id === "listing_info" ||
                      col.id === "condition" ||
                      col.id === "sale_agreed"
                    ) {
                      return "60px";
                    } else {
                      // Default width for columns without specific width
                      return "auto";
                    }
                  };
                  const widthStyle = customColumnWidth
                    ? {
                        width: getWidth(column, index, isEditable),
                        maxWidth: getWidth(column, index, isEditable),
                      }
                    : {};
                  return (
                    <th
                      {...column.getHeaderProps({
                        ...column.getSortByToggleProps(),
                        style: widthStyle,
                      })}
                    >
                      {column.render("Header")}
                      {/* {console.log(column)} */}
                      {/* Add a sort direction indicator */}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? " 🔽"
                            : " 🔼"
                          : null}
                      </span>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {isLoading ? (
              <Loader open={isLoading} />
            ) : (
              <>
                {addNewRow ? addNewRow : null}

                {rows.map((row, i) => {
                  prepareRow(row);
                  return (
                    <Fragment key={i}>
                      <tr
                        {...row.getRowProps(getRowProps(row))}
                        onClick={() => handleRowClicked(row)}
                        style={
                          rowBackgroundColor == row.original.id &&
                          selectRowChangeColor
                            ? {
                                backgroundColor: "lightblue",
                              }
                            : {}
                        }
                      >
                        {row.cells.map((cell, i) => {
                          return (
                            <td
                              className={cell?.column?.className ?? ""}
                              key={i}
                              {...cell.getCellProps()}
                            >
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>

                      {row.isExpanded ? (
                        <tr>
                          <td colSpan={visibleColumns.length}>
                            {/*
                          Inside it, call our renderRowSubComponent function. In reality,
                          you could pass whatever you want as props to
                          a component like this, including the entire
                          table instance. But for this example, we'll just
                          pass the row
                        */}
                            {renderRowSubComponent({row})}
                          </td>
                        </tr>
                      ) : null}
                    </Fragment>
                  );
                })}
                {addNewRowBottom ? addNewRowBottom : null}
                {[...Array(getDummyTableRow(height, rows?.length))].map(
                  (e, i) => {
                    return (
                      <tr key={i}>
                        {rows[0]?.cells.map((cell, i) => {
                          return <td key={i}></td>;
                        })}
                      </tr>
                    );
                  }
                )}
                {data?.length > 15 && !showPagination && (
                  <tr className="hidden">
                    <td>
                      <Waypoint onEnter={update} />
                    </td>
                  </tr>
                )}
              </>
            )}
          </tbody>
          {showFooter && !isLoading ? (
            <tfoot>
              {footerGroups.map((group) => (
                <tr {...group.getFooterGroupProps()}>
                  {group.headers.map((column) => (
                    <td {...column.getFooterProps()}>
                      {column.render("Footer")}
                    </td>
                  ))}
                </tr>
              ))}
            </tfoot>
          ) : null}
        </table>
      </div>

      {showPagination && (
        <>
          <div style={{display: "flex", justifyContent: "space-between"}}>
            <TablePagination
              component="div"
              count={paginationData?.totalItems}
              page={paginationData?.currentPage}
              onPageChange={handlePageChange}
              rowsPerPage={paginationPageSize}
              rowsPerPageOptions={[15, 30, 45, 60, 100]}
              onRowsPerPageChange={handlePageSizeChange}
              labelDisplayedRows={({from, to, count}) =>
                `${paginationData?.from} to ${paginationData?.to} from ${count}`
              }
            />

            <Pagination
              className="my-3"
              count={paginationData.lastPage}
              page={paginationData.currentPage}
              boundaryCount={2}
              variant="outlined"
              color="primary"
              onChange={handlePageChange}
            />
          </div>
        </>
      )}
    </>
  );
};
DynamicTableEditBody.defaultProps = {
  allSelectable: true,
  isConditional: false,
  height: "70",
  hideColumns: [],
  showFooter: false,
  className: "tableFixHead",
  disableSort: true,
};
export default DynamicTableEditBody;
