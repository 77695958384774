import { Divider } from "@material-ui/core";
import React from "react";
import { ReactComponent as Thumbtack } from "../../../../_metronic/_assets/media/icons/wb/pin.svg";
import moment from "moment";
import _replace from "lodash/replace";
import ThemeButton from "../../button/ThemeButton";
import MemberName from "../../popover/memberPopover/MemberName";

const replaceHtmlTags = (text) => {
  let formatString = _replace(text, new RegExp("<br />", "g"), "\r\n");

  return (formatString = _replace(
    formatString,
    new RegExp("<br>", "g"),
    "\r\n"
  ));
};

const NoteItem = ({ data, makePinned, displayGeneral, profile }) => (
  <div
    className={`${
      data?.type?.name.toLowerCase() === "general"
        ? displayGeneral
          ? null
          : "d-none"
        : null
    }`}
  >
    <div className={`note-item note-item-${data?.type?.name.toLowerCase()}`}>
      <div className="flex-grow-1">
        <div className="d-flex justify-content-between flex-wrap ">
          <div className="d-flex mr-3">
            <p className="text-dark-75 font-weight-bold mr-3">
              {data?.created_by ? (
                <MemberName id={data.created_by.id}>
                  {data?.created_by?.initial}
                </MemberName>
              ) : null}
            </p>
            <p>{moment(data?.created_at).format("DD/MM/YY h:mm a")}</p>
          </div>
          <div className="my-lg-0 my-3">
            <ThemeButton
              icon
              onClick={() => makePinned(data.id, data?.pinned)}
              className={
                "note-thumbtack " + (data?.pinned === 1 ? "pinned" : "")
              }
            >
              <Thumbtack className="kt-svg-icon kt-svg-icon--primary" />
            </ThemeButton>
          </div>
        </div>

        <div className="d-flex flex-wrap text-break">
          {data?.profileId && data?.profileId[0]?.prospect_address ? (
            <div className="d-flex flex-grow-1">
              <div style={{ width: "50%" }}>
                <p style={{ whiteSpace: "pre-wrap" }}>
                  {replaceHtmlTags(data?.detail)}
                </p>
              </div>

              <div style={{ display: "flex", width: "40%" }}>
                <p style={{ textAlign: "end", background: "#FCF6BC" }}>
                  {data?.profileId && data?.profileId[0]?.prospect_address}
                </p>
              </div>
            </div>
          ) : (
            <div className="d-flex flex-grow-1">
              <p style={{ whiteSpace: "pre-wrap" }}>
                {data?.history?.door_knock === 1 && "[Door Knock] "}
                {data?.history?.phone_call === 1 && "[Phone Call] "}
                {data?.history?.na === 1 && "[NA] "}
                {replaceHtmlTags(data?.detail)}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
    <Divider className="mb-2 mt-2" variant="middle" />
  </div>
);

export default NoteItem;
