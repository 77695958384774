/* eslint-disable no-restricted-imports */
import React, { useState, useContext, Fragment, useEffect } from "react";
import { toast } from "react-toastify";
import { IconButton, LinearProgress } from "@material-ui/core";

import moment from "moment";
import Swal from "sweetalert2";

import { deleteMicrosite } from "../../crud/socialMedailCrud";

import DynamicTableEdit from "../../../../partials/table/DynamicTableEdit";
import ThemeHeader from "../../../../partials/content/ThemeHeader";

import ThemeButton from "../../../../partials/button/ThemeButton";
import DefaultModal from "../../../../partials/modal/DefaultModal";

import MailSettings from "../../modals/microsites/MicrositesSetting";

import TableFilter from "../../../../partials/table/TableFilter";

import FilterOriginToggle from "./FilterOriginToggle";

import { MicrositesContext } from "../../pages/microsites/MicrositesContextProvider";

const MicrositesTable = ({
  setOpen,
  data,
  fetchMoreData,
  updateTable,
  handleGlobalSearch,
  setShowAutomatedMail
}) => {
  const [selectedMicrosites, setSelectedMicrosites] = useState([]);
  const [showSetting, setShowSetting] = useState(false);
  const [sentBy, setSentBy] = useState("");

  const {
    handleSort,
    isLoading,
    setIsLoading,
    setCurrentMicrosite,
    update
  } = useContext(MicrositesContext);

  const rowClick = value => {
    setCurrentMicrosite(value);
    setShowAutomatedMail(true);
  };

  useEffect(() => {
    if (sentBy) {
      update({ sent_by: sentBy });
    } else {
      update();
    }
  }, [sentBy]);

  const deleteSelectedMicrosites = () => {
    if (selectedMicrosites.length > 0) {
      Swal.fire({
        title: "Deleting Microsite",
        html: "Are you sure you want to delete the selected microsites?",
        showCancelButton: true,
        confirmButtonColor: "#A7A9AC",
        cancelButtonColor: "#2BB673",
        confirmButtonText: "Cancel",
        cancelButtonText: "Yes"
      }).then(result => {
        if (result.isDismissed) {
          setIsLoading(true);
          const selectedIds = [];
          for (let i = 0; i <= selectedMicrosites.length - 1; i++) {
            selectedIds.push(selectedMicrosites[i].id);
          }

          deleteMicrosite(selectedIds.join(","))
            .then(res => {
              if (res.status === 200) {
                toast.success("Microsites deleted!");
                update();
              } else {
                toast.error("Error while deleting microsites!");
              }
            })
            .catch(err => {
              toast.error(
                `Error sending request to delete microsites (${err})`
              );
            })
            .finally(() => setIsLoading(false));
        }
      });
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        columns: [
          {
            Header: "Date",
            accessor: "created_at",
            Cell: ({ cell: { value } }) => (
              <p className="text-center">
                {moment(value).format("DD/MM/YYYY")}
              </p>
            )
          },
          {
            Header: "Name",
            accessor: "microsite_name",
            Cell: ({
              cell: {
                row: { original }
              }
            }) => (
              <div
                style={{ cursor: "pointer" }}
                onClick={() => rowClick(original)}
              >
                {original?.name ?? ""}
              </div>
            )
          },
          {
            Header: "Type",
            accessor: "name",
            Cell: ({
              cell: {
                row: { original }
              }
            }) => <p className="text-center">{original?.type?.name ?? ""}</p>
          },
          {
            Header: "Details",
            accessor: "details",
            Cell: ({
              cell: { value },
              cell: {
                row: { original }
              },
              cell: data
            }) => {
              const micrositeSlug =
                process.env.REACT_APP_MICROSITE_URL +
                (original.slug ?? original.id);
              return (
                <div className="d-flex justify-content-between">
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => rowClick(original)}
                  >
                    {original?.address?.search_address ?? "Not defined"}
                  </div>
                  <div className="d-flex">
                    <IconButton
                      disabled
                      size="small"
                      onClick={() =>
                        console.log(
                          "open copy https://wbre.co/yourls-api.php?action=shorturl&username=wiseberry-api&password=wb-in5pir3&format=json&url=" +
                            micrositeSlug
                        )
                      }
                    >
                      <i className="fas fa-clone"></i>
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() => window.open(micrositeSlug, "_blank")}
                    >
                      <i className="fas fa-globe-americas"></i>
                    </IconButton>
                    <IconButton
                      disabled
                      size="small"
                      onClick={() =>
                        console.log(
                          "open qr code https://wbre.co/yourls-api.php?action=shorturl&username=wiseberry-api&password=wb-in5pir3&format=json&url=" +
                            micrositeSlug
                        )
                      }
                    >
                      <i className="fas fa-qrcode"></i>
                    </IconButton>
                    <IconButton
                      disabled={!original?.short_url}
                      size="small"
                      onClick={e => {
                        e.stopPropagation();
                        navigator.clipboard.writeText(original?.short_url);
                        toast.success("Link copied");
                      }}
                    >
                      <i className="fas fa-share-square"></i>
                    </IconButton>
                  </div>
                </div>
              );
            }
          },

          {
            Header: "Agent",
            accessor: "for",
            disableSortBy: true,
            Cell: ({
              cell: {
                row: { original }
              }
            }) => (
              <p className="text-center">{original?.created_for?.initial}</p>
            )
          },

          {
            Header: "Sent By",
            accessor: "origin",
            disableSortBy: true,
            Cell: ({
              cell: {
                row: { original }
              }
            }) => (
              <p className="text-center">
                {original?.origin === 2 ? "Automation" : "Manual"}
              </p>
            )
          },

          {
            Header: "Views",
            accessor: "description",
            disableSortBy: true,
            Cell: ({
              cell: {
                row: { original }
              }
            }) => <p className="text-center">{original?.views ?? 0}</p>
          }
        ]
      }
    ],
    []
  );

  return (
    <div className="p-3">
      {showSetting ? (
        <DefaultModal
          size="xl"
          centered
          show={showSetting}
          onHide={() => setShowSetting(false)}
        >
          <MailSettings closeModal={() => setShowSetting(false)} />
        </DefaultModal>
      ) : null}

      <ThemeHeader
        className="p-1"
        noScroll
        left={
          <Fragment>
            <h5 className="title  max-w">Microsites</h5>

            <IconButton className="ml-auto" onClick={() => updateTable()} icon>
              <i className="fas fa-sync-alt fa-sm"></i>
            </IconButton>
          </Fragment>
        }
        right={
          <Fragment>
            <div className="p-2">
              <TableFilter
                length={data?.length ?? 0}
                handleGlobalSearch={handleGlobalSearch}
              />
            </div>

            <FilterOriginToggle
              value={sentBy}
              changeValue={setSentBy}
              options={[
                { value: 2, label: "Automation" },
                { value: 1, label: "Manual" },
                { value: "", label: "All" }
              ]}
              border={false}
            />

            <div className="p-2">
              <ThemeButton
                className="darkGrey-button text-white font-weight-bold"
                onClick={e => setOpen(true)}
              >
                Filter
              </ThemeButton>
            </div>
            <div className="p-2">
              <ThemeButton
                className="lightGrey-button font-weight-bold"
                disabled={!selectedMicrosites.length}
                onClick={deleteSelectedMicrosites}
              >
                Delete
              </ThemeButton>
            </div>

            <div className="p-2">
              <ThemeButton
                onClick={() => {
                  setCurrentMicrosite({});
                  setShowAutomatedMail(true);
                }}
                className="green-button text-white font-weight-bold"
              >
                Create New
              </ThemeButton>
            </div>
          </Fragment>
        }
      />
      {isLoading && <LinearProgress />}
      <DynamicTableEdit
        // handleRowClick={rowClick}
        handleChange={e => setSelectedMicrosites(e)}
        isEditable={true}
        columns={columns}
        data={data}
        disableSort={false}
        update={fetchMoreData}
        onSort={handleSort}
      />
    </div>
  );
};

export default MicrositesTable;
