import React from "react";
import {Box, Grid, Typography, CardMedia, Card, CardActionArea, Divider, Button} from "@material-ui/core";
import headerBlock from "../../../../../_metronic/_assets/media/prelisting/1-Pre-Listing-Header.png";
import addressBlock from "../../../../../_metronic/_assets/media/prelisting/2-Pre-Listing-Address_Appt.png";
import letterBlock from "../../../../../_metronic/_assets/media/prelisting/3-Pre-Listing-Agent Letter.png";
import introBlock from "../../../../../_metronic/_assets/media/prelisting/4-Pre-Listing-Agent.png";
import officesBlock from "../../../../../_metronic/_assets/media/prelisting/5-Pre-Listing-offices.png";
import processBlock from "../../../../../_metronic/_assets/media/prelisting/6-Pre-Listing-Selling Process.png";
import trapsBlock from "../../../../../_metronic/_assets/media/prelisting/7-Pre-Listing-Traps _ Pitfalls.png";
import redflagsBlock from "../../../../../_metronic/_assets/media/prelisting/8-Pre-Listing-redflags.png";
import teamBlock from "../../../../../_metronic/_assets/media/prelisting/9-Pre-Listing-teamtrainedto.png";
import valuesBlock from "../../../../../_metronic/_assets/media/prelisting/10-Pre-Listing-values.png";
import ceoBlock from "../../../../../_metronic/_assets/media/prelisting/11-Pre-Listing-ceohandlescomplains.png";
import careBlock from "../../../../../_metronic/_assets/media/prelisting/12-Pre-Listing-Differentinthewaywecare.png";
import testimonialBlock from "../../../../../_metronic/_assets/media/prelisting/13-Pre-Listing-testimonial.png";
import commBlock from "../../../../../_metronic/_assets/media/prelisting/14-Pre-Listing-CommInvolvement.png";
import footerBlock from "../../../../../_metronic/_assets/media/prelisting/15-Pre-Listing-footer.png";
import "./style/microsites.css";
//notlisted image
import address_spec
    from "../../../../../_metronic/_assets/media/microsite_image/not_listed/Post-Listing-Microsite-NotListed-Blocks-1A.png";
import yourPropertyBlock
    from "../../../../../_metronic/_assets/media/microsite_image/not_listed/Post-Listing-Microsite-NotListed-Blocks-3.png";
import recommendedPriceGuideBlock
    from "../../../../../_metronic/_assets/media/microsite_image/not_listed/Post-Listing-Microsite-NotListed-Blocks-4.png";
import localMarketAnalysisBlock
    from "../../../../../_metronic/_assets/media/microsite_image/not_listed/Post-Listing-Microsite-NotListed-Blocks-5.png";
import wbSalesProcessBlock
    from "../../../../../_metronic/_assets/media/microsite_image/not_listed/Post-Listing-Microsite-NotListed-Blocks-6.png";
import agentSalesBlock
    from "../../../../../_metronic/_assets/media/microsite_image/not_listed/Post-Listing-Microsite-NotListed-Blocks-7.png";
import recentlySlodBlock
    from "../../../../../_metronic/_assets/media/microsite_image/not_listed/Post-Listing-Microsite-NotListed-Blocks-8.png";
import testimonalBlock
    from "../../../../../_metronic/_assets/media/microsite_image/not_listed/Post-Listing-Microsite-NotListed-Blocks-9.png";

import acceptFooterBlock
    from "../../../../../_metronic/_assets/media/microsite_image/not_listed/Post-Listing-Microsite-NotListed-Blocks-10.png";


import whatsNextBlock
    from "../../../../../_metronic/_assets/media/microsite_image/listed/Post-Listing-Microsite-Listed-Blocks-3.png";
import ourBestTips
    from "../../../../../_metronic/_assets/media/microsite_image/listed/Post-Listing-Microsite-Listed-Blocks-5.png";
import gettingSoldBlock
    from "../../../../../_metronic/_assets/media/microsite_image/listed/Post-Listing-Microsite-Listed-Blocks-6.jpg";
import listedPropertyBlock
    from "../../../../../_metronic/_assets/media/microsite_image/listed/Post-Listing-Microsite-Listed-Blocks-7.png";

const PreListingTemplatePreview = ({
                                       micrositeType,
                                       selling,
                                       traps,
                                       subheading,
                                       redflags,
                                       team,
                                       values,
                                       testimonial,
                                       comm,
                                       letter,
                                       intro,
                                       coIntro,
                                       ceohandle,
                                       profile, // This prop will have the authenticated user information, like first name
                                       member,
                                       appointment,
                                       coMember, gettingSold, blogs, recentlySold, agentResult

                                   }) => {
    return (

        <Grid
            container
            style={{
                background:
                    "linear-gradient(157deg, rgba(164,0,19,1) 0%, rgba(106,25,50,1) 100%)",
            }}
            className="main_container"
            justifyContent="center">

            <Grid item>
                <img src={headerBlock}/>
            </Grid>


            <Grid item style={{width: "100%"}}>
                <Box className="info_box">
                    {micrositeType !== 1 &&
                        <Grid item mt={4}>
                            <p className="sub_header textWhite">
                                {"At Wiseberry, we treat your property  \n as if it belongs to our parents."}
                            </p>
                        </Grid>
                    }
                    <Grid item style={{width: "100%", textAlign: "left"}}>

                        <Grid container>
                            <Grid item md={6} style={{textAlign: "left"}}>
                                <Typography
                                    className="adderess_typo">{String(appointment?.suburb ? appointment?.suburb : "Suburb Name").toLowerCase()} </Typography>
                                <Typography
                                    className="sub_adderess_typo">{appointment?.street_no} {String(appointment?.street ? appointment?.street : "Street name").toLowerCase()}</Typography>

                            </Grid>
                            {micrositeType !== 1 &&
                                <Grid item md={6} style={{textAlign: "right", alignSelf: "center"}}>
                                    <img src={address_spec} width="100%" height="auto"/>
                                </Grid>
                            }

                        </Grid>
                        {/*<Typography*/}
                        {/*    className="adderess_typo">{String(appointment?.suburb ? appointment?.suburb : "Suburb Name").toLowerCase()} </Typography>*/}
                        {/*<Typography*/}
                        {/*    className="sub_adderess_typo">{appointment?.street_no} {String(appointment?.street ? appointment?.street : "Street name").toLowerCase()}</Typography>*/}
                    </Grid>
                    {micrositeType === 1 &&
                        <>
                            <Grid item style={{width: "100%", textAlign: "center", marginTop: "20px"}}>
                                <Typography className="welook_typo">
                                    {"We look forward to meeting you on the"}
                                </Typography>
                                <Typography className="time_typo">
                                    {appointment?.formattedDate}
                                </Typography>
                            </Grid>

                            <Grid item style={{
                                display: "flex",
                                alignItems: "flex-end",
                                width: "100%",
                                marginTop: "40px"
                            }}>
                                <Box width="250px" className="letter_box">
                                    <Typography className="letter_from_typo">{"A letter from"}</Typography>
                                    <Typography className="letter_from_typo">
                                        {`${member?.first_name ? member.first_name : profile?.first_name}${coMember && coMember.first_name ? " and " + coMember.first_name : ""}.`}
                                    </Typography>
                                </Box>
                                <Box marginTop="5%" height="100%" width="350px" className="letter_back">
                                    <Typography className="letter_typo p-break">
                                        {letter ?? ""}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid
                                item
                                style={{
                                    width: "100%",
                                    height: 370,
                                    minHeight: "370px",
                                    marginTop: "85px",
                                    backgroundPosition: "center",
                                    backgroundRepeat: "no-repeat",
                                    display: "flex"
                                }}>
                                <Box width="50%" className="box-profile">
                                    <Card className="profile_image">
                                        <CardActionArea>
                                            <CardMedia
                                                component="img"
                                                height="100%"
                                                image={member?.profile_picture || "https://v2-wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/assets/placeholders/Profile.png"}
                                                alt="Wiseberry - Agent Profile"
                                                className="profile_image_card"
                                            />
                                        </CardActionArea>
                                    </Card>
                                </Box>

                                <Box marginTop="20px" width={300} height="100%">
                                    <Typography variant="body1" className=" bold text_white">
                                        {member?.full_name || `${profile?.first_name} ${profile?.last_name}`}
                                    </Typography>
                                    <Typography variant="body1" className="into_typo">
                                        {member?.emails || (profile?.emails?.length > 0 ? profile?.emails[0].value : null)}
                                    </Typography>
                                    <Typography variant="body1" className="into_typo">
                                        {member?.phone_number || (profile?.phones?.length > 0 && profile?.phones[0].value)}
                                    </Typography>
                                    <Typography variant="body1" className="into_typo p-break">
                                        {intro ?? ""}
                                    </Typography>
                                </Box>
                            </Grid>
                            {coMember && coMember.first_name && (
                                <Divider className="coAgentDivider"/>
                            )}

                            {coMember && coMember.first_name && (
                                <Grid
                                    item
                                    style={{
                                        width: "100%",
                                        height: 370,
                                        minHeight: "370px",
                                        marginTop: "20px",
                                        backgroundPosition: "center",
                                        backgroundRepeat: "no-repeat",
                                        display: "flex"
                                    }}>

                                    {/*<Box className="co-profile-back">*/}
                                    {/*</Box>*/}
                                    <Box marginTop="20px" width={300} height="100%">
                                        <Typography variant="body1" className="text_white bold">
                                            {coMember?.full_name}
                                        </Typography>
                                        <Typography variant="body1" className="into_typo">
                                            {coMember?.emails ?? "-"}
                                        </Typography>
                                        <Typography variant="body1" className="into_typo">
                                            {coMember?.phone_number ?? "-"}
                                        </Typography>
                                        <Typography variant="body1" className="into_typo p-break">
                                            {coIntro ?? ""}
                                        </Typography>
                                    </Box>
                                    <Box width="50%" className="box-profile">
                                        <Card className="profile_image"
                                              height={coMember && coMember.profile_picture ? "80%" : "100%"}>
                                            <CardActionArea>
                                                <CardMedia
                                                    component="img"
                                                    height={"100%"}
                                                    image={coMember?.profile_picture || "https://v2-wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/assets/placeholders/Profile.png"}
                                                    alt="Wiseberry - Agent Profile"
                                                    className="profile_image_card"
                                                />
                                            </CardActionArea>
                                        </Card>
                                    </Box>
                                </Grid>
                            )}
                        </>
                    }

                    {micrositeType != 1
                        &&
                        <Grid item style={{
                            display: "flex",
                            alignItems: "flex-end",
                            width: "100%",
                            marginTop: "40px"
                        }}>
                            <Box marginTop="5%" height="100%" width="100%" className="secondary_letter_back">
                                <Typography className="secondary_letter_typo p-break">
                                    {letter ?? ""}
                                </Typography>
                            </Box>
                        </Grid>
                    }
                    {micrositeType == 2 &&
                        <>
                            <Grid item md={12} style={{alignSelf: "center"}}>
                                <img src={whatsNextBlock} width="100%" height="auto"/>
                            </Grid>
                        </>
                    }
                </Box>
            </Grid>

            {micrositeType === 1 &&
                <Grid item>
                    <img src={officesBlock}/>
                </Grid>
            }
            {/*//listed component sold*/}
            {micrositeType === 2 &&

                <>
                    <Grid container maxWidth={"600px"} justifyContent={"center"} alignContent={"center"}
                          className={"listedBlockGrid"}>
                        <Grid md={12} item width="100%" height="auto">
                            <img src={wbSalesProcessBlock} width="100%"/>
                        </Grid>
                        {blogs &&
                            <Grid md={12} item width="100%" height="auto">
                                <img src={ourBestTips} width="100%"/>
                            </Grid>
                        }
                    </Grid>

                    {gettingSold &&
                        <Grid md={12} item width="100%" height="600px">
                            <img src={gettingSoldBlock} width="100%"/>
                        </Grid>
                    }
                    <Grid container maxWidth={"600px"} justifyContent={"center"} alignContent={"center"}
                          className={"listedBlockGrid"}>
                        <Grid md={12} item width="100%" height="auto">
                            <img src={listedPropertyBlock} width="100%"/>
                        </Grid>
                        <Grid md={12} item width="100%" height="auto">
                            <img src={recommendedPriceGuideBlock} width="100%"/>
                        </Grid>
                        <Grid item md={12} style={{textAlign: "left"}}>
                            <Typography
                                className="adderess_typo">Your Agent. </Typography>
                        </Grid>
                        <Grid
                            item
                            style={{
                                width: "100%",
                                height: 370,
                                minHeight: "370px",
                                marginTop: "10px",
                                backgroundPosition: "center",
                                backgroundRepeat: "no-repeat",
                                display: "flex"
                            }}>
                            <Box width="50%" className="box-profile">
                                <Card className="profile_image">
                                    <CardActionArea>
                                        <CardMedia
                                            component="img"
                                            height="100%"
                                            image={member?.profile_picture || "https://v2-wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/assets/placeholders/Profile.png"}
                                            alt="Wiseberry - Agent Profile"
                                            className="profile_image_card"
                                        />
                                    </CardActionArea>
                                </Card>
                            </Box>

                            <Grid container marginTop="20px" xs={6} height="100%" direction="column"
                                  justifyContent="center" alignItems="flex-start">
                                <Typography variant="body1" className=" bold text_white">
                                    {member?.full_name || `${profile?.first_name} ${profile?.last_name}`}
                                </Typography>
                                <Typography variant="body1" className="into_typo" style={{maxWidth:"300px"}}>
                                    {member?.emails || (profile?.emails?.length > 0 ? profile?.emails[0].value : null)}
                                </Typography>
                                <Typography variant="body1" className="into_typo">
                                    {member?.phone_number || (profile?.phones?.length > 0 && profile?.phones[0].value)}
                                </Typography>
                                <Button
                                    size="small"
                                    variant="outlined"
                                    className="add_contact"
                                    mt={5}
                                >
                                    Add to Contacts
                                </Button>

                            </Grid>
                        </Grid>


                        {coMember?.first_name &&
                            <>
                                <Divider width={"600px"} style={{background:"white"}}/>
                                <Grid
                                    item
                                    style={{
                                        width: '100%',
                                        height: 370,
                                        minHeight: "370px",
                                        marginTop: "10px",
                                        backgroundPosition: "center",
                                        backgroundRepeat: "no-repeat",
                                        display: "flex"
                                    }}>

                                    <Grid container marginTop="20px" xs={6} height="100%" direction="column"
                                          justifyContent="center" alignItems="flex-end">
                                        <Typography variant="body1" className=" bold text_white">
                                            {coMember?.full_name || `${profile?.first_name} ${profile?.last_name}`}
                                        </Typography>
                                        <Typography variant="body1" className="into_typo" style={{maxWidth:"300px"}}>
                                            {coMember?.emails || (profile?.emails?.length > 0 ? profile?.emails[0].value : null)}
                                        </Typography>
                                        <Typography variant="body1" className="into_typo">
                                            {coMember?.phone_number || (profile?.phones?.length > 0 && profile?.phones[0].value)}
                                        </Typography>
                                        <Button
                                            size="small"
                                            variant="outlined"
                                            className="add_contact"
                                            mt={5}
                                        >
                                            Add to Contacts
                                        </Button>

                                    </Grid>

                                    <Box width="300px" className="box-profile">
                                        <Card className="profile_image">
                                            <CardActionArea>
                                                <CardMedia
                                                    component="img"
                                                    height="100%"
                                                    image={coMember?.profile_picture || "https://v2-wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/assets/placeholders/Profile.png"}
                                                    alt="Wiseberry - Agent Profile"
                                                    className="profile_image_card"
                                                />
                                            </CardActionArea>
                                        </Card>
                                    </Box>
                                </Grid>
                            </>
                        }
                    </Grid>


                </>
            }

            {/* Not listed component*/}

            {micrositeType === 3 &&

                <>
                    <Grid container maxWidth={"600px"} justifyContent={"center"} alignContent={"center"}
                          className={"listedBlockGrid"}>
                        <Grid md={12} item width="100%" height="auto">
                            <img src={yourPropertyBlock} width="100%"/>
                        </Grid>
                        <Grid md={12} item width="100%" height="auto">
                            <img src={recommendedPriceGuideBlock} width="100%"/>
                        </Grid>
                        <Grid md={12} item width="100%" height="auto">
                            <img src={localMarketAnalysisBlock} width="100%"/>
                        </Grid>
                        {/*this one with swith case*/}
                        {selling && (
                            <Grid md={12} item width="100%" height="auto">
                                <img src={wbSalesProcessBlock} width="100%"/>
                            </Grid>
                        )
                        }

                        {/*agent block code*/}
                        <Grid item md={12} style={{textAlign: "left"}}>
                            <Typography
                                className="adderess_typo">Your Agent. </Typography>
                        </Grid>
                        <Grid
                            item
                            style={{
                                width: "100%",
                                height: 370,
                                minHeight: "370px",
                                marginTop: "10px",
                                backgroundPosition: "center",
                                backgroundRepeat: "no-repeat",
                                display: "flex"
                            }}>
                            <Box width="50%" className="box-profile">
                                <Card className="profile_image">
                                    <CardActionArea>
                                        <CardMedia
                                            component="img"
                                            height="100%"
                                            image={member?.profile_picture || "https://v2-wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/assets/placeholders/Profile.png"}
                                            alt="Wiseberry - Agent Profile"
                                            className="profile_image_card"
                                        />
                                    </CardActionArea>
                                </Card>
                            </Box>

                            <Grid container marginTop="20px" xs={6} height="100%" direction="column"
                                  justifyContent="center" alignItems="flex-start">
                                <Typography variant="body1" className=" bold text_white">
                                    {member?.full_name || `${profile?.first_name} ${profile?.last_name}`}
                                </Typography>
                                <Typography variant="body1" className="into_typo" style={{maxWidth:"300px"}}>
                                    {member?.emails || (profile?.emails?.length > 0 ? profile?.emails[0].value : null)}
                                </Typography>
                                <Typography variant="body1" className="into_typo">
                                    {member?.phone_number || (profile?.phones?.length > 0 && profile?.phones[0].value)}
                                </Typography>
                                <Button
                                    size="small"
                                    variant="outlined"
                                    className="add_contact"
                                    mt={5}
                                >
                                    Add to Contacts
                                </Button>

                            </Grid>
                        </Grid>
                        {coMember?.first_name &&
                            <>
                                <Divider width={"600px"} style={{background:"white"}}/>
                                <Grid
                                    item
                                    style={{
                                        width: '100%',
                                        height: 370,
                                        minHeight: "370px",
                                        marginTop: "10px",
                                        backgroundPosition: "center",
                                        backgroundRepeat: "no-repeat",
                                        display: "flex"
                                    }}>

                                    <Grid container marginTop="20px" xs={6} height="100%" direction="column"
                                          justifyContent="center" alignItems="flex-end">
                                        <Typography variant="body1" className=" bold text_white">
                                            {coMember?.full_name || `${profile?.first_name} ${profile?.last_name}`}
                                        </Typography>
                                        <Typography variant="body1" className="into_typo" style={{maxWidth:"300px"}}>
                                            {coMember?.emails || (profile?.emails?.length > 0 ? profile?.emails[0].value : null)}
                                        </Typography>
                                        <Typography variant="body1" className="into_typo">
                                            {coMember?.phone_number || (profile?.phones?.length > 0 && profile?.phones[0].value)}
                                        </Typography>
                                        <Button
                                            size="small"
                                            variant="outlined"
                                            className="add_contact"
                                            mt={5}
                                        >
                                            Add to Contacts
                                        </Button>

                                    </Grid>

                                    <Box width="300px" className="box-profile">
                                        <Card className="profile_image">
                                            <CardActionArea>
                                                <CardMedia
                                                    component="img"
                                                    height="100%"
                                                    image={coMember?.profile_picture || "https://v2-wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/assets/placeholders/Profile.png"}
                                                    alt="Wiseberry - Agent Profile"
                                                    className="profile_image_card"
                                                />
                                            </CardActionArea>
                                        </Card>
                                    </Box>
                                </Grid>
                            </>
                        }
                        {/*finished agent block code*/}
                        {agentResult && <Grid md={12} item width="100%" height="auto">
                            <img src={agentSalesBlock} width="100%"/>
                        </Grid>}

                        {recentlySold && <Grid md={12} item width="100%" height="auto">
                            <img src={recentlySlodBlock} width="100%"/>
                        </Grid>
                        }
                        {/*testimonals switch*/}
                        {testimonial && (
                            <Grid md={12} item width="100%" height="auto">
                                <img src={testimonalBlock} width="100%"/>
                            </Grid>
                        )
                        }


                        <Grid md={12} item width="100%" height="auto">
                            <img src={acceptFooterBlock} width="100%"/>
                        </Grid>
                    </Grid>

                    <Grid container maxWidth={"600px"} justifyContent={"center"} alignContent={"center"}
                          className={"listedBlockGrid"}>

                    </Grid>


                </>


            }
            {
                micrositeType === 1 && selling && (
                    <Grid item width={"100%"} height={"auto"}>
                        <img src={processBlock}/>
                    </Grid>
                )
            }
            {
                traps && (
                    <Grid item>
                        <img src={trapsBlock}/>
                    </Grid>
                )
            }
            {
                redflags && (
                    <Grid
                        item
                        style={{
                            backgroundColor: "#e9d0cf",
                            width: "100%",
                            textAlign: "center",
                        }}
                    >
                        <img src={redflagsBlock}/>
                    </Grid>
                )
            }
            {
                micrositeType == 1 && team && (
                    <Grid item>
                        <img src={teamBlock}/>
                    </Grid>
                )
            }
            {
                micrositeType == 1 && values && (
                    <Grid item>
                        <img src={valuesBlock}/>
                    </Grid>
                )
            }
            {
                ceohandle && (
                    <Grid
                        item
                        style={{
                            backgroundColor: "#f5f5f5",
                            width: "100%",
                            textAlign: "center",
                        }}
                    >
                        <img src={ceoBlock}/>
                    </Grid>
                )
            }

            {micrositeType != 3
                &&

                <Grid item>
                    <img src={careBlock}/>
                </Grid>
            }
            {micrositeType == 1 && testimonial && (
                <Grid item>
                    <img src={testimonialBlock}/>
                </Grid>
            )
            }
            {
                comm && (
                    <Grid item style={{backgroundColor: "#f5f5f5", borderRadius: 40}}>
                        <img src={commBlock}/>
                    </Grid>
                )
            }
            {micrositeType != 3
                &&
                <Grid item>
                    <img src={footerBlock}/>
                </Grid>
            }
        </Grid>

    )
        ;
};

export default PreListingTemplatePreview;
