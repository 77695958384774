import React, { useState, createContext, useEffect } from "react";
import moment from "moment";
import { toast } from "react-toastify";

import { useSelector } from "react-redux";
import { useCallback } from "react";
import { formToQueryString } from "../../../../_helpers/_utils/utils";
import {
  getAllMicrosite,
  getAllMicrositeTemplate,
} from "../../crud/socialMedailCrud";
import { getAllMembers } from "../../../setting/_crud/settingCrud";

import { getAllDiaryAppointmentList } from "../../../diary/_curd/diaryCurd";
// import { getAllTestimonials } from "../../../setting/_crud/settingCrud";
// import { getAllCreatedTemplate } from "../../../mail/curd/templateCrud";
// import { getAllDiaryAppointmentList } from "../../../diary/_curd/diaryCurd";

export const MicrositesContext = createContext();
export const { Consumer } = MicrositesContext;

// const queryString = require("query-string");

const MicrositesContextProvider = (props) => {
  const [template, setAutomatedMail] = useState([]);
  const [currentMicrosite, setCurrentMicrosite] = useState({});

  const [microsites, setMicrosites] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [members, setMembers] = useState([]);
  const [isLoadingMembers, setIsLoadingMembers] = useState(false);

  const [appointments, setAppointments] = useState([]);
  const [isLoadingAppointments, setIsLoadingAppointments] = useState(false);

  const [availableTemplates, setAvailableTemplates] = useState([]);
  const [isLoadingTemplates, setIsLoadingTemplates] = useState([]);

  const [continuousMode, setContinuousMode] = useState(false);
  const [otherResData, setOtherResData] = useState(null);
  const [prospectQuery, setProspectQuery] = useState(null);

  useEffect(() => {
    update();
  }, []);

  const getMicrosites = (params = null, loadMore = false) => {
    setIsLoading(true);
    getAllMicrosite(formToQueryString(params))
      .then((res) => {
        if (res.status === 200) {
            if (res?.data?.data?.data.length === 0) {
                // alert("No more prospect")
                setIsLoading(false);
                toast.error("No more microsites");
            }
            if(loadMore){
                setMicrosites(microsites.concat(res?.data?.data?.data));
            }
            else{
                setMicrosites(res?.data?.data?.data);
            }
            setOtherResData(res?.data?.data)
        } else {
          toast.error("Error loading microsites");
        }
      })
      .catch((err) => toast.error(`Error requesting microsites (${err})`))
      .finally(() => setIsLoading(false));
  };

  const onSubmitDrawerForm = (values) => {
    setProspectQuery(values);
    update(values);
  };

  const fetchMoreData = () => {
    let obj = {
      ...prospectQuery,
    };
    obj.page = otherResData?.current_page + 1;
    obj.loadMore = true;
    // console.log(obj);
    // let query = formToQueryString(obj);
    if (otherResData?.current_page !== otherResData?.last_page) {
      update(obj);
    }
  };
  const handleGlobalSearch = (value) => {
    let obj = {};
    if (value) {
      obj.search_kw = value;
    }
    update(obj);
  };

  const update = (values = null) => {
      let loadMore, merge = false;
      let obj = {
          ...values
      };
      if (values?.loadMore) {
          obj.loadMore = values?.loadMore
      }

      getMicrosites(obj, (values?.loadMore ?? false));
  };

  const handleSort = useCallback((sortBy) => {
    let obj = {};
    let sort_query;
    if (sortBy?.[0]) {
      obj.sort_by = sortBy[0].id;

      if (sortBy[0].desc) {
        obj.direction = "desc";
      } else {
        obj.direction = "asc";
      }
      setProspectQuery({ ...prospectQuery, ...obj });
      update(obj);
    } else {
      update();
    }
  }, []);

    const getListOfAppointments = (for_contact = null, apptType = 4, limit = 100) => {
        setIsLoadingAppointments(true);
        let queryString = `type=${apptType}&limit=${limit}&from=${moment().format("YYYY-MM-DD")}`;

        if (for_contact) {
            queryString += `&for_contact=${for_contact}`;
        }
        getAllDiaryAppointmentList(queryString)
          .then((res) => {
            if (res?.status === 200) {
              const formattedAppointments = [];
              const data = res.data.data;
              console.log("data", data);
              data.forEach((appointment) => {
                const address = appointment?.prospect[0]?.property?.address;
                const formattedAddress = appointment?.meeting_location;
                formattedAppointments.push({
                  id: appointment.id,
                  for_contact_initial: appointment?.for_contact?.initial,
                  for_contact_id: appointment?.for_contact?.contact?.id,
                  date: new Date(appointment?.starts).toLocaleDateString("en-US", { year: "numeric", month: "long", day: "numeric" }),
                  name: `${address?.street_unit ? `${address?.street_unit} ` : ""}${
                    address?.street_number
                  } ${formattedAddress?.street?.name}, ${address?.street?.suburb?.name}`,
                  suburb: address?.street?.suburb?.name,
                  street_no: address?.street_number,
                  street: address?.street?.name,
                  formattedDate : new Date(appointment?.starts).toLocaleString("en-US", { month: "long", day: "numeric", year: "numeric", weekday: "long", hour: "numeric", minute: "2-digit" }),
                  formattedStreet :formattedAddress?.street?.name
                });
              });
              setAppointments(formattedAppointments);
            } else {
              setAppointments([]);
            }
          })
          .catch((err) => {
            setAppointments([]);
          })
          .finally(() => setIsLoadingAppointments(false));
  };

  const getListOfMembers = (type = "dropdown") => {
    setIsLoadingMembers(true);
    getAllMembers("active=1&limit=100")
      .then((res) => {
        if (res?.status === 200) {
          if (type === "dropdown") {
            const formattedMembers = [];
            const data = res.data.data;
            data.forEach((member) => {
              formattedMembers.push({
                id: member.id,
                name: `${member?.contact?.first_name} ${member?.contact?.last_name} (${member?.initial})`,
                bio: member?.bio ?? "",
                contact_for:member?.contact?.id,
                profile_picture: member?.contact?.profile_picture,
                first_name: member?.contact?.first_name,
                emails: member?.contact?.emails?.[0]?.value,
                full_name: `${member?.contact?.first_name} ${member?.contact?.last_name}`,
                phone_number: member?.contact?.phones?.[0]?.value
              });
            });
            setMembers(formattedMembers);
          } else {
            setMembers(res.data.data);
          }
        } else {
          setMembers([]);
        }
      })
      .catch((err) => {
        setMembers([]);
      })
      .finally(() => setIsLoadingMembers(false));
  };

  const getListOfTemplates = (for_contact = null, active = 1, limit = 100) => {
    setIsLoadingTemplates(true);
      let queryString = `active=${active}&limit=${limit}`;

      if (for_contact) {
          queryString += `&for=${for_contact}`;
      }
    getAllMicrositeTemplate(queryString)
      .then((res) => {
        if (res?.status === 200) {
          const formattedTemplates = [];
          const data = res.data.data;
          data.forEach((template) => {
            formattedTemplates.push({
              id: template.id,
              name: template?.name,
              settings: { ...template },
            });
          });
          setAvailableTemplates(formattedTemplates);
        } else {
          setAvailableTemplates([]);
        }
      })
      .catch((err) => {
        setAvailableTemplates([]);
      })
      .finally(() => setIsLoadingTemplates(false));
  };

  return (
    <MicrositesContext.Provider
      value={{
        template,
        continuousMode,
        setContinuousMode,
        onSubmitDrawerForm,
        fetchMoreData,
        otherResData,
        handleGlobalSearch,
        updateTableData: update,
        handleSort,
        microsites,
        setMicrosites,
        getMicrosites,
        isLoading,
        setIsLoading,
        appointments,
        getListOfAppointments,
        isLoadingAppointments,
        members,
        setMembers,
        getListOfMembers,
        isLoadingMembers,
        availableTemplates,
        getListOfTemplates,
        isLoadingTemplates,
        currentMicrosite,
        setCurrentMicrosite,
        update,
      }}
    >
      {props.children}
    </MicrositesContext.Provider>
  );
};

export default MicrositesContextProvider;
