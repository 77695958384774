import React, {useState, useEffect} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {getAllMember, submitUserOffice, switchAccount} from "../../GeneralApi/GeneralApi";
import {useDispatch} from 'react-redux'
import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import ListItemAvatar from '@material-ui/core/ListItemAvatar';


import {actions} from '../../modules/auth/_redux/auth.duck'

const AccountSwitch = ({open, onClose}) => {
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(open && options.length === 0);
    const [value, setValue] = React.useState('');
    const [errorMessage, setErrorMessage] = React.useState('');
    const [secretKey, setSecreteKey] = React.useState('');
    const handleClose = () => {
        setLoading(false);
        onClose();
    };
    const imageUrl = "https://via.placeholder.com/40";
    const dispatch = useDispatch()

    const [error, setError] = useState(false);

    const account_switch_autocomplete = {
        width: '500px !important',

    }
    useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }
        // const member = await getAllMember();
        (async () => {
            const member_data = await getAllMember();
            // const member = await member_data.json();
            const members = member_data.data.data;

            if (active) {
                setOptions(members.map((item) => ({
                    label: item.login_username, // Modify this to match the data structure
                    value: item.login_username,
                    organisation: item.organisation_name,
                    imageUrl: item?.profile_image_url,
                    contact_name: item.contact_name
                    // Modify this to match the data structure
                })));
            }
        })();

        return () => {
            active = false;
        };
    }, [loading]);

    useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    const switchAccountRequest = () => {
        if (!secretKey || !value) {
            // Either the Key or the Autocomplete value is empty, set the error state to true
            setErrorMessage('Please enter a Key and select an Account.');
            setError(true);
        } else {
            // Both fields have values, proceed with the switch request
            setErrorMessage('');
            setError(false); // Reset the error state
            let params = {
                username: value.value,
                key: secretKey
            }
            switchAccount(params)
                .then((res) => {
                    dispatch(actions.login(res?.data?.access_token));
                })
                .catch((err) => {
                    setErrorMessage(err?.data?.message);
                    setError(true);
                });
        }
    };


    const sleep = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    const listItemStyle = {
        width: '100%',
        display: 'flex', // Make the ListItem a flex container
        alignItems: 'center', // Vertically align the contents
        gap: '16px', // Add space between Avatar and ListItemText
    };

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="md" disableEscapeKeyDown={true}>
            <DialogTitle id="form-dialog-title">Account Switch</DialogTitle>
            <DialogContent>
                {error && (
                    <Typography variant="body2" color="error">
                        {errorMessage}
                    </Typography>
                )}
                <TextField
                    id="standard-basic"
                    label="Key"
                    style={{width:'500px'}}
                    helperText="Head office's use only "
                    value={secretKey} // Set the value of the TextField to the state variable
                    onChange={(event) => {
                        setSecreteKey(event.target.value); // Update the state with the new value
                    }}
                />

                <Autocomplete
                    id="grouped-demo"
                    options={options}
                    getOptionLabel={(option) => option.label}
                    style={{ width: '500px', important: 'true' }}
                    className={"account_switch_autocomplete"}
                    renderInput={(params) => (
                        <TextField {...params} label="Account"/>
                    )}
                    PaperProps={{
                        style: {
                            width: '500px', // Make the dropdown the same width as the input
                        },
                    }}
                    onChange={(event, newValue) => {
                        setValue(newValue);
                    }}
                    renderstyle={{width: '500px'}}
                    renderOption={(option) => (
                        <ListItem style={listItemStyle}>
                            <ListItemAvatar>
                                <Avatar alt={option.label} src={option?.imageUrl || imageUrl} />
                            </ListItemAvatar>

                            <ListItemText
                                primary={option.contact_name}
                                secondary={
                                    <React.Fragment>
                                        <span>{`${option.value} | ${option.organisation}`}</span>
                                    </React.Fragment>
                                }
                            />
                        </ListItem>
                    )}
                />
                <div>
                    <Typography variant={'h6'} style={{marginTop:"20px"}}>Selected User Details</Typography>
                    {value && (
                        <Card style={{display: 'flex'}}>
                            <div sx={{width: 100}}>
                                <CardMedia
                                    component="img"
                                    alt={value.label}
                                    height= '120'
                                    sx={{width: 150}}
                                    image={value?.imageUrl || imageUrl}
                                    title={value.contact_name}
                                />
                            </div>

                            <CardContent>
                                <Typography gutterBottom variant="h5" component="h2">
                                    {value.contact_name}
                                </Typography>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    Username: {`${value.value}`}
                                </Typography>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    Organisation: {`${value.organisation}`}
                                </Typography>
                            </CardContent>
                        </Card>
                    )}

                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} className="btn darkGrey-button btn-sm btn-bold w-50 text-white">
                    Cancel
                </Button>
                <Button onClick={switchAccountRequest} className="btn green-button btn-sm btn-bold w-50 text-white">
                    Switch
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default AccountSwitch;
