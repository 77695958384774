import React, { useState, Fragment, useEffect } from "react";
import { Form, Col, Row } from "react-bootstrap";
import { reduxForm, Field, getFormValues, getFormSyncErrors } from "redux-form";
import { connect } from "react-redux";
import {
  combineValidators,
  isRequired,
  composeValidators,
  hasLengthGreaterThan,
} from "revalidate";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./addTomail.scss";
import ThemeHeader from "../../content/ThemeHeader";
import ThemeButton from "../../button/ThemeButton";
import DateInput from "../../form/DateInput";
import MultiSelect from "../../form/MultiSelect";
import TextInput from "../../form/TextInput";
import TextAreaEmoji from "../../form/TextAreaEmoji";
import { getAllMembers, getMenu } from "../../../GeneralApi/GeneralApi";
import { toast } from "react-toastify";
import ShowError from "../../display/ShowError";

const validate = combineValidators({
  from_contact: isRequired({ message: "From is required" }),
  created_at: isRequired({ message: "Date is required" }),
  type: isRequired({ message: "Type is required" }),
  email_subject: isRequired({ message: "Subject is required" }),
  content: composeValidators(
    isRequired({ message: "Please enter a description" }),
    hasLengthGreaterThan(8)({
      message: "Please enter a description",
    })
  )(),
});

const AddToMail = (props) => {
  const [show, setShow] = useState(true);
  const {
    handleSubmit,
    pristine,
    additionalContacts = [],
    submitting,
    invalid,
    currentContact,
    fieldValues,
    onHide,
    submitErrors,
  } = props;

  const [members, setMembers] = useState(null);
  const [contact_method, setContact_method] = useState(null);

  useEffect(() => {
    getAllMembers()
      .then(({ data: { data } }) => {
        let officeMembers = data.map((s) => ({
          name: `${s.initial}`,
          id: s.contact.id,
          optionTooltipTitle:`${s?.contact?.first_name ?? ''} ${s?.contact?.last_name ?? ''}`,
        }));
        console.log(data)
        setMembers([...officeMembers, currentContact]);
      })
      .catch((e) => {
        toast.error("Error while fetching");
      });

    let query = "contact_method";
    getMenu(query)
      .then(({ data: { data } }) => {
        setContact_method(data.contact_method);
      })
      .catch(() => { });
  }, []);


  return (
    <Fragment>
      <div className="add-to-email-modal">
        <form className="add-to-email" onSubmit={handleSubmit}>
          <Fragment>
            <ThemeHeader
              left={
                <Fragment>
                  <h5 className="title">Add to Mail Log</h5>
                </Fragment>
              }
              right={
                <Fragment>
                  <Fragment>
                    <div className="pl-2">
                      <ThemeButton
                        className="lightGrey-button"
                        onClick={onHide}
                      >
                        Cancel
                      </ThemeButton>
                    </div>
                    <div className="pl-2">
                      <ThemeButton
                        type="submit"
                        disabled={invalid || pristine || submitting}
                        // onClick={()=>handleAdjustPrice()}
                        className="submit-button"
                      >
                        Save
                      </ThemeButton>
                    </div>
                  </Fragment>
                </Fragment>
              }
            />
            <Form.Row className="pb-2">
              <Col md={4}>
                <Field
                  name="created_at"
                  showTimeSelect
                  component={DateInput}
                  type="date"
                  placeholder="Date"
                  label="Date"
                  required
                />
              </Col>
              <Col md={4}>
                <Field
                  required
                  name="from_contact"
                  showTimeSelect
                  component={MultiSelect}
                  showBottom={false}
                  options={members}
                  isMulti={false}
                  placeholder="From"
                  label="From"
                />
              </Col>
              <Col md={4}>
                <Field
                  name="type"
                  showTimeSelect
                  component={MultiSelect}
                  showBottom={false}
                  isMulti={false}
                  options={contact_method}
                  placeholder="Type"
                  label="Type"
                  required
                />
              </Col>
            </Form.Row>
            {fieldValues?.type == 1}
            <Form.Row className="pb-4">
              <Col>
                <Field
                  name="email_subject"
                  placeholder="Subject"
                  component={TextInput}
                  label="Subject"
                  required
                />
              </Col>
            </Form.Row>
            <Form.Row>
              <Col md={12}>
                <Form.Group as={Row} controlId="formHorizontalPassword">
                  <Col sm={12}>
                    {/* <EditorField
                      key="field"
                      name="editorText"
                      id="inputEditorText"
                      disabled={false}
                      placeholder="Type here"
                    /> */}

                    <Field
                      name="content"
                      type="textarea"
                      rows="3"
                      options={{
                        options: [
                          "inline",
                          "blockType",
                          "fontSize",
                          "list",
                          "textAlign",
                          "history",
                          "link",
                          "image",
                        ],
                        inline: { inDropdown: true },
                        list: { inDropdown: true },
                        textAlign: { inDropdown: true },
                        link: { inDropdown: true },
                        history: { inDropdown: true },
                      }}
                      component={TextAreaEmoji}
                      {...(fieldValues?.content?.length > 0 && {
                        initialValues: fieldValues?.content,
                      })}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Form.Row>
          </Fragment>
        </form>

        <ShowError errors={submitErrors} />
      </div>
    </Fragment>
  );
};

// const selector = formValueSelector("adjustPrice");
const mapState = (state) => {
  const values = getFormValues("add-to-mail")(state);
  console.log(values, "form_values mapstate");

  return {
    fieldValues: values,
    submitErrors: getFormSyncErrors("add-to-mail")(state),
  };
};
export default connect(
  mapState,
  null
)(
  reduxForm({ form: "add-to-mail", enableReinitialize: true, validate })(
    AddToMail
  )
);
