import axios from "axios";

const WiseBerryAPIEnv = process.env.REACT_APP_WISEBERRY_API;

const CONTACTS_URL = WiseBerryAPIEnv + "contact";

let ajaxRequest = null;

// CREATE =>  POST: add a new contact to the server
export function createNewContact(prams, origin) {

  if (origin == 'dk') {
    return axios.post(CONTACTS_URL + "?origin=dk", prams, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  } else {
    return axios.post(CONTACTS_URL, prams, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

export function updateContact(id, prams) {
  return axios.post(CONTACTS_URL + "/" + id, prams, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

// READ
export function getAllContacts(prams) {


  // cancel previous ajax if exists
  if (ajaxRequest) {
    ajaxRequest.cancel();
  }

  // creates a new token for upcomming ajax (overwrite the previous one)
  ajaxRequest = axios.CancelToken.source();
  const querySuffix = prams.startsWith("&") ? "" : "&";
  return axios.get(CONTACTS_URL + "?limit=30" + (prams ? querySuffix + prams : ""), {
    cancelToken: ajaxRequest.token,
  });

}

export function getContactByAddress(data) {
  return axios.get(CONTACTS_URL + "/find-by-name-address?" + data);
}
export function getContactById(id) {
  return axios.get(CONTACTS_URL + "/" + id);
}

// Notes

export function getNotesByContactId(prams) {
  return axios.get(CONTACTS_URL + "/notes?contact=" + prams);
}
// Timeline
export function getNotesByContactIdAndCreatedBy(prams) {
  return axios.get(CONTACTS_URL + "/notes?" + prams);
}

export function getTimelineByContactId(prams) {
  return axios.get(WiseBerryAPIEnv + "timeline?" + prams);
}

// get info

export function getTimelineInfo(id) {
  return axios.get(WiseBerryAPIEnv + "timeline/info?id=" + id);
}
// Timeline
// Mail
// READ

export function getMailById(mailId) {
  const mail = axios.get("/mail/34", { params: { searchText: "John" } });
  //   const contact = JSON.stringify(contacs.data.filter(contact => contact.id === contactId));
  // console.log(mail);
  return mail;
  // return axios.get(`${CONTACTS_URL}/${contactId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findContacts(queryParams) {
  return axios.post(`${CONTACTS_URL}/find`, { queryParams });
}

// UPDATE Status
export function updateStatusForContacts(ids, status) {
  return axios.post(`${CONTACTS_URL}/updateStatusForContacts`, {
    ids,
    status,
  });
}

export function deleteContact(id) {
  return axios.delete(`${CONTACTS_URL}/${id}`);
}

// File Upload
export function getFilesByContact(id) {
  return axios.get(CONTACTS_URL + "/files?contact=" + id);
}

export function uploadFilesByContact(prams) {
  return axios.post(`${WiseBerryAPIEnv}asset`, prams, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function fileDelete(id) {
  return axios.delete(`${WiseBerryAPIEnv}asset/` + id);
}

// File Upload

export function getDuplicateContacts(id) {
  return axios.get(CONTACTS_URL + "/duplicates?contact=" + id);
}

// Merge Contacts

export function contactMerge(prams) {
  return axios.post(CONTACTS_URL + "/merge", prams, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

// Birthday

export function getContactBirthday() {
  return axios.get(CONTACTS_URL + "/birthdays");
}

export function bulkDeleteContact(prams) {
  return axios.post(CONTACTS_URL + "/bulk-delete", prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}
