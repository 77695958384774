import React, { Fragment } from 'react';
import {useDropzone} from 'react-dropzone';
import { Card } from 'react-bootstrap';
import { IconButton } from '@material-ui/core';
import { formatBytes, getFileType } from '../../_helpers/_utils/utils';
import ThemeButton from '../button/ThemeButton';

const DragAndDrop = ({
  fields,acceptedFileType,uploadType,handleUploadFiles,multiple = true

}) => {
  // const {getRootProps, getInputProps, acceptedFiles} = useDropzone({noDrag: true});
  
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: acceptedFileType,
    multiple: multiple,
    // onDrop: files => onChange(files)
    onDrop: (acceptedFiles) => {
      if (typeof handleUploadFiles !== "undefined") { 
        handleUploadFiles(acceptedFiles)
       }
       else {
         let files = acceptedFiles.map((file) =>
         Object.assign(file, {
           preview: URL.createObjectURL(file),
         })
       )
       files.map((file) =>fields.push(file));
       }
      // acceptedFiles.map((file) =>fields.push(file));
     
    },
  });
  // const files = acceptedFiles.map(file => <li key={file.path}>{file.path}</li>);


  return (
    <section className="w-100 pb-4">
      <div {...getRootProps({className: 'dropzone'})}>

        <input {...getInputProps()} />
        <i className="fas fa-upload"></i>
        <p>Drag and drop files to upload or Click here</p>
      </div>

      <aside className="pb-3">
        {/* <ul>{files}</ul> */}
        <div className="row pb-2">
          {fields?.getAll()?.map((file,index )=> <Fragment>
           
          <Fragment key={index}>
            <div className="col-lg-4 pb-4">
              <Card>
                <div className="card-body">
                  <div className="row text-left ">
                    {getFileType(file.name) !== ("jpg" || "png") ? (
                      <Fragment>
                        {" "}
                        <div className="col-2 file-type lightGrey-color">
                          <i
                            className={`far fa-file-${getFileType(file.name)}`}
                          ></i>
                        </div>{" "}
                      </Fragment>
                    ) : (
                      <div className="col-2 file-type image">
                        <img
                          src={file.preview}
                          className="img-thumbnail"
                        />
                      </div>
                    )}
                    <div className="col-6">
                      <h6 className="font-weight-bold">{file.name}</h6>
                    
                    </div>
                    <div className="col-4">
                      <div className="d-flex justify-content-end pb-2">
                        <ThemeButton  onClick={() => fields.remove(index)} icon className=" pr-0 lightGrey-icon-button">
                        <i
                            className="fas fa-minus-circle"
                            fontSize="small"
                          ></i>
                          </ThemeButton>
                      </div>
                      {/* <h3>2495/-</h3> */}
                      <div className="d-flex justify-content-end">
                        <p>{formatBytes(file.size)}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          </Fragment>
          </Fragment>)}
      </div>
   
      </aside>
    </section>
  );
}

export default DragAndDrop
