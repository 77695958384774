/* eslint-disable no-restricted-imports */
import React, { Fragment, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { Chip, Input, Tooltip } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: "100%",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const MaterialMultiSelect = ({
  label,
  Placeholder,
  onChange,
  value,
  otherValue,
  className,
  options,
  required,
  showDelete = false,
  restrictMaxTagCount = false,
  meta,
  error,
  helperText,
  showErrorOnMount,
  size = "medium",
  limit,
  ...input
}) => {
  const classes = useStyles();
  const [selectedValue, setSelectedValue] = useState([]);
  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  const handleChange = (event) => {
    onChange(event.target.value);
  };

  const renderOption = (options) => {
    if (options[0]?.hasOwnProperty("optionTooltipTitle")) {
      return options.map((obj, index) => (
        <MenuItem key={index} value={obj.id} disabled={obj.disabled}>
          <Tooltip
            title={
              <h6 style={{ color: "white" }} className="mt-2">
                {obj?.optionTooltipTitle ?? ""}
              </h6>
            }
            placement="top"
          >
            <div className="w-100">{obj.name}</div>
          </Tooltip>
        </MenuItem>
      ));
    } else {
      return options.map((obj, index) => (
        <MenuItem key={index} value={obj.id} disabled={obj.disabled}>
          {obj.name}
        </MenuItem>
      ));
    }
  };

  return (
    <div>
      <FormControl required={required} className={classes.formControl}>
        <InputLabel>{label}</InputLabel>
        <Select
          className={className}
          {...input}
          multiple
          value={value}
          onChange={handleChange}
          error={
            error ?? ((meta?.touched || showErrorOnMount) && meta?.invalid)
          }
          MenuProps={MenuProps}
          renderValue={(selected) => {
            if (restrictMaxTagCount) {
              let chipsToRender = options.filter((item) =>
                selected?.includes(item.id)
              );
              return (
                <Fragment>
                  {chipsToRender.slice(0, 2).map((_value, index) => (
                    <Fragment key={index}>
                      <Chip
                        key={_value.id}
                        label={_value.name}
                        className={classes.chip}
                        clickable={true}
                        onDelete={
                          showDelete
                            ? () => {
                                onChange(
                                  value.filter((item) => item !== _value.id)
                                );
                              }
                            : null
                        }
                        onMouseDown={(event) => {
                          event.stopPropagation();
                        }}
                        size={size}
                      />
                    </Fragment>
                  ))}
                  {chipsToRender.slice(2).length ? (
                    <Chip
                      key={"plus"}
                      label={`+${chipsToRender.slice(2).length}...`}
                      className={classes.chip}
                      clickable={true}
                      size={size}
                    />
                  ) : null}
                </Fragment>
              );
            } else {
              return (
                <div className={classes.chips}>
                  {options.map((_value, index) => (
                    <Fragment key={index}>
                      {selected?.includes(_value.id) ? (
                        <Chip
                          key={_value.id}
                          label={_value.name}
                          className={classes.chip}
                          clickable={true}
                          onDelete={
                            showDelete
                              ? () => {
                                  onChange(
                                    value.filter((item) => item !== _value.id)
                                  );
                                }
                              : null
                          }
                          onMouseDown={(event) => {
                            event.stopPropagation();
                          }}
                          size={size}
                        />
                      ) : (
                        ""
                      )}
                    </Fragment>
                  ))}
                </div>
              );
            }
          }}
          input={<Input />}
        >
          {otherValue?.length
            ? otherValue.map((obj, index) => (
                <MenuItem key={index} value={obj.id}>
                  {obj.name}
                </MenuItem>
              ))
            : ""}
          {options ? renderOption(options) : ""}
        </Select>
        {(meta?.touched || showErrorOnMount) && (
          <FormHelperText error>{meta?.error}</FormHelperText>
        )}
      </FormControl>
    </div>
  );
};

MaterialMultiSelect.defaultProps = {
  required: false,
};

export default MaterialMultiSelect;
