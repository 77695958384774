import React, { Fragment, useState, useEffect, useContext } from "react";
import { Row } from "react-bootstrap";
import ThemeButton from "../../../../../partials/button/ThemeButton";
import NoteLists from "../../../../../partials/component/notes/NoteLists";
import {
  convertFormData,
  formToQueryString,
  wbSuccessToast,
} from "../../../../../_helpers/_utils/utils";
import {
  getAllNotes,
  createNotes,
  updateNotes,
} from "../../../../../GeneralApi/GeneralApi";
import TenantNotesForm from "./TenantNotesForm";
import { reset } from "redux-form";
import * as tenantsRedux from "../../../_redux/tenantsRedux";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  getNotesByTenantId,
  getNotesByTenantIdAndCreatedBy,
} from "../../../crud/tenantsCrud";
import { postDoorKnock } from "../../../../buyers/crud/buyersCrud";
import { toast } from "react-toastify";
import { TenantProfileContext } from "../TenantProfileContextProvider";
import AccessControl from "../../../../../_ accessControl/AccessControl";

const TenantNotes = (props) => {
  const { contactId, profileId, tenants } = props;
  const { continuousMode, tenantSelectedChangeId, setTenantLoading } = useContext(
    TenantProfileContext
  );
  const [notes, setNotes] = useState([]);
  const [otherResData, setOtherResData] = useState([]);
  const dispatch = useDispatch();
  const permissions = useSelector((state) => state.auth.user.permissions);
  const currentUser = useSelector(({ auth }) => auth.user.contact.id);
  const is_leader = useSelector((state) => state.auth.user.is_leader);
  useEffect(() => {
    update();
  }, [profileId]);

  const update = (value) => {
    setTenantLoading(true);
    let obj = {};
    obj.tenant = profileId;

    if (profileId) {
      if (value?.page) {
        obj.page = value.page;
      }
      if (
        permissions?.some((permission) =>
          ["tenants-all"].includes(permission?.name)
        ) ||
        is_leader
      ) {
        getNotesByTenantIdAndCreatedBy(formToQueryString(obj))
          .then(({ data: { data, ...rest } }) => {
            if (value?.loadMore) {
              setNotes(notes.concat(data));
            } else {
              setNotes(data);
            }
            setOtherResData(rest);
            setTenantLoading(false);
          })
          .catch(() => { setTenantLoading(false); });
      } else if (
        permissions?.some((permission) =>
          ["tenants-self"].includes(permission?.name)
        ) ||
        is_leader
      ) {
        obj.created_by = currentUser;
        getNotesByTenantIdAndCreatedBy(formToQueryString(obj))
          .then(({ data: { data, ...rest } }) => {
            if (value?.loadMore) {
              setNotes(notes.concat(data));
            } else {
              setNotes(data);
            }
            setOtherResData(rest);
            setTenantLoading(false);
          })
          .catch(() => { setTenantLoading(false); });
      }
    }
  };

  const handleMakePinned = (noteId, isPinned) => {
    const formData = convertFormData({});
    formData.append("pinned", isPinned === 1 ? 0 : 1);
    formData.append("_method", "PATCH");
    if (profileId) {
      updateNotes(noteId, formData)
        .then(({ data: { data } }) => {
          wbSuccessToast("success", data.message);
          update();
        })
        .catch((e) => {
          console.log(e);
          // wbSuccessToast('error',data.message);
        });
    }
  };

  const handleOnSubmit = (e) => {
    console.log("e", e)
    setTenantLoading(true);
    let obj = {};

    if (e.type == "NA" && e.notes == null) {
      obj = {
        prospect: profileId,
        na: 1,
        note: { type: 2, detail: "Na Clicked", prospect: profileId },
      };
      const doorKnockData = convertFormData(obj);
      postDoorKnock(doorKnockData)
        .then(({ data: { data } }) => {
          setTenantLoading(false);
          toast.success("Na Clicked Saved");

          update();
        })
        .catch((e) => {
          console.log(e);
          setTenantLoading(false);
          // wbSuccessToast('error',data.message);
        });

      if (continuousMode) {
        tenantSelectedChangeId("right");
      }

      dispatch(reset("tenant-notes"));
      if (document.getElementById("tenant-notes-note")) {
        document.getElementById("tenant-notes-note").value = "";
      }

    } else {
      if (e.notes == null) {
        setTenantLoading(false);
        toast.error("Note is less than 5 char");
      } else {
        if (e.type) {
          if (e.type == "TC") {
            obj = {
              tenant: profileId,
              phone_call: 1,
              door_knock: 0,
              note: { ...e.notes[0], tenant: profileId },
            };
          } else {
            obj = {
              tenant: profileId,
              na: 1,
              note: { ...e.notes[0], tenant: profileId },
            };
          }

          const doorKnockData = convertFormData(obj);
          postDoorKnock(doorKnockData)
            .then(({ data: { data } }) => {
              update();
              setTenantLoading(false);
              wbSuccessToast("success", data.message);
            })
            .catch((e) => {
              console.log(e);
              setTenantLoading(false);
              // wbSuccessToast('error',data.message);
            });
        } else {
          const formData = convertFormData(e.notes[0]);
          formData.append("tenant", profileId);
          if (profileId) {
            createNotes(formData)
              .then(({ data: { data } }) => {
                wbSuccessToast("success", data.message);
                setTenantLoading(false);
                update();
              })
              .catch((e) => {
                console.log(e);
                setTenantLoading(false);
                // wbSuccessToast('error',data.message);
              });
          }

          if (continuousMode) {
            tenantSelectedChangeId("right");
          }
        }
        dispatch(reset("tenant-notes"));
        if (document.getElementById("tenant-notes-note")) {
          document.getElementById("tenant-notes-note").value = "";
        }
      }
      // document.getElementById(props.form + "-note").value = "";
      // if (e.notes[0].detail.length < 5) {
      //   console.log("too small");
      //   throw new SubmissionError({
      //     notes: "Note is less than 5 char",
      //     _error: "Note fail!",
      //   });
      // }
    }
  };

  const fetchMore = () => {
    if (otherResData?.next_page_url) {
      update({ page: otherResData.current_page + 1, loadMore: true });
    }
  }

  return (
    <Fragment>
      <TenantNotesForm onSubmit={handleOnSubmit} />
      <AccessControl
        allowedPermissions={["tenants-delete"]}
        renderNoAccess={() => ""}
      >
        <div className="d-flex justify-content-end">
          <div className="pb-2">
            <ThemeButton icon>
              <i className="fas fa-download"></i>
            </ThemeButton>
          </div>
        </div>
      </AccessControl>

      <Row></Row>
      <NoteLists makePinned={handleMakePinned} fetchMore={fetchMore} notes={notes} />
    </Fragment>
  );
};

const mapStateToProps = ({ tenants }) => ({
  tenants: tenants,
});

const actions = {
  tenantSelectedChangeId: tenantsRedux.actions.tenantSelectedChangeId,
};

export default connect(mapStateToProps, actions)(TenantNotes);
