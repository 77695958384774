import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest, delay } from "redux-saga/effects";
import {
  getAllProspects,
  getProspectById,
  getProspectMatchedData,
  getProspectTimeline,
  getZoneAnalysisData,
} from "../curd/prospectsCrud";

export const actionTypes = {
  ProspectsLoading: "[ProspectsLoading]",
  ProspectFetched: "[ProspectFetched]",
  ProspectFetchAsync: "[ProspectFetchAsync]",
  ProspectFetchTabs: "[ProspectFetchTabs]",
  ProspectsFetched: "[ProspectsFetched]",
  ProspectCreated: "[ProspectCreated]",
  ProspectUpdated: "[ProspectUpdated]",
  ProspectDeleted: "[ProspectDeleted]",
  ProspectsDeleted: "[ProspectsDeleted]",
  ProspectsStatusUpdated: "[ProspectsStatusUpdated]",
  ProspectFetchNotes: "[ProspectFetchNotes]",
  ProspectFetchMatched: "[ProspectFetchMatched]",
  ProspectFetchContacts: "[ProspectFetchContacts]",
  ProspectFetchDetails: "[ProspectFetchDetails]",
  ProspectFetchTimeline: "[ProspectFetchTimeline]",
  ProspectFetchMail: "[ProspectFetchMail]",

  ProspectZoneAnalysisFetched: "[ProspectZoneAnalysisFetched]",
  ProspectZoneAnalysisFilter: "[ProspectZoneAnalysisFilter]",
  tabChanged: "[tabChanged]",
};

const initialProspectsState = {
  listLoading: true,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
  prospectForEdit: undefined,
  error: null,
  matched: null,
  notes: null,
  contacts: null,
  details: null,
  timeline: null,
  mail: null,
  zoneAnalysis: null,
  selectedSideTab: "prospects",
};

export const reducer = persistReducer(
  { storage, key: "wol-auth", whitelist: ["prospects"] },
  (state = initialProspectsState, action) => {
    switch (action.type) {
      case actionTypes.tabChanged: {
        return {
          ...state,
          selectedSideTab: action.payload,
        };
      }
      case actionTypes.ProspectsFetched: {
        const payload = action.payload;
        console.log(payload.data);
        return {
          ...state,
          entities: payload.data,
          listLoading: false,
        };
      }
      case actionTypes.ProspectFetched: {
        const { data } = action.payload;
        return {
          ...state,
          profile: data,
        };
      }
      case actionTypes.ProspectError: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }
      case actionTypes.ProspectFetchMatched: {
        const payload = action.payload;
        console.log(payload.data);
        return {
          ...state,
          matched: payload.data,
          error: action.error,
        };
      }
      case actionTypes.ProspectFetchNotes: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }
      case actionTypes.ProspectFetchContacts: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }
      case actionTypes.ProspectFetchDetails: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }
      case actionTypes.ProspectFetchTimeline: {
        const payload = action.payload;
        console.log(payload.data);
        return {
          ...state,
          timeline: payload.data,
          error: action.error,
        };
      }
      case actionTypes.ProspectFetchMail: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }
      case actionTypes.ProspectZoneAnalysisFetched: {
        return {
          ...state,
          zoneAnalysis: action.payload,
        };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  //
  tabChanged: (data) => ({ type: actionTypes.tabChanged, payload: data }),
  prospectsLoading: (data) => ({
    type: actionTypes.ProspectsLoading,
    payload: data,
  }),
  prospectFetchAsync: (data) => ({
    type: actionTypes.ProspectFetchAsync,
    payload: data,
  }),
  //

  prospectFetchTabs: (data) => ({
    type: actionTypes.ProspectFetchTabs,
    payload: data,
  }),
  prospectFetched: (data) => ({
    type: actionTypes.ProspectFetched,
    payload: { data },
  }),
  prospectsFetched: (data) => ({
    type: actionTypes.ProspectsFetched,
    payload: data,
  }),
  prospectCreated: (data) => ({
    type: actionTypes.ProspectCreated,
    payload: { data },
  }),
  prospectUpdated: (data) => ({
    type: actionTypes.ProspectUpdated,
    payload: { data },
  }),
  prospectDeleted: (data) => ({
    type: actionTypes.ProspectDeleted,
    payload: { data },
  }),
  prospectsDeleted: (data) => ({
    type: actionTypes.ProspectsDeleted,
    payload: { data },
  }),
  prospectsStatusUpdated: (data) => ({
    type: actionTypes.ProspectsStatusUpdated,
    payload: { data },
  }),
  prospectError: (error) => ({
    type: actionTypes.ProspectError,
    payload: { error },
  }),

  prospectFetchNotes: (data) => ({
    type: actionTypes.ProspectFetchNotes,
    payload: { data },
  }),
  prospectFetchMatched: (data) => ({
    type: actionTypes.ProspectFetchMatched,
    payload: { data },
  }),
  prospectFetchContacts: (data) => ({
    type: actionTypes.ProspectFetchContacts,
    payload: { data },
  }),
  prospectFetchDetails: (data) => ({
    type: actionTypes.ProspectFetchDetails,
    payload: { data },
  }),
  prospectFetchTimeline: (data) => ({
    type: actionTypes.ProspectFetchTimeline,
    payload: { data },
  }),
  prospectFetchMail: (data) => ({
    type: actionTypes.ProspectFetchMail,
    payload: { data },
  }),

  prospectZoneAnalysisFetched: (data) => ({
    type: actionTypes.ProspectZoneAnalysisFetched,
    payload: data,
  }),
  prospectZoneAnalysisFilter: (data) => ({
    type: actionTypes.ProspectZoneAnalysisFilter,
    payload: data,
  }),
};

export function* sagaProspects() {
  yield takeLatest(actionTypes.ProspectsLoading, function* prospectsLoading({
    payload,
  }) {
    console.log("called ProspectsLoading");
    console.log(payload);
    switch (payload) {
      case "zoneAnalysis":
        const result = yield getZoneAnalysisData();
        console.log(result);
        yield put(actions.prospectZoneAnalysisFetched(result.data));
        break;
      case "notes":
        break;
      default:
        const data = yield getAllProspects();
        console.log(data.data.data);
        // yield delay(2000);
        console.log(data);
        yield put(actions.prospectsFetched(data));
    }
  });

  yield takeLatest(
    actionTypes.ProspectZoneAnalysisFilter,
    function* prospectsFilter({ payload }) {
      console.log("called ProspectsLoasdfghjklding");
      const result = yield getZoneAnalysisData(payload);
      yield put(actions.prospectZoneAnalysisFetched(result.data));
      console.log(payload);
      console.log(result);
    }
  );

  yield takeLatest(
    actionTypes.ProspectFetchAsync,
    function* prospectFetchAsync({ payload }) {
      console.log("called socialFeedPostAsync");
      console.log(payload);
      const prospect = yield getProspectById(payload);
      yield delay(2000);
      yield put(actions.prospectFetched(prospect));
    }
  );

  yield takeLatest(actionTypes.ProspectFetchTabs, function* prospectFetchTabs({
    payload,
  }) {
    console.log(payload);
    let result = {};
    switch (payload.tab) {
      case "matched":
        result = yield getProspectMatchedData(payload);
        yield put(actions.prospectFetchMatched(result.data.data));
        break;
      case "notes":
        break;
      case "contacts":
        break;
      case "details":
        break;
      case "timeline":
        result = yield getProspectTimeline(payload);
        console.log(result.data);

        yield put(actions.prospectFetchTimeline(result.data));

        break;
      case "mail":
        break;
      default:
        console.log("something happened");
    }
  });
}
