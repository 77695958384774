import React, { useEffect, useState, Fragment } from "react";
import { LinearProgress } from "@material-ui/core";
import { Modal } from "antd";
import moment from "moment";
import { toast } from "react-toastify";
import _debounce from 'lodash/debounce';
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import ActiveDots from "../../../../partials/table/cells/ActiveDots";
import ThemeHeader from "../../../../partials/content/ThemeHeader";
import ThemeButton from "../../../../partials/button/ThemeButton";
import DefaultModal from "../../../../partials/modal/DefaultModal";
import DynamicTableEdit from "../../../../partials/table/DynamicTableEdit";
import AddNewCommunityInvolvement from "../../partials/communityInvolvement/AddNewCommunityInvolvement";
import { convertFormData, formToQueryString, processCommPostInfo } from "../../../../_helpers/_utils/utils";
import ToolTip from "../../../../partials/tooltip/ToolTip";
import SocialShare from "../../../../partials/component/speedDial/SocialShare";
import SmsWrap from "../../../../partials/component/sms/SmsWrap";
import {
  getAllMembers
} from "../../../../GeneralApi/GeneralApi";
import Email from "../../../../partials/component/email/Email";
import TableFilter from "../../../../partials/table/TableFilter";
import { getAllCommunityInvolvement, updateCommunityInvolvement, deleteCommunityInvolvement } from "../../../setting/_crud/settingCrud";
import LoadingSkeleton from "../../../../partials/display/skeleton/LoadingSkeleton";
import { Avatar } from "@material-ui/core";
import { AvatarGroup } from "@material-ui/lab";
import MemberName from "../../../../partials/popover/memberPopover/MemberName";

const CommunityInvolvement = () => {
  const [selectedData, setSelectedData] = useState([]);
  const [otherResData, setOtherResData] = useState(null);
  const [showSms, setShowSms] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [showBlogForm, setShowBlogForm] = useState(false);
  const [commInvol, setCommInvol] = useState([]);
  const [dataProcess, setDataProcess] = useState([]);
  const org = useSelector((state) => state.auth.user.organisation.id);
  const [content, setContent] = useState()
  const [selectedBlog, setSelectedBlog] = useState({});
  const [selectedBlogDetails, setSelectedBlogDetails] = useState({});
  const [isLoading, setIsLoading] = useState([]);
  const [members, setMembers] = useState(null);
  const id_user  = useSelector((state) => state.auth.user.contact.id);
  
  const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  useEffect(() => {
    getCommData();
    getAllMembers()
      .then(({ data: { data } }) => {
        setMembers(
          data.map((s) => ({
            name: `${s.initial}`,
            id: s.contact.id,
            optionTooltipTitle:`${s?.contact?.first_name ?? ''} ${s?.contact?.last_name ?? ''}`,
          }))
        );
      })
      .catch((e) => {
        toast.error("Error while fetching");
      });
  }, []);

  const getCommData = (values) => {
    let obj = { ...values, limit: 30, user_id: id_user };
    let query = formToQueryString(obj);
    setIsLoading(true)
    getAllCommunityInvolvement(query)
      .then((res) => {
        const { data, ...rest } = res.data;
        if (values?.loadMore) {
          setCommInvol(commInvol.concat(data));
        } else {
          setCommInvol(data);
        }
        setOtherResData(rest);
      })
      .catch(() => { })
      .finally(() => {
        setIsLoading(false)
      })
  };

  const fetchMoreData = () => {
    let obj = {
      page: otherResData?.current_page + 1,
      loadMore: true
    };
    if (otherResData?.current_page !== otherResData?.last_page) {
      getCommData(obj);
    }
  };
  const rowClick = data => {
    setShowBlogForm(true);
    setSelectedBlog(processCommPostInfo(data));
  };

 
  useEffect(()=>{
    
    if (members?.length > 0 && commInvol?.length >= 0) {
        const newData =  commInvol.map((obj) => {
          if (obj.members.length > 0 ) {
                const matchingObjects = []
                obj.members.map((obj1) => {
                matchingObjects.push(members.filter((sObj) => sObj.id == obj1.contact)[0])
                })
              obj.agent = matchingObjects;
              return obj;
          }else {
             return obj;
          }
    });
    setDataProcess(newData);
  } 
},[members, commInvol])


  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        columns: [
          {
            Header: "Date",
            accessor: "created_at",
            width: 150, 
            Cell: ({ cell: { value } }) => (
              <p className="center">
                {value ? moment(value).format("DD/MM/YYYY") : ""}
              </p>
            ),
          },
          {
            Header: "Title",
            accessor: "title",
            Cell: ({
              cell: { value, row: { original } }
            }) => (
              <div className="d-flex justify-content-between">
                <div>{value}</div>
              </div>
            ),
          },
          {
            Header: "Agent",
            accessor: "agent",
            Cell: ({
              cell: { value }
            }) => <div onClick={(e) => e.stopPropagation()} className='testimonial-avatar'>
                {" "}
                <AvatarGroup max={15}>
                  {value?.length
                    ? value.map((obj) => (
                      <Avatar alt={obj?.name}>
                        {" "}
                        <MemberName id={obj?.id} className='pr-0'>
                          {obj?.name ?? '??'}
                        </MemberName>{" "}
                      </Avatar>
                    ))
                    : ""}
                </AvatarGroup>
              </div>,
          },
          {
            Header: "Status",
            accessor: "active",
            width: 150, 
            Cell: ({
              cell: { value },
            }) => <div className="d-flex justify-content-center align-items-center">
                    <ActiveDots active={value} />
                    <div>{value ? 'Published' : 'Draft'}</div> 
                </div>
          },
        ],
      },
    ],
    []
  );

  const handleOnSubmit = async ({ assets, organisation, created_by, ...values }) => {
    const { id } = selectedBlog;
    if (!values.image_url) {
      toast.error("Please select header image");
    
    } else if (content?.length === 0) {
      toast.error("Please fill the conent");
    } else {
      if (id) {
        values['_method'] = 'POST'
      }
      if (values.image_url instanceof Array) {
        values = { ...values, image_url: values.image_url[0] }
      }
      values.content = content
      if (values.tags?.id) {
        values.tags  =  values.tags?.id
      }else{
        values.tags  = values.tags
      }
      values.organisation = org;
      const formData = convertFormData(values);
      return await updateCommunityInvolvement(id, formData)
        .then(res => {
          getCommData();
          closeBlogPostModal()
          toast.success(`Blog ${id ? 'Updated' : 'Created'} Successfully`)
        })
        .catch(err => {
          toast.error(`${id ? 'Update' : 'Create'} Blog Failed`)
        })
    }
  };

  const handleBlogsDelete = () => {
    let data = { selected_ids: selectedData.map(item => item.id) }
    Modal.confirm({
      title: 'Warning',
      content: 'Are you sure want to delete?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        setIsLoading(true)
        const formData = convertFormData(data);
        deleteCommunityInvolvement(data)
          .then(res => {
            toast.success("Blog(s) Deleted Successfully");
            getCommData()
          })
          .catch(err => {
            toast.error("Blog(s) Deleted Failed");
          })
          .finally(() => {
            setIsLoading(false)
          })
      }
    })
  };

  const handleSearch = (value) => {
    getCommData({ search_kw: value })
  };

  const handleGlobalSearch = _debounce(handleSearch, 500);

  const closeBlogPostModal = () => {
    setShowBlogForm(false);
    setSelectedBlog({});
  }

  const handleClose = () => {
    Swal.fire({
      title: "Warning",
      text: "Are you sure want to exit?",
      icon: "warning",
      showCancelButton: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
      confirmButtonColor: "#A7A9AC",
      cancelButtonColor: "#2BB673",
      confirmButtonText: "Cancel",
      cancelButtonText: "Yes",
    }).then((result) => {
      if (result.isDismissed) {
        closeBlogPostModal()
        setShowSms(false)
        setShowEmail(false)
      }
    });
  };

  return (
    <div style={{maxWidth: '1440px', margin: 'auto'}}>
      {showSms ? (
        <DefaultModal
          size="xl"
          centered
          show={showSms}
          onHide={handleClose}
        >
          <SmsWrap
            closeModal={() => setShowSms(false)}
            // phoneData={selectedRow.map((a) => a.phones)}
            // totalRecipient={selectedItem?.length}
            // queryString={contacts.queryString}
            data={selectedData?.map((item) => item?.contacts?.[0])}
            hasDefaultNumbers={false}
            message={selectedBlogDetails.website_url}
          />
        </DefaultModal>
      ) : (
        ""
      )}

      {showEmail ? (
        <DefaultModal
          size="xl"
          centered
          show={showEmail}
          onHide={handleClose}
        >
          <Email
            closeModal={() => setShowEmail(false)}
            // emailData={[props.profile.emails]}
            totalRecipient={selectedData?.length}
            // queryString={contacts.queryString}
            message={selectedBlogDetails.website_url}
            data={selectedData?.map((item) => item?.contacts?.[0])}
            hasDefaultEmails={false}
          />
          {/* <ContactForm  formName='create-new-contact' onSubmit={HandleOnsubmit} closeModal={() => setShowAddContact(false)} /> */}
        </DefaultModal>
      ) : (
        ""
      )}
      <ThemeHeader
        className="p-4"
        left={<h4 className="title">Community Involvement</h4>}
        right={ !showBlogForm &&
          <Fragment>
            <div className="mr-3">  <TableFilter
              length={dataProcess.length}
              handleGlobalSearch={handleGlobalSearch}
            />
            </div>
            <div className="pr-2">
              <ThemeButton
                className="lightGrey-button"
                onClick={() => handleBlogsDelete()}
                disabled={!selectedData.length}
              >
                Delete
              </ThemeButton>
            </div>
            <ThemeButton
              className="green-button"
              onClick={() => setShowBlogForm(true)}
            >
              Create New
            </ThemeButton>
          </Fragment>
        }
      />
      {isLoading && !showBlogForm ? <LinearProgress /> : ""}
      { !showBlogForm  ?
        isLoading && !dataProcess.length ?
          <LoadingSkeleton height={"77vh"} /> 
          :
          <DynamicTableEdit
            columns={columns}
            handleChange={(e) => setSelectedData(e)}
            data={dataProcess}
            isEditable={true}
            handleRowClick={rowClick}
            fetchMoreData={fetchMoreData}
            customColumnWidth={true}
          />
          :
          <AddNewCommunityInvolvement
            onSubmit={(values) => handleOnSubmit(values)}
            closeModal={closeBlogPostModal}
            closeWithAlert={handleClose}
            selectedCommunityInvolvement={selectedBlog}
            setSelectedCommunityInvolvement={setSelectedBlog}
            content={content}
            setContent={setContent}
          />
      }
    </div>
  );
};

export default CommunityInvolvement;
