import React, { useEffect, useState } from 'react'
import { TextField } from '@material-ui/core'

function AgentProfileSettings({ selectedNode }) {
  const [identifier, setIdentifier] = useState('')
  const [staffName, setStaffName] = useState('')
  const [staffPosition, setStaffPosition] = useState('')
  const [staffMobile, setStaffMobile] = useState('')
  const [visibleMobile, setVisibleMobile] = useState(false)

  const getTargetElement = targetClassName => {
    return selectedNode.getElementsByClassName(targetClassName)?.[0]
  }

  useEffect(() => {
    setIdentifier(getTargetElement('identifier').innerText)
    setStaffName(getTargetElement('agent-profile-name').innerText)
    setStaffPosition(getTargetElement('position').innerText)
    if (getTargetElement('mobile')) {
      setStaffMobile(getTargetElement('mobile').innerText)
      setVisibleMobile(true)
    }


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNode])


 
  const handleIdentifierChange = e => {
    setIdentifier(e.target.value)
    getTargetElement('identifier').innerText = e.target.value
  }

  const handleStaffNameChange = e => {
    setStaffName(e.target.value)
    getTargetElement('agent-profile-name').innerText = e.target.value
  }

  const handleStaffPositionChange = e => {
    setStaffPosition(e.target.value)
    getTargetElement('position').innerText = e.target.value
  }

  const handleStaffMobileChange = e => {
    setStaffMobile(e.target.value)
    getTargetElement('mobile').innerText = e.target.value
  }

  return (
    <div>
      <TextField
        className='mt-4'
        type="text"
        label="Agent Name"
        fullWidth
        onChange={handleStaffNameChange}
        value={staffName}
      />
        <TextField
          className='mt-4'
          type="text"
          label="Agent Position"
          fullWidth
          onChange={handleStaffPositionChange}
          value={staffPosition}
        />
      <TextField
        className='mt-4'
        type="text"
        label="Identifier"
        fullWidth
        onChange={handleIdentifierChange}
        value={identifier}
      />

      <TextField
        className='mt-4'
        type="text"
        label="Staff Member Contact"
        placeholder='Contact number'
        fullWidth
        onChange={handleStaffMobileChange}
        value={staffMobile}
      />
    </div>
  )
}

export default AgentProfileSettings
