import React from "react";
import { TextField } from "@material-ui/core";

const TextInput = ({
  edit,
  label,
  name,
  width,
  placeholder,
  meta,
  input,
  readOnly,
  defaultRef,
  className,
  fullWidth,
  fontSize,
  error,
  helperText,
  showErrorOnMount = false,
  type,
  newValue,
  ...custom
}) => {
  if (newValue) {
    input.value = newValue;
  }

  return (
    <>
      {edit ? (
        <TextField
          className={className}
          label={label}
          fullWidth={fullWidth}
          inputRef={defaultRef}
          InputProps={{
            readOnly: readOnly,
            autoComplete: "none",
          }}
          type={type}
          placeholder={placeholder}
          error={
            error ?? ((meta?.touched || showErrorOnMount) && meta?.invalid)
          }
          {...input}
          {...custom}
          helperText={
            helperText ?? ((meta?.touched || showErrorOnMount) && meta?.error)
          }
        />
      ) : (
        <h6 className="mb-0">{input.value}</h6>
      )}
    </>
  );
};

TextInput.defaultProps = {
  edit: true,
  disabled: false,
  fullWidth: true,
};
export default TextInput;
