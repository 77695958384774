import React, { Fragment, useEffect, useState } from "react";
import { Form, Col, Row } from "react-bootstrap";
import { Field } from "redux-form";
import TextInput from "../../../../../../../partials/form/TextInput";
import TextArea from "../../../../../../../partials/form/TextArea";
import DateInput from "../../../../../../../partials/form/DateInput";
import MultiSelect from "../../../../../../../partials/form/MultiSelect";
import { getMenu } from "../../../../../../../GeneralApi/GeneralApi";

const EnquiryItem = ({ fields, meta: { error, submitFailed }, label }) => {
  const [option, setOption] = useState(false);

  useEffect(() => {
    let query = "enquiry_source";
    setOption(true);
    getMenu(query)
      .then(({ data: { data } }) => {
        setOption(data.enquiry_source);
      })
      .catch(() => {});
  }, []);

  return (
    <Fragment>
      <div className="makeAnOfferItem">
        {fields.map((offer, index, members) => (
          <div>
            <Form.Row>
              <Col md={6}>
                <p className="font-weight-bold">
                  {members.get(index).address}{" "}
                </p>
              </Col>
            </Form.Row>
            <Form.Row>
              <Col md={6}>
                <Field
                  name={`${offer}.source`}
                  placeholder="Select an Enquiry Source"
                  component={MultiSelect}
                  showBottom={false}
                  isMulti={false}
                  options={option}
                  required
                  label="Enquiry Source"
                />
              </Col>
            </Form.Row>
            <Form.Row>
              <Col md={12}>
                <Field
                  name={`${offer}.comments`}
                  type="textarea"
                  rows="3"
                  component={TextArea}
                  placeholder="Comment about the enquiry"
                  required
                  label="Comments"
                />
              </Col>
            </Form.Row>
            <hr className="mb-5 mt-5" />
          </div>
        ))}
      </div>
    </Fragment>
  );
};

export default EnquiryItem;
