import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import ContactForm from "./ContactForm";
import {
  getContactById,
  createNewContact,
  updateContact,
} from "../../crud/contactsCrud";
import {
  convertFormData,
  getObjWithoutValues,
  getProcessedContactInfo,
  postProcessedContactInfo,
} from "../../../../_helpers/_utils/utils";
import { toast } from "react-toastify";
import * as contactsRedux from "../../_redux/contactsRedux";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { submit } from "redux-form";
import { createNotes } from "../../../../GeneralApi/GeneralApi";
import { updateProspect } from "../../../../modules/prospect/curd/prospectsCrud";

const NewContact = (props) => {
  const {
    closeModal,
    initialValues,
    contactsLoading,
    setNewContactId,
    OutsideContact,
    submitFromDiffComponent,
    submitFormName,
    newFormName,
    autoProspectNote,
    prospectId,
    updateContext,
    prosprofile,
    from,
    setFrom = () => {},
  } = props;
  const [profileId, setProfileID] = useState(null);
  const [profile, setProfile] = useState(initialValues);
  const [formValues, setFormValues] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    setFrom(false);
  }, []);

  let oldContactIds = prosprofile?.contacts.map((contact) => contact.id);

  const selectedOption = (value) => {
    if (value) {
      Swal.fire({
        title: "Existing contact found!!",
        text:
          "Existing contact found ( " +
          value.first_name +
          " " +
          value.last_name +
          " , " +
          value?.phones?.[0]?.value +
          " , " +
          value?.emails?.[0]?.value +
          "). Would you like to open or update that contact.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#A7A9AC",
        cancelButtonColor: "#2BB673",
        confirmButtonText: "Cancel",
        cancelButtonText: "Yes",
      }).then((result) => {
        if (result.isDismissed) {
          setProfileID(value.id);
          getContactById(value.id)
            .then(({ data: { data } }) => {
              const initialValue = getProcessedContactInfo(data);
              setProfile(initialValue);
            })
            .catch(() => {});
        }
      });
    }
  };
  const HandleOnsubmit = async () => {
    let formatedValue;
    const { id, notes, ...othersContact } = formValues;
    // const errorMessage = "Either First Name or Last Name needs to be informed";

    // if (!formValues.first_name && !formValues.last_name) {
    //   Swal.fire(
    //     "Error!",
    //     errorMessage,
    //     "error"
    //   );
    //   return false;
    // }

    // if ((formValues.first_name ?? '').trim() === '' && (formValues.last_name ?? '').trim() === '') {
    //   Swal.fire(
    //     "Error!",
    //     errorMessage,
    //     "error"
    //   );
    //   return false;
    // }

    const postProcessedContact = postProcessedContactInfo(othersContact);
    let contact_id;
    formatedValue = {
      notes: notes ? (notes[0]?.detail ? notes : null) : null,
      ...postProcessedContact,
    };

    const formData = convertFormData(
      getObjWithoutValues([undefined, null], formatedValue)
    );

    const noteDetails = `New Contact Added : First Name: ${
      othersContact?.first_name
    } || Last Name: ${othersContact?.last_name} ${
      othersContact?.phones[0]?.value !== ""
        ? `|| Phone: ${othersContact?.phones[0]?.value}`
        : ''
    } ${
      othersContact?.emails[0]?.value !== ""
        ? `|| Email: ${othersContact?.emails[0]?.value}`
        : ''
    }`;
    const noteType = 4;

    if (id) {
      formData.append("_method", "PATCH");
      return await updateContact(id, formData)
        .then(({ data }) => {
          if (OutsideContact) {
            contact_id = data.data.id;
            setNewContactId && setNewContactId(data.data.id);
            setNewContactId && dispatch(submit(submitFormName));
            contactsLoading();
          }
          if (autoProspectNote) {
            let payload = {
              prospect: prospectId,
              detail: noteDetails,
              type: noteType,
            };
            const formData = convertFormData(payload);
            createNotes(formData)
              .then(({ data: { data } }) => {
                contactsLoading();

                if (contact_id && autoProspectNote) {
                  let prospectFormValue = {};
                  prospectFormValue.contacts = [contact_id, ...oldContactIds];

                  let prospectFormatedData = convertFormData(
                    getObjWithoutValues([undefined, null], prospectFormValue)
                  );

                  prospectFormatedData.append("_method", "PATCH");

                  updateProspect(prospectId, prospectFormatedData)
                    .then(({ data }) => {
                      toast.success("Prospect Updated Successfully");
                      updateContext();
                    })
                    .catch((e) => {});
                }
              })
              .catch((e) => {});
          }
          toast.success("Contact updated Successfully");
          closeModal();
        })
        .catch((e) => {
          console.log(e, "error message");
          toast.error("Error While Saving");
        });
    } else {
      return await createNewContact(formData)
        .then(({ data }) => {
          if (OutsideContact) {
            contact_id = data.data.id;
            setNewContactId && setNewContactId(data.data.id);
            setNewContactId && dispatch(submit(submitFormName));
          }
          toast.success("Contact created Successfully");
          contactsLoading();
          closeModal();

          if (autoProspectNote && !from) {
            let payload = {
              prospect: prospectId,
              detail: noteDetails,
              type: noteType,
            };
            const formData = convertFormData(payload);
            createNotes(formData)
              .then(({ data: { data } }) => {
                contactsLoading();

                if (contact_id && autoProspectNote) {
                  let prospectFormValue = {};
                  prospectFormValue.contacts = [contact_id, ...oldContactIds];

                  let prospectFormatedData = convertFormData(
                    getObjWithoutValues([undefined, null], prospectFormValue)
                  );

                  prospectFormatedData.append("_method", "PATCH");

                  updateProspect(prospectId, prospectFormatedData)
                    .then(({ data }) => {
                      toast.success("Prospect Updated Successfully");
                      updateContext();
                    })
                    .catch((e) => {});
                }
              })
              .catch((e) => {});
          }
        })
        .catch((e) => {
          toast.error("Error while saving");
        });
    }
  };

  return (
    <div className="form-modal">
      <ContactForm
        hideNote={false}
        setFormValues={(e) => setFormValues(e)}
        initialValues={{
          ...{
            emails: [{ type: 1, value: "" }],
            phones: [{ type: 1, value: "" }],
            addresses: [
              {
                type: 1,
                street_unit: "",
                street_name: {},
                suburb: {},
                state: {},
              },
            ],
            workAddress: [
              {
                type: 1,
                street_unit: "",
                street_name: {},
                suburb: {},
                state: {},
              },
            ],
          },
          ...profile,
        }}
        formName={
          newFormName
            ? newFormName
            : profileId
            ? "edit-contact"
            : "update-contact"
        }
        profileData={profile}
        profileId={profileId}
        closeModal={closeModal}
        onSubmit={
          submitFromDiffComponent
            ? () => submitFromDiffComponent(formValues)
            : HandleOnsubmit
        }
        selectedContact={selectedOption}
      />
    </div>
  );
};

NewContact.defaultProps = {
  insideContact: true,
  initialValues: {},
};

const mapState = ({ contacts }) => ({
  contacts,
});
export default connect(mapState, contactsRedux.actions)(NewContact);
