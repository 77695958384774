import React from "react";
import { Field, getFormValues, reduxForm, reset } from "redux-form";
import { InputAdornment } from "@material-ui/core";
import { Form, Col, Row } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";
import TextInput from "../../../../partials/form/TextInput";
import ToggleSwitch from "../../../../partials/form/toggleSwitch/ToggleSwitch";
import MultiSelect from "../../../../partials/form/MultiSelect";
import { Accordion } from "react-bootstrap";
import PropertyFeatures from "../../../../partials/filter/propertyFeatures/PropertyFeatures";
import { ContactInformation } from "../../../../partials/filter/contactInformation/ContactInformation";
import PersonalDetails from "../../../../partials/filter/personalDetails/PersonalDetails";
import FamilyDetails from "../../../../partials/filter/familyDetails/FamilyDetails";
import ProspectDetails from "../../../../partials/filter/prospectDetails/ProspectDetails";
import PropertyActivity from "../../../../partials/filter/propertyActivity/PropertyActivity";
import ThemeButton from "../../../../partials/button/ThemeButton";
import { useState } from "react";
import { useEffect } from "react";
import { getAllMembers, getMenu } from "../../../../GeneralApi/GeneralApi";
import { toast } from "react-toastify";
import DateInput from "../../../../partials/form/DateInput";

const validate = (values) => {
  const errors = {};
  if (values.street_number_to) {
    if (!values.street_number_from) {
      errors.street_number_from = "Required";
    }
  }
  return errors;
};

const DrawerForm = (props) => {
  const {
    handleSubmit,
    submitting,
    formValues,
    anyTouched,
    invalid,
    resetFilter,
    onSubmitDrawerForm,
  } = props;

  const [email_template_type, setEmail_template_type] = useState([]);
  const [email_template_category, setEmail_template_category] = useState([]);
  const [contact_type, setContact_type] = useState([]);
  const [team, setTeam] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    let query = "email_template_type,email_template_category,contact_type,team";
    getMenu(query)
      .then(({ data: { data } }) => {
        setEmail_template_type(data.email_template_type);
        setEmail_template_category(data.email_template_category);
        setContact_type(data.contact_type);
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    getAllMembers()
      .then(({ data: { data } }) => {
        setTeam(
          data.map((s) => ({
            name: `${s.initial}`,
            id: s.id,
          }))
        );
      })
      .catch((e) => {
        toast.error("Error while fetching");
      });
  }, []);

  useEffect(() => {
    dispatch(reset("create-email-table"));
  }, [resetFilter]);

  const handleOnSubmit = (values) => {
    const { state, active, ...others } = values;

    let processedValues = {
      state,
      ...others,
    };

    onSubmitDrawerForm(processedValues);
  };

  return (
    <form
      onSubmit={
        handleSubmit(handleOnSubmit)
        // () => console.log("submit")
      }
      className="hide-vertical-scroll  mb-4"
      style={{ maxHeight: "70vh" }}
    >
      <Form.Group as={Row}>
        <Col className="pb-1" sm={12}>
          <Field
            name="type"
            label="Type"
            component={MultiSelect}
            showBottom={false}
            options={[
              { id: 1, name: "Pre-Listing" },
              { id: 2, name: "Post-Listing (Listed)", disabled: true  },
              { id: 3, name: "Post-Listing (Not Listed)", disabled: true },
            ]}
            isMulti={false}
          />
        </Col>

        <Col className="pb-1" sm={12}>

          <Field
              name="for"
              label="Team"
              component={MultiSelect}
              showBottom={false}
              options={team}
              isMulti={false}
          />

        </Col>

        <Col className="pb-1" sm={12}>
          <Field
              name="name"
              component={TextInput}
              placeholder="Template Name"
              label="Template Name"
          />
        </Col>


        <Col className="pb-1" sm={12}>
          <Field
              name="sent_by"
              label="Sent By"
              component={MultiSelect}
              showBottom={false}
              options={[
                { id: 1, name: "Manual" },
                { id: 2, name: "Automatic"},
              ]}
              isMulti={false}
          />
        </Col>

        <div className="col-6 pr-1 pb-2">
          <Field
            label="Date From"
            name="date_from"
            type="date"
            component={DateInput}
          />
        </div>
        <div className="col-6 pl-0 pb-2">
          <Field
            label="Date To"
            name="date_to"
            type="date"
            component={DateInput}
          />
        </div>
      </Form.Group>
      <div className="d-flex">
        <ThemeButton
          type="submit"
          className="w-100 submit-button"
          disabled={submitting || !anyTouched || invalid}
        >
          Filter
        </ThemeButton>
      </div>
    </form>
  );
};

function mapStateToProps(state, props) {
  const values = getFormValues("create-email-table")(state);

  return {
    formValues: values,
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: "create-email-table",
    enableReinitialize: true,
    touchOnChange: true,
    validate,
  })(DrawerForm)
);
