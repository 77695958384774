import React, { useState, Fragment, useEffect, useContext } from "react";
import { Form, OverlayTrigger, Popover, Row } from "react-bootstrap";
import { reduxForm, FieldArray, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import ThemeHeader from "../../../../partials/content/ThemeHeader";
import ThemeButton from "../../../../partials/button/ThemeButton";
import ContactPopover from "../../../../partials/popover/ContactPopover";
import {
  deleteContact,
  getDuplicateContacts,
  contactMerge,
} from "../../crud/contactsCrud";
import LoadingSkeleton from "../../../../partials/display/skeleton/LoadingSkeleton";
import * as contactsRedux from "../../_redux/contactsRedux";
import { toast } from "react-toastify";
import DuplicateContactsForm from "./DuplicateContactsForm";
import { convertFormData } from "../../../../_helpers/_utils/utils";
import { ContactProfileContext } from "../contactProfileModal/ContactProfileContextProvider";
import Swal from "sweetalert2";

const DuplicateContacts = (props) => {
  const {
    handleSubmit,
    pristine,
    closeModal,
    contactsLoading,
    contactId,
    duplicateContacts,
    contactFetch,
    closeContactModal,
    reset,
    selectedDuplicateContacts,
    updateContext,
    profile,
  } = props;
  const [items, setItems] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  // const { updateContext, profile } = selectedDuplicateContacts? {}: useContext(ContactProfileContext);
  const [mainContact, setMainContact] = useState(profile);
  // const [deleteContacts,setDeleteContacts] = useState()
  console.log(props);
  useEffect(() => {
    console.log("useeffect");
    selectedDuplicateContacts ? setItems(selectedDuplicateContacts) : update();
  }, []);

  useEffect(() => {
    setSelectedData(duplicateContacts?.filter((item) => item?.selected));
  }, [duplicateContacts]);

  const update = () => {
    getDuplicateContacts(contactId)
      .then(({ data }) => {
        setItems(data.data.filter((d) => d.id != contactId));
      })
      .catch(() => {});
  };

  const handleDelete = () => {
    let selectedId = duplicateContacts.filter((item) => item.selected);
    console.log(selectedId);

    selectedData.map((item) => {
      deleteContact(item.id)
        .then(({ data: { data } }) => {
          console.log(data);
          toast.success("Contact Deleted Successfully");
        })
        .catch((e) => {});
      contactsLoading();
      !selectedDuplicateContacts && contactFetch(contactId);
      reset();
      updateContext();
      selectedDuplicateContacts
        ? setItems(items?.filter((item) => !selectedId?.includes(item?.id)))
        : update();
    });
  };

  const handleMerge = () => {
    let unProcessedData = {};

    let selectedId = duplicateContacts
      .filter((item) => item.selected)
      .sort((a, b) => a.priority - a.priority)
      .map((item, index) => {
        return { priority: index + 1, id: item.id };
      });

    // console.log(selectedId, "sort dupliacte");
    unProcessedData.contacts = selectedId;
    // console.log(unProcessedData);

    let processedFormData = convertFormData(unProcessedData);

    if (
      duplicateContacts
        .filter((item) => item.selected)
        .sort((a, b) => a.priority - a.priority)
        ?.slice(1)
        ?.find((contact) => contact?.id == contactId)
    ) {
      Swal.fire({
        title: "Merge current contact into others?",
        text: "This will remove current contact ",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#A7A9AC",
        cancelButtonColor: "#2BB673",
        confirmButtonText: "Cancel",
        cancelButtonText: "Yes",
      }).then((result) => {
        if (result.isDismissed) {
          contactMerge(processedFormData)
            .then(({ data: { data } }) => {
              toast.success("Contacts Merged Successfully");
              contactsLoading();
              closeModal();
              closeContactModal();
            })
            .catch((e) => {});
        }
      });
    } else {
      contactMerge(processedFormData)
        .then(({ data: { data } }) => {
          console.log(data);
          toast.success("Contact Merged Successfully");
          contactsLoading();
          if (selectedDuplicateContacts) {
            closeModal();
          } else {
            contactFetch(contactId);
            reset();
            update();
            updateContext();
          }
        })
        .catch((e) => {});
    }
  };

  return (
    <Fragment>
      <div className="buyerFormModal">
        <Fragment>
          <ThemeHeader
            left={
              <Fragment>
                <h5 className="title">Manage Duplicates</h5>
              </Fragment>
            }
            right={
              <Fragment>
                <Fragment>
                  <div className="pl-2">
                    <ThemeButton onClick={closeModal}>Cancel</ThemeButton>
                  </div>
                  <div className="pl-2">
                    <ThemeButton
                      disabled={selectedData?.length > 0 ? false : true}
                      onClick={() => handleDelete()}
                      className="brand-button"
                    >
                      Delete
                    </ThemeButton>
                  </div>
                  <div className="pl-2">
                    <ThemeButton
                      disabled={selectedData ? false : true}
                      onClick={handleMerge}
                      className="green-button"
                    >
                      Merge
                    </ThemeButton>
                  </div>
                </Fragment>
              </Fragment>
            }
          />
        </Fragment>
      </div>

      <Form onSubmit={handleSubmit} noValidate>
        {items ? (
          <FieldArray
            data={items}
            name="contacts"
            component={DuplicateContactsForm}
            mainContact={mainContact}
          />
        ) : (
          <LoadingSkeleton height="30vh" />
        )}
      </Form>
    </Fragment>
  );
};

const selector = formValueSelector("duplicate-contacts");
const actions = {
  // socialFeedPost: news.actions.socialFeedPostAsync,
};
const mapState = (state, props) => ({
  duplicateContacts: selector(state, "contacts"),
});
export default connect(
  mapState,
  contactsRedux.actions
)(reduxForm({ form: "duplicate-contacts" })(DuplicateContacts));
