import React, { Fragment, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Field } from "redux-form";
import { getMenu } from "../../../GeneralApi/GeneralApi";
import MultiSelect from "../MultiSelect";

const FormTags = ({ labelTop,fieldArray }) => {

  const [tags, setTags] = useState({});


  useEffect(() => {

    let query = "tag"
    getMenu(query)
    .then(({ data: { data } }) => {
      setTags(data.tag)
    })
    .catch(() => {});
  }, []);
  return (
    <Fragment>
      <Form.Group as={labelTop? Col : Row } controlId="formHorizontalPassword">
        {labelTop ? (
          <h5 className="title">Tags</h5>
        ) : (
          <Form.Label column sm={4}>
            Tags:
          </Form.Label>
        )}

        <div className={labelTop ? " " : "col-sm-8"} >
          {tags.length  ? <Field
            name={fieldArray ? `${fieldArray}.tags` : 'tags'}
            placeholder="Select Tags"
            label="Select Tags"
            component={MultiSelect}
            isMulti={true}
            options={tags}
          /> : ''}
          
        </div>
      </Form.Group>
    </Fragment>
  );
};

FormTags.defaultProps = {
  labelTop: true,
};

export default FormTags;
