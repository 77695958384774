/* eslint-disable no-restricted-imports */
import React, { Fragment, useContext, useState } from "react";
import { reset } from "redux-form";
import { useDispatch } from 'react-redux'

import TableWithDrawer from "../../../../partials/layout/pageLayout/TableWithDrawer";
import NewDigitaltemplate from "../../modals/digitalTemplate/NewDigitaltemplate";
// import NewMicrosite from "../../modals/microsites/NewMicrosite";
import DigitalTemplateTable from "../../partials/digitalTemplate/DigitalTemplateTable";
import DrawerForm from "../../partials/digitalTemplate/DrawerForm";
import { DigitalTemplateContext } from "./DigitalTemplateContextProvider";

const DigitalTemplate = () => {
  const [open, setOpen] = React.useState(false);
  const {
    template,
    continuousMode,
    setContinuousMode,
    onSubmitDrawerForm,
    fetchMoreData,
    handleGlobalSearch,
    updateTableData,
    otherResData,
    setActiveTab,
    setSelectedSocialTemplate,
    setLibraryDataSource,
    setShowEditSection,
    setProspectQuery,
    category
    // update
  } = useContext(DigitalTemplateContext);
  const dispatch = useDispatch();
  const [showTemplateForm, setShowTemplateForm] = useState(false);

  const handleClose = () => {
    setShowTemplateForm(false)
    setActiveTab('template')
    setSelectedSocialTemplate({})
    setShowEditSection(false)
    setLibraryDataSource([])
  }

  const resetFilterForm = () => {
    setProspectQuery({});
    // update();
    dispatch(reset("digital-template-drawer-form"));
  }

  return showTemplateForm ? (
    <NewDigitaltemplate
      closeModal={handleClose}
      setLibraryDataSource={ setLibraryDataSource}
    // micrositeValue={selectedAutomatedMail}
    />
  ) : (
    <Fragment>
      <TableWithDrawer
        handleDrawer={open}
        drawerClosed={() => setOpen(false)}
        resetDrawerForm={resetFilterForm}
        table={
          <Fragment>
            <DigitalTemplateTable
              template={template}
              continuousMode={continuousMode}
              setContinuousMode={setContinuousMode}
              onSubmitDrawerForm={onSubmitDrawerForm}
              fetchMoreData={fetchMoreData}
              handleGlobalSearch={handleGlobalSearch}
              setOpen={setOpen}
              otherResData={otherResData}
              updateTable={updateTableData}
              showTemplateForm={showTemplateForm}
              setShowTemplateForm={setShowTemplateForm}
            />
          </Fragment>
        }
        drawer={(resetFilter) => (
          <Fragment>
            <DrawerForm
              onSubmitDrawerForm={(e) => {
                onSubmitDrawerForm(e);
                setOpen(false);
              }}
              resetFilter={resetFilter}
              category={category}
            />
          </Fragment>
        )}
      />
    </Fragment>
  );
};

export default DigitalTemplate;
