import React, {PureComponent, Suspense} from "react";
import {connect} from "react-redux";
import * as tab from "../_redux/tabRedux";
// import * as Views from "../partials/views";
import {Tab, Tabs, Spinner} from "react-bootstrap";
import {withRouter} from "react-router-dom";
// import cuid from "cuid";
import TabContent from "../partials/TabContent";
import "./_tabPage.scss";
import ErrorBoundary from "../../../_helpers/ErrorBoundary";
import PopUpwrapper from "./popUpWrapper";

class TabPage extends PureComponent {
  componentDidMount() {
    // console.log(this.props);
  }

  render() {
    // const { match, location, history } = this.props;
    const tabs = this.props.tab.tab ? this.props.tab.tab : {};

    // console.log(tabs);
    // const components = tabs.map((tab) => {
    //   const Component = Views[tab.name] ? Views[tab.name] : "";
    //   return <Component key={cuid()} />;
    // });
    // console.log(
    //   this.props?.tab?.tab?.find(
    //     (obj) => obj.props?.name === this.props.tab.selectedTab
    //   )
    // );

    const key = this.props.tab.selectedTab
      ? this.props.tab.selectedTab
      : "NewsPage";
    return (
      <>
        <div className="container-fluid tab-page pb-5">
          {/* <PopUpwrapper /> */}
          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => this.handleSelect(k)}
          >
            {/* First page */}
            {/* <Tab eventKey="NewsPage">
              <TabContent name="NewsPage" />
            </Tab> */}
            {/* End Default Page */}

            {/* Dynamic Page Start */}
            {tabs
              ? tabs.map((tab, index) => (
                  <Tab
                    key={index}
                    eventKey={
                      tab.name && tab.props.navTitle
                        ? tab.props.navTitle
                        : tab.name
                    }
                    id={
                      tab.name && tab.props.navTitle
                        ? tab.props.navTitle
                        : tab.name
                    }
                  >
                    <Suspense fallback={<Spinner animation="grow" />}>
                      <ErrorBoundary>
                        <TabContent name={tab.name} props={tab.props} />
                      </ErrorBoundary>
                    </Suspense>
                  </Tab>
                ))
              : ""}
            {/* Dynamic Page Finish */}
          </Tabs>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({tab}) => ({
  tab: tab,
});
export default withRouter(connect(mapStateToProps, tab.actions)(TabPage));
