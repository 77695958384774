import React, { useEffect, useState } from "react";
import { Spin } from "antd";

import { formToQueryString } from "../../../../_helpers/_utils/utils";
import { getAllReferral } from "../../crud/buyersCrud";
import BuyerReferralForm from "./BuyerReferralForm";
import UpdateReferral from "./UpdateReferral";


const contact_initial = {
  phones: [{ type: 1, value: null }],
  emails: [{ type: 1, value: null }],
  last_name: null,
  first_name: null,
}



function BuyerReferral({newref, closeModal, profile, updateTable, prospectProfileType = false , status }) {
  const [referral, setReferral] = useState([]);
  const [isLoading, setIsLoading] = useState(false);



  useEffect(() => {
    if (profile?.id) {
      setIsLoading(true);
      let query = { status: status };

      if (prospectProfileType) {
        query.prospect = profile?.id
      } else {
        query.buyer = profile?.id
      }
      let processedQuery = formToQueryString(query);
      getAllReferral(processedQuery)
        .then(({ data: { data } }) => {
          setReferral(data);
        })
        .catch(() => { })
        .finally(() => {
          setIsLoading(false)
        });
    }

  }, []);

  return (
    <div>
      <Spin spinning={isLoading}>
        {referral.length > 0 && !newref ? (
          <UpdateReferral
            buyerId={profile?.id}
            updateTable={updateTable}
            referral={referral.length ? referral[0] : null}
            contact={profile?.primary_contact}
            closeModal={closeModal}
            prospectProfileType={prospectProfileType}
          />
        ) : (
          <BuyerReferralForm
            buyerId={profile?.id}
            updateTable={updateTable}
            contact={profile?.primary_contact ?? contact_initial}
            closeModal={closeModal}
            prospectProfileType={prospectProfileType}
            newref 
          />
        )}
      </Spin>
    </div>
  );
}


BuyerReferral.defaultProps = {
  handleClose: () => { },
  updateTable: () => { }
};

export default BuyerReferral;
