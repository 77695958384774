import React from "react";
import { Alert, AlertTitle } from "@material-ui/lab";

const ShowError = ({ errors }) => {
  let processedErrors = Object.entries(errors).map(([key, value]) => value);
  return (
    <div className="p-2">{processedErrors.length ? 
              <Alert severity="error">
            <AlertTitle>Required Data or Errors</AlertTitle> 
            <ul>
          {processedErrors.map((obj) => (
            <li>{obj}</li>
          ))}
        </ul>
          </Alert> :''}
    </div>
  );
};

export default ShowError;
