import axios from "axios";
const WiseBerryAPIEnv = process.env.REACT_APP_WISEBERRY_API;
export const TENANTS_URL = WiseBerryAPIEnv + "tenant";

let ajaxRequest = null;
// This sets the mock adapter on the default instance
// var mock = new MockAdapter(axios);

// Mock GET request to /users when param `searchText` is 'John'
// arguments for reply are (status, data, headers)
// mock.onGet("/users", { params: { searchText: "John" } }).reply(200, {
//   users: [{ id: 1, name: "John Smith" }],
// });

// CREATE =>  POST: add a new tenant to the server

// READ
export function getAllTenants(prams) {
  // cancel  previous ajax if exists
  if (ajaxRequest) {
    ajaxRequest.cancel();
  }

  // creates a new token for upcomming ajax (overwrite the previous one)
  ajaxRequest = axios.CancelToken.source();

  return axios.get(
    TENANTS_URL + "?limit=30" + (prams != null ? "&" + prams : ""),
    { cancelToken: ajaxRequest.token }
  );
  // return axios.get(TENANTS_URL + '?limit=30' + (prams != null ? ("&" + prams) : ''));
}

// READ
export function getTenantMatchedProperty(prams) {
  return axios.get(
    TENANTS_URL + `/matched-rentals` + (prams ? "?" + prams : "")
  );
}

export function getTenantById(id) {
  return axios.get(`${TENANTS_URL}/${id}`);
}

// Mail

// Notes

export function getNotesByTenantId(prams) {
  return axios.get(TENANTS_URL + "/notes?tenant=" + prams);
}
export function getNotesByTenantIdAndCreatedBy(prams) {
  return axios.get(TENANTS_URL + "/notes?" + prams);
}

// Notes
// READ
export function getAllMail() {
  // return axios.get(TENANTS_URL);
  const mail = axios.get("/mail");
  return mail;
}

export function getMailById(mailId) {
  const mail = axios.get("/mail/34", { params: { searchText: "John" } });
  //   const tenant = JSON.stringify(contacs.data.filter(tenant => tenant.id === tenantId));
  console.log(mail);
  return mail;
  // return axios.get(`${TENANTS_URL}/${tenantId}`);
}

export function updateMailById(data) {
  const mail = axios.post("/mail/34", { data });
  //   const tenant = JSON.stringify(contacs.data.filter(tenant => tenant.id === tenantId));
  console.log(mail);
  return mail;
  // return axios.get(`${TENANTS_URL}/${tenantId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findTenants(queryParams) {
  return axios.post(`${TENANTS_URL}/find`, { queryParams });
}

// UPDATE Status
export function updateStatusForTenants(ids, status) {
  return axios.post(`${TENANTS_URL}/updateStatusForTenants`, {
    ids,
    status,
  });
}

// DELETE => delete the tenant from the server
export function deleteTenant(tenantId) {
  return axios.delete(`${TENANTS_URL}/${tenantId}`);
}

// Tenant Details
// READ
export function getModalTenantDetails(tenantId) {
  return axios.get("/tenant/modal/details", { params: { tenantId: tenantId } });
}

// Tenant Details
// READ
export function getTenantMatchedData(tenantId) {
  console.log(tenantId);
  console.log(axios.get("/tenant/modal/matchedInfo"));
  return axios.get("/tenant/modal/matchedInfo");
  // return axios.get('/tenant/modal/matchedInfo', { params: { tenantId: tenantId }})
}

export function getTenantTimeline(tenantId) {
  return axios.get("/contacts/timeline");
  // return axios.get('/tenant/modal/matchedInfo', { params: { tenantId: tenantId }})
}

export function createNewTenant(prams) {
  return axios.post(TENANTS_URL, prams, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function updateTenant(id, prams) {
  return axios.post(TENANTS_URL + "/" + id, prams, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function bulkDeleteTenant(prams) {
  return axios.post(TENANTS_URL + "/bulk-delete", prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}
export function sendTenantsListingPack(params) {
  return axios.post(`${TENANTS_URL}/send-rental-pack`, params);
}