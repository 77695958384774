import React, { useEffect, useState, Fragment } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import { Field, getFormValues, reduxForm } from "redux-form";
import _isEmpty from "lodash/isEmpty";

import { getAllMembers } from "../../../../GeneralApi/GeneralApi";
import ThemeButton from "../../../../partials/button/ThemeButton";
import ThemeHeader from "../../../../partials/content/ThemeHeader";
import MultiSelect from "../../../../partials/form/MultiSelect";
import TextArea from "../../../../partials/form/TextArea";
import TextInput from "../../../../partials/form/TextInput";
import ToggleSwitch from "../../../../partials/form/toggleSwitch/ToggleSwitch";
import AsyncMultiSelectCreatable from "../../../../partials/form/AsyncMultiSelectCreatable";
import { formToQueryString } from "../../../../_helpers/_utils/utils";
import { getAllListing } from "../../../listings/_crud/listingsCrud";
import { getAllTestimonials } from "../../../setting/_crud/settingCrud";

const validate = (values = {}) => {
  const errors = {};
  const { value } = values;
  if (value && value.length > 800) {
    errors.value = `maximum characters is 800, you entered ${value.length}`;
  }
  return errors;
};

const AddNewTestimonials = ({
  testimonialValues,
  closeModal,
  closeWithAlert,
  handleSubmit,
  formValues,
  change,
  invalid,
  pristine,
  submitting,
}) => {
  const [members, setMembers] = useState(null);
  const [testimonials, setTestimonials] = useState([]);
  const [isLoading, setIsLoading] = useState([]);
  useEffect(() => {
    getAllMembers()
      .then(({ data: { data } }) => {
        setMembers(
          data.map((s) => ({
            name: `${s.initial}`,
            id: s.contact.id,
            optionTooltipTitle: `${s?.contact?.first_name ?? ""} ${s?.contact
              ?.last_name ?? ""}`,
          }))
        );
      })
      .catch((e) => {});
  }, []);

  const getTestimonialsData = (e) => {
    let obj = {
      limit: 32,
      status: 1,
    };
    let query = formToQueryString(obj);
    setIsLoading(true);
    getAllTestimonials(query)
      .then((res) => {
        const { data, ...rest } = res.data;
        if (data.length > 30) {
          alert(
            "You have more then 30 testimonials active, please note  that the Website  only displays  30 testimonials"
          );
        }
        setTestimonials(data);
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getTestimonialsData();
  }, []);

  const getStreetOptions = (text) => {
    if (typeof text === "string" || text instanceof String) {
      let query = formToQueryString({ search_address: text, limit: 30 });
      return getAllListing(query)
        .then(({ data }) => {
          let options = data.data.map((item) => ({
            value: item.id,
            label: item.property.address.full_address,
            ...item,
          }));
          return options;
        })
        .catch((e) => {});
    }
  };

  const handleStreetOnClick = (data) => {
    let contacts = data.contacts.map((item) => item.id);
    let uniqContacts = [...new Set(contacts)];
    change("contacts", uniqContacts);
  };

  return (
    <div className="p-3 testimonial-form">
      <form onSubmit={handleSubmit}>
        <ThemeHeader
          left={
            <Fragment>
              <h5 className="title">
                {testimonialValues?.length ? "Edit" : "Add New"} Testimonial
              </h5>
            </Fragment>
          }
          right={
            <Fragment>
              <Fragment>
                <div className="pl-2">
                  <ThemeButton
                    onClick={pristine ? closeModal : closeWithAlert}
                    className="lightGrey-button font-weight-bold"
                  >
                    Cancel
                  </ThemeButton>
                </div>
                <div className="pl-2">
                  <ThemeButton
                    disabled={invalid || pristine || submitting}
                    type="submit"
                    className="green-button"
                  >
                    {submitting ? (
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      ""
                    )}
                    Save
                  </ThemeButton>
                </div>
              </Fragment>
            </Fragment>
          }
        />
        <Row>
          <Col lg={6}>
            <Field
              required
              component={TextInput}
              label="Testimonial Title"
              name={"heading"}
            />
          </Col>
          <Col lg={6}>
            <Field component={TextInput} label="Author" name={"created_by"} />
          </Col>
          <Col lg={6} className="pt-5">
            <Field
              name="listing"
              component={AsyncMultiSelectCreatable}
              isValidNewOption={() => false}
              isMulti={false}
              options={getStreetOptions}
              handleOnClick={handleStreetOnClick}
              placeholder="Property"
            />
          </Col>
          <Col
            lg={6}
            className={formValues?.contacts?.length ? "pt-1 mt-2" : "mt-4 pt-2"}
          >
            <Field
              required
              name="contacts"
              label="Team Members"
              component={MultiSelect}
              showDelete={true}
              isMulti={true}
              options={members}
              onChange={getTestimonialsData}
            />
          </Col>
        </Row>
        <h6 className="marketingSubTitle pt-5 pb-2">Website Visibility</h6>
        <Row className="home-button">
          <Col lg={3}>
            <Field
              name="show_on_website"
              component={ToggleSwitch}
              label="Office Homepage"
              labelLeft={true}
              labelClass="flex-grow-1 p-1"
              closeToLeft
              labelTrue="Show"
              labelFalse="Hide"
            />
          </Col>
          <Col lg={3}>
            <Field
              name="show_agent"
              component={ToggleSwitch}
              label="Team Members"
              labelLeft={true}
              labelClass="flex-grow-1 p-1"
              labelTrue="Show"
              labelFalse="Hide"
            />
          </Col>
        </Row>
        <Row className="p-3">
          <Field
            required
            name="value"
            component={TextArea}
            className="w-100"
            label="Testimonials"
          />
        </Row>
      </form>{" "}
    </div>
  );
};

const mapStateToProps = (state, { selectedTestimonial }) => {
  const formValues = getFormValues("new-testimonial")(state);
  return {
    formValues,
    private_view: formValues?.private_view,
    initialValues: !_isEmpty(selectedTestimonial)
      ? selectedTestimonial
      : {
          heading: "",
          property: "",
          listing: null,
          contacts: [],
          show_on_website: false,
          show_agent: false,
          value: "",
        },
  };
};

export default connect(mapStateToProps)(
  reduxForm({ form: "new-testimonial", validate })(AddNewTestimonials)
);
