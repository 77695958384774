import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { useLocation } from "react-router";
import { toast } from "react-toastify";
import { Formik } from "formik";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { LinearProgress, TextField } from "@material-ui/core";
// import clsx from "clsx";
import * as auth from "./_redux/auth.duck";
import { requestPasswordReset } from "./curd/auth.crud"; // Login using actual api Information
import ThemeButton from "../../partials/button/ThemeButton";

function ResetPassword(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);
  // const [loadingButtonStyle, setLoadingButtonStyle] = useState({
  //   paddingRight: "2.5rem",
  // });

  const enableLoading = () => {
    setLoading(true);
    // setLoadingButtonStyle({ paddingRight: "3.5rem" });
  };

  const disableLoading = () => {
    setLoading(false);
    // setLoadingButtonStyle({ paddingRight: "2.5rem" });
  };

  const location = useLocation();

  const reset_token = location.search.split("=")[1];

  if (!reset_token) return <Redirect to="/auth/login" />;
  if (redirect) return <Redirect to="/auth/login" />;

  return (
    <>
      <div className="kt-login__body">
        <div className="kt-login__form">
          <div className="kt-login__title">
            <h3>Reset Password</h3>
          </div>

          <Formik
            initialValues={{
              newPassword: "",
              confirm_newpassword: "",
            }}
            validate={(values) => {
              const errors = {};

              if (!values.newPassword) {
                errors.newPassword = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD",
                });
              }

              if (!values.confirm_newpassword) {
                errors.confirm_newpassword = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD",
                });
              }

              return errors;
            }}
            onSubmit={(values, { setStatus, setSubmitting }) => {
              enableLoading();
              requestPasswordReset(reset_token, values.newPassword)
                .then(({ data }) => {
                  disableLoading();
                  toast.success("Password Changed Successfully");
                  setTimeout(() => {
                    setRedirect(true);
                  }, 3000);
                })
                .catch(() => {
                  disableLoading();
                  setSubmitting(false);
                  setStatus(
                    intl.formatMessage({
                      id: "Somthing Went Wrong",
                    })
                  );
                });
            }}
          >
            {({
              values,
              status,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form
                noValidate={true}
                autoComplete="off"
                className="kt-form"
                onSubmit={handleSubmit}
              >
                {status ? (
                  <div role="alert" className="alert alert-danger">
                    <div className="alert-text">{status}</div>
                  </div>
                ) : (
                  ""
                )}
                <div className="form-group">
                  <TextField
                    type="password"
                    label="New Passowrd"
                    margin="normal"
                    className="kt-width-full"
                    name="newPassword"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.newPassword}
                    helperText={touched.newPassword && errors.newPassword}
                    error={Boolean(touched.newPassword && errors.newPassword)}
                  />
                </div>

                <div className="form-group">
                  <TextField
                    type="password"
                    margin="normal"
                    label="Confirm Password"
                    className="kt-width-full"
                    name="confirm_newpassword"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.confirm_newpassword}
                    helperText={
                      touched.confirm_newpassword && errors.confirm_newpassword
                    }
                    error={Boolean(
                      touched.confirm_newpassword && errors.confirm_newpassword
                    )}
                  />
                </div>

                {values.confirm_newpassword &&
                  values.newPassword &&
                  values.confirm_newpassword !== values.newPassword && (
                    <div className="d-flex mt-1 justify-content-start kt-link kt-login__link-forgot ">
                      {" "}
                      <FormattedMessage id="Password do not match " />
                    </div>
                  )}
                <div className="mt-5"></div>

                {loading ? (
                  <LinearProgress
                    classes={{
                      colorPrimary: "brand-background", // class name, e.g. `classes-nesting-label-x`
                      barColorPrimary: "darkRed-background", // class name, e.g. `classes-nesting-label-x`
                    }}
                  />
                ) : (
                  ""
                )}
                {}
                {values.confirm_newpassword &&
                  values.newPassword &&
                  values.confirm_newpassword === values.newPassword && (
                    <div className="d-flex login">
                      {" "}
                      <ThemeButton
                        disabled={isSubmitting}
                        type="submit"
                        className="w-100 brand-button"
                      >
                        Reset
                      </ThemeButton>
                    </div>
                  )}

                <div className="d-flex mt-2 justify-content-start">
                  {" "}
                  <Link
                    to="/auth/login"
                    className="kt-link kt-login__link-forgot"
                  >
                    <FormattedMessage id="< Back to login page " />
                  </Link>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(ResetPassword));
