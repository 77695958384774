
import React, { useEffect, useState } from 'react';
import { TextField } from '@material-ui/core'
import moment from 'moment';

const START_OF_THE_DAY = moment().startOf('date').format("hh:mm");
const END_OF_THE_DAY = moment().endOf('date').format("hh:mm");

const StartAndEndTime = ({ selectedNode }) => {
    const [startTime, setStartTime] = useState(START_OF_THE_DAY)
    const [endTime, setEndTime] = useState(END_OF_THE_DAY)

    const getTargetElement = targetClassName => {
        return selectedNode.getElementsByClassName(targetClassName)[0]
    }
    useEffect(() => {
        let regex = /\[(.*?)\]/gi // to find a string enclosing with []
        let start_time = getTargetElement('start-time').innerText
        let end_time = getTargetElement('end-time').innerText
        if (!regex.test(start_time)) {
            setStartTime(moment(start_time, 'hh:mm a').format('HH:mm'))
        } else {
            setStartTime(START_OF_THE_DAY)
        }
        if (regex.test(end_time)) {
            setEndTime(moment(end_time, 'hh:mm a').format('HH:mm'))
        } else {
            setEndTime(END_OF_THE_DAY)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedNode])

    const handleTimeChange = (e, type) => {
        if (type === "start") {
            getTargetElement('start-time').innerHTML = moment(e.target.value, 'hh:mm A').format('h:mm A')
            setStartTime(e.target.value)
        } else {
            getTargetElement('end-time').innerHTML = moment(e.target.value, 'hh:mm A').format('h:mm A')
            setEndTime(e.target.value)
        }
    }
    return (<div>
        <TextField
            type="time"
            id='start-time'
            label="Start Time"
            className='my-3'
            fullWidth
            value={startTime}
            onChange={(e) => handleTimeChange(e, 'start')}
            InputLabelProps={{
                shrink: true,
            }}
        />
        <TextField
            type="time"
            id='end-time'
            label="End Time"
            className='my-3'
            fullWidth
            value={endTime}
            onChange={(e) => handleTimeChange(e, 'end')}
            InputLabelProps={{
                shrink: true,
            }}
        />
    </div>)
}
export default StartAndEndTime