import React, { Fragment, useCallback, useEffect, useState } from "react";
import { reduxForm, FieldArray, formValueSelector, Field } from "redux-form";
import RenderScrapPaper from "./RenderScrapPaper";
import { connect } from "react-redux";
import { Form } from "react-bootstrap";
import ToDoTextInput from "./ToDoTextInput";
import ThemeButton from "../../button/ThemeButton";

const ScrapPaper = (props) => {
  const {
    handleSubmit,
    pristine,
    submitting,
    handleUpdate,
    handleDelete,
    description,
    submit,
    reset
  } = props;

  const handleIsDropped = (e) => {
console.log(e)
submit()
  }

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <div className="d-flex p-2  p-left-2 p-right-2">
          <Field name="description" placeholder="Type here to add to scrap paper..." component={ToDoTextInput} type="text" />{" "}
          <ThemeButton
            disabled={description?.length < 0}
            className="submit-icon-button"
            icon
            type="submit"
          >
            <i className="fas fa-plus-circle"></i>
          </ThemeButton>{" "}
        </div>
        <div className="scrap-paper-body pl-4 pr-4">


       <FieldArray handleUpdate={handleUpdate} handleDelete={handleDelete} isDropped={handleIsDropped}  isEditable={props.isEditable}   name="toDolist" component={RenderScrapPaper} /> 

       </div>
      </Form>
    </div>
  );
};

const selector = formValueSelector("scrapPaperForm"); // <-- same as form name

const mapState = (state, ownProps) => {
  const description = selector(state, "description");
  return {
    description,
    initialValues: { toDolist: ownProps.data, },
  }; // Foram name must have to be different
};

export default connect(
  mapState,
  null
)(reduxForm({ form: "scrapPaperForm", enableReinitialize: true })(ScrapPaper));
