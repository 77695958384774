import React, { useContext, useEffect } from "react";
// import { address } from "faker";

import AddNewListingsBody from "./AddNewRentalBody";
import { AddNewListingsContext } from "./AddNewRentalContextProvider";
import moment from "moment";

const AddNewListingWrap = ({ closeModal, setListingType, prospectAddress }) => {
  const { listingValues, inputAddress } = useContext(AddNewListingsContext);
  useEffect(() => {}, []);

  useEffect(() => {}, [listingValues]);
  return (
    <AddNewListingsBody
      closeModal={closeModal}
      setListingType={setListingType}
      initialValues={
        // listingValues
        //   ? {
        //       address: inputAddress,
        //       features: listingValues.features?.map(
        //         ({ feature, feature_menu_option, ...obj }) => ({
        //           feature: feature?.id,
        //           feature_menu_option: feature_menu_option?.id,
        //           ...obj,
        //         })
        //       ), // features: [
        //       //   {
        //       //     feature: listingValues.features[0].feature.name,
        //       //     feature_menu_input:
        //       //       listingValues.features[0].feature_menu_input,

        //       //     feature_menu_option:
        //       //       listingValues.features[0].feature_menu_option.name,
        //       //   },
        //       // ],
        //     }
        //   : {
        {
          show_full_address: 1,
          address: [
            prospectAddress ?? {
              type: 1,
              street_unit: "",
              street: {},
              street_name: {},
              suburb: {},
              state: {},
            },
          ],
          agreement_date: moment().format("YYYY-MM-DD"),
          payment_frequency: 2,
          available_from: moment().format("YYYY-MM-DD"),
        }
        // }
      }
    />
  );
};

export default AddNewListingWrap;
