import React from "react";
import ProspectProfileContextProvider from "./ProspectProfileContextProvider";
import ProspectProfileModalBody from "./ProspectProfileModalBody";

const ProspectProfileModal = (props) => {
  const {
    prospects,
    continuousMode,
    selectedProspect,
    setContinuousMode,
    closeModal,
    setBeen_contacted_arr,
    setPageCount,
    otherResData,
  } = props;
  return (
    <div>
      <ProspectProfileContextProvider
        continuousMode={continuousMode}
        selectedProspect={selectedProspect}
        prospects={prospects ?? [selectedProspect]}
        setContinuousMode={setContinuousMode}
        setBeen_contacted_arr={setBeen_contacted_arr}
        setPageCount={setPageCount}
        otherResData={otherResData}
      >
        <ProspectProfileModalBody closeModal={closeModal} {...props} />
      </ProspectProfileContextProvider>
    </div>
  );
};

export default ProspectProfileModal;
