/* eslint-disable no-restricted-imports */
import React, { Fragment, useState, useEffect } from "react";
import { Button, Chip } from "@material-ui/core";
import CustomDropdown from "../../../../../partials/dropdown/CustomDropdown";
import ToggleSwitch from "../../../../../partials/form/toggleSwitch/ToggleSwitch";
import MakeAnOffer from "./modals/makeAnOffer/MakeAnOffer";
import Enquiry from "./modals/enquiry/Enquiry";
import ThemeHeader from "../../../../../partials/content/ThemeHeader";
import TableFilter from "../../../../../partials/table/TableFilter";
import ThemeButton from "../../../../../partials/button/ThemeButton";
import { reduxForm, Field } from "redux-form";
import DefaultModal from "../../../../../partials/modal/DefaultModal";
import AccessControl from "../../../../../_ accessControl/AccessControl";
import moment from "moment";
import Appointment from "../../../../diary/partials/modals/appointment/Appointment";
import SmsWrap from "../../../../../partials/component/sms/SmsWrap";
import Email from "../../../../../partials/component/email/Email";
import _debounce from "lodash/debounce";
import { sendContractToBuyers, sendBuyerListingPack } from "../../../crud/buyersCrud";
import { toast } from "react-toastify";
import _split from "lodash/split";
import _concat from "lodash/concat";
import Swal from "sweetalert2";
import { PulseLoader } from 'react-spinners';

const Header = (props) => {
  const {
    handleSubmit,
    selectedItem,
    totalNumber,
    enableEnquiry,
    enableOffer,
    profileId,
    profile,
    handleGlobalSearch = () => {},
    buyer_contact,
    enableListingPack = false,
  } = props;

  const [showEnquire, setShowEnquire] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showMakeAnOffer, setShowMakeAnOffer] = useState(false);
  const [showAppointment, setShowAppointment] = useState(false);
  const [fillFormData, setFillFormData] = useState({});
  const [showSms, setShowSms] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [openListPackModal, setOpenListPackModal] = useState(false);
  const [htmlData, setHtmlData] = useState("<p></p>");
  const [agent, setAgent] = useState(null);
  const onSearch = _debounce(handleGlobalSearch, 500);

  const listingContract = selectedItem?.filter(
    item => item.is_contract == 1
  );
  const submitListpack = async (values) => {
    let listingIds = selectedItem.map((val) => {
      return val.listing;
    });
    if (!values?.subject || !values?.body) {
      toast.warn("Subject and Content are required");
      return;
    } else if (agent === null) {
      toast.warn("Please Select Agent(s)");
      return;
    }
    let toValues = _concat(
      _split(values?.to, ","),
      _split(values.additional_emails, ",")
    );
    let processedValues = {
      buyer: profileId,
      listings: listingIds,
      subject: values.subject,
      head_text: values.body,
      agentIds: agent,
      to: toValues.filter((obj) => obj),
    };
    if (values.preview === 1) {
      processedValues.preview = 1;
    }
  
    return await sendBuyerListingPack(processedValues)
      .then((res) => {
        if (values.preview === 1) {
          setHtmlData(res.data);
        } else {
          toast.success(res?.data?.message);
          setOpenListPackModal(false);
        }
      })
      .catch((err) => {
        toast.error(err?.data?.message);
      });
    };

    const submitListingContract = async () => {
      let selectedBuyerIds = [profileId]
      let listingIds = listingContract?.map(val => {
        return val?.listing;
      });
  
      let processedValues = {
        listings: listingIds,
        buyers: selectedBuyerIds
      };

      return await sendContractToBuyers(processedValues)
      .then(res => {
        toast.success(res?.data?.message);
      })
      .catch(err => {
        toast.error(err?.data?.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
    };

  useEffect(() => {
    setFillFormData({
      prospect_address: {
        meeting_location: [
          {
            street_unit: profile?.property?.address?.street_unit,
            street_number: profile?.property?.address?.street_number,
            street_name: {
              value: profile?.property?.address?.street?.id,
              label: profile?.property?.address?.street?.name,
              suburb_name: profile?.property?.address?.street?.suburb?.name,
              suburb_id: profile?.property?.address?.street?.suburb?.id,
              state_id: profile?.property?.address?.street?.suburb?.state?.id,
              state_name:
                profile?.property?.address?.street?.suburb?.state?.name,
              postcode: profile?.property?.address?.street?.suburb?.postcode,
            },
            suburb: {
              value: profile?.property?.address?.street?.suburb?.id,
              label: profile?.property?.address?.street?.suburb?.name,
            },
            state: {
              label: profile?.property?.address?.street?.suburb?.state?.name,
              value: profile?.property?.address?.street?.suburb?.state?.id,
            },
            postcode: profile?.property?.address?.street?.suburb?.postcode,
          },
        ],
      },
    });
  }, [showAppointment]);

  const sendContractClick = () => {
    if (listingContract?.length === 0) {
      Swal.fire(
        "No Contract",
        "There are no files tagged as contract for this listing",
        "danger"
      );
    } else {
      Swal.fire({
        title: "Send Contract to Buyers",
        text:
          "Are you sure you want to send the contract to the selected buyers?",
        icon: "warning",
        showCancelButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
        confirmButtonColor: "#A7A9AC",
        cancelButtonColor: "#2BB673",
        confirmButtonText: "No",
        cancelButtonText: "Yes"
      }).then(result => {
        if (result.isDismissed) {
          setIsLoading(true);
          submitListingContract();
        }
      });
    }
  };

  return (
    <Fragment>
      {isLoading && (
        <div style={{position: 'fixed', top: 0, left: 0, height: '100vh', width: '100vw', zIndex: 9999, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(0,0,0,0.3)'}}>
          <PulseLoader color={'#822433'} loading={isLoading} size={15} />
        </div>
      )}
      {showSms ? (
        <DefaultModal
          size="xl"
          centered
          show={showSms}
          onHide={() => setShowSms(false)}
        >
          <SmsWrap
            closeModal={() => setShowSms(false)}
            data={[buyer_contact]}
          />
        </DefaultModal>
      ) : (
        ""
      )}
      {showEmail ? (
        <DefaultModal
          size="xl"
          centered
          show={showEmail}
          onHide={() => setShowEmail(false)}
        >
          <Email
            closeModal={() => setShowEmail(false)}
            data={[buyer_contact]}
          />
        </DefaultModal>
      ) : (
        ""
      )}
      {showAppointment ? (
        <DefaultModal
          size="xl"
          centered
          show={showAppointment}
          onHide={() => setShowAppointment(false)}
        >
          <Appointment
            setFillFormData={setFillFormData}
            fillFormData={fillFormData}
            initialValues={{
              starts: moment().format("YYYY-MM-DDTHH:mm"),
              ends: moment().format("YYYY-MM-DDTHH:mm"),
              contacts: [profile?.primary_contact],
              app_type: 4,
              listings: selectedItem?.map((item) => item?.listing),
              testingProperty: 1,
            }}
            closeModal={() => setShowAppointment(false)}
          />
        </DefaultModal>
      ) : (
        ""
      )}
      {showEnquire ? (
        <DefaultModal
          size="xl"
          centered
          show={showEnquire}
          onHide={() => setShowEnquire(false)}
        >
          <Enquiry
            size="xl"
            centered
            profileId={profileId}
            numSelected={selectedItem}
            closeModal={() => setShowEnquire(false)}
          />
        </DefaultModal>
      ) : (
        ""
      )}

      {showMakeAnOffer ? (
        <DefaultModal
          size="xl"
          centered
          show={showMakeAnOffer}
          onHide={() => setShowMakeAnOffer(false)}
        >
          <MakeAnOffer
            size="xl"
            centered
            profileId={profileId}
            numSelected={selectedItem}
            closeModal={() => setShowMakeAnOffer(false)}
          />
        </DefaultModal>
      ) : (
        ""
      )}
      {openListPackModal ? (
        <DefaultModal
          size="xl"
          centered
          show={openListPackModal}
          onHide={() => setOpenListPackModal(false)}
        >
          <Email
            initialHtmlData={htmlData}
            subject={"Properties we think you may like"}
            message={
              "We are excited to be sharing with you some homes we think you’re going to love. Properties are not staying on the market long, give us a call if you are interested to have an inspection."
            }
            closeModal={() => setOpenListPackModal(false)}
            handleSubmitForm={submitListpack}
            data={[profile?.primary_contact]}
            ListPack={true}
            setAgent={setAgent}
          />
        </DefaultModal>
      ) : (
        ""
      )}
      <form onSubmit={handleSubmit}>
        <ThemeHeader
          left={
            <Fragment>
              <div className="pr-2">
                <CustomDropdown label="Matching By">
                  <Field name="price" label="Price" component={ToggleSwitch} />
                  <Field
                    label="Location"
                    name="location"
                    component={ToggleSwitch}
                  />
                  <Field
                    label="Property Type"
                    name="property_type"
                    component={ToggleSwitch}
                  />
                  <Field
                    label="Property Features"
                    name="feature"
                    component={ToggleSwitch}
                  />
                  <Field label="Tags" name="tags" component={ToggleSwitch} />
                </CustomDropdown>
              </div>

              <CustomDropdown label="Action">
                <div className="d-flex">
                  <Button onClick={() => setShowEmail(true)}>
                    Send via Email
                  </Button>
                </div>
                <div className="d-flex">
                  <Button onClick={() => setShowSms(true)}>Send via Sms</Button>
                </div>
                <div className="d-flex">
                  <Button onClick={() => setShowAppointment(true)}>
                    Book Appointment
                  </Button>
                </div>
                {enableOffer && selectedItem?.length >= 1 ? (
                  <div className="d-flex">
                    <Button onClick={() => setShowMakeAnOffer(true)}>
                      Make an Offer
                    </Button>
                  </div>
                ) : null}
                {enableEnquiry && selectedItem?.length >= 1 ? (
                  <div className="d-flex">
                    <Button onClick={() => setShowEnquire(true)}>
                      Enquire
                    </Button>
                  </div>
                ) : null}
              </CustomDropdown>
              {enableListingPack && selectedItem?.length >= 1 ? (
                <div className="ml-2 ">
                  <ThemeButton
                    className="border bg-white text-black"
                    onClick={() => {
                      setOpenListPackModal(true);
                    }}
                  >
                    {" "}
                    Listing Pack
                  </ThemeButton>
                </div>
              ) : null}
              {enableListingPack && selectedItem?.length >= 1 ? (
                <div className="ml-2 ">
                  <ThemeButton
                    className="border bg-white text-black"
                    onClick={() => sendContractClick()}
                  >
                    Send Contract
                  </ThemeButton>
                </div>
              ) : null}
            </Fragment>
          }
          right={
            <Fragment>
              <AccessControl
                allowedPermissions={["buyers-download"]}
                renderNoAccess={() => ""}
              >
                <div className="pb-2">
                  <ThemeButton icon>
                    <i className="fas fa-download    "></i>
                  </ThemeButton>
                </div>
              </AccessControl>

              <div className="pl-2 pr-2">
                <TableFilter handleGlobalSearch={onSearch} />
              </div>
              <Chip
                label={
                  <div className=" text-center ">
                    Matched Propertie
                    <br />
                    {totalNumber}
                  </div>
                }
                variant="outlined"
              />
            </Fragment>
          }
        />
      </form>
    </Fragment>
    // <Toolbar
    //     className={clsx(classes.root, {
    //         [classes.highlight]: numSelected > 0,
    //     })}
    // >

    //     <div className="d-flex w-100 justify-content-between">
    //         <div className="d-flex">

    //             <CustomDropdown label="Matching By">
    //                 {/* <ToggleSwitch name="price" label="Price" />
    //                 <ToggleSwitch name="propertyType" label="Property Type" />
    //                 <ToggleSwitch name="location" label="Locations" />
    //                 <ToggleSwitch name="PropertyFeature" label="Property Features" /> */}
    //             </CustomDropdown>

    //             <CustomDropdown label="Actions" className="pl-2">
    // <div className="d-flex">
    // <OverlayTrigger trigger="click" placement="right" overlay={() => popover('sendViaEmail')}>
    //     <Button>Send via Email</Button>
    // </OverlayTrigger>
    // </div>
    // <div className="d-flex">
    // <OverlayTrigger trigger="click" placement="right" overlay={() => popover('sendViaSms')}>
    //     <Button>Send via Sms</Button>
    // </OverlayTrigger>
    // </div>
    // <div className="d-flex">
    // <OverlayTrigger trigger="click" placement="right" overlay={() => popover('bookAppointment')}>
    //     <Button>Book Appointment</Button>
    // </OverlayTrigger>
    // </div>
    // <div className="d-flex">
    // <OverlayTrigger trigger="click" placement="right" overlay={() => popover('makeAnAppointment')}>
    //     <Button>Make and Offer</Button>
    // </OverlayTrigger>
    // </div>
    // <div className="d-flex">
    // <OverlayTrigger trigger="click" placement="right" overlay={() => popover('enquire')}>
    //     <Button>Enquire</Button>
    // </OverlayTrigger>
    // </div>

    //             </CustomDropdown>

    //         </div>
    //         <div className="d-flex">
    //             <div className="pr-2 bd-highlight"> <GlobalFilter
    //                 preGlobalFilteredRows={preGlobalFilteredRows}
    //                 globalFilter={globalFilter}
    //                 setGlobalFilter={setGlobalFilter}
    //             /></div>
    //             <div className="pr-2 ">
    //                 <div className="btn border p-0 pl-2 pr-2 font-weight-bold font-smaller ">Matched Properties  <br /> 60 </div>
    //             </div>
    //         </div>

    //     </div>
    // </Toolbar>
  );
};

export default reduxForm({
  form: "tenantMatchedHeader",
  initialValues: {
    price: 1,
    location: 0,
    feature: 0,
    property_type: 0,
  },
  onChange: (values, dispatch, props, previousValues) => {
    props.submit();
  }, // a unique identifier for this form
})(Header);
