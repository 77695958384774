import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest, select} from "redux-saga/effects";
import { getAllBuyers, getBuyerById } from "../crud/buyersCrud";
const _includes = require("lodash/includes"); 

export const actionTypes = {
    BuyersLoading: "[BuyersLoading]",
    BuyerFetched: "[BuyerFetched]",
    BuyerFetch: "[BuyerFetch]",
    BuyerFetchTabs: "[BuyerFetchTabs]",
    BuyersFetched: "[BuyersFetched]",
    BuyerCreated: "[BuyerCreated]",
    BuyerUpdated: "[BuyerUpdated]",
    BuyerDeleted: "[BuyerDeleted]",
    BuyersDeleted: "[BuyersDeleted]",
    BuyersStatusUpdated: "[BuyersStatusUpdated]",
    BuyerFetchNotes: "[BuyerFetchNotes]",
    BuyerFetchMatched: "[BuyerFetchMatched]",
    BuyerFetchContacts: "[BuyerFetchContacts]",
    BuyerFetchDetails: "[BuyerFetchDetails]",
    BuyerFetchTimeline: "[BuyerFetchTimeline]",
    BuyerFetchMail: "[BuyerFetchMail]",
    BuyerToggleCont: "[BuyerToggleCont]",
    BuyerSelectedId: "[BuyerSelectedId]",
    BuyerSelectedChangeId: "[BuyerSelectedChangeId]",
    BuyerSelected: "[BuyerSelected]",
    BuyerLoadingIndicator : "BuyersLoadingIndicator",
    BuyerFetchMore: "[BuyerFetchMore]",
    BuyersTableTitleChange: "[BuyersTableTitleChange]",
};

const initialBuyersState = {
    isLoading: false,
    queryString: "",
    buyersTableTitle: "All Active Buyers",
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    profile:null,
    buyerForEdit: undefined,
    otherResInfo: null,
    error: null,
    viewBuyer:{
        id: null,
        index: null,
        isLast: false,
    },
    continuousMood: false,
};

export const reducer = persistReducer(
    { storage, key: "wol-auth", whitelist: ["buyers"] },
    (state = initialBuyersState, action) => {
        switch (action.type) {

            case actionTypes.BuyersFetched: {
                const { data, otherResInfo, queryString } = action.payload;
                return {
                  ...state,
                  entities: data,
                  otherResInfo: otherResInfo,
                  queryString: queryString
                };
            }


            case actionTypes.BuyerLoadingIndicator: {
                const { indicator } = action.payload;
                return {
                  ...state,
                  isLoading: indicator 
                };
            }

            case actionTypes.BuyersTableTitleChange: {
               console.log(action.payload);
                return {
                  ...state,
                  buyersTableTitle: action.payload,
                };
            }


            case actionTypes.BuyerToggleCont: {
                return {
                    ...state,
                    continuousMood: action.payload
                }
            }

            case actionTypes.BuyerSelectedId: {
                return {
                    ...state,
                    viewBuyer : {
                        id: action.payload.id,
                        index: action.payload.index
                    }
                }
            }



            case actionTypes.BuyerFetched: {
                const {data} = action.payload;
                console.log(data)
                return {
                    ...state,
                    profile: data
                }
            }
            case actionTypes.BuyerError: {
                return {
                    ...state,
                    loading: false,
                    error: action.error
                };
            }
            case actionTypes.BuyerFetchMatched: {
                const payload = action.payload;
                console.log(payload.data)
                return {
                    ...state,
                    matched: payload.data,
                    error: action.error
                };
            }
            case actionTypes.BuyerFetchNotes: {
                return {
                    ...state,
                    loading: false,
                    error: action.error
                };
            }
            case actionTypes.BuyerFetchContacts: {
                return {
                    ...state,
                    loading: false,
                    error: action.error
                };
            }
            case actionTypes.BuyerFetchDetails: {
                return {
                    ...state,
                    loading: false,
                    error: action.error
                };
            }
            case actionTypes.BuyerFetchTimeline: {
                const payload = action.payload;
                console.log(payload.data)
                return {
                    ...state,
                    timeline: payload.data,
                    error: action.error
                };
            }
            case actionTypes.BuyerFetchMail: {
                return {
                    ...state,
                    loading: false,
                    error: action.error
                };
            }

            default:
                return state;
        }
    }
);

export const actions = {
    buyersLoading: (data) => ({ type: actionTypes.BuyersLoading, data}),
    buyersLoadingIndicator: (data) => ({
        type: actionTypes.BuyerLoadingIndicator,
        payload: data,
      }),
    buyerSelectedId: data => ({ type: actionTypes.BuyerSelectedId, payload:  data }),
    buyerSelectedChangeId: data => ({ type: actionTypes.BuyerSelectedChangeId, payload:  data }),
    buyerSelected: data => ({ type: actionTypes.BuyerSelected, payload:  data }),
    buyerToggleCont: (data) => ({ type: actionTypes.BuyerToggleCont, payload:  data }),
    buyerFetchMore: (data) => ({ type: actionTypes.BuyerFetchMore, data }),
    buyersTableTitleChange: data => ({ type: actionTypes.BuyersTableTitleChange, payload:  data }),

    buyerFetch: data => ({ type: actionTypes.BuyerFetch, payload:  data }),
    
    buyerFetchTabs: data => ({ type: actionTypes.BuyerFetchTabs, payload:  data }),
    buyerFetched: data => ({ type: actionTypes.BuyerFetched, payload: { data } }),
    buyersFetched: (data) => ({ type: actionTypes.BuyersFetched, payload: data }),
    buyerCreated: data => ({ type: actionTypes.BuyerCreated, payload: { data } }),
    buyerUpdated: data => ({ type: actionTypes.BuyerUpdated, payload: { data } }),
    buyerDeleted: data => ({ type: actionTypes.BuyerDeleted, payload: { data } }),
    buyersDeleted: data => ({ type: actionTypes.BuyersDeleted, payload: { data } }),
    buyersStatusUpdated: data => ({ type: actionTypes.BuyersStatusUpdated, payload: { data } }),
    buyerError: error => ({ type: actionTypes.BuyerError, payload: { error } }),

};

export const getBuyers = (state) => state.buyers;


export function* sagaBuyers() {



    yield takeLatest(actionTypes.BuyersLoading, function* buyersLoading(prams) {

        // console.log('====================prams=============');
        // console.log(prams);
        // console.log('====================prams=============');
        yield put(actions.buyersLoadingIndicator({ indicator: true }));
        let buyersInfo = yield select(getBuyers);
        let queryData = prams.data ? prams.data : (buyersInfo.queryString ? buyersInfo.queryString : "")
        
        const res = yield getAllBuyers(prams.data);
        const { data, ...rest } = { ...res.data };
        yield put(actions.buyersFetched({ data: data, otherResInfo: rest , queryString: queryData}));
        yield put(actions.buyersLoadingIndicator({ indicator: false }));
        
    });

    yield takeLatest(actionTypes.BuyerSelected, function* buyerSelecteds({payload}) {
        console.log('called BuyerSelected');
        let project = yield select(getBuyers);
        let index = project.entities.findIndex(item => item.id === payload);
         console.log(project.entities);
         console.log(payload);
        console.log(index);



        yield put(actions.buyerFetch(payload));

        // const profileData = yield getBuyerById(payload);
        // yield put(actions.buyerFetched(profileData.data.data));

        yield put(actions.buyerSelectedId({id:payload, index: index  }));
        
    });

    yield takeLatest(actionTypes.BuyerSelectedChangeId, function* buyerSelected({payload}) {

        let buyers = yield select(getBuyers);

        let newIndex ;
        if (payload === "left") {
            newIndex = buyers.viewBuyer.index -1;
          }
          if (payload === "right") {
            newIndex = buyers.viewBuyer.index +1;
          }
        let newId = buyers.entities[newIndex].id

        yield put(actions.buyerFetch(newId));

        yield put(actions.buyerSelectedId({id:newId, index: newIndex  }));
        
    });

    yield takeLatest(actionTypes.BuyerFetchMore, function* buyerFetchMore(
        prams
      ) {
        yield put(actions.buyersLoadingIndicator({ indicator: true }));
        let buyersInfo = yield select(getBuyers);
        const res = yield getAllBuyers(prams.data);
        const { data, ...rest } = { ...res.data };

        let buyer_ids =    buyersInfo.entities.map((obj) => obj.id);

        let new_data_ids = data.map((obj) => obj.id);
let  newData 
          let merged=  prams?.data.split('&')
         
        if(_includes(merged, "merge=true" )){
            newData = [
                ...buyersInfo.entities.map((prosObj) =>
                  new_data_ids.includes(prosObj.id)
                    ? { ...data.find((obj) => obj.id === prosObj.id) }
                    : { ...prosObj }
                ),
                ...data.filter((dataObj) => !buyer_ids.includes(dataObj.id)),
              ]
         }else{
                newData = buyersInfo.entities.concat(data);
            }





        yield put(actions.buyersFetched({ data: newData, otherResInfo: rest }));
        yield put(actions.buyersLoadingIndicator({ indicator: false }));
      });


    yield takeLatest(actionTypes.BuyerFetch, function* BuyerFetch({payload}) {

        const buyer = yield getBuyerById(payload);

        yield put(actions.buyerFetched(buyer.data.data));


    });
}
