import React, { useState, createContext, useEffect } from "react";
import { toast } from "react-toastify";
import {
  convertFormData,
  getObjWithoutValues,
} from "../../../../_helpers/_utils/utils";
import { useDispatch } from "react-redux";
import * as tabRedux from "../../../_tab/_redux/tabRedux";
import {
  getRentalById,
  getRentalAppointment,
  adjustRentPrice,
} from "../../_crud/rentalsCrud";
import { useLocation } from "react-router-dom";
export const SingleRentalContext = createContext();
export const { Consumer } = SingleRentalContext;

const SingleRentalContextProvider = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [rentalId, setRentalId] = useState(null);
  const [rentalDetails, setRentalDetails] = useState(null);
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rentalPageLoading, setRentalPageLoading] = useState(false);

  const handleEdit = () => {
    setEdit(!edit);
  };

  useEffect(() => {
    update();
  }, []);

  const updateTabInfo = (values) => {
    let res = location.pathname.replaceAll("/", " ").split(" ");
    if (res.includes("rentals") && res.length > 2) {
      if (props?.valueChanged) {
      } else {
        dispatch(
          tabRedux.actions.changeNavTitle({
            name: "SingleRental",
            id: values.id,
            navTitle: values?.property?.address?.full_address,
            valueChanged: true,
          })
        );
      }

      //   // dispatch(tabRedux.actions.callTabManager({name: 'SingleListing' ,id:res[2],navTitle:res[2],props:{}}));
    }
  };

  const update = () => {
    setRentalPageLoading(true);
    getRentalById(props.id)
      .then(({ data: { data } }) => {
        setRentalDetails(data);
        setRentalId(data.id);
        updateTabInfo(data);
      })
      .catch(() => { }).finally(() => {
        setRentalPageLoading(false);
      });
    // getRentalAppointment(props.id).then(({ data: { data } }) => {
    //   setAppointments(data);
    // });
  };

  const adjustRentalPrice = (values, closeModal = () => { }) => {
    setLoading(true);
    values.rental = rentalId;
    const formData = convertFormData(
      getObjWithoutValues([undefined, null], values)
    );
    adjustRentPrice(formData)
      .then(({ data: { data } }) => {
        update();
        toast.success("Price Adjusted Successfully");
        setLoading(false);
        closeModal();
      })
      .catch(() => {
        setLoading(false);
        closeModal();
      });
    // adjustPrice(formData)
    //   .then(({ data: { data } }) => {
    //     toast.success('Post Saved Successfully');
    //   })
    //   .catch(() => {});
  };

  return (
    <SingleRentalContext.Provider
      value={{
        rentalDetails,
        edit,
        rentalId,
        loading,
        updateContext: update,
        handleEdit: handleEdit,
        adjustRentalPrice: adjustRentalPrice,
        setRentalDetails,
        setEdit,
        setLoading,
        rentalPageLoading
      }}
    >
      {props.children}
    </SingleRentalContext.Provider>
  );
};

export default SingleRentalContextProvider;
