/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
// import Dropdown from "react-bootstrap/Dropdown";
import { getCurrentAppVersion } from "../../../app/GeneralApi/GeneralApi";
import * as builder from "../../ducks/builder";
import { toAbsoluteUrl } from "../../utils/utils";
import { APP_VERSION } from "../../../app/const/appVersion";

const Brand = ({ brandClasses }) => {
  // class Brand extends React.Component {
  // render() {
  // const { brandClasses } = this.props;
  const [version, setVersion] = useState(APP_VERSION);

  useEffect(() => {
    getCurrentAppVersion()
      .then((res) =>
        console.log("Success response - Current Version", res?.data)
      )
      .catch((err) => console.error("Error while fetchin app version", err));
  }, []);

  return (
    <div
      className={`kt-header__brand ${brandClasses} kt-grid__item`}
      id="kt_header_brand"
    >
      <div className="kt-header__brand-logo">
        <Link to="/">
          <img
            className="kt-header__brand-logo-default"
            alt="logo"
            src={toAbsoluteUrl(
              "https://v2-wiseberryonline-com-au.s3-ap-southeast-2.amazonaws.com/assets/Main-Logo.png"
            )}
          />
          <div className="d-none">{`v. ${APP_VERSION}`}</div>

          {/* 
            <img
              className="kt-header__brand-logo-sticky"
              alt="logo"
              src={toAbsoluteUrl("/media/logos/logo.png")}
            /> */}
        </Link>
      </div>
    </div>
  );
};
// }

const mapStateToProps = (store) => {
  return {
    brandClasses: builder.selectors.getClasses(store, {
      path: "brand",
      toString: true,
    }),
  };
};

export default connect(mapStateToProps)(Brand);
