import React, { Fragment, useState } from "react";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import "./toggleSwitch.scss";

const ToggleSwitchBasic = ({ value, onChange, label, name, labelLeft ,labelTrue , labelFalse, labelClass}) => {

  const handleOnChange =(e) => {
    console.log(e.target.checked)
         if(e.target.checked){
          onChange(1)
         }
         else {
          onChange(0)
         }
      }
  return (
    <Fragment>
      <div className="d-flex toggle-switch">
        {label && labelLeft ? <h6 className={labelClass}>{label}</h6> : ""}
        <div>
          <Toggle
            name={name}
            checked={value === 1 ? true : false}
            icons={{
              checked: labelTrue,
              unchecked: labelFalse,
            }}
            onChange={handleOnChange}
          />
        </div>
        {label && !labelLeft ? <h6 className='m-auto'>{label}</h6> : ""} 
      </div>
    </Fragment>
  );
};

ToggleSwitchBasic.defaultProps = {
  label: false,
  labelLeft: false,
  labelTrue: "Yes",
  labelFalse: "No",
  labelClass: "m-auto",
  onChange: () => {}
 };

export default ToggleSwitchBasic;
