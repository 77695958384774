import React from "react";

import AddZoneAnalysisContextProvider from "./AddZoneAnalysisContextProvider";
import AddNewZoneAnalysisWrap from "./AddNewZoneAnalysisWrap";



const AddNewZoneAnalysis = (props) => {

  return (
    <AddZoneAnalysisContextProvider {...props}>
      <AddNewZoneAnalysisWrap {...props}/>
    </AddZoneAnalysisContextProvider>
  );
};



export default AddNewZoneAnalysis;
