import React, { Fragment } from "react";
import Item from "./Item";

const PersonalDetails = ({ profile }) => {
  return (
    <Fragment>
      <div className="pb-2 pt-2">
        <h6 className="font-weight-bold pb-3">Personal Details</h6>
        {profile?.addresses?.map((address) => (
          <Item
            left="Address"
            right={
              <div>
                {address?.street_unit ? address?.street_unit + "/" : ""}
                {address?.street_number} {address?.street?.name}
              </div>
            }
          />
        ))}
        {profile?.workAddress?.map((address) => (
          <Item
            left="Work"
            right={
              <div>
                {address?.street_unit ? address?.street_unit + "/" : ""}
                {address?.street_number} {address?.street?.name}
              </div>
            }
          />
        ))}
        <Item left="DOB" right={profile?.dob} />
      </div>
    </Fragment>
  );
};

export default PersonalDetails;
