import React, { Fragment, useEffect, useState } from "react";
import ThemeButton from "../button/ThemeButton";
import ThemeHeader from "../content/ThemeHeader";

const IdealTimeOutModal = ({ closeModal }) => {

    const [time, setTime] = useState(10);


  useEffect(() => {
    var seconds = time;
    var countdown = setInterval(function() {
      seconds--;
      setTime(seconds)
      if (seconds <= 0) clearInterval(countdown);
    }, 1000);
    const timer = setTimeout(() => {
      window.location.href = "/logout";
    }, 10000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Fragment>
      <ThemeHeader
        left={
          <Fragment>
            <h5 className="title">Session Timeout</h5>
          </Fragment>
        }
      />
      <div>
        <p>
          You are being logged out due to inactivity. Click continue to remain
          logged in.
        </p>
      </div>
      <div className="d-flex justify-content-between">
        <div className="pl-1">
          <p className="darkRed-color font-weight-bold" id="countdown">
            {time}
          </p>
        </div>

        <div>
          <ThemeButton className="green-button" onClick={closeModal}>
            Continue
          </ThemeButton>
        </div>
      </div>
    </Fragment>
  );
};

export default IdealTimeOutModal;
