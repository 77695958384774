import React, { useState, createContext, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import _last from "lodash/last";
import { toast } from "react-toastify";
import _isEmpty from "lodash/isEmpty";
import { deleteSocialMediaTemplates, getAllSocialMediaTemplates } from "../../crud/socialMedailCrud";
import { errorAlert, formToQueryString } from "../../../../_helpers/_utils/utils";
import Swal from "sweetalert2";
const _ = require("lodash");


export const WindowCardContext = createContext();
export const { Consumer } = WindowCardContext;

const queryString = require("query-string");

const WindowCardContextProvider = (props) => {

    const [template, setTemplates] = useState([]);
    const [continuousMode, setContinuousMode] = useState(false);
    const [enableImageLoading, setEnableImageLoading] = useState(false);

    const [otherResData, setOtherResData] = useState(null);
    const [prospectQuery, setProspectQuery] = useState(null);
    const [soldListings, setSoldListings] = useState([]);
    const [selectedListing, setSelectedListing] = useState(null);
    const [editBackground, setEditBackground] = useState(false);
    const [editText, setEditText] = useState(false);
    const [editFeatures, setEditFeatures] = useState(false);
    const [showEditSection, setShowEditSection] = useState(false);
    const [selectedNode, setSelectedNode] = useState(false);
    const [activeTab, setActiveTab] = useState("template");
    const [libraryDataSource, setLibraryDataSource] = useState([])
    const [selectedSocialTemplate, setSelectedSocialTemplate] = useState({})
    const [socialMediaTemplateLoading, setSocialMediaTemplateLoading] = useState(false)
    const [listingData, setListingData] = useState(null)
    const [auctionData, setAuctionData] = useState(null)
    const [category, setCategory] = useState(null)
    const [tempdata, setData] = useState(null)

    useEffect(() => {
        update();
      
    }, []);


    
    const deleteSocialTemplates = async (values, isInvalidTemplateDelete = false) => {
      setSocialMediaTemplateLoading(true)
      return await deleteSocialMediaTemplates(values)
        .then(({ data: { message } }) => {
          setSocialMediaTemplateLoading(false)
          if (!isInvalidTemplateDelete) {
            toast.success(message)
            update();
          }
        })
        .catch(err => {
          setSocialMediaTemplateLoading(false)
          errorAlert(err?.data)
        });
    }




    const handleTemplateDelete = selectedData => {
      let values = { selected_ids: selectedData.map(item => item.id) };
      Swal.fire({
        title: "Delete Template?",
        text: "Are you sure want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#A7A9AC",
        cancelButtonColor: "#2BB673",
        confirmButtonText: "Cancel",
        cancelButtonText: "Yes",
      }).then((result) => {
        if (result.isDismissed) {
          deleteSocialTemplates(values)
        }
      });
    };
  
  

    const onSubmitDrawerForm = (values) => {
        update(values);
    };


    const handleResetFilter = async () => {

    };



    const updateTable = (values) => {

    };

    const fetchMoreData = () => {
      let obj = {
        ...prospectQuery,
      };
      obj.page = otherResData?.current_page + 1;
      obj.loadMore = true;
      console.log(obj);
      if (otherResData?.next_page_url) {
        update(obj);
      }
    };
    const handleGlobalSearch = (value) => {
      update({ name: value });
    };

    const update = (values) => {
        let obj = {
          // allocated_to: user.id,
        //   ...prospectQuery,
          ...values,
          limit: 30,
          window_card: 1
        };
    
        setSocialMediaTemplateLoading(true)
        let query = formToQueryString(obj);
    
        getAllSocialMediaTemplates(query)
          .then((res) => {
            const { data, ...rest } = res.data;
            if (values?.loadMore) {
              setTemplates(template.concat(data));
            } else {
              setTemplates(data);
            }
            setOtherResData(rest);
          })
          .catch(err => {
            errorAlert(err?.data)
          })
          .finally(() => {
            setSocialMediaTemplateLoading(false)
          })
      };
    


    return (
        <WindowCardContext.Provider
            value={{
                setActiveTab,
                activeTab,
                onSubmitDrawerForm,
                fetchMoreData,
                handleGlobalSearch,
                updateTableData: update,
                updateTable,
                handleResetFilter, template,
                continuousMode,
                setContinuousMode,
                onSubmitDrawerForm,
                fetchMoreData,
                otherResData,
                handleGlobalSearch,
                updateTableData: update,
                soldListings,
                setSoldListings,
                selectedListing,
                setSelectedListing,
                editBackground,
                setEditBackground,
                editText,
                setEditText,
                editFeatures,
                setEditFeatures,
                showEditSection,
                setShowEditSection,
                selectedNode,
                setSelectedNode,
                activeTab,
                setActiveTab,
                libraryDataSource,
                setLibraryDataSource,
                selectedSocialTemplate,
                setSelectedSocialTemplate,
                socialMediaTemplateLoading,
                setProspectQuery,
                update,
                setEnableImageLoading,
                enableImageLoading,
                listingData,
                setListingData,
                auctionData,
                setAuctionData,
                setData,
                tempdata,
                handleTemplateDelete
            }}
        >
            {props.children}
        </WindowCardContext.Provider>
    );
};

export default WindowCardContextProvider;
