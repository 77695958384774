/* eslint-disable no-restricted-imports */
import { IconButton } from "@material-ui/core";
import React, { Fragment, useContext } from "react";
import AccordionContext from "react-bootstrap/AccordionContext";
import { useAccordionToggle } from "react-bootstrap";
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import ThemeButton from "../../button/ThemeButton";

function CustomToggle({
  children,
  eventKey,
  handleDownload,
  rightArrow = false,
  className = '',
  handleClick = () => { },
  expandedKeys = null,
  tabIndex = "0"
}) {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(eventKey, () =>
    console.log("totally custom!")
  );

  const isCurrentEventKey = expandedKeys ? expandedKeys.includes(eventKey) : currentEventKey === eventKey;

  return (
    <Fragment>
      <div
        onClick={(e) => {
          decoratedOnClick();
          handleClick(eventKey)
        }}
        className={`d-flex justify-content-between border-bottom mt-4 pb-3 cursor-hand ${className}`}
      >
        <p className="m-0 font-weight-bold">{children}</p>
        <div>
          {handleDownload && (
            <ThemeButton onClick={handleDownload} icon>
              <i className="fas fa-download"></i>
            </ThemeButton>
          )}
          {rightArrow ?
            <IconButton size="small" tabIndex={tabIndex}>
              {isCurrentEventKey ? (
                <KeyboardArrowDownIcon fontSize="inherit" />
              ) : (
                <KeyboardArrowRightIcon fontSize="inherit" />
              )}
            </IconButton> :
            <IconButton size="small" tabIndex={tabIndex}>
              {isCurrentEventKey ? (
                <KeyboardArrowDownIcon fontSize="inherit" />
              ) : (
                <KeyboardArrowLeftIcon fontSize="inherit" />
              )}
            </IconButton>}
        </div>
      </div>
    </Fragment>
  );
}

export default CustomToggle;
