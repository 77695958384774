import React from "react";
import { connect } from "react-redux";
import { reduxForm, FieldArray, formValueSelector } from "redux-form";
import _get from "lodash/get";
import QuickContactBody from "./QuickContactBody";
import { Row, Col } from "react-bootstrap";
import ThemeButton from "../../button/ThemeButton";

const validate = (values) => {
  const errors = {};
  const contactsError = [];
  const emailsError = [];
  const phonesError = [];
  let contact = _get(values, "contacts[0]", {});
  // let email = _get(contact, 'emails[0]', {});
  // let phone = _get(contact, 'phones[0]', {});
  // if (!email.value) {
  //   emailsError[0] = { value: 'Required' };
  // }
  // if (!phone.value) {
  //   phonesError[0] = { value: 'Required' };
  // }
  // if (emailsError.length) {
  //   if (!contactsError.length) contactsError[0] = {};
  //   contactsError[0].emails = emailsError;
  // }
  // if (phonesError.length) {
  //   if (!contactsError.length) contactsError[0] = {};
  //   contactsError[0].phones = phonesError;
  // }
  if (contactsError.length) {
    errors.contacts = contactsError;
  }
  return errors;
};

const QuickContact = (props) => {
  const {
    handleSubmit,
    invalid,
    pristine,
    submitting,
    contactDetails,
    setContactUpdate,
  } = props;
  return (
    <div
      className={`quick-contact-form ${
        contactDetails ? "p-4" : "p-2"
      } mb-2 mt-2 darkBlue-background `}
    >
      <form
        onSubmit={handleSubmit}
        className={`${props.mobile && "increaseFont"}`}
      >
        <FieldArray
          name="contacts"
          change={props.change}
          component={QuickContactBody}
          contactDetails={contactDetails}
          // setContactUpdate={setContactUpdate}
        />
        <Row>
          <Col>
            {/* <p>
              <IconButton
                onClick={() => fields.push({ emails: [{}], phones: [{}] })}
                size="small"
                className="brand-icon-button"
              >
                <i className="fas fa-plus-circle"></i>
              </IconButton>
              Add Contact
            </p> */}

            <div className="d-flex justify-content-end">
              <ThemeButton type="submit" className="submit-button">
                Save Contact
              </ThemeButton>
            </div>
          </Col>
        </Row>
      </form>
    </div>
  );
};

const selector = formValueSelector("quick-contact-form");

function mapStateToProps(state, props) {
  // const selector = formValueSelector(props.formName);
  // const number_of_children = selector(state, "number_of_children");
  const values = selector(state, "contacts");
  if (props.setContactUpdate != null) {
    props.setContactUpdate(values);
  }
  // console.log('=========formValueSelector===========================');
  // console.log(values);
  // console.log('========formValueSelector============================');
  // console.log(props);
  return {
    initialValues: {
      contacts: [
        {
          emails: [{ type: 1, value: "" }],
          phones: [{ type: 1, value: "" }],
          first_name: "",
          last_name: "",
        },
      ],
    },
  };
}

export default connect(
  mapStateToProps,
  null
)(
  reduxForm({ form: "quick-contact-form", validate, enableReinitialize: true })(
    QuickContact
  )
);
