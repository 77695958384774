import React, { Fragment } from "react";

const StreetSuburb = ({
  value,
  expiredclassName = "",
  checkExpiration = false,
  isExpired = false,
  isExpiring = false,
  visited = null,
  seen = null
}) => {
  const getExpiredClassName = () => {
    let color = "";
    if (checkExpiration) {
      if (isExpired) {
        color = "red-color";
      } else if (isExpiring) {
        color = "blue-color";
      }
    }
    else {
      //color = visited ? "green-color" : "";
      color = seen ? "green-color" : "";
    }
    return color;
  };

  return (
    <Fragment>
      <div className={`break-word text-capitalize ${getExpiredClassName()}`}>
        {value?.street_unit ? value?.street_unit + "/" : ""}
        {value?.street_number} {value?.street?.name}
        {value?.street?.suburb?.name ? ", " + value?.street?.suburb?.name : ""}
      </div>
    </Fragment>
  );
};

export default StreetSuburb;
