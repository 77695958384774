import React, { Fragment, useState } from "react";
import { reduxForm, FieldArray } from "redux-form";
import validate from "./validate";
import ThemeHeader from "../../../../partials/content/ThemeHeader";
import ThemeButton from "../../../../partials/button/ThemeButton";
import DefaultModal from "../../../../partials/modal/DefaultModal";
import FormContacts from "../../../../partials/form/partials/formContacts/FormContacts";
import AddLinkedContacts from "../../../contacts/modals/addLinkedContacts/AddLinkedContacts";
import {
  convertFormData,
  getObjWithoutValues,
  postProcessedContactInfo,
} from "../../../../_helpers/_utils/utils";
import {
  createNewContact,
  updateContact,
} from "../../../contacts/crud/contactsCrud";
import { toast } from "react-toastify";
import NewContact from "../../../contacts/modals/contactFormModal/NewContact";

const AddNewListingContacts = (props) => {
  const { handleSubmit, contactValues } = props;

  const [modalShow, setModalShow] = useState(false);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [deleteItem, setDeleteItem] = useState(0);
  const [contactModalShow, setContactModalShow] = useState(false);
  const handleOnSelect = (e) => {
    // let selectedValue = e.map( s => ({label:s.name, value: s.id}) )
    setSelectedContacts(e);
  };

  const handleContactOnsubmit = () => {
    let formatedValue;
    const { id, notes, ...othersContact } = contactValues;

    const postProcessedContact = postProcessedContactInfo(othersContact);

    formatedValue = {
      notes: notes ? (notes[0]?.detail ? notes : null) : null,
      ...postProcessedContact,
    };

    const formData = convertFormData(
      getObjWithoutValues([undefined, null], formatedValue)
    );
    if (id) {
      formData.append("_method", "PATCH");

      updateContact(id, formData)
        .then(({ data: { data } }) => {
          toast.success("Contact updated Successfully");
          setContactModalShow(false);
          setSelectedContacts([data]);
        })
        .catch((e) => {
          toast.error("Error while saving");
        });
    } else {
      createNewContact(formData)
        .then(({ data: { data } }) => {
          toast.success("Contact created Successfully");
          setSelectedContacts([data]);
          setContactModalShow(false);
        })
        .catch((e) => {
          toast.error("Error while saving");
        });
    }
  };
  return (
    <Fragment>
      {contactModalShow ? (
        <DefaultModal
          size="xl"
          show={contactModalShow}
          onHide={() => setContactModalShow(false)}
        >
          <NewContact
            submitFromDiffComponent={handleContactOnsubmit}
            closeModal={() => setContactModalShow(false)}
          />
        </DefaultModal>
      ) : (
        ""
      )}
      {modalShow ? (
        <DefaultModal
          size="xl"
          show={modalShow}
          onHide={() => setModalShow(false)}
        >
          <AddLinkedContacts
            closeModal={() => setModalShow(false)}
            onSelect={handleOnSelect}
          />
        </DefaultModal>
      ) : (
        ""
      )}
      <ThemeHeader
        right={
          <Fragment>
            <ThemeButton
              className="mr-2 lightGrey-button"
              onClick={() => setDeleteItem((prev) => prev + 1)}
            >
              Remove{" "}
            </ThemeButton>
            <ThemeButton
              className="theme-button green-button mr-2"
              onClick={() => setModalShow(true)}
            >
              Select Existing Contact
            </ThemeButton>
            <ThemeButton
              // disabled={pristine || submitting}
              className="theme-button green-button"
              onClick={() => setContactModalShow(true)}
            >
              Create New Contact
            </ThemeButton>
          </Fragment>
        }
      />
      <form onSubmit={handleSubmit}>
        <FieldArray
          name="contacts"
          deleteItem={deleteItem}
          update={selectedContacts}
          height="65"
          component={FormContacts}
          defaultContactType="3"
          roleFilter={[3, 4, 6, 13, 15]}
        />
      </form>
    </Fragment>
  );
};

export default reduxForm({
  form: "add-new-rental", //                 <------ same form name
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate,
})(AddNewListingContacts);
