import React, { Fragment, useEffect } from "react";
import BuyerDetailsView from "./BuyerDetailsView";
import { getBuyerById } from "../../../crud/buyersCrud";
import AccessControl from "../../../../../_ accessControl/AccessControl";
import BuyerDetailsEdit from "./BuyerDetailsEdit";
import { getBuyerProcessedInfo } from "../../../../../_helpers/_utils/utils";
import { useSelector } from "react-redux";

const BuyerDetails = ({ profile }) => {
  // useEffect(() => {
  //   getBuyerById(2)
  //     .then(({ data }) => {
  //       console.log(data);
  //     })
  //     .catch(() => {});
  // }, []);
  const permissions = useSelector((state) => state.auth.user.permissions);

  return (
    <Fragment>
      <AccessControl
        allowedPermissions={["buyers-edit"]}
        renderNoAccess={() => (
          <BuyerDetailsView
            looking_date={getBuyerProcessedInfo(profile)?.looking_date}
            ready_in_date={getBuyerProcessedInfo(profile)?.ready_in_date}
          />
        )}
      >
        {" "}
        <BuyerDetailsEdit profile={getBuyerProcessedInfo(profile)} />{" "}
      </AccessControl>
      {/* {permissions?.some((permission) =>
        ["buyers-edit"].includes(permission?.name)
      ) ? (
       
      ) : (
        <BuyerDetailsView
          looking_date={getBuyerProcessedInfo(profile)?.looking_date}
          ready_in_date={getBuyerProcessedInfo(profile)?.ready_in_date}
        />
      )} */}
      {/* <AccessControl
        allowedPermissions={["edit-buyer"]}
        renderNoAccess={() => 
        }
      >
       
      </AccessControl> */}
    </Fragment>
  );
};

export default BuyerDetails;
