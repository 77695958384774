import React, { useEffect, useState } from "react";
import 'antd/dist/antd.css';
import ImgCrop from "antd-img-crop";
import { Upload } from "antd";

export const UserImageInputWithCropper = (props) => {
  const [imageUrl, setImageUrl] = useState(null)
  const {
    input: { onChange, value },
    disabled,
    className = '',
    aspectRatio = 1,
    dropZoneStyle = {},
    placeholderImg = ''
  } = props;

  useEffect(() => {
    if (value instanceof Array) {
      setImageUrl(URL.createObjectURL(value[0]))
    } else {
      setImageUrl(value)
    }
  }, [value])

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const handleFiles = ({ file }) => {
    if (file.status === 'done') {
      onChange([file.originFileObj]);
    }
  }
  if (disabled) {
    return null;
  }

  return (
    <div className='antd-image-upload-with-crop'>
      <ImgCrop rotate aspect={aspectRatio}>
        <Upload
          style={{ width: '100%', height: '100%' }}
          className={className}
          showUploadList={false}
          customRequest={dummyRequest}
          listType="picture-card"
          onChange={handleFiles}
          accept='.jpeg,.jpg,.png'
        >
          <div
            style={{
              backgroundColor: '#C1DAE6',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              ...dropZoneStyle
            }}
            className={"image-icon-preview"}
          >
            <img
              className={"image-edit"}
              src={imageUrl ? imageUrl : placeholderImg ? placeholderImg : "/media/upload/default_image.jpeg"}
              alt="image0"
            />
            {/* {imageUrl
            ? ( */}
            <div className="preview-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="1rem" height="1rem" viewBox="0 0 24 24">
                <path fill="#ffffff" d="M5 5h-3v-1h3v1zm8 5c-1.654 0-3 1.346-3 3s1.346 3 3 3 3-1.346 3-3-1.346-3-3-3zm11-4v15h-24v-15h5.93c.669 0 1.293-.334 1.664-.891l1.406-2.109h8l1.406 2.109c.371.557.995.891 1.664.891h3.93zm-19 4c0-.552-.447-1-1-1-.553 0-1 .448-1 1s.447 1 1 1c.553 0 1-.448 1-1zm13 3c0-2.761-2.239-5-5-5s-5 2.239-5 5 2.239 5 5 5 5-2.239 5-5z" />
              </svg>
            </div>
            {/* )
            : null
          } */}
          </div>
        </Upload>
      </ImgCrop>
    </div>
  );
};
