import React,{useState, useEffect} from "react";
import {useSelector} from "react-redux"
import ReactDOM from "react-dom";
import Modal from "react-modal";
import {getLeadersAvailable, postLeaderPairing} from "../../../../GeneralApi/GeneralApi"
import { toast } from "react-toastify";
import {getUserByToken} from "../../../auth/curd/auth.crud"


const customStyles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(54, 53, 53, 0.5)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    minWidth: "20%",
    padding: 0,
    border: 0
  },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");

const LeaderPairingModal = ({ openModal2, closeModal2, modalIsOpen2, setUserDetails, userDetails }) => {
    const user = useSelector((state) => state.auth.user);

    const [availableLeader, setAvailableLeader] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [leader, setLeader] = useState("")


  

    const update = () => {
        getLeadersAvailable()
          .then(({ data: { data } }) => {
            setAvailableLeader(data);
          })
          .catch((e) => console.log("error"));
      };



      const updateUser =()=>{
        getUserByToken()
        .then(({ data: { data } }) => {
          setUserDetails(data);
        })
        .catch((e) => console.log("error"));
        }; 

      useEffect(() => {
        update();
        updateUser();
      }, []);


const handleSubmit = async()=>{

    let payload= {
    leader: user?.contact.id,
    leader_to_rate: leader
    }
    setIsLoading(true);
   await  postLeaderPairing(payload)
    .then(( res) => {
        toast.success('Successfully Added ')
        setIsLoading(false);
        closeModal2()
        if(res)updateUser()
        
    })
    .catch((e) => console.log("error"));
}


  const maptoOption =  availableLeader?.map((item,i)=>{
    let options = {
        value: item.id , label: `${item.first_name}  ${item.last_name} `
    }
    return (options)
})

// console.log('============availableLeader========================');
// console.log(availableLeader);
// console.log(user);
// console.log(maptoOption);
// console.log(leader);


// console.log('============availableLeader========================');

  return (
    <div>
      <Modal
        isOpen={modalIsOpen2}
        onRequestClose={closeModal2}
        style={customStyles}
        contentLabel="Leadership Rating Board"
      >
        <div
          style={{
            display: "flex",
            margin: '10px, 0',
            padding: 20,
            justifyContent: " center",
            background: "#822533",
            color: "#ffff",
            fontSize: 15,
            fontWeight: 800,
            marginBottom: 25,
                      }}
        >
          Leadership Pairing Board
        </div>
        <div
          style={{
            display: "flex",
            marginBottom: 20,
            paddingLeft: 40,
            paddingRight: 40,
            justifyContent: " space-between",
          }}
        >
          <div style={{fontWeight: 600, fontSize: 13, width: "50%"}}>
            {" "}
            <label for="leader-select">Pairing Leader:</label>
          </div>
          <div style={{fontWeight: 700, fontSize: 13, display:'flex', width: "50%", justifyContent: 'flex-end'}}>
          <select name="problem" id="problem-select"
               value={leader}
            onChange={e => setLeader(e.target.value)}
            >
               <option value="" >
               Select a Leader...
               </option>
              {maptoOption.map((option, i) => (
                <option value={option.value} key={i}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </div>
       
          
        <div
          style={{
            display: "flex",
            justifyContent: " flex-end",
            padding: 10,
            paddingRight: 20,
            backgroundColor: "#3635350d",
          }}
        >
        {/* <div style={{width:'50%', display: 'flex', justifyContent: "space-between"}}> */}
        <button style={{marginRight: "5%", fontWeight: 'bold', color: '#fff', maxHeight: 24, background: "#414042", border: 0, borderRadius: '0.25rem'}} onClick={closeModal2}>Cancel</button>
          <button style={{ fontWeight: 'bold', background: (isLoading || leader ==="")? '#2bb67366' : '#2bb673', maxHeight: 24, color: '#fff', border: 0, borderRadius: '0.25rem', }} disabled={isLoading || leader=== ""}  onClick={handleSubmit}>Save</button>
        {/* </div> */}
         
        </div>
      </Modal>
    </div>
  );
};

export default LeaderPairingModal;





