import axios from "axios";
import faker from "faker";

let cancelRequest = null;
// const WiseBerryAPI = process.env.REACT_APP_WISEBERRY_API;
const WiseBerryAPI = process.env.REACT_APP_WISEBERRY_API;

// const WiseBerryAPI = 'http://127.0.0.1:8002/api';
export const LOGIN_URL = WiseBerryAPI + "/login";
export const APM_URL = WiseBerryAPI + "apm";
export const REGISTER_URL = WiseBerryAPI + "/register";
export const REQUEST_PASSWORD_URL = WiseBerryAPI + "/forgot-password";
export const ME_URL = WiseBerryAPI + "/details";
export const MemberStat = WiseBerryAPI + "member/ratio";

export const DIARY_URL_APPOINTMENT = WiseBerryAPI + "member/dashboard";
export const DIARY_URL_TASK = WiseBerryAPI + "task";

export const MARKET_REVIEW = WiseBerryAPI + "market-review-feedback";
export const BUYER_FEEDBACK = WiseBerryAPI + "buyer-feedback";
export const TENANT_FEEDBACK = WiseBerryAPI + "tenant-feedback";
export const LISTING_FEEDBACK = WiseBerryAPI + "listing-feedback";
export const OPEN_HOME_FEEDBACK = WiseBerryAPI + "open-home-feedback";
export const RENTAL_OPEN_HOME_FEEDBACK =
  WiseBerryAPI + "rental-open-home-feedback";
export const SELLER_VISIT_FEEDBACK = WiseBerryAPI + "seller-visit-feedback";

export const RENTAL_FEEDBACK = WiseBerryAPI + "rental-feedback";
export const HI_TOUCH = WiseBerryAPI + "hi-touch";
export const HOT_PROPERTIES = WiseBerryAPI + "hot-properties";

export const LISTING_EXPIRING = WiseBerryAPI + "listing/expiring";
export const FORECAST = WiseBerryAPI + "forecast";
export const ENQUIRY = WiseBerryAPI + "enquiry";
export const CMA = WiseBerryAPI + "cma";
export const APPOINTMENT = WiseBerryAPI + "appointment";
export const PENDING_SALES = WiseBerryAPI + "sale";

export const PROSPECTS = WiseBerryAPI + "prospect";
export const CALLREMINDER = WiseBerryAPI + "call-reminde";

export const BUYERCALLSETTING =
  WiseBerryAPI + "organisation/buyer-call-setting";
  

export function getMemberStatistics(prams) {
  return axios.get(MemberStat + (prams ? "?" + prams : ""));
}
export function getIndividualStatistics(prams) {
  return axios.get(
    WiseBerryAPI + "member/individual-stats" + (prams ? "?" + prams : "")
  );
}

export function updateBonusTarget(params) {
  return axios.post(WiseBerryAPI + "member/target", params, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

// Listing FeedBack
export function getEnquiries(prams) {
  return axios.get(ENQUIRY + (prams ? "?" + prams : ""));
}
export function getEnquiryById(id) {
  return axios.get(ENQUIRY + "/" + id);
}
export function postEnquiry(prams) {
  return axios.post(ENQUIRY, prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}
export function updateEnquiry(id, prams) {
  return axios.post(ENQUIRY + "/" + id, prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function bulkDeleteEnquiry(prams) {
  return axios.post(ENQUIRY + "/bulk-delete", prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}
export function bulkDeleteCallReminder(prams) {
  return axios.post(CALLREMINDER + "/bulk-delete", prams);
}
// Listing FeedBack

export function bulkCompleteEnquiry(prams) {
  return axios.post(ENQUIRY + "/bulk-complete", prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}
// ActivityLog
export function getActivityLogSetting(param) {
  return axios.get(WiseBerryAPI + "activity-log-setting" + (param? "?" + param : ""));
}

export function updateActivityLogSetting(prams) {
  return axios.post(WiseBerryAPI + "activity-log-setting/bulk-update", prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function getActivityLog(qString) {
  return axios.get(
    WiseBerryAPI +
    "member/member-activity-logs" +
    (qString ? "?" + qString : "")
  );
}

export function getTeamActivityLog(qString) {
  return axios.get(
    WiseBerryAPI + "member/team-activity-logs" + (qString ? "?" + qString : "")
  );
}

export function postActivityLog(prams) {
  return axios.post(WiseBerryAPI + "member/activity-log-comment", prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

// Birthday

export function getContactAnniversary(qString) {
  return axios.get(
    WiseBerryAPI + "anniversary" + (qString ? "?" + qString : "")
  );
}

export function completeContactAnniversary(prams) {
  return axios.post(WiseBerryAPI + "anniversary" + "/complete", prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function deleteContactAnniversary(prams) {
  return axios.post(WiseBerryAPI + "anniversary" + "/bulk-delete", prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function getDashboard(prams) {
  return axios.get(DIARY_URL_APPOINTMENT + (prams ? "?" + prams : ""));
}
export function getEnquiryLogSettings(prams) {
  return axios.get(`${WiseBerryAPI}organisation/enquiry-setting` + (prams ? "?" + prams : ""));
}

export function postEnquirySettings(prams) {
  return axios.post(`${WiseBerryAPI}organisation/enquiry-setting`, prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}
export function getEnquiryLogDistribution(prams) {
  return axios.get(`${WiseBerryAPI}enquiry-distribution` + (prams ? "?" + prams : ""));
}
export function postEnquiryDistribution(id, prams) {
  return axios.patch(`${WiseBerryAPI}enquiry-distribution/${id}`, prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}
export function postUpdateDashboard(prams) {
  return axios.post(DIARY_URL_APPOINTMENT, prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function getAllDiaryAppointmentList(prams) {
  return axios.get(DIARY_URL_APPOINTMENT + (prams ? "?" + prams : ""));
}

export function getAllAppointment(prams) {
  return axios.get(APPOINTMENT + (prams ? "?" + prams : ""));
}

export function updateAppointment(id, prams) {
  return axios.post(APPOINTMENT + "/" + id, prams, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function deleteAppointment(prams) {
  return axios.post(APPOINTMENT + "/bulk-delete", prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function completeAppointments(prams) {
  return axios.post(APPOINTMENT + "/bulk-update", prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function getAppointmentById(id) {
  return axios.get(DIARY_URL_APPOINTMENT + "/" + id);
}

export function getAllTasks(prams) {
  return axios.get(
    DIARY_URL_TASK +
    // + '?limit=30'
    (prams ? "?" + prams : "")
  );
}

export function postUpdateDiaryAppointmentById(id, prams) {
  return axios.post(DIARY_URL_APPOINTMENT + "/" + id, prams, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function postUpdateDiaryTaskById(id, prams) {
  return axios.post(DIARY_URL_TASK + "/" + id, prams, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function createNewAppointment(prams) {
  return axios.post(DIARY_URL_APPOINTMENT, prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function createNewTask(prams) {
  return axios.post(DIARY_URL_TASK, prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function completeTasks(prams) {
  return axios.post(DIARY_URL_TASK + "/complete", prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function undoTask(prams) {
  return axios.post(DIARY_URL_TASK + "/undo", prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function deleteTasks(prams) {
  return axios.post(DIARY_URL_TASK + "/bulk-delete", prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function createAddress(prams) {
  return axios.post(WiseBerryAPI + "address", prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function createPropertyByAddress(prams) {
  return axios.post(WiseBerryAPI + "property", prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function findProspectByAddress(prams) {
  return axios.get(PROSPECTS + "/find-by-address?" + prams);
}

// Market Review
export function getMarketReview(prams) {
  return axios.get(MARKET_REVIEW + (prams ? "?" + prams : ""));
}
export function getMarketReviewById(id) {
  return axios.get(MARKET_REVIEW + "/" + id);
}
export function postMarketReview(prams) {
  return axios.post(MARKET_REVIEW, prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}
export function bulkDeleteMarketReview(prams) {
  return axios.post(MARKET_REVIEW + "/bulk-delete", prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function updateMarketReview(id, prams) {
  return axios.post(MARKET_REVIEW + "/" + id, prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}
export function bulkUpdateMarketReview(prams) {
  return axios.get(MARKET_REVIEW, prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

// Market Review

// Buyer FeedBack
export function getBuyerFeedback(prams) {
  return axios.get(BUYER_FEEDBACK + (prams ? "?" + prams : ""));
}
export function getBuyerFeedbackById(id) {
  return axios.get(BUYER_FEEDBACK + "/" + id);
}
export function postBuyerFeedback(prams) {
  return axios.post(BUYER_FEEDBACK, prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}
export function bulkDeleteBuyerFeedback(prams) {
  return axios.post(BUYER_FEEDBACK + "/bulk-delete", prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function updateBuyerFeedback(id, prams) {
  return axios.post(BUYER_FEEDBACK + "/" + id, prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}
export function bulkUpdateBuyerFeedback(prams) {
  return axios.get(BUYER_FEEDBACK, prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

// Buyer FeedBack

// Tenant FeedBack
export function getTenantFeedback(prams) {
  return axios.get(TENANT_FEEDBACK + (prams ? "?" + prams : ""));
}
export function getTenantFeedbackById(id) {
  return axios.get(TENANT_FEEDBACK + "/" + id);
}
export function postTenantFeedback(prams) {
  return axios.post(TENANT_FEEDBACK, prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}
export function deleteTenantFeedback(prams) {
  return axios.post(TENANT_FEEDBACK + "/bulk-delete", prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}
export function updateTenantFeedback(id, prams) {
  return axios.post(TENANT_FEEDBACK + "/" + id, prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}
export function bulkUpdateTenantFeedback(prams) {
  return axios.get(TENANT_FEEDBACK, prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function bulkDeleteTenantFeedback(prams) {
  return axios.post(TENANT_FEEDBACK + "/bulk-delete", prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

// Tenant FeedBack

// Listing FeedBack
export function getListingFeedback(prams) {
  return axios.get(LISTING_FEEDBACK + (prams ? "?" + prams : ""));
}
export function getListingFeedbackById(id) {
  return axios.get(LISTING_FEEDBACK + "/" + id);
}
export function postListingFeedback(prams) {
  return axios.post(LISTING_FEEDBACK, prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}
export function deleteListingFeedback(prams) {
  return axios.post(LISTING_FEEDBACK + "/bulk-delete", prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}
export function updateListingFeedback(id, prams) {
  return axios.post(LISTING_FEEDBACK + "/" + id, prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}
export function bulkUpdateListingFeedback(prams) {
  return axios.get(LISTING_FEEDBACK, prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

// Listing FeedBack

// Listing FeedBack
export function getExpiringProperties(prams) {
  return axios.get(LISTING_EXPIRING + (prams ? "?" + prams : ""));
}

// Listing FeedBack

// SELLER_VISIT_FEEDBACK
export function getSellerVisitFeedback(prams) {
  return axios.get(SELLER_VISIT_FEEDBACK + (prams ? "?" + prams : ""));
}
export function getSellerVisitFeedbackById(id) {
  return axios.get(SELLER_VISIT_FEEDBACK + "/" + id);
}
export function postSellerVisitgFeedback(prams) {
  return axios.post(SELLER_VISIT_FEEDBACK, prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}
export function deleteSellerVisitFeedback(prams) {
  return axios.post(SELLER_VISIT_FEEDBACK + "/bulk-delete", prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}
export function updateSellerVisitFeedback(id, prams) {
  return axios.post(SELLER_VISIT_FEEDBACK + "/" + id, prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}
export function bulkUpdateSellerVisitFeedback(prams) {
  return axios.get(SELLER_VISIT_FEEDBACK, prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

// SELLER_VISIT_FEEDBACK

// Rental FeedBack
export function getRentalFeedback(prams) {
  return axios.get(RENTAL_FEEDBACK + (prams ? "?" + prams : ""));
}
export function getRentalFeedbackById(id) {
  return axios.get(RENTAL_FEEDBACK + "/" + id);
}
export function postRentalFeedback(prams) {
  return axios.post(RENTAL_FEEDBACK, prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function deleteRentalFeedback(prams) {
  return axios.post(RENTAL_FEEDBACK + "/bulk-delete", prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}
export function updateRentalFeedback(id, prams) {
  return axios.post(RENTAL_FEEDBACK + "/" + id, prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}
export function bulkUpdateRentalFeedback(prams) {
  return axios.get(RENTAL_FEEDBACK, prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

// Rental FeedBack

// Hot Properties
export function getHotProperties(prams) {
  return axios.get(HOT_PROPERTIES + (prams ? "?" + prams : ""));
}
export function getHotPropertiesById(id) {
  return axios.get(HOT_PROPERTIES + "/" + id);
}
export function postHotProperties(prams) {
  return axios.post(HOT_PROPERTIES, prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}
export function updateHotProperties(id, prams) {
  return axios.post(HOT_PROPERTIES + "/" + id, prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}
export function bulkDeleteHotProperties(prams) {
  return axios.post(HOT_PROPERTIES + "/bulk-delete", prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

// Hot Properties

// Pending SALES
export function getAllPendingSales(prams) {
  if (cancelRequest) {
    cancelRequest.cancel('Cancelled due to new request');
  }
  cancelRequest = axios.CancelToken.source();
  return axios.get(PENDING_SALES + (prams ? "?" + prams : ""), {
    cancelToken: cancelRequest.token
  });
}

// Pending SALES

//open home Feedback
export function getOpenHomeFeedback(prams) {
  return axios.get(OPEN_HOME_FEEDBACK + (prams ? "?" + prams : ""));
}

export function deleteOpenHomeFeedback(prams) {
  return axios.post(OPEN_HOME_FEEDBACK + "/bulk-delete", prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function postOpenHomeFeedback(prams, id = null) {
  let url = OPEN_HOME_FEEDBACK
  if (id) {
    url += `/${id}`
  }
  return axios.post(url, prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

//rental open home Feedback
export function getRentalOpenHomeFeedback(prams) {
  return axios.get(RENTAL_OPEN_HOME_FEEDBACK + (prams ? "?" + prams : ""));
}

export function postRentalOpenHomeFeedback(prams, id = null) {
  let url = RENTAL_OPEN_HOME_FEEDBACK
  if (id) {
    url += `/${id}`
  }
  return axios.post(url, prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

// High Touch
export function getHiTouch(prams) {
  return axios.get(HI_TOUCH + (prams ? "?" + prams : ""));
}

export function postHiTouch(prams) {
  return axios.post(HI_TOUCH, prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function deleteHiTouch(id) {
  return axios.delete(HI_TOUCH + "/" + id);
}

export function deleteBulkHighTouch(prams) {
  return axios.post(HI_TOUCH + "/bulk-delete", prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}
// High Touch
export function getForecasts(prams) {
  return axios.get(FORECAST + (prams ? "?" + prams : ""));
}

export function postForecasts(prams) {
  return axios.post(FORECAST, prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function updateForecasts(prams, id) {
  return axios.post(FORECAST + "/" + id, prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function getCMAs(prams) {
  return axios.get(CMA + (prams ? "?" + prams : ""));
}

export function getCMAsByAddress(prams) {
  return axios.get(CMA + "/fetch" + (prams ? "?" + prams : ""));
}

export function updateCMA(prams, id) {
  return axios.post(CMA + "/" + id, prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function getApmSettings() {
  return axios.get(APM_URL);
}


export function getBuyerToCallSettings(prams) {
  return axios.get(BUYERCALLSETTING + (prams ? "?" + prams : ""));
}

export function updateBuyerToCallSettings(prams) {
  return axios.post(BUYERCALLSETTING, prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}
//send postlisting from listing feedback and listing details page

//microsite/send-microsite-email/{id}
export function sendMicrosteToProspectEmail(id) {
  return axios.get(DIARY_URL_APPOINTMENT + "/send-pre-listing-email/"+id);
}

