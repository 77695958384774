import React, { Fragment, useEffect, useState } from "react";
import DragAndDrop from "../../../../../partials/form/DragAndDrop";
import { Card, OverlayTrigger, Popover } from "react-bootstrap";
import { IconButton } from "@material-ui/core";
import {
  getFilesByContact,
  uploadFilesByContact,
  fileDelete,
} from "../../../crud/contactsCrud";
import { reduxForm } from "redux-form";
import {
  convertFormData,
  getFileType,
  formatBytes,
  downloadImageFromUrl,
} from "../../../../../_helpers/_utils/utils";
import { toast } from "react-toastify";
import ToShare from "../../../../../partials/popover/toShare/ToShare";
import { connect } from "react-redux";
import ThemeButton from "../../../../../partials/button/ThemeButton";

const ContactsFiles = ({ contactId, handleSubmit, profileIno, profile }) => {
  const [files, setFiles] = useState(null);
  useEffect(() => {
    update();
  }, [contactId]);

  const update = (prams) => {
    if (contactId) {
      getFilesByContact(contactId)
        .then(({ data: { data } }) => {
          setFiles(data);
        })
        .catch((e) => { });
    }
  };

  const handleUploadFiles = (files) => {
    let value = {};
    value.contact = contactId;
    value.files = files;

    let formdData = convertFormData(value);

    console.log(...formdData);
    uploadFilesByContact(formdData)
      .then(({ data: { data } }) => {
        update();
        toast.success("File Added Successfully");
      })
      .catch((e) => { });
  };

  const handleDelete = (key) => {
    fileDelete(key)
      .then(({ data: { data } }) => {
        console.log(data);
        toast.success("File Deleted Successfully");
        update();
      })
      .catch((e) => { });
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <DragAndDrop handleUploadFiles={handleUploadFiles} />
      </form>

      <div className="row contactsFiles-files">
        {files?.map((file, id) => (
          <Fragment key={id}>
            <div className="col-lg-6 pb-4">
              <Card>
                <div className="card-body">
                  <div className="row text-left ">
                    {getFileType(file.name) !== ("jpg" || "png") ? (
                      <Fragment>
                        {" "}
                        <div className="col-2 file-type lightGrey-color">
                          <i
                            className={`far fa-file-${getFileType(file.name)}`}
                          ></i>
                        </div>{" "}
                      </Fragment>
                    ) : (
                      <div className="col-2 file-type image">
                        <img
                          src={file.url}
                          alt={file.name}
                          className="img-thumbnail"
                        />
                      </div>
                    )}

                    <div className="col-6">
                      <h6 className="font-weight-bold">{file.name}</h6>
                      <p>CL on 20/08/20 at 3.35 pm</p>
                    </div>
                    <div className="col-4">
                      <div className="d-flex justify-content-end pb-2">
                        <OverlayTrigger
                          trigger="click"
                          placement="right"
                          overlay={
                            <Popover id="popover-basic">
                              <ToShare profile={profile} url={file.url} />
                            </Popover>
                          }
                        >
                          <ThemeButton icon className="darkGrey-icon-button">
                            <i className="fas fa-share-square"></i>
                          </ThemeButton>
                        </OverlayTrigger>
                        {/* <a href={file.url} target="blank" download> */}
                        <ThemeButton onClick={() => downloadImageFromUrl(file.url)} icon className="darkGrey-icon-button">
                            <i className="fas fa-download"></i>
                          </ThemeButton>
                        {/* </a> */}
                        <ThemeButton onClick={() => handleDelete(file.id)} icon className=" pr-0 lightGrey-icon-button">
                          <i
                            className="fas fa-minus-circle"
                            fontSize="small"
                          ></i>
                        </ThemeButton>
                      </div>
                      {/* <h3>2495/-</h3> */}
                      <div className="d-flex justify-content-end">
                        <p>{formatBytes(file.size)}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          </Fragment>
        ))}


      </div>
    </div>
  );
};


const mapStateToProps = ({ contacts: { profile } }) => ({
  profileIno: profile,
});

export default connect(mapStateToProps, null)(reduxForm({ form: "contact-files", enableReinitialize: true })(
  ContactsFiles
));