import React, { Fragment } from "react";
import BasicInfoAva from "../../../../../partials/display/BasicInfoAva";
import Connections from "../../../../../partials/display/Connections";

import LinkedContacts from "../../../../../partials/display/LinkedContacts";
import ContactInfo from "../../../../../partials/display/ContactInfo";
import PersonalDetails from "../../../../../partials/display/PersonalDetails";
import FamilyDetails from "../../../../../partials/display/FamilyDetails";
import Passion from "../../../../../partials/display/Passions";
import Profession from "../../../../../partials/display/Profession";
import ContactConnections from "../../../../../partials/display/contactConnection/ContactConnections";
import Tags from "../../../../../partials/display/Tags";

const AccordionContactView = ({ profile }) => {
  return (
    <Fragment>
      <div className="row">
        <div className="col-md-4">
          <BasicInfoAva edit={false} profile={profile} />
          <ContactConnections profile={profile} />
          <LinkedContacts profile={profile} />
        </div>
        <div className="col-md-5">
          <ContactInfo profile={profile} />
          <PersonalDetails profile={profile} />
          <FamilyDetails profile={profile} />
        </div>
        <div className="col-md-3">
          <Tags profile={profile} />

          <Profession profile={profile} />
        </div>
      </div>
    </Fragment>
  );
};

export default AccordionContactView;
