import React, {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from "react";
import _forEach from "lodash/forEach";
import _includes from "lodash/includes";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import _uniqueId from "lodash/uniqueId";
import moment from "moment";
// import _cloneDeep from 'lodash/cloneDeep

import {DigitalTemplateContext} from "../../pages/digitalTemplate/DigitalTemplateContextProvider";
import {
  FEATURES_ELEMENT,
  OPEN_FOR_INSPECTION,
  CURRENT_DATE,
  AUCTIONS,
  RENTAL_OPEN_FOR_INSPECTION,
} from "./AccordianConstants";
// import ThemeButton from '../../../../partials/button/ThemeButton';

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

const toTitleCase = (name) =>
  name.replace(
    /(^\w|\s\w)(\S*)/g,
    (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase()
  );

const onDragOver = (event) => {
  const current_dragging_template_layout_to_customise = localStorage.getItem(
    "current_dragging_template_layout_to_customise"
  );
  if (current_dragging_template_layout_to_customise !== "accordian-block") {
    return true;
  }
  event.preventDefault();
};

function DigitalTemplateContent(
  {
    templateLayoutContent,
    selectedTemplateLayout,
    isEdit,
    setSeletctedDate = () => {},
  },
  ref
) {
  const initialTemplateDom = useRef(null);
  const {
    setShowEditSection,
    setSelectedNode,
    setActiveTab,
    setLibraryDataSource,
  } = useContext(DigitalTemplateContext);

  const handleClick = (e, node) => {
    setSelectedNode(node);
    setShowEditSection(true);
    setActiveTab("customise");
    e.stopPropagation();
  };

  const handleOpenHomeBlocks = (node, template = {}) => {
    const {title, starts, listings, rentals} = template;
    if (title) {
      node.getElementsByClassName("main-heading-node")[0].innerHTML =
        template.title;
    }
    if (starts) {
      node.getElementsByClassName("sub-heading-node")[0].innerHTML = moment(
        template.starts
      )
        .format("dddd ")
        .toUpperCase();
      node.getElementsByClassName(
        "sub-heading-node-date"
      )[0].innerHTML = moment(template.starts).format(" Do MMMM YYYY");
    }
    if (!_isEmpty(listings) || !_isEmpty(rentals)) {
      let appointments = !_isEmpty(listings) ? listings : rentals;
      if (!_isEmpty(appointments)) {
        let appointmentNodes = node.getElementsByClassName("open-home-node");
        let itemsToRemove = [];
        appointmentNodes.forEach((item, index) => {
          if (appointments[index]) {
            let address = _get(
              appointments[index],
              "property.address.full_address",
              ""
            );
            if (address) {
              item.getElementsByClassName("address")[0].innerHTML = address;
            }
          } else {
            itemsToRemove.push(item); // to avoid reference error
          }
        });
        itemsToRemove.forEach((item) => item.remove());
      }
    }
  };

  const handleAgentProfileBlocks = (node, template = {}) => {
    let fullName = template?.contact?.first_name + template?.contact?.last_name;
    let position = template?.position;
    let office = template?.organisation?.name;
    let mobile = template?.contact?.phones?.[0]?.value;
    let mobileNode = node?.getElementsByClassName("mobile")?.[0];
    if (fullName) {
      node.getElementsByClassName("agent-profile-name")[0].innerHTML = fullName;
    }
    if (position) {
      node.getElementsByClassName("position")[0].innerHTML = position;
    }
    if (office) {
      node.getElementsByClassName("identifier")[0].innerHTML = office;
    }
    if (mobileNode && mobile) {
      const mobileRegex = /^(\d{4})(\d{3})(\d{3})?(\d+)$/;
      mobile = mobile.replace(mobileRegex, "$1 $2 $3 $4");
      node.getElementsByClassName("mobile")[0].innerHTML = mobile;
    }
    let desciptionNode = node.getElementsByClassName("agent-profile-desc")?.[0];
    if (desciptionNode) {
    }
  };

  const updateNodes = (nodeContenItems, template) => {
    _forEach(nodeContenItems, (node) => {
      if (template) {
        if (_includes(node.className, "image")) {
          let lookUp =
            _get(template, "assets", []).find(
              (item) => _get(item, "type.id") === 1
            ) || {}; // (1 - Image, 2 - Video)
          if (_includes(node.className, "agent-node")) {
            lookUp["url"] = template?.contact?.profile_picture;
          }
          if (lookUp?.url) {
            node.style.background = `url('${lookUp.url}')`;
            // node.style.background = `https://wiseberry.com.au/load-listing-rental-image/${btoa(
            //   lookUp.url
            // )}`;
            node.style.backgroundSize = "cover";
            node.style.backgroundRepeat = "no-repeat";
            node.style.backgroundPosition = "center";
          } else {
            node.style.background = "#c8dfe9";
          }
        } else if (_includes(node.className, "address")) {
          let address = _get(template, "property.address", null);
          if (address) {
            const {
              street_number,
              street: {
                name,
                suburb: {name: suburbName},
              },
            } = address;
            if (_includes(node.className, "split")) {
              node.getElementsByClassName(
                "address-line-1"
              )[0].innerHTML = `${street_number} ${toTitleCase(name)}`;
              node.getElementsByClassName(
                "address-line-2"
              )[0].innerHTML = `${capitalizeFirstLetter(suburbName)}`;
            } else {
              node.innerHTML = `${street_number} ${toTitleCase(
                name
              )}, ${toTitleCase(suburbName)} `;
            }
          } else {
            node.remove();
          }
        } else if (_includes(node.className, "features")) {
          let templateLookUp = _get(template, "property.features", []);

          FEATURES_ELEMENT.forEach(({_className, _id}) => {
            let ele = node.getElementsByClassName(_className)?.[0];
            if (ele) {
              // can be removed once the back up issue fixed
              let lookUp = templateLookUp.find((_item) => _item.id === _id);
              if (lookUp?.qty) {
                ele.innerHTML = lookUp.qty;
              } else {
                ele.parentNode.remove();
              }
            }
          });
        } else if (_includes(node.className, "open-home-block")) {
          handleOpenHomeBlocks(node, template);
        } else if (_includes(node.className, "testimonial-block")) {
          let content = template?.value;
          if (content) {
            node.getElementsByClassName("content")[0].innerHTML = content;
          }
          let author = template?.created_by; // Need to change to full name
          if (author) {
            node.getElementsByClassName("author")[0].innerHTML = author;
          }
        } else if (_includes(node.className, "agent-profile-block")) {
          handleAgentProfileBlocks(node, template);
        } else if (
          _includes(node.className, "leased-node") ||
          _includes(node.className, "sold-node")
        ) {
          let address = _get(template, "property.address", null);
          const {
            street_number,
            street: {
              name,
              suburb: {name: suburbName},
            },
          } = address;
          if (address) {
            node.getElementsByClassName(
              "address"
            )[0].innerHTML = `${street_number ?? ""} ${toTitleCase(name) ??
              ""}`;
            node.getElementsByClassName("suburb")[0].innerHTML = `${toTitleCase(
              suburbName
            ) ?? ""}`;
          } else {
            node.remove();
          }
          if (_includes(node.className, "sold-node")) {
            // debugger
            node.getElementsByClassName(
              "price"
            )[0].innerHTML = `$${template?.sale.contract_price.toLocaleString() ??
              0}`;
            /* this is for handling the price field in sold template */
          }
        }
      }
    });
    localStorage.removeItem("current_dragging_template_layout_to_customise");
  };

  const handleOpenHomeNodesDrop = (
    e,
    section,
    isOpenHome,
    rentals,
    className
  ) => {
    // debugger
    let template = e.dataTransfer.getData("drag-element");
    if (template) {
      // let _templateLayout = document.getElementsByClassName('poster-template')?.[0] // for future - if back up issue comes
      template = JSON.parse(template);
      let assets = _get(
        template,
        isOpenHome ? "listings[0].assets" : "assets",
        ""
      );
      if (assets) {
        setLibraryDataSource(
          assets.filter((item) => _get(item, "type.id") === 1)
        ); // 1 for Image
      }
      let start_time = _get(template, "starts", "");
      let end_time = _get(template, "ends", "");
      let auction_date = _get(template, "auction_date", "");
      let address;
      if (className == "auction-node") {
        address = _get(
          template,
          isOpenHome ? "listings[0].property.address" : "property.address",
          {}
        );
      } else {
        address = _get(
          template,
          isOpenHome
            ? "listings[0].property.address"
            : rentals
            ? "rentals[0].property.address"
            : " property.address",
          {}
        );
        // a ? b : b ? c : d
      }

      // let address = _get(template, isOpenHome ? 'listings[0].property.address' : 'property.address'?rentals[0].property.address, {})
      let templateLookUp = _get(
        template,
        `${
          rentals
            ? "rentals[0].property.features"
            : "listings[0].property.features"
        }`,
        []
      );

      FEATURES_ELEMENT.forEach(({_className, _id}) => {
        let ele = section.getElementsByClassName(_className)?.[0];
        if (ele) {
          let lookUp = templateLookUp.find((_item) => _item.id === _id);
          if (lookUp?.qty) {
            ele.innerHTML = lookUp.qty;
          } else {
            ele.parentNode.remove();
          }
        }
      });
      if (_includes(section.className, "inspection")) {
        /* its for adding any necessary text related to open for inspection first-set template  */
      } else if (_includes(section.className, "private-open-home")) {
        if (start_time) {
          section.getElementsByClassName("start-time")[0].innerHTML = moment(
            start_time
          ).format("h:mm A");
        }
        if (end_time) {
          section.getElementsByClassName("end-time")[0].innerHTML = moment(
            end_time
          ).format("h:mm A");
        }
      } else {
        if (start_time) {
          section.getElementsByClassName("time")[0].innerHTML = moment(
            start_time
          ).format("h:mm A");
        }
        if (auction_date) {
          section.getElementsByClassName("time")[0].innerHTML = moment(
            auction_date
          ).format("h:mm A");
        }
      }
      if (!_isEmpty(address)) {
        try {
          const {
            street_unit,
            street_number,
            street: {
              name,
              suburb: {
                name: suburbName,
                state: {abbrev},
              },
            },
          } = address;
          let streetUnit = street_unit ? `${street_unit}/` : "";
          let fullAddress = `${streetUnit}${street_number} ${capitalizeFirstLetter(
            name
          )}, ${capitalizeFirstLetter(suburbName)}`;
          section.getElementsByClassName("address")[0].innerHTML = fullAddress;
        } catch (error) {
          console.log("error", error);
        }
      }

      let price = _get(template, "price_from", null);
      let priceNode = section.getElementsByClassName("price-node")?.[0];
      let seperator = section.getElementsByClassName(
        "auction-seperator-line"
      )?.[0];
      if (price !== null && priceNode && price != 0) {
        priceNode.innerHTML = `$${price}`;
      } else if (priceNode && (price == 0 || price == null)) {
        priceNode.style.display = "none";
        seperator.style.display = "none";
      }
    }
  };

  const handleDrop = (e, templateLayout) => {
    // let nodeContenItems = templateLayout.getElementsByClassName('node-content-item')
    let template = e.dataTransfer.getData("drag-element");
    let tempContent = templateLayout;
    if (initialTemplateDom.current) {
      tempContent = initialTemplateDom.current.cloneNode(true);
      templateLayout.parentNode.replaceChild(tempContent, templateLayout); //-- Pbm here
      addDragAndDropEvents();
    }
    if (template) {
      // let _templateLayout = document.getElementsByClassName('poster-template')?.[0] // for future - if back up issue comes
      template = JSON.parse(template);
      if (template.assets) {
        setLibraryDataSource(
          template.assets.filter((item) => _get(item, "type.id") === 1)
        ); // 1 for Image
      }
      let nodeContenItems = tempContent.getElementsByClassName(
        "node-content-item"
      );

      updateNodes(nodeContenItems, template);
    }
  };

  const handleSpecificEventsForNodes = (
    templateLayout,
    className = "open-home-node",
    rentals
  ) => {
    let contentBlock = templateLayout.getElementsByClassName(
      "main-content-block"
    )?.[0];
    let nodeContenItems = contentBlock?.getElementsByClassName(className);
    let styleModifier = templateLayout.getElementsByClassName("layout3");
    let styleModifier_2 = templateLayout.getElementsByClassName("layout4");
    let subHeadingNode = templateLayout.getElementsByClassName(
      "sub-heading-node"
    )?.[0];
    let subHeadingNodeDay = templateLayout.getElementsByClassName(
      "sub-heading-node-day"
    )?.[0];
    let subHeadingNodeMonth = templateLayout.getElementsByClassName(
      "sub-heading-node-month"
    )?.[0];
    if (subHeadingNode) {
      if (!isEdit) {
        subHeadingNodeDay.innerHTML = moment()
          .format("dddd")
          .toUpperCase();
        subHeadingNodeMonth.innerHTML = moment().format("Do MMMM YYYY");
      }
      let date = moment(subHeadingNode?.innerHTML, "dddd MMMM Do YYYY");
      if (date.isValid()) {
        setSeletctedDate(date);
      } else {
        setSeletctedDate(CURRENT_DATE);
      }
    }

    if (nodeContenItems?.length) {
      nodeContenItems.forEach((item) => {
        item.ondragover = () => {
          item.style.backgroundColor = "#b7e9d1 ";
        };
        item.ondrop = (event) => {
          item.style.backgroundColor = "transparent";
          handleOpenHomeNodesDrop(
            event,
            item,
            className === `${rentals ? "rentals" : "open-home-node"}`,
            rentals,
            className
          );
        };
        item.ondragleave = () => {
          item.style.backgroundColor = "transparent";
        };
        item.onmouseenter = () => {
          let copyIcon = document.createElement("i");
          copyIcon.className = " new-copy delete-icon fas fa-copy";
          let deleteIcon = document.createElement("i");
          deleteIcon.className = "delete-icon fas fa-trash";
          deleteIcon.onclick = (e) => {
            item.remove();
            setShowEditSection(false);
            e.stopPropagation();
          };

          if (styleModifier.length) {
            copyIcon.style.position = "absolute";
            copyIcon.style.top = "10%";
            copyIcon.style.right = "15%";
            copyIcon.style.fontSize = "13px";
            deleteIcon.style.position = "absolute";
            deleteIcon.style.top = "10%";
            deleteIcon.style.right = "4%";
            deleteIcon.style.fontSize = "13px";
          } else if (styleModifier_2.length) {
            copyIcon.style.position = "absolute";
            copyIcon.style.top = "30%";
            copyIcon.style.right = "15%";
            copyIcon.style.fontSize = "13px";
            deleteIcon.style.position = "absolute";
            deleteIcon.style.top = "30%";
            deleteIcon.style.right = "4%";
            deleteIcon.style.fontSize = "13px";
          } else {
            copyIcon.style.position = "absolute";
            copyIcon.style.top = "10%";
            copyIcon.style.right = "10%";
            copyIcon.style.fontSize = "13px";
            deleteIcon.style.position = "absolute";
            deleteIcon.style.top = "10%";
            deleteIcon.style.right = "4%";
            deleteIcon.style.fontSize = "13px";
          }

          item.appendChild(deleteIcon);
          item.appendChild(copyIcon);

          copyIcon.onclick = (e) => {
            let clone = item.cloneNode(true);
            item.parentNode.insertBefore(clone, item);
            handleApplyEventHandlers(clone);
            handleSpecificEventsForNodes(templateLayout, "open-home-node");
            addDragAndDropEvents();
            setShowEditSection(false);
            e.stopPropagation();
          };
        };

        item.onmouseleave = () => {
          let deleteIcon = item.getElementsByClassName("delete-icon")?.[0];
          let copyIcon = item.getElementsByClassName("new-copy ")?.[0];
          if (deleteIcon) {
            deleteIcon.remove();
            copyIcon.remove();
          }
        };
      });
    }
  };

  const addDragAndDropEvents = useCallback(() => {
    let templateLayout = document.getElementById("poster-template");
    if (templateLayout) {
      let nodes = templateLayout.getElementsByClassName("node-content-item");

      _forEach(nodes, (node) => {
        node.onclick = (e) => handleClick(e, node);
        node.ondragover = (event) => onDragOver(event);
      });

      // if (templateLayout?.className?.includes('open-home')) {
      if (
        selectedTemplateLayout?.id === OPEN_FOR_INSPECTION ||
        selectedTemplateLayout?.id === RENTAL_OPEN_FOR_INSPECTION
      ) {
        let rentals = selectedTemplateLayout?.id === RENTAL_OPEN_FOR_INSPECTION;
        handleSpecificEventsForNodes(templateLayout, "open-home-node", rentals);
      } else if (selectedTemplateLayout?.id === AUCTIONS) {
        handleSpecificEventsForNodes(templateLayout, "auction-node");
      } else {
        templateLayout.ondrop = (event) => handleDrop(event, templateLayout);
      }
    }
  }, [selectedTemplateLayout]);

  const handleApplyEventHandlers = (columnWrap) => {
    // cloneNode only copy the dom elements so we have to integrate javascript function with that.
    // This is used to integrate required event handlers with this.
    let deleteIcon = columnWrap.getElementsByClassName("delete-icon")?.[0];
    let copyIcon = columnWrap.getElementsByClassName("new-copy ")?.[0];

    deleteIcon.remove();
    copyIcon.remove();
  };

  useEffect(() => {
    let templateLayout = document.getElementById("poster-template");
    if (templateLayout) {
      initialTemplateDom.current = templateLayout.cloneNode(true);
    }
    addDragAndDropEvents();
    if (
      templateLayout != null &&
      templateLayout.querySelector(".bg-image-node") != null &&
      templateLayout
        .querySelector(".bg-image-node")
        .querySelector("div:first-child") != null
    ) {
      let elem = templateLayout
        .querySelector(".bg-image-node")
        .querySelectorAll("div");
      elem.forEach((item) => {
        item.addEventListener("mouseover", () => {
          templateLayout
            .querySelector(".bg-image-node")
            .classList.add("removeBorder");
        });
        item.addEventListener("mouseout", () => {
          templateLayout
            .querySelector(".bg-image-node")
            .classList.remove("removeBorder");
        });
      });
    }
  }, [templateLayoutContent, addDragAndDropEvents]);

  return (
    <div
      className="template-body2 poster-template"
      id="social_media_fix_OverFlow"
    >
      <div
        id="ref-element"
        ref={ref}
        dangerouslySetInnerHTML={{__html: templateLayoutContent}}
      />
    </div>
  );
}

export default forwardRef(DigitalTemplateContent);
