import React, { useState, createContext, useEffect } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import 'sweetalert2/src/sweetalert2.scss';
import _isEmpty from "lodash/isEmpty";

import {
  createNewAppointment,
  // createAddress,
  // createPropertyByAddress,
  getMicrositesBydAppoinmentID,
  postUpdateDiaryAppointmentById,
  sendMicrosteToProspectEmail
} from "../../../_curd/diaryCurd";
import {
  updateBuyer,
  createNewBuyer,
  getAllBuyers
  // getBuyerById,
} from "../../../../buyers/crud/buyersCrud";
import {
  getObjWithoutValues,
  convertFormData,
  postProcessAddress,
  formToQueryString,
  postProcessedContactInfo,
  processAddress,
  postProcessDomainAddress,
  convertDateStringToDate
} from "../../../../../_helpers/_utils/utils";
import {
  createNewContact,
  updateContact,
  getContactById
} from "../../../../contacts/crud/contactsCrud";
import {
  findProspectByAddress,
  createProspect,
  updateProspect
} from "../../../../prospect/curd/prospectsCrud";
import {
  updateTenant,
  getAllTenants,
  createNewTenant
} from "../../../../tenants/crud/tenantsCrud";
import EmailReportPreview from "../../../../listings/partials/singleListingList/salesAdvice/EmailReportPreview";
import { letterTest } from "../../../../listings/_crud/listingsCrud";
import DefaultModal from "../../../../../partials/modal/DefaultModal";

export const AppointmentContext = createContext();
export const { Consumer } = AppointmentContext;

const AppointmentContextProvider = props => {
  const { setFillFormData, fillFormData, setOpenHomeApp } = props;
  const [selectedListing, setSelectedlistings] = useState([]);
  const [findAddressData, setFindAddressData] = useState([]);
  const [showCMAModal, setShowCMAModal] = useState(false);
  const [selectedListingAddress, setSelectedListingAddress] = useState(null);

  const [emailContent, setEmailContent] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  let buyer_list2 = [];
  let tenant_list2 = [];
  const organisation = useSelector(state => state.auth?.user?.organisation?.id);

  const getMeetingLocation = (
    anotherDomainAddr = {},
    anotherAddr = [],
    domainAddr = {},
    addr = []
  ) => {
    if (!_isEmpty(anotherDomainAddr)) {
      const { full_address, label, value, ..._domain_address } =
        anotherDomainAddr || {};
      return { domain_address: _domain_address };
    } else if (anotherAddr?.[0]?.street_name?.value) {
      return postProcessAddress(anotherAddr[0]);
    } else if (!_isEmpty(domainAddr)) {
      const { full_address, label, value, ..._domain_address } =
        domainAddr || {};
      return { domain_address: _domain_address };
    } else {
      return postProcessAddress(addr[0]);
    }
  };

  const newAppointment = async values => {
    let generalData = {};
    values.type = values.app_type;
    // values.starts = moment(values.starts).format("YYYY-MM-DD HH:MM:SS");
    // values.ends = moment(values.ends).format("YYYY-MM-DD HH:MM");
    const {
      contacts,
      listings,
      meeting_location,
      domain_address,
      team_member,
      other_location,
      prospect_address,
      rentals,
      ...others
    } = values;
    if (values.type == 1) {
      let address_1 = processAddress(other_location?.[0]);
      generalData = {
        ...others,
        team_member: team_member,
        meeting_location: !_isEmpty(domain_address)
          ? { domain_address }
          : address_1
      };

      return await createAppointment(generalData);
    } else if (values.type == 4 || values.type == 11) {
      const { full_address, label, value, ..._domain_address } =
        prospect_address?.domain_address || {};
      let meetingAddress = !_isEmpty(_domain_address)
        ? postProcessDomainAddress(_domain_address)
        : postProcessAddress(prospect_address?.meeting_location?.[0]);
      const { street, suburb, state } = meetingAddress;
      if (_isEmpty(_domain_address) && (!street || !suburb || !state)) {
        toast.error("Please fill address field");
      } else {
        let processedContacts = await processContacts(values, contacts);
        let prospect_id = await processProspect(
          meetingAddress,
          processedContacts
        );
        let meetingLocation = getMeetingLocation(
          domain_address,
          other_location,
          prospect_address?.domain_address,
          prospect_address?.meeting_location
        );
        generalData = {
          ...others,
          ...processedContacts,
          prospect: prospect_id,
          meeting_location: meetingLocation
        };
        return await createAppointment(generalData);
      }
    } else if (values.type == 3) {
      let address_1 = processAddress(meeting_location?.[0]);
      generalData = {
        ...others,
        meeting_location: !_isEmpty(domain_address)
          ? { domain_address }
          : address_1
      };

      return await createAppointment(generalData);
    } else if (values.type == 2 || values.type == 12) {
      // let processedContacts = await processContacts(values, selectedContact.length> 0 ? selectedContact : contacts);

      for (let i = 0; i < contacts?.length; i++) {
        const contact = contacts[i];
        if (!contact?.id && !contact?.contact_id) {
          const queryString = formToQueryString({
            name: String(
              `${contact.first_name ?? ""} ${contact.last_name ?? ""}`
            ).trim()
            // limit: 100,
          });

          await getAllBuyers(queryString)
            .then(async ({ data: { data } }) => {
              if (data?.length > 1) {
                const inputOptions = data.reduce(
                  (obj, item) =>
                    Object.assign(obj, {
                      [item.id]: `${item.primary_contact.first_name ??
                        ""} ${item.primary_contact.last_name ??
                        ""} created at ${
                        item.created_at
                          ? convertDateStringToDate(item.created_at, "AU")
                          : "-"
                      }`
                    }),
                  {}
                );

                const { value: buyer } = await Swal.fire({
                  title: "Similar Buyer(s) Found",
                  input: "radio",
                  customClass: "swal-alert-wide-similar-contacts",
                  inputOptions: inputOptions,
                  // A little trick here to enable scroll bar on swal
                  // not sure if there is a better way to do it, but this is how i did (RW)
                  html: `<div style='position: absolute; height: ${
                    data?.length > 30 ? 120 : 100
                  }%; overflow-y: auto;'>&nbsp;</div>`,
                  inputValidator: value => {
                    if (!value) {
                      return "You need to Select a Buyer!";
                    }
                  }
                });

                if (buyer) {
                  const primaryContact = data.find(obj => obj.id == buyer)
                    ?.primary_contact;
                  Swal.fire({
                    html: `You selected: ${primaryContact?.first_name} ${primaryContact?.last_name}`
                  });
                  contacts[i]["id"] = primaryContact.id;
                  contacts[i]["first_name"] = primaryContact?.first_name;
                  contacts[i]["last_name"] = primaryContact?.last_name;
                }
              }
            })
            .catch(e => {
              console.log(e);
            });
        }
      }

      let processedContacts = await processContacts(values, contacts);
      let buyer_id = await processBuyerInformation(processedContacts, values);

      let sele;
      let buyerValue = {};
      if (buyer_list2.length > 0) {
        sele = buyer_list2.filter(list => list.id == buyer_id);
        // setSelectedContact(sele)
        buyerValue = {
          ...others,
          ...processedContacts,
          buyers: [{ buyer: parseInt(buyer_id), is_qualified: 0 }],
          listings: listings,
          contacts: [sele && sele[0]?.contacts[0].id]
        };
      } else {
        buyerValue = {
          ...others,
          ...processedContacts,
          buyers: [{ buyer: parseInt(buyer_id), is_qualified: 0 }],
          listings: listings
        };
      }

      return await createAppointment(buyerValue);
    } else if (values.type == 7 || values.type == 13) {
      const { full_address, label, value, ..._domain_address } =
        prospect_address?.domain_address || {};
      let meetingAddress = !_isEmpty(_domain_address)
        ? _domain_address
        : postProcessAddress(prospect_address?.meeting_location?.[0]);
      const { street, suburb, state } = meetingAddress;

      if (_isEmpty(_domain_address) && (!street || !suburb || !state)) {
        toast.error("Please fill address field");
      } else {
        let obj = {};
        if (!_isEmpty(meetingAddress?.addressComponents)) {
          obj = postProcessDomainAddress(meetingAddress);
        } else {
          obj = {
            ...meetingAddress
          };
        }
        let processedContacts = await processContacts(values, contacts);
        let prospect_id = await processProspect(obj, processedContacts);
        let meetingLocation = getMeetingLocation(
          domain_address,
          other_location,
          prospect_address?.domain_address,
          prospect_address?.meeting_location
        );
        generalData = {
          ...others,
          ...processedContacts,
          prospect: prospect_id,
          meeting_location: meetingLocation
        };
        return await createAppointment(generalData);
      }
    } else if (values.type == 10 || values.type == 14) {
      let processedContacts = await processContacts(values, contacts);
      let tenant_id = await processTenantInformation(processedContacts, values);

      let selectedTenant;
      let buyerValue = {};
      if (tenant_list2.length > 0) {
        selectedTenant = tenant_list2.filter(
          list => list.id === parseInt(tenant_id)
        );
        buyerValue = {
          ...others,
          ...processedContacts,
          tenants: [parseInt(tenant_id)],
          listings: listings,
          rentals: rentals,
          contacts: [selectedTenant[0]?.contacts[0]?.id]
        };
      } else {
        buyerValue = {
          ...others,
          ...processedContacts,
          tenants: [parseInt(tenant_id)],
          listings: listings,
          rentals: rentals
        };
      }

      return await createAppointment(buyerValue);
    } else if (values.type == 5) {
      let buyerValue = {
        ...others,
        listings: listings ? [listings] : null
      };
      return await createAppointment(buyerValue);
    } else if ([8, 15, 23, 24].includes(values.type)) {
      let buyerValue = {
        ...others,
        rentals: rentals ? [rentals] : null
      };
      return await createAppointment(buyerValue);
    } else if (values.type == 6) {
      //let address_1 = postProcessAddress(other_location[0]);
      let address_1 = selectedListingAddress.id;
      let buyerValue = {
        ...others,
        listings: listings ? [listings] : null,
        fulladdress: selectedListingAddress["full_address"],
        meeting_location: !_isEmpty(domain_address)
          ? { domain_address }
          : address_1
      };
      return await createAppointment(buyerValue);
    } else {
      let address_1 = postProcessAddress(other_location[0]);
      let buyerValue = {
        ...others,
        listings: listings ? [listings] : null,
        meeting_location: !_isEmpty(domain_address)
          ? { domain_address }
          : address_1
      };
      return await createAppointment(buyerValue);
    }
    setOpenHomeApp && setOpenHomeApp();
  };

  const processProspect = async (address, contacts = {}) => {
    let prospect_id;
    prospect_id = await handleFindProspectByAddress(address);
    // prospect_id = await handleFindProspectByAddress(address);
    if (prospect_id) {
      handleUpdateProspect(prospect_id, {
        prospect_contacts: contacts?.contacts
      });
      return prospect_id;
    } else {
      prospect_id = await handleCreateProspect(
        address?.addressComponents ? { domain_address: address } : address,
        contacts?.contacts ?? []
      );
    }

    return prospect_id;
  };

  const sendPreListingEmailToProspect = async appointmentId => {
    Swal.fire({
      title: "Confirmation",
      text: "Would you like to send the Pre-Listing Microsite?",
      icon: "info",
      showCancelButton: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
      confirmButtonColor: "#A7A9AC",
      cancelButtonColor: "#2BB673",
      confirmButtonText: "No",
      cancelButtonText: "Yes"
    }).then(result => {
      if (result.isDismissed) {
        sendMicrosteToProspectEmail(appointmentId)
          .then(({ data: { data } }) => {
            toast.success("The Pre-Listing Microsite has been sent.");
          })
          .catch(error => {
            if (
              error &&
              error.data?.message === "no_pre_listing_template_for_agent"
            ) {
              Swal.fire({
                title: "Pre-Listing Microsite",
                text:
                  'A Pre-Listing Microsite does not exist for this agent. To create, please visit the "Marketing" Module > "Microsites" and create a new Pre-Listing Microsite.',
                icon: "info",
                showCancelButton: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
                confirmButtonColor: "#2BB673",
                cancelButtonColor: "#A7A9AC",
                confirmButtonText: "Ok",
                cancelButtonText: "No"
              });
            } else {
              toast.error("Error on sending Pre-Listing");
            }
          });
      }
    });
  };
  const processContacts = async (values, contacts) => {
    let contacts_id_list = [];
    let _linked_contact;
    let contact_information = {
      ...contacts[0]
    };

    if (contact_information?.id) {
      contact_information.contact_id = contact_information?.id;
    }
    if (contact_information.contact_id == undefined) {
      _linked_contact = contacts;
    } else {
      _linked_contact = contacts;
      // _linked_contact = contacts.slice(1);
    }

    if (_linked_contact?.length) {
      _linked_contact.map(async obj => {
        if (obj.contact_id || obj.id) {
          contacts_id_list.push(obj.contact_id || obj.id);
          await updateContactInfo(obj.contact_id || obj.id, obj);
        } else {
          if(values?.type === 2){
            obj.contact_created_from = "buyer_appointment";
          }
          let new_contact_id = await createContact(obj);
          contacts_id_list.push(new_contact_id);
          obj.id = new_contact_id;
        }
      });
    }

    if (contact_information.contact_id !== undefined) {
      // let linked_contacts;
      const { linked_contacts } = await handleGetContactInfo(
        contact_information.contact_id
      );
      // linked_contacts = contactDetailsInfo.linked_contacts.length ? contactDetailsInfo.linked_contacts : []
      let linked_contact_data = [
        ...new Set(
          linked_contacts
            .map(({ relationship, ...others }) => ({
              ...others,
              relationship: relationship?.id
            }))
            .concat(
              _linked_contact.map(obj => ({
                id: obj.contact_id,
                relationship: obj.relationship
              }))
            )
        )
      ];
      let mappedData = linked_contact_data.map(obj => ({
        id: obj.id,
        relationship: obj.relationship ? obj.relationship : ""
      }));

      mappedData = mappedData?.filter(
        data => data.id !== contact_information.contact_id
      );
      if (mappedData.length > 0) {
        await updateContactInfo(contact_information.contact_id, {
          ...contact_information,
          linked_contacts: mappedData
        });
        contacts_id_list.push(contact_information.contact_id);
      }
    } else {
      _linked_contact = _linked_contact?.filter(
        data => data.id !== contact_information.contact_id
      );
      let new_contact_id =
        (contact_information?.first_name || contact_information?.last_name) &&
        (await createContact({
          ...contact_information,
          linked_contacts: _linked_contact
        }));
      // if (new_contact_id) {
      //   contacts_id_list.push(new_contact_id);
      // }
      contact_information.contact_id = new_contact_id;
    }
    // await updateBuyerInformation(_buyer.buyer_id, values);

    return {
      contacts: contacts_id_list,
      main_contact: contact_information.contact_id,
      contact_linked_contact: _linked_contact
    };
  };

  const processBuyerInformation = async (
    { contacts, main_contact, contact_linked_contact },
    values
  ) => {
    let buyer_id;
    let buyer_list = [];

    let obj = {
      contact: main_contact
    };

    let queryString = formToQueryString(obj);

    await getAllBuyers(queryString)
      .then(({ data: { data } }) => {
        buyer_list = data;
        buyer_list2 = data;
      })
      .catch(e => {});

    if (buyer_list?.length > 0) {
      buyer_id = buyer_list?.[0]?.id;
      var inputOptions = buyer_list.reduce(
        (obj, item) =>
          Object.assign(obj, {
            [item.id]:
              item.primary_contact.first_name +
              " " +
              item.primary_contact.last_name
          }),
        {}
      );
      // if (buyer_list?.length > 1) {
      //   const { value: buyer } = await Swal.fire({
      //     title: "Select Buyer",
      //     input: "radio",
      //     inputOptions: inputOptions,
      //     inputValidator: (value) => {
      //       if (!value) {
      //         return "You need to Select a Buyer!";
      //       }
      //     },
      //   });

      //   if (buyer) {
      //     Swal.fire({
      //       html: `You selected: ${buyer_list.find((obj) => obj.id == buyer)
      //         ?.primary_contact?.first_name +
      //         " " +
      //         buyer_list.find((obj) => obj.id == buyer)?.primary_contact
      //           ?.last_name}`,
      //     });
      //     buyer_id = buyer;
      //   }
      // }

      let buyer_existing_linked_contact = buyer_list.find(
        obj => obj.id == buyer_id
      )?.contacts?.length
        ? buyer_list
            ?.find(obj => obj.id == buyer_id)
            ?.contacts?.map(obj => obj.id)
        : [];
      let buyerData = {
        buyer_linked_contacts: [
          ...new Set(contacts.concat(buyer_existing_linked_contact))
        ],
        price_from: values?.price_from,
        price_to: values?.price_to
      };

      updateBuyerInformation(buyer_id, buyerData);
    } else {
      let buyer_contact = [];
      contacts.map(obj => buyer_contact.push({ id: obj }));
      let buyerData = {
        price_from: values?.price_from,
        price_to: values?.price_to,
        buyer_contact: {
          id: contacts[0]
        },
        buyer_linked_contacts: contacts
      };

      buyer_id = await createBuyer(main_contact, buyerData);
    }

    return buyer_id;
  };

  const processTenantInformation = async (
    { contacts, main_contact, contact_linked_contact },
    values
  ) => {
    let tenant_id;
    let tenant_list = [];

    let obj = {
      contact: main_contact
    };

    let queryString = formToQueryString(obj);

    await getAllTenants(queryString)
      .then(({ data: { data } }) => {
        tenant_list = data;
        tenant_list2 = data;
      })
      .catch(e => {});

    if (tenant_list?.length > 0) {
      tenant_id = tenant_list?.[0]?.id;
      var inputOptions = tenant_list.reduce(
        (obj, item) =>
          Object.assign(obj, { [item.id]: item.primary_contact.first_name }),
        {}
      );
      if (tenant_list?.length > 1) {
        const { value: buyer } = await Swal.fire({
          title: "Select Tenant",
          input: "radio",
          inputOptions: inputOptions,
          inputValidator: value => {
            if (!value) {
              return "You need to Select a Tenant!";
            }
          }
        });

        if (buyer) {
          Swal.fire({
            html: `You selected: ${
              tenant_list.find(obj => obj.id == buyer)?.primary_contact
                ?.first_name
            }`
          });
          tenant_id = buyer;
        }
      }
      let buyer_existing_linked_contact = tenant_list.find(
        obj => (obj.id = tenant_id)
      )?.contacts?.length
        ? tenant_list
            .find(obj => (obj.id = tenant_id))
            ?.contacts?.map(obj => obj.id)
        : [];
      let buyerData = {
        tenant_linked_contacts: [
          ...new Set(contacts.concat(buyer_existing_linked_contact))
        ],
        price_from: values.price_from,
        price_to: values.price_to
      };

      updateTenantInformation(tenant_id, buyerData);
    } else {
      let buyerData = {
        price_from: values.price_from,
        price_to: values.price_to,
        tenant_contact: { id: main_contact },
        tenant_linked_contacts: contacts
      };
      const BuyerFormData = convertFormData(
        getObjWithoutValues([undefined, null], buyerData)
      );

      tenant_id = await createTenant(main_contact, BuyerFormData);
    }

    return tenant_id;
  };

  const updateContactInfo = async (contact_id, values) => {
    const formData = convertFormData(
      getObjWithoutValues([undefined, null], postProcessedContactInfo(values))
    );
    formData.append("_method", "PATCH");
    await updateContact(contact_id, formData)
      .then(({ data }) => {})
      .catch(e => {});
  };

  const createBuyer = async (contact_id, values) => {
    let buyer_info;
    let buyerData = {
      ...values
    };
    const formData = convertFormData(
      getObjWithoutValues([undefined, null], buyerData)
    );
    await createNewBuyer(formData)
      .then(({ data: { data } }) => {
        buyer_info = data.id;
      })
      .catch(e => {});

    return buyer_info;
  };

  const createTenant = async (contact_id, values) => {
    let tenant_id;
    let buyerData = {
      tenant_contact: { id: contact_id },
      ...values
    };

    const formData = convertFormData(
      getObjWithoutValues([undefined, null], buyerData)
    );
    await createNewTenant(formData)
      .then(({ data: { data } }) => {
        tenant_id = data.id;
      })
      .catch(e => {});

    return tenant_id;
  };

  const createContact = async values => {
    let contact_id;
    const formData = convertFormData(
      getObjWithoutValues([undefined, null], postProcessedContactInfo(values))
    );
    await createNewContact(formData)
      .then(({ data: { data } }) => {
        contact_id = data.id;
      })
      .catch(e => {});

    return contact_id;
  };

  const updateBuyerInformation = async (buyer_id, values) => {
    const BuyerFormData = convertFormData(
      getObjWithoutValues([undefined, null], values)
    );
    BuyerFormData.append("_method", "PATCH");
    await updateBuyer(buyer_id, BuyerFormData)
      .then(({ data }) => {})
      .catch(e => {});
  };

  const updateTenantInformation = async (tenant_id, values) => {
    const BuyerFormData = convertFormData(
      getObjWithoutValues([undefined, null], values)
    );
    BuyerFormData.append("_method", "PATCH");
    await updateTenant(tenant_id, BuyerFormData)
      .then(({ data }) => {})
      .catch(e => {});
  };

  const handleFindProspectByAddress = async values => {
    let prospectId;

    let queryString = formToQueryString(values);
    await findProspectByAddress(queryString)
      .then(({ data: { data } }) => {
        prospectId = data.id;
      })
      .catch(e => {});

    return prospectId;
  };

  const handleCreateProspect = async (address, contacts) => {
    let prospect_id;
    await createProspect({
      organisation: organisation,
      property: { organisation: organisation, address: address },
      contacts: contacts
    })
      .then(({ data: { data } }) => {
        prospect_id = data.id;
      })
      .catch(e => {});
    return prospect_id;
  };

  const handleUpdateProspect = async (id, data) => {
    let prospect_id;
    const formData = convertFormData(data);

    formData.append("_method", "PATCH");

    await updateProspect(id, formData)
      .then(({ data: { data } }) => {
        prospect_id = data.id;
      })
      .catch(e => {});

    return prospect_id;
  };
  const handleGetContactInfo = async id => {
    let contact_info;
    await getContactById(id)
      .then(({ data: { data } }) => {
        contact_info = data;
      })
      .catch(e => {});

    return contact_info;
  };

  // const handleCreateProperty = async (values) => {
  //   let property_id;
  //   await createPropertyByAddress(values)
  //     .then(({ data: { data } }) => {
  //       property_id = data.id;
  //     })
  //     .catch((e) => {});

  //   return property_id;
  // };
  function showSwalContainer() {
    const swalContainer = document.querySelector(".swal2-container");
    if (swalContainer) {
      swalContainer.style.display = "flex";
    }
  }

  function hideSwalContainer() {
    const swalContainer = document.querySelector(".swal2-container");
    if (swalContainer) {
      swalContainer.style.display = "contents";
    }
  }

  const handleCloseModal = () => {
    setIsModalOpen(false);
    showSwalContainer();
  };

  const onFormSubmitTestLetter = async (values) => {
    const formData = convertFormData(values);
    console.log(values)
    return await letterTest(formData)
      .then(({data}) => {
        // Destructure 'data' from the response
        if (data) {
          toast.success("Letter generated successfully");
          return data.data;
        }
      })
      .catch((e) => {
        console.error(e);
        toast.error(e.data.data[0]);
      });
  };


  async function handlePreviewProcess(
    previewButtonId,
    loadingSpinnerId = "none",
    payload
  ) {
    const previewButton = document.getElementById(previewButtonId);
    const loadingSpinner = document.getElementById(loadingSpinnerId);
    loadingSpinner.style.display = "inline-block"; // Show loading spinner
    previewButton.style.display = "none"; // Hide button

    const emailHtml = await onFormSubmitTestLetter(payload);

    if (emailHtml) {
      handlePreviewClick(emailHtml);
    } else {
      console.error("Failed to generate email preview.");
    }

    loadingSpinner.style.display = "none"; // Hide loading spinner
    previewButton.style.display = "contents"; // Show button
  }
  function handlePreviewClick(emailHtml) {
    if (emailHtml) {
      const bannerModal = document.getElementById("banner");
      // bannerModal.style.display = "none";
      setEmailContent(emailHtml);
      setIsModalOpen(true);
      hideSwalContainer();
    } else {
      console.error("Failed to generate email preview.");
    }
  }

  const handleOpenHome = async (values) => {
    // Insert custom styles to increase padding, font size, and adjust the button width
    const MySwal = Swal.mixin({
      customClass: {
        confirmButton: 'swal2-confirm custom-swal-button',
        cancelButton: 'swal2-cancel custom-swal-button',
      },
    });
  
    // Applying custom styles globally
    const styles = `
      .swal2-actions {
        display: flex;
        flex-wrap: nowrap;
      }
      .swal2-title {
        font-size: 2.5em; /* Updated font size for the title */
      }
      .swal2-text {
        font-size: 1.4em; /* Updated font size for the text */
      }
      .custom-swal-button {
        font-size: 1.2em;
        padding: 10px 0; 
        width: 48%; 
        border-radius: 0; 
        box-sizing: border-box;
      }
    `;
  
    const styleSheet = document.createElement("style");
    styleSheet.innerText = styles;
    document.head.appendChild(styleSheet);
  
    // Display the popup
    const result = await MySwal.fire({
      title: 'Send Email',
      html: ` <style> 
            .loader {
              border: 5px solid #f3f3f3;
              border-top: 5px solid #822433;
              border-radius: 50%;
              width: 20px;
              height: 20px;
              animation: spin 2s linear infinite;
            }

            @keyframes spin {
              0% { transform: rotate(0deg); }
              100% { transform: rotate(360deg); }
            }
          </style> 

          <div style='    display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;' id='banner'>
            <span style="font-size:1.3em;">Would you like to send all Open Home attendees an email of the Listing after their visit?</span> 
            
            <span class="eye-icon" id="preview" style="color: black; margin-top: 10px"><i class="fas fa-eye"></i></span>
            <div id="loadingSpinner" style="display: none;">
              <div class="loader"></div>
            </div>
          </div>`, 
      icon: 'question',
      didOpen: () => {
        const previewBtn = document.getElementById("preview");

        previewBtn.addEventListener("click", async (event) => {
          let payload = {
            listing: values.listing,
            open_home: true,
          };
          handlePreviewProcess("preview", "loadingSpinner", payload);
          event.stopPropagation();
          event.preventDefault();
        });
      },
      showCancelButton: true,
      cancelButtonColor: "#A7A9AC",
      cancelButtonText: "No",
      confirmButtonColor: "#2BB673",
      confirmButtonText: "Yes",
      reverseButtons: true
    });
  
    // Check the result and return the appropriate value
    return result.isConfirmed ? { send_email: true } : {};
  };
  
  const createAppointment = async values => {
    let extraParams = {};
    let newVal = { ...values };
  
    if (values.type === 5) {
      extraParams = await handleOpenHome(values);
      newVal = { ...newVal, ...extraParams };
    } 
    console.log(newVal)
    if (values.type === 5 && values?.id) {
      newVal = { ...newVal, listings: null };
    }
  
    if (values.type === 4 || values.type === 6) {
      newVal = { ...newVal, addCma: true };
    }
    console.log(newVal)
    const formData = convertFormData(newVal);
    formData.append("_method", "PATCH");
  
    setFillFormData && setFillFormData({});
    if (values?.id) {
      return await postUpdateDiaryAppointmentById(values?.id, formData)
        .then(({ data: { data } }) => {
          props.update && props.update();
          toast.success("Appointment Updated succesfully");
          props.closeModal && props.closeModal();
        })
        .catch(() => {});
    } else {
      return await createNewAppointment(newVal)
        .then(({ data: { data } }) => {
          props.update && props.update();
          toast.success("Appointment Created succesfully");
          if (values.type === 4 || values.type === 6) {
            Swal.fire({
              title: "CMA Report",
              text: "Would you like to create your CMA?",
              icon: "info",
              showCancelButton: true,
              allowOutsideClick: false,
              allowEscapeKey: false,
              confirmButtonColor: "#A7A9AC",
              cancelButtonColor: "#2BB673",
              confirmButtonText: "No",
              cancelButtonText: "Yes"
            }).then(result => {
              if (result.isDismissed && values.type === 4) {
                props.openCma(
                  data?.id,
                  values?.meeting_location?.street_number
                    ? `${values?.meeting_location?.street_number} ${values?.meeting_location?.street?.name}`
                    : values?.meeting_location?.domain_address?.address
                );
              } else if (result.isDismissed && values.type === 6) {
                props.openCma(
                  data?.id,
                  selectedListingAddress?.street_number
                    ? `${selectedListingAddress?.street_number} ${selectedListingAddress?.street?.name}`
                    : selectedListingAddress?.full_address
                );
              }
              props.closeModal && props.closeModal();
              if (values.type === 4) {
                sendPreListingEmailToProspect(data?.id);
              }
            });
          } else {
            props.closeModal && props.closeModal();
          }
        })
        .catch(e => {});
    }
  };

  return (
    <AppointmentContext.Provider
      value={{
        createNewAppointment: newAppointment,
        fillFormData,
        setFillFormData,
        selectedListing,
        setSelectedlistings,
        setFindAddressData,
        findAddressData,
        showCMAModal,
        selectedListingAddress,
        setSelectedListingAddress,
        sendPreListingEmailToProspect
      }}
    >
                  {isModalOpen ? (
        <DefaultModal
          size="xl"
          centered
          show={isModalOpen}
          onHide={() => setPreview(false)}
          hide={true}
        >
          <EmailReportPreview
            closeModal={handleCloseModal}
            url={emailContent}
          />
        </DefaultModal>
      ) : (
        ""
      )}

      {props.children}
    </AppointmentContext.Provider>
  );
};

export default AppointmentContextProvider;
