import { Slider, withStyles } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { Col } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { Field } from "redux-form";
import ThemeButton from "../../../../../partials/button/ThemeButton";
import TextInput from "../../../../../partials/form/TextInput";
import { Card, OverlayTrigger, Popover } from "react-bootstrap";
import { Overlay } from "react-bootstrap";
import { fileDelete } from "../../../../contacts/crud/contactsCrud";
import { change } from "redux-form";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { getMenu } from "../../../../../GeneralApi/GeneralApi";
import MultiSelect from "../../../../../partials/form/MultiSelect";
import PoolFeature from "./PoolFeature";

const propertyFeatures = [
  "AC",
  "Alarm",
  "Balcony",
  "BroadBand",
  "Built-ins",
  "Land Size",
  "Pool",
  "Balcony",
  "BroadBand",
  "Built-ins",
  "AC",
  "Alarm",
  "Balcony",
  "BroadBand",
  "Built-ins",
  "AC",
  "Alarm",
  "Balcony",
  "BroadBand",
  "Built-ins",
  "AC",
  "Alarm",
  "Balcony",
  "BroadBand",
];

const PrettoSlider = withStyles({
  root: {
    color: "lightGrey",
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    color: "black",
    fill: "black",
    backgroundColor: "black",
    border: "2px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);
const FeatureSliders = ({ fields, meta: { touched, error, submitFailed } }) => {
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const [selectedPropertyFeatures, setSelectedPropertyFeatures] = useState([]);
  const [features_menu_options, setFeatures_menu_options] = useState({});
  const [showPoolDescription, setShowPoolDescription] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    let query = "feature_menu_option";
    getMenu(query)
      .then(({ data: { data } }) => {
        setFeatures_menu_options(data.feature_menu_option);
      })
      .catch(() => {});
  }, []);
  const handleClick = (event) => {
    setShow(!show);
    setTarget(event.target);
  };
  const handleAddFeature = (type) => {
    let obj = {};
    obj.description = "";
    obj.type = type;
    if (!selectedPropertyFeatures.includes(type)) {
      fields.push(obj);
      setSelectedPropertyFeatures([...selectedPropertyFeatures, type]);
      setShow(false);
    } else {
      toast.warn("Duplicate Feature");
    }

    // var featuresLandType = document.getElementById("featuresLandType");

    // if (typeof featuresLandType != "undefined" && featuresLandType != null) {
    //   document.getElementById("featuresLandType").value = "";
    // }
  };

  return (
    <div>
      {" "}
      {fields.map((member, index) => (
        <Row className="pt-3 pb-5">
          <Col
            lg={2}
            className="pt-4"
            style={{
              height: "5rem",
              //   width: "70%",
              backgroundColor: "lightBlue",
              textAlign: "center",
            }}
          >
            {fields.get(index).type}
          </Col>
          <Col lg={10}>
            <Row>
              {" "}
              {fields.get(index).type != "Pool" &&
                fields.get(index).type != "Land Size" && (
                  <Col lg={1} className="pr-2">
                    Quantity
                  </Col>
                )}
              <Col
                lg={
                  fields.get(index).type != "Pool" &&
                  fields.get(index).type != "Land Size"
                    ? 10
                    : 11
                }
              >
                {fields.get(index).type == "Land Size" && (
                  <div className="row">
                    <Col lg={1} className="pt-4 pr-2">
                      Details
                    </Col>
                    <div className="col-4 pr-1">
                      <Field
                        name="land_size_to"
                        component={TextInput}
                        placeholder="Land Size To"
                        label="Land Size To"
                      />
                    </div>
                    <div className="col-4 pl-0">
                      <Field
                        label=" "
                        name="feature_menu_option_to_id"
                        component={MultiSelect}
                        showBottom={false}
                        isMulti={false}
                        options={features_menu_options}
                      />
                    </div>
                  </div>
                )}
                {fields.get(index).type == "Pool" && (
                  <PoolFeature member={member} />
                )}
                {fields.get(index).type != "Pool" &&
                  fields.get(index).type != "Land Size" && (
                    <Field
                      name={`${member}.quantity`}
                      component={(props) => (
                        <PrettoSlider
                          //   valueLabelDisplay="auto"
                          aria-label="pretto slider"
                          defaultValue={2}
                          value={props.input.value}
                          valueLabelDisplay="on"
                          onChange={(event, newValue) => {
                            props.input.onChange(newValue);
                          }}
                        />
                      )}
                    />
                  )}
              </Col>
              <Col lg={1}>
                <ThemeButton onClick={() => fields.remove(index)} icon>
                  <i className="fa fa-trash" aria-hidden="true"></i>
                </ThemeButton>
              </Col>
            </Row>
            {fields.get(index)?.type != "Pool" && (
              <Row>
                <Col lg={1} className="pr-2">
                  Description
                </Col>

                <Col lg={11}>
                  <Field component={TextInput} name={`${member}.description`} />
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      ))}
      <div ref={ref}>
        <ThemeButton onClick={handleClick} icon>
          {" "}
          <i className="fas fa-plus-circle"></i>
        </ThemeButton>

        <Overlay
          show={show}
          target={target}
          placement="right"
          container={ref.current}
          containerPadding={20}
        >
          <Popover id="popover-property-features">
            <Popover.Title as="h3">Add Property Features</Popover.Title>
            <Popover.Content>
              {" "}
              <Row className="text-center">
                {propertyFeatures.map((feature) => (
                  <Col
                    lg={2}
                    style={{
                      height: "5rem",

                      //   width: "70%",
                      backgroundColor: "lightBlue",
                    }}
                    onClick={() => handleAddFeature(feature)}
                    className="m-2"
                  >
                    {feature}
                  </Col>
                ))}{" "}
              </Row>
            </Popover.Content>
          </Popover>
        </Overlay>
      </div>
      {/* <PopupState variant="popover" popupId="new_property_type">
        {(popupState) => (
          <div>
            <ThemeButton icon>
              {" "}
              <i className="fas fa-plus-circle"></i>
            </ThemeButton>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: "center",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "center",
                horizontal: "left",
              }}
            >
              {propertyFeatures.map((feature) => (
                <Row className="text-center">
                  <Col
                    lg={2}
                    style={{
                      height: "5rem",
                      //   width: "70%",
                      backgroundColor: "lightBlue",
                    }}
                    onClick={() => handleAddFeature(feature)}
                  >
                    {feature}
                  </Col>{" "}
                </Row>
              ))}
            </Popover>
          </div>
        )}
      </PopupState> */}
    </div>
  );
};

export default FeatureSliders;
