import React, { Component } from "react";
import * as auth from "./_redux/auth.duck";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { LayoutSplashScreen } from "../../../_metronic";
import { logOutUser } from "./curd/auth.crud";
import Cookies from 'js-cookie';
class Logout extends Component {
  componentDidMount() {

    // this.props.hasAuthToken
    // logOutUser()
    // .then(({ data: { data } }) => {
    //   this.props.logout();
    // })
    // .catch((e) => {
    // });
    Cookies.remove(process.env.REACT_APP_WISEBERRY_FORUM_COOKIE_NAME);
    this.props.logout();

  } 

  render() {
    const { hasAuthToken } = this.props;

    return hasAuthToken ? <LayoutSplashScreen /> : <Redirect to="/auth" />;
  }
}

export default connect(
  ({ auth }) => ({ hasAuthToken: Boolean(auth.authToken) }),
  auth.actions
)(Logout);
