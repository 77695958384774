/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import * as modal from '../../modules/_modal/_redux/modalRedux'
import { connect } from "react-redux";
import { Fab } from "@material-ui/core";

class StickyToolbar extends PureComponent {
  render() {
    return (
      <>
        <ul className="kt-sticky-toolbar" style={{ marginTop: "30px" }}>
          {/* <Fab color="secondary" aria-label="Edit" onClick={() => this.props.openModal('InfoModal')}>
          <i className="fas fa-info"></i>
          </Fab> */}
          
          <i color="secondary" aria-label="Edit" onClick={() => this.props.openModal('InfoModal')} className="fas fa-info-circle"></i>
          {/* <OverlayTrigger
            placement="left"
            overlay={<Tooltip id="layout-tooltip">Info</Tooltip>}
          >
            <li
              className="kt-sticky-toolbar__item kt-sticky-toolbar__item--brand"
              data-placement="left"
            >
              <Link to="/builder">
                <i className="flaticon-questions-circular-button" />
              </Link>
            </li>
          </OverlayTrigger> */}

          {/* <OverlayTrigger
            placement="left"
            overlay={<Tooltip id="documentations-tooltip">Documentation</Tooltip>}
          >
            <li
              className="kt-sticky-toolbar__item kt-sticky-toolbar__item--warning"
              data-placement="left"
            >
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.wiseberryonline.com.au/"
              >
                <i className="flaticon2-telegram-logo" />
              </a>
            </li>
          </OverlayTrigger> */}
        </ul>
      </>
    );
  };
}
const actions = {
  openModal: modal.actions.openModal
}
export default connect(null, actions)(StickyToolbar);