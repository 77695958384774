import { IconButton } from '@material-ui/core'
import React, { Fragment } from 'react'
import { Image } from 'react-bootstrap'
import { toAbsoluteUrl } from '../../../_metronic'
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
import LinkedInIcon from "@material-ui/icons/LinkedIn";

export const ProfileBasicInfo = ({profile}) => {
  console.log(profile)
    return (
        <div className="text-center">
        <div className="contact-avatar d-flex">
        <Image
            className="img-fluid"
            src={
              profile?.profile_picture
                ? profile?.profile_picture
                : toAbsoluteUrl("/media/users/default.jpg")
            }
            roundedCircle
          />
        </div>
        <h5 className="main-profile-name  brand-color">{profile?.first_name} {profile?.last_name}</h5>
        <div className="contact-socialButtons">
        <IconButton
        className="darkGrey-icon-button"
            aria-label="facebook.com"
            onClick={() => window.open("https://www.facebook.com"+`${profile?.facebook}`)}
          >
            <FacebookIcon />
          </IconButton>
          <IconButton className="darkGrey-icon-button"
            aria-label="instagram.com"
            onClick={() => window.open("https://www.instagram.com"+`${profile?.instagram}`)}
          >
            <InstagramIcon />
          </IconButton>
          <IconButton className="darkGrey-icon-button"
            aria-label="twitter.com"
            onClick={() => window.open("https://www.twitter.com"+`${profile?.twitter}`)}
          >
            <TwitterIcon />
          </IconButton>
          <IconButton className="darkGrey-icon-button"
            aria-label="Linkedin.com"
            onClick={() => window.open("https://www.Linkedin.com"+`${profile?.linkedin}`)}
          >
            <LinkedInIcon />
          </IconButton>
        </div>
        <p className="text-muted">
          {profile?.profession ? (
            <Fragment>
              {profile.profession} at {profile.company_name}
            </Fragment>
          ) : (
            ""
          )}
        </p>
      </div>
    )
}
