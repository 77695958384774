import {Checkbox, makeStyles} from "@material-ui/core";
import clsx from "clsx";
import React, {useEffect, Fragment, useState, useRef} from "react";

import useHasChangedValue from "../../_helpers/_hooks/usePreviousValue";
import usePreviousValue from "../../_helpers/_hooks/usePreviousValue";
import {getDummyTableRow} from "../../_helpers/_utils/utils";
import DynamicTableEditBody from "./DynamicTableEditBody";

const DynamicTableEdit = ({
  scrollToTop,
  columns,
  data,
  update,
  handleChange = () => {},
  isEditable,
  showFooter,
  allSelectable,
  handleRowClick,
  allSelected,
  isConditional,
  height,
  header,
  getRowProps,
  className,
  addNewRow,
  hideColumns,
  addNewRowBottom,
  renderRowSubComponent,
  onSort,
  disableSort,
  selectRowChangeColor,
  preSelected,
  notHidden,
  dash_header,
  customColumnWidth,
  notScrollToTop,
  selectAllDefault,
  defaultSortBy,
  fromReferral,
  frombuyerTable,
  autoResetSelectedRows,
  resetSelectedRows,
  isLoading = false,
  showPagination = false,
  paginationData,
  fetchMoreData,
  // autoResetSelectedRows,
  // resetSelectedRows,
}) => {
  const [refreshWhenLoad, setRefreshWhenLoad] = useState(false);

  const preDataLength = useHasChangedValue(data?.length);

  useEffect(() => {
    if (data?.length <= preDataLength) {
      setRefreshWhenLoad(true);
    }
    // if (data?.length > preDataLength) {
    //   setRefreshWhenLoad(false);
    // }
  });

  useEffect(() => {
    if (preDataLength == undefined && !frombuyerTable) {
      // console.log("length_data undefined");
      setRefreshWhenLoad(true);
    } else {
      setRefreshWhenLoad(false);
    }
    // setDataLength(data?.length);
  }, [data?.[data?.length - 1]?.id]);
  // useEffect(() => {
  //   setDataLength(data?.length);
  // }, [data?.length]);
  return (
    <DynamicTableEditBody
      scrollToTop={scrollToTop}
      columns={columns}
      data={data}
      update={update}
      handleChange={handleChange}
      isEditable={isEditable}
      showFooter={showFooter}
      allSelectable={allSelectable}
      handleRowClick={handleRowClick}
      allSelected={allSelected}
      isConditional={isConditional}
      height={height}
      header={header}
      getRowProps={getRowProps}
      className={className}
      addNewRow={addNewRow}
      hideColumns={hideColumns}
      addNewRowBottom={addNewRowBottom}
      renderRowSubComponent={renderRowSubComponent}
      onSort={onSort}
      disableSort={disableSort}
      selectRowChangeColor={selectRowChangeColor}
      preSelected={preSelected}
      notHidden={notHidden}
      dash_header={dash_header}
      customColumnWidth={customColumnWidth}
      notScrollToTop={notScrollToTop}
      refreshWhenLoad={refreshWhenLoad}
      selectAllDefault={selectAllDefault}
      defaultSortBy={defaultSortBy}
      fromReferral={fromReferral}
      isLoading={isLoading}
      autoResetSelectedRows={autoResetSelectedRows}
      resetSelectedRows={resetSelectedRows}
      showPagination={showPagination}
      paginationData={paginationData}
      fetchMoreData={fetchMoreData}
    />
  );
};

export default DynamicTableEdit;
