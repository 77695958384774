import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
export const actionTypes = {
  tabChanged: "[tabChanged]",
  dataListingPresentation: "[dataListingPresentation]",

};

const initialSystemBoardsState = {
  selectedSideTab: "Cooking",
};

export const reducer = persistReducer(
  { storage, key: "wol-auth", whitelist: ["settings"] },
  (state = initialSystemBoardsState, action) => {
    switch (action.type) {
      // case actionTypes.ListingLoading: {
      //     return {
      //         ...state
      //     };
      // }

      case actionTypes.tabChanged: {
        return {
          ...state,
          selectedSideTab: action.payload,
        };
      }
      case actionTypes.dataListingPresentation: {
        return {
          ...state,
          data: action.payload,
        };
      }
      // case actionTypes.ListingsFetched: {
      //     const { data, otherResInfo, queryString } = action.payload;
      //     return {
      //         ...state,
      //         entities: data,
      //         otherResInfo: otherResInfo,
      //         queryString: queryString
      //     }
      // }
      // case actionTypes.ListingFetched: {
      //     const {data} = action.payload;
      //     return {
      //         ...state,
      //         profile: data
      //     }
      // }
      // case actionTypes.ListingError: {
      //     return {
      //         ...state,
      //         loading: false,
      //         error: action.error
      //     };
      // }

      default:
        return state;
    }
  }
);

export const actions = {
  tabChanged: (data) => ({ type: actionTypes.tabChanged, payload: data }),
  dataListingPresentation: (data) => ({ type: actionTypes.dataListingPresentation, payload: data }),

  // listingFetchMore: (data) => ({ type: actionTypes.ListingFetchMore, data }),
  // listingsLoadingIndicator: (data) => ({ type: actionTypes.ListingLoadingIndicator, payload: data, }),
  // listingsTableTitleChange: data => ({ type: actionTypes.ListingsTableTitleChange, payload:  data }),
  // // socialFeedPostAsync: data => ({ type: actionTypes.SocialFeedPostAsync, payload: data }),
  // listingFetched: data => ({ type: actionTypes.ListingFetched, payload: { data } }),
  // listingsFetched: data => ({ type: actionTypes.ListingsFetched, payload: data}),
  // listingCreated: data => ({ type: actionTypes.ListingCreated, payload: { data } }),
  // listingUpdated: data => ({ type: actionTypes.ListingUpdated, payload: { data } }),
  // listingDeleted: data => ({ type: actionTypes.ListingDeleted, payload: { data } }),
  // listingsDeleted: data => ({ type: actionTypes.ListingsDeleted, payload: { data } }),
  // listingsStatusUpdated: data => ({ type: actionTypes.ListingsStatusUpdated, payload: { data } }),
  // listingError: error => ({ type: actionTypes.ListingError, payload: { error } })
};
