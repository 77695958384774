import React from 'react'
import { Accordion } from "react-bootstrap";
import CustomToggle from '../../../../../partials/filter/customToggle/CustomToggle';
const PriceFinderMarketDetails = ({ propertyDetails }) => {
    if (!propertyDetails?.marketStatus || !Object.keys(propertyDetails?.marketStatus).length) {
        return null;
    }
    return (
        <div className='price-finder-market-details'>
            <Accordion >
                <CustomToggle eventKey={3}>
                    {" "}
                    <div className="brand-color">Market Details</div>
                </CustomToggle>
                <Accordion.Collapse eventKey={3}>
                    <div>
                        <table >
                            <thead>
                                <tr >
                                    <th>Status</th>
                                    <th>ForRent</th>
                                    <th>ForSale</th>
                                    <th>Likely To List</th>
                                    <th>Recently For Rent</th>
                                    <th>Recently For Sale</th>
                                    <th>Recently Sold</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr >
                                    <td>{propertyDetails?.marketStatus?.status ? propertyDetails?.marketStatus?.status : ''}</td>
                                    <td>{propertyDetails?.marketStatus?.forRent === true ? 'True' : 'False'}</td>
                                    <td>{propertyDetails?.marketStatus?.forSale === true ? 'True' : 'False'}</td>
                                    <td>{propertyDetails?.marketStatus?.likelyToList === true ? 'True' : 'False'}</td>
                                    <td>{propertyDetails?.marketStatus?.recentlyForRent === true ? 'True' : 'False'}</td>
                                    <td>{propertyDetails?.marketStatus?.recentlyForSale === true ? 'True' : 'False'}</td>
                                    <td>{propertyDetails?.marketStatus?.recentlySold === true ? 'True' : 'False'}</td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </Accordion.Collapse>
            </Accordion>
        </div>
    )
}
export default PriceFinderMarketDetails