import { Divider } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { getTimelineInfo } from "../../../modules/contacts/crud/contactsCrud";
import Item from "../../display/Item";
import StreetAddress from "../../display/StreetAddress";

const TimelinePophover = ({ id }) => {
  const [data, setData] = useState(false);
  useEffect(() => {
    getTimelineInfo(id)
      .then(({ data: { data } }) => {
        setData(data);
      })

      .catch(() => {});
  }, []);



  return (
    <div >
      {data ? (
        <Fragment>
          {" "}
          {data?.map((item, index) => (
            <Fragment key={index}>
              {item?.address ? (
                <Item
                className="font-weight-bold"
                  left="Listing"
                  right={item?.address}
                />
              ) : (
                ""
              )}
              {item?.price_opinion ? (
                <Row>
                  <Col sm={7}>
                    {item?.price_opinion ? (
                      <Item
                      className="font-weight-bold"
                        lg={5}
                        left="Opinion"
                        right={"$" + item?.price_opinion}
                      />
                    ) : (
                      ""
                    )}
                  </Col>
                  <Col sm={5}>
                    {item?.rating ? (
                      <Item  className="font-weight-bold"lg={5} left="Rating" right={item?.rating} />
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
              ) : (
                ""
              )}
              {item?.offer ? (
                <Item className="font-weight-bold" left="Offer" right={"$" + item?.offer} />
              ) : (
                ""
              )}
              {item?.comments ? (
                <Item className="font-weight-bold" left="Comment" right={item?.comments} />
              ) : (
                ""
              )}
              {data.length == 1 ? (
                ""
              ) : (
                <Divider className="m-1" variant="middle" />
              )}
            </Fragment>
          ))}{" "}
        </Fragment>
      ) : (
        ""
      )}
    </div>
  );
};

export default TimelinePophover;
