import React, { useEffect, useState } from "react";

import cuid from "cuid";
import {
  Box,
  Button,
  IconButton,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from "@material-ui/core";

import { AddCircle } from "@material-ui/icons";

import { Row } from "react-bootstrap";

import { getAllBlogs } from "../../../setting/_crud/settingCrud";

import MicrositeToggle from "./MicrositeToggle";
import "./style/microsites.css"
import Divider from "@material-ui/core/Divider";
import {useSelector} from "react-redux";

const MicrositeBlogs = ({
  value,
  selected,
  onChange,
  onSelect,
  disabled,
  refetch = false,
  setRefetch = () => {},
  // showModal = () => {},
}) => {
  const [searchBlogs, setSearchBlogs] = useState("");
  const [blogs, setBlogs] = useState([]);
  const [filteredBlogs, setFilteredBlogs] = useState([]);
  const [hidingClass, setHidingClass] = useState("d-none");
  const organisationId = useSelector(
      (state) => state.auth.user?.organisation?.id
  );
  useEffect(() => {
    fetchBlogs();
  }, []);

  useEffect(() => {
    if (refetch) {
      setRefetch(false);
      fetchBlogs();
    }
  }, [refetch]);

  useEffect(() => {
    if (!searchBlogs) {
      setFilteredBlogs(blogs);
    } else {
      setFilteredBlogs(
        blogs.filter(
          blog =>
            String(blog.title)
              .toLowerCase()
              .includes(searchBlogs) ||
            blog?.title?.filter(
              (tc) =>
                String(tc.title)
                  .toLowerCase()
                  .includes(searchBlogs) ||
                String(tc.keywords)
                  .toLowerCase()
                  .includes(searchBlogs)
            )?.length > 0
        )
      );
    }
  }, [searchBlogs]);

  const fetchBlogs = () => {
    getAllBlogs(`limit=100&organisation=4,${organisationId}`)
      .then((res) => {
        if (res.status === 200) {
          setBlogs(res.data.data);
          setFilteredBlogs(res.data.data);
          console.log(res.data.data);
        }
      })
      .catch((err) => console.log("error loading blogs", err));
  };

  return (
    <>
      <MicrositeToggle
        label="Blogs"
        name="blogs"
        disabled={disabled}
        value={value}
        onChange={onChange}
      >
        {/*<Box marginRight={2}>*/}
        {/*  <IconButton onClick={() => showModal(true)}>*/}
        {/*    <AddCircle style={{ color: "#822433" }} />*/}
        {/*  </IconButton>*/}
        {/*</Box>*/}
      </MicrositeToggle>

      {!disabled && value ? (
        <Box className="show_more_box">
          <TextField
            name="blogs_quick_search"
            label={false}
            placeholder="Quick search name or agent"
            className="w-100"
            disabled={disabled}
            onChange={(e) => setSearchBlogs(e.target.value)}
            value={searchBlogs}
          />
          {filteredBlogs?.length > 0 ? (
            <>
              {filteredBlogs.map((blog, index) => (
                <Row
                  className={`${index > 4 ? hidingClass : ""}`}
                  key={cuid()}
                  style={{ marginLeft: 8 }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          selected[`blog_${blog.id}`] ?? false
                        }
                        onChange={(e) => {
                          onSelect({
                            ...selected,
                            [e.target.name]: e.target.checked,
                          });
                        }}
                        name={`blog_${blog.id}`}
                      />
                    }
                    label={(blog.title) + `(`+(blog.organisation?.identifier) +`)` }
                  />
                </Row>
              ))}
            </>
          ) : (
            <Typography variant="subtitle2" style={{ padding: "8px" }}>
              No Blogs found
            </Typography>
          )}

          {filteredBlogs?.length > 4 ? (
            <Box textAlign="center">
              <Button
                onClick={() => setHidingClass(!hidingClass ? "d-none" : "")}
                color="primary"
              >
                {!hidingClass ? "Show less" : "Show more"}
              </Button>
            </Box>
          ) : null}
        </Box>
      ) : null}
      <Divider/>
    </>
  );
};

export default MicrositeBlogs;
