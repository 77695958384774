import React, { useContext, useState, Fragment, useEffect } from "react";
import { connect } from "react-redux";
import ContactForm from "./ContactForm";
import { getContactById, updateContact } from "../../crud/contactsCrud";
import {
  convertFormData,
  errorAlert,
  getProcessedContactInfo,
  postProcessedContactInfo,
} from "../../../../_helpers/_utils/utils";
import { toast } from "react-toastify";
import * as contactsRedux from "../../_redux/contactsRedux";

import { SingleListingContext } from "../../../listings/partials/singleListingList/SingleListingContextProvider";

const ContactFormModal = (props) => {
  const {
    closeModal,
    profileId,
    contactFetch,
    contactsLoading,
    setProfileUpdated,
    profileUpdated,
    formName,
  } = props;
  const [profile, setProfile] = useState({});
  const [profileLoading, setProfileLoading] = useState(false);
  const { updateContext } = useContext(
    SingleListingContext
  ) ?? { updateContext: null };

  useEffect(() => {
    if (profileId) {
      // const { emails, phones, income,tags,addresses,linked_contacts , ...other } = contacts.profile;
      setProfileLoading(true);
      getContactById(profileId)
        .then(({ data: { data } }) => {
          let initialValue = getProcessedContactInfo(data);
          setProfile(initialValue);
        })
        .catch((err) => {
          errorAlert(err?.data);
        })
        .finally(() => {
          setProfileLoading(false);
        });
    }
  }, [profileId]);

  const handleOnsubmit = async (values) => {
    let processedValue = postProcessedContactInfo(values);

    // const formData = convertFormData(
    //   getObjWithoutValues([undefined, null], processedValue)
    // );
    const formData = convertFormData({
      ...processedValue,
      formDataOptions: { allowEmptyArrays: true },
    });

    // const formData = convertFormData(values)
    formData.append("_method", "PATCH");
    return await updateContact(profileId, formData)
      .then(({ data }) => {
        toast.success("Contact Updated Successfully");
        contactFetch(profileId);
        contactsLoading();
        setProfileUpdated(data);
        if (updateContext) updateContext();
        closeModal();
      })
      .catch((e) => {
        toast.error("Error While Saving");
      });
  };

  const handleSelectedContact = (value) => {
    if (value) {
      contactFetch(value.id);
    }
  };
  return (
    <Fragment>
      <div className="form-modal">
        <ContactForm
          hideNote={true}
          formName={formName}
          profileLoading={profileLoading}
          initialValues={
            profile
              ? profile
              : {
                  emails: [{ type: 1, value: " " }],
                  phones: [{ type: 1, value: " " }],
                  addresses: [
                    {
                      type: 1,
                      street_unit: "",
                      street_name: {},
                      suburb: {},
                      state: {},
                    },
                  ],
                  workAddress: [
                    {
                      type: 1,
                      street_unit: "",
                      street_name: {},
                      suburb: {},
                      state: {},
                    },
                  ],
                }
          }
          profileData={profile}
          profileId={profileId}
          closeModal={closeModal}
          onSubmit={handleOnsubmit}
          selectedContact={handleSelectedContact}
        />
      </div>
    </Fragment>
  );
};

const mapState = ({ contacts }) => ({
  contacts,
});
export default connect(mapState, contactsRedux.actions)(ContactFormModal);
