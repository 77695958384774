import { SortableHandle } from "react-sortable-hoc";
import { IconButton } from "@material-ui/core";
import React from "react";

const Handle = SortableHandle(() => (
  <IconButton size="small" className="pl-3">
    <i className="fas fa-bars"></i>
  </IconButton>
));

export default Handle;
