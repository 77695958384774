import React, { Fragment } from "react";
import TenantProfileContextProvider from "./TenantProfileContextProvider";
import TenantProfileModalBody from "./TenantProfileModalBody";

const TenantProfileModal = (props) => {
  const { selectedTenantsId, tenants, continuous, setContinuous } = props;
  return (
    <Fragment>
      <TenantProfileContextProvider
        selectedTenantsId={selectedTenantsId}
        tenants={tenants ?? [selectedTenantsId]}
        continuous={continuous}
        setContinuous={setContinuous}
        filters={props.filters}
      >
        <TenantProfileModalBody {...props} />
      </TenantProfileContextProvider>
    </Fragment>
  );
};

export default TenantProfileModal;
