import React, { PureComponent } from "react";
import * as Views from "./views";

export default class TabContent extends PureComponent {
  renderCount = 0;
  render() {
    let tabName = "ErrorPage";
    this.renderCount++;
    if (this.props.name.includes("Listisssngs")) {
    } else {
    //   console.log(this.props.name);
      tabName = this.props.name.replace(/\s/g, "_");
    //   console.log(tabName);
    }

    const Component = Views[tabName] ? Views[tabName] : "";
    return (
      <div>
        {/* {this.props.name + this.renderCount} */}
        <Component {...this.props.props} />
      </div>
    );
  }
}
