import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest, delay, select } from "redux-saga/effects";
import {
  getAllTenants,
  getTenantById,
  getTenantMatchedData,
  getTenantTimeline,
} from "../crud/tenantsCrud";

export const actionTypes = {
  TenantsLoading: "[TenantsLoading]",
  TenantFetched: "[TenantFetched]",
  TenantFetch: "[TenantFetch]",
  TenantFetchTabs: "[TenantFetchTabs]",
  TenantsFetched: "[TenantsFetched]",
  TenantCreated: "[TenantCreated]",
  TenantUpdated: "[TenantUpdated]",
  TenantDeleted: "[TenantDeleted]",
  TenantsDeleted: "[TenantsDeleted]",
  TenantsStatusUpdated: "[TenantsStatusUpdated]",
  TenantFetchNotes: "[TenantFetchNotes]",
  TenantFetchMatched: "[TenantFetchMatched]",
  TenantFetchContacts: "[TenantFetchContacts]",
  TenantFetchDetails: "[TenantFetchDetails]",
  TenantFetchTimeline: "[TenantFetchTimeline]",
  TenantFetchMail: "[TenantFetchMail]",
  TenantToggleCont: "[TenantToggleCont]",
  TenantSelectedId: "[TenantSelectedId]",
  TenantSelectedChangeId: "[TenantSelectedChangeId]",
  TenantSelected: "[TenantSelected]",
  TenantLoadingIndicator: "TenantsLoadingIndicator",
  TenantFetchMore: "[TenantFetchMore]",
  TenantsTableTitleChange: "[TenantsTableTitleChange]",
};

const initialTenantsState = {
  isLoading: false,
  queryString: "",
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  profile: null,
  tenantForEdit: undefined,
  otherResInfo: null,
  tenantsTableTitle: "All Tenants",
  error: null,
  viewTenant: {
    id: null,
    index: null,
    isLast: false,
  },
  continuousMood: false,
};

export const reducer = persistReducer(
  { storage, key: "wol-auth", whitelist: ["tenants"] },
  (state = initialTenantsState, action) => {
    switch (action.type) {
      case actionTypes.TenantsFetched: {
        const { data, otherResInfo, queryString } = action.payload;
        return {
          ...state,
          entities: data,
          otherResInfo: otherResInfo,
          queryString: queryString,
        };
      }

      case actionTypes.TenantsTableTitleChange: {
        console.log(action.payload, "title_here");
        return {
          ...state,
          tenantsTableTitle: action.payload,
        };
      }
      case actionTypes.TenantLoadingIndicator: {
        const { indicator } = action.payload;
        return {
          ...state,
          isLoading: indicator,
        };
      }
      case actionTypes.TenantToggleCont: {
        return {
          ...state,
          continuousMood: action.payload,
        };
      }

      case actionTypes.TenantSelectedId: {
        return {
          ...state,
          viewTenant: {
            id: action.payload.id,
            index: action.payload.index,
          },
        };
      }

      case actionTypes.TenantFetched: {
        const { data } = action.payload;
        console.log(data);
        return {
          ...state,
          profile: data,
        };
      }
      case actionTypes.TenantError: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }
      case actionTypes.TenantFetchMatched: {
        const payload = action.payload;
        console.log(payload.data);
        return {
          ...state,
          matched: payload.data,
          error: action.error,
        };
      }
      case actionTypes.TenantFetchNotes: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }
      case actionTypes.TenantFetchContacts: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }
      case actionTypes.TenantFetchDetails: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }
      case actionTypes.TenantFetchTimeline: {
        const payload = action.payload;
        console.log(payload.data);
        return {
          ...state,
          timeline: payload.data,
          error: action.error,
        };
      }
      case actionTypes.TenantFetchMail: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  tenantsLoading: (data) => ({ type: actionTypes.TenantsLoading, data }),
  tenantsLoadingIndicator: (data) => ({
    type: actionTypes.TenantLoadingIndicator,
    payload: data,
  }),
  tenantSelectedId: (data) => ({
    type: actionTypes.TenantSelectedId,
    payload: data,
  }),
  tenantSelectedChangeId: (data) => ({
    type: actionTypes.TenantSelectedChangeId,
    payload: data,
  }),
  tenantSelected: (data) => ({
    type: actionTypes.TenantSelected,
    payload: data,
  }),
  tenantToggleCont: (data) => ({
    type: actionTypes.TenantToggleCont,
    payload: data,
  }),
  tenantFetchMore: (data) => ({ type: actionTypes.TenantFetchMore, data }),
  tenantsTableTitleChange: (data) => ({
    type: actionTypes.TenantsTableTitleChange,
    payload: data,
  }),

  tenantFetch: (data) => ({ type: actionTypes.TenantFetch, payload: data }),
  tenantFetchTabs: (data) => ({
    type: actionTypes.TenantFetchTabs,
    payload: data,
  }),
  tenantFetched: (data) => ({
    type: actionTypes.TenantFetched,
    payload: { data },
  }),
  tenantsFetched: (data) => ({
    type: actionTypes.TenantsFetched,
    payload: data,
  }),
  tenantCreated: (data) => ({
    type: actionTypes.TenantCreated,
    payload: { data },
  }),
  tenantUpdated: (data) => ({
    type: actionTypes.TenantUpdated,
    payload: { data },
  }),
  tenantDeleted: (data) => ({
    type: actionTypes.TenantDeleted,
    payload: { data },
  }),
  tenantsDeleted: (data) => ({
    type: actionTypes.TenantsDeleted,
    payload: { data },
  }),
  tenantsStatusUpdated: (data) => ({
    type: actionTypes.TenantsStatusUpdated,
    payload: { data },
  }),
  tenantError: (error) => ({
    type: actionTypes.TenantError,
    payload: { error },
  }),

  tenantFetchNotes: (data) => ({
    type: actionTypes.TenantFetchNotes,
    payload: { data },
  }),
  tenantFetchMatched: (data) => ({
    type: actionTypes.TenantFetchMatched,
    payload: { data },
  }),
  tenantFetchContacts: (data) => ({
    type: actionTypes.TenantFetchContacts,
    payload: { data },
  }),
  tenantFetchDetails: (data) => ({
    type: actionTypes.TenantFetchDetails,
    payload: { data },
  }),
  tenantFetchTimeline: (data) => ({
    type: actionTypes.TenantFetchTimeline,
    payload: { data },
  }),
  tenantFetchMail: (data) => ({
    type: actionTypes.TenantFetchMail,
    payload: { data },
  }),
};

export const getTenants = (state) => state.tenants;

export function* sagaTenants() {
  yield takeLatest(actionTypes.TenantsLoading, function* tenantsLoading(prams) {
    console.log(prams);
    yield put(actions.tenantsLoadingIndicator({ indicator: true }));
    let tenantsInfo = yield select(getTenants);
    let queryData = prams.data
      ? prams.data
      : tenantsInfo.queryString
      ? tenantsInfo.queryString
      : "";
    const res = yield getAllTenants(prams.data);
    const { data, ...rest } = { ...res.data };
    yield put(
      actions.tenantsFetched({
        data: data,
        otherResInfo: rest,
        queryString: queryData,
      })
    );

    yield put(actions.tenantsLoadingIndicator({ indicator: false }));
  });

  yield takeLatest(actionTypes.TenantSelected, function* tenantSelecteds({
    payload,
  }) {
    console.log("called TenantSelected");
    let project = yield select(getTenants);
    let index = project.entities.findIndex((item) => item.id === payload);
    console.log(project.entities);
    console.log(payload);
    console.log(index);

    yield put(actions.tenantFetch(payload));

    // const profileData = yield getTenantById(payload);
    // yield put(actions.tenantFetched(profileData.data.data));

    yield put(actions.tenantSelectedId({ id: payload, index: index }));
  });

  yield takeLatest(
    actionTypes.TenantSelectedChangeId,
    function* tenantSelected({ payload }) {
      console.log(payload);
      let tenants = yield select(getTenants);
      console.log(tenants.viewTenant.index);

      let data = tenants.entities[tenants.viewTenant.index];
      let index = tenants.entities.findIndex((item) => item.id === payload);
      // yield delay(2000);
      console.log(tenants.entities);
      console.log(payload);
      console.log(data);

      let newIndex;
      if (payload === "left") {
        newIndex = tenants.viewTenant.index - 1;
      }
      if (payload === "right") {
        newIndex = tenants.viewTenant.index + 1;
      }
      let newId = tenants.entities[newIndex].id;
      console.log(newId);
      console.log(newIndex);
      yield put(actions.tenantFetch(newId));
      // const profileData = yield getTenantById(newId);
      // yield put(actions.tenantFetched(profileData.data.data));

      yield put(actions.tenantSelectedId({ id: newId, index: newIndex }));
    }
  );

  yield takeLatest(actionTypes.TenantFetchMore, function* tenantFetchMore(
    prams
  ) {
    yield put(actions.tenantsLoadingIndicator({ indicator: true }));
    let tenantsInfo = yield select(getTenants);
    const res = yield getAllTenants(prams.data);
    const { data, ...rest } = { ...res.data };
    const newData = tenantsInfo.entities.concat(data);
    console.log(newData);
    yield put(actions.tenantsFetched({ data: newData, otherResInfo: rest }));
    yield put(actions.tenantsLoadingIndicator({ indicator: false }));
  });

  yield takeLatest(actionTypes.TenantFetch, function* TenantFetch({ payload }) {
    console.log("called socialFeedPostAsync");
    console.log(payload);
    const tenant = yield getTenantById(payload);
    console.log(tenant);
    yield put(actions.tenantFetched(tenant.data.data));

    //     const contact = yield getContactById(data.payload);
    // yield put(actions.contactFetched(contact.data));
  });
}
