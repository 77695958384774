import React,  { Fragment } from "react";
import {
    SortableContainer,
    SortableElement,
} from "react-sortable-hoc";

import "./SortableForm.css";


const SortableItem = SortableElement(({item}) => {
    return (
        <div className="sortable-element pb-2" id={item?.key?.replace(".", "")}
        >
            {item}
        </div>
    );
});



const SortableReduxForm = SortableContainer(
    ({ items}) => {
        return (
            <Fragment><div>
                {items.map((item, index) => (
                    <SortableItem index={index} key={item.key} item={item} />
                ))}
            </div>

            </Fragment>
        );
    }
);

export default SortableReduxForm;
