import React from "react";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import ToolTip from "../../tooltip/ToolTip";
import ToolTipForSvg from "../../tooltip/ToolTipForSvg";

export default function Status({
  rented_through_us,
  unsubscribed,
  dnc,
  active,
  locked,
  referred_for_finance,
  is_in_exclusive_period,
  purchased_from_us,
  email_sent,
  has_past_appointment,
  has_future_appointment,
  offer_made,
  sms_sent,
  show_on_website,
  marketing_text,
  assets,
  is_vacant,
  contract_in_office,
  status,
  rental,
  listing,
  lwo,
  sale_agreed,
  sale_condition
}) {
  return (
    <div className="d-flex ">
      {locked === 1 ? <ToolTip name="lock " justIcon content="Locked" /> : ""}

      {is_in_exclusive_period === 1 ? (
        <ToolTip name="lock " justIcon content="Exclusive Period" />
      ) : (
        ""
      )}

      {show_on_website === 1 ? (
        <ToolTip name="globe-americas " justIcon content="Show on Website" />
      ) : (
        ""
      )}

      {is_vacant === 1 ? (
        <ToolTip name="door-open " justIcon content="Vacant" />
      ) : (
        ""
      )}

      {contract_in_office === 1 ? (
        <ToolTip name="check" justIcon content="Contract in Office" />
      ) : (
        ""
      )}

      {marketing_text?.length > 0 || assets?.length > 0 ? (
        <ToolTip
          name="image "
          justIcon
          content="Has marketing text or assets"
        />
      ) : (
        ""
      )}

      {rented_through_us === 1 ? (
        <ToolTip name="home " justIcon content="Rented through us" />
      ) : (
        ""
      )}

      {purchased_from_us === 1 ? (
        <ToolTip name="home " justIcon content="Purchased through us" />
      ) : (
        ""
      )}

      {dnc === 1 ? (
        <ToolTip name="phone-slash  " justIcon content="Do not call" />
      ) : (
        ""
      )}

      {referred_for_finance === 1 ? (
        <ToolTipForSvg status content="Referral">
          <MonetizationOnIcon className="darkGrey-svg-button" />
        </ToolTipForSvg>
      ) : (
        ""
      )}

      {is_in_exclusive_period === 1 ? (
        <ToolTip name="phone-slash  " justIcon content="Exclusive period" />
      ) : (
        ""
      )}

      {unsubscribed === 1 ? (
        <ToolTip name="flag  " justIcon content="Unsubscribed" />
      ) : (
        ""
      )}

      {active === 0 ? (
        <ToolTip name="eye-slash  " justIcon content="Inactive" />
      ) : (
        ""
      )}
      {email_sent === 1 ? (
        <ToolTip name="envelope  " justIcon content="Email Sent" />
      ) : (
        ""
      )}
      {has_past_appointment === 1 ? (
        <ToolTip name="calendar  " justIcon content="Has past appointment" />
      ) : (
        ""
      )}
      {has_future_appointment === 1 ? (
        <ToolTip
          name="calendar green-color  "
          justIcon
          content="Has future appointment"
        />
      ) : (
        ""
      )}
      {offer_made === 1 ? (
        <ToolTip name="calendar  " justIcon content="Offer made" />
      ) : (
        ""
      )}
      {sms_sent === 1 ? (
        <ToolTip name="mobile  " justIcon content="SMS sent" />
      ) : (
        ""
      )}

      {rental === 1 ? (
        <ToolTip name="key  " justIcon content="Rental" />
      ) : (
        ""
      )}

      {listing === 1 ? (
        <ToolTip name="home  " justIcon content="Listing" />
      ) : (
        ""
      )}

      {lwo === 1 ? (
        <ToolTip name="sign  " justIcon content="Listed With others" />
      ) : (
        ""
      )}

      {sale_condition === 1 ? (
        <ToolTip name="check-circle  " justIcon content="Conditional" />
      ) : (
        ""
      )}
      {sale_agreed  === 1 ? (
        <ToolTip fill={false} name="check-circle" justIcon content="Sale Agreed" />
      ) : (
        ""
      )}
    </div>
  );
}
