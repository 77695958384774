import React from "react";
import { Fragment } from "react";
import { Col, Row } from "react-bootstrap";
import { Field } from "redux-form";
import ThemeButton from "../../../../partials/button/ThemeButton";
import MultiSelect from "../../../../partials/form/MultiSelect";

const MicrositeSettingArray = (props) => {
  const { fields, edit, touch, tag, placeholder } = props;
  const handleAddTags = () => {
    let allData = fields.getAll();
    console.log(allData);
    let obj = {};
    fields.push(obj);
    // allData.sort((a, b) => a.name.localeCompare(b.name))
    // console.log(allData)
    // fields.removeAll();

    // allData.map(ob => fields.push(ob));

    //  fields.insert(0,obj);
    // document.getElementById(fields.name).value = "";
    // touch();
  };
  const handleDelete = (id) => {
    let value = fields.get(id);
    fields.remove(id);
    // fields.push({ ...value, deleted: 1 });
    // touch();
    // inputRef.current.focus();
  };
  return (
    <div>
      {fields && (
        <div>
          {fields.map((field, index) => (
            <Fragment>
              <div className={fields.get(index)?.deleted ? "d-none" : ""}>
                <Row className="   justify-content-between " key={index}>
                  <Col lg={3} className="pr-2">
                    {" "}
                    <Field
                      name={`${field}.type`}
                      component={MultiSelect}
                      label="Microsite Type"
                      //  fullWidth={false}
                      options={[{}]}
                    />
                  </Col>
                  <Col lg={3} className="pr-2">
                    {" "}
                    <Field
                      name={`${field}.eixsting_microsite`}
                      component={MultiSelect}
                      label="Copy Existing Microsite"
                      options={[{}]}
                      //  fullWidth={false}
                    />{" "}
                  </Col>
                  <Col lg={3} className="pr-2">
                    {" "}
                    <Field
                      name={`${field}.presentation`}
                      component={MultiSelect}
                      label="Presentation"
                      options={[{}]}
                      //  fullWidth={false}
                    />
                  </Col>
                  <Col lg={1}>
                    {" "}
                    {edit ? (
                      <div className=" pt-3">
                        <ThemeButton icon onClick={() => handleDelete(index)}>
                          <i className="fas fa-minus-circle    "></i>
                        </ThemeButton>
                      </div>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
              </div>
            </Fragment>
          ))}
          <div className="d-flex justify-content-center pt-2">
            <ThemeButton onClick={() => handleAddTags()} icon>
              <i className="fas fa-plus-circle "></i>
            </ThemeButton>
          </div>
        </div>
      )}
    </div>
  );
};

export default MicrositeSettingArray;
