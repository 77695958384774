import React from 'react';
import DynamicTableEdit from "../../../../../partials/table/DynamicTableEdit";
import { Accordion } from "react-bootstrap";
import CustomToggle from '../../../../../partials/filter/customToggle/CustomToggle';

const PriceFinderListingHistoryTable = ({ propertyDetails, listingChecked, setCheckedListing }) => {

    const columns = React.useMemo(
        () => [
            {
                Header: "RentalHistory",
                columns: [
                    {
                        Header: "Address",
                        disableSortBy: true,
                        accessor: "address",
                        Cell: ({
                            cell: { value },
                            cell: {
                                row: { original },
                            },
                        }) => (
                            <div>
                                {`${original?.address?.locality}-${original?.address?.street}-${original?.address?.state}` ?? ''}
                            </div>
                        ),
                    },
                    {
                        Header: "Start Date",
                        disableSortBy: true,
                        accessor: "startDate",
                        Cell: ({
                            cell: {
                                row: { original },
                            },
                        }) => <p>{original?.startDate?.display ?? 'No Date'}</p>,
                    },
                    {
                        Header: "End Date",
                        disableSortBy: true,
                        accessor: "endDate",
                        Cell: ({
                            cell: {
                                row: { original },
                            },
                        }) => <p>{original?.endDate?.display ?? 'No Date'}</p>,
                    },
                    {
                        Header: "Price",
                        disableSortBy: true,
                        accessor: "price",
                        Cell: ({
                            cell: {
                                row: { original },
                            },
                        }) => (
                            <div>{original?.price.display ?? ''}</div>
                        ),
                    },
                ],
            },
        ],
        []
    );

    return (
        <div>
            <Accordion >
                <div className="d-flex align-items-center">
                    <input
                        type="checkbox"
                        onChange={setCheckedListing}
                        checked={listingChecked}
                    />
                    <CustomToggle eventKey={3} className="flex-grow-1">
                        <div className="brand-color ml-2">
                            {" "}Listing History
                        </div>
                    </CustomToggle>
                </div>
                <Accordion.Collapse eventKey={3}>
                    <DynamicTableEdit
                        columns={columns}
                        height={"45"}
                        data={propertyDetails?.listingHistory?.listings || []}
                    />
                </Accordion.Collapse>
            </Accordion>
        </div>
    )
}

export default PriceFinderListingHistoryTable;
