import React, { Fragment } from "react";
import { CharacterCircle } from "../../component/characterCircle/CharacterCircle";

const ContactConnectionsItem = ({type, connection}) => {
    const bgColor = () => {
        switch (type) {
            case "Buyer":
              return "buyer";
            case "Landlord":
                return "buyer";
            case "Prospect":
             return "buyer";
            case "Seller":
             return "buyer";
            case "Tenant":
              return "buyer";

            default:
                return '';
          }
    }
  return (
    <Fragment>
      <div className="d-flex flex-row bd-highlight">
        <div className="p-1 pr-3  bd-highlight">
        <CharacterCircle background={type} character={type.charAt(0)} />
        
         
        </div>
        <div className="p-1 relation">{connection}</div>
      </div>
    </Fragment>
  );
};

export default ContactConnectionsItem;
