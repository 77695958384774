import React from "react";
import { connect } from "react-redux";

import InfoModal from "./InfoModal";
import ContactProfileModal from "../../contacts/modals/contactProfileModal/ContactProfileModal";
import ContactFormModal from "../../contacts/modals/contactFormModal/ContactFormModal";
import EventModal from "../../diary/partials/modals/TaskModal";
import BuyerProfileModal from "../../buyers/modals/buyerProfileModal/BuyerProfileModal";
import ProspectProfileModal from "../../prospect/modals/prospectProfileModal/ProspectProfileModal";
import AddNewZoneAnalysis from "../../prospect/modals/zoneAnalysis/addNewZoneAnalysis/AddNewZoneAnalysis";
import TenantProfileModal from "../../tenants/modals/tenantProfileModal/TenantProfileModal";
// import AddNewListingModal from "../../listings/modals/addNewListings/AddNewListingModal";
import AddNewListings from "../../listings/modals/addNewListings/AddNewListings";

const modalLookup = {
  EventModal,
  InfoModal,
  ContactProfileModal,
  ContactFormModal,
  BuyerProfileModal,
  ProspectProfileModal,
  AddNewZoneAnalysis,
  AddNewListings,
  TenantProfileModal,
  // AddNewListingModal,
};

const mapState = (state) => ({
  currentModal: state.modal,
});

const ModalManager = ({ currentModal }) => {
  let renderedModal;
  if (currentModal && currentModal.modalType) {
    const { modalType, modalProps } = currentModal;
    const ModalComponent = modalLookup[modalType] ? modalLookup[modalType] : "";
    renderedModal = <ModalComponent {...modalProps} />;
  }
  return <span>{renderedModal}</span>;
};

export default connect(mapState)(ModalManager);
