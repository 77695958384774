import React, { useEffect, useState, useRef } from "react";
import { Fragment } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Field, getFormValues, reduxForm } from "redux-form";
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { Editor } from "@tinymce/tinymce-react";
import { formToQueryString } from "../../../../_helpers/_utils/utils";
import { getMenu, getAllMembers } from "../../../../GeneralApi/GeneralApi";
import ThemeButton from "../../../../partials/button/ThemeButton";
import ThemeHeader from "../../../../partials/content/ThemeHeader";
import MultiSelect from "../../../../partials/form/MultiSelect";
import TextArea from "../../../../partials/form/TextArea";
import Templates from "./templatesBlog";
import { useSelector } from "react-redux";
import TextEditor from "../../../../partials/form/TextEditor";
import TextInput from "../../../../partials/form/TextInput";
import ToggleSwitch from "../../../../partials/form/toggleSwitch/ToggleSwitch";
import { UserImageInputWithCropper } from "../../../../partials/form/UserImageInputWithCropper";
import ToolTip from "../../../../partials/tooltip/ToolTip";
import { convertFormData } from "../../../../_helpers/_utils/utils";
import { updateCommunityInvolvement } from "../../../setting/_crud/settingCrud";
import { plugins } from "chart.js";

const AddNewCommunityInvolvement = ({ closeModal, closeWithAlert, handleSubmit, selectedCommunityInvolvement, setSelectedCommunityInvolvement, formValues, invalid, pristine, submitting, content, setContent }) => {
  const [tag, setTags] = useState({});
  const formRef = useRef(null)
  const editorRef = useRef(null);
  const org = useSelector((state) => state.auth.user.organisation.id);
  const [plugins, setPlugins] = useState()
  const [editimage_cors_hosts, setEditimageCorsHosts] = useState()
  const [menubar, setMenuBar] = useState()
  const [toolbar, setToolBar] = useState()
  const [colorMap, setColorMap] = useState([])
  const [members, setMembers] = useState(null);

  const useDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
  const isSmallScreen = window.matchMedia('(max-width: 1023.5px)').matches;
  const [remainingChars, setRemainingChars] = useState(300);
  const [isTeamMembers, setIsTeamMembers] = useState(0);

  useEffect(() => {
    let query = "comm_tag";
    getMenu(query)
      .then(({ data: { data } }) => {
        setTags(data.comm_tag);
      })
      .catch(() => { });
    formRef.current = {}

    let obj = { checkLeader: 1 }
    let queryMember = formToQueryString(obj)

    getAllMembers(queryMember)
      .then(({ data: { data } }) => {
        setMembers(
          data.map((s) => ({
            name: `${s.initial}`,
            id: s.contact.id,
            optionTooltipTitle: `${s?.contact?.first_name ?? ''} ${s?.contact?.last_name ?? ''}`,
          }))
        );
      })
      .catch((e) => { });

  }, []);

  useEffect(() => {
    if (formRef.current && formValues) {
      setIsTeamMembers(formValues.team_members)
      formRef.current = formValues
    }
  }, [formValues, content])

  const handleInputChange = (event) => {
    const currentLength = event.target.value.length;
    setRemainingChars(300 - currentLength);
  };

  return (
    <div className="p-3 blog-form mb-5">
      <form style={{ maxWidth: '1200px', margin: 'auto' }} onSubmit={handleSubmit}>
        <ThemeHeader
          left={
            <Fragment>
              <h5 className="title">
                {!_isEmpty(selectedCommunityInvolvement) ? "Edit" : "Add New"} Community Involvement
              </h5>
            </Fragment>
          }
          right={
            <Fragment>
              <Fragment>
                <div className="pr-2 pt-2 home-opening-hours">
                  <Field
                    name="active"
                    component={ToggleSwitch}
                    labelTrue="Public"
                    labelFalse="Draft"
                  />
                </div>
                <div className="pl-2">
                  <ThemeButton
                    onClick={pristine ? closeModal : closeWithAlert}
                    className="lightGrey-button font-weight-bold"
                  >
                    Cancel
                  </ThemeButton>
                </div>
                <div className="pl-2">
                  <ThemeButton
                    disabled={(_isEmpty(selectedCommunityInvolvement) ? (invalid || pristine || submitting) : (invalid || submitting))}
                    type="submit"
                    className="green-button"
                  >
                    {submitting ?
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      /> : ''}
                    Save
                  </ThemeButton>
                </div>
              </Fragment>
            </Fragment>
          }
        />
        {/* <Col lg={12}> */}
        <Row lg={12} style={{ maxWidth: '1400px' }}>
          <Col lg={6}>
            <Row className='pl-5 mt-4 d-block' >
              <Col style={{ fontWeight: 600, fontSize: "20px" }} className={"mb-4"} lg={6}>Add an Image</Col>
              <Col>
                <Field
                  name="image_url"
                  roundedCircle={false}
                  image_url='https://v2-wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/assets/placeholders/Image-Small.jpg'
                  component={UserImageInputWithCropper}
                  aspectRatio={3 / 2}
                  dropZoneStyle={{ width: '450px' }}
                />
              </Col>
              <Col className="d-flex justify-content-center align-items-center ">
                <div className="d-flex align-items-center">
                  <div style={{ fontSize: "19px", marginRight: "40px" }} className="font-weight-bold">
                    Website Visibility
                  </div>
                  <div>
                    <div style={{ width: "220px" }} className="pr-2 d-flex pt-2 home-opening-hours justify-content-between">
                      <p>Office Homepage</p>
                      <Field
                        name="office_homepage"
                        component={ToggleSwitch}
                        labelTrue="Public"
                        labelFalse="Hide"
                      />
                    </div>
                    <div style={{ width: "220px" }} className="pr-2 d-flex pt-2 home-opening-hours justify-content-between">
                      <p>Team Members</p>
                      <Field
                        name="team_members"
                        component={ToggleSwitch}
                        onChange={(e) => setIsTeamMembers(e) }
                        labelTrue="Public"
                        labelFalse="Hide"
                      />
                    </div>
                  </div>
                </div>

              </Col>
            </Row>
          </Col>
          <Col lg={6}>
            <Row style={{ marginTop: "40px", height: "414px" }} >
             
              <Col lg={12} className="pt-2" style={{ height: '1px'}}>
                <Field
                  required
                  component={TextInput}
                  label="Community Involvement Title"
                  name={"title"}
                  inputProps={{ maxLength: 40 }}
                />
              </Col>
              <Col lg={12} className="pt-2" style={{ position: 'relative' , height: '127px'}}>
                <p style={{ color: " #827d7d" }}>Description</p>
                <Field
                  required
                  className="textarea-community"
                  component={TextArea}
                  // label="Description"
                  name={"description"}
                  inputProps={{ maxLength: 300 }}
                  height={{height: "200px"}}
                  onChange={handleInputChange}
                />
                <span className={`char-counter ${remainingChars === 0 ? 'text-danger' : 'text-muted'}`}>
                  {remainingChars}
                </span>
              </Col>

              <Col lg={12} className="pt-2">
                <Field
                  required
                  component={TextInput}
                  label="Keywords"
                  name={"keywords"}
                />
              </Col>
              {isTeamMembers == 1 && <Col lg={12}>
                <Field
                  name="contact"
                  label="Select Team Members"
                  component={MultiSelect}
                  showDelete={true}
                  isMulti={true}
                  options={members}
                />
              </Col>}
            </Row>
            
          </Col>
        {/* </Row> */}
        </Row>

      </form>{" "}
    </div>
  );
};
const mapState = (state, { selectedCommunityInvolvement }) => {
  const formValues = getFormValues("new-comm")(state);
  return {
    formValues,
    initialValues: !_isEmpty(selectedCommunityInvolvement)
      ? selectedCommunityInvolvement
      : {
        tags: [],
        keywords: '',
        image_url: null,
        content: '',
      }
  };
};

export default connect(mapState)(
  reduxForm({ form: "new-comm" })(AddNewCommunityInvolvement)
);
