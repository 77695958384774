import React, { useEffect, useState } from 'react'
import { TextField,} from '@material-ui/core'

export function OpenLeasedEditSettings({ selectedNode }) {
  const [address, setAddress] = useState('')
  const [suburb, setSuburb] = useState('')

  const getTargetElement = targetClassName => {
    return selectedNode.getElementsByClassName(targetClassName)[0]
  }

  useEffect(() => {
    let leasedSuburb = getTargetElement('suburb').innerText
    setSuburb(leasedSuburb)
    let leasedAddress = getTargetElement('address').innerText
    setAddress(leasedAddress)

  }, [selectedNode])

  const handleAddressChange = e => {
    getTargetElement('address').innerHTML = e.target.value
    setAddress(e.target.value)
  }

  const handleSuburbChange = e => {
    getTargetElement('suburb').innerHTML = e.target.value
    setSuburb(e.target.value)
  }

  return (
    <div>
      <TextField
        className='my-3'
        type="text"
        label="Suburb"
        value={suburb}
        onChange={handleSuburbChange}
        fullWidth
        InputLabelProps={{
          shrink: true
        }}
      />
      <TextField
        className='my-3'
        type="text"
        label="Address"
        value={address}
        onChange={handleAddressChange}
        fullWidth
        InputLabelProps={{
          shrink: true
        }}
      />

    </div>
  )
}
