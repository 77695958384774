import React, { Fragment } from "react";
import { Image } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../_metronic";
import { IconButton } from "@material-ui/core";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import AccessControl from "../../_ accessControl/AccessControl";

const BasicInfoAva = ({ profile, edit, editClicked }) => {
  return (
    <Fragment>
      <div className="text-center">
        <div className="contact-avatar d-flex justify-content-center">
          <Image
            className="img-fluid"
            src={
              profile?.profile_picture
                ? profile?.profile_picture
                : toAbsoluteUrl("/media/users/default.jpg")
            }
            roundedCircle
          />
          <AccessControl
            allowedPermissions={["contacts-edit"]}
            renderNoAccess={() => ""}
          >
            {edit ? (
              <div className="contact-editButton">
                <IconButton onClick={editClicked}>
                  <i className="fa fa-edit"></i>
                </IconButton>
              </div>
            ) : (
              ""
            )}
          </AccessControl>
        </div>
        <h5 className="main-profile-name pt-4 brand-color">
          {profile?.first_name} {profile?.last_name}
        </h5>
        <div className="contact-socialButtons pb-1">
          <IconButton
            className="darkGrey-icon-button"
            aria-label="facebook.com"
            onClick={() =>
              window.open("https://www.facebook.com/" + `${profile?.facebook}`)
            }
          >
            <FacebookIcon />
          </IconButton>
          <IconButton
            className="darkGrey-icon-button"
            aria-label="instagram.com"
            onClick={() =>
              window.open(
                "https://www.instagram.com/" + `${profile?.instagram}`
              )
            }
          >
            <InstagramIcon />
          </IconButton>
          <IconButton
            className="darkGrey-icon-button"
            aria-label="twitter.com"
            onClick={() =>
              window.open("https://www.twitter.com/" + `${profile?.twitter}`)
            }
          >
            <TwitterIcon />
          </IconButton>
          <IconButton
            className="darkGrey-icon-button"
            aria-label="Linkedin.com"
            onClick={() =>
              window.open(
                "https://www.Linkedin.com/in/" + `${profile?.linkedin}`
              )
            }
          >
            <LinkedInIcon />
          </IconButton>
        </div>
        <p className="text-muted pb-3">
          {/* {profile?.job_role ? ( */}
          <Fragment>
            {profile?.job_role} at {profile?.company_name}
          </Fragment>
        </p>
      </div>
    </Fragment>
  );
};

export default BasicInfoAva;
