import React from "react";
import AddNewListingsContextProvider from "./AddNewRentalContextProvider";
import AddNewListingWrap from "./AddNewRentalWrap";

const AddNewListings = (props) => {
  return (
    <div>
      <AddNewListingsContextProvider>
        <AddNewListingWrap {...props} />
      </AddNewListingsContextProvider>
    </div>
  );
};

export default AddNewListings;
