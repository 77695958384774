import React, { useState, useEffect } from 'react';
import _map from 'lodash/map';
import _uniqueId from 'lodash/uniqueId';
import { OverlayTrigger, Popover } from 'react-bootstrap';

const APMContacts = ({
  APMContactData,
  selectedAPMContacts,
  setSelectedContacts,
  propertyDetails,
  selectedOwners,
  setSelectedOwners
}) => {
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [ownerContactData, setOwnerContactData] = useState([]);

  useEffect(() => {
    if (propertyDetails?.ownership?.owners?.length) {
      let tempData = [...propertyDetails?.ownership?.owners, ...APMContactData];
      setOwnerContactData(tempData);
    } else {
      setOwnerContactData(APMContactData);
    }
  }, [APMContactData, propertyDetails?.ownership?.owners])


  useEffect(() => {
    let SelcetedContactIds = [...selectedOwners, ...selectedAPMContacts]
    if (ownerContactData.length === SelcetedContactIds.length) {
      setSelectAllChecked(true);
    } else {
      setSelectAllChecked(false);
    }
  }, [selectedOwners, selectedAPMContacts, ownerContactData])

  const handleSelectAllToggle = (e) => {
    setSelectAllChecked(!selectAllChecked);

    if (e.target.checked) {
      let selectedIds = _map(APMContactData, (contact) => contact.id);
      setSelectedContacts(selectedIds);
      let ownerIds = propertyDetails?.ownership.owners.map((val, index) => index)
      setSelectedOwners(ownerIds)
    } else {
      setSelectedContacts([]);
      setSelectedOwners([]);
    }
  };
  const handleOwnerSelect = (e, index) => {
    if (e.target.checked) {
      setSelectedOwners([...selectedOwners, index]);
    } else {
      setSelectedOwners(selectedOwners.filter(val => val !== index))
    }
  };
  const handleMemberSelect = (e) => {
    let selectedData = [];
    if (e?.target?.id) {
      if (selectedAPMContacts.includes(e?.target?.id)) {
        let tempSelected = [...selectedAPMContacts].filter(val => val !== e?.target?.id);
        setSelectedContacts(tempSelected);
      } else {
        setSelectedContacts([...selectedAPMContacts, e?.target?.id]);
        selectedData.push(...selectedAPMContacts, e.target.id)
      }
    }
  };

  if (!APMContactData?.length && !propertyDetails?.ownership?.owners.length) {
    return null;
  }
  return (
    <div>
      <div className="d-flex align-items-center">
        <div className="mt-1 mr-2">
          <input
            id="checkAllContacts"
            type="checkbox"
            checked={selectAllChecked}
            onChange={handleSelectAllToggle}
          />
        </div>
        <h6 className="title m-0">Contacts</h6>
      </div>
      <div>
        {propertyDetails?.ownership?.owners.length ? <div>
          {_map(propertyDetails?.ownership?.owners, (contact, index) => {
            return <div key={index} className="ml-3 mb-2 d-flex">
              <div className="mt-1 mr-2">
                <input
                  id={contact?.id}
                  type="checkbox"
                  onChange={(e) => handleOwnerSelect(e, index)}
                  checked={selectedOwners.includes(index)}
                />
              </div>
              <OverlayTrigger
                trigger={'hover'}
                placement="top"
                rootClose
                overlay={
                  <Popover id="popover-basic">
                    <div className='px-3 py-2'>
                      Owner
                    </div>
                  </Popover>
                }
              >
                <div className='d-flex'>
                  <div >Name: <span style={{ color: '#822433' }}>{contact?.first_name} {contact?.surname}</span></div>
                  <span className='pl-2' style={{ color: '#822433' }}>
                    <i className="fa fa-user" aria-hidden="true"></i>
                  </span>

                </div>
              </OverlayTrigger>

            </div>
          })}
        </div> : ''}
      </div>
      {_map(APMContactData, (contact, index) => (
        <div key={index} className="ml-3 mb-2 d-flex">
          <div className="mt-1 mr-2">
            <input
              id={contact?.id}
              type="checkbox"
              onChange={handleMemberSelect}
              checked={selectedAPMContacts.includes(contact?.id)}
            />
          </div>
          <div>
            <div>Name: {contact?.first_name} {contact?.last_name}</div>
            <div>Phone: {contact?.number}{' '}
              {contact?.doNotCall ? <i className="fas fa-phone-slash   brand-color "></i> : null}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default APMContacts;
