import React, { Fragment } from "react";
import { Form, Row } from "react-bootstrap";
import { Field } from "redux-form";
import GroupIconInput from "../GroupIconInput";

const ContactFormSocialInfo = ({ fieldArray }) => {
  return (
    <Fragment>
      <Form.Group as={Row}>
        <Field
        placeholder="Facebook"
          name={fieldArray ? `${fieldArray}.facebook` : "facebook"}
          icon={<i className="fab fa-facebook-f"></i>}
          component={GroupIconInput}
        />
        <Field
         placeholder="Instagram"
          name={fieldArray ? `${fieldArray}.instagram` : "instagram"}
          icon={<i className="fab fa-instagram"></i>}
          component={GroupIconInput}
        />
        <Field
         placeholder="Twitter"
          name={fieldArray ? `${fieldArray}.twitter` : "twitter"}
          icon={<i className="fab fa-twitter"></i>}
          component={GroupIconInput}
        />
        <Field
         placeholder="LinkedIn"
          name={fieldArray ? `${fieldArray}.linkedin` : "linkedin"}
          icon={<i className="fab fa-linkedin-in"></i>}
          component={GroupIconInput}
        />
        <Field
         placeholder="Rea"
          name={fieldArray ? `${fieldArray}.rea` : "rea"}
          icon={<i className="fas fa-sign"></i>}
          component={GroupIconInput}
        />
        <Field
         placeholder="Introduction Video"
          name={fieldArray ? `${fieldArray}.introduction_video` : "introduction_video"}
          icon={<i className="fab fa-youtube"></i>}
         component={GroupIconInput}
        />
      </Form.Group>
    </Fragment>
  );
};

export default ContactFormSocialInfo;
