import React, { Fragment, useContext, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import Item from "../../../../../partials/display/Item";
import FeaturesTable from "../../../../../partials/display/property/featuresTable/FeaturesTable";
import {
  addNumberSeparator,
  currencyFormatter,
} from "../../../../../_helpers/_utils/utils";
import { BuyerProfileContext } from "../BuyerProfileContextProvider";
import BuyerDetailsTable from "./BuyerDetailsTable";

const BuyerDetailsView = (props) => {
  const { profile } = useContext(BuyerProfileContext);
  const { ready_in_date, looking_date } = props;
  return (
    <Fragment>
      <div className="buyerDetails">
        <Row className="pb-3">
          <Col xl="12">
            <div className="title font-weight-bold">Buyer Details</div>
          </Col>
          <Col xl="4">
            <Item
              lg="5"
              md="5"
              col="5"
              left="Buyer type"
              right={profile?.type?.name}
            />
            <Item
              lg="5"
              md="5"
              col="5"
              left="Been Looking"
              right={looking_date + " " + profile?.looking_date_time_unit?.name}
            />
            <Item
              lg="5"
              md="5"
              col="5"
              left="Condition"
              right={profile?.condition?.name}
            />
            {/* <Item lg="5" md="5" col="5" left="Username" right={profile?.username} /> */}
          </Col>
          <Col xl="4">
            <Item
              lg="5"
              md="5"
              col="5"
              left="Rating"
              right={profile?.rating?.name}
            />
            <Item
              lg="5"
              md="5"
              col="5"
              left="Ready In"
              right={
                ready_in_date + " " + profile?.ready_in_date_time_unit?.name
              }
            />
            <Item
              lg="5"
              md="5"
              col="5"
              left="To Sell"
              right={profile?.to_sell?.name}
            />
          </Col>
          <Col xl="4">
            <Item
              lg="5"
              md="5"
              col="5"
              left="Allocation"
              right={profile?.allocated_to?.name}
            />
            <Item
              lg="5"
              md="5"
              col="5"
              left="Active"
              right={profile?.active == 1 ? "Yes" : "No"}
            />
            <Item
              lg="5"
              md="5"
              col="5"
              left="Motivation"
              right={profile?.motivation}
            />
          </Col>
        </Row>
        <Row className="pb-3">
          <Col xl="12">
            <div className="title font-weight-bold">Preferences</div>
          </Col>
          <Col xl="12">
            <Item
              left="Price Range"
              right={
                profile?.price_from &&
                "$" +
                  addNumberSeparator(profile?.price_from) +
                  " - $" +
                  addNumberSeparator(profile?.price_to)
              }
            />
            <Item
              left="Locations"
              right={profile?.suburbs?.map((suburb) => suburb?.name)?.join(",")}
            />
            <Item
              left="Property Types"
              right={profile?.property_types
                ?.map((suburb) => suburb?.name)
                ?.join(",")}
            />
          </Col>
        </Row>
      </div>
      <FeaturesTable features={profile?.features} height="30" />
    </Fragment>
  );
};

export default BuyerDetailsView;
