import React, { useEffect, useState } from 'react'
import { TextField, TextareaAutosize } from '@material-ui/core'

function TextSettings({ selectedNode, label="Address" }) {
  console.log('selectedNode', selectedNode, selectedNode.innerHTML, selectedNode.innerText)
  const [value, setValue] = useState(selectedNode.innerText)

  useEffect(() => {
    setValue(selectedNode.innerText)
  }, [selectedNode])

  const handleChange = e => {
    setValue(e.target.value)
    selectedNode.innerText = e.target.value
  }

  return (
    <div>
    { label === "Description" || label === "Disclaimer"?  <TextareaAutosize
        type="text"
        label={label}
        style={{ width:" 100%" , minHeight:"150px"}}
        onChange={handleChange}
        value={value}
      /> :   <TextField
        type="text"
        label={label}
        fullWidth
        onChange={handleChange}
        value={value}
      />}
    </div>
  )
}

export default TextSettings