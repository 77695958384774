export default {
  header: {
    self: {},
    items: [
      {
        title: "News",
        component: "News",
        identifier: "news",
        root: true,
        alignment: "left",
        page: "news",
      },
      {
        title: "Dashboard",
        component: "Dashboard",
        identifier: "dashboard",
        root: true,
        alignment: "left",
        page: "dashboard",
      },
      {
        title: "Diary",
        component: "Diary",
        identifier: "diary",
        root: true,
        alignment: "left",
        page: "diary",
      },
      {
        title: "Contacts",
        component: "Contacts",
        identifier: "contacts",
        root: true,
        alignment: "left",
        page: "contacts",
      },
      {
        title: "Prospects",
        component: "Prospects",
        identifier: "prospects",
        root: true,
        alignment: "left",
        page: "prospects",
      },
      {
        title: "Listings",
        component: "listings",
        identifier: "listings",
        root: true,
        alignment: "left",
        page: "listings",
      },
      {
        title: "Buyers",
        component: "Buyers",
        identifier: "buyers",
        root: true,
        alignment: "left",
        page: "buyers",
      },
      {
        title: "Rentals",
        identifier: "rentals",
        component: "Rentals",
        root: true,
        alignment: "left",
        page: "rentals",
      },
      {
        title: "Tenants",
        identifier: "tenants",
        component: "Tenants",
        root: true,
        alignment: "left",
        page: "tenants",
      },
      // {
      //   title: "Mail",
      //   component: "under_construction",
      //   root: true,
      //   alignment: "left",
      //   page: "under_construction",
      // },
      {
        title: "Bulk Filters",
        identifier: "Bulk Filters",
        component: "Bulk Filters",
        root: true,
        alignment: "left",
        page: "bulk_filters",
      },
      {
        title: "Marketing",
        identifier: "marketing",
        component: "Marketing",
        root: true,
        alignment: "left",
        page: "marketing",
      },
      {
        title: "Mail",
        component: "mail",
        identifier: "mail",
        root: true,
        alignment: "left",
        page: "mail",
      },
      {
        title: "System Boards",
        component: "system_board",
        identifier: "system boards",
        root: true,
        alignment: "left",
        page: "system_boards",
      },
      // {
      //   title: "BuyBuy",
      //   component: "BuyBuy",
      //   root: true,
      //   alignment: "left",
      //   page: "under_construction"
      // },
      {
        title: "Accounts",
        component: "Accounts",
        root: true,
        alignment: "left",
        page: "accounts",
      },

      {
        title: "Settings",
        component: "Settings",
        identifier: "office",
        root: true,
        alignment: "left",
        page: "settings",
      },

      {
        title: "Key Management",
        component: "KeyManagement",
        root: true,
        alignment: "left",
        page: "key_management",
      },
    ],
  },
};
