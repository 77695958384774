import React, { useEffect } from "react";
import { Checkbox } from "@material-ui/core";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  useRowSelect,
} from "react-table";
import { Waypoint } from "react-waypoint";
import { getDummyTableRow } from "../../_helpers/_utils/utils";

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;
    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        {/* e.stopPropagation() will stop it from row onClick */}
        <Checkbox className="table-checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  }
);
const defaultPropGetter = () => ({});
const DynamicTable = ({
  columns,
  data,
  update,
  handleChange,
  isEditable,
  hideColumn,
  showFooter,
  handleRowClick,
  height,
  getHeaderProps = defaultPropGetter,
  getColumnProps = defaultPropGetter,
  getRowProps = defaultPropGetter,
  getCellProps = defaultPropGetter,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    preGlobalFilteredRows,
    prepareRow,
    selectedFlatRows,
    footerGroups,
    state: { sortBy },
    setHiddenColumns,
  } = useTable(
    {
      columns,
      data,
    },

    isEditable
      ? (hooks) => {
          hooks.allColumns.push((columns) => [
            // Let's make a column for selection
            {
              id: "selection",
              // The header can use the table's getToggleAllRowsSelectedProps method
              // to render a checkbox.  Pagination is a problem since this will select all
              // rows even though not all rows are on the current page.  The solution should
              // be server side pagination.  For one, the clients should not download all
              // rows in most cases.  The client should only download data for the current page.
              // In that case, getToggleAllRowsSelectedProps works fine.
              Header: ({ getToggleAllRowsSelectedProps }) => (
                <div>
                  <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                </div>
              ),
              // The cell can use the individual row's getToggleRowSelectedProps method
              // to the render a checkbox
              Cell: ({ row }) => (
                <div>
                  <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                </div>
              ),
            },
            ...columns,
          ]);
        }
      : "",
    useGlobalFilter, // useGlobalFilter!
    useSortBy,
    useRowSelect
  );

  React.useEffect(() => {
  }, [sortBy]);

  React.useEffect(() => {
    if (hideColumn) {
      setHiddenColumns(
        columns
          .filter((column) => !column.isVisible)
          .map((column) => column.accessor)
      );
    }
  }, [setHiddenColumns, columns]);


  useEffect(() => {
    if (isEditable) {
      handleChange(selectedFlatRows.map((d) => d.original));
    }
  }, [selectedFlatRows]);

  const handleRowClicked = ({ original }) => {

    if (handleRowClick) {
      handleRowClick(original);
    }
  };

  return (
    <div
      className="tableFixHead theme-table-striped"
      style={{ height: `${height}vh` }}
    >
      <table {...getTableProps()} className="table">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps(getRowProps(row))}
                onClick={() => handleRowClicked(row)}
              >
                {row.cells.map((cell, i) => {
                  return (
                    <td
                      key={i}
                      {...cell.getCellProps([
                        {
                          className: cell.column.className,
                          style: cell.column.style,
                        },
                        getColumnProps(cell.column),
                        getCellProps(cell),
                      ])}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
          {/* Demo Row */}
          {[...Array(getDummyTableRow(79, rows?.length))].map((e, i) => {
            return (
              <tr key={i}>
                {rows[0]?.cells.map((cell, i) => {
                  return <td key={i}></td>;
                })}
              </tr>
            );
          })}
          {/* Demo Row */}
          <tr>
            <td>
              <Waypoint onEnter={update} />
            </td>
          </tr>
        </tbody>
        {showFooter ? (
          <tfoot>
            {footerGroups.map((group) => (
              <tr {...group.getFooterGroupProps()}>
                {group.headers.map((column) => (
                  <td {...column.getFooterProps()}>
                    {column.render("Footer")}
                  </td>
                ))}
              </tr>
            ))}
          </tfoot>
        ) : (
          ""
        )}
      </table>
    </div>
  );
};

export default DynamicTable;
