import React, { useEffect, useState } from 'react'
import { Badge } from '@material-ui/core';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import { Spinner } from 'react-bootstrap';
import { getAllListings } from '../../listings/_crud/listingsCrud';
import { formToQueryString } from '../../../_helpers/_utils/utils';
import { useHistory } from 'react-router';




  const SearchDropdownListing = ({searchValue}) => {
    const history = useHistory();
    const [items, setItems] = useState({});
    useEffect(() => {
      if(searchValue)
      {
        getAllListings(formToQueryString({ search_kw: searchValue }))
        .then((res) => {
          setItems(res.data.data);
        })
        .catch((e) => {
          // setSuccess(true);
          // setLoading(false);
          // toast.success('Error while saving');
        });
      }

    }, [searchValue]); // here

    const onClickListing = (e) => {
      history.push("/listings/" + e);
    }

    return (
      <div>
          {items && items.length ?
          <div>
        <div className="kt-quick-search__category"> Listings <Badge  color="secondary" badgeContent={items.length}>
            <RecentActorsIcon />
               </Badge></div>
            {items.map((item, index) => (
          <React.Fragment key={index}>
           
              <div className="kt-quick-search__item">
                {/* TODO: icons and avatars don't load from array */}

                {/* <div className='kt-quick-search__item-img'>
                    <img src={item.avatar} alt="" />
                </div> */}

                <div className="kt-quick-search__item-wrapper">
                  <h6 href="#" className="kt-quick-search__item-title" listing-id={item.id} listing-title={item.title}  onClick={()=>onClickListing(item.id)} >
                   {item.property?.address?.full_address} 
                  </h6>
                </div>
              </div>
          </React.Fragment>
        ))}
          </div>
          :
          <div>
              <div className="kt-quick-search__category"> Listings <Badge  color="secondary" badgeContent={items.length}>
            <RecentActorsIcon />
               </Badge></div>
               <Spinner animation="grow" />
          </div>
          }
      </div>
    )
  }

export default SearchDropdownListing
