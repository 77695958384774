import { Divider, IconButton } from "@material-ui/core";
import React, { Fragment } from "react";
// import { ReactComponent as Thumbtack } from "../../../../_metronic/_assets/media/icons/wb/pin.svg";
import { ReactComponent as Thumbtack } from "../../../../../_metronic/_assets/media/icons/wb/pin.svg";
import moment from "moment";
import ThemeButton from "../../../../partials/button/ThemeButton";
import MemberName from "../../../../partials/popover/memberPopover/MemberName";
const MobileNoteItem = ({data, makePinned, profile, displayGeneral}) => {


  return (
    
      <div  className={`${
      data?.type?.name.toLowerCase() === "general"
        ? displayGeneral
          ? null
          : "d-none"
        : null
    }`}>
        <div className={`note-item note-item-${data?.type?.name.toLowerCase()}`}>
        <div className="flex-grow-1">
          <div className="d-flex justify-content-between flex-wrap ">
            <div className="d-flex mr-3" style={{fontSize: 18}}>
              <p
                className="text-dark-75 font-weight-bold mr-3"
              >
                {data?.created_by ?  <MemberName id={data.created_by.id} >{data?.created_by?.initial}</MemberName> : '' }


              </p>
              <p style={{fontSize: 18}}>
               {moment(data?.created_at).format('DD/MM/YY h:mm a')}
              </p>
            </div>
            <div className="my-lg-0 my-3">
            <ThemeButton icon onClick={()=>makePinned(data.id,data?.pinned )} className={"note-thumbtack "+ (data?.pinned === 1 ? "pinned" : "") }>

                <Thumbtack className="kt-svg-icon kt-svg-icon--primary" />


            </ThemeButton>
            </div>
          </div>

          <div className="d-flex flex-wrap text-break">
         { data?.profileId && data?.profileId[0]?.prospect_address ?
         <>
           <div className="d-flex flex-grow-1">
              <div style={{width: '50%'}}>
          <p> {data?.detail}</p>
             {/* {tag()?.name} */}
              </div>

              <div style={{display: "flex", width: '40%'}}>
              <p style={{textAlign: 'end', background: "#FCF6BC"}}>
              {data?.profileId && data?.profileId[0]?.prospect_address}
              </p>
              </div>
            </div>

            </> :

            <>
            <div className="d-flex flex-grow-1" style={{fontSize: 18}}>

          <p> {data?.history?.door_knock === 1 ? "[Door Knock]" : null}  {data?.history?.phone_call === 1 && "[Phone Call]"}  {data?.history?.na === 1 && "[NA]"} {" "}  {" "} {data?.detail}</p>


            </div>

            </>
            }

          </div>
        </div>
      </div>
      <Divider className="mb-2 mt-2" variant="middle" />
    </div>
  );
};

export default MobileNoteItem;
