import React, { useState, Fragment, useEffect } from "react";
import { Form, Col, Row } from "react-bootstrap";
import { reduxForm, Field } from "redux-form";
import { connect } from "react-redux";
import ThemeHeader from "../../content/ThemeHeader";
import ThemeButton from "../../button/ThemeButton";
import Item from "../../display/Item";
import DateInput from "../../form/DateInput";
import MultiSelect from "../../form/MultiSelect";
import {
  getAllMembers,
  postCallReminder,
} from "../../../GeneralApi/GeneralApi";
import { toast } from "react-toastify";
import { getObjWithoutValues } from "../../../_helpers/_utils/utils";
import Datepicker from "../../form/Datepicker";
import moment from "moment";

const CallReminder = (props) => {
  const [show, setShow] = useState(true);
  const {
    handleSubmit,
    pristine,
    submitting,
    invalid,
    closeModal,
    buyerId,
    prospectId,
    contactId,
    tenantId,
    user,
    initialize
  } = props;
  const [members, setMembers] = useState(null);

  useEffect(() => {
    getAllMembers()
      .then(({ data: { data } }) => {
        setMembers(
          data.map((s) => ({
            name: `${s.initial}`,
            id: s?.contact?.id,
          }))
        );
      })
      .catch((e) => {
        toast.error("Error while fetching");
      });
  }, []);

  const onFormSubmit = async (values) => {
    let obj = {};
    if (values.date) {
      obj.date = moment(values.date).format("YYYY-MM-DDTHH:mm");
    }
    if (values.for) {
      obj.for_contact = values.for;
    }
    if (contactId) {
      obj.contact = contactId;
      obj.type = 1;
    }
    if (buyerId) {
      obj.buyer = buyerId;
      obj.type = 2;
    }
    if (prospectId) {
      obj.prospect = {};
      obj.prospect[prospectId] = {"call_type": 1};
      obj.prospect = prospectId;
      obj.type = 3;
    }
    if (tenantId) {
      obj.tenant = tenantId;
      obj.type = 4;
    }

    await postCallReminder(obj)
      .then((res) => {
        if (res.status === 200) {
          toast.success("Reminder Saved Successfully");
          closeModal();
        }
      })
      .catch((e) => console.log(e, "error"));
  };
  return (
    <Fragment>
      <div className="buyerFormModal">
        <form className="contactForm" onSubmit={handleSubmit(onFormSubmit)}>
          <Fragment>
            <ThemeHeader
              left={
                <Fragment>
                  <h5 className="title">Set Call Reminder</h5>
                </Fragment>
              }
              right={
                <Fragment>
                  <Fragment>
                    <div className="pl-2">
                      <ThemeButton
                        className="lightGrey-button"
                        onClick={closeModal}
                      >
                        Cancel
                      </ThemeButton>
                    </div>
                    <div className="pl-2">
                      <ThemeButton
                        type="submit"
                        disabled={invalid || pristine || submitting}
                        // onClick={()=>handleAdjustPrice()}
                        className="submit-button"
                      >
                        Save
                      </ThemeButton>
                    </div>
                  </Fragment>
                </Fragment>
              }
            />
            <Form.Row>
              <Col md={6}>
                <Item
                  left="Date"
                  mainClass="pt-4"
                  right={
                    <Field
                      name="date"
                      showTimeSelect
                      dateFormat="dd/MM/yyyy"
                      component={Datepicker}
                      placeholder="Date"
                      type="datetime-local"
                      label="Date"
                    />
                  }
                />
              </Col>
              <Col md={6}>
                <Field
                  name="for"
                  component={MultiSelect}
                  showBottom={false}
                  isMulti={false}
                  options={members}
                  label="For"
                  placeholder="For"
                />
              </Col>
            </Form.Row>
          </Fragment>
        </form>
      </div>
    </Fragment>
  );
};

const actions = {
};
const mapState = (state) => {
};
export default connect(
  mapState,
  actions
)(reduxForm({ form: "call-reminder", enableReinitialize: true })(CallReminder));
