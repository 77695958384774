/* eslint-disable no-restricted-imports */
import React, { Fragment, useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Col, Modal, Row } from "react-bootstrap";
import * as modals from "../../../_modal/_redux/modalRedux";
import { LinearProgress, makeStyles } from "@material-ui/core";
import "./_contactProfileModal.scss";
import ContactNotes from "./notes/ContactNotes";
import ContactsTimeline from "./timeline/ContactsTimeline";
import ContactsMail from "./mail/ContactsMail";
import ContactProfileHeader from "./contactProfileHeader/ContactProfileHeader";
import ContactProfileSideBar from "./contactProfileSideBar/ContactProfileSideBar";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import ContactsFiles from "./files/ContactsFiles";
import { ContactProfileContext } from "./ContactProfileContextProvider";
import LoadingSkeleton from "../../../../partials/display/skeleton/LoadingSkeleton";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Fragment>{children}</Fragment>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: "none",
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
}));

const ContactProfileModalBody = (state) => {
  const { closeModal } = state;
  const [value, setValue] = React.useState(0);
  const { profile, contactId, contactLoading } = useContext(ContactProfileContext);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const classes = useStyles();

  return (
    <>
      {contactLoading? <LinearProgress /> : <div style={{height:'4px'}}></div>}
      {profile ? (
        <Fragment>
          <Row className="pb-2">
            <Col lg={{ span: 9, offset: 3 }}>
              <ContactProfileHeader
                contactId={contactId}
                closeModal={closeModal}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={4} xl={3} className="modal-sidebar mb-sm-5">
              <ContactProfileSideBar
                closeModal={closeModal}
                profile={profile}
              />
            </Col>

            <Col lg={8} xl={9} className="pl-lg-5">
              <div className={classes.root}>
                <AppBar position="static" className="app-bar">
                  <Tabs
                    className="tabs"
                    variant="scrollable"
                    scrollButtons="auto"
                    value={value}
                    onChange={handleChange}
                    TabIndicatorProps={{ style: { background: "transparent" } }}
                  >
                    <Tab label="Notes" {...a11yProps(0)} />
                    <Tab label="Timeline" {...a11yProps(1)} />
                    <Tab label="Mail" {...a11yProps(2)} />
                    <Tab label="Files" {...a11yProps(3)} />
                  </Tabs>
                </AppBar>
                <TabPanel className="single-tab-body" value={value} index={0}>
                  <ContactNotes contactId={contactId} profile={profile}/>
                </TabPanel>
                <TabPanel className="single-tab-body" value={value} index={1}>
                  <ContactsTimeline contactId={contactId} />
                </TabPanel>
                <TabPanel className="single-tab-body" value={value} index={2}>
                  <ContactsMail contactId={contactId} profile={profile} />
                </TabPanel>
                <TabPanel className="single-tab-body" value={value} index={3}>
                  <ContactsFiles contactId={contactId} profile={profile}/>
                </TabPanel>
              </div>
            </Col>
          </Row>
        </Fragment>
      ) : (
        <LoadingSkeleton />
      )}
    </>
  );
};

const mapStateToProps = () => {};



export default connect(mapStateToProps, null)(ContactProfileModalBody);
