import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { getAllTasks } from "../_curd/diaryCurd";
import faker from "faker";
export const actionTypes = {
    AddTask: "[Task add]",
    DeleteTask: "[Task Delete]",
    UpdateTask: "[Task Update]",
    TaskLoaded: "[Task Loaded]",
    TaskLoading: "[Task Loading]",
    TaskError: "[TasksError]",
    HandleDiaryType: "[HandleDiaryType]",
};

const initialTasksState = {
    tasks: [],
    loading: false,
    diaryType: 'appts',
    error: ''
};

export const reducer = persistReducer(
    { storage, key: "wol-auth", whitelist: ["loading"] },
    (state = initialTasksState, action) => {
        switch (action.type) {

            case actionTypes.TaskLoading: {
               return { ...state, loading: true };
           }

            case actionTypes.TaskLoaded: {
                 const  tasks = action.payload;
                return { ...state, tasks };
            }
            case actionTypes.AddTask: {
                console.log("a");
                 const  tasks = action.payload;
                 console.log(tasks+'update');
                return { ...state, tasks: [...state.tasks,tasks ] };
            }

            case actionTypes.UpdateTask: {
                console.log(JSON.stringify(state)+'state');
                const tasks = action.payload;
                console.log(tasks+'taskss');
                console.table(...state.tasks.filter(task => task.id !== tasks.id));
                // console.log(...state.tasks.filter(task => task.id !== tasks.id)+'before state');
                // return [...state.tabs, tasks]
                return {...state, tasks: [...state.tasks.filter(task => task.id !== tasks.id),tasks]};
                // return [...state, data];
                
            }
            case actionTypes.DeleteTask: {
                const tasks = action.payload;
                // console.log(...state.tasks.filter(task => task.name !== tasks));
                return {...state, tasks: [...state.tasks.filter(task => task.name !== tasks)]};
                // return [...state.filter(task => task.id !== action.payload)];
            }
            case actionTypes.TaskError: {
                return {
                    ...state,
                    loading: false,
                    error: action.error
                };
            }
            case actionTypes.HandleDiaryType: {
                const diaryType = action.payload;
                return {
                    ...state,
                    diaryType: diaryType
                };
            }

            default:
                return state;
        }
    }
);

export const actions = {
    handleDiaryType :tasks => ({ type: actionTypes.HandleDiaryType, payload: tasks }),
    deleteTask: tasks => ({ type: actionTypes.DeleteTask, payload: tasks }),
    taskLoading: (prams) => ({ type: actionTypes.TaskLoading, payload: prams}),
    taskLoaded: data => ({ type: actionTypes.TaskLoaded, payload: data }),
    addTask: tasks => ({ type: actionTypes.AddTask, payload: tasks }),
    updateTask: tasks => ({ type: actionTypes.UpdateTask, payload: tasks }),
    taskError: error => ({ type: actionTypes.TaskError, payload: { error } })
};


export function* sagaDiary() {
    // yield takeLatest(actionTypes.NewsLoading,function* testtingSaga() {
    //     console.log('NewsLoading');
    //     yield put(actions.());
    //   });
    yield takeLatest(actionTypes.TaskLoading, function* tasksRequested(prams) {
        // const {data:{data}} = yield getAllTasks(prams.payload);
        // console.log(data);
        // let newData = data.map(({starts,ends,...other}) => ({start: new Date(starts)  , end: new Date (ends),...other}))
        // yield put(actions.taskLoaded(newData));
    });
}
