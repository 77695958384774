import React, { Fragment, useContext, useState } from "react";
import AccordionContext from "react-bootstrap/AccordionContext";
import { useAccordionToggle, Accordion, Card } from "react-bootstrap";

import { Field } from "redux-form";
import ContactFormPersonalDetails from "../../../../../partials/form/partials/ContactFormPersonalDetails";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import ContactFormSocialInfo from "../../../../../partials/form/partials/ContactFormSocialInfo";
import FormTags from "../../../../../partials/form/partials/FormTags";
import ContactFormContactInfo from "../../../../../partials/form/partials/ContactFormContactInfo";
import ContactFormFamilyDetails from "../../../../../partials/form/partials/ContactFormFamilyDetails";
import ContactConnections from "../../../../../partials/display/contactConnection/ContactConnections";
import { IconButton } from "@material-ui/core";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Handle from "../../../../../partials/component/handle/Handle";
import { UserImageInput } from "../../../../../partials/form/UserImageInput";

import { connect } from "react-redux";
import { reduxForm, FieldArray } from "redux-form";
import RenderContact from "../../../../../partials/form/sortableArrayFields/RenderContact";
import ThemeButton from "../../../../../partials/button/ThemeButton";
import * as tenantsRedux from "../../../_redux/tenantsRedux";
import DefaultModal from "../../../../../partials/modal/DefaultModal";
import NewContact from "../../../../contacts/modals/contactFormModal/NewContact";

function CustomToggle({ children, fields, index, eventKey }) {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(eventKey, () =>
    console.log("totally custom!")
  );

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <Fragment>
      <h5 className="title">{children}</h5>
      <div className="d-flex">
        <IconButton
          onClick={(e) => {
            decoratedOnClick();
          }}
          className="pr-0"
        >
          {isCurrentEventKey ? (
            <KeyboardArrowDownIcon fontSize="inherit" />
          ) : (
            <i className="fas fa-plus-circle fa-xs	"></i>
          )}
        </IconButton>

        <Handle />
      </div>
    </Fragment>
  );
}

const TenantContacts = (props) => {
  const {
    handleSubmit,
    change,
    submitting,
    profileId,
    pristine,
    invalid,
    profile,
    setNewContactId,
    onFormSubmit,
    setAddNewContact,
  } = props;
  const [showAddNewContact, setShowAddNewContact] = useState(false);

  return (
    <Fragment>
      {showAddNewContact && (
        <DefaultModal
          size="xl"
          centered
          show={showAddNewContact}
          onHide={() => setShowAddNewContact(false)}
        >
          <NewContact
            closeModal={() => setShowAddNewContact(false)}
            setNewContactId={setNewContactId}
            onFormSubmit={onFormSubmit}
            submitFormName="tenant-contacts"
            OutsideContact={true}
          />
        </DefaultModal>
      )}
      <form className="contactForm" onSubmit={handleSubmit}>
        <div className="d-flex justify-content-end pb-3">
          <ThemeButton
            // disabled={invalid || submitting || pristine}
            // type="submit"
            className="darkGrey-button mr-3"
            onClick={() => {
              setShowAddNewContact(true);
              setAddNewContact(true);
            }}
          >
            Add Linked Contact
          </ThemeButton>
          <ThemeButton
            disabled={invalid || submitting || pristine}
            type="submit"
            className="green-button"
          >
            Save
          </ThemeButton>
        </div>
        <div
          style={{
            maxHeight: "80vh",
            overflow: "auto",
          }}
        >
          <FieldArray
            profileId={profileId}
            name="contacts"
            component={RenderContact}
            viewData={props.data}
            allowedPermission="tenants-edit"
            change={change}
          />
          {/* <Accordion>
            <Card>
              <Card.Header className="d-flex justify-content-between pl-3 pr-3">
                <CustomToggle eventKey="0">
                  Additional Contact
                 </CustomToggle>

             
              </Card.Header>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <ContactFormPersonalDetails />
                  <Row>
                    <Col md={6}>
                      <Row>
                        <Col md={4}>
                          <Field
                            name={`profile_picture`}
                            component={UserImageInput}
                          />{" "}
                        </Col>
                        <Col md={8}>
                          <ContactFormSocialInfo />
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6}>
                      <FormTags />
                    </Col>
                  </Row>
                  <hr />
                  <ContactFormContactInfo />
                  <hr />
                  <ContactFormFamilyDetails />
                  <hr />
                  <Row>
                    <Col md={6}>
                      <ContactConnections />
                    </Col>
                    <Col md={6}>
                    
                    </Col>
                  </Row>
                </Card.Body>
               </Accordion.Collapse>
            </Card>
          </Accordion> */}
        </div>
      </form>
    </Fragment>
  );
};

const mapState = (state, props) => {
  return {
    initialValues: {
      contacts: props.data,
    },
  };
};

export default connect(
  mapState,
  tenantsRedux.actions
)(
  reduxForm({
    form: "tenant-contacts",
    enableReinitialize: true,
  })(TenantContacts)
);
