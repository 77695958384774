import React, { Fragment, useEffect, useState, useContext } from "react";
import { reduxForm } from "redux-form";
import { InputAdornment, TextField } from "@material-ui/core";
import Swal from "sweetalert2";
import moment from "moment";
import { toast } from "react-toastify";
import _isEmpty from "lodash/isEmpty";

import ThemeButton from "../../../../../partials/button/ThemeButton";
import ThemeHeader from "../../../../../partials/content/ThemeHeader";
import DynamicTableEdit from "../../../../../partials/table/DynamicTableEdit";
import MaterialSelect from "../../../../../partials/form-basic/select/MaterialSelect";
import { getMenu } from "../../../../../GeneralApi/GeneralApi";
import { bulkDeleteSign, updateProspect } from "../../../curd/prospectsCrud";
import MarkSignPopUp from "./MarkSignPopUp";
import { AddZoneAnalysisContext } from "./AddZoneAnalysisContextProvider";
import DefaultModal from "../../../../../partials/modal/DefaultModal";
import AccessControl from "../../../../../_ accessControl/AccessControl";
import { required } from "../../../../validation/Validation";
import { Spin } from "antd";
import useAccessControl from "../../../../../_helpers/_hooks/accessControl";
import StreetAddress from "../../../../../partials/display/StreetAddress";
import { useSelector } from "react-redux";
import { convertFormData } from "../../../../../_helpers/_utils/utils";
import TextInputAmount from "../../../../../partials/form/TextInputAmount";

const companiesOptions = [
  { id: 0, name: "Competitors" },
  { id: 1, name: "Wiseberry" },
];

export const AddSignTable = (props) => {
  const {
    fields,
    setSignsAdded,
    signsAdded,
    streetOptions,
    formValue,
    initialValues,
  } = props;
  const hasEditPermission = useAccessControl(["zone_analysis_sales-edit"]);
  const {
    handleAddNewSign,
    updateSignTable,
    updateSign,
    signTableData,
    filterCompanies,
    setFilterCompanies,
    filterSigns,
    setFilterSigns,
    streetZoneId,
    ZONE_TYPE,
    fetchMoreSign,
    addSignLoading,
  } = useContext(AddZoneAnalysisContext);
  const [showMarkSign, setShowMarkSign] = useState(false);
  const [selectedSigns, setSelectedSigns] = useState([]);
  const [street, setStreet] = useState("");
  const [unit, setUnit] = useState("");
  const [number, setNumber] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [price, setPrice] = useState("");
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [sale, setSale] = useState("");
  const [saleOptions, setSaleOptions] = useState([]);
  const [streetOption, setStreetOption] = useState([]);
  const [singleSignDetails, setSingleSignDetails] = useState({});
  const [accessCondition, setAccessCondition] = useState([]);
  const organisation = useSelector(
    (state) => state.auth?.user?.organisation?.id
  );
  useEffect(() => {

    let query;
    if (ZONE_TYPE === 1) {
      query = "listing_sign_status";
    } else {
      query = "rental_sign_status";
    }
    getMenu(query)
      .then(({ data: { data } }) => {
        setSaleOptions(data[query]);
      })
      .catch(() => { });
  }, []);

  useEffect(() => {
    if (streetZoneId || initialValues?.id) {
      let zone_Id = initialValues?.id ? initialValues?.id : streetZoneId;
      updateSignTable({ zone: zone_Id });
    }
  }, [streetZoneId, initialValues?.id])
  useEffect(() => {
    let options = streetOptions?.length
      ? streetOptions.map((obj = {}) => {
        const { id, name, suburb } = obj;
        return { id, name: name + ", " + suburb?.name };
      })
      : [];
    console.log(options);
    setStreetOption(options);
  }, [streetOptions]);

  useEffect(() => {
    if (formValue?.streets?.length) {
      updateSignTable({ zone: formValue?.id });
    }
  }, [filterSigns, filterCompanies]);


  useEffect(() => {
    if (_isEmpty(initialValues)) {
      setAccessCondition(
        ZONE_TYPE === 1
          ? ["zone_analysis_sales-add"]
          : ["zone_analysis_rentals-add"]
      );
    } else {
      setAccessCondition(
        ZONE_TYPE === 1
          ? ["zone_analysis_sales-edit"]
          : ["zone_analysis_rentals-edit"]
      );
    }
  }, [ZONE_TYPE, initialValues]);

  const handleDeleteSigns = () => {
    Swal.fire({
      title: "Removing Sign?",
      text: "This will remove " + selectedSigns.length + " Items",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#A7A9AC",
      cancelButtonColor: "#2BB673",
      confirmButtonText: "Cancel",
      cancelButtonText: "Yes",
    }).then((result) => {
      if (result.isDismissed) {
        bulkDeleteSign({ remove_lwu: 1, selected_ids: selectedSigns.map((item) => item.id) })
          .then((data) => {
            updateSignTable({ zone: formValue?.id });
          })
          .catch((e) => { });
      }
    });
  };

  const handleAddSign = () => {
    if (street) {
      let newSigns = {
        // address: {
        //   street: street,
        //   street_unit: unit,
        //   street_number: number,
        // },
        // price: price,
        // company_name: companyName,
        // date: date,
        // status: sale,
        organisation: organisation,
        property: {
          organisation: organisation, address: {
            street: street,
            street_unit: unit,
            street_number: number,
          }
        },
        status: sale, lwo: companyName, sign_added_date: date, lwo_price: price
      };
      handleAddNewSign(newSigns, formValue);

      setSignsAdded([
        ...signsAdded,
        {
          address: {
            street: street,
            street_unit: unit,
            street_number: number,
          },
          price: price,
          company_name: companyName,
          date: date,
          status: sale,
        },
      ]);
      setStreet("");
      setUnit("");
      setNumber("");
      setCompanyName("");
      setPrice("");
      setDate("");
      setSale("");
    } else {
      toast.error("Please select street");
    }
  };
  const columns = [
    {
      Header: "Signs",
      Footer: "Name",
      columns: [
        {
          Header: "Date",
          accessor: "sign_added_date",
          // Footer: (total) => {
          //   return (
          //     <Fragment>
          //       <strong>Total:</strong> {total?.data?.length}
          //     </Fragment>
          //   );
          // },
          Cell: ({ cell: { value } }) => (
            <p>{value ? moment(value).format("DD/MM/YYYY") : ""}</p>
          ),
        },
        {
          Header: "Address",
          accessor: "address",
          Cell: ({
            cell: {
              row: { original },
            },
          }) => <StreetAddress value={original?.property?.address} />,
        },
        {
          Header: "Company",
          accessor: "company_name",
          Cell: ({
            cell: {
              row: { original },
            },
          }) => <p>{original?.lwu === 1 ? "Wiseberry" : original?.lwo}</p>,
        },
        {
          Header: "Price",
          accessor: "lwo_price",
          Cell: ({
            cell: {
              row: { original },
            },
          }) => (
            <p>
              ${original.lwo_price}{" "}
              {[1, 3].includes(original?.status?.id) ? (
                <ThemeButton
                  small
                  className={"red-button"}
                  onClick={() => {
                    setShowMarkSign(true);
                    setSingleSignDetails(original);
                  }}
                >
                  {" "}
                  {ZONE_TYPE === 1 ? "Mark Sold" : "Mark Rented"}
                </ThemeButton>
              ) : (
                <i className="fas fa-flag red-icon-button"></i>
              )}{" "}
            </p>
          ),
        },
      ],
    },
  ];

  const rowClick = (e) => {
    setSingleSignDetails(e);
  };

  const handleSignMarked = (values) => {
    const {
      id,
      address,
      price,
      company_name,
      date,
      status,
    } = singleSignDetails;

    let obj = {
      id: id,
      purchase_date: values?.date,
      sign_added_date:values?.price,
    };

    if (ZONE_TYPE == 1) {
      obj.status = 2;
    }
    else {
      obj.status = 4;
    }

    let formData = convertFormData(obj);
    formData.append("_method", "PATCH");
    updateProspect(obj?.id, formData);
    updateSignTable({ zone: formValue?.id });
    setShowMarkSign(false);
  };
  return (
    <Spin spinning={addSignLoading} tip={"Loading..."}>
      <Fragment>
        {showMarkSign && (
          <DefaultModal
            size="lg"
            show={showMarkSign}
            onHide={() => setShowMarkSign(false)}
          >
            <MarkSignPopUp
              handleSignMarked={handleSignMarked}
              setShowMarkSign={setShowMarkSign}
              closeModal={() => setShowMarkSign(false)}
              values={singleSignDetails}
              ZONE_TYPE={ZONE_TYPE}
            />
          </DefaultModal>
        )}
        <div className="d-flex flex-wrap justify-content-between mb-3">
          <div>
            <h6 className="title pb-0">Signs</h6>
          </div>
          <div className="d-flex">
            <div className="">
              <MaterialSelect
                value={filterCompanies}
                otherValue={[{ name: "All Companies", id: "all" }]}
                onChange={(e) => setFilterCompanies(e)}
                options={companiesOptions}
              />
            </div>

            <div className="pl-2">
              <MaterialSelect
                value={filterSigns}
                otherValue={[{ name: "All Signs", id: "all" }]}
                onChange={(e) => setFilterSigns(e)}
                options={saleOptions}
              />
            </div>
          </div>
        </div>
        <AccessControl
          allowedPermissions={accessCondition}
          renderNoAccess={() => ""}
        >
          <ThemeHeader
            className="pb-0"
            left={
              <ThemeHeader
                className="pb-0"
                leftClass="flex-wrap"
                left={
                  <Fragment>
                    <div
                      className="col-3 col-sm-2 col-xl-1"
                      style={{ paddingTop: "1rem" }}
                    >
                      <TextField
                        value={unit}
                        type="text"
                        id="unit"
                        className="  form-control "
                        placeholder="Unit"
                        onChange={(e) => setUnit(e.target.value)}
                      />
                    </div>
                    <div
                      className="col-3 col-sm-2 col-xl-1"
                      style={{ paddingTop: "1rem" }}
                    >
                      <TextField
                        value={number}
                        type="text"
                        id="unit_no"
                        className="  form-control "
                        placeholder="No."
                        onChange={(e) => setNumber(e.target.value)}
                      />
                    </div>
                    <div className="col-5 col-sm-4 col-xl-2">
                      <MaterialSelect
                        label="Street"
                        value={street}
                        onChange={(e) => setStreet(e)}
                        options={streetOption}
                        validate={[required]}
                      />
                    </div>

                    <div
                      className="col-4 col-sm-3 col-xl-2"
                      style={{ paddingTop: "1rem" }}
                    >
                      <TextField
                        value={companyName}
                        type="text"
                        id="company"
                        className="  form-control "
                        onChange={(e) => setCompanyName(e.target.value)}
                        placeholder="Company Name"
                      />
                    </div>
                    <div
                      className="col-5 col-sm-4 col-xl-2"
                      style={{ paddingTop: "1rem" }}
                    >
                      <TextInputAmount
                        type="number"
                        value={price}
                        id="price"
                        className="  form-control "
                        onChange={setPrice}
                        allowNegative={false}
                        placeholder="Price"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                      />
                    </div>
                    <div
                      className="col-5 col-sm-3 col-xl-2"
                      style={{ padding: "1rem 0 0 0" }}
                    >
                      <TextField
                        value={date}
                        type="date"
                        placeholder="date"
                        className="  form-control "
                        id="date"
                        onChange={(e) => setDate(e.target.value)}
                        name="date"
                      />
                    </div>
                    <div className="col-4 col-sm-3 col-xl-2">
                      <MaterialSelect
                        label={ZONE_TYPE === 1 ? "For Sale" : "For Rent"}
                        value={sale}
                        onChange={(e) => setSale(e)}
                        options={saleOptions}
                      />
                    </div>
                  </Fragment>
                }
              />
            }
            right={
              <Fragment>
                {formValue?.streets?.length > 0 && (
                  <Fragment>
                    <AccessControl
                      allowedPermissions={["zone_analysis_sales-add"]}
                      renderNoAccess={() => ""}
                    >
                      <ThemeButton
                        onClick={handleAddSign}
                        className="brand-icon-button"
                        icon
                      >
                        {" "}
                        <i className="fas fa-plus-circle"></i>
                      </ThemeButton>
                    </AccessControl>

                    <AccessControl
                      allowedPermissions={["zone_analysis_sales-delete"]}
                      renderNoAccess={() => ""}
                    >
                      <ThemeButton
                        disable={selectedSigns?.length ? false : true}
                        onClick={handleDeleteSigns}
                        className={`darkGrey-icon-button ${selectedSigns?.length ? "" : "pointer-events-none"
                          }`}
                        icon
                      >
                        {" "}
                        <i className="fas fa-minus-circle"></i>
                      </ThemeButton>{" "}
                    </AccessControl>
                  </Fragment>
                )}

                {/* <ThemeButton
              className="darkRed-icon-button"
              icon
              disable={selectedSigns?.length > 1 ? false : true}
              variant="contained"
              onClick={() => setShowMarkSign(true)}
            >
              <i className="fas fa-flag"></i>
            </ThemeButton> */}
              </Fragment>
            }
          />
        </AccessControl>

        <DynamicTableEdit
          isEditable={true}
          handleChange={(e) => setSelectedSigns(e)}
          data={signTableData ?? []}
          handleRowClick={(e) => rowClick(e)}
          update={fetchMoreSign}
          columns={columns}
          showFooter={true}
          height="63"
        />
      </Fragment>
    </Spin>
  );
};

export default (AddSignTable);
