import React, { Fragment, useState, useEffect, useContext } from "react";
import { reduxForm, getFormValues, isDirty } from "redux-form";
import { connect, useSelector } from "react-redux";
import ThemeHeader from "../../../../partials/content/ThemeHeader";
import ThemeButton from "../../../../partials/button/ThemeButton";
import AddNewListingsForm from "./AddNewListingsForm";
import {
  convertFormData,
  getObjWithoutValues,
  postCreateListing,
  getErrorMessage,
  handleQueryChangeAccordingToTitle,
} from "../../../../_helpers/_utils/utils";
import { createNewListing } from "../../_crud/listingsCrud";
import { toast } from "react-toastify";
import * as listingsRedux from "../../_redux/listingsRedux";
import useHasChanged from "../../../../_helpers/_hooks/usePrevious";
import _get from "lodash/get";
import { getMenu } from "../../../../GeneralApi/GeneralApi";
import { Spinner } from "react-bootstrap";
import { SingleListingContext } from '../../partials/singleListingList/SingleListingContextProvider';

const validate = (values) => {
  const errors = {};

  if (!values?.property?.category) {
    errors.property = { category: "Required" };
  }
  if (!values?.ends) {
    errors.ends = "Required";
  }
  //   if (!values.username) {
  //     errors.username = "Required";
  //   } else if (values.username.length > 15) {
  //     errors.username = "Must be 15 characters or less";
  //   }
  //   if (!values.email) {
  //     errors.email = "Required";
  //   } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
  //     errors.email = "Invalid email address";
  //   }
  //   if (!values.age) {
  //     errors.age = "Required";
  //   } else if (isNaN(Number(values.age))) {
  //     errors.age = "Must be a number";
  //   } else if (Number(values.age) < 18) {
  //     errors.age = "Sorry, you must be at least 18 years old";
  //   }
  return errors;
};

const AddNewListingsBody = (props) => {
  const {
    handleSubmit,
    closeModal,
    formValues,
    invalid,
    // pristine,
    submitting,
    contactValues,
  } = props;

  //  Change marketing amount depend on marketing package selection
  const [marketing_packages, setMarketing_packages] = useState([]);
  const [errorPage, setErrorPage] = useState({ page: null, value: false });
  // const [submitting, setSubmitting] = useState(false);

  const marketingPackage_changed = useHasChanged(formValues?.marketing_package);
  const listing_agent_changed = useHasChanged(props.formValues?.listing_agent);
  const listingsTitle = useSelector(
    (state) => state.listings.listingsTableTitle
  );
  const [singleFile, setSingleFile] = useState(null);
  const [sendBulkMail, setsendBulkMail] = useState(false);
  const [sendAgencyEmail, setSendAgencyEmail] = useState(false);
  const { setFormName } = useContext(
    SingleListingContext
  );

  const handleFile = (singleFile) => {
    setSingleFile(singleFile);
  }

  const handleMail = (sendBulkMail) => {
    setsendBulkMail(sendBulkMail);
  }

  useEffect(() => {
    let query = "marketing_package";
    getMenu(query)
      .then(({ data: { data } }) => {
        setMarketing_packages(data?.marketing_package);
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    setFormName("add-new-listing");
  }, []);

  useEffect(() => {
    if (marketingPackage_changed && formValues?.marketing_package) {
      props.change(
        "marketing_cost",
        marketing_packages?.find(
          (marketingPackage) =>
            marketingPackage?.id === formValues?.marketing_package
        )?.amount
      );
    }
    if (listing_agent_changed) {
      props.change("display_agent", props.formValues?.listing_agent);
    }
  });
  // useEffect(() => {
  //   console.log('logging form vlues effect', formValues?.address[0]);

  //   // setListingValues({address: formValues?.address[0]})

  //   if (
  //     formValues?.address[0]?.street_number &&
  //     formValues?.address[0]?.street_name &&
  //     !listingValues
  //   ) {
  //     console.log(formValues?.address[0]);
  //     let queryString = formToQueryString(
  //       processAddressForSearch(formValues?.address[0])
  //     );
  //     getPropertyIdByAddress(queryString)
  //       .then(({ data: { data } }) => {
  //         // console.log(getProcessedAddress(data?.address))
  //         // setInputAddress(getProcessedAddress(data?.address));
  //         // setListingValues({
  //         //   address: getProcessedAddress(data?.address),
  //         //   features: data?.features?.map(
  //         //     ({ feature, feature_menu_option, ...obj }) => ({
  //         //       feature: feature?.id,
  //         //       feature_menu_option: feature_menu_option?.id,
  //         //       ...obj,
  //         //     })
  //         //   )
  //         // });
  //       })
  //       .catch(() => { });
  //   }
  // }, [formValues?.address[0]]);
  // Not finished

  const createListing = async (formatedValues) => {
    if (singleFile) {
      formatedValues.file = singleFile
      formatedValues.files_information = singleFile?.map(file => {
        const { fileContent, ...other } = file;
        return { is_private: 1, agency_agreement_check:1 };
         //formatedValues.append('file', file);
        });
    }
    const formData = convertFormData(getObjWithoutValues([undefined, null], formatedValues));
    return await createNewListing(formData)
      .then(({ data }) => {
        closeModal();
        toast.success("Post Saved Successfully");
        props.listingsLoading(handleQueryChangeAccordingToTitle(listingsTitle));
      })
      .catch((e) => {
        if (e.status !== "404") {
          setErrorPage({ page: 0, value: true });
          getErrorMessage(e).map((message) => toast.error(message));
        } else {
          toast.error("Something Went Wrong");
        }
      });
  };

  const onFormSubmit = async (values) => {
    // setSubmitting(true);
    const { full_address, label, value, ...domain_address } = _get(
      formValues,
      "domain_address",
      {}
    );
    let _data = {
      ...formValues,
      domain_address,
      sendBulkMail,
      sendAgencyEmail
    };
    // console.log('handleAddressOnClick onFormSubmit', _data, postCreateListing(_data))

    if (!values.property && !values.property?.category) {
      toast.error("Property Category field is required");
      setErrorPage({ page: 2, value: true });
    } else {
      let formatedValues = postCreateListing(_data);
      return createListing(formatedValues);
    }
  };

  return (
    <Fragment>
      <form className="contactForm" onSubmit={handleSubmit(onFormSubmit)}>
        <Fragment>
          <ThemeHeader
            left={
              <Fragment>
                <h5 className="title">Create New Listing</h5>
              </Fragment>
            }
            right={
              <Fragment>
                <Fragment>
                  <div className="pl-2">
                    <ThemeButton
                      onClick={() => closeModal()}
                      className="lightGrey-button"
                    >
                      Cancel
                    </ThemeButton>
                  </div>
                  <div className="pl-2">
                    <ThemeButton
                      disabled={invalid || submitting}
                      type="submit"
                      className="green-button"
                    >
                      {submitting ? (
                        <Spinner
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        ""
                      )}
                      Save
                    </ThemeButton>
                  </div>
                </Fragment>
              </Fragment>
            }
          />
        </Fragment>
      </form>
      <div className="row">
        <AddNewListingsForm
          setSendAgencyEmail={setSendAgencyEmail}
          handleMail={handleMail}
          handleFile={handleFile}
          formValues={formValues}
          contactValues={contactValues}
          changeProp={props.change}
          errorPage={errorPage}
          setErrorPage={setErrorPage}
        />
      </div>
    </Fragment>
  );
};

const mapState = (state, ownProps) => {
  const values = getFormValues("add-new-listing")(state);
  const dirty = isDirty("add-new-listing")(state);
  const contactValues = getFormValues("update-contact")(state);
  const editContactValues = getFormValues("edit-contact")(state);

  return {
    formValues: values,
    dirty,
    contactValues: editContactValues?.id ? editContactValues : contactValues,
  }; // Form name must have to be different
};

export default connect(
  mapState,
  listingsRedux.actions
)(
  reduxForm({
    form: "add-new-listing",
    validate,
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
    enableReinitialize: false,
  })(AddNewListingsBody)
);
