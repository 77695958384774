import React from "react";

import { Typography } from "@material-ui/core";

const MicrositeFormLabel = ({ label, disabled }) => (
  <Typography
    variant="h6"
    className="title font-weight-bold"
    color={disabled ? "secondary" : "initial"}
    style={{ opacity: disabled ? 0.6 : 1 }}
  >
    {label}
  </Typography>
);

export default MicrositeFormLabel;
