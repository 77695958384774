import React, {
  useContext,
  useEffect,
  useState,
  useCallback,
  Fragment,
} from "react";
import ImgCrop from "antd-img-crop";
import { Upload, Row, Col, Spin, Modal } from "antd";
import "antd/dist/antd.css";
import _get from "lodash/get";
import _map from "lodash/map";
import _isEmpty from "lodash/isEmpty";

import _includes from "lodash/includes";
import Cropper from "react-easy-crop";
import { createSocialMediaTemplate } from "../../../crud/socialMedailCrud";
import {
  convertFormData,
  errorAlert,
} from "../../../../../_helpers/_utils/utils";
import getCroppedImg from "./cropImage";
import { WindowCardContext } from "../../../pages/WindowCards/WindowCardContextProvider";
import { A3PotraitAuctionWindowOverlays, A3PotraitForSaleWindowOverlays, A3PotraitForleaseWindowOverlays, A3LandscapeForSaleWindowOverlays,A3LandscapeAuctionWindowOverlays ,A3LandscapeForleaseWindowOverlays} from "../../../pages/WindowCards/WindowCardAccordianConstant";

const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};

const libData = [
  {
    url:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/LibraryImages/WOLImageLibrary-1.jpg",
  },
  {
    url:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/LibraryImages/WOLImageLibrary-2.jpg",
  },
  {
    url:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/LibraryImages/WOLImageLibrary-3.jpg",
  },
  {
    url:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/LibraryImages/WOLImageLibrary-4.jpg",
  },
  {
    url:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/LibraryImages/WOLImageLibrary-5.jpg",
  },
  {
    url:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/LibraryImages/WOLImageLibrary-6.jpg",
  },
  {
    url:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/LibraryImages/WOLImageLibrary-7.jpg",
  },
  {
    url:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/LibraryImages/WOLImageLibrary-8.jpg",
  },
  {
    url:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/LibraryImages/WOLImageLibrary-9.jpg",
  },
  {
    url:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/LibraryImages/WOLImageLibrary-10.jpg",
  },
  {
    url:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/LibraryImages/WOLImageLibrary-11.jpg",
  },
  {
    url:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/LibraryImages/WOLImageLibrary-12.jpg",
  },
  {
    url:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/LibraryImages/WOLImageLibrary-13.jpg",
  },
  {
    url:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/LibraryImages/WOLImageLibrary-14.jpg",
  },
  {
    url:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/LibraryImages/WOLImageLibrary-15.jpg",
  },
  {
    url:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/LibraryImages/WOLImageLibrary-16.jpg",
  },
  {
    url:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/LibraryImages/WOLImageLibrary-17.jpg",
  },
  {
    url:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/LibraryImages/WOLImageLibrary-18.jpg",
  },
  {
    url:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/LibraryImages/WOLImageLibrary-19.jpg",
  },
  {
    url:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/LibraryImages/WOLImageLibrary-20.jpg",
  },
  {
    url:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/LibraryImages/WOLImageLibrary-21.jpg",
  },
  {
    url:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/LibraryImages/WOLImageLibrary-22.jpg",
  },
  {
    url:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/LibraryImages/WOLImageLibrary-23.jpg",
  },
  {
    url:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/LibraryImages/WOLImageLibrary-24.jpg",
  },
  {
    url:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/LibraryImages/WOLImageLibrary-25.jpg",
  },
  {
    url:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/LibraryImages/WOLImageLibrary-26.jpg",
  },
  {
    url:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/LibraryImages/WOLImageLibrary-27.jpg",
  },
  {
    url:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/LibraryImages/WOLImageLibrary-28.jpg",
  },
  {
    url:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/LibraryImages/WOLImageLibrary-29.jpg",
  },
  {
    url:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/LibraryImages/WOLImageLibrary-30.jpg",
  },
  {
    url:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/LibraryImages/WOLImageLibrary-31.jpg",
  },
  {
    url:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/LibraryImages/WOLImageLibrary-32.jpg",
  },
  {
    url:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/LibraryImages/WOLImageLibrary-33.jpg",
  },
  {
    url:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/LibraryImages/WOLImageLibrary-34.jpg",
  },
  {
    url:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/LibraryImages/WOLImageLibrary-35.jpg",
  },
  {
    url:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/LibraryImages/WOLImageLibrary-36.jpg",
  },
  {
    url:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/LibraryImages/WOLImageLibrary-37.jpg",
  },
  {
    url:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/LibraryImages/WOLImageLibrary-38.jpg",
  },
  {
    url:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/LibraryImages/WOLImageLibrary-39.jpg",
  },
  {
    url:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/LibraryImages/WOLImageLibrary-40.jpg",
  },
  {
    url:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/LibraryImages/WOLImageLibrary-41.jpg",
  },
  {
    url:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/LibraryImages/WOLImageLibrary-42.jpg",
  },
  {
    url:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/LibraryImages/WOLImageLibrary-43.jpg",
  },
  {
    url:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/LibraryImages/WOLImageLibrary-44.jpg",
  },
  {
    url:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/LibraryImages/WOLImageLibrary-45.jpg",
  },
  {
    url:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/LibraryImages/WOLImageLibrary-46.jpg",
  },
];

export default function WindowCardImageSettings({ selectedNode, formValues }) {
  const {
    libraryDataSource = {},
    selectedSocialTemplate,
    setSelectedSocialTemplate,
    setEnableImageLoading,
  } = useContext(WindowCardContext);
  const [imageUrl, setImageUrl] = useState("");
  const [enableImageData, setEnableImageData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [crop1, setCrop1] = useState({ x: 0, y: 0 });
  const [zoom1, setZoom1] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [changeOverlay, setChangeOverlay] = useState(false);

  let dataLimit = 8;
  const [pages] = useState(libData.length / dataLimit);
  // const [pages] = useState(Math.round(libData.length / dataLimit));
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    let background = _get(selectedNode.firstChild, "style.background", "");
    let url = background.includes("url")
      ? background.match(/"(.*?)"/)?.[1]
      : "";
    setImageUrl(url);
  }, [selectedNode]);

  const handleLibImg = (data) => {
    selectedNode.firstChild.style.background = `url('${data?.url}')`;
    selectedNode.firstChild.style.backgroundSize = "cover";
    selectedNode.firstChild.style.backgroundRepeat = "round";

    // if(changeOverlay){
    //     selectedNode.firstChild.src = ` ${"https://v2-wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/marketing-module/window-cards/thumbnails/overlays/a3-portrait/A3+Portrait+Overlay6.png"}`;
    // }
 
    setImageUrl(data.url);
  };

  function goToNextPage() {
    setCurrentPage((page) => page + 1);
  }

  function goToPreviousPage() {
    setCurrentPage((page) => page - 1);
  }


console.log('========selectedNode.className============================');
console.log(selectedNode.className);
console.log( _includes(selectedNode.className,'landscapeOverlay'));
console.log( selectedNode.childNodes[0].childNodes[0] );
console.log(selectedNode);
console.log('===========selectedNode.className=========================');

  const change = ()=>{
    if(changeOverlay ){
// debugger

        if( _includes(selectedNode.className,'forsale')){
             selectedNode.firstChild.src = ` ${A3PotraitForSaleWindowOverlays[1]}`;
        }else if( _includes(selectedNode.className,'auction')){
             selectedNode.firstChild.src = ` ${A3PotraitAuctionWindowOverlays[1]}`;
        }else if( _includes(selectedNode.className,'forlease')){
             selectedNode.firstChild.src = ` ${A3PotraitForleaseWindowOverlays[1]}`;
        }else if ( _includes(selectedNode.className,'landscapeOverlay') && selectedNode.id != "auctionA3Landscape" && selectedNode.id != "leasedA3Landscape"){
          selectedNode?.childNodes[0]?.childNodes[0]?.src ?  selectedNode.childNodes[0].childNodes[0].src = ` ${A3LandscapeForSaleWindowOverlays[1]}`: selectedNode.firstChild.src = ` ${A3LandscapeForSaleWindowOverlays[1]}`
        }else if ( selectedNode.id == "auctionA3Landscape" && _includes(selectedNode.className,'landscapeOverlay') && selectedNode.id != "leasedA3Landscape" ){
          selectedNode?.childNodes[0]?.childNodes[0]?.src ?  selectedNode.childNodes[0].childNodes[0].src = ` ${A3LandscapeAuctionWindowOverlays[1]}`: selectedNode.firstChild.src = ` ${A3LandscapeAuctionWindowOverlays[1]}`}
          else if ( selectedNode.id != "auctionA3Landscape" && _includes(selectedNode.className,'landscapeOverlay') && selectedNode.id == "leasedA3Landscape" ){
            selectedNode?.childNodes[0]?.childNodes[0]?.src ?  selectedNode.childNodes[0].childNodes[0].src = ` ${A3LandscapeForleaseWindowOverlays[1]}`: selectedNode.firstChild.src = ` ${A3LandscapeForleaseWindowOverlays[1]}`}
    }else{
     if( _includes(selectedNode.className,'forsale')){
        selectedNode.firstChild.src =  ` ${A3PotraitForSaleWindowOverlays[0]}`;
     }else if ( _includes(selectedNode.className,'auction')){
        selectedNode.firstChild.src =  ` ${A3PotraitAuctionWindowOverlays[0]}`;
     }else if ( _includes(selectedNode.className,'forlease')){
        selectedNode.firstChild.src = ` ${A3PotraitForleaseWindowOverlays[0]}`
     }else if ( _includes(selectedNode.className,'landscapeOverlay') && selectedNode.id != "auctionA3Landscape" && selectedNode.id != "leasedA3Landscape"){
      selectedNode?.childNodes[0]?.childNodes[0]?.src ?  selectedNode.childNodes[0].childNodes[0].src = ` ${A3LandscapeForSaleWindowOverlays[0]}`: selectedNode.firstChild.src = ` ${A3LandscapeForSaleWindowOverlays[0]}`
   }else if ( selectedNode.id == "auctionA3Landscape" &&  _includes(selectedNode.className,'landscapeOverlay') && selectedNode.id != "leasedA3Landscape"){
    selectedNode?.childNodes[0]?.childNodes[0]?.src ?  selectedNode.childNodes[0].childNodes[0].src = ` ${A3LandscapeAuctionWindowOverlays[0]}`: selectedNode.firstChild.src = ` ${A3LandscapeAuctionWindowOverlays[0]}`}
    else if ( selectedNode.id != "auctionA3Landscape" && _includes(selectedNode.className,'landscapeOverlay') && selectedNode.id == "leasedA3Landscape" ){
      selectedNode?.childNodes[0]?.childNodes[0]?.src ?  selectedNode.childNodes[0].childNodes[0].src = ` ${A3LandscapeForleaseWindowOverlays[0]}`: selectedNode.firstChild.src = ` ${A3LandscapeForleaseWindowOverlays[0]}`}
    else{
        setChangeOverlay(null)
     }
  }

  }


  useEffect(() => {
    change()
   
  }, [changeOverlay]);

  const toggleOverlay = ()=>{
    setChangeOverlay(!changeOverlay)
   
  }

  const getPaginatedData = () => {
    const startIndex = currentPage * dataLimit - dataLimit;
    const endIndex = startIndex + dataLimit;
    return libData.slice(startIndex, endIndex);
  };

  let getOpenHomes = _includes(
    selectedNode?.childNodes[4]?.previousSibling?.className,
    "open-home-block-1"
  );

  let getTestimonial = _includes(
    selectedNode?.childNodes[4]?.previousSibling?.className,
    "testimonial-block"
  );

  const handleFiles = ({ file }) => {
    if (file.status === "done") {
      // Once the API ready we can update img
      let data = {
        background_image_file: file.originFileObj,
        name: formValues?.name ?? "",
      };
      const formData = convertFormData(data);
      if (selectedSocialTemplate.id) {
        formData.append("_method", "PATCH");
      }
      setLoading(true);
      createSocialMediaTemplate(selectedSocialTemplate.id, formData)
        .then((res) => {
          let imageUrl = _get(
            res,
            `data.data.assets[${res.data.data.assets.length - 1}].url`,
            ""
          );
          if (imageUrl) {
            selectedNode.style.background = `url('${imageUrl}')`;
            selectedNode.style.backgroundSize = "cover";
            selectedNode.style.backgroundRepeat = "round";
            // setImageUrl(imageUrl)
          }
          const { id, name } = res.data.data;
          setSelectedSocialTemplate({
            id,
            name,
            newlyCreated: !selectedSocialTemplate.id,
          }); // newlyCreated - Image uploaded not saved yet as template
        })
        .catch((err) => {
          errorAlert(err?.data);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const showCroppedImage = async () => {
    setEnableImageLoading(true);
    try {
      const cropImage = await getCroppedImg(
        imageUrl,
        croppedAreaPixels,
        rotation
      );
      setEnableImageLoading(false);
      setEnableImageData(false);
      selectedNode.firstChild.style.background = `${cropImage}`;
      setCroppedImage(cropImage);
      setZoom1(1);
    } catch (e) {}
  };
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);
  return (
    <div className="antd-image-upload-with-crop">
      <Spin spinning={loading} tip={"Loading..."}>
        <ImgCrop rotate aspect={1}>
          <Upload
            style={{ width: "100%", height: "100%" }}
            showUploadList={false}
            customRequest={dummyRequest}
            listType="picture-card"
            onChange={handleFiles}
            accept=".jpeg,.jpg,.png"
          >
            <div
              style={{
                backgroundColor: "#C1DAE6",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "200px",
                height: "200px",
              }}
              className={"image-icon-preview"}
            >
              <img
                className={"image-edit"}
                src={
                  imageUrl
                    ? imageUrl
                    : "https://v2-wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/assets/placeholders/Image-Small.jpg"
                }
                alt="image0"
                style={{ objectFit: "cover" }}
              />
              <div className="preview-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1rem"
                  height="1rem"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#ffffff"
                    d="M5 5h-3v-1h3v1zm8 5c-1.654 0-3 1.346-3 3s1.346 3 3 3 3-1.346 3-3-1.346-3-3-3zm11-4v15h-24v-15h5.93c.669 0 1.293-.334 1.664-.891l1.406-2.109h8l1.406 2.109c.371.557.995.891 1.664.891h3.93zm-19 4c0-.552-.447-1-1-1-.553 0-1 .448-1 1s.447 1 1 1c.553 0 1-.448 1-1zm13 3c0-2.761-2.239-5-5-5s-5 2.239-5 5 2.239 5 5 5 5-2.239 5-5z"
                  />
                </svg>
              </div>
            </div>
          </Upload>
        </ImgCrop>
      </Spin>
      <div onClick={() => toggleOverlay()}>
        {changeOverlay ? (
          <span style={{cursor: 'pointer',}}> Change Overlay: <span  style={{color: '#840A2D',}}>Red</span></span>
        ) : (
          <span style={{cursor: 'pointer',}}> Change Overlay: White</span>
        )}
      </div>
      <>
        <h5 className="mt-5">Library Images</h5>
        <Row>
          {_map(libraryDataSource, (item, index) => (
            <Col key={index} className="mx-2 my-1">
              <img
                className="img__wrap"
                src={item.url}
                alt="house1"
                onClick={() => {
                  handleLibImg(item);
                  
                  setEnableImageData(true);
                }}
              />
            </Col>
          ))}
        </Row>
      </>

      {/* <Modal
        mask={false}
        centered={true}
        onOk={() => {
          showCroppedImage();
        }}
        onCancel={() => {
          setEnableImageData(false);
        }}
        closable={false}
        bodyStyle={{ height: "500px", width: "500px" }}
        visible={enableImageData}
      >
        <Fragment>
          <Cropper
            image={imageUrl}
            cropSize={{ width: 450, height: 450 }}
            showGrid={false}
            rotation={rotation}
            crop={crop1}
            zoom={zoom1}
            minZoom={1}
            maxZoom={10}
            aspect={4 / 3}
            onCropChange={setCrop1}
            onCropComplete={onCropComplete}
            onRotationChange={setRotation}
            onZoomChange={setZoom1}
          />
        </Fragment>
      </Modal> */}
    </div>
  );
}
