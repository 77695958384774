import React, {Fragment} from "react";
import {Form} from "react-bootstrap";

const RadioInput = ({
  input,
  type,
  label,
  required,
  classname,
  value,
  onClick,
  ref,
}) => {
  const handleOnclick = (e) => {
    input.onChange(e.target.checked ? 1 : 0);
  };

  // console.log("==========input==========================");
  // console.log(input);
  // console.log("===========input=========================");

  return (
    <Fragment>
      <Form.Check
        className={classname}
        ref={ref}
        onClick={handleOnclick}
        type={type}
        label={label}
        required={required ? required : ""}
        checked={input.value == 1 ? true : false}
      />
    </Fragment>
  );
};

export default RadioInput;
