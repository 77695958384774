import React,{useState, useEffect} from "react";
import {useSelector} from "react-redux"
import ReactDOM from "react-dom";
import Modal from "react-modal";
import {getLeaderDetails, postLeaderRating} from "../../../../GeneralApi/GeneralApi"
import { toast } from "react-toastify";
import {getUserByToken} from "../../../auth/curd/auth.crud"

const customStyles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(54, 53, 53, 0.5)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    minWidth: "20%",
    padding: 0,
    border: 0
  },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");

const options = [
  { value: "", label: "Select..." },
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
  { value: 5, label: "5" },
  { value: 6, label: "6" },
  { value: 7, label: "7" },
  { value: 8, label: "8" },
  { value: 9, label: "9" },
  { value: 10, label: "10" },
];

const LeaderRatingModal = ({ openModal, closeModal, modalIsOpen, setUserDetails, userDetails }) => {
    const user = useSelector((state) => state.auth.user);

    const [ratingRecord, setRatingRecord] = useState([])
    const [leadership, setLeadership] = useState(0)
    const [system_knowledge, setSystem_knowledge] = useState(0)
    const [discipline, setDiscipline] = useState(0)
    const [problem_solving, setProblem_solving] = useState(0)
    const [care, setCare] = useState(0)
    const [isLoading, setIsLoading] = useState(false);
   

 

    const checkempty =()=>{
      if(leadership == 0 || system_knowledge == 0|| discipline == 0 || problem_solving ==0 ||  care==0 ){
        return true
      }else {
        return false
      }
    }

    // console.log('============ratingRecord========================');
    // console.log("problem_solving-------" , userDetails)
    // console.log("problem_solving-------" , ratingRecord)
    // console.log('============ratingRecord========================');

    const update = () => {
        getLeaderDetails(user?.contact?.id)
          .then(({ data: { data } }) => {
            setRatingRecord(data);
          })
          .catch((e) => console.log("error"));

          
      };


      const updateUser =()=>{
        getUserByToken()
        .then(({ data: { data } }) => {
          setUserDetails(data);
        })
        .catch((e) => console.log("error"));
        }; 
       
      
    
      useEffect(() => {
        update();
        updateUser()
      }, []);


const handleSubmit = async()=>{

    let payload= {
     leadership,
       system_knowledge,
       discipline,
       problem_solving,
       care
    }
    setIsLoading(true);
   await  postLeaderRating(ratingRecord[0]?.id, payload)
    .then(( res) => {
        toast.success('Ratings Successfully Added ')
        setIsLoading(false);
        closeModal()
        if(res)updateUser()
    })
    .catch((e) => console.log("error"));
}
    

  

  return (
    <div>
      {/* <button onClick={openModal}>Open Modal</button> */}
      <Modal
        isOpen={modalIsOpen}
        // onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Leadership Rating Board"
      >
        <div
          style={{
            display: "flex",
            margin: '10px, 0',
            padding: 20,
            justifyContent: " center",
            background: "#822533",
            color: "#ffff",
            fontSize: 15,
            fontWeight: 800,
            marginBottom: 25,
                      }}
        >
          Leadership Rating Board
        </div>
        <div
          style={{
            display: "flex",
            marginBottom: 20,
            paddingLeft: 20,
            paddingRight: 20,
            justifyContent: " space-between",
          }}
        >
          <div style={{fontWeight: 600, fontSize: 13, width: "50%"}}>
            {" "}
            <label for="leader-select">Rate your paired leader</label>
          </div>
          <div style={{fontWeight: 700, fontSize: 13, display:'flex', width: "50%", justifyContent: 'flex-end'}}>
         Rating
          </div>
        </div>
        <div
          style={{
            display: "flex",
            marginBottom: 15,
            justifyContent: " space-between",
            paddingLeft: 20,
            paddingRight: 20,
          }}
        >
          <div>
            {" "}
            <label for="leader-select">Leadership:</label>
          </div>
          <div>
            <select name="leader" id="leader-select"
            value={leadership}
            onChange={e => setLeadership(e.target.value)}
            >
              {options.map((option, i) => (
                <option value={option.value} key={i}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            marginBottom: 20,
            justifyContent: " space-between",
            paddingLeft: 20,
            paddingRight: 20,
          }}
        >
          <label for="system-select">System Knowledge:</label>

          <select name="system" id="system-select"
             value={system_knowledge}
            onChange={e => setSystem_knowledge(e.target.value)}
          >
            {options.map((option, i) => (
              <option value={option.value} key={i}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
        <div
          style={{
            display: "flex",
            marginBottom: 20,
            justifyContent: " space-between",
            paddingLeft: 20,
            paddingRight: 20,
          }}
        >
          <label for="discipline-select">Discipline:</label>

          <select name="discipline" id="discipline-select"
          value={discipline}
            onChange={e => setDiscipline(e.target.value)}
          >
            {options.map((option, i) => (
              <option value={option.value} key={i}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
        <div
          style={{
            display: "flex",
            marginBottom: 20,
            justifyContent: " space-between",
            paddingLeft: 20,
            paddingRight: 20,
          }}
        >
          <div>
            <label for="problem-select">Problem Solving:</label>
          </div>
          <div>
            <select name="problem" id="problem-select"
               value={problem_solving}
            onChange={e => setProblem_solving(e.target.value)}
            >
              {options.map((option, i) => (
                <option value={option.value} key={i}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            marginBottom: 20,
            justifyContent: " space-between",
            paddingLeft: 20,
            paddingRight: 20,
          }}
        >
          <label for="care-select">Care:</label>

          <select name="care" id="care-select"
              value={care}
            onChange={e => setCare(e.target.value)}
          >
            {options.map((option, i) => (
              <option value={option.value} key={i}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: " flex-end",
            padding: 10,
            paddingRight: 20,
            backgroundColor: "#3635350d",
          }}
        >
        {/* <div style={{width:'50%', display: 'flex', justifyContent: "space-between"}}> */}
        <button style={{marginRight: "5%", fontWeight: 'bold', color: '#fff', maxHeight: 24, background: "#414042", border: 0, borderRadius: '0.25rem'}} onClick={closeModal}>Cancel</button>
          <button style={{ fontWeight: 'bold', background:(isLoading || checkempty())? '#2bb67366' : '#2bb673', maxHeight: 24, color: '#fff', border: 0, borderRadius: '0.25rem', }} disabled={isLoading || checkempty()}  onClick={handleSubmit}>Save</button>
        {/* </div> */}
         
        </div>
      </Modal>
    </div>
  );
};

export default LeaderRatingModal;





