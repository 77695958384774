import React, { Fragment } from "react";

export default function CellEmail({value}) {
  return (
    <Fragment>
      <div className="d-flex justify-content-between" >
        <a href={`mailto:${value?.value}`} className="pr-1" >
          {value?.value}
        </a>
        {value?.unsubscribe_date ? (
           <i className="fas fa-flag   red-color "></i>
        ) : (
          ""
        )}
      </div>
    </Fragment>
  );
}


