import { TextField } from "@material-ui/core";
import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { Row } from "react-bootstrap";

import ThemeButton from "../../../../../partials/button/ThemeButton";

const MarkSignPopUp = (props) => {
  const {
    closeModal,
    handleSignMarked,
    ZONE_TYPE
  } = props;
  const [datePopup, setDatePopup] = useState("");
  const [pricePopup, setPricePopup] = useState("");


  return (
    <div>
      <Row>
        <Col lg={6}>
          <h5 className="title">Mark Sign as {ZONE_TYPE === 1 ? 'Sold' : 'Rented'}</h5>
        </Col>
        <Col lg={6} className="float-right">
          <div style={{ float: "right" }}>
            {" "}
            <ThemeButton
              onClick={() => closeModal()}
              // {() => {
              //   console.log("ccancel");
              // }}
              className="lightGrey-button"
            >
              Cancel
            </ThemeButton>{" "}
            <ThemeButton
              className="theme-button green-button"
              disabled={!datePopup || !pricePopup}
              onClick={() => {
                handleSignMarked({ date: datePopup, price: pricePopup });
              }}
            >
              Save
            </ThemeButton>
          </div>
        </Col>
      </Row>

      <Row className="pt-5">
        <Col lg={6}>
          <Row>
            <Col lg={3}>Sale Date:</Col>
            <Col lg={9}>
              <TextField
                type="date"
                placeholder="date"
                id="date"
                className="  form-control "
                // value={date}
                onChange={(e) => setDatePopup(e.target.value)}
                name="date"
              />
            </Col>
          </Row>
        </Col>
        <Col lg={6}>
          <Row>
            <Col lg={3}>Sale Price:</Col>
            <Col lg={9}>
              {" "}
              <TextField
                type="text"
                id="unit"
                className="  form-control "
                required
                placeholder="Price"
                onChange={(e) => setPricePopup(e.target.value)}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default (MarkSignPopUp);
