/* eslint-disable no-restricted-imports */
// *https://www.registers.service.gov.uk/registers/country/use-the-api*
import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import * as listingsRedux from "../../../app/modules/listings/_redux/listingsRedux";
import { connect } from "react-redux";
import { Fragment } from "react";

const AutocompleteForStreet = (props) => {
  const {
    options,
    input,
    handleOnchange,
    selectedOption,
    renderOption,
    placeholder,
    handleChange,
    streetValue,
    update = () => { },
    ...custom
  } = props;

  const [value, setValue] = useState(null);

  const handleoptionSelect = (event, values) => {

    setValue(values?.property?.address?.full_address);
    input.onChange(values?.listing ? values?.listing : values);
    selectedOption(values);
  };

  const defaultRenderOption = (option) => (
    <Fragment>
      <span>{option.name}</span>
    </Fragment>
  );

  return (
    <Autocomplete
      multiple
      id="tags-standard"
      options={options}
      onChange={handleChange}
      value={streetValue}
      //   onChange={(event, newValue) => {
      // 	setValue( newValue );
      // 	console.log(newValue)
      //   }}
      getOptionLabel={(option) => option.name}
      renderOption={defaultRenderOption}
      ListboxProps={{
        onScroll: (event) => {
          const listboxNode = event.currentTarget;
          if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
            update();
          }
        }
      }}
      renderInput={(params) => (
        <TextField {...params} variant="standard" label="Street" />
      )}
      {...custom}
    />
  );
};

export default connect(null, listingsRedux.actions)(AutocompleteForStreet);
