import React, { useContext } from 'react'
import AddressSettings from '../../modals/digitalTemplate/SettingsComponents/AddressSettings';
import FeaturesSettings from '../../modals/digitalTemplate/SettingsComponents/FeaturesSettings';
import ImageSettings from '../../modals/digitalTemplate/SettingsComponents/ImageSettings';
import { OpenSoldEditSettings } from '../../modals/digitalTemplate/SettingsComponents/openSoldEditSettings';
import TextSettings from '../../modals/digitalTemplate/SettingsComponents/TextSettings';
import { WindowCardContext } from './WindowCardContextProvider';
import _includes from 'lodash/includes';
import { Card, CardHeader, CardBody, Button } from "reactstrap";
import AgentDetailsSettings from '../../modals/digitalTemplate/SettingsComponents/AgentDetailsSettings';
// import OpenHomeTimeSettings from '../../modals/digitalTemplate/SettingsComponents/OpenHomeDateSettings';
import WindowCardImageSettings from '../../modals/digitalTemplate/SettingsComponents/WindowcardImageSettings';
import OfficeSettings from './EditSection/OfficeSetting';
import OpenHomeSettings from './EditSection/OpenHomesSettings';


export default function WindowCardEditSection({ formValues = {} }) {
  const { selectedNode, setShowEditSection,listingData, auctionData } = useContext(WindowCardContext);


  const getEditContent = () => {
    if (_includes(selectedNode.className, 'image') || _includes(selectedNode.className, 'bg-subImage')) {
      return { component: <WindowCardImageSettings selectedNode={selectedNode} formValues={formValues} />, title: 'Edit Image' }
    } else if (_includes(selectedNode.className, 'address') || _includes(selectedNode.className, 'fulladd') ) {
      return { component: <TextSettings selectedNode={selectedNode} />, title: 'Edit Address' }
    } else if (_includes(selectedNode.className, 'suburb')) {
      return { component: <TextSettings selectedNode={selectedNode} label="Suburb" />, title: 'Edit Suburb' }
    } else if (_includes(selectedNode.className, 'price')) {
      return { component: <TextSettings selectedNode={selectedNode} label="Price" />, title: 'Edit Price' }
    } else if (_includes(selectedNode.className, 'header')) {
      return { component: <TextSettings selectedNode={selectedNode} label="Heading" />, title: 'Edit Heading' }
    } else if (_includes(selectedNode.className, 'description')) {
      return { component: <TextSettings selectedNode={selectedNode} label="Description" />, title: 'Edit Description' }
    } else if (_includes(selectedNode.className, 'agentDetails') || _includes(selectedNode.className, 'co_agent_Details')) {
      return { component: <AgentDetailsSettings selectedNode={selectedNode} label="Agent Details" />, title: 'Edit Agent Details' }
    } else if (_includes(selectedNode.className, 'features')) {
      return { component: <FeaturesSettings selectedNode={selectedNode} />, title: 'Edit Features' }
    } else if (_includes(selectedNode.className, 'officeFooter')) {
      return { component: <OfficeSettings selectedNode={selectedNode} />, title: 'Edit Office' }
    } else if (_includes(selectedNode.className, 'openHomeDate') || _includes(selectedNode.className, 'openHomesBlock')) {
      return { component: <OpenHomeSettings selectedNode={selectedNode} listingData={listingData} showEndtime/>, title: 'Edit Open Homes' , }
    }else if (_includes(selectedNode.className, 'auctionDate') || _includes(selectedNode.className, 'openHomesAuctionBlock')) {
      return { component: <OpenHomeSettings selectedNode={selectedNode} listingData={auctionData}/>, title: 'Edit Auction' }
    }else if (_includes(selectedNode.className, 'disclaimerBlock')) {
      return { component: <TextSettings selectedNode={selectedNode} label="Disclaimer" />, title: 'Edit Disclaimer' }
    } 

    else {
      return { component: <span />, title: '' }
    }
  }

  const handleClose = () => {
    setShowEditSection(false)
  }

  let editSection = getEditContent()
  return (
    <Card className='mt-4 poster-template-edit'>
      <CardHeader className='edit-card-header'>{editSection.title} <Button close onClick={handleClose} /></CardHeader>
      <CardBody>
        {editSection.component}
      </CardBody>
    </Card>
  )

}
