import React, {
  useState,
  useEffect,
  Fragment,
  useCallback,
  useContext,
} from "react";
import {
  getContactById,
  updateContact,
} from "../../../../contacts/crud/contactsCrud";
import {
  convertFormData,
  getObjWithoutValues,
  getProcessedContactInfo,
  postProcessedContactInfo,
  formToQueryString
} from "../../../../../_helpers/_utils/utils";
import TenantContacts from "./TenantContacts";
import LoadingSkeleton from "../../../../../partials/display/skeleton/LoadingSkeleton";
import { Accordion, Card } from "react-bootstrap";
import { IconButton } from "@material-ui/core";
import DefaultModal from "../../../../../partials/modal/DefaultModal";
import AddLinkedContacts from "../../../../contacts/modals/addLinkedContacts/AddLinkedContacts";
import { toast } from "react-toastify";
// import * as tenantsRedux from "../../../_redux/tenantsRedux";
import { updateTenant } from "../../../crud/tenantsCrud";
import { connect } from "react-redux";
import { TenantProfileContext } from "../TenantProfileContextProvider";

const dynamicGetContact = (id) => {
  return getContactById(id);
};

const TenantContactsWrap = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [additionalContact, setAdditionalContact] = useState(null);
  const [showContactModal, setShowContactModal] = useState(null);
  const [newContactId, setNewContactId] = useState(null);
  const [tenantsList, setTenantsList] = useState([]);
  const [addNewContact, setAddNewContact] = useState(false);

  const {
    profile,
    selectedTenantsId,
    tenants,
    tenantSelectedChangeId,
    continuousMode,
    setContinuousMode,
    tenantsTableUpdate,
    update,
    filters
  } = useContext(TenantProfileContext);

  const handleOnSelect = (e) => {
    // let selectedValue = e.map( s => ({label:s.name, value: s.id}) )
    setAdditionalContact(e);
  };

  const [data, setData] = useState(null);

  useEffect(() => {
    // console.log(selectedTenantsId, profile, "context_api");
    setAdditionalContact(null);
  }, [selectedTenantsId]);

  useEffect(() => {
    let contact_list = [];
    let raw_tenants_list = [];
    setIsLoading(true);
    const { contacts, ...other } = profile ? profile : {};
    const promises = [];
    // promises.push(dynamicGetContact(profile?.contact.id));

    if (contacts?.length > 0) {
      contacts.map((item) => {
        promises.push(dynamicGetContact(item.id));
      });
    }

    if (additionalContact?.length > 0) {
      additionalContact.map((item) => {
        promises.push(dynamicGetContact(item.id));
      });
    }

    Promise.all(promises)
      .then((res) => {
        res.map((item) => {
          contact_list.push(getProcessedContactInfo(item.data.data));
          raw_tenants_list.push(item.data.data);
        });
        setIsLoading(false);
      })
      .then(() => {
        setTenantsList(raw_tenants_list);
        // console.log(contact_list, "contact_list");
      });
    contact_list.map((obj, index) => {
      obj.isOpen = false;
      obj.actionType = "View";
    });
    setData(contacts?.map((contact) => getProcessedContactInfo(contact)));
  }, [profile?.contacts, additionalContact]);

  const onFormSubmit = async (values) => {
    const contactsId = newContactId
      ? [...values.contacts.map((item) => item.id), newContactId]
      : values.contacts.map((item) => item.id);
    if (!addNewContact) {
      await Promise.all(
        values.contacts.map((item) => {
          // let linked_contacts = item.linked_contacts;

          // console.log(item);
          // console.log(linked_contacts);
          // if (item.id == main_contact.id) {
          //   item.linked_contacts.push(
          //     ..._linked_contact.map((obj) => ({ id: obj.id, relationship: "" }))
          //   );
          // }
          // console.log(
          //   _linked_contact.map((obj) => ({ id: obj.id, relationship: "" }))
          // );
          // console.log(linked_contacts);

          let formatedValue = postProcessedContactInfo(item);

          const formData = convertFormData(
            getObjWithoutValues([undefined, null], formatedValue)
          );

          formData.append("_method", "PATCH");
          updateContact(item.id, formData)
            .then(({ data }) => {
              // console.log(data);
              update();
              toast.success("Post Updated Successfully");
            })
            .then(({ data }) => {})
            .catch((e) => {});
        })
      );
    } else {
      setAddNewContact(false);
    }

    let tenantFormValue = {};
    // tenantFormValue.contacts = contactsId;
    tenantFormValue.tenant_linked_contacts = contactsId.filter((oj) => oj);

    // console.log(tenantFormValue);
    let tenantFormatedData = convertFormData(
      getObjWithoutValues([undefined, null], tenantFormValue)
    );

    tenantFormatedData.append("_method", "PATCH");
    updateTenant(selectedTenantsId, tenantFormatedData)
      .then(({ data }) => {
        // console.log(data);
        toast.success("Tenant Saved Successfully");
        tenantsTableUpdate(formToQueryString(filters));
        update();
      })
      .catch((e) => {
        toast.error("Error while tenant saving");
      });

    // setAdditionalContact(null);
    // tenantsTableUpdate();
    // update();
  };

  return (
    <Fragment>
      {showContactModal ? (
        <DefaultModal
          size="xl"
          centered
          show={showContactModal}
          onHide={() => setShowContactModal(false)}
        >
          <AddLinkedContacts
            closeModal={() => setShowContactModal(false)}
            onSelect={handleOnSelect}
          />
        </DefaultModal>
      ) : (
        ""
      )}
      {isLoading && data ? (
        <LoadingSkeleton />
      ) : (
        <TenantContacts
          onSubmit={onFormSubmit}
          profileId={selectedTenantsId}
          data={data}
          viewData={tenantsList}
          setNewContactId={setNewContactId}
          onFormSubmit={onFormSubmit}
          setAddNewContact={setAddNewContact}
        />
      )}
      {/* <Accordion>
        <Card>
          <Card.Header
            onClick={() => setShowContactModal(true)}
            className="d-flex justify-content-between pl-3 pr-3"
          >
            <h5 className="title">Additional Contact</h5>
            <div className="d-flex">
              <IconButton size="small">
                <i className="fas fa-plus-circle"></i>
              </IconButton>
            </div>
          </Card.Header>
        </Card>
      </Accordion> */}
    </Fragment>
  );
};

export default TenantContactsWrap;
