export function getContactsData(searchValue) {
console.log(searchValue);
    const contacts = [
        {
          "id": "1",
          "name": "Hardy Luettgen",
          "avatar": "https://s3.amazonaws.com/uifaces/faces/twitter/mahmoudmetwally/128.jpg",
          "desc": "Steel Refined navigate",
          "contact_number": "1-966-689-8960 x20641",
          "address": "24822 Cole Island"
        },
        {
          "id": "2",
          "name": "Talon Lind",
          "avatar": "https://s3.amazonaws.com/uifaces/faces/twitter/swooshycueb/128.jpg",
          "desc": "teal Associate",
          "contact_number": "1-356-337-9668",
          "address": "3151 Fred Meadow"
        },
        {
          "id": "3",
          "name": "Dr. Florencio Wehner",
          "avatar": "https://s3.amazonaws.com/uifaces/faces/twitter/carlosgavina/128.jpg",
          "desc": "Jewelery",
          "contact_number": "790-459-0609",
          "address": "35824 Carter Crescent"
        },
        {
          "id": "4",
          "name": "Brad Blick",
          "avatar": "https://s3.amazonaws.com/uifaces/faces/twitter/slowspock/128.jpg",
          "desc": "Buckinghamshire whiteboard",
          "contact_number": "1-326-872-0690 x4719",
          "address": "98421 Donavon Cliff"
        },
        {
          "id": "5",
          "name": "Buford Shields",
          "avatar": "https://s3.amazonaws.com/uifaces/faces/twitter/dansowter/128.jpg",
          "desc": "Buckinghamshire Home Loan Account",
          "contact_number": "886.709.8337 x06025",
          "address": "9828 Brielle Pass"
        },
        {
          "id": "6",
          "name": "Karolann Brakus",
          "avatar": "https://s3.amazonaws.com/uifaces/faces/twitter/maximsorokin/128.jpg",
          "desc": "Utah monetize Expanded",
          "contact_number": "566.783.1875",
          "address": "737 Bins Junctions"
        },
        {
          "id": "7",
          "name": "Neal Ward",
          "avatar": "https://s3.amazonaws.com/uifaces/faces/twitter/bermonpainter/128.jpg",
          "desc": "Forward optical Way",
          "contact_number": "1-813-093-4444 x74680",
          "address": "9383 Aufderhar Islands"
        },
        {
          "id": "8",
          "name": "Bobbie Nicolas",
          "avatar": "https://s3.amazonaws.com/uifaces/faces/twitter/VMilescu/128.jpg",
          "desc": "end-to-end",
          "contact_number": "(170) 054-2601 x9062",
          "address": "381 Kertzmann Islands"
        },
        {
          "id": "9",
          "name": "Emerald Cummerata",
          "avatar": "https://s3.amazonaws.com/uifaces/faces/twitter/sergeysafonov/128.jpg",
          "desc": "invoice",
          "contact_number": "(866) 760-2176 x8560",
          "address": "282 Turcotte Dam"
        },
        {
          "id": "10",
          "name": "Candace Leuschke",
          "avatar": "https://s3.amazonaws.com/uifaces/faces/twitter/jonathansimmons/128.jpg",
          "desc": "Multi-channelled",
          "contact_number": "1-710-317-2289",
          "address": "8588 Geovany Causeway"
        },
        {
          "id": "11",
          "name": "Miss Nona Muller",
          "avatar": "https://s3.amazonaws.com/uifaces/faces/twitter/falling_soul/128.jpg",
          "desc": "Legacy Borders Customer",
          "contact_number": "1-648-150-5816 x1716",
          "address": "31866 Yundt Trail"
        },
        {
          "id": "12",
          "name": "Miss Rashawn Bernhard",
          "avatar": "https://s3.amazonaws.com/uifaces/faces/twitter/benefritz/128.jpg",
          "desc": "Gloves Orchestrator Hills",
          "contact_number": "1-250-939-3917",
          "address": "7245 Gabriella Harbors"
        },
        {
          "id": "13",
          "name": "Jabari Gorczany IV",
          "avatar": "https://s3.amazonaws.com/uifaces/faces/twitter/snowwrite/128.jpg",
          "desc": "aggregate Berkshire",
          "contact_number": "082-610-4602",
          "address": "2587 Kuphal Island"
        },
        {
          "id": "14",
          "name": "Dameon Rutherford",
          "avatar": "https://s3.amazonaws.com/uifaces/faces/twitter/danpliego/128.jpg",
          "desc": "Directives",
          "contact_number": "1-261-920-3486 x64705",
          "address": "0631 Aletha Stream"
        },
        {
          "id": "15",
          "name": "Uriel McGlynn",
          "avatar": "https://s3.amazonaws.com/uifaces/faces/twitter/jeremiaha/128.jpg",
          "desc": "Concrete systems",
          "contact_number": "400.517.8860",
          "address": "139 Treutel Island"
        },
        {
          "id": "16",
          "name": "Carey Schuster",
          "avatar": "https://s3.amazonaws.com/uifaces/faces/twitter/joshhemsley/128.jpg",
          "desc": "plum",
          "contact_number": "377.098.7027 x10841",
          "address": "023 Schneider Center"
        }
      ]
    // Authorization head should be fulfilled in interceptor.
    return contacts;
}

export function getListingsData(searchValue) {
console.log(searchValue);
    const listings = [
        {
          "id": "1",
          "title": "0938 Lera Motorway",
          "avatar": "https://s3.amazonaws.com/uifaces/faces/twitter/mactopus/128.jpg",
          "desc": "Engineer Frozen",
          "owner": "Everette Kutch",
          "address": "Apt. 810"
        },
        {
          "id": "2",
          "title": "085 Koelpin Ways",
          "avatar": "https://s3.amazonaws.com/uifaces/faces/twitter/nelshd/128.jpg",
          "desc": "Rustic cohesive auxiliary",
          "owner": "Augustine Schulist",
          "address": "Apt. 440"
        },
        {
          "id": "3",
          "title": "4335 Tobin View",
          "avatar": "https://s3.amazonaws.com/uifaces/faces/twitter/flexrs/128.jpg",
          "desc": "Georgia",
          "owner": "Kayli Homenick",
          "address": "Suite 174"
        },
        {
          "id": "4",
          "title": "30320 Hailey Neck",
          "avatar": "https://s3.amazonaws.com/uifaces/faces/twitter/ralph_lam/128.jpg",
          "desc": "Massachusetts",
          "owner": "Blaze Schumm",
          "address": "Apt. 812"
        },
        {
          "id": "5",
          "title": "1289 Durgan Trail",
          "avatar": "https://s3.amazonaws.com/uifaces/faces/twitter/emmeffess/128.jpg",
          "desc": "programming Home Loan Account Table",
          "owner": "Elliot Conn",
          "address": "Suite 431"
        },
        {
          "id": "6",
          "title": "2729 Adella Villages",
          "avatar": "https://s3.amazonaws.com/uifaces/faces/twitter/coreyweb/128.jpg",
          "desc": "Human Adaptive",
          "owner": "Bryana McKenzie",
          "address": "Apt. 845"
        },
        {
          "id": "7",
          "title": "98495 Gleason Park",
          "avatar": "https://s3.amazonaws.com/uifaces/faces/twitter/beshur/128.jpg",
          "desc": "Timor-Leste Web",
          "owner": "Sebastian Heaney",
          "address": "Apt. 060"
        },
        {
          "id": "8",
          "title": "610 Wolff Manor",
          "avatar": "https://s3.amazonaws.com/uifaces/faces/twitter/yesmeck/128.jpg",
          "desc": "generate",
          "owner": "Ms. Oran Lowe",
          "address": "Apt. 514"
        },
        {
          "id": "9",
          "title": "3882 Luettgen River",
          "avatar": "https://s3.amazonaws.com/uifaces/faces/twitter/dixchen/128.jpg",
          "desc": "payment",
          "owner": "Lera McClure",
          "address": "Suite 126"
        },
        {
          "id": "10",
          "title": "678 Parker Turnpike",
          "avatar": "https://s3.amazonaws.com/uifaces/faces/twitter/geneseleznev/128.jpg",
          "desc": "bifurcated",
          "owner": "Cydney Sawayn",
          "address": "Suite 971"
        },
        {
          "id": "11",
          "title": "131 Denesik Valley",
          "avatar": "https://s3.amazonaws.com/uifaces/faces/twitter/madcampos/128.jpg",
          "desc": "access",
          "owner": "Meghan Jacobi",
          "address": "Suite 882"
        },
        {
          "id": "12",
          "title": "1101 Schoen Roads",
          "avatar": "https://s3.amazonaws.com/uifaces/faces/twitter/matthewkay_/128.jpg",
          "desc": "application background Awesome",
          "owner": "Haleigh Conn",
          "address": "Apt. 646"
        },
        {
          "id": "13",
          "title": "77223 Rubye Brook",
          "avatar": "https://s3.amazonaws.com/uifaces/faces/twitter/sbtransparent/128.jpg",
          "desc": "invoice",
          "owner": "Allie Walsh DVM",
          "address": "Suite 112"
        },
        {
          "id": "14",
          "title": "257 Schowalter Expressway",
          "avatar": "https://s3.amazonaws.com/uifaces/faces/twitter/flashmurphy/128.jpg",
          "desc": "Fully-configurable Buckinghamshire drive",
          "owner": "Brain Schimmel",
          "address": "Suite 938"
        },
        {
          "id": "15",
          "title": "97856 Bechtelar Gateway",
          "avatar": "https://s3.amazonaws.com/uifaces/faces/twitter/n1ght_coder/128.jpg",
          "desc": "Tasty Plastic Keyboard",
          "owner": "Vivianne Block",
          "address": "Apt. 764"
        },
        {
          "id": "16",
          "title": "294 Vladimir Unions",
          "avatar": "https://s3.amazonaws.com/uifaces/faces/twitter/gavr1l0/128.jpg",
          "desc": "JBOD program e-markets",
          "owner": "Ezekiel Breitenberg II",
          "address": "Suite 933"
        }
      ]
    // Authorization head should be fulfilled in interceptor.
    return listings;
}