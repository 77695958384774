import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const SmsCounterTooltip = ({ message }) => (
  <>
    {message?.length ? (
      <OverlayTrigger
        placement="right"
        overlay={<Tooltip><strong>Note</strong>: 1 SMS has a total of 160 characters. Each SMS costs $0.034USD. If exceeding 160 characters, you will be charged for 2 SMS. If exceeding 320 characters, you will be charged for 3 SMS, and so on.</Tooltip>}
      >
        <div
          className={`${
            message.length <= 160
              ? "text-success"
              : message.length > 160 && message.length <= 320
              ? "text-warning"
              : "text-danger"
          } text-right mt-3`}
        >{`${message.length} / 160 = ${Math.ceil(
          message.length / 160
        )} SMS`}</div>
      </OverlayTrigger>
    ) : (
      <div className="text-right text-disabled">0 / 160 = 0</div>
    )}
  </>
);

export default SmsCounterTooltip;
