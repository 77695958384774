/* eslint-disable no-restricted-imports */
import React, { useState } from "react";
import NewListingAddress from "./NewRentalAddress";
import AddNewListingContacts from "./AddNewRentalContacts";
import AddNewListingDetails from "./AddNewRentalDetails";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import ThemeButton from "../../../../partials/button/ThemeButton";
import AddNewListingFeatures from "./AddNewRentalFeatures";
import AddNewListingImages from "./AddNewRentalImages";
import AddNewListingText from "./AddNewRentalText";
import { StepButton } from "@material-ui/core";
// import AddNewListingVideos from "./AddNewListingVideos";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    overflow: "auto",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },

  iconContainer: {
    transform: "scale(2)",
    marginRight: theme.spacing.unit * 5,
  },
}));

function getSteps() {
  return [
    "Address",
    "Contacts",
    "Details",
    "Features",
    "Images",
    // "Videos",
    "Text",
  ];
}

// function getStepContent(stepIndex) {
//   switch (stepIndex) {
//     case 0:
//       return "Select campaign settings...";
//     case 1:
//       return "What is an ad group anyways?";
//     case 2:
//       return "This is the bit I really care about!";
//     default:
//       return "Unknown stepIndex";
//   }
// }

const AddNewListingsForm = ({ formValues, contactValues, changeProp }) => {
  const [page, setPage] = useState(0);
  const [selectedItems, setSelectedItems] = useState([]);

  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const steps = getSteps();

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
        // find the first step that has been completed
        steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setPage(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  const nextPage = () => {
    setPage(page + 1);
  };

  const previousPage = () => {
    setPage(page - 1);
  };

  const onSubmit = (e) => {
  };

  const stepOnClick = (e) => {
    setPage(e);
  };
  return (
    <div className="AddNewListingsForm container-fluid">
      <div className=" pb-5 pt-5 overflow-auto">
        <div className="d-flex">
          <ThemeButton
            disabled={page === 0 ? true : false}
            icon
            onClick={() => previousPage()}
          >
            <i className="fas fa-arrow-left    "></i>
          </ThemeButton>
          <Stepper
            nonLinear
            className={classes.root}
            activeStep={page}
            alternativeLabel
          >
            {steps.map((label, index) => (
              <Step key={label}>
                <StepButton
                  className="stepButton"
                  onClick={() => stepOnClick(index)}
                  completed={completed[index]}
                >
                  {label}
                </StepButton>
              </Step>
            ))}
          </Stepper>
          {/* <Stepper   className={classes.root} activeStep={page} alternativeLabel>
        {steps.map((label, index) => (
          <Step key={label}>
            <StepButton onClick={handleStep(index)}  completed={completed[index]}>
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper> */}
          <ThemeButton
            disabled={page === 5 ? true : false}
            onClick={() => nextPage()}
            icon
          >
            <i className="fas fa-arrow-right    "></i>
          </ThemeButton>
        </div>
      </div>

      <div className="container-fluid">
        {page === 0 && <NewListingAddress onSubmit={nextPage} changeProp={changeProp} {...formValues} />}
        {page === 1 && (
          <AddNewListingContacts
            previousPage={previousPage}
            onSubmit={nextPage}
            contactValues={contactValues}
          />
        )}
        {page === 2 && (
          <AddNewListingDetails
            previousPage={previousPage}
            onSubmit={onSubmit}
            changeProp={changeProp}
            {...formValues}
          />
        )}
        {page === 3 && (
          <AddNewListingFeatures
            previousPage={previousPage}
            onSubmit={onSubmit}
          />
        )}
        {page === 4 && (
          <AddNewListingImages
            previousPage={previousPage}
            onSubmit={onSubmit}
            hanldeSelectedImages={setSelectedItems}
            selectedItems={selectedItems}
          />
        )}
        {/* {page === 5 && (
          <AddNewListingVideos
            previousPage={previousPage}
            onSubmit={onSubmit}
            hanldeSelectedImages={setSelectedItems}
            selectedItems={selectedItems}
          />
        )} */}
        {page === 5 && (
          <AddNewListingText previousPage={previousPage} onSubmit={onSubmit} />
        )}
      </div>
    </div>
  );
};

export default AddNewListingsForm;
