import React from "react";
import ContactProfileContextProvider from "./ContactProfileContextProvider";
import ContactProfileModalBody from "./ContactProfileModalBody";

const ContactProfileModal = (props) => {
  const { contactList, continuousMode, contactId } = props;
  return (
    <div>
      <ContactProfileContextProvider
        continuousMode={continuousMode}
        contactId={contactId}
        contactList={contactList ?? []}
      >
        <ContactProfileModalBody {...props} />
      </ContactProfileContextProvider>
    </div>
  );
};

export default ContactProfileModal;
