import React, { Fragment, useEffect, useRef } from "react";
import NoteForm from "../../../../../partials/form/partials/note/NoteForm";
import { FieldArray, reduxForm, formValueSelector } from "redux-form";
import { useSelector } from 'react-redux';

const validate = (values) => {
  const errors = {};
  console.log(values);
  if (!values.notes || !values.notes.length) {
    errors.notes = { _error: "At least one note must be entered" };
  } else {
    const notesArrayErrors = [];
    values.notes.forEach((member, index) => {
      const noteErrors = {};
      if (!member || !member.detail) {
        noteErrors.detail = "Required";
        notesArrayErrors[index] = noteErrors;
      }
      if (member?.detail?.length < 15) {
        noteErrors.detail = "Note must be 15 characters or less";
        notesArrayErrors[index] = noteErrors;
      }
    });
    if (notesArrayErrors.length) {
      errors.notes = notesArrayErrors;
    }
  }

  console.log(errors);
  return errors;
};

const TenantNotesForm = (props) => {
  const { onSubmit } = props;
  const selector = formValueSelector('tenant-notes');
  const noteFormDataRedux = useSelector(state => selector(state, 'notes'));
  const currentFormDataRef = useRef(null);

  useEffect(() => {
    currentFormDataRef.current = noteFormDataRedux;
  }, [noteFormDataRedux]);

  useEffect(() => {
    return () => {
      onSubmit({notes: currentFormDataRef.current});
    };
  }, []);

  return (
    <Fragment>
      <form className="contactForm" onSubmit={onSubmit}>
        <FieldArray
          defaultValue={{ id: 6, name: "Tenant" }}
          name="notes"
          component={NoteForm}
        />
      </form>
    </Fragment>
  );
};

export default reduxForm({ form: "tenant-notes" })(TenantNotesForm);
