import React from "react";
import Item from "./Item";
import moment from "moment";
import { Tooltip } from "@material-ui/core";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import ToolTipWrap from "./ToolTipWrap";
import { formatPhoneNumber } from "../../_helpers/_utils/utils";

const BasicInfo = ({ profile, dnc }) => (
  <>
    <div className="d-flex justify-content-between">
      <div className="flex-grow-1">
        {profile?.phones?.map(function(item, index) {
          return (
            <Row key={index}>
              <Col lg={4}>
                {item?.value && (item?.type?.name ? item?.type?.name : "Phone")}
              </Col>
              <Col lg={8}>
                {item && (
                  <Tooltip title={formatPhoneNumber(item.value)}>
                    <div className="justify-content-between text-truncate">
                      {dnc ? (
                        <p>
                          <del className="pr-3">
                            {formatPhoneNumber(item.value)}
                          </del>
                          <i className="fas fa-phone-slash brand-color "></i>
                        </p>
                      ) : (
                        <a href={`tel:${item.value}`}>
                          {formatPhoneNumber(item.value)}
                        </a>
                      )}

                      {item.unsubscribe_date && (
                        <i className="fas ml-1 fa-flag brand-color"></i>
                      )}
                    </div>
                  </Tooltip>
                )}
              </Col>
            </Row>
          );
        })}
      </div>
    </div>
    {profile?.emails ? (
      <Row>
        <Col lg={4}>Email</Col>
        <Col lg={8}>
          <>
            {profile?.emails?.map(function(item, index) {
              return (
                <>
                  <ToolTipWrap title={item.value}>
                    <div className="justify-content-between text-truncate">
                      <a href={`mailto:${item.value}`}>{item.value}</a>{" "}
                      {item.unsubscribe_date ? (
                        <i className="fas ml-1 fa-flag brand-color"></i>
                      ) : null}
                    </div>
                  </ToolTipWrap>
                  {/*                     
                    <Tooltip title={item.value}>
                      <div className="justify-content-between text-truncate">
                        <a href={`mailto:${item.value}`}>{item.value}</a>{" "}
                        {item.unsubscribe_date ? (
                          <i className="fas ml-1 fa-flag brand-color"></i>
                        ) : (
                          ""
                        )}
                      </div>
                    </Tooltip> */}

                  {index > 1 ? <br /> : null}
                </>
              );
            })}
          </>
        </Col>
      </Row>
    ) : null}

    {profile?.addresses?.map((item, index) => (
      <Item
        noMargin
        key={index}
        left="Address"
        // left={item?.type?.name}
        right={item.full_address}
      />
    ))}
    {profile?.dob ? (
      <Item
        left="D.O.B"
        right={
          <>
            {moment(profile.dob).format("DD/MM/YYYY")} (
            {moment().diff(profile.dob, "years")} years)
          </>
        }
      />
    ) : null}
  </>
);

export default BasicInfo;
