import React, { Fragment } from "react";
import Item from "./Item";

const ContactInfo = ({ profile }) => {
  return (
    <Fragment>
      <div className="pt-2 pb-2">
        <h6 className="font-weight-bold pb-3">Contact Information</h6>
        {profile?.phones?.map((phone) => (
          <Item
            left={phone?.type?.name ? phone?.type?.name : "Phone"}
            right={phone?.value}
          />
        ))}
        {profile?.emails.map((email) => (
          <Item left="Email" right={email.value} />
        ))}
      </div>
    </Fragment>
  );
};

export default ContactInfo;
