import React, { useState, Fragment, useEffect } from "react";
import { Form, Col, Row } from "react-bootstrap";
import {
  reduxForm,
  Field,
  formValueSelector,
  FormSection,
  FieldArray,
  getFormValues,
} from "redux-form";
import { connect } from "react-redux";

import moment from "moment";
import Item from "../../../../partials/display/Item";
import TextInput from "../../../../partials/form/TextInput";
import MultiSelect from "../../../../partials/form/MultiSelect";
import TextArea from "../../../../partials/form/TextArea";
import ThemeHeader from "../../../../partials/content/ThemeHeader";
import ThemeButton from "../../../../partials/button/ThemeButton";
import RadioInput from "../../../../partials/form/RadioInput";
import { Divider, InputAdornment } from "@material-ui/core";
import { getAllSuburbs, getMenu } from "../../../../GeneralApi/GeneralApi";
import AsyncMultiSelect from "../../../../partials/form/AsyncMultiSelect";
import NestedPhone from "../../../../partials/form/NestedPhone";
import { convertFormData, renameKey } from "../../../../_helpers/_utils/utils";
import { updateReferral } from "../../crud/buyersCrud";
const queryString = require("query-string");

const getOptionSuburbLabel = (option) => (
  <Fragment>
    {option.label ? `${option.label} ${option?.state_name}` : ""}
  </Fragment>
);

const UpdateReferral = (props) => {
  const {
    handleSubmit,
    pristine,
    closeModal,
    submitting,
    updateTable,
    allValues,
    invalid,
    comments,
    prospectProfileType,
    buyerId
  } = props;
  console.log(props);
  const [contact_method, setContact_method] = useState(null);
  const [status, setStatus] = useState(null);

  const getSuburbOptions = (text) => {
    let query = queryString.stringify({ name: text });

    return getAllSuburbs(query)
      .then(({ data }) => {
        console.log(data.data);
        let options = data.data.map((item) => ({
          value: item.id,
          label: item.name,
          state_id: item.state.id,
          state_name: item.state.abbrev,
          postcode: item.postcode,
        }));
        console.log(options);
        return options;
      })
      .catch((e) => {});
  };

  useEffect(() => {
    update();
  }, []);

  const update = () => {
    let query = "contact_method,finance_status";
    getMenu(query)
      .then(({ data: { data } }) => {
        setContact_method(data.contact_method);
        setStatus(data.finance_status)
      })
      .catch(() => {});

  };

  const userOptions = [
    {
      name: "Refer buyer for finance",
      id: "1",
    },
    {
      name: "Do not refer buyer for finance",
      id: "2",
    },
  ];



  const handleSuburbOnClick = (e) => {
    props.initialize({ ...allValues, postcode: e.postcode });
  };

  const onFormSubmit = async (values) => {
    let processedValue = {};
    let { buyer_contact, ...other } = values;
    const {
      email,
      mobile,
      phone,
      emails,
      phones,
      ...other_primary_contact
    } = buyer_contact;
    other.suburb = values.suburb.value;
    other.has_disclosed = values.has_disclosed == false ? 0 : 1;
    other.consent = values.consent == false ? 0 : 1;

    let phoneList = [];
    if (mobile) {
      phoneList.push(mobile[0]);
    }
    if (other?.buyer) {
      delete (other?.buyer)
    }
    if (phone) {
      phoneList.push(phone[0]);
    }
    if (prospectProfileType) {
      other.propsect = buyerId
    } else {
      other.buyer = buyerId
    }
    // if (email) {
    //   emails.push(email[0]);
    // }
    let processedObj = {
      ...other_primary_contact,
      emails: email.map(({ type, ...others }) => ({ type: type?.id, ...others })),
      phones: phoneList.map(({ type, ...others }) => ({ type: type?.id, ...others })),
    }

    if (prospectProfileType) {
      processedValue = {
        prospect_contact: processedObj,
        ...other,
      };
    } else {
      processedValue = {
        buyer_contact: processedObj,
        ...other,
      };
    }
    const formValue = convertFormData(processedValue);
    formValue.append("_method", "PATCH");
  return await   updateReferral(allValues.id, formValue)
      .then(({ data: { data } }) => {

        updateTable();
        closeModal();
      })
      .catch(() => {});
  };
  return (
    <Fragment>
      <div className="buyerFormModal">
        <form
          className="contactForm"
          onSubmit={handleSubmit((values) => onFormSubmit(values))}
        >
          <Fragment>
            <ThemeHeader
              left={
                <Fragment>
                  <h5 className="title">Update Referral</h5>
                </Fragment>
              }
              right={
                <Fragment>
                  <Fragment>
                    <div className="pl-2">
                      <ThemeButton
                        className="lightGrey-button font-weight-bold"
                        onClick={closeModal}
                      >
                        Cancel
                      </ThemeButton>
                    </div>
                    <div className="pl-2">
                      <ThemeButton
                        type="submit"
                        disabled={invalid || pristine || submitting}
                        // onClick={()=>handleAdjustPrice()}
                        className="submit-button"
                      >
                        Save
                      </ThemeButton>
                    </div>
                  </Fragment>
                </Fragment>
              }
            />

            <Form.Row>
              <Col md={4}>
                <Field
                  name="outcome"
                  component={MultiSelect}
                  isMulti={false}
                  showBottom={false}
                  options={userOptions}
                  label="Outcome"
                  placeholder="Please Select Outcome"
                />
              </Col>
              {allValues?.outcome !== "2" ? (
                <Col md={4}>
                  <Field
                    name="status"
                    component={MultiSelect}
                    isMulti={false}
                    showBottom={false}
                    options={status}
                    label="Status"
                    placeholder="Please Select Status"
                  />
                </Col>
              ) : (
                ""
              )}
            </Form.Row>
            {allValues?.outcome !== "2" ? (
              <Fragment>
                
                  <Row className="pb-2">
                    <Col md={4}>
                    <FormSection name="buyer_contact">
                      <Field
                        name="first_name"
                        placeholder="First Name"
                        component={TextInput}
                        label="First Name"
                      /></FormSection>
                    </Col>
                    <Col md={4}><FormSection name="buyer_contact">
                      <Field
                        name="last_name"
                        label="Last Name"
                        component={TextInput}
                      /></FormSection>
                    </Col>

                    <Col md={4}>
                      <Field
                        name="preferred_contact_method"
                        placeholder="Select Contact Method"
                        component={MultiSelect}
                        showBottom={false}
                        isMulti={false}
                        options={contact_method}
                        label="Preferred Method of Contact"
                      />
                    </Col>
                  </Row>
                  <FormSection name="buyer_contact">
                  <Row className="pb-2">
                    <Col md={4}>
                      <FieldArray
                        label="Mobile"
                        name="mobile"
                        type="text"
                        component={NestedPhone}
                        placeholder="Mobile"
                      />
                    </Col>
                    <Col md={4}>
                      <FieldArray
                        label="Phone"
                        name="phone"
                        type="text"
                        component={NestedPhone}
                        placeholder="Phone"
                      />
                    </Col>
                    <Col md={4}>
                      <FieldArray
                        label="Email"
                        name="email"
                        type="email"
                        component={NestedPhone}
                        placeholder="Email"
                      />
                    </Col>
                  </Row>
                </FormSection>
                <Row className="pb-2">
                  <Col md={4}>
                    <Field
                      name="suburb"
                      placeholder="Enter Suburb"
                      component={AsyncMultiSelect}
                      showBottom={false}
                      isMulti={false}
                      getOptionLabel={getOptionSuburbLabel}
                      options={getSuburbOptions}
                      handleOnClick={handleSuburbOnClick}
                      // defaultRef={suburbRef}
                      label="Suburb"
                    />
                  </Col>
                  <Col md={4}>
                    <Field
                      name="postcode"
                      label="Postcode"
                      component={TextInput}
                    />
                  </Col>
                </Row>
                <Divider className="divider" variant="middle" />
                <Row className="pb-2">
                  <Col md={4}>
                    <Field
                      name="amount"
                      label="Approx. Amount Required"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      component={TextInput}
                    />
                  </Col>
                  <Col md={4}>
                    <Field name="bank" label="Bank" component={TextInput} />
                  </Col>
                </Row>
                <Divider className="divider" variant="middle" />
                <Form.Row className="pb-4">
                  <Col md={12}>
                    <Field
                      name="comments"
                      label="Comments"
                      multiple
                      row={4}
                      component={TextArea}
                    />
                  </Col>
                </Form.Row>
                <p className="d-flex pb-4">
                  <span className="pr-1">
                    {" "}
                    <Field
                      name="has_disclosed"
                      l
                      labelRight
                      component={RadioInput}
                    />
                  </span>
                  The referrer has disclosed to the client any commission or
                  associated benefits applicable to this referral.
                </p>
                <p className="d-flex">
                  <span className="pr-1">
                    {" "}
                    <Field name="consent" l labelRight component={RadioInput} />
                  </span>
                  The client have provided the referrer with consent to forward
                  their details to a licensed 3rd party provider.
                </p>
                <Divider
                  className="divider"
                  classes="pb-5 pt-3"
                  variant="middle"
                />
                <div style={{ paddingBottom: "3rem", paddingTop: "3rem" }}>
                  <div
                    className="overflow-auto darkBlue-background pt-3"
                    style={{ maxHeight: "10vh", margin: "-6%", padding: "6%" }}
                  >
                    <Row>
                      {comments ? (
                        <Fragment>
                          {comments.map((item) => (
                            <Fragment>
                              <Col md={12}>
                                <div className="d-flex">
                                  <div className="d-flex flex-column pr-5 pb-4">
                                    <span className="font-weight-bold">
                                      {new Date(
                                        item.created_at
                                      ).toLocaleDateString()}{" "}
                                    </span>
                                    <span className="text-muted">
                                      {moment(item.created_at).format(
                                        "hh:mm A"
                                      )}
                                    </span>
                                  </div>
                                  <div className="d-flex flex-grow-1 ">
                                    <span className="mr-3 font-weight-bold">
                                      {item.created_by?.initial} :
                                    </span>
                                    <span className="mr-3">{item.detail}</span>
                                    {/* {type.} */}
                                  </div>
                                </div>
                              </Col>
                            </Fragment>
                          ))}
                        </Fragment>
                      ) : (
                        ""
                      )}
                    </Row>
                  </div>
                </div>
              </Fragment>
            ) : (
              ""
            )}
          </Fragment>
        </form>
      </div>
    </Fragment>
  );
};

const actions = {
  // socialFeedPost: news.actions.socialFeedPostAsync,
};

const selector = formValueSelector("buyer-update-referral-form");
let suburbValue = {};
const mapState = (state, props) => {
  console.log(props);
  let mobile,
    phone,
    email,
    otherData,
    otherRefData = null;
  const {
    buyer,
    status,
    outcome,
    note,
    referred_by,
    suburb,
    has_disclosed,
    consent,
    preferred_contact_method,
    ...other
  } = props.referral;
  const {
    phones,
    emails,
    ...others
  } = buyer?.primary_contact ? buyer?.primary_contact : {};
  mobile =
    phones?.length > 0
      ? phones.filter((item) => item.type?.id == 1)[0]
        ? [phones.filter((item) => item.type?.id == 1)[0]]
        : [{ type: {id: 1, name: 'Primary'}, value: "" }]
      : [{ type: {id: 1, name: 'Primary'}, value: "" }];
  phone =
    phones?.length > 0
      ? phones.filter((item) => item.type?.id == 2)[0]
        ? [phones.filter((item) => item.type?.id == 2)[0]]
        : [{ type: {id: 2, name: 'Primary'}, value: "" }]
      : [{ type: {id: 2, name: 'Primary'}, value: "" }];
  email =
    emails?.length > 0
      ? emails.filter((item) => item.type?.id == 1)[0]
        ? [emails.filter((item) => item.type?.id == 1)[0]]
        : [{ type: {id: 1, name: 'Primary'}, value: "" }]
      : [{ type:{id: 1, name: 'Primary'}, value: "" }];
  otherData = others;
  otherRefData = other;

  if (suburb) {
    suburbValue.label = suburb.name;

    suburbValue.value = suburb.id;
  }
  const selectedOutcome = selector(props, "outcome");

  return {
    allValues: getFormValues("buyer-update-referral-form")(state),
    outcome: selectedOutcome,
    comments: note,
    initialValues: {
      buyer_contact: {
        mobile: mobile,
        phone: phone,
        email: email,
        phones:
          mobile || phone
            ? phones?.filter((item) => item.id != mobile[0]?.id || item.id != phone[0]?.id )
            : [],
        emails: email
          ? emails?.filter(function(el) {
              return el.id !== email[0]?.id;
            })
          : [],
        
        ...otherData,
      },
      preferred_contact_method: preferred_contact_method?.id,
      suburb: suburbValue,
      has_disclosed: has_disclosed == 0 ? false : true,
      consent: consent == 0 ? false : true,
      postcode: suburb?.postcode,
      status: status?.id,
      outcome: outcome?.id,
      referred_by: referred_by?.id,
      buyer:buyer?.id,
      ...otherRefData,
    },
  };
};
export default connect(
  mapState,
  actions
)(reduxForm({ form: "buyer-update-referral-form" })(UpdateReferral));
