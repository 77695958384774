import React from "react";
import { connect } from "react-redux";
import * as modal from "../_redux/modalRedux";
import { Modal, Button } from "react-bootstrap";
import {
  ListingInfo,
  ListingTab,
  tabInfo,
  tabInfoFormName,
} from "../../../../_metronic/__mocks__/Data";
const tabNameInfoNameDictionary = {
  System_boards: "System_boards",
  Prospects: "prospects",
  Listings: "listings",
  SingleListing: "listings",
  SingleRental: "rentals",
  Settings: "settings",
};
const InfoModal = (props) => {
  const { selectedTab, closeModal, selectedForm, state } = props;
  let singleObj = state?.tab?.tab?.find(
    (el) => el.props.navTitle == selectedTab
  )?.name;
  console.log(
    selectedTab,
    singleObj,
    // tabInfoFormName?.[selectedTab]?.[
    //     state?.[selectedTab]?.selectedSideTab
    //   ],
    tabInfoFormName?.[singleObj],
    state,
    state?.[tabNameInfoNameDictionary?.[singleObj]]?.selectedSideTab,
    tabInfoFormName?.[singleObj]?.[
      state?.[tabNameInfoNameDictionary?.[singleObj]]?.selectedSideTab
    ],
    "selected_modal"
  );
  // console.log("hellpo modal");
  // console.log(
  //   selectedForm,
  //   "selectedForm_",
  //   // tab.find((ta) => selectedForm?.[-1]?.includes(ta)),
  //   // tab.find((ta) => console.log(ta, "selectedForm_")),
  //   selectedForm?.[selectedForm?.length - 1]
  // );
  return (
    // <Modal closeIcon='close' open={true} onClose={closeModal}>
    //   <Modal.Header>Test Modal</Modal.Header>
    //   <Modal.Content>
    //     <Modal.Description>
    //       <p>Test Modal... nothing to see here</p>
    //     </Modal.Description>
    //   </Modal.Content>
    // </Modal>
    <Modal
      show={true}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={closeModal}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {selectedTab}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>
          {/* {ListingTab
            .find((ta) =>
              selectedForm?.[selectedForm?.length - 1]?.includes(ta)
            )
            ?.charAt(1)
            .toUpperCase() +
            ListingTab
              .find((ta) =>
                selectedForm?.[selectedForm?.length - 1]?.includes(ta)
              )
              ?.slice(2)} */}
        </h4>
        <p style={{ fontSize: "1.2rem" }}>
          {selectedTab in tabInfo
            ? tabInfo?.[selectedTab]?.split(".").map((t) => <div>{t}</div>)
            : singleObj
            ? tabInfoFormName?.[singleObj]?.[
                state?.[tabNameInfoNameDictionary?.[singleObj]]?.selectedSideTab
              ]
                ?.split(".")
                .map((t) => <div>{t}</div>)
            : tabInfoFormName?.[selectedTab]?.[
                state?.[tabNameInfoNameDictionary?.[selectedTab]]
                  ?.selectedSideTab
              ]
                ?.split(".")
                .map((t) => <div>{t}</div>)}
          {/* {
            ListingInfo[
              ListingTab.indexOf(
                ListingTab.find((ta) =>
                  selectedForm?.[selectedForm?.length - 1]?.includes(ta)
                )
              )
            ]
          } */}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={closeModal}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  selectedTab: state.tab.selectedTab,
  selectedForm: Object.keys(state.form),
  state: state,
});

export default connect(mapStateToProps, modal.actions)(InfoModal);
