import React, { useEffect, useState } from 'react'
import { TextField } from '@material-ui/core'

function AddressSettings({ selectedNode }) {
  const getTargetElement = targetClassName => {
    return selectedNode.getElementsByClassName(targetClassName)[0]
  }

  console.log('selectedNode', selectedNode, selectedNode.innerHTML, selectedNode.innerText)
  const [streetName, setStreetName] = useState('')
  const [suburbName, setSuburbName] = useState('')

  useEffect(() => {
    setStreetName(getTargetElement('address-line-1').innerText)
    setSuburbName(getTargetElement('address-line-2').innerText)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNode])

  const handleStreetChange = e => {
    setStreetName(e.target.value)
    getTargetElement('address-line-1').innerHTML = e.target.value
  }

  const handleSuburbChange = e => {
    setSuburbName(e.target.value)
    getTargetElement('address-line-2').innerHTML = e.target.value
  }

  return (
    <div>
      <TextField
        type="text"
        label="Street Name"
        fullWidth
        onChange={handleStreetChange}
        value={streetName}
      />
      <TextField
        className='mt-4'
        type="text"
        label="Suburb Name"
        fullWidth
        onChange={handleSuburbChange}
        value={suburbName}
      />
    </div>
  )
}

export default AddressSettings