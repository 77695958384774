import React, { Fragment, useState } from 'react';
import { FieldArray } from "redux-form";

import Address from './Address';
import PropertyFullAddress from './PropertyFullAddress';
import ThemeButton from "../button/ThemeButton";

function PropertyAddressSwitch({
  changeProp,
  name,
  formSectionName,
  placeHolder,
  googleAddressName,
  multiLine,
  defaultOptions,
  setSelectedAddress = () => { },
  ...rest
}) {
  const [addressType, setAddressType] = useState(1) // 0 - Manual, 1 - Autocomplete
  const handleAddressType = () => {
    if (addressType) { // Going to change for Manual, so initialize for manual address
      let fieldKey = formSectionName ? `${formSectionName}.${name}` : name
      changeProp(fieldKey, [{
        type: 1,
        street_unit: "",
        street_name: {},
        street_type:{},
        suburb: {},
        state: {},
      }])
      if (googleAddressName) {
        let googleAddressdFieldKey = formSectionName ? `${formSectionName}.${googleAddressName}` : googleAddressName
        changeProp(googleAddressdFieldKey, {})
      }
    }
    setAddressType(!addressType)
  }
  return (
    <Fragment>
      {
        addressType ?
          <PropertyFullAddress
            setSelectedAddress={setSelectedAddress}
            changeProp={changeProp}
            placeHolder={placeHolder}
            name={googleAddressName ?? name}
            formSectionName={formSectionName}
            multiLine={multiLine}
          // defaultOptions={defaultOptions}
          /> :
          <FieldArray
            name={name}
            component={Address}
            {...rest}
          />
      }
      <div className='mt-2 d-flex justify-content-end'>
        <ThemeButton
          onClick={handleAddressType}
          className="address-alternative-text"
        >
          Try with {addressType ? 'Manual' : 'Google'} Address
        </ThemeButton>
        {/* <div onClick={handleAddressType} className='address-alternative-text'>Try with {addressType ? 'Manual' : 'Google'} Address</div> */}
      </div>
    </Fragment>
  )
}

export default PropertyAddressSwitch;