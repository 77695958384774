import React, { Fragment } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import MemberPopover from "./MemberPopover";
import AnniPopover from "./AnniversaryMemeberPopover";
import { useState } from "react";
import DefaultModal from "../../modal/DefaultModal";
import ContactProfileModal from "../../../modules/contacts/modals/contactProfileModal/ContactProfileModal";

// Pass only id if you want to make a api call for the property preview
// pass data if you don't want to make a api call

const MemberName = (props) => {
  const [showContact, setShowContact] = useState(false);

  const handleClick = () => {
    if (typeof props.iconClickCallBack !== "undefined") {
      props.iconClickCallBack();
    } else {
      setShowContact(true);
    }
  };

  let ref = React.useRef(null);

  return (
    <Fragment>
      {showContact ? (
        <DefaultModal
          size="xl"
          centered
          show={showContact}
          onHide={() => setShowContact(false)}
        >
          <ContactProfileModal
            contactId={props.id}
            contactList={[props.id]}
            closeModal={() => setShowContact(false)}
          />
        </DefaultModal>
      ) : null}
      <div
        className={`d-flex justify-content-between ${
          props?.showContactForm ? "cursor-pointer" : ""
        }`}
      >
        <OverlayTrigger
          trigger={props.trigger}
          placement="auto"
          rootClose
          ref={(r) => (ref = r)}
          container={ref.current}
          overlay={
            <Popover id="popover-basic" onClick={() => ref.handleHide()}>
              {props?.data?.anniversary_date ? (
                <AnniPopover
                  {...props}
                  profileData={props.data}
                  setShowContact={setShowContact}
                />
              ) : (
                <MemberPopover
                  {...props}
                  profileData={props.data}
                  setShowContact={setShowContact}
                />
              )}
            </Popover>
          }
        >
          <div className={"text-ellipsis m-0  pr-2 " + props?.className}>
            {props.children}{" "}
          </div>
        </OverlayTrigger>

        {props?.editable ? (
          <div onClick={handleClick} className="cursor-pointer">
            {" "}
            <i className="fas fa-user-edit"></i>
          </div>
        ) : null}
      </div>
    </Fragment>
  );
};

MemberName.defaultProps = {
  trigger: "click",
  edit_type: "contact",
  editable: false,
};
export default MemberName;
