/* eslint-disable no-restricted-imports */
import React, { useContext, useState, useEffect } from "react";
import _debounce from "lodash/debounce";

// import { getAllMicrosite } from "../../crud/socialMedailCrud";

import TableWithDrawer from "../../../../partials/layout/pageLayout/TableWithDrawer";
import NewMicrosite from "../../modals/microsites/NewMicrosite";
import DrawerForm from "../../partials/microsites/DrawerForm";
import MicrositesTable from "../../partials/microsites/MicrositesTable";
import { MicrositesContext } from "./MicrositesContextProvider";

const Microsites = () => {
  const [open, setOpen] = React.useState(false);
  const {
    // template,
    continuousMode,
    setContinuousMode,
    onSubmitDrawerForm,
    fetchMoreData,
    handleGlobalSearch,
    updateTableData,
    otherResData,
    microsites,
    getMicrosites,
    // listingAppointmentData,
  } = useContext(MicrositesContext);
  const [showAutomatedMail, setShowAutomatedMail] = useState(false);
  const onSearch = _debounce(handleGlobalSearch, 500);

  useEffect(() => {
    getMicrosites();
  }, []);

  return showAutomatedMail ? (
    <NewMicrosite closeModal={() => setShowAutomatedMail(false)} />
  ) : (
    <TableWithDrawer
      handleDrawer={open}
      drawerClosed={() => setOpen(false)}
      table={
        <MicrositesTable
          data={microsites}
          continuousMode={continuousMode}
          setContinuousMode={setContinuousMode}
          onSubmitDrawerForm={onSubmitDrawerForm}
          fetchMoreData={fetchMoreData}
          handleGlobalSearch={onSearch}
          setOpen={setOpen}
          otherResData={otherResData}
          updateTable={updateTableData}
          showAutomatedMail={showAutomatedMail}
          setShowAutomatedMail={setShowAutomatedMail}
        />
      }
      drawer={(resetFilter) => (
        <DrawerForm
          onSubmitDrawerForm={onSubmitDrawerForm}
          resetFilter={resetFilter}
        />
      )}
    />
  );
};

export default Microsites;
