import { getAllListings } from "../../../listings/_crud/listingsCrud";
import { getAllRentals } from "../../../rentals/_crud/rentalsCrud";
import { getAllAppointment } from "../../../dashboard/_curd/dashboardCurd";
import { getAllTestimonials } from "../../../setting/_crud/settingCrud";
import {
  getTemplate1,
  getTemplate2,
  getTemplate3,
  getTemplate4,
  getTemplate5,
  getTemplate6,
  getTemplate7,
  getTemplate8,
  getTemplate9,
} from "./Layouts/LisitngAndRentalLayouts";
import {
  getOpenHomeTemplate1,
  getOpenHomeTemplate2,
  getAuctionTemplate1,
  getAuctionTemplate2,
  getAuctionTemplate3,
  getOpenHomeTemplate3,
  getOpenHomeTemplate4,
} from "./Layouts/OpenHomeAndAuctionLayouts";
import { getTestimonialTemplate } from "./Layouts/TestimonialLayout";
import {
  getAgentTemplate1,
  getAgentTemplate2,
  getAgentTemplate3,
} from "./Layouts/AgentProfileLayout";
import moment from "moment";
import { getAllMembers } from "../../../../GeneralApi/GeneralApi";
import {
  LeasedLayout1,
  LeasedLayout2,
  LeasedLayout3,
  LeasedLayout4,
} from "./Layouts/LeasedLayouts";
import { SoldLayout1 } from "./Layouts/soldLayout";
import {
  ComingSoon1,
  ComingSoon2,
  ComingSoon3,
  ComingSoon4,
} from "./Layouts/ComingSoon";

const forSaleOverlays = {
  1: `https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/For+Sale/ForSale%2BOverlay%2B2A.png`,
  2: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/For+Sale/ForSale%2BOverlay%2B2E.png",
  3: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/For+Sale/ForSale%2BOverlay%2B2I.png",
  4: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/For+Sale/ForSale%2BOverlay%2B2M.png",

  5: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/For+Sale/ForSale%2BOverlay%2B2B.png",
  6: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/For+Sale/ForSale%2BOverlay%2B2F.png",
  7: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/For+Sale/ForSale%2BOverlay%2B2J.png",
  8: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/For+Sale/ForSale%2BOverlay%2B2N.png",

  9: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/For+Sale/ForSale%2BOverlay%2B2C.png",
  10: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/For+Sale/ForSale%2BOverlay%2B2G.png",
  11: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/For+Sale/ForSale%2BOverlay%2B2K.png",
  12: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/For+Sale/ForSale%2BOverlay%2B2O.png",

  13: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/For+Sale/ForSale%2BOverlay%2B2D.png",
  14: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/For+Sale/ForSale%2BOverlay%2B2H.png",
  15: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/For+Sale/ForSale%2BOverlay%2B2L.png",
  16: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/For+Sale/ForSale%2BOverlay%2B2P.png",
};

const soldOverlays = {
  1: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Sold/Sold%2BOverlay2A.png",
  2: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Sold/Sold%2BOverlay2F.png",
  3: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Sold/Sold%2BOverlay2K.png",
  4: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Sold/Sold%2BOverlay2P.png",

  5: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Sold/Sold%2BOverlay2B.png",
  6: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Sold/Sold%2BOverlay2G.png",
  7: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Sold/Sold%2BOverlay2L.png",
  8: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Sold/Sold%2BOverlay2Q.png",

  9: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Sold/Sold%2BOverlay2C.png",
  10: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Sold/Sold%2BOverlay2H.png",
  11: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Sold/Sold%2BOverlay2M.png",
  12: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Sold/Sold%2BOverlay2R.png",

  13: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Sold/Sold%2BOverlay2D.png",
  14: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Sold/Sold%2BOverlay2I.png",
  15: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Sold/Sold%2BOverlay2N.png",
  16: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Sold/Sold%2BOverlay2S.png",

  17: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Sold/Sold%2BOverlay2E.png",
  18: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Sold/Sold%2BOverlay2J.png",
  19: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Sold/Sold%2BOverlay2O.png",
  20: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Sold/Sold%2BOverlay2T.png",
};

const ComingSoonOverlay = {
  1: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Coming+Soon/OVERLAY/A-OVERLAY.png",
  2: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Coming+Soon/OVERLAY/B-OVERLAY.png",
  3: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Coming+Soon/OVERLAY/C-OVERLAY.png",
  4: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Coming+Soon/OVERLAY/D-OVERLAY.png",

  5: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Coming+Soon/OVERLAY/E-OVERLAY.png",
  6: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Coming+Soon/OVERLAY/F-OVERLAY.png",
  7: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Coming+Soon/OVERLAY/G-OVERLAY.png",
  8: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Coming+Soon/OVERLAY/H-OVERLAY.png",

  9: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Coming+Soon/OVERLAY/I-OVERLAY.png",
  10: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Coming+Soon/OVERLAY/J-OVERLAY.png",
  11: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Coming+Soon/OVERLAY/K-OVERLAY.png",
  12: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Coming+Soon/OVERLAY/L-OVERLAY.png",

  13: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Coming+Soon/OVERLAY/M-OVERLAY.png",
  14: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Coming+Soon/OVERLAY/N-OVERLAY.png",
  15: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Coming+Soon/OVERLAY/O-OVERLAY.png",
  16: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Coming+Soon/OVERLAY/P-OVERLAY.png",
};

const forLeaseOverlays = {
  1: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/For+Lease/ForLease%2BOverlay3A.png",
  2: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/For+Lease/ForLease%2BOverlay3E.png",
  3: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/For+Lease/ForLease%2BOverlay3I.png",
  4: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/For+Lease/ForLease%2BOverlay3M.png",

  5: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/For+Lease/ForLease%2BOverlay3B.png",
  6: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/For+Lease/ForLease%2BOverlay3F.png",
  7: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/For+Lease/ForLease%2BOverlay3J.png",
  8: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/For+Lease/ForLease%2BOverlay3N.png",

  9: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/For+Lease/ForLease%2BOverlay3C.png",
  10: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/For+Lease/ForLease%2BOverlay3G.png",
  11: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/For+Lease/ForLease%2BOverlay3K.png",
  12: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/For+Lease/ForLease%2BOverlay3O.png",

  13: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/For+Lease/ForLease%2BOverlay3D.png",
  14: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/For+Lease/ForLease%2BOverlay3H.png",
  15: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/For+Lease/ForLease%2BOverlay3L.png",
  16: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/For+Lease/ForLease%2BOverlay3P.png",
};

const leasedOverlays = {
  1: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Leased/Leased%2BOverlay2A.png",
  2: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Leased/Leased%2BOverlay2Ea.png",
  3: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Leased/Leased%2BOverlay2I.png",
  4: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Leased/Leased%2BOverlay2M.png",
  /* A,E,I,M */
  5: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Leased/Leased%2BOverlay2B.png",
  6: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Leased/Leased%2BOverlay2F.png",
  7: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Leased/Leased%2BOverlay2J.png",
  8: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Leased/Leased%2BOverlay2N.png",
  /* B,F,J,N */

  9: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Leased/Leased%2BOverlay2C.png",
  10: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Leased/Leased%2BOverlay2G.png",
  11: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Leased/Leased%2BOverlay2K.png",
  12: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Leased/Leased%2BOverlay2O.png",
  /* C,G,K,O*/
  13: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Leased/Leased%2BOverlay2D.png",
  14: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Leased/Leased%2BOverlay2H.png",
  15: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Leased/Leased%2BOverlay2L.png",
  16: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Leased/Leased%2BOverlay2P.png",
  /*D,H,L,P*/
};

const openForInspectionOverlays = {
  1: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Open+for+Inspection/OpenforInspection%2BOverlay%2BA.png",
  2: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Open+for+Inspection/OpenforInspection%2BOverlay%2BC.png",
  /* A and C */
  3: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Open+for+Inspection/OpenforInspection%2BOverlay%2BB.png",
  4: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Open+for+Inspection/OpenforInspection%2BOverlay%2BD.png",
  /* B and D */
  5: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Open+for+Inspection/OpenforInspection%2BOverlay%2BE.png",
  6: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Open+for+Inspection/OpenforInspection%2BOverlay%2BG.png",
  /* E and G */
  7: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Open+for+Inspection/OpenforInspection%2BOverlay%2BF.png",
  8: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Open+for+Inspection/OpenforInspection%2BOverlay%2BH.png",
  /* F and H */
};

const auctionOverlays = {
  1: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Auction/Auction%2BOverlay%2BA.png",
  2: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Auction/Auction%2BOverlay%2BB.png",
  3: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Auction/Auction%2BOverlay%2B2C.png",
  4: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Auction/Auction%2BOverlay%2B2D.png",
};

const testimonialOverlays = {
  1: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Testimonials/Testimonials%2BOverlay%2BA.png",
  2: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Testimonials/Testimonials%2BOverlay%2BB.png",
  3: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Testimonials/Testimonials%2BOverlay%2BC.png",
  4: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Testimonials/Testimonials%2BOverlay%2BD.png",
};

const agentProfileOverlays = {
  1: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Agent+Profile/AgentProfile%2BOverlay%2BA.png",
  2: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Agent+Profile/AgentProfile%2BOverlay%2BD.png",
  3: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Agent+Profile/AgentProfile%2BOverlay%2BG.png",
  4: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Agent+Profile/AgentProfile%2BOverlay%2BJ.png",
  /* A,D,G,J */
  5: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Agent+Profile/AgentProfile%2BOverlay%2BB.png",
  6: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Agent+Profile/AgentProfile%2BOverlay%2BE.png",
  7: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Agent+Profile/AgentProfile%2BOverlay%2BH.png",
  8: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Agent+Profile/AgentProfile%2BOverlay%2BK.png",
  /* B,E,H,K */

  9: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Agent+Profile/AgentProfile%2BOverlay%2BC.png",
  10: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Agent+Profile/AgentProfile%2BOverlay%2BF.png",
  11: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Agent+Profile/AgentProfile%2BOverlay%2BI.png",
  12: "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Agent+Profile/AgentProfile%2BOverlay%2BL.png",
  /* C,F,I,L */
};

const forSaleAccordianData = [
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/For+Sale/ForSale%2BA.jpg",
    html: LeasedLayout1(forSaleOverlays[1], null, "#464545"),
  },
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/For+Sale/ForSale%2BE.jpg",
    html: LeasedLayout1(forSaleOverlays[2]),
  },
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/For+Sale/ForSale%2BI.jpg",
    html: LeasedLayout1(forSaleOverlays[3], null, "#464545"),
  },
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/For+Sale/ForSale%2BM.jpg",
    html: LeasedLayout1(forSaleOverlays[4]),
  },
  /* A,E,I,M */
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/For+Sale/ForSale%2BB.jpg",
    html: LeasedLayout2(forSaleOverlays[5], null, "#464545"),
  },
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/For+Sale/ForSale%2BF.jpg",
    html: LeasedLayout2(forSaleOverlays[6]),
  },
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/For+Sale/ForSale%2BJ.jpg",
    html: LeasedLayout2(forSaleOverlays[7], null, "#464545"),
  },
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/For+Sale/ForSale%2BN.jpg",
    html: LeasedLayout2(forSaleOverlays[8]),
  },
  /* B,F,J,N */
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/For+Sale/ForSale%2BC.jpg",
    html: LeasedLayout3(forSaleOverlays[9], null, "#464545"),
  },
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/For+Sale/ForSale%2BG.jpg",
    html: LeasedLayout3(forSaleOverlays[10]),
  },
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/For+Sale/ForSale%2BK.jpg",
    html: LeasedLayout3(forSaleOverlays[11], null, "#464545"),
  },
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/For+Sale/ForSale%2BO.jpg",
    html: LeasedLayout3(forSaleOverlays[12]),
  },
  /* C,G,K,O */
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/For+Sale/ForSale%2BD.jpg",
    html: LeasedLayout4(forSaleOverlays[13], null, "#464545"),
  },
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/For+Sale/ForSale%2BH.jpg",
    html: LeasedLayout4(forSaleOverlays[14]),
  },
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/For+Sale/ForSale%2BL.jpg",
    html: LeasedLayout4(forSaleOverlays[15], null, "#464545"),
  },
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/For+Sale/ForSale%2BP.jpg",
    html: LeasedLayout4(forSaleOverlays[16]),
  },
  /* D,H,L,P */
];

const soldAccordianData = [
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Sold/Sold%2BA.jpg",
    html: SoldLayout1(soldOverlays[1], "#464545"),
  },
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Sold/Sold%2BF.jpg",
    html: SoldLayout1(soldOverlays[2]),
  },
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Sold/Sold%2BK.jpg",
    html: SoldLayout1(soldOverlays[3], "#464545"),
  },
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Sold/Sold%2BP.jpg",
    html: SoldLayout1(soldOverlays[4]),
  },
  /* A,F,K,P */
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Sold/Sold%2BB.jpg",
    html: SoldLayout1(soldOverlays[5], "#464545"),
  },
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Sold/Sold%2BG.jpg",
    html: SoldLayout1(soldOverlays[6]),
  },
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Sold/Sold%2BL.jpg",
    html: SoldLayout1(soldOverlays[7], "#464545"),
  },
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Sold/Sold%2BQ.jpg",
    html: SoldLayout1(soldOverlays[8]),
  },
  /* B,G,L,Q */
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Sold/Sold%2BC.jpg",
    html: SoldLayout1(soldOverlays[9], "#464545"),
  },
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Sold/Sold%2BH.jpg",
    html: SoldLayout1(soldOverlays[10]),
  },
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Sold/Sold%2BM.jpg",
    html: SoldLayout1(soldOverlays[11], "#464545"),
  },
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Sold/Sold%2BR.jpg",
    html: SoldLayout1(soldOverlays[12]),
  },
  /* C,H,M,R */
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Sold/Sold%2BD.jpg",
    html: LeasedLayout4(soldOverlays[13], null, "#464545"),
  },
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Sold/Sold%2BI.jpg",
    html: LeasedLayout4(soldOverlays[14]),
  },
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Sold/Sold%2BN.jpg",
    html: LeasedLayout4(soldOverlays[15], null, "#464545"),
  },
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Sold/Sold%2BS.jpg",
    html: LeasedLayout4(soldOverlays[16]),
  },
  /* D,I,N,S */
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Sold/Sold%2BE.jpg",
    html: LeasedLayout3(soldOverlays[17], null, "#464545"),
  },
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Sold/Sold%2BJ.jpg",
    html: LeasedLayout3(soldOverlays[18]),
  },
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Sold/Sold%2BO.jpg",
    html: LeasedLayout3(soldOverlays[19], null, "#464545"),
  },
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Sold/Sold%2BT.jpg",
    html: LeasedLayout3(soldOverlays[20]),
  },
  /* E,J,O,T */
];
const ComingSoonAccordianData = [
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Coming+Soon/JPG/A.jpg",
    html: ComingSoon1(ComingSoonOverlay[1], "#464545", "#464545"),
  },
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Coming+Soon/JPG/B.jpg",
    html: ComingSoon1(ComingSoonOverlay[2]),
  },
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Coming+Soon/JPG/C.jpg",
    html: ComingSoon1(ComingSoonOverlay[3], "#464545", "#464545"),
  },
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Coming+Soon/JPG/D.jpg",
    html: ComingSoon1(ComingSoonOverlay[4]),
  },
  /* A,F,K,P */
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Coming+Soon/JPG/E.jpg",
    html: ComingSoon2(ComingSoonOverlay[5], "#464545", "#464545"),
  },
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Coming+Soon/JPG/F.jpg",
    html: ComingSoon2(ComingSoonOverlay[6]),
  },
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Coming+Soon/JPG/G.jpg",
    html: ComingSoon2(ComingSoonOverlay[7], "#464545", "#464545"),
  },
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Coming+Soon/JPG/H.jpg",
    html: ComingSoon2(ComingSoonOverlay[8]),
  },
  /* B,G,L,Q */
  // { template: 'https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Coming+Soon/JPG/H.jpg', html: LeasedLayout3(ComingSoonOverlay[9], '#464545') },
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Coming+Soon/JPG/I.jpg",
    html: ComingSoon3(ComingSoonOverlay[9], "#464545", "#464545"),
  },
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Coming+Soon/JPG/J.jpg",
    html: ComingSoon3(ComingSoonOverlay[10], "#464545"),
  },
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Coming+Soon/JPG/K.jpg",
    html: ComingSoon3(ComingSoonOverlay[11]),
  },
  /* C,H,M,R */
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Coming+Soon/JPG/L.jpg",
    html: ComingSoon3(ComingSoonOverlay[12]),
  },
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Coming+Soon/JPG/M.jpg",
    html: ComingSoon4(ComingSoonOverlay[13], "#464545", "#464545"),
  },
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Coming+Soon/JPG/N.jpg",
    html: ComingSoon4(ComingSoonOverlay[14]),
  },
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Coming+Soon/JPG/O.jpg",
    html: ComingSoon4(ComingSoonOverlay[15], "#464545", "#464545"),
  },
  /* D,I,N,S */
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Coming+Soon/JPG/P.jpg",
    html: ComingSoon4(ComingSoonOverlay[16]),
  },

  /* E,J,O,T */
];

const forLeaseAccordianData = [
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/For+Lease/ForLease%2BA.png",
    html: LeasedLayout1(forLeaseOverlays[1], null, "#464545"),
  },
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/For+Lease/ForLease%2BE.png",
    html: LeasedLayout1(forLeaseOverlays[2]),
  },
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/For+Lease/ForLease%2BI.png",
    html: LeasedLayout1(forLeaseOverlays[3], null, "#464545"),
  },
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/For+Lease/ForLease%2BM.png",
    html: LeasedLayout1(forLeaseOverlays[4]),
  },

  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/For+Lease/ForLease%2BB.png",
    html: LeasedLayout2(forLeaseOverlays[5], null, "#464545"),
  },
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/For+Lease/ForLease%2BF.png",
    html: LeasedLayout2(forLeaseOverlays[6]),
  },
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/For+Lease/ForLease%2BJ.png",
    html: LeasedLayout2(forLeaseOverlays[7], null, "#464545"),
  },
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/For+Lease/ForLease%2BN.png",
    html: LeasedLayout2(forLeaseOverlays[8]),
  },

  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/For+Lease/ForLease%2BC.png",
    html: LeasedLayout1(forLeaseOverlays[9], null, "#464545"),
  },
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/For+Lease/ForLease%2BG.png",
    html: LeasedLayout1(forLeaseOverlays[10]),
  },
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/For+Lease/ForLease%2BK.png",
    html: LeasedLayout1(forLeaseOverlays[11], null, "#464545"),
  },
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/For+Lease/ForLease%2BO.png",
    html: LeasedLayout1(forLeaseOverlays[12]),
  },

  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/For+Lease/ForLease%2BD.png",
    html: LeasedLayout4(forLeaseOverlays[13], null, "#464545"),
  },
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/For+Lease/ForLease%2BH.png",
    html: LeasedLayout4(forLeaseOverlays[14]),
  },
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/For+Lease/ForLease%2BL.png",
    html: LeasedLayout4(forLeaseOverlays[15], null, "#464545"),
  },
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/For+Lease/ForLease%2BP.png",
    html: LeasedLayout4(forLeaseOverlays[16]),
  },
];

const leasedAccordianData = [
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Leased/Leased%2BA.png",
    html: LeasedLayout1(leasedOverlays[1], "#464545", "#464545"),
  },
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Leased/Leased%2BE.png",
    html: LeasedLayout1(leasedOverlays[2], "#ffffff", "#ffffff"),
  },
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Leased/Leased%2BI.png",
    html: LeasedLayout1(leasedOverlays[3], "#824433", "#464545"),
  },
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Leased/Leased%2BM.png",
    html: LeasedLayout1(leasedOverlays[4], "#ffffff", "#ffffff"),
  },
  /* A,E,I,M */

  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Leased/Leased%2BB.png",
    html: LeasedLayout2(leasedOverlays[5], "#464545", "#464545"),
  },
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Leased/Leased%2BF.png",
    html: LeasedLayout2(leasedOverlays[6], "#ffffff", "#ffffff"),
  },
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Leased/Leased%2BJ.png",
    html: LeasedLayout2(leasedOverlays[7], "#824433", "#464545"),
  },
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Leased/Leased%2BN.png",
    html: LeasedLayout2(leasedOverlays[8], "#ffffff", "#ffffff"),
  },
  /* B,F,J,N */

  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Leased/Leased%2BC.png",
    html: LeasedLayout3(leasedOverlays[9], "#464545", "#464545"),
  },
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Leased/Leased%2BG.png",
    html: LeasedLayout3(leasedOverlays[10], "#ffffff", "#ffffff"),
  },
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Leased/Leased%2BK.png",
    html: LeasedLayout3(leasedOverlays[11], "#824433", "#464545"),
  },
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Leased/Leased%2BO.png",
    html: LeasedLayout3(leasedOverlays[12], "#ffffff", "#ffffff"),
  },
  /* C,G,K,O*/
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Leased/Leased%2BD.png",
    html: LeasedLayout4(leasedOverlays[13], "#464545", "#464545"),
  },
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Leased/Leased%2BH.png",
    html: LeasedLayout4(leasedOverlays[14], "#ffffff", "#ffffff"),
  },
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Leased/Leased%2BL.png",
    html: LeasedLayout4(leasedOverlays[15], "#824433", "#464545"),
  },
  // { template: 'https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Leased/Leased%2BP.png', html: LeasedLayout4(leasedOverlays[16], '#ffffff', '#ffffff') },
  /* D,H,L,P*/
];

const openForInspectionAccordianData = [
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Open+for+Inspection/OpenforInspection%2BA.jpg",
    html: getOpenHomeTemplate1(openForInspectionOverlays[1], "#FFFFFF"),
  },
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Open+for+Inspection/OpenforInspection%2BC.jpg",
    html: getOpenHomeTemplate1(
      openForInspectionOverlays[2],
      "#000000",
      "#824433"
    ),
  },
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Open+for+Inspection/OpenforInspection%2BB.jpg",
    html: getOpenHomeTemplate2(openForInspectionOverlays[3], "#FFFFFF"),
  },
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Open+for+Inspection/OpenforInspection%2BD.jpg",
    html: getOpenHomeTemplate2(
      openForInspectionOverlays[4],
      "#000000",
      "#824433"
    ),
  },
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Open+for+Inspection/OpenforInspection%2BE.jpg",
    html: getOpenHomeTemplate3(openForInspectionOverlays[5], "#FFFFFF"),
  },
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Open+for+Inspection/OpenforInspection%2BG.jpg",
    html: getOpenHomeTemplate3(
      openForInspectionOverlays[6],
      "#000000",
      "#824433"
    ),
  },
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Open+for+Inspection/OpenforInspection%2BF.jpg",
    html: getOpenHomeTemplate4(openForInspectionOverlays[7]),
  },
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Open+for+Inspection/OpenforInspection%2BH.jpg",
    html: getOpenHomeTemplate4(
      openForInspectionOverlays[8],
      "#000000",
      "#824433"
    ),
  },
];

const auctionsAccordianData = [
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Auction/Auction%2BA.jpg",
    html: getAuctionTemplate1(auctionOverlays[1], "#FFFFFF"),
  },
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Auction/Auction%2BB.jpg",
    html: getAuctionTemplate1(auctionOverlays[2], "#000000", null, "#824433"),
  },
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Auction/Auction%2BC.jpg",
    html: getAuctionTemplate3(auctionOverlays[3], "#FFFFFF", true),
  },
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Auction/Auction%2BD.jpg",
    html: getAuctionTemplate3(auctionOverlays[4], "#000000"),
  },
];

const testimonialsAccordianData = [
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Testimonials/Testimonials%2BA.jpg",
    html: getTestimonialTemplate(
      testimonialOverlays[1],
      "#414143",
      "#E2E3E7",
      true
    ),
  },
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Testimonials/Testimonials%2BB.jpg",
    html: getTestimonialTemplate(testimonialOverlays[2], "#414143", "#E2E3E7"),
  },
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Testimonials/Testimonials%2BC.jpg",
    html: getTestimonialTemplate(
      testimonialOverlays[3],
      "#F5F5F5",
      "#9E6470",
      true
    ),
  },
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Testimonials/Testimonials%2BD.jpg",
    html: getTestimonialTemplate(testimonialOverlays[4], "#F5F5F5", "#9E6470"),
  },
];

const agentProfileAccordianData = [
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Agent+Profile/AgentProfile%2BA.jpeg",
    html: getAgentTemplate1(agentProfileOverlays[1], "#464545"),
  },
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Agent+Profile/AgentProfile%2BD.jpg",
    html: getAgentTemplate1(agentProfileOverlays[2], "#FFFFFF"),
  },
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Agent+Profile/AgentProfile%2BG.jpg",
    html: getAgentTemplate1(agentProfileOverlays[3], "#FFFFFF"),
  },
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Agent+Profile/AgentProfile%2BJ.jpg",
    html: getAgentTemplate1(agentProfileOverlays[4], "#464545"),
  },
  /* A,D,G,J */
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Agent+Profile/AgentProfile%2BB.jpeg",
    html: getAgentTemplate2(agentProfileOverlays[5], "#464545"),
  },
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Agent+Profile/AgentProfile%2BE.jpg",
    html: getAgentTemplate2(agentProfileOverlays[6], "#FFFFFF"),
  },
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Agent+Profile/AgentProfile%2BH.jpg",
    html: getAgentTemplate2(agentProfileOverlays[7], "#FFFFFF"),
  },
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Agent+Profile/AgentProfile%2BK.jpg",
    html: getAgentTemplate2(agentProfileOverlays[8], "#464545"),
  },
  /* B,E,H,K */

  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Agent+Profile/AgentProfile%2BC.jpeg",
    html: getAgentTemplate3(agentProfileOverlays[9], "#464545"),
  },
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Agent+Profile/AgentProfile%2BF.jpg",
    html: getAgentTemplate3(agentProfileOverlays[10], "#FFFFFF"),
  },
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Agent+Profile/AgentProfile%2BI.jpg",
    html: getAgentTemplate3(agentProfileOverlays[11], "#FFFFFF"),
  },
  {
    template:
      "https://wiseberryonline-com-au.s3.ap-southeast-2.amazonaws.com/Marketing/Social+Media/Agent+Profile/AgentProfile%2BL.jpg",
    html: getAgentTemplate3(agentProfileOverlays[12], "#464545"),
  },
  /* C,F,I,L */
];

export const FOR_SALE = 1;
export const SOLD = 2;

export const FOR_LEASE = 3;
export const LEASED = 4;
export const OPEN_FOR_INSPECTION = 5;
export const RENTAL_OPEN_FOR_INSPECTION = 6;
export const AUCTIONS = 7;
export const TESTIMONIALS = 8;
export const AGENT_PROFILES = 9;
export const COMING_SOON = 10;

export const CURRENT_DATE = moment(new Date()).format("YYYY-MM-DD");

export const SectionDetails = {
  [FOR_SALE]: {
    apiInfo: { endPoint: getAllListings, params: { state: 1 } },
    searchPlaceHolder: "Search Properties For Sale",
  },
  [SOLD]: {
    apiInfo: { endPoint: getAllListings, params: { state: 4 } },
    searchPlaceHolder: "Search Sold Properties",
  },
  [FOR_LEASE]: {
    apiInfo: { endPoint: getAllRentals, params: { state: 1 } },
    searchPlaceHolder: "Search Properties For Lease",
  },
  [LEASED]: {
    apiInfo: { endPoint: getAllRentals, params: { status: 2 } },
    searchPlaceHolder: "Search Leased Properties",
  },
  [OPEN_FOR_INSPECTION]: {
    apiInfo: {
      endPoint: getAllAppointment,
      params: {
        type: 5,
        sort_by: "starts",
        direction: "asc",
        from: CURRENT_DATE,
        to: CURRENT_DATE,
      },
    },
    searchPlaceHolder: "Search Appointments",
  },
  [RENTAL_OPEN_FOR_INSPECTION]: {
    apiInfo: {
      endPoint: getAllAppointment,
      params: {
        type: 8,
        sort_by: "starts",
        direction: "asc",
        from: CURRENT_DATE,
        to: CURRENT_DATE,
      },
    },
    searchPlaceHolder: "Search Appointments",
  },
  [AUCTIONS]: {
    apiInfo: {
      endPoint: getAllListings,
      params: {
        state: 1,
        auction_date_from: CURRENT_DATE,
        auction_date_to: CURRENT_DATE,
        sort_by: "auction_date",
        direction: "asc",
      },
    },
    searchPlaceHolder: "Search Properties For Auctions",
  },
  [TESTIMONIALS]: {
    apiInfo: { endPoint: getAllTestimonials, params: {} },
    searchPlaceHolder: "Search Testimonials",
  },
  [AGENT_PROFILES]: {
    apiInfo: { endPoint: getAllMembers, params: { active: 1 } },
    searchPlaceHolder: "Search Agent Profiles",
  },
  [COMING_SOON]: {
    apiInfo: { endPoint: getAllListings, params: { state: 1 } },
    searchPlaceHolder: "Search  Properties",
  },
};

export const AccordianData = [
  { id: FOR_SALE, data: forSaleAccordianData, ...SectionDetails[FOR_SALE] },
  { id: SOLD, data: soldAccordianData, ...SectionDetails[SOLD] },
  { id: FOR_LEASE, data: forLeaseAccordianData, ...SectionDetails[FOR_LEASE] },
  { id: LEASED, data: leasedAccordianData, ...SectionDetails[LEASED] },
  {
    id: OPEN_FOR_INSPECTION,
    data: openForInspectionAccordianData,
    ...SectionDetails[OPEN_FOR_INSPECTION],
  },
  {
    id: RENTAL_OPEN_FOR_INSPECTION,
    data: openForInspectionAccordianData,
    ...SectionDetails[RENTAL_OPEN_FOR_INSPECTION],
  },
  { id: AUCTIONS, data: auctionsAccordianData, ...SectionDetails[AUCTIONS] },
  {
    id: TESTIMONIALS,
    data: testimonialsAccordianData,
    ...SectionDetails[TESTIMONIALS],
  },
  {
    id: AGENT_PROFILES,
    data: agentProfileAccordianData,
    ...SectionDetails[AGENT_PROFILES],
  },
  {
    id: COMING_SOON,
    data: ComingSoonAccordianData,
    ...SectionDetails[COMING_SOON],
  },
];

export const IMAGE_PLACEHOLDER_URL =
  "https://t4.ftcdn.net/jpg/02/07/87/79/360_F_207877921_BtG6ZKAVvtLyc5GWpBNEIlIxsffTtWkv.jpg";

export const FEATURES_ELEMENT = [
  { _className: "bed", _id: 6 },
  { _className: "bath", _id: 5 },
  { _className: "car", _id: 23 },
];
