import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../modules/auth/_redux/auth.duck";
import * as diary from "../modules/diary/_redux/diaryRedux";
import * as modal from "../modules/_modal/_redux/modalRedux";
import * as news from "../modules/news/_redux/newsRedux";
import * as tab from "../modules/_tab/_redux/tabRedux";
import * as contacts from "../modules/contacts/_redux/contactsRedux";
import * as buyers from "../modules/buyers/_redux/buyersRedux";
import * as prospects from "../modules/prospect/_redux/prospectsRedux";
import * as listings from "../modules/listings/_redux/listingsRedux";
import * as rentals from "../modules/rentals/_redux/RentalsRedux";
import * as systemBoards from "../modules/systemBoards/_redux/systemBoardsRedux";
import * as settings from "../modules/setting/_redux/settingsRedux";

import * as tenants from "../modules/tenants/_redux/tenantsRedux";
import { metronic } from "../../_metronic";
import { reducer as formReducer } from "redux-form";

 const combinedReducer = combineReducers({
  auth: auth.reducer,
  i18n: metronic.i18n.reducer,
  builder: metronic.builder.reducer,
  form: formReducer,
  modal: modal.reducer,
  news: news.reducer,
  tab: tab.reducer,
  contacts: contacts.reducer,
  listings: listings.reducer,
  buyers: buyers.reducer,
  prospects: prospects.reducer,
  diary: diary.reducer,
  tenants: tenants.reducer,
  rentals: rentals.reducer,
  settings: settings.reducer,
  System_boards: systemBoards.reducer,
});

// const initialTabsState = {
//   tab: [],
//   newTab: [],
//   selectedTab: [],
// };

export const rootReducer = (state, action) => {
  if (action?.type === '[Logout] Action') { // check for action type 
    state.tab = tab.initialTabsState;
  }
  return combinedReducer(state, action);
};

export function* rootSaga() {
  yield all([
    auth.saga(),
    news.sagaNews(),
    tab.sagaTab(),
    contacts.sagaContacts(),
    listings.sagaListings(),
    buyers.sagaBuyers(),
    prospects.sagaProspects(),
    diary.sagaDiary(),
    tenants.sagaTenants(),
    rentals.sagaRentals(),
  ]);
}
