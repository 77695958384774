import React, { Fragment } from 'react'
import './_mobileView.scss'

const MobileView = ({sms, preview}) => {
    return (
       <Fragment>
           <div >
<div className="iphone">
  <div className="mic">
    <li className="prox"></li>
    <li className="grid"></li>
  </div>
<div className="container">
  <div className="header">
    <div className="not_bar">
      <div className="sim">AirTel</div>
      <div className="wifi"></div>
      <div className="time">1:27 AM</div>
      <div className="bat"></div>
    </div>
    <div className="text_head">
      <div className="back"> <i className="fas fa-arrow-left"></i>
        </div>
          <div>Wiseberry</div>
          <div className="info">i</div>
      </div>
    </div>
    
    <div className="message_area">
    <div><span className="sms_box animated bounceInUp"> {sms?.replace(/&nbsp;/g, '').split('\n').map((item, key) => {
  return <Fragment key={key}>{item}<br/></Fragment>
})}</span>
   
    </div>
    </div>
    
    <div className="text">
      <div className="send_dock">
        <div className="cam_icon"></div>
        <input disabled type="text" id="m_text"/>
     { preview ? null :   <div className="send_button">Send</div>}
      </div>
    </div>
  </div>
  <div className="home"></div>
</div></div>
       </Fragment>
    )
}

export default MobileView
