import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import {
  Box,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
} from "@material-ui/core";

import MicrositeFormLabel from "./MicrositeFormLabel";

const GreenSwitch = withStyles({
  switchBase: {
    "&$checked": {
      color: `${green[500]} !important`,
    },
    "&$checked + $track": {
      backgroundColor: `${green[500]} !important`,
    },
  },
  checked: {},
  track: {},
})(Switch);

const MicrositeToggle = ({
  label,
  name,
  value,
  disabled,
  onChange,
  children,
}) => (
  <Grid container justifyContent="space-between">
    <Grid item>
      <MicrositeFormLabel disabled={disabled} label={label} />
    </Grid>
    <Grid item style={{ paddingTop: !children ? 14 : 0 }}>
      <Box style={{ display: "flex" }}>
        {children}
        <Box>
          <FormGroup style={{ marginTop: !children ? -5 : 0 }}>
            <FormControlLabel
              control={
                <GreenSwitch
                  disabled={disabled}
                  defaultChecked
                  checked={value ? true : false}
                />
              }
              onChange={(e) => onChange(e, "toggle")}
              name={name}
            />
          </FormGroup>
        </Box>
      </Box>
    </Grid>
  </Grid>
);

export default MicrositeToggle;
