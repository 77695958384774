import React, { useContext } from 'react'
import AddNewZoneAnalysisForm from './AddNewZoneAnalysisForm'
import { AddZoneAnalysisContext } from './AddZoneAnalysisContextProvider';

const AddNewZoneAnalysisWrap = (props) => {

    const { initialValues } = useContext(AddZoneAnalysisContext);
    return (
        <div>
            <AddNewZoneAnalysisForm closeModal={props?.closeModal} initialValues={{...initialValues}}/>
        </div>
    )
}

export default AddNewZoneAnalysisWrap
