import { connect } from "react-redux";
// const _ = require("lodash");

const checkPermissions = (userPermissions, allowedPermissions) => {
  if (allowedPermissions.length === 0) {
    return false;
  }
  return userPermissions.some((permission) =>
    allowedPermissions.includes(permission?.name)
  );
};

const AccessControl = ({
  userPermissions,
  allowedPermissions,
  children,
  renderNoAccess,
  is_leader,
  excludeIsLeader = false
}) => {
  const permitted = checkPermissions(userPermissions, allowedPermissions);

  if (permitted || (!excludeIsLeader && is_leader)) {
    return children;
  }
  return renderNoAccess();
};
//   if (permitted ) {
//     return children;
//   }
//   return renderNoAccess();
// };

AccessControl.defaultProps = {
  allowedPermissions: [],
  userPermissions: [],
  renderNoAccess: () => null,
};

function mapStateToProps(state) {
  return {
    userPermissions: state.auth.user && state.auth.user.permissions,
    is_leader: state.auth.user && state.auth.user.is_leader,
  };
}

export default connect(mapStateToProps)(AccessControl);
