import React from "react";
import BuyerProfileContextProvider from "./BuyerProfileContextProvider";
import BuyerModal from "./BuyerModal";

const BuyerProfileModal = (props) => (
  <BuyerProfileContextProvider
    continuousMode={props.continuousMode}
    buyerId={props.buyerId}
    buyerList={props.buyerList}
    filters={props.filters}
  >
    <BuyerModal {...props} />
  </BuyerProfileContextProvider>
);

export default BuyerProfileModal;
