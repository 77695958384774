import React, {Fragment, useRef, useState} from "react";
import {Popover, OverlayTrigger, Card, Col} from "react-bootstrap";
import {Field} from "redux-form";
import {toast} from "react-toastify";
import {EditorState, ContentState, convertFromHTML} from "draft-js";
import {IconButton} from "@material-ui/core";
import TextEditor from "../../../../partials/form/TextEditor";
import ThemeButton from "../../../button/ThemeButton";
import Handle from "../../../component/handle/Handle";
import SimpleTextEditor from "../../../../partials/form/SimpleTextEditor";

import {Editor} from "@tinymce/tinymce-react";
import {Markup} from "interweave";
import RadioInput from "../../RadioInput";
import SortableList from "../../sortableForm/SortableList";
import MediaTags from "../../../popover/mediaTags/MediaTags";
import {useEffect} from "react";

const ListingText = (props) => {
  const {fields, handleSelectedTexts} = props;

  const [selectedTags, setSelectedTags] = useState(null);
  const [currenEditedIndex, setCurrenEditedIndex] = useState(null);
  const [text, setText] = useState("");
  const [textEdit, setTextEdit] = useState("");
  const [newData, setNewData] = useState("");
  const [plugins, setPlugins] = useState(null);
  const [isEditing, setIsEditing] = useState();

  const [editimage_cors_hosts, setEditimageCorsHosts] = useState();
  const [menubar, setMenuBar] = useState();
  const [toolbar, setToolBar] = useState();
  const [colorMap, setColorMap] = useState([]);
  const [tags, setTags] = useState({
    website: 0,
    property_portals: 0,
    is_description: 0,
    is_heading: 0,
    is_link: 0,
    is_virtual_tour: 0,
    youtube_id: 0,
  });
  const selectedTextRef = useRef([]);

  useEffect(() => {
    setEditimageCorsHosts([]);
    setMenuBar("");
    setToolBar(
      "undo redo | alignleft aligncenter alignright alignjustify | lineheight | outdent indent |  bullist | fullscreen  preview"
    );
    setColorMap([
      "822433",
      "Red",
      "95a1ab",
      "Grey",
      "fff",
      "White",
      "000000",
      "black",
    ]);
  }, {});

  const handleEdit = (index) => {
    let data = fields.get(index);
    setIsEditing(null)
    setTextEdit(data?.text);
    setSelectedTags({
      website: data?.website,
      property_portals: data?.property_portals,
      is_description: data?.is_description,
      is_heading: data?.is_heading,
      is_link: data?.is_link,
      is_virtual_tour: data?.is_virtual_tour,
      youtube_id: data?.youtube_id,
    });

    setNewData(data?.text);
    setIsEditing(index)
    // fields.remove(index);
  };

  useEffect(() => {
    setTags({...selectedTags});
  }, [selectedTags]);

  const updateEditedText = () => {
    let text = document.getElementById("textInput").value;
    let value = fields.get(currenEditedIndex);
    fields.remove(currenEditedIndex);
    fields.insert(currenEditedIndex, {...value, text});
    setCurrenEditedIndex(null);
    document.getElementById("textInput").value = "";
  };

  const hanldeEditCancel = () => {
    setCurrenEditedIndex(null);
    document.getElementById("textInput").value = "";
  };

  const handleAddTags = (file) => {
    let values;
    if (selectedTags == null) {
      values = [...document.querySelectorAll("input[id=textTags]:checked")].map(
        (e) => e.value
      );
      setSelectedTags({...selectedTags, values});
    } else {
      values = [...document.querySelectorAll("input[id=textTags]:checked")].map(
        (e) => e.value
      );
      // debugger;
      setSelectedTags({...selectedTags, values});
    }
  };

  const handleAdd = (file) => {
    if (isEditing !== null) {
      fields.remove(isEditing);
    }
    handleOnChange();

    setTags({...selectedTags});
    setTextEdit("");

  };

  // setTags({
  //   website: 0,
  //   property_portals: 0,
  //   is_description: 0,
  //   is_heading: 0,
  //   is_link: 0,
  //   is_virtual_tour: 0,
  //   youtube_id: 0,
  // });
  const handleChangeTags = (e) => {
    let value = fields.get(e);
    if (selectedTags != null) {
      fields.remove(e);
      value.tags = selectedTags;
      fields.insert(e, value);
      // setSelectedTags(null);
    }
  };

  const handleChangeTest = (editor) => {
    setNewData(editor?.target?.getContent());
  };
  const handleOnChange = () => {
    let obj = {
      ...tags,
    };
    obj.text = newData;
    if (obj.text.length < 5) {
      toast.error("The notes length should be at least 5");
      return;
    }
    fields.push(obj);

    setNewData("");
  };

  const TagPopover = (
    <Popover id="popover-contained">
      <Popover.Content>
        <MediaTags tags={tags} setTags={setTags} />
      </Popover.Content>
    </Popover>
  );

  const cleanUpHtml = (html) => {
    const replacements = [
        [/(\n|\r| class=(")?Mso[a-zA-Z]+(")?)/g, ' '],
        [/<!--(.*?)-->/g, ''],
        [/<(\/)*(title|meta|link|span|\\?xml:|st1:|o:|font)(.*?)>/gi, ''],
        [/<style.*?style(.*?)>/gi, ''],
        [/ style="(.*?)"/gi, ''],
        [/<\/?div[^>]*>/g, ''],
        [/ class="[^"]*"/g, ''],
        [/ (lang|xml:lang)="[^"]*"/g, ''],
        [/ data-[^=]*="[^"]*"/g, ''],
        [/ id="[^"]*"/g, ''],
        [/<p>\s*<\/p>/g, ''],
        [/ role="listitem" aria-setsize="-1"/g, ''],
        [/&nbsp;/g, ''],
        [/ role="[^"]*"/g, ''],
        [/\s\s+/g, ' '],
        [/^\n+|\n+$/g, ''],
        [/ align="[^"]*"/g, ''],
        [/<pre>/g, "<p>"], // Add this line to replace <pre> with <p>
        [/<\/pre>/g, "</p>"] // Add this line to replace </pre> with </p>
    ];

    let output = html;
    replacements.forEach(([pattern, replacement]) => {
        output = output.replace(pattern, replacement);
    });

    return output.trim();
}
  const handleChange = (val, index) => {
    const {id} = fields.get(index);
    let finalValues = [...selectedTextRef.current];
    if (val) {
      finalValues = [...finalValues, id.toString()];
    } else {
      finalValues = finalValues.filter((item) => item !== id.toString());
    }
    selectedTextRef.current = finalValues;
    handleSelectedTexts(finalValues);
  };

  const escapeHtmlChars = (unsafe) => {
    return unsafe
      .replaceAll("&", "")
      .replaceAll("<p>", "")
      .replaceAll("</p>", "")
      .replaceAll('"', "")
      .replaceAll("'", "");
  };

  return (
    <Fragment>
      <div className="tableTheme theme-table-striped">
        <div className="form-group d-flex note-form">
          <Col className="p-0 h-100" lg={11} md={11}>
            <Editor
              apiKey="e53jc2n43qe7uy6rpcs41creusckt8s8rbbleq58178peutv"
              onInit={(evt, editor) => (selectedTextRef.current = editor)}
              initialValue={textEdit ?? ""}
              init={{
                selector: "textarea",
                plugins: "lists fullscreen preview paste visualchars",
                menubar: false,
                toolbar:
                  "undo redo | alignleft aligncenter alignright alignjustify | lineheight | outdent indent |  bullist | fullscreen  preview |",
                autosave_interval: "30s",
                autosave_prefix: "{path}{query}-{id}-",
                autosave_restore_when_empty: false,
                autosave_retention: "5m",
                height: 250,
                image_caption: true,
                line_height_formats: "0.5 1 1.2 1.4 1.6 2",
                noneditable_class: "mceNonEditable",
                skin: "oxide",
                content_css: "default",
                visualchars_default_state: false,
                paste_word_valid_elements: "h1,h2,h3,h4,h5,h6,u,p,ol,ul,li,a[href]",
                paste_postprocess: function(plugin, args) {
                  args.node.innerHTML = cleanUpHtml(args.node.innerHTML);
                },
                paste_retain_style_properties: "",
                format_empty_lines: true,
                content_style:
                  "body { font-family:Helvetica,Arial,sans-serif; font-size:16px }" +
                  "p { margin: 0 !important; }",
              }}
              onChange={handleChangeTest}
            />
          </Col>
          {currenEditedIndex !== null ? (
            <Col lg={1} md={1}>
              <ThemeButton
                className="edit-note"
                icon
                onClick={updateEditedText}
              >
                <i className="fas fa-check-circle"></i>
              </ThemeButton>
              <ThemeButton
                className="close-note"
                icon
                onClick={hanldeEditCancel}
              >
                <i className="fas fa-times-circle"></i>
              </ThemeButton>
            </Col>
          ) : (
            <Col lg={1} md={1}>
              <div className="box-icons-text-editor">
                <OverlayTrigger
                  rootClose
                  onExit={(e) => handleAddTags(e)}
                  trigger="click"
                  placement="auto-start"
                  overlay={TagPopover}
                >
                  <ThemeButton className="add-tags no-position" icon>
                    <i className="fas fa-tags "></i>
                  </ThemeButton>
                </OverlayTrigger>
                {/* <Dictaphone handleVoiceToSpeech={handleVoiceToSpeech} /> */}
                <IconButton
                  className="add-note"
                  onClick={(e) => handleAdd(e)}
                  aria-label="delete"
                >
                  <i
                    className={`fas fa-plus-circle ${newData?.replace(
                      /(<([^>]+)>)/gi,
                      ""
                    )?.length > 10 && "submit-icon-button"}`}
                  ></i>
                </IconButton>
              </div>
            </Col>
          )}
        </div>
        <div className="width-box-text">
          <SortableList fields={fields}>
            {fields.map((text, index) => {
              return (
                <Card body className="d-flex hoverEffect">
                  <div className="flex-grow-1 hoverEffectMain">
                    <div
                      className="font-size-tablet-text"
                      dangerouslySetInnerHTML={{__html: fields.get(index).text}}
                    ></div>
                    <div className="w-100 hoverEffectItem">
                      <ThemeButton icon onClick={() => handleEdit(index)}>
                        <i className="fas fa-edit"></i>
                      </ThemeButton>
                      <OverlayTrigger
                        rootClose
                        onExit={(e) => handleAddTags(e)}
                        onExited={() => handleChangeTags(index)}
                        trigger="click"
                        placement="auto-start"
                        overlay={
                          <Popover id="popover-basic">
                            <Popover.Title as="h3">Tags</Popover.Title>
                            <Popover.Content>
                              <Fragment>
                                <div className="d-flex pb-2">
                                  <Field
                                    name={`${text}.website`}
                                    type="checkbox"
                                    component={RadioInput}
                                    value={fields.get(index).website}
                                  />
                                  <div className="pl-3">Website</div>
                                </div>
                                <div className="d-flex pb-2">
                                  <Field
                                    name={`${text}.property_portals`}
                                    type="checkbox"
                                    component={RadioInput}
                                    value={fields.get(index).property_portals}
                                  />
                                  <div className="pl-3">Property Portals</div>
                                </div>
                                <div className="d-flex pb-2">
                                  <Field
                                    name={`${text}.floorplan`}
                                    type="checkbox"
                                    component={RadioInput}
                                  />
                                  <div className="pl-3">Floor Plan</div>
                                </div>

                                <div className="d-flex pb-2">
                                  <Field
                                    name={`${text}.is_heading`}
                                    type="checkbox"
                                    component={RadioInput}
                                  />
                                  <div className="pl-3">Heading</div>
                                </div>

                                <div className="d-flex pb-2">
                                  <Field
                                    name={`${text}.is_description`}
                                    type="checkbox"
                                    component={RadioInput}
                                  />
                                  <div className="pl-3">Description</div>
                                </div>

                                <div className="d-flex pb-2">
                                  <Field
                                    name={`${text}.is_link`}
                                    type="checkbox"
                                    component={RadioInput}
                                  />
                                  <div className="pl-3">Link</div>
                                </div>

                                <div className="d-flex pb-2">
                                  <Field
                                    name={`${text}.is_virtual_tour`}
                                    type="checkbox"
                                    component={RadioInput}
                                  />
                                  <div className="pl-3">Virtual Tour</div>
                                </div>

                                <div className="d-flex pb-2">
                                  <Field
                                    name={`${text}.youtube_id`}
                                    type="checkbox"
                                    component={RadioInput}
                                  />
                                  <div className="pl-3">Youtube ID</div>
                                </div>
                              </Fragment>
                            </Popover.Content>
                          </Popover>
                        }
                      >
                        <ThemeButton className="add-tags" icon>
                          <i className="fas fa-tags    "></i>
                        </ThemeButton>
                      </OverlayTrigger>
                      <ThemeButton icon onClick={() => fields.remove(index)}>
                        <i className="fas fa-trash"></i>
                      </ThemeButton>
                    </div>
                  </div>
                  <div className="rightButton  d-flex m-auto float-right">
                    <Field
                      name={`${text}.selected`}
                      type="checkbox"
                      component={RadioInput}
                      onChange={(e) => handleChange(e, index)}
                    />

                    <Handle />
                  </div>
                </Card>
              );
            })}
          </SortableList>
        </div>
      </div>
    </Fragment>
  );
};

export default ListingText;
