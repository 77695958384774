import React, { Fragment } from "react";
import { Carousel } from "react-bootstrap";

import BasicInfo from "../../../../../partials/display/BasicInfo";
import Preferences from "../../../../../partials/display/Preferences";
import ContactDetails from "../../../../../partials/display/ContactDetails";
import ThemeButton from "../../../../../partials/button/ThemeButton";
import { deleteBuyer } from "../../../crud/buyersCrud";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import * as buyersRedux from "../../../_redux/buyersRedux";
import BasicInfoAva from "../../../../../partials/display/BasicInfoAva";
import AccessControl from "../../../../../_ accessControl/AccessControl";
import Swal from "sweetalert2";

const BuyerProfileSideBar = ({
  dnc,
  profile,
  closeModal,
  queryString,
  buyersLoading,
  updateContext,
}) => {
  const handleDelete = (key) => {
    let value = {};
    value.id = key;
    Swal.fire({
      title: "Warning",
      text: "Are you sure want to delete?",
      icon: "warning",
      showCancelButton: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
      confirmButtonColor: "#A7A9AC",
      cancelButtonColor: "#2BB673",
      confirmButtonText: "Cancel",
      cancelButtonText: "Yes",
    }).then((result) => {
      if (result.isDismissed) {
        deleteBuyer(key)
          .then(({ data: { data } }) => {
            console.log(data);
            toast.success("File Deleted Successfully");
            buyersLoading(queryString);
            closeModal();
          })
          .catch((e) => {});
      }
    });

  };
  console.log(profile);
  return (
    <Fragment>
      <div className="card mb-5">
        <div className="card-body">
          <div className="pl-0">
            <div className="main-profile-overview">
              <div className="d-flex">
                <Carousel
                  className="profile-basicInfo-carousel w-100 pb-3"
                  interval={null}
                  nextIcon={<i className="fas fa-angle-right"></i>}
                  controls={profile?.contacts?.length > 1 ? true : false}
                  indicators={profile?.contacts?.length > 1 ? true : false}
                >
                  {profile?.contacts?.map((item, i) => (
                    <Carousel.Item key={i}>
                      <BasicInfoAva profile={item} />
                      <div className="contact-profileBasicInfo">
                        <BasicInfo dnc={profile?.dnc} profile={item} />
                      </div>
                    </Carousel.Item>
                  ))}
                </Carousel>
              </div>
              <div className="sidebar-preferences pt-3">
                <Preferences
                  profile={profile}
                  updateContext={updateContext}
                  fromBuyer
                />
              </div>
              <div className="linked-contacts pt-3 pb-5">
                <ContactDetails type="Buyer" profile={profile} />
              </div>
              <AccessControl
                allowedPermissions={["buyers-delete"]}
                renderNoAccess={() => ""}
              >
                <ThemeButton
                  onClick={() => handleDelete(profile.id)}
                  className="brand-button w-100"
                >
                  Delete
                </ThemeButton>
              </AccessControl>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default connect(null, buyersRedux.actions)(BuyerProfileSideBar);
