import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import _includes from "lodash/includes";
import { TextField } from "@material-ui/core";
import cuid from "cuid";

import ThemeButton from "../../button/ThemeButton";
import ThemeHeader from "../../content/ThemeHeader";
import MaterialSelect from "../../form-basic/select/MaterialSelect";
import ImagePreview from "./ImagePreview";

import { validateAndResizeImage } from "../../../_helpers/_utils/utils";

const convertEmbed = (url) => {
  var pattern = /(?:http?s?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=)?(.+)/g;
  let embedUrl = url;
  let replacement = "";
  if (pattern.test(url) && !_includes(url, "/embed")) {
    replacement = "https://www.youtube.com/embed/$1";
    embedUrl = url.replace(pattern, replacement);
  }
  return embedUrl;
};

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const DropzoneField = ({
  restrictDefaultStyle = false,
  fields,
  acceptedFileType,
  uploadType,
  bulkAction,
  deletePermissions,
  overWriteStyles,
  downLoadPermissions,
  enableYouTubeLinks = false,
  enableSizeRestriction = false,
  hanldeSelectedImages = () => {},
  isSingle = false,
  isProfileVideo = false,
  selectedItems,
  refData = {},
  // disabled,
  // label,
  // name,
  // width,
  // type,
  // placeholder,
  // meta: { touched, error },
  // input: { onChange ,value},
}) => {
  const [images, setImages] = useState([]);
  const [bulkActions, setBulkActions] = useState("");
  const [youTubeUrl, setYouTubeUrl] = useState("");
  const [url, setUrl] = useState("");
  const [selectedImages, setSelectedImages] = useState([]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: acceptedFileType,
    onDrop: (acceptedFiles) => {
      let targetImages = [];
      acceptedFiles.map(async (file) => {
        const blobFile = await validateAndResizeImage(file);
        const formatedFile = new File([blobFile], file.name);

        targetImages.push(
          Object.assign(formatedFile, {
            preview: URL.createObjectURL(formatedFile),
            id: cuid(),
            isSelected: false,
            title: "",
          })
        );

        fields.push({ fileContent: formatedFile });
      });
      setImages(images.concat(targetImages));
    },
  });

  // const onHanddleDrop = (acceptedFiles) => {
  //   if (isSingle) {
  //     fields.removeAll();
  //   }
  //   acceptedFiles
  //     .map((file, index) =>
  //       Object.assign(file, {
  //         preview: URL.createObjectURL(file),
  //         id: cuid(),
  //         isSelected: false,
  //         title: "",
  //       })
  //     )
  //     .map((file) => fields.push({ fileContent: file }));

  //   let items = acceptedFiles.map((file, index) =>
  //     Object.assign(file, {
  //       preview: URL.createObjectURL(file),
  //       id: cuid(),
  //       isSelected: false,
  //       title: "",
  //     })
  //   );
  //   if (isSingle) {
  //     setImages(items);
  //   } else {
  //     setImages(images.concat(items));
  //   }
  // };

  const handleURL = () => {
    let imageObj = Object.assign(
      {},
      {
        preview: youTubeUrl,
        id: cuid(),
        isSelected: false,
        title: "",
        isUrl: true,
      }
    );
    fields.push({
      fileContent: imageObj,
    });
    setImages((prev) => [...prev, imageObj]);
    setYouTubeUrl("");
    setUrl("");
  };

  const handleChange = (e) => {
    let url = convertEmbed(e.target.value);
    setUrl(e.target.value);
    if (_includes(url, "youtube.")) {
      setYouTubeUrl(url);
    } else {
      setYouTubeUrl("");
    }
  };

  useEffect(() => {
    if (fields.length > 0) {
      setImages(fields.getAll());
    }
  }, []);

  const remove = (file) => {
    const newFiles = [...images]; // make a var for the new array
    newFiles.splice(file, 1); // remove the file from the array
    setImages(newFiles); // update the state
    fields.remove(file);
  };

  const selectedImage = (file) => {
    var values = [].filter
      .call(document.getElementsByName("selectedImage"), (c) => c.checked)
      .map((c) => c.value);
    setSelectedImages(values);
    hanldeSelectedImages(values);
  };

  // const removeImage = (e) => {
  //   console.log(e);
  //   setImages(images.splice(e, 1));
  //   // onChange();
  //   console.log(images);
  //   // console.log(value)
  // };

  const handleChangeTitle = (value, id) => {
    const newFiles = images;
    newFiles[id].title = value;
    setImages(newFiles);
  };

  const handleBulkAction = (e) => {
    if (e === 1 && selectedImages.length) {
      let filteredData = images.filter((x) => !selectedImages?.includes(x.id));

      setImages(filteredData);
      fields.removeAll();
      filteredData.map((x) => fields.push(x));
    }
  };

  const bulkActionsOption = [
    {
      id: 1,
      name: "Delete",
    },
  ];

  useEffect(() => {
    if (fields.length > 0) {
      refData.current = fields;
    }
  }, [fields]);

  return (
    <>
      {bulkAction ? (
        <ThemeHeader
          hide={images.length === 0 ? true : false}
          right={
            <div className="form-group float-item">
              <MaterialSelect
                label="Bulk Actions"
                value={bulkActions}
                onChange={(e) => handleBulkAction(e)}
                options={bulkActionsOption}
              />
            </div>
          }
        />
      ) : null}

      <section>
        <div {...getRootProps({ className: "dropzone disabled" })}>
          <input {...getInputProps()} />
          <p>Drag 'n' drop some files here, or click to select files</p>
        </div>
        {enableYouTubeLinks ? (
          <>
            <div className="my-4 text-center">(Or)</div>
            <Row>
              <Col xl={10} lg={10} md={10}>
                <TextField
                  label="Youtube Link"
                  className="w-100"
                  onChange={handleChange}
                  value={url}
                  helperText={
                    !youTubeUrl && url ? "Enter valid youtube url" : ""
                  }
                  error={Boolean(!youTubeUrl && url)}
                />
              </Col>
              <Col>
                <ThemeButton
                  type="submit"
                  className="mr-2 green-button d-flex"
                  size=""
                  onClick={handleURL}
                  disabled={!youTubeUrl}
                >
                  Add
                </ThemeButton>
              </Col>
            </Row>
          </>
        ) : null}

        <aside style={thumbsContainer}>
          <div className="d-flex flex-wrap">
            {fields.map((file, index) => (
              <ImagePreview
                selectedItems={selectedItems}
                restrictDefaultStyle={restrictDefaultStyle}
                images={images}
                removeImage={remove}
                selectedImage={selectedImage}
                changeTitle={handleChangeTitle}
                uploadType={uploadType}
                fields={fields}
                overWriteStyles={overWriteStyles}
                imageInfo={
                  fields.get(index)?.fileContent
                    ? fields.get(index)?.fileContent
                    : fields.get(index)
                }
                file={file}
                index={index}
                deletePermissions={deletePermissions}
                downLoadPermissions={downLoadPermissions}
                isProfileVideo={isProfileVideo}
              />
            ))}
          </div>
        </aside>
      </section>
    </>
  );
};

export default DropzoneField;
