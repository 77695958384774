import React, { lazy, Suspense } from "react";
import { Spinner } from "react-bootstrap";
// import { AppointmentContext } from "../AppointmentContextProvider";
// import { DiaryContext } from "../../../../pages/DiaryContextProvider";
const Buyer = lazy(() => import(`./Buyer`));
const General = lazy(() => import(`./General`));
const Listing = lazy(() => import(`./Listing`));
const MarketReview = lazy(() => import(`./MarketReview`));
const OneToOne = lazy(() => import(`./OneToOne`));
const OpenHome = lazy(() => import(`./OpenHome`));
const Rental = lazy(() => import(`./Rental`));
const RentalOpenHome = lazy(() => import(`./RentalOpenHome`));
const SellerVisit = lazy(() => import(`./SellerVisit`));
const Tenant = lazy(() => import(`./Tenant`));
const VirtualBuyer = lazy(() => import(`./VirtualBuyer`));
const VirtualListing = lazy(() => import(`./VirtualListing`));
const VirtualRental = lazy(() => import(`./VirtualRental`));
const VirtualTenant = lazy(() => import(`./VirtualTenant`));
const Auction = lazy(() => import(`./Auction`));

const TypeList = (props) => {
  const { setFillFormData, selectedListings, selectedRentals } = props;

  const renderSwitch = (param) => {
    switch (param) {
      case 1:
        return <OneToOne {...props} />;
      case 2:
        return <Buyer {...props} selectedListings={selectedListings} />;
      case 3:
        return <General {...props} />;
      case 4:
        return <Listing {...props} />;
      case 5:
        return <OpenHome {...props} selectedListings={selectedListings} />;
      case 6:
        return <MarketReview {...props} />;
      case 7:
        return <Rental {...props} />;
      case 8:
      case 15:
      case 23:
      case 24:
        return (
          <RentalOpenHome
            {...props}
            type={param}
            selectedRentals={selectedRentals}
          />
        );
      case 9:
        return <SellerVisit {...props} />;
      case 10:
        return <Tenant {...props} setFillFormData={null} />;
      case 11:
        return <VirtualListing {...props} />;
      case 12:
        return (
          <VirtualBuyer
            {...props}
            selectedListings={selectedListings}
            setFillFormData={null}
          />
        );
      case 13:
        return <VirtualRental {...props} />;
      case 14:
        return <VirtualTenant {...props} />;
      case 25:
        return <Auction {...props} selectedListings={selectedListings} />;
      default:
        return "";
    }
  };

  return (
    <Suspense fallback={<Spinner />}>
      {/* <Component {...props} /> */}
      {props.type ? renderSwitch(props.type) : ""}
    </Suspense>
  );
};

export default TypeList;
