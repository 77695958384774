import React, { Fragment, useEffect } from "react";
import { Form, Col, Row } from "react-bootstrap";
import { Field } from "redux-form";
import TextInput from "../../../../../../../partials/form/TextInput";
import TextArea from "../../../../../../../partials/form/TextArea";
import DateInput from "../../../../../../../partials/form/DateInput";

const MakeAnOfferItem = ({ fields, meta: { error, submitFailed }, label ,numSelected}) => {
    useEffect(() => {
        console.log(numSelected)
        numSelected.map(obj => {
            fields.push({date:new Date(), address:obj.address});
            console.log(obj.address)
         })
      }, []);
  return (
    <Fragment>
        <div className="makeAnOfferItem">
        {fields.map((offer, index,members) => (
        <div>
          <Form.Row>
            <Col md={6}>
              <Form.Group as={Row} >
                <Form.Label column sm={4}>
                  Listing:
                </Form.Label>
                <Form.Label column sm={8}>
                {members.get(index).address} 
                </Form.Label>
              </Form.Group>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col md={6}>
              <Form.Group as={Row} >
                <Form.Label column sm={4}>
                  Date:
                </Form.Label>
                <Col sm={8}>

                  <Field
            name={`${offer}.date`}
            component={DateInput}
            timeIntervals={15}
            dateFormat="MMMM d, yyyy"
            placeholder="Creation Date From"
          />
                </Col>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group as={Row} controlId="formHorizontalPassword">
                <Form.Label column sm={4}>
                  Offer:
                </Form.Label>
                <Col sm={8}>
                  <Field
                    name={`${offer}.offer`}
                    type="text"
                    component={TextInput}
                    placeholder="Amount"
                  />
                </Col>
              </Form.Group>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col md={12}>
              <Form.Group as={Row} >
                <Form.Label column sm={2}>
                  Comments:
                </Form.Label>
                <Col sm={10}>
                  <Field
               name={`${offer}.comments`}
                type="textarea"
                rows="3"
                component={TextArea}
                placeholder="Comment about the offer"
              />
                </Col>
              </Form.Group>
            </Col>
          </Form.Row>
          <hr/>
        </div>
        
      ))}
        </div>
      
    </Fragment>
  );
};

export default MakeAnOfferItem;
