import React from "react";
import { Popover } from "react-bootstrap";
import { OverlayTrigger } from "react-bootstrap";

const ToolTip = ({
  name,
  content,
  onClick,
  justIcon,
  className,
  fill,
  ...others
}) => {
  return (
    <div className={className}>
      <OverlayTrigger
        // trigger="hover"
        rootClose
        placement="bottom"
        overlay={
          <Popover className="tooltipContent" id="popover-basic">
            {content}
          </Popover>
        }
      >
        {/* {justIcon ? ( */}
        <div className="p-1">
          <i
            style={{ cursor: onClick && "pointer" }}
            className={
              (fill ? "fas" : "far") +
              " fa-" +
              name +
              (!justIcon ? " tooltipIcon" : "")
            }
            onClick={() => {
              onClick && onClick();
            }}
          ></i>
        </div>

        {/* ) : (
        <ThemeButton
          icon
         
        >
          <i className={"fas fa-" + name}></i>
        </ThemeButton>
      )} */}
        {/* <div
        // className={"text-truncate "}
          // className={checkIsBetweenDate(original.ends, new moment().add(14, 'day').format("YYYY-MM-DD")) == 1 ? "color-red" : ''}
      >
        "test"
      </div> */}
      </OverlayTrigger>
    </div>
  );
};

ToolTip.defaultProps = {
  className: "",
  fill: true,
};

export default ToolTip;
