import React from "react";
import { connect } from "react-redux";
import { change, submit } from "redux-form";
import ThemeButton from "../../../partials/button/ThemeButton";

const NoteRemoteFormButton = ({
  dispatch,
  label,
  setPc,
  setDk,
  handleDoorKnock,
  formName,
  disabled = false,
  className = '',
  setNAButtonLoading = () => { }
}) => {

// console.log('===========formName=========================');
// console.log(formName);
// console.log('=============formName=======================');


  const handleOnclick = () => {
    // if(label == "PC"){
    //   setPc("[PC]");
    // }else if(label == "DK"){
    //   setDk("[DK]")
    // }

    setNAButtonLoading(true);
    dispatch(change(formName, "type", label));
    setTimeout(() => {
      dispatch(submit(formName));
    }, 1000);

    // handleDoorKnock(label);
  };
  return (
    <ThemeButton disabled={disabled} className={`door-knock-button ${className}`} onClick={() => handleOnclick()}>
      {label}
    </ThemeButton>
  );
};

export default connect()(NoteRemoteFormButton);
