import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import AccessControl from "../../../../../_ accessControl/AccessControl";
import {
  getProcessedAddress,
  getProspectDetails,
} from "../../../../../_helpers/_utils/utils";
import ProspectDetailsEdit from "./ProspectDetailsEdit";
import ProspectDetailsView from "./ProspectDetailsView";
const ProspectDetails = (props) => {
  const { permissions, currentUser } = props;
  useEffect(() => {
    // console.log(
    //   permissions?.some((permission) =>
    //     ["prospects-edit"].includes(permission?.name)
    //   ),
    //   "permission_"
    // );
  }, []);

  return (
    <Fragment>
      <AccessControl
        allowedPermissions={["prospects-edit"]}
        renderNoAccess={() => (
          <ProspectDetailsView prospectId={props?.profile?.id} />
        )}
      >
        {" "}
        <ProspectDetailsEdit prospectId={props?.profile?.id} />{" "}
      </AccessControl>
    </Fragment>
  );
};
function mapStateToProps(state, props) {
  const { property, purchase_date, purchase_price, dnc, tags } = props?.profile
    ? props?.profile
    : {};

  const permissions = state.auth.user.permissions;

  return {
    form: "prospectDetailsform",
    permissions,
    initialValues: props?.profile
      ? getProspectDetails(props?.profile)
      : {
          address: [
            { type: 1, street_unit: "", street: {}, suburb: {}, state: {} },
          ],
        },
  };
}

export default connect(mapStateToProps)(
  reduxForm({ enableReinitialize: true })(ProspectDetails)
);
