import React, { Fragment } from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Dropdown } from "react-bootstrap";
import HeaderDropdownToggle from "../../content/CustomDropdowns/HeaderDropdownToggle";
import { toAbsoluteUrl } from "../../../../_metronic";
import ThemeHeader from "../../content/ThemeHeader";
import ThemeButton from "../../button/ThemeButton";
import ScrapBookList from "./ScrapBookList";
import ScrapPaper from "./ScrapPaper";
import { ScrapPaperWrap } from "./ScrapPaperWrap";

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false
};

export default class ScrapBook extends React.Component {
  constructor() {
    super();

    this.state = {
      value: "",
      items: [],
      isEditable:false
    }

    this.handleInput = (event) => {
      this.setState({
        value: event.target.value
      })
    }

    this.handleAddItem = (event) => {
      event.preventDefault();

      if (this.state.value === "")
        return;

      const newItem = {
        task: this.state.value,
        id: Date.now(),
        status: false
      }

      this.setState((prevState) => ({
        items: prevState.items.concat(newItem),
        value: "",

      }))
    }

    this.handleMarkItemComplete = (itemId) => {

      const updatedItems = this.state.items.map(item => {
        if (itemId === item.id)
          item.status = !item.status;

        return item;
      })

      this.setState({
        items: [].concat(updatedItems)
      })
    }

    this.handleIsEditable= () => {

      this.setState({
        isEditable: !this.state.isEditable
      })
    }

    this.handleDeleteItem = (itemId) => {

      const updatedItems = this.state.items.filter(item => {
        return item.id !== itemId
      })

      this.setState({
        items: [].concat(updatedItems)
      })
    }
  }
  render() {
    const { bgImage, useSVG, icon, iconType } = this.props;

    return (
      <Dropdown className="kt-header__topbar-item" drop="down" alignRight>
        <Dropdown.Toggle as={HeaderDropdownToggle} id="dropdown-toggle-my-cart">
          <span
            className={clsx("kt-header__topbar-icon", {
              "kt-header__topbar-icon--brand": iconType === "brand"
            })}
          >
            <i className="fas fa-scroll"></i>
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-xl">
          <form className="flex-column scrapBook p-0">
            <div className="kt-mycart">
              <ThemeHeader
                className="p-3 darkBlue-background"
                left={<h5 className="font-weight-bold p-3">Scrap Paper</h5>}
                right={
                  <Fragment>
                    <ThemeButton className="darkGrey-icon-button " onClick={this.handleIsEditable} icon><i className="fas fa-edit    "></i></ThemeButton>
                  </Fragment>
                }
              />
              <PerfectScrollbar
                options={perfectScrollbarOptions}
                style={{ height: "35vh", position: "relative" }}
              >
                <div>
                <ScrapPaperWrap isEditable={this.state.isEditable}/>
                </div>
              </PerfectScrollbar>
            </div>
          </form>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}
