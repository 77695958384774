import React, { Fragment, useEffect, useState, useContext } from "react";
import {
  FieldArray,
  formValueSelector,
  reduxForm,
  Field,
  getFormValues
} from "redux-form";
import { Row, Col, Spinner } from "react-bootstrap";
import "./_contactForm.scss";
import ContactFormSideBar from "./contactFormSidebar/ContactFormSideBar";
import ContactFormPersonalDetails from "../../../../partials/form/partials/ContactFormPersonalDetails";
import ContactFormContactInfo from "../../../../partials/form/partials/ContactFormContactInfo";
import ContactFormFamilyDetails from "../../../../partials/form/partials/ContactFormFamilyDetails";
import ThemeHeader from "../../../../partials/content/ThemeHeader";
import ThemeButton from "../../../../partials/button/ThemeButton";
import { connect } from "react-redux";
import NoteForm from "../../../../partials/form/partials/note/NoteForm";
import * as contactsRedux from "../../_redux/contactsRedux";
import { Divider } from "@material-ui/core";
import { ContactProfileContext } from "../contactProfileModal/ContactProfileContextProvider";

const validate = (values = {}) => {
  const errors = {};
  // console.log(values);
  // if (!values?.first_name) {
  //   errors.first_name = "Required";
  // }
  // if (!values?.last_name) {
  //   errors.last_name =  "Required";
  // }
  // return errors;
};

const ContactForm = props => {
  const {
    handleSubmit,
    profileId,
    closeModal,
    hideNote,
    number_of_children,
    selectedContact,
    invalid,
    formValues,
    change,
    pristine,
    submitting,
    formName,
    profileLoading
  } = props;

  // const { profile } = useContext(ContactProfileContext)

  // check the form is Calling from from NewContact or not

  useEffect(() => {
    if (typeof props.setFormValues === "function") {
      props.setFormValues(formValues);
    }
  }, [formValues]);

  return (
    <form className="contactForm" onSubmit={handleSubmit}>
      <ThemeHeader
        left={<h5 className="title">{profileId ? "Edit" : "New"} Contact</h5>}
        right={
          <Fragment>
            <Fragment>
              <div className="pl-2">
                <ThemeButton
                  className="lightGrey-button"
                  bold
                  onClick={closeModal}
                >
                  Cancel
                </ThemeButton>
              </div>
              <div className="pl-2">
                <ThemeButton
                  disabled={invalid || submitting}
                  type="submit"
                  className="green-button"
                >
                  {submitting ? (
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    ""
                  )}
                  Save
                </ThemeButton>
              </div>
            </Fragment>
          </Fragment>
        }
      />

      <Row>
        <Col lg={4} xl={3} className="contactForm-left  pr-lg-5">
          <ContactFormSideBar closeModal={closeModal} profileId={profileId} />
        </Col>

        <Col className="contactForm-right" lg={8} xl={9}>
          <ContactFormPersonalDetails
            // profile={profile}
            profileId={profileId}
            selectedContact={selectedContact}
            change={change}
            profileLoading={profileLoading}
          />

          <Divider className="mt-4 mb-4" variant="middle" />
          <ContactFormContactInfo />
          <Divider className="mt-4 mb-4" variant="middle" />
          <ContactFormFamilyDetails number_of_children={number_of_children} />
          {hideNote ? "" : <Divider className="mt-4 mb-4" variant="middle" />}

          {hideNote ? (
            " "
          ) : (
            <Fragment>
              <h5 className="title pb-4">Notes</h5>
              <FieldArray
                placeholder="Enter a note about this contact"
                name="notes"
                displayPlus={false}
                component={NoteForm}
              />
            </Fragment>
          )}
        </Col>
      </Row>
    </form>
  );
};

function mapStateToProps(state, props) {
  console.log(props, "passed props");
  const selector = formValueSelector(props.formName);
  const number_of_children = selector(state, "number_of_children");
  const formValues = getFormValues(props.formName)(state);

  return {
    formValues,
    form: props.formName,
    number_of_children
  };
}
ContactForm.defaultProps = {
  hideNote: true
};
export default connect(
  mapStateToProps,
  contactsRedux.actions
)(reduxForm({ enableReinitialize: true, validate })(ContactForm));
