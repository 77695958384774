import React, { useEffect, useState, Fragment, useContext } from "react";
import { connect } from "react-redux";
import { LinearProgress } from "@material-ui/core";
import { reduxForm, Field, getFormValues } from "redux-form";
// import * as modal from '../_redux/modalRedux'

// import {
//   combineValidators,
//   isRequired,
//   composeValidators,
//   hasLengthGreaterThan,
// } from "revalidate";

import ThemeHeader from "../../../../partials/content/ThemeHeader";
import ThemeButton from "../../../../partials/button/ThemeButton";
import Item from "../../../../partials/display/Item";
import { Col } from "react-bootstrap";
import TextInput from "../../../../partials/form/TextInput";
import TextArea from "../../../../partials/form/TextArea";
import { SingleRentalContext } from "../../partials/singleRentalList/SingleRentalContextProvider";
import { Row } from "react-bootstrap";
import { getRetalPriceHistory } from "../../_crud/rentalsCrud";
import moment from "moment";
import { addNumberSeparator } from "../../../../_helpers/_utils/utils";
import MultiSelect from "../../../../partials/form/MultiSelect";
import { getMenu } from "../../../../GeneralApi/GeneralApi";

// const validate = combineValidators({
//   price_from: isRequired({ message: "The event title is required" }),
//   price_to: isRequired({ message: "The category is required" }),
//   reason: composeValidators(
//     isRequired({ message: "Please enter a description" }),
//     hasLengthGreaterThan(4)({
//       message: "Description needs to be at least 5 characters",
//     })
//   )(),
//   price_view: isRequired("city"),
//   venue: isRequired("venue"),
//   checkbox: isRequired("checkbox"),
//   startTime: isRequired("date"),
//   endTime: isRequired("date"),
// });

const AdjustPrice = (props) => {
  const {
    handleSubmit,
    pristine,
    onHide,
    submitting,
    invalid,
    selectedValues = () => {},
    adjustPrice,
  } = props;
  const [price_history, setPriceHistory] = useState(null);
  const [rental_payment_frequency, setRental_payment_frequency] = useState([]);

  const { adjustRentalPrice, rentalId, loading } = useContext(
    SingleRentalContext
  );
  useEffect(() => {
    getRetalPriceHistory(rentalId)
      .then(({ data: { data } }) => {
        setPriceHistory(data);
      })
      .catch(() => {});
    let query = "rental_payment_frequency";
    getMenu(query)
      .then(({ data: { data } }) => {
        setRental_payment_frequency(data.rental_payment_frequency);
      })
      .catch(() => {});
  }, []);

  const handleAdjustPrice = () => {
    adjustRentalPrice(adjustPrice, onHide);
    selectedValues(adjustPrice);
  };

  return (
    <Fragment>
      <div className="buyerFormModal">
        {loading ? <LinearProgress /> : <div className="py-1" />}
        <form className="contactForm" onSubmit={handleSubmit}>
          <Fragment>
            <ThemeHeader
              left={
                <Fragment>
                  <h5 className="title">Adjust Rent</h5>
                </Fragment>
              }
              right={
                <Fragment>
                  <Fragment>
                    <div className="pl-2">
                      <ThemeButton onClick={onHide}>Cancel</ThemeButton>
                    </div>
                    <div className="pl-2">
                      <ThemeButton
                        disabled={invalid || pristine || submitting}
                        onClick={() => handleAdjustPrice()}
                        className="theme-button green-button"
                      >
                        Adjust
                      </ThemeButton>
                    </div>
                  </Fragment>
                </Fragment>
              }
            />
            <Item
              left="New Rent"
              right={
                <Row>
                  <Col lg={6}>
                    <Field
                      name="rent_amount"
                      component={TextInput}
                      placeholder="Rent"
                      className="pt-2"
                    />
                  </Col>{" "}
                  <Col lg={6}>
                    {" "}
                    <Field
                      name="payment_frequency"
                      component={MultiSelect}
                      className="w-100"
                      showBottom={false}
                      isMulti={false}
                      options={rental_payment_frequency}
                      label=" "
                    />
                  </Col>
                </Row>
              }
            />

            <Item
              left="New Price View"
              right={
                <Field
                  name="price_view"
                  component={TextInput}
                  placeholder="Public View"
                />
              }
            />

            <Item
              left="Reason"
              right={
                <Field
                  name="reason"
                  rows={4}
                  component={TextArea}
                  placeholder="Reason"
                />
              }
            />
          </Fragment>
        </form>
        <Row className="pb-3 price-history">
          <div className="pr-5"></div>
          <Col lg={2} className="pl-4 ">
            {" "}
            Past Adjustments
          </Col>
          <Col lg={8}>
            {" "}
            <div className="price-history-body">
              {price_history?.map((obj) => (
                <p>
                  {moment(obj.updated_at).format("DD/MM/YYYY")} $
                  {addNumberSeparator(obj.rent_amount)}{" "}
                  {obj?.payment_frequency
                    ? rental_payment_frequency?.find(
                        (f) => f?.id == obj?.payment_frequency
                      )?.name
                    : ""}{" "}
                  {obj.reason}
                </p>
              ))}
            </div>{" "}
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

const actions = {
  // socialFeedPost: news.actions.socialFeedPostAsync,
};

const mapState = (state) => {
  const values = getFormValues("adjustPrice")(state);
  return {
    adjustPrice: values,
  };
};
export default connect(
  mapState,
  actions
)(
  reduxForm({
    form: "adjustPrice",
    initialValues: { payment_frequency: 2 },
    // validate
  })(AdjustPrice)
);
