import React, { Fragment, useState } from "react";
import { Row, Col, Form, Modal } from "react-bootstrap";
import { Field } from "redux-form";
import {
  getAllSuburbs,
  getAllStreets,
  getAllStates,
} from "../../GeneralApi/GeneralApi";
import AsyncMultiSelect from "./AsyncMultiSelect";
import AsyncSelect from "react-select/async";
import AsyncMultiSelectCreatable from "./AsyncMultiSelectCreatable";
import TextInput from "./TextInput";
import ThemeButton from "../button/ThemeButton";
import { createNewStreet } from "../../modules/prospect/curd/prospectsCrud";
import { formToQueryString } from "../../_helpers/_utils/utils";
import _debounce from 'lodash/debounce';
const NewStreet = (props) => {
  const [suburb, setSuburb] = useState(null);

  const saveNewStreet = () => {
    let obj;
    obj = {
      suburb: suburb.value,
      name: props.value.value,
    };

    let street;
    createNewStreet(obj)
      .then(({ data: { data } }) => {
        street = {
          isDataChanged: true,
          street_name: {
            value: data.id,
            label: data.name,
          },
          suburb_name: data.suburb.name,
          suburb_id: data.suburb.id,
          state_id: data.suburb.state.id,
          state_name: data.suburb.state.abbrev,
          postcode: data.suburb.postcode,
        };
        props.handleStreetOnClick(street);
      })
      .catch((e) => {});
  };

  return (
    <Fragment>
      <Modal
        {...props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Select Suburb
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AsyncSelect
            onChange={(e) => setSuburb(e)}
            cacheOptions
            defaultOptions
            loadOptions={props.getSuburbOptions}
          />
        </Modal.Body>
        <Modal.Footer>
          <ThemeButton className="green-button" onClick={() => saveNewStreet()}>
            Save
          </ThemeButton>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

const getOptionStreetLabel = (option) => (
  <Fragment>
    {option.label
      ? `${option.label} - ${option?.suburb_name} ${option?.state_name}`
      : ""}
  </Fragment>
);

const getOptionSuburbLabel = (option) => (
  <Fragment>
    {option.label ? `${option.label} ${option?.state_name}` : ""}
  </Fragment>
);
const getOptionStateLabel = (option) => (
  <Fragment>{option.label ? `${option.label}` : ""}</Fragment>
);

const SingleAddress = (props) => {
  const {
    fieldArray,
    formName,
    showIcon,
    isTwoRow,
    index,
    values,
    fields,
    icon,
    required,
  } = props;
  console.log(values);
  console.log(fields.get(index));
  const [modalShow, setModalShow] = useState(false);
  const [street, setStreet] = useState(null);
  
  const [streetValue, setStreetValue] = useState(null);


  const getSuburbOptions = (text) => {
    let query = formToQueryString({ name: text , limit:50 });

    return getAllSuburbs(query)
      .then(({ data }) => {
        let options = data.data.map((item) => ({
          value: item.id,
          label: item.name,
          state_id: item.state.id,
          state_name: item.state.abbrev,
          postcode: item.postcode,
        }));
        return options;
      })
      .catch((e) => {});
  };




  const getStreetOptions = async (text) => {
    if (typeof text === "string" || text instanceof String) {
      let query = formToQueryString({ search_street: text ,limit:30  });
      let options = [];
    await   getAllStreets(query)
        .then( async ({ data }) => {
          options = data.data.map((item) => ({
            value: item.id,
            label: item.name,
            suburb_name: item.suburb.name,
            suburb_id: item.suburb.id,
            state_id: item.suburb.state.id,
            state_name: item.suburb.state.abbrev,
            postcode: item.suburb.postcode,
          }));
        })
        .catch((e) => {});

      return options;
    }
  };

  const debounceStreetSearch = _debounce(getStreetOptions, 500)

  const getStateOptions = (text) => {
    let query = formToQueryString({ name: text, limit:50 });
    return getAllStates(query)
      .then(({ data }) => {
        let options = data.data.map((item) => ({
          value: item.id,
          label: item.name,
        }));
        return options;
      })
      .catch((e) => {});
  };

  const handleStreetOnClick = async (e) => {
    if (e?.__isNew__) {
      setStreet(e);
      setModalShow(true);
    } else {
      let oldData = fields.get(index);
      oldData.suburb.value = e?.suburb_id ? e?.suburb_id : "";
      oldData.suburb.label = e?.suburb_name ? e?.suburb_name : "";
      oldData.postcode = e?.postcode ? e?.postcode : "";
      oldData.suburb.state_name = e?.state_name ? e?.state_name : "";
      oldData.state.label = e?.state_name ? e?.state_name : "";
      oldData.state.value = e?.state_id ? e?.state_id : "";
      if (e?.isDataChanged) {
        oldData.street_name = e?.street_name;
      }
      fields.remove(index);
      fields.push(oldData);
    }
  };

  const handleSuburbOnClick = (e) => {
    let oldData = fields.get(index);
    oldData.state.label = e?.state_name ? e?.state_name : "";
    oldData.state.value = e?.state_id ? e?.state_id : "";
    oldData.postcode = e.postcode;
    oldData.street_name.label = null;
    oldData.street_name.value = null;
    fields.remove(index);
    fields.push(oldData);
  };

  const handleStateOnClick = (e) => {
    let oldData = fields.get(index);
    oldData.suburb.label = "";
    oldData.suburb.state_name = "";
    oldData.street_name.label = "";
    oldData.street_name.value = "";
    oldData.postcode = "";
    fields.remove(index);
    fields.insert(index, oldData);
  };

  return (
    <Fragment>
      <NewStreet
        value={street}
        handleStreetOnClick={handleStreetOnClick}
        show={modalShow}
        getSuburbOptions={getSuburbOptions}
        onHide={() => setModalShow(false)}
      />
      <Row>
        <Col xl={isTwoRow ? 12 : 6}>
          <Form.Group as={Row}>
            <Col sm={3} className="pr-3 pr-md-0 pb-1 pb-md-3">
              <Row>
                {showIcon ? (
                  <div className="single-address-icon">{icon}</div>
                ) : (
                  ""
                )}

                <Col>
                  <Field
                    name={
                      fieldArray ? `${fieldArray}.street_unit` : "street_unit"
                    }
                    type="text"
                    component={TextInput}
                    placeholder="Unit"
                    label="Unit"
                  />
                </Col>
              </Row>
            </Col>
            <Col sm={3} className="pr-3 pr-md-0 pb-1 pb-md-3">
              <Field
                name={
                  fieldArray ? `${fieldArray}.street_number` : "street_number"
                }
                type="text"
                component={TextInput}
                placeholder="Street No."
                label="Street No."
              />
            </Col>
            <Col
              sm={6}
              className={
                "pr-3 pr-md-3 pb-1 pb-md-3 " + isTwoRow ? "" : "pr-lg-0 "
              }
            >
              <Field
                name={fieldArray ? `${fieldArray}.street_name` : "street_name"}
                component={AsyncMultiSelectCreatable}
                showBottom={false}
                isMulti={false}
                getOptionLabel={getOptionStreetLabel}
                options={getStreetOptions}
                handleOnClick={handleStreetOnClick}
                label="Street Name"
                error={required && !values?.street_name?.label}
              />
            </Col>
          </Form.Group>
        </Col>
        <Col xl={isTwoRow ? 12 : 6}>
          <Form.Group as={Row}>
            <Col sm={6} className="pr-3 pr-md-0 pb-1 pb-md-3">
              <Field
                name={fieldArray ? `${fieldArray}.suburb` : "suburb"}
                placeholder="Enter Suburb"
                component={AsyncMultiSelect}
                showBottom={false}
                isMulti={false}
                getOptionLabel={getOptionSuburbLabel}
                options={getSuburbOptions}
                handleOnClick={handleSuburbOnClick}
                label="Suburb"
                error={required && !streetValue && !values?.suburb?.value}
              />
            </Col>
            <Col sm={3} className="pr-3 pr-md-0 pb-1 pb-md-3">
              <Field
                name={fieldArray ? `${fieldArray}.state` : "state"}
                placeholder="Select state"
                component={AsyncMultiSelect}
                showBottom={false}
                defaultValue={fields.get(index).state}
                isMulti={false}
                getOptionLabel={getOptionStateLabel}
                options={getStateOptions}
                handleOnClick={handleStateOnClick}
                label="State"
                error={required && !values?.state?.value}
              />
            </Col>
            <Col sm={3} className="pr-3 pr-md-3 pb-1 pb-md-3">
              <Field
                name={fieldArray ? `${fieldArray}.postcode` : "postcode"}
                type="number"
                component={TextInput}
                placeholder="Postcode"
                label="Postcode"
              />
            </Col>
          </Form.Group>
        </Col>
      </Row>
    </Fragment>
  );
};

export default SingleAddress;
