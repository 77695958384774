/* eslint-disable no-restricted-imports */
import React, { useEffect, useState, Fragment } from "react";
import { Field, reduxForm, getFormValues } from "redux-form";
import { Form, Col, Row } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import { Accordion } from "react-bootstrap";
import { toast } from "react-toastify";
import ThemeButton from "../../../../partials/button/ThemeButton";
import AsyncMultiSelect from "../../../../partials/form/AsyncMultiSelect";
import MultiSelect from "../../../../partials/form/MultiSelect";
import TextInput from "../../../../partials/form/TextInput";
import DateInput from "../../../../partials/form/DateInput";

const validate = (values) => {
    const errors = {};
    if (!values.username) {
        errors.username = "Required";
    } else if (values.username.length > 15) {
        errors.username = "Must be 15 characters or less";
    }
    if (!values.email) {
        // errors.email = "Required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = "Invalid email address";
    }
    if (!values.age) {
        errors.age = "Required";
    } else if (isNaN(Number(values.age))) {
        errors.age = "Must be a number";
    } else if (Number(values.age) < 18) {
        errors.age = "Sorry, you must be at least 18 years old";
    }
    return errors;
};

const listing_type_option = [
    {
        name: "All",
        id: "0",
    },
    {
        name: "Current",
        id: "1",
    },
    {
        name: "Expired",
        id: "2",
    },
    {
        name: "Inactive",
        id: "3",
    },
    {
        name: "Unconditional",
        id: "4",
    },
    {
        name: "Settled",
        id: "5",
    },
    {
        name: "Withdrawn",
        id: "6",
    },
];

const WindowCardDrawerForm = (props) => {
    const {
        handleSubmit,
        submitting,
        pristine,
        anyTouched,
        drawerFormSubmit,
        formValues,
        resetFilter,
        reset,
        drawerClosed,
    } = props;

    const [members, setMembers] = useState(null);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(reset("listings-filter-form"));
    }, [resetFilter]);
    useEffect(() => {


    }, []);

    const handleOnSubmit = (values) => {

    };

    const getOptions = (text) => {

    };



    return (
        <form onSubmit={handleSubmit(handleOnSubmit)}>
            <div className="hide-vertical-scroll  mb-4" style={{ maxHeight: "64vh" }}>
                <Form.Group as={Row}>

                    <Col className="pb-1" sm={12}>
                        <Field
                            label="Template"
                            name="template"
                            component={MultiSelect}
                            showBottom={false}
                            isMulti={false}
                            options={listing_type_option}
                        />
                        <Field
                            name="title"
                            component={TextInput}
                            placeholder="Title"
                            label="Title"
                        />
                        <Field
                            type="date"
                            name="date_from"
                            component={DateInput}
                            placeholder="Date From"
                            label="Date From"
                        />
                        <Field
                            type="date"
                            name="date_to"
                            component={DateInput}
                            placeholder="Date To"
                            label="Date To"
                        />
                    </Col>
                </Form.Group>
            </div>

            <div className="d-flex">
                <ThemeButton
                    type="submit"
                    className="w-100 submit-button"
                    disabled={submitting || !anyTouched}
                >
                    Filter
                </ThemeButton>
            </div>
        </form>
    );
};

const mapState = (state, props) => {
    const values = getFormValues("windowCard-filter-form")(state);
    return {
        formValues: values,


    };
};

export default connect(
    mapState,

)(
    reduxForm({
        form: "windowCard-filter-form",
        validate,
        enableReinitialize: true,
        touchOnChange: true,
    })(WindowCardDrawerForm)
);
