import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";

const checkPermissions = (userPermissions, allowedPermissions) => {
  // console.log('userPermissions', userPermissions)
  // console.log('allowedPermissions', allowedPermissions)
  if (allowedPermissions.length === 0) {
    return false;
  }

  return userPermissions?.some((permission) =>
    allowedPermissions?.includes(permission?.name)
  );
};

export default function useAccessControl(allowedPermissions) {
  const user = useSelector((state) => state.auth.user) ?? [];
  const [hookValue, setHookValue] = useState(false);

  useEffect(() => {
    const permitted = checkPermissions(user?.permissions, allowedPermissions);

    // if(user?.is_leader === 1)
    // {
    //   return true;
    // }

    if (user?.is_leader === 1 || permitted) {
      setHookValue(true);
    } else {
      setHookValue(false);
    }
  }, []);

  return hookValue;
}
