import React, { useState, useEffect, useContext } from "react";
// import { useEffect } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import {
  reduxForm,
  Field,
  formValueSelector,
  getFormValues,
  FormSection
} from "redux-form";
import { connect, useSelector } from "react-redux";
import { combineValidators } from "revalidate";
import moment from "moment";
import _isEmpty from "lodash/isEmpty";
import Swal from "sweetalert2";
import { CircularProgress, Backdrop, makeStyles } from "@material-ui/core";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import ThemeHeader from "../../../../../partials/content/ThemeHeader";
import ThemeButton from "../../../../../partials/button/ThemeButton";
import Item from "../../../../../partials/display/Item";
import DateInput from "../../../../../partials/form/DateInput";
import TextInput from "../../../../../partials/form/TextInput";
import MultiSelect from "../../../../../partials/form/MultiSelect";
import TextArea from "../../../../../partials/form/TextArea";
import TypeList from "./type/TypeList";
import DefaultModal from "../../../../../partials/modal/DefaultModal";
import PreviewEmailSms from "../previewEmailSms/PreviewEmailSms";

import { getMenu, getAllMembers } from "../../../../../GeneralApi/GeneralApi";
import { AppointmentContext } from "./AppointmentContextProvider";
import useHasChanged from "../../../../../_helpers/_hooks/usePrevious";
import { findProspectByAddress } from "../../../../prospect/curd/prospectsCrud";
import { getMicrositesBydAppoinmentID } from "../../../_curd/diaryCurd";
import {
  formToQueryString,
  postProcessAddress,
  postProcessDomainAddress
} from "../../../../../_helpers/_utils/utils";
import ToggleIcon from "../../../../../partials/form/toggleIcon/ToggleIcon";
import { required } from "../../../../validation/Validation";
import { PoweroffOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { MailOutlined } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  }
}));

const validate = combineValidators({
  // title: isRequired({ message: "The event title is required" }),
  // category: isRequired({ message: "The category is required" }),
  // description: composeValidators(
  //   isRequired({ message: "Please enter a description" }),
  //   hasLengthGreaterThan(4)({
  //     message: "Description needs to be at least 5 characters",
  //   })
  // )(),
  // city: isRequired("city"),
  // venue: isRequired("venue"),
  // checkbox: isRequired("checkbox"),
  // startTime: isRequired("date"),
  // endTime: isRequired("date"),
});

const AppointmentForm = props => {
  const {
    handleSubmit,
    submitting,
    invalid,
    formValues,
    closeModal,
    setFillFormData,
    selectedListings,
    selectedRentals,
    initialValues,
    change,
    mobile
  } = props;

  // Preview Email Sms Modal
  const classes = useStyles();
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [appointment_type, setAppointment_type] = useState({});
  const [selectedAddress, setSelectedAddress] = useState({});
  const [appointment_repeat_type, setAppointment_repeat_type] = useState({});
  const [members, setMembers] = useState([]);
  const [
    preLisitngAppointmentMicrosite,
    setPreLisitngAppointmentMicrosite
  ] = useState([]);
  const [preListingSendButton, setPreListingSendButton] = useState(false);
  const {
    createNewAppointment,
    setFindAddressData,
    sendPreListingEmailToProspect

    // findAddressData,
  } = useContext(AppointmentContext);
  const prospect_address_changed = useHasChanged(
    formValues?.prospect_address?.meeting_location
  );
  const google_address_changed = useHasChanged(
    formValues?.prospect_address?.domain_address
  );

  //todo::remove when pre-lisitng is open for all org
  const organisationId = useSelector(
    state => state.auth.user?.organisation?.id
  );

  useEffect(() => {
    if (
      prospect_address_changed &&
      formValues?.prospect_address?.meeting_location?.[0]?.street_name?.value
    ) {
      let queryString = formToQueryString(
        postProcessAddress(formValues?.prospect_address?.meeting_location[0])
      );

      setShowBackdrop(true);
      findProspectByAddress(queryString)
        .then(res => {
          setShowBackdrop(false);
          setFindAddressData(res?.data?.data);
          // Remove contact information pop-up for now which causing issues on removing contact information and duplicating.
          // postProcessUpdateContactInformation(res?.data?.data);
        })
        .catch(() => {});
    }
  }, []);

  useEffect(() => {
    if (google_address_changed && !_isEmpty(selectedAddress)) {
      let tempAddress = formValues?.prospect_address?.domain_address;
      let obj = postProcessDomainAddress(tempAddress);
      let queryString = formToQueryString(obj);

      setShowBackdrop(true);
      findProspectByAddress(queryString)
        .then(res => {
          setShowBackdrop(false);
          setFindAddressData(res?.data?.data);
          // Remove contact information pop-up for now which causing issues on removing contact information and duplicating.
          // postProcessUpdateContactInformation(res?.data?.data);
        })
        .catch(() => {});
    }
  }, [google_address_changed]);

  const postProcessUpdateContactInformation = async data => {
    let selectedRadio;
    let addressDataSource = data;
    if (addressDataSource?.contacts?.length > 0) {
      var inputOptions = addressDataSource?.contacts.reduce(
        (obj, item) =>
          Object.assign(obj, {
            [item.id]: item.first_name + " " + item.last_name
          }),
        {}
      );
    }
    if (addressDataSource?.contacts?.length) {
      const { value: selectedId } = await Swal.fire({
        title: "Select a contact to make an appointment with",
        input: "radio",
        inputOptions: inputOptions,
        inputValidator: value => {
          if (!value) {
            return "You need to Select a contact!";
          }
        }
      });
      selectedRadio = selectedId;
      let filteredDataSource;
      if (selectedRadio) {
        filteredDataSource = addressDataSource?.contacts.filter(
          (val, index) => val?.id == selectedRadio
        );
      }
      if (filteredDataSource) {
        change("contacts[0]first_name", filteredDataSource[0]?.first_name);
        change("contacts[0]last_name", filteredDataSource[0]?.last_name);
        filteredDataSource.map((val, index) => {
          change(`contacts[${index}].phones`, val?.phones);
          change(`contacts[${index}].emails`, val?.emails);
        });
      }
    }
  };
  useEffect(() => {
    let query = "appointment_type,repeat_type";
    getMenu(query)
      .then(({ data: { data } }) => {
        setAppointment_type(data.appointment_type);
        setAppointment_repeat_type(data.repeat_type);
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    getAllMembers()
      .then(({ data: { data } }) => {
        setMembers(
          data.map(s => ({
            name: `${s.initial}`,
            id: s.contact.id,
            optionTooltipTitle: `${s?.contact?.first_name ?? ""} ${s?.contact
              ?.last_name ?? ""}`,
            micrositesTemplateID: s?.microsites_templates?.id ?? null
          }))
        );
      })
      .catch(e => {});
  }, []);

  useEffect(() => {
    if (initialValues?.id != null && initialValues?.app_type === 4) {
      setPreListingSendButton(true);
    }
  }, []);

  //   useEffect(()=>{
  //     if(initialValues?.id != null && appointment_type == 4){
  //       setPreListingSendButton(true);
  // /*      getMicrositesBydAppoinmentID(initialValues?.id)
  //           .then(({ data: { data } }) => {
  //             setPreLisitngAppointmentMicrosite (data);
  //           })
  //           .catch(e => {});
  //     }*/
  //   }, []);

  const handleFormSubmit = e => {
    return createNewAppointment(e);
  };

  const sendPreListEmail = e => {
    sendPreListingEmailToProspect(e);
  };
  const handleStartDateChange = date => {
    let minutes = moment(date).minutes();
    let minutesToAdd = 0;
    if (minutes % 5 !== 0) {
      minutesToAdd = 5 - (minutes % 5);
    }
    if (isNaN(minutesToAdd)) {
      props.change("starts", moment(new Date()).format("YYYY-MM-DDTHH:mm"));
      props.change(
        "ends",
        moment(new Date())
          .add(new Date() + 30, "minutes")
          .format("YYYY-MM-DDTHH:mm")
      );
    } else {
      props.change(
        "starts",
        moment(date)
          .add(minutesToAdd, "minutes")
          .format("YYYY-MM-DDTHH:mm")
      );
      props.change(
        "ends",
        moment(date)
          .add(minutesToAdd + 30, "minutes")
          .format("YYYY-MM-DDTHH:mm")
      );
    }
  };

  const handleEndDateChange = date => {
    let minutes = moment(date).minutes();
    let minutesToAdd = 0;
    if (minutes % 5 !== 0) {
      minutesToAdd = 5 - (minutes % 5);
    }
    if (isNaN(minutesToAdd)) {
      props.change(
        "ends",
        moment(new Date())
          .add(new Date() + 30, "minutes")
          .format("YYYY-MM-DDTHH:mm")
      );
    } else {
      props.change(
        "ends",
        moment(date)
          .add(minutesToAdd, "minutes")
          .format("YYYY-MM-DDTHH:mm")
      );
    }
  };

  const onAppointmentTypeChange = () => {
    change("contacts", [
      {
        emails: [{ type: 1, value: "" }],
        phones: [{ type: 1, value: "" }]
      }
    ]);
    setPreListingSendButton(false);
  };
  return (
    <>
      {modalShow ? (
        <DefaultModal
          size="xl"
          centered
          show={modalShow}
          onHide={() => setModalShow(false)}
          classNames="borderLess previewSmsEmail"
        >
          <PreviewEmailSms onHide={() => setModalShow(false)} />
        </DefaultModal>
      ) : null}

      <form className="contactForm" onSubmit={handleSubmit(handleFormSubmit)}>
        <ThemeHeader
          left={
            <h5 className={`title ${mobile && "fontmodifier"}`}>
              {initialValues?.id ? "Edit" : "New"} Appointment
            </h5>
          }
          right={
            <>
              <div className="pl-2">
                <ThemeButton
                  onClick={() => {
                    closeModal();
                    setFillFormData && setFillFormData({});
                  }}
                  className={`lightGrey-button ${mobile && "fontmodifier"}`}
                >
                  Cancel
                </ThemeButton>
              </div>
              <div className="pl-2">
                <ThemeButton
                  disabled={invalid || submitting}
                  type="submit"
                  className={`green-button ${mobile && "fontmodifier"}`}
                >
                  {submitting ? (
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : null}
                  Save
                </ThemeButton>
              </div>
            </>
          }
        />

        <Row>
          <Col md={5} lg={4}>
            <div className="pb-3">
              <Field
                label="Type"
                name="app_type"
                isMulti={false}
                component={MultiSelect}
                showBottom={false}
                options={appointment_type}
                onChange={onAppointmentTypeChange}
              />
            </div>
            <div className="pb-3">
              {members?.length ? (
                <Field
                  label="For"
                  name="for_contact"
                  component={MultiSelect}
                  showBottom={false}
                  isMulti={false}
                  options={members}
                />
              ) : null}
            </div>
            <div className="pb-3">
              <Field
                label="Title"
                name="title"
                validate={props.type === 3 && [required]}
                component={TextInput}
                error={props.type === 3 && !formValues?.title}
              />
            </div>
            <div className="pb-3">
              <Field
                name="starts"
                label="Start Date"
                type="datetime-local"
                onBlurChange={handleStartDateChange}
                component={DateInput}
                validate={[required]}
                error={!formValues?.starts}
              />
            </div>
            <div className="pb-3">
              <Field
                name="ends"
                label="End Date"
                type="datetime-local"
                onBlurChange={handleEndDateChange}
                component={DateInput}
                validate={[required]}
                error={!formValues?.ends}
                inputProps={{
                  min: formValues?.starts
                }}
              />
            </div>
            <div className="pb-3">
              <Field
                label="Repeat"
                name="repeat"
                component={MultiSelect}
                showBottom={false}
                isMulti={false}
                options={appointment_repeat_type}
              />
            </div>
            <div className="pb-3">
              <Field label="Notes" name="notes" rows="3" component={TextArea} />
            </div>
            <div className="pb-3">
              <Field
                label="Attendees"
                name="members"
                component={MultiSelect}
                showBottom={false}
                options={members}
              />
            </div>

            {props.type !== 3 && (
              <Item
                mainClass="darkBlue-background"
                left="Alerts"
                right={
                  <div className="d-flex justify-content-between">
                    {/* <div>
                        <ThemeButton
                          onClick={handleModal}
                          icon
                          className="darkGrey-icon-button"
                          tabIndex={'-1'}
                        >
                          <i className="fas fa-eye"></i>
                        </ThemeButton>
                      </div> */}
                    <div className="pr-5">Notify</div>
                    <div>Remind</div>
                  </div>
                }
              />
            )}
            <FormSection name="notifications">
              {props.type === 1 && (
                <FormSection name="team_member">
                  <Item
                    mainClass="lightBlue-background"
                    left="Team Members"
                    rightClass="p-0"
                    right={
                      <div className="d-flex justify-content-between">
                        <div>
                          <Field
                            name="notify_email"
                            icon={<i className="fas fa-envelope"></i>}
                            checkedIcon={
                              <i className="fas fa-envelope green-color"></i>
                            }
                            className="m-0"
                            component={ToggleIcon}
                          />
                          <Field
                            name="notify_sms"
                            className="m-0"
                            icon={<i className="fas fa-mobile-alt"></i>}
                            checkedIcon={
                              <i className="fas fa-mobile-alt green-color"></i>
                            }
                            component={ToggleIcon}
                          />
                        </div>
                        <div>
                          <Field
                            name="remind_email"
                            className="m-0"
                            icon={<i className="fas fa-envelope"></i>}
                            checkedIcon={
                              <i className="fas fa-envelope green-color"></i>
                            }
                            component={ToggleIcon}
                          />
                          <Field
                            name="remind_sms"
                            className="m-0"
                            icon={<i className="fas fa-mobile-alt"></i>}
                            checkedIcon={
                              <i className="fas fa-mobile-alt green-color"></i>
                            }
                            component={ToggleIcon}
                          />
                        </div>
                      </div>
                    }
                  />
                </FormSection>
              )}
              {(props.type === 2 ||
                props.type === 5 ||
                props.type === 6 ||
                props.type === 9 ||
                props.type === 12) && (
                <FormSection name="seller">
                  <Item
                    mainClass="lightBlue-background"
                    left="Seller"
                    rightClass="p-0"
                    right={
                      <div className="d-flex justify-content-between">
                        <div>
                          <Field
                            name="notify_email"
                            icon={<i className="fas fa-envelope"></i>}
                            checkedIcon={
                              <i className="fas fa-envelope green-color"></i>
                            }
                            className="m-0"
                            component={ToggleIcon}
                          />
                          <Field
                            name="notify_sms"
                            className="m-0"
                            icon={<i className="fas fa-mobile-alt"></i>}
                            checkedIcon={
                              <i className="fas fa-mobile-alt green-color"></i>
                            }
                            component={ToggleIcon}
                          />
                        </div>
                        <div>
                          <Field
                            name="remind_email"
                            className="m-0"
                            icon={<i className="fas fa-envelope"></i>}
                            checkedIcon={
                              <i className="fas fa-envelope green-color"></i>
                            }
                            component={ToggleIcon}
                          />
                          <Field
                            name="remind_sms"
                            className="m-0"
                            icon={<i className="fas fa-mobile-alt"></i>}
                            checkedIcon={
                              <i className="fas fa-mobile-alt green-color"></i>
                            }
                            component={ToggleIcon}
                          />
                        </div>
                      </div>
                    }
                  />
                </FormSection>
              )}
              {(props.type === 2 || props.type === 12) && (
                <FormSection name="buyer">
                  <Item
                    left="Buyers"
                    rightClass="p-0"
                    mainClass="lightBlue-background"
                    right={
                      <div className="d-flex justify-content-between">
                        <div>
                          <Field
                            name="notify_email"
                            icon={<i className="fas fa-envelope-open-text"></i>}
                            checkedIcon={
                              <i className="fas fa-envelope-open-text green-color"></i>
                            }
                            className="m-0"
                            component={ToggleIcon}
                          />
                        </div>
                        <div className="pr-5">
                          <Field
                            name="notify_email"
                            icon={<i className="fas fa-envelope"></i>}
                            checkedIcon={
                              <i className="fas fa-envelope green-color"></i>
                            }
                            className="m-0"
                            component={ToggleIcon}
                          />
                          <Field
                            name="notify_sms"
                            className="m-0"
                            icon={<i className="fas fa-mobile-alt"></i>}
                            checkedIcon={
                              <i className="fas fa-mobile-alt green-color"></i>
                            }
                            component={ToggleIcon}
                          />
                        </div>
                        <div>
                          <Field
                            name="remind_email"
                            className="m-0"
                            icon={<i className="fas fa-envelope"></i>}
                            checkedIcon={
                              <i className="fas fa-envelope green-color"></i>
                            }
                            component={ToggleIcon}
                          />
                          <Field
                            name="remind_sms"
                            className="m-0"
                            icon={<i className="fas fa-mobile-alt"></i>}
                            checkedIcon={
                              <i className="fas fa-mobile-alt green-color"></i>
                            }
                            component={ToggleIcon}
                          />
                        </div>
                      </div>
                    }
                  />
                </FormSection>
              )}
              {(props.type === 4 || props.type === 11) && (
                <FormSection name="prospect">
                  <Item
                    left="Prospects"
                    rightClass="p-0"
                    mainClass="lightBlue-background"
                    right={
                      <div className="d-flex justify-content-between">
                        <div></div>
                        <div>
                          <Field
                            name="notify_email"
                            icon={<i className="fas fa-envelope"></i>}
                            checkedIcon={
                              <i className="fas fa-envelope green-color"></i>
                            }
                            className="m-0"
                            component={ToggleIcon}
                          />
                          <Field
                            name="notify_sms"
                            className="m-0"
                            icon={<i className="fas fa-mobile-alt"></i>}
                            checkedIcon={
                              <i className="fas fa-mobile-alt green-color"></i>
                            }
                            component={ToggleIcon}
                          />
                        </div>
                        <div>
                          <Field
                            name="remind_email"
                            className="m-0"
                            icon={<i className="fas fa-envelope"></i>}
                            checkedIcon={
                              <i className="fas fa-envelope green-color"></i>
                            }
                            component={ToggleIcon}
                          />
                          <Field
                            name="remind_sms"
                            className="m-0"
                            icon={<i className="fas fa-mobile-alt"></i>}
                            checkedIcon={
                              <i className="fas fa-mobile-alt green-color"></i>
                            }
                            component={ToggleIcon}
                          />
                        </div>
                      </div>
                    }
                  />
                </FormSection>
              )}
              {(props.type === 7 ||
                props.type === 8 ||
                props.type === 10 ||
                props.type === 13 ||
                props.type === 14) && (
                <FormSection name="landlord">
                  <Item
                    mainClass="lightBlue-background"
                    left="Landlords"
                    rightClass="p-0"
                    right={
                      <div className="d-flex justify-content-between">
                        <div></div>
                        <div>
                          <Field
                            name="notify_email"
                            icon={<i className="fas fa-envelope"></i>}
                            checkedIcon={
                              <i className="fas fa-envelope green-color"></i>
                            }
                            className="m-0"
                            component={ToggleIcon}
                            tabIndex={"-1"}
                          />
                          <Field
                            name="notify_sms"
                            className="m-0"
                            icon={<i className="fas fa-mobile-alt"></i>}
                            checkedIcon={
                              <i className="fas fa-mobile-alt green-color"></i>
                            }
                            component={ToggleIcon}
                            tabIndex={"-1"}
                          />
                        </div>
                        <div>
                          <Field
                            name="remind_email"
                            className="m-0"
                            icon={<i className="fas fa-envelope"></i>}
                            checkedIcon={
                              <i className="fas fa-envelope green-color"></i>
                            }
                            component={ToggleIcon}
                            tabIndex={"-1"}
                          />
                          <Field
                            name="remind_sms"
                            className="m-0"
                            icon={<i className="fas fa-mobile-alt"></i>}
                            checkedIcon={
                              <i className="fas fa-mobile-alt green-color"></i>
                            }
                            component={ToggleIcon}
                            tabIndex={"-1"}
                          />
                        </div>
                      </div>
                    }
                  />
                </FormSection>
              )}

              {(props.type === 10 || props.type === 14) && (
                <FormSection name="tenant">
                  <Item
                    mainClass="lightBlue-background"
                    left="Tenants"
                    rightClass="p-0"
                    right={
                      <div className="d-flex justify-content-between">
                        <div></div>
                        <div>
                          <Field
                            name="notify_email"
                            icon={<i className="fas fa-envelope"></i>}
                            checkedIcon={
                              <i className="fas fa-envelope green-color"></i>
                            }
                            className="m-0"
                            component={ToggleIcon}
                          />
                          <Field
                            name="notify_sms"
                            className="m-0"
                            icon={<i className="fas fa-mobile-alt"></i>}
                            checkedIcon={
                              <i className="fas fa-mobile-alt green-color"></i>
                            }
                            component={ToggleIcon}
                          />
                        </div>
                        <div>
                          <Field
                            name="remind_email"
                            className="m-0"
                            icon={<i className="fas fa-envelope"></i>}
                            checkedIcon={
                              <i className="fas fa-envelope green-color"></i>
                            }
                            component={ToggleIcon}
                          />
                          <Field
                            name="remind_sms"
                            className="m-0"
                            icon={<i className="fas fa-mobile-alt"></i>}
                            checkedIcon={
                              <i className="fas fa-mobile-alt green-color"></i>
                            }
                            component={ToggleIcon}
                          />
                        </div>
                      </div>
                    }
                  />
                </FormSection>
              )}
            </FormSection>

            {/* {props.type?.value ? renderSwitch(props.type) : ""} */}
          </Col>
          <Col md={7} lg={8}>
            {props.type ? (
              <TypeList
                setSelectedAddress={setSelectedAddress}
                change={change}
                appointment_type={appointment_type}
                setFillFormData={setFillFormData}
                formValues={formValues}
                selectedListings={selectedListings}
                selectedRentals={selectedRentals}
                {...props}
              />
            ) : null}
          </Col>
          {preListingSendButton ? (
            <div className="d-flex justify-content-md-end ml-auto">
              <div style={{ marginRight: "15px" }}>
                {preListingSendButton && (
                  <Button
                    type="default"
                    style={{
                      backgroundColor: "#880c22",
                      color: "white",
                      borderRadius: "4px"
                    }}
                    icon={<MailOutlined />}
                    onClick={() => sendPreListEmail(initialValues?.id)}
                  >
                    Send Pre Listing
                  </Button>
                )}
              </div>
            </div>
          ) : null}
        </Row>
      </form>

      <Backdrop className={classes.backdrop} open={showBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

const actions = {
  // socialFeedPost: news.actions.socialFeedPostAsync,
};

const selector = formValueSelector("new-Appointment");

const mapState = (state, props) => {
  const values = getFormValues("new-Appointment")(state);
  const type = selector(state, "app_type");
  props.setAppointmentForm(values);
  return {
    type,
    formValues: values
  };
};
export default connect(
  mapState,
  actions
)(
  reduxForm({ form: "new-Appointment", validate, enableReinitialize: true })(
    AppointmentForm
  )
);
