import React from "react";
import { LinearProgress } from "@material-ui/core";

const LinearProgressLoading = ({ className }) => (
  <div className={className}>
    <LinearProgress />
  </div>
);

export default LinearProgressLoading;
