import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import moment from "moment";
import TimelinePophover from "../../popover/timeline/TimelinePophover";

const TimelineItem = (props) => {
  const { date, text, type, id, info_items } = props;
  return (
    <div className="timeline-item align-items-start">
      <div className="timeline-label">
        {new Date(date).toLocaleDateString()} <br />
        <span className="text-muted">{moment(date).format("hh:mm A")}</span>
      </div>
      <div
        className={`timeline-badge ${type[0]?.name
          ?.split(" ")[0]
          .toLowerCase()}-background `}
      ></div>
      <div className="timeline-content d-flex flex-column">
        {info_items > 0 ? (
          <OverlayTrigger
            trigger="click"
            rootClose={true}
            rootCloseEvent="click"
            placement="bottom"
            overlay={
              <Popover className="timeline-popover">
                <Popover.Content>
                  <TimelinePophover id={id} />
                </Popover.Content>
              </Popover>
            }
          >
            <span className="mr-3" style={{ whiteSpace: "pre-line" }}>
              {text}
            </span>
          </OverlayTrigger>
        ) : (
          <span className="mr-3" style={{ whiteSpace: "pre-line" }}>
            {text}
          </span>
        )}
        {/* {type.} */}
      </div>
    </div>
  );
};

export default TimelineItem;
