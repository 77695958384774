import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { connect } from "react-redux";
import { Field, FieldArray, getFormValues, reduxForm } from "redux-form";
import ThemeButton from "../../../../../partials/button/ThemeButton";
import ThemeHeader from "../../../../../partials/content/ThemeHeader";
import TextArea from "../../../../../partials/form/TextArea";
import { getBuyerById } from "../../../../buyers/crud/buyersCrud";
import { createProperty } from "../../../../listings/_crud/listingsCrud";
import FeatureSliders from "./FeatureSliders";

const AddNewInspection = ({ closeModal, formValues }) => {
  const handleSubmit = () => {
    let obj = {};
    obj.address = {
      id: null,
      street_unit: null,
      street_number: "13",
      street: 27416,
      street_name: "Sylvan",
      suburb: 13946,
      state: 1,
      postcode: 2154,
    };
    obj.features = formValues?.property_features?.map((feature) => {
      return { feature: feature.id, qty: feature.quantity };
    });
    createProperty(obj);
  };
  return (
    <div>
      <ThemeHeader
        left={<h5 className="title">Inspection(23/09/20)</h5>}
        right={
          <Fragment>
            <div className="pl-2">
              <ThemeButton className="lightGrey-button" onClick={closeModal}>
                Cancel
              </ThemeButton>
            </div>

            <div className="pl-2">
              <ThemeButton
                className="submit-button"
                onClick={() => handleSubmit()}
                // onClick={() => setShowAddNew(true)}
              >
                Save
              </ThemeButton>
            </div>
          </Fragment>
        }
      />
      <FieldArray component={FeatureSliders} name="property_features" default />
      <Field component={TextArea} name="general_notes" />
    </div>
  );
};

function mapStateToProps(state, props) {
  console.log(props.features, "getFeatures");
  const values = getFormValues("new-inspection")(state);

  return {
    formValues: values,
    initialValues: {
      property_features: props?.features.map((feature) => {
        return {
          type: feature?.feature?.name,
          quantity: feature?.qty,
          id: feature?.feature?.id,
        };
      }),
      // { type: props. },
      // { type: "Bath" },
      // { type: "Parking" },
    },
  };
}
export default connect(mapStateToProps)(
  reduxForm({
    form: "new-inspection",
    enableReinitialize: true,
  })(AddNewInspection)
);
