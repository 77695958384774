import React from "react";
import { connect } from "react-redux";
import { change, submit } from "redux-form";
// import ThemeButton from "../../../partials/button/ThemeButton";
import { Button } from "@material-ui/core";

const NoteRemoteFormButton = ({
  dispatch,
  label,
  setPc,
  setDk,
  handleDoorKnock,
  formName,
  disabled = false,
  className = '',
  setIsDisabled,
  setNAButtonLoading = () => { }
}) => {




  const handleOnclick = () => {
    // if(label == "PC"){
    //   setPc("[PC]");
    // }else if(label == "DK"){
    //   setDk("[DK]")
    // }

    setNAButtonLoading(true);
    dispatch(change(formName, "type", label));
    setIsDisabled(null)
    setTimeout(() => {
      dispatch(submit(formName));
    }, 1000);

    // handleDoorKnock(label);
  };
  return (
    <Button disabled={disabled} size="large" className={`m-2 btnM2 ${className}`} onClick={() => handleOnclick()}>
      {label}
    </Button>
  );
};

export default connect()(NoteRemoteFormButton);
