import React from "react";
import { formatPhoneNumber } from "../../../_helpers/_utils/utils";

const CellPhone = ({ value }) => (
  <div className="d-flex justify-content-between">
    <a href={`tel:${value?.value}`}>{formatPhoneNumber(value?.value)}</a>
    {value?.unsubscribe_date ? (
      <i className="fas fa-flag red-color "></i>
    ) : null}
  </div>
);
export default CellPhone;
