/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { Fragment, useState} from "react";
import { Link } from "react-router-dom";
import { Dropdown, Accordion } from "react-bootstrap";
import { connect } from "react-redux";
import Chip from "@material-ui/core/Chip";
import Button from "@material-ui/core/Button";
import _get from "lodash/get";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import IdleTimer from "react-idle-timer";

import { toAbsoluteUrl } from "../../../_metronic";
import HeaderDropdownToggle, {
  OfficeCustomToggle
} from "../content/CustomDropdowns/HeaderDropdownToggle";
import * as tabRedux from "../../modules/_tab/_redux/tabRedux";

import DefaultModal from "../modal/DefaultModal";
import IdealTimeOutModal from "../idelTimeout/IdealTimeOutModal";

import {getAllMember, submitUserOffice, loginMailCoach} from "../../GeneralApi/GeneralApi";
import { getErrorMessage } from "../../_helpers/_utils/utils";
import * as auth from "../../modules/auth/_redux/auth.duck";
import AccountSwitch from "./AccountSwitch";
import Cookies from "js-cookie";
import * as jose from "jose";
// import { SignJWT, createSecretKey } from 'jose/jwt/sign';

class UserProfile extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      timeout: 1000 * 60 * 60,
      showModal: false,
      userLoggedIn: false,
      isTimedOut: false,
      openMenu: false,
      showAccountSwitch:false,
    };

    this.idleTimer = null;
    this.onAction = this._onAction.bind(this);
    this.onActive = this._onActive.bind(this);
    this.onIdle = this._onIdle.bind(this);
    this.closeModal = this._closeModal.bind(this);
  }

  _onAction(e) {
    this.setState({ isTimedOut: false });
  }

  _onActive(e) {
    this.setState({ isTimedOut: false });
  }

  _closeModal(e) {
    this.setState({ showModal: false });
  }

  _onIdle(e) {
    const isTimedOut = this.state.isTimedOut;
    if (isTimedOut) {
      console.log("timeout");
    } else {
      this.setState({ showModal: true });
      this.idleTimer.reset();
      this.setState({ isTimedOut: true });
    }
  }

  handleChipClick = menu => {
    Swal.fire({
      title: "Warning",
      text: "Are you sure want to change the office?",
      icon: "warning",
      showCancelButton: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
      confirmButtonColor: "#A7A9AC",
      cancelButtonColor: "#2BB673",
      confirmButtonText: "Cancel",
      cancelButtonText: "Yes"
    }).then(result => {
      if (result.isDismissed) {
        this.handleStoreOffice(menu?.id);
      }
    });
  };

  handleStoreOffice = organisation_id => {
    const { user } = this.props;
    submitUserOffice(user?.id, {
      primary_office: organisation_id,
      _method: "PATCH"
    })
      .then(res => {
        this.props.requestUser({ reload: true });
        // window.location.reload();
      })
      .catch(err => {
        getErrorMessage(err).map(message => toast.error(message));
      });
  };

mailCoachAction = async () => {
  try {
    const response = await loginMailCoach();
    const token = response.data.token;

    const mailcoachUrl = process.env.REACT_APP_WISEBERRY_MAIL_URL +`login?token=${encodeURIComponent(token)}`;

    window.open(mailcoachUrl, '_blank');
  } catch (error) {
    console.error('Error logging into Mailcoach:', error);
  }
};

  accountSwitch =async () => {

    this.setState({ showAccountSwitch: true });
  }

  handleCloseDialog = (event ,reason) => {
    if(reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
      // Set 'open' to false, however you would do that with your particular code.
      this.setState({ showAccountSwitch: false });
    }
  };

  forumAction = () => {
    this.generateJWTTokenAndSaveInCookie();
    // Create a JWT token with user data
  };
  generateJWTTokenAndSaveInCookie = async () => {
    // const { createSecretKey } = require('crypto');
    const secretKey = process.env.REACT_APP_WISEBERRY_FORUM_JWT_TOKEN_KEY;

    const secret = new TextEncoder().encode(secretKey);
    const { user } = this.props;
    try {
      const payload = {
        sub: `${user?.id}`, // Subject (typically a user ID)
        username: `${user?.username}`,
        email: `${user?.contact?.emails?.[0]?.value}`,
        picture: `${user?.contact?.profile_picture}`,
        fullname: `${user?.contact?.first_name} ${user?.contact?.last_name}`, // Custom claims
        iat: Math.floor(Date.now() / 1000), // Issued At timestamp
        exp: Math.floor(Date.now() / 1000) + 3600 // Expiration timestamp (1 hour from now)
      };

      const jwt = await new jose.SignJWT(payload)
        .setProtectedHeader({ alg: "HS256" }) // Algorithm (HS256 for HMAC-SHA256)
        .setIssuedAt() // Issued At (current time)
        .setExpirationTime("2h") // Expiration time (2 hours from now)
        .sign(secret);
      // REACT_APP_WISEBERRY_FORUM_URL;
      Cookies.set(process.env.REACT_APP_WISEBERRY_FORUM_COOKIE_NAME, jwt, {
        expires: 1,
        domain : '.wiseberryonline.com.au'
      });
      window.open(process.env.REACT_APP_WISEBERRY_FORUM_URL, "_blank");
    } catch (error) {
      console.error("Error generating JWT token:", error);
      return null;
    }
  };

  render() {
    const { user } = this.props;
    const accessible_organisations = user?.accessible_organisations ?? [];
    const active_organisation = user?.organisation?.id;
    const active_organisation_name = _get(
      accessible_organisations?.filter(
        organisation => organisation.id === active_organisation
      ),
      "[0].name",
      user?.organisation?.name
    );

    return (
      <Fragment>
        {this.state.showModal ? (
          <Fragment>
            {" "}
            <DefaultModal
              size="md"
              centered
              show={this.state.showModal}
              onHide={() => this.closeModal()}
              classNames="borderLess previewSmsEmail"
            >
              <IdealTimeOutModal closeModal={() => this.closeModal()} />
            </DefaultModal>{" "}
          </Fragment>
        ) : (
          ""
        )}

        <Dropdown
          className="kt-header__topbar-item kt-header__topbar-item--user pl-2"
          drop="down"
          alignRight
        >
          <IdleTimer
            ref={ref => {
              this.idleTimer = ref;
            }}
            element={document}
            onActive={this.onActive}
            onIdle={this.onIdle}
            onAction={this.onAction}
            debounce={250}
            timeout={this.state.timeout}
          />
          <Dropdown.Toggle
            as={HeaderDropdownToggle}
            id="dropdown-toggle-user-profile"
          >
            {/* If profile picture does not exists show default image */}
            <img
              alt="Pic"
              src={
                user?.contact?.profile_picture
                  ? user?.contact?.profile_picture
                  : toAbsoluteUrl("/media/users/default.jpg")
              }
            />

            <span className="kt-header__topbar-username kt-hidden-mobile">
              {user?.contact?.first_name} {user?.contact?.last_name}
            </span>
          </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
            {/** ClassName should be 'dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl' */}
            <div className="kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x darkBlue-background">
              <div className="kt-user-card__avatar">
                {/* If profile picture does not exists show default image */}
                <img
                  alt="Pic"
                  src={
                    user?.contact?.profile_picture
                      ? user?.contact?.profile_picture
                      : toAbsoluteUrl("/media/users/default.jpg")
                  }
                />

                <span className="kt-badge kt-hidden kt-badge--lg kt-badge--rounded kt-badge--bold kt-font-success">
                  S
                </span>
              </div>
              <div className="kt-user-card__personalInfo">
                <span className="full_name">
                  {user?.contact?.first_name} {user?.contact?.last_name}
                </span>
                <br />
                <span className="organization">
                  {user?.organisation ? user.organisation?.name : ""}
                </span>
                <br />
                <span className="position">
                  {user?.position ? user.position : ""}
                </span>
                <br />
              </div>
            </div>
            <div className="kt-notification">
              <div className="kt-notification__item">
                <div className="kt-notification__item-details">
                  <div className="kt-notification__item-time">
                    {accessible_organisations?.length ? (
                      <Accordion>
                        <OfficeCustomToggle
                          eventKey="0"
                          rightArrow={true}
                          tabIndex={"-1"}
                        >
                          <div className="kt-notification__item-selected font-weight-bold my-3">
                            <Chip label={active_organisation_name} />
                          </div>
                        </OfficeCustomToggle>
                        <Accordion.Collapse className="mb-5" eventKey="0">
                          <div className="kt-notification__item-content">
                            {accessible_organisations?.map((menu, index) => (
                              <Chip
                                label={menu?.name}
                                key={index}
                                className="m-2 pointer"
                                onClick={() => this.handleChipClick(menu)}
                              />
                            ))}
                          </div>
                        </Accordion.Collapse>
                      </Accordion>
                    ) : (
                      <div className="kt-notification__item-selected font-weight-bold my-3">
                        <Chip label={active_organisation_name} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="kt-notification">
              <div className="kt-notification__custom justify-content-center">
                <Button
                  onClick={() => this.mailCoachAction()}
                  className="btn darkGrey-button btn-sm btn-bold w-50 text-white"
                  label="Go To Mail"
                >
                  Go To Mail
                </Button>
              </div>
              <div className="kt-notification__custom justify-content-center">
                <Button
                  onClick={() => this.forumAction()}
                  className="btn darkGrey-button btn-sm btn-bold w-50 text-white"
                  label="Go To Forum"
                >
                  Go To Forum
                </Button>
              </div>
              <div className="kt-notification__custom justify-content-center">
                <Link
                  to="/logout"
                  className="btn darkGrey-button btn-sm btn-bold w-50 text-white"
                >
                  Sign Out
                </Link>
              </div>
            </div>
            {user?.organisation?.id === 4 &&
            <div className="kt-notification">
              <div className="kt-notification__custom justify-content-center">
                <Button
                    onClick={() => this.accountSwitch()}
                    className="btn darkGrey-button btn-sm btn-bold w-50 text-white"
                    style={{textTransform: 'initial'}}
                >
                  Switch Account
                </Button>
              </div>
            </div>
          }
          </Dropdown.Menu>
        </Dropdown>
        {/*<AccountSwitch open={true}/>*/}
        {this.state.showAccountSwitch && <AccountSwitch  open={true} onClose={ () => this.handleCloseDialog()}/>}
      </Fragment>
    );
  }
}

const mapStateToProps = ({ auth: { user } }) => ({
  user
});

const actions = {
  callTabManager: tabRedux.actions.callTabManager,
  requestUser: auth.actions.requestUser
};

export default connect(mapStateToProps, actions)(UserProfile);
