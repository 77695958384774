import React, { useContext, useState } from "react";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import EmailIcon from "@material-ui/icons/Email";
import EventIcon from "@material-ui/icons/Event";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import { connect } from "react-redux";
import ThemeHeader from "../../../../../partials/content/ThemeHeader";
import ThemeButton from "../../../../../partials/button/ThemeButton";
import clsx from "clsx";
import NoteRemoteFormButton from "../../../partials/RemoteFormButton";
import DefaultModal from "../../../../../partials/modal/DefaultModal";
import Email from "../../../../../partials/component/email/Email";
import BuyerReferral from "../../buyerReferral/BuyerReferral";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import CallReminder from "../../../../../partials/component/callReminder/CallReminder";
import BuyerOpenHome from "../../buyerOpenHome/BuyerOpenHome";
import { BuyerProfileContext } from "../BuyerProfileContextProvider";
import moment from "moment";
import Appointment from "../../../../diary/partials/modals/appointment/Appointment";
import SmsWrap from "../../../../../partials/component/sms/SmsWrap";
import ToolTipForSvg from "../../../../../partials/tooltip/ToolTipForSvg";
import _isEmpty from "lodash/isEmpty";

import useSendBuyerAppPromo from "../../../../../hooks/useSendBuyerAppPromo";

import BuyersAppDropdown from "../../../partials/BuyersAppDropdown";

const BuyerProfileHeader = (props) => {
  const {
    closeModal,
    // hideNav,
    selectedHeaderTab,
    // resetTableData,
    setPageCount,
    updateTable,
      triggerFrom,
    refresh = () => {},
  } = props;

  const {
    profile,
    buyerId,
    buyerList,
    buyerIndex,
    continuousMode,
    setContinuousMode,
    changeDirection,
    buyerLoading,
    setBuyerLoading,
    buyerNotContacted,
    // buyersTableUpdate,
    matchedListingSelected,
    filters,
    updateContext,
  } = useContext(BuyerProfileContext);

  const [showSms, setShowSms] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [showReferral, setShowReferral] = useState(false);
  const [showAppointment, setShowAppointment] = useState(false);
  const [showCallReminder, setShowCallReminder] = useState(false);
  const [showOpenHome, setShowOpenHome] = useState(false);
  const [fillFormData, setFillFormData] = useState({});

  const sendBuyersAppPromo = useSendBuyerAppPromo();

  const handleDoorKnock = () => {
    if (continuousMode && buyerList && buyerList?.length !== buyerIndex) {
      handleClickedRight("right");
      setPageCount((count) => count + 1);
    }
  };

  const handleClickedLeft = () => {
    changeDirection("left");
  };

  const handleClickedRight = () => {
    changeDirection("right");
    setPageCount((count) => count + 1);
  };

  return (
    <>
      {showSms ? (
        <DefaultModal
          size="xl"
          centered
          show={showSms}
          onHide={() => setShowSms(false)}
        >
          <SmsWrap
            closeModal={() => setShowSms(false)}
            data={[profile?.primary_contact]}
          />
        </DefaultModal>
      ) : null}

      {showEmail ? (
        <DefaultModal
          size="xl"
          centered
          show={showEmail}
          onHide={() => setShowEmail(false)}
        >
          <Email
            closeModal={() => setShowEmail(false)}
            data={[profile?.primary_contact]}
          />
        </DefaultModal>
      ) : null}

      {showAppointment ? (
        <DefaultModal
          size="xl"
          centered
          show={showAppointment}
          onHide={() => setShowAppointment(false)}
        >
          <Appointment
            setFillFormData={setFillFormData}
            fillFormData={fillFormData}
            initialValues={{
              starts: moment().format("YYYY-MM-DDTHH:mm"),
              ends: moment().format("YYYY-MM-DDTHH:mm"),
              contacts: [profile.primary_contact],
              app_type: 2,
              price_from: profile?.price_from,
              price_to: profile?.price_to,
              listings: matchedListingSelected?.map((item) => item?.listing),
            }}
            closeModal={() => setShowAppointment(false)}
          />
        </DefaultModal>
      ) : null}
      {showReferral ? (
        <DefaultModal
          size="xl"
          centered
          show={showReferral}
          onHide={() => setShowReferral(false)}
        >
          <BuyerReferral
            buyerId={buyerId}
            profile={profile}
            closeModal={() => setShowReferral(false)}
          />
        </DefaultModal>
      ) : null}

      {showCallReminder ? (
        <DefaultModal
          size="xl"
          centered
          show={showCallReminder}
          onHide={() => setShowCallReminder(false)}
        >
          <CallReminder
            buyerId={buyerId}
            contactId={profile.primary_contact.id}
            closeModal={() => setShowCallReminder(false)}
            initialValues={{ for: props?.user?.contact?.id }}
          />
        </DefaultModal>
      ) : null}

      {showOpenHome ? (
        <DefaultModal
          size="xl"
          centered
          show={showOpenHome}
          onHide={() => setShowOpenHome(false)}
        >
          <BuyerOpenHome
            profile={profile}
            buyerId={buyerId}
            closeModal={() => setShowOpenHome(false)}
          />
        </DefaultModal>
      ) : null}

      <ThemeHeader
        noScroll
        left={
          <div className="pl-4 ml-2">
            <NoteRemoteFormButton
              className={
                !profile?.contacts?.length ||
                buyerLoading ||
                selectedHeaderTab !== 0
                  ? "disable-prospect-header-btn"
                  : ""
              }
              disabled={
                !profile?.contacts?.length ||
                buyerLoading ||
                selectedHeaderTab !== 0
              }
              label={"BC"}
              // handleDoorKnock={handleDoorKnock}
              setNAButtonLoading={setBuyerLoading}
              formName="buyer-notes"
            />
            <NoteRemoteFormButton
              className={
                !profile?.contacts?.length ||
                buyerLoading ||
                selectedHeaderTab !== 0
                  ? "disable-prospect-header-btn"
                  : ""
              }
              disabled={
                !profile?.contacts?.length ||
                buyerLoading ||
                selectedHeaderTab !== 0
              }
              label={"NA"}
              // handleDoorKnock={handleDoorKnock}
              setNAButtonLoading={setBuyerLoading}
              formName="buyer-notes"
            />
          </div>
        }
        className="pb-3"
        right={
          <>
            <BuyersAppDropdown
              disabled={false}
              chooseOption={sendBuyersAppPromo}
              contacts={[profile]}
              onCallback={() => refresh(true)}
            />

            <ToolTipForSvg content="SMS">
              <ThemeButton onClick={() => setShowSms(true)} icon>
                <PhoneAndroidIcon />
              </ThemeButton>
            </ToolTipForSvg>
            <ToolTipForSvg content="Email">
              <ThemeButton onClick={() => setShowEmail(true)} icon>
                <EmailIcon />
              </ThemeButton>
            </ToolTipForSvg>
            <ToolTipForSvg content="Appointment">
              <ThemeButton onClick={() => setShowAppointment(true)} icon>
                <EventIcon />
              </ThemeButton>
            </ToolTipForSvg>
            <ToolTipForSvg content="Open Home">
              <ThemeButton icon onClick={() => setShowOpenHome(true)}>
                <i className="fas fa-door-open"></i>
              </ThemeButton>
            </ToolTipForSvg>
            <ToolTipForSvg content="Call Reminder">
              <ThemeButton onClick={() => setShowCallReminder(true)} icon>
                <AccessTimeIcon />
              </ThemeButton>
            </ToolTipForSvg>
            <ToolTipForSvg content="Referral">
              <ThemeButton onClick={() => setShowReferral(true)} icon>
                <MonetizationOnIcon />
              </ThemeButton>
            </ToolTipForSvg>
            {buyerList && buyerList.length > 1 ? (
              <div className={clsx("btn-toolbar nav-icon-button")}>
                <div className="btn-group">
                  <ThemeButton
                    disabled={
                      buyerLoading ||
                      buyerList[0]?.id === buyerId ||
                      (continuousMode && buyerNotContacted[0]?.id) ===
                        buyerId ||
                      _isEmpty(profile)
                    }
                    onClick={() => handleClickedLeft()}
                    icon
                  >
                    <i
                      className={
                        buyerLoading ||
                        buyerList[0]?.id === buyerId ||
                        _isEmpty(profile)
                          ? "disable-prospect-header-btn fas fa-caret-left"
                          : "fas fa-caret-left"
                      }
                    ></i>
                  </ThemeButton>
                  <ThemeButton
                    className={clsx({
                      "green-icon-button": continuousMode,
                    })}
                    onClick={() => setContinuousMode(!continuousMode)}
                    icon
                  >
                    <i className="fas fa-infinity    "></i>
                  </ThemeButton>
                  <ThemeButton
                    // disabled={buyerLoading || buyerList[0].id === buyerId || (continuousMode &&buyerNotContacted[0].id) === buyerId ||_isEmpty(profile)}
                    disabled={
                      buyerLoading ||
                      _isEmpty(profile) ||
                      buyerList[buyerList.length - 1].id === buyerId
                    }
                    onClick={() => handleClickedRight()}
                    icon
                  >
                    <i
                      className={
                        buyerLoading || _isEmpty(profile)
                          ? "disable-prospect-header-btn fas fa-caret-right"
                          : "fas fa-caret-right"
                      }
                    ></i>
                  </ThemeButton>
                </div>
              </div>
            ) : null}

            <ThemeButton
              className="lightGrey-button"
              onClick={() => {

                localStorage.removeItem("note");
                // resetTableData(filters)
                if (triggerFrom != "OpenHomeBuyers" && triggerFrom != "SalesMeetingAAABuyer" && triggerFrom != "DashBoardAAABuyer") {
                  updateTable(filters);
                }
                if(triggerFrom == "SalesMeetingAAABuyer" || triggerFrom != "DashBoardAAABuyer"){
                  closeModal(true);
                }
                closeModal();
                // updateTable(filters);
                // buyersTableUpdate(filters);
              }}
            >
              Close
            </ThemeButton>
          </>
        }
      />
    </>
  );
};

const mapStateToProps = ({ buyers, auth }) => ({
  buyers: buyers,
  user: auth.user,
});

const actions = {};

export default connect(mapStateToProps, actions)(BuyerProfileHeader);
