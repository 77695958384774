import React, { Fragment } from "react";
import { Accordion, Col, Form, Row } from "react-bootstrap";
import { Field } from "redux-form";
import TextInput from "../../form/TextInput";
import CustomToggle from "../customToggle/CustomToggle";

export const ContactInformation = () => {

  const onChange = e => {
    let regex = /(^[0-9,+]+$)+/g
    if (!regex.test(e.target.value) && e.target.value.length) {
      e.preventDefault();
    }
  };

  return (
    <Fragment>
      <CustomToggle eventKey="0" rightArrow={true} tabIndex={'-1'}>Contact Information</CustomToggle>
      <Accordion.Collapse className="mb-5" eventKey="0">
        <Form.Group as={Row}>
          <Col className="pb-1" sm={12}>
            <Field
              name="name"
              component={TextInput}
              placeholder="Name"
              label="Name"
            />
          </Col>
          <Col className="pb-1" sm={12}>
            <Field
              name="phone"
              component={TextInput}
              placeholder="Phone Number"
              label="Phone Number"
              onChange={onChange}
            />
          </Col>
          <Col className="pb-1" sm={12}>
            <Field
              name="email"
              component={TextInput}
              placeholder="Email Address"
              label="Email Address"
            />
          </Col>
          <Col className="pb-1" sm={12}>
            <Field
              name="company_name"
              component={TextInput}
              placeholder="Company Name"
              label="Company Name"
            />
          </Col>
          <Col className="pb-1" sm={12}>
            <div className="row">
              <div className="col-6 pr-2">
                <Field
                  name="street_unit"
                  component={TextInput}
                  placeholder="Unit"
                  label="Unit"
                />
              </div>
              <div className="col-6 pl-1">
                <Field
                  name="street_number"
                  component={TextInput}
                  placeholder="Street No."
                  label="Street No."
                />
              </div>
            </div>
          </Col>
          <Col className="pb-1" sm={12}>
            <Field
              name="street_name"
              component={TextInput}
              placeholder="Street Name"
              label="Street Name"
            />
          </Col>
          <Col className="pb-1" sm={12}>
            <Field
              name="suburb_name"
              component={TextInput}
              placeholder="Suburb"
              label="Suburb"
            />
          </Col>
          <Col className="pb-1" sm={12}>
            <Field
              name="postcode"
              component={TextInput}
              placeholder="Postcode"
              label="Postcode"
            />
          </Col>
        </Form.Group>
      </Accordion.Collapse>
    </Fragment>
  );
};
