import React, { useEffect, useState, Fragment } from "react";
import { getMenu } from "../../../../GeneralApi/GeneralApi";
import LoadingSkeleton from "../../../../partials/display/skeleton/LoadingSkeleton";

import { toast } from "react-toastify";
import MicrositesSettingForm from "./DigitalTemplateSettingForm";

const DigitalTemplateSetting = ({ closeModal }) => {
  const [email_template_category, setEmail_template_category] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    update();
  }, []);

  const update = () => {
    let query = "email_template_category";
    getMenu(query)
      .then(({ data: { data } }) => {
        setEmail_template_category(data.email_template_category);
        setLoading(false);
      })
      .catch(() => {});
  };

  const onFormSubmit = (values) => {
    console.log(values);

    const { email_template_category, ...others } = values;
  };

  return (
    <Fragment>
      {loading ? (
        <LoadingSkeleton height="40vh" />
      ) : (
        <MicrositesSettingForm
          initialValues={{
            email_template_category: email_template_category,
          }}
          onSubmit={onFormSubmit}
          closeModal={closeModal}
          email_template_category={email_template_category}
        />
      )}
    </Fragment>
  );
};

export default DigitalTemplateSetting;
