import React, { useState, useContext, createContext, useEffect } from "react";
// import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import _last from "lodash/last";
import _replace from "lodash/replace";
import {
  getProspectById,
  viewProspect,
  viewProspectData,
} from "../../curd/prospectsCrud";

import { createNewContact } from "../../../contacts/crud/contactsCrud";
import { updateProspect } from "../../../prospect/curd/prospectsCrud";

import {
  postProcessedContactInfo,
  convertFormData,
  getObjWithoutValues,
  getDateDiffInDays,
} from "../../../../_helpers/_utils/utils";

import { ProspectsContext } from "../../pages/prospects/ProspectsContextProvider";

import useLoggedUserInfo from "../../../../hooks/useLoggedUserInfo";

export const ProspectProfileContext = createContext({});
export const { Consumer } = ProspectProfileContext;

const PROPERTY_FEATURE_MAP = {
  bedrooms: 6,
  bathrooms: 5,
  carParks: 23,
};

const validatePeriod = (date, period, type) => {
  // If prospect had never ran APM autofill function
  if (!date) return true;

  const diffInDays = getDateDiffInDays(date);

  // Weeks
  if (type === 4 && diffInDays / 14 >= period) {
    return true;
  }
  // Months
  else if (type === 1 && diffInDays / 30 >= period) {
    return true;
  }
  return false;
};

const formatDate = (string) => {
  const dateArray = string.split("/");
  return `${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`;
};

const shouldRunPriceFinder = (contacts) => {
  if (contacts?.length) {
    const phones = [];

    for (let i = 0; i <= contacts?.length - 1; i++) {
      if (contacts[i].phones?.length) {
        phones.push(...contacts[i].phones);
      }
    }

    return phones?.length === 0;
  } else {
    return true;
  }
};

const ProspectProfileContextProvider = (props) => {
  const {
    prospects,
    selectedProspect,
    continuousMode,
    setContinuousMode,
    setBeen_contacted_arr = () => {},
  } = props;

  const [prospectProfile, setProspectProfile] = useState({});
  const [prospectIndex, setProspectIndex] = useState(null);
  const [prospectLoading, setProspectLoading] = useState(false);
  const [prospectId, setProspectId] = useState(selectedProspect);
  const [pc, setPc] = useState(null);
  const [dk, setDk] = useState(null);
  const [searchForContacts, setSearchForContacts] = useState(false);
  const [searchForContactsMessage, setSearchForContactsMessage] = useState("");
  const [updateNote, setUpdateNote] = useState(false);
  const { updateTable } = useContext(ProspectsContext);
  const loggedUser = useLoggedUserInfo();
  const [prospectApmDate, setProspectApmDate] = useState(false);

  let prospectNotContacted = prospects?.filter(
    (item) => item.been_contacted === 0
  );

  let last = _last(prospectNotContacted)?.id === prospectId;

  useEffect(() => {
    if (prospectProfile && prospects) {
      let index = prospects.findIndex((item) => item.id === prospectProfile.id);
      setProspectIndex(index);
      setProspectLoading(false);
      setBeen_contacted_arr(
        (last && last) || prospectNotContacted?.length === 0 ? true : false
      );
    }

    if (loggedUser?.organisation?.id === 252 && loggedUser?.apm_autofill) {
      const lastApmAutofill = prospectProfile?.apm_autofill;
      // If the user enabled the autofill function BUT didn't set the period
      // by default the system will user monthly as default
      const isValidPeriod = validatePeriod(
        lastApmAutofill,
        loggedUser?.apm_autofill_period ?? 1,
        loggedUser?.apm_autofill_period_type ?? 4
      );
      setProspectApmDate(isValidPeriod);
      if (isValidPeriod) {
        // Shutting down for now (29/03/2023) because some offices are complaining that
        // the system was slowed down due this feature
        // if (
        //   !prospectProfile?.purchase_date ||
        //   prospectProfile?.purchase_date === "0000-00-00" ||
        //   shouldRunPriceFinder(prospectProfile?.contacts)
        // ) {
        // if (false) {
        setSearchForContacts(true);
        setSearchForContactsMessage("Searching information on APM...");
        // }
      }
    }
  }, [prospectProfile]);

  useEffect(() => {
    if (searchForContacts) {
      if (prospectProfile?.property?.address?.full_address) {
        getPropertyData();
      } else {
        setSearchForContacts(false);
      }
    }
  }, [searchForContacts]);

  const getPropertyData = () => {
    viewProspect({
      address: prospectProfile?.property?.address?.full_address,
    })
      .then((res) => {
        if (res?.status === 200) {
          if (res?.data?.data) {
            if (res.data.data.length === 1) {
              getPropertyDetails(res.data.data[0]);
            } else {
              changeSearchContactMessage();
            }
          }
        } else {
          changeSearchContactMessage();
        }
      })
      .catch((err) => {
        // console.log("error loading", err);
        changeSearchContactMessage();
      });
  };

  const getPropertyDetails = async (property) => {
    if (property?.property?.id) {
      viewProspectData({ property: property?.property?.id })
        .then((res) => {
          if (res?.data?.data) {
            const { phoneNumbers, features, saleHistory } = res?.data?.data;

            const saleHistoryData = {};
            const newFeatures = [];

            if (
              !prospectProfile?.purchase_date ||
              prospectProfile?.purchase_date === "0000-00-00"
            ) {
              if (saleHistory?.sales?.length) {
                saleHistoryData["purchase_date"] = formatDate(
                  saleHistory?.sales[0].saleDate.display
                );
                saleHistoryData["purchase_price"] = _replace(
                  saleHistory?.sales[0].price.display,
                  "$",
                  ""
                ).trim();
                saleHistoryData["purchase_price"] = _replace(
                  saleHistoryData["purchase_price"],
                  new RegExp(",", "g"),
                  ""
                );
              }

              Object.keys(features).forEach((feat) => {
                if (PROPERTY_FEATURE_MAP[feat]) {
                  newFeatures.push({
                    feature: PROPERTY_FEATURE_MAP[feat],
                    qty: features[feat],
                  });
                }
              });
            }

            // let newContacts = []
            const newContacts = [...prospectProfile.contacts];

            if (
              phoneNumbers?.length > 0 &&
              shouldRunPriceFinder(prospectProfile?.contacts)
            ) {
              const currentPhones = [];
              for (let j = 0; j <= prospectProfile.contacts.length - 1; j++) {
                const contactPhones = prospectProfile.contacts[j].phones;
                if (contactPhones?.length) {
                  contactPhones.forEach((cp) => {
                    currentPhones.push(cp.value);
                  });
                }
              }

              phoneNumbers.forEach((phone) => {
                if (phone?.number) {
                  const addPhoneNumber = currentPhones?.filter(
                    (pc) => pc === phone?.number
                  );

                  if (addPhoneNumber.length === 0) {
                    newContacts.push({
                      first_name: phone?.initials ?? phone?.surname,
                      last_name: phone?.surname ?? "",
                      phones: [
                        {
                          type: 1,
                          value: phone.number,
                          unsubscribe_date: phone?.doNotCall
                            ? new Date()
                            : null,
                        },
                      ],
                    });
                  }
                }
              });
            }

            if (
              // newContacts.length !== prospectProfile.contacts?.length ||
              // newFeatures.length ||
              // saleHistoryData?.purchase_date
              prospectApmDate
            ) {
              updateProspectInfoWithApmAutofill(
                newContacts,
                newFeatures,
                saleHistoryData
              );
            } else {
              changeSearchContactMessage();
            }
          } else {
            changeSearchContactMessage();
          }
        })
        .catch((err) => {
          changeSearchContactMessage();
        });
    } else {
      changeSearchContactMessage();
    }
  };

  const updateProspectInfoWithApmAutofill = async (
    contacts = [],
    features = [],
    propertyHistory = {}
  ) => {
    const prospectData = { ...propertyHistory, apm_autofill: new Date() };

    if (contacts.length !== prospectProfile.contacts?.length) {
      let contactsId = [];
      await Promise.all(
        contacts.map((item) => {
          return new Promise((resolve, reject) => {
            let formatedValue = postProcessedContactInfo(item);

            const formData = convertFormData(
              getObjWithoutValues([undefined, null], formatedValue)
            );

            createNewContact(formData)
              .then(({ data }) => {
                resolve(data);
                contactsId = [...contactsId, data?.data?.id];
              })
              .then(({ data }) => {})
              .catch((e) => {
                resolve({});
              });
          });
        })
      );
      prospectData.contacts = contactsId;
    }

    if (features?.length && prospectProfile?.property?.id) {
      prospectData.property = {
        id: prospectProfile?.property.id,
        features: features,
      };
    }

    const formData = convertFormData(
      getObjWithoutValues([undefined, null], prospectData)
    );

    formData.append("_method", "PATCH");

    // if (notes.length) {
    //   const payload = {
    //     prospect: prospectId,
    //     detail: notes.join("\r\n"),
    //     type: 4,
    //   };

    //   const formData = convertFormData(payload);
    //   createNotes(formData)
    //     .then((res) => {
    //       setUpdateNote(true);
    //     })
    //     .catch((err) => {
    //       console.error("Error saving notes", err);
    //     });
    // }

    updateProspect(prospectId, formData)
      .then(({ data }) => {
        update();
        if (updateTable) updateTable({ added: true });

        changeSearchContactMessage(
          "Saving information into the system. Please wait."
        );
        toast.success("Prospect Saved Successfully");
      })
      .catch((e) => {
        changeSearchContactMessage("Error while prospect saving.");
        toast.error(`Error while prospect saving (${e})`);
      });
  };

  const changeSearchContactMessage = (
    message = "No information found.",
    autoClose = true
  ) => {
    setSearchForContactsMessage(message);
    if (autoClose) {
      setTimeout(() => {
        setSearchForContacts(false);
      }, 5000);
    }
  };

  const changeDirection = (direction) => {
    localStorage.removeItem("note");
    setProspectLoading(true);
    let index;
    let newId;
    if (continuousMode) {
      index = prospectNotContacted.findIndex(
        (item) => item.id === prospectProfile.id
      );
    } else {
      index = prospects.findIndex((item) => item.id === prospectProfile.id);
    }

    let newIndex;
    if (direction === "left") {
      newIndex = index - 1;
    }
    if (direction === "right") {
      newIndex = index + 1;
    }

    if (continuousMode) {
      newId = prospectNotContacted[newIndex]?.id;
    } else {
      newId = prospects[newIndex]?.id;
    }

    if (newId) {
      setProspectId(newId);
    }
  };

  const update = () => {
    getProspectById(prospectId)
      .then(({ data: { data } }) => {
        setProspectProfile(data);
      })
      .catch(() => {});
  };

  return (
    <ProspectProfileContext.Provider
      value={{
        prospects,
        continuousMode,
        // setContinuousMode,
        prospectProfile,
        setProspectProfile,
        prospectIndex,
        changeDirection,
        prospectId,
        updateContext: update,
        setProspectId,
        prospectLoading,
        setProspectLoading,
        setContinuousMode,
        prospectNotContacted,
        setPc,
        setDk,
        pc,
        dk,
        searchForContacts,
        searchForContactsMessage,
        updateNote,
        setUpdateNote,
      }}
    >
      {props.children}
    </ProspectProfileContext.Provider>
  );
};

export default ProspectProfileContextProvider;
