import React, { Fragment } from "react";
import { List, ListItem } from "@material-ui/core";
import cuid from "cuid";
import { stringCompare } from "../../../../../_helpers/_utils/utils";
import clsx from "clsx";
import { Row, Col } from "react-bootstrap";
import PreviewEmail from "./type/PreviewEmail";
import PreviewSms from "./type/PreviewSms";

function PreviewEmailSmsList({ component, closeModal }) {
  console.log(component);
  switch (component) {
    case "Appointment Notification Email to Seller":
      return <PreviewEmail closeModal={closeModal} />;
    case "Appointment Notification Email to Buyer":
      return <PreviewEmail closeModal={closeModal} />;
    case "Appointment Reminder SMS to Seller":
      return <PreviewSms closeModal={closeModal} />;
    case "Appointment Reminder SMS to Buyer":
      return <PreviewSms closeModal={closeModal} />;
    default:
      return "";
  }
}

const PreviewEmailSms = (props) => {
  const [component, setComponent] = React.useState(
    "Appointment Notification Email to Seller"
  );
  const handleChangeComponent = (e) => {
    setComponent(e);
  };
  return (
    <Fragment>
      <Row>
        <Col xs={4} className="pt-3">
          <div className="d-flex flex-column bd-highlight border-right mb-3">
            <div>
              <h5 className="title p-4">Preview an Email or Sms</h5>
            </div>
            <List className="drawer-list">
              {[
                "Appointment Notification Email to Seller",
                "Appointment Notification Email to Buyer",
                "Appointment Reminder SMS to Seller",
                "Appointment Reminder SMS to Buyer",
              ].map((text, index) => (
                <ListItem
                  onClick={() => handleChangeComponent(text)}
                  key={cuid()}
                  className={clsx({ active: stringCompare(text, component) })}
                >
                  {text}
                </ListItem>
              ))}
            </List>
          </div>
        </Col>
        <Col xs={8}>
          <PreviewEmailSmsList
            closeModal={props.onHide}
            component={component}
          />
        </Col>
      </Row>
    </Fragment>
  );
};

export default PreviewEmailSms;
