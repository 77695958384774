import React from "react";
import { Grid, TextField } from "@material-ui/core";
import MicrositeFormLabel from "./MicrositeFormLabel";

const MicrositeTextarea = ({
  name,
  label,
  value,
  placeholder,
  disabled,
  onChange,
  multiline = true,
  maxlength,
  ...rest
}) => (
  <Grid container direction="row" style={{ marginBottom: 12 }}>
    <Grid item xs={12}>
      <MicrositeFormLabel label={label} disabled={disabled} />
    </Grid>
    <Grid item xs={12}>
      <TextField
        name={name}
        placeholder={placeholder}
        label={false}
        multiline={multiline}
        className="w-100"
        minRows={4}
        value={value}
        disabled={disabled}
        onChange={onChange}
        inputProps={{ maxlength }}
        {...rest}
      />
    </Grid>
  </Grid>
);

export default MicrositeTextarea;
