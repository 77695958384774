/* eslint-disable no-restricted-imports */
import React, {
    Fragment,
    useContext,
    useCallback,
    useState,
    useEffect,
} from "react";

import { connect } from "react-redux";
// import DrawerForm from "../../partials/prospectsTable/DrawerForm";
import _debounce from "lodash/debounce";

import TableWithDrawer from "../../../../partials/layout/pageLayout/TableWithDrawer";
import WindowCardsTable from "./WindowCardsTable";
import WindowCardDrawerForm from "./WindowCardDrawerForm";
import { WindowCardContext } from "./WindowCardContextProvider";
import NewWindowCard from "./NewWindowCard";

const WindowCards = (props) => {
    const [open, setOpen] = useState(false);
    const [showAddNewWindowCard, setShowAddNewWindowCard] = useState(false);
    const {
        template,
        updateTableData,
        setActiveTab,
        setSelectedSocialTemplate
    } = useContext(WindowCardContext);

    const handleClose = () => {
        setShowAddNewWindowCard(false)
        setActiveTab('template')
        setSelectedSocialTemplate({})
    }

    const resetFilterForm = () => {
        // update();
        // dispatch(reset("digital-template-drawer-form"));
    }



    return (
        <>

            {
                showAddNewWindowCard ? <NewWindowCard closeModal={handleClose} updateTableData={updateTableData}/> :
                    < Fragment >
                        <TableWithDrawer
                            handleDrawer={open}
                            drawerClosed={() => setOpen(false)}
                            table={
                                <Fragment>
                                    <WindowCardsTable
                                        setOpen={setOpen}
                                        setShowAddNewWindowCard={setShowAddNewWindowCard}
                                        template={template}
                                    />
                                </Fragment>
                            }
                            drawer={(resetFilter) => (
                                <Fragment>
                                    <WindowCardDrawerForm

                                    />
                                </Fragment>
                            )}
                        />
                    </Fragment >
            }
        </>
    );
};

const mapStateToProps = () => ({
});

// export default connect(mapStateToProps, buyersRedux.actions)(BuyersPage);
export default connect(mapStateToProps, {})(WindowCards
);
