import React, { useState, createContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMenu, getAllMembers } from "../../../../GeneralApi/GeneralApi";
import { formToQueryString } from "../../../../_helpers/_utils/utils";
import { getAllProspects, getZoneAnalysis } from "../../curd/prospectsCrud";

export const ZonesContext = createContext({ zones: [] });
export const { Consumer } = ZonesContext;

const queryString = require("query-string");

const ZoneAnalysisContextProvider = (props) => {
  const user = useSelector((state) => state.auth.user.contact);
  const [zones, setZones] = useState([]);
  const [tableOptions, setTableOptions] = useState(false);
  const [drawerOptions, setDrawerOptions] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState("");
  const [streetName, setStreetName] = useState("");
  const [suburb, setSuburb] = useState("");
  const [team, setTeam] = useState("");
  const [addZoneSuburb, setAddZoneSuburb] = useState("");
  const [addZoneStreet, setAddZoneStreet] = useState("");
  const [addZoneSaleState, setAddZoneSaleState] = useState("");
  const [zoneAnalysis_of, setZoneAnalysis_of] = useState(user?.id);
  const [members, setMembers] = useState(null);
  const [otherResData, setOtherResData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [initialUserID, setInitialUserID] = useState(user?.id);

  useEffect(() => {
    getAllMembers()
      .then(({ data: { data } }) => {
        setMembers(
          data.map((s) => ({
            name: `${s.initial}`,
            id: s.contact.id,
            optionTooltipTitle:`${s?.contact?.first_name ?? ''} ${s?.contact?.last_name ?? ''}`,
          }))
        );
      })
      .catch((e) => { });
  }, []);

  useEffect(() => {
    update();
  }, [zoneAnalysis_of, props.ZONE_TYPE]);

  const fetchMoreData = () => {
    if (otherResData?.next_page_url) {
      update({ page: otherResData.current_page + 1, loadMore: true });
    }
  };

  const handleGlobalSearch = (value) => {
    update({ name: value });
  };

  const onSubmitDrawerForm = (prams) => {
    setStreetName(prams.street_name);
    setSuburb(prams.suburb);
    setTeam(prams.team);
    console.log(streetName, suburb, team, "ffilter");
    let query = queryString.stringify(prams);
    if (Object.keys(prams).length > 0) {
      update(prams);
      // setProspects(query);
    } else {
      let q = {
        name: "",
      };
      query = queryString.stringify(q);
      // update(query);
      // setProspects(query);
      // console.log(prams);
    }
  };

  const update = (value) => {
    setIsLoading(true);
    let obj = {
      type: props.ZONE_TYPE,
      limit: 30,
      ...value,
    };
    if (zoneAnalysis_of != "all") {
      obj.contacts = zoneAnalysis_of;
    }
    if (value?.page) {
      obj.page = value.page;
    }

    let queryString = formToQueryString(obj);
    getZoneAnalysis(queryString).then((res) => {

      const { data, ...rest } = res.data;
      if (Array.isArray(data)) {
        if (value?.loadMore) {
          setZones(zones.concat(data));
        } else {
          setZones(data);
        }
        if (value?.team && zoneAnalysis_of !== value?.team) {
          setZoneAnalysis_of(value.team);
        }
      }

      setIsLoading(false);
      setOtherResData(rest);

    });
  };

  return (
    <ZonesContext.Provider
      value={{
        isLoading,
        zones,
        members,
        onSubmitDrawerForm,
        fetchMoreData,
        handleGlobalSearch,
        tableOptions,
        drawerOptions,
        setTeam,
        setSelectedTeam,
        addZoneSuburb,
        addZoneStreet,
        addZoneSaleState,
        zoneAnalysis_of,
        setZoneAnalysis_of,
        update,
        ZONE_TYPE: props.ZONE_TYPE,
        initialUserID
      }}
    >
      {props.children}
    </ZonesContext.Provider>
  );
};

export default ZoneAnalysisContextProvider;
