import axios from "axios";
// import faker from "faker";
// const WiseBerryAPI = process.env.REACT_APP_WISEBERRY_API;
const WiseBerryAPI = process.env.REACT_APP_WISEBERRY_API;

const WiseBerryMember = WiseBerryAPI + "member";
const WiseBerryRecentlyDeleted = WiseBerryAPI + "recently-deleted";
const WiseBerryTeam = WiseBerryAPI + "team";
const WiseBerryBonus = WiseBerryAPI + "bonus";
const ORGANISATION_URL = WiseBerryAPI + "organisation";
const APM_SETUP = WiseBerryAPI + "apm";
const LISTING_UPLOAD_PORTAL = WiseBerryAPI + "listing-upload-portals";
const WiseBerryOrganisationServicer = WiseBerryAPI + "organisation-servicer";
const ORGANISATION_SETTING = WiseBerryAPI + "organisation/notification-setting";
const EMAIL_LAYOUTS = WiseBerryAPI + "email-layouts";
const MARKETING_ITEM = WiseBerryAPI + "marketing-item";
const MARKETING_PACKAGES = WiseBerryAPI + "organisation/marketing-package";
const LISTING_BENEFITS = WiseBerryAPI + "benefits";
const BILLING_INCLUSIONS = WiseBerryAPI + "organisation/inclusions";
const INVOICES = WiseBerryAPI + "invoice";
const SUBSCRIBE = WiseBerryAPI + "subscription";
const TESTIMONIAL = WiseBerryAPI + "testimonial";
const COMMUNITY_INVOLVEMENT = WiseBerryAPI + "community-involvement";
const BLOG = WiseBerryAPI + "blog";
const MarketingAutomationType = WiseBerryAPI + "marketing-automation";

const PLAN = WiseBerryAPI + "plan";

export const ASSETS_URL = WiseBerryAPI + "asset";

export const ROLE = WiseBerryAPI + "role";

// File Upload
export function getMemberInfoById(id) {
  return axios.get(WiseBerryMember + "/" + id);
}

export function validateUsername(username) {
  return axios.get(WiseBerryMember + "/" + username);
}

export function createNewMember(prams) {
  return axios.post(WiseBerryMember, prams, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function updateMember(id, prams) {
  return axios.post(WiseBerryMember + "/" + id, prams, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function getAllMembers(props) {
  return axios.get(WiseBerryMember + (props ? "?" + props : ""));
}

// Testimonials

export function getAllTestimonials(props) {
  return axios.get(TESTIMONIAL + (props ? "?" + props : ""));
}

export function testimonialAPI(id = null, params) {
  let url = TESTIMONIAL;
  if (id) {
    url = TESTIMONIAL + "/" + id;
  }
  console.log("++ url", url);
  return axios.post(url, params, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

// Blogs

export function getAllBlogs(props) {
  return axios.get(BLOG + (props ? "?" + props : ""));
}

export function updateBlog(id = null, params) {
  let url = BLOG;
  if (id) {
    url = BLOG + "/" + id;
  }
  console.log("++ url", url);
  return axios.post(url, params, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
export function getAllCommunityInvolvement(props) {
  return axios.get(COMMUNITY_INVOLVEMENT + (props ? "?" + props : ""));
}

export function updateCommunityInvolvement(id = null, params) {
  let url = COMMUNITY_INVOLVEMENT + "/create"
  if (id) {
    url = COMMUNITY_INVOLVEMENT + "/update/" + id
  }
  console.log(url,'url url');
  return axios.post(url, params, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function deleteCommunityInvolvement(data) {
  return axios.delete(COMMUNITY_INVOLVEMENT + "/delete", { data });
}

// Team

export function deleteTeamInfoById(id) {
  return axios.delete(WiseBerryTeam + "/" + id);
}

export function getTeamInfoById(id) {
  return axios.get(WiseBerryTeam + "/" + id);
}

export function createNewTeam(prams) {
  return axios.post(WiseBerryTeam, prams, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function updateTeam(id, prams) {
  return axios.post(WiseBerryTeam + "/" + id, prams, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function getAllTeams() {
  return axios.get(WiseBerryTeam);
}

// Team

// Servicer

export function deleteOrganisationServicerById(id) {
  return axios.delete(`${WiseBerryOrganisationServicer}/${id}`);
}

export function getOrganisationServicerById(id) {
  return axios.get(WiseBerryOrganisationServicer + "/" + id);
}

export function createNewOrganisationServicer(prams) {
  return axios.post(WiseBerryOrganisationServicer, prams, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function updateOrganisationServicer(id, prams) {
  return axios.post(WiseBerryOrganisationServicer + "/" + id, prams, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function getAllOrganisationServicers() {
  return axios.get(WiseBerryOrganisationServicer);
}

// Servicer

export function deleteMember(memberId) {
  return axios.delete(`${WiseBerryMember}/${memberId}`);
}

// Bonus

export function deleteBonusInfoById(id) {
  return axios.delete(WiseBerryBonus + "/" + id);
}

export function getBonusInfoById(id) {
  return axios.get(WiseBerryBonus + "/" + id);
}

export function getBonusInfo(prams) {
  return axios.get(WiseBerryBonus + "?" + prams);
}

// plan
export function getPlan(id) {
  return axios.get(PLAN);
}

export function postSubscribe(prams) {
  // return axios.delete(WiseBerryAPI + "role/bulk-delete" + id);

  return axios.post(SUBSCRIBE, prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function getSubscribe(prams) {
  // return axios.delete(WiseBerryAPI + "role/bulk-delete" + id);

  return axios.get(SUBSCRIBE + (prams ? "?" + prams : ""));
}
//

export function createNewBonus(prams) {
  return axios.post(WiseBerryBonus, prams, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function updateBonus(id, prams) {
  return axios.post(WiseBerryBonus + "/" + id, prams, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function deleteBonus(prams) {
  return axios.post(WiseBerryBonus + "/bulk-delete", prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function getAllBonus() {
  return axios.get(WiseBerryBonus);
}

// Bonus

// Recently Deleted

export function getAllRecentlyDeleted(prams) {
  return axios.get(
    WiseBerryRecentlyDeleted + "?limit=30" + (prams != null ? "&" + prams : "")
  );
}

export function restoreRecentlyDeleted(prams) {
  return axios.post(WiseBerryRecentlyDeleted, prams, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

// Recently Deleted

// Mandatory Notification

export function getOrganisationSetting(prams) {
  return axios.get(ORGANISATION_SETTING);
}

export function updateOrganisationSetting(prams) {
  return axios.post(ORGANISATION_SETTING, prams, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

// Mandatory Notification

// Branding

export function getAllEmailLayout() {
  return axios.get(EMAIL_LAYOUTS);
}

export function updateBranding(id, prams) {
  return axios.post(ORGANISATION_SETTING + "/" + id, prams, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

// Branding

export function updateOfficeDetails(id, prams) {
  return axios.post(ORGANISATION_URL + "/" + id, prams, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
export function getOfficeDetails(id) {
  return axios.get(ORGANISATION_URL + "/" + id);
}

export function updateApmSetup(id, prams) {
  return axios.post(APM_SETUP + "/" + id, prams, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
export function getApmSetup() {
  return axios.get(APM_SETUP);
}

export function getListingUploadPortals() {
  return axios.get(LISTING_UPLOAD_PORTAL);
}

export function getRoleManagement(props) {
  return axios.get(ROLE + (props ? "?" + props : ""));
}

export function updateRoleManagement(id, prams) {
  return axios.post(ROLE + "/" + id, prams, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function getRoleManagementById(id) {
  return axios.get(ROLE + "/" + id);
}
export function postRoleManagement(prams) {
  return axios.post(ROLE, prams, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
export function getPermissions() {
  return axios.get(WiseBerryAPI + "permission");
}
// export function deleteRoleManagement(id) {
//   return axios.delete(WiseBerryAPI+"organisation/role-management/"+id);
// }

export function deleteRoleManagement(prams) {
  // return axios.delete(WiseBerryAPI + "role/bulk-delete" + id);

  return axios.post(ROLE + "/bulk-delete", prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

// Assets Api

export function getAllAssets(prams) {
  return axios.get(ASSETS_URL + "?limit=30" + (prams ? "&" + prams : ""));
}

export function deleteAssets(prams) {
  return axios.post(ASSETS_URL + "/" + "bulk-delete", prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

// Cloud- Storage

export function getCloudStorage() {
  return axios.get(ORGANISATION_URL + "/cloud-storage");
}

export function postMarketingItem(prams) {
  return axios.post(MARKETING_ITEM, prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function deleteMarketingItem(prams) {
  return axios.post(MARKETING_ITEM + "/bulk-delete", prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function getMarketingItem(prams) {
  return axios.get(MARKETING_ITEM + (prams ? "?" + prams : ""));
}

export function getUpdateMarketingItems(prams) {
  return axios.post(MARKETING_ITEM + "/update-item", prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}
// Marketing Automation Type
export function getMarketingAutomationType(props) {
  return axios.get(MarketingAutomationType + (props ? "?" + props : ""));
}

export function postMarketingAutomationType(id,prams) {
    return axios.patch(MarketingAutomationType+'/'+id, prams, {
        headers: {
        "Content-Type": "application/json",
        },
    });
}

//End Marketing Automation Type


export function getMarketingPackages(prams) {
  return axios.get(MARKETING_PACKAGES + (prams ? "?" + prams : ""));
}

export function postMarketingPackagesByListing(prams) {
  return axios.post(MARKETING_PACKAGES + "/listing", prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}
export function postMarketingPackages(prams) {
  return axios.post(MARKETING_PACKAGES + "/bulk-update", prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function postMarketingPackagesState(prams) {
  return axios.post(MARKETING_PACKAGES + "/bulk-update-state", prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function getListingBenefits(prams) {
  return axios.get(LISTING_BENEFITS + (prams ? "?" + prams : ""));
}
export function getVideosBenefits(prams) {
  return axios.get(LISTING_BENEFITS + "/videos");
}
export function getStatusStats(prams) {
  return axios.get(LISTING_BENEFITS + "/stats-status");
}
export function getVideosGettingSold(prams) {
  return axios.get(LISTING_BENEFITS + "/getting-sold");
}
export function getImagesBenefits(prams) {
  return axios.get(LISTING_BENEFITS + "/images");
}
export function getStatsBenefits(prams) {
  return axios.get(LISTING_BENEFITS + "/stats");
}
export function postVideosBenefits(prams) {
  return axios.post(LISTING_BENEFITS + "/videos", prams, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function getBillingInclusions(prams) {
  return axios.get(BILLING_INCLUSIONS + (prams ? "&" + prams : ""));
}

export function getAllInvoice(prams) {
  return axios.get(INVOICES + (prams ? "?" : "") + (prams ? "&" + prams : ""));
}

export function updateLayout(id, prams) {
  return axios.post(ORGANISATION_URL + "/" + id, prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });

}
