import React, { useEffect, useState } from 'react'
import { TextField, InputAdornment } from '@material-ui/core'
import { OpenLeasedEditSettings } from './openLeasedEditSettings'
import TextInputAmount from '../../../../../partials/form/TextInputAmount'

export function OpenSoldEditSettings({ selectedNode }) {
    const [price, setPrice] = useState('')

    const getTargetElement = targetClassName => {
        return selectedNode.getElementsByClassName(targetClassName)[0]
    }
    useEffect(() => {
        let soldPrice = getTargetElement('price').innerText
        setPrice(soldPrice)

    }, [selectedNode])

    const handlePriceChange = e => {
        console.log('asfsdjfsdjfbsdjfbsdhf', e);
        getTargetElement('price').innerHTML = `$ ${e.toLocaleString()}`
        setPrice(e)
    }

    return (
        <div>
            <TextInputAmount
                type="number"
                value={price}
                id="price"
                onChange={handlePriceChange}
                allowNegative={false}
                placeholder="Price"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                    ),
                }}
            />
            <OpenLeasedEditSettings selectedNode={selectedNode} />

        </div>
    )
}
