/* eslint-disable no-restricted-imports */
import React, { useState } from "react";

import { alpha, makeStyles } from "@material-ui/core/styles";
import Dictaphone from "../dictaphone/Dictaphone";
import { TextField } from "@material-ui/core";
import _isEmpty from "lodash/isEmpty";

const useStyles = makeStyles((theme) => ({
  search: {
    display: "flex",
    position: "relative",
    border: "1px solid",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: 222,
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    fontSize: "16px !important",
  },
  inputInput: {
    padding: "8px",
    fontSize: "16px !important",
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
  },
}));

const TableFilter = ({
  handleGlobalSearch,
  length,
  searchFilter,
  onChangeText,
}) => {
  const classes = useStyles();
  const [text, setText] = useState("");
  //   const count = preGlobalFilteredRows.length
  const handleVoiceToSpeech = (voiceText) => {
    setText(voiceText);
    if (searchFilter?.current) {
      searchFilter.current.search_kw = voiceText;
    }
    if (!_isEmpty(voiceText)) {
      handleGlobalSearch(voiceText);
    }
  };

  // useEffect(() => {
  //   handleGlobalSearch(text)
  // }, [text]);

  const handleSearchChange = (e) => {
    setText(e.target.value);
    handleGlobalSearch(e.target.value);
    if (onChangeText) {
      onChangeText(e.target.value);
    }
  };

  // Global filter only works with pagination from the first page.
  // This may not be a problem for server side pagination when
  // only the current page is downloaded.
  // console.log(text);
  return (
    <div className="d-flex">
      <TextField
        value={text || ""}
        onChange={(e) => handleSearchChange(e)}
        placeholder={"Quick Search"}
        classes={{
          root: classes.inputRoot,
        }}
        style={{ minWidth: "160px" }}
      />
      <Dictaphone handleVoiceToSpeech={handleVoiceToSpeech} />
    </div>
  );
};

export default TableFilter;
