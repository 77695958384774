import axios from "axios";
import faker from "faker";
// const WiseBerryAPI = process.env.REACT_APP_WISEBERRY_API;
const WiseBerryAPI = process.env.REACT_APP_WISEBERRY_API;

// const WiseBerryAPI = 'http://127.0.0.1:8002/api';
export const LOGIN_URL = WiseBerryAPI + "/login";
export const REGISTER_URL = WiseBerryAPI + "/register";
export const REQUEST_PASSWORD_URL = WiseBerryAPI + "/forgot-password";
export const ME_URL = WiseBerryAPI + "/details";
export const To_Do = WiseBerryAPI + "member/to-do-items/";
export const To_Do_item = WiseBerryAPI + "to-do-item/";

export function getEventsData() {

    const now = new Date();
    const events = [
      {
          id: 0,
          title: 'All Day Event very long title',
          allDay: true,
          start: new Date(2020, 5, 0),
          end: new Date(2020, 5, 1),
      },
      {
          id: 1,
          title: 'Long Event',
          start: new Date(2015, 3, 7),
          end: new Date(2015, 3, 10),
      },

      {
          id: 2,
          title: 'DTS STARTS',
          start: new Date(2016, 2, 13, 0, 0, 0),
          end: new Date(2016, 2, 20, 0, 0, 0),
      },

      {
          id: 3,
          title: 'DTS ENDS',
          start: new Date(2016, 10, 6, 0, 0, 0),
          end: new Date(2016, 10, 13, 0, 0, 0),
      },

      {
          id: 4,
          title: 'Some Event',
          start: new Date(2015, 3, 9, 0, 0, 0),
          end: new Date(2015, 3, 10, 0, 0, 0),
      },
      {
          id: 5,
          title: 'Conference',
          start: new Date(2015, 3, 11),
          end: new Date(2015, 3, 13),
          desc: 'Big conference for important people',
      },
      {
          id: 6,
          title: 'Meeting',
          start: new Date(2015, 3, 12, 10, 30, 0, 0),
          end: new Date(2015, 3, 12, 12, 30, 0, 0),
          desc: 'Pre-meeting meeting, to prepare for the meeting',
      },
      {
          id: 7,
          title: 'Lunch',
          start: new Date(2020, 8, 12, 12, 0, 0, 0),
          end: new Date(2015, 3, 12, 13, 0, 0, 0),
          desc: 'Power lunch',
      },
      {
          id: 8,
          title: 'Meeting',
          start: new Date(2015, 3, 12, 14, 0, 0, 0),
          end: new Date(2015, 3, 12, 15, 0, 0, 0),
      },
      {
          id: 9,
          title: 'Happy Hour',
          start: new Date(2015, 3, 12, 17, 0, 0, 0),
          end: new Date(2015, 3, 12, 17, 30, 0, 0),
          desc: 'Most important meal of the day',
      },
      {
          id: 10,
          title: 'Dinner',
          start: new Date(2015, 3, 12, 20, 0, 0, 0),
          end: new Date(2015, 3, 12, 21, 0, 0, 0),
      },
      {
          id: 11,
          title: 'Birthday Party',
          start: new Date(2015, 3, 13, 7, 0, 0),
          end: new Date(2015, 3, 13, 10, 30, 0),
      },
      {
          id: 12,
          title: 'Late Night Event',
          start: new Date(2015, 3, 17, 19, 30, 0),
          end: new Date(2015, 3, 18, 2, 0, 0),
      },
      {
        id: 13,
        title: 'Today',
        start: new Date(new Date().setHours(new Date().getHours() - 3)),
        end: new Date(new Date().setHours(new Date().getHours() + 3)),
    },
      {
        id: 14,
        title: 'Today',
        start: new Date(new Date().setHours(new Date().getHours() - 1)),
        end: new Date(new Date().setHours(new Date().getHours() + 1)),
    },
      {
          id: 15,
          title: 'Today',
          start: new Date(new Date().setHours(new Date().getHours() - 3)),
          end: new Date(new Date().setHours(new Date().getHours() + 3)),
      },
      {
          id: 15,
          title: 'Point in Time Event',
          start: now,
          end: now,
      },
    ]
    // Authorization head should be fulfilled in interceptor.
    return events;
}

export function getDiaryTaskList(queryParams) {
    console.log(queryParams);
    // console.log(axios.get('/addressList', { queryParams }));
    return axios.get('/diary/tasks', { params: queryParams});
    // return axios.get('/prospect/modal/matchedInfo', { params: { prospectId: prospectId }})
  }



  export function getAllToDo() {
    return axios.get(To_Do);
  }

  export function postToDo(prams) {
    return axios.post(To_Do_item+"bulk-update",prams, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
  });
  }

  export function deleteToDo(id) {
    return axios.delete(To_Do_item+id);
  }
  
  export function updateToDo(id,prams) {
    return axios.post(To_Do_item+id,prams, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
  });
  }