import React, { Fragment, useEffect, useState } from "react";
import { Form, Col } from "react-bootstrap";
import { Field } from "redux-form";
import TextInput from "../TextInput";
import TextArea from "../TextArea";
import { IconButton, TextField } from "@material-ui/core";
import ThemeButton from "../../button/ThemeButton";
import ThemeHeader from "../../content/ThemeHeader";
import SortableTable from "../sortableTable/SortableTable";
import Handle from "../../component/handle/Handle";
import RadioInput from "../RadioInput";
import MultiSelect from "../MultiSelect";
import { getMenu } from "../../../GeneralApi/GeneralApi";
import LoadingSkeleton from "../../display/skeleton/LoadingSkeleton";
import MaterialSelect from "../../form-basic/select/MaterialSelect";
import { getDummyTableRow } from "../../../_helpers/_utils/utils";
import AccessControl from "../../../_ accessControl/AccessControl";

const FormFeatures = (props) => {
  const { fields, title, height, fromListingDetails } = props;
  const [featurePreference, setFeaturePreference] = useState(null);
  const [landSize, setLandSize] = useState(null);
  const [comments, setComments] = useState(null);
  const [featuresValue, setFeaturesValue] = useState("");
  const [features_menu, setFeatures_menu] = useState("");
  const [features, setFeatures] = useState({});
  const [features_menu_options, setFeatures_menu_options] = useState([]);
  const [features_menu_zone, setFeatures_menu_zone] = useState([]);
  const [checkAll, setCheckAll] = useState(false);
  const [selectedId, setSelectedId] = useState([]);

  useEffect(() => {
    let query = "feature,feature_menu_option";
    getMenu(query)
      .then(({ data: { data } }) => {
        setFeatures(data.feature);
        setFeatures_menu_options(
          data.feature_menu_option.filter((obj) => [1, 2, 3].includes(obj.id))
        );
        setFeatures_menu_zone(
          data.feature_menu_option.filter((obj) =>
            [6, 7, 8, 9, 10].includes(obj.id)
          )
        );
      })
      .catch(() => {});
  }, []);

  const handleAddFeature = () => {
    let obj = {};
    obj.feature = featuresValue ? featuresValue : "";
    obj.comments = comments;
    obj.qty = featurePreference;
    obj.feature_menu_input = landSize ? landSize : "";
    obj.feature_menu_option = features_menu ? features_menu : "";

    fields.push(obj);

    // var featuresLandType = document.getElementById("featuresLandType");

    // if (typeof featuresLandType != "undefined" && featuresLandType != null) {
    //   document.getElementById("featuresLandType").value = "";
    // }
    setFeaturesValue("");
    setLandSize("");
    setFeaturePreference("");
    setComments("");
    setFeaturesValue("");
    setFeatures_menu("");
  };

  const handleRemove = () => {
    for (let i = fields.length - 1; i >= 0; i--) {
      if (fields.get(i).selected) {
        fields.remove(i);
      }
    }
  };

  const handleSelectAllToggle = (e) => {
    const all = fields.getAll();
    if (all) {
      const selected = document.getElementById("checkToggle").checked
        ? true
        : false;
      setCheckAll(selected);
      fields.removeAll();
      let selectAllArray = [];
      all.forEach((item, index) => {
        // all[index].selected = selected;
        fields.push({ ...item, selected });
        selectAllArray.push(index);
      });
      if (selected) {
        setSelectedId(selectAllArray);
      } else {
        setSelectedId([]);
      }
    }
  };
  const individualCheckBox = (event, index) => {
    let tempData = [];
    if (event) {
      setSelectedId([...selectedId, index]);
      tempData.push(...selectedId, index);
    } else {
      setSelectedId(selectedId.filter((val) => val !== index));
    }
    if (fields?.length === tempData?.length) {
      setCheckAll(true);
    } else {
      setCheckAll(false);
    }
  };
  return (
    <Fragment>
      <ThemeHeader
        classnames="pb-1"
        left={<h5 className="pt-1 title ">{!fromListingDetails && title}</h5>}
        right={
          <AccessControl
            allowedPermissions={["rentals-delete"]}
            renderNoAccess={() => ""}
          >
            <ThemeButton className="lightGrey-button" onClick={handleRemove}>
              Remove
            </ThemeButton>
          </AccessControl>
        }
      />
      <div className="tableFixHead theme-table-striped d-flex overflow-hidden">
        <table className="table simple  ">
          <thead>
            <tr></tr>
            <tr>
              <th>
                {" "}
                <input
                  id="checkToggle"
                  type="checkbox"
                  checked={checkAll}
                  onChange={handleSelectAllToggle}
                />
              </th>
              <th>Feature</th>
              <th style={{ width: "150px" }}>
                {fromListingDetails ? "Details" : "Preference"}
              </th>
              <th>Notes</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td></td>
              <td>
                {/* <select
                  className="form-control"
                  id="features"
                  onChange={(e) => setFeaturesValue(e.target.value)}
                  className="custom-select"
                >
                  <option value="bedrooms">Bedrooms</option>
                  <option value="bathrooms">Bathrooms</option>
                  <option value="parking">Parking</option>
                  <option value="landsize">Land Size</option>
                </select> */}
                {features.length > 0 ? (
                  <MaterialSelect
                    // id="features"
                    onChange={(e) => setFeaturesValue(e)}
                    options={features}
                    value={featuresValue}
                    // placeholder="Feature"
                  />
                ) : (
                  <LoadingSkeleton />
                )}
              </td>
              <td>
                {![9, 20, 36].includes(featuresValue) ? (
                  <TextField
                    fullWidth
                    value={featurePreference}
                    onChange={(e) => setFeaturePreference(e.target.value)}
                    placeholder={fromListingDetails ? "Details" : "Preference"}
                  />
                ) : featuresValue === 36 ? (
                  <div className="d-flex">
                    <Col className="pr-3 pr-md-0 mt-auto">
                      {features_menu_zone.length > 0 ? (
                        <MaterialSelect
                          id="featuresLandType"
                          fullWidth
                          value={features_menu}
                          options={features_menu_zone}
                          onChange={(e) => setFeatures_menu(e)}
                          placeholder="Feature"
                        />
                      ) : (
                        <LoadingSkeleton />
                      )}
                    </Col>
                  </div>
                ) : featuresValue === 9 ? (
                  <div className="d-flex">
                    <Col sm={6} className="pr-3 pr-md-0">
                      <TextField
                        fullWidth
                        label="Building Size"
                        type="number"
                        value=""
                        onChange={(e) => setLandSize(e.target.value)}
                      />
                    </Col>
                    <Col sm={6} className="pr-3 pr-md-0 mt-auto">
                      {features_menu_options.length > 0 ? (
                        <MaterialSelect
                          id="featuresLandType"
                          fullWidth
                          value={features_menu}
                          options={features_menu_options}
                          onChange={(e) => setFeatures_menu(e)}
                          placeholder="Feature"
                        />
                      ) : (
                        <LoadingSkeleton />
                      )}
                    </Col>
                  </div>
                ) : (
                  <div className="d-flex">
                    <Col sm={6} className="pr-3 pr-md-0">
                      <TextField
                        fullWidth
                        label="Land Size"
                        type="number"
                        value={landSize}
                        onChange={(e) => setLandSize(e.target.value)}
                      />
                    </Col>
                    <Col sm={6} className="pr-3 pr-md-0 mt-auto">
                      {features_menu_options.length > 0 ? (
                        <MaterialSelect
                          id="featuresLandType"
                          fullWidth
                          value={features_menu}
                          options={features_menu_options}
                          onChange={(e) => setFeatures_menu(e)}
                          placeholder="Feature"
                        />
                      ) : (
                        <LoadingSkeleton />
                      )}
                    </Col>
                  </div>
                )}
              </td>
              <td>
                <div className="d-flex">
                  <TextField
                    fullWidth
                    multiline
                    type="text"
                    value={comments}
                    placeholder="Notes"
                    onChange={(e) => setComments(e.target.value)}
                  />
                  <IconButton
                    onClick={() => handleAddFeature()}
                    aria-label="delete"
                    size="small"
                    disabled={!featuresValue && !featurePreference}
                    className="submit-icon-button"
                  >
                    <i className="fas fa-plus-circle"></i>
                  </IconButton>
                </div>
              </td>
            </tr>
          </tbody>
          <SortableTable fields={fields}>
            {fields.map((item, index) => {
              return (
                <Fragment key={index}>
                  <td>
                    <Field
                      name={`${item}.selected`}
                      component={RadioInput}
                      type="checkbox"
                      onChange={(event) => individualCheckBox(event, index)}
                    />
                  </td>
                  <td>
                    <Field
                      name={`${item}.feature`}
                      placeholder="Select a status"
                      component={MultiSelect}
                      showBottom={false}
                      isMulti={false}
                      options={features}
                    />
                  </td>
                  <td>
                    {![9, 20, 36].includes(fields?.get(index)?.feature) ? (
                      <Field
                        name={`${item}.qty`}
                        component={TextInput}
                        placeholder="preference"
                      />
                    ) : fields?.get(index)?.feature === 36 ? (
                      <Fragment>
                        <Form.Group className="d-flex">
                          <Col className="pr-3 pr-md-0">
                            <Field
                              name={`${item}.feature_menu_option`}
                              component={MultiSelect}
                              showBottom={false}
                              isMulti={false}
                              options={features_menu_zone}
                            />
                          </Col>
                        </Form.Group>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <Form.Group className="d-flex">
                          <Col sm={6} className="pr-3 pr-md-0">
                            <Field
                              name={`${item}.feature_menu_input`}
                              type="number"
                              component={TextInput}
                              placeholder=""
                            />
                          </Col>
                          <Col sm={6} className="pr-3 pr-md-0">
                            <Field
                              name={`${item}.feature_menu_option`}
                              component={MultiSelect}
                              showBottom={false}
                              isMulti={false}
                              options={features_menu_options}
                            />
                          </Col>
                        </Form.Group>
                      </Fragment>
                    )}
                  </td>
                  <td>
                    <div className="d-flex">
                      <Field
                        name={`${item}.comments`}
                        component={TextArea}
                        type="text"
                        rows={1}
                        placeholder="Notes"
                      />
                      <Handle />
                    </div>
                  </td>
                </Fragment>
              );
            })}
          </SortableTable>

          <tbody>
            {[...Array(getDummyTableRow(height, fields?.length))].map(
              (e, i) => {
                return (
                  <tr key={i}>
                    <td key={i}></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                );
              }
            )}
          </tbody>
        </table>
      </div>
    </Fragment>
  );
};

FormFeatures.defaultProps = {
  title: "Features",
  height: 49,
};
export default FormFeatures;
