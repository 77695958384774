import axios from "axios";
const WiseBerryAPIEnv = process.env.REACT_APP_WISEBERRY_API;
const grant_type = "password";
const client_id = 1;
const client_secret = "vrUohoUGuqykR1bV3TLh77mdLSA9QL2jDzaivwrX";
export const LOGIN_URL = WiseBerryAPIEnv + "login";

export const LOGOUT_URL = WiseBerryAPIEnv + "logout";
export const REGISTER_URL = WiseBerryAPIEnv + "api/auth/register";
export const REQUEST_PASSWORD_URL = WiseBerryAPIEnv + "forgot-password";
export const REQUEST_PASSWORDRESET_URL = WiseBerryAPIEnv + "reset-password";
export const ME_URL = WiseBerryAPIEnv + "member/details";

export function login(
  username,
  password,
  root = null,
  trustedDevice = null,
  mfa_code = null,
  trust_this_device = null,
  oauthsootoken = null
) {
  // console.log(LOGIN_URL);
  return axios.post(LOGIN_URL, {
    username,
    password,
    root,
    client_id,
    grant_type,
    client_secret,
    trustedDevice,
    mfa_code,
    trust_this_device,
    oauthsootoken,
  });
}

export function logOutUser(username, password) {
  // console.log(LOGOUT_URL);
  return axios.get(LOGOUT_URL);
}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(username) {
  return axios.post(REQUEST_PASSWORD_URL, username, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
  // return getUserReply;
}

export function requestPasswordReset(reset_token, new_password) {
  return axios.post(
    REQUEST_PASSWORDRESET_URL,
    { reset_token, new_password },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
}
