import React, { useContext, useEffect, useState } from "react";
import { Image } from "react-bootstrap";
// import Swal from "sweetalert2";
import { toAbsoluteUrl } from "../../../../../../_metronic";
import DefaultModal from "../../../../../partials/modal/DefaultModal";
import Item from "../../../../../partials/display/Item";
import ThemeButton from "../../../../../partials/button/ThemeButton";
import Preferences from "../../../../../partials/display/Preferences";
import moment from "moment";
import MaterialMultiSelect from "../../../../../partials/form-basic/select/MaterialMultiSelect";
import { getMenu } from "../../../../../GeneralApi/GeneralApi";
import {
  addNumberSeparator,
  convertFormData,
  formatPhoneNumber,
} from "../../../../../_helpers/_utils/utils";
import {
  updateProspect,
  viewProspect,
  viewProspectData,
} from "../../../curd/prospectsCrud";
import { toast } from "react-toastify";
import { ProspectProfileContext } from "../ProspectProfileContextProvider";
import ProspectViewPriceModal from "../ProspectViewPriceModal";
import ThemeHeader from "../../../../../partials/content/ThemeHeader";
import { Radio } from "antd";

const ProspectProfileSideBar = ({ profile, searchForContacts }) => {
  const [modalShow, setModalShow] = React.useState(false);
  const [options, setOptions] = useState([]);
  const [tags, setTags] = useState([]);
  const [isOpenView, setOpenViewModal] = useState(false);
  const [isOpenPropery, setOpenProperty] = useState(false);
  const [propertiesDetails, setPropertiesDetails] = useState([]);
  const [propertyDetails, setPropertyDetails] = useState({});
  const [selectedProperty, setSelectedProperty] = useState({});
  const [viewLoading, setViewLoading] = useState(false);
  const { updateContext } = useContext(ProspectProfileContext);

  const handleSubmit = () => {
    const formData = convertFormData({ tags: tags });
    formData.append("_method", "PATCH");
    updateProspect(profile?.id, formData)
      .then(({ data }) => {
        toast.success("Tags Saved Successfully");
        updateContext();
      })
      .catch((e) => {
        toast.error("Error while prospect saving");
      });
  };
  useEffect(() => {
    let query = "tag";
    getMenu(query)
      .then(({ data: { data } }) => {
        setOptions(data.tag);
      })
      .catch(() => {});
  }, []);
  useEffect(() => {
    profile?.tags?.length > 0 && setTags(profile?.tags?.map((tag) => tag?.id));
  }, [profile]);

  const handleViewProperty = (clicked = false) => {
    setViewLoading(true);
    if (profile?.property?.address?.full_address) {
      viewProspect({ address: profile?.property?.address?.full_address })
        .then((res) => {
          if (res?.data?.data) {
            if (res.data.data.length === 1) {
              handleViewPropertyData(res.data.data[0]);
              setOpenViewModal(true);
            } else if (res.data.data.length > 1) {
              setPropertiesDetails(res.data.data);
              setSelectedProperty(res.data.data[0]);
              if (clicked) {
                setOpenProperty(true);
              }
            }
          }
          setViewLoading(false);
        })
        .catch((err) => {
          setViewLoading(false);
        });
    }
  };

  const handleViewPropertyData = (data = {}) => {
    setViewLoading(true);
    if (data?.property?.id) {
      viewProspectData({ property: data?.property?.id })
        .then((res) => {
          if (res?.data?.data) {
            // console.log("res?.data?.data", res?.data?.data);
            setPropertyDetails(res?.data?.data);
          }
          setViewLoading(false);
        })
        .catch((err) => {
          setViewLoading(false);
        });
    }
  };

  const closeModal = () => {
    setOpenViewModal(false);
  };

  const closePropertyModal = () => {
    setOpenProperty(false);
  };

  return (
    <>
      {modalShow ? (
        <DefaultModal show={modalShow} onHide={() => setModalShow(false)}>
          {/* <BuyerFormModal onHide={() => setModalShow(false)} /> */}
        </DefaultModal>
      ) : null}

      <div className="card mb-5">
        <div className="card-body">
          <div className="pl-0">
            <div className="main-profile-overview">
              <div className="d-flex justify-content-center text-center">
                <div className="pb-3">
                  <div className="circle-avatar m-auto d-flex">
                    <Image
                      className="img-fluid"
                      src={
                        profile?.avatar
                          ? profile?.avatar
                          : toAbsoluteUrl("/media/listings/wol.jpeg")
                      }
                      roundedCircle
                    />
                  </div>
                  <h5 className="title m-0">
                    {profile?.property?.address?.full_address}
                  </h5>
                  <Preferences profile={profile?.property} forSideBar={true} />
                  <div className="text-left">
                    {profile?.listing === 1 && profile?.lwu === 1 && (
                      <div
                        className=""
                        style={{
                          color: `${profile?.lwu === 1 ? "green" : "black"}`,
                        }}
                      >
                        <i className="fas fa-sign"></i> Listed with Wiseberry
                      </div>
                    )}
                    {profile?.listing === 1 && profile?.lwu === 0 && (
                      <div className="">
                        <i className="fas fa-sign"></i> Listed with{" "}
                        {profile?.lwo && profile?.lwo_price
                          ? profile?.lwo
                          : " others "}{" "}
                        {profile?.lwo_price && profile?.lwo
                          ? ` for $${addNumberSeparator(profile?.lwo_price)}`
                          : ""}
                      </div>
                    )}

                    {profile?.listing === 0 && ""}

                    {profile?.rental === 1 && profile?.rwu === 1 && (
                      <div
                        className=""
                        style={{
                          color: `${profile?.rwu === 1 ? "green" : "black"}`,
                        }}
                      >
                        <i className="fas fa-sign"></i> Rental with Wiseberry
                      </div>
                    )}
                    {profile?.rental === 1 && profile?.rwu === 0 && (
                      <div className="">
                        <i className="fas fa-sign"></i> Rental with{" "}
                        {profile?.rwo && profile?.rwo_price
                          ? profile?.rwo
                          : " others "}{" "}
                        {profile?.rwo_price
                          ? ` for $${addNumberSeparator(profile?.rwo_price)}`
                          : ""}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {profile?.contacts?.length > 0 && (
                <h6 className="title"> Contacts</h6>
              )}
              {profile?.contacts?.map((contact) => (
                <div className="item">
                  <Item
                    left="Name"
                    right={
                      <>
                        {contact?.first_name ?? ""} {contact?.last_name ?? ""}
                      </>
                    }
                  />
                  <Item
                    left="Phone"
                    noMargin
                    right={
                      profile?.dnc ? (
                        <p>
                          {contact?.phones.map((phone) => (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <del className="pr-3">
                                <a href={`tel:${phone.value}`}>
                                  {formatPhoneNumber(phone.value)}
                                </a>
                              </del>
                            </div>
                          ))}

                          <i className="fas fa-phone-slash brand-color"></i>
                        </p>
                      ) : (
                        <p>
                          {contact?.phones.map((phone) => (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <span className="pr-3">
                                <a href={`tel:${phone.value}`}>
                                  {formatPhoneNumber(phone.value)}
                                </a>
                                {phone?.unsubscribe_date ? (
                                  <i className="fas ml-1 fa-flag brand-color"></i>
                                ) : null}
                              </span>
                            </div>
                          ))}
                        </p>
                      )
                    }
                  />
                  <Item
                    left="Email"
                    right={
                      <>
                        <a href={`mailto:${contact?.emails[0]?.value}`}>{contact?.emails[0]?.value}</a>{" "}
                        {contact?.emails[0]?.unsubscribe_date ? (
                          <i className="fas ml-1 fa-flag brand-color"></i>
                        ) : null}
                      </>
                    }
                  />
                </div>
              ))}

              <h6 className="title"> Details</h6>
              <div className="item">
                <Item
                  left="Purchased"
                  right={`${
                    profile?.purchase_date
                      ? moment(profile?.purchase_date).format("DD/MM/YYYY")
                      : "No date"
                  }
                     for $${profile?.purchase_price ?? " 0"}`}
                />

                <Item left="Rating" right={profile?.rating?.name} />

                <Item
                  left="Buyers"
                  right={
                    profile?.contacts?.filter(
                      (contact) => contact?.contact_type == 9
                    )?.length
                  }
                />
                <Item
                  left="Rented"
                  right={`Last rented for $${addNumberSeparator(
                    profile?.rwo_price
                  )}`}
                />
              </div>
            </div>
            <MaterialMultiSelect
              label="Tags"
              // placeholder={}
              options={options}
              onClose={() => {
                // console.log("blur");
                handleSubmit();
              }}
              value={tags}
              // value={tags}
              // value={input?.value}
              onChange={(e) => {
                setTags(e);
              }}
            />
            <div className="d-flex text-center mt-4 p-4 sunlight-added darkBlue-background flex-column">
              <h6> There is PriceFinder Data available for this property </h6>
              <div className="p-3">
                <ThemeButton
                  className="darkGrey-button themeButton text-white"
                  onClick={() => handleViewProperty(true)}
                  disabled={viewLoading}
                >
                  View
                </ThemeButton>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DefaultModal size={"l"} centered show={isOpenView} onHide={closeModal}>
        <ProspectViewPriceModal
          profile={profile}
          viewLoading={viewLoading}
          propertyDetails={propertyDetails}
          closeModal={closeModal}
        />
      </DefaultModal>
      <DefaultModal
        size={"l"}
        centered
        show={isOpenPropery}
        onHide={closePropertyModal}
      >
        <ThemeHeader
          left={<h5 className="title m-0">Select the property</h5>}
          right={<ThemeButton onClick={closePropertyModal}>Close</ThemeButton>}
        />
        <Radio.Group
          onChange={(e) =>
            setSelectedProperty(propertiesDetails[e.target.value])
          }
          defaultValue={0}
        >
          {propertiesDetails.map(({ display }, index) => (
            <Radio className="pt-1" key={index} value={index}>
              {display}
            </Radio>
          ))}
        </Radio.Group>

        <div className="mt-4 text-center">
          <ThemeButton
            className="green-button mr-3"
            // disabled={!APMContactData?.length}
            onClick={() => {
              handleViewPropertyData(selectedProperty);
              setOpenProperty(false);
              setOpenViewModal(true);
            }}
          >
            Submit
          </ThemeButton>
        </div>
      </DefaultModal>
    </>
  );
};

export default ProspectProfileSideBar;
