import React, { useContext, useState, Fragment } from "react";
import { Accordion, Col, Row } from "react-bootstrap";
import _isEqual from 'lodash/isEqual';

import CustomToggle from "../../../../partials/filter/customToggle/CustomToggle";
import { toAbsoluteUrl } from "../../../../../_metronic";
import { DigitalTemplateContext } from "../../pages/digitalTemplate/DigitalTemplateContextProvider";

const DigitalTemplateLayouts = ({
  templates,
  _setSelectedTemplate,
  setSelectedTemplateLayout,
}) => {
  const { setShowEditSection } = useContext(DigitalTemplateContext);
  const [selectedIndex, setSelectedIndex] = useState(null);
  return (
    <div>
      {console.log('templatestemplates', templates)}
      <Accordion defaultActiveKey={0} className='mt-2 social-media-accordian'>
        {templates?.map(({ name, data, id, ...rest }, index) => (
          <Fragment>
            <CustomToggle eventKey={index.toString()} rightArrow={true}>
              <div className="brand-color">
                {name ?? "Category Name Here"}
              </div>
            </CustomToggle>

            <Accordion.Collapse className="mb-5" eventKey={index.toString()}>
              <Row>
                {" "}
                {data?.map(({ template, html }, subIndex) => (
                  <Col lg={6} md={6} sm={6} key={subIndex}>
                    {" "}
                    <img
                      onClick={() => {
                        setSelectedIndex([index, subIndex])
                        _setSelectedTemplate(html)
                        setSelectedTemplateLayout({ name, id, ...rest });
                        setShowEditSection(false)
                      }}
                      src={toAbsoluteUrl(template)}
                      className={`p-1 ${_isEqual(selectedIndex, [index, subIndex]) ? 'green-border' : ''}`}
                      style={{ width: "100%" }}
                      alt=""
                    />
                  </Col>
                ))}
              </Row>
            </Accordion.Collapse>
          </Fragment>
        ))}
      </Accordion>
    </div>
  );
};

export default DigitalTemplateLayouts;
