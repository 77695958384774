import React, { useState, useEffect, Fragment } from "react";

import { Button } from "react-bootstrap";
import cuid from "cuid";
import { TimelineDropdown } from "../../../../../partials/display/timeLine/TimelineDropdown";
import TimelineItem from "../../../../../partials/display/timeLine/TimelineItem";
import ThemeButton from "../../../../../partials/button/ThemeButton";
import { getMenu } from "../../../../../GeneralApi/GeneralApi";
import { formToQueryString } from "../../../../../_helpers/_utils/utils";
import { Waypoint } from "react-waypoint";
import { getTimelineByContactId } from "../../../../contacts/crud/contactsCrud";
import AccessControl from "../../../../../_ accessControl/AccessControl";

export default function ContactsTimeline({ profileId }) {
  const [selected, setSelected] = useState([]);

  const [pastTimelineOtherData, setPastTimelineOtherData] = useState(null);
  const [pastTimeline, setPastTimeline] = useState([]);
  const [futureTimeline, setFutureTimeline] = useState([]);

  const [timelineType, setTimelineType] = useState(null);

  useEffect(() => {
    let query = "timeline_activity_type";
    getMenu(query)
      .then(({ data: { data } }) => {
        setTimelineType(
          data.timeline_activity_type.map((s) => ({
            label: s.name,
            value: s.id,
          }))
        );
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    let selectedOptions = selected.map(function(el) {
      return el.value;
    });
    setPastTimeline([]);
    setFutureTimeline([]);
    setPastTimelineOtherData([]);
    getPastUpdate(
      formToQueryString({
        type: selectedOptions,
        direction: "past",
        limit: 20,
        tenant: profileId,
      })
    );
    let futureQuery = formToQueryString({
      type: selectedOptions,
      limit: 50,
      tenant: profileId,
    });
    console.log(futureQuery);
    getFutureUpdate(futureQuery);
  }, [selected, profileId]);

  const getPastUpdate = (prams) => {
    getTimelineByContactId(prams)
      .then((res) => {
        console.log(res);
        let { data, ...other } = res.data;
        console.log(data);
        console.log(res.data);

        setPastTimeline(data);
        setPastTimelineOtherData(other);
      })
      .catch(() => {});
  };

  const getFutureUpdate = (prams) => {
    getTimelineByContactId(prams)
      .then(({ data: { data } }) => {
        setFutureTimeline(data);
      })
      .catch(() => {});
  };

  const handlePastGetmore = () => {
    let selectedOptions = selected.map(function(el) {
      return el.value;
    });
    if (
      pastTimelineOtherData?.current_page !== pastTimelineOtherData?.last_page
    ) {
      let pastQuery = formToQueryString({
        tenant: profileId,
        type: selectedOptions,
        direction: "past",
        limit: 20,
        page: pastTimelineOtherData.current_page + 1,
      });
      getTimelineByContactId(pastQuery)
        .then((res) => {
          let { data, ...other } = res.data;

          setPastTimeline(data.concat(pastTimeline));
          setPastTimelineOtherData(other);
        })
        .catch(() => {});
    }
  };

  return (
    <React.Fragment>
      <div className="timeline-dropdown d-flex justify-content-end">
        <AccessControl
          allowedPermissions={["tenants-download"]}
          renderNoAccess={() => ""}
        >
          <ThemeButton icon>
            <i className="fas fa-download    "></i>
          </ThemeButton>
        </AccessControl>

        <div className="float-item">
          {timelineType ? (
            <TimelineDropdown
              options={timelineType}
              value={selected}
              onChange={setSelected}
            />
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="timeline-body">
        <div className="timeline timeline-5">
          {futureTimeline.length
            ? futureTimeline?.map((item, i) => (
                <Fragment key={cuid()}>
                  <TimelineItem
                    date={item.timeline_date}
                    text={item.text}
                    type={item.type}
                    id={item.id}
                    info_items={item.info_items}
                  />
                  {console.log(item)}
                </Fragment>
              ))
            : ""}
          <div className="timeline-item align-items-start timeline-start-button">
            <div className="timeline-label"></div>
            <div className="timeline-badge">
              <Button variant="outline-secondary">Now</Button>
            </div>
            <div className="timeline-content"></div>
          </div>
          {pastTimeline.length
            ? pastTimeline?.map((item, i) => (
                <Fragment key={cuid()}>
                  <TimelineItem
                    date={item.timeline_date}
                    text={item.text}
                    type={item.type}
                    id={item.id}
                    info_items={item.info_items}
                  />
                  {console.log(item)}
                </Fragment>
              ))
            : ""}
          <Waypoint onEnter={handlePastGetmore} />
        </div>
      </div>
    </React.Fragment>
  );
}
