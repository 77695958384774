import React, {
  useState,
  useEffect,
  Fragment,
  useContext,
} from "react";
import {
  getContactById,
  updateContact,
} from "../../../../contacts/crud/contactsCrud";
import {
  convertFormData,
  getObjWithoutValues,
  getProcessedContactInfo,
  postProcessedContactInfo,
} from "../../../../../_helpers/_utils/utils";
import ProspectContacts from "./ProspectContacts";
import LoadingSkeleton from "../../../../../partials/display/skeleton/LoadingSkeleton";
import DefaultModal from "../../../../../partials/modal/DefaultModal";
import AddLinkedContacts from "../../../../contacts/modals/addLinkedContacts/AddLinkedContacts";
import { toast } from "react-toastify";
import { ProspectProfileContext } from "../ProspectProfileContextProvider";
import { updateProspect } from "../../../curd/prospectsCrud";

const dynamicGetContact = (id) => {
  return getContactById(id);
};

const ProspectContactsWrap = (props) => {
  const { updateContext } = useContext(ProspectProfileContext);

  const { profile, profileId, updateTable } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [additionalContact, setAdditionalContact] = useState([]);
  const [showContactModal, setShowContactModal] = useState(null);
  const [items, setItems] = useState(null);
  const [newContactId, setNewContactId] = useState(null);
  const [addNewContact, setAddNewContact] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const handleOnSelect = (e) => {
    setAdditionalContact(e);
  };

  useEffect(() => {
    setAdditionalContact(null);
  }, [profileId]);

  useEffect(() => {
    let contact_list = [];
    setIsLoading(true);
    const { contacts, ...other } = profile;
    const promises = [];

    if (contacts?.length > 0) {
      contacts.map((item) => {
        promises.push(dynamicGetContact(item.id));
      });
    }

    if (additionalContact?.length > 0) {
      additionalContact.map((item) => {
        promises.push(dynamicGetContact(item.id));
      });
    }

    Promise.all(promises).then((res) => {
      res.map((item) =>
        contact_list.push(getProcessedContactInfo(item.data.data))
      );
      setIsLoading(false);
    });
    contact_list.map((obj, index) => {
      obj.isOpen = false;
      obj.actionType = "View";
    });
    setItems(contacts?.map((contact) => getProcessedContactInfo(contact)));
  }, [profile.contacts, additionalContact]);

  const onFormSubmit = async (values) => {
    setSaveLoading(true);
    let showedToast = false;
    const contactsId = newContactId
      ? [...values.contacts.map((item) => item.id), newContactId]
      : values.contacts.map((item) => item.id);

    if (!addNewContact) {


      await Promise.all(
        values.contacts.map((item) => {

          const formatedValue = postProcessedContactInfo(item);
          const formData = convertFormData({
            ...getObjWithoutValues([undefined, null], formatedValue),
            formDataOptions: { allowEmptyArrays: true },
          });

          formData.append("_method", "PATCH");
          updateContact(item.id, formData)
            .then(({ data }) => {
              updateTable();
              updateContext();
              setSaveLoading(false);
              if (!showedToast) {
                showedToast = true;
                toast.success("Post Updated Successfully");
              }
            })
            .catch((e) => {
              setSaveLoading(false);
            });
        })
      );
    } else {
      setAddNewContact(false);
    }

    let prospectFormValue = {};
    prospectFormValue.contacts = contactsId;
    prospectFormValue._method = "PATCH";

    updateProspect(profileId, prospectFormValue, "application/json")
      .then(({ data }) => {
        updateTable();
        updateContext();
        setSaveLoading(false);

        if (!showedToast) {
          toast.success("Prospect Saved Successfully");
        }

      })
      .catch((e) => {
        setSaveLoading(false);
      });
  };

  return (
    <Fragment>
      {showContactModal ? (
        <DefaultModal
          size="xl"
          centered
          show={showContactModal}
          onHide={() => setShowContactModal(false)}
        >
          <AddLinkedContacts
            closeModal={() => setShowContactModal(false)}
            onSelect={handleOnSelect}
          />
        </DefaultModal>
      ) : null}
      {isLoading && items ? (
        <LoadingSkeleton />
      ) : (
        <ProspectContacts
          onSubmit={onFormSubmit}
          saveLoading={saveLoading}
          profileId={profileId}
          data={items}
          setNewContactId={(e) => setNewContactId(e)}
          setAddNewContact={setAddNewContact}
          updateContext={updateContext}
          profile={profile}
        />
      )}
    </Fragment>
  );
};

export default ProspectContactsWrap;
