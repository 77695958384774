import React, { Fragment } from 'react'
import Maps from '../../../../../partials/map/Maps'

const ProspectMap = ({ prospectProfile }) => {
    const { latitude, longitude } = prospectProfile?.property?.address || {};
    return (
        <Fragment>
            {latitude && longitude ? <Maps prospectProfile={prospectProfile?.property?.address} center={[parseFloat(latitude), parseFloat(longitude)]} /> : <div className='text-center'>No Map data Found</div>
            }
        </Fragment>
    )
}

export default ProspectMap
