/* eslint-disable no-restricted-imports */
import React, { Fragment, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  InputLabel,
  MenuItem,
  FormHelperText,
  FormControl,
  Select,
  LinearProgress,
  Tooltip,
} from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';

const renderFromHelper = (meta) => {
  if (!(meta?.invalid && meta?.error)) {
    return;
  } else {
    return <FormHelperText>{meta?.invalid && meta?.error}</FormHelperText>;
  }
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  menuPaper: {
    maxHeight: 200,
  },
  clearIcon: {
    position: 'absolute',
    right: '20px',
    top: '60%',
    transform: 'translateY(-50%)',
    zIndex: 1,
  },
  relativeContainer: {
    position: 'relative',
  }
}));

const MaterialSelect = ({
  label,
  Placeholder,
  onChange,
  value,
  className,
  otherValue,
  options,
  formik,
  required,
  bulkFilter,
  meta,
  removable = false,
  enableLoading = false,
  customHeight = false,
  fetchMore = () => {},
  ...input
}) => {
  const classes = useStyles();
  const [selectedValue, setSelectedValue] = useState("");

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  const handleChange = (event) => {
    setSelectedValue(event.target?.value);
    if (formik) {
      onChange(event);
    } else if (bulkFilter) {
      onChange([event.target?.value]);
    } else {
      onChange(event.target?.value);
    }
  };

  const handleClear = () => {
    console.log("selected Value", selectedValue)
    setSelectedValue('');
    if (formik) {
      onChange({ target: { value: '' } });
    } else if (bulkFilter) {
      onChange(['']);
    } else {
      onChange('');
    }
  };

  const renderOption = (options) => {
    // console.log("options", options);
    if (options[0].hasOwnProperty("optionTooltipTitle")) {
      return options.map((obj, index) => (
        <MenuItem key={index} value={obj.id} disabled={obj.disabled}>
          <Tooltip
            title={
              <h6 style={{ color: "white" }} className="mt-2">
                {obj?.optionTooltipTitle ?? ""}
              </h6>
            }
            placement="top"
          >
            <div className="w-100">{obj.name}</div>
          </Tooltip>
        </MenuItem>
      ));
    } else {
      return options.map((obj, index) => (
        <MenuItem key={index} value={obj.id} disabled={obj.disabled}>
          {obj.name}
        </MenuItem>
      ));
    }
  };

  return (
    <Fragment>
      <div className={classes.relativeContainer}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {options?.length ? (
            <FormControl
              required={required}
              className={classes.formControl}
              error={meta?.invalid}
            >
              {label ? <InputLabel>{label}</InputLabel> : ""}
              {enableLoading ? <LinearProgress /> : ""}
              <Select
                MenuProps={{
                  onScroll: (event) => {
                    const currentTarget = event.target;
                    if (
                      currentTarget.scrollTop + currentTarget.clientHeight ===
                      currentTarget.scrollHeight
                    ) {
                      fetchMore();
                    }
                  },
                  getContentAnchorEl: null,
                  classes: { paper: customHeight ? classes.menuPaper : "" },
                }}
                className={className}
                {...input}
                value={value ? value : value === 0 ? value : ""}
                size="small"
                onChange={(e) => handleChange(e)}
              >
                {otherValue?.length
                  ? otherValue.map((obj, index) => (
                      <MenuItem key={index} value={obj.id}>
                        {obj.name}
                      </MenuItem>
                    ))
                  : ""}
                {options ? renderOption(options) : ""}
              </Select>
              {renderFromHelper(meta)}
            </FormControl>
          ) : (
            <FormControl required={required} className={classes.formControl}>
              {label ? <InputLabel>{label}</InputLabel> : ""}
              {enableLoading ? <LinearProgress /> : ""}
              <Select
                className={className}
                {...input}
                value={otherValue?.some((obj) => obj?.id === value) ? value : ""}
                size="small"
                onChange={(e) => handleChange(e)}
              >
                {otherValue?.length
                  ? otherValue.map((obj, index) => (
                      <MenuItem key={index} value={obj.id}>
                        {obj.name}
                      </MenuItem>
                    ))
                  : ""}
              </Select>
              {renderFromHelper(meta)}
            </FormControl>
          )}
        </div>
        {selectedValue && removable && (
          <IconButton
            size="small"
            onClick={handleClear}
            className={classes.clearIcon}
          >
            <ClearIcon fontSize="small" />
          </IconButton>
        )}
      </div>
    </Fragment>
  );
    
};

MaterialSelect.defaultProps = {
  formik: false,
  required: false,
};

export default MaterialSelect;
