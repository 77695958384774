import React, { useState } from "react";
import { useSelector } from "react-redux";
import AppointmentContextProvider from "./AppointmentContextProvider";
import AppointmentForm from "./AppointmentForm";

const Appointment = (props) => {
  const {
    initialValues,
    update,
    type,
    fillFormData,
    setFillFormData,
    setOpenHomeApp,
    mobile,
    openCma,
  } = props;

  const [appointmentForm, setAppointmentForm] = useState([]);
  const [selectedListings, setSelectedListing] = useState(
    initialValues?.listings
  );
  const [selectedRentals, setSelectedRentals] = useState(
    initialValues?.rentals
  );

  const user = useSelector(({ auth }) => auth.user.contact);

  const iniValues = {
    prospect_address: {
      meeting_location: [
        {
          street_unit: "",
          street_number: "",
          street_name: {},
          suburb: {},
          state: {},
        },
      ],
    },

    other_location: [
      {
        street_unit: "",
        street_number: "",
        street_name: {},
        suburb: {},
        state: {},
      },
    ],
    meeting_location: [
      {
        street_unit: "",
        street_number: "",
        street_name: {},
        suburb: {},
        state: {},
      },
    ],
    contacts: [
      {
        emails: [{ type: 1, value: "" }],
        phones: [{ type: 1, value: "" }],
      },
    ],
    for_contact: user?.id,
    repeat: 1,
    app_type: type ? type : 3,
  };

  return (
    <AppointmentContextProvider
      update={update}
      closeModal={props.closeModal}
      setFillFormData={setFillFormData}
      fillFormData={fillFormData}
      setOpenHomeApp={setOpenHomeApp}
      openCma={openCma}
    >
      <AppointmentForm
        {...props}
        initialValues={{
          ...iniValues,
          ...initialValues,
          ...appointmentForm,
          ...fillFormData,
          // listings: selectedListings,
        }}
        selectedListings={selectedListings}
        setAppointmentForm={setAppointmentForm}
        setFillFormData={setFillFormData}
        selectedRentals={selectedRentals}
        mobile={mobile}
      />
    </AppointmentContextProvider>
  );
};

Appointment.defaultProps = {
  initialValues: {},
};

export default Appointment;
