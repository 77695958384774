import React, { Fragment, useRef, useState, useEffect } from "react";
import FacebookLogin from "react-facebook-login";
import { saveAs } from 'file-saver';
import {  toBlob, } from 'html-to-image';
import axios from "axios";
import {useSelector} from "react-redux";
import {
  EmailShareButton,
  FacebookShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton,
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  InstapaperIcon,
  LineIcon,
  LinkedinIcon,
  LivejournalIcon,
  MailruIcon,
  OKIcon,
  PinterestIcon,
  PocketIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  ViberIcon,
  VKIcon,
  WeiboIcon,
  WhatsappIcon,
  WorkplaceIcon,
} from "react-share";
import { OverlayTrigger, Popover, Overlay } from "react-bootstrap";
import ThemeButton from "../../button/ThemeButton";
import useComponentVisible from "../../../_helpers/_hooks/useComponentVisible";
import { Avatar, Divider, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  medium: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

const SocialShare = ({ emailFunction, smsFunction, url, tags, description, quote, title, clipBoardFunction,setFbPagedetails,setFbmodal,setLoading,setimageURL }) => {
  const classes = useStyles();
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [longlifeaccessToken, setLonglifeaccessToken] = useState(null);
  // const [imagUrl, setimageURL] = useState(null);
  const userId = useSelector(({ auth }) => auth?.user?.id);
  const popRef = useRef(null);
  // console.log("called");
  const {
    ref,
    isComponentVisible,
    setIsComponentVisible,
  } = useComponentVisible(false);
  const handleClick = (event) => {
    event.stopPropagation();
    console.log(isComponentVisible);
    setIsComponentVisible(!isComponentVisible);
    setShow(!show);
    setTarget(event.target);
  };

  useEffect(() => {
    setShow(isComponentVisible);
  }, [isComponentVisible]);

  // console.log(isComponentVisible);

 



  const processImg= async(urll) => {
    setLoading(true)
try {

  let userID = await  axios.get(`https://graph.facebook.com/v6.0/me?access_token=${accessToken}`)

let pageDetails
let getPageTopost
let postToPage
  if(userID?.data.id){
    pageDetails = await  axios.get(`https://graph.facebook.com/${userID?.data.id}/accounts?access_token=${accessToken}`)
  }

  // debugger
if(pageDetails?.data.data){
  setFbPagedetails(pageDetails?.data.data)
  getPageTopost = pageDetails?.data.data.filter((item)=>(item.name ==
    "Wiseberry Dev "))
}
setLoading(false)
} catch (error) {
  console.log('======error==============================');
    console.log(error);
    console.log('=======error=============================');
}
   }

  

const getLongLifeToken = async()=>{
  try {
    await setLonglifeaccessToken(accessToken)
  } catch (error) {
    console.log('======error==============================');
    console.log(error);
    console.log('=======error=============================');
  }
}



   const componentClicked =async data => {
    setimageURL(data)
  };

  const responseFacebook = response => {
    console.log(response.accessToken);
    setAccessToken(response.accessToken);
  };



useEffect(() => {
  if(accessToken != null ){
    getLongLifeToken()
  }

}, [accessToken,]);
useEffect(() => {
  if(longlifeaccessToken != null ){
    setFbmodal(true)
    processImg()
  }

}, [longlifeaccessToken,]);

// console.log('===========url=========================');
// // console.log(url);
// console.log('============url========================');
  return (
    <Fragment>
      <div>
        <div onClick={handleClick}>
          <i className="fas fa-share-square"></i>
        </div>
        <Overlay
          show={show}
          target={target}
          container={popRef.current}
          placement="bottom"
          containerPadding={20}
        >
          <Popover className="social-share">
            <Popover.Content>
              <div ref={ref} onClick={e => {
                setIsComponentVisible(false);
                // setShow(false);
                e.stopPropagation()
              }}>
                <div className='pointer' onClick={() => clipBoardFunction && clipBoardFunction()}>
                  <ThemeButton icon>
                    <Avatar className={classes.medium}>
                      <i className="fas fa-copy    "></i>
                    </Avatar>
                  </ThemeButton>
                  Copy Link
                </div>
                <Divider className="m-2" variant="middle" />
                <div
                  className='pointer'
                  onClick={(event) => {
                    // event.stopPropagation();
                    smsFunction && smsFunction();
                  }}
                >
                  <ThemeButton
                    icon
                  >
                    <Avatar className={classes.medium}>
                      <i className="fas fa-mobile    "></i>
                    </Avatar>
                  </ThemeButton>
                  SMS
                </div>
                <Divider className="m-2" variant="middle" />
                <div
                  onClick={(event) => {
                    // event.stopPropagation();
                    emailFunction && emailFunction();
                  }}
                  className='pointer'
                >
                  <ThemeButton
                    icon
                  >
                    <Avatar className={classes.medium}>
                      <i className="fas fa-envelope-open    "></i>
                    </Avatar>
                  </ThemeButton>
                  Email
                </div>
                <Divider className="m-2" variant="middle" />
                
            { userId === 194764 &&  <FacebookLogin
        appId="1150988645837821"
        // autoLoad={true}
        fields="name,email,picture,photos,pages_show_list"
        scope="pages_show_list"
        // scope="pages_show_list,pages_manage_posts,pages_manage_engagement,public_profile"
        onClick={()=>componentClicked(url)}
        callback={responseFacebook}
        cssClass="my-facebook-button-class"
    icon={<FacebookIcon className="mr-1" size={32} round  />}
    textButton='Facebook'
      />}
                <Divider className="m-2" variant="middle" />
                <FacebookShareButton
                  url={
                    url
                  }
                  quote={quote}
                  hashtag={tags}
                  description={description}
                  className="ml-2"
                >
                  <FacebookMessengerIcon className="mr-1" size={32} round />
                  Messenger
                </FacebookShareButton>
                <Divider className="m-2" variant="middle" />
                <TwitterShareButton
                  title={title ?? "test"}
                  url={url ?? "https://peing.net/ja/"}
                  // hashtags={["hashtag1", "hashtag2"]}
                  className="ml-2"
                  hashtag={tags}
                >
                  <TwitterIcon className="mr-1" size={32} round />
                  Twitter
                </TwitterShareButton>
                <Divider className="m-2" variant="middle" />
                {/* <div className='pointer'>
                  <ThemeButton icon>
                    <Avatar className={classes.medium}>
                      <i className="fab fa-google-plus-g"></i>
                    </Avatar>
                  </ThemeButton>
                  Google Plus
                </div>
                <Divider className="m-2" variant="middle" /> */}
                {/* <PinterestShareButton
                  title={title ?? "test"}
                  url={url ?? "https://peing.net/ja/"}
                  // hashtags={["hashtag1", "hashtag2"]}
                  className="ml-2"
                >
                  <PinterestIcon className="mr-2" size={32} round />
                  Pinterest
                </PinterestShareButton>
                <Divider className="m-2" variant="middle" /> */}
                <LinkedinShareButton
                  title={title ?? "test"}
                  url={url ?? "https://peing.net/ja/"}
                  // hashtags={["hashtag1", "hashtag2"]}
                  className="ml-2"
                >
                  <LinkedinIcon className="mr-2" size={32} round />
                  LinkedIn
                </LinkedinShareButton>
              </div>
            </Popover.Content>
          </Popover>
        </Overlay>
      </div>
    </Fragment>
  );
};

export default SocialShare;
