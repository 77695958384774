import React, { Fragment } from "react";

import { reduxForm, FieldArray } from "redux-form";

import ThemeHeader from "../../../../partials/content/ThemeHeader";
import ThemeButton from "../../../../partials/button/ThemeButton";

import MicrositeSettingArray from "../../partials/microsites/MicrositeSettingArray";

const MicrositesSettingForm = (props) => {
  const [edit, setEdit] = React.useState(false);
  const {
    handleSubmit,
    pristine,
    submitting,
    invalid,
    closeModal,
    touch,
    dirty,
  } = props;

  return (
    <Fragment>
      <div className="settings-modal">
        <form className="settings-form" onSubmit={handleSubmit}>
          <Fragment>
            <ThemeHeader
              left={
                <Fragment>
                  <h5 className="title">Microsites Settings</h5>
                </Fragment>
              }
              right={
                <Fragment>
                  <Fragment>
                    <div className="pl-2">
                      <ThemeButton icon onClick={() => setEdit(!edit)}>
                        <i className="fas fa-edit    "></i>
                      </ThemeButton>
                    </div>
                    <div className="pl-2">
                      <ThemeButton
                        className="lightGrey-button"
                        onClick={closeModal}
                      >
                        Cancel
                      </ThemeButton>
                    </div>
                    <div className="pl-2">
                      <ThemeButton
                        type="submit"
                        disabled={invalid || pristine || submitting || !dirty}
                        className="submit-button"
                      >
                        Save
                      </ThemeButton>
                    </div>
                  </Fragment>
                </Fragment>
              }
            />

            <h6 className="font-weight-bold">Automatic Microsites Creation</h6>

            <FieldArray
              // placeholder="Add a Category"
              name="automatic_microsite"
              edit={edit}
              // touch={touch}
              component={MicrositeSettingArray}
            />
          </Fragment>
        </form>
      </div>
    </Fragment>
  );
};

export default reduxForm({
  form: "microsites-setting",
  enableReinitialize: true,
})(MicrositesSettingForm);
