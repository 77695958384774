/* eslint-disable no-restricted-imports */
// *https://www.registers.service.gov.uk/registers/country/use-the-api*
import React, { useState, useEffect } from "react";
import { Chip, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import * as contactsRedux from "../../../app/modules/contacts/_redux/contactsRedux";
import { connect } from "react-redux";
import _isEmpty from "lodash/isEmpty";

const AutoComplete = (props) => {
  const {
    options,
    input,
    handleOnchange,
    selectedOption,
    renderOption,
    placeholder,
    error,
    meta,
    helperText,
    showErrorOnMount = false,
    highlight = false,
    // isLoading = false,
    multiple = false,

    ...custom
  } = props;

  const [isLoading, setIsLoading] = useState(false);

  const handleoptionSelect = (event, values) => {
    input.onChange(values?.first_name ? values?.first_name : values);
    selectedOption(values);
  };

  const defaultRenderOption = (option) => (
    <>
      <span
        style={{ fontWeight: highlight && option?.is_buyer_app ? 700 : 400 }}
      >
        {option?.first_name} {option?.last_name} - {option?.phones?.[0]?.value}{" "}
        - {option?.emails?.[0]?.value}
        {highlight && option?.is_buyer_app && (
          <Chip
            style={{ marginLeft: "5px" }}
            size="small"
            label="Buyer App"
            color="secondary"
          />
        )}
      </span>
      <hr />
    </>
  );

  useEffect(() => {
    if (highlight) {
      setIsLoading(!(options?.length === 0));
    }
  }, [options]);

  return (
    <Autocomplete
      freeSolo={!highlight}
      options={options}
      getOptionLabel={(option) => option.first_name}
      inputValue={input.value}
      renderOption={renderOption ? renderOption : defaultRenderOption}
      onChange={handleoptionSelect}
      filterOptions={(x) => x}
      loading={highlight && isLoading}
      loadingText={"Searching..."}
      onInputChange={(e) => {
        if (highlight && e?.target?.value) {
          setIsLoading(true);
        } else {
          setIsLoading(false);
        }
      }}
      renderInput={(params) => (
        <TextField
          {...input}
          {...custom}
          onChange={(e) => {
            handleOnchange(e);
            input.onChange(e);
          }}
          {...params}
          label={placeholder}
          error={
            error ??
            (custom.required
              ? _isEmpty(input.value)
              : (meta?.touched || showErrorOnMount) && meta?.invalid)
          }
          helperText={
            _isEmpty(input.value)
              ? helperText
              : (meta?.touched || showErrorOnMount) && meta?.error
          }
        />
      )}
    />
  );
};

export default connect(null, contactsRedux.actions)(AutoComplete);
