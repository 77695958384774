import axios from "axios";

const WiseBerryAPIEnv = process.env.REACT_APP_WISEBERRY_API;
export const LOGIN_URL = "api/auth/login";

export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";
export const ASSETS_URL = WiseBerryAPIEnv + "assets";

export const ME_URL = "api/me";
export const LEADER_URL = "leader-rating";

let ajaxRequest = null;

export function getCurrentAppVersion() {
  return axios.get(WiseBerryAPIEnv + "get-current-version");
}

export function login(email, password) {
  return axios.post(LOGIN_URL, { email, password });
}

export function loginMailCoach() {
  return axios.post(WiseBerryAPIEnv + "mailcoach", { });
}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function postLeaderRating(prams, payload) {
  return axios.patch(
    WiseBerryAPIEnv + "leader-rating" + (prams ? "/" + prams : ""),
    payload
  );
}
export function postLeaderPairing(payload) {
  return axios.post(WiseBerryAPIEnv + "leader-rating/pair-leader", payload);
}
export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function csvDownload(type, prams) {
  return axios.get(WiseBerryAPIEnv + type + "/export?" + prams);
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.

  return axios.get(ME_URL);
}

export function getLocations(value) {
  // Authorization head should be fulfilled in interceptor.
  return axios.get("/locations", { params: { searchText: value } });
}

export function getAllSuburbs(prams) {
  // Authorization head should be fulfilled in interceptor.
  // cancel  previous ajax if exists
  if (ajaxRequest) {
    ajaxRequest.cancel();
  }

  // creates a new token for upcomming ajax (overwrite the previous one)
  ajaxRequest = axios.CancelToken.source();

  return axios.get(WiseBerryAPIEnv + "suburb?" + prams, {
    cancelToken: ajaxRequest.token,
  });
  // return axios.get(WiseBerryAPIEnv + 'suburb?' + prams)
}

export function getAllStates(prams) {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(WiseBerryAPIEnv + "state?" + prams);
}

export function getAllStreets(prams) {
  // Authorization head should be fulfilled in interceptor.
  // cancel  previous ajax if exists
  if (ajaxRequest) {
    ajaxRequest.cancel();
  }

  // creates a new token for upcomming ajax (overwrite the previous one)
  ajaxRequest = axios.CancelToken.source();

  return axios.get(WiseBerryAPIEnv + "street?" + prams, {
    cancelToken: ajaxRequest.token,
  });
  // return axios.get(WiseBerryAPIEnv + 'street?' + prams)
}

export function getAllMembers(prams) {
  return axios.get(
    WiseBerryAPIEnv + "member/team-members" + (prams ? "?" + prams : "")
  );
}
export function getLeaderDetails(prams) {
  return axios.get(
    WiseBerryAPIEnv + "leader-rating" + (prams ? "?leader=" + prams : "")
  );
}
export function getLeadersAvailable() {
  return axios.get(WiseBerryAPIEnv + "leader-rating/available-leaders");
}

export function getAllRole(prams) {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(WiseBerryAPIEnv + "role");
}

export function getMenu(prams) {
  return axios.get(WiseBerryAPIEnv + "menu?name=" + prams);
}

//  Notes Api
export function createNotes(prams) {
  return axios.post(WiseBerryAPIEnv + "note", prams, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function updateNotes(noteId, prams) {
  return axios.post(WiseBerryAPIEnv + "note/" + noteId, prams, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function getAllNotes(prams) {
  return axios.get(WiseBerryAPIEnv + "note?" + prams);
}

// Notes Api

//  SMS & Email
export function sendSms(queryString, prams) {
  return axios.post(WiseBerryAPIEnv + "member/send-sms?" + queryString, prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function sendEmail(queryString, prams) {
  return axios.post(
    WiseBerryAPIEnv + "member/send-email?" + queryString,
    prams,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
}

//

// Notifications

export function getAllNotification(prams) {
  return axios.get(WiseBerryAPIEnv + "member/notifications?member=" + prams);
}

export function getNotificationsUndeleted(prams) {
  return axios.get(
    WiseBerryAPIEnv + "member/notifications"
  );
}

export function getUnseenNotificationNumber(prams) {
  return axios.get(
    WiseBerryAPIEnv + "member/unseen-notifications-count?member=" + prams
  );
}

export function markNotificationAsUserDeleted(prams) {
  return axios.post(
    WiseBerryAPIEnv + "member/notification-user-delete",
    prams,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
}

export function markAllNotificationsAsUserDeleted(prams) {
  return axios.post(
    WiseBerryAPIEnv + "member/notification-user-deleteall",
    prams,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
}

export function addNewNotification(prams) {
  return axios.post(WiseBerryAPIEnv + "communication", prams, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
// Notifications

// Timeline
export function createTimeline(prams) {
  return axios.post(WiseBerryAPIEnv + "timeline", prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}
// Timeline
export function getAllActivities(prams) {
  return axios.get(WiseBerryAPIEnv + "communication?" + prams);
}

export function addNewActivity(prams) {
  return axios.post(WiseBerryAPIEnv + "communication", prams, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function updateActivity(id, prams) {
  return axios.post(WiseBerryAPIEnv + "communication/" + id, prams, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function bulkDeleteCommunication(prams) {
  return axios.post(WiseBerryAPIEnv + "communication/bulk-delete", prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}
// Automated mail
export function getAllAutomatedEmail(prams) {
  return axios.get(WiseBerryAPIEnv + "automated-email?" + prams);
}

export function updateAutomatedEmail(id, prams) {
  return axios.post(WiseBerryAPIEnv + "automated-email/" + id, prams, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function bulkDeleteAutomatedEmail(prams) {
  return axios.post(WiseBerryAPIEnv + "automated-email/bulk-delete", prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function sendSingleAutomatedMail(id, prams) {
  return axios.patch(WiseBerryAPIEnv + "automated-email/" + id, prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}
export function sendBulkAutomatedMail(prams) {
  return axios.post(
    WiseBerryAPIEnv + "automated-email/bulk-email-send",
    prams,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
}
export function deleteBulkAutomatedMail(prams) {
  return axios.post(WiseBerryAPIEnv + "automated-email/bulk-delete", prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}
export function automatedEmailPreview(id, prams) {
  return axios.get(WiseBerryAPIEnv + "automated-email/preview/" + id, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}
// Automated mail

export function deleteCommunication(id) {
  return axios.delete(WiseBerryAPIEnv + "communication/" + id);
}

export function postTagBulkUpdate(prams) {
  return axios.post(WiseBerryAPIEnv + "organisation/tag/bulk-update", prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function postRatingBulkUpdate(prams) {
  return axios.post(
    WiseBerryAPIEnv + "organisation/rating/bulk-update",
    prams,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
}

export function postBuyerTypeBulkUpdate(prams) {
  return axios.post(WiseBerryAPIEnv + "buyer-type/bulk-update", prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function postTenantTypeBulkUpdate(prams) {
  return axios.post(WiseBerryAPIEnv + "tenant-type/bulk-update", prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

// files

// getTeams

export function getTeams(prams) {
  return axios.get(WiseBerryAPIEnv + "organisation/teams");
}

export function postCallReminder(prams) {
  return axios.post(WiseBerryAPIEnv + "call-reminder", prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function postCallReminderComplete(prams) {
  return axios.post(WiseBerryAPIEnv + "call-reminder/complete", prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function undoCallReminderComplete(prams) {
  return axios.post(WiseBerryAPIEnv + "call-reminder/undo", prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function getCallReminder(prams) {
  return axios.get(WiseBerryAPIEnv + "call-reminder?" + prams);
}

export function getAccountInvoice(params) {
  return axios.get(WiseBerryAPIEnv + "account?" + params);
}

export function submitAccountInvoice(params) {
  return axios.post(WiseBerryAPIEnv + "account-invoice", params, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function submitUserOffice(id, params) {
  return axios.post(`${WiseBerryAPIEnv}member/${id}`, params, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function submitAccountConjuction(params) {
  return axios.post(`${WiseBerryAPIEnv}account`, params, {
    headers: {
      "Content-Type": "application/json",
    },
    responseType: "blob",
  });
}

export function updateAsset(id, params) {
  return axios.post(`${ASSETS_URL}/${id}`, params, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

//get all member from all office
export function getAllMember(id, params) {
    return axios.post(`${WiseBerryAPIEnv}member/get-all`, params, {
        headers: {
            "Content-Type": "application/json",
        },
    });
}
//switch account
  export function switchAccount(params) {
    // return axios.get(WiseBerryAPIEnv + "switch-account?" + params);
    return axios.post(`${WiseBerryAPIEnv}switch-account`, params, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  //get listingfeedback when type
export function getListingFeedbackWhenType(params) {
  return axios.get(`${WiseBerryAPIEnv}menu?name=listing_feedback_duration`, params, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}
