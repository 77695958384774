import React, { useEffect, useState } from "react";
import {
  TextField,
  InputAdornment /* Select, MenuItem */,
} from "@material-ui/core";
import moment from "moment";
import NumberFormatCustom from "../../../../../partials/form-basic/textField/NumberFormatCustom.jsx";
// const OPTIONS = [
//   { value: 1, label: 'Listings' },
//   { value: 2, label: 'Rentals' }
// ]

export function OpenHomeHeadingSettings({
  selectedNode,
  setSeletctedDate = () => {},
}) {
  const [heading, setHeading] = useState("");
  const [subHeading, setSubHeading] = useState("");
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  // const [type, setType] = useState(1)

  const getTargetElement = (targetClassName) => {
    return selectedNode.getElementsByClassName(targetClassName)[0];
  };

  useEffect(() => {
    setHeading(getTargetElement("main-heading-node").innerText);
    let regex = /\[(.*?)\]/gi; // to find a string enclosing with []
    let subHeadingDay = getTargetElement("sub-heading-node-day").innerText;
    let subHeadingMonth = getTargetElement("sub-heading-node-month").innerText;
    let finalDate = `${subHeadingDay}, ${subHeadingMonth}`;
    setSubHeading(finalDate);
    if (!regex.test(finalDate)) {
      setDate(moment(finalDate, "dddd Do MMMM YYYY").format("YYYY-MM-DD"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNode]);

  const handleDateChange = (e) => {
    setDate(e.target.value);
    setSeletctedDate(e.target.value);
    let processedDate = moment(e.target.value).format("dddd Do MMMM YYYY");
    let processedDay = moment(e.target.value).format("dddd");
    let processedmonth = moment(e.target.value).format("Do MMMM YYYY");
    setSubHeading(processedDate);
    getTargetElement(
      "sub-heading-node-day"
    ).innerHTML = processedDay.toUpperCase();
    getTargetElement("sub-heading-node-month").innerHTML = processedmonth;
  };
  console.log("dateVAlueeeee", date);
  return (
    <div>
      <h5 className="mt-4 font-weight-bold">Open Homes</h5>
      <TextField
        className="mt-4"
        type="date"
        label="Date"
        fullWidth
        value={date}
        onChange={handleDateChange}
        InputLabelProps={{
          shrink: true,
        }}
      />
      {/* <Select
        fullWidth
        className='mt-5'
        label="Type"
        value={type}
        onChange={e => setType(e.target.value)}
      >
        {OPTIONS.map((obj, index) => (
          <MenuItem key={index} value={obj.value}>
            {obj.label}
          </MenuItem>
        ))}
      </Select> */}
    </div>
  );
}

const START_OF_THE_DAY = moment()
  .startOf("date")
  .format("hh:mm");

export function OpenHomeContentSettings({ selectedNode }) {
  const [time, setTime] = useState(START_OF_THE_DAY);
  const [address, setAddress] = useState("");
  const [price, setPrice] = useState("");
  const [priceVisible, setPriceVisible] = useState(false);

  const getTargetElement = (targetClassName) => {
    return selectedNode.getElementsByClassName(targetClassName)[0];
  };

  useEffect(() => {
    setAddress(getTargetElement("address").innerText);
    let regex = /\[(.*?)\]/gi; // to find a string enclosing with []
    let _time = getTargetElement("time").innerText;
    if (!regex.test(_time)) {
      setTime(moment(_time, "hh:mm a").format("HH:mm"));
    } else {
      setTime(START_OF_THE_DAY);
    }
    let price = getTargetElement("price-node");
    if (price) {
      setPriceVisible(true);
      setPrice(price?.innerText);
    } else {
      setPrice("");
      setPriceVisible(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNode]);
  useEffect(() => {
    let priceNode = getTargetElement("price-node");
    let seperatorNode = getTargetElement("auction-seperator-line");
    if (
       priceNode && 
      (priceNode?.innerText == "$0" || priceNode.innerText == "[Listing Price]")
    ) {
      priceNode.style.display = "none";
      if(seperatorNode){
        seperatorNode.style.display = "none";
      }

    } else {
      priceNode.style.display = "block";
      if(seperatorNode){
        seperatorNode.style.display = "block";
      }

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [price]);

  const handleTimeChange = (e) => {
    getTargetElement("time").innerHTML = moment(
      e.target.value,
      "hh:mm A"
    ).format("h:mm A");
    setTime(e.target.value);
  };

  const handleAddressChange = (e) => {
    getTargetElement("address").innerHTML = e.target.value;
    setAddress(e.target.value);
  };

  const handlePriceChange = (e) => {
    setPrice(e);
    getTargetElement("price-node").innerHTML = `$${e.toLocaleString()}`;
  };

  return (
    <div>
      <TextField
        type="time"
        id="time"
        label="Start"
        className="my-3"
        fullWidth
        value={time}
        onChange={handleTimeChange}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <TextField
        className="my-3"
        type="text"
        label="Address"
        value={address}
        onChange={handleAddressChange}
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
      />
      {priceVisible ? (
        <TextField
          className="my-3"
          type="text"
          label="Price"
          value={price}
          onChange={handlePriceChange}
          fullWidth
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
            inputComponent: NumberFormatCustom,
            // inputProps: {
            //   setZeroForEmpty: setZeroForEmpty,
            //   allowNegative: allowNegative
            // }
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      ) : null}
    </div>
  );
}
