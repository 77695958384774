import React, { Fragment } from "react";
import { Form, Col } from "react-bootstrap";
import { Field } from "redux-form";
import { InputAdornment } from "@material-ui/core";

import TextArea from "../../../../../../../partials/form/TextArea";
import DateInput from "../../../../../../../partials/form/DateInput";
import TextInputAmount from "../../../../../../../partials/form/TextInputAmount";
import { required } from "../../../../../../validation/Validation";

const MakeAnOfferItem = ({
  fields,
  meta: { error, submitFailed },
  label,
  numSelected
}) => {
  // useEffect(() => {
  //   console.log(numSelected);
  //   numSelected.map((obj) => {
  //     fields.push({ date: new Date(), address: obj?.address?.full_address , listing: obj.id,  offer_party: 1 });
  //     console.log(obj.address);
  //   });
  // }, []);
  return (
    <Fragment>
      <div className="makeAnOfferItem">
        {fields.map((offer, index, members) => (
          <div>
            <Form.Row>
              <Col md={12}>
                <p className="font-weight-bold">{members.get(index).address} {console.log(members.get(index))} </p>
              </Col>
            </Form.Row>
            <Form.Row>
              {/* <Col md={6}>
                <Field
                  name={`${offer}.date`}
                  component={DateInput}
                  timeIntervals={15}
                  type="datetime-local"
                  placeholder="Creation Date From"
                  label="Date"
                />
              </Col> */}

              <Col md={6}>
                <Field
                  name={`${offer}.offer_amount`}
                  type="number"
                  validate={[required]}
                  component={TextInputAmount}
                  label="Offer"
                  allowNegative={false}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                />
              </Col>
            </Form.Row>
            <Form.Row>
              <Col md={12}>
                <Field
                  name={`${offer}.comments`}
                  type="textarea"
                  rows="3"
                  component={TextArea}
                  placeholder="Comment about the offer"
                  label="Comments"
                />
              </Col>
            </Form.Row>
            <hr className="mb-5 mt-5" />
          </div>
        ))}
      </div>
    </Fragment>
  );
};

export default MakeAnOfferItem;
