import React, { useState, Fragment, useEffect } from "react";
import { Form, Col, Row } from "react-bootstrap";
import {
  reduxForm,
  Field,
  formValueSelector,
  FormSection,
  FieldArray,
  getFormValues,
} from "redux-form";
import { connect, useSelector } from "react-redux";
import TextInput from "../../../../partials/form/TextInput";
import MultiSelect from "../../../../partials/form/MultiSelect";
import TextArea from "../../../../partials/form/TextArea";
import ThemeHeader from "../../../../partials/content/ThemeHeader";
import ThemeButton from "../../../../partials/button/ThemeButton";
import RadioInput from "../../../../partials/form/RadioInput";
import { Divider, InputAdornment, LinearProgress } from "@material-ui/core";
import { getAllSuburbs, getMenu } from "../../../../GeneralApi/GeneralApi";
import AsyncMultiSelect from "../../../../partials/form/AsyncMultiSelect";
import NestedPhone from "../../../../partials/form/NestedPhone";
import { createNewReferral } from "../../crud/buyersCrud";
import { convertFormData } from "../../../../_helpers/_utils/utils";
import { required } from "../../../validation/Validation";
const queryString = require("query-string");

const getOptionSuburbLabel = (option) => (
  <Fragment>
    {option.label ? `${option.label} ${option?.state_name}` : ""}
  </Fragment>
);



const BuyerReferralForm = (props) => {
  const {
    handleSubmit,
    buyerId,
    pristine,
    closeModal,
    updateTable,
    submitting,
    outcome,
    allValues,
    invalid,
    prospectProfileType = false
  } = props;
  const [contact_method, setContact_method] = useState(null);
  const [isLoading, setIsLoading] = useState(null);

  const getSuburbOptions = (text) => {
    let query = queryString.stringify({ name: text });

    return getAllSuburbs(query)
      .then(({ data }) => {
        let options = data.data.map((item) => ({
          value: item.id,
          label: item.name,
          state_id: item.state.id,
          state_name: item.state.abbrev,
          postcode: item.postcode,
        }));
        return options;
      })
      .catch((e) => { });
  };

  useEffect(() => {
    update();
  }, []);

  const update = () => {
    let query = "contact_method";
    getMenu(query)
      .then(({ data: { data } }) => {
        setContact_method(data.contact_method);
      })
      .catch(() => { });
  };

  const userOptions = [
    {
      name: "Refer buyer for finance",
      id: "1",
    },
    {
      name: "Do not refer buyer for finance",
      id: "2",
    },
  ];

  const status = [
    {
      name: "Approved",
      id: "1",
    },
    {
      name: "Not Approved",
      id: "2",
    },
    {
      name: "Pending",
      id: "3",
    },
  ];

  const user = useSelector(({ auth }) => auth.user.contact);

  const handleSuburbOnClick = (e) => {
    props.initialize({ ...allValues, postcode: e.postcode });
  };

  const onFormSubmit = async (values) => {
    setIsLoading(true);
    let { buyer_contact, ...other } = values;
    let processedValue = {};
    const {
      email,
      mobile,
      phone,
      emails,
      phones,
      ...other_primary_contact
    } = buyer_contact;
    other.suburb = values?.suburb?.value;

    if (prospectProfileType) {
      other.prospect = buyerId;
    } else {
      other.buyer = buyerId;
    }
    other.referred_by = user?.id;
    other.has_disclosed = values?.has_disclosed == false ? 0 : 1;
    other.consent = values?.consent == false ? 0 : 1;
    let phoneList = [];
    if (mobile) {
      phoneList.push(mobile[0]);
    }

    if (phone) {
      phoneList.push(phone[0]);
    }

    // if (mobile.length) {
    //   emails.push(email[0]);
    // }
    let processedObj = {
      ...other_primary_contact,
      emails: email.map(({ type, ...others }) => ({ type: type?.id ?? 1, ...others })),
      phones: phoneList.map(({ type, ...others }) => ({ type: type?.id ?? 1, ...others })),
    }
    if (prospectProfileType) {
      processedValue = {
        prospect_contact: processedObj,
        ...other,
      };
    } else {
      processedValue = {
        buyer_contact: processedObj,
        ...other,
      };
    }

    const formValue = convertFormData(processedValue);
    return await createNewReferral(formValue)
      .then(({ data: { data } }) => {
        updateTable();
        closeModal();
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };
  return (
    <Fragment>
      <div className="buyerFormModal">
        <form
          className="contactForm"
          onSubmit={handleSubmit((values) => onFormSubmit(values))}
        >
          {isLoading ? <LinearProgress /> : ''}
          <Fragment>
            <ThemeHeader
              left={
                <Fragment>
                  <h5 className="title">New Referral</h5>
                </Fragment>
              }
              right={
                <Fragment>
                  <Fragment>
                    <div className="pl-2">
                      <ThemeButton
                        className="lightGrey-button font-weight-bold"
                        onClick={closeModal}
                      >
                        Cancel
                      </ThemeButton>
                    </div>
                    <div className="pl-2">
                      <ThemeButton
                        type="submit"
                        disabled={(invalid || pristine || submitting) || !allValues?.has_disclosed || !allValues?.consent}
                        className="submit-button"
                      >
                        Save
                      </ThemeButton>
                    </div>
                  </Fragment>
                </Fragment>
              }
            />

            <Form.Row>
              <Col md={4}>
                <Field
                  name="outcome"
                  component={MultiSelect}
                  isMulti={false}
                  showBottom={false}
                  options={userOptions}
                  label="Outcome"
                  placeholder="Please Select Outcome"
                />
              </Col>
            </Form.Row>
            {outcome !== "2" ? (
              <Fragment>
                <Row className="pb-2">
                  <Col md={4}>
                    <FormSection name="buyer_contact">
                      <Field
                        name="first_name"
                        placeholder="First Name"
                        component={TextInput}
                        label="First Name"
                      // validate={[required]}
                      />
                    </FormSection>
                  </Col>
                  <Col md={4}>
                    <FormSection name="buyer_contact">
                      <Field
                        name="last_name"
                        label="Last Name"
                        component={TextInput}
                      />
                    </FormSection>
                  </Col>

                  <Col md={4}>
                    <Field
                      name="preferred_contact_method"
                      placeholder="Select Contact Method"
                      component={MultiSelect}
                      showBottom={false}
                      isMulti={false}
                      options={contact_method}
                      label="Preferred Method of Contact"
                    />
                  </Col>
                </Row>
                <FormSection name="buyer_contact">
                  <Row className="pb-2">
                    <Col md={4}>
                      <FieldArray
                        label="Mobile"
                        name="mobile"
                        type="text"
                        component={NestedPhone}
                        placeholder="Mobile"
                      />
                    </Col>
                    <Col md={4}>
                      <FieldArray
                        label="Phone"
                        name="phone"
                        type="text"
                        component={NestedPhone}
                        placeholder="Phone"
                      />
                    </Col>
                    <Col md={4}>
                      <FieldArray
                        label="Email"
                        name="email"
                        type="email"
                        component={NestedPhone}
                        placeholder="Email"
                      />
                    </Col>
                  </Row>
                </FormSection>
                <Row className="pb-2">
                  <Col md={4} >
                    <Field
                      name="suburb"
                      placeholder="Enter Suburb"
                      component={AsyncMultiSelect}
                      showBottom={false}
                      isMulti={false}
                      getOptionLabel={getOptionSuburbLabel}
                      options={getSuburbOptions}
                      handleOnClick={handleSuburbOnClick}
                      label="Enter Suburb"
                      // defaultRef={suburbRef}
                      // label="Suburb   
                      // addWith= {true}
                      // edit={true}
                    />
                  </Col>
                  <Col md={4}>
                    <Field
                      name="postcode"
                      label="Postcode"
                      component={TextInput}
                    />
                  </Col>
                </Row>
                <Divider className="divider" variant="middle" />
                <Row className="pb-2">
                  <Col md={4}>
                    <Field
                      name="amount"
                      label="Approx. Amount Required"
                      validate={[required]}
                      showErrorOnMount={true}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      component={TextInput}
                    />
                  </Col>
                  <Col md={4}>
                    <Field validate={[required]} showErrorOnMount={true} name="bank" label="Bank" component={TextInput} />
                  </Col>
                </Row>
                <Divider className="divider" variant="middle" />
                <Form.Row className="pb-4">
                  <Col md={12}>
                    <Field
                      name="comments"
                      label="Comments"
                      multiple
                      row={4}
                      component={TextArea}
                    />
                  </Col>
                </Form.Row>
                <p className="d-flex">
                  <span className="pr-1">
                    {" "}
                    <Field
                      name="has_disclosed"
                      l
                      labelRight
                      component={RadioInput}
                    />
                  </span>
                  The referrer has disclosed to the client any commission or
                  associated benefits applicable to this referral.
                </p>
                <p className="d-flex">
                  <span className="pr-1">
                    {" "}
                    <Field name="consent" l labelRight component={RadioInput} />
                  </span>
                  The client have provided the referrer with consent to forward
                  their details to a licensed 3rd party provider.
                </p>
              </Fragment>
            ) : (
              ""
            )}
          </Fragment>
        </form>
      </div>
    </Fragment>
  );
};

const actions = {
  // socialFeedPost: news.actions.socialFeedPostAsync,
};

const selector = formValueSelector("buyer-referral-form");
const mapState = (state, props) => {
  let suburbValue = {};
  let mobile,
    phone,
    email,
    lastName,
    firstName,
    otherData,
    otherRefData = null;


  const {
    phones,
    emails,
    duplicate_contacts,
    last_name,
    first_name,
    addresses,
    ...otherContacts
  } = props.contact;
  lastName = last_name;
  firstName = first_name;
  mobile =
    phones?.length > 0
      ? phones.filter((item) => item?.type?.id === 1)[0]
        ? [phones.filter((item) => item?.type?.id === 1)[0]]
        : [{ type: { id: 1, name: 'Primary' }, value: "" }]
      : [{ type: { id: 1, name: 'Primary' }, value: "" }];
  phone =
    phones?.length > 0
      ? phones.filter((item) => item?.type?.id === 2)[0]
        ? [phones.filter((item) => item?.type?.id === 2)[0]]
        : [{ type: { id: 2, name: 'Primary' }, value: "" }]
      : [{ type: { id: 2, name: 'Primary' }, value: "" }];
  email =
    emails?.length > 0
      ? emails.filter((item) => item?.type?.id === 1)[0]
        ? [emails.filter((item) => item?.type?.id === 1)[0]]
        : [{ type: { id: 1, name: 'Primary' }, value: "" }]
      : [{ type: { id: 1, name: 'Primary' }, value: "" }];
  otherData = otherContacts;

  let postcode;
  if (addresses?.length) {
    suburbValue.label = addresses[0]?.street?.suburb?.name;
    suburbValue.value = addresses[0]?.street?.suburb?.id;
    postcode = addresses[0]?.street?.suburb?.postcode;
  }

  const outcome = selector(props, "outcome");
  return {
    outcome: outcome,
    allValues: getFormValues("buyer-new-referral-form")(state),
    initialValues: {
      buyer_contact: {
        mobile: mobile,
        phone: phone,
        email: email,
        phones:
          mobile || phone
            ? phones.filter((item) => item.id != mobile[0].id)?.map(({ type, ...others }) => ({ type: type?.id, ...others }))
            : [],
        emails: email
          ? emails.filter(function (el) {
            return el.id !== email[0]?.id;
          })?.map(({ type, ...others }) => ({ type: type?.id, ...others }))
          : [],
        last_name: lastName,
        first_name: firstName,
      },
      suburb: suburbValue,
      postcode: postcode,
      ...otherRefData,
      status: 1,
    },
  };
};
export default connect(
  mapState,
  actions
)(reduxForm({ form: "buyer-new-referral-form" })(BuyerReferralForm));
