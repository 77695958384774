import React, { useEffect, useState, Fragment } from "react";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import {
  convertFormData,
  dotString,
  formToQueryString,
  getMailProcessData,
} from "../../../../../_helpers/_utils/utils";
import {
  addNewActivity,
  deleteCommunication,
  getAllActivities,
} from "../../../../../GeneralApi/GeneralApi";
import AddToMail from "../../../../../partials/component/addToMail/AddToMail";
import DefaultModal from "../../../../../partials/modal/DefaultModal";
import ThemeHeader from "../../../../../partials/content/ThemeHeader";
import ThemeButton from "../../../../../partials/button/ThemeButton";
import DynamicTableEdit from "../../../../../partials/table/DynamicTableEdit";
import { toast } from "react-toastify";
import MemberName from "../../../../../partials/popover/memberPopover/MemberName";
import AccessControl from "../../../../../_ accessControl/AccessControl";

const ContactsMail = ({ edit, contactId, profile }) => {
  const [items, setItems] = useState(null);
  const [otherResData, setOtherResData] = useState(null);
  const [showAddEmail, setShowAddEmail] = useState(false);
  const [showRowOnClick, setShowRowOnClick] = useState(false);
  const [rowOnClickData, setRowOnClickData] = useState(null);
  const [selectedRow, setSelectedRow] = useState([{}]);

  // setItems(data.data);
  console.log(edit);
  useEffect(() => {
    update();
  }, [contactId]);

  const update = (value = {}) => {
    let obj = value;
    obj.contact = contactId;
    let query = formToQueryString(obj);

    if (contactId) {
      getAllActivities(query)
        .then((res) => {
          const { data, ...rest } = res.data;
          if (value?.loadMore) {
            setItems(items.concat(data));
          } else {
            setItems(data);
          }
          setOtherResData(rest);
        })
        .catch(() => {});
    }
  };

  console.log(items);
  const fetchMoreData = () => {
    if (otherResData?.next_page_url) {
      update({ page: otherResData.current_page + 1, loadMore: true });
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Mail",
        columns: [
          {
            Header: "Date",
            accessor: "created_at",
            Cell: ({ cell: { value } }) => (
              <p className="text-center">
                {moment(value).format("DD/MM/YYYY")}
              </p>
            ),
          },
          {
            Header: "Time",
            accessor: "id",
            Cell: ({
              cell: {
                row: { original },
              },
            }) => (
              <p className="text-center">
                {moment(original.created_at).format("LT")}
              </p>
            ),
          },
          {
            Header: "By",
            accessor: "from_contact",
            Cell: ({
              cell: {
                row: { original },
              },
              cell: { value },
            }) => (
              <div className="text-center" onClick={(e) => e.stopPropagation()}>
                <MemberName id={value?.id}>
                  {value?.initial ? value?.initial : "Contact"}
                </MemberName>
              </div>
            ),
          },
          {
            Header: "Type",
            accessor: "type",
            Cell: ({
              cell: {
                row: { original },
              },
              cell: { value },
            }) => (
              <p className="text-center">
                {original?.type?.id === 1 ? (
                  <i className="fas fa-mobile    "></i>
                ) : (
                  <i className="fa fa-envelope-open" aria-hidden="true"></i>
                )}
              </p>
            ),
          },
          {
            Header: "Subject",
            accessor: "subject",
            Cell: ({
              cell: {
                row: { original },
              },
            }) => (
              <p>
                {original?.email
                  ? dotString(original.email.email_subject, 60, 20)
                  : original?.sms
                  ? dotString(original?.sms?.content, 60, 20)
                  : ""}
              </p>
            ),
          },
        ],
      },
    ],
    []
  );

  const rowClick = (e) => {
    setRowOnClickData(e);
    setShowRowOnClick(!showRowOnClick);
  };

  const handleMailAdd = (e) => {
    const { email_subject, content, type, ...others } = e;
    let formData = { ...others };
    if (type == 2) {
      formData.email = {
        content:content, // draftToHtml(convertToRaw(content)),
        email_subject: email_subject,
      };
    }
    if (type == 1) {
      formData.sms = { content: content };
    }
    formData.type = type;
    formData.contacts = [contactId];
    formData.created_manually = 1;

    formData = convertFormData(formData);

    addNewActivity(formData)
      .then(({ data }) => {
        toast.success("Mail Added Successfully");
        setShowAddEmail(false);
        update();
      })
      .catch((e) => {
        toast.error("Error while saving");
      });
    console.log(...formData);

    //
  };

  const handleRemove = () => {
    selectedRow.map((item) => {
      deleteCommunication(item.id)
        .then(({ data }) => {
          update();
        })
        .catch((e) => {
          toast.error("Error while Deleting");
        });
    });
  };

  const datas = React.useMemo(() => items, [items]);

  return (
    <div>
      {showAddEmail ? (
        <DefaultModal
          size="xl"
          centered
          show={showAddEmail}
          onHide={() => setShowAddEmail(false)}
        >
          <AddToMail
            onHide={() => setShowAddEmail(false)}
            onSubmit={handleMailAdd}
            currentContact={{
              name: profile.first_name + " " + profile.last_name,
              id: profile.id,
            }}
          />
        </DefaultModal>
      ) : (
        ""
      )}

      {showRowOnClick ? (
        <DefaultModal
          size="xl"
          centered
          show={showRowOnClick}
          onHide={() => setShowRowOnClick(false)}
        >
          <AddToMail
            initialValues={getMailProcessData(rowOnClickData)}
            clickData={rowOnClickData}
            onHide={() => setShowRowOnClick(false)}
            onSubmit={handleMailAdd}
            currentContact={{
              name: profile.first_name + " " + profile.last_name,
              id: profile.id,
            }}
          />
        </DefaultModal>
      ) : (
        ""
      )}

      <ThemeHeader
        right={
          <Fragment>
            <AccessControl
              allowedPermissions={["contacts-download"]}
              renderNoAccess={() => ""}
            >
              <ThemeButton
                icon
                className="mr-2 lightGrey-button-icon"
                // OnClick={() => setDeleteItem((prev) => prev + 1)}
              >
                <i className="fas fa-download    "></i>{" "}
              </ThemeButton>
            </AccessControl>

            <AccessControl
              allowedPermissions={["contacts-delete"]}
              renderNoAccess={() => ""}
            >
              <ThemeButton
                className="mr-2 lightGrey-button font-weight-bold"
                onClick={() => handleRemove()}
                disabled={!selectedRow.length}
              >
                Remove{" "}
              </ThemeButton>
            </AccessControl>

            <ThemeButton
              onClick={() => setShowAddEmail(true)}
              className=" green-button font-weight-bold"
            >
              Add
            </ThemeButton>
          </Fragment>
        }
      />
      <div className="mail-log-table">
        {datas ? (
          <DynamicTableEdit
            allSelected={(e) => console.log(e.target.checked)}
            handleChange={(e) =>
              setSelectedRow(e.filter((obj) => obj.created_manually))
            }
            // isEditable="false"
            isEditable={true}
            isConditional
            columns={columns}
            data={datas}
            update={fetchMoreData}
            handleRowClick={rowClick}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default ContactsMail;
