import React from "react";
import { useSelector } from "react-redux";
import AddNewListingsBody from "./AddNewListingsBody";
import moment from "moment";

const AddNewListingWrap = ({ closeModal, setListingType, prospectAddress }) => {
  const currentUser = useSelector(({ auth }) => auth.user);
  return (
    <AddNewListingsBody
      closeModal={closeModal}
      setListingType={setListingType}
      initialValues={{
        contract_date: moment().format("YYYY-MM-DD"),
        starts: moment().format("YYYY-MM-DD"),
        ends: "",
        agency_agreement_check_date: moment().format("YYYY-MM-DD"),
        organisation: currentUser.organisation.id,
        display_agent: currentUser.contact.id,
        show_full_address: 1,
        listing_agent: currentUser.contact.id,
        features: [
          { feature: 6 },
          { feature: 5 },
          { feature: 23 },
          { feature: 20 },
        ],
        status: 1,
        agreement_type: 1,
        address: [
          prospectAddress ?? {
            type: 1,
            street_unit: "",
            street: {},
            street_name: {},
            suburb: {},
            state: {},
          },
        ],
      }}
    />
  );
};

export default AddNewListingWrap;
