import React, { Fragment, useEffect, useState } from "react";
import { Accordion, Col, Form, Row } from "react-bootstrap";
import { Field } from "redux-form";
import { getMenu } from "../../../GeneralApi/GeneralApi";
import DateInput from "../../form/DateInput";
import MultiSelect from "../../form/MultiSelect";
import TextInput from "../../form/TextInput";
import CustomToggle from "../customToggle/CustomToggle";

const PersonalDetails = () => {

  const [tags, setTags] = useState({});


  useEffect(() => {

    let query = "contact_type,tag,marital_status"
    getMenu(query)
      .then(({ data: { data } }) => {
        setTags(data.tag)
      })
      .catch(() => { });
  }, []);
  return (
    <Fragment>
      <CustomToggle eventKey="1" rightArrow={true} tabIndex={'-1'}>Personal Details</CustomToggle>
      <Accordion.Collapse className="mb-5" eventKey="1">
        <Form.Group as={Row}>

          <Col className="pb-1" sm={12}>
            <Field
              name="contact_tags"
              component={MultiSelect}
              showBottom={false}
              isMulti={true}
              defaultValue
              options={tags}
              placeholder="Tags"
              label="Tags"
            />
          </Col>
          <Col className="pb-1 mt-2" sm={12}>
            <div className="row">
              <div className="col-6 pr-1">
                <Field name="age_from" component={TextInput} label="Age From" placeholder="Age From" />
              </div>
              <div className="col-6 pl-1">
                <Field name="age_to" component={TextInput} label="Age To" placeholder="Age To" />
              </div>
            </div>
          </Col>
          <Col className="pb-1 mt-2" sm={12}>
            <div className="row">
              <div className="col-6 pr-1">
                <Field name="dob_from" type="date" component={DateInput} label="Birthday From" placeholder="Birthday From" />
              </div>
              <div className="col-6 pl-1">
                <Field name="dob_to" type="date" component={DateInput} label="Birthday To" placeholder="Birthday To" />
              </div>
            </div>
          </Col>
        </Form.Group>
      </Accordion.Collapse>
    </Fragment>
  )
}

export default PersonalDetails
