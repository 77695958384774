import React, { Fragment, useState, useEffect, useContext } from "react";
import { Row } from "react-bootstrap";
import ThemeButton from "../../../../../partials/button/ThemeButton";
import NoteLists from "../../../../../partials/component/notes/NoteLists";
import {
  convertFormData,
  formToQueryString,
  wbSuccessToast,
} from "../../../../../_helpers/_utils/utils";
import { createNotes, updateNotes } from "../../../../../GeneralApi/GeneralApi";
import { getTimelineByContactId } from "../../../../../modules/contacts/crud/contactsCrud";
import BuyerNotesForm from "./BuyerNotesForm";
import { reset } from "redux-form";
import * as buyersRedux from "../../../_redux/buyersRedux";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  getNotesByBuyerIdAndCreatedBy,
  postDoorKnock,
} from "../../../crud/buyersCrud";
import { toast } from "react-toastify";
import { BuyerProfileContext } from "../BuyerProfileContextProvider";
import AccessControl from "../../../../../_ accessControl/AccessControl";
import moment from "moment";

const BuyerNotes = (props) => {
  const {
    // contactId,
    profileId,
    // buyerSelectedChangeId,
    // buyers,
    permissions,
    currentUser,
    setPageCount,
    refresh = false,
    setRefresh = () => {},
  } = props;

  console.log("###refresh", refresh);

  const [notes, setNotes] = useState([]);
  const [otherResData, setOtherResData] = useState([]);
  const [pastTimeline, setPastTimeline] = useState([]);
  const dispatch = useDispatch();
  const is_leader = useSelector((state) => state.auth.user.is_leader);

  const {
    continuousMode,
    changeDirection,
    setBuyerLoading,
    conNote,
  } = useContext(BuyerProfileContext);
  useEffect(() => {
    if (!continuousMode || profileId) {
      update();
    } else if (continuousMode && conNote) {
      update();
    }
  }, [conNote, continuousMode, profileId]);

  useEffect(() => {
    getPastUpdate(
      formToQueryString({
        type: 5,
        buyer: profileId,
        direction: "past",
        // limit: 20,
      })
    );
  }, [profileId]);

  useEffect(() => {
    console.log("###refresh 123", refresh);
    if (refresh) {
      console.log("###refresh 234", refresh);
      update();
      setRefresh(false);
    }
  }, [refresh]);

  const getPastUpdate = (prams) => {
    getTimelineByContactId(prams)
      .then((res) => {
        let { data, ...other } = res.data;

        setPastTimeline(data);
      })
      .catch(() => {});
  };

  let combine = pastTimeline?.map((past) => {
    let map;
    map = {
      created_at: past?.timeline_date,
      created_by: "",
      detail: past?.text.startsWith("Made an offer ") && past?.text,
      id: past?.id,
      pinned: null,
      type: past?.type,
    };

    return map;
  });

  let offers = combine?.filter((offer) => {
    let detailsWithText;
    if (offer.detail !== false) {
      detailsWithText = offer;
      return offer;
    }

    return detailsWithText;
  });
  let listC = notes.concat(offers);
  listC.sort((a, b) => {
    return moment(a.created_at)
      .format("YYYY-MM-DDTHH:mm")
      .localeCompare(moment(b.created_at).format("YYYY-MM-DDTHH:mm"));
  });

  const update = (value = null) => {
    if (conNote || profileId) {
      setBuyerLoading(true);
      let obj = {};
      obj.buyer = conNote || profileId;

      if (value?.page) {
        obj.page = value.page;
      }

      if (
        permissions?.some((permission) =>
          ["buyers-all"].includes(permission?.name)
        ) ||
        is_leader
      ) {
        getNotesByBuyerIdAndCreatedBy(formToQueryString(obj))
          .then(({ data: { data, ...rest } }) => {
            if (value?.loadMore) {
              setNotes(notes.concat(data));
            } else {
              setNotes(data);
            }
            setOtherResData(rest);
            setBuyerLoading(false);
          })
          .catch(() => {
            setBuyerLoading(false);
          });
      } else if (
        permissions?.some((permission) =>
          ["buyers-self"].includes(permission?.name)
        ) ||
        is_leader
      ) {
        obj.created_by = currentUser;
        getNotesByBuyerIdAndCreatedBy(formToQueryString(obj))
          .then(({ data: { data, ...rest } }) => {
            if (value?.loadMore) {
              setNotes(notes.concat(data));
            } else {
              setNotes(data);
            }
            setOtherResData(rest);
          })
          .catch(() => {});
      }
    }
  };

  const handleMakePinned = (noteId, isPinned) => {
    const formData = convertFormData({});
    formData.append("pinned", isPinned === 1 ? 0 : 1);
    formData.append("_method", "PATCH");
    if (profileId) {
      updateNotes(noteId, formData)
        .then(({ data: { data } }) => {
          wbSuccessToast("success", data.message);
          update();
        })
        .catch((e) => {
          console.log(e);
          // wbSuccessToast('error',data.message);
        });
    }
  };

  const handleOnSubmit = async (e) => {
    // debugger
    let storedNote = localStorage.getItem("note");
    let obj = {};
    let postCall;
    setBuyerLoading(true);
    if (
      e.type == "NA" &&
      e.notes == null &&
      storedNote === (undefined || null)
    )
    {
      obj = {
        buyer: conNote || profileId,
        na: 1,
        note: { type: 1, detail: "[Not Available] Na Clicked", buyer: conNote || profileId },
      };
      const doorKnockData = convertFormData(obj);
      postCall = await postDoorKnock(doorKnockData)
        .then(({ data: { data } }) => {
          if (continuousMode) {
            changeDirection("right");
            setPageCount((count) => count + 1);
          }

          toast.success("Na Saved");
          setBuyerLoading(false);
          update();
        })
        .catch((e) => {
          console.log(e);
          setBuyerLoading(false);
          // wbSuccessToast('error',data.message);
        });

      dispatch(reset("buyer-notes"));
      localStorage.removeItem("note");
      document.getElementById("buyer-notes-note").value = "";
    }
    else
    {
      if (storedNote === (undefined || null) && e.notes == null) {
        setBuyerLoading(false);
        //toast.error("Note is empty");
      } else {
        if (e.type) {
          if (e.type == "BC") {
            obj = {
              buyer: profileId,
              phone_call: 1,
              door_knock: 0,
              note: { ...e.notes[0], detail: '[Buyer Call] ' + e.notes[0].detail, buyer: profileId },
            };
          } else {
            obj = {
              buyer: profileId,
              na: 1,
              note: { ...e.notes[0], detail: '[Not Available] ' + e.notes[0].detail, buyer: profileId },
            };
          }

          const doorKnockData = convertFormData(obj);
          postCall = await postDoorKnock(doorKnockData)
            .then(({ data: { data } }) => {
              update();
              setBuyerLoading(false);
              localStorage.removeItem("note");
            })
            .catch((e) => {
              console.log(e);
              setBuyerLoading(false);
              // wbSuccessToast('error',data.message);
            });

          if (continuousMode) {
            changeDirection("right");
            setPageCount((count) => count + 1);
          }
        } else {
          const formData = convertFormData(e.notes[0]);
          formData.append("buyer", profileId);
          if (profileId) {
            postCall = await createNotes(formData)
              .then(({ data: { data } }) => {
                wbSuccessToast("success", data.message);

                setBuyerLoading(false);
                update();
              })
              .catch((e) => {
                setBuyerLoading(false);
                console.log(e);
                // wbSuccessToast('error',data.message);
              });
          }
        }

        if (continuousMode) {
          changeDirection("right");
          setPageCount((count) => count + 1);
        }

        dispatch(reset("buyer-notes"));
        if (document.getElementById("buyer-notes-note")) {
            document.getElementById("buyer-notes-note").value = "";
        }
        
      }
    }

    return postCall;
  };
  const fetchMore = () => {
    if (otherResData?.next_page_url) {
      update({ page: otherResData.current_page + 1, loadMore: true });
    }
  };
  return (
    <Fragment>
      <BuyerNotesForm onSubmit={handleOnSubmit} />
      <div className="d-flex justify-content-end">
        <AccessControl
          allowedPermissions={["buyers-download"]}
          renderNoAccess={() => ""}
        >
          <div className="pb-2">
            <ThemeButton icon>
              <i className="fas fa-download"></i>
            </ThemeButton>
          </div>
        </AccessControl>
      </div>
      <Row></Row>
      <NoteLists
        makePinned={handleMakePinned}
        fetchMore={fetchMore}
        notes={listC}
      />
    </Fragment>
  );
};

const mapStateToProps = (state, { buyers }) => {
  const permissions = state.auth.user.permissions;
  const currentUser = state.auth.user.contact?.id;
  return {
    buyers: buyers,
    permissions,
    currentUser,
  };
};

const actions = {
  buyerSelectedChangeId: buyersRedux.actions.buyerSelectedChangeId,
};

export default connect(mapStateToProps, actions)(BuyerNotes);
