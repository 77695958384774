import React, { Fragment } from "react";

import { FieldArray } from "redux-form";
import FormFeatures from "../../../../partials/form/partials/FormFeatures";

const AddNewListingFeatures = () => {
  return (
    <Fragment>
      <FieldArray name="features" component={FormFeatures} />
    </Fragment>
  );
};

export default AddNewListingFeatures;
