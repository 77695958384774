import React, { Fragment } from "react";
import { toast } from "react-toastify";
import { sendSms } from "../../../GeneralApi/GeneralApi";
import { convertFormData } from "../../../_helpers/_utils/utils";
import Sms from "./Sms";
import PreviewSms from "./PreviewSms";

const SmsWrap = (props) => {
  const {
    handleSubmit,
    sms,
    queryString,
    data,
    totalRecipient,
    isAllSelected,
    closeModal,
    change,
    // phoneData,
    attached,
    preview,
    previewData,
  } = props;

  const handleOnSubmit = (values) => {
    let unProcessedData = {};
    unProcessedData.sms_attributes = {};
    unProcessedData.sms_attributes.additional_numbers =
      values.additional_numbers;
    unProcessedData.sms_attributes.message = values.message;
    unProcessedData.sms_attributes.contact_ids = isAllSelected
      ? []
      : data.map((a) => a.id);


    let formData = convertFormData(unProcessedData);
  

    sendSms(queryString ? queryString : "", unProcessedData)
      .then(({ data: { data } }) => {
        toast.success("SMS Sent Out Successfully");
        closeModal();
      })
      .catch(() => {});
  };
  return (
    <Fragment>
      {" "}
    { preview ?  <PreviewSms {...props}/> : <Sms onSubmit={(e) => handleOnSubmit(e)} {...props} />}
    </Fragment>
  );
};

export default SmsWrap;
