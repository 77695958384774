import React from "react";
import {Grid} from "@material-ui/core";
import MultiSelect from "../../../../partials/form/MultiSelect";
import MicrositeFormLabel from "./MicrositeFormLabel";

const MicrositeDropdown = ({
                               label,
                               name,
                               value,
                               placeholder,
                               options,
                               disabled,
                               onChange,
                               isMulti = false,
                               isLoading = false,
                               ...rest
                           }) => (
    <Grid container direction="row" style={{marginBottom: 12}}>
        <Grid item xs={12}>
            <MicrositeFormLabel label={label} disabled={disabled}/>
        </Grid>
        <Grid item xs={12}>
            <MultiSelect
                name={name}
                placeholder={placeholder}
                isMulti={isMulti}
                options={options}
                label={false}
                disabled={disabled}
                onChange={(value) => onChange(value, "input", name)}
                value={value}
                enableLoading={isLoading}
                {...rest}
            />
        </Grid>
    </Grid>
);

export default MicrositeDropdown;
