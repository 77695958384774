/* eslint-disable no-restricted-imports */
import React, { useState, Fragment, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { reduxForm, Field } from "redux-form";
import { connect } from "react-redux";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";

import Skeleton from "@material-ui/lab/Skeleton";
import TextInput from "../../form/TextInput";
import ThemeButton from "../../button/ThemeButton";
import { getAllContacts } from "../../../modules/contacts/crud/contactsCrud";
const queryString = require("query-string");

const ListingSearchPopover = (props) => {
  const {
    handleSubmit,
    onHide,
    pristine,
    closeModal,
    submitting,
    onSelect,
    invalid,
  } = props;
  const [items, setItems] = useState(null);
  const [formData, setFormData] = useState(null);

  // setItems(data.data);
  console.log(items);
  useEffect(() => {
    console.log("useeffect");
    update();
  }, []);

  const update = (prams) => {
    getAllContacts(prams)
      .then(({ data }) => {
        setItems(data.data);
        console.log(data.data);
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (formData) {
      console.log(formData);
      let query = queryString.stringify(formData);
      update(query);
    }
  }, [formData]);

  return (
    <Fragment>
      <div>
        <form
          onChange={() =>
            setTimeout(handleSubmit((params) => setFormData(params)))
          }
        >
          <Row>
            <Col md={3}>
              <Field name="name" placeholder="Name" component={TextInput} />
            </Col>
            <Col md={3}>
              <Field
                name="phone"
                placeholder="Phone Number"
                component={TextInput}
              />
            </Col>
            <Col md={3}>
              <Field
                name="email"
                placeholder="Email Address"
                component={TextInput}
              />
            </Col>
            <Col md={3}>
              <Field
                name="company_name"
                placeholder="Company Name"
                component={TextInput}
              />
            </Col>
          </Row>
        </form>

        <div className="dynamic-table pt-2">
          {items ? (
            <hello />
          ) : (
            <div>
              <Skeleton animation="wave" variant="text" />
              <Skeleton animation="wave" variant="text" />
              <Skeleton animation="wave" variant="rect" width="100%" />
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default reduxForm({ form: "add-micro-site" })(ListingSearchPopover);
