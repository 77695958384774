import React, { useState, useRef, useEffect } from "react";
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import Modal from "react-modal";
import ThemeButton from "../../../../../partials/button/ThemeButton";
import { imgPreview } from "./imgPreview";
import { useDebounceEffect } from "./useDebounceEffect";

// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 100,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

const ImageCropper = ({
  selectedImage,
  setIsModalOpen,
  isModalOpen,
  selectedNode,
}) => {
  const [crop, setCrop] = useState({
    unit: "px",
    aspect: 1,
    width: 600,
    height: 600,
    x: 50,
    y: 50,
  });
  const [loadedImage, setLoadedImage] = useState(null);

  const [imgSrc, setImgSrc] = useState("");
  const [imgPreviewUrl, setPreview] = useState(null);
  const [aspect, setAspect] = useState(1);
  const [completedCrop, setCompletedCrop] = useState();
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const imgRef = useRef(null);

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  const loadImage = (url) => {
    const image = new Image();
    image.src = url;
    // image.crossOrigin = "anonymous";
    image.onload = () => {
      setLoadedImage(image);
    };
  };

  useEffect(() => {
    if (selectedImage) {
      loadImage(selectedImage);
    }
  }, [selectedImage]);

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current
        // previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        let preImg;
        preImg = await imgPreview(
          imgRef.current,
          // previewCanvasRef.current,
          completedCrop,
          scale
          // rotate,
        );
        setPreview(preImg);
      }
    },
    100,
    [completedCrop, scale, rotate]
  );
  const customStyles = {
    content: {
      top: "55%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "800px",
      height: "600px",
    },
  };

  const addCropImg = async () => {
    if (imgPreviewUrl) {
      selectedNode.style.background = `url('${imgPreviewUrl ?? ""}')`;
      selectedNode.style.backgroundSize = "contain";
      selectedNode.style.backgroundRepeat = "no-repeat";
      //   selectedNode.style.width = "600px";
      //   selectedNode.style.height = "600px";
      //   selectedNode.style.backgroundPosition = "center";
      setIsModalOpen(false);
    }
  };

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        style={customStyles}
      >
        {selectedImage && (
          <>
            <ReactCrop
              //   onImageLoaded={onImageLoaded}
              crop={crop}
              onChange={(c) => setCrop(c)}
              onComplete={(c) => setCompletedCrop(c)}
              aspect={4 / 3}
              //   crossOrigin="anonymous"
            >
              <img
                ref={imgRef}
                alt="Crop me"
                src={`https://wiseberry.com.au/load-listing-rental-image/${btoa(
                  selectedImage
                )}`}
                onLoad={onImageLoad}
                crossOrigin="*"
                style={{
                  //   height: 600,
                  margin: "0",
                  //   width: 600,
                  objectFit: "cover",
                }}
              />
            </ReactCrop>
            {/* {croppedImageUrl && (
              <img
                alt="Cropped"
                style={{ maxWidth: "20%" }}
                src={croppedImageUrl}
              />
            )} */}
          </>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            alignItem: "center",
            marginTop: 20,
          }}
        >
          <ThemeButton
            onClick={() => setIsModalOpen(false)}
            className=" darkGrey-button text-white font-weight-bold "
            id="update"
          >
            Cancel
          </ThemeButton>
          <ThemeButton
            onClick={() => addCropImg()}
            className="green-button text-white font-weight-bold"
          >
            OK
          </ThemeButton>
        </div>
      </Modal>
    </>
  );
};

export default ImageCropper;
