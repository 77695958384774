import React, { useState, createContext, useEffect } from "react";
// import { getAllDiaryAppointmentList } from "../../../diary/_curd/diaryCurd";
import { getAllContacts, getContactById } from "../../crud/contactsCrud";

export const ContactProfileContext = createContext();
export const { Consumer } = ContactProfileContext;

const ContactProfileContextProvider = (props) => {
  const { contactList } = props;
  const [continuousMode, setContinuousMode] = useState(false);
  const [contactLoading, setContactLoading] = useState(false);
  const [profile, setProfile] = useState(null);
  const [contactId, setContactId] = useState(null);
  const [contactIndex, setContactIndex] = useState(null);

  useEffect(() => {
    update();
  }, []);

  useEffect(() => {
    if (props.continuousMode) {
      setContinuousMode(props.continuousMode);
    }
  }, [props.continuousMode]);

  useEffect(() => {
    if (profile && contactList) {
      let index = contactList.findIndex((item) => item.id === profile.id);
      setContactIndex(index);
      setContactLoading(false);
    }
  }, [profile]);

  useEffect(() => {
    if (props.contactId) {
      setContactId(props.contactId);
    }
  }, [props.contactId]);

  useEffect(() => {
    if (contactId) {
      update();
    }
  }, [contactId]);

  const update = () => {
    if (contactId) {
      getContactById(contactId)
        .then(({ data: { data } }) => {
          setProfile(data);
        })
        .catch(() => {});
    }
  };

  const changeDirection = (direction) => {
    setContactLoading(true);
    let index = contactList.findIndex((item) => item.id === profile.id);
    // yield delay(2000);
    let newIndex;
    if (direction === "left") {
      newIndex = index - 1;
    }
    if (direction === "right") {
      newIndex = index + 1;
    }
    let newId = contactList[newIndex].id;
    setContactId(newId);
  };
  return (
    <ContactProfileContext.Provider
      value={{
        contactList,
        continuousMode,
        setContinuousMode,
        profile,
        setProfile,
        contactId,
        setContactId,
        contactIndex,
        setContactIndex,
        changeDirection,
        update,
        updateContext: update,
        setContactLoading,
        contactLoading,
      }}
    >
      {props.children}
    </ContactProfileContext.Provider>
  );
};

export default ContactProfileContextProvider;
