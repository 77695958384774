import React, { useEffect, useState, useContext } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { getMenu } from "../../../../GeneralApi/GeneralApi";
import ThemeButton from "../../../button/ThemeButton";
import Dictaphone from "../../../dictaphone/Dictaphone";
import { ProspectProfileContext } from "../../../../modules/prospect/modals/prospectProfileModal/ProspectProfileContextProvider";

import NoteRemoteFormButton from "../../../../modules/prospect/partials/NoteRemoteFormButton";

const NoteForm = (props) => {
  const {
    fields,
    placeholder,
    displayPlus,
    meta,
    defaultValue,
    setPageCount,
    hideType,
    rows,
    initialNote,
    selectedHeaderTab,
    profileId,
  } = props;

  const [selectedTags, setSelectedTags] = useState(null);
  const [text, setText] = useState(initialNote ?? "");
  const [options, setOptions] = useState(null);
  const {
    prospectProfile,
    prospects,
    prospectIndex,
    continuousMode,
    setContinuousMode,
    changeDirection,
    prospectId,
    prospectLoading,
    setProspectLoading,
    prospectNotContacted,
    setPc,
    setDk,
  } = useContext(ProspectProfileContext);
  const handleVoiceToSpeech = (text) => {
    if (text) {
      var voiceToText =
        document.getElementById(meta.form + "-note").value + text;
      document.getElementById(meta.form + "-note").value = voiceToText;
      handleOnChange();
    }
    // setGlobalFilter(text);
  };

  useEffect(() => {
    let query = "note_type";
    getMenu(query)
      .then(({ data: { data } }) => {
        setOptions(data.note_type);
      })
      .catch(() => {});
    document.getElementById(meta.form + "-note").value = "";
    setSelectedTags(defaultValue ? defaultValue : { id: 2, name: "general" });
  }, []);

  const handleDoorKnock = (e) => {
    if (continuousMode && prospects && prospects?.length != prospectIndex) {
      handleClickedRight("right");
    }
  };

  const handleClickedRight = () => {
    changeDirection("right");
    setPageCount((count) => count + 1);
  };

  const handleAddTags = (e) => {
    var value = e.target.value;
    setSelectedTags(options.filter((item) => item.id == value)[0]);

    fields.removeAll();
    let obj = {};
    obj.detail = document.getElementById(meta.form + "-note").value;
    obj.type = e.target.value;
    fields.push(obj);
  };

  const handleClearNote = () => {
    handleOnChange();
    document.getElementById(meta.form + "-note").value = "";
    localStorage.removeItem("note");
    setTimeout(() => {
      setText("");
    }, 500); // Emptying the text will disable the save button -> handleSubmit will not trigger.
  };

  let obj = {};
  const handleOnChange = () => {
    fields.removeAll();
    localStorage.setItem(
      "note",
      document.getElementById(meta.form + "-note").value
    );
    obj.detail = document.getElementById(meta.form + "-note").value;
    if (note) {
      obj.detail = document.getElementById(meta.form + "-note").value;
    }
    obj.type = selectedTags?.id;

    fields.push(obj);
    setText(obj.detail);
    // if(text){
    // localStorage.setItem('note', text)
    // }
  };

  // let obj ={}
  // let note = localStorage.getItem("note");
  let note = initialNote ?? "";
  // let prosID = localStorage.getItem('prospectId')
  useEffect(() => {
    // localStorage.setItem('prospectId', profileId )
    obj.detail = document.getElementById(meta.form + "-note").value = `${
      note === null ? "" : note
    }`;
    // fields.push(obj);
    setText(obj.detail);
  }, []);

  const TagPopover = (
    <Popover id="popover-contained">
      <Popover.Content>
        {options?.map((item, index) => (
          <React.Fragment key={index}>
            <div className="form-check">
              <input
                className={`form-check-input`}
                type="radio"
                value={item.id}
                checked={selectedTags.id === item.id ? true : false}
                onClick={(e) => handleAddTags(e)}
                name="textTags"
              />
              <label className="form-check-label">{item.name}</label>
            </div>
            <hr />
          </React.Fragment>
        ))}
      </Popover.Content>
    </Popover>
  );

  return (
    <div className="form-group d-flex note-form">
      {hideType ? (
        ""
      ) : (
        <OverlayTrigger
          rootClose
          trigger="click"
          placement="auto-start"
          overlay={TagPopover}
        >
          <div
            className={`note-form-textarea position-absolute note-item-${selectedTags?.name?.toLowerCase()}`}
          ></div>
        </OverlayTrigger>
      )}

      <textarea
        onChange={handleOnChange}
        className={`form-control form-control-lg form-control-solid ${meta.form}-details`}
        // value={text}
        id={meta.form + "-note"}
        rows={rows}
        placeholder={placeholder ? placeholder : "Enter a note"}
        minLength="1"
      ></textarea>
     
      <div className='d-flex flex-column'>
      <Dictaphone handleVoiceToSpeech={handleVoiceToSpeech} />
            { prospectLoading || selectedHeaderTab !== 0 ? 
            <ThemeButton
          disabled={!text}
          onClick={() => handleClearNote()}
          type="submit"
          className={"add-note submit-icon-button "}
          icon
        >
          <i className="fas fa-plus-circle"></i>
        </ThemeButton> : <>
            <NoteRemoteFormButton
              className={
                prospectLoading || selectedHeaderTab !== 0
                  ? "disable-prospect-header-btn"
                  : ""
              }
              disabled={prospectLoading || selectedHeaderTab !== 0}
              // <NoteRemoteFormButton
              setNAButtonLoading={setProspectLoading}
              setPc={setPc}
              label={"PC"}
              handleDoorKnock={handleDoorKnock}
              formName="prospect-notes"
            />
            <NoteRemoteFormButton
              className={
                prospectLoading || selectedHeaderTab !== 0
                  ? "disable-prospect-header-btn"
                  : ""
              }
              disabled={prospectLoading || selectedHeaderTab !== 0}
              // <NoteRemoteFormButton
              setDk={setDk}
              label={"DK"}
              setNAButtonLoading={setProspectLoading}
              handleDoorKnock={handleDoorKnock}
              formName="prospect-notes"
            />
            <NoteRemoteFormButton
              className={
                prospectLoading || selectedHeaderTab !== 0
                  ? "disable-prospect-header-btn"
                  : ""
              }
              disabled={prospectLoading || selectedHeaderTab !== 0}
              setNAButtonLoading={setProspectLoading}
              label={"NA"}
              handleDoorKnock={handleDoorKnock}
              formName="prospect-notes"
            />
            {displayPlus ? (
          <ThemeButton
            disabled={!text}
            onClick={() => handleClearNote()}
            type="submit"
            className={"add-note submit-icon-button "}
            icon
          >
            <i className="fas fa-plus-circle"></i>
          </ThemeButton>
      ) : null}
            </>}
      </div>
    </div>
  );
};

NoteForm.defaultProps = {
  displayPlus: true,
  hideType: false,
  rows: 4,
};
export default NoteForm;
