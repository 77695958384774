import React, { Fragment, useEffect, useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { getMenu } from "../../../../GeneralApi/GeneralApi";
import MobileNoteRemoteForm from "./MobileNoteRemoteForm";
import ThemeButton from "../../../../partials/button/ThemeButton";
import Dictaphone from "../../../../partials/dictaphone/Dictaphone";

const NoteForm = (props) => {
const { fields, placeholder, displayPlus, meta, defaultValue, hideType, rows,initialNote,profileId ,setIsDisabled= ()=> {}, isDisabled, classname, classNameButton, prospectLoading,
setProspectLoading, isLoading} = props;
const [selectedTags, setSelectedTags] = useState(null);
const [text, setText] = useState(initialNote ?? '');
const [options, setOptions] = useState(null);
const handleVoiceToSpeech = (text) => {
if (text) {
var voiceToText =
document.getElementById(meta.form + "-note").value + text;
document.getElementById(meta.form + "-note").value = voiceToText;
handleOnChange();
}

// setGlobalFilter(text);
};


console.log('========texttexttexttext============================');
console.log(initialNote);
console.log(text);
console.log(typeof(text));
console.log('=========texttexttexttext===========================');

useEffect(() => {

let query = "note_type";
getMenu(query)
.then(({ data: { data } }) => {
console.log(data);
setOptions(data.note_type);
})
.catch(() => { });
document.getElementById(meta.form + "-note").value = "";
setSelectedTags(defaultValue ? defaultValue : { id: 2, name: "general" });
}, []);
// useEffect(() => {
// if(text == ""){
//   setIsDisabled( text)
// }

// }, [text]);

console.log('=========ypeof(text)ypeof(text)===========================');
console.log(typeof(text));
console.log('==========ypeof(text)==========================');



const handleAddTags = (e) => {
var value = e.target.value;
setSelectedTags(options.filter((item) => item.id == value)[0]);

fields.removeAll();
let obj = {};
obj.detail = document.getElementById(meta.form + "-note").value;
console.log(document.getElementById(meta.form + "-note").value);
obj.type = e.target.value;
fields.push(obj);
};

const handleClearNote = () => {
handleOnChange()
document.getElementById(meta.form + "-note").value = "";
localStorage.removeItem("note")
setTimeout(() => {
setText('')
setIsDisabled( null)
}, 500); // Emptying the text will disable the save button -> handleSubmit will not trigger.
};

let obj = {};
const handleOnChange = () => {
fields.removeAll();
localStorage.setItem('note', document.getElementById(meta.form + "-note").value)
obj.detail = document.getElementById(meta.form + "-note").value;
if(note){
obj.detail = document.getElementById(meta.form + "-note").value;
}
obj.type = selectedTags?.id;
setIsDisabled( document.getElementById(meta.form + "-note").value)

fields.push(obj);
setText(obj.detail);

// if(text){
// localStorage.setItem('note', text)
// }
};


// let obj ={}
let note= localStorage.getItem('note')
// let prosID = localStorage.getItem('prospectId')
useEffect(() => {
  // localStorage.setItem('prospectId', profileId )
obj.detail = document.getElementById(meta.form + "-note").value=`${note === null  ? "" : note}`;
// fields.push(obj);
setText(obj.detail);
}, [])



console.log(meta);
const TagPopover = (
<Popover id="popover-contained">
<Popover.Content>
{options?.map((item, index) => (
<Fragment key={index}>
<div className="form-check">
<input
className={`form-check-input`}
type="radio"
value={item.id}
checked={selectedTags.id === item.id ? true : false}
onClick={(e) => handleAddTags(e)}
name="textTags"
/>
<label className="form-check-label">{item.name}</label>
</div>
<hr />
</Fragment>
))}
</Popover.Content>
</Popover>
);

return (
<Fragment>
<div className={`form-group d-flex note-form ${classname}`}>

{hideType ? '' : <OverlayTrigger
rootClose
trigger="click"
placement="auto-start"
overlay={TagPopover}
>
<div
className={`position-absolute`}
></div>
</OverlayTrigger>}


<textarea
onChange={handleOnChange}
className={`form-control form-control-lg form-control-solid ${meta.form}-details`}
// value={text}
id={meta.form + "-note"}
rows={rows}
placeholder={placeholder ? placeholder : "Enter a note"}
minLength="1"
style={{fontSize: 20, paddingRight: "2.5rem"}}
></textarea>
{/* <Dictaphone handleVoiceToSpeech={handleVoiceToSpeech} /> */}
{displayPlus ? (
  <div className="container-buttons-notes">
  <MobileNoteRemoteForm
    className={
      prospectLoading || isLoading
        ? "disable-prospect-header-btn buttons-notes-mobile left"
        : "buttons-notes-mobile left"
    }
    disabled={prospectLoading || isLoading}
    setNAButtonLoading={setProspectLoading}
    label={"NA"}
    formName="mobile-notes"
    setIsDisabled={setIsDisabled}
  />
  <MobileNoteRemoteForm
    className={
      prospectLoading || isLoading
        ? "disable-prospect-header-btn buttons-notes-mobile"
        : "buttons-notes-mobile"
    }
    disabled={prospectLoading || isLoading || !isDisabled}
    // <NoteRemoteFormButton
    setNAButtonLoading={setProspectLoading}
    label={"DK"}
    formName="mobile-notes"
    setIsDisabled={setIsDisabled}
  />

<ThemeButton
  disabled={ !isDisabled}
  onClick={() => handleClearNote()}
  type="submit"
  className={`newPlusBtn add-note  plus ${classNameButton}`}
  mobile
  small
>
Add Notes
</ThemeButton>
</div>
) : (
""
)}
</div>
</Fragment>
);
};

NoteForm.defaultProps = {
displayPlus: true,
hideType: false,
rows:4,
};
export default NoteForm;
