import React, { Fragment, useState } from "react";

import { connect } from "react-redux";
import { reduxForm, FieldArray, formValueSelector } from "redux-form";
import RenderContact from "../../../../../partials/form/sortableArrayFields/RenderContact";
import ThemeButton from "../../../../../partials/button/ThemeButton";
import * as prospectsRedux from "../../../_redux/prospectsRedux";

import DefaultModal from "../../../../../partials/modal/DefaultModal";
import NewContact from "../../../../contacts/modals/contactFormModal/NewContact";
import { LinearProgress } from "@material-ui/core";
import AccessControl from "../../../../../_ accessControl/AccessControl";

const ProspectContacts = (props) => {
  const {
    handleSubmit,
    change,
    submitting,
    profileId,
    pristine,
    invalid,
    // profile,
    setNewContactId,
    onFormSubmit,
    setAddNewContact,
    saveLoading,
    contactsFieldsValues,
    updateContext,
    profile,
  } = props;
  const [showAddNewContact, setShowAddNewContact] = useState(false);
  const [isSubmit, setSubmit] = useState(Date().now);

  const [from, setFrom] = useState(false);

  return (
    <Fragment>
      {showAddNewContact && (
        <DefaultModal
          size="xl"
          centered
          show={showAddNewContact}
          onHide={() => setShowAddNewContact(false)}
        >
          <NewContact
            closeModal={() => setShowAddNewContact(false)}
            setNewContactId={setNewContactId}
            onFormSubmit={onFormSubmit}
            submitFormName="prospect-contacts"
            OutsideContact={true}
            autoProspectNote={true}
            prospectId={profileId}
            updateContext={updateContext}
            prosprofile={profile}
            from={from}
            setFrom={setFrom}
          />
        </DefaultModal>
      )}
      <form className="contactForm" onSubmit={handleSubmit}>
        <div className="d-flex justify-content-end pb-3">
          <AccessControl
            allowedPermissions={["prospects-edit"]}
            renderNoAccess={() => ""}
          >
            {" "}
            <div className="pr-2">
              <ThemeButton
                // disabled={invalid || submitting || pristine}
                // type="submit"
                className="darkGrey-button pr-3"
                onClick={() => {
                  setShowAddNewContact(true);
                  setAddNewContact(true);
                }}
              >
                Add Contacts
              </ThemeButton>
            </div>
          </AccessControl>
          <AccessControl
            allowedPermissions={["prospects-edit"]}
            renderNoAccess={() => ""}
          >
            <ThemeButton
              disabled={invalid || pristine || submitting}
              type="submit"
              className="green-button"
              onClick={() => setSubmit(Date.now())}
            >
              Save
            </ThemeButton>
          </AccessControl>
        </div>
        {saveLoading ? <LinearProgress /> : null}
        <div
          style={{
            maxHeight: "80vh",
            overflow: "auto",
          }}
        >
          <FieldArray
            profileId={profileId}
            name="contacts"
            component={RenderContact}
            allowedPermission="prospects-edit"
            change={change}
            isSubmit={isSubmit}
            formFields={contactsFieldsValues}
            setFrom={setFrom}
          />
        </div>
      </form>
    </Fragment>
  );
};

const selector = formValueSelector("prospect-contacts");

const mapState = (state, props) => {
  return {
    contactsFieldsValues: selector(state, `contacts`),
    initialValues: {
      contacts: props.data,
    },
  };
};

export default connect(
  mapState,
  prospectsRedux.actions
)(
  reduxForm({
    form: "prospect-contacts",
    enableReinitialize: true,
  })(ProspectContacts)
);
