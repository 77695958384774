import React from 'react';
import { connect } from 'react-redux';
import {submit } from 'redux-form';
import ThemeButton from '../../button/ThemeButton';



const NoteRemoteFormButton = ({ dispatch ,label,handleDoorKnock,formName}) => {

    const handleOnclick =() => {
        dispatch(submit(formName));
        handleDoorKnock(label);

    }
    return (
    <ThemeButton
    className="door-knock-button "
    onClick={() => handleOnclick() }
  >
    {label}
  </ThemeButton>)
};

export default connect()(NoteRemoteFormButton);