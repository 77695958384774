import React, { Fragment, useEffect, useState, useContext } from "react";
import { reduxForm } from "redux-form";
import { Row, Col } from "react-bootstrap";
import _isEmpty from 'lodash/isEmpty';
import { LinearProgress, Tooltip } from "@material-ui/core";
import { toast } from "react-toastify";
import { Spin } from "antd";
import Swal from "sweetalert2";

import MaterialSelect from "../../../../../partials/form-basic/select/MaterialSelect";
import DynamicTableEdit from "../../../../../partials/table/DynamicTableEdit";
import { formToQueryString, getPercentage } from "../../../../../_helpers/_utils/utils";
import {
  getOrganisationSuburbs,
  getStreetsBySuburb,
} from "../../../curd/prospectsCrud";
import { AddZoneAnalysisContext } from "./AddZoneAnalysisContextProvider";
import AutocompleteForStreet from "../../../../../partials/form/AutocompleteForStreet";
import ThemeHeader from "../../../../../partials/content/ThemeHeader";
import AccessControl from "../../../../../_ accessControl/AccessControl";
import ThemeButton from "../../../../../partials/button/ThemeButton";
import useAccessControl from "../../../../../_helpers/_hooks/accessControl";

export const AddStreetsTable = (props) => {
  const { formValue, fields, change, initialValues, disableStreetsuburb, setSelectedTempStreets } = props;
  const hasEditPermission = useAccessControl(["zone_analysis_sales-edit"]);
  const { ZONE_TYPE, addStreetLoading, fetchMoreStreets, addNewStreet, streetZoneId, zoneStreets, deleteZoneAnalysisStreets, setPrevStreetData } = useContext(
    AddZoneAnalysisContext
  );
  const [selectedStreets, setSelectedStreets] = useState(null);
  const [streets, setStreets] = useState([]);
  const [suburb, setSuburb] = useState("");
  const [suburbOptions, setSuburbOptions] = useState([]);
  const [streetOptions, setStreetOptions] = useState([]);
  const [otherStreetData, setOtherStreetData] = useState([]);
  // const [clearAfterSubmit, setClearAfterSubmit] = useState(false);
  // const [data, setData] = useState([]);
  const [allStreets, setAllStreets] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [accessCondition, setAccessCondition] = useState([]);
  useEffect(() => {
    if (streets.length) {
      setSelectedTempStreets({ streets: streets, suburb: suburb })
    } else {
      setSelectedTempStreets([])
    }
  }, [streets])
  useEffect(() => {
    let obj = {
      limit: 300,
    }

    let query = formToQueryString(obj);
    getOrganisationSuburbs(query)
      .then(({ data: { data } }) => {
        let _data = data.sort((a, b) => a.name.localeCompare(b.name))
        setSuburbOptions(_data);
      })
      .catch(() => { });
  }, []);

  useEffect(() => {
    if (_isEmpty(initialValues)) {
      setAccessCondition(ZONE_TYPE === 1 ? ["zone_analysis_sales-add"] : ["zone_analysis_rentals-add"])
    } else {
      setAccessCondition(ZONE_TYPE === 1 ? ["zone_analysis_sales-edit"] : ["zone_analysis_rentals-edit"])
    }
  }, [ZONE_TYPE, initialValues])

  useEffect(() => {
    setAllStreets([]);
    setStreets([]);
    getSuburbDetails();
  }, [suburb]);
  const getSuburbDetails = (params = {}) => {
    if (suburb) {
      setIsLoading(true);
      let obj = {
        limit: 1000,
        suburb: suburb,
        ...params
      };
      if (obj.loadMore) {
        delete (obj.loadMore)
      }
      if (obj.type || !obj?.type?.length) {
        delete (obj.type)
      }
      let queryString = formToQueryString(obj);
      getStreetsBySuburb(queryString)
        .then((res) => {
          const { data, ...rest } = res.data;
          if (params.loadMore) {
            setStreetOptions([
              ...streetOptions.concat(data)
            ]);
            setAllStreets(allStreets.concat(data));
          } else {
            setStreetOptions([
              { id: "all", name: "Add All" },
              { id: "remove_all", name: "Remove All" },
              ...data,
            ]);
            setAllStreets(data);
          }
          if (params.type === 'load') {
            change('streets', fields?.getAll()?.concat(data))
          }

          setOtherStreetData(rest);
        })
        .catch(() => { }).finally(() => {
          setIsLoading(false);
        });
    }
  }

  const handleAddStreets = async () => {
    let formAddData = { ...formValue }
    if (formValue.contacts.includes('all')) {
      formAddData.contacts = formValue.contacts.filter((val, index) => val !== 'all')/* to remove the all in payload */
    }
    if (streets?.find((street) => street.id == "all")) {
      Swal.fire({
        title: "Add all streets under selected suburb?",
        text: "This will add all streets under selected suburb.",
        icon: "warning",
        showCancelButton: true,
        showLoaderOnConfirm: true,
        reverseButtons: true,
        cancelButtonColor: "#A7A9AC",
        confirmButtonColor: "#2BB673",
        cancelButtonText: "Cancel",
        confirmButtonText: "Yes",
        preConfirm: () => {
          let processesdData = {}
          streets.map((street) => {
            processesdData = {
              streets: [{
                suburb: suburb,
                add_streets: [],
                remove_streets: []
              }],
              contacts: formAddData.contacts, name: formValue.name, type: 1,
              zone: formValue.id
            }
            setStreets([]);
          });
          return addNewStreet(processesdData)
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          Swal.fire('Added Selected Streets!', '', 'success')
        } else if (result.isDenied) {
          Swal.fire('Changes are not saved', '', 'info')
        }
      });
    } else if (streets?.find((street) => street.id == "remove_all")) {
      Swal.fire({
        title: "Remove all streets under selected suburb?",
        text: "This will remove all streets under selected suburb.",
        icon: "warning",
        showCancelButton: true,
        showLoaderOnConfirm: true,
        reverseButtons: true,
        cancelButtonColor: "#A7A9AC",
        confirmButtonColor: "#2BB673",
        cancelButtonText: "Cancel",
        confirmButtonText: "Yes",
        preConfirm: () => {
          let processesdData = {
            streets: [{
              suburb: suburb,
              add_streets: [],
              remove_streets: ['all']
            }],
            contacts: formAddData.contacts, name: formValue.name, type: 1,
            zone: formValue.id
          }
          setStreets([]);
          setPrevStreetData([]);
          return addNewStreet(processesdData)
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          Swal.fire('Removed Selected Streets !', '', 'success')
        } else if (result.isDenied) {
          Swal.fire('Changes are not saved', '', 'info')
        }
      });
    } else {
      if (!_isEmpty(streets)) {

    Swal.fire({
      title: "Add a Selected streets under selected suburb?",
      text: "This will Add Selected streets under selected suburb.",
      icon: "warning",
      showCancelButton: true,
      showLoaderOnConfirm: true,
      reverseButtons: true,
      cancelButtonColor: "#A7A9AC",
      confirmButtonColor: "#2BB673",
      cancelButtonText: "Cancel",
      confirmButtonText: "Yes",
      preConfirm: () => {
        let processesdData = {
          streets: [{
            suburb: suburb,
            add_streets: streets.map((val) => val.id),
            remove_streets: []
          }],
          contacts: formAddData.contacts,
          name: formValue?.name,
          type: 1,
          zone: formValue?.id
        }
            setStreets([]);
            return addNewStreet(processesdData)
          },
        }).then(async (result) => {
          if (result.isConfirmed) {
            Swal.fire('Added Selected Streets !', '', 'success')
          } else if (result.isDenied) {
            Swal.fire('Changes are not saved', '', 'info')
          }
        });

      } else {
        toast.error('Please select Suburb and Street')
      }
    }
  };

  const handleDeleteStreets = () => {

    Swal.fire({
      title: "Remove the streets under selected suburb?",
      text: "This will remove selected streets under selected suburb.",
      icon: "warning",
      showCancelButton: true,
      showLoaderOnConfirm: true,
      reverseButtons: true,
      cancelButtonColor: "#A7A9AC",
      confirmButtonColor: "#2BB673",
      cancelButtonText: "Cancel",
      confirmButtonText: "Yes",
      preConfirm: () => {
        let result = selectedStreets?.map((a) => a.id);
        let zone_ID = formValue?.id ? formValue?.id : streetZoneId
        let processesdData = {
          zone: zone_ID,
          remove_streets: result
        }
        return deleteZoneAnalysisStreets(processesdData);
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire('Deleted Selected Streets !', '', 'success')
      } else if (result.isDenied) {
        Swal.fire('Changes are not saved', '', 'info')
      }
    });
  };

  const ToolTipComponent = React.forwardRef(function ToolTipComponent(
    props,
    ref
  ) {
    //  Spread the props to the underlying DOM element.
    return (
      <p {...props} ref={ref} className="min-content-width">
        {props.street}
      </p>
    );
  });
  const columns = React.useMemo(
    () => [
      {
        Header: "Streets",
        Footer: "Name",
        columns: [
          {
            Header: "Street",
            accessor: "name",
            Cell: ({
              cell: { value },
              cell: {
                row: { original },
              },
            }) => (
              <>
                {/* <p>{original?.name}</p> */}

                <Tooltip
                  title={<h6>{original?.suburb?.name}</h6>}
                  placement="right-start"
                  arrow
                >
                  {/* <Button style={{ cursor: "default" }}> */}
                  <ToolTipComponent street={original?.name} />
                  {/* <p className="text-left">{original?.name}</p> */}
                  {/* </Button> */}
                </Tooltip>
              </>
            ),
            Footer: (total) => {
              return (
                <Fragment>
                  <strong>Total:</strong> {total.data.length}
                </Fragment>
              );
            },
          },
          {
            Header: "Doors",
            accessor: "properties_count",
            Footer: (total) => {
              // Only calculate total visits if rows change
              const item = React.useMemo(
                () =>
                  total.rows.reduce(
                    (sum, row) => parseInt(row.values.properties_count) + sum,
                    0
                  ),
                [total.rows]
              );
              return <Fragment>{item}</Fragment>;
            },
            Cell: ({ cell: { value } }) => (
              // <p>{moment(original.date).format("hh : m")}</p>
              <p>{value}</p>
            ),
          },
          {
            Header: "Contacted",
            accessor: "contacted",
            Footer: (total) => {
              const doors = React.useMemo(
                () =>
                  total.rows.reduce(
                    (sum, row) => row.values.properties_count + sum,
                    0
                  ),
                [total.rows]
              );
              const item = React.useMemo(
                () =>
                  total.rows.reduce(
                    (sum, row) => row.values.contacted + sum,
                    0
                  ),
                [total.rows]
              );

              return (
                <Fragment>
                  {item}({getPercentage(item, doors)})%
                </Fragment>
              );
            },
            Cell: ({
              cell: { value },
              cell: {
                row: { original },
              },
            }) => (
              <p>
                {value} (
                {getPercentage(original?.contacted, original?.properties_count)}
                %)
              </p>
            ),
          },
        ],
      },
    ],
    []
  );
  const fetchStreetsData = () => {
    let Zone_Id = formValue.id ? formValue.id : streetZoneId
    if (Zone_Id) {
      fetchMoreStreets(Zone_Id);

    }
  }
  const fetchMoreAutoCompleteData = (val = '') => {
    let obj = { type: val }
    if (otherStreetData?.next_page_url) {
      obj.loadMore = true
      obj.page = otherStreetData.current_page + 1
      getSuburbDetails(obj)
    }
  }
  return (
    <Spin spinning={addStreetLoading} tip={'Loading...'}>
      <Fragment>
        <div className="d-flex flex-wrap justify-content-between mb-3">
          <h6 className="title pb-0">Streets</h6>
        </div>
        <AccessControl
          allowedPermissions={accessCondition}
          renderNoAccess={() => ""}
        >
          <ThemeHeader
            leftClass='w-100'
            rightClass='align-items-end'
            left={
              <Row className='w-100'>
                <Tooltip
                  title={<h6 style={{ color: 'white' }} className='mt-2'>Please fill zone name and team</h6>}
                  placement="bottom"
                  arrow
                  disableHoverListener={disableStreetsuburb}
                  disableFocusListener={true}
                >
                  <Col lg={6}>
                    {" "}
                    <MaterialSelect
                      label="Suburb"
                      value={suburb}
                      onChange={(e) => setSuburb(e)}
                      options={suburbOptions}
                      disabled={!disableStreetsuburb}
                    />
                  </Col>
                </Tooltip>
                <Tooltip
                  title={<h6 style={{ color: 'white' }} className='mt-2'>Please fill zone name and team</h6>}
                  placement="bottom"
                  arrow
                  disableHoverListener={disableStreetsuburb}
                  disableFocusListener={true}
                >
                  <Col lg={6}>
                    {" "}
                    {/* {streetOptions.length > 0 && ( */}
                    <AutocompleteForStreet
                      update={fetchMoreAutoCompleteData}
                      handleChange={(event, newValue) => {
                        setStreets(newValue);
                      }}
                      streetValue={streets}
                      options={streetOptions}
                      disabled={!suburb || isLoading || !disableStreetsuburb}
                      isLoading={isLoading}
                      size='small'
                    />
                    {isLoading ? <LinearProgress /> : ''}
                  </Col>
                </Tooltip>
              </Row>
            }
            right={
              <Fragment>
                <AccessControl
                  allowedPermissions={["zone_analysis_sales-add"]}
                  renderNoAccess={() => ""}
                >
                  <ThemeButton
                    onClick={handleAddStreets}
                    className="brand-icon-button"
                    icon
                  >
                    {" "}
                    <i className="fas fa-plus-circle"></i>
                  </ThemeButton>
                </AccessControl>

                <AccessControl
                  allowedPermissions={["zone_analysis_sales-delete"]}
                  renderNoAccess={() => ""}
                >
                  <ThemeButton
                    disabled={!selectedStreets?.length}
                    className={`darkGrey-icon-button ${selectedStreets?.length ? '' : 'pointer-events-none'}`}
                    onClick={handleDeleteStreets}
                    icon
                  >
                    {" "}
                    <i className="fas fa-minus-circle"></i>
                  </ThemeButton>{" "}
                </AccessControl>
              </Fragment>
            }
          />
        </AccessControl>
        <DynamicTableEdit
          // isEditable={hasEditPermission}
          isEditable={true}
          handleChange={(e) => setSelectedStreets(e)}
          data={zoneStreets ?? []}
          columns={columns}
          showFooter={true}
          height="63"
          update={fetchStreetsData}
        />
      </Fragment>
    </Spin>
  );
};

export default reduxForm({
  form: "addZoneStreetsForm",
})(AddStreetsTable);
