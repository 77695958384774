import React, { useState, Fragment } from "react";
import { connect } from "react-redux";
import * as modal from "../../../_modal/_redux/modalRedux";
import { reduxForm, Field } from "redux-form";
import TextInput from "../../../../partials/form/TextInput";
// import validate from "../../partials/form/validate";
import { Form, Modal, Button, Col, Row } from "react-bootstrap";
import * as diary from "../../_redux/diaryRedux";
import {
  combineValidators,
  composeValidators,
  isRequired,
  hasLengthGreaterThan,
} from "revalidate";
import DateInput from "../../../../partials/form/DateInput";
import cuid from "cuid";
import TextArea from "../../../../partials/form/TextArea";

const validate = combineValidators({
  title: isRequired({ message: "The task title is required" }),
  category: isRequired({ message: "The category is required" }),
  description: composeValidators(
    isRequired({ message: "Please enter a description" }),
    hasLengthGreaterThan(4)({
      message: "Description needs to be at least 5 characters",
    })
  )(),
  city: isRequired("city"),
  venue: isRequired("venue"),
  checkbox: isRequired("checkbox"),
  startTime: isRequired("date"),
  endTime: isRequired("date"),
});

const TaskModal = (props) => {
  const {
    edit,
    task_id,
    handleSubmit,
    start,
    end,
    pristine,
    submitting,
    invalid,
    closeModal,
  } = props;
  const [startDate, setStartDate] = useState(start);

  const [endDate, setEndDate] = useState(end);
  const onFormSubmit = async (values) => {

  };

  return (

    <Fragment>

        {edit && task_id ? "Edit" : "not edit"}
        <Form onSubmit={handleSubmit(onFormSubmit)} noValidate>
          <Form.Group as={Row}>
            <Col>
              <Field
                name="Task"
                type="text"
                component={TextArea}
                label="Task"
                rows="4"
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Col>
              <Field
                name="due_date"
                component={DateInput}
                type="datetime-local"
                label="Due Date"
              />
            </Col>

            <Col>
              <Field
                name="due_date"
                component={DateInput}
                type="datetime-local"
                label="Due Date"
              />
            </Col>

            <Col>
              <Field
                name="due_date"
                component={DateInput}
                type="datetime-local"
                label="Due Date"
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm={2}>
              End Time
            </Form.Label>
            <Col sm={10}>
              <Field
                selected={end}
                name="endTime"
                component={DateInput}
                timeIntervals={15}
                // dateFormat="dd LLL yyyy h:mm a"
                dateFormat="MMMM d, yyyy h:mm aa"
                placeholder="Task End Time"
                showTimeSelect
                timeFormat="HH:mm"
              />
            </Col>
          </Form.Group>

          {/* <Field
                            name='date'
                            type='text'
                            component={DateInput}
                            dateFormat='yyyy/LL/dd HH:mm'
                            timeFormat='HH:mm'
                            showTimeSelect
                            placeholder='Task date'
                        /> */}
          <Button type="submit" disabled={invalid || pristine || submitting}>
            Submit
          </Button>
          {/* <button type="submit" disabled={invalid ||pristine || submitting}>Submit</button> */}
        </Form>
        </Fragment>
  );
};

// const mapState = (state, ownProps) => {
//   let task = {};

//   if (typeof state.task != "undefined") {
//     task = state.task;
//   }

//   return {
//     start: state.modal.modalProps.start
//       ? state.modal.modalProps.start
//       : ownProps.task.start,
//     end: state.modal.modalProps.end
//       ? state.modal.modalProps.end
//       : ownProps.task.end,
//     popssss: state.task,
//     taskModalForm: state.form,
//     closeModal: state.closeModal,
//     initialValues: { ...ownProps.task },
//   }; // Foram name must have to be different
// };

export default connect(
  // mapState,
  // actions
)(
  reduxForm({ form: "task-modal", validate, enableReinitialize: true })(
    TaskModal
  )
);
