import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest, delay ,call} from "redux-saga/effects";
import { getNewsData } from "../curd/news";

export const actionTypes = {
    NewsLoading: "[NewsLoading]",
    NewsLoaded: "[NewsLoaded]",
    NewsError: "[NewsError]",
    SocialFeedPost: "[SocialFeedPost]",
    SocialFeedPostAsync: "[SocialFeedPostAsync]",
};

const initialAuthState = {
    data: [],
    loading: true,
    error: ''
};

export const reducer = persistReducer(
    { storage, key: "wol-auth", whitelist: ["news"] },
    (state = initialAuthState, action) => {
        switch (action.type) {

            case actionTypes.NewsLoading: {
                return {
                    ...state,
                    loading: true,
                    error:''
                };
            }
            case actionTypes.NewsLoaded: {
                const { data } = action.payload;
                return {
                    ...state,
                    data: data,
                    loading: false
                }
            }
            case actionTypes.SocialFeedLoad: {
                // const { data } = action.payload;
                // return {
                //     ...state,
                //     data: data,
                //     loading: false
                // }
                return state;
            }
            case actionTypes.NewsError: {
                return {
                    ...state,
                    loading: false,
                    error: action.error
                };
            }

            default:
                return state;
        }
    }
);

export const actions = {
    newsLoaded: data => ({ type: actionTypes.NewsLoaded, payload: { data } }),
    newsLoading: () => ({ type: actionTypes.NewsLoading}),
    socialFeedPostAsync: data => ({ type: actionTypes.SocialFeedPostAsync, payload: data }),
    newsError: error => ({ type: actionTypes.NewsError, payload: { error } })

};


export function* sagaNews() {
    yield takeLatest(actionTypes.NewsLoading, function* newsRequested() {
        console.log('called NewsR');
        const data = yield getNewsData();
        yield put(actions.newsLoaded(data));
        
    });

    yield takeLatest(actionTypes.SocialFeedPostAsync, function* socialFeedPostAsync({payload: {data}}) {
        console.log('called socialFeedPostAsync');
        console.log(data);
        yield delay(10000);

    });
}
