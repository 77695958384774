
import React, { } from 'react';

import InspectionSettings from './InspectionSettings';
import StartAndEndTime from './StartAndEndTime';


const OpenHomeInspectionSettings = ({ selectedNode }) => {
    return (<div>
        <StartAndEndTime selectedNode={selectedNode} />
        <InspectionSettings selectedNode={selectedNode} />
    </div>)
}
export default OpenHomeInspectionSettings