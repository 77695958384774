import React, { Fragment, useContext, useState } from "react";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import EmailIcon from "@material-ui/icons/Email";
import EventIcon from "@material-ui/icons/Event";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import { connect } from "react-redux";
import * as modals from "../../../../_modal/_redux/modalRedux";
import ThemeHeader from "../../../../../partials/content/ThemeHeader";
import ThemeButton from "../../../../../partials/button/ThemeButton";
import * as tenantsRedux from "../../../_redux/tenantsRedux";
import clsx from "clsx";
import SmallButton from "../../../../../partials/form/remoteSubmitButton/SmallButton";
import DefaultModal from "../../../../../partials/modal/DefaultModal";
import Sms from "../../../../../partials/component/sms/Sms";
import Email from "../../../../../partials/component/email/Email";
import { TenantProfileContext } from "../TenantProfileContextProvider";
import CallReminder from "../../../../../partials/component/callReminder/CallReminder";
import Appointment from "../../../../diary/partials/modals/appointment/Appointment";
import moment from "moment";
import NoteRemoteFormButton from "../../../../prospect/partials/NoteRemoteFormButton";
import SmsWrap from "../../../../../partials/component/sms/SmsWrap";
import ToolTipForSvg from "../../../../../partials/tooltip/ToolTipForSvg";
import _isEmpty from 'lodash/isEmpty';

const TenantProfileHeader = (props) => {
  const { closeModal, selectedTenantsId ,selectedHeaderTab, user} = props;
  const [showSms, setShowSms] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [showCallReminder, setShowCallReminder] = useState(false);
  const [showAppointment, setShowAppointment] = useState(false);
  const [fillFormData, setFillFormData] = useState({});

  const {
    profile,
    profileId,
    tenants,
    tenantSelectedChangeId,
    continuousMode,
    setContinuousMode,
    tenantLoading,
    setTenantLoading
  } = useContext(TenantProfileContext);

  const handleDoorKnock = (e) => {
    if (tenants.continuousMood) {
      // tenantSelectedChangeId("right");
    }
  };

  const handleClickedLeft = () => {
    tenantSelectedChangeId("left");
  };

  const handleClickedRight = () => {
    tenantSelectedChangeId("right");
  };

  const toggleContinuous = () => {
    setContinuousMode(!continuousMode);
  };
  return (
    <Fragment>
      {showSms ? (
        <DefaultModal
          size="xl"
          centered
          show={showSms}
          onHide={() => setShowSms(false)}
        >
          <SmsWrap
            closeModal={() => setShowSms(false)}
            data={[profile?.primary_contact]}
          />
        </DefaultModal>
      ) : (
        ""
      )}

      {showEmail ? (
        <DefaultModal
          size="xl"
          centered
          show={showEmail}
          onHide={() => setShowEmail(false)}
        >
          <Email
            closeModal={() => setShowEmail(false)}
            data={[profile?.primary_contact]}
          />
        </DefaultModal>
      ) : (
        ""
      )}
      {showAppointment ? (
        <DefaultModal
          size="xl"
          centered
          show={showAppointment}
          onHide={() => setShowAppointment(false)}
        >
          <Appointment
            setFillFormData={setFillFormData}
            fillFormData={fillFormData}
            initialValues={{
              starts: moment().format("YYYY-MM-DDTHH:mm"),
              ends: moment().format("YYYY-MM-DDTHH:mm"),
              contacts: [profile?.primary_contact],
              app_type: 10,
            }}
            closeModal={() => setShowAppointment(false)}
          />
        </DefaultModal>
      ) : (
        ""
      )}
      {showCallReminder ? (
        <DefaultModal
          size="xl"
          centered
          show={showCallReminder}
          onHide={() => setShowCallReminder(false)}
        >
          <CallReminder
            tenantId={profile.id}
            contactId={profile.primary_contact.id}
            closeModal={() => setShowCallReminder(false)}
            initialValues={{ for: user?.contact?.id }}
          />
        </DefaultModal>
      ) : (
        ""
      )}
      <ThemeHeader
        noScroll
        left={
          <Fragment>
            <NoteRemoteFormButton
              className={(!profile?.contacts?.length) || tenantLoading || selectedHeaderTab !== 0? 'disable-prospect-header-btn' : ''}
              disabled={(!profile?.contacts?.length) || tenantLoading || selectedHeaderTab !== 0}
              label={"TC"}
              setNAButtonLoading={setTenantLoading}
              handleDoorKnock={handleDoorKnock}
              formName="tenant-notes"
            />
            <NoteRemoteFormButton
              className={(!profile?.contacts?.length) || tenantLoading || selectedHeaderTab !== 0 ? 'disable-prospect-header-btn' : ''}
              disabled={(!profile?.contacts?.length) || tenantLoading || selectedHeaderTab !== 0}
              setNAButtonLoading={setTenantLoading}
              label={"NA"}
              handleDoorKnock={handleDoorKnock}
              formName="tenant-notes"
            />
          </Fragment>
        }
        className="pb-3"
        right={
          <Fragment>
            <ToolTipForSvg content="SMS">
              {/* <ThemeButton  icon> */}
              {/* <div className="p-2"> */}
              <PhoneAndroidIcon onClick={() => setShowSms(true)} />
              {/* </div> */}
              {/* </ThemeButton> */}
            </ToolTipForSvg>
            <ToolTipForSvg content="Email">
              {/* <ThemeButton onClick={() => setShowEmail(true)} icon> */}
              <EmailIcon onClick={() => setShowEmail(true)} />
              {/* </ThemeButton> */}
            </ToolTipForSvg>
            <ToolTipForSvg content="Appointment">
              {/* <ThemeButton  icon> */}
              <EventIcon onClick={() => setShowAppointment(true)} />
              {/* </ThemeButton> */}
            </ToolTipForSvg>
            <ToolTipForSvg content="Call Reminder">
              {/* <ThemeButton icon> */}
              <AccessTimeIcon onClick={() => setShowCallReminder(true)} />
              {/* </ThemeButton> */}
            </ToolTipForSvg>
            <div className="btn-toolbar nav-icon-button">
              <div className="btn-group">
                <ThemeButton disabled={tenantLoading || tenants[0].id === selectedTenantsId || _isEmpty(profile)} onClick={() => handleClickedLeft()} icon>
                  <i className={tenantLoading || tenants[0].id === selectedTenantsId ||
                    _isEmpty(profile) ? "disable-prospect-header-btn fas fa-caret-left" : "fas fa-caret-left"}></i>
                </ThemeButton>
                <ThemeButton
                  className={clsx({
                    "green-icon-button": continuousMode,
                  })}
                  onClick={() => toggleContinuous()}
                  icon
                >
                  <i className="fas fa-infinity    "></i>
                </ThemeButton>
                <ThemeButton disabled={tenantLoading || _isEmpty(profile)} onClick={() => handleClickedRight()} icon>
                  <i className={tenantLoading || _isEmpty(profile) ? "disable-prospect-header-btn fas fa-caret-right" : "fas fa-caret-right"}></i>

                </ThemeButton>
              </div>
            </div>
            <ThemeButton onClick={() => closeModal()}>Close</ThemeButton>
          </Fragment>
        }
      />
    </Fragment>
  );
};

const mapStateToProps = ({ auth }) => ({
  user: auth.user
});

export default connect(mapStateToProps, null)(TenantProfileHeader);
