
import { InputAdornment } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import React, { Fragment } from 'react';
import NumberFormatCustom from '../form-basic/textField/NumberFormatCustom';

const TextInputAmount = ({
  edit,
  label,
  name,
  width,
  type,
  placeholder,
  meta,
  input,
  InputProps,
  readOnly,
  defaultRef,
  className,
  fullWidth,
  onChange,
  hideZero,
  fontSize,
  error,
  helperText,
  setZeroForEmpty,
  allowNegative = true,
  isPercentage=false, 
  priceFrom,
  ...custom

}) => {



  const handleOnchange = (e) => {
    let value = e;
    if (e === "undefined") {
      value = 0;
    }


    if (typeof onChange !== "undefined") {
      onChange(value)
    }
    if (input) {
      input.onChange(value)
    }
  }

  return (
    <Fragment>
      {edit ?
        <Fragment>
          <TextField
            className={className}
            label={label}
            fullWidth={fullWidth}
            inputRef={defaultRef}
            placeholder={placeholder}
            error={  error ?? (meta?.invalid)}
            value={input?.value}
            {...custom}
            helperText={helperText ?? meta?.error}
            onChange={(e) => handleOnchange(e)}
            InputProps={{
              startAdornment: (
                isPercentage?<InputAdornment position="start">%</InputAdornment>:<InputAdornment position="start">$</InputAdornment>
              ),
              inputComponent: NumberFormatCustom,
              inputProps: {
                setZeroForEmpty: setZeroForEmpty,
                allowNegative: allowNegative
              }
            }}
          />
        </Fragment>

        : <h6 className="mb-0">{input.value}</h6>}

    </Fragment>



  )

};

TextInputAmount.defaultProps = {
  edit: true,
  disabled: false,
  fullWidth: true,
  hideZero: false
};
export default TextInputAmount;