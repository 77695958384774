import React from "react";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import "./toggleSwitch.scss";

const ToggleSwitch = ({
  input,
  label,
  name,
  defaultChecked,
  labelLeft,
  labelTrue,
  labelFalse,
  labelClass,
  toggleClass,
  closeToLeft,
  customOnChange,
  style,
  triggerChange = false,
}) => {
  const handleOnChange = (e) => {
    if (
      e.target.checked &&
      (typeof customOnChange === "undefined" || customOnChange(input))
    ) {
      input.onChange(1);
    } else {
      input.onChange(0);
    }
  };

  if (triggerChange) {
    input.onChange(defaultChecked);
  }

  return (
    <div className="d-flex toggle-switch mt-auto justi" style={style}>
      {label && labelLeft ? <h6 className={labelClass}>{label}</h6> : ""}
      <div>
        <Toggle
          name={name}
          className={toggleClass}
          defaultChecked={defaultChecked}
          checked={input?.value === 1 ? true : false}
          icons={{
            checked: labelTrue,
            unchecked: labelFalse,
          }}
          onChange={handleOnChange}
        />
      </div>
      {label && !labelLeft ? (
        <h6 className={"mt-1 mb-2" + !closeToLeft && " ml-2"}>
          {label}
        </h6>
      ) : (
          ""
        )}
    </div>
  );
};

ToggleSwitch.defaultProps = {
  label: false,
  labelLeft: false,
  labelTrue: "Yes",
  labelFalse: "No",
  labelClass: "m-auto",
  toggleClass: "",
  customOnChange: undefined,
};

export default ToggleSwitch;
