import React, { Fragment } from "react";
import DynamicTable from "../../../table/DynamicTable";

const FeaturesTable = (props) => {
  const { features, height } = props;

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        columns: [
          {
            Header: "Feature",
            accessor: "feature",
            Cell: ({
              cell: { value },
              cell: {
                row: { original },
              },
              cell: data,
            }) => <Fragment>{value?.name}</Fragment>,
          },
          {
            Header: "Details",
            accessor: "Details",
            Cell: ({
              cell: { value },
              cell: {
                row: { original },
              },
              cell: data,
            }) => (
              <Fragment>
                {![9, 20, 36].includes(original?.id)
                  ? original.qty
                  : original?.id === 20 || original?.id === 9
                  ? original?.feature_menu_input +
                    " /" +
                    original?.feature_menu_option?.name
                  : original?.feature_menu_option?.name}{" "}
              </Fragment>
            ),
          },

          {
            Header: "Notes",
            accessor: "comments",
            Cell: ({
              cell: { value },
              cell: {
                row: { original },
              },
              cell: data,
            }) => <Fragment>{value}</Fragment>,
          },
        ],
      },
    ],
    [features]
  );
  return <DynamicTable columns={columns} height={height} data={features} />;
};

FeaturesTable.defaultProps = {
  height: 60,
};

export default FeaturesTable;
