import React, { useEffect, useState, useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Field } from 'redux-form';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import _find from 'lodash/find';
import _debounce from 'lodash/debounce';
import moment from 'moment'

import TextInput from '../../../../partials/form/TextInput';
import ThemeButton from '../../../../partials/button/ThemeButton';
import { toast } from 'react-toastify';
import { formToQueryString } from '../../../../_helpers/_utils/utils';
import { FOR_SALE, SOLD, FOR_LEASE, LEASED, IMAGE_PLACEHOLDER_URL, OPEN_FOR_INSPECTION, RENTAL_OPEN_FOR_INSPECTION,AUCTIONS, TESTIMONIALS, AGENT_PROFILES, COMING_SOON } from './AccordianConstants';
import { LinearProgress, TextField } from '@material-ui/core';

const onDragStart = (event, template) => {
  event.dataTransfer.setData("drag-element", JSON.stringify(template));
  localStorage.setItem('current_dragging_template_layout_to_customise', event.target.className);
};

function DigitalTemplateContentCustomise({
  selectedTemplateLayout = {},
  seletctedDate,
  setSeletctedDate
}) {

  const [blocks, setBlocks] = useState([])
  const [otherResData, setOtherResData] = useState({})
  const [loading, setLoading] = useState(false)

  const getBlocks = useCallback(

    (query = {}) => {
      // debugger
      const { apiInfo: { endPoint, params } = {} } = selectedTemplateLayout
      let obj = {
        limit: 10,
        // state: 1,
        ...params,
        ...query
      }
      setLoading(true)
      if (typeof endPoint !== "undefined") {
        endPoint(formToQueryString(obj), true) // true for customized limit 32
          .then(({ data: { data, ...rest } }) => {
            if (query.loadMore) {
              setBlocks(prev => prev.concat(data));
            } else {
              setBlocks(data);
            }
            setOtherResData(rest)
          })
          .catch((e) => {
            toast.error('Error while fetching');
          })
          .finally(() => {
            setLoading(false)
          })
      }
    },
    [],
  )

  useEffect(() => {
    if (_isEmpty(blocks)) {
      if (selectedTemplateLayout.id === OPEN_FOR_INSPECTION && seletctedDate) {
        getBlocks({ from: seletctedDate, to: seletctedDate })
      } else if (selectedTemplateLayout.id === AUCTIONS && seletctedDate) {
        getBlocks({ auction_date_from: seletctedDate, auction_date_to: seletctedDate })
      }else if (selectedTemplateLayout.id === RENTAL_OPEN_FOR_INSPECTION && seletctedDate){
        getBlocks({ from: seletctedDate, to: seletctedDate })
      }else {
        getBlocks()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTemplateLayout, getBlocks, seletctedDate])

  const getImageURL = (assets = []) => {
    if (assets.length) {
      let imageObj = _find(assets, function (item) { return _get(item, 'type.id') === 1 })
      if (imageObj) {
        return imageObj.url
      } else {
        return IMAGE_PLACEHOLDER_URL
      }
    } else {
      return IMAGE_PLACEHOLDER_URL
    }
  }

  const getSectionContent = item => {
    switch (selectedTemplateLayout.id) {
      case FOR_SALE:
      case SOLD:
      case COMING_SOON:
      case FOR_LEASE:
      case LEASED:
        return { url: getImageURL(_get(item, 'assets')), hoverContent: _get(item, 'property.address.full_address') }
      case OPEN_FOR_INSPECTION:
        return { url: getImageURL(_get(item, 'listings[0].assets')), hoverContent: _get(item, 'listings[0].property.address.full_address', '') }
      case  RENTAL_OPEN_FOR_INSPECTION:
        return { url: getImageURL(_get(item, 'rentals[0].assets')), hoverContent: _get(item, 'rentals[0].property.address.full_address', '') }
      case AUCTIONS:
        return { url: getImageURL(_get(item, 'assets')), hoverContent: _get(item, 'property.address.full_address', '') }
      case TESTIMONIALS:
        return { url: IMAGE_PLACEHOLDER_URL, hoverContent: _get(item, 'heading', '') }
      case AGENT_PROFILES:
        return {
          url: item.contact.profile_picture ?? IMAGE_PLACEHOLDER_URL,
          hoverContent: _get(item, 'contact.first_name', '') + _get(item, 'contact.last_name', '')
        }
      default:
        return ''
    }
  }

  const handleLoadMore = () => {
    let obj = {
      page: otherResData?.current_page + 1,
      loadMore: true
    };
    getBlocks(obj)
  }

  // const handleSearch = () => {
  //   let obj = {
  //     // for_contact: 49,
  //     from: dateFilter,
  //     to: dateFilter
  //   };
  //   getBlocks(obj);
  // }

  const handleTemplateCustomiseSearch = (e) => {
    let obj = {
      search_kw: e.target.value
    };
    getBlocks(obj);
  };

  const templateCustomiseSearch = _debounce(handleTemplateCustomiseSearch, 500)

  const handleDateChange = e => {
    setSeletctedDate(e.target.value)
    let subHeadingNode = document.getElementById("poster-template")?.querySelector('.sub-heading-node')
    if (subHeadingNode) {
      let processedDay = moment(e.target.value).format('dddd')
      let processedmonth = moment(e.target.value).format('Do MMMM YYYY')
      subHeadingNode.getElementsByClassName("sub-heading-node-day")[0].innerText = processedDay.toUpperCase()
      subHeadingNode.getElementsByClassName("sub-heading-node-month")[0].innerText = processedmonth
    }
    let obj = {}
    if (selectedTemplateLayout.id === OPEN_FOR_INSPECTION || selectedTemplateLayout.id ===  RENTAL_OPEN_FOR_INSPECTION) {
      obj = {
        // for_contact: 49,
        from: e.target.value,
        to: e.target.value
      };
    } else if (selectedTemplateLayout.id === AUCTIONS) {
      obj = {
        auction_date_from: e.target.value,
        auction_date_to: e.target.value
      };
    }
    getBlocks(obj);
  }

  return (
    <div className='mt-1 poster-template-customize social-media-accordian'>
      {[OPEN_FOR_INSPECTION, AUCTIONS, RENTAL_OPEN_FOR_INSPECTION].includes(selectedTemplateLayout.id) ?
        <Row className='my-3 mx-1'>
          <Col>
            <TextField
              className='w-100'
              type="date"
              onChange={handleDateChange}
              value={seletctedDate}
            />
          </Col>
          {/* <Col lg={3}>
            < ThemeButton
              className="green-button mt-sm-2 mt-lg-0"
              onClick={handleSearch}
              disabled={!dateFilter}
            >
              Search
            </ThemeButton>
          </Col> */}
        </Row> :
        <Field
          name={`templates`}
          label={selectedTemplateLayout.searchPlaceHolder ?? "Search Properties"}
          component={TextInput}
          onChange={(e) => templateCustomiseSearch(e)}
          className='mb-4'
        />}
      <Row>
     {/* { _isEmpty(blocks) && <div> Sales Open Homes </div>} */}
        {_map(blocks, (item, index) => {
          let { url, hoverContent } = getSectionContent(item)

          let splitAddress = hoverContent.split('NSW')
      
          return (
            <Col className='my-2 mx-2 p-0' key={index} style={{ flexGrow: 0 }}>
              <div
                className='accordian-block' // className used for onDragOver identity purpose *
                draggable
                onDragStart={(event) => onDragStart(event, item)}
              >
                <img
                  className="img__img"
                  src={url}
                  alt='house1'
                />
                <div className="img__description_layer">
                  <p className="img__description">{splitAddress? splitAddress[0]: hoverContent}</p>
                </div>
              </div>
            </Col>
          )
        })}
      </Row>
      {loading ? <LinearProgress /> : null}
      <div className='d-flex justify-content-center'>
        {_isEmpty(blocks) && !loading ?
          <div className='mt-5'>No Data</div> : null}
        {!_isEmpty(blocks) ?
          otherResData?.next_page_url  &&< ThemeButton
            className={ !otherResData?.next_page_url ? 'darkGrey-button ' : "green-button"}
            onClick={handleLoadMore}
            disabled={!otherResData?.next_page_url}

          // disabled={otherResData?.current_page === otherResData?.last_page}
          >
            Load More
          </ThemeButton> : null}
      </div>
    </div>
  )
}

export default DigitalTemplateContentCustomise
