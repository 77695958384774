import React, { useState } from "react";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import "./_toggleButtons.scss";

export default function ToggleButtons({ input, labelOne, labelTwo }) {
  const [alignment, setAlignment] = useState(input.value);
  console.log(input.value);
  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);

    input.onChange(newAlignment);
  };

  // console.log(input);
  return (
    <ToggleButtonGroup
      className="toggle-button-group w-100"
      // value={alignment ?? ""}
      value={alignment}
      exclusive
      onChange={handleAlignment}
      name={input.name}
    >
      {labelOne && <ToggleButton value="true">{labelOne}</ToggleButton>}
      {labelTwo && <ToggleButton value="false">{labelTwo}</ToggleButton>}
    </ToggleButtonGroup>
  );
}
