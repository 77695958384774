import React, { useEffect, useState } from "react";
import { getMenu } from "../../../../GeneralApi/GeneralApi";
import LoadingSkeleton from "../../../../partials/display/skeleton/LoadingSkeleton";

import NewMicrositeForm from "./NewMicrositeForm";

const NewMicrosite = ({ closeModal, micrositeValue }) => {
  const [email_template_category, setEmail_template_category] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    update();
  }, []);

  const update = () => {
    let query = "email_template_category";
    getMenu(query)
      .then(({ data: { data } }) => {
        setEmail_template_category(data.email_template_category);
        setLoading(false);
      })
      .catch(() => {});
  };

  return (
    <div className="p-3">
      {loading ? (
        <LoadingSkeleton height="40vh" />
      ) : (
        <NewMicrositeForm
          initialValues={{
            email_template_category: email_template_category,
          }}
          closeModal={closeModal}
          email_template_category={email_template_category}
        />
      )}
    </div>
  );
};

export default NewMicrosite;
