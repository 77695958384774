import { TextField } from "@material-ui/core";
import React, { Fragment, useState } from "react";
import { Col, Row } from "react-bootstrap";
// import { connect } from "react-redux";
import { FormSection, Field } from "redux-form";

import { getAllListingAddress } from "../../modules/listings/_crud/listingsCrud";
import { errorAlert, formToQueryString } from "../../_helpers/_utils/utils";
import AsyncMultiSelectCreatable from "./AsyncMultiSelectCreatable";
import TextInput from "./TextInput";

function PropertyFullAddress({
  changeProp,
  multiLine,
  formSectionName,
  placeHolder,
  name,
  defaultOptions = {},
  setSelectedAddress = () => {},
  defaultAddress,
}) {
  // const [address, setAddress] = useState({});
  const [foundAddress, setFoundAddress] = useState(false);

  const getAddressOptions = (text) => {
    if (text === undefined) {
      if (defaultAddress && defaultAddress.length) {
        text = defaultAddress[0].full_address;
      }
    }

    if (typeof text === "string" || text instanceof String) {
      let query = formToQueryString({
        terms: text,
      });

      return getAllListingAddress(query)
        .then(({ data }) => {
          let options = data.data.map((item) => ({
            value: item.id,
            label: item.address,
            ...item,
          }));

          if (defaultAddress && defaultAddress.length) {
            if (text === defaultAddress[0].full_address) {
              const defaultValue = options.filter(
                (opt) =>
                  String(opt.label).toLowerCase() ===
                  String(defaultAddress[0].full_address).toLowerCase()
              );
              if (defaultValue.length) {
                // setAddress(defaultValue[0]);
                handleAddressOnClick(defaultValue[0]);
                setFoundAddress(true);
              }
            }
          }

          return options;
        })
        .catch((err) => {
          errorAlert(err?.data);
        });
    }
  };

  const handleAddressOnClick = (data) => {
    if (formSectionName) {
      changeProp(`${formSectionName}.${name}`, data);
    } else {
      changeProp(name, data);
    }
    setSelectedAddress(data && data);
  };

  return (
    <Fragment>
      <FormSection name={name}>
        <Row>
          <Col>
            {foundAddress ? (
              <TextField fullWidth value={defaultAddress[0].full_address} disabled />
            ) : (
              <Field
                name="full_address"
                component={AsyncMultiSelectCreatable}
                isValidNewOption={() => false}
                isMulti={false}
                options={getAddressOptions}
                handleOnClick={handleAddressOnClick}
                placeholder={placeHolder}
                isClearable={true}
              />
            )}
          </Col>
        </Row>
        <FormSection name="addressComponents">
          <Row className="my-4">
            <Col lg={multiLine ? 3 : 1}>
              <Field
                name={"unitNumber"}
                type="text"
                disabled={true}
                component={TextInput}
                label="Unit"
              />
            </Col>
            <Col lg={multiLine ? 3 : 2}>
              <Field
                name={"streetNumber"}
                type="text"
                disabled={true}
                component={TextInput}
                label="Street No."
              />
            </Col>
            <Col lg={multiLine ? 4 : 2}>
              <Field
                name={"streetName"}
                type="text"
                disabled={true}
                component={TextInput}
                label="Street Name"
              />
            </Col>
            <Col lg={multiLine ? 3 : 2}>
              <Field
                name={"streetTypeLong"}
                type="text"
                disabled={true}
                component={TextInput}
                label="Street Type"
              />
            </Col>
            <Col lg={multiLine ? 4 : 2}>
              <Field
                name={"suburb"}
                type="text"
                disabled={true}
                component={TextInput}
                label="Suburb"
              />
            </Col>
            <Col lg={multiLine ? 4 : 1}>
              <Field
                name={"state"}
                type="text"
                disabled={true}
                component={TextInput}
                label="State"
              />
            </Col>
            <Col lg={multiLine ? 3 : 2}>
              <Field
                name={"postCode"}
                type="text"
                disabled={true}
                component={TextInput}
                label="Postcode"
              />
            </Col>
          </Row>
        </FormSection>
      </FormSection>
    </Fragment>
  );
}

PropertyFullAddress.defaultProps = {
  label: "",
  changeProp: () => {},
  multiLine: false,
  formSectionName: "",
  fieldArray: "",
  placeHolder: "Property Address",
  name: "domain_address",
};

export default PropertyFullAddress;
