import React, { Fragment } from "react";
import { Carousel } from "react-bootstrap";

import BasicInfo from "../../../../../partials/display/BasicInfo";
import Preferences from "../../../../../partials/display/Preferences";
import ContactDetails from "../../../../../partials/display/ContactDetails";
import ThemeButton from "../../../../../partials/button/ThemeButton";
import { ProfileBasicInfo } from "../../../../../partials/display/ProfileBasicInfo";
import { deleteTenant } from "../../../crud/tenantsCrud";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import * as tenantsRedux from "../../../_redux/tenantsRedux";
import AccessControl from "../../../../../_ accessControl/AccessControl";
import Swal from "sweetalert2";

const TenantProfileSideBar = ({
  profile,
  closeModal,
  queryString,
  tenantsLoading,
  dnc,
  updateContext,
}) => {
  const handleDelete = (key) => {
    let value = {};
    value.id = key;

    Swal.fire({
      title: "Warning",
      text: "Are you sure want to delete?",
      icon: "warning",
      showCancelButton: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
      confirmButtonColor: "#A7A9AC",
      cancelButtonColor: "#2BB673",
      confirmButtonText: "Cancel",
      cancelButtonText: "Yes",
    }).then((result) => {
      if (result.isDismissed) {
        deleteTenant(key)
          .then(({ data: { data } }) => {
            console.log(data);
            toast.success("File Deleted Successfully");
            tenantsLoading(queryString);
            closeModal();
          })
          .catch((e) => { });
      }
    });
  };

  return (
    <Fragment>
      <div className="card mb-5">
        <div className="card-body">
          <div className="pl-0">
            <div className="main-profile-overview">
              <div className="d-flex">
                <Carousel
                  className="profile-basicInfo-carousel w-100 pb-5"
                  interval={null}
                  nextIcon={<i className="fas fa-angle-right"></i>}
                  controls={profile?.contacts?.length > 1 ? true : false}
                  indicators={profile?.contacts?.length > 1 ? true : false}
                >
                  {profile?.contacts?.map((item, i) => (
                    <Carousel.Item key={i}>
                      <ProfileBasicInfo profile={item} />
                      <div className="contact-profileBasicInfo">
                        <BasicInfo dnc={dnc} profile={item} />
                      </div>
                    </Carousel.Item>
                  ))}
                </Carousel>
              </div>

              <Preferences
                profile={profile}
                fromTenant
                updateContext={updateContext}
              />
              <ContactDetails
                type="Tenant"
                profile={profile}
                updateContext={updateContext}
              />
              <AccessControl
                allowedPermissions={["tenants-delete"]}
                renderNoAccess={() => ""}
              >
                <ThemeButton
                  onClick={() => handleDelete(profile.id)}
                  className="brand-button w-100"
                >
                  Delete
                </ThemeButton>
              </AccessControl>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = ({ tenants: { queryString, profile } }) => ({
  queryString: queryString,
  dnc: profile?.dnc,
});

export default connect(
  mapStateToProps,
  tenantsRedux.actions
)(TenantProfileSideBar);
