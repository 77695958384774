import React from "react";
import { connect } from "react-redux";
import * as tab from "../_redux/tabRedux";
// import * as Views from "../partials/views";

const mapState = (state) => ({
  currentTab: state.tab.newTab,
});

const TabManager = (props) => {
  // console.log("tabManager");
  // console.log(props.currentTab);
  if (props.currentTab.name) {
    // const Cmp = Views[props.currentTab.name];
    const newTabObject = {
      name: props.currentTab.name,
      props: { ...props.currentTab },
    };
    props.add(newTabObject);
    props.close();
  }

  return <span></span>;
};

const actions = {
  add: tab.actions.tabAdd,
  close: tab.actions.closetab,
};

export default connect(mapState, actions)(TabManager);
