import React, { useEffect, useState, Fragment } from "react";
import { LinearProgress } from "@material-ui/core";
import { Modal } from "antd";
import moment from "moment";
import { toast } from "react-toastify";
import _debounce from 'lodash/debounce';
import Swal from "sweetalert2";

import ActiveDots from "../../../../partials/table/cells/ActiveDots";
import ThemeHeader from "../../../../partials/content/ThemeHeader";
import ThemeButton from "../../../../partials/button/ThemeButton";
import DefaultModal from "../../../../partials/modal/DefaultModal";
import DynamicTableEdit from "../../../../partials/table/DynamicTableEdit";
import AddNewBlogPost from "../../partials/blogPosts/AddNewBlogPost";
import { convertFormData, formToQueryString, processBlogPostInfo } from "../../../../_helpers/_utils/utils";
import ToolTip from "../../../../partials/tooltip/ToolTip";
import SocialShare from "../../../../partials/component/speedDial/SocialShare";
import SmsWrap from "../../../../partials/component/sms/SmsWrap";
import Email from "../../../../partials/component/email/Email";
import TableFilter from "../../../../partials/table/TableFilter";
import { getAllBlogs, updateBlog } from "../../../setting/_crud/settingCrud";
import LoadingSkeleton from "../../../../partials/display/skeleton/LoadingSkeleton";

const BlogPosts = () => {
  const [selectedData, setSelectedData] = useState([]);
  const [otherResData, setOtherResData] = useState(null);
  const [showSms, setShowSms] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [showBlogForm, setShowBlogForm] = useState(false);
  const [blogPosts, setBlogPosts] = useState([]);
  const [content, setContent] = useState()
  const [selectedBlog, setSelectedBlog] = useState({});
  const [selectedBlogDetails, setSelectedBlogDetails] = useState({});
  const [isLoading, setIsLoading] = useState([]);
  const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  useEffect(() => {
    getBlogssData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getBlogssData = (values) => {
    let obj = { ...values, limit: 30, };
    let query = formToQueryString(obj);
    setIsLoading(true)
    getAllBlogs(query)
      .then((res) => {
        const { data, ...rest } = res.data;
        if (values?.loadMore) {
          setBlogPosts(blogPosts.concat(data));
        } else {
          setBlogPosts(data);
        }
        setOtherResData(rest);
      })
      .catch(() => { })
      .finally(() => {
        setIsLoading(false)
      })
  };

  const fetchMoreData = () => {
    let obj = {
      page: otherResData?.current_page + 1,
      loadMore: true
    };
    if (otherResData?.current_page !== otherResData?.last_page) {
      getBlogssData(obj);
    }
  };
  const rowClick = data => {
    setShowBlogForm(true);
    setSelectedBlog(processBlogPostInfo(data));
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        columns: [
          {
            Header: "Date",
            accessor: "created_at",
            Cell: ({ cell: { value } }) => (
              <p className="center">
                {value ? moment(value).format("DD/MM/YYYY") : ""}
              </p>
            ),
          },
          {
            Header: "Title",
            accessor: "title",
            Cell: ({
              cell: { value, row: { original } }
            }) => (
              <div className="d-flex justify-content-between">
                <div>{value}</div>
                <div className="d-flex">
                  {" "}
                  { original?.is_public == 1 ? 
                    <ThemeButton onClick={(e)=>{ e.stopPropagation(); openInNewTab(original?.website_url)}} icon>
                          <ToolTip name="globe" content="Globe" />
                    </ThemeButton> : '' 
                  }
                  <ThemeButton onClick={(e) => {  e.stopPropagation(); navigator.clipboard.writeText(original?.website_url); toast.success("Link copied") }} icon>
                    <div>
                      <i className="fas fa-share-square"></i>
                    </div>
                  </ThemeButton>
                </div>
              </div>
            ),
          },
          {
            Header: "Status",
            accessor: "is_public",
            Cell: ({
              cell: { value },
            }) => <ActiveDots active={value} />
          },
        ],
      },
    ],
    []
  );

  const handleOnSubmit = async ({ assets, organisation, created_by, ...values }) => {
    const { id } = selectedBlog;
    if (!values.header_image) {
      toast.error("Please select header image");
    
    } else if (content?.length === 0) {
      toast.error("Please fill the conent");
    } else {
      if (id) {
        values['_method'] = 'PATCH'
      }
      if (values.header_image instanceof Array) {
        values = { ...values, header_image: values.header_image[0] }
      }
      values.content = content
      if (values.tags?.id) {
        values.tags  =  values.tags?.id
      }else{
        values.tags  = values.tags
      }
      const formData = convertFormData(values);
      return await updateBlog(id, formData)
        .then(res => {
          getBlogssData();
          closeBlogPostModal()
          toast.success(`Blog ${id ? 'Updated' : 'Created'} Successfully`)
        })
        .catch(err => {
          toast.error(`${id ? 'Update' : 'Create'} Blog Failed`)
        })
    }
  };

  const handleBlogsDelete = () => {
    let data = { selected_ids: selectedData.map(item => item.id) }
    Modal.confirm({
      title: 'Warning',
      content: 'Are you sure want to delete?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        setIsLoading(true)
        const formData = convertFormData(data);
        updateBlog('bulk-delete', formData)
          .then(res => {
            toast.success("Blog(s) Deleted Successfully");
            getBlogssData()
          })
          .catch(err => {
            toast.error("Blog(s) Deleted Failed");
          })
          .finally(() => {
            setIsLoading(false)
          })
      }
    })
  };

  const handleSearch = (value) => {
    getBlogssData({ search_kw: value })
  };

  const handleGlobalSearch = _debounce(handleSearch, 500);

  const closeBlogPostModal = () => {
    setShowBlogForm(false);
    setSelectedBlog({});
  }

  const handleClose = () => {
    Swal.fire({
      title: "Warning",
      text: "Are you sure want to exit?",
      icon: "warning",
      showCancelButton: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
      confirmButtonColor: "#A7A9AC",
      cancelButtonColor: "#2BB673",
      confirmButtonText: "Cancel",
      cancelButtonText: "Yes",
    }).then((result) => {
      if (result.isDismissed) {
        closeBlogPostModal()
        setShowSms(false)
        setShowEmail(false)
      }
    });
  };

  return (
    <div>
      {showSms ? (
        <DefaultModal
          size="xl"
          centered
          show={showSms}
          onHide={handleClose}
        >
          <SmsWrap
            closeModal={() => setShowSms(false)}
            // phoneData={selectedRow.map((a) => a.phones)}
            // totalRecipient={selectedItem?.length}
            // queryString={contacts.queryString}
            data={selectedData?.map((item) => item?.contacts?.[0])}
            hasDefaultNumbers={false}
            message={selectedBlogDetails.website_url}
          />
        </DefaultModal>
      ) : (
        ""
      )}

      {showEmail ? (
        <DefaultModal
          size="xl"
          centered
          show={showEmail}
          onHide={handleClose}
        >
          <Email
            closeModal={() => setShowEmail(false)}
            // emailData={[props.profile.emails]}
            totalRecipient={selectedData?.length}
            // queryString={contacts.queryString}
            message={selectedBlogDetails.website_url}
            data={selectedData?.map((item) => item?.contacts?.[0])}
            hasDefaultEmails={false}
          />
          {/* <ContactForm  formName='create-new-contact' onSubmit={HandleOnsubmit} closeModal={() => setShowAddContact(false)} /> */}
        </DefaultModal>
      ) : (
        ""
      )}
      <ThemeHeader
        className="p-4"
        left={<h4 className="title">Blog Posts</h4>}
        right={ !showBlogForm &&
          <Fragment>
            <div className="mr-3">  <TableFilter
              length={blogPosts.length}
              handleGlobalSearch={handleGlobalSearch}
            />
            </div>
            <div className="pr-2">
              <ThemeButton
                className="lightGrey-button"
                onClick={() => handleBlogsDelete()}
                disabled={!selectedData.length}
              >
                Delete
              </ThemeButton>
            </div>
            <ThemeButton
              className="green-button"
              onClick={() => setShowBlogForm(true)}
            >
              Add Blog Post
            </ThemeButton>
          </Fragment>
        }
      />
      {isLoading && !showBlogForm ? <LinearProgress /> : ""}
      { !showBlogForm  ?
        isLoading && !blogPosts.length ?
          <LoadingSkeleton height={"77vh"} /> 
          :
          <DynamicTableEdit
            columns={columns}
            handleChange={(e) => setSelectedData(e)}
            data={blogPosts}
            isEditable={true}
            handleRowClick={rowClick}
            fetchMoreData={fetchMoreData}
          />
          :
          <AddNewBlogPost
            onSubmit={(values) => handleOnSubmit(values)}
            closeModal={closeBlogPostModal}
            closeWithAlert={handleClose}
            selectedBlog={selectedBlog}
            setSelectedBlog={setSelectedBlog}
            content={content}
            setContent={setContent}
          />
      }
    </div>
  );
};

export default BlogPosts;
