import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export const actionTypes = {
    OpenModal: "[open Modal]",
    CloseModal: "[close Modal]"
};

const initialState = null;

export const reducer = persistReducer(
    { storage, key: "wol-auth", whitelist: ["modal"]},
    (state = initialState, action) => {
        switch (action.type) {

            case actionTypes.OpenModal: {
                const {modalType, modalProps} = action.payload;  // Get Modal Type and props for Modal View

                return {modalType, modalProps}
            }
            case actionTypes.CloseModal: {
                return null;  // Modal Close
            }

            default:
                return state;
        }
    }
);

export const actions = {
    closeModal: data => ({ type: actionTypes.CloseModal}),
    openModal: (modalType, modalProps) => ({ type: actionTypes.OpenModal, payload: { modalType, modalProps } }),
};
