import React, { Fragment } from "react";
import Sms from "../../../../../../partials/component/sms/Sms";

const PreviewSms = ({ closeModal }) => {
  return (
    <Fragment>
      <Sms data={[]} closeModal={closeModal} hasDefaultNumbers={false}/>
    </Fragment>
  );
};

export default PreviewSms;
