import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  Backdrop,
  CircularProgress,
  Grid,
  LinearProgress,
  Typography,
} from "@material-ui/core";

import { updateMicrositeSettings } from "../../crud/socialMedailCrud";

import MultiSelect from "../../../../partials/form/MultiSelect";
import { MicrositesContext } from "./MicrositesContextProvider";

const MicrositesSettings = () => {
  const {
    members,
    getListOfMembers,
    isLoadingMembers,
    availableTemplates,
    getListOfTemplates,
  } = useContext(MicrositesContext);

  const [memberSelectedTemplate, setMemberSelectedTemplate] = useState({});
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    getListOfMembers("list");
    getListOfTemplates("","","600");
  }, []);

  useEffect(() => {
    const newObj = {};

    members.forEach((member) => {
      newObj[member.id] = {
        pre: member?.microsites_templates?.pre_listing_template ?? null,
        plist:
          member?.microsites_templates?.post_listing_listed_template ?? null,
        pnlist:
          member?.microsites_templates?.post_listing_not_listed_template ??
          null,
      };
    });

    setMemberSelectedTemplate(newObj);
  }, [members]);


  const filterAvailableTemplates = (memberId, settingType) => {
    let filteredTemplates = [];

    if (availableTemplates) {
      filteredTemplates = availableTemplates.filter((available) =>
          (available?.settings?.for === memberId ||
              available?.settings?.secondary_for === memberId) &&
          available?.settings?.type === settingType
      );
    }

    // Check if the filteredTemplates array is not empty
    if (filteredTemplates.length > 0) {
      // Add an additional object at the beginning
      filteredTemplates.unshift({
        id: null,
        name: "None",
        settings: ""
      });
    }

    return filteredTemplates;
  };
  

  return (
    <>
      <Grid container className="p-3">
        <Grid item xs={12}>
          <h6 className="title max-w">Microsites Settings</h6>
        </Grid>
        <Grid container style={{ border: "3px solid #822633" }}>
          <Grid item xs={12} style={{ backgroundColor: "#822633", padding: 3 }}>
            <Grid container>
              <Grid item xs={3} >
                <Typography
                  variant="h6"
                  style={{ color: "white", fontWeight: 700 }}
                >
                  Name
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography
                  variant="h6"
                  style={{ color: "white", fontWeight: 700 }}
                >
                  Type
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          {isLoadingMembers && (
            <Grid item xs={12}>
              <LinearProgress />
            </Grid>
          )}

          {!isLoadingMembers &&
            members?.map((member) => (
              <Grid
                item
                xs={12}
                key={member.id}
                className="grid-item-information"
              >
                <Grid container>
                  <Grid
                    item
                    xs={3}
                    style={{
                      padding: 8,
                      borderRight: "1px solid #e1e1e1",
                    }}
                  >
                    <Typography
                      variant="h6"
                      style={{ fontWeight: 700 }}
                    >{`${member?.contact?.first_name} ${member?.contact?.last_name}`}</Typography>
                  </Grid>
                  <Grid item xs={9} style={{ padding: 8, marginBottom: 8 }}>
                    <Grid container>
                      <Grid item xs={12}>
                        <MultiSelect
                          label="Pre-Listing Template"
                          placeholder="Pre-Listing Template"
                          isMulti={false}
                          options={filterAvailableTemplates(member.id,1)}
                          onChange={(value) => {
                            const currentMember = {
                              ...memberSelectedTemplate[member.id],
                            };

                            currentMember["pre"] = value;
                            setMemberSelectedTemplate({
                              ...memberSelectedTemplate,
                              [member.id]: currentMember,
                            });

                            setIsSaving(true);
                            updateMicrositeSettings(member.id, 1, value)
                              .then((res) => {
                                if (res.status === 200) {
                                  toast.success("Microsite Template Saved!");
                                } else {
                                  toast.error(
                                    "Error while saving the template!"
                                  );
                                }
                              })
                              .catch((err) =>
                                toast.error(
                                  `Error while saving the template (${err})`
                                )
                              )
                              .finally(() => setIsSaving(false));
                          }}
                          value={memberSelectedTemplate[member.id]?.pre ?? ""}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <MultiSelect
                          label="Post-Listing (Listed)"
                          placeholder="Post-Listing (Listed)"
                          isMulti={false}
                          options={filterAvailableTemplates(member.id,2)}
                          value={memberSelectedTemplate[member.id]?.plist ?? ""}
                          onChange={(value) => {
                            const currentMember = {
                              ...memberSelectedTemplate[member.id],
                            };

                            currentMember["plist"] = value;
                            setMemberSelectedTemplate({
                              ...memberSelectedTemplate,
                              [member.id]: currentMember,
                            });

                            setIsSaving(true);
                            updateMicrositeSettings(member.id, 2, value)
                                .then((res) => {
                                  if (res.status === 200) {
                                    toast.success("Microsite Template Saved!");
                                  } else {
                                    toast.error(
                                        "Error while saving the template!"
                                    );
                                  }
                                })
                                .catch((err) =>
                                    toast.error(
                                        `Error while saving the template (${err})`
                                    )
                                )
                                .finally(() => setIsSaving(false));
                          }}
                          disabled={false}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <MultiSelect
                          label="Post-Listing (Not Listed)"
                          placeholder="Post-Listing (Not Listed)"
                          isMulti={false}
                          options={filterAvailableTemplates(member.id,3)}
                          value={memberSelectedTemplate[member.id]?.pnlist ?? ""}
                          disabled={false}
                          onChange={(value) => {
                            const currentMember = {
                              ...memberSelectedTemplate[member.id],
                            };

                            currentMember["pnlist"] = value;
                            setMemberSelectedTemplate({
                              ...memberSelectedTemplate,
                              [member.id]: currentMember,
                            });

                            setIsSaving(true);
                            updateMicrositeSettings(member.id, 3, value)
                                .then((res) => {
                                  if (res.status === 200) {
                                    toast.success("Microsite Template Saved!");
                                  } else {
                                    toast.error(
                                        "Error while saving the template!"
                                    );
                                  }
                                })
                                .catch((err) =>
                                    toast.error(
                                        `Error while saving the template (${err})`
                                    )
                                )
                                .finally(() => setIsSaving(false));
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ))}
        </Grid>
      </Grid>
      <Backdrop open={isSaving} style={{ zIndex: 9 }}>
        <CircularProgress style={{ color: "#822633" }} />
      </Backdrop>
    </>
  );
};

export default MicrositesSettings;
