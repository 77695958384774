import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest, delay, select } from "redux-saga/effects";
import { getAllRentals, getRentalById } from "../_crud/rentalsCrud";

export const actionTypes = {
  RentalsLoading: "[RentalsLoading]",
  RentalFetched: "[RentalFetched]",
  RentalFetchAsync: "[RentalFetchAsync]",
  RentalsFetched: "[RentalsFetched]",
  RentalCreated: "[RentalCreated]",
  RentalUpdated: "[RentalUpdated]",
  RentalDeleted: "[RentalDeleted]",
  RentalsDeleted: "[RentalsDeleted]",
  RentalsStatusUpdated: "[RentalsStatusUpdated]",
  RentalLoadingIndicator: "RentalsLoadingIndicator",
  RentalFetchMore: "[RentalFetchMore]",
  RentalsTableTitleChange: "[RentalsTableTitleChange]",
  tabChanged: "[tabChanged]",
};

const initialRentalsState = {
  isLoading: false,
  rentalLoading: true,
  queryString: "",
  rentalsTableTitle: "Available Rentals",
  otherResInfo: null,
  entities: null,
  error: null,
  selectedSideTab: "details",
};

export const reducer = persistReducer(
  { storage, key: "wol-auth", whitelist: ["rentals"] },
  (state = initialRentalsState, action) => {
    switch (action.type) {
      // case actionTypes.RentalLoading: {
      //     return {
      //         ...state
      //     };
      // }
      case actionTypes.tabChanged: {
        return {
          ...state,
          selectedSideTab: action.payload,
        };
      }
      case actionTypes.RentalLoadingIndicator: {
        const { indicator } = action.payload;
        return {
          ...state,
          isLoading: indicator,
        };
      }

      case actionTypes.RentalsTableTitleChange: {
        return {
          ...state,
          rentalsTableTitle: action.payload,
        };
      }
      case actionTypes.RentalsFetched: {
        const { data, otherResInfo, queryString } = action.payload;
        return {
          ...state,
          entities: data,
          otherResInfo: otherResInfo,
          queryString: queryString,
        };
      }
      case actionTypes.RentalFetched: {
        const { data } = action.payload;
        return {
          ...state,
          profile: data,
        };
      }
      case actionTypes.RentalError: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  tabChanged: (data) => ({ type: actionTypes.tabChanged, payload: data }),
  rentalsLoading: (data) => ({ type: actionTypes.RentalsLoading, data }),
  rentalFetchAsync: (data) => ({
    type: actionTypes.RentalFetchAsync,
    payload: data,
  }),
  rentalFetchMore: (data) => ({ type: actionTypes.RentalFetchMore, data }),
  rentalsLoadingIndicator: (data) => ({
    type: actionTypes.RentalLoadingIndicator,
    payload: data,
  }),
  rentalsTableTitleChange: (data) => ({
    type: actionTypes.RentalsTableTitleChange,
    payload: data,
  }),
  // socialFeedPostAsync: data => ({ type: actionTypes.SocialFeedPostAsync, payload: data }),
  rentalFetched: (data) => ({
    type: actionTypes.RentalFetched,
    payload: { data },
  }),
  rentalsFetched: (data) => ({
    type: actionTypes.RentalsFetched,
    payload: data,
  }),
  rentalCreated: (data) => ({
    type: actionTypes.RentalCreated,
    payload: { data },
  }),
  rentalUpdated: (data) => ({
    type: actionTypes.RentalUpdated,
    payload: { data },
  }),
  rentalDeleted: (data) => ({
    type: actionTypes.RentalDeleted,
    payload: { data },
  }),
  rentalsDeleted: (data) => ({
    type: actionTypes.RentalsDeleted,
    payload: { data },
  }),
  rentalsStatusUpdated: (data) => ({
    type: actionTypes.RentalsStatusUpdated,
    payload: { data },
  }),
  rentalError: (error) => ({
    type: actionTypes.RentalError,
    payload: { error },
  }),
};

export const getRentals = (state) => state.rentals;

export function* sagaRentals() {
  yield takeLatest(actionTypes.RentalsLoading, function* rentalsLoading(prams) {
    yield put(actions.rentalsLoadingIndicator({ indicator: true }));
    let rentalsInfo = yield select(getRentals);
    let queryData = prams.data
      ? prams.data
      : rentalsInfo.queryString
        ? rentalsInfo.queryString
        : "";
    const res = yield getAllRentals(prams.data);
    // getAllRentals(prams.data);

    const { data, ...rest } = res.data;

    yield put(
      actions.rentalsFetched({
        data: data,
        otherResInfo: rest,
        queryString: queryData,
      })
    );

    yield put(actions.rentalsLoadingIndicator({ indicator: false }));
  });

  yield takeLatest(actionTypes.RentalFetchAsync, function* rentalFetchAsync({
    payload,
  }) {
    const rental = yield getRentalById(payload);
    yield delay(2000);
    yield put(actions.rentalFetched(rental));
  });

  yield takeLatest(actionTypes.RentalFetchMore, function* rentalFetchMore(
    prams
  ) {
    yield put(actions.rentalsLoadingIndicator({ indicator: true }));
    let rentalsInfo = yield select(getRentals);
    const res = yield getAllRentals(prams.data);
    // getAllRentals(prams.data);
    const { data, ...rest } = { ...res.data };
    const newData = rentalsInfo.entities.concat(data);
    yield put(actions.rentalsFetched({ data: newData, otherResInfo: rest }));
    yield put(actions.rentalsLoadingIndicator({ indicator: false }));
  });
}
