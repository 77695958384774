import React, {
  Fragment,
  useState,
  useEffect,
  useContext,
} from "react";
import {
  reduxForm,
  getFormValues,
} from "redux-form";
import { connect, useSelector } from "react-redux";
import ThemeHeader from "../../../../partials/content/ThemeHeader";
import ThemeButton from "../../../../partials/button/ThemeButton";
import AddNewListingsForm from "./AddNewRentalForm";
import {
  convertFormData,
  getObjWithoutValues,
  postCreateRental,
  handleQueryChangeAccordingToTitleRental,
  getErrorMessage,
} from "../../../../_helpers/_utils/utils";
import { Spinner } from "react-bootstrap";

import { toast } from "react-toastify";
import * as RentalsRedux from "../../_redux/RentalsRedux";
// import { AddNewListingsContext } from "./AddNewListingsContextProvider";
import { createNewRental } from "../../_crud/rentalsCrud";
import {
  createProperty,
  getPropertyIdByAddress,
  updateProperty,
} from "../../../listings/_crud/listingsCrud";

// const validate = (values) => {
// const errors = {};
//   if (!values.username) {
//     errors.username = "Required";
//   } else if (values.username.length > 15) {
//     errors.username = "Must be 15 characters or less";
//   }
//   if (!values.email) {
//     errors.email = "Required";
//   } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
//     errors.email = "Invalid email address";
//   }
//   if (!values.age) {
//     errors.age = "Required";
//   } else if (isNaN(Number(values.age))) {
//     errors.age = "Must be a number";
//   } else if (Number(values.age) < 18) {
//     errors.age = "Sorry, you must be at least 18 years old";
//   }
// return errors;
// };

const AddNewListingsBody = (props) => {
  const {
    handleSubmit,
    closeModal,
    formValues,
    // submitting,
    invalid,
    change,
    contactValues,
    // setListingType,
  } = props;
  // const { setListingValues, setInputAddress, listingValues } = useContext(
  //   AddNewListingsContext
  // );
  //  Change marketing amount depend on marketing package selection

  const [submitting, setSubmitting] = useState(false);
  const rentalsTitle = useSelector((state) => state.rentals.rentalsTableTitle);

  // useEffect(() => {
  // if (address_changed && !listingValues) {
  //   let queryString = formToQueryString(
  //     processAddressForSearch(formValues?.address[0])
  //   );
  //   getPropertyIdByAddress(queryString)
  //     .then(({ data: { data } }) => {
  //       setListingValues(data);
  //       setInputAddress(formValues?.address);
  //     })
  //     .catch(() => {});
  // }
  // });
  // Not finished

  const onFormSubmit = async (values) => {
    setSubmitting(true);
    const { full_address, label, value, ...domain_address } = formValues.domain_address || {}
    let _data = {
      ...formValues,
      domain_address
    }
    const formData = convertFormData(
      getObjWithoutValues([undefined, null], postCreateRental(_data))
    );
    return await createNewRental(formData)
      .then(({ data }) => {
        closeModal();
        toast.success("Post Saved Successfully");
        props.rentalsLoading(
          handleQueryChangeAccordingToTitleRental(rentalsTitle)
        );

        // setListingType(0);
        setSubmitting(false);
      })
      .catch((e) => {
        setSubmitting(false);
        if (e?.status !== "404") {
          getErrorMessage(e).map((message) => toast.error(message));
        } else {
          toast.error("Something Went Wrong");
        }
        // toast.warn("post new rental failed");
      });
  };
  // const onFormSubmit = async () => {
  //   let propertyId;

  //   if (listingValues?.id) {
  //     await updateProperty(
  //       processUpdateProperty(formValues),
  //       listingValues?.id
  //     );
  //   } else {
  //     await createProperty(processUpdateProperty(formValues, false)).then(
  //       (d) => {
  //         propertyId = d.data?.data?.id;

  //         setNewPropertyId(d.data?.data?.id);
  //       }
  //     );
  //   }
  //   const formData = convertFormData(
  //     getObjWithoutValues(
  //       [undefined, null],
  //       postCreateListing({
  //         ...formValues,
  //         property: listingValues?.id ? listingValues?.id : propertyId,
  //       })
  //     )
  //   );
  //   createNewRental(formData)
  //     .then(({ data }) => {
  //       closeModal();
  //       toast.success("Post Saved Successfully");
  //       props.listingsLoading();
  //     })
  //     .catch((e) => {
  //       toast.warn("post new rental failed");
  //     });
  // };

  return (
    <Fragment>
      <form className="contactForm" onSubmit={handleSubmit(onFormSubmit)}>
        <Fragment>
          <ThemeHeader
            left={
              <Fragment>
                <h5 className="title">Create New Rental</h5>
              </Fragment>
            }
            right={
              <Fragment>
                <Fragment>
                  <div className="pl-2">
                    <ThemeButton
                      onClick={() => closeModal()}
                      className="lightGrey-button"
                    >
                      Cancel
                    </ThemeButton>
                  </div>
                  <div className="pl-2">
                    <ThemeButton
                      disabled={invalid || submitting}
                      type="submit"
                      className="theme-button green-button"
                    >
                      {submitting ?
                        <Spinner
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        /> : ''}
                      Save
                    </ThemeButton>
                  </div>
                </Fragment>
              </Fragment>
            }
          />
        </Fragment>
      </form>
      <div className="row">
        <AddNewListingsForm
          formValues={formValues}
          contactValues={contactValues}
          changeProp={change}
        />
      </div>
    </Fragment>
  );
};

const mapState = (state, ownProps) => {
  const values = getFormValues("add-new-rental")(state);
  const contactValues = getFormValues("update-contact")(state);
  const editContactValues = getFormValues("edit-contact")(state);
  return {
    formValues: values,
    contactValues: editContactValues?.id ? editContactValues : contactValues,
    // initialValues: {
    //   address: [
    //     { type: 1, street_unit: "", street: {}, suburb: {}, state: {} },
    //   ],
    // },
  }; // Form name must have to be different
};

export default connect(
  mapState,
  RentalsRedux.actions
)(
  reduxForm({
    form: "add-new-rental",
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
    enableReinitialize: true,
  })(AddNewListingsBody)
);
