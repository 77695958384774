import React, { useEffect, useState } from "react";
import { getFormValues, reduxForm } from "redux-form";
import { connect, useSelector } from "react-redux";
import { toast } from "react-toastify";
import _sortBy from "lodash/sortBy";

import HomePageSetupForm from "./HomePageSetupForm";
import {
  getAllMembers,
  getOfficeDetails,
  updateOfficeDetails,
} from "../../../setting/_crud/settingCrud";
import {
  convertFormData,
  errorAlert,
  formToQueryString,
  getProcessedAddress,
} from "../../../../_helpers/_utils/utils";

const HomepageSetup = (props) => {
  const { formValues, change, handleSubmit, selectedTabData } = props;
  const organisation_id = useSelector(({ auth }) => auth.user.organisation.id);
  const [officeDetails, setOfficeDetails] = useState({});
  // const [members, setMembers] = useState([])
  // const [membersCount, setMembersCount] = useState(0)
  const [officeDetailsLoading, setOfficeDetailsLoading] = useState(false);
  const [membersLoading, setMembersLoading] = useState(false);
  const [otherResData, setOtherResData] = useState(null);
  // const [memberDisplayOrder, setmemberDisplayOrder] = useState([]);

  // useEffect(() => {
  //   change('members', members.slice(0, membersCount))

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [membersCount])

  useEffect(() => {
    if (selectedTabData === "Marketing") {
      getMembers({ page: 1 });
    }
  }, [selectedTabData]);

  // useEffect(() => {
  //   if (members.length) {
  //     setMembersCount(6)
  //   }
  // }, [members])

  const getMembers = (params) => {
    let obj = {
      ...params,
      limit: 6,
      visible_on_website: 1,
      sort_by: "website_display_order",
      direction: "asc",
    };
    setMembersLoading(true);
    let query = formToQueryString(obj);
    getAllMembers(query)
      .then((res) => {
        const { data, ...rest } = res.data;
        let _data = _sortBy(data, ["website_display_order"]),
          _member = [];
        if (params?.loadMore) {
          _member = formValues.members.concat(_data);
        } else {
          _member = _data;
        }
        // setMembers(_member)
        change("members", _member);
        // if (_member.length)
        //   setmemberDisplayOrder(_member.map((data) => data.id))
        setOtherResData(rest);
      })
      .catch((err) => {
        errorAlert(err?.data);
      })
      .finally(() => {
        setMembersLoading(false);
      });
  };

  const fetchMoreData = () => {
    if (otherResData?.current_page !== otherResData?.last_page) {
      let obj = {
        page: otherResData?.current_page + 1,
        loadMore: true,
      };
      getMembers(obj);
    }
  };

  useEffect(() => {
    // getAllMembers('limit=6&visible_on_website=1')
    //   .then(res => {
    //     if (res.data?.data?.length) {
    //       let data = _sortBy(res.data.data, ['website_display_order']);
    //       setMembers(data)
    //     }
    //   })
    //   .catch(err => {
    //     toast.error("Error while fetching member details");
    //   })
    // getMembers({ page: 1 })

    setOfficeDetailsLoading(true);
    getOfficeDetails(organisation_id)
      .then(({ data: { data } }) => {
        const { address, ...other } = data;

        let processedData = {
          address: [getProcessedAddress(address)], // Address converted as array
          ...other,
        };

        setOfficeDetails(processedData);
        updateFormValues(processedData);
      })
      .catch(() => {
        toast.error("Error while fetching office details");
      })
      .finally(() => {
        setOfficeDetailsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const handleLoadMore = () => {
  //   setMembersCount(prevCount => prevCount + 6)
  // }

  const updateFormValues = (processedData) => {
    // console.log('processedData', processedData)
    if (processedData.opening_hours instanceof Array) {
      change("opening_hours", processedData.opening_hours);
    }
    change("page_title", processedData?.page_title);
    change("sub_title", processedData?.sub_title);
    change("keywords", processedData?.keywords);
    change("short_description", processedData?.short_description);
    change("content", processedData?.content);
    change("awards", processedData?.awards);
    change("facebook", processedData?.facebook);
    change("facebook_pixel", processedData?.facebook_pixel);
    change("linkedin", processedData?.linkedin);
    change("instagram", processedData?.instagram);
    change("youtube", processedData?.youtube);
    change("twitter", processedData?.twitter);
    change("gtm", processedData?.gtm);
    change("google_review_url", processedData?.google_review_url);
    change("btn_auctions", processedData?.btn_auctions);
    change("btn_join_us", processedData?.btn_join_us);
    change("btn_notice_to_repair", processedData?.btn_notice_to_repair);
    change("btn_notice_to_vacate", processedData?.btn_notice_to_vacate);
    change(
      "btn_rental_application_form",
      processedData?.btn_rental_application_form
    );
    change("btn_rental_open_home", processedData?.btn_rental_open_home);
    change(
      "btn_request_rental_appraisal",
      processedData?.btn_request_rental_appraisal
    );
    change(
      "btn_request_sale_appraisal",
      processedData?.btn_request_sale_appraisal
    );
    change("btn_sales_open_home", processedData?.btn_sales_open_home);
    change(
      "rental_application_form_url",
      processedData?.rental_application_form_url
    );
    change(
      "btn_book_holiday_accomodation",
      processedData?.btn_book_holiday_accomodation
    );
    change(
      "book_holiday_accomodation_url",
      processedData?.book_holiday_accomodation_url
    );
    change("display_image", processedData?.display_image);
    change("thumbnail_image", processedData?.thumbnail_image);
  };

  const formSubmit = async (formProps) => {
    const { members, ...rest } = formProps;
    // console.log('rest', rest)
    let data = {
      ...rest,
      facebook: formProps.facebook === undefined ? null : formProps.facebook,
      linkedin: formProps.linkedin === undefined ? null : formProps.linkedin,
      instagram: formProps.instagram === undefined ? null : formProps.instagram,
      youtube: formProps.youtube === undefined ? null : formProps.youtube,
      twitter: formProps.twitter === undefined ? null : formProps.twitter,
      gtm: formProps.gtm === undefined ? null : formProps.gtm,
      google_review_url:
        formProps.google_review_url === undefined
          ? null
          : formProps.google_review_url,
      facebook_pixel:
        formProps.facebook_pixel === undefined
          ? null
          : formProps.facebook_pixel,
      member_display_order: members.map((item) => item.id),
      rental_application_form_url: formProps.btn_rental_application_form
        ? formProps.rental_application_form_url
        : null,
    };

    const formData = convertFormData(data);

    formData.append("_method", "PATCH");
    return await updateOfficeDetails(organisation_id, formData)
      .then(({ data: { data } }) => {
        toast.success("Organization Updated Successfully");
      })
      .catch((err) => {
        toast.error("Organization Update Failed");
      });
  };

  return (
    <div>
      <HomePageSetupForm
        formValuesProps={formValues}
        officeDetails={officeDetails}
        handleSubmitCallback={handleSubmit(formSubmit)}
        handleLoadMore={fetchMoreData}
        organisation_id={organisation_id}
        officeDetailsLoading={officeDetailsLoading}
        otherResData={otherResData}
        membersLoading={membersLoading}
        {...props}
      />
    </div>
  );
};

function mapStateToProps(state, props) {
  let processedData = {};
  // if (props?.listingDetails) {
  //   processedData = getListingDetails(props?.listingDetails);
  // }
  const values = getFormValues("homepage_setup")(state);
  return {
    form: "homepage_setup",
    formValues: values,
    initialValues: {
      ...processedData,
      members: [],
    },
    selectedTabData: state?.tab?.selectedTab,
  };
}
export default connect(mapStateToProps)(
  reduxForm({
    enableReinitialize: true,
    destroyOnUnmount: true, //        <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  })(HomepageSetup)
);
