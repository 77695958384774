import React from "react";
import {Grid} from "@material-ui/core";
import MultiSelect from "../../../../partials/form/MultiSelect";
import MicrositeFormLabel from "./MicrositeFormLabel";
import {Field} from "redux-form";
import AsyncMultiSelectCreatable from "../../../../partials/form/AsyncMultiSelectCreatable";

const MicrositeDropdown = ({
                               label,
                               name,
                               value,
                               placeholder,
                               options,
                               disabled,
                               onChange,
                               isMulti = false,
                               isLoading = false,
                               ...rest
                           }) => {
    return (
        <Grid container direction="row" style={{marginBottom: 12}}>
            <Grid item xs={12}>
                <MicrositeFormLabel label={label} disabled={disabled}/>
            </Grid>
            <Grid item xs={12}>
                <Field
                    name={name}
                    placeholder={placeholder}
                    label={false}
                    component={MultiSelect}
                    showDelete={true}
                    isMulti={true}
                    options={options}
                    onChange={(value) => onChange(value, "input", name)}
                    // enableLoading={isLoading}
                />
            </Grid>
        </Grid>
    )
};

export default MicrositeDropdown;
