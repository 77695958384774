import {persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
import {put, takeLatest, delay, call, select} from "redux-saga/effects";
import {getAllListings, getListingById} from "../_crud/listingsCrud";

export const actionTypes = {
  ListingsLoading: "[ListingsLoading]",
  ListingFetched: "[ListingFetched]",
  ListingFetchAsync: "[ListingFetchAsync]",
  ListingsFetched: "[ListingsFetched]",
  ListingCreated: "[ListingCreated]",
  ListingUpdated: "[ListingUpdated]",
  ListingDeleted: "[ListingDeleted]",
  ListingsDeleted: "[ListingsDeleted]",
  ListingsStatusUpdated: "[ListingsStatusUpdated]",
  ListingLoadingIndicator: "ListingsLoadingIndicator",
  ListingFetchMore: "[ListingFetchMore]",
  ListingsTableTitleChange: "[ListingsTableTitleChange]",
  tabChanged: "[tabChanged]",
  SetReloadTrigger: "[Set Reload Trigger]",
};

const initialListingsState = {
  isLoading: false,
  listingLoading: true,
  queryString: "",
  listingsTableTitle: "Current Listings",
  otherResInfo: null,
  entities: null,
  error: null,
  selectedSideTab: "details",
  shouldReload: false,
};

export const reducer = persistReducer(
  {storage, key: "wol-auth", whitelist: ["listings"]},
  (state = initialListingsState, action) => {
    switch (action.type) {
      // case actionTypes.ListingLoading: {
      //     return {
      //         ...state
      //     };
      // }
      case actionTypes.tabChanged: {
        return {
          ...state,
          selectedSideTab: action.payload,
        };
      }
      case actionTypes.ListingLoadingIndicator: {
        const {indicator} = action.payload;
        return {
          ...state,
          isLoading: indicator,
        };
      }
      case actionTypes.SetReloadTrigger: {
        return {
          ...state,
          shouldReload: action.payload,
        };
      }

      case actionTypes.ListingsTableTitleChange: {
        return {
          ...state,
          listingsTableTitle: action.payload,
        };
      }
      case actionTypes.ListingsFetched: {
        const {data, otherResInfo, queryString} = action.payload;
        return {
          ...state,
          entities: data,
          otherResInfo: otherResInfo,
          queryString: queryString,
        };
      }
      case actionTypes.ListingFetched: {
        const {data} = action.payload;
        return {
          ...state,
          profile: data,
        };
      }
      case actionTypes.ListingError: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  tabChanged: (data) => ({type: actionTypes.tabChanged, payload: data}),

  listingsLoading: (data) => ({type: actionTypes.ListingsLoading, data}),
  listingFetchAsync: (data) => ({
    type: actionTypes.ListingFetchAsync,
    payload: data,
  }),
  listingFetchMore: (data) => ({type: actionTypes.ListingFetchMore, data}),
  listingsLoadingIndicator: (data) => ({
    type: actionTypes.ListingLoadingIndicator,
    payload: data,
  }),
  listingsTableTitleChange: (data) => ({
    type: actionTypes.ListingsTableTitleChange,
    payload: data,
  }),
  // socialFeedPostAsync: data => ({ type: actionTypes.SocialFeedPostAsync, payload: data }),
  listingFetched: (data) => ({
    type: actionTypes.ListingFetched,
    payload: {data},
  }),
  listingsFetched: (data) => ({
    type: actionTypes.ListingsFetched,
    payload: data,
  }),
  listingCreated: (data) => ({
    type: actionTypes.ListingCreated,
    payload: {data},
  }),
  listingUpdated: (data) => ({
    type: actionTypes.ListingUpdated,
    payload: {data},
  }),
  listingDeleted: (data) => ({
    type: actionTypes.ListingDeleted,
    payload: {data},
  }),
  listingsDeleted: (data) => ({
    type: actionTypes.ListingsDeleted,
    payload: {data},
  }),
  listingsStatusUpdated: (data) => ({
    type: actionTypes.ListingsStatusUpdated,
    payload: {data},
  }),
  listingError: (error) => ({
    type: actionTypes.ListingError,
    payload: {error},
  }),
  setReloadTrigger: (shouldReload) => ({
    type: actionTypes.SetReloadTrigger,
    payload: shouldReload,
  }),
};

export const getListings = (state) => state.listings;

export function* sagaListings() {
  yield takeLatest(actionTypes.ListingsLoading, function* listingsLoading(
    prams
  ) {
    yield put(actions.listingsLoadingIndicator({indicator: true}));
    let listingsInfo = yield select(getListings);
    let queryData = prams.data
      ? prams.data
      : listingsInfo.queryString
      ? listingsInfo.queryString
      : "";
    const res = yield getAllListings(prams.data ?? "status=1");
    const {data, ...rest} = res.data;

    yield put(
      actions.listingsFetched({
        data: data,
        otherResInfo: rest,
        queryString: queryData,
      })
    );

    yield put(actions.listingsLoadingIndicator({indicator: false}));
  });

  yield takeLatest(actionTypes.ListingFetchAsync, function* listingFetchAsync({
    payload,
  }) {
    const listing = yield getListingById(payload);
    yield delay(2000);
    yield put(actions.listingFetched(listing));
  });

  yield takeLatest(actionTypes.ListingFetchMore, function* listingFetchMore(
    prams
  ) {
    yield put(actions.listingsLoadingIndicator({indicator: true}));
    let listingsInfo = yield select(getListings);
    const res = yield getAllListings(prams.data);
    const {data, ...rest} = {...res.data};
    const newData = listingsInfo.entities.concat(data);
    yield put(actions.listingsFetched({data: newData, otherResInfo: rest}));
    yield put(actions.listingsLoadingIndicator({indicator: false}));
  });
}
