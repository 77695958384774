import React, {useState, createContext, useEffect, useRef} from "react";
import {formToQueryString} from "../../../../_helpers/_utils/utils";
import {getAllProspects} from "../../curd/prospectsCrud";
import {useSelector} from "react-redux";
import _last from "lodash/last";
import {toast} from "react-toastify";
import _isEmpty from "lodash/isEmpty";
const _ = require("lodash");

export const ProspectsContext = createContext({prospects: []});
export const {Consumer} = ProspectsContext;

// const queryString = require("query-string");

const ProspectsContextProvider = (props) => {
  const [prospects, setProspects] = useState([]);
  const [continuousMode, setContinuousMode] = useState(false);
  const [otherResData, setOtherResData] = useState(null);
  const [prospectQuery, setProspectQuery] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [resetFlag, setResetFlag] = useState(false);
  const [sortFilterItems, setSortFilterItems] = useState("");
  const [been_contacted_arr, setBeen_contacted_arr] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const searchFilter = useRef({search_kw: ""});
  const [iSelectedStreet, setISelectedStreet] = useState([]);
  const [iSelectedSuburb, setISelectedSuburb] = useState([]);
  const clearSelectedStreetAndSuburb = () => {
    setISelectedStreet([]);
    setISelectedSuburb([]);
    // setProspectQuery(null);
    // setOtherResData(null);
    // setProspects([]);
  };

  const user = useSelector(({auth}) => auth.user.contact);
  const [paginationData, setPaginationData] = useState({
    currentPage: 1, // Current page
    lastPage: 1, // Total number of pages
    totalItems: 0, // Total number of items
    itemsPerPage: 15, // Number of items per page
    from: 0,
    to: 0,
    // Add more pagination-related data as needed
  });

  useEffect(() => {
    //update();
  }, []);

  useEffect(() => {
    if (continuousMode && been_contacted_arr) {
      fetchMoreData(
        paginationData?.currentPage + 1,
        paginationData?.itemsPerPage
      );
    }
  }, [continuousMode, been_contacted_arr, pageCount]);

  const onSubmitDrawerForm = (values) => {
    setProspectQuery(values);
    let tempObj = {
      ...values,
    };
    tempObj.page = 1;
    tempObj.pageSize = paginationData?.itemsPerPage || 15;

    update(tempObj);
  };

  useEffect(() => {
    if (!isLoading && resetFlag && prospectQuery === null) {
      //update();
      setResetFlag(false);
    }
  }, [resetFlag, prospectQuery]);

  const handleResetFilter = async (reset = false) => {
    // debugger
    if (reset) {
      setProspectQuery(null);
    }
    setOtherResData(null);
    setProspects([]);
    setResetFlag(true);
    setSortFilterItems("");
  };

  const setProspectfilter = (values) => {
    setProspectQuery((prev) => ({...prev, ...values}));
  };

  const updateTable = (values) => {
    let obj = {};

    if (values?.added) {
      obj.page = 1;
      obj.loadMore = false;
    } else {
      if (!values?.delete) {
        obj.merge = true;
      } else {
        // obj.page = 1;
        obj.loadMore = false;
      }
    }

    update({...prospectQuery, ...obj});
  };

  const fetchMoreData = (page = null, pageSize = 15) => {
    let obj = {
      ...prospectQuery,
    };
    if (page != null) {
      obj.page = page;
    } else obj.page = paginationData?.current_page + 1;

    obj.pageSize = pageSize;
    setPaginationData({...paginationData, itemsPerPage: pageSize});
    obj.loadMore = true;
    if (
      otherResData?.current_page !== otherResData?.last_page ||
      page != null
    ) {
      update(obj);
    }
  };
  const handleGlobalSearch = (value) => {
    searchFilter.current.search_kw = value;
    let tempObj = {
      ...value,
    };
    tempObj.page = 1;
    tempObj.pageSize = paginationData?.itemsPerPage || 15;
    update(tempObj);
    // console.log(query);
    // props.buyersLoading(query);
  };

  const update = (values) => {
    if (values?.contact_tags === true) {
      delete values.contact_tags;
    }
    if (values?.property_tags === true) {
      delete values.property_tags;
    }

    let loadMore,
      merge = false;
    setIsLoading(true);
    let obj = {
      sort_by: "street_number",
      ...sortFilterItems,
      direction: "asc",
      // ...prospectQuery,
      ...values,
      limit: values?.pageSize || 15,
      page: values?.page,
      pageSize: values?.pageSize || 15,
    };
    if (searchFilter?.current?.search_kw) {
      obj.search_kw = searchFilter?.current?.search_kw;
    }

    // if (!searchFilter?.current?.search_kw) {
    //   obj.prospectQuery = prospectQuery;
    // }

    if (!obj.suburb && !searchFilter?.current?.search_kw) {
      toast.error("Suburb not selected");
      return;
    }

    if (values?.sorting_options == "22") {
      obj.direction = "desc";
      delete obj["sorting_options"];
    }

    if (values?.merge && otherResData?.current_page) {
      obj.limit = values?.pageSize || 15;
    }

    if (values?.loadMore) {
      loadMore = values?.loadMore;
      delete obj?.loadMore;
    }
    if (values?.merge) {
      merge = obj?.merge;
      delete obj?.merge;
    }

    let query = formToQueryString(obj);

    getAllProspects(query)
      .then((res) => {
        const {data, ...rest} = res.data;

        if (data.length === 0) {
          // alert("No more prospect")
          setIsLoading(false);
          toast.error("No more prospects");
        }

        //UD:: Set the data as it is for testing serverside pagination
        setProspects(data);

        setPaginationData({
          currentPage: res.data.current_page,
          lastPage: res.data.last_page,
          totalItems: res.data.total,
          itemsPerPage: res.data.per_page,
          from: res.data.from,
          to: res.data.to,
        });

        /* if (merge) {
             let prospect_ids = prospects.map((obj) => obj.id);

             let new_data_ids = data.map((obj) => obj.id);

             setProspects([
               ...prospects.map((prosObj) =>
                 new_data_ids.includes(prosObj.id)
                   ? { ...data.find((obj) => obj.id === prosObj.id) }
                   : { ...prosObj }
               ),
               ...data.filter((dataObj) => !prospect_ids.includes(dataObj.id)),
             ]);
           } else if (loadMore) {
             setProspects(prospects.concat(data));
           } else {
             setProspects(data);
             // setIsLoading(false);
           }*/
        setIsLoading(false);
        setOtherResData(rest);
        setResetFlag(false);
      })
      .catch((err) => {
        console.log(err);
        if (err?.status == 504) {
          toast.error("Query took too long. Process terminated.");
        }
        setIsLoading(false);
        setResetFlag(false);
      });

    if (values?.limit) {
      delete obj?.limit;
    }

    setProspectfilter(values);
  };

  const updateProspectData = (values) => {
    setProspects(values);
  };

  return (
    <ProspectsContext.Provider
      value={{
        prospects,
        setProspects,
        updateProspectData,
        continuousMode,
        setContinuousMode,
        onSubmitDrawerForm,
        fetchMoreData,
        otherResData,
        handleGlobalSearch,
        isLoading,
        updateTableData: update,
        updateTable,
        handleResetFilter,
        setResetFlag,
        setProspectfilter,
        prospectQuery,
        setSortFilterItems,
        sortFilterItems,
        setBeen_contacted_arr,
        setPageCount,
        pageCount,
        paginationData,
        iSelectedStreet,
        iSelectedSuburb,
        setISelectedStreet,
        setISelectedSuburb,
        clearSelectedStreetAndSuburb,
      }}
    >
      {props.children}
    </ProspectsContext.Provider>
  );
};

export default ProspectsContextProvider;