import React, { Fragment, useState } from "react";
import { IconButton } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import ContactConnectionsItem from "./ContactConnectionsItem";
import { useDispatch } from "react-redux";
import * as modalRedux from "../../../modules/_modal/_redux/modalRedux";
import * as buyersRedux from "../../../modules/buyers/_redux/buyersRedux";
import DefaultModal from "../../modal/DefaultModal";
import BuyerModal from "../../../modules/buyers/modals/buyerProfileModal/BuyerModal";
import BuyerProfileModal from "../../../modules/buyers/modals/buyerProfileModal/BuyerProfileModal";
import { Popover } from "react-bootstrap";
import PopoverBody from "reactstrap/lib/PopoverBody";
import { OverlayTrigger } from "react-bootstrap";
import { Button } from "react-bootstrap";
import ThemeButton from "../../button/ThemeButton";
import AccessControl from "../../../_ accessControl/AccessControl";
import ProspectProfileModal from "../../../modules/prospect/modals/prospectProfileModal/ProspectProfileModal";

const ContactConnections = ({ profile }) => {
  const [showBuyer, setShowBuyer] = useState(false);
  const [showProspect, setShowProspect] = useState(false);
  const [selectedId, setSelectedId] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);

  const toggle = () => setPopoverOpen(!popoverOpen);

  const openBuyer = (id) => {
    setSelectedId(id);
    setShowBuyer(true);
  };
  const openProspect = (id) => {
    setSelectedId(id);
    setShowProspect(true);
  };
  const popover = (
    <Popover id="popover-basic">
      <Popover.Content>
        <ul>
          <li>Buyer</li>
          <li>Landlord</li>
          <li>Tenant</li>
          <li>Prospect</li>
          <li>Seller</li>
        </ul>{" "}
      </Popover.Content>
    </Popover>
  );
  return (
    <Fragment>
      {showBuyer ? (
        <DefaultModal
          size="xl"
          centered
          show={showBuyer}
          onHide={() => setShowBuyer(false)}
        >
          <BuyerProfileModal
            buyerId={selectedId}
            closeModal={() => setShowBuyer(false)}
          />
        </DefaultModal>
      ) : (
        ""
      )}
      {showProspect ? (
        <DefaultModal
          size="xl"
          centered
          show={showProspect}
          onHide={() => setShowProspect(false)}
        >
          <ProspectProfileModal
            selectedProspect={selectedId}
            closeModal={() => setShowProspect(false)}
          />
        </DefaultModal>
      ) : (
        ""
      )}
      <div className="connections">
        <h6 className="font-weight-bold">
          Connections
          <OverlayTrigger trigger="click" placement="bottom" overlay={popover}>
            {/* <IconButton aria-label="delete" size="small" id="Popover1"> */}
            {/* <AddCircleIcon className="brand-text" fontSize="small" /> */}
            <AccessControl
              allowedPermissions={["contacts-edit"]}
              renderNoAccess={() => ""}
            >
              <ThemeButton icon>
                {" "}
                <i
                  class="fa fa-plus-circle"
                  aria-hidden="true"
                  size="small"
                ></i>
              </ThemeButton>
            </AccessControl>

            {/* </IconButton>{" "} */}
          </OverlayTrigger>
        </h6>

        {profile?.types?.length
          ? profile?.types?.map(function(item, index) {
              return (
                <Fragment>
                  {item.name.includes("Buyer") ? (
                    <div onClick={() => openBuyer(item.buyer_id)}>
                      {" "}
                      <ContactConnectionsItem
                        key={index}
                        type={item.name}
                        connection={item?.name || item?.prospect_address}
                      />{" "}
                    </div>
                  ) : (
                    ""
                  )}
                  {item.name.includes("Tenant") ? (
                    <ContactConnectionsItem
                      key={index}
                      type={item.name}
                      connection={
                        item?.prospect_address || item?.prospect_address
                      }
                    />
                  ) : (
                    ""
                  )}
                  {item.name.includes("Landlord") ? (
                    <ContactConnectionsItem
                      key={index}
                      type={item.name}
                      connection={
                        item?.rental_address || item?.rental_address
                      }
                    />
                  ) : (
                    ""
                  )}
                  {item.name.includes("Seller") ? (
                    <ContactConnectionsItem
                      key={index}
                      type={item.name}
                      connection={
                        item?.listing_address || item?.prospect_address
                      }
                    />
                  ) : (
                    ""
                  )}
                  {item.name.includes("Prospect") ? (
                    <div onClick={() => openProspect(item.prospect_id)}>
                    <ContactConnectionsItem
                      key={index}
                      type={item.name}
                      connection={
                        item?.prospect_address || item?.prospect_address
                      }
                    />
                    </div>
                  ) : (
                    ""
                  )}
                  {item.name.includes("Servicer") ? (
                    <ContactConnectionsItem
                      key={index}
                      type={item.name}
                      connection={
                        item?.prospect_address || item?.prospect_address
                      }
                    />
                  ) : (
                    ""
                  )}
                </Fragment>
              );
            })
          : ""}
      </div>
    </Fragment>
  );
};

export default ContactConnections;
