import React from "react";
import { Form } from "react-bootstrap";

const SelectOptions = (props) => {
  const { input, options, placeholder } = props;
  return (
    <Form.Control
      size="sm"
      as="select"
      className="mr-sm-2"
      id="inlineFormCustomSelect"
      custom
      {...input}
      onBlur={() => input.onBlur(input.value)}
      value={input.value ? input.value : null}
    >
      {placeholder ? <option>{placeholder}</option> : ""}

      {options?.map((option, i) => (
        <option key={i} value={option.value}>
          {option.label}
        </option>
      ))}
    </Form.Control>
  );
};

SelectOptions.defaultProps = {
  placeholder: "Please Select...",
};
export default SelectOptions;
