import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { getUserByToken } from "../curd/auth.crud";
// import { getUserByToken } from "../../../crud/auth.crud";
import * as routerHelpers from "../../../router/RouterHelpers";
const _ = require("lodash");
export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
};

const initialAuthState = {
  user: undefined,
  authToken: undefined,
};

export const reducer = persistReducer(
  { storage, key: "demo2-auth", whitelist: ["user", "authToken"] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { authToken } = action.payload;

        return { authToken, user: undefined };
      }

      case actionTypes.Register: {
        const { authToken } = action.payload;

        return { authToken, user: undefined };
      }

      case actionTypes.Logout: {
        routerHelpers.forgotLastLocation();
        return initialAuthState;
      }

      case actionTypes.UserLoaded: {
        const { user } = action.payload;

        return { ...state, user };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: (authToken) => ({ type: actionTypes.Login, payload: { authToken } }),
  register: (authToken) => ({
    type: actionTypes.Register,
    payload: { authToken },
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: (user) => ({
    type: actionTypes.UserRequested,
    payload: { user },
  }),
  fulfillUser: (user) => ({ type: actionTypes.UserLoaded, payload: { user } }),
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    const urlParameter = new URL(window.location.href).searchParams;

    const { data } = yield getUserByToken(); // Take only data From the request.

    let permission_array = data.data?.roles?.map((obj) => obj?.permissions);
    // alert();
    let flat_permissions = _.flattenDepth(permission_array, 2);

    let unique = flat_permissions.filter(
      (permission, index, array) =>
        array.findIndex((t) => t.id == permission.id) == index
    );

    let obj = {
      ...data.data,
      permissions: unique,
    };

    if (urlParameter.has("page")) {
      setTimeout(() => {
        window.location.href = `https://wiseberryonline.com.au/${urlParameter.get(
          "page"
        )}`;
      }, 2000);
    }

    yield put(actions.fulfillUser(obj));
  });

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser());
  });

  // Get logged in users information
  yield takeLatest(actionTypes.UserRequested, function* userRequested({
    payload,
  }) {
    const { data } = yield getUserByToken(); // Take only data From the request.
    let permission_array = data.data.roles?.map((obj) => obj?.permissions);
    // alert();
    let flat_permissions = _.flattenDepth(permission_array, 2);

    let unique = flat_permissions.filter(
      (permission, index, array) =>
        array.findIndex((t) => t.id == permission.id) == index
    );

    let obj = {
      ...data.data,
      permissions: unique,
    };
    yield put(actions.fulfillUser(obj));

    if (payload?.user?.reload) {
      window.location.reload();
    }
  });
}
