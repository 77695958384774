import { toast } from "react-toastify";

export function removeCSSClass(ele, cls) {
  const reg = new RegExp("(\\s|^)" + cls + "(\\s|$)");
  ele.className = ele.className.replace(reg, " ");
}

export function addCSSClass(ele, cls) {
  ele.classList.add(cls);
}

export const toAbsoluteUrl = pathname => process.env.PUBLIC_URL + pathname;

export function setupAxios(axios, store) {
  axios.interceptors.request.use(
    config => {
      const {
        auth: { authToken }
      } = store.getState();

      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }

      // config.headers['Access-Control-Allow-Origin'] = '*';
      // config.headers['Access-Control-Allow-Credentials'] = 'true';
      // config.headers['Access-Control-Allow-Methods'] = 'POST, PUT, GET, OPTIONS, DELETE';
      // config.headers['Access-Control-Allow-Headers'] = 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With,observe';
      // config.headers['Content-Type'] = 'application/json';
      // config.header("Access-Control-Allow-Headers", "Origin, X-Requested-With, Content-Type");
      //       console.log(axios)
      //       console.log(store)
      // console.log(config)
      return config;
    },
    err => {
      Promise.reject(err);
    }
  );

  // Add a response interceptor

  // Add a response interceptor
  axios.interceptors.response.use(
    function(response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    function(error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      const { status } = error.response || {};

      if (status === 401) {
        window.location.href = "/logout";
        toast.error("You are not Authorized");
      }

      return Promise.reject(error.response);
    }
  );

  // axios.interceptors.response.use(undefined,error => {
  // console.log(error.response)
  //   const {status} = error.response;
  //   if(status ===401)
  //   {
  //     window.location.href = '/logout'
  //   }

  // });
  // axios.interceptors.request.use('*', cors());
}

/*  removeStorage: removes a key from localStorage and its sibling expiracy key
    params:
        key <string>     : localStorage key to remove
    returns:
        <boolean> : telling if operation succeeded
 */
export function removeStorage(key) {
  try {
    localStorage.setItem(key, "");
    localStorage.setItem(key + "_expiresIn", "");
  } catch (e) {
    // console.log(
    //   "removeStorage: Error removing key [" +
    //     key +
    //     "] from localStorage: " +
    //     JSON.stringify(e)
    // );
    return false;
  }
  return true;
}

/*  getStorage: retrieves a key from localStorage previously set with setStorage().
    params:
        key <string> : localStorage key
    returns:
        <string> : value of localStorage key
        null : in case of expired key or failure
 */
export function getStorage(key) {
  const now = Date.now(); //epoch time, lets deal only with integer
  // set expiration for storage
  let expiresIn = localStorage.getItem(key + "_expiresIn");
  if (expiresIn === undefined || expiresIn === null) {
    expiresIn = 0;
  }

  expiresIn = Math.abs(expiresIn);
  if (expiresIn < now) {
    // Expired
    removeStorage(key);
    return null;
  } else {
    try {
      const value = localStorage.getItem(key);
      return value;
    } catch (e) {
      // console.log(
      //   "getStorage: Error reading key [" +
      //     key +
      //     "] from localStorage: " +
      //     JSON.stringify(e)
      // );
      return null;
    }
  }
}
/*  setStorage: writes a key into localStorage setting a expire time
    params:
        key <string>     : localStorage key
        value <string>   : localStorage value
        expires <number> : number of seconds from now to expire the key
    returns:
        <boolean> : telling if operation succeeded
 */
export function setStorage(key, value, expires) {
  if (expires === undefined || expires === null) {
    expires = 24 * 60 * 60; // default: seconds for 1 day
  }

  const now = Date.now(); //millisecs since epoch time, lets deal only with integer
  const schedule = now + expires * 1000;
  try {
    localStorage.setItem(key, value);
    localStorage.setItem(key + "_expiresIn", schedule);
  } catch (e) {
    // console.log(
    //   "setStorage: Error setting key [" +
    //     key +
    //     "] in localStorage: " +
    //     JSON.stringify(e)
    // );
    return false;
  }
  return true;
}
