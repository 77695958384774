import React, { Fragment } from "react";

const Profession = ({ profile }) => {
  return (
    <Fragment>
      <h6 className="font-weight-bold">Profession</h6>
      <div className="d-flex flex-wrap bd-highlight pb-3">
      {profile?.profession ? <div className="label-brand">{profile?.profession}</div> : ''}
        
      </div>
    </Fragment>
  );
};

export default Profession;
