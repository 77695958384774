import React, { useState } from "react";
import { Field, FieldArray } from "redux-form";
import ThemeButton from "../../../../partials/button/ThemeButton";

import Address from "../../../../partials/form/Address";
import PropertyFullAddress from "../../../../partials/form/PropertyFullAddress";
import ToggleIcon from "../../../../partials/form/toggleIcon/ToggleIcon";



const NewListingAddress = ({ handleSubmit, changeProp, address }) => {
  const [addressType, setAddressType] = useState(1); // 0 - Manual, 1 - Autocomplete
  
  const handleAddressType = () => {
    if (addressType) {
      // Going to change for Manual, so clear domain address value
      changeProp("domain_address", {});
    }
    setAddressType(!addressType);
  };
  
  return (
    <form onSubmit={handleSubmit}>
      {addressType ? (
        <PropertyFullAddress changeProp={changeProp} name={"domain_address"} defaultAddress={address} />
      ) : (
        <div className="d-flex">
          <div className="container-fluid">
            <FieldArray
              name="address"
              component={Address}
              label=""
              singleData={true}
            />
          </div>
          <Field
            name="show_full_address"
            icon={<i className="fas fa-eye    "></i>}
            checkedIcon={<i className="fas fa-eye green-color"></i>}
            component={ToggleIcon}
          />
        </div>
      )}
      <div class="mt-2 d-flex justify-content-end">
        <ThemeButton
          onClick={handleAddressType}
          className="address-alternative-text"
        >
          Try with {addressType ? "Manual" : "Google"} Address
        </ThemeButton>
        {/* <div onClick={handleAddressType} className='address-alternative-text'>Try with {addressType ? 'Manual' : 'Google'} Address</div> */}
      </div>
    </form>
  );
};

export default NewListingAddress;
