import React, { useState, createContext, useEffect } from "react";
import {
  convertFormData,
  getObjWithoutValues,
  wbSuccessToast,
  formToQueryString,
} from "../../../../_helpers/_utils/utils";

import { adjustPrice, getListingById } from "../../_crud/listingsCrud";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import * as tabRedux from "../../../_tab/_redux/tabRedux";
import { useLocation } from "react-router-dom";
import { getMenu } from "../../../../GeneralApi/GeneralApi";
import {
  getMarketingPackages,
  postMarketingPackagesByListing,
} from "../../../setting/_crud/settingCrud";
export const SingleListingContext = createContext();
export const { Consumer } = SingleListingContext;

const SingleListingContextProvider = (props) => {
  const location = useLocation();

  const dispatch = useDispatch();
  const [listingId, setListingId] = useState(null);
  const [listingDetails, setListingDetails] = useState(null);
  const [edit, setEdit] = useState(false);
  const [marketing_packages, setMarketing_packages] = useState([]);
  const [marketingPackageSelected, setMarketingPackageSelected] = useState([]);
  const [loading, setLoading] = useState(false);
  const [listingPageLoading, setListingPageLoading] = useState(false);
  const [deletedContact, setDeletedContact] = useState([]);
  const [marketing_package, setMarketing_package] = useState([{}]);
  const [itemFiltered, setItemFiltered] = useState([{}]);
  const [formName, setFormName] = useState(""); 

  const handleEdit = (e) => {
    setEdit(!edit);
  };

  const handleDestroyEdit = () => {
    setEdit(false);
  };

  const updateTabInfo = (values) => {
    let res = location.pathname.replaceAll("/", " ").split(" ");

    if (res.includes("listings") && res.length > 2) {
      if (props?.valueChanged) {
      } else {
        dispatch(
          tabRedux.actions.changeNavTitle({
            name: "SingleListing",
            id: values.id,
            navTitle: values?.property?.address?.full_address,
            valueChanged: true,
          })
        );
      }

      //   // dispatch(tabRedux.actions.callTabManager({name: 'SingleListing' ,id:res[2],navTitle:res[2],props:{}}));
    }
  };
  useEffect(() => {
    let query = "marketing_package";
    getMenu(query)
      .then(({ data: { data } }) => {
        setMarketing_packages(data?.marketing_package);
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    update("fromUseEffect");
  }, []);

  const update = (fromUseEffect) => {
    setListingPageLoading(true);
    if (!fromUseEffect) {
      setEdit(false);
    }
    getListingById(props.id)
      .then(({ data: { data } }) => {
        setListingDetails(data);
        setListingId(data.id);
        updateTabInfo(data);
      })
      .catch(() => {})
      .finally(() => {
        setListingPageLoading(false);
      });
  };

  const adjustListingPrice = (values, sendEmail, sendEmailBuyer, publicPrice, file = null) => {
                console.log(file)
    setLoading(true);
    values.listing = listingId;
    values.sendEmail = sendEmail;
    values.sendEmailBuyer = sendEmailBuyer;
    values.publicPrice = publicPrice;
    const formData = convertFormData(getObjWithoutValues([undefined, null], values));
  
    // Return a promise from this function
    return new Promise((resolve, reject) => {
      adjustPrice(formData)
        .then(({ data: { data } }) => {
          update();
          wbSuccessToast("success", "Price Adjusted Successfully");
          setLoading(false);
          resolve(data); // Resolve the promise with the response data
        })
        .catch((error) => {
          setLoading(false);
          reject(error); // Reject the promise with the error
        });
    });
  };
  

  const UpdateMarketingPackageByListing = (
    listing_id,
    marketing_package_id
  ) => {
    let obj = {
      listing: listing_id,
      marketing_package_id: marketing_package_id,
    };

    let queryString = convertFormData(obj);
    postMarketingPackagesByListing(queryString)
      .then(({ data: { data } }) => {
        getListingById(data.id)
          .then(({ data: { data } }) => {
            setListingDetails(data);
            setListingId(data.id);
            updateTabInfo(data);
            toast.success("New Package Assigned");
          })
          .catch(() => {})
          .finally(() => {
            setListingPageLoading(false);
          });
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (listingDetails && listingDetails.marketing_package !== null) {
      setMarketingPackageSelected(listingDetails.marketing_package);
    }
  }, [listingDetails]);

  useEffect(() => {
    
    let obj = {
      listing: listingDetails?.id,
      marketing_package_id: listingDetails?.marketing_package?.id,
    };

    let queryString = formToQueryString(obj);
    getMarketingPackages(queryString)
      .then(({ data: { data } }) => {
        setMarketing_package(data);
      })
      .catch(() => {});
  }, [listingDetails]);

  useEffect(() => {
    if (marketing_package?.length > 1) {
      const filtered = marketing_package?.filter((packages) => {
        return packages.id === listingDetails?.marketing_package?.id;
      });
      const filteredItems = filtered[0]?.items.filter((item) => {
        return item.status === 1;
      });

      setItemFiltered(filteredItems);
    }
  }, [marketing_package, listingDetails]);

  return (
    <SingleListingContext.Provider
      value={{
        listingDetails,
        marketing_package,
        marketingPackageSelected,
        itemFiltered,
        edit,
        setEdit,
        listingId,
        setListingDetails,
        updateContext: update,
        handleEdit,
        UpdateMarketingPackageByListing: UpdateMarketingPackageByListing,
        handleDestroyEdit,
        adjustListingPrice: adjustListingPrice,
        marketing_packages,
        setLoading,
        loading,
        listingPageLoading,
        setDeletedContact,
        deletedContact,
        formName, 
        setFormName
      }}
    >
      {props.children}
    </SingleListingContext.Provider>
  );
};

export default SingleListingContextProvider;
