import React, { Fragment, useEffect, useState } from "react";
import { Form, Col, Row } from "react-bootstrap";
import { FieldArray, Field } from "redux-form";
import TextInput from "../TextInput";
import NestedChildInfo from "../NestedChildInfo";
import { getMenu } from "../../../GeneralApi/GeneralApi";
import MultiSelect from "../MultiSelect";
import Item from "../../display/Item";

const ContactFormFamilyDetails = ({ fieldArray,number_of_children }) => {
  const [childrenNumber, setChildrenNumber] = useState(0);
  const [options, setOptions] = useState({});
  const [incomeOptions, setIncomeOptions] = useState({});


  useEffect(() => {

    let query = "marital_status,income"
    getMenu(query)
    .then(({ data: { data } }) => {
      console.log(data)
      setOptions(data.marital_status)
      setIncomeOptions(data.income)
    })
    .catch(() => {});
  }, []);

  return (
    <Fragment>
      <h5 className="title">Family Details</h5>

      <Form.Row className="pb-3">
        <Col md={4}>
        <Field
            name={
              fieldArray ? `${fieldArray}.marital_status` : "marital_status"
            }
            component={MultiSelect}
            showBottom={false}
            isMulti={false}
            defaultValue
            options={options}
            placeholder="Please select"
            label="Marital Status"
          />
        </Col>

        <Col md={4}>
        <Field
            name={
              fieldArray ? `${fieldArray}.income` : "income"
            }
            component={MultiSelect}
            showBottom={false}
            isMulti={false}
            defaultValue
            options={incomeOptions}
            placeholder="Select household income range"
            label="Household Income"
          />
        </Col>
        <Col md={4}>
        <Field
              name={fieldArray ? `${fieldArray}.pet_names` : "pet_names"}
                type="text"
                component={TextInput}
                label="Pet Names"
                placeholder="Pet Names"
              />
        </Col>
      </Form.Row>

      <Form.Row className="pb-3">
        <Col md={4}>
        <Field
                name={
                  fieldArray ? `${fieldArray}.number_of_children` : "number_of_children"
                }
                type="number"
                component={TextInput}
                placeholder="Number of Children"
                label="Number of Children"
                // onChange={(evt) => setChildrenNumber(evt.target.value)}
              />
        </Col>
      </Form.Row>
      {/* <input type="text" onBlur={}></input> */}

      {number_of_children > 0  ? (
        <FieldArray
          number={number_of_children}
          name={fieldArray ? `${fieldArray}.children` : "children"}
          label="Child"
          type="text"
          component={NestedChildInfo}
        />
      ) : (
        ""
      )}
    </Fragment>
  );
};
ContactFormFamilyDetails.defaultProps = {
  fieldArray: "",
 };
export default ContactFormFamilyDetails;
