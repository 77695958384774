import React, { Fragment, useEffect, useState } from "react";
import { Accordion, Col, Form, Row } from "react-bootstrap";
import { Field } from "redux-form";

import { getMenu } from "../../../GeneralApi/GeneralApi";
import MultiSelect from "../../form/MultiSelect";
import TextInput from "../../form/TextInput";
import ToggleSingleButton from "../../form/toggleButtons/ToggleSingleButton";
import CustomToggle from "../customToggle/CustomToggle";

const PropertyFeatures = () => {
  const [property_type, setProperty_type] = useState({});
  const [tag, setTag] = useState({});
  const [features_menu_options, setFeatures_menu_options] = useState({});

  useEffect(() => {
    let query = "property_type,tag,feature_menu_option";
    getMenu(query)
      .then(({ data: { data } }) => {
        setTag(data.tag);
        setProperty_type(data.property_type);
        setFeatures_menu_options(data.feature_menu_option);
      })
      .catch(() => {});
  }, []);
  return (
    <Fragment>
      <CustomToggle eventKey="7" rightArrow={true} tabIndex={'-1'}>Property Features</CustomToggle>
      <Accordion.Collapse eventKey="7">
        <Form.Group as={Row}>
        <Col className="pb-1" sm={12}>
            <Field
              name="property_tags"
              component={MultiSelect}
              showBottom={false}
              isMulti={true}
              defaultValue
              options={tag}
              placeholder="Tags"
              label="Tags"
            />
          </Col>
          <Col className="pb-1" sm={12}>
            <Field
              name="property_types"
              placeholder="Property Type"
              label="Property Type"
              component={MultiSelect}
              showBottom={false}
              isMulti={true}
              options={property_type}
            />
          </Col>

          <Col className="pb-1" sm={12}>
            <div className="row">
              <div className="col-4 pr-1">
                <Field
                  label="Bed"
                  name="bed"
                  component={TextInput}
                  placeholder="Bed"
                />
              </div>
              <div className="col-4 pl-0">
                <Field
                  label="Bath"
                  name="bath"
                  component={TextInput}
                  placeholder="Bath"
                />
              </div>
              <div className="col-4 pl-0">
                <Field
                  label="Car"
                  name="car"
                  component={TextInput}
                  placeholder="Car"
                />
              </div>
            </div>
          </Col>
          <Col className="pb-1" sm={12}>
            <div className="row">
              <div className="col-8 pr-1">
                <Field
                  name="land_size_from"
                  component={TextInput}
                  label="Land Size From"
                  placeholder="Land Size From"
                />
              </div>
              <div className="col-4 pl-0">
                <Field
                  label=" "
                  name="feature_menu_option_from_id"
                  component={MultiSelect}
                  showBottom={false}
                  isMulti={false}
                  options={features_menu_options}
                />
              </div>
            </div>
          </Col>
          <Col className="pb-1" sm={12}>
            <div className="row">
              <div className="col-8 pr-1">
                <Field
                  name="land_size_to"
                  component={TextInput}
                  placeholder="Land Size To"
                  label="Land Size To"
                />
              </div>
              <div className="col-4 pl-0">
                <Field
                  label=" "
                  name="feature_menu_option_to_id"
                  component={MultiSelect}
                  showBottom={false}
                  isMulti={false}
                  options={features_menu_options}
                />
              </div>
            </div>
          </Col>
          <Col>
            <div className="d-flex justify-content-between pt-3">
              <div>
                <Field
                  name="land_size_from"
                  className="p-2"
                  component={ToggleSingleButton}
                  label="Ens."
                />
              </div>
              <div>
                <Field
                  name="land_size_from"
                  className="p-2"
                  component={ToggleSingleButton}
                  label="Pool"
                />
              </div>
              <div>
                <Field
                  name="land_size_from"
                  className="p-2"
                  component={ToggleSingleButton}
                  label="AC"
                />
              </div>
              <div>
                <Field
                  name="land_size_from"
                  className="p-2"
                  component={ToggleSingleButton}
                  label="Vac"
                />
              </div>
            </div>
          </Col>
        </Form.Group>
      </Accordion.Collapse>
    </Fragment>
  );
};

export default PropertyFeatures;
