import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { Col } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { Field } from "redux-form";
import MaterialSelect from "../../../../../partials/form-basic/select/MaterialSelect";
import MultiSelect from "../../../../../partials/form/MultiSelect";
import TextInput from "../../../../../partials/form/TextInput";

const PoolFeature = ({ show, member }) => {
  const [showPoolDescription, setShowPoolDescription] = useState("");

  useEffect(() => {
    console.log("refrresh");
  }, [showPoolDescription]);
  return (
    <Fragment>
      <Row>
        <Col lg={1}>Details</Col>
        <Col lg={3}>
          <MaterialSelect
            label=""
            placeholder="Description"
            options={[
              {
                name: "Yes",
                id: "1",
              },
              {
                name: "No",
                id: "2",
              },
            ]}
            value={showPoolDescription}
            onChange={(e) => {
              console.log(e, "multile_select");
              setShowPoolDescription(e);
            }}
          />
        </Col>
        {/* <MultiSelect
          showBottom={false}
          isMulti={false}
          Label="Description"
          onChange={(e) => setShowPoolDescription(e == 1)}
          options={[
            {
              name: "Yes",
              id: "1",
            },
            {
              name: "No",
              id: "2",
            },
          ]}
        /> */}
      </Row>

      {showPoolDescription == 1 && (
        <Row>
          <Col lg={1} className="pr-2">
            Description
          </Col>

          <Col lg={11}>
            <Field component={TextInput} name={`${member}.description`} />
          </Col>
        </Row>
      )}
    </Fragment>
  );
};

export default PoolFeature;
