import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { Badge } from "@material-ui/core";
import RecentActorsIcon from "@material-ui/icons/RecentActors";
import { makeStyles } from "@material-ui/styles";
import { getContactsData, getListingsData } from "../curd/globalSearch";
import { Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import * as modalRedux from "../../_modal/_redux/modalRedux";
import { getAllContacts } from "../../contacts/crud/contactsCrud";
import { formToQueryString } from "../../../_helpers/_utils/utils";
import { toAbsoluteUrl } from "../../../../_metronic";
import DefaultModal from "../../../partials/modal/DefaultModal";
import ContactProfileModal from "../../contacts/modals/contactProfileModal/ContactProfileModal";
const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(2),
    marginRight: theme.spacing(3),
  },
}));

const SearchDropdownContact = ({searchValue}) => {
  const [items, setItems] = useState({});
  const [showContactModal, setShowContactModal] = useState(false);
  const [selectedId, setSelectedId] = useState("");

  useEffect(() => {

    if(searchValue)
    {
      getAllContacts(formToQueryString({ search_kw: searchValue }))
      .then((res) => {
        setItems(res.data.data);
      })
      .catch((e) => {
        // setSuccess(true);
        // setLoading(false);
        // toast.success('Error while saving');
      });
    }

  }, [searchValue]); // here

  const onClickProfile = (e) => {
    e.preventDefault();
    setShowContactModal(true);
    console.log("sselected", e.target.attributes, e.target);
    setSelectedId(e.target.attributes.getNamedItem("contact-profile-id").value);
    console.log(
      "sselected",
      e.target.attributes.getNamedItem("contact-profile-id").value
    );

    // Call ContactProfileModal Dynamically depends on search Result Select.
    //  dispatch(modalRedux.actions.openModal('ContactProfileModal',{ contactProfileId: e.target.attributes.getNamedItem("contact-profile-id").value }))
  };

  return (
    <div className="pb-4">
      {showContactModal ? (
        <DefaultModal
          size="xl"
          centered
          show={showContactModal}
          onHide={() => setShowContactModal(false)}
        >
          <ContactProfileModal
            closeModal={() => setShowContactModal(false)}
            contactList={items}
            contactId={selectedId}
            // contactId=
          />
        </DefaultModal>
      ) : (
        ""
      )}
      {items && items.length ? (
        <div>
          <div className="kt-quick-search__category">
            {" "}
            Contact List{" "}
            <Badge color="secondary" badgeContent={items.length}>
              <RecentActorsIcon />
            </Badge>
          </div>
          {items.map((item, index) => (
            <React.Fragment key={index}>
              <div className="kt-quick-search__item">
                {/* TODO: icons and avatars don't load from array */}

                <div className="kt-quick-search__item-img">
                  <img
                    src={
                      item?.profile_picture
                        ? item?.profile_picture
                        : toAbsoluteUrl("/media/users/default.jpg")
                    }
                    alt=""
                  />
                </div>

                <div className="kt-quick-search__item-wrapper">
                  <a
                    href="#"
                    className="kt-quick-search__item-title"
                    contact-profile-id={item.id}
                    contact-profile-name={item.name}
                    onClick={onClickProfile}
                  >
                    {item?.types?.[0]?.name} {item?.types?.[0]?.name && "-"}{" "}
                    {item.first_name}
                  </a>
                  <div className="kt-quick-search__item-desc">
                    {item?.addresses?.[0]?.full_address}
                  </div>
                  <div className="kt-quick-search__item-desc">
                    {item?.phones[0]?.value}
                  </div>
                  <div className="kt-quick-search__item-desc">{item.desc}</div>
                </div>
              </div>
            </React.Fragment>
          ))}
        </div>
      ) : (
        <div>
          <div className="kt-quick-search__category">
            {" "}
            Contact List{" "}
            <Badge color="secondary" badgeContent={items.length}>
              <RecentActorsIcon />
            </Badge>
          </div>
          <Spinner animation="grow" />
        </div>
      )}
    </div>
  );
};

export default SearchDropdownContact;
