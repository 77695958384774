import React from "react";
import Microsites from "./Microsites";
import MicrositesSettings from "./MicrositesSettings";
import MicrositesContextProvider from "./MicrositesContextProvider";

const MicrositesWrap = ({ settings }) => (
  <MicrositesContextProvider>
    {settings ? <MicrositesSettings /> : <Microsites />}
  </MicrositesContextProvider>
);

export default MicrositesWrap;
