import React, { useEffect, useState } from "react";
import "./propectmobile.css";
import { Button } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row } from "react-bootstrap";
import { toast } from "react-toastify";
import {
  faArrowCircleDown,
  faChevronCircleLeft,
  faChevronCircleRight,
  faSms,
  faArrowCircleUp,
  faPenSquare,
  faUserPlus
} from "@fortawesome/free-solid-svg-icons";
import { PNG_ICONS } from "../../../mail/modals/TemplateCreator/Icons";
import {
  getProcessedAddress,
  getProcessedDomainAddress,
} from "../../../../_helpers/_utils/utils";
import QuickContact from "../../../../partials/form/quickContact/QuickContact";
import ThemeButton from "../../../../partials/button/ThemeButton";
import DefaultModal from "../../../../partials/modal/DefaultModal";
import Appointment from "../../../../modules/diary/partials/modals/appointment/Appointment";
import NoteFormWrapper from "./NoteFormWrapper";
import NoteList from "./MobileNoteList";
import EventIcon from "@material-ui/icons/Event";
// import EditIcon from "@material-ui/icons/EditIcon";
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import {
  getProspectById,
  getNotes,
  getAllProspects,
  updateProspect as updateProspectRequest
} from "../../curd/prospectsCrud";
import { createNotes, updateNotes } from "../../../../GeneralApi/GeneralApi";
import { postDoorKnock } from "../../../buyers/crud/buyersCrud";
import {
  formToQueryString,
  convertFormData,
  wbSuccessToast,
  getObjWithoutValues,
  getProcessedContactInfo,
} from "../../../../_helpers/_utils/utils";
import { Link } from "react-router-dom";
import { Alert, AlertTitle } from "@material-ui/lab";
import MobileNoteRemoteForm from "./MobileNoteRemoteForm";
import _last from "lodash/last";
import LoadingSkeleton from "../../../../partials/display/skeleton/LoadingSkeleton";
import LinearProgressLoading from "../../../../partials/icon/LinearProgressLoading";
import { arrayIncludes } from "@material-ui/pickers/_helpers/utils";
import {
  updateContact,
  createNewContact,
  getContactById,
} from "../../../../modules/contacts/crud/contactsCrud";
import moment from "moment";
import EditContact from "./EditContact"
// import { toAbsoluteUrl } from "../../";

const PropectMobileview = (props) => {
  const {
    changeDirection,
    closeModal,
    mobileProspect,
    profileId,
    prospectLoading,
    setProspectLoading,
    restData,
    setMobileProspect,
    prospectQuery,
    setRestData,
    setProfile = () => {},
    more,
    setMore,
  } = props;
  const [prospect, setProspect] = useState(null);
  const [notes, setNotes] = useState(null);
  const [otherResData, setOtherResData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(null);
  const [showMore, setShowMore] = useState(false);
  const [dirTracker, setDirTracker] = useState(null);
  const [addContact, setShowAddContact] = useState(false);
  const [showAppointment, setShowAppointment] = useState(false);
  const [fillFormData, setFillFormData] = useState({});
  const [editContact, setEditContact] = useState(false);
  const [contactDetails, setContactDetails] = useState(null);
  const [contactUpdate, setContactUpdate] = useState(null);
  const [type, setType] = useState(null);

console.log('========contactUpdate============================');
console.log(contactUpdate);
console.log('==========contactUpdate==========================');

  const [hide, setHide] = useState(0);
  let last = _last(mobileProspect)?.id;

  const update = () => {
    getProspectById(profileId)
      .then(({ data: { data } }) => {
        setProspect(data);
        setProspectLoading(false);
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (last === profileId && restData?.next_page_url === null) {
      setHide(true);
    } else {
      setHide(false);
    }
  }, [last, profileId, restData?.next_page_url]);

  const handleChangeDir = () => {
    changeDirection("right");
    setDirTracker('right')
    setMore((prev) => prev + 1);
    if (mobileProspect.length - 2 === more) {
      fetchMoreData();
    }
  };
  const handleChangeDir2 = () => {
    changeDirection("left");
    setDirTracker('left')
    if (more > 0) {
      setMore((prev) => prev - 1);
    }
  };

  useEffect(() => {
    if (mobileProspect.length - 2 === more) {
      fetchMoreData();
    }
  }, [mobileProspect.length, more]);

  useEffect(() => {
    updateNote();
  }, [profileId]);

  useEffect(() => {
    update();
  }, [profileId]);

  const updateProspect = (value) => {
    setIsLoading(true);

    let obj = {
      ...value,
    };

    const query = formToQueryString(obj);
    getAllProspects(query)
      .then((res) => {
        const { data, ...rest } = res.data;
        if (value?.loadMore) {
          setMobileProspect([...mobileProspect, ...data]);
        }

        setRestData(rest);

        setIsLoading(false);
      })
      .catch(() => {   setIsLoading(false);});
  };

  const fetchMoreData = () => {
    if (restData?.next_page_url) {
      updateProspect({
        page: restData.current_page + 1,
        loadMore: true,
        ...prospectQuery,
      });
    }
  };

  const updateNote = (value) => {
    if (profileId !== null) {
      setIsLoading(true);

      let obj = {
        prospect: profileId,
        limit: 100,
      };

      if (value?.page) {
        obj.page = value.page;
      }

      const query = formToQueryString(obj);
      getNotes(query)
        .then((res) => {
          const { data, ...rest } = res.data;
          if (value?.loadMore) {
            setNotes(notes.concat(data));
          } else {
            setNotes(data);
          }
          setOtherResData(rest);

          setIsLoading(false);
        })
        .catch(() => { setIsLoading(false);});
    }
  };
  const fetchMore = () => {
    if (otherResData?.next_page_url) {
      updateNote({ page: otherResData.current_page + 1, loadMore: true });
    }
  };

  const handleMakePinned = (noteId, isPinned) => {
    const formData = convertFormData({});
    formData.append("pinned", isPinned === 1 ? 0 : 1);
    formData.append("_method", "PATCH");
    if (profileId) {
      updateNotes(noteId, formData)
        .then(({ data: { data } }) => {
          wbSuccessToast("success", data.message);
          updateNote();
        })
        .catch((e) => {});
    }
  };

  const handleOnSubmit = async (e) => {
    let postCall;
    setProspectLoading(true);
    let obj = {};

    if (e.type == "NA" && e.notes == null) {
      obj = {
        prospect: profileId,
        na: 1,
        note: { type: 2, detail: "Na Clicked", prospect: profileId },
      };
      const doorKnockData = convertFormData(obj);
      postCall = await postDoorKnock(doorKnockData)
        .then(({ data: { data } }) => {
          setProspectLoading(false);
          toast.success("Na Saved");
          updateNote();
        })
        .catch((e) => {
          setProspectLoading(false);
          wbSuccessToast("error", "Data Error");
        });

        if(dirTracker != null && dirTracker == 'right'){
          handleChangeDir()
        }else if(dirTracker != null && dirTracker == 'left') {
          handleChangeDir2 ()
        }else{
          changeDirection("right");
       setMore((prev) => prev + 1);
       }


      // changeDirection("right");
      // setMore((prev) => prev + 1);

      document.getElementById("mobile-notes-note").value = "";
    } else {
      if (e.notes == null) {
        setProspectLoading(false);
        // debugger
        toast.error("Note is empty");
      }

      // else if (!prospect?.contacts?.length) {
      //   setProspectLoading(false);
      //   toast.error("No Contact Linked, Please Add a Contact");
      // }
      else {
        if (e.type) {
          // debugger
          if (e.type == "NA") {
            obj = {
              prospect: profileId,
              na: 1,
              note: { type: 2, detail: e.notes[0].detail, prospect: profileId },
            };
          } else if (e.type == "DK") {
            obj = {
              prospect: profileId,
              door_knock: 1,
              note: { type: 4, detail: e.notes[0].detail, prospect: profileId },
            };
          }

          const doorKnockData = convertFormData(obj);
          postCall = await postDoorKnock(doorKnockData)
            .then(({ data: { data } }) => {
              update();
              setProspectLoading(false);
            })
            .catch((e) => {
              setProspectLoading(false);
              wbSuccessToast("error", "Data Error");
            });

            // changeDirection("right");
            // setMore((prev) => prev + 1);
            if(dirTracker != null && dirTracker == 'right'){
              handleChangeDir()
            }else if(dirTracker != null && dirTracker == 'left') {
              handleChangeDir2 ()
            }else{
               changeDirection("right");
            setMore((prev) => prev + 1);
            }
        } else {
          const formData = convertFormData(e.notes[0]);
          formData.append("prospect", profileId);
          if (profileId) {
            postCall = await createNotes(formData)
              .then(({ data: { data } }) => {
                wbSuccessToast("success", data.message);
                setProspectLoading(false);
                update();
                // updateTable();
                updateNote();
              })
              .catch((e) => {
                setProspectLoading(false);
                wbSuccessToast("error", "Data Error");
              });
          }
        }

  // changeDirection("right");
  //       setMore((prev) => prev + 1);



        document.getElementById("mobile-notes-note").value = "";
      }
    }

    return postCall;
  };

  const handleClose = () => {
    closeModal();
    setProspect(null);
    setMore(0);
  };

  const handleMore = ()=>{
    setShowMore(!showMore)
  }



const handleEditContact =(id,index)=>{
if(id){
  getContactById(id).then(({data})=>{

    setContactDetails(data.data)

    setEditContact(true)
  })
}

}


  const handleAddContact = async (e) => {
    setProspectLoading(true);
// debugger
    const { contacts } = e;
    let contact_id;
    let processedContact
    // console.log('====================================');
    // console.log(contactUpdate);
    // console.log('====================================');
    if(contactUpdate == null ){
      processedContact = getProcessedContactInfo(contacts[0]);
    }else{
      processedContact = getProcessedContactInfo(contactUpdate[0]);
    }

    let contactIdarrays= prospect?.contacts.map((contact)=> contact.id)
    let newProfile = prospect?.contacts
    const formData = convertFormData(processedContact);

    const noteDetails = `New Contact Added : First Name: ${
      contacts[0]?.first_name
      } || Last Name: ${contacts[0]?.last_name} ${
        contacts[0]?.phones[0]?.value !== ""
          ? `|| Phone: ${contacts[0]?.phones[0]?.value}`
          : ''
      } ${
        contacts[0]?.emails[0]?.value !== ""
          ? `|| Email: ${contacts[0]?.emails[0]?.value}`
          : ''
      }`;
// debugger
    const noteType = 4;
    if (processedContact?.contact_id) {
      formData.append("_method", "PATCH");
      await updateContact(processedContact?.contact_id, formData)
        .then(({ data: { data } }) => {
          console.log(data);
          contact_id = data.id;
          setContactUpdate(null)
          toast.success("Contact Updated Successfully");

          if (!newProfile?.length) {
            let payload = {
              prospect: profileId,
              detail: noteDetails,
              type: noteType,
            };
            const formData = convertFormData(payload);
            createNotes(formData)
              .then(({ data: { data } }) => {
                wbSuccessToast("success", data.message);
                setProspectLoading(false);
                update();
               
              })
              .catch((e) => {
                setProspectLoading(false);
                wbSuccessToast("error", "Data Error");
              });
          }

          setProspectLoading(false);
        })
        .catch((e) => {
          console.log(e.value, e, "errormesage");
          toast.error("Error While Saving");
          setProspectLoading(false);
        });
    }else{
      await createNewContact(formData,'dk')
      .then(({ data: { data } }) => {
        console.log(data);
        contact_id = data.id;

        let payload = {
          prospect: profileId,
          detail: noteDetails,
          type: noteType,
        }
        const formData = convertFormData(payload);
        createNotes(formData)
          .then(({ data: { data } }) => {
            wbSuccessToast("success", data.message);
            setProspectLoading(false);
           
            // update();
          })
          .catch((e) => {
            setProspectLoading(false);
            wbSuccessToast("error", "Data Error");
          });

        toast.success("Contact Created Successfully");
        setContactUpdate(null)
        setProspectLoading(false);
        setShowAddContact(false)
      })
      .catch((e) => {
        console.log(e.value, e, "errormesage");
        toast.error("Error While Saving");
        setProspectLoading(false);
      });
    }




    let prospectFormValue = {};
    prospectFormValue.contacts = [...contactIdarrays,contact_id];

    let prospectFormatedData = convertFormData(
      getObjWithoutValues([undefined, null], prospectFormValue)
    );

    prospectFormatedData.append("_method", "PATCH");

    updateProspectRequest(profileId, prospectFormatedData)
      .then(({ data }) => {
        console.log(data);
        toast.success("Prospect Updated Successfully");
        update();
        setEditContact(false)
        setShowAddContact(false)
        setContactUpdate(null)
      })
      .catch((e) => {});
  };


  return (
    <>
      {/* <div
        id="kt_header_mobile"
        className="kt-header-mobile kt-header-mobile--fixed"
      >
        <div className="kt-header-mobile__logo">
          <a href="/">
            <img
              alt="logo"
              src="https://v2-wiseberryonline-com-au.s3-ap-southeast-2.amazonaws.com/assets/Mobile-Logo.png"
            />
          </a>
        </div>
      </div> */}
      <div className="cover" style={{ padding: 0 }}>
        {prospectLoading || isLoading ? <LinearProgressLoading /> : ""}
        {/* {prospectLoading?
          <div style={{position:"relative", height:"60vh"}}>
     <div className='vertical-center '>
           <span style={{marginBottom: 15}}><LoadingSkeleton height='10vh' /> </span>
           <LoadingSkeleton height='30vh'  />

          </div>
          </div>
        : */}

        <div className="cover2">
          <div className="btn-container2">
            <Button
              className=" m-2 btnM"
              size="large"
              onClick={handleClose}
              style={{ background: "#3A3A3B", color: "#fff" }}
            >
              Back
            </Button>
            {/* <Button className=" m-2 btnM" size="large">
              NA
            </Button> */}
            {/* <MobileNoteRemoteForm
              className={
                prospectLoading || isLoading
                  ? "disable-prospect-header-btn"
                  : ""
              }
              disabled={prospectLoading || isLoading}
              setNAButtonLoading={setProspectLoading}
              label={"NA"}
              formName="mobile-notes"
              setIsDisabled={setIsDisabled}
            />
            <MobileNoteRemoteForm
              className={
                prospectLoading || isLoading
                  ? "disable-prospect-header-btn"
                  : ""
              }
              disabled={prospectLoading || isLoading || !isDisabled}
              // <NoteRemoteFormButton
              setNAButtonLoading={setProspectLoading}
              label={"DK"}
              formName="mobile-notes"
              setIsDisabled={setIsDisabled}
            /> */}
          </div>
          <div className="header-layout">
            {more !== 0 ? (
              <div className="icon" onClick={() => handleChangeDir2()}>
                <FontAwesomeIcon
                  icon={faChevronCircleLeft}
                  size="3x"
                  className="kt-font-brand"
                />
              </div>
            ) : (
              <div className="icon"> </div>
            )}

            <div className="icon">
              <div style={{ margin: 0, width: "100%", textAlign: "center" }}>
                <p className="streetname">
                  {prospect?.property?.address?.street_unit
                    ? `${prospect?.property?.address?.street_unit} / `
                    : ""}{" "}
                  {prospect?.property?.address?.street_number}{" "}
                  {prospect?.property?.address?.street?.name}
                </p>
                <p className="suburbname">
                  {prospect?.property?.address?.street?.suburb?.name}
                </p>
              </div>
            </div>
            {!hide ? (
              <div className="icon" onClick={() => handleChangeDir()}>
                <FontAwesomeIcon
                  icon={faChevronCircleRight}
                  size="3x"
                  className="kt-font-brand "
                />
              </div>
            ) : (
              <div className="icon"> </div>
            )}
          </div>
          <div
            className="header-layout"
            style={{
              margin: 20,
              width: "35%",
              textAlign: "center",
              flexDirection: "row",
            }}
          >
            {prospect?.property?.features.length > 0 &&
              prospect?.property?.features.map((feature) => (
                <>
                  {feature.name === "Bedroom" && (
                    <div className="features">
                      <span>{feature.qty}</span>{" "}
                      <img
                        src={`${PNG_ICONS.BED}`}
                        width={20}
                        height={20}
                        alt=""
                      />{" "}
                    </div>
                  )}

                  {feature.name === "Bathroom" && (
                    <div className="features">
                      <span>{feature.qty} </span>{" "}
                      <img
                        src={`${PNG_ICONS.BATH}`}
                        width={22}
                        height={22}
                        alt=""
                      />
                    </div>
                  )}
                  {feature.name === "Parking" && (
                    <div className="features">
                      <span>{feature.qty}</span>{" "}
                      <img
                        src={`${PNG_ICONS.PARKING}`}
                        width={22}
                        height={22}
                        alt=""
                      />
                    </div>
                  )}
                </>
              ))}
          </div>
          <div className="appointment">
            <ThemeButton icon onClick={() => setShowAppointment(true)}>
              <EventIcon style={{ width:40, height:40 }} />
            </ThemeButton>
            <ThemeButton icon onClick={() => setShowAddContact(true)}>
        
              <FontAwesomeIcon
                              icon={faUserPlus}
                              // size="1.5x"
                              style={{color: '#414042',width:41, height:45, marginTop:6 }}
                            />
            </ThemeButton>
          </div>

          {editContact ? (
            <DefaultModal
          size="sm"
          centered
          show={editContact}
          onHide={() => setEditContact(false)}
          className={"p-0"}
        >
   {/* <EditContact contactDetails={contactDetails}/> */}
   <QuickContact onSubmit={handleAddContact}  mobile={true} origin='dk'  contactDetails={contactDetails} setContactUpdate={setContactUpdate}/>
    </DefaultModal>
          ):("")}

          {showAppointment ? (
        <DefaultModal
          size="xl"
          centered
          show={showAppointment}
          onHide={() => setShowAppointment(false)}

        >
          <Appointment
            setFillFormData={setFillFormData}
            fillFormData={fillFormData}
            initialValues={{
              starts: moment().format("YYYY-MM-DDTHH:mm"),
              ends: moment().format("YYYY-MM-DDTHH:mm"),
              contacts: [
                prospect?.contacts && prospect?.contacts[0],
              ],
              app_type: 4,

              prospect_address: {
                meeting_location: [
                  getProcessedAddress(prospect?.property?.address),

                ],
                domain_address: getProcessedDomainAddress(
                  prospect?.property?.address
                ),
              },
            }}
            closeModal={() => setShowAppointment(false)}
            mobile={true}
          />{" "}
        </DefaultModal>
      ) : (
        ""
      )}
          {mobileProspect.length ? (
            prospect?.contacts.length ? (
              <>
                <div
                  className="header-layout"
                  style={{
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    position: "relative",

                  }}
                >

                  <span
                    style={{
                      textAlign: "start",
                      marginBottom: 10,
                      fontSize: 18,
                      position: "relative",
                      width: "100%",
                    }}
                  >
                    <span style={{position: "absolute",right:5}}> <FontAwesomeIcon
                              icon={faPenSquare}
                              size="2x"
                              className="kt-font-brand"
                              onClick={()=>handleEditContact(prospect?.primary_contact?.id)}
                            /></span>
                    Name: {prospect?.primary_contact?.first_name}{" "}
                    {prospect?.primary_contact?.last_name}{" "}
                  </span>
                  <div
                    className="Mphonecontainer"
                    style={{
                      textAlign: "start",
                      fontSize: 18,
                      marginBottom: 20,
                    }}
                  >
                    <div className="mphone">Phone: </div>
                    <div className="mphone2">
                      {prospect?.primary_contact?.phones.map((phone) => (
                        <span className="pr-3 smsnphone">
                          <a
                            href={`sms:${phone.value}, ${phone.value}`}
                            className="mr-1"
                          >
                            <FontAwesomeIcon
                              icon={faSms}
                              size="2x"
                              className="kt-font-brand"
                            />
                          </a>{" "}
                          <a href={`tel:${phone.value}`}>{phone.value} </a>
                        </span>
                      ))}
                    </div>
                  </div>
                  {showMore
                    ? prospect?.contacts
                        .filter(
                          (item) => item.id !== prospect?.primary_contact?.id
                        )
                        .map((contact) => (
                          <>
                            <span
                              style={{
                                textAlign: "start",
                                marginBottom: 10,
                                fontSize: 18,
                                position: "relative",
                                width: "100%",
                              }}
                            >
                              <span style={{position: "absolute", right:5}}> <FontAwesomeIcon
                              icon={faPenSquare}
                              size="2x"
                              className="kt-font-brand"
                              onClick={()=>handleEditContact(contact.id)}
                            /></span>
                              Name: {contact?.first_name} {contact?.last_name}{" "}
                            </span>
                            <div
                              className="Mphonecontainer"
                              style={{ textAlign: "start", fontSize: 18 }}
                            >
                              <div className="mphone">Phone: </div>
                              <div
                                className="mphone2"
                                style={{ marginBottom: 20 }}
                              >
                                {contact?.phones.map((phone) => (
                                  <span className="pr-3 smsnphone">
                                    <a
                                      href={`sms:${phone.value}, ${phone.value}`}
                                      className="mr-1"
                                    >
                                      <FontAwesomeIcon
                                        icon={faSms}
                                        size="2x"
                                        className="kt-font-brand"
                                      />
                                    </a>{" "}
                                    <a href={`tel:${phone.value}`}>
                                      {phone.value}{" "}
                                    </a>
                                  </span>
                                ))}
                              </div>
                            </div>
                          </>
                        ))
                    : " "}

                  {prospect?.contacts.length >= 2 && (
                    <div>
                      {!showMore ? (
                        <FontAwesomeIcon
                          icon={faArrowCircleDown}
                          size="2x"
                          className="kt-font-brand"
                          onClick={handleMore}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faArrowCircleUp}
                          size="2x"
                          className="kt-font-brand"
                          onClick={handleMore}
                        />
                      )}
                    </div>
                  )}
                </div>
              </>
            ) : (
              !addContact && (
                <div
                  style={{
                    backgroundColor: "#FEEBF1",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    position: "relative",
                    paddingBottom: "20px",
                  }}
                >
                  {" "}
                  <Alert severity="error">
                    <AlertTitle>
                      <span style={{ fontSize: 18 }}>No Contact Found</span>
                    </AlertTitle>
                  </Alert>
                  <span
                    className="addbtnnew"
                    style={{ position: "absolute" }}
                    onClick={() => setShowAddContact(true)}
                  >
                    Add Contact
                  </span>
                </div>
              )
            )
          ) : (
            <Alert severity="error">
              <AlertTitle>
                <span style={{ fontSize: 18 }}>No Prospects Found</span>
              </AlertTitle>
            </Alert>
          )}

          {addContact ? (
            <>
              <div onClick={() => setShowAddContact(false)}>
                {" "}
                <i className=" fa fa-solid fa-eye-slash hidebtn"></i>{" "}
              </div>
              <QuickContact onSubmit={handleAddContact}  mobile={true} origin='dk' setType={setType}/>
            </>
          ) : (
            ""
          )}

          {mobileProspect.length ? (
            <>
              <div
                className="header-layout"
                style={{ flexDirection: "column" }}
              >
                <NoteFormWrapper
                   prospectLoading={prospectLoading}
                   setProspectLoading={setProspectLoading}
                   isLoading={isLoading}
                  contact={prospect?.contact}
                  profileId={profileId}
                  onSubmit={handleOnSubmit}
                  setIsDisabled={setIsDisabled}
                  isDisabled={isDisabled}
                />
              </div>

              <Row></Row>
              <div
                className="header-layout"
                style={{ flexDirection: "column" }}
              >
                <Row></Row>
                <NoteList
                  makePinned={handleMakePinned}
                  fetchMore={fetchMore}
                  notes={notes}
                />
              </div>
            </>
          ) : (
            " "
          )}
        </div>
      </div>
    </>
  );
};

export default PropectMobileview;
