import axios from "axios";
const WiseBerryAPIEnv = process.env.REACT_APP_WISEBERRY_API;
export const PROSPECT_URL = WiseBerryAPIEnv + "prospect";

let ajaxRequest = null;
export const ZONEANALYSIS = WiseBerryAPIEnv + "zone";
export const SIGN = WiseBerryAPIEnv + "sign";

// Address Management

export function getOrganisationSuburbs(prams) {
  return axios.get(
    WiseBerryAPIEnv + "organisation/suburbs" + (prams ? "?" + prams : "")
  );
}

export function deleteOrganisationSuburbs(props) {
  return axios.delete(
    WiseBerryAPIEnv + "organisation/suburbs/" + props ? props : ""
  );
}

export function addOrganisationSuburb(prams) {
  return axios.post(WiseBerryAPIEnv + "organisation/suburbs", prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function getStreetsBySuburb(props) {
  return axios.get(WiseBerryAPIEnv + `street?` + (props ? props : ""));
}

// export function bulkDeleteOrganisationSuburb(prams) {
//   return axios.post(WiseBerryAPIEnv + 'organisation/suburbs/delete', prams, {
//     headers: {
//       'Content-Type': 'application/json'
//     },

//   })
// }

export function bulkDeleteSuburbs(prams) {
  return axios.post(WiseBerryAPIEnv + "organisation/suburbs/delete", prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function bulkDeleteStreet(prams) {
  return axios.post(WiseBerryAPIEnv + "street/bulk-delete", prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function bulkDeleteStreetNo(prams) {
  return axios.post(WiseBerryAPIEnv + "address/bulk-delete", prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function getStreetNumbersByStreet(id) {
  // return axios.get(`/street?suburb=${id}`);;
}
export function createNewStreet(prams) {
  return axios.post(WiseBerryAPIEnv + "street", prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function getAllAddress(prams) {
  return axios.get(
    WiseBerryAPIEnv + "address?" + (prams != null ? "&" + prams : "")
  );
}

export function createNewAddress(prams) {
  return axios.post(WiseBerryAPIEnv + "address", prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

// Address Management

export const CONTACTS_URL = WiseBerryAPIEnv + "prospect";

export function createProspect(prams) {
  return axios.post(PROSPECT_URL, prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

// Prospect Settings

export function updateProspectSettings(prams) {
  return axios.post(WiseBerryAPIEnv + "organisation/prospect-setting", prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function getProspectSettings() {
  return axios.get(WiseBerryAPIEnv + "organisation/prospect-setting");
}

// Prospect

// READ
export function getAllProspects(prams) {
  // cancel  previous ajax if exists
  if (ajaxRequest) {
    ajaxRequest.cancel();
  }

  // creates a new token for upcomming ajax (overwrite the previous one)
  ajaxRequest = axios.CancelToken.source();

  return axios.get(PROSPECT_URL + (prams ? "?" + prams : ""), {
    cancelToken: ajaxRequest.token,
  });

  // return axios.get(PROSPECT_URL  + (prams ? ("?" + prams) : ''));
}
export function getProspectById(prospectId) {
  return axios.get(PROSPECT_URL + `/${prospectId}`);
  //   const prospect = JSON.stringify(contacs.data.filter(prospect => prospect.id === prospectId));
  // return axios.get(`${CONTACTS_URL}/${prospectId}`);
}
export function getNotes(prams) {
  return axios.get(PROSPECT_URL + `/notes` + (prams ? "?" + prams : ""));
  //   const prospect = JSON.stringify(contacs.data.filter(prospect => prospect.id === prospectId));
  // return axios.get(`${CONTACTS_URL}/${prospectId}`);
}

export function createProspectNotes(prospectId, prams) {
  return axios.post(WiseBerryAPIEnv + `note?prospect=${prospectId}`, prams, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function getTimelineByProspectId(prams) {
  return axios.get(WiseBerryAPIEnv + "timeline?" + prams);
}

export function getProspectContactById(prospectId) {
  return axios.get(PROSPECT_URL + `/contacts?prospect=/${prospectId}`);
  //   const prospect = JSON.stringify(contacs.data.filter(prospect => prospect.id === prospectId));
  // return axios.get(`${CONTACTS_URL}/${prospectId}`);
}

// Mail
// READ
export function getAllMail() {
  // return axios.get(CONTACTS_URL);
  const mail = axios.get("/mail");
  return mail;
}

export function getMailById(mailId) {
  const mail = axios.get("/mail/34", { params: { searchText: "John" } });
  //   const prospect = JSON.stringify(contacs.data.filter(prospect => prospect.id === prospectId));
  // console.log(mail);
  return mail;
  // return axios.get(`${CONTACTS_URL}/${prospectId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findProspects(queryParams) {
  return axios.post(`${CONTACTS_URL}/find`, { queryParams });
}

// UPDATE => PUT: update the prospect on the server
export function updateProspect(id, prams, contentType = "multipart/form-data") {
  return axios.post(`${PROSPECT_URL}/${id}`, prams, {
    headers: {
      "Content-Type": contentType,
      // "Content-Type": "multipart/form-data",
    },
  });
}

export function viewProspect(params) {
  return axios.get(WiseBerryAPIEnv + "apm/get-surrounding-properties", {
    params,
  });
}

export function viewProspectData(params) {
  return axios.get(WiseBerryAPIEnv + "apm/comparables-extended", { params });
}

// UPDATE Status
export function updateStatusForProspects(ids, status) {
  return axios.post(`${CONTACTS_URL}/updateStatusForProspects`, {
    ids,
    status,
  });
}

// DELETE => delete the prospect from the server
export function deleteProspect(prospectId) {
  return axios.delete(`${CONTACTS_URL}/${prospectId}`);
}

// DELETE Prospects by ids
export function deleteProspects(ids) {
  return axios.post(`${CONTACTS_URL}/deleteProspects`, { ids });
}

// Prospect Details
// READ
export function getModalProspectDetails(prospectId) {
  return axios.get("/prospect/modal/details", {
    params: { prospectId: prospectId },
  });
}

// Prospect Details
// READ
//http://127.0.0.1:8004/v1/prospect/matched-buyers?prospect=458756&match_by=price
export function getProspectMatchedData(params) {
  return axios.get(
    PROSPECT_URL + `/matched-buyers` + (params ? "?" + params : "")
  );
  // return axios.get(PROSPECT_URL + `/matched-buyers?prospect=${prospectId}&match_by=${params}`);

  // return axios.get('/prospect/modal/matchedInfo', { params: { prospectId: prospectId }})
}

export function getProspectTimeline(prospectId) {
  // console.log(prospectId);
  // console.log(axios.get("/contacts/timeline"));
  return axios.get("/contacts/timeline");
  // return axios.get('/prospect/modal/matchedInfo', { params: { prospectId: prospectId }})
}

export function getAddressManagement(queryParams) {
  // console.log(queryParams);
  // console.log(axios.get('/addressList', { queryParams }));
  return axios.get("/addressList", { params: queryParams });
  // return axios.get('/prospect/modal/matchedInfo', { params: { prospectId: prospectId }})
}

export function getZoneAnalysisData(queryParams) {
  // console.log(queryParams);
  // console.log(axios.get('/addressList', { queryParams }));
  return axios.get("/zoneAnalysis", { params: queryParams });
  // return axios.get('/prospect/modal/matchedInfo', { params: { prospectId: prospectId }})
}

export function getZoneAnalysisEdit(queryParams) {
  // console.log(queryParams);
  // console.log(axios.get('/addressList', { queryParams }));
  return axios.get("/zoneAnalysis/edit", { params: queryParams });
  // return axios.get('/prospect/modal/matchedInfo', { params: { prospectId: prospectId }})
}

export function findProspectByAddress(prams) {
  return axios.get(PROSPECT_URL + "/find-by-address?" + prams);
}

// zone Analysis
export function getZoneAnalysis(prams) {
  return axios.get(ZONEANALYSIS + (prams ? "?" + prams : ""));
}
export function getZoneAnalysisById(id) {
  return axios.get(ZONEANALYSIS + "/" + id);
}
export function postZoneAnalysis(prams) {
  return axios.post(ZONEANALYSIS, prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}
export function getZoneAnalysisSalesStreets(prams) {
  return axios.get(`${ZONEANALYSIS}/streets` + (prams ? "?" + prams : ""), {
    headers: {
      "Content-Type": "application/json",
    },
  });
}
export function deleteZoneAnalysisSalesStreets(prams) {
  return axios.post(`${ZONEANALYSIS}/remove-streets`, prams);
}
export function updateZoneAnalysisSalesStreets(id, params) {
  return axios.post(`${ZONEANALYSIS}/${id}`, params);
}

export function updateZoneAnalysis(id, prams) {
  return axios.post(ZONEANALYSIS + "/" + id, prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function deleteZone(zoneId) {
  return axios.delete(`${ZONEANALYSIS}/${zoneId}`);
}
// Zone Analysis

// SIGN
export function getSign(prams) {
  return axios.get(SIGN + (prams ? "?" + prams : ""));
}
export function getSignById(id) {
  return axios.get(SIGN + "/" + id);
}
export function postSign(prams) {
  return axios.post(SIGN, prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}
export function updateSign(id, prams) {
  return axios.post(SIGN + "/" + id, prams, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function bulkDeleteSign(prams) {
  return axios.post(PROSPECT_URL + "/bulkupdate", prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}
// SIGN

export function bulkDeleteProspect(prams) {
  return axios.post(PROSPECT_URL + "/bulk-delete", prams, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function prospectMerge(prams) {
  return axios.post(PROSPECT_URL + "/merge", prams, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
