import React, { Fragment } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { Field } from "redux-form";
import AccessControl from "../../../_ accessControl/AccessControl";
import { downloadImageFromUrl } from "../../../_helpers/_utils/utils";
import ThemeButton from "../../button/ThemeButton";
import { CharacterCircle } from "../../component/characterCircle/CharacterCircle";
import RadioInput from "../RadioInput";
import TextInput from "../TextInput";

const ImagePreview = ({
  file,
  index,
  fields,
  imageInfo,
  restrictDefaultStyle,
  images,
  removeImage,
  selectedImage,
  changeTitle,
  uploadType,
  deletePermissions,
  downLoadPermissions,
  isProfileVideo,
  overWriteStyles = {},
  selectedItems = [],
}) => {
  // const thumbsContainer = {
  //   display: "flex",
  //   flexDirection: "row",
  //   flexWrap: "wrap",
  //   marginTop: 16,
  // };

  const thumb = {
    display: "inline-flex",
    borderRadius: 2,
    border: "1px solid #eaeaea",
    marginBottom: 8,

    width: 350,
    height: 350,
    padding: 4,
    boxSizing: "border-box",
    position: "relative",
  };
  const box = {};
  const thumbInner = {
    display: "flex",
    minWidth: 0,
    overflow: "hidden",
    width: "100%",
  };

  const img = {
    display: "block",
    width: "100%",
    height: "100%",
  };

  const imagePopover = (
    <Popover id="popover-basic">
      {/* <Popover.Title as="h3">Image Tags</Popover.Title> */}
      <Popover.Content>
        <Fragment>
          <div className="d-flex pb-2">
            <Field
              name={`${file}.website`}
              type="checkbox"
              component={RadioInput}
            />
            <div className="pl-3">Wiseberry Website</div>
          </div>
          <div className="d-flex pb-2">
            <Field
              name={`${file}.property_portals`}
              type="checkbox"
              component={RadioInput}
            />
            <div className="pl-3">Property Portals</div>
          </div>
          <div className="d-flex pb-2">
            <Field
              name={`${file}.floorplan`}
              type="checkbox"
              component={RadioInput}
            />
            <div className="pl-3">Floor Plan</div>
          </div>
        </Fragment>
      </Popover.Content>
    </Popover>
  );

  const handleDownloadVideo = (imageInfo) => {
    if (imageInfo?.url) {
      downloadImageFromUrl(imageInfo?.url);
    } else {
      let blob = new Blob([imageInfo], { type: "video/mp4" });
      var reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = function() {
        var base64String = reader.result;
        var a = document.createElement("a");
        a.href = base64String;
        a.download = imageInfo.name;
        a.click();
        window.URL.revokeObjectURL(base64String);
      };
    }
  };

  return (
    <Fragment>
      <div style={box} className="p-2">
        <div
          style={restrictDefaultStyle ? {} : { ...thumb, ...overWriteStyles }}
          key={imageInfo.name}
        >
          <div style={thumbInner}>
            <div
              className={`position-absolute d-flex flex-column image-preview ${
                restrictDefaultStyle ? "" : "w-100 h-100"
              }`}
            >
              {isProfileVideo ? null : (
                <div className="d-flex justify-content-between p-3 image-preview-head ">
                  <div>
                    <CharacterCircle small character={index + 1} />
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      // onBlur={() => selectedImage(index)}
                      onChange={() => selectedImage(index)}
                      value={imageInfo.id}
                      name="selectedImage"
                      checked={selectedItems.includes(
                        (imageInfo?.id).toString()
                      )}
                    />
                  </div>
                </div>
              )}

              {uploadType === "image" ? (
                <div className={"d-flex m-auto image-preview-body"}>
                  <OverlayTrigger
                    trigger="click"
                    placement="right"
                    overlay={imagePopover}
                    rootClose
                    rootCloseEvent="mousedown"
                  >
                    <ThemeButton icon>
                      <i className="fas fa-tags"></i>
                    </ThemeButton>
                  </OverlayTrigger>

                  {imageInfo?.url && (
                    <ThemeButton
                      className="video-preview-buttons"
                      icon
                      onClick={() =>
                        imageInfo?.url && downloadImageFromUrl(imageInfo.url)
                      }
                    >
                      <i className="fas fa-download"></i>
                    </ThemeButton>
                  )}

                  <ThemeButton
                    className="video-preview-buttons"
                    icon
                    onClick={() => fields.remove(index)}
                    style={{ position: "relative", zIndex: "1" }}
                  >
                    <i className="fas fa-trash    "></i>
                  </ThemeButton>
                </div>
              ) : (
                ""
              )}
              {uploadType === "video" ? (
                <div className={"d-flex m-auto  "}>
                  {isProfileVideo ? null : (
                    <OverlayTrigger
                      trigger="click"
                      placement="right"
                      overlay={imagePopover}
                      rootClose
                      rootCloseEvent="mousedown"
                    >
                      <ThemeButton className="video-preview-buttons" icon>
                        <i className="fas fa-tags    "></i>
                      </ThemeButton>
                    </OverlayTrigger>
                  )}
                  {downLoadPermissions ? (
                    <AccessControl
                      allowedPermissions={downLoadPermissions}
                      renderNoAccess={() => ""}
                    >
                      <ThemeButton
                        className="video-preview-buttons"
                        icon
                        onClick={() => handleDownloadVideo(imageInfo)}
                      >
                        <i className="fas fa-download    "></i>
                      </ThemeButton>
                    </AccessControl>
                  ) : !isProfileVideo ? (
                    <ThemeButton
                      className="video-preview-buttons"
                      icon
                      onClick={() =>
                        imageInfo?.url && downloadImageFromUrl(imageInfo?.url)
                      }
                    >
                      <i className="fas fa-download    "></i>
                    </ThemeButton>
                  ) : null}

                  {deletePermissions ? (
                    <AccessControl
                      allowedPermissions={deletePermissions}
                      renderNoAccess={() => ""}
                    >
                      <ThemeButton
                        className="video-preview-buttons"
                        icon
                        onClick={() => fields.remove(index)}
                      >
                        <i className="fas fa-trash    "></i>
                      </ThemeButton>
                    </AccessControl>
                  ) : !isProfileVideo ? (
                    <ThemeButton
                      className="video-preview-buttons"
                      icon
                      onClick={() => fields.remove(index)}
                    >
                      <i className="fas fa-trash    "></i>
                    </ThemeButton>
                  ) : null}
                </div>
              ) : (
                ""
              )}
            </div>

            {uploadType === "image" ? (
              <img
                src={imageInfo?.preview ? imageInfo?.preview : imageInfo?.url}
                style={img}
                alt="_image"
              />
            ) : (
              ""
            )}
            {uploadType === "video" ? (
              imageInfo.isUrl ? (
                <div className="videoWrapper w-100">
                  <iframe
                    title={imageInfo.id}
                    // width="420"
                    // height="345"
                    src={imageInfo.preview}
                  />
                </div>
              ) : (
                <video controls className="w-100">
                  <source src={imageInfo.preview || imageInfo.url} />
                  Your browser does not support HTML5 video.
                </video>
              )
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="form-group">
          {/* <input
            onChange={(e) => changeTitle(e.target.value, index)}
            type="text"
            id={imageInfo.id}
            defaultValue={imageInfo.title}
            name="title"
            className="form-control"
          /> */}
          {uploadType === "video" && !isProfileVideo ? (
            <Field
              name={`${file}.title`}
              type="text"
              component={TextInput}
              label="Title"
            />
          ) : null}
        </div>
      </div>
    </Fragment>
  );
};

export default ImagePreview;

// const thumbs = images?.map((image, index) => (
//   <Fragment>
//     <div style={box} className="p-2">
//       <div style={thumb} key={image.name}>
//         <div style={thumbInner}>
//           <div className="position-absolute d-flex flex-column w-100 h-100 image-preview">
//             <div className="d-flex justify-content-between p-3 image-preview-head ">
//               <div>
//                 <CharacterCircle small character={index} />
//               </div>
//               <div>
//                 <input
//                   type="checkbox"
//                   onBlur={() => selectedImage(index)}
//                   value={image.id}
//                   name="selectedImage"
//                 />
//               </div>
//             </div>

//             {uploadType === "image" ? (
//               <div className={"d-flex m-auto image-preview-body"}>
//                 <OverlayTrigger
//                   trigger="click"
//                   placement="right"
//                   overlay={imagePopover}
//                   rootClose
//                   rootCloseEvent='mousedown'
//                 >
//                   <ThemeButton icon>
//                     <i className="fas fa-tags    "></i>
//                   </ThemeButton>
//                 </OverlayTrigger>

//                 <ThemeButton icon>
//                   <i className="fas fa-download    "></i>
//                 </ThemeButton>
//                 <ThemeButton icon onClick={() => removeImage(index)}>
//                   <i className="fas fa-trash    "></i>
//                 </ThemeButton>
//               </div>
//             ) : (
//               ""
//             )}
//             {uploadType === "video" ? (
//               <div className={"d-flex m-auto video-preview-body"}>
//                 <ThemeButton icon>
//                   <i className="fas fa-tags    "></i>
//                 </ThemeButton>
//                 <ThemeButton icon>
//                   <i className="fas fa-download    "></i>
//                 </ThemeButton>
//                 <ThemeButton icon onClick={() => removeImage(index)}>
//                   <i className="fas fa-trash    "></i>
//                 </ThemeButton>
//               </div>
//             ) : (
//               ""
//             )}
//           </div>

//           {uploadType === "image" ? (
//             <img src={image.preview} style={img} />
//           ) : (
//             ""
//           )}
//           {uploadType === "video" ? (
//             <video controls>
//               <source src={image.preview} />
//               Your browser does not support HTML5 video.
//             </video>
//           ) : (
//             ""
//           )}
//         </div>
//       </div>
//       <div className="form-group">
//         <input
//           onChange={(e) => changeTitle(e.target.value, index)}
//           type="text"
//           id={image.id}
//           defaultValue={image.title}
//           name="title"
//           className="form-control"
//         />
//       </div>
//     </div>
//   </Fragment>
// ));
