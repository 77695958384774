import React, { useEffect, useState, Fragment, useContext } from "react";

import NewDigitaltemplateForm from "./NewDigitaltemplateForm";
import { AccordianData } from "./AccordianConstants";
import { DigitalTemplateContext } from "../../pages/digitalTemplate/DigitalTemplateContextProvider";

const NewDigitaltemplate = ({ closeModal, micrositeValue }) => {
  const { category } = useContext(DigitalTemplateContext);
  const [
    socialMedaiTemplateCategory,
    setSocialMedaiTemplateCategory,
  ] = useState([]);

  console.log("accordianDatatatat", AccordianData);
  useEffect(() => {
    let categories = category.map((item) => {
      return {
        ...item,
        ...AccordianData.find((_item) => item.id === _item.id),
      };
    });
    setSocialMedaiTemplateCategory(categories);
  }, [category]);
  return (
    <div className="p-3">
      <NewDigitaltemplateForm
        closeModal={closeModal}
        socialMedaiTemplateCategory={socialMedaiTemplateCategory}
      />
    </div>
  );
};

export default NewDigitaltemplate;
