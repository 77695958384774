import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import Marker from './Marker';


function createMapOptions(maps) {
  // next props are exposed at maps
  // "Animation", "ControlPosition", "MapTypeControlStyle", "MapTypeId",
  // "NavigationControlStyle", "ScaleControlStyle", "StrokePosition", "SymbolPath", "ZoomControlStyle",
  // "DirectionsStatus", "DirectionsTravelMode", "DirectionsUnitSystem", "DistanceMatrixStatus",
  // "DistanceMatrixElementStatus", "ElevationStatus", "GeocoderLocationType", "GeocoderStatus", "KmlLayerStatus",
  // "MaxZoomStatus", "StreetViewStatus", "TransitMode", "TransitRoutePreference", "TravelMode", "UnitSystem"
  return {
    zoomControlOptions: {
      position: maps.ControlPosition.RIGHT_CENTER,
      style: maps.ZoomControlStyle.SMALL
    },
    mapTypeControlOptions: {
      position: maps.ControlPosition.TOP_RIGHT
    },
    mapTypeControl: true
  };
}
 
class Maps extends Component {
  static defaultProps = {
    center: [59.938043, 30.337157],
    zoom: 15,
    greatPlaceCoords: {lat: 59.724465, lng: 30.080121}
  };
  renderMarker = (map, maps) => {
    new maps.Marker({
      map: map,
      position: this.props?.prospectProfile?.latitude && this.props?.prospectProfile?.longitude ? (new maps.LatLng(parseFloat(this.props?.prospectProfile?.latitude), parseFloat(this.props?.prospectProfile?.longitude)
      )) : new maps.LatLng(-33.8688, 151.2093),
      title: "UK"
    });
  };
 
  render() {
    return (
      // Important! Always set the container height explicitly
      <div style={{ height: '70vh', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key:'AIzaSyCj7Xf0Bl-efoe9ZFYT9DAXTkHb2r59vz0' }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
          options={createMapOptions}
          yesIWantToUseGoogleMapApiInternals={true}
          onGoogleApiLoaded={({ map, maps }) => this.renderMarker(map, maps)}
        >
          {/* <Marker lat={-33.918331}
            lng={151.038284}
            text="My Marker" /> */}
             <Marker lat={59.955413} lng={30.337844} text={'A'} /* Kreyser Avrora */ />
             <Marker {...this.props.greatPlaceCoords} text={'B'} /* road circle */ />
        </GoogleMapReact>
      </div>
    );
  }
}
 
export default Maps;