import React, { useEffect, useState } from 'react'
import { TextField } from '@material-ui/core'

function TestimonialSettings({ selectedNode }) {
  const [testimonial, setTestimonial] = useState('')
  const [author, setAuthor] = useState('')

  const getTargetElement = targetClassName => {
    return selectedNode.getElementsByClassName(targetClassName)[0]
  }

  useEffect(() => {
    setTestimonial(getTargetElement('content').innerText)
    setAuthor(getTargetElement('author').innerText)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNode])

  const handleTestimonialChange = e => {
    setTestimonial(e.target.value)
    getTargetElement('content').innerHTML = e.target.value
  }

  const handleAuthorChange = e => {
    setAuthor(e.target.value)
    getTargetElement('author').innerHTML = e.target.value
  }

  return (
    <div>
      <TextField
        className='mt-4'
        multiline
        rows={6}
        label="Testimonial"
        fullWidth
        onChange={handleTestimonialChange}
        value={testimonial}
        inputProps={{ maxLength: 800 }}
      />
      <TextField
        className='mt-4'
        type="text"
        label="Author"
        fullWidth
        onChange={handleAuthorChange}
        value={author}
      />
    </div>
  )
}

export default TestimonialSettings
