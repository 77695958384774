import React from "react";
import DigitalTemplate from "./DigitalTemplate";
import DigitalTemplateContextProvider from "./DigitalTemplateContextProvider";

const DigitalTemplateWrap = () => {
  return (
    <div>
      <DigitalTemplateContextProvider>
        <DigitalTemplate />
      </DigitalTemplateContextProvider>
    </div>
  );
};

export default DigitalTemplateWrap;
