import React, { Fragment } from "react";
import Item from "./Item";
import moment from "moment";
import { Col } from "react-bootstrap";
import { Row } from "react-bootstrap";

const FamilyDetails = ({ profile }) => {
  return (
    <Fragment>
      <h6 className="font-weight-bold">Family Details</h6>

      {/* {profile?.marital_status ? ( */}
      <Row>
        <Col lg={4}>Status:</Col>
        <Col lg={8}>{profile?.marital_status?.name}</Col>
      </Row>
      {/* ) : (
        ""
      )} */}
      {/* {profile?.income ? ( */}
      <Row>
        <Col lg={4}>Income:</Col>
        <Col lg={8}>{profile?.income?.name}</Col>
      </Row>
      {/* ) : (
        ""
      )} */}
      {/* {profile?.children ? ( */}
      <Fragment>
        {" "}
        {profile?.children?.map((item, index) => (
          <Row>
            <Col lg={4}>{`Child ${index + 1}`}</Col>
            {profile?.children?.length > 0 && (
              <Col lg={8}>
                <p className="m-0">
                  {item?.name} <br />
                  {moment(item?.dob).format("DD/MM/YYYY")} (
                  {moment().diff(item?.dob, "years")} years)
                </p>
              </Col>
            )}
          </Row>
        ))}{" "}
      </Fragment>
      {/* ) : (
        ""
      )} */}
      {/* {profile?.pet_names ? ( */}
      <Row>
        <Col lg={4}>Pets:</Col>
        <Col lg={8}>
          {profile?.pet_names?.split(",")?.map((name) => (
            <div>{name}</div>
          ))}
        </Col>
      </Row>
      {/* ) : (
        ""
      )} */}
    </Fragment>
  );
};

export default FamilyDetails;
