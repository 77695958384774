import React, { useState, useCallback, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { reduxForm, FieldArray } from "redux-form";
import { connect } from "react-redux";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./_enquiry.scss";
import EnquiryItem from "./EnquiryItem";
import moment from "moment";
import { createNewEnquiry } from "../../../../../crud/buyersCrud";
import { convertFormData } from "../../../../../../../_helpers/_utils/utils";
import ThemeButton from "../../../../../../../partials/button/ThemeButton";

const validate = (values) => {
  const errors = {};
  if (!values.clubName) {
    errors.clubName = "Required";
  }
  if (!values.members || !values.members.length) {
    errors.members = { _error: "At least one member must be entered" };
  } else {
    const membersArrayErrors = [];
    values.members.forEach((member, memberIndex) => {
      const memberErrors = {};
      if (!member || !member.firstName) {
        memberErrors.firstName = "Required";
        membersArrayErrors[memberIndex] = memberErrors;
      }
      if (!member || !member.lastName) {
        memberErrors.lastName = "Required";
        membersArrayErrors[memberIndex] = memberErrors;
      }
      if (member && member.hobbies && member.hobbies.length) {
        const hobbyArrayErrors = [];
        member.hobbies.forEach((hobby, hobbyIndex) => {
          if (!hobby || !hobby.length) {
            hobbyArrayErrors[hobbyIndex] = "Required";
          }
        });
        if (hobbyArrayErrors.length) {
          memberErrors.hobbies = hobbyArrayErrors;
          membersArrayErrors[memberIndex] = memberErrors;
        }
        if (member.hobbies.length > 5) {
          if (!memberErrors.hobbies) {
            memberErrors.hobbies = [];
          }
          memberErrors.hobbies._error = "No more than five hobbies allowed";
          membersArrayErrors[memberIndex] = memberErrors;
        }
      }
    });
    if (membersArrayErrors.length) {
      errors.members = membersArrayErrors;
    }
  }
  return errors;
};

const Enquiry = (props) => {
  const {
    handleSubmit,
    pristine,
    profileId,
    submitting,
    invalid,
    numSelected,
    closeModal,
  } = props;
  const [show, setShow] = useState(true);
  const [enquiry_source, setEnquiry_source] = useState({});

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const onFormSubmit = useCallback((values) => {
    let filteredValue = {};
    filteredValue.enquiries = values.enquiries.map(
      ({ date, address, offer_party, ...other }) => {
        return {
          // date: moment(date).format("YYYY-MM-DD"),
          buyer: profileId,
          type: 1,
          ...other,
        };
      }
    );
    let processedValue = convertFormData(filteredValue);
    createNewEnquiry(processedValue)
      .then(({ data }) => {
        console.log(data);
        closeModal();
      })
      .catch(() => {});
  }, []);

  return (
    <>
      <form className="contactForm" onSubmit={handleSubmit(onFormSubmit)}>
        <div className="d-flex justify-content-between pb-3">
          <div className="header-left d-flex w-100 justify-content-start m-auto">
            <h5 className="brand-color font-weight-bold">New Enquiry</h5>
          </div>
          <div className="header-right d-flex w-100 justify-content-end">
            <div className="pr-2 ">
              <ThemeButton
                onClick={() => closeModal()}
                className="lightGrey-button"
              >
                Cancel
              </ThemeButton>
            </div>
            <div>
              <ThemeButton
                className="submit-button"
                type="submit"
                disabled={invalid || pristine || submitting}
              >
                Save
              </ThemeButton>
            </div>
          </div>
        </div>
        <FieldArray
          numSelected={numSelected}
          name="enquiries"
          component={EnquiryItem}
        />
      </form>
    </>
  );
};

const actions = {
  // socialFeedPost: news.actions.socialFeedPostAsync,
};
const mapState = (state, props) => {
  return {
    initialValues: {
      enquiries: props.numSelected,
    },
  };
};
export default connect(
  mapState,
  actions
)(reduxForm({ form: "tenantEnquiry", validate })(Enquiry));
