import React, { Fragment } from "react";
import { Image, Spinner } from "react-bootstrap";
import "./_userPopOver.scss";
import { toAbsoluteUrl } from "../../../_metronic";
import { CharacterCircle } from "../component/characterCircle/CharacterCircle";
import ContactType from "../table/cells/ContactType";

const ContactPopover = ({ data }) => {
  // console.log(id);
  // useEffect(() => {
  //   // console.log("called" + state.contactProfileId);
  //   getContactById(id)
  //     .then(({ data }) => {
  //       setData(data);
  //       setLoading(false);
  //       console.log(data);
  //     })
  //     .catch(() => {});
  // }, []);
  return (
    <Fragment>
      {console.log(data)}
      <div className="container contact-popover">
        <div className="row">
          <div className="userPopOver  dropShadow">
            <div className="d-flex p-2 pb-3 mb-3 darkBlue-background">
              <div className="col-5 rel dropShadow">
                <div className="avatar">
                  <Image
                    src={
                      data?.profile_picture
                        ? data?.profile_picture
                        : toAbsoluteUrl("/media/users/default.jpg")
                    }
                  />
                </div>
              </div>
              <div className="col-7 m-auto dropShadow">
                <h6 className="font-weight-bold">{data?.name}</h6>
                <p>{data?.profession}</p>
                <p>{data?.company_name}</p>
                {/* <p>{id}</p> */}
              </div>
              <Spinner animation="grow" />
            </div>
            <div className="col rubyColor profileDetails ">
              <div className="row my-2 mx-auto">
                <div className="col-2 text-right border-right m-auto border-dark">
                  <i className="fas fa-phone-alt"></i>
                </div>
                <div className="col-10 pl-4">
                  {data?.phones?.map(function(item, index) {
                    return (
                      <Fragment key={index}>
                        <div key={index}>{item.value}</div>{" "}
                      </Fragment>
                    );
                  })}
                </div>
              </div>
              <div className="row my-2 mx-auto">
                <div className="col-2 text-right m-auto border-right border-dark">
                  <i className="far fa-envelope"></i>
                </div>
                <div className="col-10 pl-4">
                  {data?.emails?.map(function(item, index) {
                    return (
                      <Fragment key={index}>
                        <div key={index}>{item.value}</div>{" "}
                      </Fragment>
                    );
                  })}
                  allSelected
                </div>
              </div>
              {data?.addresses?.map(function(item, index) {
                return (
                  <Fragment key={index}>
                    <div className="row my-2 mx-auto">
                      <div className="col-2 text-right m-auto border-right border-dark">
                        {item.type == "Work" ? (
                          <i className="fas fa-city    "></i>
                        ) : (
                          <i className="fas fa-home"></i>
                        )}
                      </div>
                      <div className="col-10 pl-4">
                        {/* {data?.address}  */}
                        {item.full_address}
                      </div>
                    </div>
                  </Fragment>
                );
              })}

              <div className="row justify-content-center p-3">
                <ContactType value={data?.types} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ContactPopover;
