import React, { useContext } from 'react'
import { Card, CardHeader, CardBody, Button } from "reactstrap";
import _includes from 'lodash/includes';

import { DigitalTemplateContext } from '../../pages/digitalTemplate/DigitalTemplateContextProvider';
import ImageSettings from './SettingsComponents/ImageSettings';
import TextSettings from './SettingsComponents/TextSettings';
import FeaturesSettings from './SettingsComponents/FeaturesSettings';
import AddressSettings from './SettingsComponents/AddressSettings';
import InspectionSettings from './SettingsComponents/InspectionSettings';
import { OpenHomeHeadingSettings, OpenHomeContentSettings } from './SettingsComponents/OpenHomeSettings';
import TestimonialSettings from './SettingsComponents/TestimonialSettings';
import AgentProfileSettings from './SettingsComponents/AgentProfileSettings';
import OpenHomeInspectionSettings from './SettingsComponents/OpenHomeInspectionSettings';
import { OpenLeasedEditSettings } from './SettingsComponents/openLeasedEditSettings';
import { OpenSoldEditSettings } from './SettingsComponents/openSoldEditSettings';

function DigitalTemplateContentEdit({ formValues, setSeletctedDate }) {
  const { selectedNode, setShowEditSection } = useContext(DigitalTemplateContext);
  const getEditContent = () => {
    console.log('selectedNode', selectedNode)
    if (_includes(selectedNode.className, 'image')) {
      return { component: <ImageSettings selectedNode={selectedNode} formValues={formValues} />, title: 'Edit Image' }
    } else if (_includes(selectedNode.className, 'address')) {
      if (_includes(selectedNode.className, 'split')) {
        return { component: <AddressSettings selectedNode={selectedNode} />, title: 'Edit Address' }
      } else {
        return { component: <TextSettings selectedNode={selectedNode} />, title: 'Edit Address' }
      }
    } else if (_includes(selectedNode.className, 'features')) {
      return { component: <FeaturesSettings selectedNode={selectedNode} />, title: 'Edit Features' }
    } else if (_includes(selectedNode.className, 'sold-node')) {
      return { component: <OpenSoldEditSettings selectedNode={selectedNode} />, title: 'Edit Sold Templates' }

    } 
     else if (_includes(selectedNode.className, 'leased-node')) {
      return { component: <OpenLeasedEditSettings selectedNode={selectedNode} />, title: 'Edit Templates' }

    } else if (_includes(selectedNode.className, 'open-home-node')) {
      if (_includes(selectedNode.className, 'main-heading-block')) {
        return { component: <OpenHomeHeadingSettings selectedNode={selectedNode} setSeletctedDate={setSeletctedDate} />, title: 'Edit Open Homes' }
      } else if (_includes(selectedNode.className, 'inspection')) {
        return { component: <InspectionSettings selectedNode={selectedNode} />, title: 'Edit Open Homes' }
      } else if (_includes(selectedNode.className, 'private-open-home')) {
        return {
          component: <OpenHomeInspectionSettings selectedNode={selectedNode} />, title: 'Edit Open Homes'
        }
      } else {

        return { component: <OpenHomeContentSettings selectedNode={selectedNode} />, title: 'Edit Open Homes' }
      }
    }
    else if (_includes(selectedNode.className, 'auction-node')) {
      if (_includes(selectedNode.className, 'main-heading-block')) {
        return { component: <OpenHomeHeadingSettings selectedNode={selectedNode} setSeletctedDate={setSeletctedDate} />, title: 'Edit Auctions' }
      } else {
        return { component: <OpenHomeContentSettings selectedNode={selectedNode} />, title: 'Edit Auctions' }
      }
    } else if (_includes(selectedNode.className, 'testimonial-block')) {
      return { component: <TestimonialSettings selectedNode={selectedNode} />, title: 'Edit Testimonial' }
    } else if (_includes(selectedNode.className, 'agent-profile-block')) {
      return { component: <AgentProfileSettings selectedNode={selectedNode} />, title: 'Edit Agent Profile' }
    } else {
      return { component: <span />, title: '' }
    }
  }

  const handleClose = () => {
    setShowEditSection(false)
  }

  let editSection = getEditContent()
  return (
    <Card className='mt-4 poster-template-edit'>
      <CardHeader className='edit-card-header'>{editSection.title} <Button close onClick={handleClose} /></CardHeader>
      <CardBody>
        {editSection.component}
      </CardBody>
    </Card>
  )
}

export default DigitalTemplateContentEdit
