import React, { useEffect, Fragment } from "react";
import { connect } from "react-redux";
// import * as modal from '../_redux/modalRedux'
import { reduxForm, Field, FieldArray, FormSection } from "redux-form";

import { combineValidators } from "revalidate";
import ThemeHeader from "../../../../partials/content/ThemeHeader";
import ThemeButton from "../../../../partials/button/ThemeButton";
import Item from "../../../../partials/display/Item";
import TextInput from "../../../../partials/form/TextInput";
import { Row, Col } from "react-bootstrap";
import MicrositeSettingArray from "../../partials/microsites/MicrositeSettingArray";
import DigitalTemplateSettingArray from "../../partials/digitalTemplate/DigitalTemplateSettingArray";

const validate = combineValidators({
  //   price_from: isRequired({ message: "The event title is required" }),
  //   price_to: isRequired({ message: "The category is required" }),
  //   reason: composeValidators(
  //     isRequired({ message: "Please enter a description" }),
  //     hasLengthGreaterThan(4)({
  //       message: "Description needs to be at least 5 characters",
  //     })
  //   )(),
  //   price_view: isRequired("city"),
  //   venue: isRequired("venue"),
  //   checkbox: isRequired("checkbox"),
  //   startTime: isRequired("date"),
  //   endTime: isRequired("date"),
});

const DigitalTemplateSetting = (props) => {
  const [edit, setEdit] = React.useState(false);
  const {
    handleSubmit,
    pristine,
    submitting,
    invalid,
    closeModal,
    touch,
    dirty,
  } = props;

  console.log(props);

  return (
    <Fragment>
      <div className="settings-modal">
        <form className="settings-form" onSubmit={handleSubmit}>
          <Fragment>
            <ThemeHeader
              left={
                <Fragment>
                  <h5 className="title">Digital Template Settings</h5>
                </Fragment>
              }
              right={
                <Fragment>
                  <Fragment>
                    <div className="pl-2">
                      <ThemeButton icon onClick={() => setEdit(!edit)}>
                        <i className="fas fa-edit    "></i>
                      </ThemeButton>
                    </div>
                    <div className="pl-2">
                      <ThemeButton
                        className="lightGrey-button"
                        onClick={closeModal}
                      >
                        Cancel
                      </ThemeButton>
                    </div>
                    <div className="pl-2">
                      <ThemeButton
                        type="submit"
                        disabled={invalid || pristine || submitting || !dirty}
                        className="submit-button"
                      >
                        Save
                      </ThemeButton>
                    </div>
                  </Fragment>
                </Fragment>
              }
            />

            <h6 className="font-weight-bold">Automatic Template Creation</h6>

            <FieldArray
              // placeholder="Add a Category"
              name="digital-template"
              edit={edit}
              // touch={touch}
              component={DigitalTemplateSettingArray}
            />
          </Fragment>
        </form>
      </div>
    </Fragment>
  );
};

export default reduxForm({
  form: "digital-template-setting",
  enableReinitialize: true,
})(DigitalTemplateSetting);
