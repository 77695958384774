import React, {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import ReactDOM from "react-dom";
import _forEach from "lodash/forEach";
import _includes from "lodash/includes";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import _uniqueId from "lodash/uniqueId";
import moment from "moment";
import { WindowCardContext } from "./WindowCardContextProvider";
import { FEATURES_ELEMENT } from "../../modals/digitalTemplate/AccordianConstants";
import { useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { formValueSelector } from "redux-form";
import "./cards.css";
import { split } from "lodash";
import QRCode from "qrcode";

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}
const toTitleCase = (name) =>
  name.replace(
    /(^\w|\s\w)(\S*)/g,
    (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase()
  );
const onDragOver = (event) => {
  const current_dragging_template_layout_to_customise = localStorage.getItem(
    "current_dragging_template_layout_to_customise"
  );
  if (current_dragging_template_layout_to_customise !== "accordian-block") {
    return true;
  }
  event.preventDefault();
};
function WindowCardContent(
  {
    // ref,
    templateLayoutContent,
    selectedTemplateLayout,
  },
  ref
) {
  const {
    setShowEditSection,
    setSelectedNode,
    setActiveTab,
    setLibraryDataSource,
    setListingData,
    setAuctionData,
    setData,
  } = useContext(WindowCardContext);
  const officeDetails = useSelector((state) => state.auth.user);
  const initialTemplateDom = useRef(null);

  const imgRef = React.createRef();

  const handleClick = (e, node) => {
    // debugger
    if (!node.className.includes("noEditable")) {
      setSelectedNode(node);
      setShowEditSection(true);
      setActiveTab("customise");
      e.stopPropagation();
    }
  };
  const addDragAndDropEvents = useCallback(() => {
    let templateLayout = document.getElementById("poster-template");
    if (templateLayout) {
      let nodes = templateLayout.getElementsByClassName("node-content-item");

      _forEach(nodes, (node) => {
        node.onclick = (e) => handleClick(e, node);
        node.ondragover = (event) => onDragOver(event);
      });

      templateLayout.ondrop = (event) => handleDrop(event, templateLayout);
    }
  }, [selectedTemplateLayout]);
  const updateNodes = (nodeContenItems, template) => {
    setData(template);

    // console.log("=========nodeContenItems===========================");
    // console.log(template);
    // console.log(nodeContenItems);
    // console.log("========nodeContenItems============================");

    nodeContenItems.forEach((node) => {
      if (template) {
        if (_includes(node.className, "image")) {
          // debugger
          let landScape =
            _includes(node.className, "landscapeOverlay") ||
            _includes(node.className, "auctionLandscape");
          // console.log('####landScape', landScape);
          let lookUp =
            _get(template, "assets", []).find(
              (item) => _get(item, "type.id") === 1
            ) || {}; // (1 - Image, 2 - Video)

          if (lookUp?.url) {
            // node.firstChild.src = `${lookUp.url}`
            if (landScape) {
              node.firstChild.style.background = `url('${lookUp.url}')`;
              node.firstChild.style.backgroundSize = "cover";
              node.firstChild.style.backgroundRepeat = "no-repeat";
              node.firstChild.style.backgroundPosition = "center";
            } else {
              node.firstChild.style.background = `url('${lookUp.url}')`;
              node.firstChild.style.backgroundSize = "cover";
              node.firstChild.style.backgroundRepeat = "round";
              // node.firstChild.style.backgroundPosition = 'center'
            }
          } else {
            node.style.background = "#c8dfe9";
          }

          let showOnWebsite = _get(template, "show_on_website", 0);
          let websiteLink = _get(
            template,
            "website_url",
            "https://www.wiseberry.com.au/"
          );

          if (
            _includes(node.childNodes[1].className, "qr_code") &&
            showOnWebsite == 1
          ) {
            QRCode.toDataURL(`${websiteLink}`, {
              errorCorrectionLevel: "M",
              width: 100,
              small: true,
            })
              .then((url) => {
                console.log(url);
                node.childNodes[1].innerHTML = `<img src="${url}" alt="QR code" />`;
              })
              .catch((err) => {
                console.error(err);
              });
          } else {
            node.childNodes[1].style.display = "none";
          }
        } else if (_includes(node.className, "bg_subImage")) {
          // console.log('#### sub image')
          // debugger
          let test = node.getElementsByClassName("bg_subImage");
          const templateAssets = template.assets?.filter(asset => asset.url.indexOf('.pdf') === -1)
          _forEach(test, (el, index) => {

            // console.log('list of assets', templateAssets[index]?.url)

            if (templateAssets[index]?.url) {
              if (_includes(el.className, "header_img")) {
                el.style.background = `url('${templateAssets[index].url}')`;
                el.style.backgroundSize = "cover";
                el.style.backgroundRepeat = "round";
              } else {
                el.src = templateAssets[index].url;
              }
            }
          });
          let showOnWebsite = _get(template, "show_on_website", 0);
          let websiteLink = _get(
            template,
            "website_url",
            "https://www.wiseberry.com.au/"
          );

          if (
            _includes(node.childNodes[2].className, "qr_code") &&
            showOnWebsite == 1
          ) {
            QRCode.toDataURL(`${websiteLink}`, {
              errorCorrectionLevel: "M",
              width: 100,
              small: true,
            })
              .then((url) => {
                
                node.childNodes[2].innerHTML = `<img src="${url}" alt="QR code" />`;
              })
              .catch((err) => {
                console.error(err);
              });
          } else {
            node.childNodes[2].style.display = "none";
          }
        } else if (_includes(node.className, "suburb")) {
          let address = _get(template, "property.address", null);
          const {
            street: {
              suburb: { name: suburbName },
            },
          } = address;
          node.innerHTML = `${toTitleCase(suburbName) ?? ""}`;
        } else if (_includes(node.className, "address node-content-item")) {
          let address = _get(template, "property.address", null);
          const {
            street_number,
            street: { name },
          } = address;

          node.innerHTML = `${street_number ?? ""} ${toTitleCase(name) ?? ""}`;
        } else if (_includes(node.className, "fulladd node-content-item")) {
          let address = _get(template, "property.address", null);
          const {
            street_number,
            street: { name, suburb },
          } = address;
          node.innerHTML = `${street_number ?? ""} ${toTitleCase(name) ?? ""}${
            suburb?.name ? "," : ""
          } ${toTitleCase(suburb?.name) ?? ""}`;
        } else if (_includes(node.className, "description")) {
          let templateLookUp = _get(template, "marketing_text");
          let description =
            templateLookUp &&
            templateLookUp.filter((item) => item?.is_description);
          // console.log("descriptionnnnn", split(description[0]?.text, "\n"));
          let res;
          if (node.id == "landscapedesp" || node.id == "descriptionWidth") {
            res = description[0]?.text.substring(0, 380);
          } else {
            res = description[0]?.text.substring(0, 600);
          }

          node.innerHTML =
            res
              ?.split("\n")
              .map(
                (item, idx) => `<p style="margin-bottom: 10px;">${item} </p>`
              )
              .join("") ?? "";
          // node.innerHTML = `${text[0]} <br/> ${text[1]}` ?? ''
        } else if (_includes(node.className, "features")) {
          // debugger
          let templateLookUp = _get(template, "property.features", []);

          FEATURES_ELEMENT.forEach(({ _className, _id }) => {
            let ele = node.querySelectorAll(`.${_className}`)?.[0];

            if (ele) {
              // can be removed once the back up issue fixed
              // debugger
              let lookUp = templateLookUp.find((_item) => _item.id === _id);
              if (lookUp?.qty) {
                ele.innerHTML = lookUp.qty;
              } else {
                // ele.parentNode.remove()
                ele.nextElementSibling.style.display = "none";
                ele.style.display = "none";
              }
            }
          });
        } else if (_includes(node.className, "price")) {
          node.innerHTML = ` ${template?.price_view?.toLocaleString() ?? 0} `;
        } else if (_includes(node.className, "openHomesBlock")) {
          let templateLookUp = _get(template, "appointments");
          let showOnWebsite = _get(template, "show_on_website", 0);

          let lookUp = templateLookUp?.find((app) => {
            let checkDate = app.starts;
            let todaysDate = new Date();
            if (
              new Date(checkDate).getTime() >= todaysDate.getTime() &&
              app.type.id == 5
            ) {
              return app;
            }
          });

          // console.log("============lookUp========================");
          // console.log(templateLookUp);
          // console.log(lookUp);
          // console.log(templateLookUp);
          // console.log("=============lookUp=======================");
          if (lookUp == undefined) {
            node.remove();
          } else {
            let openHomesDate = _includes(
              node.childNodes[1].className,
              "openHomeDate"
            );

            let dateOverlay = _includes(
              node.childNodes[1].className,
              "Dateverlay"
            );

            setListingData(lookUp);
            if (openHomesDate) {
              let day = moment(lookUp.starts).format("dddd");

              let date = moment(lookUp.starts).format("D MMMM YYYY");

              node.childNodes[1].innerHTML = `${day}, ${date}, ${moment(
                lookUp.starts
              ).format("LT")}  - ${moment(lookUp.ends).format("LT")}`;
            } else if (dateOverlay) {
              // debugger
              // let day = moment(lookUp.starts).format(
              //   "ddd")
              let showOnWebsite = _get(template, "show_on_website", 0);
              if (showOnWebsite == 0 || showOnWebsite == undefined) {
                node.style.right = "1rem";
              } else if (showOnWebsite == 1 || showOnWebsite != undefined) {
                node.style.right = "200px";
              }

              let date = moment(lookUp.starts).format("D MMM YYYY");

              node.childNodes[1].innerHTML = ` ${date.toUpperCase()}<span style="color:#840A2D;font-weight: 600; padding-right: 3px; padding-left: 3px">|</span>${moment(
                lookUp.starts
              ).format("LT")} - ${moment(lookUp.ends).format("LT")}`;
            }
          }

          node.onmouseenter = () => {
            // debugger
            let deleteIcon = document.createElement("i");
            deleteIcon.className = "delete-icon fas fa-trash";
            deleteIcon.style.position = "absolute";
            deleteIcon.style.top = "-10%";
            deleteIcon.style.right = "4%";
            deleteIcon.style.fontSize = "30px";
            // deleteIcon.style.display = 'block'
            deleteIcon.style.color = "green";
            // deleteIcon.innerText = 'Delete'
            deleteIcon.onclick = (e) => {
              node.remove();
              setShowEditSection(false);
              e.stopPropagation();
            };
            node.appendChild(deleteIcon);
          };
          node.onmouseleave = () => {
            let deleteIcon = node.getElementsByClassName("delete-icon")?.[0];
            if (deleteIcon) {
              deleteIcon.remove();
            }
          };
        } else if (_includes(node.className, "rentalOpenBlock")) {
          let templateLookUp = _get(template, "appointments");
          let showOnWebsite = _get(template, "show_on_website", 0);
          let lookUp = templateLookUp?.find((app) => {
            // debugger
            let checkDate = app.starts;
            let todaysDate = new Date();
            if (
              new Date(checkDate).getTime() >= todaysDate.getTime() &&
              app.type.id == 8
            ) {
              return app;
            }
          });

          // console.log("============lookUp========================");
          // console.log(lookUp);
          // console.log(templateLookUp);
          // console.log("=============lookUp=======================");
          if (lookUp == undefined) {
            node.remove();
          } else {
            // console.log("============childNodes========================");
            // console.log(node);
            // console.log(node.childNodes);
            // console.log("=============childNodes=======================");
            // debugger
            let RentalopenHomesDate = _includes(
              node.childNodes[1].className,
              "rentalOpenDate"
            );

            let dateOverlay = _includes(
              node.childNodes[1].className,
              "Dateverlay"
            );

            setListingData(lookUp);
            if (RentalopenHomesDate) {
              let day = moment(lookUp.starts).format("dddd");

              let date = moment(lookUp.starts).format("D MMMM YYYY");

              node.childNodes[1].innerHTML = `${day}, ${date}, ${moment(
                lookUp.starts
              ).format("LT")}  - ${moment(lookUp.ends).format("LT")}`;
            } else if (dateOverlay) {
              // debugger
              // let day = moment(lookUp.starts).format(
              //   "ddd")
              let showOnWebsite = _get(template, "show_on_website", 0);
              if (showOnWebsite == 0 || showOnWebsite == undefined) {
                node.style.right = "1rem";
              } else if (showOnWebsite == 1 || showOnWebsite != undefined) {
                node.style.right = "200px";
              }

              let date = moment(lookUp.starts).format("D MMM YYYY");

              node.childNodes[1].innerHTML = ` ${date.toUpperCase()}<span style="color:#840A2D;font-weight: 600; padding-right: 3px; padding-left: 3px">|</span>${moment(
                lookUp.starts
              ).format("LT")} - ${moment(lookUp.ends).format("LT")}`;
            }
          }

          node.onmouseenter = () => {
            // debugger
            let deleteIcon = document.createElement("i");
            deleteIcon.className = "delete-icon fas fa-trash";
            deleteIcon.style.position = "absolute";
            deleteIcon.style.top = "-10%";
            deleteIcon.style.right = "4%";
            deleteIcon.style.fontSize = "30px";
            // deleteIcon.style.display = 'block'
            deleteIcon.style.color = "green";
            // deleteIcon.innerText = 'Delete'
            deleteIcon.onclick = (e) => {
              node.remove();
              setShowEditSection(false);
              e.stopPropagation();
            };
            node.appendChild(deleteIcon);
          };
          node.onmouseleave = () => {
            let deleteIcon = node.getElementsByClassName("delete-icon")?.[0];
            if (deleteIcon) {
              deleteIcon.remove();
            }
          };
        } else if (_includes(node.className, "openHomesAuctionBlock")) {
          let todaysDate = new Date();
          let auctionLookUp = _get(template, "auction_date");
          let activeAuction =
            new Date(auctionLookUp).getTime() >= todaysDate.getTime();

          if (activeAuction == false) {
            node.remove();
          } else {
            // debugger
            let openHomesDate = _includes(
              node.childNodes[1].className,
              "auctionDate"
            );
            let auctiondateOverlay = _includes(
              node.childNodes[1].className,
              "auctiondateOverlay"
            );
            setAuctionData(auctionLookUp);
            if (openHomesDate) {
              let day = moment(auctionLookUp).format("dddd");

              let date = moment(auctionLookUp).format("D MMMM YYYY");

              node.childNodes[1].innerHTML = `${day}, ${date}, ${moment(
                auctionLookUp
              ).format("LT")}  `;
            } else if (auctiondateOverlay) {
              let showOnWebsite = _get(template, "show_on_website", 0);
              if (showOnWebsite == 0 || showOnWebsite == undefined) {
                node.style.right = "1rem";
              } else if (showOnWebsite == 1 || showOnWebsite != undefined) {
                node.style.right = "200px";
              }

              let date = moment(auctionLookUp).format("D MMM YYYY");
              node.childNodes[1].innerHTML = ` ${date.toUpperCase()}<span style="color:#840A2D;font-weight: 600; padding-right: 3px; padding-left: 3px">|</span>${moment(
                auctionLookUp
              ).format("LT")}`;
            }
          }
        } else if (_includes(node.className, "auction_openHomes")) {
          node.onmouseenter = () => {
            // debugger
            let deleteIcon = document.createElement("i");
            deleteIcon.className = "delete-icon fas fa-trash";
            deleteIcon.style.position = "absolute";
            deleteIcon.style.top = "-10%";
            deleteIcon.style.right = "4%";
            deleteIcon.style.fontSize = "30px";
            // deleteIcon.style.display = 'block'
            deleteIcon.style.color = "green";
            // deleteIcon.innerText = 'Delete'
            deleteIcon.onclick = (e) => {
              node.remove();
              setShowEditSection(false);
              e.stopPropagation();
            };
            node.appendChild(deleteIcon);
          };
          node.onmouseleave = () => {
            let deleteIcon = node.getElementsByClassName("delete-icon")?.[0];
            if (deleteIcon) {
              deleteIcon.remove();
            }
          };
          let templateLookUp = _get(template, "appointments");
          let todaysDate = new Date();
          let activeAppointments = templateLookUp?.find((app) => {
            let checkDate = app.starts;

            if (new Date(checkDate).getTime() >= todaysDate.getTime()) {
              return app;
            }
          });
          let auctionLookUp = _get(template, "auction_date");
          let activeAuction =
            new Date(auctionLookUp).getTime() >= todaysDate.getTime();

          if (activeAppointments == undefined && activeAuction == false) {
            node.remove();
          }
          // console.log("=========activeAuction===========================");
          // console.log(activeAuction);
          // console.log(auctionLookUp);
          // console.log(activeAppointments);
          // console.log("==========activeAuction==========================");
        } else if (_includes(node.className, "header")) {
          let templateLookUp = _get(template, "marketing_text");
          let headerText =
            templateLookUp && templateLookUp.filter((item) => item?.is_heading);
          node.innerHTML = headerText[0]?.text ?? "";
        } else if (_includes(node.className, "agentDetails")) {
          // debugger
          let templateLookUp = _get(template, "contacts");
          let agentText =
            templateLookUp &&
            templateLookUp.filter(
              (item) =>
                item?.contact_type?.id === 2 || item?.contact_type?.id === 6
            )[0];
          let str = agentText?.phones[0]?.value;
          if (str?.startsWith("04")) {
            const phoneRegex = /^(\d{4})(\d{3})(\d{3})?(\d+)$/;
            str = str.replace(phoneRegex, "$1 $2 $3 $4");
          } else if (str?.startsWith("02") || str?.startsWith("07")) {
            const phoneRegex = /^(\d{2})(\d{4})(\d{4})?(\d+)$/;
            str = str.replace(phoneRegex, "$1 $2 $3 $4");
          } else {
            const phoneRegex = /^(\d{4})(\d{3})(\d{3})?(\d+)$/;
            str = str?.replace(phoneRegex, "$1 $2 $3 $4");
          }

          if (agentText?.profile_picture != null) {
            node.previousElementSibling.style.borderColor = "#EBEBEB";
            node.previousElementSibling.innerHTML = `<img src=${agentText?.profile_picture} style="height:100px;width:100px;border-radius:50%;" />`;
          }

          _forEach(node.childNodes, (el, index) => {
            if (el.className) {
              if (el.className.includes("agent_name")) {
                el.innerHTML = `${agentText.first_name} ${agentText.last_name}`;
              } else if (el.className.includes("agent_mail")) {
                el.innerHTML = `${agentText.emails[0].value}`;
              } else if (el.className.includes("agent_phone")) {
                el.innerHTML = `${str}`;
              } else if (el.className.includes("agent_picture")) {
                el.innerHTML = `<img src={${agentText.profile_picture}}/>`;
              }
            }
          });
        } else if (_includes(node.className, "co_agent_Details")) {
          let templateLookUp = _get(template, "contacts");
          let agentText =
            templateLookUp &&
            templateLookUp.filter((item) => item?.contact_type?.id === 7)[0];

          if (agentText) {
            let str = agentText?.phones[0].value;
            if (str.startsWith("04")) {
              const phoneRegex = /^(\d{4})(\d{3})(\d{3})?(\d+)$/;
              str = str.replace(phoneRegex, "$1 $2 $3 $4");
            } else if (str.startsWith("02") || str.startsWith("07")) {
              const phoneRegex = /^(\d{2})(\d{4})(\d{4})?(\d+)$/;
              str = str.replace(phoneRegex, "$1 $2 $3 $4");
            } else {
              const phoneRegex = /^(\d{4})(\d{3})(\d{3})?(\d+)$/;
              str = str.replace(phoneRegex, "$1 $2 $3 $4");
            }

            if (agentText?.profile_picture != null) {
              node.previousElementSibling.style.borderColor = "#EBEBEB";
              node.previousElementSibling.innerHTML = `<img src=${agentText?.profile_picture} style="height:100px;width:100px;border-radius:50%;" />`;
            }

            _forEach(node.childNodes, (el, index) => {
              if (el.className) {
                if (el.className.includes("agent_name")) {
                  el.innerHTML = `${agentText.first_name} ${agentText.last_name}`;
                } else if (el.className.includes("agent_mail")) {
                  el.innerHTML = `${agentText.emails[0].value}`;
                } else if (el.className.includes("agent_phone")) {
                  el.innerHTML = `${str}`;
                } else if (el.className.includes("agent_picture")) {
                  el.innerHTML = `<img src={${agentText.profile_picture}}/>`;
                }
              }
            });
          } else {
            node.previousElementSibling.remove();
            node.remove();
            // node.previousElementSibling.remove()
          }
        } else if (_includes(node.className, "officeFooter")) {
          node.getElementsByClassName("officeName")[0].innerHTML =
            officeDetails?.organisation?.name;
          if (officeDetails?.organisation?.phones[0].value) {
            let str = officeDetails?.organisation?.phones[0].value;
            if (str.startsWith("04")) {
              const phoneRegex = /^(\d{4})(\d{3})(\d{3})?(\d+)$/;
              str = str.replace(phoneRegex, "$1 $2 $3 $4");
            } else if (str.startsWith("02") || str.startsWith("07")) {
              const phoneRegex = /^(\d{2})(\d{4})(\d{4})?(\d+)$/;
              str = str.replace(phoneRegex, "$1 $2 $3 $4");
            } else {
              const phoneRegex = /^(\d{4})(\d{3})(\d{3})?(\d+)$/;
              str = str.replace(phoneRegex, "$1 $2 $3 $4");
            }

            node.getElementsByClassName("office_phone")[0].innerHTML = str;
          }

          node.getElementsByClassName("office_website")[0].innerHTML =
            officeDetails?.organisation?.office_website;
        } else if (_includes(node.className, "disclaimerBlock")) {
          node.onmouseenter = () => {
            // debugger
            let deleteIcon = document.createElement("i");
            deleteIcon.className = "delete-icon fas fa-trash";
            deleteIcon.style.position = "absolute";
            deleteIcon.style.top = "-10%";
            deleteIcon.style.right = "4%";
            deleteIcon.style.fontSize = "30px";
            // deleteIcon.style.display = 'block'
            deleteIcon.style.color = "green";
            // deleteIcon.innerText = 'Delete'
            deleteIcon.onclick = (e) => {
              node.remove();
              setShowEditSection(false);
              e.stopPropagation();
            };
            node.appendChild(deleteIcon);
          };
          node.onmouseleave = () => {
            let deleteIcon = node.getElementsByClassName("delete-icon")?.[0];
            if (deleteIcon) {
              deleteIcon.remove();
            }
          };
        }
      }
    });
    localStorage.removeItem("current_dragging_template_layout_to_customise");
  };
  const handleDrop = (e, templateLayout) => {
    // let nodeContenItems = templateLayout.getElementsByClassName('node-content-item')
    let template = e.dataTransfer.getData("drag-element");

    let tempContent = templateLayout;
    if (initialTemplateDom.current) {
      tempContent = initialTemplateDom.current.cloneNode(true);
      templateLayout.parentNode.replaceChild(tempContent, templateLayout); //-- Pbm here
      addDragAndDropEvents();
    }
    if (template) {
      // let _templateLayout = document.getElementsByClassName('poster-template')?.[0] // for future - if back up issue comes
      template = JSON.parse(template);
      if (template.assets) {
        setLibraryDataSource(
          template.assets.filter((item) => _get(item, "type.id") === 1)
        ); // 1 for Image
      }
      let nodeContenItems = tempContent.querySelectorAll(".node-content-item");

      updateNodes(nodeContenItems, template);
    }
  };

  useEffect(() => {
    let templateLayout = document.getElementById("poster-template");
    if (templateLayout) {
      initialTemplateDom.current = templateLayout.cloneNode(true);
      let disclaimer = templateLayout.querySelector(".disclaimerBlock");
      // console.log(templateLayout.querySelector(".disclaimerBlock"));
      if (disclaimer) {
        disclaimer.onmouseenter = () => {
          // debugger
          let deleteIcon = document.createElement("i");
          deleteIcon.className = "delete-icon fas fa-trash";
          deleteIcon.style.position = "absolute";
          deleteIcon.style.top = "-10%";
          deleteIcon.style.right = "4%";
          deleteIcon.style.fontSize = "30px";
          // deleteIcon.style.display = 'block'
          deleteIcon.style.color = "green";
          // deleteIcon.innerText = 'Delete'
          deleteIcon.onclick = (e) => {
            disclaimer.remove();
            setShowEditSection(false);
            e.stopPropagation();
          };
          disclaimer.appendChild(deleteIcon);
          // disclaimer.parentNode.insertBefore(deleteIcon, disclaimer);
        };
        disclaimer.onmouseleave = () => {
          let deleteIcon = disclaimer.getElementsByClassName(
            "delete-icon"
          )?.[0];
          if (deleteIcon) {
            deleteIcon.remove();
          }
        };
      }
    }
    addDragAndDropEvents();

    if (
      templateLayout != null &&
      templateLayout.querySelector(".bg-image-node") != null &&
      templateLayout
        .querySelector(".bg-image-node")
        .querySelector("div:first-child") != null
    ) {
      let elem = templateLayout
        .querySelector(".bg-image-node")
        .querySelectorAll("div");

      // console.log("========addEventListener============================");
      // console.log(elem);
      // console.log("=========addEventListener===========================");
      elem.forEach((item) => {
        item.addEventListener("mouseover", () => {
          templateLayout
            .querySelector(".bg-image-node")
            .classList.add("removeBorder");
        });
        item.addEventListener("mouseout", () => {
          templateLayout
            .querySelector(".bg-image-node")
            .classList.remove("removeBorder");
        });

        // item.addEventListener('mouseover', ()=>{
        //   // debugger
        //   let disclaimer  =  templateLayout.querySelector('.disclaimerBlock');
        //   let deleteIcon = document.createElement('i')
        //   deleteIcon.className = 'delete-icon fas fa-trash'
        //   deleteIcon.style.position = 'absolute'
        //   deleteIcon.style.top = '10%'
        //   deleteIcon.style.right = '4%'
        //   deleteIcon.style.fontSize = '100px'
        //   deleteIcon.onclick = e => {
        //     disclaimer.remove()
        //     setShowEditSection(false)
        //     e.stopPropagation()
        //   }
        // })
      });
    }
  }, [templateLayoutContent, addDragAndDropEvents]);

  const imgStyle2 = { position: "absolute", top: -28 };
  const transformOptions = {
    wheel: {
      disabled: true,
      // wheelDisabled: false
    },

    panning: {
      disabled: false,
    },
    initialPositionX: 161.5,
    initialPositionY: 0,
    // initialScale: 0.5
  };

  return (
    <>
      {/* <TransformWrapper
        initialScale={0.3}
        initialPositionX={50}
        // initialPositionY={0}
        options={transformOptions}
        maxScale={0.6}
        maxPositionY={0}
        wheel={{ wheelDisabled: true }}
        onWheel={(e) => {
          console.log("wheel++++++++++++++++++", e.zoomOut);
          
        }}
        
      >
        {({
          zoomIn,
          zoomOut,
          resetTransform,
          positionX,
          positionY,
          setPositionX,
          setPositionY,
          setScale,
          ...rest
        }) => {
        

          return (
            <React.Fragment>
              <div
                className="template-body poster-template"
                style={{ position: "relative" }}
                // ref={imgRef}
              >
                <div className="tools" style={imgStyle2}>
                  <button
                  type="button"
                    onClick={() => 
                      rest.setTransform(50, 0, 0.6, 300, "easeOut")
                    }
                    // onClick={() => zoomIn()}
                    style={{
                      marginRight: 20,
                      borderRadius: 5,
                      padding: "2px 10px",
                    }}
                  >
                    +
                  </button>
               
                  <button
                  type="button"
                    onClick={() =>
                      rest.setTransform(50, 0, 0.3, 300, "easeOut")
                    }
                    style={{ borderRadius: 5, padding: "2px 10px" }}
                  >
                    -
                  </button>
                </div>
                <TransformComponent>
                  <div
                    ref={ref}
                    dangerouslySetInnerHTML={{ __html: templateLayoutContent }}
                  />
                </TransformComponent>
              </div>
            </React.Fragment>
          );
        }}
      </TransformWrapper> */}
      <div
        style={{
          position: "relative",
          background: "#EBEBEB",
          minWidth: 1000,
          height: "70%",
        }}
        className="template-body poster-template"
      >
        <div style={{ transform: " translate3d(0px, 0px, 0px) scale(0.6)" }}>
          <div
            ref={ref}
            dangerouslySetInnerHTML={{ __html: templateLayoutContent }}
          />
        </div>
      </div>
    </>
  );
}

export default forwardRef(WindowCardContent);
