import React, { useState, Fragment, useEffect, useContext } from "react";
import { connect } from "react-redux";
import { reduxForm, Field, FieldArray } from "redux-form";
import { Form, Button, Col, Row } from "react-bootstrap";
import {
  combineValidators,
  composeValidators,
  isRequired,
  hasLengthGreaterThan,
} from "revalidate";
import TextInput from "../../../../../partials/form/TextInput";
import Item from "../../../../../partials/display/Item";
import MultiSelect from "../../../../../partials/form/MultiSelect";
import ToggleSwitch from "../../../../../partials/form/toggleSwitch/ToggleSwitch";
import FormFeatures from "../../../../../partials/form/partials/FormFeatures";
import FormTenantPreferences from "../../../../../partials/form/partials/FormTenantPreferences";
import ThemeHeader from "../../../../../partials/content/ThemeHeader";
import ThemeButton from "../../../../../partials/button/ThemeButton";
import { getMenu } from "../../../../../GeneralApi/GeneralApi";
import { updateTenant } from "../../../crud/tenantsCrud";
import { toast } from "react-toastify";
import {
  convertFormData,
  getObjWithoutValues,
  postTenantProcessedInfo,
  formToQueryString
} from "../../../../../_helpers/_utils/utils";
import * as tenantsRedux from "../../../_redux/tenantsRedux";
import { TenantProfileContext } from "../TenantProfileContextProvider";

const validate = combineValidators({
  title: isRequired({ message: "The event title is required" }),
  category: isRequired({ message: "The category is required" }),
  description: composeValidators(
    isRequired({ message: "Please enter a description" }),
    hasLengthGreaterThan(4)({
      message: "Description needs to be at least 5 characters",
    })
  )(),
  city: isRequired("city"),
  venue: isRequired("venue"),
  checkbox: isRequired("checkbox"),
  startTime: isRequired("date"),
  endTime: isRequired("date"),
});

const TenantDetailsEdit = (props) => {
  const { handleSubmit, pristine, submitting, invalid, tenantFetch } = props;
  const { tenantsTableUpdate, update,filters } = useContext(TenantProfileContext);
  const onFormSubmit = async (values) => {
    console.log(values, "form_values");
    const { contacts, ...otherData } = values;

    let contacts_Id = contacts.map((item) => item.id);
    let savingValue = {
      contacts: contacts_Id,
      ...otherData,
    };
    let formatedValue = postTenantProcessedInfo(savingValue);

    const formData = convertFormData(formatedValue);

    console.log(...formData);

    formData.append("_method", "PATCH");
    updateTenant(values.id, formData)
      .then(({ data }) => {
        console.log(data);
        toast.success("Tenant Updated Successfully");
        tenantFetch(values.id);
        update();
        tenantsTableUpdate(formToQueryString(filters));
      })
      .catch((e) => {
        toast.error("Error while tenant saving");
      });
  };
  const [tenant_type, setTenant_type] = useState({});
  const [date_time_unit, setDate_time_unit] = useState({});
  const [rating, setRating] = useState({});

  useEffect(() => {
    let query = "property_type,rating,tenant_type,date_time_unit";
    getMenu(query)
      .then(({ data: { data } }) => {
        setTenant_type(data.tenant_type);
        setDate_time_unit(
          data.date_time_unit?.filter((unit) => [3, 5, 4, 6].includes(unit?.id))
        );
        setRating(data.rating);
      })
      .catch(() => {});
  }, []);
  //

  return (
    <Fragment>
      <div className="tenantDetails">
        <Form onSubmit={handleSubmit(onFormSubmit)} noValidate>
          <ThemeHeader
            left={<h5 className="title">Tenants</h5>}
            right={
              <ThemeButton
                type="submit"
                className="submit-button"
                disabled={invalid || pristine || submitting}
              >
                Save
              </ThemeButton>
            }
          />

          <Row>
            <Col>
              <Item
                lg="5"
                md="5"
                col="5"
                left="Tenant type"
                right={
                  <Field
                    name="type"
                    placeholder="Select type"
                    component={MultiSelect}
                    showBottom={false}
                    isMulti={false}
                    options={tenant_type}
                  />
                }
              />
              <Item
                lg="5"
                md="5"
                col="5"
                left="Been Looking"
                right={
                  <Fragment>
                    <div className="d-flex">
                      <Field
                        className="w-50 mr-1"
                        name="looking_date"
                        type="text"
                        component={TextInput}
                      />
                      <Field
                        name="looking_date_time_unit"
                        component={MultiSelect}
                        className="w-100"
                        showBottom={false}
                        isMulti={false}
                        options={date_time_unit}
                      />
                    </div>
                  </Fragment>
                }
              />
              <Item
                lg="5"
                md="5"
                col="5"
                left="Motivation"
                right={
                  <Field name="motivation" type="text" component={TextInput} />
                }
              />
            </Col>
            <Col>
              <Item
                lg="5"
                md="5"
                col="5"
                left="Rating"
                right={
                  <Field
                    name="rating"
                    placeholder="Select a rating"
                    component={MultiSelect}
                    showBottom={false}
                    isMulti={false}
                    options={rating}
                  />
                }
              />
              <Item
                lg="5"
                md="5"
                col="5"
                left="Ready In"
                right={
                  <Fragment>
                    <div className="d-flex">
                      <Field
                        className="w-50 mr-1"
                        name="ready_in_date"
                        type="text"
                        component={TextInput}
                      />
                      <Field
                        name="ready_in_date_time_unit"
                        component={MultiSelect}
                        className="w-100"
                        showBottom={false}
                        isMulti={false}
                        options={date_time_unit}
                      />
                    </div>
                  </Fragment>
                }
              />
            </Col>
            <Col>
              <Item
                lg="5"
                md="5"
                col="5"
                left="Active"
                classNames="m-auto"
                right={<Field name="active" component={ToggleSwitch} />}
              />
              <Item
                lg="5"
                md="5"
                col="5"
                left="Do Not Call?"
                classNames="m-auto"
                right={<Field name="dnc" component={ToggleSwitch} />}
              />
            </Col>
          </Row>
          <FormTenantPreferences />
          <FieldArray name="features" component={FormFeatures} height="30" />
        </Form>
      </div>
    </Fragment>
  );
};

const mapState = (state, ownProps) => {
  let details = {};

  if (typeof state.event != "undefined") {
    details = state.event;
  }

  return {
    initialValues: { ...ownProps.profile },
  }; // Foram name must have to be different
};

export default connect(
  mapState,
  tenantsRedux.actions
)(
  reduxForm({ form: "tenant-details", validate, enableReinitialize: true })(
    TenantDetailsEdit
  )
);
