import React, { Fragment, useState, useContext, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { compose } from 'redux';
import { Row, Col, Spinner } from "react-bootstrap";
import { reduxForm, Field, getFormValues } from "redux-form";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
// import { Divider } from "@material-ui/core";
import _isEmpty from 'lodash/isEmpty';
import Swal from "sweetalert2";
import domtoimage from 'dom-to-image'
import { WindowCardContext } from "./WindowCardContextProvider";
import ThemeHeader from "../../../../partials/content/ThemeHeader";
import TextInput from "../../../../partials/form/TextInput";
import ThemeButton from "../../../../partials/button/ThemeButton";
import WindowCardLayouts from "./WindowCardLayouts";
import WindowCardEditSection from "./WindowCardEditSection";
import WindowCardContent from "./WindowCardContent";
import WindowcardCustomise from "./WindowcardCustomise";
import { convertFormData, errorAlert } from "../../../../_helpers/_utils/utils";
import { createSocialMediaTemplate } from "../../crud/socialMedailCrud";

const validate = (values = {}) => {
    const errors = {};
    if (!values?.name) {
        errors.name = "Required";
    }
    return errors;
}

const styleFamily = `
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans'), url('https://fonts.cdnfonts.com/s/14884/OpenSans-Regular.woff') format('woff');
}
`

const NewWindowCardForm = (props) => {
    const ref = useRef(null)
    const {
        setEditBackground,
        showEditSection,
        activeTab,
        setActiveTab,
        selectedSocialTemplate,
        setSelectedSocialTemplate,
        deleteSocialTemplates,
        updateTableData,
        listingData,
        tempdata,
        setListingData
    } = useContext(WindowCardContext);
    const [selectedTemplateLayout, setSelectedTemplateLayout] = useState({});
    const [_selectedTemplate, _setSelectedTemplate] = useState('');
    const [seletctedDate, setSeletctedDate] = useState(null);
    const isEdit = !_isEmpty(selectedSocialTemplate)

    const HandleActiveTab = (event, newAlignment) => {
        if (newAlignment !== null) {
            setActiveTab(newAlignment);
        }
    };
    
    const {
        handleSubmit,
        pristine,
        submitting,
        invalid,
        closeModal,
        socialMedaiTemplateCategory,
        change,
        formValues,
        setLibraryDataSource,
        windowCardsTemplateCategory
    } = props;

    useEffect(() => {
        if (isEdit) {
            // debugger
          let templateUrl = selectedSocialTemplate?.assets?.find(item => item.type?.id === 5 && item.extension === 'html')?.url
          if (templateUrl) {
            fetch(templateUrl)
              .then(r => r.text())
              .then(t => {
                _setSelectedTemplate(t)
              })
          }
          setActiveTab('customise')
        }
        change("name", selectedSocialTemplate?.name);
    
        
      }, [selectedSocialTemplate])


    const onFormSubmit = async (values) => {
      
        let orientation 
        let details 
        let details1
        let type
        let name
        let listing
 if(selectedTemplateLayout?.template){
    details = selectedTemplateLayout?.template?.split("photo/")
    details1 = selectedTemplateLayout?.template?.split("thumbnails/")
   name  = details[1]?.split("-")[0]
    orientation =details1[1]?.split("-")[1].split("/")[0]
    type = selectedTemplateLayout.name.split(" ")[0]
    listing =tempdata.id
   
 }else if (isEdit &&selectedSocialTemplate ){
    type = selectedSocialTemplate.type
    orientation =selectedSocialTemplate.orientation
   name = selectedSocialTemplate.category.name
   listing= selectedSocialTemplate.listing
 }
 
//  debugger
        let htmlContent = `<html>${ref.current.innerHTML}</html>`
        const html_file = new File([htmlContent], `${values.name}.html`, { type: "text/html;charset=utf-8", lastModified: new Date() });

        let data = {
            ...values,
            html_file, 
            type,
            orientation,
            listing,
            category : name == "Auction" ? 'Auctions':name
          }
          const formData = convertFormData(data);
          if (selectedSocialTemplate.id) {
            formData.append("_method", "PATCH");
          }

          return await createSocialMediaTemplate(selectedSocialTemplate.id, formData)
          .then(res => {
            props.updateTableData()
            setSelectedSocialTemplate({})
            setActiveTab('template')
            props.closeModal()
          })
          .catch(err => {
            setSelectedSocialTemplate({})
            errorAlert(err?.data)
          })
    };





    const handleCancel = () => {
        Swal.fire({
            title: "Warning!",
            text: "Are you sure want to exit?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#A7A9AC",
            cancelButtonColor: "#2BB673",
            confirmButtonText: "Cancel",
            cancelButtonText: "Yes",
        }).then(async (result) => {
            if (result.isDismissed) {
                closeModal()
            }
        });
    }
    return (
        <Fragment>
            <div className="p-3">
                <form className="settings-form" onSubmit={handleSubmit(onFormSubmit)}>
                    <Fragment>
                        <ThemeHeader
                            left={
                                <Fragment>
                                    <h5 className="title pt-4 pr-3">
                                        {isEdit ? "Edit" : "New"} Template
                                    </h5>
                                    <Field
                                        style={{ width: "20vh" }}
                                        label={"Name"}
                                        name='name'
                                        showErrorOnMount={true}
                                        component={TextInput}
                                    />
                                </Fragment>
                            }
                            right={
                                <Fragment>
                                    <div className="pl-2">
                                        <ThemeButton
                                            className="lightGrey-button"
                                            onClick={handleCancel}
                                        >
                                            Cancel
                                        </ThemeButton>
                                    </div>
                                    <div className="pl-2">
                                        <ThemeButton
                                            disabled={invalid || pristine || submitting}
                                            type="submit"
                                            className="green-button"
                                        >
                                            {submitting ?
                                                <Spinner
                                                    as="span"
                                                    animation="grow"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                /> : ''}
                                            Save
                                        </ThemeButton>
                                    </div>
                                </Fragment>
                            }
                        />
                        <Row>
                            <Col lg={4} sm={4}>
                                <Row>
                                    <ToggleButtonGroup
                                        value={activeTab}
                                        exclusive
                                        // className="float-right"
                                        onChange={HandleActiveTab}
                                        aria-label="text alignment"
                                        style={{ width: "100%" }}
                                    >
                                        <ToggleButton
                                            className={`themeButton white-button ${isEdit ? 'pointer-events-none' : ''}`}
                                            value="template"
                                            fullWidth
                                            style={{
                                                border: "solid",
                                                borderWidth: "thin",
                                                borderColor: "lightGrey",
                                                width: "50%",
                                            }}
                                            disabled={isEdit}

                                        >
                                            TEMPLATE
                                        </ToggleButton>

                                        <ToggleButton
                                            className={`themeButton white-button ${_isEmpty(_selectedTemplate) ? 'pointer-events-none' : ''}`}
                                            value="customise"
                                            fullWidth
                                            style={{
                                                border: "solid",
                                                borderWidth: "thin",
                                                borderColor: "lightGrey",
                                                width: "50%",
                                            }}
                                            sx={{ width: 1 }}
                                            disabled={_isEmpty(_selectedTemplate) }
                                        >
                                            CUSTOMISE
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                </Row>
                           
                                {activeTab == "template" ? (
                                    <WindowCardLayouts
                                        setSelectedTemplateLayout={setSelectedTemplateLayout}
                                        templates={windowCardsTemplateCategory}
                                        _setSelectedTemplate={_setSelectedTemplate}
                                    />
                                ) : (
                                    showEditSection ?
                                            <WindowCardEditSection formValues={formValues} setSeletctedDate={setSeletctedDate} /> :
                                        <WindowcardCustomise selectedTemplateLayout={selectedTemplateLayout}
                                            seletctedDate={seletctedDate}
                                            setSeletctedDate={setSeletctedDate} 
                                            isEdit={isEdit}
                                            selectedSocialTemplate={selectedSocialTemplate}
                                            _selectedTemplate={_selectedTemplate}
                                            />
                                )}
                            </Col>
                            <Col
                                lg={8} sm={8}
                                onClick={() => setEditBackground(true)}
                                style={{overflowX: 'scroll'}}
                            >
                                <WindowCardContent
                                    ref={ref}
                                    templateLayoutContent={_selectedTemplate}
                                    selectedTemplateLayout={selectedTemplateLayout}
                                    isEdit={isEdit}
                                    setSeletctedDate={setSeletctedDate}       

                                />
                            </Col>
                        </Row>
                    </Fragment>
                </form>
            </div>
        </Fragment>
    );
};
const mapState = (state, props) => {
    const values = getFormValues("new-digital-template")(state);
    return {
        testimonial: values?.testimonial,
        author: values?.author,
        template_image: values?.template_image?.[0]?.preview,
        templateText: values?.templateText,
        formValues: values
        // ...props.preFillContactProfile?.contact,
    };
};

export default compose(
    connect(mapState),
    reduxForm({
        form: "new-window-card",
        enableReinitialize: true,
        validate
    })
)(NewWindowCardForm);

