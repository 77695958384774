import { lazy } from "react";

import Builder from "../../../pages/home/Builder";

const ErrorPage = lazy(() => import(`../../../pages/errors/ErrorPage3`));
const Notification_settings = lazy(() =>
  import(`../../header/pages/notifications/NotificationSettings`)
);
const Resource_center = lazy(() =>
  import(`../../header/pages/resourceCenter/ResourceCenter`)
);
const Help_center = lazy(() =>
  import(`../../header/pages/helpCenter/HelpCenter`)
);
const My_profile = lazy(() => import(`../../header/pages/myProfile/MyProfile`));
const Listings = lazy(() => import(`../../listings/pages/ListingsPage`));
const SingleListing = lazy(() => import(`../../listings/pages/SingleListing`));
const Diary = lazy(() => import(`../../diary/pages/DiaryWrap`));
const Prospects = lazy(() => import(`../../prospect/pages/Prospects`));
const Buyers = lazy(() => import(`../../buyers/pages/BuyersPage`));
const Rentals = lazy(() => import(`../../rentals/pages/RentalsPage`));
const SingleRental = lazy(() => import(`../../rentals/pages/SingleRental`));

const Dashboard = lazy(() => import(`../../dashboard/pages/Dashboard`));
const News = lazy(() => import(`../../news/pages/NewsPage`));
const Settings = lazy(() => import(`../../setting/pages/SettingPage`));
const Contacts = lazy(() => import(`../../contacts/pages/ContactsPage`));
const Help = lazy(() => import(`../../help/pages/Help`));

const Tenants = lazy(() => import(`../../tenants/pages/TenantsPage`));
const System_boards = lazy(() =>
  import(`../../systemBoards/pages/SystemBoards`)
);
const Accounts = lazy(() => import(`../../accounts/pages/Accounts`));
const Bulk_filters = lazy(() => import(`../../bulkFilters/pages/BulkFilters`));
const Marketing = lazy(() => import(`../../marketing/pages/MarketingBoards`));
const Key_management = lazy(() => import(`../../keyManagement/KeyManagement`));

const Mail = lazy(() => import(`../../mail/pages/MailPage`));
// const Under_construction = lazy(() => import(`../../underConstruction/UnderConstruction`));
const Under_construction = lazy(() =>
  import(`../../marketing/TemplateCreator/index`)
);

export {
  Diary,
  Dashboard,
  News,
  Settings,
  Contacts,
  Help,
  SingleListing,
  Listings,
  Builder,
  Resource_center,
  Help_center,
  Buyers,
  Prospects,
  My_profile,
  Notification_settings,
  ErrorPage,
  Tenants,
  System_boards,
  Accounts,
  Rentals,
  SingleRental,
  Bulk_filters,
  Marketing,
  Under_construction,
  Key_management,
  Mail,
};
