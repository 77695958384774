import React, { Fragment, useEffect, useState } from "react";
import { components } from "react-select";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import AsyncSelect from "react-select/async";
import { InputLabel } from "@material-ui/core";
const menuHeaderStyle = {
  padding: "8px 12px",
};
function getLength(options) {
  return options.reduce((acc, curr) => {
    if (curr.options) return acc + getLength(curr.options);
    return acc + 1;
  }, 0);
}
const Menu = (props) => {
  const optionsLength = getLength(props.options);
  return (
    <Fragment>
      <div style={menuHeaderStyle}>
        Custom Menu with {optionsLength} options
      </div>
      <components.Menu {...props}>{props.children}</components.Menu>
    </Fragment>
  );
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <ArrowDropDownIcon />
    </components.DropdownIndicator>
  );
};

const SingleValue = (props) => (
  <components.SingleValue {...props}>{props.data.label}</components.SingleValue>
);

const AsyncMultiSelect = (props) => {
  const {
    edit,
    input,
    options,
    placeholder,
    defaultValue,
    isMulti,
    defaultRef,
    handleOnClick = () => { },
    label,
    error,
    defaultOptions,
    addWith,
    ...others
  } = props;

  const [item, setItem] = useState(null);
  const [typedText, setTypedText] = useState('');


  const handleChange = (e) => {
    // console.log(e);

    if (typeof handleOnClick != "undefined") {
      handleOnClick(e);
    }
    setTypedText('');
    input.onChange(e);
  };
  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      width: state.selectProps.width,
      borderBottom: "1px dotted pink",
      color: state.selectProps.menuColor,
      padding: 20,
    }),

    control: (_, { selectProps: { width } }) => ({
      width: width,
    }),

    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
  };
  const changedInput = (inputValue, { action }) => {
    if (action === "input-change") {
      handleOnClick(inputValue);
      setTypedText(inputValue);
    }
  }
  return (
    <Fragment>
      {edit ? (
        <div
          className={
            "multiSelect" +
            (error ? " required-border" : " not-required-border") +
            (!label ? " w-100" :  "")
          }
        >
          <InputLabel>{label}</InputLabel>
          <AsyncSelect
            {...others}
            // components={{ Menu }}
            inputValue={typedText}
            onInputChange={changedInput}
            onChange={handleChange}
            classNamePrefix="multiSelect"
            styles={{
              // Fixes the overlapping problem of the component
              menu: (provided) => ({ ...provided, zIndex: 9999 }),
            }}
            hideSelectedOptions={false}
            cacheOptions
            isClearable
            // defaultOptions={item}
            defaultValue={defaultOptions ? "Enter Suburb" : "Select" }
            loadOptions={options}
            value={input.value}
            isMulti={isMulti}
            closeMenuOnSelect={isMulti ? false : true}
            placeholder={placeholder}
            components={{ SingleValue, DropdownIndicator }}
            ref={defaultRef}
            // getOptionLabel={option =>
            //   <Fragment>
            //    { `${option.label} - ${option?.suburb_name} ${option?.state_name}  `}
            //   </Fragment>
            // }
          />
        </div>
      ) : (
        <h6>{input.value.value}</h6>
      )}
    </Fragment>
  );
};

AsyncMultiSelect.defaultProps = {
  isMulti: true, //  pass true or false to make that specific Select Field multiple input accept or not. By default it's can accept multiple input.
  edit: true, // pass true or false to make that specific field editable or not. By default it's editable
  showBottom: false, // pass true or false to make selected options Bottom of the option field.
};

export default AsyncMultiSelect;
