import React, { Fragment, useState, useEffect, useContext,useRef } from "react";

import { Row } from "react-bootstrap";
import NoteForm from "../../../../../partials/form/partials/note/NoteForm";
import ThemeButton from "../../../../../partials/button/ThemeButton";
import NoteLists from "../../../../../partials/component/notes/NoteLists";
import { FieldArray, reduxForm, formValueSelector } from "redux-form";
import moment from "moment";
import {
  convertFormData,
  formToQueryString,
  wbSuccessToast,
} from "../../../../../_helpers/_utils/utils";
import {
  getAllNotes,
  createNotes,
  updateNotes,
} from "../../../../../GeneralApi/GeneralApi";
import {
  getNotesByContactId,
  getNotesByContactIdAndCreatedBy,
  getTimelineByContactId,
} from "../../../crud/contactsCrud";
import AccessControl from "../../../../../_ accessControl/AccessControl";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ContactProfileContext } from "../ContactProfileContextProvider";
const ContactNotes = (props) => {
  const { contactId, handleSubmit, profile } = props;
  const [notes, setNotes] = useState([]);
  const [otherResData, setOtherResData] = useState([]);
  const [pastTimeline, setPastTimeline] = useState([]);
  const permissions = useSelector((state) => state.auth.user.permissions);
  const currentUser = useSelector(({ auth }) => auth.user.contact.id);
  const is_leader = useSelector((state) => state.auth.user.is_leader);
  const { setContactLoading } = useContext(ContactProfileContext);
  const selector = formValueSelector('contact-notes');
  const noteFormDataRedux = useSelector(state => selector(state, 'notes'));
  const currentFormDataRef = useRef(null);

  useEffect(() => {
    update();
  }, [contactId]);

  useEffect(() => {
    // if(props.submitSucceeded)
    // {
    //   document.getElementById(props.form+"-note").value = "";
    // }
  }, [props.submitSucceeded]);

  useEffect(() => {
    getPastUpdate(
      formToQueryString({
        type: 5,
        contact: contactId,
        direction: "past",
        // limit: 20,
      })
    );
  }, [contactId]);

  const getPastUpdate = (prams) => {
    getTimelineByContactId(prams)
      .then((res) => {
        console.log(res);
        let { data, ...other } = res.data;
        console.log(data);
        console.log(res.data);

        setPastTimeline(data);
      })
      .catch(() => {});
  };

  // console.log('=======pastTimeline=============================');
  // console.log(pastTimeline);
  // console.log(notes);
  // console.log('======pastTimeline==============================');

  let combine = pastTimeline?.map((past) => {
    // console.log('========past============================');
    // console.log(past?.text.startsWith("Made an offer "));
    // console.log('=========past===========================');
    let map;
    map = {
      created_at: past?.timeline_date,
      created_by: "",
      detail: past?.text.startsWith("Made an offer ") && past?.text,
      id: past?.id,
      pinned: null,
      type: past?.type,
    };

    return map;
  });

  let offers = combine?.filter((offer) => {
    let detailsWithText;
    if (offer.detail !== false) {
      detailsWithText = offer;
      return offer;
    }

    return detailsWithText;
  });

  // console.log("=======combine=============================");
  // console.log(combine);
  // console.log(notes.concat(offers));
  let listC = notes.concat(offers);
  // console.log(offers);
  // console.log("======combine==============================");
  listC.sort((a, b) => {
    return moment(a.created_at)
      .format("YYYY-MM-DDTHH:mm")
      .localeCompare(moment(b.created_at).format("YYYY-MM-DDTHH:mm"));
  });

  // console.log('===========listC=========================');
  // console.log(listC);
  // console.log('============listC========================');

  const update = (value) => {
    setContactLoading(true);
    let obj = {};
    obj.contact = contactId;

    if (contactId) {
      if (value?.page) {
        obj.page = value.page;
      }

      if (
        permissions?.some((permission) =>
          ["contacts-self"].includes(permission?.name)
        ) ||
        is_leader
      ) {
        // obj.created_by = currentUser;
        getNotesByContactIdAndCreatedBy(formToQueryString(obj))
          .then(({ data: { data, ...rest } }) => {
            if (value?.loadMore) {
              setNotes(notes.concat(data));
            } else {
              setNotes(data);
            }
            setOtherResData(rest);
            setContactLoading(false);
          })
          .catch(() => {
            setContactLoading(false);
          });
      } else if (
        permissions?.some((permission) =>
          ["contacts-all"].includes(permission?.name)
        ) ||
        is_leader
      ) {
        getNotesByContactIdAndCreatedBy(formToQueryString(obj))
          .then(({ data: { data, ...rest } }) => {
            if (value?.loadMore) {
              // setNotes(notes.concat(data));
              setNotes(notes.concat(combine && combine));
            } else {
              setNotes(data);
            }
            setOtherResData(rest);
            setContactLoading(false);
          })
          .catch(() => {
            setContactLoading(false);
          });
      }
    }
  };

  const handleMakePinned = (noteId, isPinned) => {
    const formData = convertFormData({});
    formData.append("pinned", isPinned === 1 ? 0 : 1);
    formData.append("_method", "PATCH");
    if (contactId) {
      updateNotes(noteId, formData)
        .then(({ data: { data } }) => {
          wbSuccessToast("success", data.message);
          update();
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  useEffect(() => {
    currentFormDataRef.current = noteFormDataRedux;
  }, [noteFormDataRedux]);

  useEffect(() => {
    return () => {
      handleOnSubmit({notes: currentFormDataRef.current});
    };
  }, []);

  const handleOnSubmit = (e) => {
    if (e && e?.notes?.length) {
      const formData = convertFormData(e.notes[0]);
      formData.append("contact", contactId);
      if (contactId) {
        createNotes(formData)
          .then(({ data: { data } }) => {
            wbSuccessToast("success", data.message);
            update();
            document.getElementById("contact-notes-note").value = "";
          })
          .catch((e) => {
            console.log(e);
          });
      }
    } else {
      //toast.error("Note is empty");
    }
  };
  useEffect(() => {
    props.destroy();
  }, [props.submitSucceeded]);

  const fetchMore = () => {
    if (otherResData?.next_page_url) {
      update({ page: otherResData.current_page + 1, loadMore: true });
    }
  };
  return (
    <Fragment>
      <form className="contactForm" onSubmit={handleSubmit(handleOnSubmit)}>
        <FieldArray
          placeholder="Enter a note about this contact..."
          name="notes"
          component={NoteForm}
        />
      </form>
      <AccessControl
        allowedPermissions={["contacts-download"]}
        renderNoAccess={() => ""}
      >
        <div className="d-flex justify-content-end">
          <div className="pb-2">
            <ThemeButton icon>
              <i className="fas fa-download"></i>
            </ThemeButton>
          </div>
        </div>
      </AccessControl>

      <Row></Row>
      <NoteLists
        makePinned={handleMakePinned}
        fetchMore={fetchMore}
        notes={listC}
        profile={profile}
      />
    </Fragment>
  );
};

export default reduxForm({ form: "contact-notes" })(ContactNotes);
