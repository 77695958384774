import React, {
  useState,
  Fragment,
  useCallback,
  useContext,
} from "react";
import moment from "moment";
import { Row } from "react-bootstrap";
import { useReactToPrint } from "react-to-print";
import _debounce from "lodash/debounce";

import { WindowCardContext } from "./WindowCardContextProvider";
import ThemeHeader from "../../../../partials/content/ThemeHeader";
import ThemeButton from "../../../../partials/button/ThemeButton";
import TableFilter from "../../../../partials/table/TableFilter";
import DynamicTableEdit from "../../../../partials/table/DynamicTableEdit";
import WindowCardSettings from "./WindowCardSettingsModal";
import DefaultModal from "../../../../partials/modal/DefaultModal";

import "./cards.css";
import Orientation_icon from "./icons/orientation_icon.png";
import Paper_icon from "./icons/paper_icon.png";
import { LinearProgress } from "@material-ui/core";


// import { downloadImageFromUrl } from '../../../../_helpers/_utils/utils';
// import LinearProgressLoading from "../../../../partials/icon/LinearProgressLoading";
// import AccessControl from "../../../../_ accessControl/AccessControl";
// import ToolTip from "../../../../partials/tooltip/ToolTip";
// import WindowCardContextProvider from "./WindowCardContextProvider";
// import Icon from '@ant-design/icons';

const PrintIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 24 24"
  >
    <path d="M24 5h-4v-5h-16v5h-4v13h4v6h9.519c2.947 0 6.029-3.577 6.434-6h4.047v-13zm-18-3h12v3h-12v-3zm8.691 16.648s1.469 3.352-2 3.352h-6.691v-8h12v2.648c0 3.594-3.309 2-3.309 2zm6.809-10.648c-.276 0-.5-.224-.5-.5s.224-.5.5-.5.5.224.5.5-.224.5-.5.5zm-5.5 9h-8v-1h8v1zm-3 1h-5v1h5v-1z" />
  </svg>
);
const WindowCardsTable = ({ setOpen, setShowAddNewWindowCard, template }) => {
  const {
    setSelectedSocialTemplate,
    socialMediaTemplateLoading,
    handleTemplateDelete,
    fetchMoreData,
    handleGlobalSearch,
  } = useContext(WindowCardContext);
  const [openSettingsModal, SetOpenSettingsModal] = useState(false);

  const [selectedRows, setSelectedRows] = useState([]);
  // const [showPreview, setShowPreview] = useState(false);
  // const [openPrint, setOpenPrint] = useState(false);
  // const [printData, setPrintData] = useState({});
  // const previewData = useRef({ data: "" });

  const rowClick = (value) => {
    setShowAddNewWindowCard(true);
    setSelectedSocialTemplate(value);
  };
  const handleSort = useCallback((sortBy) => {
    let obj = {};
    if (sortBy?.[0]) {
      obj.sort_by = sortBy[0].id;
      if (sortBy[0].desc) {
        obj.direction = "desc";
      } else {
        obj.direction = "asc";
      }
    }
  }, []);

  const ReactToPrintPDF = ({ data }) => {
    const [html, setHtml] = React.useState(null);
    const componentRef = React.useRef(null);
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
      documentTitle: `${data.name}`,
      removeAfterPrint: true,
      copyStyles: true,
    });

    React.useEffect(() => {
      // debugger
      let templateUrl = data?.assets?.find(
        (item) => item.type?.id === 5 && item.extension === "html"
      )?.url;

      if (templateUrl) {
        fetch(templateUrl).then((r) => r.text()).then((t) => {
            setHtml(t)
          }).catch((err)=>{
            
            console.log(err);
     
          })
      }
    }, [data]);

    window.onload = function() {
      document.getElementById("A4_potrait_print").style.cssText =
        "width: 1189px !important;height: 1680px !important;padding: 0 !important;";
    };

    return (
      <>
        <i onClick={handlePrint}>{PrintIcon()}</i>
        <div style={{ display: "none" }}>
          <div
            ref={componentRef}
            dangerouslySetInnerHTML={{ __html: ` <div>${html}</div>` }}
          ></div>
        </div>
      </>
    );
  };

  const renderPreviewandDownload = (original) => {
    // setPrintData(original)
    return (
      <>
        <ThemeButton icon>
          <div>
            <ReactToPrintPDF data={original} />
          </div>
        </ThemeButton>
        <ThemeButton icon></ThemeButton>
      </>
    );
  };
  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        columns: [
          {
            Header: "Date",
            width: 25,
            accessor: "created_at",
            Cell: ({ cell: { value } }) => (
              <p className="text-center">
                {moment(value).format("DD/MM/YYYY")}
              </p>
            ),
          },
          {
            Header: "Template",
            accessor: "category",
            Cell: ({
              cell: {
                value,
                row: { original },
              },
            }) => (
              <p onClick={() => rowClick(original)} className="cursor-hand">
                {value?.name ?? ""}
              </p>
            ),
          },
          {
            Header: "Title",
            accessor: "name",
            Cell: ({
              cell: { value },
              cell: {
                row: { original },
              },
            }) => (
              <div onClick={() => rowClick(original)} className="cursor-hand ">
                <p>{original?.name ?? ""} </p>{" "}
              </div>
            ),
          },
          {
            Header: "Type",
            accessor: "type",
            Cell: ({
              cell: {
                value,
                row: { original },
              },
            }) => (
              <p onClick={() => rowClick(original)} className="cursor-hand">
                <p>
                  <img src={Paper_icon} alt="icon" width="25px" height="25px" />
                  <span style={{ marginLeft: 10 }}>{original?.type}</span>
                </p>
              </p>
            ),
          },
          {
            Header: "Orientation",
            accessor: "orientation",
            Cell: ({
              cell: {
                value,
                row: { original },
              },
            }) => (
              <div className="d-flex justify-content-between">
                <p onClick={() => rowClick(original)} className="cursor-hand">
                  <p style={{ textTransform: "capitalize" }}>
                    <img
                      src={Orientation_icon}
                      alt="icon"
                      width="25px"
                      height="25px"
                    />
                    <span style={{ marginLeft: 10 }}>
                      {" "}
                      {original?.orientation}
                    </span>
                  </p>
                </p>
                <div className="d-flex">
                  {" "}
                  {renderPreviewandDownload(original)}
                </div>
              </div>
            ),
          },
        ],
      },
    ],
    []
  );

  const onSearch = _debounce(handleGlobalSearch, 500);

  // const handleDelete = () => {};

  const handleClose = () => {
    SetOpenSettingsModal(false);
  };
  return (
    <div>
      <div className="pl-4">
        <ThemeHeader
          noScroll
          left={
            <Fragment>
              <h5 className="title">Window Cards</h5>

              {/* <ToolTip
                                className="mt-2"
                                name="cog"
                                content="setup"
                                onClick={() => SetOpenSettingsModal(true)}
                            /> */}
            </Fragment>
          }
          right={
            <Row style={{ margin: "auto" }}>
              <div className="p-2">
                <TableFilter
                  // length={prospects?.length}
                  handleGlobalSearch={onSearch}
                />
              </div>
              {/* <div className="pl-1">
                                <ThemeButton onClick={(e) => setOpen(true)}>Filter</ThemeButton>
                            </div> */}
              <div className="pl-1">
                <ThemeButton
                  className={"lightGrey-button"}
                  onClick={() => handleTemplateDelete(selectedRows)}
                >
                  Delete
                </ThemeButton>
              </div>
              <div className="pl-1 ">
                <ThemeButton
                  className={"green-button"}
                  onClick={(e) => setShowAddNewWindowCard(true)}
                >
                  Add
                </ThemeButton>
              </div>
            </Row>
          }
        />{" "}
        {openSettingsModal && (
          <DefaultModal
            size="xl"
            centered
            show={openSettingsModal}
            onHide={() => handleClose()}
          >
            <WindowCardSettings />
          </DefaultModal>
        )}
        {socialMediaTemplateLoading ? <LinearProgress /> : null}
        <div className="dynamic-table">
          <DynamicTableEdit
            columns={columns}
            data={template}
            handleChange={(e) => setSelectedRows(e)}
            // handleRowClick={(e) => rowClick(e)}
            isEditable={true}
            update={fetchMoreData}
            mainBoard
            disableSort={false}
            onSort={handleSort}
          />
        </div>
      </div>
    </div>
  );
};

export default WindowCardsTable;
