import React, { Fragment } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { Field, FieldArray } from "redux-form";
import { UserImageInput } from "../../../../../partials/form/UserImageInput";
import FormLinkedContacts from "../../../../../partials/form/partials/FormLinkedContacts";
import ContactFormSocialInfo from "../../../../../partials/form/partials/ContactFormSocialInfo";
import FormTags from "../../../../../partials/form/partials/FormTags";
import ThemeButton from "../../../../../partials/button/ThemeButton";
import { deleteContact } from "../../../crud/contactsCrud";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import * as contactsRedux from "../../../_redux/contactsRedux";
import { UserImageInputWithCropper } from "../../../../../partials/form/UserImageInputWithCropper";

const ContactFormSideBar = ({ profileId, closeModal, queryString,
  contactsLoading }) => {

  const handleDelete = (key) => {
    let value = {};
    value.id = key;
    deleteContact(key)
      .then(({ data: { data } }) => {
        console.log(data);
        toast.success("File Deleted Successfully");
        contactsLoading(queryString);
        closeModal();
      })
      .catch((e) => { });
  };
  return (
    <Fragment>
      <Form.Group as={Row}>
        <Col sm={12}>
          <div className="p-md-5 p-sm-3 profile-picture-wrap">
            <Field
              name="profile_picture"
              component={UserImageInputWithCropper}
              className='profile'
              dropZoneStyle={{ width: '150px', height: '150px', borderRadius: '50%' }}
            />
          </div>
        </Col>
      </Form.Group>
      <ContactFormSocialInfo />
      <Form.Group as={Row}>
        <FieldArray
          name="linked_contacts"
          fullButton
          component={FormLinkedContacts}
        />
        {/* <FormLinkedContacts /> */}
      </Form.Group>
      <Form.Group as={Row}>
        <FormTags />
      </Form.Group>

      <Form.Group as={Row} className="pt-5">
        <Col>
          {profileId ? <ThemeButton
            onClick={() => handleDelete(profileId)}
            className="brand-button w-100 font-weight-bold"
          >
            Delete
          </ThemeButton> : ''}

        </Col>

      </Form.Group>
    </Fragment>
  );
};

const mapStateToProps = ({ contacts: { queryString } }) => ({
  queryString: queryString,
});

export default connect(
  mapStateToProps,
  contactsRedux.actions
)(ContactFormSideBar);