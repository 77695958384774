import React, { Fragment, useContext, useState } from "react";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import EmailIcon from "@material-ui/icons/Email";
import EventIcon from "@material-ui/icons/Event";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import ThemeHeader from "../../../../../partials/content/ThemeHeader";
import ThemeButton from "../../../../../partials/button/ThemeButton";
import clsx from "clsx";
import DefaultModal from "../../../../../partials/modal/DefaultModal";
import Email from "../../../../../partials/component/email/Email";
import Sms from "../../../../../partials/component/sms/Sms";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import CallReminder from "../../../../../partials/component/callReminder/CallReminder";
import NoteRemoteFormButton from "../../../partials/NoteRemoteFormButton";
import { ProspectProfileContext } from "../ProspectProfileContextProvider";
import moment from "moment";
import Appointment from "../../../../diary/partials/modals/appointment/Appointment";

import AddNewListings from "../../../../listings/modals/addNewListings/AddNewListings";
import { default as AddNewRental } from "../../../../rentals/modals/addNewRentals/AddNewRental";

import {
  getProcessedAddress,
  getProcessedDomainAddress,
} from "../../../../../_helpers/_utils/utils";
import AddNewInspection from "../newInspection/AddNewInspection";
import NewInspectionPopover from "./NewInspectionPopover";
import { ReactComponent as VideoPresentation } from "../../../../../../_metronic/_assets/media/icons/wb/presentation.svg";
import ToolTipForSvg from "../../../../../partials/tooltip/ToolTipForSvg";
import _isEmpty from "lodash/isEmpty";
// import _last from "lodash/last";
import { connect } from "react-redux";
import BuyerReferral from "../../../../buyers/modals/buyerReferral/BuyerReferral";

const ProspectProfileHeader = (props) => {
  const {
    closeModal,
    selectedHeaderTab,
    // updateTable,
    setPageCount,
    // otherResData,
  } = props;

  const {
    prospectProfile,
    prospects,
    prospectIndex,
    continuousMode,
    setContinuousMode,
    changeDirection,
    prospectId,
    prospectLoading,
    setProspectLoading,
    prospectNotContacted,
    setPc,
    setDk,
  } = useContext(ProspectProfileContext);

  const prospectAddress = prospectProfile?.property?.address;
  const [showSms, setShowSms] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [showReferral, setShowReferral] = useState(false);
  const [showAppointment, setShowAppointment] = useState(false);
  const [showCallReminder, setShowCallReminder] = useState(false);
  const [showOpenHome, setShowOpenHome] = useState(false);
  // const [showPresent, setShowPresent] = useState(false);
  // const [showCheckList, setShowCheckList] = useState(false);
  // const [newProspectPopover, setNewProspectPopover] = useState(false);
  const [features, setFeatures] = useState([]);
  const [fillFormData, setFillFormData] = useState({});

  const [showAddListing, setShowAddListing] = useState(false);
  const [showAddRental, setShowAddRental] = useState(false);

  // const handleClosemodal = () => {
  //   // updateTable({})
  //   closeModal();
  // };

  // let count = 0;

  const handleDoorKnock = (e) => {
    if (continuousMode && prospects && prospects?.length != prospectIndex) {
      handleClickedRight("right");
    }
  };
  const handleClickedLeft = () => {
    changeDirection("left");
  };

  const handleClickedRight = () => {
    changeDirection("right");
    // setPageCount((count) => count + 1);
  };
  let newProspectprofile = {
    ...prospectProfile,
    primary_contact: {
      ...prospectProfile.primary_contact,
      addresses: prospectProfile?.contacts?.length
        ? prospectProfile?.contacts[0]?.addresses
        : [],
      emails: prospectProfile?.contacts?.length
        ? prospectProfile?.contacts[0]?.emails
        : [],
      phones: prospectProfile?.contacts?.length
        ? prospectProfile?.contacts[0]?.phones
        : [],
    },
  };

  const handleClose = () => {
    // Swal.fire({
    //   title: "Warning",
    //   text: "Are you sure want to exit?",
    //   icon: "warning",
    //   showCancelButton: true,
    //   allowOutsideClick: false,
    //   allowEscapeKey: false,
    //   confirmButtonColor: "#A7A9AC",
    //   cancelButtonColor: "#2BB673",
    //   confirmButtonText: "Cancel",
    //   cancelButtonText: "Yes",
    // }).then((result) => {
    //   if (result.isDismissed) {
    //     setShowSettings(false);
    //     setShowAddListing(false);
    //   }
    // });
  };

  return (
    <Fragment>
      {showSms ? (
        <DefaultModal
          size="xl"
          centered
          show={showSms}
          onHide={() => setShowSms(false)}
        >
          <Sms
            closeModal={() => setShowSms(false)}
            data={prospectProfile?.contacts}
          />
        </DefaultModal>
      ) : (
        ""
      )}

      {showEmail ? (
        <DefaultModal
          size="xl"
          centered
          show={showEmail}
          onHide={() => setShowEmail(false)}
        >
          <Email
            closeModal={() => setShowEmail(false)}
            data={prospectProfile?.contacts}
          />
        </DefaultModal>
      ) : (
        ""
      )}
      {showAppointment ? (
        <DefaultModal
          size="xl"
          centered
          show={showAppointment}
          onHide={() => setShowAppointment(false)}
        >
          <Appointment
            setFillFormData={setFillFormData}
            fillFormData={fillFormData}
            initialValues={{
              starts: moment().format("YYYY-MM-DDTHH:mm"),
              ends: moment().format("YYYY-MM-DDTHH:mm"),
              contacts: [
                prospectProfile?.contacts && prospectProfile?.contacts[0],
              ],
              app_type: 4,

              prospect_address: {
                meeting_location: [
                  getProcessedAddress(prospectAddress),
                  // {
                  //   ...prospectProfile?.address,
                  //   street_name: prospectProfile?.address?.street?.name,
                  //   suburb:
                  //     prospectProfile?.address?.street?.suburb,
                  //   state:
                  //     prospectProfile?.address?.street?.suburb
                  //       .state,
                  // },
                ],
                domain_address: getProcessedDomainAddress(
                  prospectProfile?.property?.address
                ),
              },
            }}
            closeModal={() => setShowAppointment(false)}
          />{" "}
        </DefaultModal>
      ) : (
        ""
      )}
      {showReferral ? (
        <DefaultModal
          size="xl"
          centered
          show={showReferral}
          onHide={() => setShowReferral(false)}
        >
          <BuyerReferral
            buyerId={prospectId}
            profile={newProspectprofile}
            newref={true}
            closeModal={() => setShowReferral(false)}
            prospectProfileType={true}
          />
        </DefaultModal>
      ) : (
        ""
      )}

      {showCallReminder ? (
        <DefaultModal
          size="xl"
          centered
          show={showCallReminder}
          onHide={() => setShowCallReminder(false)}
        >
          <CallReminder
            prospectId={prospectId}
            contactId={prospectProfile?.contacts[0]?.id}
            closeModal={() => setShowCallReminder(false)}
            initialValues={{ for: props?.user?.contact?.id }}
          />
        </DefaultModal>
      ) : (
        ""
      )}

      {showOpenHome && features ? (
        <DefaultModal
          size="xl"
          centered
          show={showOpenHome}
          onHide={() => setShowOpenHome(false)}
        >
          <AddNewInspection
            features={features}
            prospectId={prospectId}
            contactId={prospectProfile?.contacts?.[0]?.id}
            closeModal={() => setShowOpenHome(false)}
          />
        </DefaultModal>
      ) : (
        ""
      )}

      <ThemeHeader
        noScroll
        // left={
        //   <div className="pl-4 ml-2 max-content">
        //     {" "}
        //     <NoteRemoteFormButton
        //       className={
        //         prospectLoading || selectedHeaderTab !== 0
        //           ? "disable-prospect-header-btn"
        //           : ""
        //       }
        //       disabled={prospectLoading || selectedHeaderTab !== 0}
        //       // <NoteRemoteFormButton
        //       setNAButtonLoading={setProspectLoading}
        //       setPc={setPc}
        //       label={"PC"}
        //       handleDoorKnock={handleDoorKnock}
        //       formName="prospect-notes"
        //     />
        //     <NoteRemoteFormButton
        //       className={
        //         prospectLoading || selectedHeaderTab !== 0
        //           ? "disable-prospect-header-btn"
        //           : ""
        //       }
        //       disabled={prospectLoading || selectedHeaderTab !== 0}
        //       // <NoteRemoteFormButton
        //       setDk={setDk}
        //       label={"DK"}
        //       setNAButtonLoading={setProspectLoading}
        //       handleDoorKnock={handleDoorKnock}
        //       formName="prospect-notes"
        //     />
        //     <NoteRemoteFormButton
        //       className={
        //         prospectLoading || selectedHeaderTab !== 0
        //           ? "disable-prospect-header-btn"
        //           : ""
        //       }
        //       disabled={prospectLoading || selectedHeaderTab !== 0}
        //       setNAButtonLoading={setProspectLoading}
        //       label={"NA"}
        //       handleDoorKnock={handleDoorKnock}
        //       formName="prospect-notes"
        //     />
        //   </div>
        // }
        className="pb-3"
        right={
          <Fragment>
            <ToolTipForSvg content="SMS">
              <ThemeButton
                className={
                  _isEmpty(prospectProfile) ? "disable-prospect-header-btn" : ""
                }
                disabled={_isEmpty(prospectProfile)}
                onClick={() => setShowSms(true)}
                icon
              >
                <PhoneAndroidIcon />
              </ThemeButton>
            </ToolTipForSvg>
            <ToolTipForSvg content="Email">
              <ThemeButton
                className={
                  _isEmpty(prospectProfile) ? "disable-prospect-header-btn" : ""
                }
                disabled={_isEmpty(prospectProfile)}
                onClick={() => setShowEmail(true)}
                icon
              >
                <EmailIcon />
              </ThemeButton>
            </ToolTipForSvg>
            <ToolTipForSvg content="Appointment">
              <ThemeButton
                className={
                  _isEmpty(prospectProfile) ? "disable-prospect-header-btn" : ""
                }
                disabled={_isEmpty(prospectProfile)}
                onClick={() => setShowAppointment(true)}
                icon
              >
                <EventIcon />
              </ThemeButton>
            </ToolTipForSvg>
            <ToolTipForSvg content="New Inspection">
              <NewInspectionPopover
                prospectProfile={prospectProfile}
                setShowOpenHome={setShowOpenHome}
                setShowListingModal={setShowAddListing}
                setShowRentalModal={setShowAddRental}
              />
            </ToolTipForSvg>

            <ToolTipForSvg content="Referral">
              <ThemeButton
                className={
                  _isEmpty(prospectProfile) ? "disable-prospect-header-btn" : ""
                }
                disabled={_isEmpty(prospectProfile)}
                onClick={() => setShowReferral(true)}
                icon
              >
                <MonetizationOnIcon />
              </ThemeButton>
            </ToolTipForSvg>
            <ToolTipForSvg content="Call Reminder">
              <ThemeButton
                className={
                  _isEmpty(prospectProfile) ? "disable-prospect-header-btn" : ""
                }
                disabled={_isEmpty(prospectProfile)}
                onClick={() => setShowCallReminder(true)}
                icon
              >
                <AccessTimeIcon />
              </ThemeButton>
            </ToolTipForSvg>

            <ToolTipForSvg content="Present">
              <ThemeButton
                className={
                  _isEmpty(prospectProfile) ? "disable-prospect-header-btn" : ""
                }
                disabled={_isEmpty(prospectProfile) || true}
                icon
                // onClick={() => setShowPresent(true)}
              >
                <VideoPresentation className="disable-prospect-header-btn kt-svg-icon kt-svg-icon--primary" />
              </ThemeButton>
            </ToolTipForSvg>
            {/* <ThemeButton icon onClick={() => setShowCheckList(true)}>
              <i className="far fa-calendar-check"></i>{" "}
            </ThemeButton> */}

            {prospects?.length > 1 ? (
              <div className={clsx("btn-toolbar nav-icon-button")}>
                <div className="btn-group">
                  <ThemeButton
                    disabled={
                      prospectLoading ||
                      prospects[0]?.id === prospectId ||
                      (continuousMode && prospectNotContacted[0]?.id) ===
                        prospectId ||
                      _isEmpty(prospectProfile)
                    }
                    onClick={() => handleClickedLeft()}
                    icon
                  >
                    <i
                      className={
                        prospectLoading ||
                        prospects[0]?.id === prospectId ||
                        _isEmpty(prospectProfile)
                          ? "disable-prospect-header-btn fas fa-caret-left"
                          : "fas fa-caret-left"
                      }
                    ></i>
                  </ThemeButton>
                  <ThemeButton
                    className={clsx({
                      "green-icon-button": continuousMode,
                    })}
                    disabled={_isEmpty(prospectProfile)}
                    onClick={() => {
                      setContinuousMode(!continuousMode);
                    }}
                    icon
                  >
                    <i
                      className={
                        prospectLoading | _isEmpty(prospectProfile)
                          ? "disable-prospect-header-btn fas fa-infinity"
                          : "fas fa-infinity"
                      }
                    ></i>
                  </ThemeButton>

                  {continuousMode ? (
                    <ThemeButton onClick={() => handleClickedRight()} icon>
                      <i className={"fas fa-caret-right"}></i>
                    </ThemeButton>
                  ) : (
                    <ThemeButton
                      disabled={prospectLoading || _isEmpty(prospectProfile)}
                      onClick={() => handleClickedRight()}
                      icon
                    >
                      <i
                        className={
                          prospectLoading | _isEmpty(prospectProfile)
                            ? "disable-prospect-header-btn fas fa-caret-right"
                            : "fas fa-caret-right"
                        }
                      ></i>
                    </ThemeButton>
                  )}
                </div>
              </div>
            ) : (
              ""
            )}

            <ThemeButton className="lightGrey-button" onClick={closeModal}>
              Close
            </ThemeButton>

            {showAddListing && (
              <DefaultModal
                size="xl"
                centered
                show={showAddListing}
                onHide={() => setShowAddListing(false)}
              >
                <AddNewListings
                  closeModal={() => setShowAddListing(false)}
                  // setListingType={() => {}}
                  prospectAddress={prospectAddress}
                />
              </DefaultModal>
            )}

            {showAddRental && (
              <DefaultModal
                size="xl"
                centered
                show={showAddRental}
                onHide={() => setShowAddRental(false)}
              >
                <AddNewRental
                  closeModal={() => setShowAddRental(false)}
                  prospectAddress={prospectAddress}
                />
              </DefaultModal>
            )}
          </Fragment>
        }
      />
    </Fragment>
  );
};

const mapState = (state) => ({
  user: state.auth.user,
});
export default connect(mapState)(ProspectProfileHeader);
