import React, { Fragment } from "react";
import Email from "../../../../../../partials/component/email/Email";

const PreviewEmail = ({ closeModal }) => {
  return (
    <Fragment>
      <Email data={[]} closeModal={closeModal} />
    </Fragment>
  );
};

export default PreviewEmail;
