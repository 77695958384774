import React, { Fragment, useEffect } from "react";
import NoteForm from "../../../../../partials/form/partials/note/NoteForm";
import { FieldArray, reduxForm, submit } from "redux-form";
import { useDispatch } from 'react-redux';

const validate = (values) => {
  const errors = {};
  // console.log(values)
  // if (!values.notes || !values.notes.length) {
  //   errors.notes = { _error: 'At least one note must be entered' }
  // } else {
  //   const notesArrayErrors = []
  //   values.notes.forEach((member, index) => {
  //     const noteErrors = {}
  //     if (!member || !member.detail) {
  //       noteErrors.detail = 'Required'
  //       notesArrayErrors[index] = noteErrors
  //     }
  //     if (member.detail.length < 15) {
  //       noteErrors.detail = 'Note must be 15 characters or less'
  //       notesArrayErrors[index] = noteErrors
  //     }
  //   })
  //   if (notesArrayErrors.length) {
  //     errors.notes = notesArrayErrors
  //   }
  // }

  console.log(errors);
  return errors;
};

const BuyerNotesForm = (props) => {
  console.log("test");
  const { handleSubmit, handleClearNote } = props;
  const dispatch = useDispatch()
// console.log('============BuyerNotesForm========================');
// console.log(props);
// console.log('=============BuyerNotesForm=======================');
  useEffect(() => {
    // if(props.submitSucceeded)
    // {
    //   document.getElementById(props.form+"-note").value = "";
    // }
  }, [props.submitSucceeded]);

  useEffect(() => {
    // Your existing code
    console.log("1")
    return () => {
      // Dispatch a submit action when the component unmounts
      console.log("2")
      handleSubmit()
    };
  }, []);

  return (
    <Fragment>
      <form className="contactForm" onSubmit={handleSubmit}>
        <FieldArray
          name="notes"
          defaultValue={{ id: 1, name: "Buyer" }}
          component={NoteForm}
        />
      </form>
    </Fragment>
  );
};

export default reduxForm({ form: "buyer-notes", onSubmit: submit, validate })(
  BuyerNotesForm
);
