import React from "react";
import { Avatar } from "@material-ui/core";
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from "@material-ui/core/styles";
import { OverlayTrigger, Popover } from "react-bootstrap";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    fontSize: 10,
    lineHeight: 2,
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

export const CharacterCircle = ({
  character,
  // color,
  background,
  small,
}) => {
  const classes = useStyles();
  const customClass = `darkGrey-color font-weight-bold ${background?.toLowerCase()}-background  ${
    small ? classes.small : classes.large
  }`;

  return (
    <OverlayTrigger
      // trigger="hover"
      placement="top"
      overlay={
        <Popover
          className="character-circle"
          style={{
            backgroundColor: "#707070",
            color: "black",
            borderRadius: "5px",
          }}
          id="popover-basic"
        >
          <div className="px-3 py-2 text-capitalize">
            {background ?? character}
          </div>
        </Popover>
      }
    >
      <Avatar className={customClass}>{character}</Avatar>
    </OverlayTrigger>
  );
};
