export const number = (value) => {
  console.log(value, "got_validation");
  return value && isNaN(Number(value)) ? "Must be a number" : undefined;
};
export const maxValue = (max) => (value) =>
  value && value > max ? `Must be less than ${max}` : undefined;

export const required = (value) => (value ? undefined : "Required");

export const validEmail = (value) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(value).toLowerCase()) ? undefined : "Enter valid email";
}

export const validEmailSequence = (value) => {
  const emailData = String(value).toLowerCase().split(',');
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const checkInValid = emailData.filter((email) => {
    let tempEmail = String(email).trim();
    return tempEmail && !re.test(String(tempEmail).toLowerCase());
  });
  return checkInValid?.length ? "Enter valid email" : undefined;
}
