import React, { Fragment, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import Item from "../../../../../partials/display/Item";
import DynamicTableEdit from "../../../../../partials/table/DynamicTableEdit";
import {
  addNumberSeparator,
  currencyFormatter,
} from "../../../../../_helpers/_utils/utils";

const TenantDetailsView = ({ profile }) => {
  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        columns: [
          {
            Header: "Features",
            accessor: "feature",
            Cell: ({
              cell: { value },
              cell: {
                row: { original },
              },
            }) => <p>{value.name}</p>,
          },
          {
            Header: "Preference",
            accessor: "preference",
            Cell: ({
              cell: { value },
              cell: {
                row: { original },
              },
            }) => <p>{value}</p>,
          },
          {
            Header: "Comments",
            accessor: "comments",
            Cell: ({
              cell: { value },
              cell: {
                row: { original },
              },
            }) => <p>{value}</p>,
          },
        ],
      },
    ],
    []
  );
  return (
    <Fragment>
      <div className="tenantDetails">
        <Row className="p-3">
          <Col xl="12">
            <div className="title font-weight-bold">Tenant Details</div>
          </Col>
          <Col xl="4">
            <Item
              lg="5"
              md="5"
              col="5"
              left="Tenant type"
              right={profile?.type?.name}
            />
            <Item
              lg="5"
              md="5"
              col="5"
              left="Been Looking"
              right={`${profile?.looking_date} ${profile?.looking_date_unit?.name}`}
            />
          </Col>
          <Col xl="4">
            <Item
              lg="5"
              md="5"
              col="5"
              left="Rating"
              right={profile?.rating?.name}
            />
            <Item
              lg="5"
              md="5"
              col="5"
              left="Ready In"
              right={`${profile?.ready_in_date} ${profile?.ready_in_date_time_unit?.name}`}
            />
          </Col>
          <Col xl="4">
            <Item
              lg="5"
              md="5"
              col="5"
              left="Active?"
              right={profile?.active == 1 ? "Yes" : "No"}
            />
            <Item
              lg="5"
              md="5"
              col="5"
              left="Motivation"
              right={profile?.motivation}
            />
          </Col>
        </Row>
        <Row className="p-3">
          <Col xl="12">
            <div className="title font-weight-bold">Preferences</div>
          </Col>
          <Col xl="12">
            <Item
              left="Price Range"
              right={`$${addNumberSeparator(
                profile?.rent_from
              )} - $${addNumberSeparator(profile?.rent_to)}`}
            />
            <Item
              left="Locations"
              right={profile?.suburbs?.map((suburb) => (
                <p>{suburb?.name}</p>
              ))}
            />
            <Item
              left="Property Types"
              right={profile?.property_types?.map((type) => (
                <p>{type?.name}</p>
              ))}
            />
          </Col>
        </Row>
        {/* <TenantDetailsTable data={table}/> */}
        <DynamicTableEdit
          columns={columns}
          data={profile?.features}
          height="60"
        />
      </div>
    </Fragment>
  );
};

export default TenantDetailsView;
