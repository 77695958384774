import React, { PropTypes, useState, Fragment, useEffect } from "react";
import Select from "react-select";
import cuid from "cuid";
import { Row, Col } from "react-bootstrap";
import Item from "../../../../partials/display/Item";

import AsyncSelect from "react-select/async";
const VerticalAsyncMultiSelect = (props) => {
  const { input, options, placeholder,SingleValue, label,handleOnClick, ...others } = props;
  const [item, setItem] = useState(null);
  useEffect(() => {
    setItem([input.value]);
  
}, [input.value]);
  //   Remove Selected Item
  const handleRemove = (label) => {
    input.onChange(
      input.value.filter(function(el) {
        return el.label !== label;
      })
    );
  };
  const handleChange = (e) => {
    console.log(e)
    input.onChange(e);
  };
  return (
    <Fragment>
      <Row className="pb-3">
        <Col xl="6">
        <Item xs={props.xs} md={props.md} lg={props.lg} left={label} right={<div className="multiSelect">
        <AsyncSelect
            {...others}
            // components={{ Menu }}
              onChange={handleChange}
              classNamePrefix="multiSelect"
              hideSelectedOptions={false}
              cacheOptions
              defaultOptions={item}
              // defaultValue={defaultValue ? defaultValue : item[0] }
              loadOptions={options}
              value={input.value}
              isMulti={true}
              closeMenuOnSelect={false}
              placeholder={placeholder}
              components={{ SingleValue }} 
              // getOptionLabel={option =>
              //   <Fragment>
              //    { `${option.label} - ${option?.suburb_name} ${option?.state_name}  `}
              //   </Fragment>
              // }
              styles={{
                // Fixes the overlapping problem of the component
                menu: provided => ({ ...provided, zIndex: 9999 })
              }}
            />
              </div>}/>
        </Col>
        <Col xl="6">
          <div className="d-flex flex-wrap selectedItems justify-content-start justify-content-lg-start">
            {input.value?.length > 0 &&
              input.value.map((item) => (
                <div key={cuid()} className="btn-group pr-2 pb-2" role="group">
                  <button
                    type="button"
                    className="btn brand-button btn-sm text-white"
                  >
                    {item.label}
                  </button>
                  <button
                    onClick={() => handleRemove(item.label)}
                    type="button"
                    className="btn brand-button btn-sm text-white"
                  >
                    <i className="fas fa-times"></i>
                  </button>
                </div>
              ))}
          </div>
        </Col>
      </Row>
    </Fragment>
  );
};

VerticalAsyncMultiSelect.defaultProps = {
  isMulti: true,
  xs: 5,
  md: 5,
  lg: 5
};

export default VerticalAsyncMultiSelect;