import React, {Fragment, useState, useEffect} from "react";
import {connect, useSelector} from "react-redux";
import {Field, reduxForm, formValueSelector} from "redux-form";
import {Row, Col, Spinner} from "react-bootstrap";
import Swal from "sweetalert2";
import {toast} from "react-toastify";
import _get from "lodash/get";

import ThemeHeader from "../../content/ThemeHeader";
import ThemeButton from "../../button/ThemeButton";
// import TextAreaEmoj from "../../form/TextAreaEmoji";
import TextEditor from "../../form/TextEditor";
import {sendEmail} from "../../../GeneralApi/GeneralApi";
import {convertFormData} from "../../../_helpers/_utils/utils";
import TextInput from "../../form/TextInput";
import TextArea from "../../form/TextArea";
import DefaultModal from "../../modal/DefaultModal";
import PreviewEmail from "./PreviewEmail";
import {
  getFilesByContact,
  uploadFilesByContact,
} from "../../../modules/contacts/crud/contactsCrud";
import {
  required,
  validEmailSequence,
} from "../../../modules/validation/Validation";
import {getAllMembers} from "../../../GeneralApi/GeneralApi";
import MaterialSelect from "../../../partials/form-basic/select/MaterialSelect";
import MultiSelect from "../../../partials/form/MultiSelect";

const Email = (props) => {
  const {
    handleSubmit,
    queryString,
    data,
    // totalRecipient,
    pristine,
    submitting,
    invalid,
    urlPDF,
    setUrlPDF,
    hasDefaultEmails,
    initialValues,
    initialHtmlData,
    defaultMails,
    listingId,
    ListPack,
    setAgent,
    fromMyActiveBuyer,
  } = props;

  console.log("==============Email======================");
  console.log(data);
  console.log(props);
  console.log("==============Email======================");

  const [showEmail, setShowEmail] = useState(false);
  const [htmlData, setHtmlData] = useState("<p></p>");
  const [members, setMembers] = useState(null);

  const [agent1, setAgent1] = useState("");
  const [agent2, setAgent2] = useState("");

  const contactId = useSelector((state) => state.auth.user.contact.id);

  const user = useSelector(({auth: {user}}) => user);

  useEffect(() => {
    if (initialHtmlData) {
      setHtmlData(initialHtmlData);
    }
  }, [initialHtmlData]);

  // useEffect(() => {
  //   let emails = isAllSelected
  //     ? ""
  //     : data
  //       ?.map((a) => a?.emails)
  //       ?.map((a) => a && a[0]?.value)
  //       ?.join(", ");
  //   let formPlaceholder = isAllSelected
  //     ? `Total ${totalRecipient} people selected`
  //     : data?.length > 0
  //       ? emails
  //       : "Please Enter Your Email";
  //   setPlaceHolder(formPlaceholder);
  // }, []);

  useEffect(() => {
    getAllMembers()
      .then(({data: {data}}) => {
        setMembers(
          data.map((s) => ({
            name: `${s.initial}`,
            id: s.contact.id,
            optionTooltipTitle: `${s?.contact?.first_name ?? ""} ${s?.contact
              ?.last_name ?? ""}`,
          }))
        );
      })
      .catch((e) => {});
  }, []);

  const handleOnSubmit = async (values) => {
    if (!hasDefaultEmails && !values.additional_emails) {
      toast.warn("Please Fill Email Address");
      return;
    } else if (!values?.subject || !values?.body) {
      toast.warn("Subject and Content are required");
      return;
    }
    let unProcessedData = {};
    unProcessedData.email_attributes = {};
    unProcessedData.email_attributes.additional_emails =
      values.additional_emails;
    unProcessedData.email_attributes.body = values.body.trim();
    unProcessedData.email_attributes.preview = values.preview;
    unProcessedData.email_attributes.subject = values.subject;
    unProcessedData.email_attributes.files = urlPDF;
    unProcessedData.email_attributes.contact_ids = data
      .map((a = {}) => a?.id || a?.Contact_ID)
      .filter((item) => item);

    return await sendEmail(queryString, unProcessedData)
      .then((res) => {
        if (values.preview === 1) {
          setHtmlData(res.data);
          setShowEmail(true);
        }
        if (values.preview === 0) {
          toast.success("Email Sent Out Successfully");
          props.closeModal();
        }
      })
      .catch(() => {
        toast.error("Email Sent Failed");
      });
  };

  const uploadImageCallBack = async (file) => {
    let value = {
      "files[]": file,
      sub_folder: "user-personal-files/" + user.id,
      contact: contactId,
    };
    let formdData = convertFormData(value);

    const {
      data: {data},
    } = await uploadFilesByContact(formdData);
    if (data) {
      // data === true
      return getFilesByContact(contactId)
        .then((res) => {
          let files = _get(res, "data.data", []);
          if (files?.length) {
            if (files[0].type?.id == 1) {
              return {data: {link: files[0].url}};
            } else {
              let obj = {
                url: files[0].url,
                name: files[0].original_name,
              };
              return setUrlPDF((urlPDF) => [...urlPDF, obj]);
            }
          }
        })
        .catch((e) => {
          return null;
        });
    }
  };

  const closeEmailModal = () => {
    setShowEmail(false);
    Swal.fire({
      title: "Warning",
      text: "Are you sure want to exit?",
      icon: "warning",
      showCancelButton: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
      confirmButtonColor: "#A7A9AC",
      cancelButtonColor: "#2BB673",
      confirmButtonText: "Cancel",
      cancelButtonText: "Yes",
    }).then((result) => {
      if (result.isDismissed) {
        props.closeModal();
      }
    });
  };

  const closePreviewEmailModal = (bool) => {
    setShowEmail(bool);
  };

  const {handleSubmitForm = handleOnSubmit} = props;

  const handleAgent = () => {
    let agents = [];
    if (agent1) {
      agents.push(agent1);
    }
    if (agent2) {
      agents.push(agent2);
    }

    return agents;
  };

  useEffect(() => {
    if (agent1 || agent2) {
      setAgent(handleAgent());
      // setAgent((prev)=> [...prev,agent1 ]);
      // setAgent((prev)=> [...prev,agent2 ]);
    }
  }, [agent1, agent2]);

  useEffect(() => {
    // let contentFileBody = ""
    // if (urlPDF.length > 0) {
    //   urlPDF.map((file)=> {
    //     contentFileBody = `<div><a href='${file.url}' target=blank> ${file.name} </a></div>`
    //   })
    // }
  }, [urlPDF]);

  return (
    <Fragment>
      {showEmail ? (
        <DefaultModal
          size="xl"
          centered
          show={showEmail}
          onHide={() => setShowEmail(false)}
        >
          <PreviewEmail closeModal={closePreviewEmailModal} data={htmlData} />
          {/* <ContactForm  formName='create-new-contact' onSubmit={HandleOnsubmit} closeModal={() => setShowAddContact(false)} /> */}
        </DefaultModal>
      ) : (
        ""
      )}
      <div className="mobileView email-view">
        <form className="smsForm ">
          <ThemeHeader
            left={
              <div style={{display: "flex", alignItems: "center"}}>
                <h5 className="title">Preview and Send Email</h5>
              </div>
            }
            right={
              <Fragment>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    minWidth: "50%",
                  }}
                >
                  {ListPack && (
                    <>
                      {" "}
                      <div className="pl-2">Select an Agent(s) :</div>
                      <Col className="pl-2">
                        <MaterialSelect
                          value={agent1}
                          onChange={(e) => setAgent1(e)}
                          options={members}
                        />

                        <MaterialSelect
                          value={agent2}
                          otherValue={[{id: null, name: "None"}]}
                          onChange={(e) => setAgent2(e)}
                          options={members}
                        />
                      </Col>
                    </>
                  )}

                  <div className="pl-2">
                    {/* <Field name="preview" icon={<i className="fas fa-eye    "></i>} checkedIcon={<i className="fas fa-eye green-color    "></i>} component={ToggleIcon} /> */}
                    <ThemeButton
                      icon
                      onClick={handleSubmit((values) => {
                        handleSubmitForm({
                          ...values,
                          preview: 1,
                        });

                        setShowEmail(true);
                      })}
                    >
                      <i className="fas fa-eye    "></i>
                    </ThemeButton>
                  </div>
                  <div className="pl-2">
                    <ThemeButton
                      className="lightGrey-button"
                      onClick={closeEmailModal}
                    >
                      Cancel
                    </ThemeButton>
                  </div>
                  <div className="pl-2">
                    <ThemeButton
                      disabled={invalid || submitting}
                      onClick={handleSubmit((values) =>
                        handleSubmitForm({
                          ...values,
                          preview: 0,
                        })
                      )}
                      className="green-button"
                    >
                      {submitting ? (
                        <Spinner
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        ""
                      )}
                      Send
                    </ThemeButton>
                  </div>
                </div>
              </Fragment>
            }
          />
          {hasDefaultEmails ? (
            <Row className="pb-2">
              <Col
                className={`mt-auto ${
                  defaultMails?.length ? "pointer-none" : ""
                }`}
              >
                <Field
                  name="to"
                  component={TextArea}
                  validate={[required, validEmailSequence]}
                  className="scroll-hide"
                  label="Mail(s) to"
                />
              </Col>
            </Row>
          ) : null}
          <Row className="pb-2">
            <Col md={6} className="mt-auto">
              <Field
                name="additional_emails"
                component={TextInput}
                // validate={hasDefaultEmails && !defaultMails?.length ? [required, validEmailSequence] : []}
                label={hasDefaultEmails ? "Additional Emails" : "Email to"}
              />
            </Col>
            <Col md={6}>
              <Field
                name="subject"
                component={TextInput}
                placeholder="Subject"
                label="Subject"
              />
            </Col>
          </Row>

          {!listingId || fromMyActiveBuyer ? (
            <Row className="mt-2">
              <Col>
                <Field
                  name="body"
                  type="textarea"
                  rows="3"
                  options={{
                    image: {
                      urlEnabled: true,
                      uploadEnabled: true,
                      previewImage: true,
                      alignmentEnabled: true,
                      inputAccept:
                        "image/jpeg,image/jpg,image/png,application/pdf,text/plain,application/vnd.openxmlformatsofficedocument.wordprocessingml.document,application/msword,application/vnd.ms-excel",
                      alt: {present: false, mandatory: false},
                      uploadCallback: uploadImageCallBack,
                    },
                  }}
                  component={TextEditor}
                  isContentHavingImage={true}
                  // component={TextAreaEmoj}
                  {...(initialValues?.body?.length > 0 && {
                    initialValues: initialValues?.body,
                  })}
                />
              </Col>
            </Row>
          ) : (
            ""
          )}
        </form>
      </div>
    </Fragment>
  );
};

const actions = {
  // closeModal: modal.actions.closeModal,
  // tasksAdd: diary.actions.addTask,
  // tasksUpdate: diary.actions.deleteTask,
};

const selector = formValueSelector("emailPreview");

const mapState = (state, props) => {
  const preview = selector(state, "preview");
  const {
    hasDefaultEmails = true,
    data = [],
    message,
    subject,
    attached,
    fullFile,
    urlPDF,
  } = props;

  console.log("============mapState========================");
  console.log(message);
  console.log("================mapState====================");
  let defaultMails = hasDefaultEmails
      ? [...new Set(data
          ?.map((item = {}) => {
            return item?.Email || item?.emails?.[0]?.value || item.value;
          })
          .filter((item) => item))]
      : [];
  let mailBody = "";
  if (message && message !== undefined) mailBody = `<p>${message}</p>`;
  if (urlPDF?.length > 0) {
    urlPDF.map((file) => {
      mailBody =
        mailBody +
        `<div><a href='${file.url}' target=blank> ${file.name} </a></div>`;
    });
  }
  if (attached && fullFile?.type?.name === "Image") {
    mailBody = `<div>${mailBody}  <img src='${attached}'/></div>`;
  } else if (attached && fullFile?.type?.name === "PDF") {
    mailBody = `<div> ${mailBody} <a href='${attached}' target=blank> <img src='https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/50px-PDF_file_icon.svg.png'/> </a> </div>`;
  } else {
    if (attached && attached !== undefined)
      mailBody = `<div>${mailBody} \n ${attached}</div>`;
  }
  return {
    preview,
    defaultMails,
    initialValues: {
      to: defaultMails ? defaultMails.join(", ") : "",
      additional_emails: "",
      body: mailBody,
      files: urlPDF,
      subject,
    },
  };
};
Email.defaultProps = {
  queryString: "",
  totalRecipient: "",
  data: [],
  hasDefaultEmails: true,
};
export default connect(
  mapState,
  actions
)(reduxForm({form: "emailPreview", enableReinitialize: true})(Email));
