import {serialize} from "object-to-formdata";
import {toast} from "react-toastify";
import moment from "moment";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import imageCompression from "browser-image-compression";

import {DEFAULT_DATE_FORMAT} from "./Constants";
const queryString = require("query-string");
const todayDate = moment().format("YYYY-MM-DDTHH:mm");

export function errorAlert(error) {
  if (error?.message) {
    toast.error(error.message);

    if (error?.data?.length) {
      error.data.map((message) => toast.error(message));
    } else if (error.data) {
      Object.values(error?.data).map((message) => toast.error(message[0]));
    }
  }
}

export function createQueryString(data) {
  return Object.keys(data)
    .map((key) => {
      let val = data[key];
      if (val !== null && typeof val === "object") val = createQueryString(val);
      return `${key}=${encodeURIComponent(`${val}`.replace(/\s/g, "_"))}`;
    })
    .join("&");
}

export function addBusinessDays(date, days) {
  var d = moment(new Date(date)).add(Math.floor(days / 5) * 7, "d");
  var remaining = days % 5;
  while (remaining) {
    d.add(1, "d");
    if (d.day() !== 0 && d.day() !== 6) remaining--;
  }
  return d;
}

export function mungeEmailAddress(s) {
  var i = s.indexOf("@");
  var startIndex = (i * 0.2) | 0;
  var endIndex = (i * 0.9) | 0;
  return (
    s.slice(0, startIndex) +
    s.slice(startIndex, endIndex).replace(/./g, "*") +
    s.slice(endIndex)
  );
}
export function addressInitialValue() {
  return [
    {
      type: 1,
      street_unit: "",
      street_name: {},
      suburb: {},
      state: {},
    },
  ];
}

export function groupBy(key, array) {
  let value = array.reduce((acc, obj) => {
    const property = obj[key];
    acc[property] = acc[property] || [];
    acc[property].push(obj);
    return acc;
  }, {});
  return value;
}

export function getProcessedDomainAddress(address = {}) {
  const {street, street_number, street_unit, type, id, full_address} = address;
  return {
    addressComponents: {
      postCode: street?.suburb?.postcode,
      state: street?.suburb?.state?.abbrev,
      streetName: street?.name,
      streetNumber: street_number,
      // streetType: "St",
      streetTypeLong: street?.street_type || "",
      suburb: street?.suburb?.name,
      unitNumber: street_unit,
    },
    full_address: {
      value: id,
      label: full_address,
    },
    // ...(type?.id ? { type: type?.id } : {})
  };
}

export function getProcessedAddress(s) {
  var address = {
    // id: s?.id,
    street_unit: s?.street_unit,
    postcode: s?.street?.suburb?.postcode,
    street_number: s?.street_number,
    street_name: {
      label: s?.street?.name,
      value: s?.street?.id,
    },
    suburb: {
      label: s?.street?.suburb?.name,
      value: s?.street?.suburb?.id,
    },
    state: {
      label: s?.street?.suburb?.state?.abbrev,
      value: s?.street?.suburb?.state?.id,
    },
    type: s?.type?.id ? s?.type?.id : 1,
  };

  return address;
}

export function getProcessedTasks(values) {
  let processedValue = values?.map(
    ({
      allocated_role,
      created_by,
      repeat,
      completed_date,
      created_at,
      sub_tasks,
      ...other
    }) => ({
      allocated_role: allocated_role?.id,
      created_at,
      isSelected: completed_date ? true : false,
      created_by: created_by?.id,
      sub_tasks: getProcessedTasks(sub_tasks),
      repeat: repeat?.id,
      ...other,
    })
  );

  return processedValue;
}

export function processAddressForSearch(s) {
  var address = {
    street_unit: s.street_unit ? s.street_unit : null,
    street_number: s.street_number ? s.street_number : null,
    street: s.street_name?.value ? s.street_name.value : null,
    suburb: s.suburb?.value ? s.suburb.value : null,
    postcode: s.postcode ? s.postcode : null,
  };

  return address;
}

export function postProcessAddress(s = {}) {
  var address = {
    id: s.id ? s.id : null,
    postcode: s.postcode ? s.postcode : null,
    street_unit: s.street_unit ? s.street_unit : null,
    street_number: s.street_number ? s.street_number : null,
    street: s.street_name?.value ? s.street_name.value : null,
    suburb: s.suburb?.value ? s.suburb.value : null,
    state: s.state?.value ? s.state.value : null,
    type: s.type ? s.type : 1,
  };

  return address;
}
export function postProcessDomainAddress(domain_address) {
  const {addressComponents = {}} = domain_address;
  var address = {
    domain_id: domain_address.id,
    street_unit: addressComponents.unitNumber,
    street_number: addressComponents.streetNumber,
    street_name: addressComponents.streetName,
    street_type: addressComponents.streetTypeLong,
    suburb_name: addressComponents.suburb,
    postcode: addressComponents.postCode,
    state_abbrev: addressComponents.state,
  };

  return address;
}

export function stringCompare(a, b) {
  if (a?.length !== b?.length) {
    return false;
  }

  return a.localeCompare(b) === 0;
}

export function dateCompare(a, b) {
  var date1 = new Date(a);
  var date2 = new Date(b);

  if (date1.getTime() > date2.getTime()) {
    return true;
  } else {
    return false;
  }
}

export function cleanObject(obj) {
  for (var propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined) {
      delete obj[propName];
    }
  }
  return obj;
}

export const getRoundValue = (numerator, denominator, digits) => {
  if (numerator % denominator === 0) {
    return (numerator / denominator).toString();
  }
  return (numerator / denominator).toFixed(digits);
};

export function currencyFormatter(num, digits = 2) {
  var si = [
    {value: 1, symbol: ""},
    {value: 1e3, symbol: "K"},
    {value: 1e6, symbol: "M"},
    {value: 1e9, symbol: "G"},
    {value: 1e12, symbol: "T"},
    {value: 1e15, symbol: "P"},
    {value: 1e18, symbol: "E"},
  ];
  var rx = /\.$|(\.[0-9]*[1-9])0+$/;
  // var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var i;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }
  return (
    getRoundValue(num, si[i].value, digits).replace(rx, "$1") + si[i].symbol
  ); // Now digits default to 1
}

export function getRndInteger(min, max) {
  return Math.floor(Math.random() * (max - min)) + min;
}

export function getPercentage(percent, totalValue) {
  return totalValue > 0
    ? ((100 * percent) / totalValue).toFixed(2)
    : (0).toFixed(2);
}

export function getTotalPercentageValue(percent, totalValue) {
  return ((percent / 100) * totalValue).toFixed(2);
}

export function ucFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
export function lcFirst(string) {
  return string?.charAt(0)?.toLowerCase()() + string.slice(1);
}

export function spaceRemove(string) {
  return string.replace(/\s/g, "");
}

export function dotString(string, max, after) {
  if (string?.length > max) {
    return string?.substring(0, after) + "...";
  } else return string;
}

export function pushToArray(arr, obj) {
  const index = arr.findIndex((e) => e.id === obj.id);
  const array = arr;

  if (index === -1) {
    array.push(obj);
  } else {
    array[index] = obj;
  }

  return array;
}

export function convertFormData({formDataOptions, ...others} = {}) {
  const options = {
    /**
     * include array indices in FormData keys
     * defaults to false
     */
    indices: true,

    /**
     * treat null values like undefined values and ignore them
     * defaults to false
     */
    nullsAsUndefineds: formDataOptions?.nullsAsUndefineds ?? false,

    /**
     * convert true or false to 1 or 0 respectively
     * defaults to false
     */
    booleansAsIntegers: false,

    /**
     * store arrays even if they're empty
     * defaults to false
     */
    allowEmptyArrays: formDataOptions?.allowEmptyArrays ?? false,
  };
  const formData = serialize(others, options);

  return formData;
}

export function wbErrorToast(e) {
  const {data} = e;

  return toast.error(data.message);
}

export function wbSuccessToast({type, message}) {
  switch (type) {
    case "success":
      return toast.success(message);
    case "error":
      return toast.error(message);
    default:
      return "";
  }
}
export function formToQueryString(values) {
  return queryString.stringify(values, {arrayFormat: "comma"});
}

export function convertedDateFormate(value, formate) {
  return moment(value).format(formate);
}

export function getFileType(value) {
  return value.split(".").pop();
}

export function getProcessedContactInfo(value) {
  let address;
  let workAddress;
  const {
    emails,
    phones,
    income,
    tags,
    title,
    addresses,
    linked_contacts,
    duplicate_contacts,
    marital_status,
    ...other
  } = value;

  if (addresses?.length > 0) {
    // address = addresses
    //   ?.filter((f) => f?.type?.id === 1)
    //   .map((s) => ({
    //     id: s?.id,
    //     street_unit: s.street_unit,
    //     postcode: s.street?.suburb?.postcode,
    //     street_number: s.street_number,
    //     street_name: {
    //       label: s.street?.name,
    //       value: s.street?.id,
    //     },
    //     suburb: {
    //       label: s.street?.suburb?.name,
    //       value: s.street?.suburb?.id,
    //     },
    //     state: {
    //       label: s.street?.suburb?.state?.abbrev,
    //       value: s.street?.suburb?.state?.id,
    //     },
    //     type: s?.type?.id,
    //   }));
    // // work Address
    // workAddress = addresses
    //   ?.filter((f) => f?.type?.id === 2)
    //   .map((s) => ({
    //     id: s.id,
    //     street_unit: s.street_unit,
    //     postcode: s.street?.suburb?.postcode,
    //     street_number: s.street_number,
    //     street_name: {
    //       label: s.street?.name,
    //       value: s.street?.id,
    //     },
    //     suburb: {
    //       label: s.street?.suburb?.name,
    //       value: s.street?.suburb?.id,
    //     },
    //     state: {
    //       label: s.street?.suburb?.state?.abbrev,
    //       value: s.street?.suburb?.state?.id,
    //     },
    //     type: s?.type?.id,
    //   }));
  }

  if (addresses?.length > 0) {
    address = addresses?.find((f) => f?.type?.id === 1) || {};
    workAddress = addresses?.find((f) => f?.type?.id === 2) || {};
    if (!_isEmpty(address)) {
      address = getProcessedDomainAddress(address);
    }
    if (!_isEmpty(workAddress)) {
      workAddress = getProcessedDomainAddress(workAddress);
    }
  }

  let linked_contact;
  if (linked_contacts?.length > 0) {
    linked_contact = linked_contacts?.map((s) => ({
      // state: s.street.suburb.state.id,
      id: s.id,
      name: s.first_name,
      relationship: s.relationship?.id,
    }));
  }

  let initialValues = {
    emails:
      emails?.length > 0
        ? emails.map(({type, ...obj}) => ({type: type?.id ?? type, ...obj}))
        : [{type: 1, value: ""}],
    tags: tags?.filter((item) => item).map((obj) => obj.id),
    phones:
      phones?.length > 0
        ? phones.map(({type, ...obj}) => ({type: type?.id ?? type, ...obj}))
        : [{type: 1, value: ""}],
    income: income?.id,
    marital_status: marital_status?.id,
    addresses: address ?? [
      {type: 1, street_unit: "", street: {}, suburb: {}, state: {}},
    ],
    workAddress: workAddress ?? [
      {type: 2, street_unit: "", street: {}, suburb: {}, state: {}},
    ],
    linked_contacts: linked_contact ? linked_contact : [],
    ...other,
  };
  return initialValues;
}

export function processPhoneNumber(phones) {
  return phones?.length > 0
    ? phones.map(({type, ...obj}) => ({type: type?.id ?? 1, ...obj}))
    : [{type: 1, value: ""}];
}

export function processEmail(emails) {
  return emails?.length > 0
    ? emails.map(({type, ...obj}) => ({type: type?.id ?? 1, ...obj}))
    : [{type: 1, value: ""}];
}

export function postProcessedContactInfo(values) {
  let address = [];
  let work_address;
  let initialValues;
  const {
    addresses,
    workAddress,
    tenant_address,
    buyer_address,
    duplicate_contacts,
    emails,
    phones,
    title,
    tags,
    organisation,
    number_of_children,
    children,
    ...other
  } = values;

  if (!_isEmpty(addresses)) {
    if (addresses instanceof Array) {
      if (addresses?.length > 0) {
        address = addresses
          ?.map((s) => ({
            id: s.id ? s.id : null,
            postcode: s.postcode ? s.postcode : null,
            street_unit: s.street_unit ? s.street_unit : null,
            street_number: s.street_number ? s.street_number : null,
            street: s.street_name?.value ? s.street_name.value : null,
            suburb: s.suburb?.value ? s.suburb.value : null,
            state: s.state?.value ? s.state.value : null,
            type: 1,
          }))
          .filter(
            (item) =>
              item.postcode &&
              item.state &&
              item.suburb &&
              item.street &&
              item.street_number
          );
      }
    } else {
      const {label, value, full_address, ...domain_address} = addresses;
      address.push({domain_address, type: 1});
    }
  }
  if (!_isEmpty(workAddress)) {
    if (workAddress instanceof Array) {
      if (workAddress?.length > 0) {
        work_address = workAddress
          ?.map((s) => ({
            id: s.id ? s.id : null,
            postcode: s.postcode ? s.postcode : null,
            street_unit: s.street_unit ? s.street_unit : null,
            street_number: s.street_number ? s.street_number : null,
            street: s.street_name?.value ? s.street_name.value : null,
            suburb: s.suburb?.value ? s.suburb.value : null,
            state: s.state?.value ? s.state.value : null,
            type: 2,
          }))
          .filter(
            (item) =>
              item.postcode &&
              item.state &&
              item.suburb &&
              item.street &&
              item.street_number
          );
        if (work_address.length) {
          address.push(work_address[0]);
        }
      }
    } else {
      const {label, value, full_address, ...domain_address} = workAddress;
      address.push({domain_address: domain_address, type: 2});
    }
  }

  let _tenant_address = [];
  if (tenant_address?.length) {
    tenant_address.forEach((item, index) => {
      if (item instanceof Array && item.length) {
        let _address = item
          ?.map((s) => ({
            id: s.id ? s.id : null,
            postcode: s.postcode ? s.postcode : null,
            street_unit: s.street_unit ? s.street_unit : null,
            street_number: s.street_number ? s.street_number : null,
            street: s.street_name?.value ? s.street_name.value : null,
            suburb: s.suburb?.value ? s.suburb.value : null,
            state: s.state?.value ? s.state.value : null,
            type: index + 1,
          }))
          .filter(
            (item) =>
              item.postcode &&
              item.state &&
              item.suburb &&
              item.street &&
              item.street_number
          );
        if (_address?.length) {
          _tenant_address = [..._tenant_address, ..._address];
        }
      } else {
        const {label, value, full_address, ..._domain_address} = item;
        _tenant_address = [
          ..._tenant_address,
          {domain_address: _domain_address, type: index + 1},
        ];
      }
    });
  }

  let editedPhone;
  if (phones) {
    editedPhone = phones && phones.filter((phone) => phone.value !== "");
  }

  let _buyer_address = [];
  if (buyer_address?.length) {
    buyer_address.forEach((item, index) => {
      if (item instanceof Array && item.length) {
        let _address = item
          ?.map((s) => ({
            id: s.id ? s.id : null,
            postcode: s.postcode ? s.postcode : null,
            street_unit: s.street_unit ? s.street_unit : null,
            street_number: s.street_number ? s.street_number : null,
            street: s.street_name?.value ? s.street_name.value : null,
            suburb: s.suburb?.value ? s.suburb.value : null,
            state: s.state?.value ? s.state.value : null,
            type: index + 1,
          }))
          .filter(
            (item) =>
              item.postcode &&
              item.state &&
              item.suburb &&
              item.street &&
              item.street_number
          );
        if (_address?.length) {
          _buyer_address = [..._buyer_address, ..._address];
        }
      } else {
        const {label, value, full_address, ..._domain_address} = item;
        _buyer_address = [
          ..._buyer_address,
          {domain_address: _domain_address, type: index + 1},
        ];
      }
    });
  }

  initialValues = {
    number_of_children,
    ...(number_of_children && {children: children}),
    addresses: address?.length > 0 ? address : null,
    emails: emails
      ?.filter((item) => item?.value)
      .map((item) => {
        return {...item, ...(item.type?.id && {type: item.type?.id})};
      }),
    phones:
      editedPhone?.length > 0
        ? editedPhone
            ?.filter((item) => item?.value)
            .map((item) => {
              return {...item, ...(item.type?.id && {type: item.type?.id})};
            })
        : [],
    tags: tags?.filter((obj) => obj),
    ...(_tenant_address.length ? {addresses: _tenant_address} : {}),
    ...(_buyer_address.length ? {addresses: _buyer_address} : {}),
    ...other,
  };

  return initialValues;
}
// Buyer
export function getBuyerProcessedInfo(value) {
  const {
    primary_contact,
    type,
    rating,
    condition,
    to_sell,
    suburbs,
    property_types,
    looking_date,
    looking_date_time_unit,
    ready_in_date_time_unit,
    ready_in_date,
    finance_status,
    features,
    allocated_to,
    ...other_data
  } = value;

  let processedContact = getProcessedContactInfo(primary_contact);

  let PropertyTypes;
  let suburbsData;
  if (suburbs?.length > 0) {
    suburbsData = suburbs?.map((s) => ({
      // state: s.street.suburb.state.id,
      value: s.id,
      label: s.name,
      state_name: s.state_name ? s.state_name : "",
    }));
  }

  if (property_types?.length > 0) {
    PropertyTypes = property_types?.map((s) => s.id);
  }
  // Buyers

  let initialValues = {
    primary_contact: processedContact,
    type: type?.id,
    rating: rating?.id,
    condition: condition?.id,
    to_sell: to_sell?.id,
    suburbs: suburbsData,
    property_types: PropertyTypes,
    finance_status: finance_status?.id,
    looking_date_time_unit: looking_date_time_unit?.id,
    allocated_to: allocated_to?.id,
    allocated_to_initial: allocated_to?.initial,
    features: features?.map(({feature, feature_menu_option, ...obj}) => ({
      feature: feature.id,
      feature_menu_option: feature_menu_option?.id,
      ...obj,
    })),
    ready_in_date_time_unit: ready_in_date_time_unit?.id,
    looking_date: moment().diff(
      moment(looking_date).format("YYYY-MM-DD"),
      looking_date_time_unit?.name
    )
      ? moment().diff(
          moment(looking_date).format("YYYY-MM-DD"),
          looking_date_time_unit?.name
        )
      : "",
    ready_in_date: Math.abs(
      moment().diff(
        moment(ready_in_date).format("YYYY-MM-DD"),
        ready_in_date_time_unit?.name
      )
    )
      ? Math.abs(
          moment().diff(
            moment(ready_in_date).format("YYYY-MM-DD"),
            ready_in_date_time_unit?.name
          )
        ) + 1
      : "",
    ...other_data,
  };

  return initialValues;
}

export function postBuyerProcessedInfo(values) {
  let pastDate;
  var futureDate;
  var date = new Date();
  let buyer_featuresData;
  let formatedValue;
  const {
    buyer_contact,
    suburbs,
    looking_date,
    looking_date_time_unit,
    ready_in_date_time_unit,
    ready_in_date,
    features,
    profile_picture,
    ...other_values
  } = values;

  let processedContactInfo = postProcessedContactInfo(buyer_contact);

  if (features?.length) {
    buyer_featuresData = features?.map((s) =>
      s.id === 20
        ? {
            feature: s?.feature,
            feature_menu_input: s.feature_menu_input ? s.feature_menu_input : 0,
            feature_menu_option: s.feature_menu_option
              ? s.feature_menu_option
              : 0,
            comments: s.comments,
          }
        : {feature: s.feature, qty: s.qty ? s.qty : "", comments: s.comments}
    );
  }

  // Been Looking
  if (looking_date && looking_date_time_unit) {
    pastDate = moment(
      getPastDate(date, looking_date, looking_date_time_unit)
    ).format("YYYY-MM-DD");
  }

  // Been Looking
  // Ready In

  if (ready_in_date && ready_in_date_time_unit) {
    futureDate = moment(
      getFutureDate(date, ready_in_date, ready_in_date_time_unit)
    ).format("YYYY-MM-DD");
  }
  // Ready In

  if (typeof profile_picture === "string") {
    formatedValue = {
      // contact: id,
      buyer_contact: {
        profile_picture: profile_picture,
        ...processedContactInfo,
      },
      suburbs: suburbs?.map((item) => item.value),
      features: features?.length > 0 ? buyer_featuresData : null,
      looking_date: looking_date ? pastDate : null,
      looking_date_time_unit: looking_date_time_unit,
      ready_in_date: ready_in_date ? futureDate : null,
      ready_in_date_time_unit: ready_in_date_time_unit,
      ...other_values,
    };
  } else {
    formatedValue = {
      // contact: id,
      buyer_contact: {
        ...processedContactInfo,
      },
      suburbs: suburbs?.map((item) => item.value),
      features: features?.length > 0 ? buyer_featuresData : null,
      profile_picture: profile_picture,
      looking_date: looking_date ? pastDate : null,
      looking_date_time_unit: looking_date_time_unit,
      ready_in_date: ready_in_date ? futureDate : null,
      ready_in_date_time_unit: ready_in_date_time_unit,
      ...other_values,
    };
  }

  return formatedValue;
}
export function postBuyerDetailsProcessedInfo(values) {
  let pastDate;
  var futureDate;
  var date = new Date();
  let buyer_featuresData;
  let formatedValue;
  const {
    suburbs,
    looking_date,
    looking_date_time_unit,
    ready_in_date_time_unit,
    ready_in_date,
    features,
    // allocated_to :allocated_to,
    ...other_values
  } = values;

  if (features?.length) {
    buyer_featuresData = features?.map((s) =>
      s.feature === 20 || s.feature === 9
        ? {
            feature: s.feature,
            feature_menu_input: s.feature_menu_input ? s.feature_menu_input : 0,
            feature_menu_option: s.feature_menu_option
              ? s.feature_menu_option
              : 0,
            comments: s.comments,
          }
        : {feature: s.feature, qty: s.qty ? s.qty : "", comments: s.comments}
    );
  }

  // Been Looking
  if (looking_date && looking_date_time_unit) {
    pastDate = moment(
      getPastDate(date, looking_date, looking_date_time_unit)
    ).format("YYYY-MM-DD");
  }

  // Been Looking
  // Ready In

  if (ready_in_date && ready_in_date_time_unit) {
    futureDate = moment(
      getFutureDate(date, ready_in_date, ready_in_date_time_unit)
    ).format("YYYY-MM-DD");
  }
  // Ready In

  if (typeof profile_picture === "string") {
    formatedValue = {
      // contact: id,

      suburbs: suburbs?.map((item) => item.value),
      features: features?.length > 0 ? buyer_featuresData : null,
      looking_date: looking_date ? pastDate : null,
      looking_date_time_unit: looking_date_time_unit,
      ready_in_date: ready_in_date ? futureDate : null,
      ready_in_date_time_unit: ready_in_date_time_unit,
      ...other_values,
    };
  } else {
    formatedValue = {
      // contact: id,

      suburbs: suburbs?.map((item) => item.value),
      features: features?.length > 0 ? buyer_featuresData : null,
      looking_date: looking_date ? pastDate : null,
      looking_date_time_unit: looking_date_time_unit,
      ready_in_date: ready_in_date ? futureDate : null,
      ready_in_date_time_unit: ready_in_date_time_unit,
      ...other_values,
    };
  }

  return formatedValue;
}
// Buyer
// Tenant
export function getTenantProcessedInfo(value = {}) {
  const {
    primary_contact,
    type,
    rating,
    suburbs,
    property_types,
    payment_frequency,
    looking_date,
    looking_date_time_unit,
    ready_in_date_time_unit,
    ready_in_date,
    finance_status,
    tenant_features,
    features,
    ...other_data
  } = value;

  let processedContact = getProcessedContactInfo(primary_contact);

  let PropertyTypes;
  let suburbsData;
  if (suburbs?.length > 0) {
    suburbsData = suburbs?.map((s) => ({
      value: s.id,
      label: s.name,
      state_name: s.state_name ? s.state_name : "",
    }));
  }

  if (property_types?.length > 0) {
    PropertyTypes = property_types?.map(
      (s) => s.id
      // ({
      //   value: s.id,
      //   label: s.name,
      // })
    );
  }
  // Buyers

  let initialValues = {
    primary_contact: processedContact,
    type: type?.id,
    rating: rating?.id,
    payment_frequency: payment_frequency?.id,
    suburbs: suburbsData,
    property_types: PropertyTypes,
    finance_status: finance_status?.id,
    looking_date_time_unit: looking_date_time_unit?.id,
    ready_in_date_time_unit: ready_in_date_time_unit?.id,
    features: features?.map(({feature, feature_menu_option, ...obj}) => ({
      feature: feature?.id,
      feature_menu_option: feature_menu_option?.id,
      ...obj,
    })),
    looking_date: moment().diff(
      moment(looking_date).format("YYYY-MM-DD"),
      looking_date_time_unit?.name
    )
      ? moment().diff(
          moment(looking_date).format("YYYY-MM-DD"),
          looking_date_time_unit?.name
        )
      : "",
    ready_in_date: Math.abs(
      moment().diff(
        moment(ready_in_date).format("YYYY-MM-DD"),
        ready_in_date_time_unit?.name
      )
    )
      ? Math.abs(
          moment().diff(
            moment(ready_in_date).format("YYYY-MM-DD"),
            ready_in_date_time_unit?.name
          )
        ) + 1
      : "",

    ...other_data,
  };

  return initialValues;
}

export function postTenantProcessedInfo(values) {
  let pastDate;
  var futureDate;
  var date = new Date();
  let tenant_featuresData;
  let formatedValue;
  const {
    primary_contact,
    suburbs,
    property_types,
    features,
    looking_date,
    looking_date_time_unit,
    ready_in_date_time_unit,
    ready_in_date,
    ...other_values
  } = values;

  const {profile_picture, ...other_primary_contact} = primary_contact;

  let processedContactInfo = postProcessedContactInfo(other_primary_contact);
  // Been Looking

  if (looking_date && looking_date_time_unit) {
    pastDate = moment(
      getPastDate(date, looking_date, looking_date_time_unit)
    ).format("YYYY-MM-DD");
  }
  // Been Looking
  // Ready In
  if (ready_in_date && ready_in_date_time_unit) {
    futureDate = moment(
      getFutureDate(date, ready_in_date, ready_in_date_time_unit)
    ).format("YYYY-MM-DD");
  }
  // Ready In
  if (features?.length) {
    tenant_featuresData = features?.map((s) =>
      s.feature === 20 || s.feature === 9
        ? {
            feature: s.feature,
            feature_menu_input: s.feature_menu_input ? s.feature_menu_input : 0,
            feature_menu_option: s.feature_menu_option
              ? s.feature_menu_option
              : 0,
            comments: s.comments,
          }
        : {feature: s.feature, qty: s.qty ? s.qty : "", comments: s.comments}
    );
  }

  if (typeof profile_picture === "string") {
    formatedValue = {
      // contact: id,
      tenant_contact: {
        profile_picture: profile_picture,
        ...processedContactInfo,
      },
      property_types,
      suburbs: suburbs?.map((item) => item.value),
      features: features?.length > 0 ? tenant_featuresData : null,
      looking_date: looking_date ? pastDate : null,
      looking_date_time_unit: looking_date_time_unit,
      ready_in_date: ready_in_date ? futureDate : null,
      ready_in_date_time_unit: ready_in_date_time_unit,
      ...other_values,
    };
  } else {
    formatedValue = {
      // contact: id,
      tenant_contact: {
        ...processedContactInfo,
      },
      property_types,
      suburbs: suburbs?.map((item) => item.value),
      features: features?.length > 0 ? tenant_featuresData : null,
      looking_date: looking_date ? pastDate : null,
      looking_date_time_unit: looking_date_time_unit,
      ready_in_date: ready_in_date ? futureDate : null,
      ready_in_date_time_unit: ready_in_date_time_unit,
      profile_picture: profile_picture,

      ...other_values,
    };
  }

  return formatedValue;
}

// Tenant
export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export function getObjWithoutValues(dontReturnValsArr, obj) {
  const cleanObj = {};
  Object.entries(obj).forEach(([key, val]) => {
    if (!dontReturnValsArr.includes(val)) {
      cleanObj[key] = val;
    }
  });
  return cleanObj;

  // for (var propName in obj) {
  //   if (obj[propName] === null || obj[propName] === undefined) {
  //     delete obj[propName];
  //   }
  // }
  // return obj
}

export function removeEmpty(obj) {
  return Object.entries(obj)
    .filter(([_, v]) => v !== null)
    .reduce(
      (acc, [k, v]) => ({...acc, [k]: v === Object(v) ? removeEmpty(v) : v}),
      {}
    );
}

export function getPastDate(dt, amount, dateType) {
  switch (dateType) {
    case 3:
      return moment(dt).subtract(amount, "days");
    case 4:
      return moment(dt).subtract(amount, "weeks");
    case 5:
      return moment(dt).subtract(amount, "months");
    case 6:
      return moment(dt).subtract(amount, "years");
    default:
      return dt;
  }
}

export function getFutureDate(dt, amount, dateType) {
  switch (dateType) {
    case 3:
      return moment(dt).add(amount, "days");
    case 4:
      return moment(dt).add(amount, "weeks");
    case 5:
      return moment(dt).add(amount, "months");
    case 6:
      return moment(dt).add(amount, "years");
    default:
      return dt;
  }
}

export function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * max + min); //The maximum is exclusive and the minimum is inclusive
}

export function getProcessedMemberInfo(value) {
  const {
    contact,
    mfa_channel,
    bonus,
    roles,
    username,
    ...otherMemberInfo
  } = value;

  const {id, emails, phones, ...other} = contact;
  let mobile =
    phones?.length > 0
      ? phones.filter((item) => item.type?.id === 1)[0]
        ? [
            phones
              .map(({type, ...obj}) => ({type: type?.id, ...obj}))
              .filter((item) => item.type === 1)[0],
          ]
        : [{type: 1, value: ""}]
      : [{type: 1, value: ""}];
  let home_phone =
    phones?.length > 0
      ? phones.filter((item) => item.type?.id === 2)[0]
        ? [
            phones
              .map(({type, ...obj}) => ({type: type?.id, ...obj}))
              .filter((item) => item.type === 2)[0],
          ]
        : [{type: 2, value: ""}]
      : [{type: 2, value: ""}];
  let member_email =
    emails?.length > 0
      ? emails.filter((item) => item.type?.id === 1)[0]
        ? [
            emails
              .map(({type, ...obj}) => ({type: type?.id, ...obj}))
              .filter((item) => item.type === 1)[0],
          ]
        : [{type: 1, value: ""}]
      : [{type: 1, value: ""}];

  let _member_login = {
    roles: roles?.map((role) => role.id) ?? [],
    mfa: mfa_channel?.id,
  };

  if (username && username !== "") {
    _member_login = {
      ..._member_login,
      username: username,
    };
  }

  let initialValues = {
    mobile: mobile,
    bonus: bonus?.id,
    home_phone: home_phone,
    member_email: member_email,
    phones:
      mobile || home_phone
        ? phones
            .filter((item) => item.id !== mobile[0].id)
            .map(({type, ...obj}) => ({type: type?.id, ...obj}))
        : [],
    emails: member_email
      ? emails
          .filter(function(el) {
            return el.id !== member_email[0]?.id;
          })
          .map(({type, ...obj}) => ({type: type?.id, ...obj}))
      : [],
    contact_id: id,
    member_login: _member_login,
    ...otherMemberInfo,
    ...other,
  };

  return initialValues;
}

export function postProcessedMemberInfo(values) {
  let initialValues;

  const {
    first_name,
    contact_id,
    last_name,
    dob,
    facebook,
    linkedin,
    twitter,
    instagram,
    rea,
    introduction_video,
    emails,
    mobile,
    phones,
    home_phone,
    member_email,
    member_login,
    ...other
  } = values;

  if (mobile) {
    phones.push(mobile[0]);
  }

  if (home_phone) {
    phones.push(home_phone[0]);
  }

  if (member_email) {
    emails.push(member_email[0]);
  }

  initialValues = {
    member_contact: {
      id: contact_id,
      first_name: first_name,
      last_name: last_name,
      dob: dob,
      facebook: facebook,
      instagram: instagram,
      twitter: twitter,
      linkedin: linkedin,
      rea: rea,
      introduction_video :introduction_video,
      emails: emails.filter((obj) => obj.value),
      phones: phones.filter((obj) => obj.value),
    },
    member_login: member_login, //? removeEmpty(member_login) : member_login,
    ...other,
  };
  return initialValues;
}

export function processMemberInfo(values) {
  let formValues;
  const {
    contact,
    organisation,
    roles,
    bonus,
    mfa_channel,
    forcast_required_on_login,
    username,
    accessible_organisations,
    awards,
    ...other
  } = values;
  formValues = {
    profile_picture: contact?.profile_picture,
    profile_video: contact?.profile_video
      ? [{url: contact?.profile_video}]
      : null,
    first_name: contact?.first_name,
    last_name: contact?.last_name,
    dob: contact?.dob,
    mobile: [
      {
        type: 1,
        value: (contact?.phones.filter((p) => p.type.id === 1)).length
          ? (contact?.phones.filter((p) => p.type.id === 1))[0].value
          : "",
      },
    ],
    home_phone: [
      {
        type: 2,
        value: (contact?.phones.filter((p) => p.type.id === 2)).length
          ? (contact?.phones.filter((p) => p.type.id === 2))[0].value
          : "",
      },
    ],
    phones: [],
    emails: [],
    member_email: [
      {
        type: 1,
        value: contact?.emails?.[0]?.value,
      },
    ],

    bonus: bonus?.id,

    forecast: forcast_required_on_login,

    member_login: {
      username: username,
      roles: roles?.map((role) => role.id),
      mfa_channel: mfa_channel?.id,
    },
    accessible_organisations: accessible_organisations?.map((org) => org?.id),
    primary_office: organisation?.id,
    facebook: contact?.facebook,
    instagram: contact?.instagram,
    twitter: contact?.twitter,
    linkedin: contact?.linkedin,
    introduction_video: contact?.introduction_video,
    rea: contact?.rea,
    awards: awards ?? "",
    ...other,
  };

  return formValues;
}
export function postCreateRental(values) {
  let address_1, listing_featuresData, files_information, files, allFiles;
  let initialValues;
  const {
    listing_agent,
    features,
    co_listing_agent,
    display_agent,
    contacts = [],
    address,
    domain_address,
    images,
    videos,
    property,
    property_type,
    type,
    property_manager,
    bdm,
    contract_in_office,
    agreement_date,
    ...other
  } = values;

  let filteredContact = contacts.filter((obj) => obj.contact_type !== 6);

  if (property_manager) {
    filteredContact.push({id: property_manager, contact_type: 6});
  }
  if (bdm) {
    filteredContact.push({id: bdm, contact_type: 20});
  }

  if (address?.length > 0) {
    address_1 = address?.map((s) => ({
      postcode: s.postcode ? s.postcode : null,
      street_unit: s.street_unit ? s.street_unit : null,
      street_number: s.street_number ? s.street_number : null,
      street: s.street_name?.value ? s.street_name.value : null,
      suburb: s.suburb?.value ? s.suburb.value : null,
      state: s.state?.value ? s.state.value : null,
      type: s.type ? s.type : 1,
    }));
  }

  if (features?.length) {
    listing_featuresData = features?.map((s) =>
      s.feature === 20 || s.feature === 9
        ? {
            feature: s.feature,
            feature_menu_input: s.feature_menu_input ? s.feature_menu_input : 0,
            feature_menu_option: s.feature_menu_option
              ? s.feature_menu_option
              : 0,
            comments: s.comments,
          }
        : {
            feature: s?.feature,
            qty: s?.qty ? s.qty : "",
            comments: s?.comments,
          }
    );
  }
  if (images) {
    files = images
      ?.map((image) => image?.fileContent)
      .concat(videos?.map((video) => video?.fileContent));
    allFiles = images?.concat(videos);
    if (files?.length > 0) {
      files_information = allFiles?.map((file) => {
        if (file) {
          const {fileContent, ...other} = file;
          return {...other};
        }
        return;
      });
    }
  }
  initialValues = {
    contacts: filteredContact,
    property: {
      address: !_isEmpty(domain_address)
        ? {domain_address}
        : address_1?.[0] ?? {},
      features: listing_featuresData,
      type: property?.type,
    },
    files: files,
    files_information: files_information,
    type: type,
    property_type: property_type?.id,
    contract_in_office,
    ...(contract_in_office === 1 && {agreement_date: agreement_date}),
    ...other,
  };

  return initialValues;
}
export function postCreateListing(values) {
  let address_1, listing_featuresData, files_information, files, allFiles;
  let initialValues;
  const {
    listing_agent,
    features,
    co_listing_agent,
    display_agent,
    contacts = [],
    address,
    images,
    videos,
    property,
    property_type,
    type,
    property_manager,
    bdm,
    contract_in_office,
    contract_date,
    agency_agreement_check_date,
    agency_agreement_check,
    domain_address,
    ...other
  } = values;

  let filteredContact = contacts.filter((obj) => obj.contact_type !== 6);

  if (listing_agent) {
    filteredContact.push({id: listing_agent, contact_type: 2});
  }

  if (co_listing_agent) {
    filteredContact.push({id: co_listing_agent, contact_type: 7});
  }
  if (display_agent) {
    filteredContact.push({id: display_agent, contact_type: 17});
  }

  if (property_manager) {
    filteredContact.push({id: property_manager, contact_type: 6});
  }

  if (address?.length > 0) {
    address_1 = address?.map((s) => ({
      postcode: s.postcode ? s.postcode : null,
      street_unit: s.street_unit ? s.street_unit : null,
      street_number: s.street_number ? s.street_number : null,
      street: s.street_name?.value ? s.street_name.value : null,
      suburb: s.suburb?.value ? s.suburb.value : null,
      state: s.state?.value ? s.state.value : null,
      type: s.type ? s.type : 1,
    }));
  }

  if (features?.length) {
    listing_featuresData = features?.map((s) =>
      s.feature === 20 || s.feature === 9
        ? {
            feature: s.feature,
            feature_menu_input: s.feature_menu_input ? s.feature_menu_input : 0,
            feature_menu_option: s.feature_menu_option
              ? s.feature_menu_option
              : 1,
            comments: s.comments,
          }
        : {
            feature: s?.feature,
            qty: s?.qty ? s.qty : 0,
            comments: s?.comments,
          }
    );
  }
  if (images) {
    files = images
      ?.map((image) => image?.fileContent)
      .concat(videos?.map((video) => video?.fileContent));
    allFiles = images?.concat(videos);
    if (files?.length > 0) {
      files_information = allFiles?.map((file) => {
        if (file) {
          const {fileContent, ...other} = file;
          return {...other};
        }
        return;
      });
    }
  }
  initialValues = {
    contacts: filteredContact,
    property: {
      address: !_isEmpty(domain_address)
        ? {domain_address}
        : address_1?.[0] ?? {},
      features: listing_featuresData,
      type: property?.type,
      category: property?.category,
    },
    files: files,
    files_information: files_information,
    type: type,
    property_type: property_type?.id,
    contract_in_office,
    ...(contract_in_office === 1 && {contract_date: contract_date}),
    agency_agreement_check,
    ...(agency_agreement_check === 1
      ? {
          agency_agreement_check_date: agency_agreement_check_date,
        }
      : {agency_agreement_check_date: ""}),
    ...other,
  };

  return initialValues;
}

export function getListingDetails(value) {
  let listContact;

  const {
    contacts,
    organisation,
    property,
    tags,
    amount,
    agreement_type,
    price_from,
    marketing_package,
    price_to,
    status,
    ranking,
    lived_in,
    lead_source,
    lead_source_team,
    lifestyle,
    show_full_address,
    contact_number,
    auction_date,
    ...otherListingInfo
  } = value;
  const {address, features, type, category, ...otherProperty} = property;

  const featuresData = features?.map(
    ({feature, feature_menu_option, ...obj}) => ({
      feature: feature?.id,
      feature_menu_option: feature_menu_option?.id,
      ...obj,
    })
  );

  listContact = contacts
    // ?.filter((k) => ![2, 17, 5, 7].includes(k?.contact_type?.id))
    .map((obj) => ({
      id: obj.id,
      isSelected: false,
      contact_type: obj?.contact_type?.id,
      name: obj?.first_name + " " + obj?.last_name,
      phone: obj?.phones ? obj?.phones[0]?.value : "",
      email: obj?.emails ? obj?.emails[0]?.value : "",
    }));

  let initialValues = {
    contacts: listContact,
    property: {
      ...otherProperty,
      features: featuresData,
      type: property?.type?.id,
      category: category?.id,
    },
    domain_address: getProcessedDomainAddress(address),
    address: [
      {
        id: address?.id,
        street_unit: address?.street_unit,
        postcode: address?.street?.suburb?.postcode,
        street_number: address?.street_number,
        street_name: {
          label: address?.street?.name,
          value: address?.street?.id,
        },
        suburb: {
          label: address?.street?.suburb?.name,
          value: address?.street?.suburb?.id,
        },
        state: {
          label: address?.street?.suburb?.state?.name,
          value: address?.street?.suburb?.state?.id,
        },
      },
    ],
    listing_agent: contacts
      ?.filter((k) => [2].includes(k?.contact_type?.id))
      .map((obj) => ({id: obj.id}))[0]?.id,
    display_agent: contacts
      ?.filter((k) => [17].includes(k?.contact_type?.id))
      .map((obj) => ({id: obj.id}))[0]?.id,
    co_listing_agent: contacts
      ?.filter((k) => [7].includes(k?.contact_type?.id))
      .map((obj) => ({id: obj.id}))[0]?.id,
    agreement_type: agreement_type?.id,
    // marketing: [{
    //   marketing_package: marketing_package?.id,
    //   amount: marketing_package?.id !== 1 ? marketing_package?.amount: amount,
    // }],
    lifestyle: lifestyle?.id,
    organisation: organisation?.id,
    lead_source: lead_source?.id,
    lead_source_team: lead_source_team?.id,
    lived_in: lived_in?.id,
    ranking: ranking?.id,
    marketing_package: marketing_package?.id,
    status: status?.id,
    tags: tags.map((obj) => obj.id),
    show_full_address: show_full_address ? show_full_address : 0,
    contact_number: contact_number?.id,
    auction_date: auction_date
      ? moment(new Date(auction_date)).format("YYYY-MM-DDTHH:mm")
      : todayDate,
    ...otherListingInfo,
  };

  return initialValues;
}
export function getProspectDetails(value) {
  const {
    contacts,
    property,
    status,
    rating,
    tags,
    ...otherProspectInfo
  } = value;
  const {address, features, ...otherProperty} = property ? property : {};

  const featuresData = features?.map(
    ({feature, feature_menu_option, ...obj}) => ({
      feature: feature?.id,
      feature_menu_option: feature_menu_option?.id,
      ...obj,
    })
  );

  let initialValues = {
    property: {
      ...otherProperty,
      features: featuresData,
      domain_address: getProcessedDomainAddress(address),
      address: address
        ? [
            {
              type: 1,
              id: address?.id,
              street_unit: address?.street_unit,
              postcode: address?.street?.suburb?.postcode,
              street_number: address?.street_number,
              street_name: {
                label: address?.street?.name,
                value: address?.street?.id,
              },
              suburb: {
                label: address?.street?.suburb?.name,
                value: address?.street?.suburb?.id,
              },
              state: {
                label: address?.street?.suburb?.state?.name,
                value: address?.street?.suburb?.state?.id,
              },
            },
          ]
        : [{type: 1, street_unit: "", street: {}, suburb: {}, state: {}}],
    },
    tags: tags?.filter((item) => item).map((obj) => obj.id),
    rating: rating?.id,
    ...otherProspectInfo,
  };

  return initialValues;
}

export function updateProspectDetails(values) {
  const {property, primary_contact, ...other} = values;
  const {features, address, domain_address, ...otherProperty} = property;
  let address_1, listing_featuresData;
  if (address?.length > 0) {
    address_1 = address?.map((s) => ({
      // id: s?.id,
      postcode: s.postcode ? s.postcode : null,
      street_unit: s.street_unit ? s.street_unit : null,
      street_number: s.street_number ? s.street_number : null,
      street: s.street_name?.value ? s.street_name.value : null,
      suburb: s.suburb?.value ? s.suburb.value : null,
      state: s.state?.value ? s.state.value : null,
      type: s.type ? s.type : 1,
    }));
  }

  if (features?.length) {
    listing_featuresData = features?.map((s) =>
      s.feature === 20 || s.feature === 9
        ? {
            feature: s.feature,
            feature_menu_input: s.feature_menu_input ? s.feature_menu_input : 0,
            feature_menu_option: s.feature_menu_option
              ? s.feature_menu_option
              : 0,
            comments: s.comments,
          }
        : {feature: s.feature, qty: s.qty ? s.qty : "", comments: s.comments}
    );
  }

  let initialValues = {
    property: {
      address: !_isEmpty(domain_address)
        ? {domain_address}
        : address_1?.[0] ?? {},
      features: listing_featuresData,
      ...otherProperty,
    },
    ...other,
  };

  return initialValues;
}

export function getRentalDetails(value = {}) {
  let listContact;
  const {
    contacts,
    organisation,
    property,
    tags,
    agreement_type,
    status,
    lead_source,
    lead_source_team,
    payment_frequency,
    term_of_agreement_period,
    // property_manager,
    // bdm,
    contact_number,
    ...otherListingInfo
  } = value;
  const {address, category, features, type, ...otherProperty} = property;

  const featuresData = features?.map(
    ({feature, feature_menu_option, ...obj}) => ({
      feature: feature?.id,
      feature_menu_option: feature_menu_option?.id,
      ...obj,
    })
  );
  const property_manager = contacts?.find(
    (contact) => contact?.contact_type?.id === 6
  )?.id;
  const bdm = contacts?.find((contact) => contact?.contact_type?.id === 20)?.id;

  listContact = contacts
    // ?.filter((k) => ![2, 17, 5, 7].includes(k?.contact_type?.id))
    .map((obj) => ({
      id: obj.id,
      isSelected: false,
      contact_type: obj?.contact_type?.id,
      name: obj?.first_name + " " + obj?.last_name,
      phone: obj?.phones ? obj?.phones[0]?.value : "",
      email: obj?.emails ? obj?.emails[0]?.value : "",
    }));

  let initialValues = {
    contacts: listContact,
    organisation: organisation?.id,
    property: {
      ...otherProperty,
      features: featuresData,
      category: category?.id,
      type: property?.type?.id,
    },
    domain_address: getProcessedDomainAddress(address),
    address: [
      {
        id: address?.id,
        street_unit: address?.street_unit,
        postcode: address?.street?.suburb?.postcode,
        street_number: address?.street_number,
        street_name: {
          label: address?.street?.name,
          value: address?.street?.id,
        },
        suburb: {
          label: address?.street?.suburb?.name,
          value: address?.street?.suburb?.id,
        },
        state: {
          label: address?.street?.suburb?.state?.name,
          value: address?.street?.suburb?.state?.id,
        },
      },
    ],
    agreement_type: agreement_type?.id,
    // marketing: [{
    //   marketing_package: marketing_package?.id,
    //   amount: marketing_package?.id !== 1 ? marketing_package?.amount: amount,
    // }],
    lead_source: lead_source?.id,
    status: status?.id,
    tags: tags.map((obj) => obj.id),

    term_of_agreement_period: term_of_agreement_period?.id,
    property_manager,
    bdm,
    contact_number: contact_number?.id,
    payment_frequency: payment_frequency?.id,
    ...otherListingInfo,
    type: value.type?.id,
  };

  return initialValues;
}

export function getProcessedListingSalesAdvice(value) {
  const {
    contacts,
    property,
    tags,
    amount,
    agreement_type,
    price_from,
    price_to,
    status,
    show_full_address,
    marketing_package,
    ranking,
    fee_percent,
    marketing_amount_owed,
    ...otherListingInfo
  } = value;
  const {address, features, ...otherProperty} = property;

  let buyer_agent = contacts?.filter((k) =>
    [5].includes(k?.contact_type?.id)
  )[0]?.id;
  let listing_agent = contacts?.filter((k) =>
    [2].includes(k?.contact_type?.id)
  )[0]?.id;

  let listContact = contacts
    ?.filter((k) => [10, 8].includes(k?.contact_type?.id))
    .map((obj) => ({
      id: obj.id,
      isSelected: false,
      contact_type: obj.contact_type.id,
      name: obj?.first_name + " " + obj?.last_name,
      phone: obj?.phones ? obj?.phones[0]?.value : "",
      email: obj?.emails ? obj?.emails[0]?.value : "",
    }));

  let salesAdviceContact = contacts
    ?.filter((k) => [9, 11].includes(k?.contact_type?.id))
    .map((obj) => ({
      id: obj.id,
      isSelected: false,
      contact_type: obj.contact_type.id,
      name: obj?.first_name + " " + obj?.last_name,
      phone: obj?.phones ? obj?.phones[0]?.value : "",
      email: obj?.emails ? obj?.emails[0]?.value : "",
    }));

  let initialValues = {
    marketing_amount_owed: marketing_amount_owed ?? 0,
    fee_percent: fee_percent ?? 0,
    listing_details_contacts: listContact,
    buyer_agent: buyer_agent,
    listing_agent: listing_agent,
    contacts: salesAdviceContact,

    address: [
      {
        id: address?.id,
        street_unit: address?.street_unit,
        postcode: address?.street?.suburb?.postcode,
        street_number: address?.street_number,
        street_name: {
          label: address?.street?.name,
          value: address?.street?.id,
        },
        suburb: {
          label: address?.street?.suburb?.name,
          value: address?.street?.suburb?.id,
        },
        state: {
          label: address?.street?.suburb?.state?.name,
          value: address?.street?.suburb?.state?.id,
        },
      },
    ],
    show_full_address: show_full_address,
  };

  return initialValues;
}

export function getListingDetailsBasic(value = {}) {
  let listContact;

  const {
    contacts,
    property,
    tags,
    amount,
    agreement_type,
    price_from,
    price_to,
    status,
    show_full_address,
    marketing_package,
    ranking,
    ...otherListingInfo
  } = value;
  const {address, features, ...otherProperty} = property;

  const featuresData = features?.map(({feature, ...obj}) => ({
    feature: feature.id,
    ...obj,
  }));

  listContact = contacts
    // ?.filter((k) => ![2, 17, 7].includes(k?.contact_type?.id))
    .map((obj) => ({
      id: obj.id,
      isSelected: false,
      contact_type: obj?.contact_type?.id,
      name: obj?.first_name + " " + obj?.last_name,
      phone: obj?.phones ? obj?.phones[0]?.value : "",
      email: obj?.emails ? obj?.emails[0]?.value : "",
    }));

  let initialValues = {
    contacts: listContact,
    address: [
      {
        id: address?.id,
        street_unit: address?.street_unit,
        postcode: address?.street?.suburb?.postcode,
        street_number: address?.street_number,
        street_name: {
          label: address?.street?.name,
          value: address?.street?.id,
        },
        suburb: {
          label: address?.street?.suburb?.name,
          value: address?.street?.suburb?.id,
        },
        state: {
          label: address?.street?.suburb?.state?.name,
          value: address?.street?.suburb?.state?.id,
        },
      },
    ],
    show_full_address: show_full_address,
  };
  return initialValues;
}

export function updateRentalDetails(values) {
  let address_1, listing_featuresData, files, files_information;
  let initialValues;
  const {
    assets,
    reports,
    property,
    contacts,
    address,
    domain_address,
    images,
    videos,
    property_manager,
    property_type,
    type,
    bdm,
    contract_in_office,
    agreement_date,

    ...other
  } = values;
  const {features, ...otherProperty} = property;

  let filteredContact = contacts.filter(
    (obj) => obj.contact_type !== 6 && obj.contact_type !== 20
  );
  // if (listing_agent) {
  //   filteredContact.push({ id: listing_agent, contact_type: 2 });
  // }

  // if (co_listing_agent) {
  //   filteredContact.push({ id: co_listing_agent, contact_type: 7 });
  // }

  // if (display_agent) {
  //   filteredContact.push({ id: display_agent, contact_type: 17 });
  // }
  if (bdm) {
    filteredContact.push({id: bdm, contact_type: 20});
  }
  if (property_manager) {
    filteredContact.push({id: property_manager, contact_type: 6});
  }

  if (address?.length > 0) {
    address_1 = address?.map((s) => ({
      id: s?.id,
      postcode: s.postcode ? s.postcode : null,
      street_unit: s.street_unit ? s.street_unit : null,
      street_number: s.street_number ? s.street_number : null,
      street: s.street_name?.value ? s.street_name.value : null,
      suburb: s.suburb?.value ? s.suburb.value : null,
      state: s.state?.value ? s.state.value : null,
      type: s.type ? s.type : 1,
    }));
  }

  if (features?.length) {
    listing_featuresData = features?.map((s) =>
      s.feature === 20 || s.feature === 9
        ? {
            feature: s.feature,
            feature_menu_input: s.feature_menu_input ? s.feature_menu_input : 0,
            feature_menu_option: s.feature_menu_option
              ? s.feature_menu_option
              : 0,
            comments: s.comments,
          }
        : {feature: s.feature, qty: s.qty ? s.qty : "", comments: s.comments}
    );
  }
  if (images || videos) {
    files = images
      .map((image) => image.path)
      .concat(videos.map((video) => video.path));

    if (files?.length) {
      files_information = files?.map((s) => ({
        title: s?.title ? s?.title : s?.name,
        id: s?.id,
      }));
    }
  }
  initialValues = {
    contacts: filteredContact,
    property: {
      address: !_isEmpty(domain_address)
        ? {domain_address}
        : address_1?.[0] ?? {},
      features: listing_featuresData,
      ...otherProperty,
    },
    files: files,
    files_information: files_information,
    type: type,
    contract_in_office,
    ...(contract_in_office === 1 && {agreement_date: agreement_date}),
    ...other,
  };
  return initialValues;
}

export function getChangedValues(values, initialValues) {
  return Object.entries(values).reduce((acc, [key, value]) => {
    const hasChanged = initialValues[key] !== value;

    if (hasChanged) {
      acc[key] = value;
    }

    return acc;
  }, {});
}

const _listingFeatureMapping = (values) => {
  return values?.map((s) =>
    ![9, 20, 36].includes(s.feature)
      ? {feature: s.feature, qty: s.qty ? s.qty : 0, comments: s.comments}
      : s.feature === 20 || s.feature === 9
      ? {
          feature: s.feature,
          feature_menu_input: s.feature_menu_input ? s.feature_menu_input : 0,
          feature_menu_option: s.feature_menu_option
            ? s.feature_menu_option
            : 0,
          comments: s.comments,
        }
      : {
          feature: s.feature,
          feature_menu_option: s.feature_menu_option
            ? s.feature_menu_option
            : 0,
          comments: s.comments,
        }
  );
};

export function updateListingDetails(values) {
  let address_1, listing_featuresData, files, files_information;
  let initialValues;
  const {
    assets,
    reports,
    property,
    contacts,
    address,
    images,
    videos,
    auction_date,
    listing_agent,
    co_listing_agent,
    display_agent,
    property_type,
    type,
    property_manager,
    contract_in_office,
    contract_date,
    agency_agreement_check,
    agency_agreement_check_date,
    marketing_text,
    appointments,
    domain_address,
    ...other
  } = values;
  const {features, ...otherProperty} = property;

  let filteredContact = contacts.filter(
    (obj) => ![2, 7, 17].includes(obj.contact_type)
  );

  if (listing_agent) {
    filteredContact.push({id: listing_agent, contact_type: 2});
  }

  if (co_listing_agent) {
    filteredContact.push({id: co_listing_agent, contact_type: 7});
  }

  if (display_agent) {
    filteredContact.push({id: display_agent, contact_type: 17});
  }

  if (property_manager) {
    filteredContact.push({id: property_manager, contact_type: 6});
  }

  if (address?.length > 0) {
    address_1 = address?.map((s) => ({
      // id: s?.id,
      postcode: s.postcode ? s.postcode : null,
      street_unit: s.street_unit ? s.street_unit : null,
      street_number: s.street_number ? s.street_number : null,
      street: s.street_name?.value ? s.street_name.value : null,
      suburb: s.suburb?.value ? s.suburb.value : null,
      state: s.state?.value ? s.state.value : null,
      type: s.type ? s.type : 1,
    }));
  }

  if (features?.length) {
    listing_featuresData = _listingFeatureMapping(features);
  }
  if (images || videos) {
    files = images
      .map((image) => image.path)
      .concat(videos.map((video) => video.path));

    if (files?.length) {
      files_information = files?.map((s) => ({
        title: s?.title ? s?.title : s?.name,
        id: s?.id,
      }));
    }
  }
  initialValues = {
    contacts: filteredContact,
    auction_date: auction_date
      ? moment(auction_date).format("YYYY-MM-DDTHH:mm")
      : null,
    property: {
      // address: { domain_address },
      address: !_isEmpty(domain_address)
        ? {domain_address}
        : address_1?.[0] ?? {},
      features: listing_featuresData ?? [],
      ...otherProperty,
    },
    files: files,
    files_information: files_information,
    type: type?.id ? type?.id : type,
    contract_in_office,
    ...(contract_in_office === 1 && {contract_date: contract_date}),
    agency_agreement_check,
    ...(agency_agreement_check === 1
      ? {
          agency_agreement_check_date: agency_agreement_check_date,
        }
      : {agency_agreement_check_date: ""}),
    ...other,
  };
  return initialValues;
}

export function updateListingContact(values) {
  let address_1, listing_featuresData, files, files_information;
  let initialValues;
  const {
    assets,
    reports,
    property,
    contacts,
    address,
    images,
    videos,
    auction_date,
    listing_agent,
    co_listing_agent,
    display_agent,
    property_type,
    type,
    property_manager,
    contract_in_office,
    contract_date,
    agency_agreement_check,
    agency_agreement_check_date,
    marketing_text,
    appointments,
    domain_address,
    deletedContact,
    ...other
  } = values;
  const {features, ...otherProperty} = property;

  let filteredContact = contacts.filter(
    (obj) => ![2, 7, 17].includes(obj.contact_type)
  );

  let deleted_listing_agent = deletedContact.filter(
    (del) =>
      del.contact_type === 2 &&
      (del.id === listing_agent || del?.delete_contact === 1)
  );

  let deleted_co_listing_agent = deletedContact.filter(
    (del) =>
      del.contact_type === 7 &&
      (del.id === co_listing_agent || del?.delete_contact === 1)
  );

  let deleted_display_agent = deletedContact.filter(
    (del) =>
      del.contact_type === 17 &&
      (del.id === display_agent || del?.delete_contact === 1)
  );

  if (listing_agent && deleted_listing_agent.length === 0) {
    filteredContact.push({id: listing_agent, contact_type: 2});
  } else if (deleted_listing_agent.length > 0) {
    let filtercon = filteredContact.filter((id) => id !== listing_agent);
    filteredContact = filtercon;
  }

  if (co_listing_agent && deleted_co_listing_agent.length === 0) {
    filteredContact.push({id: co_listing_agent, contact_type: 7});
  } else if (deleted_co_listing_agent.length > 0) {
    let filtercon = filteredContact.filter((id) => id !== co_listing_agent);
    filteredContact = filtercon;
  }

  if (display_agent && deleted_display_agent.length === 0) {
    filteredContact.push({id: display_agent, contact_type: 17});
  } else if (deleted_display_agent.length > 0) {
    let filtercon = filteredContact.filter((id) => id !== display_agent);
    filteredContact = filtercon;
  }

  if (property_manager) {
    filteredContact.push({id: property_manager, contact_type: 6});
  }

  if (address?.length > 0) {
    address_1 = address?.map((s) => ({
      // id: s?.id,
      postcode: s.postcode ? s.postcode : null,
      street_unit: s.street_unit ? s.street_unit : null,
      street_number: s.street_number ? s.street_number : null,
      street: s.street_name?.value ? s.street_name.value : null,
      suburb: s.suburb?.value ? s.suburb.value : null,
      state: s.state?.value ? s.state.value : null,
      type: s.type ? s.type : 1,
    }));
  }

  if (features?.length) {
    listing_featuresData = _listingFeatureMapping(features);
  }
  if (images || videos) {
    files = images
      .map((image) => image.path)
      .concat(videos.map((video) => video.path));

    if (files?.length) {
      files_information = files?.map((s) => ({
        title: s?.title ? s?.title : s?.name,
        id: s?.id,
      }));
    }
  }

  initialValues = {
    contacts: filteredContact,
    auction_date: auction_date
      ? moment(auction_date).format("YYYY-MM-DDTHH:mm")
      : null,
    property: {
      // address: { domain_address },
      address: !_isEmpty(domain_address)
        ? {domain_address}
        : address_1?.[0] ?? {},
      features: listing_featuresData ?? [],
      ...otherProperty,
    },
    files: files,
    files_information: files_information,
    type: type?.id ? type?.id : type,
    contract_in_office,
    ...(contract_in_office === 1 && {contract_date: contract_date}),
    agency_agreement_check,
    ...(agency_agreement_check === 1
      ? {
          agency_agreement_check_date: agency_agreement_check_date,
        }
      : {agency_agreement_check_date: ""}),
    ...other,
  };

  return initialValues;
}

const clone = (obj) => Object.assign({}, obj);
export function renameKey(object, key, newKey) {
  const clonedObj = clone(object);

  const targetKey = clonedObj[key];

  delete clonedObj[key];

  clonedObj[newKey] = targetKey;

  return clonedObj;
}

export function getProcessedListingReport(values) {
  let initialValues;

  const {type, ...others} = values;

  initialValues = {
    type: type?.id,
    ...others,
  };
  return initialValues;
}

//  getDummyTableRow return number of dummy Table rows required depends on display Vh number - number of rows already printed.
export function getDummyTableRow(vh, printedRows) {
  let px = Math.round(window.innerHeight / (100 / vh));
  let number = Math.round(px / 37) - (printedRows + 1);
  if (number > 0) {
    return number;
  } else return 0;
}

export function checkIsBetweenDate(from, to) {
  let beginningTime = moment();

  return beginningTime.isBetween(
    moment(from, "YYYY-MM-DD"),
    moment(to, "YYYY-MM-DD")
  );
}

export function getGSTValue(value) {
  return ((10 / 100) * value).toFixed(2);
}

export function postBonusStructure(values) {
  const {sales, commission, listings, ...others} = values;

  let salesData = sales?.map((obj, index, array) => ({
    type: 3,
    sale_from: index === 0 ? 0 : array[index - 1]?.sale_to,
    ...obj,
  }));
  let commissionData = commission?.map((obj, index, array) => ({
    type: 1,
    sale_from: index === 0 ? 0 : array[index - 1]?.sale_to,
    ...obj,
  }));
  let listingsData = listings?.map((obj, index, array) => ({
    type: 2,
    sale_from: index === 0 ? 0 : array[index - 1]?.sale_to,
    ...obj,
  }));

  let processedValue = {
    bonus_items: [...salesData, ...commissionData, ...listingsData],
    ...others,
  };

  return processedValue;
}

export function getBonusStructure(values) {
  const {bonus_items, ...others} = values;

  let salesData = bonus_items
    .filter((obj) => obj.type.id === 1)
    .map(({type, ...other}) => ({type: type.id, ...other}));
  let commissionData = bonus_items
    .filter((obj) => obj.type.id === 2)
    .map(({type, ...other}) => ({type: type.id, ...other}));
  let listingsData = bonus_items
    .filter((obj) => obj.type.id === 3)
    .map(({type, ...other}) => ({type: type.id, ...other}));

  let processedValue = {
    sales: salesData,
    commission: commissionData,
    listings: listingsData,
    ...others,
  };

  return processedValue;
}

export function postListingLibrary(values) {
  let address_1, files_information, files, allFiles;
  let initialValues;
  const {
    assets,
    reports,
    listing_agent,
    property,
    show_full_address,
    co_listing_agent,
    display_agent,
    contacts,
    address,
    domain_address,
    images,
    videos,
    youtube_links,
    ...other
  } = values;

  if (listing_agent) {
    contacts.push({id: listing_agent, contact_type: 2});
  }

  if (co_listing_agent) {
    contacts.push({id: co_listing_agent, contact_type: 7});
  }

  if (display_agent) {
    contacts.push({id: display_agent, contact_type: 17});
  }

  if (address?.length > 0) {
    address_1 = address?.map((s) => ({
      id: s?.id,
      postcode: s.postcode ? s.postcode : null,
      street_unit: s.street_unit ? s.street_unit : null,
      street_number: s.street_number ? s.street_number : null,
      street: s.street_name?.value ? s.street_name.value : null,
      suburb: s.suburb?.value ? s.suburb.value : null,
      state: s.state?.value ? s.state.value : null,
      type: s.type ? s.type : 1,
    }));
  }

  if (images) {
    files = images
      ?.map((image) => image?.fileContent)
      .concat(videos?.map((video) => video?.fileContent));
    allFiles = images?.concat(videos);
    if (files?.length > 0) {
      files_information = allFiles?.map((file) => {
        if (file) {
          const {
            fileContent,
            website,
            floorplan,
            property_portals,
            is_private,
            id,
            ...other
          } = file;

          return {
            floorplan: floorplan,
            is_private: is_private,
            website: website,
            property_portals: property_portals,
            id,
            // ...other
          };
        }
        return;
      });
    }
  }

  initialValues = {
    // contacts,
    property: {
      address: !_isEmpty(domain_address)
        ? {domain_address}
        : address_1?.[0] ?? {},
    },
    show_full_address,
    files: files ?? [],
    files_information: files_information ?? [],
    assets: images
      ?.filter((image) => !image?.fileContent)
      .concat(videos?.filter((video) => !video?.fileContent))
      .map((value) => value?.id),
    youtube_links: youtube_links || "",
  };
  return initialValues;
}

export function getMailProcessData(values) {
  const {
    created_at,
    from_contact,
    type,
    content,
    contact,
    id,
    email,
    sms,
    ...other
  } = values;

  let intialValue = {
    id: id,
    created_at: created_at?.split("T")?.[0],
    from_contact: from_contact?.id,
    email_subject: email?.email_subject,
    type: type?.id,
    content: type?.id === 1 ? sms?.content : email?.content,
  };

  return intialValue;
}

export function getFeaturesProcessedData(values, feature) {
  return values?.find((value) => value?.feature?.name === feature)?.qty;
}

export function getAppointmentProcessedForOpenHome(values) {
  return values.map((value) => {
    return {
      date: value?.starts?.split(" ")[0],
      timeStart: value?.starts ? moment(value?.starts).format("hh:mm a") : "",
      timeEnd: value?.starts ? moment(value?.ends).format("hh:mm a") : "",
    };
  });
}

export function processUpdateProperty(values, update = true) {
  let address_1, listing_featuresData;
  let initialValues;
  const {
    features,

    address,
  } = values;

  if (address?.length > 0) {
    address_1 = address?.map((s) => ({
      id: s.id,
      postcode: s.postcode ? s.postcode : null,
      street_unit: s.street_unit ? s.street_unit : null,
      street_number: s.street_number ? s.street_number : null,
      street: s.street_name?.value ? s.street_name.value : null,
      suburb: s.suburb?.value ? s.suburb.value : null,
      state: s.state?.value ? s.state.value : null,
    }));
  }

  if (features?.length) {
    listing_featuresData = features
      ?.filter((s) => s?.qty > 0)
      .map((s) => {
        return s.feature === 20 || s.feature === 9
          ? {
              feature: s.feature,
              feature_menu_input: s.feature_menu_input
                ? s.feature_menu_input
                : 0,
              feature_menu_option: s.feature_menu_option
                ? s.feature_menu_option
                : 0,
              comments: s.comments,
            }
          : {
              feature: s?.feature,
              qty: s?.qty ? s.qty : "",
              comments: s?.comments,
            };
      });
  }

  initialValues = update
    ? {
        address: address_1[0],
        features: listing_featuresData,
        _method: "Patch",
      }
    : {
        address: address_1[0],
        features: listing_featuresData,
      };

  return initialValues;
}

export function getProcessedBuyerSettings(values) {
  const obj = {
    contact_frequency: values?.contact_frequency,
    contact_frequency_unit: values?.contact_frequency_unit.id,
    exclusive_period: values?.exclusive_period,
    exclusive_period_unit: values?.exclusive_period_unit.id,
    allocation_rules: values?.allocation_rules,
    id: values?.id,
  };

  return obj;
}

export function getProcessedZoneAnalysis(values) {
  const {allocated_to, contacts, ...others} = values;

  let initialValues = {
    allocated_to: allocated_to?.id,
    contacts: contacts.map((item) => item.id),
    ...others,
  };

  return initialValues;
}

export function processSalesAdvice(values) {
  const {contacts, ...other} = values;

  return {
    contacts: contacts?.map((contact) => ({
      name: contact?.first_name + " " + contact?.last_name,
      email: contact?.emails?.[0]?.value,
      phone: contact?.phones?.[0]?.value,
      contact_type: contact?.contact_type?.id,
    })),
    ...other,
  };
}

export function addNumberSeparator(number, options = {}) {
  let value = number ? parseFloat(number)?.toLocaleString("en-US", options) : 0;

  return value;
}

export function getErrorMessage(e) {
  return e ? Object.values(e?.data?.data).map((message) => message[0]) : "";
}

export function getAddressForDiary(address) {
  return {
    id: address?.id,
    street_unit: address?.street_unit,
    street_number: address?.street_number,
    street_name: {
      value: address?.street?.id,
      label: address?.street?.name,
      suburb_name: address?.street?.suburb?.name,
      suburb_id: address?.street?.suburb?.id,
      state_id: address?.street?.suburb?.state?.id,
      state_name: address?.street?.suburb?.state?.name,
      postcode: address?.street?.suburb?.postcode,
    },
    suburb: {
      value: address?.street?.suburb?.id,
      label: address?.street?.suburb?.name,
    },
    state: {
      label: address?.street?.suburb?.state?.name,
      value: address?.street?.suburb?.state?.id,
    },
    postcode: address?.street?.suburb?.postcode,
  };
}

const convertToArray = (data) => {
  if (!data || data instanceof Array) {
    return data;
  }
  return data?.split(",").map((v) => parseInt(v));
};

export function getBulkFilterData(
  {
    agent,
    buyer_type,
    buyer_condition,
    buyer_to_sell,
    finance_status,
    tags,
    buyer_property_type,
    tenant_active,
    tenant_types,
    seller_listing_status,
    seller_property_type,
    seller_agreement_type,
    landlord_rental_type,
    landlord_rental_status,
    landlord_property_type,
      suburbs,
    listing_tags,
    prospect_tags,
    ...rest
  },
  data
) {
  let obj = {
    filter_name: data.name,
    copy_existing: data.copy_existing ?? null,
    agent: convertToArray(agent),
    buyer_type: convertToArray(buyer_type),
    buyer_condition: convertToArray(buyer_condition),
    buyer_to_sell: convertToArray(buyer_to_sell),
    finance_status: convertToArray(finance_status),
    tags: convertToArray(tags),
    buyer_property_type: convertToArray(buyer_property_type),
    tenant_active: convertToArray(tenant_active),
    tenant_types: convertToArray(tenant_types),
    seller_listing_status: convertToArray(seller_listing_status),
    seller_property_type: convertToArray(seller_property_type),
    seller_agreement_type: convertToArray(seller_agreement_type),
    landlord_rental_type: convertToArray(landlord_rental_type),
    landlord_rental_status: convertToArray(landlord_rental_status),
    landlord_property_type: convertToArray(landlord_property_type),
    suburbs: convertToArray(suburbs)||[],
    listing_tags: convertToArray(listing_tags)||[],
    prospect_tags: convertToArray(prospect_tags)||[],
    ...rest,
  };
  // Object.entries(values).forEach(([key, value]) => {
  //   obj[key] = (value instanceof Array ? value : value.split(",")).map((v) => parseInt(v));
  // });
  // let initialData = {
  //   ...obj, filter_name: data.name, copy_existing: data.copy_existing ?? null
  // }
  return obj;
}

export function checkIfSubModulesAllSelected(values) {
  return (
    values?.sub_modules &&
    Object.entries(values?.sub_modules).filter(([key, value]) => {
      return (
        value?.permissions &&
        value.permissions.filter((permission) => permission.selected)
          ?.length === value?.permissions?.length
      );
    })?.length === Object.keys(values?.sub_modules)?.length
  );
}

export function handleQueryChangeAccordingToTitle(values) {
  switch (values) {
    case "All Listings":
      return "";
    case "Current Listings":
      return "contract_in_office=1&state=1";
    case "Expired Listings":
      return "state=2";
    case "Inactive Listings":
      return "state=3";
    case "Unconditional Listings":
      return "state=4";
    case "Settled Listings":
      return "state=5";
    case "Withdrawn Listings":
      return "state=6";
    default:
      return "contract_in_office=1&state=1";
  }
}
export function handleQueryChangeAccordingToTitleRental(values) {
  switch (values) {
    case "All Rentals":
      return "";
    case "Available Rentals":
      return "contract_in_office=1&status=1";
    case "Rented Rentals":
      return "status=2";
    case "Withdrawn Rentals":
      return "status=3";

    default:
      return "contract_in_office=1&status=1";
  }
}

export function calCommissionSplit(splitArray = [], commission, test) {
  let result = 0;
  splitArray.forEach((sArray = {}) => {
    const {sale_to, sale_from, commission_percent} = sArray;

    if (commission > sale_to) {
      result = result + (sale_to - sale_from) * (commission_percent / 100);
    } else if (sale_from <= commission && commission <= sale_to) {
      result = result + (commission - sale_from) * (commission_percent / 100);
    }
  });

  return Math.round(result);
}
export function compareSettlementAndSaleTime(
  unconditional,
  settlement,
  period,
  compareWay
) {
  switch (period) {
    case 1:
      if (compareWay === "past") {
        return (
          moment(unconditional, "YYYY/MM/DD").format("M") <
          moment(settlement, "YYYY/MM/DD").format("M")
        );
      } else {
        return (
          moment(unconditional, "YYYY/MM/DD").format("M") ===
          moment(settlement, "YYYY/MM/DD").format("M")
        );
      }
    case 2:
      if (compareWay === "past") {
        return moment(unconditional)?.quarter() < moment(settlement)?.quarter();
      } else {
        return (
          moment(unconditional)?.quarter() === moment(settlement)?.quarter()
        );
      }
    case 3:
      if (compareWay === "past") {
        return (
          Math.round(moment(unconditional)?.quarter() / 2) <
          Math.round(moment(settlement)?.quarter() / 2)
        );
      } else {
        return (
          Math.round(moment(unconditional)?.quarter() / 2) ===
          Math.round(moment(settlement)?.quarter() / 2)
        );
      }
    case 4:
      if (compareWay === "past") {
        return (
          moment(unconditional, "YYYY/MM/DD").format("Y") <
          moment(settlement, "YYYY/MM/DD").format("Y")
        );
      } else {
        return (
          moment(unconditional, "YYYY/MM/DD").format("Y") ===
          moment(settlement, "YYYY/MM/DD").format("Y")
        );
      }
    default:
      return "";
  }
}

export function accumulateSales(array) {
  const reducer = (accumulator, currentValue) => accumulator + currentValue;

  return array.map((arr, index) => array.slice(0, index + 1).reduce(reducer));
}

export function getBonusForTeamMmeber(structures, memberId) {
  return structures
    ?.find((s) => s?.members?.some((m) => m?.id === memberId))
    ?.bonus_items?.filter((b) => b?.type?.id === 1);
}
export function getPeriodForTeamMmeber(structures, memberId) {
  return structures?.find((s) => s?.members?.some((m) => m?.id === memberId))
    ?.reporting_period;
}
// export function calculat

export const processBlogPostInfo = (values) => {
  const {tags, ...rest} = values;
  let formValues = {
    tags: tags[0],
    ...rest,
  };
  return formValues;
};

export const processCommPostInfo = (values) => {
  const {tags, ...rest} = values;
  let formValues = {
    tags: tags,
    ...rest,
  };
  return formValues;
};

export const processTestimonialInfo = (values) => {
  const {contacts, listing, ...rest} = values;
  let formValues = {
    contacts: contacts.map((item) => item.id),
    listing: listing
      ? {
          label: _get(listing, "property.address.full_address", null),
          value: _get(listing, "id", null),
        }
      : null,
    ...rest,
  };
  return formValues;
};

export const getFormattedDate = (date) => {
  if (date) {
    return moment(date).format(DEFAULT_DATE_FORMAT);
  } else {
    return "";
  }
};

export const parseQueryString = (data) => {
  return queryString.parse(data);
};

export const downloadImageFromUrl = (url, fileName) => {
  fetch(url, {headers: {"Cache-Control": "no-cache"}})
    .then((res) => {
      res.blob().then(function(blob) {
        // debugger
        const downloadLink = document.createElement("a");

        downloadLink.href = window.URL.createObjectURL(blob);
        downloadLink.setAttribute(
          "download",
          url.split("/")?.at(-1) ?? "image.png"
        );

        document.body.appendChild(downloadLink);

        if (fileName) {
          downloadLink.download = fileName;
        }

        // item.style.objectFit = 'cover'
        downloadLink.style.height = "1080px";
        downloadLink.style.width = "1080px";
        downloadLink.click();
        downloadLink.remove();
      });
    })
    .catch((err) => {
      toast.error("Image download Failed");
    });
};

export const downloadPdfDocument = (
  blob,
  type = "pdf",
  fileName = "download"
) => {
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName;
  document.body.appendChild(link);
  link.dispatchEvent(
    new MouseEvent("click", {bubbles: true, cancelable: true, view: window})
  );
  link.remove();
  window.URL.revokeObjectURL(link.href);
};

export const processAddress = (address = {}) => {
  return {
    postcode: address?.postcode ?? null,
    street_unit: address?.street_unit ?? null,
    street_number: address?.street_number ?? null,
    street: address?.street_name?.value ?? null,
    suburb: address?.suburb?.value ?? null,
    state: address?.state?.value ?? null,
  };
};

export const getProcessAddress = (address = []) => {
  if (address.length) {
    return address.map((item) => getProcessedDomainAddress(item));
  } else {
    return [
      {
        street_unit: "",
        street_number: "",
        postcode: "",
        street_name: {},
        suburb: {},
        state: {},
      },
    ];
  }
};

export const _postProcessAddress = (address = []) => {
  return address?.map((s) => ({
    // id: s?.id,
    postcode: s.postcode ? s.postcode : null,
    street_unit: s.street_unit ? s.street_unit : null,
    street_number: s.street_number ? s.street_number : null,
    street: s.street_name?.value ? s.street_name.value : null,
    suburb: s.suburb?.value ? s.suburb.value : null,
    state: s.state?.value ? s.state.value : null,
    type: s.type ? s.type : 1,
  }));
};

export const getMonthsAsArray = () => {
  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return month.map((value, index) => {
    return {id: index + 1, name: value};
  });
};

export const getLastNumberOfYears = (num = 20) => {
  let years = [];
  for (let i = 0; i < num; i++) {
    const val = new Date(moment().subtract(i, "year"));
    years = [...years, {id: val.getFullYear(), name: `${val.getFullYear()}`}];
  }
  return years;
};

export const getBase64URL = async (url) => {
  return await new Promise(function(resolve, reject) {
    var img = new Image();
    //ios safari 10.3 taints canvas with data urls unless crossOrigin is set to anonymous
    img.setAttribute("crossOrigin", "anonymous");
    img.crossOrigin = "anonymous";
    img.src = url + "&v=" + new Date().getTime();
    img.onerror = reject;
    var onload = function onload() {
      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);
      resolve(canvas.toDataURL());
    };
    img.onload = onload;
    if (img.complete === true) {
      setTimeout(function() {
        onload();
      }, 500);
    }
  });
};

export const formatPhoneNumber = (number) => {
  if (number && number !== "") {
    // const first = number?.substring(0, 1);
    // if (first !== "0") number = `0${number}`;
    const first2 = number?.substring(0, 2);

    if (number.length === 8) {
      number = number.replace(/(\d{4})(\d{4})/, "$1 $2");
    } else if (first2 === "04") {
      number = number.replace(/(\d{4})(\d{3})(\d*)/, "$1 $2 $3");
    } else if (first2 === "02") {
      number = number.replace(/(\d{2})(\d{4})(\d*)/, "$1 $2 $3");
    }
  }

  return number;
};

export const getMoneyFormat = (number, options = {}) => {
  return number
    ? parseFloat(number)?.toLocaleString("en", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
    : 0;
};

export const getRemainingCommission = (sale) => {
  const sum =
    parseFloat(sale?.royalty ?? 0) +
    parseFloat(sale?.conjunction ?? 0) +
    parseFloat(sale?.marketing_fee_deduction ?? 0) +
    parseFloat(sale?.other_fee_deduction ?? 0);
  return sale.commission - sum > 0 ? sale.commission - sum : 0;
};

export const systemBoardsGetResultFontColor = (
  result,
  target,
  index,
  tIndex = null
) => {
  return result[index] >= target[tIndex ?? index] ? "green-color" : "red-color";
};

export const validateAndResizeImage = async (file) => {
  if (file.size / 1024 / 1024 > 1) {
    const options = {
      maxSizeMB: 0.7,
      maxWidthOrHeight: 1920,
    };
    try {
      const compressedFile = await imageCompression(file, options);
      return compressedFile;
    } catch (error) {
      console.error(error);
      return file;
    }
  } else {
    return file;
  }
};

export const capitalizeWord = (word) => {
  return (
    word.charAt(0).toUpperCase() + String(word.slice(1)).toLocaleLowerCase()
  );
};

export const capitalizeText = (string) => {
  const arrayOfWords = string.split(" ");

  arrayOfWords.forEach((word, index) => {
    arrayOfWords[index] = capitalizeWord(word);
  });

  return arrayOfWords.join(" ");
};

export const convertDateStringToDate = (dateString, format = "US") => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // months are 0-indexed, so add 1 and pad with leading zeros
  const day = String(date.getDate()).padStart(2, "0");
  const simpleDateFormat =
    format === "US" ? `${year}-${month}-${day}` : `${day}/${month}/${year}`;
  return simpleDateFormat;
};

export const reOrderAppointment = (appointmentsArray) => {
  const outputObject = appointmentsArray?.reduce((result, item) => {
    const arrayIndex = convertDateStringToDate(item.start);
    result[arrayIndex] = result[arrayIndex] || [];
    result[arrayIndex].push(item);
    return result;
  }, {});

  const reOrderedAppointments = [];

  if (outputObject && Object.keys(outputObject)?.length > 0) {
    Object.keys(outputObject).forEach((appointmentDay) => {
      const appointmentsByDay = outputObject[appointmentDay];
      appointmentsByDay.sort((a, b) => new Date(a.start) - new Date(b.start));
      appointmentsByDay.forEach((appt) => reOrderedAppointments.push(appt));
    });
  }

  return reOrderedAppointments;
};

export const createGuid = () => {
  function S4() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  }
  return (
    S4() +
    S4() +
    "-" +
    S4() +
    "-4" +
    S4().substr(0, 3) +
    "-" +
    S4() +
    "-" +
    S4() +
    S4() +
    S4()
  ).toLowerCase();
};

export const getDateDiffInDays = (startDate, endDate = new Date()) => {
  return Math.abs(
    moment(startDate).diff(moment(endDate).format("YYYY-MM-DD"), "days")
  );
};
