import React, { useState, Fragment } from "react";
import { Card } from "react-bootstrap";
import { FormGroup, FormControlLabel, Switch } from "@material-ui/core";
import LoadingSkeleton from "../../display/skeleton/LoadingSkeleton";
import NoteItem from "./NoteItem";
import { Waypoint } from "react-waypoint";

const NoteLists = ({ notes = [], makePinned, fetchMore, profile }) => {
  const [displayGeneralNote, setDisplayGeneralNote] = useState(false);
  const tag = notes?.map((note) => {
    let noteId = note?.prospect && note?.prospect[0]?.id;
    let profileId = profile?.types.filter((pro) => pro?.prospect_id == noteId);

    return { ...note, profileId };
  });

  let pinnedNotes = tag?.filter((note) => note.pinned === 1);
  let normalNotes = tag
    ?.filter((note) => note.pinned !== 1)
    .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
  return (
    <Fragment>
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              onChange={() => setDisplayGeneralNote(!displayGeneralNote)}
            />
          }
          label="Display general notes"
        />
      </FormGroup>
      <Card className="note-list">
        {notes ? (
          <Fragment>
            {pinnedNotes?.map((item) => (
              <NoteItem
                key={item?.id}
                makePinned={makePinned}
                data={item}
                displayGeneral={displayGeneralNote}
              />
            ))}
            {normalNotes?.map((item) => (
              <NoteItem
                key={item?.id}
                makePinned={makePinned}
                data={item}
                profile={profile?.types}
                displayGeneral={displayGeneralNote}
              />
            ))}
          </Fragment>
        ) : (
          <LoadingSkeleton height="30vh" />
        )}

        <Waypoint onEnter={fetchMore} />
      </Card>
    </Fragment>
  );
};

export default NoteLists;
