import React, { Fragment } from 'react'
import ThemeButton from '../../../button/ThemeButton'
import ThemeHeader from '../../../content/ThemeHeader'

function LinkedContactModal({handleLinkedContact}) {
    return (
        <div>
            <ThemeHeader left="Want to add the linked Contacts" right={<Fragment><ThemeButton className="mr-2 green-button" onClick={()=> handleLinkedContact(true)}>Yes</ThemeButton><ThemeButton onClick={()=> handleLinkedContact(false)}>No</ThemeButton></Fragment>} />
        </div>
    )
}

export default LinkedContactModal
