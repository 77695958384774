import React, { Fragment } from "react";
import { Form, Row, Col } from "react-bootstrap";
import cuid from "cuid";
import { Label } from "reactstrap";
import { IconButton } from "@material-ui/core";
import { Field } from "redux-form";
import TextInput from "./TextInput";
import DeleteIcon from "@material-ui/icons/Delete";


const NestedPhone = ({ fields, placeholder, meta: { error, submitFailed }, label, showErrorOnMount = false, onChange }) => {

  return (
    <Fragment>
      {fields.map((phone, index) => (
        <Field
          label={label}
          name={`${phone}.value`}
          type="text"
          component={TextInput}
          onChange={onChange}
          placeholder={placeholder}
          showErrorOnMount={showErrorOnMount}
        />
      ))}
    </Fragment>
  );
};

export default NestedPhone;
