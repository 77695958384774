import React, { useEffect, useState, Fragment, useContext } from "react";
import { connect } from "react-redux";
// import * as modal from '../_redux/modalRedux'
import { InputAdornment, LinearProgress } from "@material-ui/core";
import { reduxForm, Field, formValueSelector } from "redux-form";
import moment from "moment";
import {
  combineValidators,
  isRequired,
  // composeValidators,
  // hasLengthGreaterThan,
} from "revalidate";
import ThemeHeader from "../../../../partials/content/ThemeHeader";
import DragAndDrop from "../../../../partials/form/DragAndDrop";
import ThemeButton from "../../../../partials/button/ThemeButton";
import { Col, Row } from "react-bootstrap";
import TextInput from "../../../../partials/form/TextInput";
import { toast } from "react-toastify";
import TextArea from "../../../../partials/form/TextArea";
import { getListingPriceHistory } from "../../_crud/listingsCrud";
import { SingleListingContext } from "../../partials/singleListingList/SingleListingContextProvider";
import { addNumberSeparator } from "../../../../_helpers/_utils/utils";
import TextInputAmount from "../../../../partials/form/TextInputAmount";
import DefaultModal from "../../../../partials/modal/DefaultModal";
import EmailReportPreview from "../../partials/singleListingList/salesAdvice/EmailReportPreview";
import { uploadListingById, letterTest } from "../../_crud/listingsCrud";
import { convertFormData } from "../../../../_helpers/_utils/utils";
import Swal from "sweetalert2";
import { PulseLoader } from 'react-spinners';

const validate = combineValidators({
  price_from: isRequired({ message: "Price From is required" }),
  price_to: isRequired({ message: "Price to is required" }),
  reason: isRequired({ message: "Reason is required" }),
  // price_view: isRequired({ message: "Price view is required" }),
});

const AdjustPrice = (props) => {
  const {
    handleSubmit,
    pristine,
    onHide,
    submitting,
    invalid,
    selectedValues,
    adjustPrice,
  } = props;

  const [price_history, setPriceHistory] = useState(null);
  const { adjustListingPrice, listingId, loading } = useContext(
    SingleListingContext
  );
  const [showPopup, setShowPopup] = useState(false);
  const [showPopupAttach, setShowPopupAttach] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [previewOpen, setpreviewOpen] = useState(false);
  const [emailContent, setEmailContent] = useState("");
  const [marketReviewFile, setmarketReviewFile] = useState(null);

  const [fileUploadModalOpened, setFileUploadModalOpened] = useState(false);


  useEffect(() => {
    getListingPriceHistory(listingId)
      .then(({ data: { data } }) => {
        setPriceHistory(data);
      })
      .catch(() => {});
  }, []);
  
  const handleUploadFiles = async files => {
    setIsLoading(true);
    let value = {
      listing: listingId, // Ensure that listingId is defined and accessible here
      files: files
    };
  
    if (files?.length > 0) {
      value.files_information = files.map(file => {
        return { is_private: 1, is_price_form: 1 };
      });
    }
  
    let formData = convertFormData(value);
  
    try {
      const response = await uploadListingById(formData);
      toast.success("File Added Successfully");
      // Handle the response if needed
    } catch (e) {
      toast.error(e.response?.data?.message ?? "Error while uploading file");
    } finally {
      setIsLoading(false);
      setmarketReviewFile(files);
      setShowPopupAttach(false);
    }
  };
  

  useEffect(() => {
    console.log(marketReviewFile)
  }, [marketReviewFile]);

  useEffect(() => {
    if (!showPopupAttach && fileUploadModalOpened) {
      // Reopen Swal modal only if it was closed for the file upload modal
      showSwalContainer();
      setFileUploadModalOpened(false); // Reset for next time
    }
  }, [showPopupAttach, fileUploadModalOpened]);

  const attachFile = () => {
    Swal.fire({
      title: "Price Update Notification",
      text: "Would you like to attach a file ?",
      icon: "question",
      showCancelButton: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
      confirmButtonColor: "#A7A9AC",
      cancelButtonColor: "#2BB673",
      confirmButtonText: "No",
      cancelButtonText: "Yes"
    }).then(result => {
      if (result.dismiss === Swal.DismissReason.cancel) {
        setShowPopupAttach(true);
      }
    });
  };
  
  function hideSwalContainer() {
    const swalContainer = document.querySelector(".swal2-container");
    if (swalContainer) {
      swalContainer.style.display = "none";
    }
  }

  function showSwalContainer() {
    const swalContainer = document.querySelector(".swal2-container");
    if (swalContainer) {
      swalContainer.style.display = "flex";
    }
  }

  async function handlePreviewProcess(
    previewButtonId,
    loadingSpinnerId,
    payload
  ) {
    const previewButton = document.getElementById(previewButtonId);
    const loadingSpinner = document.getElementById(loadingSpinnerId);

    loadingSpinner.style.display = "inline-block"; // Show loading spinner
    previewButton.style.display = "none"; // Hide button

    const emailHtml = await onFormSubmitTestLetter(payload);

    if (emailHtml) {
      handlePreviewClick(emailHtml);
    } else {
      console.error("Failed to generate email preview.");
    }

    loadingSpinner.style.display = "none"; // Hide loading spinner
    previewButton.style.display = "inline-block"; // Show button
  }

  function handlePreviewClick(emailHtml) {
    if (emailHtml) {
      setEmailContent(emailHtml);
      setpreviewOpen(true);
      hideSwalContainer();
    } else {
      console.error("Failed to generate email preview.");
    }
  }

  const handleClosePreview= () => {
    setpreviewOpen(false);
    showSwalContainer();
  };

  const onFormSubmitTestLetter = async (values) => {
    const formData = convertFormData(values);
    return await letterTest(formData)
      .then(({data}) => {
        // Destructure 'data' from the response
        if (data) {
          toast.success("Letter generated successfully");
          return data.data; // Assuming 'data' contains the HTML content you want to return
        }
      })
      .catch((e) => {
        handleClosePreview();
        toast.error(e.data.data[0]);
      });
  };

  const handleAdjustPrice = () => {
    const mainModal = document.querySelector('.fade.modal.show');
    if (mainModal) {
      mainModal.style.display = 'none'; // Hide the modal
    }

    setTimeout(() => {
      Swal.fire({
        title: '<span style="font-size:1.9em; font-weight:bold;">Price Update Notification</span>',
        html: `
          <style>
            .loader {
              border: 5px solid #f3f3f3;
              border-top: 5px solid #822433;
              border-radius: 50%;
              width: 20px;
              height: 20px;
              animation: spin 2s linear infinite;
            }

            @keyframes spin {
              0% { transform: rotate(0deg); }
              100% { transform: rotate(360deg); }
            }

            .eye-icon {
              display: inline-block;
              cursor: pointer;
              margin-left: 10px;
            }
            .eye-icon i {
              color: #000000;
            }
            .eye-icon.disabled {
              cursor: not-allowed;
              opacity: 0.5;
              pointer-events: none;
            }
          </style>
          <div style="font-size:1.3em;">
            <p>Select recipients for the email notification:</p>
            <div class="swal2-checkbox-container">
              <div class="swal2-checkboxes">
                <input type="checkbox" id="send-to-vendor"> 
                <label for="send-to-vendor">Send to Vendor</label>
                <span class="eye-icon" id="sendToVendorView"><i class="fas fa-eye"></i></span>
                <div id="loadingSpinner" style="display: none;">
                  <div class="loader"></div>
                </div>
              </div>
              <!--<div class="swal2-checkboxes">
                <input type="checkbox" id="send-to-buyer"> 
                <label for="send-to-buyer">Send to Buyer</label>
                <span class="eye-icon" id="sendToBuyerView"><i class="fas fa-eye"></i></span>
                <div id="loadingSpinner1" style="display: none;">
                  <div class="loader"></div>
                </div>
              </div>-->
            </div>
            <!--<input id="public-price" class="swal2-input" placeholder="Public Price (optional)" type="text">
            <div id="public-price-note" style="font-size: 0.9em; margin-top: 10px; color: #555; display: none;">
              Note: The amount entered in the "Public Price" field will appear as is in the email.
            </div>-->
          </div>
          <style>
          .swal2-checkbox-container {
            display: flex;
            flex-direction: column; /* Stack items vertically */
            align-items: center; /* Center items horizontally */
            justify-content: center; /* Center items vertically if needed */
            width: 100%; /* Ensure the container takes full width */
          }
        
          .swal2-checkboxes {
            display: flex;
            align-items: center;
            margin-bottom: 12px;
            justify-content: flex-start;
          }
        
          .swal2-checkboxes input[type='checkbox'] {
            margin-right: 8px;
            width: 20px;
            height: 20px;
          }
        
          .swal2-checkboxeslabel {
            margin: 0;
            font-size: 16px;
          }
            .swal2-popup {
              font-family: Arial, sans-serif;
              max-width: 500px;
            }

            .swal2-checkboxes input[type='checkbox'] {
              margin-right: 8px;
              width: 20px;
              height: 20px;
            }
            .swal2-checkboxes label {
              margin: 0;
              font-size: 16px;
            }
            #public-price {
              width: 100%;
              padding: 8px;
              font-size: 16px;
              display: none;
              margin-top: 10px;
            }
            .swal2-input[type='number'] {
                max-width: 22em;
            }
          </style>
        `,
        didOpen: () => {
          const sendToVendorView = document.getElementById("sendToVendorView");
          // const sendToBuyerView = document.getElementById("sendToBuyerView");

          sendToVendorView.addEventListener("click", async (event) => {
            let payload = {
              listing: listingId,
              price_from: adjustPrice.price_from,
              price_to: adjustPrice.price_to,
              priceAdjust: 1,
            };
            handlePreviewProcess("sendToVendorView", "loadingSpinner", payload);
            event.stopPropagation();
            event.preventDefault();
          });
  
        /*  sendToBuyerView.addEventListener("click", async (event) => {
            console.log("hooo")
              // Get the input element and its value
              let publicPriceInput = document.getElementById('public-price');
              const publicPriceValue = publicPriceInput.value.trim();

              // If the input is empty, set the value to null
              const publicPrice = publicPriceValue === '' ? null : publicPriceValue;
            let payload = {
              listing: listingId,
              priceAdjustBuyer: 1,
              public_price: publicPrice,
            };
            handlePreviewProcess("sendToBuyerView", "loadingSpinner1", payload);
            event.stopPropagation();
            event.preventDefault();
          });

          const buyerCheckbox = document.getElementById('send-to-buyer');
          const publicPriceInput = document.getElementById('public-price');
          const publicPriceNote = document.getElementById('public-price-note');*/

          const sellerCheckbox = document.getElementById('send-to-vendor');

          sellerCheckbox.addEventListener('change', () => {
            if (sellerCheckbox.checked) {
              hideSwalContainer();
              setShowPopupAttach(true);
              setFileUploadModalOpened(true); // Set this to true when opening the file upload modal
            } 
          });

          /*buyerCheckbox.addEventListener('change', () => {
            if (buyerCheckbox.checked) {
              publicPriceInput.style.display = 'block';
              publicPriceNote.style.display = 'block';
              setTimeout(() => publicPriceInput.focus(), 1000); // Focus the input field with a slight delay
            } else {
              publicPriceInput.style.display = 'none';
              publicPriceNote.style.display = 'block';
            }
          });*/
        },
        onOpen: () => {
          const actions = Swal.getActions();
          actions.style.cssText = "display: flex; flex-direction: row; justify-content: space-between; width: 98%; padding: 0; margin-top: 20px; margin-left: 5px; margin-bottom: 5px; margin-right: 5px;";
          Swal.getCancelButton().style.cssText = "flex-grow: 1; margin: 0 5px; font-size: 1.2em;";
          Swal.getConfirmButton().style.cssText = "flex-grow: 1; margin: 0 5px; background-color: #2BB673; color: white; font-size: 1.2em;";
        },
        showCancelButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
        confirmButtonColor: '#2BB673',
        cancelButtonColor: '#A7A9AC',
        confirmButtonText: 'Send',
        cancelButtonText: 'Cancel',
        reverseButtons: true,
        onRender: () => {
          const popup = Swal.getPopup();
          popup.style.padding = '0px';  // Remove padding
        },
        preConfirm: () => {
          const sendToVendorCheckbox = document.getElementById('send-to-vendor');
          const sendToVendor = sendToVendorCheckbox ? sendToVendorCheckbox.checked : false;


         /* const sendToBuyerCheckbox = document.getElementById('send-to-buyer');
          const publicPriceInput = document.getElementById('public-price');
          const sendToBuyer = sendToBuyerCheckbox ? sendToBuyerCheckbox.checked : false;
          const publicPrice = sendToBuyer && publicPriceInput ? publicPriceInput.value : 0;*/
  
          return new Promise((resolve, reject) => {
            adjustListingPrice(adjustPrice, sendToVendor)
              .then(data => {
                resolve(data);
              })
              .catch(error => {
                reject(error);
              });
          });
        },
        showLoaderOnConfirm: true,
      }).then(result => {
        if (result.isConfirmed) {
          Swal.fire('Success', 'Price Adjusted Successfully', 'success');
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          onHide()
        } else if (result.isRejected) {
          Swal.fire('Error', 'An error occurred', 'error');
        }
      });
    }, 300);
  };  

  return (
    <Fragment>
      {isLoading && (
        <div style={{position: 'fixed', top: 0, left: 0, height: '100vh', width: '100vw', zIndex: 9999, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(0,0,0,0.3)'}}>
          <PulseLoader color={'#822433'} loading={isLoading} size={15} />
        </div>
      )}
      {loading ? <LinearProgress /> : ""}
      <div className="buyerFormModal">
        <form className="contactForm p-4" onSubmit={handleSubmit}>
          <Fragment>
            <ThemeHeader
              left={
                <Fragment>
                  <h5 className="title">Adjust Price</h5>
                </Fragment>
              }
              right={
                <Fragment>
                  <Fragment>
                    <div className="pl-2">
                      <ThemeButton
                        className="lightGrey-button font-weight-bold"
                        onClick={onHide}
                      >
                        Cancel
                      </ThemeButton>
                    </div>
                    <div className="pl-2">
                      <ThemeButton
                        disabled={invalid || pristine || submitting || loading}
                        onClick={() => handleAdjustPrice()}
                        className="submit-button"
                      >
                        Adjust
                      </ThemeButton>
                    </div>
                  </Fragment>
                </Fragment>
              }
            />
            <Row className="pb-3">
              <div className="col-6 pr-1">
                <Field
                  name="price_from"
                  component={TextInputAmount}
                  placeholder="From"
                  type="number"
                  label="New Price From"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                />
              </div>

              <div className="col-6 pl-0">
                <Field
                  name="price_to"
                  type="number"
                  component={TextInputAmount}
                  placeholder="To"
                  label="New Price To"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                />
              </div>
            </Row>
            <Row className="pb-3">
              <Col>
                <Field
                  name="price_view"
                  component={TextInput}
                  placeholder="New Public Price View"
                  label="New Public Price View"
                />
              </Col>
            </Row>
            <Row className="pb-3">
              <Col>
                <Field
                  name="reason"
                  rows={4}
                  component={TextArea}
                  placeholder="Reason"
                  label="Reason"
                />
              </Col>
            </Row>
          </Fragment>
        </form>
        <Row className="pb-3 price-history">
          <div className="pr-5"></div>
          <Col lg={2} className="pl-4 ">
            {" "}
            Past Adjustments
          </Col>
          <Col lg={8}>
            {" "}
            <div className="price-history-body">
              {price_history?.map((obj) => (
                <p>
                  {moment(obj.updated_at).format("DD/MM/YYYY")} $
                  {addNumberSeparator(obj.price_from)} - $
                  {addNumberSeparator(obj.price_to)} {obj.reason}
                </p>
              ))}
            </div>{" "}
          </Col>
        </Row>
      </div>      
      {showPopupAttach && (
        <DefaultModal
          show={showPopupAttach}
          onHide={() => {
            setShowPopupAttach(false);
          }}
          title="Upload file"
          size="lg"
        >
          <Fragment>
            <DragAndDrop handleUploadFiles={handleUploadFiles} 
                          multiple={false}  
            />
            <Fragment>
                  <Fragment>
                    <div className="pl-2">
                      <ThemeButton
                        className="lightGrey-button font-weight-bold"
                        onClick={() => setShowPopupAttach(false)}
                      >
                        Cancel
                      </ThemeButton>
                    </div>
                  </Fragment>
                </Fragment>
          </Fragment>
        </DefaultModal>
      )}
      {previewOpen ? (
        <DefaultModal
          size="xl"
          centered
          show={previewOpen}
          onHide={() => setShowPopup(false)}
          hide={true}
        >
          <EmailReportPreview
            closeModal={handleClosePreview}
            url={emailContent}
          />
        </DefaultModal>
      ) : (
        ""
      )}
    </Fragment>
  );
};

const selector = formValueSelector("adjustPrice");
const mapState = (state) => {
  const values = selector(
    state,
    "price_from",
    "price_to",
    "price_view",
    "reason"
  );
  return {
    adjustPrice: values,
    initialValues: {
      price_view: "",
    },
  };
};
export default connect(
  mapState,
  null
)(
  reduxForm({
    form: "adjustPrice",
    validate,
  })(AdjustPrice)
);
