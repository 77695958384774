import React, {Fragment, useEffect, useState} from "react";
import {Form, Row, Col} from "react-bootstrap";
import {Checkbox, FormControlLabel, IconButton} from "@material-ui/core";
import {Field} from "redux-form";
import TextInput from "./TextInput";
import DeleteIcon from "@material-ui/icons/Delete";
import MultiSelect from "./MultiSelect";
import FlagIcon from "@material-ui/icons/Flag";
import ToggleIcon from "./toggleIcon/ToggleIcon";
import ThemeButton from "../button/ThemeButton";
import {required} from "../../modules/validation/Validation";
import {formToQueryString} from "../../_helpers/_utils/utils";
import {
  getAllContacts,
  getContactById,
} from "../../modules/contacts/crud/contactsCrud";
import Swal from "sweetalert2";
import {getAllProspects} from "../../modules/prospect/curd/prospectsCrud";
import AutoComplete from "./AutoComplete";
//validate
// const required = (value) => (value ? undefined : "Required");

// const numberValidate = (value) =>
//   value && isNaN(Number(value)) ? "Must be a number" : undefined;
// const minValue = (min) => (value) =>
//   value && value < min ? `Must be at least ${min}` : undefined;
// const minValue1 = minValue(1);
// const emailValidate = (value) =>
//   value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
//     ? "Invalid email address"
//     : undefined;
const RenderUnsubscribe = ({input, icon, checkedIcon, name}) => {
  return (
    <Fragment>
      <FormControlLabel
        control={
          <Checkbox
            onClick={(e) =>
              input.onChange(e.target.checked ? new Date() : null)
            }
            checked={input.value ? true : false}
            icon={icon}
            checkedIcon={checkedIcon}
            name={input.name}
          />
        }
      />
    </Fragment>
  );
};

const NestedPhoneEmailAutoComplete = ({
  contactFields,
  fields,
  options,
  mobile,
  email,
  meta: {error, submitFailed},
  placeholder,
  defaultValue,
  setFillFormData,
  fromDiary,
}) => {
  const [contactsList, setContactsList] = useState([]);
  const [contactLoaded, setContactLoaded] = useState([]);
  const [selectedProspect, setSelectedProspect] = useState(null);
  const [allProspects, setAllProspects] = useState({});
  //
  const handleOnchange = (e) => {
    if (e.target.value) {
      let query = mobile
        ? formToQueryString({phone: e.target.value})
        : formToQueryString({email: e.target.value});
      getAllContacts(query)
        .then(({data: {data}}) => {
          setContactsList(
            data
              .filter((d) => d.first_name)
              ?.map((obj) =>
                // obj.first_name
                ({
                  name: obj.first_name + "" + obj.last_name,
                  ...obj,
                })
              )
          );
        })
        .catch((e) => {});
    } else {
      setContactsList([]);
    }

    console.log(e.target.value);
  };

  const handleSelectedContact = async (e, index) => {
    let contactInfo;
    if (fromDiary) return;
    // debugger;
    if (e) {
      getContactById(e.id)
        .then(({data}) => {
          const contactdata = data.data;

          let query = formToQueryString({contact: e.id});
          getAllProspects(query)
            .then(({data: {data}}) => {
              console.log(contactdata?.emails?.length > 0, "why?_");
              let obj = {};
              for (let i = 0; i < data.length; i++) {
                obj[data[i]?.id] =
                  data[i]?.property?.address?.street_number +
                  data[i]?.property?.address?.street?.name;
              }

              if (data?.length > 0) {
                Swal.fire({
                  title: "Select Prospect",
                  input: "radio",
                  inputOptions: obj,
                  inputValidator: (value) => {
                    if (!value) {
                      return "You need to choose something!";
                    }
                  },
                }).then((d) => {
                  if (d) {
                    Swal.fire(
                      `You selected: ${
                        data?.find((da) => da?.id == d?.value)?.property
                          ?.address?.street?.name
                      }`
                    );
                    // console.log(
                    //   data?.find((da) => da?.id == d?.value),
                    //   data,
                    //   "data_selected_",
                    //   d
                    // );
                    setSelectedProspect(data?.find((da) => da?.id == d?.value));
                    setFillFormData({
                      app_type: 4,
                      contacts: [
                        {
                          emails:
                            contactdata?.emails?.length > 0
                              ? contactdata?.emails
                              : [{type: 1, value: ""}],
                          phones:
                            contactdata?.phones?.length > 0
                              ? contactdata?.phones
                              : [{type: 1, value: ""}],
                          first_name: contactdata?.first_name,
                          last_name: contactdata?.last_name,
                        },
                      ],
                      prospect_address: {
                        meeting_location: [
                          {
                            street_unit: data?.find((da) => da?.id == d?.value)
                              ?.property?.address?.street_unit,
                            street_number: data?.find(
                              (da) => da?.id == d?.value
                            )?.property?.address?.street_number,
                            street_name: {
                              value: data?.find((da) => da?.id == d?.value)
                                ?.property?.address?.street?.id,
                              label: data?.find((da) => da?.id == d?.value)
                                ?.property?.address?.street?.name,
                              suburb_name: data?.find(
                                (da) => da?.id == d?.value
                              )?.property?.address?.street?.suburb?.name,
                              suburb_id: data?.find((da) => da?.id == d?.value)
                                ?.property?.address?.street?.suburb?.id,
                              state_id: data?.find((da) => da?.id == d?.value)
                                ?.property?.address?.street?.suburb?.state?.id,
                              state_name: data?.find((da) => da?.id == d?.value)
                                ?.property?.address?.street?.suburb?.state
                                ?.name,
                              postcode: data?.find((da) => da?.id == d?.value)
                                ?.property?.address?.street?.suburb?.postcode,
                            },
                            suburb: {
                              value: data?.find((da) => da?.id == d?.value)
                                ?.property?.address?.street?.suburb?.id,
                              label: data?.find((da) => da?.id == d?.value)
                                ?.property?.address?.street?.suburb?.name,
                            },
                            state: {
                              label: data?.find((da) => da?.id == d?.value)
                                ?.property?.address?.street?.suburb?.state
                                ?.name,
                              value: data?.find((da) => da?.id == d?.value)
                                ?.property?.address?.street?.suburb?.state?.id,
                            },
                            postcode: data?.find((da) => da?.id == d?.value)
                              ?.property?.address?.street?.suburb?.postcode,
                          },
                        ],
                      },
                    });
                  }
                });
              } else {
                setFillFormData({
                  // app_type: 4,
                  contacts: [
                    {
                      emails:
                        contactdata?.emails.length > 0
                          ? contactdata?.emails
                          : [{type: 1, value: ""}],
                      phones:
                        contactdata?.phones.length > 0
                          ? contactdata?.phones
                          : [{type: 1, value: ""}],
                      first_name: contactdata?.first_name,
                      last_name: contactdata?.last_name,
                    },
                  ],

                  prospect_address: {
                    meeting_location: [
                      {
                        street_unit: contactdata?.addresses?.[0]?.street_unit,
                        street_number:
                          contactdata?.addresses?.[0]?.street_number,
                        street_name: {
                          value: contactdata?.addresses?.[0]?.street?.id,
                          label: contactdata?.addresses?.[0]?.street?.name,
                          suburb_name:
                            contactdata?.addresses?.[0]?.street?.suburb?.name,
                          suburb_id:
                            contactdata?.addresses?.[0]?.street?.suburb?.id,
                          state_id:
                            contactdata?.addresses?.[0]?.street?.suburb?.state
                              ?.id,
                          state_name:
                            contactdata?.addresses?.[0]?.street?.suburb?.state
                              ?.name,
                          postcode:
                            contactdata?.addresses?.[0]?.street?.suburb
                              ?.postcode,
                        },
                        suburb: {
                          value:
                            contactdata?.addresses?.[0]?.street?.suburb?.id,
                          label:
                            contactdata?.addresses?.[0]?.street?.suburb?.name,
                        },
                        state: {
                          label:
                            contactdata?.addresses?.[0]?.street?.suburb?.state
                              ?.name,
                          value:
                            contactdata?.addresses?.[0]?.street?.suburb?.state
                              ?.id,
                        },
                        postcode:
                          contactdata?.addresses?.[0]?.street?.suburb?.postcode,
                      },
                    ],
                  },
                });
              }
            })
            .catch((e) => {});
          // setContactLoaded(contactLoaded.concat(index));
        })
        .catch((e) => {});
    }

    // if (e) {
    //   Swal.fire({
    //     title: "Update Contact?",
    //     text:
    //       "This will open " +
    //       e?.first_name +
    //       " " +
    //       e?.last_name +
    //       "'s contact details so you can update or use their contact.",
    //     icon: "warning",
    //     showCancelButton: true,
    //     confirmButtonColor: "#A7A9AC",
    //     cancelButtonColor: "#2BB673",
    //     confirmButtonText: "Cancel",
    //     cancelButtonText: "Yes",
    //   }).then((result) => {
    //     console.log(result);
    //     if (result.isDismissed) {
    //       let contactInfo;
    //       getContactById(e.id)
    //         .then(({ data: { data } }) => {
    //           contactInfo = data;
    //           let filedValue = fields.getAll();
    //           console.log(data);
    //           filedValue[index].first_name = contactInfo?.first_name;
    //           filedValue[index].last_name = contactInfo?.last_name;
    //           filedValue[index].phones =
    //             contactInfo?.phones?.length > 0
    //               ? contactInfo?.phones?.map(({ type, ...obj }) => ({
    //                   type: type.id,
    //                   ...obj,
    //                 }))
    //               : [{ type: 1, value: "" }];
    //           filedValue[index].emails =
    //             contactInfo?.emails?.length > 0
    //               ? contactInfo?.emails?.map(({ type, ...obj }) => ({
    //                   type: type.id,
    //                   ...obj,
    //                 }))
    //               : [{ type: 1, value: "" }];
    //           filedValue[index].contact_id = contactInfo?.id;
    //           console.log(filedValue);
    //           fields.removeAll();
    //           filedValue.map((obj) => fields.push(obj));
    //           setContactLoaded(contactLoaded.concat(index));
    //         })
    //         .catch((e) => {});
    //     }
    //   });
    // }
  };
  return (
    <Fragment>
      {fields.map((member, index) => (
        <Row className="pb-2">
          {(mobile || email) && index === 0 ? (
            ""
          ) : (
            <Col className="col-5">
              <Field
                name={
                  fields.get(index).type.id
                    ? `${member}.type.id`
                    : `${member}.type`
                }
                component={MultiSelect}
                showBottom={false}
                isMulti={false}
                defaultValue={defaultValue}
                options={options}
                placeholder="Please select"
                label="Type"
              />
            </Col>
          )}

          <Col>
            {" "}
            {contactLoaded.includes(index) ? (
              <Field
                name={`${member}.value`}
                component={TextInput}
                label={mobile ? "Number" : "Email Address"}
              />
            ) : fromDiary ? (
              <Field
                name={`${member}.value`}
                component={TextInput}
                label={"Number"}
              />
            ) : (
              <Field
                label={mobile ? "Number" : "Email Address"}
                placeholder={mobile ? "Number" : "Email Address"}
                name={`${member}.value`}
                type="text"
                // component={TextInput}
                // error={!fields.get(index)?.value?.length > 0}
                // validate={[required]}
                handleOnchange={handleOnchange}
                options={contactsList}
                selectedOption={(e) => handleSelectedContact(e, index)}
                component={AutoComplete}
              />
            )}
          </Col>
          {/* <Col className="col-2 m-auto"> */}
          <div>
            {index === 0 ? (
              ""
            ) : (
              <ThemeButton
                className="small-icon lightGrey-icon-button"
                icon
                onClick={() => fields.remove(index)}
              >
                <i className="fas fa-minus-circle"></i>
              </ThemeButton>
            )}
            <Field
              name={`${member}.unsubscribe_date`}
              icon={<FlagIcon />}
              checkedIcon={<FlagIcon className="brand-color" />}
              component={RenderUnsubscribe}
            />
          </div>

          {/* </Col> */}
        </Row>
      ))}
      <div>
        <p>
          <IconButton
            onClick={() => fields.push({type: 2})}
            aria-label="delete"
            size="small"
            className="brand-icon-button pr-2"
          >
            <i className="fas fa-plus-circle"></i>
          </IconButton>
          Add{" "}
          {mobile ? (
            <Fragment>phone number</Fragment>
          ) : (
            <Fragment>email address</Fragment>
          )}
        </p>
      </div>
    </Fragment>
  );
};

export default NestedPhoneEmailAutoComplete;
