import React from "react";

const Item = ({
  left,
  right,
  col,
  md,
  lg,
  xl,
  className,
  leftClass,
  rightClass,
  isRow,
  mainClass,
  noMargin,
  hasHtml = false
}) => {
  const preClass = `col-${col} col-md-${md} col-lg-${lg} col-xl-${xl ? xl : lg
    } ${noMargin ? "" : "m-auto"}  ${className}`;
  return (
    <div className={"row " + mainClass}>
      <div className={preClass + "  text-ellipsis"}>
        <div className={"min-content" + (leftClass ? leftClass : "")}>
          {left}
          {isRow ? "" : left?.length > 0 && ":"}
        </div>
      </div>
      <div className={"col " + rightClass}>
        {hasHtml ? <div dangerouslySetInnerHTML={{ __html: right }} />
          : <div>{right}</div>}

      </div>
    </div>
  );
};

Item.defaultProps = {
  col: "4",
  md: "4",
  lg: "3",
  className: "",
  noMargin: false,
  mainClass: "pb-2",
};

export default Item;
