import { PixelCrop } from "react-image-crop";
import { canvasPreview } from "./canvasPreview";

let previewUrl = "";

function toBlob(canvas) {
  return new Promise((resolve) => {
    canvas.toBlob(resolve);
  });
}

// Returns an image source you should set to state and pass
// `{previewSrc && <img alt="Crop preview" src={previewSrc} />}`
export async function imgPreview(image, crop, scale = 1, rotate = 0) {
  const canvas = document.createElement("canvas");
  canvasPreview(image, canvas, crop, scale, rotate);
  //   image.crossOrigin = '*'
  //   canvas.width = crop.width;
  //   canvas.height = crop.height;
  //   const ctx = canvas.getContext("2d");
  //   console.log("============crop========================");
  //   console.log(crop);
  //   console.log("===========crop=========================");

  //   ctx.drawImage(
  //     image,
  //     crop.x * scale,
  //     crop.y * scale,
  //     crop.width * scale,
  //     crop.height * scale,
  //     0,
  //     0,
  //     crop.width,
  //     crop.height
  //   );
  //   ctx.restore();

  //   return new Promise((resolve, reject) => {
  //     canvas.toBlob(
  //       (blob) => {
  //         if (!blob) {
  //           reject(new Error("Canvas is empty"));
  //           return;
  //         }
  //         blob.name = "test";
  //         const fileUrl = window.URL.createObjectURL(blob);
  //         resolve(fileUrl);
  //       },
  //       "image/jpeg",
  //       1
  //     );
  //   });

  const blob = await toBlob(canvas);

  if (!blob) {
    console.error("Failed to create blob");
    return "";
  }

  if (previewUrl) {
    URL.revokeObjectURL(previewUrl);
  }

  previewUrl = URL.createObjectURL(blob);
  return previewUrl;
}
