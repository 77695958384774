import React, { useEffect, useState } from "react";
import MaterialSelect from "../form-basic/select/MaterialSelect";
import MaterialMultiSelect from "../form-basic/select/MaterialMultiSelect";
import _isArray from "lodash/isArray";

const MultiSelect = props => {
  const {
    edit,
    input,
    options,
    label,
    placeholder,
    onChange,
    showBottom,
    isMulti,
    defaultValue,
    className,
    otherValue,
    bulkFilter,
    limit,
    removable = false, 
    fetchMore = () => {},
    enableLoading = false,
    customHeight = false,
    divStyle = {},
    ...others
  } = props;

  const [optionList, setOptionList] = useState([]);

  const initialValue = bulkFilter
    ? optionList?.some(obj => obj.id === input?.value?.[0])
      ? input?.value?.[0]
      : ""
    : optionList?.some(obj => obj.id === input?.value)
    ? input?.value
    : "";

  if (input?.value?.id) input.value = input.value.id;

  useEffect(() => {
    if (_isArray(options)) {
      setOptionList(options);
    } else {
      setOptionList([]);
    }
  }, [options]);

  useEffect(() => {
    if (defaultValue) input.onChange(defaultValue);
  }, []);

  //   Remove Selected Item
  const handleRemove = value => {
    input.onChange(
      input.value.filter(function(el) {
        return el !== value;
      })
    );
  };

  const getValue = (opts, val) => {
    if (isMulti) {
      return opts?.filter(o => val.includes(o.id));
    } else {
      return opts?.filter(o => val === o.id);
    }
  };

  const handleMultiChange = e => {
    // debugger;
    if (typeof onChange !== "undefined") {
      onChange(e);
    } else {
      input.onChange(e);
    }
  };

  const handleChange = e => {
    if (typeof onChange !== "undefined") {
      onChange(e);
    } else {
      input.onChange(e);
    }
  };

  const handleClick = e => {
    if (input?.value) {
      const value = e.target.value;
      if (value === undefined) {
        if (typeof onChange !== "undefined") {
          onChange("");
        } else {
          input.onChange("");
        }
      }
    }
  };

  return (
    <>
      {edit ? (
        <div
          className={"multiSelect " + className ? className : ""}
          style={divStyle}
        >
          <>
            {isMulti && optionList?.length > 0 ? (
              <MaterialMultiSelect
                {...others}
                otherValue={otherValue}
                label={label}
                placeholder={placeholder}
                options={optionList?.length > 0 ? optionList : []}
                limit={limit ?? 999}
                value={
                  optionList?.some(obj => `${input?.value}`.includes(obj.id))
                    ? input?.value
                    : []
                }
                onChange={e => handleMultiChange(e)}
              />
            ) : (
              <MaterialSelect
                enableLoading={enableLoading}
                customHeight={customHeight}
                fetchMore={fetchMore}
                label={label}
                otherValue={otherValue}
                placeholder={placeholder}
                removable={removable}
                options={optionList?.length > 0 ? optionList : []}
                value={
                  initialValue || initialValue === 0
                    ? initialValue
                    : defaultValue !== true && defaultValue !== ""
                    ? defaultValue
                    : ""
                }
                {...others}
                bulkFilter={bulkFilter}
                onChange={e => handleChange(e)}
                onClick={e => handleClick(e)}
              />
            )}
          </>

          {showBottom ? (
            <div className="d-flex flex-wrap selectedItems pt-3">
              {getValue(optionList, input.value)?.length > 0 &&
                getValue(optionList, input.value).map((item, index) => (
                  <div key={index} className="btn-group pr-2 pb-2" role="group">
                    <button
                      type="button"
                      className="btn brand-button btn-sm text-white"
                    >
                      {item.label}
                    </button>
                    <button
                      onClick={() => handleRemove(item.value)}
                      type="button"
                      className="btn brand-button btn-sm text-white"
                    >
                      <i className="fas fa-times"></i>
                    </button>
                  </div>
                ))}
            </div>
          ) : null}
        </div>
      ) : (
        <h6>{input.value.value}</h6>
      )}
    </>
  );
};

MultiSelect.defaultProps = {
  isMulti: true, //  pass true or false to make that specific Select Field multiple input accept or not. By default it's can accept multiple input.
  edit: true, // pass true or false to make that specific field editable or not. By default it's editable
  showBottom: false, // pass true or false to make selected options Bottom of the option field.
  defaultValue: ""
};

export default MultiSelect;
