import React from "react";
// import {mailTemplateHtmlString} from "../../../../../_metronic/_assets/media/html/MailTemplates";
// import parse, {attributesToProps} from "html-react-parser";

const EmailReportPreview = ({url, closeModal}) => {
  const closeButtonStyle = {
    padding: "10px",
    backgroundColor: "transparent", // Transparent background
    color: "black", // Your specified color
    border: "none",
    fontSize: "40px", // Larger font size for the X
    cursor: "pointer",
    lineHeight: "1", // Ensure the button doesn't add extra height
    position: "absolute", // Positioning the button
    top: "10px", // Distance from the top of the container
    right: "10px", // Distance from the right of the container
  };

  return (
    <div style={{position: "relative"}}>
      {" "}
      {/* Container to position the button */}
      <button onClick={closeModal} style={closeButtonStyle} aria-label="Close">
        &times; {/* HTML entity for X */}
      </button>
      <div
        className="container"
        id="seller-preview"
        style={{
          backgroundColor: "#822433",
          // backgroundColor: "#f5f5f5",
          overflow: "auto",
          height: "65rem",
          display: "flex",
          justifyContent: "center",
          alignItems: "baseline",
        }}
      >
        <div
          dangerouslySetInnerHTML={{__html: url}}
          style={{
            width: "600px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        />
      </div>
    </div>
  );
};

export default EmailReportPreview;
