import React, { useEffect, useState } from 'react'
import { TextField, } from '@material-ui/core'

const AgentDetailsSettings = ({ selectedNode }) => {
    const [agentPhone, setAgentPhone] = useState('')
    const [agentMail, setAgentMail] = useState('')
    const [agentName, setAgentName] = useState('')

    const getTargetElement = targetClassName => {
        return selectedNode.getElementsByClassName(targetClassName)
    }
    useEffect(() => {

        let agentName = getTargetElement('agent_name')[0].innerText
        setAgentName(agentName);
        let agentmail = getTargetElement('agent_mail')[0].innerText;
        setAgentMail(agentmail);
        let agentPhone = getTargetElement('agent_phone')[0].innerText
        setAgentPhone(agentPhone);

    }, [selectedNode])

    const handleAgentPhoneChange = e => {
        getTargetElement('agent_phone')[0].innerHTML = e.target.value
        setAgentPhone(e.target.value);
    }

    const handleAgentNameChange = e => {
        getTargetElement('agent_name')[0].innerHTML = e.target.value;
        setAgentName(e.target.value);
    }
    const handleAgentEmailChange = e => {
        getTargetElement('agent_mail')[0].innerHTML = e.target.value;
        setAgentMail(e.target.value);
    }

    return (
        <div>
            <TextField
                className='my-3'
                type="text"
                label={'Agent Name'}
                value={agentName}
                onChange={handleAgentNameChange}
                fullWidth
                InputLabelProps={{
                    shrink: true
                }}
            />
            <TextField
                className='my-3'
                type="text"
                label={'Agent Email'}
                value={agentMail}
                onChange={handleAgentEmailChange}
                fullWidth
                InputLabelProps={{
                    shrink: true
                }}
            />
            <TextField
                className='my-3'
                type="text"
                label={'Agent Phone'}
                value={agentPhone}
                onChange={handleAgentPhoneChange}
                fullWidth
                InputLabelProps={{
                    shrink: true
                }}
            />

        </div>
    )
}
export default AgentDetailsSettings
