import React, { Fragment, useEffect, useState, useMemo } from "react";
import { Form, Row } from "react-bootstrap";
import htmlToDraft from 'html-to-draftjs';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertFromHTML, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import _includes from 'lodash/includes';
// import draftToMarkdown from "draftjs-to-markdown";
// import { stateFromMarkdown } from "draft-js-import-markdown";

const embedVideoCallBack = (url) => {
  var pattern1 = /(?:http?s?:\/\/)?(?:www\.)?(?:vimeo\.com)\/?(.+)/g;
  var pattern2 = /(?:http?s?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=)?(.+)/g;
  // var pattern3 = /([-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?(?:jpg|jpeg|gif|png))/gi;
  let embedUrl = url;
  let replacement = "";

  if (pattern1.test(url) && !_includes(url, 'player.')) {
    replacement = '//player.vimeo.com/video/$1';
    embedUrl = url.replace(pattern1, replacement);
  }

  if (pattern2.test(url) && !_includes(url, '/embed')) {
    replacement = 'https://www.youtube.com/embed/$1';
    embedUrl = url.replace(pattern2, replacement);
  }

  // if (pattern3.test(url)) {
  //     replacement = '<a href="$1" target="_blank"><img class="sml" src="$1" /></a><br />';
  //     embedUrl = url.replace(pattern3, replacement);
  // }
  return embedUrl;
};

const REDO_ICON = 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iMTYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTYuNTA0IDEzLjk3N2E0LjQ5NyA0LjQ5NyAwIDAgMS00LjQ5Mi00LjQ5MiA0LjQ5NyA0LjQ5NyAwIDAgMSA0LjQ5Mi00LjQ5M3YyLjk5NWw0Ljk5LTMuOTkzTDYuNTA0IDB2Mi45OTVhNi40OTYgNi40OTYgMCAwIDAtNi40ODggNi40OWMwIDMuNTc4IDIuOTEgNi40OSA2LjQ4OCA2LjQ5YTYuNDk2IDYuNDk2IDAgMCAwIDYuNDg3LTYuNDloLTEuOTk2YTQuNDk3IDQuNDk3IDAgMCAxLTQuNDkxIDQuNDkyeiIgZmlsbD0iIzAwMCIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+'

const OPTIONS = {
  options: ['inline', 'fontSize', 'fontFamily', 'colorPicker', 'textAlign', 'list', 'link', 'emoji', 'embedded', 'image', 'history'],
  inline: { inDropdown: true },
  fontSize: {
    options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
  },
  list: {
    options: ['unordered'],
  },
  textAlign: { inDropdown: true },
  fontFamily: {
    options: ['Arial', 'ChunkFive', 'Playlist'],
  },
  colorPicker: {
    colors: ['rgb(130,36,51)', 'rgb(65,64,66)', 'rgb(255,255,255)'],
  },
  history: {
    redo: { icon: REDO_ICON, className: 'redo-icon' },
  },
  embedded: {
    embedCallback: embedVideoCallBack
  }
}

const MultiTextEeditor = ({
  input,
  rows,
  meta,
  classnames,
  placeholder,
  meta: { touched, error },
  edit,
  options,
  returnHtml,
  initialValues = "",
  emailContent,
  isContentHavingImage = false,
  noImage = false,
  ...props
}) => {
  const initializeEditorState = () => {
    // if (isContentHavingImage) {
    //   const blocksFromHtml = htmlToDraft(initialValues);
    //   return ContentState.createFromBlockArray(blocksFromHtml.contentBlocks, blocksFromHtml.entityMap);
    // } else {
    //   const contentBlock = convertFromHTML(initialValues);
    //   return ContentState.createFromBlockArray(contentBlock.contentBlocks);
    // }
    const blocksFromHtml = htmlToDraft(initialValues);
    return ContentState.createFromBlockArray(blocksFromHtml.contentBlocks, blocksFromHtml.entityMap);
  }
  const [data, setData] = useState(
    EditorState.createWithContent(initializeEditorState()
    )
  );

  const defaulOptions = useMemo(() => {
    if (noImage) {
      return {
        ...OPTIONS,
        options: OPTIONS.options.filter(item => item !== 'image')
      }
    }
    return OPTIONS
  }, [noImage])

  const handleChange = (e) => {
    setData(e);
    const value = draftToHtml(convertToRaw(e.getCurrentContent()));
    input.onChange(value);
    //  if (returnHtml) {
    //    input.onChange(draftToHtml(convertToRaw(e.getCurrentContent())));
    // } else {
    //    input.onChange(draftToMarkdown(convertToRaw(e.getCurrentContent())));
    // }
  };

  useEffect(() => {
    if (meta?.dirty) {
      return;
    }
    if (!input?.value) {
      return;
    }
    console.log('initialValues', initialValues)
    setData(EditorState.createWithContent(initializeEditorState()));

  }, [initialValues]);

  return (
    <Fragment>
      {edit ? (
        <Fragment>
          <div className={"d-flex textAreaEmoji " + classnames}>
            <Editor
              wrapperClassName="textAreaEmoji-wrapper w-100"
              editorClassName="textAreaEmoji-editor w-100"
              onEditorStateChange={handleChange}
              toolbar={{ ...defaulOptions, ...options }}
              editorState={data}
            />
          </div>
          <Form.Control.Feedback type="invalid">
            {touched && error && <span>{error}</span>}
          </Form.Control.Feedback>
        </Fragment>
      ) : (
        <Fragment>
          <Row>
            <p>{input?.value}</p>
          </Row>
        </Fragment>
      )}
    </Fragment>
  );
};
MultiTextEeditor.defaultProps = {
  rows: "3",
  edit: true,
  options: {},
  returnHtml: true,
};

export default MultiTextEeditor;
